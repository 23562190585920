/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ZoomType = {
    None: 'None',
    ByDuration: 'ByDuration',
    ByDepth: 'ByDepth',
    ByDate: 'ByDate'
} as const;
export type ZoomType = typeof ZoomType[keyof typeof ZoomType];


export function instanceOfZoomType(value: any): boolean {
    for (const key in ZoomType) {
        if (Object.prototype.hasOwnProperty.call(ZoomType, key)) {
            if (ZoomType[key as keyof typeof ZoomType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ZoomTypeFromJSON(json: any): ZoomType {
    return ZoomTypeFromJSONTyped(json, false);
}

export function ZoomTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoomType {
    return json as ZoomType;
}

export function ZoomTypeToJSON(value?: ZoomType | null): any {
    return value as any;
}

