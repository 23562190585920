import { PDComponent } from "components/PDComponents";
import { useManageTabsModal } from "pages/WellDashboard/ManageTabs/useManageTabsModal";
import { useShareTabsModal } from "pages/WellDashboard/ShareTabs/ShareTabsModal/useShareTabsModal";
import { useCallback, useEffect, useState } from "react";
import { Dropdown, Tooltip } from "utils/componentLibrary";

import * as Styled from "./style";

export const TabManagementDropdown = ({ openEdit }: { openEdit: () => void }) => {
  const [isManagementMenuOpen, setIsManagementMenuOpen] = useState(false);
  const { openModal: openShareTabsModal, modalElement: shareTabsElement } = useShareTabsModal();
  const { openModal: openManageTabsModal, modalElement: manageTabsModal } = useManageTabsModal();

  const handleOnClickShare = useCallback(() => {
    openShareTabsModal();
    setIsManagementMenuOpen(false);
  }, [openShareTabsModal]);

  const handleOnClickManageTabs = useCallback(() => {
    openManageTabsModal();
    setIsManagementMenuOpen(false);
  }, [openManageTabsModal]);

  useEffect(() => {
    const element = document.getElementById("scroll-container-id-fix-tbf");
    const onScroll = () => {
      setIsManagementMenuOpen(false);
    };
    element?.addEventListener("scroll", onScroll);
    return () => {
      element?.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      <Dropdown
        overlay={
          <Styled.TabOperationChoiceModal>
            <Styled.ModalRow onClick={openEdit}>
              <PDComponent.SvgIcon name="editTab" />
              Edit tabs
            </Styled.ModalRow>
            <Styled.ModalRow onClick={handleOnClickShare}>
              <Styled.SendIcon />
              Share tabs
            </Styled.ModalRow>
            <Styled.ModalRow onClick={handleOnClickManageTabs}>
              <PDComponent.SvgIcon name="collapseAll" />
              Manage tabs
            </Styled.ModalRow>
          </Styled.TabOperationChoiceModal>
        }
        placement="bottomRight"
        trigger={["click"]}
        onOpenChange={(open) => setIsManagementMenuOpen(open)}
        open={isManagementMenuOpen}
      >
        <Tooltip title={"Tab Menu"} overlayStyle={{ maxWidth: "none" }} placement="topLeft" arrowPointAtCenter>
          <Styled.DropdownButton>
            <PDComponent.SvgIcon name="stemLeaf" />
          </Styled.DropdownButton>
        </Tooltip>
      </Dropdown>
      {shareTabsElement}
      {manageTabsModal}
    </>
  );
};
