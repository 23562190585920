import { Title } from "atoms/Typography";
import styled from "styled-components";
import { useCustomTheme } from "utils/useTheme";

const Value = styled(Title).attrs({
  level: 3,
})`
  font-size: 24px !important;
  font-weight: 500 !important;
`;

export default function KpiView({ title, value }: { title: string; value: string }) {
  const { atomThemeVariant } = useCustomTheme();
  return (
    <div>
      <Title level={5} variant={"faded"}>
        {title}
      </Title>
      <Value variant={atomThemeVariant}>{value}</Value>
    </div>
  );
}
