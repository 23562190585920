/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TimeUnit = {
    Unknown: 'Unknown',
    Second: 'Second',
    Minute: 'Minute',
    Hour: 'Hour',
    Day: 'Day',
    Week: 'Week',
    Month: 'Month',
    Quarter: 'Quarter',
    Year: 'Year'
} as const;
export type TimeUnit = typeof TimeUnit[keyof typeof TimeUnit];


export function instanceOfTimeUnit(value: any): boolean {
    for (const key in TimeUnit) {
        if (Object.prototype.hasOwnProperty.call(TimeUnit, key)) {
            if (TimeUnit[key as keyof typeof TimeUnit] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TimeUnitFromJSON(json: any): TimeUnit {
    return TimeUnitFromJSONTyped(json, false);
}

export function TimeUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeUnit {
    return json as TimeUnit;
}

export function TimeUnitToJSON(value?: TimeUnit | null): any {
    return value as any;
}

