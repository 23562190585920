/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface TagBottomFingerprintUserLensQueryDto
 */
export interface TagBottomFingerprintUserLensQueryDto {
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    useMockData: boolean;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    sampleCount: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    comparisonWellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    selectedRigIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    selectedFilters: FiltersDto;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    zoomStartDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    zoomEndDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    zoomStartDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    zoomEndDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensQueryDto
     */
    wellId: number;
}

/**
 * Check if a given object implements the TagBottomFingerprintUserLensQueryDto interface.
 */
export function instanceOfTagBottomFingerprintUserLensQueryDto(value: object): value is TagBottomFingerprintUserLensQueryDto {
    if (!('useMockData' in value) || value['useMockData'] === undefined) return false;
    if (!('sampleCount' in value) || value['sampleCount'] === undefined) return false;
    if (!('selectedFilters' in value) || value['selectedFilters'] === undefined) return false;
    if (!('from' in value) || value['from'] === undefined) return false;
    if (!('to' in value) || value['to'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    return true;
}

export function TagBottomFingerprintUserLensQueryDtoFromJSON(json: any): TagBottomFingerprintUserLensQueryDto {
    return TagBottomFingerprintUserLensQueryDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintUserLensQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintUserLensQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'useMockData': json['UseMockData'],
        'sampleCount': json['SampleCount'],
        'comparisonWellIds': json['ComparisonWellIds'] == null ? undefined : json['ComparisonWellIds'],
        'selectedRigIds': json['SelectedRigIds'] == null ? undefined : json['SelectedRigIds'],
        'selectedFilters': FiltersDtoFromJSON(json['SelectedFilters']),
        'zoomStartDuration': json['ZoomStartDuration'] == null ? undefined : json['ZoomStartDuration'],
        'zoomEndDuration': json['ZoomEndDuration'] == null ? undefined : json['ZoomEndDuration'],
        'zoomStartDepth': json['ZoomStartDepth'] == null ? undefined : json['ZoomStartDepth'],
        'zoomEndDepth': json['ZoomEndDepth'] == null ? undefined : json['ZoomEndDepth'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'wellId': json['WellId'],
    };
}

export function TagBottomFingerprintUserLensQueryDtoToJSON(value?: TagBottomFingerprintUserLensQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'UseMockData': value['useMockData'],
        'SampleCount': value['sampleCount'],
        'ComparisonWellIds': value['comparisonWellIds'],
        'SelectedRigIds': value['selectedRigIds'],
        'SelectedFilters': FiltersDtoToJSON(value['selectedFilters']),
        'ZoomStartDuration': value['zoomStartDuration'],
        'ZoomEndDuration': value['zoomEndDuration'],
        'ZoomStartDepth': value['zoomStartDepth'],
        'ZoomEndDepth': value['zoomEndDepth'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'WellId': value['wellId'],
    };
}

