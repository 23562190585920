import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { ApiWellsIdTvdsPutRequest, RawTvdSeriesDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import type { PDWellOverviewZoomQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";
import { PDQueryType } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";
import { useSelectedWell } from "./useSelectedWell";

const wells = new WellsApi(apiConfig);
interface IZoomParams {
  report?: boolean;
  lens?: boolean;
  options?: UseQueryOptions<RawTvdSeriesDto>;
}

export function useOverviewZoomData({ report = false, lens = false, options }: IZoomParams) {
  const wellDataID = useSelectedWell({ isReport: report });
  const comparisonWellIds = useAppSelector((state) => state.report.offsetWells);
  const [offsetWells] = useStateQuery<Array<number>>(
    lens ? URL_STATE_PARAM.OFFSET_WIDGET : URL_STATE_PARAM.OFFSET_WELL,
    [],
  );

  const requestParameters: ApiWellsIdTvdsPutRequest = useMemo(() => {
    return {
      id: wellDataID,
      baseFocalQueryDto: {
        useMockData: false,
        selectedFilters: {
          includeFlatTime: true,
          includeRotatingTime: true,
          includeSlidingTime: true,
          includeNullHoleDepth: true,
          includeAlphaRigs: true,
          includeNoneAlphaRigs: true,
        },
        from: defaultDateDto.from,
        to: defaultDateDto.to,
        wellId: wellDataID,
        sampleCount: TVD_SAMPLE_COUNT,
        comparisonWellIds: report ? comparisonWellIds : offsetWells,
      },
    };
  }, [comparisonWellIds, offsetWells, report, wellDataID]);

  const WellOverviewQueryZoomKey: PDWellOverviewZoomQueryKey = {
    type: PDQueryType.WELL_OVERVIEW_ZOOM,
    uid: RequestUID.wellOverviewZoom,
    offsetWells: report ? comparisonWellIds : offsetWells,
    wellId: wellDataID,
  };

  const defaultValue: RawTvdSeriesDto = {
    wellId: 0,
    tvdSeries: {
      wellId: 0,
      lastUpdatedAt: defaultDateDto.from,
      dataState: "NoData",
    },
    planTvdSeries: {
      wellId: 0,
      planId: 0,
      lastUpdatedAt: defaultDateDto.from,
      dataState: "NoData",
    },
    comparisonTvdSeries: null,
  };

  const { data } = useQuery<RawTvdSeriesDto>({
    queryKey: [WellOverviewQueryZoomKey],
    queryFn: () => {
      if (requestParameters === null) return new Promise((resolve) => resolve(defaultValue));
      return wells.apiWellsIdTvdsPut(requestParameters);
    },
    ...{
      ...options,
      // we are loading zoom data just after the main TvD has already been loaded
      enabled: requestParameters !== null,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  });
  return data ?? defaultValue;
}
