import type { DateDto } from "apis/oag";
import type { CurveFactory } from "d3";
import type { ScaleLinear } from "d3-scale";
import type { UOMHelper } from "utils/format";

export const LEGEND_HEIGHT = 25;
export const DECIMAL_THRESHOLD = 10;

export interface SeriesPoint {
  wellId: number;
  at: DateDto;
  value: number;
  index: number;
}
export interface AxisDetails {
  scale: ScaleLinear<number, number, never>;
  uom: UOMHelper;
  label: string;
  useFuelFormatting?: boolean;
}

export enum CurveType {
  Gen = "Gen",
  Fuel = "Fuel",
  AvailablePower = "AvailablePower",
  Diesel = "Diesel",
  NatGas = "NatGas",
  Boiler = "Boiler",
}
export interface CurveDetails {
  legendTitle: string;
  type: CurveType;
  key: string;
  data: SeriesPoint[];
  curve?: CurveFactory;
  yScale: ScaleLinear<number, number, never>;
  color: string;
  disabled?: boolean;
}

export interface TooltipPoint {
  at?: DateDto;
  index: number;
  left: number;
  values: Record<string, number>;
}
