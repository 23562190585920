import CoreLayout, { SectionLayout } from "components/Layout";
import Legend from "components/Legend";
import { TvDChart } from "components/TvDChart";
import { DrillingDetailsHeader } from "components/WellDashboard/DrillingDetailsHeader";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useWellDetails } from "hooks/useWellDetails";
import NameBar from "pages/Report/components/NameBar";
import { Suspense, useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import type { IDisplayOptionsType } from "reducers/types";
import { CurvesEnum, initialDisplayOptions } from "reducers/types";
import colors from "utils/colors";
import { Col, Row, Typography } from "utils/componentLibrary";

const { Text } = Typography;

export default function TvDReport() {
  const dispatch = useAppDispatch();
  const { wellId: ws } = useParams<{ wellId: string; lensId: string }>();
  const wellId = Number(ws);
  const { data: selectedWellDetails } = useWellDetails(wellId);

  const [offsetWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);

  const [displayOptions, setDisplayOptions] = useStateQuery<IDisplayOptionsType>(
    URL_STATE_PARAM.DISPLAY_OPTIONS_WELL,
    initialDisplayOptions,
  );

  useEffect(() => {
    if (offsetWells?.length && !displayOptions.curves?.includes(CurvesEnum.OFFSET_WELLS)) {
      setDisplayOptions({ ...displayOptions, curves: [...(displayOptions.curves || []), CurvesEnum.OFFSET_WELLS] });
    }
  }, [offsetWells, setDisplayOptions, displayOptions]);

  const reportIncludeLegendState = useAppSelector((state) => state.global?.reportIncludeLegend ?? true);

  useEffect(() => {
    dispatch({
      type: "SET_SELECTED_WELL",
      payload: {
        well: wellId,
      },
    });
  }, [dispatch, wellId]);

  useEffect(() => {
    if (selectedWellDetails) {
      dispatch({
        type: "SET_WELL_DETAILS",
        payload: selectedWellDetails,
      });
    }
  }, [dispatch, selectedWellDetails]);

  return (
    <CoreLayout
      bgColor={colors.neutral_white}
      sidebarwidth={376}
      sidebar={
        reportIncludeLegendState ? (
          <SectionLayout
            isReport
            isReportTvD
            header={
              <Row>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 20,
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 0,
                      color: "rgba(0, 0, 0, 0.85)",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: 1.35,
                    }}
                  >
                    Legend
                  </Text>
                </Col>
              </Row>
            }
          >
            <div style={{ overflowX: "hidden", background: colors.default_bg, height: "100%" }}>
              <Suspense fallback={null}>
                <Legend isReport />
              </Suspense>
            </div>
          </SectionLayout>
        ) : null
      }
    >
      <SectionLayout
        header={
          <div>
            <NameBar wellId={wellId} />
          </div>
        }
        isReportTvD
        isReport
      >
        <Suspense fallback={<div />}>
          <div
            style={{
              paddingTop: 20,
              backgroundColor: colors.default_bg,
            }}
          >
            <div
              style={{
                paddingLeft: 20,
                marginBottom: 70,
              }}
            >
              <DrillingDetailsHeader />
            </div>
            <TvDChart isReport={true} timelineOverride={null} setTimelineOverride={null} />
          </div>
        </Suspense>
      </SectionLayout>
    </CoreLayout>
  );
}
