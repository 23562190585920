// TODO fill this up with indexes from different places
export const zIndexLayer = {
  below: -1,
  ground: 0,
  base: 1,
  elevated: 2,
  above: 3,
  fence: 4,
  wall: 5,
  building: 6,
  scraper: 7,
  high: 8,
  sky: 9,
  sky_plus: 10,
  mesosphere: 11,
  stratosphere: 12,
  shuttle: 13,
  moon: 14,
  phobos: 15,
  mars: 16,
  jupiter: 17,
  kuiper: 18,
};

// Below are the original values before compressing them -- for possible later debugging

// below: -1,
// ground: 0,
// base: 1,
// elevated: 2,
// above: 3,
// fence: 10,
// wall: 11,
// building: 30,
// scraper: 98,
// high: 99,
// sky: 100,
// sky_plus: 101,
// mesosphere: 200,
// stratosphere: 299,
// shuttle: 988,
// moon: 999,
// phobos: 1000,
// mars: 1001,
// jupiter: 1030,
// kuiper: 9999,
