/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationCategoryType } from './OperationCategoryType';
import {
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';
import type { LensTemplateType } from './LensTemplateType';
import {
    LensTemplateTypeFromJSON,
    LensTemplateTypeFromJSONTyped,
    LensTemplateTypeToJSON,
} from './LensTemplateType';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import type { WedgeBreakdownDto } from './WedgeBreakdownDto';
import {
    WedgeBreakdownDtoFromJSON,
    WedgeBreakdownDtoFromJSONTyped,
    WedgeBreakdownDtoToJSON,
} from './WedgeBreakdownDto';

/**
 * 
 * @export
 * @interface WedgeLensTemplateDto
 */
export interface WedgeLensTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof WedgeLensTemplateDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WedgeLensTemplateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WedgeLensTemplateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WedgeLensTemplateDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {LensTemplateType}
     * @memberof WedgeLensTemplateDto
     */
    type: LensTemplateType;
    /**
     * 
     * @type {DashboardType}
     * @memberof WedgeLensTemplateDto
     */
    targetDashboardType: DashboardType;
    /**
     * 
     * @type {number}
     * @memberof WedgeLensTemplateDto
     */
    nbCondensedDataPoints: number;
    /**
     * 
     * @type {boolean}
     * @memberof WedgeLensTemplateDto
     */
    useBitDepth: boolean;
    /**
     * 
     * @type {number}
     * @memberof WedgeLensTemplateDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof WedgeLensTemplateDto
     */
    wellAggregationThreshold: number;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof WedgeLensTemplateDto
     */
    operationCategory: OperationCategoryType;
    /**
     * 
     * @type {Array<WedgeBreakdownDto>}
     * @memberof WedgeLensTemplateDto
     */
    breakdowns?: Array<WedgeBreakdownDto> | null;
}



/**
 * Check if a given object implements the WedgeLensTemplateDto interface.
 */
export function instanceOfWedgeLensTemplateDto(value: object): value is WedgeLensTemplateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('targetDashboardType' in value) || value['targetDashboardType'] === undefined) return false;
    if (!('nbCondensedDataPoints' in value) || value['nbCondensedDataPoints'] === undefined) return false;
    if (!('useBitDepth' in value) || value['useBitDepth'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('wellAggregationThreshold' in value) || value['wellAggregationThreshold'] === undefined) return false;
    if (!('operationCategory' in value) || value['operationCategory'] === undefined) return false;
    return true;
}

export function WedgeLensTemplateDtoFromJSON(json: any): WedgeLensTemplateDto {
    return WedgeLensTemplateDtoFromJSONTyped(json, false);
}

export function WedgeLensTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeLensTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'type': LensTemplateTypeFromJSON(json['Type']),
        'targetDashboardType': DashboardTypeFromJSON(json['TargetDashboardType']),
        'nbCondensedDataPoints': json['NbCondensedDataPoints'],
        'useBitDepth': json['UseBitDepth'],
        'position': json['Position'],
        'wellAggregationThreshold': json['WellAggregationThreshold'],
        'operationCategory': OperationCategoryTypeFromJSON(json['OperationCategory']),
        'breakdowns': json['Breakdowns'] == null ? undefined : ((json['Breakdowns'] as Array<any>).map(WedgeBreakdownDtoFromJSON)),
    };
}

export function WedgeLensTemplateDtoToJSON(value?: WedgeLensTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'Description': value['description'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Type': LensTemplateTypeToJSON(value['type']),
        'TargetDashboardType': DashboardTypeToJSON(value['targetDashboardType']),
        'NbCondensedDataPoints': value['nbCondensedDataPoints'],
        'UseBitDepth': value['useBitDepth'],
        'Position': value['position'],
        'WellAggregationThreshold': value['wellAggregationThreshold'],
        'OperationCategory': OperationCategoryTypeToJSON(value['operationCategory']),
        'Breakdowns': value['breakdowns'] == null ? undefined : ((value['breakdowns'] as Array<any>).map(WedgeBreakdownDtoToJSON)),
    };
}

