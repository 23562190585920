/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AvailableIdsByFormationsQueryDto,
  AvailableIdsByOperatorsQueryDto,
  AvailableIdsByRigsQueryDto,
  AvailableIdsByWellsQueryDto,
  LinkedFiltersSetDto,
} from '../models/index';
import {
    AvailableIdsByFormationsQueryDtoFromJSON,
    AvailableIdsByFormationsQueryDtoToJSON,
    AvailableIdsByOperatorsQueryDtoFromJSON,
    AvailableIdsByOperatorsQueryDtoToJSON,
    AvailableIdsByRigsQueryDtoFromJSON,
    AvailableIdsByRigsQueryDtoToJSON,
    AvailableIdsByWellsQueryDtoFromJSON,
    AvailableIdsByWellsQueryDtoToJSON,
    LinkedFiltersSetDtoFromJSON,
    LinkedFiltersSetDtoToJSON,
} from '../models/index';

export interface ApiFiltersFormationsByWellsPutRequest {
    availableIdsByWellsQueryDto?: AvailableIdsByWellsQueryDto;
}

export interface ApiFiltersLinkedPutRequest {
    linkedFiltersSetDto?: LinkedFiltersSetDto;
}

export interface ApiFiltersOperatorsByRigsPutRequest {
    availableIdsByRigsQueryDto?: AvailableIdsByRigsQueryDto;
}

export interface ApiFiltersRigsByOperatorsPutRequest {
    availableIdsByOperatorsQueryDto?: AvailableIdsByOperatorsQueryDto;
}

export interface ApiFiltersWellsByFormationsPutRequest {
    availableIdsByFormationsQueryDto?: AvailableIdsByFormationsQueryDto;
}

/**
 * 
 */
export class FiltersApi extends runtime.BaseAPI {

    /**
     */
    async apiFiltersFormationsByWellsPutRaw(requestParameters: ApiFiltersFormationsByWellsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Filters/Formations/ByWells`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AvailableIdsByWellsQueryDtoToJSON(requestParameters['availableIdsByWellsQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiFiltersFormationsByWellsPut(requestParameters: ApiFiltersFormationsByWellsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apiFiltersFormationsByWellsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFiltersLinkedPutRaw(requestParameters: ApiFiltersLinkedPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkedFiltersSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Filters/Linked`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LinkedFiltersSetDtoToJSON(requestParameters['linkedFiltersSetDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedFiltersSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFiltersLinkedPut(requestParameters: ApiFiltersLinkedPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkedFiltersSetDto> {
        const response = await this.apiFiltersLinkedPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFiltersOperatorsByRigsPutRaw(requestParameters: ApiFiltersOperatorsByRigsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Filters/Operators/ByRigs`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AvailableIdsByRigsQueryDtoToJSON(requestParameters['availableIdsByRigsQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiFiltersOperatorsByRigsPut(requestParameters: ApiFiltersOperatorsByRigsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apiFiltersOperatorsByRigsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFiltersRigsByOperatorsPutRaw(requestParameters: ApiFiltersRigsByOperatorsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Filters/Rigs/ByOperators`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AvailableIdsByOperatorsQueryDtoToJSON(requestParameters['availableIdsByOperatorsQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiFiltersRigsByOperatorsPut(requestParameters: ApiFiltersRigsByOperatorsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apiFiltersRigsByOperatorsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFiltersWellsByFormationsPutRaw(requestParameters: ApiFiltersWellsByFormationsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Filters/Wells/ByFormations`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AvailableIdsByFormationsQueryDtoToJSON(requestParameters['availableIdsByFormationsQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiFiltersWellsByFormationsPut(requestParameters: ApiFiltersWellsByFormationsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apiFiltersWellsByFormationsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
