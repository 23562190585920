/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface FuelConsumptionByGeneratorPowerUsageFactDto
 */
export interface FuelConsumptionByGeneratorPowerUsageFactDto {
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorPowerUsageFactDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof FuelConsumptionByGeneratorPowerUsageFactDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorPowerUsageFactDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorPowerUsageFactDto
     */
    availablePower: number;
}

/**
 * Check if a given object implements the FuelConsumptionByGeneratorPowerUsageFactDto interface.
 */
export function instanceOfFuelConsumptionByGeneratorPowerUsageFactDto(value: object): value is FuelConsumptionByGeneratorPowerUsageFactDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('at' in value) || value['at'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('availablePower' in value) || value['availablePower'] === undefined) return false;
    return true;
}

export function FuelConsumptionByGeneratorPowerUsageFactDtoFromJSON(json: any): FuelConsumptionByGeneratorPowerUsageFactDto {
    return FuelConsumptionByGeneratorPowerUsageFactDtoFromJSONTyped(json, false);
}

export function FuelConsumptionByGeneratorPowerUsageFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelConsumptionByGeneratorPowerUsageFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'at': DateDtoFromJSON(json['At']),
        'value': json['Value'],
        'availablePower': json['AvailablePower'],
    };
}

export function FuelConsumptionByGeneratorPowerUsageFactDtoToJSON(value?: FuelConsumptionByGeneratorPowerUsageFactDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'At': DateDtoToJSON(value['at']),
        'Value': value['value'],
        'AvailablePower': value['availablePower'],
    };
}

