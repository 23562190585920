/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ScorecardKpiCommentDto,
  ScorecardKpiCommentExceptionInfoDto,
  ScorecardKpiCommentGroupDto,
  ScorecardKpiCommentInfoDto,
  WellIdListDto,
  WellKpiCommentDetailsDto,
} from '../models/index';
import {
    ScorecardKpiCommentDtoFromJSON,
    ScorecardKpiCommentDtoToJSON,
    ScorecardKpiCommentExceptionInfoDtoFromJSON,
    ScorecardKpiCommentExceptionInfoDtoToJSON,
    ScorecardKpiCommentGroupDtoFromJSON,
    ScorecardKpiCommentGroupDtoToJSON,
    ScorecardKpiCommentInfoDtoFromJSON,
    ScorecardKpiCommentInfoDtoToJSON,
    WellIdListDtoFromJSON,
    WellIdListDtoToJSON,
    WellKpiCommentDetailsDtoFromJSON,
    WellKpiCommentDetailsDtoToJSON,
} from '../models/index';

export interface ApiScorecardKpiCommentsIdApprovePutRequest {
    id: number;
}

export interface ApiScorecardKpiCommentsIdDeleteRequest {
    id: number;
}

export interface ApiScorecardKpiCommentsIdPutRequest {
    id: number;
    scorecardKpiCommentDto?: ScorecardKpiCommentDto;
}

export interface ApiScorecardKpiCommentsIdRejectPutRequest {
    id: number;
}

export interface ApiScorecardKpiCommentsIdRestorePutRequest {
    id: number;
}

export interface ApiScorecardKpiCommentsIdWithFactsGetRequest {
    id: number;
}

export interface ApiScorecardKpiCommentsPostRequest {
    scorecardKpiCommentDto?: ScorecardKpiCommentDto;
}

export interface ApiScorecardKpiCommentsPutRequest {
    wellIdListDto?: WellIdListDto;
}

/**
 * 
 */
export class ScorecardKpiCommentsApi extends runtime.BaseAPI {

    /**
     */
    async apiScorecardKpiCommentsExceptionsPendingCountGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ScorecardKpiComments/Exceptions/Pending/Count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiScorecardKpiCommentsExceptionsPendingCountGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiScorecardKpiCommentsExceptionsPendingCountGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiScorecardKpiCommentsExceptionsPendingGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ScorecardKpiCommentExceptionInfoDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ScorecardKpiComments/Exceptions/Pending`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScorecardKpiCommentExceptionInfoDtoFromJSON));
    }

    /**
     */
    async apiScorecardKpiCommentsExceptionsPendingGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ScorecardKpiCommentExceptionInfoDto>> {
        const response = await this.apiScorecardKpiCommentsExceptionsPendingGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiScorecardKpiCommentsIdApprovePutRaw(requestParameters: ApiScorecardKpiCommentsIdApprovePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScorecardKpiCommentInfoDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiScorecardKpiCommentsIdApprovePut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ScorecardKpiComments/{id}/Approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScorecardKpiCommentInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiScorecardKpiCommentsIdApprovePut(requestParameters: ApiScorecardKpiCommentsIdApprovePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScorecardKpiCommentInfoDto> {
        const response = await this.apiScorecardKpiCommentsIdApprovePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiScorecardKpiCommentsIdDeleteRaw(requestParameters: ApiScorecardKpiCommentsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiScorecardKpiCommentsIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ScorecardKpiComments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiScorecardKpiCommentsIdDelete(requestParameters: ApiScorecardKpiCommentsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiScorecardKpiCommentsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiScorecardKpiCommentsIdPutRaw(requestParameters: ApiScorecardKpiCommentsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScorecardKpiCommentDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiScorecardKpiCommentsIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ScorecardKpiComments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ScorecardKpiCommentDtoToJSON(requestParameters['scorecardKpiCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScorecardKpiCommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiScorecardKpiCommentsIdPut(requestParameters: ApiScorecardKpiCommentsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScorecardKpiCommentDto> {
        const response = await this.apiScorecardKpiCommentsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiScorecardKpiCommentsIdRejectPutRaw(requestParameters: ApiScorecardKpiCommentsIdRejectPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScorecardKpiCommentInfoDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiScorecardKpiCommentsIdRejectPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ScorecardKpiComments/{id}/Reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScorecardKpiCommentInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiScorecardKpiCommentsIdRejectPut(requestParameters: ApiScorecardKpiCommentsIdRejectPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScorecardKpiCommentInfoDto> {
        const response = await this.apiScorecardKpiCommentsIdRejectPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiScorecardKpiCommentsIdRestorePutRaw(requestParameters: ApiScorecardKpiCommentsIdRestorePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiScorecardKpiCommentsIdRestorePut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ScorecardKpiComments/{id}/Restore`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiScorecardKpiCommentsIdRestorePut(requestParameters: ApiScorecardKpiCommentsIdRestorePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiScorecardKpiCommentsIdRestorePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiScorecardKpiCommentsIdWithFactsGetRaw(requestParameters: ApiScorecardKpiCommentsIdWithFactsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellKpiCommentDetailsDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiScorecardKpiCommentsIdWithFactsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ScorecardKpiComments/{id}/WithFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellKpiCommentDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiScorecardKpiCommentsIdWithFactsGet(requestParameters: ApiScorecardKpiCommentsIdWithFactsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellKpiCommentDetailsDto> {
        const response = await this.apiScorecardKpiCommentsIdWithFactsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiScorecardKpiCommentsPostRaw(requestParameters: ApiScorecardKpiCommentsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScorecardKpiCommentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ScorecardKpiComments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScorecardKpiCommentDtoToJSON(requestParameters['scorecardKpiCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScorecardKpiCommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiScorecardKpiCommentsPost(requestParameters: ApiScorecardKpiCommentsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScorecardKpiCommentDto> {
        const response = await this.apiScorecardKpiCommentsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiScorecardKpiCommentsPutRaw(requestParameters: ApiScorecardKpiCommentsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ScorecardKpiCommentGroupDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ScorecardKpiComments`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellIdListDtoToJSON(requestParameters['wellIdListDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScorecardKpiCommentGroupDtoFromJSON));
    }

    /**
     */
    async apiScorecardKpiCommentsPut(requestParameters: ApiScorecardKpiCommentsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ScorecardKpiCommentGroupDto>> {
        const response = await this.apiScorecardKpiCommentsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
