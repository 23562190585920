import { Brush } from "@visx/brush";
import type { Bounds } from "@visx/brush/lib/types";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { ZoomChartContext, ZoomState } from "components/Lenses/common/LensZoom/ZoomChartContext";
import type { Domain } from "components/Lenses/ContainerLens/StickSlipByTime";
import { selectedBrushStyle } from "components/Lenses/utils";
import { TEMP_BRUSH_FIX_CONST } from "components/WellDashboard/ControlHeader/atoms/Zoom/ZoomSvg";
import { bisector } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { clamp } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

export function useZoom<SeriesT extends { index?: number }>({
  xScale,
  yScaleBrush,
  series,
  plotWidth,
  plotHeight,
  plotWidthOffset = 0,
  minimumZoomThreshold = 2,
  handlePointerMove,
}: {
  xScale: ScaleLinear<number, number, never>;
  yScaleBrush: ScaleLinear<number, number, never>;
  series: SeriesT[];
  plotWidth: number;
  plotHeight: number;
  plotWidthOffset?: number;
  minimumZoomThreshold?: number;
  handlePointerMove?: (x: number, y: number) => void;
}) {
  const zoomChartContext = useContext(ZoomChartContext);
  if (!zoomChartContext) {
    throw new Error("ZoomChartContext is not defined");
  }
  const { setDomain, zoomState, domain, initialDomain, triggerZoomReset } = zoomChartContext;
  const [isDragging, setIsDragging] = useState(false);
  const [hideTooltip, setHideTooltip] = useState(false);
  const brushRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [pointerPosition, setPointerPosition] = useState<number | null>(null);

  const handleOnBrushStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const getPointForChartPosition = useCallback((series: SeriesT[], pointIndexInSeries: number) => {
    if (!series) return null;
    const index = bisector<SeriesT, number>((p) => p?.index || 0).left(series, pointIndexInSeries);
    if (index >= 0 && index < series.length && Number.isFinite(series[index].index)) {
      return series[index];
    }

    return null;
  }, []);
  const clampedEventBounds = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      const { left, top } = event.currentTarget.getBoundingClientRect();

      const x = clamp(("clientX" in event ? event.clientX : 0) - left - plotWidthOffset, 0, +Infinity);
      const y = ("clientY" in event ? event.clientY : 0) - top;
      return { x, y };
    },
    [plotWidthOffset],
  );
  const [panOriginPoint, setPanOriginPoint] = useState(0);

  const handleOnPointerMove = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      // return pointer position before mouse down check
      const { x, y } = clampedEventBounds(event);
      if (handlePointerMove) {
        handlePointerMove(x, y);
      }
      const domainBounds = initialDomain;
      setPointerPosition(x);
      if (!isMouseDown) return;

      if (zoomState === ZoomState.Pan && domain) {
        const dx = (panOriginPoint - x) * Math.abs((domain[0] - domain[1]) / (xScale.range()[0] - xScale.range()[1]));
        // Prevent panning outside of bounds
        setDomain((prevDomain) => {
          const [min, max] = prevDomain || [0, 0];
          if (min + dx >= (domainBounds?.[0] || 0) && max + dx <= (domainBounds?.[1] || 0)) {
            return [min + dx, max + dx];
          } else return [min, max];
        });

        setPanOriginPoint(x);
      }
    },
    [
      clampedEventBounds,
      handlePointerMove,
      initialDomain,
      isMouseDown,
      zoomState,
      panOriginPoint,
      domain,
      xScale,
      setDomain,
    ],
  );

  const xScaleBrush = useMemo(() => {
    const chartSize = plotWidth;
    return scaleLinear({
      domain: xScale.domain(),
      range: [-TEMP_BRUSH_FIX_CONST, chartSize + TEMP_BRUSH_FIX_CONST],
    });
  }, [plotWidth, xScale]);

  const handleOnBrushEnd = useCallback(
    (bounds: Bounds | null) => {
      setHideTooltip(true);
      const { x0: start, x1: end } = bounds ?? { x0: 0, x1: 0 };
      const difference = Math.abs(start - end);
      if (difference <= minimumZoomThreshold) {
        return;
      }
      // the bounds come from the brush scaler
      const startPoint = getPointForChartPosition(series, start);
      const endPoint = getPointForChartPosition(series, end);
      setDomain(
        [startPoint?.index ?? 0, endPoint?.index ?? series.slice(-1)[0].index ?? series.length].sort(
          (a, b) => a - b,
        ) as Domain,
      );

      // wait for the component know if it's suspended
      requestAnimationFrame(() => {
        setHideTooltip(false);
        setIsDragging(false);
      });
    },
    [getPointForChartPosition, minimumZoomThreshold, series, setDomain],
  );

  const SelectedRectangleElement = useMemo(
    () => (
      <Group left={plotWidthOffset} id={"SELECTION_RECT_ELEMENT"} width={plotWidth} height={plotHeight}>
        {zoomState === ZoomState.Zooming ? (
          <Brush
            margin={{
              left: plotWidthOffset,
              top: 0,
              bottom: 0,
              right: 0,
            }}
            brushRegion="chart"
            innerRef={brushRef}
            xScale={xScaleBrush}
            yScale={yScaleBrush}
            width={plotWidth}
            height={plotHeight}
            handleSize={8}
            resizeTriggerAreas={["left", "right"]}
            brushDirection={"horizontal"}
            onBrushEnd={handleOnBrushEnd}
            onBrushStart={handleOnBrushStart}
            selectedBoxStyle={selectedBrushStyle}
            useWindowMoveEvents
            onClick={() => setIsDragging(false)}
            resetOnEnd
          />
        ) : null}
      </Group>
    ),
    [handleOnBrushEnd, handleOnBrushStart, plotHeight, plotWidth, plotWidthOffset, xScaleBrush, yScaleBrush, zoomState],
  );
  useEffect(() => {
    const scrollCallback = () => {
      setPanOriginPoint(0);
      setPointerPosition(null);
      setIsMouseDown(false);
    };
    window.addEventListener("scroll", scrollCallback);
    return () => {
      window.removeEventListener("scroll", scrollCallback);
    };
  }, []);

  return {
    selectionRectangleElement: SelectedRectangleElement,
    isDragging,
    hideTooltip,
    pointerPosition,
    zoomEvents: {
      onPointerMove: handleOnPointerMove,
      onMouseLeave: () => {
        setPanOriginPoint(0);
        setPointerPosition(null);
        setIsMouseDown(false);
      },
      onDoubleClick: triggerZoomReset,
      onMouseEnter: () => {
        setPanOriginPoint(0);
        setPointerPosition(null);
        setIsMouseDown(false);
      },
      ...(zoomState === ZoomState.Pan
        ? {
          onMouseDown: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            const { x } = clampedEventBounds(event);
            setPanOriginPoint(x);
            setIsMouseDown(true);
          },
          onMouseUp: () => {
            setIsMouseDown(false);
          },
        }
        : {}),
    },
  };
}
