import styled from "styled-components";

export const Container = styled.div`
  height: calc(100vh - 119px - 64px - 152px - 45px - 56px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  &,
  h2 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

export const Circle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  width: 524px;
  height: 524px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.themeStyle.colors.alternate_bg};
`;
