import { Button as AtomButton } from "atoms/Form";
import { shade } from "polished";
import type { DateRangeProps } from "react-date-range";
import { DateRange as ReactDateRange } from "react-date-range";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Button as AntButton, Menu as AntMenu } from "utils/componentLibrary";

export const Icon = styled.div<{ up?: boolean }>`
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-left: 1px solid ${colors.light_gray};
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform: ${(props) => (props.up ? "rotate(180deg)" : "rotate(0deg)")};
    }
  }
`;

export const Button = styled(AntButton)<{ isActive?: boolean }>`
  padding: 0;
  padding-left: 12px;
  margin-bottom: 12px;
  gap: 12px;
  font-weight: 400;
  height: 36px;
  line-height: 36px;
  letter-spacing: -0.2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.themeStyle.colors.secondary_bg} !important;
  color: ${(props) => (props.isActive ? colors.gray : props.theme.themeStyle.colors.primary_typography)};
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};

  :focus,
  &.ant-btn:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid ${colors.light_gray};
    color: ${colors.gray};
  }
  :hover {
    background: ${(props) => (props.isActive ? colors.actions_bg : colors.white)};
  }
  ${Icon} {
    background: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;
  }
`;

export const StyledButton = styled(AtomButton)<{ $isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  .pd-icon {
    font-size: 20px;
    line-height: 10px;
    ${(props) =>
      props.$isActive
        ? css`
            margin-right: 8px;
          `
        : ""}
  }
  span {
    margin-left: 0px !important;
  }
`;

export const ResetButton = styled(AtomButton)`
  letter-spacing: -0.2px;
`;

export const ApplyButton = styled(AtomButton)`
  letter-spacing: -0.2px;
  background: ${colors.well_color};
  color: ${colors.white};
  &.ant-btn:hover,
  &.ant-btn:focus {
    background: ${shade(0.2, colors.well_color)};
    color: ${colors.white} !important;
  }
`;

export const DateSeparator = styled.span`
  color: ${colors.gray};
  margin: 0px 10px;
`;

export const DateInput = styled.input``;

export const DateInputContainer = styled.div`
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  input {
    background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
    color: ${colors.gray};
    box-shadow: none;
    border: none;
    flex: none;
    width: 86px;
    height: 24px;
    font-size: 14px;
    width: 86px;
    height: 1.5em;
    line-height: 1.5em;
    cursor: text;
    border-radius: 2px;
    border: 1px solid transparent;
    ::placeholder {
      color: ${colors.off_state};
    }
    :hover,
    :focus {
      border: 1px solid ${colors.gray};
    }
  }
`;

export const Footer = styled.div`
  margin: 5px -12px -12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 15px 28px;
  background: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  display: flex;
  justify-content: space-between;
`;

export const Menu = styled(AntMenu)`
  padding-top: 0;
  min-width: 320px;
  border-radius: 4px;
  &.ant-menu {
    box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  }
`;

export const Item = styled.li<{ isActive?: boolean }>`
  margin: 0px;
  margin-top: 1px;
  padding: 0px 15px;
  border-bottom: 1px solid ${colors.actions_bg};
  line-height: 38px;
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  border-left: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
    color: ${colors.well_color};
  }

  ${(props) =>
    props.isActive &&
    `
      background: ${props.theme.themeStyle.colors.primary_bg};
      color: ${props.theme.themeStyle.colors.disabled_typography};
      cursor: auto;
      :hover{
        color: ${props.theme.themeStyle.colors.disabled_typography};
      }
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateRange = styled(ReactDateRange)<DateRangeProps>`
  .rdrMonthAndYearPickers select {
    line-height: 18px;
    padding: 8px 30px 8px 10px;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};

    :hover {
      background: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
    }
  }
  .rdrNextPrevButton {
    background: transparent;
    i {
      border-color: transparent transparent transparent ${colors.gray};
    }
  }
  .rdrPprevButton i {
    border-color: transparent ${colors.gray} transparent transparent;
  }
  .rdrMonth:last-child {
    padding-bottom: 0;
  }
  .rdrDateDisplayWrapper {
    background: transparent;
  }
  .rdrDateDisplayItemActive {
    border: none;
  }
  .rdrMonthAndYearWrapper {
    padding: 0;
    height: 36px;
    box-shadow: 0px 2px 2px -1px #0001;
  }
  .rdrWeekDays {
    display: none;
  }
  .rdrMonthName {
    color: ${colors.gray};
    font-weight: normal;
    letter-spacing: -0.18px;
    font-size: 14px;
    padding-top: 24px;
    padding-bottom: 10px;
  }
  .rdrMonthPicker,
  .rdrYearPicker {
    font-size: 14px;
  }

  .rdrDays {
    .rdrDayPassive {
      visibility: hidden;
    }
    .rdrDayNumber {
      font-size: 14px;
      font-weight: 400;
    }

    ${({ theme: { isDark } }) =>
      isDark &&
      css`
        .rdrDayDisabled {
          background-color: ${colors.gray};
        }
      `}

    .rdrDay:not(.rdrDayPassive, .rdrDayDisabled) {
      .rdrDayNumber {
        span {
          color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
        }
      }
      .rdrInRange ~ .rdrDayNumber span,
      .rdrSelected ~ .rdrDayNumber span {
        color: ${colors.well_color_light};
      }

      .rdrStartEdge {
        & + .rdrDayNumber span,
        & + span + .rdrDayNumber span {
          color: ${colors.white};
        }
      }
      .rdrEndEdge {
        & + .rdrDayNumber span,
        & + span + .rdrDayNumber span {
          color: ${colors.white};
        }
      }
    }
    .rdrDayStartOfMonth .rdrInRange,
    .rdrDayStartOfMonth .rdrEndEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrEndEdge {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      left: 0px;
    }
    .rdrDayEndOfMonth .rdrInRange,
    .rdrDayEndOfMonth .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrStartEdge {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      right: 0px;
    }
    .rdrEndEdge.rdrStartEdge {
      border-top-right-radius: 99px;
      border-bottom-right-radius: 99px;
      border-top-left-radius: 99px;
      border-bottom-left-radius: 99px;
    }

    .rdrDayWeekend span {
      border-radius: 0px;
      &.rdrEndEdge,
      &.rdrDayEndPreview {
        border-top-right-radius: 99px;
        border-bottom-right-radius: 99px;
      }
      &.rdrStartEdge,
      &.rdrDayStartPreview {
        border-top-left-radius: 99px;
        border-bottom-left-radius: 99px;
      }
    }
    .rdrStartEdge,
    .rdrDayStartPreview {
      border-top-left-radius: 99px;
      border-bottom-left-radius: 99px;
    }
    .rdrEndEdge,
    .rdrDayEndPreview {
      border-top-right-radius: 99px;
      border-bottom-right-radius: 99px;
    }

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      position: absolute;
      top: 1px;
      left: 0px;
      right: 0px;
      bottom: 1px;
      border: 1px solid ${colors.well_color_light};
    }

    .rdrDayInPreview {
      border-right: none;
      border-left: none;
    }

    .rdrDayStartPreview {
      border-right: none;
    }
    .rdrDayEndPreview {
      border-left: none;
    }
    .rdrDayStartPreview.rdrDayEndPreview {
      border-left: 1px solid ${colors.well_color_light};
      border-right: 1px solid ${colors.well_color_light};
    }

    .rdrDay {
      line-height: 36px;
      height: 36px;
    }
    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      top: 1px;
      bottom: 1px;
      left: 0px;
      right: 0px;
    }
    .rdrDayToday {
      .rdrDayNumber {
        border-radius: 99px;
        height: 34px;
        border: 1px solid ${colors.neutral};
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        :hover {
          border: 1px solid ${colors.well_color};
        }
        span:after {
          content: none;
          width: 0px;
          height: 0px;
          border-radius: 2px;
          background: transparent;
        }
      }
    }

    .rDayHovered .rdrDayToday .rdrDayNumber {
      border: 1px solid ${colors.well_color};
    }
  }
`;
