/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScoreSummaryTabGroupDto } from './ScoreSummaryTabGroupDto';
import {
    ScoreSummaryTabGroupDtoFromJSON,
    ScoreSummaryTabGroupDtoFromJSONTyped,
    ScoreSummaryTabGroupDtoToJSON,
} from './ScoreSummaryTabGroupDto';

/**
 * 
 * @export
 * @interface ScoreSummaryRootDto
 */
export interface ScoreSummaryRootDto {
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    rigId?: number | null;
    /**
     * 
     * @type {Array<ScoreSummaryTabGroupDto>}
     * @memberof ScoreSummaryRootDto
     */
    children: Array<ScoreSummaryTabGroupDto>;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    rigScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    readonly targetDeltaTimeMaxRange: number;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryRootDto
     */
    readonly invisibleLostTimeMaxRange: number;
}

/**
 * Check if a given object implements the ScoreSummaryRootDto interface.
 */
export function instanceOfScoreSummaryRootDto(value: object): value is ScoreSummaryRootDto {
    if (!('children' in value) || value['children'] === undefined) return false;
    if (!('targetDeltaTimeMaxRange' in value) || value['targetDeltaTimeMaxRange'] === undefined) return false;
    if (!('invisibleLostTimeMaxRange' in value) || value['invisibleLostTimeMaxRange'] === undefined) return false;
    return true;
}

export function ScoreSummaryRootDtoFromJSON(json: any): ScoreSummaryRootDto {
    return ScoreSummaryRootDtoFromJSONTyped(json, false);
}

export function ScoreSummaryRootDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreSummaryRootDto {
    if (json == null) {
        return json;
    }
    return {
        
        'targetDeltaTime': json['TargetDeltaTime'] == null ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': json['InvisibleLostTime'] == null ? undefined : json['InvisibleLostTime'],
        'rigId': json['RigId'] == null ? undefined : json['RigId'],
        'children': ((json['Children'] as Array<any>).map(ScoreSummaryTabGroupDtoFromJSON)),
        'operatorScore': json['OperatorScore'] == null ? undefined : json['OperatorScore'],
        'rigScore': json['RigScore'] == null ? undefined : json['RigScore'],
        'targetDeltaTimeMaxRange': json['TargetDeltaTimeMaxRange'],
        'invisibleLostTimeMaxRange': json['InvisibleLostTimeMaxRange'],
    };
}

export function ScoreSummaryRootDtoToJSON(value?: Omit<ScoreSummaryRootDto, 'TargetDeltaTimeMaxRange'|'InvisibleLostTimeMaxRange'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'TargetDeltaTime': value['targetDeltaTime'],
        'InvisibleLostTime': value['invisibleLostTime'],
        'RigId': value['rigId'],
        'Children': ((value['children'] as Array<any>).map(ScoreSummaryTabGroupDtoToJSON)),
        'OperatorScore': value['operatorScore'],
        'RigScore': value['rigScore'],
    };
}

