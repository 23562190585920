import { Title } from "atoms/Typography";
import styled from "styled-components";

export const CenteredError = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80ch;

  h3,
  h2,
  h1.ant-typography {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

export const StyledTitle = styled(Title)`
  color: white !important;
  cursor: pointer;

  .pd-icon {
    pointer-events: none;
  }
`;
