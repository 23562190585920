import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-grow: 1;
`;

export const FlexGrowContainer = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
`;
