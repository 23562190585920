/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface FuelFlowRateFactDto
 */
export interface FuelFlowRateFactDto {
    /**
     * 
     * @type {number}
     * @memberof FuelFlowRateFactDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof FuelFlowRateFactDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof FuelFlowRateFactDto
     */
    value: number;
}

/**
 * Check if a given object implements the FuelFlowRateFactDto interface.
 */
export function instanceOfFuelFlowRateFactDto(value: object): value is FuelFlowRateFactDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('at' in value) || value['at'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function FuelFlowRateFactDtoFromJSON(json: any): FuelFlowRateFactDto {
    return FuelFlowRateFactDtoFromJSONTyped(json, false);
}

export function FuelFlowRateFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelFlowRateFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'at': DateDtoFromJSON(json['At']),
        'value': json['Value'],
    };
}

export function FuelFlowRateFactDtoToJSON(value?: FuelFlowRateFactDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'At': DateDtoToJSON(value['at']),
        'Value': value['value'],
    };
}

