/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportStatusType } from './ReportStatusType';
import {
    ReportStatusTypeFromJSON,
    ReportStatusTypeFromJSONTyped,
    ReportStatusTypeToJSON,
} from './ReportStatusType';

/**
 * 
 * @export
 * @interface ReportStatusDto
 */
export interface ReportStatusDto {
    /**
     * 
     * @type {number}
     * @memberof ReportStatusDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReportStatusDto
     */
    filename?: string | null;
    /**
     * 
     * @type {ReportStatusType}
     * @memberof ReportStatusDto
     */
    status: ReportStatusType;
    /**
     * 
     * @type {Date}
     * @memberof ReportStatusDto
     */
    generationStartUtc?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ReportStatusDto
     */
    generationEndUtc?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ReportStatusDto
     */
    errorMessage?: string | null;
}



/**
 * Check if a given object implements the ReportStatusDto interface.
 */
export function instanceOfReportStatusDto(value: object): value is ReportStatusDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function ReportStatusDtoFromJSON(json: any): ReportStatusDto {
    return ReportStatusDtoFromJSONTyped(json, false);
}

export function ReportStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportStatusDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'filename': json['Filename'] == null ? undefined : json['Filename'],
        'status': ReportStatusTypeFromJSON(json['Status']),
        'generationStartUtc': json['GenerationStartUtc'] == null ? undefined : (new Date(json['GenerationStartUtc'])),
        'generationEndUtc': json['GenerationEndUtc'] == null ? undefined : (new Date(json['GenerationEndUtc'])),
        'errorMessage': json['ErrorMessage'] == null ? undefined : json['ErrorMessage'],
    };
}

export function ReportStatusDtoToJSON(value?: ReportStatusDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Filename': value['filename'],
        'Status': ReportStatusTypeToJSON(value['status']),
        'GenerationStartUtc': value['generationStartUtc'] == null ? undefined : ((value['generationStartUtc'] as any).toISOString()),
        'GenerationEndUtc': value['generationEndUtc'] == null ? undefined : ((value['generationEndUtc'] as any).toISOString()),
        'ErrorMessage': value['errorMessage'],
    };
}

