import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { Bar, BarRounded } from "@visx/shape";
import type { StandKpiDetailsDto } from "apis/oag";
import { ScoreComplianceStateType } from "apis/oag";
import { DimensionType, VisualAidType } from "apis/oag";
import { AverageLabel, AverageLine } from "components/Lenses/common/AverageLine";
import { Chart } from "components/Lenses/common/Chart";
import { Indicators } from "components/Lenses/common/Indicators";
import { useStandKpiInspectionView } from "components/Lenses/common/InspectionView/StandKpis/useStandKpiInspectionView";
import { Label } from "components/Lenses/common/Label";
import { MedianLabel, MedianLine } from "components/Lenses/common/MedianLine";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { Scrollbar } from "components/Lenses/common/Scrollbar";
import TargetLine from "components/Lenses/common/TargetLine";
import { TooltipHighlightValue, TooltipInfo } from "components/Lenses/common/Tooltip";
import { useAggregatedIndicators } from "components/Lenses/common/useAggregatedIndicators";
import { TooltipVisualAidInfo, useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { useOutlierThreshold } from "components/Lenses/common/useOutlierThreshold";
import type {
  SingleKpiChartSimpleProps,
  TransformedSingleStandItem,
} from "components/Lenses/ContainerLens/SingleKpi/interfaces";
import { getMinMax, getSVGNormalizedValue } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import { useTargetSegments } from "hooks/charting/useTargetSegments";
import { isDrillingProductivityLensTemplate, useLensTemplates } from "hooks/useLensTemplates";
import { shade } from "polished";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { useUOM } from "utils/format";
import { formatDate, formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

export const MINI_ZOOM_SCALER = 125;
export const DETAILED_ZOOM_SCALER = 25;
export const COMMENTS_ICON_SIZE = 16;

export function SingleStandViewChart({
  detailed,
  data,
  enableTooltip,
  disableAxis,
  lens,
  indicators,
  valueUOM,
}: SingleKpiChartSimpleProps) {
  const depthUOM = useUOM(DimensionType.Metres);
  const { data: templates } = useLensTemplates();
  const template = lens?.lensTemplateId ? templates?.byId[lens.lensTemplateId] : null;
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  // TODO hack to not have to change mock elements
  const outliersSettingsEnabled = lens === undefined ? true : lens?.showsOutliers;
  const isManual = !!lens?.isManualYaxis;
  const outliersEnabled = outliersSettingsEnabled || isManual;
  const { inspectionViewElement, openInspectionView } = useStandKpiInspectionView({
    lens: lens,
    showDrillerComments: lens.showStandKpiComments,
    standList: data?.detailsByStandDepth,
  });

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const plotHeight = getSVGNormalizedValue(chartHeight - (detailed ? 135 : 36) + (disableAxis ? 35 : 0));
  const plotWidth = getSVGNormalizedValue((chartWidth ?? 76) - (detailed ? 76 : 44));

  const scrollbarPadding = detailed ? 28 : 4;
  const scrollbarWidth = getSVGNormalizedValue(plotWidth - scrollbarPadding * 2);
  const scrollbarHeight = 4;

  const getStandValue = (d: TransformedSingleStandItem | StandKpiDetailsDto) => d.value || 0;
  const isDrillingProductivity = useMemo(
    () => (template ? isDrillingProductivityLensTemplate(template) : false),
    [template],
  );

  const transformedData: Array<TransformedSingleStandItem> = useMemo(
    () =>
      (isDrillingProductivity ? data?.detailsByDate : data?.detailsByStandDepth)?.map?.((e, index) => ({
        ...e,
        index,
      })) ?? [],
    [data?.detailsByDate, data?.detailsByStandDepth, isDrillingProductivity],
  );

  // A quarter of the chart by default
  const zoomRate = useMemo(() => {
    return lens?.squeezesDisplay
      ? 1
      : Math.ceil(transformedData.length / (detailed ? DETAILED_ZOOM_SCALER : MINI_ZOOM_SCALER));
  }, [detailed, lens?.squeezesDisplay, transformedData.length]);

  const [scrollbarRange, setScrollbarRange] = useState<ScrollbarRange>({ startX: 1 - 1 / zoomRate, endX: 1 });

  const categoryScale = useMemo(() => {
    const chartSize = plotWidth * zoomRate + (disableAxis ? 35 : 0);

    const startX = chartSize * scrollbarRange.startX;

    return scaleBand<number>({
      domain: transformedData.map((i) => i?.standId || i?.date?.getTime() || 0),
      // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
      range: [-startX, chartSize - startX],
      paddingInner: 0.2,
      paddingOuter: 0.5,
    });
  }, [disableAxis, plotWidth, scrollbarRange.startX, transformedData, zoomRate]);

  const computedTargetSegments = useTargetSegments(
    transformedData.map((td) => ({
      ...td,
      id: td?.standId || td?.date?.getTime() || 0,
      targetValue: td?.targetValue ?? 0,
    })),
    categoryScale,
  );

  const { outlierThreshold, gradientDefinition, gradientFill } = useOutlierThreshold({
    values: data?.detailsByStandDepth?.map((e) => e.value) || [],
    enabled: outliersEnabled,
    selectedVisualAids: lens?.selectedVisualAids,
    targetSegments: computedTargetSegments,
  });

  const valueScale = useMemo(() => {
    return scaleLinear<number>({
      domain: getMinMax(
        (isDrillingProductivity
          ? data?.detailsByDate?.map((d) => d.value)
          : data?.detailsByStandDepth?.filter((stand) => !stand?.isOutlier).map(getStandValue)) || [],
        computedTargetSegments,
        outlierThreshold,
        data.summary?.median,
        lens?.selectedVisualAids,
        lens?.outlierFlaggingType,
        lens?.isManualYaxis,
        lens?.yaxisEnd,
        lens?.yaxisStart,
      ),
      range: [plotHeight, 0],
      clamp: true,
    });
  }, [
    isDrillingProductivity,
    data.detailsByDate,
    data?.detailsByStandDepth,
    data.summary?.median,
    computedTargetSegments,
    outlierThreshold,
    lens?.selectedVisualAids,
    lens?.outlierFlaggingType,
    lens?.isManualYaxis,
    lens?.yaxisEnd,
    lens?.yaxisStart,
    plotHeight,
  ]);

  const valuesDomain = useMemo(() => valueScale.domain(), [valueScale]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<TransformedSingleStandItem>({
    containerRef,
    renderContent: ({ tooltipData }) => {
      if (isDrillingProductivity)
        return (
          <>
            <TooltipHighlightValue>
              {valueUOM.displayWithAutoDecimals(valuesDomain, tooltipData?.value)}
            </TooltipHighlightValue>
            <TooltipInfo>
              {tooltipData?.date
                ? formatDate(new Date(tooltipData?.date), { formatStr: "MM-DD-YY", useUTC: true })
                : ""}
            </TooltipInfo>
          </>
        );
      return (
        <>
          <TooltipHighlightValue>
            {valueUOM.displayWithAutoDecimals(valuesDomain, tooltipData?.value)}
          </TooltipHighlightValue>

          <TooltipVisualAidInfo
            selectedVisualAids={lens?.selectedVisualAids}
            display={(value) => valueUOM.displayWithAutoDecimals(valuesDomain, value)}
            targetValue={tooltipData?.targetValue}
            averageValue={data.summary?.average}
            median={data.summary?.median}
          />

          <TooltipInfo>
            {depthUOM.display(tooltipData?.startDepth)} to {depthUOM.display(tooltipData?.endDepth)}
          </TooltipInfo>

          {tooltipData?.startAt && tooltipData?.endAt ? (
            <TooltipInfo>
              {formatTime(tooltipData.startAt, { formatStr: "MM/D HH:mm" })} to{" "}
              {formatTime(tooltipData.endAt, { formatStr: "MM/D HH:mm" })}
            </TooltipInfo>
          ) : null}
          <TooltipInfo>{tooltipData?.isDayShift ? "Day" : "Night"}</TooltipInfo>

          {tooltipData?.isOutlier ? <TooltipHighlightValue fontWeight="normal">OUTLIER</TooltipHighlightValue> : null}
          {(
            [
              ScoreComplianceStateType.ActionCompleted,
              ScoreComplianceStateType.CommentedOnWithoutActionRequired,
            ] as Array<ScoreComplianceStateType | undefined>
          ).includes(tooltipData?.complianceState) && lens.showStandKpiComments ? (
            <TooltipHighlightValue>
              <PDComponent.SvgIcon name="chat" width={14} height={14} />
            </TooltipHighlightValue>
          ) : null}
        </>
      );
    },
  });

  const scrollbarScale = useMemo(
    () => scaleLinear<number>({ domain: [0, 1], range: [0, scrollbarWidth] }),
    [scrollbarWidth],
  );

  const depthScale = useMemo(
    () =>
      scaleOrdinal({
        domain: transformedData.map((i) => i?.standId || i?.date?.getTime() || 0),
        range: transformedData.map((i) => i.standNumber),
      }),
    [transformedData],
  );

  const gapWidth = categoryScale.paddingInner() * categoryScale.step();

  const aggregatedIndicators = useAggregatedIndicators(data.indicators);

  // update if squeeze changed
  useEffect(() => {
    setScrollbarRange({ startX: 1 - 1 / zoomRate, endX: 1 });
  }, [zoomRate]);
  const columnWidth = categoryScale.bandwidth();
  const handleMouseOver = useCallback(
    ({ item, top, left }: { item: TransformedSingleStandItem; top: number; left: number }) => {
      if (!enableTooltip) return;
      showTooltip({
        tooltipLeft: left + categoryScale.bandwidth() / 2,
        tooltipTop: top,
        tooltipData: item,
      });
    },
    [categoryScale, enableTooltip, showTooltip],
  );

  return data ? (
    <div
      ref={containerRef}
      // eslint-disable-next-line react/forbid-dom-props
      style={{
        padding: 0,
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <svg
        width={chartWidth}
        height={chartHeight > 0 ? chartHeight : 0}
        style={{ overflow: "visible", userSelect: "none" }}
      >
        {gradientDefinition}
        <Chart
          disableAxis={disableAxis}
          isManual={lens?.isManualYaxis}
          plotWidth={plotWidth + (disableAxis ? 45 : 0)}
          detailed={detailed}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          plotHeight={plotHeight}
          valueScale={valueScale}
          maxBottomTickWidth={transformedData.length >= 10 && isDrillingProductivity ? 75 : undefined}
          categoryScale={categoryScale}
          tickFormat={(value) => {
            if (template && isDrillingProductivityLensTemplate(template))
              return formatDate(new Date(value as number | string | Date), { formatStr: "MM-DD-YY", useUTC: true });
            return depthUOM.display(depthScale(value as number), { unit: "", fractionDigits: 0 });
          }}
          valueUOM={valueUOM}
        >
          {transformedData.map((d, index) => {
            let barHeight = Math.min(valueScale(0) - valueScale(getStandValue(d) ?? 0), plotHeight);
            const maxValue = valueScale.domain()[1];
            const threshold = isManual || outlierThreshold === null ? maxValue : outlierThreshold;
            const thresholdMaxHeight = valueScale(0) - valueScale(maxValue);
            let barY = plotHeight - barHeight;
            let showGradient = false;

            if (threshold !== null) {
              showGradient = getStandValue(d) > threshold;
              barHeight = Math.min(barHeight, thresholdMaxHeight);
              barY = Math.max(0, plotHeight - barHeight);
            }
            const barX = categoryScale(d?.standId || d?.date?.getTime() || 0);
            if (!barX || !columnWidth) return null;
            return (
              <Group
                key={`bar-stack-${d.index}`}
                style={{ cursor: isDrillingProductivity ? "auto" : "pointer" }}
                onClick={() => {
                  if (isDrillingProductivity) return;
                  Track.interact("Open Inspection View", { "Stand Id": d?.standId });
                  openInspectionView(
                    // @ts-expect-error standId is not in the SingleStandKpiDetailsDto | SingleStandKpiDetailByDateDto
                    { ...d, standKpiType: lens.kpiTypeId },
                    valueUOM,
                    valueUOM.displayWithAutoDecimals(valuesDomain, d.value),
                  );
                }}
              >
                <BarRounded
                  radius={4}
                  top
                  x={barX}
                  y={barY}
                  key={`single-kpi-${d.index}-${d.startDepth}-${d.isDayShift}-${index}-${getStandValue(d) ?? 0}`}
                  width={columnWidth}
                  onMouseOver={() => handleMouseOver({ item: d, top: barY, left: barX })}
                  onMouseOut={hideTooltip}
                  height={barHeight}
                  fill={(() => {
                    if (d.isOutlier) return themeColors.outlier_bars_transparent;
                    if (d.isDayShift === undefined) return colors.well_color;
                    return shade(d.isDayShift ? 0 : 0.5, colors.well_color);
                  })()}
                />
                {d.isOutlier ? (
                  <Bar
                    x={barX - 1}
                    y={barY - 1}
                    width={columnWidth + 2}
                    onMouseOver={() => handleMouseOver({ item: d, top: barY, left: barX })}
                    onMouseOut={hideTooltip}
                    height={barHeight}
                    fill={gradientFill}
                  />
                ) : null}

                {(
                  [
                    ScoreComplianceStateType.ActionCompleted,
                    ScoreComplianceStateType.CommentedOnWithoutActionRequired,
                  ] as Array<ScoreComplianceStateType | undefined>
                ).includes(d?.complianceState) &&
                columnWidth >= COMMENTS_ICON_SIZE &&
                lens.showStandKpiComments ? (
                  <svg
                    x={barX - 1}
                    y={barY + barHeight - COMMENTS_ICON_SIZE - 2}
                    width={columnWidth}
                    height={COMMENTS_ICON_SIZE}
                    style={{ color: colors.white }}
                    viewBox="0 0 32 32"
                  >
                    <use href="#icon-chat" fill="currentColor" />
                  </svg>
                ) : null}

                {showGradient ? (
                  <Bar
                    x={barX - 1}
                    y={barY - 1}
                    width={columnWidth + 2}
                    height={detailed ? 50 : 25}
                    fill={gradientFill}
                  />
                ) : null}
              </Group>
            );
          })}

          {transformedData.map((d, index) => {
            let barHeight = Math.min(valueScale(0) - valueScale(getStandValue(d) ?? 0), plotHeight);
            const maxValue = valueScale.domain()[1];
            const threshold = isManual || outlierThreshold === null ? maxValue : outlierThreshold;

            const thresholdMaxHeight = valueScale(0) - valueScale(maxValue);
            let barY = plotHeight - barHeight;

            if (threshold !== null) {
              barHeight = Math.min(barHeight, thresholdMaxHeight);
              barY = Math.max(0, plotHeight - barHeight);
            }
            const barX = categoryScale(d?.standId || d?.date?.getTime() || 0);
            if (!barX || !columnWidth) return null;
            const value = valueUOM.displayWithAutoDecimals(valuesDomain, d.value, { unit: "" });

            return (
              <Label
                barHeight={barHeight}
                barX={barX}
                barY={barY + (detailed ? 0 : 10)}
                columnWidth={columnWidth}
                detailed={detailed}
                index={index}
                topLabel={barY !== plotHeight}
                value={value}
                label={lens?.label}
                key={`bar-single-lb-${d.index}-${value}`}
              />
            );
          })}
          {Number.isNaN(valueScale(data.summary?.average ?? 0)) ? null : (
            <AverageLine
              isVisible={(lens?.selectedVisualAids ?? []).includes(VisualAidType.Average)}
              y={valueScale(data.summary?.average ?? 0)}
              x={-RIGHT_AXIS_PADDING}
              width={plotWidth + RIGHT_AXIS_PADDING}
            />
          )}

          <MedianLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(VisualAidType.Median)}
            y={valueScale(data.summary?.median ?? 0)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
            computedTargetSegments.map(({ target, lineStart, lineEnd, showTag }, index) => {
              if (!target) return null;
              return (
                <TargetLine
                  key={`${index}-${lineStart}-${lineEnd}}`}
                  start={lineStart}
                  end={lineEnd}
                  y={valueScale(target)}
                  label={valueUOM.displayWithAutoDecimals(valuesDomain, target, { unit: "" })}
                  showTag={!!showTag}
                  detailed={detailed}
                />
              );
            })}
        </Chart>

        {!lens?.squeezesDisplay && zoomRate > 1 && (
          <Group top={plotHeight + (detailed ? 62 : 26)} left={scrollbarPadding}>
            <Scrollbar
              width={scrollbarWidth}
              height={scrollbarHeight}
              scale={scrollbarScale}
              wheelCaptureContainer={containerRef}
              onScroll={setScrollbarRange}
              valueSpan={1 / zoomRate}
            />
          </Group>
        )}
        <Indicators
          keys={aggregatedIndicators.keys}
          value={(key: string) => aggregatedIndicators.map[key]}
          xScale={categoryScale}
          height={plotHeight}
          gapWidth={gapWidth}
          selectedIndicators={indicators}
        />
      </svg>

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Average) && (
        <AverageLabel
          style={{
            top: `${valueScale(data.summary?.average ?? 0) - 16}px`,
          }}
        >
          Average: {valueUOM.displayWithAutoDecimals(valuesDomain, data.summary?.average ?? 0)}
        </AverageLabel>
      )}

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Median) && (
        <MedianLabel
          isDetailed={detailed}
          style={{
            top: `${valueScale(data.summary?.median ?? 0) - 16}px`,
          }}
        >
          Median: {valueUOM.displayWithAutoDecimals(valuesDomain, data.summary?.median ?? 0)}
        </MedianLabel>
      )}

      {tooltipElement}
      {inspectionViewElement}
    </div>
  ) : null;
}
