import { FormGroupContainer } from "components/General/style";
import { CustomSwitch } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/style";
import { PDComponent } from "components/PDComponents";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Button, Col, Row, Tabs } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const StyledCol = styled(Col)<{ $isEditingTabs: boolean }>`
  width: 100%;
  z-index: ${({ $isEditingTabs }) => ($isEditingTabs ? zIndexLayer.mars : zIndexLayer.elevated)};
  background-color: ${({ theme: { themeStyle } }) => themeStyle.colors.active_tab_bg};
`;

export const StyledTooltipBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  svg {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  }
`;

export const StyledOverlay = styled(Col)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndexLayer.mars};
`;

export const StyledRigIcon = styled(PDComponent.SvgIcon).attrs({ name: "rig" })<{ isDisabled?: boolean }>`
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.themeStyle.colors.disabled_typography : theme.themeStyle.colors.primary_typography};
`;

export const StyledEditTabButton = styled.div`
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DefaultButtonTemplate = styled.div`
  width: 36px;
  height: 36px;
  padding: 9px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  background-color: ${colors.white};
`;

export const StyledSelect = styled.div``;

export const StyledCloseIcon = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const RigCustomSwitch = styled(CustomSwitch)`
  padding: 2px 2px 23px 22px !important;
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const StyledModalContent = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.white_black};
  hr {
    margin: 0px;
    width: 100%;
    background: ${({ theme }) => theme.themeStyle.colors.delimiter};
    opacity: 0.3;
  }
  ${FormGroupContainer} {
    padding: 16px 24px;
  }
`;

export const StyledDepthText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.themeStyle.colors.secondary_typography};
`;

export const ButtonRow = styled(Row)`
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
  padding: 14px 24px;
`;

export const LensTabsBar = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.themeStyle.colors.active_tab_bg};
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: transparent;
  }
`;

export const AddButton = styled(Button)`
  width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  border-radius: 5px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  &.ant-btn:hover {
    background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  }
  &.ant-btn[disabled] {
    background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
    border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  }
  box-shadow: ${({ theme, disabled }) =>
    disabled ? "none" : `0px 2px 4px 0px rgba(0, 0, 0, ${theme.isDark ? 0.5 : 0.25} )`};
`;

export const AddNewTabButton = styled.div`
  display: grid;
  place-items: center;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_tabs_bg};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  border-radius: 12px;
  align-self: center;
`;

// Prateek: TODO: Why don't we use the atoms button?
// TB: super unsure; but it s used throughout the app
// waiting for a better time to change it
export const StyledTabs = styled(Tabs)`
  margin-top: -14px !important;

  & > .ant-tabs-nav {
    margin-bottom: 0;
    margin-left: 24px;
    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 0;
      margin-right: 20px;
    }

    .ant-tabs-tab {
      padding: 20px 0 !important;
      margin-right: 20px;
    }

    .ant-tabs-tab {
      color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    }
  }

  & .ant-tabs-nav-wrap {
    height: 64px;
  }

  & .ant-tabs-tab-active > div {
    font-weight: unset !important;
  }
`;

export const PersonalLensTabContainer = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-right: 16px;
  padding-left: 16px;
`;

export const StyledInputEdit = styled.input`
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const PersonalTabs = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 1;
  height: 64px;
  padding-left: 10px;
  border-top-left-radius: 12px;

  background-color: ${({ theme }) => theme.themeStyle.colors.personal_tab_bar_bg};
  position: relative;
  z-index: ${zIndexLayer.wall};
  &::before {
    content: " ";
    pointer-events: none;
    position: absolute;
    display: block;
    height: 80%;
    width: 100%;
    bottom: 0px;
    z-index: ${zIndexLayer.fence};
    left: -100%;
    border-radius: 0 0 10px 0;
    opacity: 1;
    border-top-left-radius: 8px;
    box-shadow: 14px 0.25em 0 -4px ${({ theme }) => theme.themeStyle.colors.personal_tab_bar_bg};
  }
`;
export const OperatorTabs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  height: 64px;
  padding: 0px 16px;
  flex-shrink: 0;
  border-top-left-radius: 12px;
  position: relative;

  background-color: ${({ theme }) => theme.themeStyle.colors.operator_tab_bar_bg};
  &::before {
    content: " ";
    pointer-events: none;
    position: absolute;
    display: block;
    height: 80%;
    width: 20px;
    bottom: 20%;
    z-index: ${zIndexLayer.fence};
    right: -10px;
    border-radius: 0 0 10px 0;
    opacity: 1;
    background-color: ${({ theme }) => theme.themeStyle.colors.operator_tab_bar_bg};
  }
`;

// TODO move this to the comp
export const LensTabButton = styled.div<{
  $active?: boolean;
  $isOperatorTab?: boolean;
  $width?: number;
  $isEditingTabs?: boolean;
}>`
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  flex-shrink: 0;
  position: relative;
  border-radius: 8px;
  width: ${({ $width }) => ($width ? `${$width}px` : "auto")};
  cursor: pointer;
  background-color: ${({ theme, $isOperatorTab }) => {
    if ($isOperatorTab) return theme.themeStyle.colors.primary_tabs_bg;
    return theme.themeStyle.colors.secondary_tabs_bg;
  }};

  color: ${({ theme, $active }) => {
    if ($active) return theme.themeStyle.colors.primary_typography;
    return theme.themeStyle.colors.tabs_typography;
  }};
  height: 40px;
  place-self: center;
  ${({ $active, theme }) =>
    $active
      ? css`
          /* don t know how to align this correctly */
          margin-top: 9px;
          margin-bottom: 0px;
          align-self: flex-end;
          height: 55px;
          padding-bottom: 7.5px;
          background-color: ${theme.themeStyle.colors.active_tab_bg};
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          &::before {
            content: " ";
            pointer-events: none;
            position: absolute;
            display: block;
            height: 80%;
            width: 100%;
            bottom: 0px;
            z-index: ${zIndexLayer.ground};
            left: -100%;
            border-radius: 0 0 10px 0;
            opacity: 1;
            box-shadow: 14px 0.25em 0 -4px ${theme.themeStyle.colors.active_tab_bg};
          }
          &::after {
            content: " ";
            pointer-events: none;
            position: absolute;
            display: block;
            height: 80%;
            width: 100%;
            bottom: 0px;
            z-index: ${zIndexLayer.ground};
            right: -100%;
            border-radius: 0 0 0 10px;
            opacity: 1;
            box-shadow: -14px 0.25em 0 -4px ${theme.themeStyle.colors.active_tab_bg};
          }
        `
      : ``}
`;

export const NoWrapText = styled.span`
  white-space: nowrap;
  display: flex;
`;
