import type { ParameterByDepthRoadmapBoundariesDto, ParameterByDepthTrackValuesDto } from "apis/oag";
import type { ParameterByDepthTrack } from "components/Lenses/interfaces";
import { useMemo } from "react";

interface ParameterDtoWithOutliers extends ParameterByDepthTrackValuesDto {
  isOutlier?: boolean;
}

export interface TrackWithRanges extends ParameterByDepthTrack {
  ranges: BoundaryRange[];
}

export interface BoundaryRange {
  start: number;
  end: number;
  rangePoints: ParameterDtoWithOutliers[];
  // Compress point intervals of outliers to be displayed on the bottom status bar. Less DOM boxes
  compressedOutlierRanges: Array<ParameterDtoWithOutliers[]>;
  boundary?: ParameterByDepthRoadmapBoundariesDto;
}

function getLastItem<T>(arr: T[]) {
  return arr[arr.length - 1];
}

const computeRanges = (track: ParameterByDepthTrack): BoundaryRange[] => {
  if ((track.boundaries || []).length > 0) {
    const ranges: BoundaryRange[] = [];
    const boundaries = track.boundaries || [];

    if (track.trackValues.length > 0) {
      boundaries.forEach((boundary, i) => {
        const range: BoundaryRange = {
          start: boundaries[i - 1] ? boundaries[i - 1].measuredDepth : track.trackValues[0].holeDepth,
          end: boundary.measuredDepth,
          rangePoints: [],
          compressedOutlierRanges: [],
          boundary: { ...boundary },
        };
        ranges.push(range);
      });
    }

    if (getLastItem(ranges).end < getLastItem(track.trackValues).holeDepth) {
      // To account for the last boundary to max value in the chart, if it's needed
      ranges.push({
        start: getLastItem(ranges).end,
        end: getLastItem(track.trackValues).holeDepth,
        rangePoints: [],
        compressedOutlierRanges: [],
        boundary: { measuredDepth: 0, min: null, max: null },
      });
    }

    track.trackValues.forEach((trackValue) => {
      ranges.forEach((range) => {
        if (trackValue.holeDepth >= range.start && trackValue.holeDepth <= range.end) {
          range.rangePoints.push({
            ...trackValue,
            isOutlier:
              (Number.isFinite(range.boundary?.min) || Number.isFinite(range.boundary?.max))
                && trackValue.value !== null 
                && trackValue.value !== undefined 
                && (trackValue.value > (range.boundary?.max ?? 0) || trackValue.value < (range.boundary?.min ?? 0)),
          });
        }
      });
    });

    ranges.forEach((range) => {
      range.compressedOutlierRanges = compressOutliers(range.rangePoints);
    });

    return ranges;
  } else {
    return [
      // If there are no boundaries, we default
      {
        start: track.trackValues.length == 0 ? 0 : track.trackValues[0].holeDepth,
        end: getLastItem(track.trackValues)?.holeDepth,
        rangePoints: track.trackValues,
        boundary: { min: null, max: null } as ParameterByDepthRoadmapBoundariesDto,
        compressedOutlierRanges: compressOutliers(track.trackValues),
      },
    ];
  }
};

export function useTrackRanges(tracks: ParameterByDepthTrack[]) {
  return useMemo(() => {
    return tracks.map((track) => {
      return {
        ...track,
        ranges: computeRanges(track),
      };
    });
  }, [tracks]);
}

function compressOutliers(arr: ParameterDtoWithOutliers[]): Array<ParameterDtoWithOutliers[]> {
  const sequences = [];
  let start = null;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i].isOutlier) {
      if (start === null) {
        start = i;
      }
    } else {
      if (start !== null) {
        sequences.push([
          arr[start],
          arr[i - 1],
        ]);
        start = null;
      }
    }
  }

  // Check if the last sequence ends at the end of the array
  if (start !== null) {
    sequences.push([
      arr[start],
      arr[arr.length - 1],
    ]);
  }

  return sequences;
}
