/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface InspectionTvdPointDto
 */
export interface InspectionTvdPointDto {
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    dynamicDuration: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    holeDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    dynamicHoleDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof InspectionTvdPointDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    operationClassId: number;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    bitDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    blockHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    torque?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    hookLoad?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    standpipePressure?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    pumpOutput?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    rateOfPenetration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    revolutionsPerSecond?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    differentialPressure?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    weightOnBit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    sssi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InspectionTvdPointDto
     */
    zTorqueStatus?: number | null;
}

/**
 * Check if a given object implements the InspectionTvdPointDto interface.
 */
export function instanceOfInspectionTvdPointDto(value: object): value is InspectionTvdPointDto {
    if (!('cumulativeDuration' in value) || value['cumulativeDuration'] === undefined) return false;
    if (!('dynamicDuration' in value) || value['dynamicDuration'] === undefined) return false;
    if (!('at' in value) || value['at'] === undefined) return false;
    if (!('operationClassId' in value) || value['operationClassId'] === undefined) return false;
    return true;
}

export function InspectionTvdPointDtoFromJSON(json: any): InspectionTvdPointDto {
    return InspectionTvdPointDtoFromJSONTyped(json, false);
}

export function InspectionTvdPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionTvdPointDto {
    if (json == null) {
        return json;
    }
    return {
        
        'cumulativeDuration': json['CumulativeDuration'],
        'dynamicDuration': json['DynamicDuration'],
        'holeDepth': json['HoleDepth'] == null ? undefined : json['HoleDepth'],
        'dynamicHoleDepth': json['DynamicHoleDepth'] == null ? undefined : json['DynamicHoleDepth'],
        'at': DateDtoFromJSON(json['At']),
        'operationClassId': json['OperationClassId'],
        'bitDepth': json['BitDepth'] == null ? undefined : json['BitDepth'],
        'blockHeight': json['BlockHeight'] == null ? undefined : json['BlockHeight'],
        'torque': json['Torque'] == null ? undefined : json['Torque'],
        'hookLoad': json['HookLoad'] == null ? undefined : json['HookLoad'],
        'standpipePressure': json['StandpipePressure'] == null ? undefined : json['StandpipePressure'],
        'pumpOutput': json['PumpOutput'] == null ? undefined : json['PumpOutput'],
        'rateOfPenetration': json['RateOfPenetration'] == null ? undefined : json['RateOfPenetration'],
        'revolutionsPerSecond': json['RevolutionsPerSecond'] == null ? undefined : json['RevolutionsPerSecond'],
        'differentialPressure': json['DifferentialPressure'] == null ? undefined : json['DifferentialPressure'],
        'weightOnBit': json['WeightOnBit'] == null ? undefined : json['WeightOnBit'],
        'sssi': json['Sssi'] == null ? undefined : json['Sssi'],
        'zTorqueStatus': json['ZTorqueStatus'] == null ? undefined : json['ZTorqueStatus'],
    };
}

export function InspectionTvdPointDtoToJSON(value?: InspectionTvdPointDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'CumulativeDuration': value['cumulativeDuration'],
        'DynamicDuration': value['dynamicDuration'],
        'HoleDepth': value['holeDepth'],
        'DynamicHoleDepth': value['dynamicHoleDepth'],
        'At': DateDtoToJSON(value['at']),
        'OperationClassId': value['operationClassId'],
        'BitDepth': value['bitDepth'],
        'BlockHeight': value['blockHeight'],
        'Torque': value['torque'],
        'HookLoad': value['hookLoad'],
        'StandpipePressure': value['standpipePressure'],
        'PumpOutput': value['pumpOutput'],
        'RateOfPenetration': value['rateOfPenetration'],
        'RevolutionsPerSecond': value['revolutionsPerSecond'],
        'DifferentialPressure': value['differentialPressure'],
        'WeightOnBit': value['weightOnBit'],
        'Sssi': value['sssi'],
        'ZTorqueStatus': value['zTorqueStatus'],
    };
}

