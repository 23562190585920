import { RadioButtonGroup } from "atoms/common";
import { PDComponent } from "components/PDComponents";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Radio } from "utils/componentLibrary";

const StyledRadioButton = styled(Radio.Button)`
  padding: 0 10px;
`;

const StyledSwitcherContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 14px;
`;

export function RigPageSwitcher() {
  const navigate = useNavigate();
  const location = useLocation();
  const isScoreCard = location.pathname.includes("scorecard");
  const { rigId } = useParams<{ rigId: string }>();
  const { data: currentUser } = useCurrentUser();

  if (!rigId || !currentUser?.canAccessRigScorecard) return null;

  return (
    <StyledSwitcherContainer>
      <RadioButtonGroup value={isScoreCard} size="large">
        <StyledRadioButton
          value={false}
          onClick={() => {
            navigate(`/rig/${rigId}`);
          }}
        >
          <PDComponent.SvgIcon name="rigDashboardIcon" />
        </StyledRadioButton>
        <StyledRadioButton
          value={true}
          onClick={() => {
            navigate(`/rig/scorecard/${rigId}`);
          }}
        >
          <PDComponent.SvgIcon name="rigScoreIcon" width="15.9" height={18} />
        </StyledRadioButton>
      </RadioButtonGroup>
    </StyledSwitcherContainer>
  );
}
