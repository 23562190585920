import "./wdyr";
import "./index.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./index.css";
import "virtual:svg-icons-register";

import type { AuthenticationResult, EventMessage } from "@azure/msal-browser";
import { EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { msalInstance } from "msalInstance";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Track } from "services/Mixpanel";

Sentry.init({
  release: import.meta.env.VITE_REACT_APP_RELEASE_VERSION,
  dsn: "https://fcd36c65d2f44d4f9bb5abc19046cd19@o690051.ingest.sentry.io/5775288",
  ignoreErrors: ["ResizeObserver loop limit exceeded", "ResizeObserver loop completed with undelivered notifications."],
  environment: import.meta.env.VITE_REACT_APP_ENV as string,
  tracesSampleRate: 1.0,
});

reportWebVitals();
msalInstance
  .initialize()
  .then(() => {
    // Default to using the first account if no account is active on page load
    // if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    //   msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    // }

    // Optional - This will update account state if a user signs in from another tab or window
    //msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event: EventMessage) => {
      if (!event.payload) return;

      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload?.account;
        Track.event("Login", { "Email": account.name });
        msalInstance.setActiveAccount(account);
      }
    });
    
    // Forcefully render react in v17, to avoid grid-layout bug in v18, more details BUG 12626
    // const root = createRoot(document.getElementById("root")!);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>,
      document.getElementById("root"),
    );
  })
  .catch((e) => console.log(e));
