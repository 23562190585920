import { Orientation } from "@visx/axis";
import { Group } from "@visx/group";
import { Text as VisXText } from "@visx/text";
import { BOTTOM_AXIS_HEIGHT, getAxisFontSize } from "components/Lenses/ContainerLens/common/utils/utils";
import { useMemo } from "react";
import styled from "styled-components";
import { useCustomTheme } from "utils/useTheme";

interface RotatedAxisLabelProps {
  chartHeight: number;
  chartWidth?: number;
  rightPadding?: number;
  displayText: string | null;
  orientation?: keyof typeof Orientation;
}

const StyledText = styled(VisXText)<{
  $xtranslate: number;
  $rightPadding: number;
  $orientation: keyof typeof Orientation;
}>`
  transform: rotate(-90deg)
    translate(
      ${({ $xtranslate, $orientation, $rightPadding }) =>
        `${$xtranslate}px, ${$orientation === Orientation.left ? 15 : -$rightPadding - 15}px`}
    );
  transform-origin: top ${({ $orientation }) => ($orientation === Orientation.left ? "left" : "right")};
`;

export const RotatedAxisLabel = ({
  chartHeight,
  chartWidth,
  rightPadding = 0,
  displayText,
  orientation = Orientation.left,
}: RotatedAxisLabelProps) => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const axisFontSize = useMemo(() => getAxisFontSize(false), []);
  return (
    <Group>
      <StyledText
        $xtranslate={-(chartHeight - BOTTOM_AXIS_HEIGHT) / 2}
        $rightPadding={rightPadding}
        $orientation={orientation}
        textAnchor="middle"
        fill={themeColors.disabled_typography}
        fontSize={axisFontSize}
        x={orientation === Orientation.left ? 0 : chartWidth}
        y={0}
      >
        {displayText ? displayText : ""}
      </StyledText>
    </Group>
  );
};
