/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WellIdListDto
 */
export interface WellIdListDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof WellIdListDto
     */
    wellIds: Array<number>;
}

/**
 * Check if a given object implements the WellIdListDto interface.
 */
export function instanceOfWellIdListDto(value: object): value is WellIdListDto {
    if (!('wellIds' in value) || value['wellIds'] === undefined) return false;
    return true;
}

export function WellIdListDtoFromJSON(json: any): WellIdListDto {
    return WellIdListDtoFromJSONTyped(json, false);
}

export function WellIdListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellIdListDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellIds': json['WellIds'],
    };
}

export function WellIdListDtoToJSON(value?: WellIdListDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellIds': value['wellIds'],
    };
}

