import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type { WellShortInfo } from "apis/oag";
import { WellsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const wells = new WellsApi(apiConfig);

export async function fetchWellShortInfo() {
  const data = await wells.apiWellsShortInfoGet();

  return {
    list: data,
    byId: data.reduce<Record<number, WellShortInfo>>((acc, cur) => {
      acc[cur.id ?? 0] = cur;
      return acc;
    }, {}),
  };
}

export type WellShortInfoResult = {
  list: WellShortInfo[];
  byId: Record<number, WellShortInfo>;
};

export function useWellShortInfo(options?: Omit<UseQueryOptions<WellShortInfoResult>, "queryKey" | "queryFn">) {
  const WellShortInfoQueryKey: PDUniqueQueryKey = { uid: RequestUID.wellShortInfo };
  return useQuery<WellShortInfoResult>({
    queryKey: [WellShortInfoQueryKey],
    queryFn: () => fetchWellShortInfo(),
    ...options,
    refetchOnMount: false,
  });
}

export function useWellShortInfoSuspended(
  options?: Omit<UseQueryOptions<WellShortInfoResult>, "queryKey" | "queryFn">,
) {
  const WellShortInfoQueryKey: PDUniqueQueryKey = { uid: RequestUID.wellShortInfo };
  return useSuspenseQuery<WellShortInfoResult>({
    queryKey: [WellShortInfoQueryKey],
    queryFn: () => fetchWellShortInfo(),
    ...options,
    refetchOnMount: false,
  });
}
