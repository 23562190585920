import React from "react";

export enum ZoomState {
  Zooming = "Zooming",
  Pan = "Pan",
}

type Domain = [number, number];

type ContextType = {
  zoomState: ZoomState;
  triggerZoomReset: () => void;
  initialDomain: Domain | null;
  domain: Domain | null;
  setDomain: React.Dispatch<React.SetStateAction<Domain | null>>;
};

const initialState: ContextType = {
  zoomState: ZoomState.Zooming,
  triggerZoomReset: () => {},
  domain: [0, 0],
  initialDomain: [0, 0],
  setDomain: () => {},
};

export const ZoomChartContext = React.createContext<ContextType>(initialState);
