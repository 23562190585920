/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ByDepthUserLensQueryDto,
  FiltersDto,
  ParameterByDepthKpiComparisonDto,
  ParameterByDepthUserLensDto,
} from '../models/index';
import {
    ByDepthUserLensQueryDtoFromJSON,
    ByDepthUserLensQueryDtoToJSON,
    FiltersDtoFromJSON,
    FiltersDtoToJSON,
    ParameterByDepthKpiComparisonDtoFromJSON,
    ParameterByDepthKpiComparisonDtoToJSON,
    ParameterByDepthUserLensDtoFromJSON,
    ParameterByDepthUserLensDtoToJSON,
} from '../models/index';

export interface ApiParameterByDepthUserLensesAvailableFiltersPutRequest {
    byDepthUserLensQueryDto?: ByDepthUserLensQueryDto;
}

export interface ApiParameterByDepthUserLensesIdFactsPutRequest {
    id: number;
    byDepthUserLensQueryDto?: ByDepthUserLensQueryDto;
}

export interface ApiParameterByDepthUserLensesIdPutRequest {
    id: number;
    parameterByDepthUserLensDto?: ParameterByDepthUserLensDto;
}

export interface ApiParameterByDepthUserLensesPostRequest {
    parameterByDepthUserLensDto?: ParameterByDepthUserLensDto;
}

/**
 * 
 */
export class ParameterByDepthUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiParameterByDepthUserLensesAvailableFiltersPutRaw(requestParameters: ApiParameterByDepthUserLensesAvailableFiltersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FiltersDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ParameterByDepthUserLenses/AvailableFilters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ByDepthUserLensQueryDtoToJSON(requestParameters['byDepthUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FiltersDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiParameterByDepthUserLensesAvailableFiltersPut(requestParameters: ApiParameterByDepthUserLensesAvailableFiltersPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FiltersDto> {
        const response = await this.apiParameterByDepthUserLensesAvailableFiltersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiParameterByDepthUserLensesIdFactsPutRaw(requestParameters: ApiParameterByDepthUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParameterByDepthKpiComparisonDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiParameterByDepthUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ParameterByDepthUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ByDepthUserLensQueryDtoToJSON(requestParameters['byDepthUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParameterByDepthKpiComparisonDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiParameterByDepthUserLensesIdFactsPut(requestParameters: ApiParameterByDepthUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParameterByDepthKpiComparisonDto> {
        const response = await this.apiParameterByDepthUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiParameterByDepthUserLensesIdPutRaw(requestParameters: ApiParameterByDepthUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParameterByDepthUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiParameterByDepthUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ParameterByDepthUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ParameterByDepthUserLensDtoToJSON(requestParameters['parameterByDepthUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParameterByDepthUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiParameterByDepthUserLensesIdPut(requestParameters: ApiParameterByDepthUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParameterByDepthUserLensDto> {
        const response = await this.apiParameterByDepthUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiParameterByDepthUserLensesPostRaw(requestParameters: ApiParameterByDepthUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParameterByDepthUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ParameterByDepthUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParameterByDepthUserLensDtoToJSON(requestParameters['parameterByDepthUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParameterByDepthUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiParameterByDepthUserLensesPost(requestParameters: ApiParameterByDepthUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParameterByDepthUserLensDto> {
        const response = await this.apiParameterByDepthUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
