import type { ApiGeneratorStatusBarUserLensesIdFactsPutRequest } from "apis/oag/apis/GeneratorStatusBarUserLensesApi";
import { useGeneratorStatusBarFacts } from "hooks/lens-facts/useGeneratorStatusBarFacts";
import { useFilterParams } from "hooks/useFilterParams";

interface UseGeneratorStatusBarFetcherParams {
  lensId: number;
  comparisonWellIds?: number[];
}

export function useGeneratorStatusBarFetcher({ lensId }: UseGeneratorStatusBarFetcherParams) {
  const filterParams = useFilterParams();

  const requestParameters: ApiGeneratorStatusBarUserLensesIdFactsPutRequest = {
    id: lensId ?? -1,
    rigPowerQueryDto: { ...filterParams, selectedOperationClassIds: null },
  };
  const request = useGeneratorStatusBarFacts(lensId, requestParameters, {
    enabled: !!lensId,
  });

  return { request };
}
