/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface RigFleetPerformanceCardUserLensDto
 */
export interface RigFleetPerformanceCardUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof RigFleetPerformanceCardUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RigFleetPerformanceCardUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof RigFleetPerformanceCardUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof RigFleetPerformanceCardUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RigFleetPerformanceCardUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RigFleetPerformanceCardUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {StandKpiType}
     * @memberof RigFleetPerformanceCardUserLensDto
     */
    cardKpiType: StandKpiType;
}



/**
 * Check if a given object implements the RigFleetPerformanceCardUserLensDto interface.
 */
export function instanceOfRigFleetPerformanceCardUserLensDto(value: object): value is RigFleetPerformanceCardUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('cardKpiType' in value) || value['cardKpiType'] === undefined) return false;
    return true;
}

export function RigFleetPerformanceCardUserLensDtoFromJSON(json: any): RigFleetPerformanceCardUserLensDto {
    return RigFleetPerformanceCardUserLensDtoFromJSONTyped(json, false);
}

export function RigFleetPerformanceCardUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigFleetPerformanceCardUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'cardKpiType': StandKpiTypeFromJSON(json['CardKpiType']),
    };
}

export function RigFleetPerformanceCardUserLensDtoToJSON(value?: RigFleetPerformanceCardUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'CardKpiType': StandKpiTypeToJSON(value['cardKpiType']),
    };
}

