/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseDisplayOptionUserLensQueryDto,
  KpiGroupUserLensDto,
  StackedStandKpiComparisonDto,
  StackedStandKpiDto,
} from '../models/index';
import {
    BaseDisplayOptionUserLensQueryDtoFromJSON,
    BaseDisplayOptionUserLensQueryDtoToJSON,
    KpiGroupUserLensDtoFromJSON,
    KpiGroupUserLensDtoToJSON,
    StackedStandKpiComparisonDtoFromJSON,
    StackedStandKpiComparisonDtoToJSON,
    StackedStandKpiDtoFromJSON,
    StackedStandKpiDtoToJSON,
} from '../models/index';

export interface ApiKpiGroupUserLensesIdComparisonFactsPutRequest {
    id: number;
    baseDisplayOptionUserLensQueryDto?: BaseDisplayOptionUserLensQueryDto;
}

export interface ApiKpiGroupUserLensesIdPutRequest {
    id: number;
    kpiGroupUserLensDto?: KpiGroupUserLensDto;
}

export interface ApiKpiGroupUserLensesIdStackedFactsPutRequest {
    id: number;
    baseDisplayOptionUserLensQueryDto?: BaseDisplayOptionUserLensQueryDto;
}

export interface ApiKpiGroupUserLensesPostRequest {
    kpiGroupUserLensDto?: KpiGroupUserLensDto;
}

/**
 * 
 */
export class KpiGroupUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiKpiGroupUserLensesIdComparisonFactsPutRaw(requestParameters: ApiKpiGroupUserLensesIdComparisonFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StackedStandKpiComparisonDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiKpiGroupUserLensesIdComparisonFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/KpiGroupUserLenses/{id}/ComparisonFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseDisplayOptionUserLensQueryDtoToJSON(requestParameters['baseDisplayOptionUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StackedStandKpiComparisonDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiKpiGroupUserLensesIdComparisonFactsPut(requestParameters: ApiKpiGroupUserLensesIdComparisonFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StackedStandKpiComparisonDto> {
        const response = await this.apiKpiGroupUserLensesIdComparisonFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiKpiGroupUserLensesIdPutRaw(requestParameters: ApiKpiGroupUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiGroupUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiKpiGroupUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/KpiGroupUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KpiGroupUserLensDtoToJSON(requestParameters['kpiGroupUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiGroupUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiKpiGroupUserLensesIdPut(requestParameters: ApiKpiGroupUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiGroupUserLensDto> {
        const response = await this.apiKpiGroupUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiKpiGroupUserLensesIdStackedFactsPutRaw(requestParameters: ApiKpiGroupUserLensesIdStackedFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StackedStandKpiDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiKpiGroupUserLensesIdStackedFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/KpiGroupUserLenses/{id}/StackedFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseDisplayOptionUserLensQueryDtoToJSON(requestParameters['baseDisplayOptionUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StackedStandKpiDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiKpiGroupUserLensesIdStackedFactsPut(requestParameters: ApiKpiGroupUserLensesIdStackedFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StackedStandKpiDto> {
        const response = await this.apiKpiGroupUserLensesIdStackedFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiKpiGroupUserLensesPostRaw(requestParameters: ApiKpiGroupUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiGroupUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/KpiGroupUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KpiGroupUserLensDtoToJSON(requestParameters['kpiGroupUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiGroupUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiKpiGroupUserLensesPost(requestParameters: ApiKpiGroupUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiGroupUserLensDto> {
        const response = await this.apiKpiGroupUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
