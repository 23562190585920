import { useQuery } from "@tanstack/react-query";
import { UsersApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const users = new UsersApi(apiConfig);

export function useAdminSingleUser({ userId }: { userId: number }, enabled = true) {
  const queryKey = { type: PDQueryType.ADMIN_USERS_SINGLE, uid: RequestUID.adminUsersSingle, userId };

  return useQuery({
    queryKey: [queryKey],
    queryFn: () =>
      users.apiUsersIdGet({
        id: userId,
      }),
    enabled,
  });
}
