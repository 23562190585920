/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GeneratorStatusBarFactSetDto,
  GeneratorStatusBarUserLensDto,
  RigPowerQueryDto,
} from '../models/index';
import {
    GeneratorStatusBarFactSetDtoFromJSON,
    GeneratorStatusBarFactSetDtoToJSON,
    GeneratorStatusBarUserLensDtoFromJSON,
    GeneratorStatusBarUserLensDtoToJSON,
    RigPowerQueryDtoFromJSON,
    RigPowerQueryDtoToJSON,
} from '../models/index';

export interface ApiGeneratorStatusBarUserLensesIdFactsPutRequest {
    id: number;
    rigPowerQueryDto?: RigPowerQueryDto;
}

export interface ApiGeneratorStatusBarUserLensesIdPutRequest {
    id: number;
    generatorStatusBarUserLensDto?: GeneratorStatusBarUserLensDto;
}

export interface ApiGeneratorStatusBarUserLensesPostRequest {
    generatorStatusBarUserLensDto?: GeneratorStatusBarUserLensDto;
}

/**
 * 
 */
export class GeneratorStatusBarUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiGeneratorStatusBarUserLensesIdFactsPutRaw(requestParameters: ApiGeneratorStatusBarUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneratorStatusBarFactSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiGeneratorStatusBarUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GeneratorStatusBarUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigPowerQueryDtoToJSON(requestParameters['rigPowerQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorStatusBarFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGeneratorStatusBarUserLensesIdFactsPut(requestParameters: ApiGeneratorStatusBarUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneratorStatusBarFactSetDto> {
        const response = await this.apiGeneratorStatusBarUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGeneratorStatusBarUserLensesIdPutRaw(requestParameters: ApiGeneratorStatusBarUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneratorStatusBarUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiGeneratorStatusBarUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GeneratorStatusBarUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GeneratorStatusBarUserLensDtoToJSON(requestParameters['generatorStatusBarUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorStatusBarUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGeneratorStatusBarUserLensesIdPut(requestParameters: ApiGeneratorStatusBarUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneratorStatusBarUserLensDto> {
        const response = await this.apiGeneratorStatusBarUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGeneratorStatusBarUserLensesPostRaw(requestParameters: ApiGeneratorStatusBarUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneratorStatusBarUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GeneratorStatusBarUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GeneratorStatusBarUserLensDtoToJSON(requestParameters['generatorStatusBarUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorStatusBarUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGeneratorStatusBarUserLensesPost(requestParameters: ApiGeneratorStatusBarUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneratorStatusBarUserLensDto> {
        const response = await this.apiGeneratorStatusBarUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
