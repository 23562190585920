/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LensTemplateType } from './LensTemplateType';
import {
    LensTemplateTypeFromJSON,
    LensTemplateTypeFromJSONTyped,
    LensTemplateTypeToJSON,
} from './LensTemplateType';
import type { StandKpiGroup } from './StandKpiGroup';
import {
    StandKpiGroupFromJSON,
    StandKpiGroupFromJSONTyped,
    StandKpiGroupToJSON,
} from './StandKpiGroup';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import type { StackingType } from './StackingType';
import {
    StackingTypeFromJSON,
    StackingTypeFromJSONTyped,
    StackingTypeToJSON,
} from './StackingType';

/**
 * 
 * @export
 * @interface KpiGroupLensTemplateDto
 */
export interface KpiGroupLensTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof KpiGroupLensTemplateDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof KpiGroupLensTemplateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiGroupLensTemplateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KpiGroupLensTemplateDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {LensTemplateType}
     * @memberof KpiGroupLensTemplateDto
     */
    type: LensTemplateType;
    /**
     * 
     * @type {DashboardType}
     * @memberof KpiGroupLensTemplateDto
     */
    targetDashboardType: DashboardType;
    /**
     * 
     * @type {number}
     * @memberof KpiGroupLensTemplateDto
     */
    nbCondensedDataPoints: number;
    /**
     * 
     * @type {boolean}
     * @memberof KpiGroupLensTemplateDto
     */
    useBitDepth: boolean;
    /**
     * 
     * @type {number}
     * @memberof KpiGroupLensTemplateDto
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof KpiGroupLensTemplateDto
     */
    canSelectTimeUnit: boolean;
    /**
     * 
     * @type {Array<StandKpiGroup>}
     * @memberof KpiGroupLensTemplateDto
     */
    selectableKpiGroupIds?: Array<StandKpiGroup> | null;
    /**
     * 
     * @type {StackingType}
     * @memberof KpiGroupLensTemplateDto
     */
    defaultStackingType: StackingType;
}



/**
 * Check if a given object implements the KpiGroupLensTemplateDto interface.
 */
export function instanceOfKpiGroupLensTemplateDto(value: object): value is KpiGroupLensTemplateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('targetDashboardType' in value) || value['targetDashboardType'] === undefined) return false;
    if (!('nbCondensedDataPoints' in value) || value['nbCondensedDataPoints'] === undefined) return false;
    if (!('useBitDepth' in value) || value['useBitDepth'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('canSelectTimeUnit' in value) || value['canSelectTimeUnit'] === undefined) return false;
    if (!('defaultStackingType' in value) || value['defaultStackingType'] === undefined) return false;
    return true;
}

export function KpiGroupLensTemplateDtoFromJSON(json: any): KpiGroupLensTemplateDto {
    return KpiGroupLensTemplateDtoFromJSONTyped(json, false);
}

export function KpiGroupLensTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiGroupLensTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'type': LensTemplateTypeFromJSON(json['Type']),
        'targetDashboardType': DashboardTypeFromJSON(json['TargetDashboardType']),
        'nbCondensedDataPoints': json['NbCondensedDataPoints'],
        'useBitDepth': json['UseBitDepth'],
        'position': json['Position'],
        'canSelectTimeUnit': json['CanSelectTimeUnit'],
        'selectableKpiGroupIds': json['SelectableKpiGroupIds'] == null ? undefined : ((json['SelectableKpiGroupIds'] as Array<any>).map(StandKpiGroupFromJSON)),
        'defaultStackingType': StackingTypeFromJSON(json['DefaultStackingType']),
    };
}

export function KpiGroupLensTemplateDtoToJSON(value?: KpiGroupLensTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'Description': value['description'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Type': LensTemplateTypeToJSON(value['type']),
        'TargetDashboardType': DashboardTypeToJSON(value['targetDashboardType']),
        'NbCondensedDataPoints': value['nbCondensedDataPoints'],
        'UseBitDepth': value['useBitDepth'],
        'Position': value['position'],
        'CanSelectTimeUnit': value['canSelectTimeUnit'],
        'SelectableKpiGroupIds': value['selectableKpiGroupIds'] == null ? undefined : ((value['selectableKpiGroupIds'] as Array<any>).map(StandKpiGroupToJSON)),
        'DefaultStackingType': StackingTypeToJSON(value['defaultStackingType']),
    };
}

