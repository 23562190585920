import { Title } from "atoms/Typography";
import { useConfirmModal } from "components/ConfirmModal";
import { EmptyNotificationsList } from "components/General/EmptyNotificationsList/EmptyNotificationsList";
import { PDComponent } from "components/PDComponents";
import {
  useActiveNotifications,
  useArchivedNotifications,
  useArchivedNotificationsCount,
} from "hooks/notifications/useNotifications";
import { useNotificationsActions } from "hooks/notifications/useNotificationsActions";
import { type FC, useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePageView } from "services/Mixpanel";
import { READ_UNREAD_LABEL } from "utils/notifications/constants";
import { ActiveNotificationsSelector } from "utils/notifications/mappers";

import { NotificationList } from "./components/NotificationList";
import { CheckboxVisible, CloseIcon } from "./components/style";
import {
  ButtonsContainer,
  CustomCol,
  CustomRow,
  CustomSwitch,
  NotificationHeader,
  NotificationPage,
  SelectAllContainer,
  StyledGear,
  StyledNotificationContainer,
  StyledTab,
  StyledTabCounter,
  TabName,
  TitleBar,
  UnreadText,
} from "./style";
import { NotificationTabs, notificationTabs } from "./utils";

export const AllNotifications: FC = () => {
  usePageView("All Notifications");
  const { notificationTab: notificationTabFromParam } = useParams<{ notificationTab?: NotificationTabs }>();
  const notificationTab = useMemo(() => {
    if (notificationTabFromParam && Object.values(NotificationTabs).includes(notificationTabFromParam))
      return notificationTabFromParam;

    throw new Error("Invalid notification tab");
  }, [notificationTabFromParam]);
  const { data: activeNotifications } = useActiveNotifications({ select: ActiveNotificationsSelector });
  const { data: archivedNotificationsCountString } = useArchivedNotificationsCount();
  const archivedNotificationsCount = +(archivedNotificationsCountString || "0");

  const { data: archivedNotifications } = useArchivedNotifications({
    select: ActiveNotificationsSelector,
    enabled: notificationTab === NotificationTabs.Archived,
  });

  const [isUnreadOnly, setIsUnreadOnly] = useState(false);

  const [selectedNotifications, setSelectedNotifications] = useState<number[]>([]);
  const { archive, deleteNotification, markRead, markUnread } = useNotificationsActions();

  const deleteConfirm = useCallback(() => {
    if (selectedNotifications.length === 0) return;
    deleteNotification(selectedNotifications, () => {
      setSelectedNotifications([]);
    });
  }, [selectedNotifications, deleteNotification]);

  const { confirmModalElement, openConfirmModal } = useConfirmModal({
    title: "Notification will be permanently deleted",
    description: "Record deletion is instant and irreversible. Confirm to proceed.",
    onConfirm: deleteConfirm,
  });

  const handleNotificationSelect = useCallback(
    (id: number) => {
      if (selectedNotifications.includes(id)) {
        setSelectedNotifications(selectedNotifications.filter((selectedId) => selectedId !== id));
      } else {
        setSelectedNotifications([...selectedNotifications, id]);
      }
    },
    [selectedNotifications],
  );

  const notifications = useMemo(() => {
    if (activeNotifications || archivedNotifications) {
      const allNotifications = () => {
        switch (notificationTab) {
          case NotificationTabs.ScorecardComments:
            return activeNotifications.scorecardCommentNotifications;
          case NotificationTabs.Exceptions:
            return activeNotifications.exceptionRequestNotifications;
          case NotificationTabs.Archived:
            return archivedNotifications?.getNotificationList?.() ?? [];
          default:
            return activeNotifications?.getNotificationList?.() ?? [];
        }
      };
      return allNotifications().filter((notification) => {
        if (isUnreadOnly) {
          return !notification.isRead;
        }
        return true;
      });
    }
    return [];
  }, [activeNotifications, archivedNotifications, isUnreadOnly, notificationTab]);

  const allSelected = useMemo(
    () => selectedNotifications.length === notifications.length,
    [selectedNotifications, notifications],
  );

  const toggleRead = useCallback(
    (notifIds: number[]) => {
      const allRead = notifIds.every((id) => notifications.find((notification) => notification.id === id)?.isRead);
      if (allRead) {
        markUnread(notifIds);
      } else {
        markRead(notifIds);
      }
    },
    [markRead, markUnread, notifications],
  );

  const getNotificationsCount = useCallback(
    (tab: NotificationTabs) => {
      const comments = activeNotifications.getUnreadUnarchivedCount(activeNotifications.scorecardCommentNotifications);
      const exceptions = activeNotifications.getUnreadUnarchivedCount(
        activeNotifications.exceptionRequestNotifications,
      );

      switch (tab) {
        case NotificationTabs.ScorecardComments:
          return comments;
        case NotificationTabs.Exceptions:
          return exceptions;
        case NotificationTabs.Archived:
          return archivedNotificationsCount;
        default:
          return comments + exceptions;
      }
    },
    [archivedNotificationsCount, activeNotifications],
  );

  const navigate = useNavigate();
  return (
    <NotificationPage>
      <TitleBar>
        <CustomCol span={12}>
          <Title level={2}>Notifications</Title>
          <Link to={"/notification-settings"}>
            <StyledGear />
          </Link>
        </CustomCol>

        <CustomCol span={6}>
          <CustomRow justify={"end"}>
            <CustomCol>
              <CustomSwitch
                onChange={(e) => {
                  if (e) {
                    const readNotifications = notifications
                      .filter((notification) => notification.isRead)
                      .map((notification) => notification.id);
                    setSelectedNotifications(
                      selectedNotifications.filter((selectedId) => !readNotifications.includes(selectedId)),
                    );
                  }
                  setIsUnreadOnly(e);
                }}
              />
              <UnreadText $isActive={isUnreadOnly}>Unread Only</UnreadText>
            </CustomCol>
            <CustomCol>
              <CloseIcon
                $large
                onClick={() => {
                  navigate(-1);
                }}
              >
                <PDComponent.SvgIcon name="close" />
              </CloseIcon>
            </CustomCol>
          </CustomRow>
        </CustomCol>
      </TitleBar>
      <NotificationHeader>
        {notificationTabs.map((tab) => (
          <StyledTab
            $isActive={tab.key === notificationTab}
            key={tab.key}
            onClick={() => {
              setSelectedNotifications([]);
              navigate(`/notifications/${tab.key}`, { replace: true });
            }}
          >
            <TabName>{tab.label}</TabName> <StyledTabCounter>{getNotificationsCount(tab.key)}</StyledTabCounter>
          </StyledTab>
        ))}
      </NotificationHeader>
      <StyledNotificationContainer key={notificationTab}>
        {selectedNotifications.length > 0 ? (
          <ButtonsContainer>
            <SelectAllContainer
              onClick={() => {
                setSelectedNotifications(allSelected ? [] : notifications.map((notification) => notification.id));
              }}
            >
              <CheckboxVisible
                checked={selectedNotifications.length === notifications.length}
                indeterminate={selectedNotifications.length > 0 && selectedNotifications.length < notifications.length}
              />
              <Title level={3}>
                <strong>{allSelected ? "Deselect All" : "Select All"}</strong>
              </Title>
            </SelectAllContainer>

            <Title
              level={3}
              onClick={() => {
                if (selectedNotifications.length === 0) return;
                toggleRead(selectedNotifications);
              }}
            >
              <strong>{READ_UNREAD_LABEL}</strong>
            </Title>
            {notificationTab === NotificationTabs.Archived ? (
              <Title level={3} onClick={openConfirmModal}>
                <strong>Delete</strong>
              </Title>
            ) : (
              <Title
                level={3}
                onClick={() => {
                  if (selectedNotifications.length === 0) return;
                  archive(selectedNotifications);
                }}
              >
                <strong>Archive</strong>
              </Title>
            )}
          </ButtonsContainer>
        ) : null}

        {notifications?.length > 0 ? (
          <NotificationList
            notifications={notifications}
            selectedIdsList={selectedNotifications}
            handleNotificationSelect={(id: number) => handleNotificationSelect(id)}
            key={notificationTab}
          />
        ) : (
          <EmptyNotificationsList size="large" />
        )}
      </StyledNotificationContainer>
      {confirmModalElement}
    </NotificationPage>
  );
};
