/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagBottomFingerprintStandSeriesDto
 */
export interface TagBottomFingerprintStandSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintStandSeriesDto
     */
    standId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof TagBottomFingerprintStandSeriesDto
     */
    facts: Array<number>;
}

/**
 * Check if a given object implements the TagBottomFingerprintStandSeriesDto interface.
 */
export function instanceOfTagBottomFingerprintStandSeriesDto(value: object): value is TagBottomFingerprintStandSeriesDto {
    if (!('standId' in value) || value['standId'] === undefined) return false;
    if (!('facts' in value) || value['facts'] === undefined) return false;
    return true;
}

export function TagBottomFingerprintStandSeriesDtoFromJSON(json: any): TagBottomFingerprintStandSeriesDto {
    return TagBottomFingerprintStandSeriesDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintStandSeriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintStandSeriesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'standId': json['StandId'],
        'facts': json['Facts'],
    };
}

export function TagBottomFingerprintStandSeriesDtoToJSON(value?: TagBottomFingerprintStandSeriesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StandId': value['standId'],
        'Facts': value['facts'],
    };
}

