import styled from "styled-components";
import colors from "utils/colors";

export const ZeroStateTimeline = styled.div`
  height: 100vh;
  width: 100%;
  background-image: ${colors.default_bg};
  display: grid;
  place-items: center;
`;
