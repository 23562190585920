export const GEN_POWER_ID = 1;
export const CAPACITY_ID = 2;

export const getInvartiantBusinessRuleForAxisValue = (maxAxisNumber: number) => {
  // in WATTS
  if (maxAxisNumber < 1200 * 1e3) {
    return 1200 * 1e3;
  } else if (maxAxisNumber < 3500 * 1e3) {
    return 3500 * 1e3;
  } else {
    if (maxAxisNumber > 4500 * 1e3) {
      console.error("Generator Load Profile: Possible malformed data on max Y Axis threshold");
    }
    return 4500 * 1e3;
  }
};
