import type { ParameterByDepthTrackComparisonWellDto, ResultDataState, SingleStandKpiComparisonWellDto, StackedStandKpiComparisonWellDto, WedgeComparisonWellDto } from "apis/oag";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { URL_STATE_PARAM } from "hooks/navigation/useQueryState";
import { useStateQuery } from "hooks/navigation/useQueryState";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { isEqual } from "lodash";
import { useEffect, useRef } from "react";
import { useAppDispatch } from "reducers/store";

import { useAppSelector } from "./../../../../reducers/store";

// we really only need { wellId, dataState }
export type BaseComparisonWellDto = SingleStandKpiComparisonWellDto | StackedStandKpiComparisonWellDto | ParameterByDepthTrackComparisonWellDto | WedgeComparisonWellDto;

export function useDetailedLegendActiveState({
  comparisons,
  detailed,
  dataState,
}: {
  comparisons: BaseComparisonWellDto[];
  detailed: boolean;
  dataState: ResultDataState;
}) {
  const { data: wellInfo } = useWellShortInfoSuspended();

  const prevOffset = useRef<number[]>([]);
  const selectedWell = useAppSelector((state) => state.state.selectedWell);
  const [realTimeDataState, setRealtimeData] = useStateQuery<RealTimeDataEnum>(URL_STATE_PARAM.REALTIME_DATA_DASHBOARD, RealTimeDataEnum.UNAVAILABLE);

  const comparisonWellsState = useAppSelector((state) => state.widgetOptions.comparisonWellInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // EXTRACTED FROM legacy lens fetcher as is, probably a lot could be improved here

    if (!detailed) return;
    const isActiveWell = (wellId: number) => wellInfo?.byId?.[wellId]?.status === "Active";
    const offsetWells: Array<number> = (comparisons ?? []).map(
      (well) => well.wellId,
    );
    if (
      wellInfo &&
      realTimeDataState !== RealTimeDataEnum.UNAVAILABLE &&
      JSON.stringify(offsetWells) !== JSON.stringify(prevOffset.current)
    ) {
      prevOffset.current = offsetWells;
      if ((selectedWell && isActiveWell(selectedWell)) || offsetWells.some((e) => isActiveWell(e))) {
        setRealtimeData(RealTimeDataEnum.ACTIVE);
      } else {
        setRealtimeData(RealTimeDataEnum.UNAVAILABLE);
      }
    }

    const comparisonWells: {
      [id: number]: ResultDataState;
    } = (() => {
      if (comparisons)
        return comparisons.reduce<{ [id: number]: ResultDataState }>(
          (accumulator: { [id: number]: ResultDataState }, current) => {
            accumulator[current.wellId] = current.dataState;
            return accumulator;
          },
          {},
        );
      if (selectedWell) return { [selectedWell]: dataState };
      return {};
    })();

    if (!isEqual(comparisonWellsState, comparisonWells)) {
      dispatch({
        type: "SET_COMPARISON_WELL_INFO",
        payload: comparisonWells,
      });
    }
  }, [
    comparisonWellsState,
    comparisons,
    dataState,
    detailed,
    dispatch,
    realTimeDataState,
    selectedWell,
    setRealtimeData,
    wellInfo,
  ]);
}
