/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ResultDataState = {
    NoData: 'NoData',
    Valid: 'Valid'
} as const;
export type ResultDataState = typeof ResultDataState[keyof typeof ResultDataState];


export function instanceOfResultDataState(value: any): boolean {
    for (const key in ResultDataState) {
        if (Object.prototype.hasOwnProperty.call(ResultDataState, key)) {
            if (ResultDataState[key as keyof typeof ResultDataState] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ResultDataStateFromJSON(json: any): ResultDataState {
    return ResultDataStateFromJSONTyped(json, false);
}

export function ResultDataStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultDataState {
    return json as ResultDataState;
}

export function ResultDataStateToJSON(value?: ResultDataState | null): any {
    return value as any;
}

