/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GeneratorSlotType } from './GeneratorSlotType';
import {
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeFromJSONTyped,
    GeneratorSlotTypeToJSON,
} from './GeneratorSlotType';
import type { FuelType } from './FuelType';
import {
    FuelTypeFromJSON,
    FuelTypeFromJSONTyped,
    FuelTypeToJSON,
} from './FuelType';

/**
 * 
 * @export
 * @interface GeneratorStatsFactDto
 */
export interface GeneratorStatsFactDto {
    /**
     * 
     * @type {GeneratorSlotType}
     * @memberof GeneratorStatsFactDto
     */
    generator: GeneratorSlotType;
    /**
     * 
     * @type {string}
     * @memberof GeneratorStatsFactDto
     */
    generatorModel?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatsFactDto
     */
    rating?: number | null;
    /**
     * 
     * @type {FuelType}
     * @memberof GeneratorStatsFactDto
     */
    fuelType: FuelType;
}



/**
 * Check if a given object implements the GeneratorStatsFactDto interface.
 */
export function instanceOfGeneratorStatsFactDto(value: object): value is GeneratorStatsFactDto {
    if (!('generator' in value) || value['generator'] === undefined) return false;
    if (!('fuelType' in value) || value['fuelType'] === undefined) return false;
    return true;
}

export function GeneratorStatsFactDtoFromJSON(json: any): GeneratorStatsFactDto {
    return GeneratorStatsFactDtoFromJSONTyped(json, false);
}

export function GeneratorStatsFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorStatsFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'generator': GeneratorSlotTypeFromJSON(json['Generator']),
        'generatorModel': json['GeneratorModel'] == null ? undefined : json['GeneratorModel'],
        'rating': json['Rating'] == null ? undefined : json['Rating'],
        'fuelType': FuelTypeFromJSON(json['FuelType']),
    };
}

export function GeneratorStatsFactDtoToJSON(value?: GeneratorStatsFactDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Generator': GeneratorSlotTypeToJSON(value['generator']),
        'GeneratorModel': value['generatorModel'],
        'Rating': value['rating'],
        'FuelType': FuelTypeToJSON(value['fuelType']),
    };
}

