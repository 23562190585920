import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ScorecardKpiCommentExceptionInfoDto } from "apis/oag";
import { ScorecardKpiCommentsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const scorecardCommentsApi = new ScorecardKpiCommentsApi(apiConfig);

export const TasksCountQueryKey: PDUniqueQueryKey = { uid: RequestUID.tasksCount };
export const TasksPendingQueryKey: PDUniqueQueryKey = { uid: RequestUID.pendingTasks };

export function useTasksCount(options?: Partial<UseSuspenseQueryOptions<number, Error>>) {
  return useSuspenseQuery({
    queryKey: [TasksCountQueryKey],
    queryFn: () => scorecardCommentsApi.apiScorecardKpiCommentsExceptionsPendingCountGet(),
    ...options,
  });
}

export function useTasks(options?: Partial<UseSuspenseQueryOptions<ScorecardKpiCommentExceptionInfoDto[], Error>>) {
  return useSuspenseQuery({
    queryKey: [TasksPendingQueryKey],
    queryFn: () => scorecardCommentsApi.apiScorecardKpiCommentsExceptionsPendingGet(),
    ...options,
  });
}
