import { useQuery } from "@tanstack/react-query";
import type { UserRigAccessDto } from "apis/oag";
import { UsersApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const users = new UsersApi(apiConfig);

const queryKey: PDUniqueQueryKey = { uid: RequestUID.userWellAccess };

export function useCurrentWellAccess() {
  return useQuery<UserRigAccessDto[]>({
    queryKey: [queryKey],
    queryFn: () => users.apiUsersMeWellAccessGet(),
  });
}
