/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftType } from './ShiftType';
import {
    ShiftTypeFromJSON,
    ShiftTypeFromJSONTyped,
    ShiftTypeToJSON,
} from './ShiftType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface StandDrillingInfoDto
 */
export interface StandDrillingInfoDto {
    /**
     * 
     * @type {number}
     * @memberof StandDrillingInfoDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StandDrillingInfoDto
     */
    standNumber: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StandDrillingInfoDto
     */
    startDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof StandDrillingInfoDto
     */
    endDate: DateDto;
    /**
     * 
     * @type {number}
     * @memberof StandDrillingInfoDto
     */
    startBitDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandDrillingInfoDto
     */
    endBitDepth?: number | null;
    /**
     * 
     * @type {ShiftType}
     * @memberof StandDrillingInfoDto
     */
    shift: ShiftType;
    /**
     * 
     * @type {boolean}
     * @memberof StandDrillingInfoDto
     */
    isAlpha: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandDrillingInfoDto
     */
    isOutlier: boolean;
    /**
     * 
     * @type {number}
     * @memberof StandDrillingInfoDto
     */
    readonly duration: number;
    /**
     * 
     * @type {number}
     * @memberof StandDrillingInfoDto
     */
    readonly distance: number;
}



/**
 * Check if a given object implements the StandDrillingInfoDto interface.
 */
export function instanceOfStandDrillingInfoDto(value: object): value is StandDrillingInfoDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('standNumber' in value) || value['standNumber'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    if (!('shift' in value) || value['shift'] === undefined) return false;
    if (!('isAlpha' in value) || value['isAlpha'] === undefined) return false;
    if (!('isOutlier' in value) || value['isOutlier'] === undefined) return false;
    if (!('duration' in value) || value['duration'] === undefined) return false;
    if (!('distance' in value) || value['distance'] === undefined) return false;
    return true;
}

export function StandDrillingInfoDtoFromJSON(json: any): StandDrillingInfoDto {
    return StandDrillingInfoDtoFromJSONTyped(json, false);
}

export function StandDrillingInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandDrillingInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'standNumber': json['StandNumber'],
        'startDate': DateDtoFromJSON(json['StartDate']),
        'endDate': DateDtoFromJSON(json['EndDate']),
        'startBitDepth': json['StartBitDepth'] == null ? undefined : json['StartBitDepth'],
        'endBitDepth': json['EndBitDepth'] == null ? undefined : json['EndBitDepth'],
        'shift': ShiftTypeFromJSON(json['Shift']),
        'isAlpha': json['IsAlpha'],
        'isOutlier': json['IsOutlier'],
        'duration': json['Duration'],
        'distance': json['Distance'],
    };
}

export function StandDrillingInfoDtoToJSON(value?: Omit<StandDrillingInfoDto, 'Duration'|'Distance'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'StandNumber': value['standNumber'],
        'StartDate': DateDtoToJSON(value['startDate']),
        'EndDate': DateDtoToJSON(value['endDate']),
        'StartBitDepth': value['startBitDepth'],
        'EndBitDepth': value['endBitDepth'],
        'Shift': ShiftTypeToJSON(value['shift']),
        'IsAlpha': value['isAlpha'],
        'IsOutlier': value['isOutlier'],
    };
}

