import styled from "styled-components";
import { zIndexLayer } from "utils/zIndex";

export const StyledResizeHandle = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: calc(100% - 32px);
  border-radius: 2px 2px 0 0;
  box-shadow: 0 0.1px 3px -1px gray;
  bottom: 16px;
  background: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
  z-index: ${zIndexLayer.above};
  cursor: row-resize;
  font-size: 12px;
`;
