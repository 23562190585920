/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlanTimelineEventDto } from './PlanTimelineEventDto';
import {
    PlanTimelineEventDtoFromJSON,
    PlanTimelineEventDtoFromJSONTyped,
    PlanTimelineEventDtoToJSON,
} from './PlanTimelineEventDto';
import type { TimelineSegmentDto } from './TimelineSegmentDto';
import {
    TimelineSegmentDtoFromJSON,
    TimelineSegmentDtoFromJSONTyped,
    TimelineSegmentDtoToJSON,
} from './TimelineSegmentDto';

/**
 * 
 * @export
 * @interface PlanTimelineDto
 */
export interface PlanTimelineDto {
    /**
     * 
     * @type {Array<TimelineSegmentDto>}
     * @memberof PlanTimelineDto
     */
    sections?: Array<TimelineSegmentDto> | null;
    /**
     * 
     * @type {Array<TimelineSegmentDto>}
     * @memberof PlanTimelineDto
     */
    phases?: Array<TimelineSegmentDto> | null;
    /**
     * 
     * @type {Array<PlanTimelineEventDto>}
     * @memberof PlanTimelineDto
     */
    events?: Array<PlanTimelineEventDto> | null;
}

/**
 * Check if a given object implements the PlanTimelineDto interface.
 */
export function instanceOfPlanTimelineDto(value: object): value is PlanTimelineDto {
    return true;
}

export function PlanTimelineDtoFromJSON(json: any): PlanTimelineDto {
    return PlanTimelineDtoFromJSONTyped(json, false);
}

export function PlanTimelineDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanTimelineDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sections': json['Sections'] == null ? undefined : ((json['Sections'] as Array<any>).map(TimelineSegmentDtoFromJSON)),
        'phases': json['Phases'] == null ? undefined : ((json['Phases'] as Array<any>).map(TimelineSegmentDtoFromJSON)),
        'events': json['Events'] == null ? undefined : ((json['Events'] as Array<any>).map(PlanTimelineEventDtoFromJSON)),
    };
}

export function PlanTimelineDtoToJSON(value?: PlanTimelineDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Sections': value['sections'] == null ? undefined : ((value['sections'] as Array<any>).map(TimelineSegmentDtoToJSON)),
        'Phases': value['phases'] == null ? undefined : ((value['phases'] as Array<any>).map(TimelineSegmentDtoToJSON)),
        'Events': value['events'] == null ? undefined : ((value['events'] as Array<any>).map(PlanTimelineEventDtoToJSON)),
    };
}

