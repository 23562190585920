import type { ResultDataState, VisualAidType } from "apis/oag";
import { DisplayOption } from "apis/oag";

import type { ReportState } from "./stateReducer";
import type { IAction, IZoomData } from "./types";

// TODO do we need this?
export enum IIndicators {
  Formations = "Formations",
  HoleSections = "HoleSections",
  DirectionalIntervals = "DirectionalIntervals",
}

export const comparisonDisplayOptions: DisplayOption[] = [
  DisplayOption.Well,
  DisplayOption.Shift,
  DisplayOption.DirectionInterval,
  DisplayOption.HoleSection,
];

export const singleWellDisplayOptions: DisplayOption[] = [
  DisplayOption.StandDepth,
  DisplayOption.DateShift,
  DisplayOption.Shift,
];

const defaultComparisonDisplayOption = DisplayOption.Well;
const defaultSingleWellDisplayOption = DisplayOption.StandDepth;

export interface IWidgetState {
  comparisonWellInfo: { [id: number]: ResultDataState };
  display_options: DisplayOption;
  indicators: Array<IIndicators>;
  legendVisible: boolean;
  offsetWells: Array<number>;
  outliers: boolean;
  visualAids: Array<VisualAidType>;
}

export const initialState: IWidgetState = {
  comparisonWellInfo: [],
  display_options: DisplayOption.StandDepth,
  indicators: [],
  legendVisible: false,
  offsetWells: [],
  outliers: false,
  visualAids: [],
};

interface ISetDisplayOptionsAction extends IAction {
  type: "SET_DISPLAY_OPTIONS_WIDGET";
  payload: {
    display_options: IWidgetState["display_options"];
  };
}

interface ISetComparisonWellInfoAction extends IAction {
  type: "SET_COMPARISON_WELL_INFO";
  payload: IWidgetState["comparisonWellInfo"];
}

interface ISetComparisonAction extends IAction {
  type: "SET_OFFSET_WELLS_WIDGET";
  payload: {
    wells: IWidgetState["offsetWells"];
  };
}

interface ISetIndicatorsAction extends IAction {
  type: "SET_INDICATORS_WIDGET";
  payload: {
    indicators: IWidgetState["indicators"];
  };
}

interface ISetLegendVisibleAction extends IAction {
  type: "SET_LEGEND_VISIBLE_WIDGET";
  payload: {
    legendVisible: IWidgetState["legendVisible"];
  };
}

interface IResetZoomWidgetAction extends IAction {
  type: "RESET_ZOOM_WIDGET";
  payload?: null;
}

interface ISetZoomWidgetAction extends IAction {
  type: "SET_ZOOM_WIDGET";
  payload?: {
    zoom: IZoomData;
    state: boolean;
  };
}

interface IRawWidgetState extends IAction {
  type: "RAW_WIDGET_STATE_SET";
  payload: IWidgetState | (IWidgetState & ReportState);
}

type AvailableActions =
  | IRawWidgetState
  | IResetZoomWidgetAction
  | ISetZoomWidgetAction
  | ISetComparisonAction
  | ISetComparisonWellInfoAction
  | ISetDisplayOptionsAction
  | ISetIndicatorsAction
  | ISetLegendVisibleAction;

function widgetReducer(state: IWidgetState = initialState, action: AvailableActions): IWidgetState {
  const isComparing = (offsetWells: Array<number>) => offsetWells?.length > 0;
  switch (action.type) {
    case "SET_COMPARISON_WELL_INFO":
      return {
        ...state,
        comparisonWellInfo: action.payload,
      };
    case "SET_DISPLAY_OPTIONS_WIDGET":
      if (isComparing(state.offsetWells) && !comparisonDisplayOptions.includes(action.payload.display_options))
        return {
          ...state,
          display_options: defaultComparisonDisplayOption,
        };
      if (!isComparing(state.offsetWells) && !singleWellDisplayOptions.includes(action.payload.display_options))
        return {
          ...state,
          display_options: defaultSingleWellDisplayOption,
        };
      return {
        ...state,
        display_options: action.payload.display_options,
      };
    case "SET_OFFSET_WELLS_WIDGET":
      const newState = {
        ...state,
        offsetWells: action.payload.wells,
      };
      if (isComparing(action.payload.wells)) {
        newState["display_options"] = comparisonDisplayOptions.includes(state.display_options)
          ? state.display_options
          : defaultComparisonDisplayOption;
      } else {
        newState["display_options"] = singleWellDisplayOptions.includes(state.display_options)
          ? state.display_options
          : defaultSingleWellDisplayOption;
      }
      return newState;
    case "SET_INDICATORS_WIDGET":
      return {
        ...state,
        indicators: action.payload.indicators,
      };
    case "SET_LEGEND_VISIBLE_WIDGET":
      return {
        ...state,
        legendVisible: action.payload.legendVisible,
      };
    case "RAW_WIDGET_STATE_SET":
      return {
        ...state,
        ...action.payload,
        indicators: action.payload.indicators ?? state.indicators,
        display_options: (action.payload.display_options?.[0] as DisplayOption) ?? state.display_options,
      };
    default:
      return state;
  }
}

export default widgetReducer;
