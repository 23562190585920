import { useQuery } from "@tanstack/react-query";
import type { DateDto, ListWellQueryDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { useLinkedFilters } from "components/RigsHeader/useLinkedFilters";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto, last30Days, today } from "utils/helper";
import type { PDWellSummariesQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";
import { useDashboardType } from "./useDashboardType";

const wells = new WellsApi(apiConfig);

export function useWellSummaries(isRigs?: boolean) {
  const { isEvergreen } = useDashboardType();
  const reduxState = isEvergreen ? "evergreenWells" : "allWells";
  const searchState = useAppSelector((state) => state[reduxState].search);
  const statusState = useAppSelector((state) => state[reduxState].status);
  const startDateState = useAppSelector((state) => state[reduxState].startDate);
  const endDateState = useAppSelector((state) => state[reduxState].endDate);

  const {
    selectedValues,
    options,
    isLoading
  } = useLinkedFilters({
    keepEvengreenOnly: isEvergreen && !isRigs,
    storeName: (() => {
      if (isRigs) return "rigsCommon";
      if (isEvergreen) return "evergreenWells";
      return "allWells";
    })(),
  })

  const [period] = useStateQuery<RangeType>(URL_STATE_PARAM.PERIOD_RIG_WIDGET, {
    startDate: last30Days,
    endDate: today,
  });

  const dispatch = useAppDispatch();

  const startDate = useMemo(() => {
    if (isRigs) {
      return period.startDate;
    }
    return startDateState || defaultDateDto.from.utc;
  }, [isRigs, period.startDate, startDateState]);

  const endDate = useMemo(() => {
    if (isRigs) {
      return period.endDate;
    }
    return endDateState || defaultDateDto.to.utc;
  }, [isRigs, period.endDate, endDateState]);

  const filterState = useAppSelector((state) => state[reduxState].filters);

  const requestQuery: ListWellQueryDto = useMemo(() => {
    return {
      search: isRigs ? "" : searchState,
      operatorIds: selectedValues.operators === null ? null : options?.operators.map((op) => op.id),
      rigIds: selectedValues.rigs === null ? null : options?.rigs.map((rig) => rig.id),
      from: dateToDateOnlyDto(startDate || defaultDateDto.from.utc),
      to: dateToDateOnlyDto(endDate || defaultDateDto.to.utc),
      includeEverGreenOnly: isEvergreen,
      formationIds: selectedValues.formations === null ? null : options?.formations.map((f) => f.id),
      selectedFilters: {
        includeFlatTime: true,
        includeRotatingTime: true,
        includeSlidingTime: true,
        includeNullHoleDepth: true,
        selectedWellStatusTypes: isRigs ? null : statusState,
        ...(filterState
          ? {
            includeAlphaRigs: !!filterState?.technology.map((tech) => tech.id).includes(RigTechnology.Alpha),
            includeNoneAlphaRigs: !!filterState?.technology.map((tech) => tech.id).includes(RigTechnology.NonAlpha),
            selectedClasses: filterState?.type.map((t) => (t.id || -1).toString()),
            selectedCountryIds: filterState?.country.map((c) => +(c.id || -1)),
            selectedHorsePowers: filterState?.horsePower.map((hp) => +(hp.id || -1)),
            selectedOperatingCenters: filterState?.operatingCenter.map((oc) => (oc.id || -1).toString()),
          }
          : {
            includeAlphaRigs: true,
            includeNoneAlphaRigs: true,
          }),
      },
    };
  }, [
    isRigs,
    searchState,
    selectedValues.operators,
    selectedValues.rigs,
    selectedValues.formations,
    options?.operators,
    options?.rigs,
    options?.formations,
    startDate,
    endDate,
    isEvergreen,
    statusState,
    filterState
  ]);


  const queryKey: PDWellSummariesQueryKey = {
    type: PDQueryType.WELL_SUMMARIES,
    uid: RequestUID.wellSummaries,
    query: requestQuery,
  };

  const data = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      return wells.apiWellsSummariesPut({
        listWellQueryDto: requestQuery,
      });
    },
    enabled: Object.keys(isLoading).every((key) => {
      if (key === "wells") return true;
      return !isLoading[key as keyof typeof isLoading];
    }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (data.isFetched) {
      const wells = data?.data?.wells || [];
      if (wells) {
        const lastUpdatedAtDate = wells.reduce((acc: DateDto | null, well) => {
          const freshDate = isRigs ? well.lastRigStateFactUpdateAt : well.lastWellFactUpdateAt;
          if (!freshDate) return acc;
          const date1 = new Date(freshDate.utc).getTime();
          const date2 = acc ? new Date(acc.utc).getTime() : null;
          if (!date2) return freshDate;
          if (date1 > date2) return freshDate;
          return acc;
        }, null);
        dispatch({
          type: (() => {
            if (isRigs) return "SET_LAST_REFRESH_DATE";
            if (isEvergreen) return "EVERGREEN_WELLS_SET_LAST_UPDATE_DATE";
            return "ALL_WELLS_SET_LAST_UPDATE_DATE";
          })(),
          payload: lastUpdatedAtDate,
        });
      }
    }
  }, [data, dispatch, isEvergreen, isRigs]);
  return data;
}
