import { ScoreBenchmarkProvider } from "pages/RigScoreCard/ScoreBenchmarkContext";

import RigScoreCardInner from "./RigScoreCardPage";

const RigScoreCard = () => {
  return (
    <ScoreBenchmarkProvider>
      <RigScoreCardInner />
    </ScoreBenchmarkProvider>
  );
};

export default RigScoreCard;
