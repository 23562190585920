import { DashboardType } from "apis/oag";
import { capitalize } from "lodash";
import { useLocation, useParams } from "react-router-dom";
import { base } from "routePaths";
import { checkDashboardType } from "utils/helper";
const numberRegex = /^-?\d+$/;

export function useDashboardType() {
  const location = useLocation();

  const { dashboardType: dashboardTypeFromParam } = useParams<{
    dashboardType: string;
  }>();

  const dashboardTypeFromUrl = checkDashboardType(location.pathname.replaceAll("/", ""));

  let dashboardType = dashboardTypeFromUrl ?? (capitalize(dashboardTypeFromParam) as DashboardType);

  if (!dashboardType) {
    dashboardType = DashboardType.Well;
  }

  const matchesEvergreenDashboard = location.pathname.startsWith(base.evergreen) && numberRegex.test(location.pathname.split("/")[2]);
  const matchesRigDashboard = location.pathname.startsWith(base.rig) && numberRegex.test(location.pathname.split("/")[2]);
  const matchesWellDashboard = location.pathname.startsWith(base.well) && numberRegex.test(location.pathname.split("/")[2]);
  return {
    dashboardType,
    isEvergreen: dashboardType === DashboardType.EverGreen,
    isRig: dashboardType === DashboardType.Rig,
    isWell: dashboardType === DashboardType.Well,
    isRootDashboardPage: !!matchesEvergreenDashboard || !!matchesRigDashboard || !!matchesWellDashboard,
  };
}
