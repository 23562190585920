import { useQuery } from "@tanstack/react-query";
import { UsersApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const users = new UsersApi(apiConfig);
const queryKey: PDUniqueQueryKey = { uid: RequestUID.userWhoAmI };

export function useCurrentUser(enabled: boolean = true) {
  return useQuery({
    queryKey: [queryKey],
    queryFn: () => users.apiUsersMeGet(),
    enabled,
    staleTime: Infinity,
    gcTime: Infinity
  });
}
