import { PDComponent } from "components/PDComponents";
import styled from "styled-components";

export const DropdownButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.themeStyle.colors.tab_edit_button};

  height: 64px;
  width: 36px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  &.ant-btn-icon-only.ant-btn-lg {
    padding: 0px 10px;
    align-items: center;
    height: 64px;
    width: 36px;
  }
`;

export const Chevron = styled(PDComponent.SvgIcon).attrs({ name: "chevronDown" })<{ isOpen: boolean }>`
  width: 10px;
  height: 10px;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  font-size: 10px !important;
  line-height: 16px !important;
  margin-left: 0 !important;
`;

export const TabOperationChoiceModal = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
`;

export const ModalRow = styled.div`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  padding: 10px 10px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  gap: 5px;
  display: flex;
  &:hover {
    background-color: ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};
  }
`;

export const SendIcon = styled(PDComponent.SvgIcon).attrs({ name: "sendFilled" })`
  transform: rotate(-45deg);
`;
