import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiPowerLoadEfficiencyUserLensesIdFactsPutRequest,
  GeneratorSlotType,
  PowerLoadEfficiencyResultDto,
  PowerLoadEfficiencyUserLensDto,
  UserLensDto,
} from "apis/oag";
import { PowerLoadEfficiencyUserLensesApi } from "apis/oag";
import { allGenerators } from "components/Lenses/utils";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useFilterParams } from "hooks/useFilterParams";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useCallback } from "react";
import { useAppDispatch } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateDto } from "utils/helper";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const powerLoadEfficiencyUserLensesApi = new PowerLoadEfficiencyUserLensesApi(apiConfig);

export function usePowerLoadEfficiencyFetcher(
  lens: PowerLoadEfficiencyUserLensDto,
  options?: UseQueryOptions<PowerLoadEfficiencyResultDto>,
) {
  const filterParams = useFilterParams();
  const crtWellId = useSelectedWell();
  const dispatch = useAppDispatch();
  const requestParameters: ApiPowerLoadEfficiencyUserLensesIdFactsPutRequest = {
    id: lens.id ?? -1,
    powerLoadEfficiencyQueryDto: {
      ...filterParams,
      selectedGenerators: lens.selectedGenerators ? lens.selectedGenerators : allGenerators,
      selectedOperationClassIds: lens.selectedOperationClassId ? [lens.selectedOperationClassId] : null,
    },
  };

  const [zoomData] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WELL, initialZoomData, [
    URL_STATE_PARAM.ZOOM_WIDGET,
  ]);
  let requestParametersWithZoom = requestParameters;
  if (zoomData.date_start && zoomData.type === IZoomType.DATE) {
    requestParametersWithZoom = {
      ...requestParameters,
      powerLoadEfficiencyQueryDto: requestParameters.powerLoadEfficiencyQueryDto
        ? {
            ...requestParameters.powerLoadEfficiencyQueryDto,
            from: dateToDateDto(zoomData.date_start) || defaultDateDto.from,
            to: dateToDateDto(zoomData.date_end) || defaultDateDto.to,
          }
        : undefined,
    };
  }

  const queryKey: PDLensFactsQueryKey<ApiPowerLoadEfficiencyUserLensesIdFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.powerLoadEfficiencyFacts,
    params: requestParametersWithZoom,
    lensId: lens.id,
  };

  const request = useSuspenseQuery<PowerLoadEfficiencyResultDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      powerLoadEfficiencyUserLensesApi
        .apiPowerLoadEfficiencyUserLensesIdFactsPut(requestParametersWithZoom, {
          signal,
        })
        .then((data) => {
          if (data?.lastUpdatedAt) {
            dispatch({
              type: "SET_POWER_MANAGEMENT_REFRESH_DATE",
              payload: {
                [crtWellId]: data?.lastUpdatedAt,
              },
            });
          }

          return data;
        }),
    ...options,
  });
  return request;
}

export function usePowerLoadEfficiencyLensUpdate(
  lens: PowerLoadEfficiencyUserLensDto,
  onLensUpdated?: (newItem: UserLensDto) => void,
) {
  const handleLensUpdate = useMutation({
    mutationFn: (lens: PowerLoadEfficiencyUserLensDto) => {
      return powerLoadEfficiencyUserLensesApi.apiPowerLoadEfficiencyUserLensesIdPut({
        id: lens.id,
        powerLoadEfficiencyUserLensDto: lens,
      });
    },

    onSettled: (lens) => {
      if (lens && onLensUpdated) {
        onLensUpdated(lens);
      }
    },
  });

  const handleGeneratorUpdate = useCallback(
    async (generators: GeneratorSlotType[]) => {
      await handleLensUpdate.mutateAsync({ ...lens, selectedGenerators: generators });
    },

    [handleLensUpdate, lens],
  );

  const handleOperationClassUpdate = useCallback(
    async (operator: number) => {
      await handleLensUpdate.mutateAsync({ ...lens, selectedOperationClassId: operator });
    },
    [handleLensUpdate, lens],
  );

  return { handleGeneratorUpdate, handleOperationClassUpdate };
}
