import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { OperatorClaimDto } from "apis/oag";
import { ClaimPermissionType, ClaimType, OperatorsApi } from "apis/oag";
import { Button } from "atoms/Form";
import { toast } from "atoms/toast";
import { Title } from "atoms/Typography";
import { OverviewHeaderContainer, StyledTabs } from "components/Layout/Tabbed";
import { Loader } from "components/Loader";
import { useOperators } from "hooks/useOperators";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "reducers/store";
import { useMixpanel } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { Col, Row, Space, Tabs } from "utils/componentLibrary";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import Users from "./Users";
import WellAccess from "./WellAccess";

const ops = new OperatorsApi(apiConfig);

const UsersPage = () => {
  const navigate = useNavigate();
  const { accountId } = useParams<{ accountId: string }>();
  const operators = useOperators();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const [selectedRoute, setSelectedRoute] = useState("wells");
  const params = useParams<{ accountId: string; tabId?: string }>();
  useEffect(() => {
    if (params.tabId) {
      setSelectedRoute(params.tabId);
    }
  }, [params.tabId]);
  const { atomThemeVariant } = useCustomTheme();
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();
  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("Accounts Editor", {
        "Account ID": params.accountId,
        Tab: params.tabId && `${params.tabId[0].toLocaleUpperCase()}${params.tabId.slice(1)}`,
      });
    }
  }, [viewPage, params, accountId]);

  const tempClaims = useRef<Partial<OperatorClaimDto>[]>([]);

  useEffect(() => {
    dispatch({
      type: "ADMIN_WELL_ACCESS_RESET",
      payload: null,
    });
    dispatch({
      type: "ADMIN_SINGLE_ACCOUNT_RESET",
      payload: null,
    });
  }, [dispatch]);

  const tabs = [
    {
      key: "wells",
      path: "wells",
      children: (
        <WellAccess
          accountId={Number(accountId)}
          allClaimsCallback={useCallback((operatorClaims) => {
            tempClaims.current = operatorClaims;
          }, [])}
        />
      ),
      label: "Well Access",
    },
    {
      key: "users",
      path: "users",
      children: <Users />,
      label: "Users",
    },
  ];

  const updateClaims = useMutation({
    mutationFn: () => {
      return ops.apiOperatorsIdClaimsPut({
        id: Number(accountId),
        operatorClaimsQueryDto: {
          claims: [...tempClaims.current]
            .filter(
              (value, index, arr) =>
                (value.wellId === undefined || index === arr.findIndex((val) => val.wellId === value.wellId)) &&
                value.permission === ClaimPermissionType.All,
            )
            .map((claim) =>
              claim.type === ClaimType.FutureWellAccess
                ? {
                  id: claim.id!,
                  type: claim.type,
                  permission: claim.permission!,
                  operatorId: claim.operatorId!,
                  rigId: claim.rigId,
                }
                : {
                  id: claim.id!,
                  type: claim.type!,
                  permission: claim.permission!,
                  operatorId: claim.operatorId!,
                  wellId: claim.wellId,
                },
            ),
        },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.ADMIN_ACCOUNTS_WELL_ACCESS }], exact: false });
    },
    onSuccess: () => {
      toast.success({ message: "Well Access has been updated!" });
    },
    onError: () => {
      toast.error({ message: "Could not update Well Access!" });
    },
  });

  return (
    <Row>
      <Col span={24}>
        <OverviewHeaderContainer>
          <Row justify="space-between" align="middle" style={{ height: "80px" }}>
            <Col flex="0 auto">
              <Title level={3} variant={atomThemeVariant} weight={500} style={{ margin: "0 12px" }}>
                {operators.isLoading ? "Loading..." : operators.data?.byId[+(accountId || 0)]?.name}
              </Title>
            </Col>
            <Col flex="0 auto">
              <Space>
                <Button onClick={() => navigate("/admin/accounts")}>Cancel</Button>
                <Button onClick={() => updateClaims.mutate()} loading={updateClaims.isPending} type="primary">
                  Update Access
                </Button>
              </Space>
            </Col>
          </Row>
          <StyledTabs
            type="card"
            tabBarStyle={{ margin: 0 }}
            tabBarGutter={6}
            activeKey={params.tabId}
            onTabClick={(key) => navigate(`/admin/accounts/${params.accountId}/${key}`)}
            items={tabs}
          />
        </OverviewHeaderContainer>
      </Col>
      <Col flex="1 auto">
        <Suspense fallback={<Loader centered />}>{tabs.find((tab) => tab.path === selectedRoute)?.children}</Suspense>
      </Col>
    </Row>
  );
};

export default UsersPage;
