import { UserLensTabsApi } from "apis/oag";
import { useEffect, useState } from "react";
import { apiConfig } from "utils/apiConfig";

import { TAB_NAME_MAX_LENGTH } from "./AddNewTabModal";
import { StyledInputEdit } from "./style";

const userLensTabsApi = new UserLensTabsApi(apiConfig);

export const InputEdit = ({
  localTabName,
  tabId,
  setTabNameCB,
}: {
  localTabName: string;
  tabId: number;
  setTabNameCB: (newName: string) => void;
}) => {
  const [tabName, setTabName] = useState(localTabName ?? "");

  useEffect(() => {
    if (localTabName) setTabName(localTabName);
  }, [localTabName]);
  return (
    <StyledInputEdit
      value={tabName}
      onChange={(e) => {
        setTabName(e.currentTarget.value);
      }}
      autoFocus
      maxLength={TAB_NAME_MAX_LENGTH}
      onBlur={() => {
        if (tabName === localTabName) {
          setTabNameCB(localTabName);
          return;
        }
        if (tabName === "") {
          setTabNameCB(localTabName);
          setTabName(localTabName);
          return;
        }

        userLensTabsApi
          .apiUserLensTabsIdRenameNewNamePut({
            id: tabId,
            newName: tabName,
          })
          .then((newName) => {
            setTabNameCB(newName);
          });
      }}
    />
  );
};
