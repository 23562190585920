/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VisualAidType } from './VisualAidType';
import {
    VisualAidTypeFromJSON,
    VisualAidTypeFromJSONTyped,
    VisualAidTypeToJSON,
} from './VisualAidType';
import type { StandKpiGroup } from './StandKpiGroup';
import {
    StandKpiGroupFromJSON,
    StandKpiGroupFromJSONTyped,
    StandKpiGroupToJSON,
} from './StandKpiGroup';
import type { TimeUnit } from './TimeUnit';
import {
    TimeUnitFromJSON,
    TimeUnitFromJSONTyped,
    TimeUnitToJSON,
} from './TimeUnit';
import type { OutlierFlaggingType } from './OutlierFlaggingType';
import {
    OutlierFlaggingTypeFromJSON,
    OutlierFlaggingTypeFromJSONTyped,
    OutlierFlaggingTypeToJSON,
} from './OutlierFlaggingType';
import type { PivotType } from './PivotType';
import {
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import type { StackingType } from './StackingType';
import {
    StackingTypeFromJSON,
    StackingTypeFromJSONTyped,
    StackingTypeToJSON,
} from './StackingType';

/**
 * 
 * @export
 * @interface PivotKpiGroupUserLensDto
 */
export interface PivotKpiGroupUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {TimeUnit}
     * @memberof PivotKpiGroupUserLensDto
     */
    selectedTimeUnit: TimeUnit;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    isManualYaxis: boolean;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    yaxisStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    yaxisEnd?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    showsOutliers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiGroupUserLensDto
     */
    showOperationCount: boolean;
    /**
     * 
     * @type {OutlierFlaggingType}
     * @memberof PivotKpiGroupUserLensDto
     */
    outlierFlaggingType: OutlierFlaggingType;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    outlierMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiGroupUserLensDto
     */
    outlierMax?: number | null;
    /**
     * 
     * @type {Array<VisualAidType>}
     * @memberof PivotKpiGroupUserLensDto
     */
    selectedVisualAids: Array<VisualAidType>;
    /**
     * 
     * @type {StackingType}
     * @memberof PivotKpiGroupUserLensDto
     */
    stackingType: StackingType;
    /**
     * 
     * @type {StandKpiGroup}
     * @memberof PivotKpiGroupUserLensDto
     */
    kpiGroupId: StandKpiGroup;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotKpiGroupUserLensDto
     */
    defaultPivotLevel1: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotKpiGroupUserLensDto
     */
    defaultPivotLevel2: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotKpiGroupUserLensDto
     */
    defaultPivotLevel3: PivotType;
}



/**
 * Check if a given object implements the PivotKpiGroupUserLensDto interface.
 */
export function instanceOfPivotKpiGroupUserLensDto(value: object): value is PivotKpiGroupUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('squeezesDisplay' in value) || value['squeezesDisplay'] === undefined) return false;
    if (!('selectedTimeUnit' in value) || value['selectedTimeUnit'] === undefined) return false;
    if (!('isManualYaxis' in value) || value['isManualYaxis'] === undefined) return false;
    if (!('showsOutliers' in value) || value['showsOutliers'] === undefined) return false;
    if (!('showOperationCount' in value) || value['showOperationCount'] === undefined) return false;
    if (!('outlierFlaggingType' in value) || value['outlierFlaggingType'] === undefined) return false;
    if (!('selectedVisualAids' in value) || value['selectedVisualAids'] === undefined) return false;
    if (!('stackingType' in value) || value['stackingType'] === undefined) return false;
    if (!('kpiGroupId' in value) || value['kpiGroupId'] === undefined) return false;
    if (!('defaultPivotLevel1' in value) || value['defaultPivotLevel1'] === undefined) return false;
    if (!('defaultPivotLevel2' in value) || value['defaultPivotLevel2'] === undefined) return false;
    if (!('defaultPivotLevel3' in value) || value['defaultPivotLevel3'] === undefined) return false;
    return true;
}

export function PivotKpiGroupUserLensDtoFromJSON(json: any): PivotKpiGroupUserLensDto {
    return PivotKpiGroupUserLensDtoFromJSONTyped(json, false);
}

export function PivotKpiGroupUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotKpiGroupUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedTimeUnit': TimeUnitFromJSON(json['SelectedTimeUnit']),
        'isManualYaxis': json['IsManualYaxis'],
        'yaxisStart': json['YaxisStart'] == null ? undefined : json['YaxisStart'],
        'yaxisEnd': json['YaxisEnd'] == null ? undefined : json['YaxisEnd'],
        'showsOutliers': json['ShowsOutliers'],
        'showOperationCount': json['ShowOperationCount'],
        'outlierFlaggingType': OutlierFlaggingTypeFromJSON(json['OutlierFlaggingType']),
        'outlierMin': json['OutlierMin'] == null ? undefined : json['OutlierMin'],
        'outlierMax': json['OutlierMax'] == null ? undefined : json['OutlierMax'],
        'selectedVisualAids': ((json['SelectedVisualAids'] as Array<any>).map(VisualAidTypeFromJSON)),
        'stackingType': StackingTypeFromJSON(json['StackingType']),
        'kpiGroupId': StandKpiGroupFromJSON(json['KpiGroupId']),
        'defaultPivotLevel1': PivotTypeFromJSON(json['DefaultPivotLevel1']),
        'defaultPivotLevel2': PivotTypeFromJSON(json['DefaultPivotLevel2']),
        'defaultPivotLevel3': PivotTypeFromJSON(json['DefaultPivotLevel3']),
    };
}

export function PivotKpiGroupUserLensDtoToJSON(value?: PivotKpiGroupUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'SqueezesDisplay': value['squeezesDisplay'],
        'SelectedTimeUnit': TimeUnitToJSON(value['selectedTimeUnit']),
        'IsManualYaxis': value['isManualYaxis'],
        'YaxisStart': value['yaxisStart'],
        'YaxisEnd': value['yaxisEnd'],
        'ShowsOutliers': value['showsOutliers'],
        'ShowOperationCount': value['showOperationCount'],
        'OutlierFlaggingType': OutlierFlaggingTypeToJSON(value['outlierFlaggingType']),
        'OutlierMin': value['outlierMin'],
        'OutlierMax': value['outlierMax'],
        'SelectedVisualAids': ((value['selectedVisualAids'] as Array<any>).map(VisualAidTypeToJSON)),
        'StackingType': StackingTypeToJSON(value['stackingType']),
        'KpiGroupId': StandKpiGroupToJSON(value['kpiGroupId']),
        'DefaultPivotLevel1': PivotTypeToJSON(value['defaultPivotLevel1']),
        'DefaultPivotLevel2': PivotTypeToJSON(value['defaultPivotLevel2']),
        'DefaultPivotLevel3': PivotTypeToJSON(value['defaultPivotLevel3']),
    };
}

