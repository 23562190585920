import styled from "styled-components";
import colors from "utils/colors";
import { Tag } from "utils/componentLibrary";

export const StyledFilterTag = styled(Tag)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 6px 3px 10px;
  gap: 2px;
  height: 22px;
  background: ${colors.pale_grey};
  border-radius: 16px;
  flex-grow: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;
