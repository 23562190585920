import type { StickSlipByDepthUserLensTrackItemDto } from "apis/oag";
import { TrackFactType } from "apis/oag";
import { useTracks } from "hooks/useTracks";
import { difference, indexOf } from "lodash";
import { useMemo } from "react";

export const useAvailableTrackIds = (selectedTrackItems: StickSlipByDepthUserLensTrackItemDto[]) => {
  const { data: tracks } = useTracks();

  const acceptableTracks = useMemo(() => {
    const acceptableTrackFactTypes: TrackFactType[] = [
      TrackFactType.Torque,
      TrackFactType.RevolutionsPerSecond,
      TrackFactType.DifferentialPressure,
      TrackFactType.WeightOnBit,
      TrackFactType.RateOfPenetration,
      TrackFactType.FlowIn,
      TrackFactType.StandpipePressure,
      TrackFactType.Sssi,
      TrackFactType.CumulativeSssi,
    ];

    return tracks
      ? tracks.list
          ?.filter((track) => (track?.factType ? acceptableTrackFactTypes.includes(track?.factType) : false))
          .sort((a, b) => indexOf(acceptableTrackFactTypes, a.factType) - indexOf(acceptableTrackFactTypes, b.factType))
          .map((track) => track.id)
      : [];
  }, [tracks]);

  return difference(
    acceptableTracks ?? [],
    selectedTrackItems.map((item) => item.trackId ?? -1),
  );
};
