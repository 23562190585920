import { useIsFetching } from "@tanstack/react-query";
import type { StandKpiDetailsDto } from "apis/oag";
import { ZoomChartContext, ZoomState } from "components/Lenses/common/LensZoom/ZoomChartContext";
import type { Domain } from "components/Lenses/ContainerLens/StickSlipByTime";
import * as Styled from "components/Lenses/ContainerLens/StickSlipByTime/styles";
import { PDComponent } from "components/PDComponents";
import type { StandData } from "hooks/useWellInspectionDetails";
import { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import colors from "utils/colors";
import type { UOMHelper } from "utils/format";
import { RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

import { InspectionViewInner } from "./InspectionViewInner";
import type { Curve } from "./utils";
import { MODAL_HEIGHT_PX, MODAL_TOP_PADDING, MODAL_VERTICAL_PADDING, MODAL_WIDTH_PX } from "./utils";

const customStyles = {
  overlay: {
    zIndex: zIndexLayer.phobos,
    backgroundColor: "rgba(32,43,48,0.6)",
  },
  content: {
    background: colors.transparent,
    top: MODAL_TOP_PADDING,
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0px!important",
    transform: "translate(-50%, 0%)",
    minHeight: MODAL_HEIGHT_PX,
    width: MODAL_WIDTH_PX + 10,
    border: "none",
  },
};

const scrollableModalContent = {
  ...customStyles.content,
  top: MODAL_VERTICAL_PADDING,
};

export interface InspectionViewProps {
  onCancel: (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
  selectedStand: StandData;
  isVisible: boolean;
  lensTitle: string;
  totalDisplay: string;
  valueUOM: UOMHelper;
  lensId: number;
  showDrillerComments: boolean;
  standList: StandKpiDetailsDto[];
  curvesGroup: Curve[][];
  ControlButtons?: (() => JSX.Element) | null;
  inspectionRef?: (node: HTMLDivElement | null) => void;
}

export interface LegendItem {
  id: string;
  label: string;
  color: string;
  isVisible: boolean;
}

export const InspectionView: React.FC<InspectionViewProps> = (props) => {
  const { isVisible, onCancel } = props;
  const initialDomain: Domain = useMemo(() => [0, 0], []);
  const [domain, setDomain] = useState<Domain | null>(initialDomain);
  const [zoomState, setZoomState] = useState(ZoomState.Zooming);
  const [currentDomain, setCurrentDomain] = useState<Domain | null>([0, 0]);
  const { themeStyle } = useCustomTheme();

  // Change is fetching
  const isFetchingData = useIsFetching({ queryKey: [{ uid: RequestUID.StickSlipByTime }] });

  useEffect(() => {
    if (!isFetchingData) setDomain(initialDomain);
  }, [initialDomain, isFetchingData]);

  const resetZoom = useCallback(() => {
    setDomain(initialDomain);
    setCurrentDomain([0, 0]);
    setZoomState(ZoomState.Zooming);
  }, [initialDomain, setDomain, setZoomState]);

  useEffect(() => {
    if (currentDomain?.some((domainPoint) => domainPoint !== 0)) {
      if (currentDomain[0] !== currentDomain[1]) {
        setDomain(currentDomain);
      }
    }
  }, [currentDomain]);
  const [modalInnerHeight, setModalInnerHeight] = useState(0);

  const inspectionRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      const resizeObserver = new ResizeObserver(() => {
        setModalInnerHeight(node.getBoundingClientRect().height);
      });
      resizeObserver.observe(node);

      setModalInnerHeight(node.getBoundingClientRect().height);
      return () => resizeObserver.disconnect();
    }
  }, []);

  const ControlButtons = useCallback(
    () => (
      <Styled.ButtonContainer>
        <Styled.Button onClick={resetZoom}>
          <PDComponent.SvgIcon name="reset" />
        </Styled.Button>
      </Styled.ButtonContainer>
    ),
    [resetZoom],
  );

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const initialZoom = useMemo(
    () => ({
      zoomState,
      triggerZoomReset: resetZoom,
      domain,
      initialDomain,
      setDomain: setCurrentDomain,
    }),
    [zoomState, resetZoom, domain, initialDomain],
  );
  if (!props?.selectedStand) return null;

  const modalHeight = (modalInnerHeight ?? MODAL_HEIGHT_PX) + MODAL_VERTICAL_PADDING * 2;

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onCancel}
      style={{
        content: {
          ...(modalHeight > windowHeight ? scrollableModalContent : customStyles.content),
          height: modalHeight > windowHeight ? windowHeight - MODAL_VERTICAL_PADDING * 2 : undefined,
          backgroundColor: themeStyle.colors.alternate_bg,
          border: `1px solid ${themeStyle.colors.inspection_view_border}`,
        },
        overlay: customStyles.overlay,
      }}
      ariaHideApp={false}
    >
      <ZoomChartContext.Provider value={initialZoom}>
        <InspectionViewInner {...props} inspectionRef={inspectionRef} ControlButtons={ControlButtons} />
      </ZoomChartContext.Provider>
    </Modal>
  );
};
