/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GeneratorSlotType } from './GeneratorSlotType';
import {
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeFromJSONTyped,
    GeneratorSlotTypeToJSON,
} from './GeneratorSlotType';

/**
 * 
 * @export
 * @interface GeneratorStatsUserLensDto
 */
export interface GeneratorStatsUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatsUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatsUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatsUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneratorStatsUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneratorStatsUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneratorStatsUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneratorStatsUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {Array<GeneratorSlotType>}
     * @memberof GeneratorStatsUserLensDto
     */
    selectedGenerators: Array<GeneratorSlotType>;
}

/**
 * Check if a given object implements the GeneratorStatsUserLensDto interface.
 */
export function instanceOfGeneratorStatsUserLensDto(value: object): value is GeneratorStatsUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('squeezesDisplay' in value) || value['squeezesDisplay'] === undefined) return false;
    if (!('selectedGenerators' in value) || value['selectedGenerators'] === undefined) return false;
    return true;
}

export function GeneratorStatsUserLensDtoFromJSON(json: any): GeneratorStatsUserLensDto {
    return GeneratorStatsUserLensDtoFromJSONTyped(json, false);
}

export function GeneratorStatsUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorStatsUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedGenerators': ((json['SelectedGenerators'] as Array<any>).map(GeneratorSlotTypeFromJSON)),
    };
}

export function GeneratorStatsUserLensDtoToJSON(value?: GeneratorStatsUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'SqueezesDisplay': value['squeezesDisplay'],
        'SelectedGenerators': ((value['selectedGenerators'] as Array<any>).map(GeneratorSlotTypeToJSON)),
    };
}

