/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WedgeValueDto
 */
export interface WedgeValueDto {
    /**
     * 
     * @type {number}
     * @memberof WedgeValueDto
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof WedgeValueDto
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof WedgeValueDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof WedgeValueDto
     */
    proportion: number;
}

/**
 * Check if a given object implements the WedgeValueDto interface.
 */
export function instanceOfWedgeValueDto(value: object): value is WedgeValueDto {
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('proportion' in value) || value['proportion'] === undefined) return false;
    return true;
}

export function WedgeValueDtoFromJSON(json: any): WedgeValueDto {
    return WedgeValueDtoFromJSONTyped(json, false);
}

export function WedgeValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeValueDto {
    if (json == null) {
        return json;
    }
    return {
        
        'position': json['Position'],
        'text': json['Text'],
        'value': json['Value'],
        'proportion': json['Proportion'],
    };
}

export function WedgeValueDtoToJSON(value?: WedgeValueDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Position': value['position'],
        'Text': value['text'],
        'Value': value['value'],
        'Proportion': value['proportion'],
    };
}

