/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RigCardFactValue
 */
export interface RigCardFactValue {
    /**
     * 
     * @type {number}
     * @memberof RigCardFactValue
     */
    value?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactValue
     */
    highlightedValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactValue
     */
    readonly rank: number;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactValue
     */
    readonly trend: number;
}

/**
 * Check if a given object implements the RigCardFactValue interface.
 */
export function instanceOfRigCardFactValue(value: object): value is RigCardFactValue {
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('trend' in value) || value['trend'] === undefined) return false;
    return true;
}

export function RigCardFactValueFromJSON(json: any): RigCardFactValue {
    return RigCardFactValueFromJSONTyped(json, false);
}

export function RigCardFactValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardFactValue {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['Value'] == null ? undefined : json['Value'],
        'highlightedValue': json['HighlightedValue'] == null ? undefined : json['HighlightedValue'],
        'rank': json['Rank'],
        'trend': json['Trend'],
    };
}

export function RigCardFactValueToJSON(value?: Omit<RigCardFactValue, 'Rank'|'Trend'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Value': value['value'],
        'HighlightedValue': value['highlightedValue'],
    };
}

