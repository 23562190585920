import { MODAL_HEIGHT_PX, MODAL_WIDTH_PX } from "components/Lenses/ContainerLens/TorqueAndDrag/utils";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import { Suspense } from "react";

import { InspectionViewInner } from "./InspectionInner";
import type { InspectionViewProps } from "./types";

export const InspectionView: React.FC<InspectionViewProps> = (props) => {
  const { isVisible, onCancel } = props;
  return (
    <PDComponent.Modal
      open={isVisible}
      onCancel={onCancel}
      centered
      hasCloseIcon={false}
      footer={null}
      width={MODAL_WIDTH_PX}
      bodyStyle={{ height: MODAL_HEIGHT_PX, padding: 0 }}
    >
      <Suspense fallback={<Loader centered />}>
        <InspectionViewInner {...props} />
      </Suspense>
    </PDComponent.Modal>
  );
};
