/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigLeaderboardListAco } from './RigLeaderboardListAco';
import {
    RigLeaderboardListAcoFromJSON,
    RigLeaderboardListAcoFromJSONTyped,
    RigLeaderboardListAcoToJSON,
} from './RigLeaderboardListAco';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';

/**
 * 
 * @export
 * @interface ClaraSessionDto
 */
export interface ClaraSessionDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof ClaraSessionDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {string}
     * @memberof ClaraSessionDto
     */
    sessionId: string;
    /**
     * 
     * @type {RigLeaderboardListAco}
     * @memberof ClaraSessionDto
     */
    context: RigLeaderboardListAco;
}

/**
 * Check if a given object implements the ClaraSessionDto interface.
 */
export function instanceOfClaraSessionDto(value: object): value is ClaraSessionDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    if (!('context' in value) || value['context'] === undefined) return false;
    return true;
}

export function ClaraSessionDtoFromJSON(json: any): ClaraSessionDto {
    return ClaraSessionDtoFromJSONTyped(json, false);
}

export function ClaraSessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaraSessionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'sessionId': json['SessionId'],
        'context': RigLeaderboardListAcoFromJSON(json['Context']),
    };
}

export function ClaraSessionDtoToJSON(value?: ClaraSessionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'SessionId': value['sessionId'],
        'Context': RigLeaderboardListAcoToJSON(value['context']),
    };
}

