import { operationColorsByExternalId } from "components/Lenses/ContainerLens/common/utils/utils";
import { useCallback } from "react";

import { useOperationClasses } from "./useOperationClasses";

export function useOperationColors() {
  const { data: operationClasses } = useOperationClasses();
  const getOperationColor = useCallback(
    (internalId: number) => {
      return operationColorsByExternalId[
        operationClasses.find((opId) => opId.id === internalId)
          ?.externalId as unknown as keyof typeof operationColorsByExternalId
      ];
    },
    [operationClasses],
  );

  return { getOperationColor };
}
