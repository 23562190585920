/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SingleStandKpiDetailByDateDto
 */
export interface SingleStandKpiDetailByDateDto {
    /**
     * 
     * @type {Date}
     * @memberof SingleStandKpiDetailByDateDto
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByDateDto
     */
    value: number;
}

/**
 * Check if a given object implements the SingleStandKpiDetailByDateDto interface.
 */
export function instanceOfSingleStandKpiDetailByDateDto(value: object): value is SingleStandKpiDetailByDateDto {
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function SingleStandKpiDetailByDateDtoFromJSON(json: any): SingleStandKpiDetailByDateDto {
    return SingleStandKpiDetailByDateDtoFromJSONTyped(json, false);
}

export function SingleStandKpiDetailByDateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleStandKpiDetailByDateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'date': (new Date(json['Date'])),
        'value': json['Value'],
    };
}

export function SingleStandKpiDetailByDateDtoToJSON(value?: SingleStandKpiDetailByDateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Date': ((value['date']).toISOString().substring(0,10)),
        'Value': value['value'],
    };
}

