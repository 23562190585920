import type { ApiPowerConsumptionUserLensesIdFactsPutRequest } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { usePowerConsumptionByOperationFacts } from "hooks/lens-facts/usePowerConsumptionByOperationFacts";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useFilterParams } from "hooks/useFilterParams";
import { defaultDateDto } from "utils/common";
import { dateToDateDto } from "utils/helper";

import type { IZoomData } from "./../../../../reducers/types";
import { IZoomType } from "./../../../../reducers/types";

interface UsePowerConsumptionByOperationFetcherParams {
  lensId: number;
  comparisonWellIds?: number[];
}

export function usePowerConsumptionByOperationFetcher({ lensId }: UsePowerConsumptionByOperationFetcherParams) {
  const filterParams = useFilterParams();

  const [zoomData] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WELL, initialZoomData, [
    URL_STATE_PARAM.ZOOM_WIDGET,
  ]);

  const requestParameters: ApiPowerConsumptionUserLensesIdFactsPutRequest = {
    id: lensId ?? -1,
    // TODO Talk about those

    rigPowerQueryDto: { ...filterParams, selectedOperationClassIds: null },
  };

  let requestParametersWithZoom: ApiPowerConsumptionUserLensesIdFactsPutRequest = requestParameters;
  if (zoomData.date_start && zoomData.type === IZoomType.DATE) {
    requestParametersWithZoom = {
      ...requestParameters,
      rigPowerQueryDto: requestParameters.rigPowerQueryDto
        ? {
            ...requestParameters.rigPowerQueryDto,
            from: dateToDateDto(zoomData.date_start) || defaultDateDto.from,
            to: dateToDateDto(zoomData.date_end) || defaultDateDto.to,
          }
        : undefined,
    };
  }
  const request = usePowerConsumptionByOperationFacts(lensId, requestParametersWithZoom, {
    enabled: !!lensId,
  });

  return { request };
}
