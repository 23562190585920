/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigPowerSummaryValuesDto } from './RigPowerSummaryValuesDto';
import {
    RigPowerSummaryValuesDtoFromJSON,
    RigPowerSummaryValuesDtoFromJSONTyped,
    RigPowerSummaryValuesDtoToJSON,
} from './RigPowerSummaryValuesDto';

/**
 * 
 * @export
 * @interface RigPowerSummaryDto
 */
export interface RigPowerSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    totalActiveDuration: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    totalCumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    totalEnergyInKWh: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    totalMeanPower: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    totalCapacityInKWh: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryDto
     */
    maxLoad: number;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    generator1Summary: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    generator2Summary: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    generator3Summary: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    generator4Summary: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    availablePower: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    stateOfCharge: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    rigDemand: RigPowerSummaryValuesDto;
    /**
     * 
     * @type {RigPowerSummaryValuesDto}
     * @memberof RigPowerSummaryDto
     */
    bessOutput: RigPowerSummaryValuesDto;
}

/**
 * Check if a given object implements the RigPowerSummaryDto interface.
 */
export function instanceOfRigPowerSummaryDto(value: object): value is RigPowerSummaryDto {
    if (!('totalActiveDuration' in value) || value['totalActiveDuration'] === undefined) return false;
    if (!('totalCumulativeDuration' in value) || value['totalCumulativeDuration'] === undefined) return false;
    if (!('totalEnergyInKWh' in value) || value['totalEnergyInKWh'] === undefined) return false;
    if (!('totalMeanPower' in value) || value['totalMeanPower'] === undefined) return false;
    if (!('totalCapacityInKWh' in value) || value['totalCapacityInKWh'] === undefined) return false;
    if (!('maxLoad' in value) || value['maxLoad'] === undefined) return false;
    if (!('generator1Summary' in value) || value['generator1Summary'] === undefined) return false;
    if (!('generator2Summary' in value) || value['generator2Summary'] === undefined) return false;
    if (!('generator3Summary' in value) || value['generator3Summary'] === undefined) return false;
    if (!('generator4Summary' in value) || value['generator4Summary'] === undefined) return false;
    if (!('availablePower' in value) || value['availablePower'] === undefined) return false;
    if (!('stateOfCharge' in value) || value['stateOfCharge'] === undefined) return false;
    if (!('rigDemand' in value) || value['rigDemand'] === undefined) return false;
    if (!('bessOutput' in value) || value['bessOutput'] === undefined) return false;
    return true;
}

export function RigPowerSummaryDtoFromJSON(json: any): RigPowerSummaryDto {
    return RigPowerSummaryDtoFromJSONTyped(json, false);
}

export function RigPowerSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigPowerSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'totalActiveDuration': json['TotalActiveDuration'],
        'totalCumulativeDuration': json['TotalCumulativeDuration'],
        'totalEnergyInKWh': json['TotalEnergyInKWh'],
        'totalMeanPower': json['TotalMeanPower'],
        'totalCapacityInKWh': json['TotalCapacityInKWh'],
        'maxLoad': json['MaxLoad'],
        'generator1Summary': RigPowerSummaryValuesDtoFromJSON(json['Generator1Summary']),
        'generator2Summary': RigPowerSummaryValuesDtoFromJSON(json['Generator2Summary']),
        'generator3Summary': RigPowerSummaryValuesDtoFromJSON(json['Generator3Summary']),
        'generator4Summary': RigPowerSummaryValuesDtoFromJSON(json['Generator4Summary']),
        'availablePower': RigPowerSummaryValuesDtoFromJSON(json['AvailablePower']),
        'stateOfCharge': RigPowerSummaryValuesDtoFromJSON(json['StateOfCharge']),
        'rigDemand': RigPowerSummaryValuesDtoFromJSON(json['RigDemand']),
        'bessOutput': RigPowerSummaryValuesDtoFromJSON(json['BessOutput']),
    };
}

export function RigPowerSummaryDtoToJSON(value?: RigPowerSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'TotalActiveDuration': value['totalActiveDuration'],
        'TotalCumulativeDuration': value['totalCumulativeDuration'],
        'TotalEnergyInKWh': value['totalEnergyInKWh'],
        'TotalMeanPower': value['totalMeanPower'],
        'TotalCapacityInKWh': value['totalCapacityInKWh'],
        'MaxLoad': value['maxLoad'],
        'Generator1Summary': RigPowerSummaryValuesDtoToJSON(value['generator1Summary']),
        'Generator2Summary': RigPowerSummaryValuesDtoToJSON(value['generator2Summary']),
        'Generator3Summary': RigPowerSummaryValuesDtoToJSON(value['generator3Summary']),
        'Generator4Summary': RigPowerSummaryValuesDtoToJSON(value['generator4Summary']),
        'AvailablePower': RigPowerSummaryValuesDtoToJSON(value['availablePower']),
        'StateOfCharge': RigPowerSummaryValuesDtoToJSON(value['stateOfCharge']),
        'RigDemand': RigPowerSummaryValuesDtoToJSON(value['rigDemand']),
        'BessOutput': RigPowerSummaryValuesDtoToJSON(value['bessOutput']),
    };
}

