/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InspectionTvdPointDto } from './InspectionTvdPointDto';
import {
    InspectionTvdPointDtoFromJSON,
    InspectionTvdPointDtoFromJSONTyped,
    InspectionTvdPointDtoToJSON,
} from './InspectionTvdPointDto';
import type { TorqueAndDragActivityType } from './TorqueAndDragActivityType';
import {
    TorqueAndDragActivityTypeFromJSON,
    TorqueAndDragActivityTypeFromJSONTyped,
    TorqueAndDragActivityTypeToJSON,
} from './TorqueAndDragActivityType';
import type { TorqueAndDragPointDto } from './TorqueAndDragPointDto';
import {
    TorqueAndDragPointDtoFromJSON,
    TorqueAndDragPointDtoFromJSONTyped,
    TorqueAndDragPointDtoToJSON,
} from './TorqueAndDragPointDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface TorqueAndDragInspectionResultDto
 */
export interface TorqueAndDragInspectionResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof TorqueAndDragInspectionResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof TorqueAndDragInspectionResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragInspectionResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof TorqueAndDragInspectionResultDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof TorqueAndDragInspectionResultDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragInspectionResultDto
     */
    wellId: number;
    /**
     * 
     * @type {TorqueAndDragActivityType}
     * @memberof TorqueAndDragInspectionResultDto
     */
    activity: TorqueAndDragActivityType;
    /**
     * 
     * @type {DateDto}
     * @memberof TorqueAndDragInspectionResultDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof TorqueAndDragInspectionResultDto
     */
    to: DateDto;
    /**
     * 
     * @type {TorqueAndDragPointDto}
     * @memberof TorqueAndDragInspectionResultDto
     */
    sourcePoint: TorqueAndDragPointDto;
    /**
     * 
     * @type {Array<InspectionTvdPointDto>}
     * @memberof TorqueAndDragInspectionResultDto
     */
    series?: Array<InspectionTvdPointDto> | null;
}



/**
 * Check if a given object implements the TorqueAndDragInspectionResultDto interface.
 */
export function instanceOfTorqueAndDragInspectionResultDto(value: object): value is TorqueAndDragInspectionResultDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('activity' in value) || value['activity'] === undefined) return false;
    if (!('from' in value) || value['from'] === undefined) return false;
    if (!('to' in value) || value['to'] === undefined) return false;
    if (!('sourcePoint' in value) || value['sourcePoint'] === undefined) return false;
    return true;
}

export function TorqueAndDragInspectionResultDtoFromJSON(json: any): TorqueAndDragInspectionResultDto {
    return TorqueAndDragInspectionResultDtoFromJSONTyped(json, false);
}

export function TorqueAndDragInspectionResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TorqueAndDragInspectionResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'wellId': json['WellId'],
        'activity': TorqueAndDragActivityTypeFromJSON(json['Activity']),
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'sourcePoint': TorqueAndDragPointDtoFromJSON(json['SourcePoint']),
        'series': json['Series'] == null ? undefined : ((json['Series'] as Array<any>).map(InspectionTvdPointDtoFromJSON)),
    };
}

export function TorqueAndDragInspectionResultDtoToJSON(value?: TorqueAndDragInspectionResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'DataState': ResultDataStateToJSON(value['dataState']),
        'WellId': value['wellId'],
        'Activity': TorqueAndDragActivityTypeToJSON(value['activity']),
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'SourcePoint': TorqueAndDragPointDtoToJSON(value['sourcePoint']),
        'Series': value['series'] == null ? undefined : ((value['series'] as Array<any>).map(InspectionTvdPointDtoToJSON)),
    };
}

