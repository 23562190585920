import type { LinearScaleConfig } from "@visx/scale";
import { scaleLinear } from "@visx/scale";
import type { ScaleLinear } from "d3-scale";
import { useMemo } from "react";
import { getYAxisTicksCount } from "utils/charting/getYAxisTicksCount";
import type { UOMHelper } from "utils/format";
import { formatTickDecimalsByDomain } from "utils/helper";

export function getDisplayScale({
  originalScale,
  uom,
  scaleProps,
}: {
  originalScale?: ScaleLinear<number, number, never>;
  uom?: UOMHelper;
  scaleProps?: LinearScaleConfig;
}) {
  return scaleLinear<number>({
    ...scaleProps,

    domain: uom?.fromSI ? originalScale?.domain()?.map(uom.fromSI) ?? [0, 0] : [0, 0],
    range: originalScale?.range() ?? [],
  });
}

export function getManualTickValues({
  yDisplayOnlyScale,
  verticalAxisTicksCount,
}: {
  yDisplayOnlyScale: ScaleLinear<number, number, never>;
  verticalAxisTicksCount: number;
}) {
  if (!yDisplayOnlyScale.domain()) return [0, 0];
  const [min, max] = yDisplayOnlyScale.domain() ?? [0, 0];
  if (!min && !max) return [];

  return [
    ...Array.from(
      { length: verticalAxisTicksCount },
      (v, k) => min + (((Number.isNaN(max) ? 0 : max) - min) / verticalAxisTicksCount) * k,
    ),
    Number.isNaN(max) ? 0 : max,
  ];
}

export const useYAxisDisplayScale = ({
  originalScale,
  uom,
  tickContainerHeight,
  tickHeight,
  isManual,
}: {
  originalScale?: ScaleLinear<number, number, never>;
  uom?: UOMHelper;
  tickContainerHeight?: number;
  tickHeight?: number;
  isManual?: boolean;
}) => {
  const yDisplayOnlyScale = useMemo(() => getDisplayScale({ originalScale, uom }), [originalScale, uom]);

  const verticalAxisTicksCount = getYAxisTicksCount({ tickContainerHeight, tickHeight });

  const manualTickValues = useMemo(
    () => getManualTickValues({ yDisplayOnlyScale, verticalAxisTicksCount }),
    [verticalAxisTicksCount, yDisplayOnlyScale],
  );

  function formatTickDecimals(value: number | string, decimalPlaces: number = 0) {
    return formatTickDecimalsByDomain(value, decimalPlaces, yDisplayOnlyScale.domain(), isManual);
  }

  return { yDisplayOnlyScale, verticalAxisTicksCount, formatTickDecimals, manualTickValues };
};
