import type { UOMHelper } from "utils/format";

import type { StandKpiCurves } from "./StandKpis/utils";

// Inspection
export const MODAL_TOP_PADDING = 100;
export const MODAL_VERTICAL_PADDING = 40;
export const MODAL_WIDTH_PX = 1015;
export const MODAL_HEIGHT_PX = 460;
export const X_AXIS_HEIGHT = 36;
export const CHART_PADDING_TOP = 36;
export const CHART_PADDING_BOTTOM = 12;
export const STATUS_BAR_HEIGHT = 24;
export const Y_AXIS_WIDTH = 48;
export const BORDER_WIDTH = 1;
export const CHART_CONTAINER_X = 795;
export const GRAPH_CONTAINER_HEIGHT_PX =
  MODAL_HEIGHT_PX - X_AXIS_HEIGHT - STATUS_BAR_HEIGHT - CHART_PADDING_TOP - CHART_PADDING_BOTTOM;
export const GRAPH_CONTAINER_WIDTH_PX = CHART_CONTAINER_X - 4 * Y_AXIS_WIDTH;
export const GRAPH_HEIGHT_PX = 134;
export const GRAPH_PADDING = 20;
export const Y_AXIS_PADDING = 30;
export const CONTROLS_CONTAINER_WIDTH = 120;

export const LEFT_SIDEBAR_WIDTH = MODAL_WIDTH_PX - CHART_CONTAINER_X;

export const DEFAULT_DECIMAL_PLACE_CUTOFF = 10;
export const SINGLE_DECIMAL_PLACE_CUTOFF = 1000;
export const DEFAULT_DECIMAL_POINTS = 2;

export interface Curve {
  label: string;
  color: string;
  unit?: string;
  dashed?: boolean;
  valueTransformer: UOMHelper;
  key: string;
  invertedScale?: boolean;
  combinedScaleWith?: StandKpiCurves;
}

export interface CurveWithData extends Curve {
  series?: number[];
  axisSeries?: number[];
}
