/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlanDrillingParameterDtoListAuditTrailVersionDto } from './PlanDrillingParameterDtoListAuditTrailVersionDto';
import {
    PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSON,
    PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSONTyped,
    PlanDrillingParameterDtoListAuditTrailVersionDtoToJSON,
} from './PlanDrillingParameterDtoListAuditTrailVersionDto';

/**
 * 
 * @export
 * @interface PlanRoadmapAuditTrailDto
 */
export interface PlanRoadmapAuditTrailDto {
    /**
     * 
     * @type {Date}
     * @memberof PlanRoadmapAuditTrailDto
     */
    firstUtc: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlanRoadmapAuditTrailDto
     */
    lastUtc: Date;
    /**
     * 
     * @type {Array<PlanDrillingParameterDtoListAuditTrailVersionDto>}
     * @memberof PlanRoadmapAuditTrailDto
     */
    versions?: Array<PlanDrillingParameterDtoListAuditTrailVersionDto> | null;
}

/**
 * Check if a given object implements the PlanRoadmapAuditTrailDto interface.
 */
export function instanceOfPlanRoadmapAuditTrailDto(value: object): value is PlanRoadmapAuditTrailDto {
    if (!('firstUtc' in value) || value['firstUtc'] === undefined) return false;
    if (!('lastUtc' in value) || value['lastUtc'] === undefined) return false;
    return true;
}

export function PlanRoadmapAuditTrailDtoFromJSON(json: any): PlanRoadmapAuditTrailDto {
    return PlanRoadmapAuditTrailDtoFromJSONTyped(json, false);
}

export function PlanRoadmapAuditTrailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanRoadmapAuditTrailDto {
    if (json == null) {
        return json;
    }
    return {
        
        'firstUtc': (new Date(json['FirstUtc'])),
        'lastUtc': (new Date(json['LastUtc'])),
        'versions': json['Versions'] == null ? undefined : ((json['Versions'] as Array<any>).map(PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSON)),
    };
}

export function PlanRoadmapAuditTrailDtoToJSON(value?: PlanRoadmapAuditTrailDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'FirstUtc': ((value['firstUtc']).toISOString()),
        'LastUtc': ((value['lastUtc']).toISOString()),
        'Versions': value['versions'] == null ? undefined : ((value['versions'] as Array<any>).map(PlanDrillingParameterDtoListAuditTrailVersionDtoToJSON)),
    };
}

