import type { DetailedLegendColumn } from "components/Lenses/common/ChartLegend/DetailedLegend";
import colors from "utils/colors";
import { Alignments } from "utils/enums";

export const RIG_POWER_SAMPLE_COUNT = 3000;
export const LEGEND_EMPTY_COLUMN_KEY = "name";
export const FALLBACK_DETAILED_LEGEND_HEIGHT = 100;

export const detailedLegendColumns: DetailedLegendColumn[] = [
  {
    title: "",
    widthPercent: 70,
    key: LEGEND_EMPTY_COLUMN_KEY,
    align: Alignments.Left,
  },
  {
    title: "Min",
    widthPercent: 10,
    //  `minWidth: 80` here supports upto 8 digits. Ideally we would want this to be dynamic.
    minWidth: 80,
    key: "Min",
  },
  {
    title: "Max",
    widthPercent: 10,
    minWidth: 80,
    key: "Max",
  },
  {
    title: "Mean",
    widthPercent: 10,
    minWidth: 80,
    key: "Mean",
  },
];

export const parameterCurveColors = {
  stateOfCharge: colors.fresco_green,
  rigDemand: colors.marble_green_grey,
  bessOutput: colors.flickering_sea,
};
