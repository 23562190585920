/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParameterByDepthTrackDetailsDto } from './ParameterByDepthTrackDetailsDto';
import {
    ParameterByDepthTrackDetailsDtoFromJSON,
    ParameterByDepthTrackDetailsDtoFromJSONTyped,
    ParameterByDepthTrackDetailsDtoToJSON,
} from './ParameterByDepthTrackDetailsDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface ParameterByDepthTrackComparisonWellDto
 */
export interface ParameterByDepthTrackComparisonWellDto {
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackComparisonWellDto
     */
    wellId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterByDepthTrackComparisonWellDto
     */
    isFocalWell: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackComparisonWellDto
     */
    aggregatedWellsCount: number;
    /**
     * 
     * @type {Array<ParameterByDepthTrackDetailsDto>}
     * @memberof ParameterByDepthTrackComparisonWellDto
     */
    tracks: Array<ParameterByDepthTrackDetailsDto>;
    /**
     * 
     * @type {ResultDataState}
     * @memberof ParameterByDepthTrackComparisonWellDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the ParameterByDepthTrackComparisonWellDto interface.
 */
export function instanceOfParameterByDepthTrackComparisonWellDto(value: object): value is ParameterByDepthTrackComparisonWellDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('isFocalWell' in value) || value['isFocalWell'] === undefined) return false;
    if (!('aggregatedWellsCount' in value) || value['aggregatedWellsCount'] === undefined) return false;
    if (!('tracks' in value) || value['tracks'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function ParameterByDepthTrackComparisonWellDtoFromJSON(json: any): ParameterByDepthTrackComparisonWellDto {
    return ParameterByDepthTrackComparisonWellDtoFromJSONTyped(json, false);
}

export function ParameterByDepthTrackComparisonWellDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterByDepthTrackComparisonWellDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'isFocalWell': json['IsFocalWell'],
        'aggregatedWellsCount': json['AggregatedWellsCount'],
        'tracks': ((json['Tracks'] as Array<any>).map(ParameterByDepthTrackDetailsDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function ParameterByDepthTrackComparisonWellDtoToJSON(value?: ParameterByDepthTrackComparisonWellDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'IsFocalWell': value['isFocalWell'],
        'AggregatedWellsCount': value['aggregatedWellsCount'],
        'Tracks': ((value['tracks'] as Array<any>).map(ParameterByDepthTrackDetailsDtoToJSON)),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

