import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { UserClaimDto } from "apis/oag";
import { UserRoleType } from "apis/oag";
import {
  ClaimPermissionType,
  ClaimType,
  ClarityUiType,
  SystemOfMeasurementType,
  UiThemeType,
  UsersApi,
  UserStatusType,
} from "apis/oag";
import { Button } from "atoms/Form";
import { toast } from "atoms/toast";
import { Title } from "atoms/Typography";
import { OverviewHeaderContainer, StyledTabs } from "components/Layout/Tabbed";
import dayjs from "dayjs";
import { useAdminSingleUser } from "hooks/admin/useAdminSingleUser";
import { useAdminSingleUserAccessInfo } from "hooks/admin/useAdminSingleUserAccessInfo";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "reducers/store";
import { useMixpanel } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { Col, Row, Space, Tooltip } from "utils/componentLibrary";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import Overview from "./Overview";
import WellAccess from "./WellAccess";

const users = new UsersApi(apiConfig);

export interface UserFormValues {
  account: {
    firstName: string;
    lastName: string;
    userName: string;
    operatorId?: number;
    jobTitle: string;
    role?: UserRoleType;
    expirationDate?: Date | null;
    status: UserStatusType;
    uiType: ClarityUiType;
    focusOperatorId?: number
    focusOperatorAsAccount: boolean;
  };
  access: {
    claimedRigCount: number;
    claimedWellCount: number;
    canAccessCrew: boolean;
    isMfaDisabled: boolean;
    canAccessRigScorecard: boolean;
    canSelectClaraEndpoint: boolean;
    isApprover: boolean;
  };
}

const UsersPage = ({ mode }: { mode?: "create" | "edit" }) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { atomThemeVariant } = useCustomTheme();

  const params = useParams<{ userId?: string; tabId?: string; breadcrumbs?: string; accountId?: string }>();
  const singleUser = useAdminSingleUser({ userId: Number(params.userId) }, mode === "edit");
  const accessInfo = useAdminSingleUserAccessInfo({ userId: Number(params.userId) }, mode === "edit");
  const [canUpdate, setCanUpdate] = useState(false);
  useEffect(() => {
    dispatch({
      type: "ADMIN_WELL_ACCESS_RESET",
      payload: null,
    });
  }, [dispatch]);

  const [formValues, setFormValues] = useState<UserFormValues | null>(null);
  const [initialValues, setInitialValues] = useState<UserFormValues | null>(null);

  const updateFormValues = useCallback(
    (values: UserFormValues) => {
      setFormValues(values);
      setCanUpdate(!(JSON.stringify(values) === JSON.stringify(initialValues) && canUpdate));
    },
    [canUpdate, initialValues],
  );

  useEffect(() => {
    if (singleUser?.data) {
      if (!singleUser.isLoading && !accessInfo.isLoading && formValues === null) {
        const initial : UserFormValues = {
          account: {
            firstName: singleUser?.data?.firstName ?? "",
            lastName: singleUser?.data?.lastName ?? "",
            userName: singleUser?.data?.userName ?? "",
            jobTitle: singleUser?.data?.jobTitle ?? "",
            operatorId: singleUser?.data?.operatorId,
            role: singleUser?.data?.role ?? "",
            status: singleUser?.data?.status,
            uiType: singleUser?.data?.uiType,
            expirationDate: singleUser?.data?.expirationDate,
            focusOperatorId: singleUser?.data?.focusOperatorId ?? undefined,
            focusOperatorAsAccount: singleUser?.data?.focusOperatorAsAccount ?? false,
          },
          access: {
            claimedRigCount: accessInfo?.data?.claimedRigCount ?? 0,
            claimedWellCount: accessInfo?.data?.claimedWellCount ?? 0,
            canAccessCrew: accessInfo?.data?.canAccessCrew ?? false,
            isMfaDisabled: accessInfo?.data?.isMfaDisabled ?? false,
            canAccessRigScorecard: accessInfo?.data?.canAccessRigScorecard ?? false,
            canSelectClaraEndpoint: accessInfo?.data?.canSelectClaraEndpoint ?? false,
            isApprover: accessInfo?.data?.isApprover ?? false,
          },
        };
        setFormValues(initial);
        setInitialValues(initial);
      }
    }
  }, [singleUser, accessInfo, formValues, mode]);

  useEffect(() => {
    if (mode === "create") {
      const initial : UserFormValues = {
        account: {
          firstName: "",
          lastName: "",
          userName: "",
          jobTitle: "",
          status: UserStatusType.Active,
          uiType: ClarityUiType.Web,
          focusOperatorId: undefined,
          focusOperatorAsAccount: false,
        },
        access: {
          claimedRigCount: 0,
          claimedWellCount: 0,
          canAccessCrew: false,
          isMfaDisabled: false,
          canAccessRigScorecard: false,
          canSelectClaraEndpoint: false,
          isApprover: false,
        },
      };
      setFormValues(initial);
      setInitialValues(initial);
    }
  }, [mode]);

  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();
  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("Personal Account", {
        Mode: `${(mode?.[0] || "").toLocaleUpperCase()}${mode?.slice(1)}`,
        "User ID": params.userId,
        "Account ID": params.accountId,
        "Tab ID": params.tabId,
      });
    }
  }, [viewPage, params, mode]);
  const tempClaims = useRef<Partial<UserClaimDto>[]>([]);

  const [hasFeedback, setHasFeedback] = useState(false);

  const tabs = [
    {
      key: "overview",
      path: "overview",
      children: (
        <Overview
          mode={mode!}
          singleUser={singleUser}
          userId={Number(params.userId) || 0}
          formValues={formValues}
          hasFeedback={hasFeedback}
          setFormValues={updateFormValues}
        />
      ),
      label: "Overview",
      disabled: false,
    },
    {
      key: "wells",
      path: "wells",
      children: (
        <WellAccess
          setCanUpdate={setCanUpdate}
          userId={Number(params.userId) || 0}
          singleUser={singleUser}
          allClaimsCallback={useCallback((e) => (tempClaims.current = e), [])}
        />
      ),
      label: <Tooltip title={mode === "create" ? "Create User to show Well Access" : ""}>Well Access</Tooltip>,
      disabled: mode === "create",
    },
  ];

  const validateFields = (formValues: UserFormValues | null) => {
    if (!formValues) return ["Form values are not set."];
    const errors: string[] = [];
    if (!formValues.account.firstName) errors.push("First Name is required.");
    if (!formValues.account.lastName) errors.push("Last Name is required.");
    if (!formValues.account.userName) errors.push("Email is required.");
    if (!formValues.account.role) errors.push("Role is required.");
    if (!formValues.account.operatorId) errors.push("Account is required.");

    if (formValues.account.expirationDate && dayjs(formValues.account.expirationDate).isBefore(dayjs())) {
      errors.push("Expiration Date cannot be in the past.");
    }

    return errors;
  };

  const createAccountMutation = useMutation({
    mutationFn: () => {
      if (!formValues) throw new Error("Form values are not set.");

      return users
        .apiUsersPost({
          userDto: {
            firstName: formValues.account.firstName,
            lastName: formValues.account.lastName,
            userName: formValues.account.userName,
            operatorId: formValues.account.operatorId ?? -1,
            role: formValues.account.role ?? UserRoleType.General,
            jobTitle: formValues.account.jobTitle,
            expirationDate: formValues.account.expirationDate ? formValues.account.expirationDate : null,
            canAccessCrew: formValues.access.canAccessCrew ?? false,
            isMfaDisabled: formValues.access.isMfaDisabled ?? false,
            canAccessRigScorecard: formValues.access.canAccessRigScorecard ?? false,
            canSelectClaraEndpoint: formValues.access.canSelectClaraEndpoint ?? false,
            id: 0,
            activeProfileId: 0,
            externalId: null,
            status: UserStatusType.Active,
            systemOfMeasurement: SystemOfMeasurementType.Imperial,
            uiTheme: UiThemeType.Light,
            createdAtUtc: null,
            isApprover: formValues.access.isApprover ?? false,
            isInDemoMode: false,
            claraEndpoint: "Prod",
            uiType: formValues.account.uiType ?? ClarityUiType.Web,
            focusOperatorId: formValues.account.focusOperatorId,
            focusOperatorAsAccount: formValues.account.focusOperatorAsAccount,
          },
        })
        .catch(() => {
          return toast.error({
            message: "Something went wrong.",
          });
        });
    },
    onSuccess(data) {
      toast.success({ message: "User created." });
      if (data) {
        navigate(`/admin/management/id/users/${data.id}/overview`);
      }
    },
    onError() {
      toast.error({ message: "Could not create user!" });
    },
  });

  const createAccount = () => {
    const errors = validateFields(formValues);

    if (errors.length > 0) {
      setHasFeedback(true);
      return errors.forEach((error) => {
        toast.error({
          message: error,
        });
      });
    }

    createAccountMutation.mutate();
  };

  const updateUserAccountMutation = useMutation({
    mutationFn: () => {
      if (!formValues) throw new Error("Form values are not set.");
      return users
        .apiUsersIdPut({
          id: singleUser.data?.id ?? 0,
          userDto: {
            id: singleUser.data?.id ?? 0,
            activeProfileId: singleUser?.data?.activeProfileId || -1, // this value cannot be changed using this endpoint
            firstName: formValues.account.firstName,
            lastName: formValues.account.lastName,
            userName: formValues.account.userName,
            operatorId: formValues.account.operatorId ?? -1,
            status: singleUser.data?.status ?? UserStatusType.Active,
            role: formValues.account.role ?? UserRoleType.General,
            jobTitle: formValues.account.jobTitle,
            expirationDate: formValues.account.expirationDate ? new Date(formValues.account.expirationDate) : null,
            canAccessCrew: formValues.access.canAccessCrew ?? false,
            isMfaDisabled: formValues.access.isMfaDisabled ?? false,
            canAccessRigScorecard: formValues.access.canAccessRigScorecard ?? false,
            canSelectClaraEndpoint: formValues.access.canSelectClaraEndpoint ?? false,
            externalId: singleUser.data?.externalId,
            systemOfMeasurement: singleUser.data?.systemOfMeasurement ?? SystemOfMeasurementType.Imperial,
            uiTheme: singleUser.data?.uiTheme ?? UiThemeType.Light,
            createdAtUtc: null,
            isApprover: formValues.access.isApprover ?? false,
            isInDemoMode: false,
            claraEndpoint: "Prod",
            uiType: formValues.account.uiType ?? ClarityUiType.Web,
            focusOperatorId: formValues.account.focusOperatorId,
            focusOperatorAsAccount: formValues.account.focusOperatorAsAccount,
          },
        })
        .catch(() => {
          return toast.error({
            message: "Something went wrong.",
          });
        });
    },
    ...{
      onSettled() {
        queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.ADMIN_USERS_SINGLE }], exact: false });
      },
      onSuccess() {
        toast.success({ message: "User updated." });
      },
      onError() {
        toast.error({ message: "Could not update user!" });
      },
    },
  });

  const updateAccount = () => {
    const errors = validateFields(formValues);
    if (errors.length > 0) {
      setHasFeedback(true);
      return errors.forEach((error) => {
        toast.error({
          message: error,
        });
      });
    }

    setInitialValues(formValues);
    updateUserAccountMutation.mutate();
  };

  const updateClaims = useMutation({
    mutationFn: () => {
      return users.apiUsersIdClaimsPut({
        id: Number(params.userId),
        userClaimsRequestDto: {
          claims: tempClaims.current
            .filter(
              (value, index, arr) =>
                (value.wellId === undefined || index === arr.findIndex((val) => val.wellId === value.wellId)) &&
                value.permission === ClaimPermissionType.All,
            )
            .map((claim) =>
              claim.type === ClaimType.FutureWellAccess
                ? {
                    id: claim.id!,
                    type: claim.type,
                    permission: claim.permission!,
                    userId: claim.userId!,
                    rigId: claim.rigId!,
                  }
                : {
                    id: claim.id!,
                    type: claim.type!,
                    permission: claim.permission!,
                    userId: claim.userId!,
                    wellId: claim.wellId!,
                  },
            ),
        },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.ADMIN_USERS_WELL_ACCESS }], exact: false });
    },
    onSuccess: () => {
      toast.success({ message: "Well Access has been updated!" });
    },
    onError: () => {
      toast.error({ message: "Could not update Well Access!" });
    },
  });
  const [selectedElement, setSelectedElement] = useState("overview");
  useEffect(() => {
    if (params.tabId) setSelectedElement(params.tabId);
  }, [params.tabId]);

  return (
    <Row>
      <Col span={24}>
        <OverviewHeaderContainer>
          <Row justify="space-between" align="middle" style={{ height: "80px" }}>
            <Col flex="0 auto">
              <Title level={3} variant={atomThemeVariant} weight={500} style={{ margin: "0 12px" }}>
                {mode === "edit" && (
                  <>
                    {!singleUser.data && "Loading..."}
                    {singleUser.data ? `${singleUser.data?.firstName} ${singleUser.data?.lastName}` : null}
                  </>
                )}
                {mode === "create" && "Create User"}
              </Title>
            </Col>
            <Col flex="0 auto">
              <Space>
                <Button
                  onClick={() =>
                    params.breadcrumbs === "management"
                      ? navigate("/admin/users")
                      : navigate(`/admin/accounts/${params?.accountId}/users`)
                  }
                >
                  Cancel
                </Button>
                <Space>
                  {!singleUser.isLoading && mode === "edit" && (
                    <Button
                      onClick={() => {
                        if (params.tabId === undefined || params.tabId === "overview") {
                          setCanUpdate(false);
                          updateAccount();
                        } else {
                          setCanUpdate(false);
                          updateClaims.mutate();
                        }
                      }}
                      loading={
                        params.tabId === undefined || params.tabId === "overview"
                          ? updateUserAccountMutation.isPending
                          : updateClaims.isPending
                      }
                      type="primary"
                      disabled={!canUpdate}
                    >
                      Update User
                    </Button>
                  )}
                  {mode === "create" && (
                    <Button loading={createAccountMutation.isPending} onClick={createAccount} type="primary">
                      Create User
                    </Button>
                  )}
                </Space>
              </Space>
            </Col>
          </Row>
          <StyledTabs
            type="card"
            tabBarStyle={{ margin: 0 }}
            tabBarGutter={6}
            activeKey={params.tabId}
            onTabClick={(key) =>
              navigate(`/admin/${params.breadcrumbs}/${params.accountId}/users/${params.userId ?? 0}/${key}`)
            }
            items={tabs}
          />
        </OverviewHeaderContainer>
      </Col>
    </Row>
  );
};

export default UsersPage;
