import type { InputProps } from "antd/lib/input/Input";
import { DimensionType } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import React, { useEffect, useState } from "react";
import type { IUnitSystem } from "reducers/types";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Input as AntInput, Tooltip } from "utils/componentLibrary";
import { useUOM } from "utils/format";

const StyledInput = styled(AntInput) <{ $hasError?: boolean }>`
  padding: 0px 10px;
  &,
  input {
    background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg} !important;
    color: ${(props) => (props.$hasError ? colors.error : props.theme.themeStyle.colors.primary_typography)};
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;
  }
  ${(props) =>
    props.disabled &&
    css`
      &,
      input {
        background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg} !important;
        border: ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;
      }
    `}

  &::placeholder,
  & .ant-input-suffix, & .ant-input-suffix .pd-icon {
    color: ${(props) => (props.$hasError ? colors.error : props.theme.themeStyle.colors.primary_scroll_bg)};
  }
  &.ant-input:focus {
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg} !important;
    border: solid 1px ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;
    outline: none !important;
    box-shadow: none !important;
  }
  input:focus {
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg} !important;
  }
  &.ant-input-affix-wrapper-focused {
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg} !important;
    border: solid 1px ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;
    outline: none !important;
    box-shadow: none !important;
  }

  border-radius: 4px;
  height: 36px;
`;

export const Input = React.forwardRef<
  typeof AntInput,
  InputProps & {
    isStartDepth?: boolean;
    error?: string;
    uom?: IUnitSystem | string;
    initialvalue: number | undefined;
    tosi?: (value: number) => number | undefined;
    fromsi?: (value: number) => number | undefined;
    updatevalue?: (value?: number) => void;
    decimals?: number;
    defaultsuffix?: string;
  }
>(function Input(props, ref) {
  const [value, setValue] = useState((props.initialvalue ?? "").toString());
  const [suffix, setSuffix] = useState(<span />);
  const { fromsi: fromSI, error, placeholder } = props;
  const uom = useUOM(DimensionType.Undefined).currentUOM;

  useEffect(() => {
    const getParsedValue = (initialvalue: number, decimals: number) => {
      if (!fromSI) return "";
      return (fromSI(Number.parseFloat((initialvalue || "").toString())) || 0).toFixed(decimals).toString();
    };
    if (props.initialvalue === -1) {
      setValue("");
      return;
    }

    if ((props.isStartDepth && props.initialvalue !== undefined) || props.initialvalue !== undefined) {
      setValue(getParsedValue(props?.initialvalue, props?.decimals ?? 0));
      return;
    }
    setValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uom, props.uom, props?.initialvalue]);

  useEffect(() => {
    if (!error) {
      setSuffix(value !== "" ? <span>{placeholder}</span> : <span />);
      return;
    }
    setSuffix(
      <div>
        {value !== "" ? placeholder : ""}{" "}
        <Tooltip placement="bottomRight" title={error} arrowPointAtCenter>
          <PDComponent.SvgIcon name="warning" />
        </Tooltip>
      </div>,
    );
  }, [error, value, placeholder]);

  const onBlur = () => {
    setValue((value) => (value !== "" ? Number.parseFloat(value).toFixed(props.decimals ?? 0) : ""));
    if (!props.updatevalue) return;
    if (value === "") props.updatevalue(undefined);
    else if (props.tosi) props.updatevalue(props.tosi(Number.parseFloat(value)));
  };

  return (
    <StyledInput
      // @ts-ignore
      ref={ref}
      size="large"
      onKeyDown={(event) => (event.key === "e" || event.key === "-" || event.key === "+") && event.preventDefault()}
      onBlur={onBlur}
      onChange={(event) => {
        const re = /^[0-9,.\b]+$/;
        const value = event.target.value;
        if (!(re.test(value) || value === "")) return;
        setValue(value ?? "");
      }}
      suffix={props.defaultsuffix !== undefined ? props.defaultsuffix : suffix}
      $hasError={!!props.error}
      {...props}
      value={value}
    />
  );
});
