import { useCallback, useMemo, useState } from "react";

import { ShareTabsModal } from "./ShareTabsModal";

export const useShareTabsModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const modalElement = useMemo(() => {
    return showModal ? <ShareTabsModal isVisible={showModal} onCancel={() => setShowModal(false)} /> : null;
  }, [showModal]);

  return { modalElement, openModal };
};
