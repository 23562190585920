/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SystemOfMeasurementType } from './SystemOfMeasurementType';
import {
    SystemOfMeasurementTypeFromJSON,
    SystemOfMeasurementTypeFromJSONTyped,
    SystemOfMeasurementTypeToJSON,
} from './SystemOfMeasurementType';

/**
 * 
 * @export
 * @interface StickSlipByDepthUserLensTrackItemDto
 */
export interface StickSlipByDepthUserLensTrackItemDto {
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    isManualYaxis: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    label: boolean;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    yaxisStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    yaxisEnd?: number | null;
    /**
     * 
     * @type {SystemOfMeasurementType}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    systemOfMeasurementType: SystemOfMeasurementType;
    /**
     * 
     * @type {boolean}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    isUomUnlocked: boolean;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    trackId: number;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    userLensId: number;
    /**
     * 
     * @type {number}
     * @memberof StickSlipByDepthUserLensTrackItemDto
     */
    positionDisplay?: number | null;
}



/**
 * Check if a given object implements the StickSlipByDepthUserLensTrackItemDto interface.
 */
export function instanceOfStickSlipByDepthUserLensTrackItemDto(value: object): value is StickSlipByDepthUserLensTrackItemDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('isManualYaxis' in value) || value['isManualYaxis'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('systemOfMeasurementType' in value) || value['systemOfMeasurementType'] === undefined) return false;
    if (!('isUomUnlocked' in value) || value['isUomUnlocked'] === undefined) return false;
    if (!('trackId' in value) || value['trackId'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    return true;
}

export function StickSlipByDepthUserLensTrackItemDtoFromJSON(json: any): StickSlipByDepthUserLensTrackItemDto {
    return StickSlipByDepthUserLensTrackItemDtoFromJSONTyped(json, false);
}

export function StickSlipByDepthUserLensTrackItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StickSlipByDepthUserLensTrackItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'position': json['Position'],
        'isManualYaxis': json['IsManualYaxis'],
        'label': json['Label'],
        'yaxisStart': json['YaxisStart'] == null ? undefined : json['YaxisStart'],
        'yaxisEnd': json['YaxisEnd'] == null ? undefined : json['YaxisEnd'],
        'systemOfMeasurementType': SystemOfMeasurementTypeFromJSON(json['SystemOfMeasurementType']),
        'isUomUnlocked': json['IsUomUnlocked'],
        'trackId': json['TrackId'],
        'userLensId': json['UserLensId'],
        'positionDisplay': json['PositionDisplay'] == null ? undefined : json['PositionDisplay'],
    };
}

export function StickSlipByDepthUserLensTrackItemDtoToJSON(value?: StickSlipByDepthUserLensTrackItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Position': value['position'],
        'IsManualYaxis': value['isManualYaxis'],
        'Label': value['label'],
        'YaxisStart': value['yaxisStart'],
        'YaxisEnd': value['yaxisEnd'],
        'SystemOfMeasurementType': SystemOfMeasurementTypeToJSON(value['systemOfMeasurementType']),
        'IsUomUnlocked': value['isUomUnlocked'],
        'TrackId': value['trackId'],
        'UserLensId': value['userLensId'],
        'PositionDisplay': value['positionDisplay'],
    };
}

