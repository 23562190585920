import { useQuery } from "@tanstack/react-query";
import { ProfilesApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDAdminUsersListQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const profiles = new ProfilesApi(apiConfig);

export function useProfiles() {
  const queryKey: PDAdminUsersListQueryKey = {
    type: PDQueryType.ADMIN_USERS_LIST,
    uid: RequestUID.adminUsersList,
    params: null,
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: () => profiles.apiProfilesInfoAllGet(),
  });
}
