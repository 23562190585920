/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProfileOriginType } from './ProfileOriginType';
import {
    ProfileOriginTypeFromJSON,
    ProfileOriginTypeFromJSONTyped,
    ProfileOriginTypeToJSON,
} from './ProfileOriginType';

/**
 * 
 * @export
 * @interface ProfileInfoDto
 */
export interface ProfileInfoDto {
    /**
     * 
     * @type {number}
     * @memberof ProfileInfoDto
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileInfoDto
     */
    profileId: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfoDto
     */
    profileName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfoDto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileInfoDto
     */
    userName: string;
    /**
     * 
     * @type {ProfileOriginType}
     * @memberof ProfileInfoDto
     */
    origin: ProfileOriginType;
}



/**
 * Check if a given object implements the ProfileInfoDto interface.
 */
export function instanceOfProfileInfoDto(value: object): value is ProfileInfoDto {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('profileId' in value) || value['profileId'] === undefined) return false;
    if (!('profileName' in value) || value['profileName'] === undefined) return false;
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    if (!('userName' in value) || value['userName'] === undefined) return false;
    if (!('origin' in value) || value['origin'] === undefined) return false;
    return true;
}

export function ProfileInfoDtoFromJSON(json: any): ProfileInfoDto {
    return ProfileInfoDtoFromJSONTyped(json, false);
}

export function ProfileInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['UserId'],
        'profileId': json['ProfileId'],
        'profileName': json['ProfileName'],
        'displayName': json['DisplayName'],
        'userName': json['UserName'],
        'origin': ProfileOriginTypeFromJSON(json['Origin']),
    };
}

export function ProfileInfoDtoToJSON(value?: ProfileInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'UserId': value['userId'],
        'ProfileId': value['profileId'],
        'ProfileName': value['profileName'],
        'DisplayName': value['displayName'],
        'UserName': value['userName'],
        'Origin': ProfileOriginTypeToJSON(value['origin']),
    };
}

