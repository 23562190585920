/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TorqueAndDragType = {
    Unknown: 'Unknown',
    Torque: 'Torque',
    Drag: 'Drag'
} as const;
export type TorqueAndDragType = typeof TorqueAndDragType[keyof typeof TorqueAndDragType];


export function instanceOfTorqueAndDragType(value: any): boolean {
    for (const key in TorqueAndDragType) {
        if (Object.prototype.hasOwnProperty.call(TorqueAndDragType, key)) {
            if (TorqueAndDragType[key as keyof typeof TorqueAndDragType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TorqueAndDragTypeFromJSON(json: any): TorqueAndDragType {
    return TorqueAndDragTypeFromJSONTyped(json, false);
}

export function TorqueAndDragTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TorqueAndDragType {
    return json as TorqueAndDragType;
}

export function TorqueAndDragTypeToJSON(value?: TorqueAndDragType | null): any {
    return value as any;
}

