import type { QueryObserverOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { AllRigsQueryDto, DateDto, RigSummaryDto, WellStatusType } from "apis/oag";
import { RigsApi } from "apis/oag";
import { useLinkedFilters } from "components/RigsHeader/useLinkedFilters";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto } from "utils/helper";
import type { PDRigsSummariesQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const rigs = new RigsApi(apiConfig);

export function useRigSummaries(optionsQuery?: QueryObserverOptions<RigSummaryDto[]>) {
  const filterState = useAppSelector((state) => state.rigsCommon.filters);
  const periodState = useAppSelector((state) => state.rigsCommon.period);

  const {
    options,
    isLoading: isLoadingObject,
    selectedValues,
  } = useLinkedFilters({
    keepEvengreenOnly: false,
    storeName: "rigsCommon",
  });
  // should send the set difference 
  const dispatch = useAppDispatch();

  const requestQuery: AllRigsQueryDto = {
    operatorIds: selectedValues.operators === null ? null : options?.operators.map((op) => op.id),
    rigIds: selectedValues.rigs === null ? null : options?.rigs.map((rig) => rig.id),
    from: dateToDateOnlyDto(periodState?.startDate || defaultDateDto.from.utc),
    to: dateToDateOnlyDto(periodState?.endDate || defaultDateDto.to.utc),
    includeFullWellFacts: false,
    formationIds: selectedValues.formations === null ? null : options?.formations.map((f) => f.id),

    // BEGIN : Rig List/Summaries should not be impacted by Fact related data
    selectedFilters: {
      directionalIntervalIds: null,
      includeFlatTime: true,
      includeSlidingTime: true,
      includeRotatingTime: true,
      includeNullHoleDepth: true,
      holeSizeIds: null,
      sectionIds: null,
      selectedWellStatusTypes: filterState?.wellStatus.map((ws) => ws.value as WellStatusType) ?? null,
      includeAlphaRigs: !!filterState?.technology.map((tech) => tech.id).includes(RigTechnology.Alpha),
      includeNoneAlphaRigs: !!filterState?.technology.map((tech) => tech.id).includes(RigTechnology.NonAlpha),
      selectedClasses: filterState?.type.map((t) => (t.id || -1).toString()),
      selectedCountryIds: filterState?.country.map((c) => +(c.id || -1)),
      selectedHorsePowers: filterState?.horsePower.map((hp) => +(hp.id || -1)),
      selectedOperatingCenters: filterState?.operatingCenter.map((oc) => (oc.id || -1).toString()),
    },
    // END
  };

  const RigsSummariesQueryKey: PDRigsSummariesQueryKey = {
    uid: RequestUID.rigsSummaries,
    params: requestQuery,
    type: PDQueryType.RIGS_SUMMARIES,
  };

  const request = useQuery<RigSummaryDto[]>({
    queryKey: [RigsSummariesQueryKey],
    queryFn: () =>
      rigs.apiRigsSummariesPut({
        allRigsQueryDto: requestQuery,
      }),
    ...(optionsQuery ?? {}),
    enabled: !Object.values(isLoadingObject).some((loading) => loading),
  });
  useEffect(() => {
    if (!request.isSuccess) return;
    const { data } = request;
    if (data && data.length > 0) {
      const lastUpdatedAtDate = data.reduce<DateDto | null>((acc: DateDto | null, rig) => {
        if (!rig.lastFactUpdatedAt) return acc;
        const date1 = new Date(rig.lastFactUpdatedAt.utc).getTime();
        const date2 = acc && new Date(acc.utc).getTime();
        if (date2 && date1 > date2) return rig.lastFactUpdatedAt;
        return acc;
      }, null);
      dispatch({
        type: "RIGS_COMMON_SET_LAST_UPDATE_DATE",
        payload: lastUpdatedAtDate,
      });
    }
  }, [dispatch, request]);
  return request;
}
