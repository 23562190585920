import { Title } from "atoms/Typography";
import styled from "styled-components";
import { Button } from "utils/componentLibrary";

export const CustomIcon = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  .ant-typography {
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography}!important;
  }
  .pd-icon {
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography}!important;
  }
`;

export const AddOutlierButton = styled(Button)`
  margin: 0;
  padding: 0;
`;

export const AddOutlier = styled(Title).attrs({ level: 3 })`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const ToggleButton = styled.button<{ inactive?: boolean }>`
  outline: none;
  background: none;
  border: none;

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  opacity: ${(props) => (props.inactive ? 0.4 : 1)};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  width: 100%;
`;

export const CustomButtonRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
  button {
    display: flex;
  }
`;
