/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FiltersDto,
  WedgeComparisonDto,
  WedgeUserLensDto,
  WedgeUserLensQueryDto,
} from '../models/index';
import {
    FiltersDtoFromJSON,
    FiltersDtoToJSON,
    WedgeComparisonDtoFromJSON,
    WedgeComparisonDtoToJSON,
    WedgeUserLensDtoFromJSON,
    WedgeUserLensDtoToJSON,
    WedgeUserLensQueryDtoFromJSON,
    WedgeUserLensQueryDtoToJSON,
} from '../models/index';

export interface ApiWedgeUserLensesAvailableFiltersPutRequest {
    wedgeUserLensQueryDto?: WedgeUserLensQueryDto;
}

export interface ApiWedgeUserLensesIdFactsPutRequest {
    id: number;
    wedgeUserLensQueryDto?: WedgeUserLensQueryDto;
}

export interface ApiWedgeUserLensesIdPutRequest {
    id: number;
    wedgeUserLensDto?: WedgeUserLensDto;
}

export interface ApiWedgeUserLensesPostRequest {
    wedgeUserLensDto?: WedgeUserLensDto;
}

/**
 * 
 */
export class WedgeUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiWedgeUserLensesAvailableFiltersPutRaw(requestParameters: ApiWedgeUserLensesAvailableFiltersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FiltersDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/WedgeUserLenses/AvailableFilters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WedgeUserLensQueryDtoToJSON(requestParameters['wedgeUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FiltersDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWedgeUserLensesAvailableFiltersPut(requestParameters: ApiWedgeUserLensesAvailableFiltersPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FiltersDto> {
        const response = await this.apiWedgeUserLensesAvailableFiltersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWedgeUserLensesIdFactsPutRaw(requestParameters: ApiWedgeUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WedgeComparisonDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWedgeUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/WedgeUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WedgeUserLensQueryDtoToJSON(requestParameters['wedgeUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WedgeComparisonDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWedgeUserLensesIdFactsPut(requestParameters: ApiWedgeUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WedgeComparisonDto> {
        const response = await this.apiWedgeUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWedgeUserLensesIdPutRaw(requestParameters: ApiWedgeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WedgeUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWedgeUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/WedgeUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WedgeUserLensDtoToJSON(requestParameters['wedgeUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WedgeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWedgeUserLensesIdPut(requestParameters: ApiWedgeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WedgeUserLensDto> {
        const response = await this.apiWedgeUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWedgeUserLensesPostRaw(requestParameters: ApiWedgeUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WedgeUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/WedgeUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WedgeUserLensDtoToJSON(requestParameters['wedgeUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WedgeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWedgeUserLensesPost(requestParameters: ApiWedgeUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WedgeUserLensDto> {
        const response = await this.apiWedgeUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
