import { Bar } from "@visx/shape";
import type { TrackWithRanges } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackRanges";
import type { ScaleLinear } from "d3-scale";
import React from "react";
import colors from "utils/colors";

import { returnZeroIfInvalid } from "./RoadmapColoredSegment";
import { COMPLIANCE_BAR_HEIGHT } from "./utils";

export const RangesComponent = React.memo(
    ({
        tracksWithRanges,
        chartHeight,
        depthScale,
    }: {
        tracksWithRanges: TrackWithRanges[];
        chartHeight: number;
        depthScale: ScaleLinear<number, number>;
    }) => {
        return tracksWithRanges[0]
            ? tracksWithRanges[0].ranges.map((range, idx) => {
                return range.compressedOutlierRanges.map((compressedRange, rangeIdx) => {
                    const x0 = depthScale(compressedRange[0].holeDepth);
                    const x1 = depthScale(compressedRange[1].holeDepth);
                    const keyVal = (compressedRange[0].value ?? 0) + (compressedRange[1].value ?? 0);

                    if (Number.isFinite(x0) && Number.isFinite(x1)) {
                        return (
                            <Bar
                                key={`${x0}-${x1}-${idx}-${keyVal}-${rangeIdx}`}
                                y={returnZeroIfInvalid(chartHeight - COMPLIANCE_BAR_HEIGHT)}
                                height={COMPLIANCE_BAR_HEIGHT}
                                x={returnZeroIfInvalid(Math.min(x0, x1))}
                                width={returnZeroIfInvalid(Math.abs(x1 - x0))}
                                fill={colors.error}
                            />
                        );
                    } else return null;
                });
            })
            : null;
    },
);
