import { StyledButton } from "components/Header/RightContent/style";
import { PDComponent } from "components/PDComponents";
import { useTasksCount } from "hooks/notifications/useTasks";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { PDRoutesMapping } from "routes";
import { Track } from "services/Mixpanel";
import { secondsInMinute } from "utils/common";

import * as Styled from "./style";

export const TasksButton = () => {
  const { data: tasksCount } = useTasksCount({
    refetchIntervalInBackground: true,
    refetchInterval: secondsInMinute * 1000,
  });

  const label = useMemo(() => (tasksCount > 99 ? "99+" : tasksCount), [tasksCount]);

  return (
    <Styled.OuterContainer>
      <StyledButton
        ghost
        onClick={() => {
          Track.clickEvent("Header - Open Tasks");
        }}
      >
        <Link to={PDRoutesMapping.private.pendingTasks.path}>
          <Styled.Container $isActive={tasksCount > 0}>
            <PDComponent.SvgIcon name="tag" />
            My Tasks
            {tasksCount > 0 ? <Styled.CircleCount>{label}</Styled.CircleCount> : null}
          </Styled.Container>
        </Link>
      </StyledButton>
    </Styled.OuterContainer>
  );
};
