/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GeneratorSlotType } from './GeneratorSlotType';
import {
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeFromJSONTyped,
    GeneratorSlotTypeToJSON,
} from './GeneratorSlotType';
import type { PowerLoadDistributionType } from './PowerLoadDistributionType';
import {
    PowerLoadDistributionTypeFromJSON,
    PowerLoadDistributionTypeFromJSONTyped,
    PowerLoadDistributionTypeToJSON,
} from './PowerLoadDistributionType';

/**
 * 
 * @export
 * @interface PowerLoadDistributionUserLensDto
 */
export interface PowerLoadDistributionUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof PowerLoadDistributionUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PowerLoadDistributionUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof PowerLoadDistributionUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof PowerLoadDistributionUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PowerLoadDistributionUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PowerLoadDistributionUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PowerLoadDistributionUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {Array<GeneratorSlotType>}
     * @memberof PowerLoadDistributionUserLensDto
     */
    selectedGenerators: Array<GeneratorSlotType>;
    /**
     * 
     * @type {PowerLoadDistributionType}
     * @memberof PowerLoadDistributionUserLensDto
     */
    distributionType: PowerLoadDistributionType;
}



/**
 * Check if a given object implements the PowerLoadDistributionUserLensDto interface.
 */
export function instanceOfPowerLoadDistributionUserLensDto(value: object): value is PowerLoadDistributionUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('squeezesDisplay' in value) || value['squeezesDisplay'] === undefined) return false;
    if (!('selectedGenerators' in value) || value['selectedGenerators'] === undefined) return false;
    if (!('distributionType' in value) || value['distributionType'] === undefined) return false;
    return true;
}

export function PowerLoadDistributionUserLensDtoFromJSON(json: any): PowerLoadDistributionUserLensDto {
    return PowerLoadDistributionUserLensDtoFromJSONTyped(json, false);
}

export function PowerLoadDistributionUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowerLoadDistributionUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedGenerators': ((json['SelectedGenerators'] as Array<any>).map(GeneratorSlotTypeFromJSON)),
        'distributionType': PowerLoadDistributionTypeFromJSON(json['DistributionType']),
    };
}

export function PowerLoadDistributionUserLensDtoToJSON(value?: PowerLoadDistributionUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'SqueezesDisplay': value['squeezesDisplay'],
        'SelectedGenerators': ((value['selectedGenerators'] as Array<any>).map(GeneratorSlotTypeToJSON)),
        'DistributionType': PowerLoadDistributionTypeToJSON(value['distributionType']),
    };
}

