/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OutliersFilteringType = {
    Percentile: 'Percentile',
    Value: 'Value'
} as const;
export type OutliersFilteringType = typeof OutliersFilteringType[keyof typeof OutliersFilteringType];


export function instanceOfOutliersFilteringType(value: any): boolean {
    for (const key in OutliersFilteringType) {
        if (Object.prototype.hasOwnProperty.call(OutliersFilteringType, key)) {
            if (OutliersFilteringType[key as keyof typeof OutliersFilteringType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OutliersFilteringTypeFromJSON(json: any): OutliersFilteringType {
    return OutliersFilteringTypeFromJSONTyped(json, false);
}

export function OutliersFilteringTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutliersFilteringType {
    return json as OutliersFilteringType;
}

export function OutliersFilteringTypeToJSON(value?: OutliersFilteringType | null): any {
    return value as any;
}

