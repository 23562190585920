import type { TickRendererProps } from "@visx/axis";
import { getAxisFontSize, LATERAL_AXIS_WIDTH } from "components/Lenses/ContainerLens/common/utils/utils";
import { useMemo } from "react";
import React from "react";
import { useCustomTheme } from "utils/useTheme";

export enum AxisLocation {
  BOTTOM = "BOTTOM",
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}

export const StandardTickComponent = React.memo(
  ({
    rendererProps,
    hasBigFont = false,
    axisLocation = AxisLocation.RIGHT,
    children,
  }: {
    rendererProps: TickRendererProps;
    hasBigFont?: boolean;
    axisLocation?: AxisLocation;
    children?: (val: string) => string;
  }) => {
    const axisFontSize = useMemo(() => getAxisFontSize(hasBigFont), [hasBigFont]);
    const {
      themeStyle: { colors: themeColors },
    } = useCustomTheme();

    const { x, y, dy, formattedValue } = rendererProps;
    const val = formattedValue?.replaceAll(",", "").replaceAll("−", "-") || "";

    return (
      <text
        x={x}
        y={y}
        dx={axisLocation === AxisLocation.BOTTOM ? LATERAL_AXIS_WIDTH : undefined}
        dy={axisLocation === AxisLocation.BOTTOM ? undefined : dy}
        fontSize={axisFontSize}
        textAnchor={axisLocation === AxisLocation.BOTTOM ? "middle" : "right"}
        fill={themeColors.disabled_typography}
        pointerEvents="none"
      >
        {children ? children(val) : null}
      </text>
    );
  },
);

StandardTickComponent.displayName = "StandardTickComponent";
