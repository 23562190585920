import { DisplayOption } from "apis/oag";
import { baseMatrix, MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import type {
  AbilityMatrixProps,
  AbilityMatrixType,
} from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export const getStackedKpiAbilityMatrix = (props?: AbilityMatrixProps): AbilityMatrixType => {
  return {
    ...baseMatrix,
    outlierFlaggingType: MatrixAvailability.AVAILABLE,
    showsOutliers: props?.isComparing ? MatrixAvailability.NOT_AVAILABLE : MatrixAvailability.AVAILABLE,
    squeezesDisplay: props?.isComparing ? MatrixAvailability.NOT_AVAILABLE : MatrixAvailability.AVAILABLE,
    showOperationCount:
      props?.displayOption === DisplayOption.StandDepth
        ? MatrixAvailability.NOT_AVAILABLE
        : MatrixAvailability.AVAILABLE,
    indicators:
      props?.isComparing || props?.displayOption === DisplayOption.DateShift
        ? MatrixAvailability.NOT_AVAILABLE
        : MatrixAvailability.AVAILABLE,
  };
};
