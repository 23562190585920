/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DimensionType } from './DimensionType';
import {
    DimensionTypeFromJSON,
    DimensionTypeFromJSONTyped,
    DimensionTypeToJSON,
} from './DimensionType';

/**
 * 
 * @export
 * @interface WellKpiInfoDto
 */
export interface WellKpiInfoDto {
    /**
     * 
     * @type {number}
     * @memberof WellKpiInfoDto
     */
    value: number;
    /**
     * 
     * @type {DimensionType}
     * @memberof WellKpiInfoDto
     */
    dimension: DimensionType;
}



/**
 * Check if a given object implements the WellKpiInfoDto interface.
 */
export function instanceOfWellKpiInfoDto(value: object): value is WellKpiInfoDto {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('dimension' in value) || value['dimension'] === undefined) return false;
    return true;
}

export function WellKpiInfoDtoFromJSON(json: any): WellKpiInfoDto {
    return WellKpiInfoDtoFromJSONTyped(json, false);
}

export function WellKpiInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellKpiInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['Value'],
        'dimension': DimensionTypeFromJSON(json['Dimension']),
    };
}

export function WellKpiInfoDtoToJSON(value?: WellKpiInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Value': value['value'],
        'Dimension': DimensionTypeToJSON(value['dimension']),
    };
}

