/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface RigDto
 */
export interface RigDto {
    /**
     * 
     * @type {number}
     * @memberof RigDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RigDto
     */
    externalId: number;
    /**
     * 
     * @type {string}
     * @memberof RigDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RigDto
     */
    shortName: string;
    /**
     * 
     * @type {number}
     * @memberof RigDto
     */
    contractorRigNumber: number;
    /**
     * 
     * @type {string}
     * @memberof RigDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof RigDto
     */
    _class: string;
    /**
     * 
     * @type {number}
     * @memberof RigDto
     */
    countryId: number;
    /**
     * 
     * @type {string}
     * @memberof RigDto
     */
    operatingCenter: string;
    /**
     * 
     * @type {number}
     * @memberof RigDto
     */
    horsePower: number;
    /**
     * 
     * @type {boolean}
     * @memberof RigDto
     */
    isAlpha: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RigDto
     */
    isActive: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof RigDto
     */
    lastFactUpdatedAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof RigDto
     */
    lastActiveFactAt: DateDto;
}

/**
 * Check if a given object implements the RigDto interface.
 */
export function instanceOfRigDto(value: object): value is RigDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('externalId' in value) || value['externalId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('shortName' in value) || value['shortName'] === undefined) return false;
    if (!('contractorRigNumber' in value) || value['contractorRigNumber'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('_class' in value) || value['_class'] === undefined) return false;
    if (!('countryId' in value) || value['countryId'] === undefined) return false;
    if (!('operatingCenter' in value) || value['operatingCenter'] === undefined) return false;
    if (!('horsePower' in value) || value['horsePower'] === undefined) return false;
    if (!('isAlpha' in value) || value['isAlpha'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('lastFactUpdatedAt' in value) || value['lastFactUpdatedAt'] === undefined) return false;
    if (!('lastActiveFactAt' in value) || value['lastActiveFactAt'] === undefined) return false;
    return true;
}

export function RigDtoFromJSON(json: any): RigDto {
    return RigDtoFromJSONTyped(json, false);
}

export function RigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': json['ExternalId'],
        'name': json['Name'],
        'shortName': json['ShortName'],
        'contractorRigNumber': json['ContractorRigNumber'],
        'type': json['Type'],
        '_class': json['Class'],
        'countryId': json['CountryId'],
        'operatingCenter': json['OperatingCenter'],
        'horsePower': json['HorsePower'],
        'isAlpha': json['IsAlpha'],
        'isActive': json['IsActive'],
        'lastFactUpdatedAt': DateDtoFromJSON(json['LastFactUpdatedAt']),
        'lastActiveFactAt': DateDtoFromJSON(json['LastActiveFactAt']),
    };
}

export function RigDtoToJSON(value?: RigDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'ExternalId': value['externalId'],
        'Name': value['name'],
        'ShortName': value['shortName'],
        'ContractorRigNumber': value['contractorRigNumber'],
        'Type': value['type'],
        'Class': value['_class'],
        'CountryId': value['countryId'],
        'OperatingCenter': value['operatingCenter'],
        'HorsePower': value['horsePower'],
        'IsAlpha': value['isAlpha'],
        'IsActive': value['isActive'],
        'LastFactUpdatedAt': DateDtoToJSON(value['lastFactUpdatedAt']),
        'LastActiveFactAt': DateDtoToJSON(value['lastActiveFactAt']),
    };
}

