import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar } from "@visx/shape";
import { DimensionType } from "apis/oag";
import NoData from "components/Lenses/common/NoData";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import {
  ChartContainer,
  InfoContainer,
  InnerDiv,
  KPIText,
  ParameterAvgKpiVal,
  ParameterComparisonContainer,
  ParameterComparisonGrid,
  ParameterComparisonGridItem,
  ParameterContainer,
  ParameterName,
  UOMText,
} from "components/Lenses/ContainerLens/StickSlipByDepth/Chart/style";
import { MIN_TRACK_HEIGHT } from "components/Lenses/ContainerLens/StickSlipByDepth/Chart/utils";
import { useVisibleTracks } from "components/Lenses/ContainerLens/StickSlipByDepth/Parts/hooks/useVisibleTracks";
import type { ZTrackTorqueProps } from "components/Lenses/ContainerLens/StickSlipByDepth/Parts/ZTorqueTrack/interfaces";
import type { ZtrackInterval, ZtrackIntervalsWithWellId } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { extent, range } from "d3-array";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { Typography } from "utils/componentLibrary";
import { useUOM } from "utils/format";
import { useColors } from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";
const { Text } = Typography;

export const zTorqueTrackId = 12;

export function ZTorqueTrack({
  chartWidth,
  depthScale,
  detailed,
  maxChartHeight,
  selectedUnit,
  showGraph,
  ztTracks: rawZtTracks,
  tracks: rawTracks,
  trackCount,
  onZTOverlayWellChange,
}: ZTrackTorqueProps) {
  const tracks = useVisibleTracks(rawTracks);
  const ztTracks = useVisibleTracks(rawZtTracks);

  const { height: chartHeightHook, ref: chartRef } = useResizeDetector();
  const { chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
  };
  const { data: wellData } = useWellShortInfoSuspended();
  const containerRef = useRef(null);
  const [selectedWellOverlay, setSelectedWellOverlay] = useState<null | ZtrackIntervalsWithWellId>(null);

  useEffect(() => {
    onZTOverlayWellChange(selectedWellOverlay);
  }, [onZTOverlayWellChange, selectedWellOverlay]);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const selectedWell = useAppSelector((state) => state.state.selectedWell);
  const { setColor: getColor } = useColors();

  const hasNoData = tracks.every((tr) => !tr.trackValues?.length);
  const depthUOM = useUOM(DimensionType.Metres);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent: ({ tooltipData }: { tooltipData?: { interval: ZtrackInterval; index: number } }) => {
      return tooltipData ? (
        <>
          <TooltipHighlightValue>
            ZTorque: On <PDComponent.SvgIcon name="zTorqueIndicatorOn" />
          </TooltipHighlightValue>

          <TooltipHighlightValue style={{ color: themeColors.disabled_typography, fontSize: 12 }}>
            {depthUOM.display(tooltipData.interval.startDepth, { fractionDigits: 0 }) +
              " - " +
              depthUOM.display(tooltipData.interval.endDepth, { fractionDigits: 0 })}
          </TooltipHighlightValue>

          <TooltipHighlightValue style={{ color: themeColors.disabled_typography, fontSize: 12 }}>
            {wellData?.byId[tracks[tooltipData.index].wellId]?.name ?? ""}
          </TooltipHighlightValue>
        </>
      ) : null;
    },
  });

  const valueScale = useMemo(() => {
    return scaleBand<number>({
      domain: range(tracks?.length),
      range: [chartHeight, 0],
      padding: 0.75,
      align: 0.5,
      reverse: true,
    });
  }, [chartHeight, tracks?.length]);

  const handleOnShowTooltip = useCallback(
    (interval: ZtrackInterval, index: number) => {
      showTooltip({
        tooltipLeft:
          depthScale(interval.startDepth) + (depthScale(interval.endDepth) - depthScale(interval.startDepth)) / 2,
        tooltipTop: valueScale(index),
        tooltipData: { interval, index },
      });
    },
    [depthScale, showTooltip, valueScale],
  );

  const averageSIByWell = useMemo(
    () =>
      tracks.reduce<Record<number, number>>((acc, track) => {
        acc[track.wellId] = track.average ?? 0;
        return acc;
      }, {}),
    [tracks],
  );

  const comparisonBarScale = useMemo(() => {
    const [min = 0, max = 0] = extent(Object.values(averageSIByWell).concat(0));
    return scaleLinear<number>({
      range: [0, detailed ? 80 : 72],
      domain: [min, max],
    });
  }, [averageSIByWell, detailed]);

  if (!selectedUnit) {
    return null;
  }

  return (
    <ParameterComparisonContainer>
      <InnerDiv detailed={detailed} style={{ minHeight: detailed ? 150 : MIN_TRACK_HEIGHT }} showGraph={showGraph}>
        <ParameterName detailed={detailed ? trackCount > 1 : undefined} $ignoreHoverEffects>
          <InfoContainer>
            <KPIText $detailed={detailed}>ZT On</KPIText>
            <UOMText $detailed={detailed}>(%)</UOMText>
          </InfoContainer>
        </ParameterName>

        {hasNoData ? (
          <NoData isStatic detailed={detailed} />
        ) : (
          <ParameterAvgKpiVal detailed={detailed} $isSingle={trackCount === 1}>
            {tracks.filter((t) => !t.isFocalWell).length === 0 ? (
              selectedWell && averageSIByWell[selectedWell] ? (
                <Text style={{ color: themeColors.primary_typography, fontSize: 16 }}>
                  {selectedUnit?.toString(averageSIByWell[selectedWell]) || ""}{" "}
                </Text>
              ) : null
            ) : (
              <ParameterComparisonGrid rowCount={tracks.length}>
                {[...tracks].map((track) => {
                  const barWidth = comparisonBarScale(averageSIByWell[track.wellId]);
                  return (
                    <ParameterComparisonGridItem
                      key={`${track.trackId}-${track.wellId}`}
                      isFocalWell={track.isFocalWell}
                    >
                      <div
                        style={{
                          width: barWidth + "px",
                          // TODO the assumption if just well id 0 is going to be aggregated is a bit inelegant
                          backgroundColor:
                            track.wellId === 0 ? colors.off_state : getColor({ key: track.wellId.toString() }),
                        }}
                      />
                      <div>{selectedUnit?.toString(averageSIByWell[track.wellId]) || "No data available"} </div>
                    </ParameterComparisonGridItem>
                  );
                })}
              </ParameterComparisonGrid>
            )}
          </ParameterAvgKpiVal>
        )}
        {showGraph ? (
          <>
            <ParameterContainer />

            <ChartContainer ref={chartRef}>
              <svg
                width={chartWidth < 0 ? 0 : chartWidth}
                height={(maxChartHeight || chartHeight) ?? 0}
                ref={containerRef}
              >
                <Group>
                  {ztTracks?.map((outerInterval, trackIndex) => {
                    const isTrackSelected = selectedWellOverlay?.wellId === outerInterval.wellId;

                    return (
                      <React.Fragment key={outerInterval.wellId + outerInterval.intervals?.length + trackIndex}>
                        <Bar
                          x={0}
                          y={valueScale(trackIndex)}
                          width={Math.abs(depthScale.range()[1] - depthScale.range()[0])}
                          height={detailed ? 12 : 9}
                          fill={colors.widget_line}
                          opacity={0.25}
                          cursor={"pointer"}
                          onClick={() => setSelectedWellOverlay(null)}
                        />

                        {outerInterval.intervals.map((interval, intIndex) => (
                          <Bar
                            key={"" + outerInterval.wellId + intIndex + interval.startDepth + interval.isOn}
                            x={depthScale(interval.startDepth)}
                            y={valueScale(trackIndex)}
                            width={Math.abs(depthScale(interval.endDepth) - depthScale(interval.startDepth))}
                            height={detailed ? 12 : 9}
                            fill={interval.isOn ? getColor({ key: "" + outerInterval.wellId }) : colors.widget_line}
                            onMouseEnter={() => handleOnShowTooltip(interval, trackIndex)}
                            onMouseLeave={() => hideTooltip()}
                            opacity={isTrackSelected || !selectedWellOverlay?.wellId ? 1 : 0.2}
                            cursor={"pointer"}
                            onClick={() =>
                              setSelectedWellOverlay((current) =>
                                current?.wellId === outerInterval.wellId ? null : interval.isOn ? outerInterval : null,
                              )
                            }
                          />
                        ))}
                      </React.Fragment>
                    );
                  })}
                </Group>
                {tooltipElement}
              </svg>
            </ChartContainer>
          </>
        ) : null}
      </InnerDiv>
    </ParameterComparisonContainer>
  );
}
