import { useEffect, useState } from "react";

import { useDirectionalIntervals } from "./useDirectionalIntervals";
import { useHoleSections } from "./useHoleSections";
import { useHoleSizes } from "./useHoleSizes";
import { usePhases } from "./usePhases";
import { useReferenceFilters } from "./useReferenceFilters";

export const useFiltersLoading = () => {
  const [filtersLoading, setFiltersLoading] = useState(false);
  const { data: holeSections, isLoading: isLoadingHoleSections } = useHoleSections();
  const { data: holeSizes, isLoading: isLoadingHoleSizes } = useHoleSizes();
  const { data: phases, isLoading: isLoadingPhases } = usePhases();
  const { data: directionalIntervals, isLoading: isLoadingDirectionalIntervals } = useDirectionalIntervals();
  const { data: availableFilters, isLoading: isLoadingAvailableFilters } = useReferenceFilters();

  useEffect(() => {
    setFiltersLoading(
      isLoadingAvailableFilters ||
        isLoadingHoleSections ||
        isLoadingHoleSizes ||
        isLoadingDirectionalIntervals ||
        isLoadingPhases,
    );
  }, [
    isLoadingAvailableFilters,
    isLoadingHoleSections,
    isLoadingHoleSizes,
    isLoadingDirectionalIntervals,
    isLoadingPhases,
  ]);

  return {
    filtersLoading,
    holeSections,
    phases,
    holeSizes,
    directionalIntervals,
    availableFilters,
  };
};
