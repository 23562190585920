import type { UserLensDto } from "apis/oag";
import { OutlierFlaggingType } from "apis/oag";
import { CustomSwitch } from "atoms/common";
import { Input } from "atoms/Form";
import { Text, Title } from "atoms/Typography";
import { AddOutlier, AddOutlierButton, Grid } from "components/Lenses/CommonLensSettingsModalContents/style";
import { Col, Row } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

interface Props {
  localLens: UserLensDto & { outlierFlaggingType?: OutlierFlaggingType };
  abbr: string;
  yMin: string;
  setYMin: React.Dispatch<React.SetStateAction<string>>;
  yMinError: string;
  yMax: string;
  setYMax: React.Dispatch<React.SetStateAction<string>>;
  yMaxError: string;
  setLocalLens: React.Dispatch<React.SetStateAction<UserLensDto & { outlierFlaggingType?: OutlierFlaggingType }>>;
}

function IncludeOutliers({ localLens, abbr, yMin, setYMin, yMinError, yMax, setYMax, yMaxError, setLocalLens }: Props) {
  const { atomThemeVariant } = useCustomTheme();
  return (
    <>
      {localLens?.outlierFlaggingType === OutlierFlaggingType.None && (
        <AddOutlierButton
          type="text"
          onClick={() => {
            setLocalLens({ ...localLens, outlierFlaggingType: OutlierFlaggingType.Manual });
          }}
        >
          <AddOutlier variant={atomThemeVariant}>Add Outlier Threshold</AddOutlier>
        </AddOutlierButton>
      )}
      {localLens?.outlierFlaggingType === OutlierFlaggingType.Manual && (
        <Col span={24}>
          <Row justify="space-between">
            <Title variant={atomThemeVariant} level={3}>
              Outlier Threshold
            </Title>
            <AddOutlierButton
              type="text"
              onClick={() => {
                setLocalLens({ ...localLens, outlierFlaggingType: OutlierFlaggingType.None });
              }}
            >
              <AddOutlier variant={atomThemeVariant}>Remove</AddOutlier>
            </AddOutlierButton>
          </Row>
          <Row>
            <Grid>
              <Input
                suffix={abbr}
                disabled={localLens?.outlierFlaggingType !== OutlierFlaggingType.Manual}
                value={yMin}
                onChange={(e) => setYMin(e.currentTarget.value)}
                error={yMinError}
              />
              <Input
                suffix={abbr}
                disabled={localLens?.outlierFlaggingType !== OutlierFlaggingType.Manual}
                value={yMax}
                onChange={(e) => setYMax(e.currentTarget.value)}
                error={yMaxError}
              />
            </Grid>
          </Row>
        </Col>
      )}
    </>
  );
}

export function IncludeOutliersButton({
  localLens,
  setLocalLens,
}: {
  localLens: UserLensDto & { outlierFlaggingType?: OutlierFlaggingType };
  setLocalLens: React.Dispatch<React.SetStateAction<UserLensDto & { outlierFlaggingType?: OutlierFlaggingType }>>;
}) {
  return (
    <div style={{ marginTop: "12px" }}>
      <Col>
        <Row gutter={[10, 0]}>
          <Col
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Text primary="description" variant="faded">
              {localLens?.outlierFlaggingType !== OutlierFlaggingType.Auto ? "ON" : "OFF"}
            </Text>
          </Col>
          <Col>
            <CustomSwitch
              $isChecked={localLens?.outlierFlaggingType !== OutlierFlaggingType.Auto}
              checked={localLens?.outlierFlaggingType !== OutlierFlaggingType.Auto}
              onChange={(checked) => {
                setLocalLens({
                  ...localLens,
                  outlierFlaggingType: checked ? OutlierFlaggingType.None : OutlierFlaggingType.Auto,
                });
              }}
            />
          </Col>
        </Row>
      </Col>
    </div>
  );
}

export default IncludeOutliers;
