import type { RigCardAverages, RigScorecardUserLensDto, UserLensDto } from "apis/oag";
import { RigScorecardLensType } from "apis/oag";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useRigNavigationFilters } from "hooks/navigation/useRigNavigationFilters";
import { useKpiTypes } from "hooks/useKpiTypes";
import { useRigs } from "hooks/useRigs";
import { useSelectedRig } from "hooks/useSelectedRig";
import { max } from "lodash";
import { TSummary } from "pages/RigLeaderboard/components/ScoreBreakout/SummarizedComments";
import { useScoreBreakoutModal } from "pages/RigLeaderboard/components/ScoreBreakout/useScoreBreakoutModal";
import { RigScoreCard } from "pages/RigScoreCard/LeftPane/RigScoreCard";
import { constructDetailedUrl, useScoreCardTags } from "pages/RigScoreCard/LeftPane/utils";
import { BenchmarkType, useScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";
import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";

import { GroupTitle } from "./GroupTitle";
import { Grid, Padding, StyledLensGroup } from "./style";
import { useRigScores } from "./useRigScores";
import { WellScorecard } from "./WellScorecard";

interface LensGroupProps {
  title: string;
  maxLen: number;
  index: number;
  lensGroup: {
    title: string;
    id: number;
    lenses: RigScorecardUserLensDto[];
  };
  isOverAllWellScore: boolean;
  lensesGroups: {
    title: string;
    id: number;
    lenses: RigScorecardUserLensDto[];
  }[];
  description: string;
  innerBoxRefArray: React.MutableRefObject<HTMLDivElement[]>;
}

const LensGroup = React.forwardRef<HTMLDivElement, LensGroupProps>(
  ({ title, maxLen, index, lensGroup, lensesGroups, innerBoxRefArray, isOverAllWellScore, description }, ref) => {
    const { getTagsByAveragesOrFact } = useScoreCardTags();
    const getTags = useCallback(
      (averages: RigCardAverages) => {
        const tags = getTagsByAveragesOrFact({ averages });
        return [tags.score, tags.opportunityTime, tags.timeSaved];
      },
      [getTagsByAveragesOrFact],
    );
    const [selectedWells] = useStateQuery(URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD, []);
    const rigScores = useRigScores({ selectedWells });
    // lens group averages
    const tags = getTags((rigScores?.cardGroups ?? []).find((group) => group.tabId === lensGroup.id)?.averages ?? {});
    const cardGroups = rigScores?.cardGroups ?? [];
    const { openScoreBreakout, scoreBreakoutElement } = useScoreBreakoutModal();
    const { data } = useKpiTypes();

    const { data: navigationFilters } = useRigNavigationFilters();
    const rigId = useSelectedRig();
    const { data: rigs } = useRigs();
    const location = useLocation();
    const getLensName = useCallback(
      (kpiType: number) => {
        const getName = () => {
          try {
            const lens = data?.byId[kpiType];
            return lens?.shortName || lens?.name || "";
          } catch {
            return "";
          }
        };
        return getName();
      },
      [data?.byId],
    );
    const { scoreBenchmark } = useScoreBenchmarkContext();

    return (
      <StyledLensGroup ref={ref}>
        <GroupTitle
          title={title}
          description={description}
          tags={isOverAllWellScore ? [] : tags}
          openCommentModal={() =>
            openScoreBreakout(
              {
                justComment: true,
                name: title,
                description: description,
                id: +rigId,
                opportunityTime: 0,
                score: 0,
                summaryType: TSummary.Kpi,
                kpiInfo: lensGroup.lenses.map((lens) => {
                  const fact = cardGroups
                    ?.find((e) => e.tabId === lens.lensTabId)
                    ?.facts?.find((e) => lens.id === e.userLensId);

                  return {
                    id: lens.id,
                    maxVal: isOverAllWellScore
                      ? max(
                          fact?.facts.map((e) =>
                            scoreBenchmark === BenchmarkType.OperatorTarget
                              ? e.operatorScore?.value
                              : e.rigScore?.value,
                          ),
                        ) || 0
                      : max(fact?.facts.map((e) => (e.kpiValue.value || 0) + (e.kpiValue?.exception || 0))) || 0,
                    name:
                      lens.scorecardType === RigScorecardLensType.OverallWellScores
                        ? "Well Scores"
                        : getLensName(lens.cardKpiType),
                  };
                }),
              },
              {
                title: "Rig Scorecard - Open Comment Modal",
                data: {
                  Kpi: title,
                  "Rig Id": +rigId,
                  "Rig Name": rigs?.byId[+rigId]?.name || "",
                },
              },
            )
          }
        />
        <Grid
          max={maxLen}
          crt={lensGroup?.lenses?.length || 0}
          $isWellOverview={isOverAllWellScore}
          selectedWells={selectedWells.length}
        >
          <Padding />
          {lensGroup?.lenses?.map((el: UserLensDto, idx) => {
            const fact = cardGroups?.find((e) => e.tabId === el.lensTabId)?.facts?.find((e) => el.id === e.userLensId);
            return fact ? (
              <div
                key={el.id}
                ref={(el) =>
                  innerBoxRefArray.current &&
                  el &&
                  (innerBoxRefArray.current[
                    (index > 0 ? lensesGroups.slice(0, index).reduce((a, b) => a + b.lenses.length, 0) : 0) + idx
                  ] = el)
                }
              >
                {isOverAllWellScore ? (
                  <WellScorecard
                    showNavigationButton={
                      !!navigationFilters?.find((lensNavigation) => lensNavigation.sourceLensId === el.id)?.targetLensId
                    }
                    getDetailedViewUrl={(wellId: number) => {
                      const targetLens = navigationFilters?.find(
                        (lensNavigation) => lensNavigation.sourceLensId === el.id,
                      );
                      return targetLens?.targetLensId
                        ? constructDetailedUrl(
                            wellId,
                            targetLens?.targetLensId,
                            targetLens?.filters,
                            rigId.toString(),
                            location.search.slice(1),
                          )
                        : "";
                    }}
                    rigCardScore={fact}
                    lens={el}
                  />
                ) : (
                  <RigScoreCard
                    showNavigationButton={
                      !!navigationFilters?.find((lensNavigation) => lensNavigation.sourceLensId === el.id)?.targetLensId
                    }
                    getDetailedViewUrl={(wellId: number) => {
                      const targetLens = navigationFilters?.find(
                        (lensNavigation) => lensNavigation.sourceLensId === el.id,
                      );
                      return targetLens?.targetLensId
                        ? constructDetailedUrl(
                            wellId,
                            targetLens?.targetLensId,
                            targetLens?.filters,
                            rigId.toString(),
                            location.search.slice(1),
                          )
                        : "";
                    }}
                    rigCardScore={fact}
                    lens={el}
                  />
                )}
              </div>
            ) : null;
          })}
          <Padding />
          {scoreBreakoutElement}
        </Grid>
      </StyledLensGroup>
    );
  },
);

export { LensGroup };
