import { shade, tint } from "polished";
import { dimensionMap } from "utils/format";

export const MAGICAL_WELLS_NUMBER = 7; // TODO why is this 7 ?

export const getShadeColor = (position: number, keyColor: string) => {
  if (position === 1) {
    return tint(0.3, keyColor);
  } else if (position === 2) {
    return keyColor;
  } else if (position > 2) {
    return shade(0.3 * (position - 2), keyColor);
  } else {
    return keyColor;
  }
};

export function getComparisonDisplayValue(kpi: any) {
  return {
    name: kpi?.text ?? "",
    main: dimensionMap.Seconds.MetricView(kpi?.focalAverage, {
      factor: 1 / (60 * 24),
      unit: "days",
    }),
    secondary: `${
      kpi?.comparisonAverageRelativeDiff !== undefined
        ? (kpi?.comparisonAverageRelativeDiff * 100).toFixed(2) + "% · "
        : "- -"
    }
          ${dimensionMap.Seconds.MetricView(kpi?.comparisonAverage, {
            factor: 1 / (60 * 24),
            unit: "days",
          })} avg`,
  };
}

export function getNonComparisonSecondaryValues(kpi: { text: string; value: number }) {
  return {
    name: kpi?.text ?? "",
    main: dimensionMap.Seconds.MetricView(kpi?.value, {
      factor: 1 / (60 * 24),
      unit: "days",
    }),
    secondary: "",
  };
}
