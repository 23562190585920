export enum NotificationTabs {
  All = "all",
  ScorecardComments = "scorecardComments",
  Exceptions = "exceptions",
  Archived = "archived",
}

export const notificationTabs = [
  { key: NotificationTabs.All, label: "All" },
  { key: NotificationTabs.ScorecardComments, label: "Scorecard Comments" },
  { key: NotificationTabs.Exceptions, label: "Exceptions" },
  { key: NotificationTabs.Archived, label: "Archived" },
];
