export const TEXT_ALL_GENERATORS = "All Gens";
export const TEXT_TOTAL = "Total";
export const TEXT_DESCRIPTION_TOTAL = "(Gens + Boilers)";
export const TEXT_ALL_BOILERS = "All Boilers";

export enum GeneratorAndBoilerOptions {
  Total = "Total",
  AllGens = "AllGens",
  SingleGen = "SingleGen",
  AllDieselBoilers = "AllDieselBoilers",
  Boiler1Diesel = "Boiler1Diesel",
  Boiler2Diesel = "Boiler2Diesel",
  BoilerNatGas = "BoilerNatGas",
}
