/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StandGroupSummaryDto
 */
export interface StandGroupSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    allAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    focalAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    comparisonAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    comparisonAverageRelativeDiff?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    focalDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    comparisonDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    comparisonDistributionRelativeDiff?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof StandGroupSummaryDto
     */
    isAggregate: boolean;
    /**
     * 
     * @type {number}
     * @memberof StandGroupSummaryDto
     */
    median: number;
}

/**
 * Check if a given object implements the StandGroupSummaryDto interface.
 */
export function instanceOfStandGroupSummaryDto(value: object): value is StandGroupSummaryDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('isAggregate' in value) || value['isAggregate'] === undefined) return false;
    if (!('median' in value) || value['median'] === undefined) return false;
    return true;
}

export function StandGroupSummaryDtoFromJSON(json: any): StandGroupSummaryDto {
    return StandGroupSummaryDtoFromJSONTyped(json, false);
}

export function StandGroupSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandGroupSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'allAverage': json['AllAverage'] == null ? undefined : json['AllAverage'],
        'focalAverage': json['FocalAverage'] == null ? undefined : json['FocalAverage'],
        'comparisonAverage': json['ComparisonAverage'] == null ? undefined : json['ComparisonAverage'],
        'comparisonAverageRelativeDiff': json['ComparisonAverageRelativeDiff'] == null ? undefined : json['ComparisonAverageRelativeDiff'],
        'focalDistribution': json['FocalDistribution'] == null ? undefined : json['FocalDistribution'],
        'comparisonDistribution': json['ComparisonDistribution'] == null ? undefined : json['ComparisonDistribution'],
        'comparisonDistributionRelativeDiff': json['ComparisonDistributionRelativeDiff'] == null ? undefined : json['ComparisonDistributionRelativeDiff'],
        'id': json['Id'],
        'position': json['Position'],
        'isAggregate': json['IsAggregate'],
        'median': json['Median'],
    };
}

export function StandGroupSummaryDtoToJSON(value?: StandGroupSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'AllAverage': value['allAverage'],
        'FocalAverage': value['focalAverage'],
        'ComparisonAverage': value['comparisonAverage'],
        'ComparisonAverageRelativeDiff': value['comparisonAverageRelativeDiff'],
        'FocalDistribution': value['focalDistribution'],
        'ComparisonDistribution': value['comparisonDistribution'],
        'ComparisonDistributionRelativeDiff': value['comparisonDistributionRelativeDiff'],
        'Id': value['id'],
        'Position': value['position'],
        'IsAggregate': value['isAggregate'],
        'Median': value['median'],
    };
}

