/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Int32DecimalFactIndicatorDto } from './Int32DecimalFactIndicatorDto';
import {
    Int32DecimalFactIndicatorDtoFromJSON,
    Int32DecimalFactIndicatorDtoFromJSONTyped,
    Int32DecimalFactIndicatorDtoToJSON,
} from './Int32DecimalFactIndicatorDto';

/**
 * 
 * @export
 * @interface Int32DecimalFactIndicatorSetDto
 */
export interface Int32DecimalFactIndicatorSetDto {
    /**
     * 
     * @type {Array<Int32DecimalFactIndicatorDto>}
     * @memberof Int32DecimalFactIndicatorSetDto
     */
    holeSections?: Array<Int32DecimalFactIndicatorDto> | null;
    /**
     * 
     * @type {Array<Int32DecimalFactIndicatorDto>}
     * @memberof Int32DecimalFactIndicatorSetDto
     */
    directionIntervals?: Array<Int32DecimalFactIndicatorDto> | null;
    /**
     * 
     * @type {Array<Int32DecimalFactIndicatorDto>}
     * @memberof Int32DecimalFactIndicatorSetDto
     */
    formations?: Array<Int32DecimalFactIndicatorDto> | null;
}

/**
 * Check if a given object implements the Int32DecimalFactIndicatorSetDto interface.
 */
export function instanceOfInt32DecimalFactIndicatorSetDto(value: object): value is Int32DecimalFactIndicatorSetDto {
    return true;
}

export function Int32DecimalFactIndicatorSetDtoFromJSON(json: any): Int32DecimalFactIndicatorSetDto {
    return Int32DecimalFactIndicatorSetDtoFromJSONTyped(json, false);
}

export function Int32DecimalFactIndicatorSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Int32DecimalFactIndicatorSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'holeSections': json['HoleSections'] == null ? undefined : ((json['HoleSections'] as Array<any>).map(Int32DecimalFactIndicatorDtoFromJSON)),
        'directionIntervals': json['DirectionIntervals'] == null ? undefined : ((json['DirectionIntervals'] as Array<any>).map(Int32DecimalFactIndicatorDtoFromJSON)),
        'formations': json['Formations'] == null ? undefined : ((json['Formations'] as Array<any>).map(Int32DecimalFactIndicatorDtoFromJSON)),
    };
}

export function Int32DecimalFactIndicatorSetDtoToJSON(value?: Int32DecimalFactIndicatorSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'HoleSections': value['holeSections'] == null ? undefined : ((value['holeSections'] as Array<any>).map(Int32DecimalFactIndicatorDtoToJSON)),
        'DirectionIntervals': value['directionIntervals'] == null ? undefined : ((value['directionIntervals'] as Array<any>).map(Int32DecimalFactIndicatorDtoToJSON)),
        'Formations': value['formations'] == null ? undefined : ((value['formations'] as Array<any>).map(Int32DecimalFactIndicatorDtoToJSON)),
    };
}

