import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import type {
  DrillingProductivityType,
  KpiGroupUserLensDto,
  KpiTypeUserLensDto,
  StandKpiGroup,
  StandKpiType,
  UserLensDto,
  WedgeUserLensDto,
} from "apis/oag";
import { DashboardType } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { useKpiGroups } from "hooks/useKPIGroups";
import { useKpiTypes } from "hooks/useKpiTypes";
import type { LensTemplate } from "hooks/useLensTemplates";
import {
  isDrillingProductivityLensTemplate,
  isMultipleKpiTemplate,
  isPivotKpiGroupLensTemplate,
  isPivotKpiTypeLensTemplate,
  isSingleKpiTemplate,
  isWedgeTemplate,
} from "hooks/useLensTemplates";
import { useLensUpdate } from "hooks/useLensUpdate";
import { UserLensesQueryKey } from "hooks/useUserLenses";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Track } from "services/Mixpanel";
import { drillingProductivityMap } from "utils/common";
import { Popover } from "utils/componentLibrary";
import { RequestUID } from "utils/queryNamespaces";

import * as Styled from "./style";

function KpiSelect({
  template,
  lens: userLens,
  onLensUpdated,
  isDisabled,
  lensUpdater,
}: {
  template: LensTemplate;
  lens: UserLensDto;
  onLensUpdated?: (newLens: UserLensDto) => void;
  isDisabled?: boolean;
  lensUpdater?: (newLens: UserLensDto) => Promise<UserLensDto>;
}) {
  const { data: kpiGroups } = useKpiGroups();
  const { data: kpiTypes } = useKpiTypes();

  const queryClient = useQueryClient();

  const handleLensUpdate = useLensUpdate({
    updateLens: lensUpdater,

    onMutate: () => {
      setKpiLoading(true);
    },
    onError: () => {
      setKpiLoading(false);
    },
    onLensUpdated: (newLens) => {
      onLensUpdated?.(newLens);
      queryClient.removeQueries({ queryKey: [{ lensId: newLens.id }], exact: false });
      setTimeout(() => {
        setKpiLoading(false);
      }, 100);
    },
  });

  const kpiType = kpiTypes?.byId[(userLens as KpiTypeUserLensDto).kpiTypeId ?? -1];
  const kpiGroup = kpiGroups?.byId[(userLens as KpiGroupUserLensDto).kpiGroupId ?? -1];

  const breakdown = isWedgeTemplate(template)
    ? (template.breakdowns ?? []).find((brk) => brk.id === (userLens as WedgeUserLensDto)?.breakdownId)
    : undefined;

  const filterOptions: Array<{ value: StandKpiType | StandKpiGroup | string; label: string }> = useMemo(() => {
    if (template.targetDashboardType === DashboardType.Rig) {
      if (isPivotKpiTypeLensTemplate(template)) {
        return template.selectableKpiTypeIds?.map((id) => ({ value: id, label: kpiTypes?.byId[id].name || "" })) || [];
      }
      if (isPivotKpiGroupLensTemplate(template)) {
        return (
          template.selectableKpiGroupIds?.map((id) => ({ value: id, label: kpiGroups?.byId[id].name || "" })) || []
        );
      }
    }

    if (isSingleKpiTemplate(template)) {
      return template.selectableKpiTypeIds?.map((id) => ({ value: id, label: kpiTypes?.byId[id].name || "" })) || [];
    }

    if (isMultipleKpiTemplate(template)) {
      return template.selectableKpiGroupIds?.map((id) => ({ value: id, label: kpiGroups?.byId[id].name || "" })) || [];
    }

    if (isWedgeTemplate(template)) {
      return (
        template.breakdowns?.map((breakdown) => ({
          value: breakdown.id as StandKpiType | StandKpiGroup | string,
          label: breakdown.name || "",
        })) || []
      );
    }
    if (isDrillingProductivityLensTemplate(template)) {
      return drillingProductivityMap.map((i) => ({
        value: i.id,
        label: i.name,
      }));
    }
    return [];
  }, [kpiGroups?.byId, kpiTypes?.byId, template]);

  const getSelectedKpiOptionFromId = useCallback(() => {
    if (isWedgeTemplate(template)) {
      return filterOptions.find((option) => option.value === breakdown?.id);
    }
    if (isDrillingProductivityLensTemplate(template)) {
      return filterOptions.find(
        (option) =>
          option.value === ("drillingProductivityType" in userLens ? userLens?.drillingProductivityType : null),
      );
    }
    return filterOptions.find((option) => option.value === (kpiType?.id || kpiGroup?.id));
  }, [template, filterOptions, breakdown?.id, userLens, kpiType?.id, kpiGroup?.id]);

  const [selectedKpiOption, setSelectedKpiOption] = useState(getSelectedKpiOptionFromId());
  useEffect(() => setSelectedKpiOption(getSelectedKpiOptionFromId()), [getSelectedKpiOptionFromId]);
  const [kpiLoading, setKpiLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const isFetchingLenses = useIsFetching({ queryKey: [UserLensesQueryKey], exact: false });
  const isFetchingKpiGroupPivotFacts = useIsFetching({
    queryKey: [{ uid: RequestUID.kpiGroupPivotFacts }],
    exact: false,
  });

  const isFetchingKpiTypeFacts = useIsFetching({ queryKey: [{ uid: RequestUID.kpiTypeFacts }], exact: false });

  const isFetchingKpiGroupStackedFacts = useIsFetching({
    queryKey: [{ uid: RequestUID.kpiGroupStackedFacts }],
    exact: false,
  });

  const isFetching = !!(
    isFetchingLenses ||
    isFetchingKpiGroupPivotFacts ||
    isFetchingKpiTypeFacts ||
    isFetchingKpiGroupStackedFacts
  );

  const getKpiUpdatedLens: (newId: number | string) => UserLensDto = (newId: number | string) => {
    if (isPivotKpiTypeLensTemplate(template)) {
      return {
        ...userLens,
        kpiTypeId: newId as StandKpiType,
      };
    }
    if (isPivotKpiGroupLensTemplate(template)) {
      return {
        ...userLens,
        kpiGroupId: newId as StandKpiGroup,
      };
    }

    if (isSingleKpiTemplate(template)) {
      return {
        ...userLens,
        kpiTypeId: newId as StandKpiType,
      };
    }
    if (isMultipleKpiTemplate(template)) {
      return {
        ...userLens,
        kpiGroupId: newId as StandKpiGroup,
      };
    }
    if (isWedgeTemplate(template)) {
      return {
        ...userLens,
        breakdownId: newId as StandKpiGroup,
      };
    }
    if (isDrillingProductivityLensTemplate(template)) {
      return {
        ...userLens,
        drillingProductivityType: newId as DrillingProductivityType,
      };
    }
    return {
      ...userLens,
      kpiTypeId: newId as StandKpiType,
    };
  };

  return selectedKpiOption ? (
    <Popover
      content={
        <Styled.StyledMenu>
          {/* Remove unknown from the selectable options */}
          {filterOptions.map((option) =>
            option.value.toString() === "Unknown" ? null : (
              <Styled.StyledItem
                key={option.value}
                $isActive={(option.value === selectedKpiOption.value).toString()}
                onClick={
                  option.value === selectedKpiOption.value
                    ? undefined
                    : () => {
                        Track.clickEvent("Lens Detailed - Changing KPI", {
                          "KPI Value": option.label,
                          "Lens Name": template?.name,
                        });
                        setVisible(false);
                        setSelectedKpiOption(option);
                        handleLensUpdate.mutate(getKpiUpdatedLens(option.value));
                      }
                }
              >
                {option.label}
              </Styled.StyledItem>
            ),
          )}
        </Styled.StyledMenu>
      }
      open={visible}
      onOpenChange={(visible) => {
        setVisible(visible);
      }}
      placement="bottomLeft"
      trigger={[isDisabled ? "none" : "click"]}
    >
      <Styled.StyledButton isDisabled={isDisabled} isActive={visible} loading={kpiLoading || isFetching}>
        {selectedKpiOption?.label ?? "Label not found"}
        <Styled.StyledIcon up={visible}>
          <PDComponent.SvgIcon name="chevronDown" />
        </Styled.StyledIcon>
      </Styled.StyledButton>
    </Popover>
  ) : null;
}

export default KpiSelect;
