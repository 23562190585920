import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { StandKpiCommentGroupDto } from "apis/oag";
import { StandKpiCommentsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDStandCommentsKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const commentsApi = new StandKpiCommentsApi(apiConfig);

export function useStandComments(
  lensId: number,
  wellId: number[],
  options?: UseQueryOptions<StandKpiCommentGroupDto[]>,
) {
  const StandCommentsQueryKey: PDStandCommentsKey = {
    wellId: wellId,
    lensId: lensId,
    uid: RequestUID.standComments,
    type: PDQueryType.STAND_COMMENTS,
  };

  return useSuspenseQuery<StandKpiCommentGroupDto[]>({
    queryKey: [StandCommentsQueryKey],
    queryFn: () =>
      commentsApi.apiStandKpiCommentsLensLensIdPut({
        lensId: lensId,
        wellIdListDto: { wellIds: wellId },
      }),
    ...options,
  });
}
