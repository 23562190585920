import type { BitIndicatorProps, IndicatorPosition } from "components/TvDChart/types";
import styled, { css } from "styled-components";
import colors from "utils/colors";
export const EndOfWellIndicator = styled.div<IndicatorPosition>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  opacity: ${(props) => (props.isSelected === "true" ? 1 : 0.5)};
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  background-color: ${colors.white};
  border: 2.5px solid ${colors.well_color};
  ${(props) =>
    props.$realTime === "true"
      ? "animation: pulsate 2.5s infinite"
      : `box-shadow: 0px 0px 2.5px 3.5px ${colors.well_color_transparent};`};
  @keyframes pulsate {
    0% {
      box-shadow: 0px 0px 0px 0px ${colors.well_color_transparent};
    }
    50% {
      box-shadow: 0px 0px 2.5px 3.5px ${colors.well_color_transparent};
    }
    100% {
      box-shadow: 0px 0px 0px 0px ${colors.well_color_transparent};
    }
  }
  pointer-events: none;
`;
export const BitDepthIndicator = styled.div<BitIndicatorProps>`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  position: absolute;
  top: ${(props) => props.bitIndicatorTop}px;
  left: ${(props) => props.bitIndicatorLeft}px;
  background-color: ${colors.well_color};
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

export const AddIconWrapper = styled.div<{
  hasPointerEvents?: string;
  top?: string;
  isTimelineAdd?: string;
  backgroundColor: string;
  isAnimated?: string;
}>`
  position: absolute;
  // 100%(height of tooltip) + 5px padding between tooltip and line
  top: ${({ top }) => top || "calc(100% + 5px)"};
  pointer-events: ${({ hasPointerEvents }) => hasPointerEvents || "none"};
  background-color: ${(props) => props.backgroundColor};
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: ${colors.white};
  ${(props) =>
    props.isAnimated === "true"
      ? css`
          box-shadow: 0px 0px 1px 6px ${props.theme.themeStyle.colors.primary_button_bg}4d;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        `
      : ""}
`;

export const AxisIndicator = styled.div<{ backgroundColor: string }>`
  position: absolute;
  // 100%(height of tooltip) + 5px padding between tooltip and line
  top: calc(100% + 10px);
  box-shadow: 0px -0px 0px 2px ${(props) => props.backgroundColor}4d;
  pointer-events: none;
  background-color: ${(props) => props.backgroundColor};
  height: 10px;
  width: 10px;
  border-radius: 50%;
  color: ${colors.white};
  &::before {
    content: "";
    position: absolute;
    transform: translateX(50%) translateY(50%);
    width: 5px;
    height: 5px;
    background-color: ${colors.white};
    border-radius: 50%;
  }
`;
