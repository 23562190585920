import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { AllWellsDataFeedQueryDto } from "apis/oag";
import { DataFeedsApi } from "apis/oag";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { useLinkedFilters } from "components/RigsHeader/useLinkedFilters";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useDashboardType } from "hooks/useDashboardType";
import { useCallback, useEffect, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { useDebounce } from "use-debounce";
import { API_REFRESH_INTERVAL, apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateOnlyDto } from "utils/helper";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const DataFeeds = new DataFeedsApi(apiConfig);

export const useAllWellsDataFeed = (isEnabled: boolean, optionRQ?: UseQueryOptions<boolean>) => {
  const { isEvergreen } = useDashboardType();
  const searchState = useAppSelector((state) => state.allWells.search);
  const statusState = useAppSelector((state) => state.allWells.status);
  const startDateState = useAppSelector((state) => state.allWells.startDate);
  const endDateState = useAppSelector((state) => state.allWells.endDate);
  const queryClient = useQueryClient();
  const lastAllWellRefreshDate = useAppSelector((state) => state.allWells.lastAllWellRefreshDate);
  const {
    selectedValues,
    options,
    isLoading
  } = useLinkedFilters({
    keepEvengreenOnly: isEvergreen,
    storeName: isEvergreen ? "evergreenWells" : "allWells",
  })

  const [realTimeDataState] = useStateQuery<RealTimeDataEnum>(
    URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.UNAVAILABLE,
  );

  const refreshAllWells = useCallback(
    (shallRefresh: string) => {
      if (shallRefresh === "true") {
        queryClient.invalidateQueries({
          queryKey: [{ type: PDQueryType.WELL_INCLUDED_WELLS_KPIS }],
          ...{ exact: false },
        });
        queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.WELL_SUMMARIES }], ...{ exact: false } });
      }
    },
    [queryClient],
  );
  const requestQuery: AllWellsDataFeedQueryDto = useMemo(
    () => ({
      includeEverGreenOnly: false,
      lastWellRefreshDate: lastAllWellRefreshDate || defaultDateDto.from,
      search: searchState,
      operatorIds: selectedValues.operators === null ? null : options?.operators.map((op) => op.id),
      rigIds: selectedValues.rigs === null ? null : options?.rigs.map((rig) => rig.id),
      from: startDateState ? dateToDateOnlyDto(startDateState) : defaultDateDto.from,
      to: endDateState ? dateToDateOnlyDto(endDateState) : defaultDateDto.to,
      formationIds: selectedValues.formations === null ? null : options?.formations.map((f) => f.id),
      selectedFilters: {
        includeFlatTime: true,
        includeRotatingTime: true,
        includeSlidingTime: true,
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNoneAlphaRigs: true,
        selectedWellStatusTypes: statusState,
      },
    }),
    [
      endDateState,
      lastAllWellRefreshDate,
      options?.formations,
      options?.operators,
      options?.rigs,
      searchState,
      selectedValues.formations,
      selectedValues.operators,
      selectedValues.rigs,
      startDateState,
      statusState],
  );

  const [debouncedQuery] = useDebounce(requestQuery, 1000, {
    leading: true,
    equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b),
  });

  const { data } = useQuery<boolean>({
    queryKey: [{ uid: RequestUID.wellsDataFeed, debouncedQuery }],
    queryFn: () => {
      if (!isEnabled || !lastAllWellRefreshDate || realTimeDataState !== RealTimeDataEnum.ACTIVE)
        return Promise.resolve(false);
      return DataFeeds.apiDataFeedsWellsPut({
        allWellsDataFeedQueryDto: debouncedQuery,
      });
    },
    ...optionRQ,
    refetchOnMount: false,
    enabled:
      isEnabled &&
      realTimeDataState === RealTimeDataEnum.ACTIVE &&
      !!lastAllWellRefreshDate &&
      Object.values(isLoading).some((loading) => !loading),
    refetchIntervalInBackground: true,
    refetchInterval: API_REFRESH_INTERVAL,
  });
  useEffect(() => {
    if (data) refreshAllWells(data.toString());
  }, [data, refreshAllWells]);
};
