import { useSuspenseQuery } from "@tanstack/react-query";
import { PivotFactOrderApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import { secondsInDay } from "utils/common";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";
const pivotFactOrder = new PivotFactOrderApi(apiConfig);
const QueryKey: PDUniqueQueryKey = { uid: RequestUID.pivotFactsOrder };

export const usePivotFactsOrder = () => {
  return useSuspenseQuery({
    queryKey: [QueryKey],
    queryFn: () => pivotFactOrder.apiPivotFactOrderGet(),
    ...{
      staleTime: secondsInDay,
    },
  });
};
