/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WedgeSummaryValuesDto } from './WedgeSummaryValuesDto';
import {
    WedgeSummaryValuesDtoFromJSON,
    WedgeSummaryValuesDtoFromJSONTyped,
    WedgeSummaryValuesDtoToJSON,
} from './WedgeSummaryValuesDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { WedgeComparisonWellDto } from './WedgeComparisonWellDto';
import {
    WedgeComparisonWellDtoFromJSON,
    WedgeComparisonWellDtoFromJSONTyped,
    WedgeComparisonWellDtoToJSON,
} from './WedgeComparisonWellDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { WedgeSummaryDto } from './WedgeSummaryDto';
import {
    WedgeSummaryDtoFromJSON,
    WedgeSummaryDtoFromJSONTyped,
    WedgeSummaryDtoToJSON,
} from './WedgeSummaryDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface WedgeComparisonDto
 */
export interface WedgeComparisonDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof WedgeComparisonDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof WedgeComparisonDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeComparisonDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WedgeComparisonDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {Array<WedgeComparisonWellDto>}
     * @memberof WedgeComparisonDto
     */
    comparisons?: Array<WedgeComparisonWellDto> | null;
    /**
     * 
     * @type {WedgeSummaryDto}
     * @memberof WedgeComparisonDto
     */
    summary: WedgeSummaryDto;
    /**
     * 
     * @type {Array<WedgeSummaryValuesDto>}
     * @memberof WedgeComparisonDto
     */
    summaryByKpiNames?: Array<WedgeSummaryValuesDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof WedgeComparisonDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the WedgeComparisonDto interface.
 */
export function instanceOfWedgeComparisonDto(value: object): value is WedgeComparisonDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function WedgeComparisonDtoFromJSON(json: any): WedgeComparisonDto {
    return WedgeComparisonDtoFromJSONTyped(json, false);
}

export function WedgeComparisonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeComparisonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'comparisons': json['Comparisons'] == null ? undefined : ((json['Comparisons'] as Array<any>).map(WedgeComparisonWellDtoFromJSON)),
        'summary': WedgeSummaryDtoFromJSON(json['Summary']),
        'summaryByKpiNames': json['SummaryByKpiNames'] == null ? undefined : ((json['SummaryByKpiNames'] as Array<any>).map(WedgeSummaryValuesDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function WedgeComparisonDtoToJSON(value?: WedgeComparisonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'Comparisons': value['comparisons'] == null ? undefined : ((value['comparisons'] as Array<any>).map(WedgeComparisonWellDtoToJSON)),
        'Summary': WedgeSummaryDtoToJSON(value['summary']),
        'SummaryByKpiNames': value['summaryByKpiNames'] == null ? undefined : ((value['summaryByKpiNames'] as Array<any>).map(WedgeSummaryValuesDtoToJSON)),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

