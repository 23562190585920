import type {
  ParameterByDepthUserLensTrackItemDto,
  StickSlipByDepthUserLensTrackItemDto,
  SystemOfMeasurementType,
} from "apis/oag";
import type { ITrackUnitTypes } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/utils/trackUnitTypes";
import type { TracksResponse } from "hooks/useTracks";
import { useCallback, useEffect, useRef, useState } from "react";
import { transformGlobalUOM } from "reducers/globalReducer";
import { useAppSelector } from "reducers/store";
import { IUnitSystem } from "reducers/types";
import type { IValueWithUnit } from "utils/interfaces/Well/interfaces";

export const useTrackUnits = ({
  tracks,
  selectedTrackItems,
  trackUnitTypes,
}: {
  tracks?: TracksResponse;
  trackUnitTypes: ITrackUnitTypes;
  selectedTrackItems: (ParameterByDepthUserLensTrackItemDto | StickSlipByDepthUserLensTrackItemDto)[];
}) => {
  const globalUOM = useAppSelector((state) => state.global.unit);
  const selectedUom = useRef<{ [key: number]: SystemOfMeasurementType }>({});
  const prevGlobalUOM = useRef<IUnitSystem | null>(null);

  useEffect(() => {
    if (prevGlobalUOM.current !== globalUOM) {
      selectedUom.current = {};
      prevGlobalUOM.current = globalUOM;
    }
  }, [globalUOM, tracks, selectedTrackItems, selectedUom]);

  const [selectedUnits, setSelectedUnits] = useState<Record<number, IValueWithUnit>>({});

  const getUnits = useCallback(
    (nTracks: ParameterByDepthUserLensTrackItemDto[]) => {
      return (
        nTracks?.reduce<Record<number, IValueWithUnit>>((acc, trackItem) => {
          if (!tracks || !trackItem.trackId) {
            return acc;
          }

          const track = tracks.byId[trackItem.trackId];

          if (!track || !track.name || !track.id) {
            return acc;
          }

          const trackUnits = trackUnitTypes?.[track.name];

          const key =
            Object.keys(trackUnits ?? {}).find((key) => {
              if (!selectedUom.current[trackItem.trackId] && trackItem.isUomUnlocked) {
                return trackUnits?.[key]?.unitSystem === globalUOM;
              }
              return (
                transformGlobalUOM(trackUnits?.[key]?.unitSystem || IUnitSystem.METRIC) ===
                trackItem.systemOfMeasurementType
              );
            }) || Object.keys(trackUnits ?? {})[0];
          acc[track.id] = trackUnits?.[key];
          return acc;
        }, {}) || {}
      );
    },
    [tracks, globalUOM, selectedUom, trackUnitTypes],
  );

  useEffect(() => {
    setSelectedUnits(getUnits(selectedTrackItems));
  }, [selectedTrackItems, getUnits, globalUOM]);

  return { selectedUnits, selectedUom };
};
