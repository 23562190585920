import { useIsFetching } from "@tanstack/react-query";
import { DimensionType } from "apis/oag";
import { Spinner } from "components/Loader/Spinner";
import DrillingDetails from "components/TvDChart/components/DrillingDetails";
import { useMemo } from "react";
import { useAppSelector } from "reducers/store";
import styled from "styled-components";
import colors from "utils/colors";
import { secondsInDay } from "utils/common";
import { Col, Row } from "utils/componentLibrary";
import { PdCol } from "utils/componentLibrary/Col";
import { useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

const StyledRow = styled(Row)`
  box-shadow: 0 1px 2px 0
    ${({ theme }) => (theme.isDark ? theme.themeStyle.colors.tertiary_bg : colors.buttons_clicked)};
  border-radius: 4px;
  margin: 0;
  > ${PdCol}:last-child {
    padding-right: 12px;
    padding-left: 0px;
  }
  > ${PdCol}:first-child {
    padding-left: 12px;
  }
`;

export const DrillingDetailsHeader = () => {
  const wellInfo = useAppSelector((state) => state.timeline.currentWellDetails);
  const depthUOM = useUOM(DimensionType.Metres);
  const isFetchingTvD = useIsFetching({ queryKey: [{ type: PDQueryType.WELL_OVERVIEW }], exact: false });
  const wellDetails: {
    [key: string]: string;
  } = useMemo(() => {
    return {
      Hole: !wellInfo ? "" : depthUOM.display(wellInfo?.drillingDetails?.lastHoleDepth).replace(/\.[-9]{2} /, " "),
      Bit: !wellInfo ? "" : depthUOM.display(wellInfo?.drillingDetails?.lastBitDepth).replace(/\.[0-9]{2} /, " "),
      Day: !wellInfo ? "" : (wellInfo?.drillingDetails?.cumulativeDuration / secondsInDay).toFixed(2),
      Time: !wellInfo
        ? ""
        : formatTime(wellInfo?.drillingDetails?.lastWellFactUpdateAt, { formatStr: "MM/DD/YYYY hh:mm:ss A" }),
      UTC: !wellInfo?.drillingDetails?.lastWellFactUpdateAt
        ? ""
        : ` ${wellInfo.drillingDetails?.lastWellFactUpdateAt.minutesOffset / 60}`,
    };
  }, [depthUOM, wellInfo]);
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  return (
    <StyledRow gutter={24}>
      {isFetchingTvD ? (
        <Col
          style={{
            backgroundColor: themeColors.off_bg,
            height: 36,
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spinner color={themeColors.off_secondary_bg} />
        </Col>
      ) : (
        wellInfo?.drillingDetails &&
        Object.keys(wellDetails).map((key) => {
          return wellDetails[key] ? (
            <DrillingDetails
              key={key}
              muted={key === "UTC"}
              label={key === "Time" ? wellDetails[key] : `${wellDetails[key] ? key : ""} ${wellDetails[key]}`}
            />
          ) : null;
        })
      )}
    </StyledRow>
  );
};
