/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperatorStatusType } from './OperatorStatusType';
import {
    OperatorStatusTypeFromJSON,
    OperatorStatusTypeFromJSONTyped,
    OperatorStatusTypeToJSON,
} from './OperatorStatusType';

/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    activeProfileId: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    providesCrossOperatorClaims: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    userCount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    activeUserCount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    operatorRigCount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    operatorWellCount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    claimedWellCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    ongoingAccess: boolean;
    /**
     * 
     * @type {OperatorStatusType}
     * @memberof AccountDto
     */
    status: OperatorStatusType;
}



/**
 * Check if a given object implements the AccountDto interface.
 */
export function instanceOfAccountDto(value: object): value is AccountDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('activeProfileId' in value) || value['activeProfileId'] === undefined) return false;
    if (!('providesCrossOperatorClaims' in value) || value['providesCrossOperatorClaims'] === undefined) return false;
    if (!('userCount' in value) || value['userCount'] === undefined) return false;
    if (!('activeUserCount' in value) || value['activeUserCount'] === undefined) return false;
    if (!('operatorRigCount' in value) || value['operatorRigCount'] === undefined) return false;
    if (!('operatorWellCount' in value) || value['operatorWellCount'] === undefined) return false;
    if (!('claimedWellCount' in value) || value['claimedWellCount'] === undefined) return false;
    if (!('ongoingAccess' in value) || value['ongoingAccess'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function AccountDtoFromJSON(json: any): AccountDto {
    return AccountDtoFromJSONTyped(json, false);
}

export function AccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'activeProfileId': json['ActiveProfileId'],
        'providesCrossOperatorClaims': json['ProvidesCrossOperatorClaims'],
        'userCount': json['UserCount'],
        'activeUserCount': json['ActiveUserCount'],
        'operatorRigCount': json['OperatorRigCount'],
        'operatorWellCount': json['OperatorWellCount'],
        'claimedWellCount': json['ClaimedWellCount'],
        'ongoingAccess': json['OngoingAccess'],
        'status': OperatorStatusTypeFromJSON(json['Status']),
    };
}

export function AccountDtoToJSON(value?: AccountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'ActiveProfileId': value['activeProfileId'],
        'ProvidesCrossOperatorClaims': value['providesCrossOperatorClaims'],
        'UserCount': value['userCount'],
        'ActiveUserCount': value['activeUserCount'],
        'OperatorRigCount': value['operatorRigCount'],
        'OperatorWellCount': value['operatorWellCount'],
        'ClaimedWellCount': value['claimedWellCount'],
        'OngoingAccess': value['ongoingAccess'],
        'Status': OperatorStatusTypeToJSON(value['status']),
    };
}

