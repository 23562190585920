import { GridLayout, StyledCard } from "atoms/common";
import { Loader } from "components/Loader";
import Header from "components/RigsHeader";
import { useRigSummaries } from "hooks/useRigSummaries";
import { Suspense, useCallback, useLayoutEffect, useState } from "react";
import type { IFilterOptions } from "reducers/rigsCommonReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { usePageView } from "services/Mixpanel";
import { zIndexLayer } from "utils/zIndex";

import FleetPerformanceSection from "./components/FleetPerformance";
import { FleetPerformanceMetricSelectionContextProvider } from "./components/FleetPerformanceMetricSelectionContext";
import { RigList } from "./RigList/RigList";
import { SelectedRigsContextProvider } from "./RigList/SelectedRigsContext";
import { getAllRigsOptions, RigOptionsContext } from "./utils";

export const FleetPerformance = () => {
  usePageView("Fleet Performance");
  const { data: summaries } = useRigSummaries();

  const dispatch = useAppDispatch();

  const setFilters = useCallback(
    (filters: IFilterOptions | null) => {
      dispatch({ type: "RIGS_COMMON_SET_FILTERS", payload: filters });
    },
    [dispatch],
  );

  const rigsCommonFilters = useAppSelector((state) => state.rigsCommon.filters);

  useLayoutEffect(() => {
    dispatch({
      type: "RIGS_COMMON_FULL_WELLS_INCLUDED",
      payload: true,
    });
    dispatch({ type: "RESET_RIG_DASHBOARD" });
    dispatch({ type: "RESET_RIGS_COMMON_FILTERS_90_DAYS" });
  }, [dispatch]);

  const [rigOptions, setRigOptions] = useState(getAllRigsOptions());

  return (
    <SelectedRigsContextProvider>
      <Header filters={rigsCommonFilters} setFilters={setFilters} totalRigs={summaries?.length ?? 0} />
      <GridLayout>
        <StyledCard>
          <RigList />
        </StyledCard>
        <StyledCard>
          <Suspense fallback={<Loader centered zIndex={zIndexLayer.mars} position="absolute" />}>
            <FleetPerformanceMetricSelectionContextProvider>
              <RigOptionsContext.Provider value={[rigOptions, setRigOptions]}>
                <FleetPerformanceSection />
              </RigOptionsContext.Provider>
            </FleetPerformanceMetricSelectionContextProvider>
          </Suspense>
        </StyledCard>
      </GridLayout>
    </SelectedRigsContextProvider>
  );
};
