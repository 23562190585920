import { PDComponent } from "components/PDComponents";

import * as Styled from "./style";

export const NoContent = ({ text }: { text: string }) => {
  return (
    <Styled.Container>
      <Styled.Circle>
        <PDComponent.SvgIcon name="notificationLogo" style={{ fontSize: 80 }} />
        <h2>{text}</h2>
      </Styled.Circle>
    </Styled.Container>
  );
};
