import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { WellInfoDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDWellBasicQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const wells = new WellsApi(apiConfig);

export function useWellBasic(id: number, options?: Omit<UseQueryOptions<WellInfoDto>, "queryKey" | "queryFn">) {
  const WellBasicQueryKey: PDWellBasicQueryKey = {
    uid: RequestUID.wellBasic,
    wellId: id,
    type: PDQueryType.WELL_BASIC,
  };

  return useSuspenseQuery<WellInfoDto>({
    queryKey: [WellBasicQueryKey],
    queryFn: () =>
      options?.enabled === false
        ? Promise.resolve({} as WellInfoDto)
        : wells.apiWellsIdGet({
            id: id,
          }),
    ...options,
  });
}
