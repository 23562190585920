/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationCategoryType } from './OperationCategoryType';
import {
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';
import type { WedgeBreakdownSlice } from './WedgeBreakdownSlice';
import {
    WedgeBreakdownSliceFromJSON,
    WedgeBreakdownSliceFromJSONTyped,
    WedgeBreakdownSliceToJSON,
} from './WedgeBreakdownSlice';

/**
 * 
 * @export
 * @interface WedgeBreakdownDto
 */
export interface WedgeBreakdownDto {
    /**
     * 
     * @type {number}
     * @memberof WedgeBreakdownDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WedgeBreakdownDto
     */
    name?: string | null;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof WedgeBreakdownDto
     */
    operationCategory: OperationCategoryType;
    /**
     * 
     * @type {Array<WedgeBreakdownSlice>}
     * @memberof WedgeBreakdownDto
     */
    slices?: Array<WedgeBreakdownSlice> | null;
}



/**
 * Check if a given object implements the WedgeBreakdownDto interface.
 */
export function instanceOfWedgeBreakdownDto(value: object): value is WedgeBreakdownDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('operationCategory' in value) || value['operationCategory'] === undefined) return false;
    return true;
}

export function WedgeBreakdownDtoFromJSON(json: any): WedgeBreakdownDto {
    return WedgeBreakdownDtoFromJSONTyped(json, false);
}

export function WedgeBreakdownDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeBreakdownDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'operationCategory': OperationCategoryTypeFromJSON(json['OperationCategory']),
        'slices': json['Slices'] == null ? undefined : ((json['Slices'] as Array<any>).map(WedgeBreakdownSliceFromJSON)),
    };
}

export function WedgeBreakdownDtoToJSON(value?: WedgeBreakdownDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'OperationCategory': OperationCategoryTypeToJSON(value['operationCategory']),
        'Slices': value['slices'] == null ? undefined : ((value['slices'] as Array<any>).map(WedgeBreakdownSliceToJSON)),
    };
}

