/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingSplitsDto
 */
export interface DrillingSplitsDto {
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    rotatingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    slidingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    rotatingDistance: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    slidingDistance: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    readonly rotatingDurationSplit: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    readonly slidingDurationSplit: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    readonly rotatingDistanceSplit: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingSplitsDto
     */
    readonly slidingDistanceSplit: number;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingSplitsDto
     */
    readonly isEmpty: boolean;
}

/**
 * Check if a given object implements the DrillingSplitsDto interface.
 */
export function instanceOfDrillingSplitsDto(value: object): value is DrillingSplitsDto {
    if (!('rotatingDuration' in value) || value['rotatingDuration'] === undefined) return false;
    if (!('slidingDuration' in value) || value['slidingDuration'] === undefined) return false;
    if (!('rotatingDistance' in value) || value['rotatingDistance'] === undefined) return false;
    if (!('slidingDistance' in value) || value['slidingDistance'] === undefined) return false;
    if (!('rotatingDurationSplit' in value) || value['rotatingDurationSplit'] === undefined) return false;
    if (!('slidingDurationSplit' in value) || value['slidingDurationSplit'] === undefined) return false;
    if (!('rotatingDistanceSplit' in value) || value['rotatingDistanceSplit'] === undefined) return false;
    if (!('slidingDistanceSplit' in value) || value['slidingDistanceSplit'] === undefined) return false;
    if (!('isEmpty' in value) || value['isEmpty'] === undefined) return false;
    return true;
}

export function DrillingSplitsDtoFromJSON(json: any): DrillingSplitsDto {
    return DrillingSplitsDtoFromJSONTyped(json, false);
}

export function DrillingSplitsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingSplitsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'rotatingDuration': json['RotatingDuration'],
        'slidingDuration': json['SlidingDuration'],
        'rotatingDistance': json['RotatingDistance'],
        'slidingDistance': json['SlidingDistance'],
        'rotatingDurationSplit': json['RotatingDurationSplit'],
        'slidingDurationSplit': json['SlidingDurationSplit'],
        'rotatingDistanceSplit': json['RotatingDistanceSplit'],
        'slidingDistanceSplit': json['SlidingDistanceSplit'],
        'isEmpty': json['IsEmpty'],
    };
}

export function DrillingSplitsDtoToJSON(value?: Omit<DrillingSplitsDto, 'RotatingDurationSplit'|'SlidingDurationSplit'|'RotatingDistanceSplit'|'SlidingDistanceSplit'|'IsEmpty'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'RotatingDuration': value['rotatingDuration'],
        'SlidingDuration': value['slidingDuration'],
        'RotatingDistance': value['rotatingDistance'],
        'SlidingDistance': value['slidingDistance'],
    };
}

