import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import type { GeneratorSlotType, RigPowerFactDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { AVAILABLE_POWER_ID } from "components/Lenses/ContainerLens/RigPower/RigPowerSimple/useAdditionalLegendItems";
import { bisector } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { useOperationClasses } from "hooks/useOperationClasses";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { OnRefChangeType } from "react-resize-detector/build/types/types";
import { useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

export const DEFAULT_DATE_FORMAT = "MM/D/YY HH:mm:ss";

export const usePowerConsumptionByOperationTooltip = ({
  containerRef,
  xScale,
  yScale,
  legend,
  legendItems,
  series,
  isPointerInsideChart,
  plotWidth,
  plotHeight,
}: {
  containerRef: OnRefChangeType<HTMLDivElement> | React.MutableRefObject<HTMLDivElement>;
  xScale: ScaleLinear<number, number, never>;
  legend: Array<GeneratorSlotType | number> | null;
  legendItems: LegendItem[];
  yScale: ScaleLinear<number, number, never>;
  series: RigPowerFactDto[];
  isPointerInsideChart: boolean;
  plotWidth: number;
  plotHeight: number;
}) => {
  const [pointerPosition, setPointerPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const availablePowerAvailable = legendItems.find(
    (legendItem) => legendItem.id === AVAILABLE_POWER_ID && legendItem.isEnabled,
  );

  const uomValue = useUOM(DimensionType.Watts);
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const handlePointerMove = useCallback((event: React.PointerEvent<SVGSVGElement>) => {
    const { left, top } = event.currentTarget.getBoundingClientRect();
    const x = ("clientX" in event ? event.clientX : 0) - left;
    const y = ("clientY" in event ? event.clientY : 0) - top;
    setPointerPosition({ x, y });
  }, []);

  const { data: operationClasses } = useOperationClasses();
  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent: ({ tooltipData }: { tooltipData?: RigPowerFactDto }) => {
      return (
        <>
          <TooltipHighlightValue style={{ fontWeight: "bold", fontSize: 12 }}>
            {operationClasses.find((opClass) => opClass.id === tooltipData?.operationClassId)?.name}
          </TooltipHighlightValue>
          <TooltipHighlightValue style={{ fontSize: 12, color: themeColors.disabled_typography }}>
            {uomValue.display(tooltipData?.totalPower, { fractionDigits: 0 })}
          </TooltipHighlightValue>
          <TooltipHighlightValue style={{ fontSize: 12, color: themeColors.disabled_typography }}>
            Available Power: {uomValue.display(tooltipData?.availablePower, { fractionDigits: 0 })}
          </TooltipHighlightValue>

          {tooltipData?.at ? (
            <TooltipHighlightValue style={{ color: themeColors.disabled_typography, fontSize: 12 }}>
              {formatTime(tooltipData?.at, { formatStr: DEFAULT_DATE_FORMAT })}
            </TooltipHighlightValue>
          ) : null}
        </>
      );
    },
  });

  const tooltipPoint: RigPowerFactDto | null = useMemo(() => {
    if (!series) return null;

    if (pointerPosition.x > plotWidth) {
      return null;
    }

    const x0 = xScale.invert(pointerPosition.x);

    let extremitiesBisectFix = 0;

    if (pointerPosition.x < 30) {
      // To make sure we can select both points at the edge of the graph, normal  bisect leaves out either one or the other
      // Artificially adding mouse X if it's close to the plot edges to get correct edge index
      extremitiesBisectFix = -0.001;
    } else if (Math.abs(pointerPosition.x - plotWidth) < 10) {
      extremitiesBisectFix = 0.001;
    }

    const index = bisector<RigPowerFactDto, number>((p) => p.index).left(series, x0 + extremitiesBisectFix);

    if (index >= 0 && index < series.length && series[index].index) {
      return series[index];
    }

    return null;
  }, [plotWidth, pointerPosition.x, series, xScale]);

  useEffect(() => {
    const top =
      yScale(
        Math.max(
          tooltipPoint?.availablePower && availablePowerAvailable ? tooltipPoint.availablePower : -Infinity,
          tooltipPoint?.slices ? Math.max(...tooltipPoint?.slices.map((slice) => slice.positionalValue || 0)) : 0,
        ),
      ) || 0;

    return isPointerInsideChart && tooltipPoint
      ? showTooltip({
          tooltipLeft: xScale(tooltipPoint.index) || 0,
          tooltipTop: top,
          tooltipData: tooltipPoint,
        })
      : hideTooltip();
  }, [
    availablePowerAvailable,
    hideTooltip,
    isPointerInsideChart,
    plotHeight,
    pointerPosition,
    showTooltip,
    tooltipPoint,
    xScale,
    yScale,
  ]);

  const Tooltip =
    tooltipPoint && isPointerInsideChart && (legend === null || legend.includes(tooltipPoint.operationClassId)) ? (
      <>
        {tooltipElement}
        <Group>
          <Line
            from={{
              x: xScale(tooltipPoint.index),
              y: plotHeight,
            }}
            to={{
              x: xScale(tooltipPoint.index),
              y: 0,
            }}
            strokeOpacity={0.3}
            stroke={themeColors.primary_typography}
            strokeWidth={2}
          />

          <>
            <circle
              cx={xScale(tooltipPoint.index)}
              cy={yScale(tooltipPoint.totalPower)}
              r={3}
              fill={themeColors.black_white}
            />
          </>
          {tooltipPoint?.availablePower && availablePowerAvailable ? (
            <circle
              cx={xScale(tooltipPoint.index)}
              cy={yScale(tooltipPoint.availablePower)}
              r={3}
              fill={themeColors.black_white}
            />
          ) : null}
        </Group>
      </>
    ) : null;
  return { handlePointerMove, tooltipPoint, tooltipElement: Tooltip };
};
