import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar, BarRounded } from "@visx/shape";
import { ResultDataState, VisualAidType } from "apis/oag";
import { AverageLabel, AverageLine } from "components/Lenses/common/AverageLine";
import { Chart } from "components/Lenses/common/Chart";
import { Label } from "components/Lenses/common/Label";
import { MedianLabel, MedianLine } from "components/Lenses/common/MedianLine";
import OperationCount, { StyledOperationCountContainer } from "components/Lenses/common/OperationCount";
import TargetLine from "components/Lenses/common/TargetLine";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { TooltipVisualAidInfo, useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { useOutlierThreshold } from "components/Lenses/common/useOutlierThreshold";
import type { SingleKpiChartComparisonProps } from "components/Lenses/ContainerLens/SingleKpi/interfaces";
import { getMinMax, getSVGNormalizedValue } from "components/Lenses/utils";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import { useTargetSegments } from "hooks/charting/useTargetSegments";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import React, { useCallback, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { OPERATION_COUNT_HEIGHT, TOP_LABEL_HEIGHT, TOP_LABEL_MARGIN } from "utils/constants";
import { truncateMiddleString } from "utils/helper";
import { useColors } from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";

export interface TransformedData {
  id: number;
  targetValue: number;
  wellId: number;
}

export function SingleComparisonWellChart({ data, detailed, valueUOM, lens }: SingleKpiChartComparisonProps) {
  const { getColor } = useColors();
  const { data: wellShortInfo } = useWellShortInfoSuspended();

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight: containerHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const chartHeight = getSVGNormalizedValue(containerHeight - (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0));

  const operationCountTopMargin = useMemo(() => {
    if (lens.label && lens.showOperationCount) {
      return TOP_LABEL_MARGIN;
    }
    return 0;
  }, [lens.label, lens.showOperationCount]);

  const topMargin = useMemo(() => {
    if (lens.label) {
      return TOP_LABEL_HEIGHT + operationCountTopMargin;
    }
    return 0;
  }, [lens.label, operationCountTopMargin]);

  const plotHeight = getSVGNormalizedValue(chartHeight - (detailed ? 91 : 36) - topMargin);
  const plotWidth = getSVGNormalizedValue(chartWidth - (detailed ? 76 : 44));
  const transformedData = useMemo(() => {
    if (!data.comparisons) return [];
    return data.comparisons
      .filter((d) => d.dataState === ResultDataState.Valid)
      .map((detail, index) => {
        const totalValue = detail.average;
        return {
          ...detail,
          index,
          id: detail.wellId,
          targetValue: detail.info?.targetAverage,
          operationCount: detail?.operationCount,
          isOutlier: detail.info?.isOutlier,
          totalValue,
        };
      });
  }, [data.comparisons]);

  const categoryScale = useMemo(
    () =>
      scaleBand<number>({
        domain: transformedData.map((i) => i.id),
        // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
        range: [0, plotWidth],
        paddingInner: 0.2,
        paddingOuter: 1,
      }),
    [plotWidth, transformedData],
  );

  const computedTargetSegments = useTargetSegments(transformedData, categoryScale);

  const totalValues = useMemo(
    () => transformedData?.filter((stand) => !stand?.isOutlier).map((stand) => stand.totalValue),
    [transformedData],
  );

  const { outlierThreshold, gradientDefinition, gradientFill } = useOutlierThreshold({
    values: totalValues,
    enabled: lens?.showsOutliers,
    selectedVisualAids: lens?.selectedVisualAids,
    targetSegments: computedTargetSegments,
  });

  const valueScale = useMemo(() => {
    return scaleLinear<number>({
      domain: getMinMax(
        totalValues,
        computedTargetSegments,
        outlierThreshold,
        data.summaryByKpi?.median,
        lens?.selectedVisualAids,
        lens?.outlierFlaggingType,
        lens?.isManualYaxis,
        lens?.yaxisEnd,
        lens?.yaxisStart,
      ),
      range: [plotHeight, 0],
      clamp: true,
    });
  }, [
    totalValues,
    computedTargetSegments,
    outlierThreshold,
    data.summaryByKpi?.median,
    lens?.selectedVisualAids,
    lens?.outlierFlaggingType,
    lens?.isManualYaxis,
    lens?.yaxisEnd,
    lens?.yaxisStart,
    plotHeight,
  ]);

  const valuesDomain = useMemo(() => valueScale.domain(), [valueScale]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<(typeof transformedData)[0]>({
    containerRef,
    renderContent: ({ tooltipData }) => (
      <>
        <TooltipHighlightValue>
          {valueUOM.displayWithAutoDecimals(valuesDomain, tooltipData?.totalValue)}
        </TooltipHighlightValue>

        <TooltipVisualAidInfo
          selectedVisualAids={lens?.selectedVisualAids}
          display={(value) => valueUOM.displayWithAutoDecimals(valuesDomain, value)}
          targetValue={tooltipData?.targetValue}
          averageValue={data.summaryByKpi?.allAverage}
          median={data.summaryByKpi?.median}
        />
        {lens.showOperationCount && !!tooltipData?.operationCount ? (
          <TooltipHighlightValue>Operation count: {tooltipData?.operationCount}</TooltipHighlightValue>
        ) : null}
        <span>{tooltipData?.id ? wellShortInfo?.byId?.[tooltipData?.id]?.name : null}</span>
        {tooltipData?.isOutlier ? <TooltipHighlightValue fontWeight="normal">OUTLIER</TooltipHighlightValue> : null}
      </>
    ),
  });

  const columnWidth = categoryScale.bandwidth();
  const handleMouseOver = useCallback(
    (barData: (typeof transformedData)[0]) => {
      showTooltip({
        tooltipLeft: (categoryScale(barData.id) || 0) + columnWidth / 2,
        tooltipTop: valueScale(barData.totalValue),
        tooltipData: barData,
      });
    },
    [categoryScale, columnWidth, showTooltip, valueScale],
  );

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  return (
    <div
      ref={containerRef}
      style={{
        padding: 0,
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <StyledOperationCountContainer visible={lens.showOperationCount} />
      <svg width={chartWidth} height={chartHeight} style={{ overflow: "visible", userSelect: "none" }}>
        {gradientDefinition}
        <Chart
          topMargin={topMargin}
          operationCountTopMargin={-operationCountTopMargin}
          detailed={detailed}
          isManual={lens?.isManualYaxis}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          plotWidth={plotWidth}
          plotHeight={plotHeight}
          valueScale={valueScale}
          showOperationCount={lens.showOperationCount}
          categoryScale={categoryScale}
          valueUOM={valueUOM}
          tickFormat={(id) => truncateMiddleString(wellShortInfo?.byId[id as number]?.name || "", columnWidth / 10)}
        >
          {transformedData.map((d, index) => {
            const barHeight = Math.min(plotHeight - valueScale(d.totalValue ?? 0), plotHeight);
            const barY = plotHeight - barHeight;
            if (!d.totalValue) return null;
            const value = valueUOM.displayWithAutoDecimals(valuesDomain, d.totalValue, { unit: "" });
            return (
              <React.Fragment key={`${d.id}-bar-${value}`}>
                <BarRounded
                  radius={4}
                  top
                  x={categoryScale(d.id) || 0}
                  y={barY}
                  key={`single-kpi-${d.id}-${index}-${d.totalValue}`}
                  onMouseOver={() => handleMouseOver(d)}
                  onMouseOut={hideTooltip}
                  width={categoryScale.bandwidth()}
                  height={barHeight}
                  fill={d.isOutlier ? themeColors.outlier_bars_transparent : getColor({ key: d.id.toString() })}
                />

                {d.isOutlier ? (
                  <Bar
                    x={(categoryScale(d.id) || 0) - 1}
                    y={barY - 1}
                    width={categoryScale.bandwidth() + 2}
                    onMouseOver={() => handleMouseOver(d)}
                    onMouseOut={hideTooltip}
                    height={barHeight}
                    fill={gradientFill}
                  />
                ) : null}
                <Label
                  barHeight={barHeight}
                  barX={categoryScale(d.id) || 0}
                  barY={barY + (detailed ? 0 : 10)}
                  columnWidth={columnWidth}
                  detailed={detailed}
                  index={index}
                  topLabel={barY !== plotHeight}
                  value={value}
                  label={lens?.label}
                />
                {lens.showOperationCount ? (
                  <OperationCount
                    x={categoryScale(d.id) || 0}
                    width={columnWidth}
                    index={index}
                    detailed={detailed}
                    value={d.operationCount}
                    topMargin={-operationCountTopMargin}
                  />
                ) : null}
              </React.Fragment>
            );
          })}

          {data.summaryByKpi?.allAverage ? (
            <AverageLine
              isVisible={(lens.selectedVisualAids ?? []).includes(VisualAidType.Average)}
              y={valueScale(data.summaryByKpi?.allAverage)}
              x={-RIGHT_AXIS_PADDING}
              width={plotWidth + RIGHT_AXIS_PADDING}
            />
          ) : null}

          <MedianLine
            isVisible={(lens.selectedVisualAids ?? []).includes(VisualAidType.Median)}
            y={valueScale(data.summaryByKpi?.median)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
            computedTargetSegments.map(({ target, lineEnd, lineStart, showTag }, index) => {
              if (!target) return null;
              return (
                <TargetLine
                  key={`target-line-${index}-${lineStart}-${lineEnd}`}
                  start={lineStart}
                  end={lineEnd}
                  y={valueScale(target)}
                  label={valueUOM.displayWithAutoDecimals(valuesDomain, target, { unit: "" })}
                  showTag={!!showTag}
                  detailed={detailed}
                />
              );
            })}
        </Chart>
      </svg>
      {(lens.selectedVisualAids ?? []).includes(VisualAidType.Average) && data.summaryByKpi?.allAverage ? (
        <AverageLabel
          style={{
            top: `${
              valueScale(data.summaryByKpi?.allAverage) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)
            }px`,
          }}
        >
          Average: {valueUOM.displayWithAutoDecimals(valuesDomain, data.summaryByKpi?.allAverage)}
        </AverageLabel>
      ) : null}
      {(lens.selectedVisualAids ?? []).includes(VisualAidType.Median) && data.summaryByKpi?.median ? (
        <MedianLabel
          isDetailed={detailed}
          style={{
            top: `${
              valueScale(data.summaryByKpi?.median) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)
            }px`,
          }}
        >
          Median: {valueUOM.displayWithAutoDecimals(valuesDomain, data.summaryByKpi?.median)}
        </MedianLabel>
      ) : null}
      {tooltipElement}
    </div>
  );
}
