import { useSuspenseQuery } from "@tanstack/react-query";
import type { BasinDto } from "apis/oag";
import { BasinsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const basinsApi = new BasinsApi(apiConfig);
const BasinsQueryKey: PDUniqueQueryKey = { uid: RequestUID.basins };

export const getCorrectCoord = (testP: string) => {
  return testP.split(" ").map((coord: string) =>
    coord
      .split(",")
      .slice(0, 2)
      .map((x) => +x),
  );
};
export const getBasinCoordByName = (basinsArray: BasinDto[], name: string) => {
  return basinsArray.find((basin) => basin.name === name);
};

export const getAreaObject = (coordObject: BasinDto) => {
  return {
    type: "FeatureCollection",
    features: [
      ...coordObject.basinAreas.map((coord, index) => ({
        id: `${coordObject.name}${index}`,
        type: "Feature",
        properties: {},
        geometry: {
          coordinates: [coord.coordinates],
          type: "Polygon",
        },
      })),
    ],
  } as unknown as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
};

export const useGetBasins = () => {
  return useSuspenseQuery<BasinDto[]>({
    queryKey: [BasinsQueryKey],
    queryFn: async () => basinsApi.apiBasinsGet(),
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
