import type { WellStatusType } from "apis/oag";
import { useFilterOptions } from "hooks/useFilterOptions";
import type { linkedFilters } from "hooks/useFilterTags";
import { useFormations } from "hooks/useFormations";
import { useOperators } from "hooks/useOperators";
import { useRigs } from "hooks/useRigs";
import { useWellShortInfo } from "hooks/useWellShortInfo";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { match } from "ts-pattern";

const useUpdateFilters = ({
  storeName,
}: {
  storeName: "rigsCommon" | "allWells" | "evergreenWells";
}) => {
  const customReducerName = useMemo(() => {
    return match(storeName)
      .with("rigsCommon", () => "RIGS_COMMON_")
      .with("allWells", () => "ALL_WELLS_")
      .with("evergreenWells", () => "EVERGREEN_WELLS_")
      .exhaustive() as "RIGS_COMMON_" | "ALL_WELLS_" | "EVERGREEN_WELLS_";
  }, [storeName]);
  const dispatch = useAppDispatch();

  const updateFormations = useCallback(
    (formationIds: number[] | null) => {
      dispatch({
        type: `${customReducerName}FORMATIONS_IDS`,
        payload: formationIds ? formationIds.toSorted((a, b) => a - b) : null,
      });
      return true;
    },
    [customReducerName, dispatch],
  );

  const updateOperators = useCallback(
    (operatorIds: number[] | null) => {
      dispatch({
        type: `${customReducerName}SET_OPERATORS`,
        payload: {
          operators: operatorIds ?? null,
        },
      });
    },
    [customReducerName, dispatch],
  );

  const updateRigs = useCallback(
    (rigIds: number[] | null) => {
      dispatch({
        type: `${customReducerName}SET_RIGS`,
        payload: {
          rigIds: rigIds ?? null,
        },
      });
    },
    [customReducerName, dispatch],
  );

  const onWellsChange = useCallback(
    (selectedWells: number[] | null) => {
      if (customReducerName === "RIGS_COMMON_")
        dispatch({
          type: `${customReducerName}SET_WELLS`,
          payload: {
            selectedWells: selectedWells ?? null,
          },
        });
      // we don't have selected wells in other places than rigsCommon
    },
    [customReducerName, dispatch],
  );

  const updateFilters = useCallback(
    (filter: (typeof linkedFilters)[number]) => (value: number[] | null) => {
      match(filter)
        .with("rigs", () => updateRigs(value))
        .with("operators", () => updateOperators(value))
        .with("formations", () => updateFormations(value))
        .with("wells", () => onWellsChange(value))
        .exhaustive();
    },
    [onWellsChange, updateFormations, updateOperators, updateRigs],
  );
  const resetFilters = useCallback(() => {
    updateOperators(null);
    updateRigs(null);
    onWellsChange(null);
    updateFormations(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onWellsChange, updateFormations, updateOperators, updateRigs]);

  return {
    updateFilters,
    resetFilters,
  };
};

export const useLinkedFilters = ({
  keepEvengreenOnly,
  storeName,
  closePopups,
}: {
  keepEvengreenOnly: boolean;
  storeName: "rigsCommon" | "allWells" | "evergreenWells";
  closePopups?: () => void;
}) => {
  // isLoadingOperators/ rigs and formations are always false ... interesting... typewise
  const { data: operators, isLoading: isLoadingOperators } = useOperators();
  const { data: rigs, isLoading: isLoadingRigs } = useRigs();
  const { data: formations, isLoading: isLoadingFormations } = useFormations();
  const { data: wellData, isLoading: isLoadingWells } = useWellShortInfo();
  const [options, setOptions] = useState<{
    rigs: {
      id: number;
      name: string;
    }[];
    operators: {
      id: number;
      name: string;
    }[];
    formations: {
      id: number;
      name: string;
    }[];
    wells: {
      id: number;
      name: string;
    }[];
  }>({
    rigs: [],
    operators: [],
    formations: [],
    wells: [],
  });

  const { updateFilters, resetFilters } = useUpdateFilters({ storeName });

  const formationIdsState = useAppSelector(
    (state) => state[storeName].formationIds,
  );
  const operatorsIdsState = useAppSelector(
    (state) => state[storeName].operators,
  );
  const rigsIdsState = useAppSelector((state) => state[storeName].rigIds);
  const wellsIdsState =
    useAppSelector(
      (state) =>
        (
          state[storeName] as {
            selectedWells: number[] | null;
          }
        )?.selectedWells,
    ) ?? null;

  // consider null as all
  const filterState = useAppSelector((state) => state[storeName].filters);
  const { data, isPlaceholderData: isLoadingOptions } = useFilterOptions({
    //  we are handling date differently in the rigs and wells... bad
    storeName: storeName as "allWells" | "evergreenWells",
    keepEvengreenOnly,
    wellIds: wellsIdsState,
    targetFormationIds: formationIdsState,
    operatorIds: operatorsIdsState,
    rigIds: rigsIdsState,
    wellStatuses:
      (filterState?.wellStatus.map((x) => x.id) as WellStatusType[]) ?? null,
  });

  useEffect(() => {
    const {
      rigIds: rigOptions,
      operatorIds: operatorOptions,
      targetFormationIds: formationOptions,
      wellIds: wellOptions,
    } = data ?? {
      rigIds: null,
      operatorIds: null,
      targetFormationIds: null,
      wellIds: null,
    };

    const optionsData = {
      rigs: (rigs?.list ?? [])
        .filter((r) => (rigOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.id, name: r.shortName })),
      operators: (operators?.list ?? [])
        .filter((r) => (operatorOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.id, name: r.name })),
      formations: (formations?.list ?? [])
        .filter((r) => (formationOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.id, name: r.name })),
      wells: (wellData?.list ?? [])
        .filter((r) => (wellOptions ?? []).find((id) => id === r.id))
        .map((r) => ({ id: r.id, name: r.name })),
    };
    setOptions(optionsData);
  }, [data, formations?.list, operators?.list, rigs?.list, wellData?.list]);

  return {
    resetFilters: () => {
      resetFilters();
      closePopups?.();
    },
    options,
    selectedValues: {
      rigs: rigsIdsState,
      operators: operatorsIdsState,
      formations: formationIdsState,
      wells: wellsIdsState,
    },
    isSelected: {
      rigs:
        rigsIdsState !== null &&
        (rigsIdsState ?? []).length !== rigs?.list.length,
      operators:
        operatorsIdsState !== null &&
        (operatorsIdsState ?? []).length !== operators?.list.length,
      formations:
        formationIdsState !== null &&
        (formationIdsState ?? []).length !== formations?.list.length,
      wells:
        wellsIdsState !== null &&
        (wellsIdsState ?? []).length !== wellData?.list.length,
    },
    updateFilters,
    isLoading: {
      rigs: isLoadingRigs,
      operators: isLoadingOperators,
      formations: isLoadingFormations,
      wells: isLoadingWells,
      filterOptions: isLoadingOptions,
    },
  };
};
