/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GeneratorStatsQueryDto,
  GeneratorStatsResultDto,
  GeneratorStatsUserLensDto,
} from '../models/index';
import {
    GeneratorStatsQueryDtoFromJSON,
    GeneratorStatsQueryDtoToJSON,
    GeneratorStatsResultDtoFromJSON,
    GeneratorStatsResultDtoToJSON,
    GeneratorStatsUserLensDtoFromJSON,
    GeneratorStatsUserLensDtoToJSON,
} from '../models/index';

export interface ApiGeneratorStatsUserLensesIdFactsPutRequest {
    id: number;
    generatorStatsQueryDto?: GeneratorStatsQueryDto;
}

export interface ApiGeneratorStatsUserLensesIdPutRequest {
    id: number;
    generatorStatsUserLensDto?: GeneratorStatsUserLensDto;
}

export interface ApiGeneratorStatsUserLensesPostRequest {
    generatorStatsUserLensDto?: GeneratorStatsUserLensDto;
}

/**
 * 
 */
export class GeneratorStatsUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiGeneratorStatsUserLensesIdFactsPutRaw(requestParameters: ApiGeneratorStatsUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneratorStatsResultDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiGeneratorStatsUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GeneratorStatsUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GeneratorStatsQueryDtoToJSON(requestParameters['generatorStatsQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorStatsResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGeneratorStatsUserLensesIdFactsPut(requestParameters: ApiGeneratorStatsUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneratorStatsResultDto> {
        const response = await this.apiGeneratorStatsUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGeneratorStatsUserLensesIdPutRaw(requestParameters: ApiGeneratorStatsUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneratorStatsUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiGeneratorStatsUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GeneratorStatsUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GeneratorStatsUserLensDtoToJSON(requestParameters['generatorStatsUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorStatsUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGeneratorStatsUserLensesIdPut(requestParameters: ApiGeneratorStatsUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneratorStatsUserLensDto> {
        const response = await this.apiGeneratorStatsUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGeneratorStatsUserLensesPostRaw(requestParameters: ApiGeneratorStatsUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneratorStatsUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GeneratorStatsUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GeneratorStatsUserLensDtoToJSON(requestParameters['generatorStatsUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorStatsUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGeneratorStatsUserLensesPost(requestParameters: ApiGeneratorStatsUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneratorStatsUserLensDto> {
        const response = await this.apiGeneratorStatsUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
