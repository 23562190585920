import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import type { FC } from "react";
import { useNavigate } from "react-router";
import { Result } from "utils/componentLibrary";

const SignedOut: FC = () => {
  const navigate = useNavigate();

  return (
    <Result
      icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
      title="You have been signed out!"
      extra={[
        <Button key="homepage_button" onClick={() => navigate("/")}>
          Back to homepage
        </Button>,
      ]}
    />
  );
};

export default SignedOut;
