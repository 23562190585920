/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WellStatusType } from './WellStatusType';
import {
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';
import type { WellJobsDto } from './WellJobsDto';
import {
    WellJobsDtoFromJSON,
    WellJobsDtoFromJSONTyped,
    WellJobsDtoToJSON,
} from './WellJobsDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface WellDrillingSummaryDto
 */
export interface WellDrillingSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WellDrillingSummaryDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    longitude?: number | null;
    /**
     * 
     * @type {Array<WellJobsDto>}
     * @memberof WellDrillingSummaryDto
     */
    jobs?: Array<WellJobsDto> | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    operatorId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingSummaryDto
     */
    spudDateTime: DateDto;
    /**
     * 
     * @type {WellStatusType}
     * @memberof WellDrillingSummaryDto
     */
    status: WellStatusType;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    lastBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    lastHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryDto
     */
    numberOfStrings: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingSummaryDto
     */
    lastTvdFactUpdatedAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingSummaryDto
     */
    lastWellFactUpdateAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingSummaryDto
     */
    lastRigStateFactUpdateAt: DateDto;
}



/**
 * Check if a given object implements the WellDrillingSummaryDto interface.
 */
export function instanceOfWellDrillingSummaryDto(value: object): value is WellDrillingSummaryDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('operatorId' in value) || value['operatorId'] === undefined) return false;
    if (!('spudDateTime' in value) || value['spudDateTime'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('cumulativeDuration' in value) || value['cumulativeDuration'] === undefined) return false;
    if (!('lastBitDepth' in value) || value['lastBitDepth'] === undefined) return false;
    if (!('lastHoleDepth' in value) || value['lastHoleDepth'] === undefined) return false;
    if (!('numberOfStrings' in value) || value['numberOfStrings'] === undefined) return false;
    if (!('lastTvdFactUpdatedAt' in value) || value['lastTvdFactUpdatedAt'] === undefined) return false;
    if (!('lastWellFactUpdateAt' in value) || value['lastWellFactUpdateAt'] === undefined) return false;
    if (!('lastRigStateFactUpdateAt' in value) || value['lastRigStateFactUpdateAt'] === undefined) return false;
    return true;
}

export function WellDrillingSummaryDtoFromJSON(json: any): WellDrillingSummaryDto {
    return WellDrillingSummaryDtoFromJSONTyped(json, false);
}

export function WellDrillingSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellDrillingSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'latitude': json['Latitude'] == null ? undefined : json['Latitude'],
        'longitude': json['Longitude'] == null ? undefined : json['Longitude'],
        'jobs': json['Jobs'] == null ? undefined : ((json['Jobs'] as Array<any>).map(WellJobsDtoFromJSON)),
        'operatorId': json['OperatorId'],
        'spudDateTime': DateDtoFromJSON(json['SpudDateTime']),
        'status': WellStatusTypeFromJSON(json['Status']),
        'cumulativeDuration': json['CumulativeDuration'],
        'lastBitDepth': json['LastBitDepth'],
        'lastHoleDepth': json['LastHoleDepth'],
        'numberOfStrings': json['NumberOfStrings'],
        'lastTvdFactUpdatedAt': DateDtoFromJSON(json['LastTvdFactUpdatedAt']),
        'lastWellFactUpdateAt': DateDtoFromJSON(json['LastWellFactUpdateAt']),
        'lastRigStateFactUpdateAt': DateDtoFromJSON(json['LastRigStateFactUpdateAt']),
    };
}

export function WellDrillingSummaryDtoToJSON(value?: WellDrillingSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'Latitude': value['latitude'],
        'Longitude': value['longitude'],
        'Jobs': value['jobs'] == null ? undefined : ((value['jobs'] as Array<any>).map(WellJobsDtoToJSON)),
        'OperatorId': value['operatorId'],
        'SpudDateTime': DateDtoToJSON(value['spudDateTime']),
        'Status': WellStatusTypeToJSON(value['status']),
        'CumulativeDuration': value['cumulativeDuration'],
        'LastBitDepth': value['lastBitDepth'],
        'LastHoleDepth': value['lastHoleDepth'],
        'NumberOfStrings': value['numberOfStrings'],
        'LastTvdFactUpdatedAt': DateDtoToJSON(value['lastTvdFactUpdatedAt']),
        'LastWellFactUpdateAt': DateDtoToJSON(value['lastWellFactUpdateAt']),
        'LastRigStateFactUpdateAt': DateDtoToJSON(value['lastRigStateFactUpdateAt']),
    };
}

