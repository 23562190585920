import styled, { css } from "styled-components";
import colors from "utils/colors";

export interface IEventProps {
  top?: number;
  left?: number;
  translateX?: string;
  translateY?: string;
  color?: string;
  isPhase?: string;
  isGlowing?: string;
  isHovered?: string;
  isColored?: string;
  isEdited?: string;
  isFuturePlanEvent?: string;
}

export const EventIcon = styled.div<IEventProps>`
  position: absolute;
  top: ${(props) => props.top || 0}px;
  left: ${(props) => props.left || 0}px;
  height: ${(props) => (props.isPhase === "true" ? 16 : 28)}px;
  width: ${(props) => (props.isPhase === "true" ? 16 : 28)}px;
  border-radius: ${(props) => (props.isPhase === "true" ? 20 : 50)}%;
  transform: translate(${(props) => props.translateX || 0}, ${(props) => props.translateY || 0});
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};

  &:hover {
    ${(props) =>
      props.isHovered === "true"
        ? null
        : css`
            outline: 2px solid ${colors.well_color};
          `}
  }

  ${(props) => {
    if (props.isColored === "true") {
      return css`
        background: ${({ theme }) =>
          props.isPhase === "true"
            ? theme.themeStyle.colors.phase_indicator
            : theme.themeStyle.colors.primary_button_bg};
        .pd-icon {
          color: ${props.color || colors.white};
        }
      `;
    }

    if (props.isHovered === "true")
      return css`
        background: ${({ theme }) => theme.themeStyle.colors.primary_button_bg};
        box-shadow: 0px 0px 1px 6px ${({ theme }) => theme.themeStyle.colors.primary_button_bg}4d;
        .pd-icon {
          color: ${colors.white};
        }
      `;

    return css`
      background: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
      animation: ${props.isFuturePlanEvent === "true" ? "pulsate 1.5s infinite" : "none"};
      border: ${props.isFuturePlanEvent === "true"
        ? `1px solid ${props.theme.themeStyle.colors.quaterniary_bg}`
        : "none"};
      @keyframes pulsate {
        0% {
          box-shadow: 0px 0px 0px 0px ${colors.well_color_transparent};
        }
        50% {
          box-shadow: 0px 0px 1.5px 4.5px ${colors.well_color_transparent};
        }
        100% {
          box-shadow: 0px 0px 0px 0px ${colors.well_color_transparent};
        }
      }
      ${props.isEdited === "true"
        ? css`
            box-shadow: 0px 0px 1px 6px ${colors.well_color}4d;
          `
        : `box-shadow: 0px 1px 2px 0px ${colors.buttons_clicked};`}
      .pd-icon {
        color: ${props.theme.themeStyle.colors.primary_typography};
      }
    `;
  }}

  text-align: center;
  line-height: ${(props) => (props.isPhase === "true" ? 16 : 28)}px;
`;
