/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LensTabStateType = {
    Unknown: 'Unknown',
    Visible: 'Visible',
    Hidden: 'Hidden',
    PendingApproval: 'PendingApproval'
} as const;
export type LensTabStateType = typeof LensTabStateType[keyof typeof LensTabStateType];


export function instanceOfLensTabStateType(value: any): boolean {
    for (const key in LensTabStateType) {
        if (Object.prototype.hasOwnProperty.call(LensTabStateType, key)) {
            if (LensTabStateType[key as keyof typeof LensTabStateType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LensTabStateTypeFromJSON(json: any): LensTabStateType {
    return LensTabStateTypeFromJSONTyped(json, false);
}

export function LensTabStateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LensTabStateType {
    return json as LensTabStateType;
}

export function LensTabStateTypeToJSON(value?: LensTabStateType | null): any {
    return value as any;
}

