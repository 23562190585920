import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { CrewMemberDto } from "apis/oag";
import { CrewMembersApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const CrewMembers = new CrewMembersApi(apiConfig);

const CrewMembersApiQueryKey: PDUniqueQueryKey = { uid: RequestUID.crewMemberNames };

export function useCrewMembers(options?: UseQueryOptions<CrewMemberDto[]>) {
  const queryOptions: UseQueryOptions<CrewMemberDto[]> = {
    queryKey: [CrewMembersApiQueryKey],
    queryFn: () => CrewMembers.apiCrewMembersGet(),
    ...options,
    refetchOnMount: false,
  };
  return useSuspenseQuery<CrewMemberDto[]>(queryOptions);
}
