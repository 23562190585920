/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimelineEventType } from './TimelineEventType';
import {
    TimelineEventTypeFromJSON,
    TimelineEventTypeFromJSONTyped,
    TimelineEventTypeToJSON,
} from './TimelineEventType';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ActualTimelineEventDto
 */
export interface ActualTimelineEventDto {
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ActualTimelineEventDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {TimelineEventType}
     * @memberof ActualTimelineEventDto
     */
    type: TimelineEventType;
    /**
     * 
     * @type {string}
     * @memberof ActualTimelineEventDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActualTimelineEventDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof ActualTimelineEventDto
     */
    authorDisplayName: string;
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    cumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    holeDepth?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActualTimelineEventDto
     */
    isSetByDepth: boolean;
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    wellId: number;
    /**
     * 
     * @type {number}
     * @memberof ActualTimelineEventDto
     */
    planEventId?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof ActualTimelineEventDto
     */
    at: DateDto;
    /**
     * 
     * @type {DashboardType}
     * @memberof ActualTimelineEventDto
     */
    dashboardType: DashboardType;
}



/**
 * Check if a given object implements the ActualTimelineEventDto interface.
 */
export function instanceOfActualTimelineEventDto(value: object): value is ActualTimelineEventDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('authorId' in value) || value['authorId'] === undefined) return false;
    if (!('authorDisplayName' in value) || value['authorDisplayName'] === undefined) return false;
    if (!('cumulativeDuration' in value) || value['cumulativeDuration'] === undefined) return false;
    if (!('isSetByDepth' in value) || value['isSetByDepth'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('at' in value) || value['at'] === undefined) return false;
    if (!('dashboardType' in value) || value['dashboardType'] === undefined) return false;
    return true;
}

export function ActualTimelineEventDtoFromJSON(json: any): ActualTimelineEventDto {
    return ActualTimelineEventDtoFromJSONTyped(json, false);
}

export function ActualTimelineEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActualTimelineEventDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': json['DeletedAtUtc'] == null ? undefined : (new Date(json['DeletedAtUtc'])),
        'type': TimelineEventTypeFromJSON(json['Type']),
        'title': json['Title'] == null ? undefined : json['Title'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'authorId': json['AuthorId'],
        'authorDisplayName': json['AuthorDisplayName'],
        'cumulativeDuration': json['CumulativeDuration'],
        'holeDepth': json['HoleDepth'] == null ? undefined : json['HoleDepth'],
        'isSetByDepth': json['IsSetByDepth'],
        'wellId': json['WellId'],
        'planEventId': json['PlanEventId'] == null ? undefined : json['PlanEventId'],
        'at': DateDtoFromJSON(json['At']),
        'dashboardType': DashboardTypeFromJSON(json['DashboardType']),
    };
}

export function ActualTimelineEventDtoToJSON(value?: ActualTimelineEventDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'DeletedAtUtc': value['deletedAtUtc'] == null ? undefined : ((value['deletedAtUtc'] as any).toISOString()),
        'Type': TimelineEventTypeToJSON(value['type']),
        'Title': value['title'],
        'Description': value['description'],
        'AuthorId': value['authorId'],
        'AuthorDisplayName': value['authorDisplayName'],
        'CumulativeDuration': value['cumulativeDuration'],
        'HoleDepth': value['holeDepth'],
        'IsSetByDepth': value['isSetByDepth'],
        'WellId': value['wellId'],
        'PlanEventId': value['planEventId'],
        'At': DateDtoToJSON(value['at']),
        'DashboardType': DashboardTypeToJSON(value['dashboardType']),
    };
}

