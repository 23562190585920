import type { ParameterByDepthKpiComparisonDto, StickSlipByDepthSetDto } from "apis/oag";
import { ResultDataState } from "apis/oag";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/useSelectedWell";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";

type TWellsInfo = { [id: number]: ResultDataState };

export const useLegendAutoUpdate = ({
  detailed,
  data,
}: {
  detailed: boolean;
  data?: ParameterByDepthKpiComparisonDto | StickSlipByDepthSetDto;
}) => {
  const wellId = useSelectedWell();
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);
  const comparisonWellsState = useAppSelector((state) => state.widgetOptions.comparisonWellInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!detailed || comparisonWells.length < 0) return;
    const comparisonWellsInfo: TWellsInfo = (() => {
      if (!data) return {};
      return data?.facts
        ? ({
            ...[wellId, ...comparisonWells].reduce((accumulator: TWellsInfo, current) => {
              accumulator[current] = ResultDataState.NoData;
              return accumulator;
            }, {}),
            ...data.facts.reduce((accumulator: TWellsInfo, current) => {
              accumulator[current.wellId] = current.dataState;
              return accumulator;
            }, {}),
          } as TWellsInfo)
        : { [wellId]: data?.dataState };
    })();

    if (!isEqual(comparisonWellsState, comparisonWellsInfo)) {
      dispatch({
        type: "SET_COMPARISON_WELL_INFO",
        payload: comparisonWellsInfo,
      });
    }
  }, [comparisonWells, comparisonWellsState, data, detailed, dispatch, wellId]);
};
