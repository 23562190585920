import type { ScorecardKpiCommentExceptionInfoDto } from "apis/oag";
import { Loader } from "components/Loader";
import { ModalBodyTask } from "components/Notification/ModalBodyTask";
import { isBaseNotificationVm } from "components/Notification/utils";
import { PDComponent } from "components/PDComponents";
import { useNotificationsActions } from "hooks/notifications/useNotificationsActions";
import * as Styled from "pages/Notifications/components/style";
import React, { useEffect } from "react";
import { Suspense } from "react";
import Modal from "react-modal";
import colors from "utils/colors";
import type { BaseNotificationVm } from "utils/notifications/mappers";
import { useCustomTheme } from "utils/useTheme";

import { ModalBody } from "./ModalBody";
import { detailedModalStyles } from "./style";

interface ModalProps {
  onCancel: (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
  isVisible: boolean;
  notification: BaseNotificationVm | ScorecardKpiCommentExceptionInfoDto | null;
}

export const DetailedModal: React.FC<ModalProps> = ({ isVisible, onCancel, notification: selectedNotification }) => {
  const { isDark } = useCustomTheme();

  const { markRead } = useNotificationsActions();

  useEffect(() => {
    if (selectedNotification && !(selectedNotification as BaseNotificationVm)?.isRead) {
      markRead([selectedNotification.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotification]);

  if (!selectedNotification) return null;

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onCancel}
      style={{
        content: { ...detailedModalStyles.content, backgroundColor: isDark ? colors.gluon_grey : colors.actions_bg },
        overlay: detailedModalStyles.overlay,
      }}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <Styled.ModalContainer>
        <Styled.ModalHeader>
          <Styled.DetailedCloseIcon onClick={onCancel}>
            <PDComponent.SvgIcon name="close" />
          </Styled.DetailedCloseIcon>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <Suspense fallback={<Loader centered />}>
            {isBaseNotificationVm(selectedNotification) ? (
              <ModalBody notification={selectedNotification} />
            ) : (
              <ModalBodyTask task={selectedNotification} />
            )}
          </Suspense>
        </Styled.ModalContent>
      </Styled.ModalContainer>
    </Modal>
  );
};
