import type { DragEndEvent } from "@dnd-kit/core";
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { LensTabStateType, type LensTabWithVisibilityDto } from "apis/oag";
import { arrayMoveImmutable } from "array-move";
import { Title } from "atoms/Typography";
import { useShareRecipients } from "hooks/useShareRecipients";
import * as Styled from "pages/WellDashboard/ManageTabs/Tables/style";
import { useFilterTabsByText } from "pages/WellDashboard/ManageTabs/Tables/useFilterTabsByText";
import { SortableGridRow } from "pages/WellDashboard/ManageTabs/Tables/UserTabsTable.tsx/SortableGridRow";
import { useCallback, useMemo } from "react";

export const UserTabsTable = ({
  tabs,
  filterTerm,
  uncomittedDeletedTabs,
  handleOnAttemptDeleteId,
  handleOnUndoDelete,
  onTabsChange,
}: {
  tabs: LensTabWithVisibilityDto[];
  filterTerm: string;
  uncomittedDeletedTabs: number[];
  handleOnAttemptDeleteId: (id: number) => void;
  handleOnUndoDelete: (id: number) => void;
  onTabsChange: (tabs: LensTabWithVisibilityDto[]) => void;
}) => {
  const filteredTabs = useFilterTabsByText(tabs, filterTerm);
  const visibleTabs = useMemo(
    () =>
      filteredTabs?.filter((tab) => tab.state === LensTabStateType.Visible || tab.state === LensTabStateType.Hidden),
    [filteredTabs],
  );

  const { isLoading: isLoadingRecipients, data: shareRecipients } = useShareRecipients();
  const getRecipientName = useCallback(
    (id: number) => {
      return isLoadingRecipients
        ? "Loading..."
        : shareRecipients?.find((recipient) => recipient.userId === id)?.displayName || "";
    },
    [isLoadingRecipients, shareRecipients],
  );

  const isTabPendingDelete = useCallback(
    (tab: LensTabWithVisibilityDto) => uncomittedDeletedTabs.includes(tab.id),
    [uncomittedDeletedTabs],
  );

  const handleOnClickToggleVisibility = useCallback(
    (tabId: number) => {
      onTabsChange(
        tabs.map((tab) =>
          tab.id === tabId
            ? {
                ...tab,
                state: tab.state === LensTabStateType.Visible ? LensTabStateType.Hidden : LensTabStateType.Visible,
              }
            : tab,
        ),
      );
    },
    [onTabsChange, tabs],
  );

  const handleOnClickDelete = useCallback(
    (removedTabId: number) => {
      const deletedTab = (tabs || []).find((tab) => tab.id === removedTabId);
      if (deletedTab?.id) {
        handleOnAttemptDeleteId(deletedTab?.id);
      }
    },
    [handleOnAttemptDeleteId, tabs],
  );

  const handleOnNameChange = useCallback(
    (tabId: number, newName: string) => {
      onTabsChange(tabs.map((tab) => (tab.id === tabId ? { ...tab, name: newName } : tab)));
    },
    [onTabsChange, tabs],
  );

  const handleOnClickToggleLock = useCallback(
    (tabId: number) => {
      onTabsChange(tabs.map((tab) => (tab.id === tabId ? { ...tab, isLocked: !tab.isLocked } : tab)));
    },
    [onTabsChange, tabs],
  );

  const handleOnRigIdsSelect = useCallback(
    (tabId: number, rigIds: null | number[]) => {
      onTabsChange(tabs.map((tab) => (tab.id === tabId ? { ...tab, rigIds } : tab)));
    },
    [onTabsChange, tabs],
  );
  const stringIds = useMemo(() => tabs.map((tab) => tab.id.toString()), [tabs]);
  const sensors = useSensors(useSensor(PointerSensor));

  const handleChangeTabsPosition = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = stringIds.indexOf(active.id.toString());
        const newIndex = stringIds.indexOf(over.id.toString());

        const newTabs = arrayMoveImmutable(tabs, oldIndex, newIndex).map((tabItem, index) => ({
          ...tabItem,
          position: index + 1,
        }));
        onTabsChange(newTabs);
      }
    },
    [onTabsChange, stringIds, tabs],
  );

  return visibleTabs?.length ? (
    <>
      <Styled.GridLayoutRow>
        <Styled.HeaderCol>Configuration</Styled.HeaderCol>
        <Styled.HeaderCol>Tab Name</Styled.HeaderCol>
        <Styled.HeaderCol>Shared by</Styled.HeaderCol>
        <Styled.HeaderCol>Date Shared</Styled.HeaderCol>
        <Styled.HeaderCol $isCentered>Visibility</Styled.HeaderCol>
        <Styled.HeaderCol></Styled.HeaderCol>
      </Styled.GridLayoutRow>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleChangeTabsPosition}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext items={stringIds} strategy={verticalListSortingStrategy}>
          {visibleTabs.map((tab) => {
            return (
              <SortableGridRow
                key={tab.id}
                tab={tab}
                handleOnClickToggleLock={handleOnClickToggleLock}
                handleOnRigIdsSelect={handleOnRigIdsSelect}
                handleOnNameChange={handleOnNameChange}
                getRecipientName={getRecipientName}
                handleOnClickToggleVisibility={handleOnClickToggleVisibility}
                handleOnClickDelete={handleOnClickDelete}
                isTabPendingDelete={isTabPendingDelete(tab)}
                handleOnUndoDelete={handleOnUndoDelete}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </>
  ) : (
    <em>
      <Title level={4}>No items to display</Title>
    </em>
  );
};
