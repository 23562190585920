/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';
import type { PivotType } from './PivotType';
import {
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface RigScorecardFactQueryDto
 */
export interface RigScorecardFactQueryDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    formationIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {DateDto}
     * @memberof RigScorecardFactQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof RigScorecardFactQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardFactQueryDto
     */
    includeFullWellFacts: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    selectedWellIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof RigScorecardFactQueryDto
     */
    selectedFilters: FiltersDto;
    /**
     * 
     * @type {DashboardType}
     * @memberof RigScorecardFactQueryDto
     */
    dashboard: DashboardType;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    highlightedRigIds: Array<number>;
    /**
     * 
     * @type {PivotType}
     * @memberof RigScorecardFactQueryDto
     */
    pivot: PivotType;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigScorecardFactQueryDto
     */
    tabIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardFactQueryDto
     */
    useExceptions: boolean;
}



/**
 * Check if a given object implements the RigScorecardFactQueryDto interface.
 */
export function instanceOfRigScorecardFactQueryDto(value: object): value is RigScorecardFactQueryDto {
    if (!('from' in value) || value['from'] === undefined) return false;
    if (!('to' in value) || value['to'] === undefined) return false;
    if (!('includeFullWellFacts' in value) || value['includeFullWellFacts'] === undefined) return false;
    if (!('selectedFilters' in value) || value['selectedFilters'] === undefined) return false;
    if (!('dashboard' in value) || value['dashboard'] === undefined) return false;
    if (!('highlightedRigIds' in value) || value['highlightedRigIds'] === undefined) return false;
    if (!('pivot' in value) || value['pivot'] === undefined) return false;
    if (!('useExceptions' in value) || value['useExceptions'] === undefined) return false;
    return true;
}

export function RigScorecardFactQueryDtoFromJSON(json: any): RigScorecardFactQueryDto {
    return RigScorecardFactQueryDtoFromJSONTyped(json, false);
}

export function RigScorecardFactQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigScorecardFactQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'formationIds': json['FormationIds'] == null ? undefined : json['FormationIds'],
        'operatorIds': json['OperatorIds'] == null ? undefined : json['OperatorIds'],
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'includeFullWellFacts': json['IncludeFullWellFacts'],
        'selectedWellIds': json['SelectedWellIds'] == null ? undefined : json['SelectedWellIds'],
        'selectedFilters': FiltersDtoFromJSON(json['SelectedFilters']),
        'dashboard': DashboardTypeFromJSON(json['Dashboard']),
        'highlightedRigIds': json['HighlightedRigIds'],
        'pivot': PivotTypeFromJSON(json['Pivot']),
        'tabIds': json['TabIds'] == null ? undefined : json['TabIds'],
        'useExceptions': json['UseExceptions'],
    };
}

export function RigScorecardFactQueryDtoToJSON(value?: RigScorecardFactQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'FormationIds': value['formationIds'],
        'OperatorIds': value['operatorIds'],
        'RigIds': value['rigIds'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'IncludeFullWellFacts': value['includeFullWellFacts'],
        'SelectedWellIds': value['selectedWellIds'],
        'SelectedFilters': FiltersDtoToJSON(value['selectedFilters']),
        'Dashboard': DashboardTypeToJSON(value['dashboard']),
        'HighlightedRigIds': value['highlightedRigIds'],
        'Pivot': PivotTypeToJSON(value['pivot']),
        'TabIds': value['tabIds'],
        'UseExceptions': value['useExceptions'],
    };
}

