import type { PlanActivityDto, PlanDto, PlanFormationDto } from "apis/oag";
import { DimensionType, WellsApi } from "apis/oag";
import { SelectAlt } from "atoms/Form";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { ActionsHeader, PlanSectionLayout } from "components/Layout/Tabbed";
import { PDComponent } from "components/PDComponents";
import Well from "components/WellPlan/Overview";
import { useCopyFromModal } from "components/WellPlan/WellPlanCommun/CopyFromModal";
import { useHoleSections } from "hooks/useHoleSections";
import { usePhases } from "hooks/usePhases";
import { useWellDetails } from "hooks/useWellDetails";
import React, { useCallback, useMemo, useState } from "react";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { Col, Row } from "utils/componentLibrary";
import { useUOM } from "utils/format";
import { useCustomTheme } from "utils/useTheme";
import type { IErrorPlanEditor } from "utils/wellplan/errors";
import { timeData } from "utils/wellplan/utils";

interface OverviewProps {
  wellId: number;
  plan?: PlanDto;
  setPlan: React.Dispatch<React.SetStateAction<PlanDto | undefined>>;
  setPlanModified: React.Dispatch<React.SetStateAction<boolean>>;
  errors: IErrorPlanEditor["overview"];
}

export function Overview({ wellId, plan, setPlan, setPlanModified, errors }: Readonly<OverviewProps>) {
  const { data: selectedWell } = useWellDetails(wellId);
  const { data: phaseData } = usePhases();
  const getSectionId = useCallback((phaseId: number) => phaseData?.find((phase) => phase.id === phaseId)?.sectionId, [phaseData]);
  const wellsApi = useMemo(() => new WellsApi(apiConfig), []);
  const handleCopy = useCallback((selectedWellId: number) => {
    wellsApi.apiWellsIdPlanActivitiesGet({ id: selectedWellId }).then((set) => {
      (set.activities as Array<PlanActivityDto & { sectionId: number }>).forEach((p, i) => {
        p.id = -(i + 1); // BE will interpret it as a proper copy
        p.sectionId = getSectionId(p.phaseId) ?? 0;
      });
      setPlan((prevPlan) => {
        return prevPlan ? { ...prevPlan, startHoleDepth: set.startDepth, activities: set.activities } : undefined;
      });
      setPlanModified(true);
    });
  }, [getSectionId, setPlan, setPlanModified, wellsApi]);
  const { node: copyFromModalElement, open: openCopyFrom } = useCopyFromModal({
    wellId,
    onCopy: handleCopy,
    type: "overview",
  }
  );
  const [selection, setSelection] = useState({
    time: "days",
  });
  const depthUOM = useUOM(DimensionType.Metres);
  const { atomThemeVariant } = useCustomTheme();
  const totalDepth = useMemo(
    () =>
      Math.max(
        ...[
          plan?.startHoleDepth ?? 0,
          (plan?.activities ?? []).reduce((acc: number, item) => Math.max(acc, item.endHoleDepth || 0), 0) || 0,
        ],
      ).toFixed(2),
    [plan?.activities, plan?.startHoleDepth],
  );

  const totalDays = useMemo(
    () =>
      plan?.activities
        .reduce((acc: number, item) => acc + Number(timeData[selection.time]?.toString(Number(item?.duration ?? 0))), 0)
        .toFixed(2) || 0,
    [plan?.activities, selection.time],
  );

  if (!plan) {
    return null;
  }

  return (
    <>
      {copyFromModalElement}
      <PlanSectionLayout
        header={
          <ActionsHeader>
            <Row gutter={12} justify="space-between" align="middle" style={{ height: "64px" }}>
              <Col flex="0 300px">
                <SelectAlt
                  placeholder="Time"
                  style={{ width: "70%", textTransform: "capitalize" }}
                  size="large"
                  value={selection.time}
                  onSelect={(item) => {
                    Track.interact("Well Overview - Time Select", {
                      Selected: Object.keys(timeData)[Number(item)],
                    });
                    setSelection((newState) => {
                      return { ...newState, time: Object.keys(timeData)[Number(item)] };
                    });
                  }}
                >
                  {Object.keys(timeData).map((data, index) => (
                    <SelectAlt.Option key={data} value={index} style={{ textTransform: "capitalize" }}>
                      {data}
                    </SelectAlt.Option>
                  ))}
                </SelectAlt>
              </Col>
              <Button
                trackingName="Copy From Activities"
                size="large"
                onClick={openCopyFrom}
                icon={<PDComponent.SvgIcon name="migrate" />}
              >
                Copy From
              </Button>
            </Row>
          </ActionsHeader>
        }
        content={
          <>
            <Row justify="space-between" style={{ margin: "16px 20px 16px 20px" }}>
              <Col flex="0 auto">
                <Title level={3} variant={atomThemeVariant} weight={500}>
                  #{selectedWell?.externalId}
                </Title>
              </Col>
              <Col flex="0 auto">
                <Title level={3} variant={atomThemeVariant}>
                  {depthUOM.display(+totalDepth)} in {totalDays} {timeData[selection.time]?.abv}
                </Title>
              </Col>
            </Row>
            <Well
              errors={errors}
              selection={selection.time}
              wellName={selectedWell?.name ?? ""}
              plan={plan}
              setPlan={setPlan as React.Dispatch<React.SetStateAction<PlanDto>>}
              setPlanModified={setPlanModified}
            />
          </>
        }
      />
    </>
  );
}
