import { ResultDataState } from "apis/oag";
import { Title } from "atoms/Typography";
import NoData from "components/Lenses/common/NoData";
import { InfoContainer } from "components/MiniLens/InfoContainer";
import styled from "styled-components";
import { Space } from "utils/componentLibrary";

const StyledChartContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
`;

export const LensLoadingContainer = ({
  isDetailed,
  dataState,
  title = "",
  smallSizeLens,
  LoadedComponent,
  NoDataChartComponent,
  SummaryComponent,
}: {
  isDetailed?: boolean;
  dataState?: ResultDataState;
  title?: string;
  smallSizeLens?: boolean;
  LoadedComponent?: React.ReactElement;
  NoDataChartComponent?: React.ReactElement;
  SummaryComponent?: React.ReactElement;
}) => {
  switch (dataState) {
    case ResultDataState.Valid:
      return LoadedComponent;
    default:
      return (
        <div
          style={{
            padding: 0,
            margin: 0,
            width: "100%",
            height: `calc(100% - ${isDetailed ? 100 : 0}px`,
            position: "relative",
          }}
        >
          {SummaryComponent ? (
            SummaryComponent
          ) : isDetailed ? (
            <InfoContainer size={6} direction="vertical">
              <Title ellipsis={{ rows: 1 }} level={3} variant="faded">
                {title}
              </Title>
            </InfoContainer>
          ) : (
            <InfoContainer size={6} direction="vertical" $smallSizeLens={smallSizeLens}>
              <Title ellipsis={{ rows: 1 }} level={3} variant="faded">
                {title}
              </Title>
            </InfoContainer>
          )}

          {NoDataChartComponent ? (
            <Space direction="vertical">
              <NoData detailed={isDetailed} />
              <StyledChartContainer>{NoDataChartComponent}</StyledChartContainer>
            </Space>
          ) : (
            dataState ? <NoData detailed={isDetailed} /> : null
          )}
        </div>
      );
  }
};
