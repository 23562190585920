import type { LabeledValue } from "antd/lib/select";
import type { ActualTvdPointDto, DateDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useDisplayLocalWellTime } from "hooks/useLocalWellTime";
import { isNumber } from "lodash";
import { useAppSelector } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { IUnitSystem, IZoomType } from "reducers/types";
import { secondsInDay } from "utils/common";
import { Tooltip } from "utils/componentLibrary";
import { SECONDS_IN_HOUR, useUOM } from "utils/format";
import { formatTime } from "utils/helper";

const useTextByType = ({ type }: { type: IZoomType }) => {
  const currentUOM = useAppSelector((state) => state.global.unit);
  if (type === IZoomType.TIME) return "Day";
  if (type === IZoomType.DEPTH) return currentUOM === IUnitSystem.METRIC ? "Meters" : "Feet";
  if (type === IZoomType.DATE) return "Date";
};

export const DatepickDateFormat = "MM/DD/YY HH:mm:ss";

export const Suffix = ({ disabled, type }: { disabled: boolean; type: IZoomType }) => {
  const text = useTextByType({ type });
  if (disabled)
    return (
      <Tooltip title="Zoom couldn't be captured due to the small distance selected.">
        <span>
          {text} <PDComponent.SvgIcon name="warningField" />
        </span>
      </Tooltip>
    );
  else return <span> {text} </span>;
};

export const customPredefinedRange: LabeledValue = { key: "custom", label: "Custom Range", value: "" };

const ENTIRE_WELL = "Entire Well";

export const predefinedTimeRangeList = [
  { key: "1", label: "Last 1 hour", value: SECONDS_IN_HOUR },
  { key: "2", label: "Last 3 hours", value: SECONDS_IN_HOUR * 3 },
  { key: "3", label: "Last 6 hours", value: SECONDS_IN_HOUR * 6 },
  { key: "4", label: "Last 12 hours", value: SECONDS_IN_HOUR * 12 },
  { key: "5", label: "Last 24 hours", value: SECONDS_IN_HOUR * 24 },
  { key: "6", label: "Last 2 days", value: SECONDS_IN_HOUR * 24 * 2 },
  { key: "7", label: "Last 7 days", value: SECONDS_IN_HOUR * 24 * 7 },
  { key: "8", label: "Last 30 days", value: SECONDS_IN_HOUR * 24 * 30 },
  { key: "9", label: ENTIRE_WELL, value: 0 },
] as LabeledValue[];

export const isBetween = ({ max, min, value }: { max: number; min: number; value: number }) => {
  if (!min && !max) return true;
  return value >= min && value <= max;
};

export const convertDateToDuration = (series: ActualTvdPointDto[]) => (date: Date) => {
  if (!date || !series || !series.length) return 0;

  const index = series.findIndex((e) => e.at.utc.getTime() >= date.getTime());

  if (index === 0) return series[0].cumulativeDuration;
  if (index === -1 || index > series.length - 1) return series[series.length - 1].cumulativeDuration;

  const [startDate, endDate] = [series[index - 1].at.utc.getTime(), series[index].at.utc.getTime()];
  const [durationStart, durationEnd] = [series[index - 1].cumulativeDuration, series[index].cumulativeDuration];

  const factor = (date.getTime() - startDate) / (endDate - startDate);
  const smoothDuration = +durationStart + Math.round(factor * (durationEnd - durationStart));

  return smoothDuration;
};

export const displayDate =
  ({ zoomInfo, data }: { zoomInfo: IZoomData; data: ActualTvdPointDto[] }) =>
  (value: DateDto | number | null): string => {
    if (zoomInfo.date_start && zoomInfo.date_end) {
      return formatTime(value as DateDto, {}).toString();
    } else return convertDurationToDate(data)(value as number);
  };

export const convertDurationToDate = (series: ActualTvdPointDto[]) => (rawDuration: number) => {
  let duration = Math.round(rawDuration);

  if (!duration) {
    return new Date(formatTime(series[0].at, {})).toString();
  } else if (duration < 0) {
    duration = series[series.length - 1].cumulativeDuration + duration;
  }

  const dateIndex = series.findIndex((dataPoint) => dataPoint.cumulativeDuration >= duration);

  // This date <= first point in the graph
  if (dateIndex === 0) return new Date(series[0].at.utc).toString();

  // This date > last point in the graph
  if (dateIndex === -1 || dateIndex > series.length - 1) return new Date(series[series.length - 1].at.utc).toString();
  if (series[dateIndex].cumulativeDuration === duration) new Date(series[dateIndex].at.utc).toString();

  const beforeDateIdx = dateIndex - 1;

  const [startDate, endDate] = [series[beforeDateIdx].at.utc.getTime(), series[dateIndex].at.utc.getTime()];
  const [durationStart, durationEnd] = [series[beforeDateIdx].cumulativeDuration, series[dateIndex].cumulativeDuration];

  const factor = (duration - durationStart) / (durationEnd - durationStart);
  const smoothDate = +startDate + Math.round(factor * (endDate - startDate));

  return new Date(smoothDate).toString();
};

export const useZoomModeLabel = (zoomData: IZoomData) => {
  const type = zoomData?.type;
  const label = ENTIRE_WELL;

  const depthUOM = useUOM(DimensionType.Metres);
  const getCumulativeDuration = (cumulativeDuration: number | null) =>
    Math.trunc(((cumulativeDuration ?? 0) / secondsInDay) * 100) / 100;
  const [offsetWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELL, []);
  const { displayInLocalWellTime } = useDisplayLocalWellTime();

  if (!zoomData) {
    return { type, label };
  }

  if (zoomData.type === IZoomType.DEPTH && isNumber(zoomData.depth_start) && isNumber(zoomData.depth_end)) {
    return {
      label: `${depthUOM.display(zoomData.depth_start, { fractionDigits: 0 })} to ${depthUOM.display(
        zoomData.depth_end,
        {
          fractionDigits: 0,
        },
      )}`,
      type: IZoomType.DEPTH,
    };
  }

  const matchedStartingDate = predefinedTimeRangeList.find((predefined) => predefined.value === -zoomData.ts_start);
  if (
    zoomData.ts_end === null &&
    matchedStartingDate &&
    matchedStartingDate.label !== ENTIRE_WELL &&
    zoomData.date_start === null
  ) {
    return { label: matchedStartingDate.label as string, type: IZoomType.DYNAMIC_WINDOW };
  }

  if (zoomData.type === IZoomType.TIME && (zoomData.ts_start || zoomData.ts_end)) {
    return {
      label: `Day ${getCumulativeDuration(zoomData.ts_start)} to Day ${getCumulativeDuration(zoomData.ts_end)}`,
      type: IZoomType.TIME,
    };
  }

  if (zoomData.type === IZoomType.DATE && offsetWells?.length === 0 && zoomData.date_start && zoomData.date_end) {
    const start = displayInLocalWellTime(zoomData.date_start, zoomData.date_start_well_offset);
    const end = displayInLocalWellTime(zoomData.date_end, zoomData.date_end_well_offset);

    return {
      label: `${start.format(DatepickDateFormat)} to ${end.format(DatepickDateFormat)}`,
      type: IZoomType.DATE,
    };
  }
  return {
    type,
    label: ENTIRE_WELL,
  };
};
