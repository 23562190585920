import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { DefaultButtonTemplate } from "pages/WellDashboard/style";
import React from "react";
import { DataState } from "reducers/stateReducer";
import { useAppSelector } from "reducers/store";
import type { IDisplayOptionsType } from "reducers/types";
import { CurvesEnum, initialDisplayOptions } from "reducers/types";
import styled from "styled-components";
import colors from "utils/colors";
import { secondsInDay } from "utils/common";
import { Col, Row } from "utils/componentLibrary";
import { Tooltip } from "utils/componentLibrary";

import StatusBehind from "./StatusBehindWrapper";

const WellDrillingRelativeStatusIndicator = styled(Col)`
  width: 109px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  background-color: ${colors.white};
`;

const Stats = () => {
  const aheadSchedule = useAppSelector((state) => state.state.aheadSchedule);
  const [displayOptions] = useStateQuery<IDisplayOptionsType>(
    URL_STATE_PARAM.DISPLAY_OPTIONS_WELL,
    initialDisplayOptions,
  );
  const showPlan = displayOptions.curves === null || displayOptions.curves?.includes(CurvesEnum.PLAN);
  const showIndicator = aheadSchedule !== null && showPlan;
  const selectedDataState = useAppSelector((state) => state.state.selectedData);
  return (
    <Row gutter={[8, 0]}>
      {showIndicator ? (
        <Col>
          <WellDrillingRelativeStatusIndicator>
            <StatusBehind
              style={{
                transform: `rotate(${aheadSchedule > 0 ? 180 : 0}deg)`,
                paddingLeft: 4,
              }}
            />
            <Title level={3} variant="dark">
              {Math.abs(aheadSchedule / secondsInDay).toFixed(2)} day
              {Math.abs(aheadSchedule / secondsInDay) === 1 ? "" : "s"}
            </Title>
          </WellDrillingRelativeStatusIndicator>
        </Col>
      ) : (
        <></>
      )}
      <Col>
        {selectedDataState !== DataState.Active && (
          <Tooltip placement="right" title="- -">
            <DefaultButtonTemplate>
              <PDComponent.SvgIcon name="hazard" style={{ color: colors.error_color }} />
            </DefaultButtonTemplate>
          </Tooltip>
        )}
      </Col>
    </Row>
  );
};

export default Stats;
