import type { VirtualizerOptions } from "@tanstack/react-virtual";
import { useVirtualizer } from "@tanstack/react-virtual";
import { PDComponent } from "components/PDComponents";
import type { ScrollHintContainerProps } from "components/PDComponents/ScrollHintContainer/ScrollHintContainer";
import { ListRowItem } from "components/PDComponents/VirtualizedList/ListRowItem";
import type { ReactElement } from "react";
import React, { useImperativeHandle } from "react";

interface VirtualizedListProps<T> {
  items: T[];
  itemSizePx: number;
  children(item: T, index: number, width: number): ReactElement;
  scrollbarDistance?: number;
  hideScrollHints?: boolean;
  overscanCount?: number;
  virtualizerOptions?: Partial<VirtualizerOptions<HTMLDivElement, Element>>;
  scrollHintProps?: ScrollHintContainerProps;
}

export const _VirtualizedListInner = function _VirtualizedList<T>(
  {
    items,
    itemSizePx,
    hideScrollHints,
    scrollbarDistance = 0,
    scrollHintProps,
    overscanCount = 10,
    virtualizerOptions,
    children,
  }: VirtualizedListProps<T>,
  ref: React.Ref<{ scrollToIndex: (index: number) => void }>,
) {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemSizePx,
    overscan: overscanCount,
    ...virtualizerOptions,
  });

  useImperativeHandle(ref, () => ({
    scrollToIndex: (index: number) => {
      rowVirtualizer.scrollToIndex(index);
    },
  }));

  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <PDComponent.ScrollHintContainer
      ref={parentRef}
      style={{
        height: "100%",
        overflow: "scroll",
        position: "relative",
        width: `calc(100% - ${scrollbarDistance}px)`,
      }}
      {...scrollHintProps}
      disabled={hideScrollHints}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: `100%`,
          position: "absolute",
        }}
      >
        {virtualItems.map((virtualRow) => {
          return (
            <ListRowItem virtualRow={virtualRow} key={virtualRow.key}>
              {children(items[virtualRow.index], virtualRow.index, parentRef.current?.clientWidth ?? 0)}
            </ListRowItem>
          );
        })}
      </div>
    </PDComponent.ScrollHintContainer>
  );
};

export const _VirtualizedList = React.forwardRef(_VirtualizedListInner) as <T>(
  props: VirtualizedListProps<T> & { ref?: React.ForwardedRef<{ scrollToIndex: (index: number) => void }> },
) => ReturnType<typeof _VirtualizedListInner>;
