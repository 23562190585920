import { Button } from "atoms/Form";
import type { Dict } from "mixpanel-browser";
import { Col, Row, Space } from "utils/componentLibrary";

import { FilterControls, FiltersBackground, Popup } from "./style";


function FiltersPopup({
  isAllSelected,
  trackingProps,
  deselectAll,
  onCancel,
  applyCustomization,
  numRows = 2,
  numCols = 3,
  hideSecondCol = false,
  children,
  gridArea
}: Readonly<{
  isAllSelected: boolean;
  trackingProps?: Dict;
  deselectAll: () => void;
  onCancel?: () => void;
  applyCustomization: () => void;
  numRows?: number;
  numCols?: number;
  hideSecondCol?: boolean;
  children: React.ReactNode;
  gridArea?: string;
}>) {
  return (
    <Popup>
      <FilterControls>
        <Col flex="0 auto">
          <Button
            trackingName={isAllSelected ? "Deselect All" : "Select All"}
            trackingProps={trackingProps}
            size="large"
            onClick={deselectAll}
          >
            {isAllSelected ? "Deselect all" : "Select all"}
          </Button>
        </Col>
        <Col flex="1 1 auto">
          <Row justify="end">
            <Space>
              <Button trackingName="Cancel Filters" trackingProps={trackingProps} size="large" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                trackingName="Apply Filters"
                trackingProps={trackingProps}
                size="large"
                type="primary"
                onClick={applyCustomization}
              >
                Apply
              </Button>
            </Space>
          </Row>
        </Col>
      </FilterControls>
      <FiltersBackground
        gridArea={gridArea}
        numRows={numRows} numCols={numCols} hideSecondCol={hideSecondCol}>
        {children}
      </FiltersBackground>
    </Popup>
  );
}

export default FiltersPopup;
