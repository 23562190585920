/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GeneratorSlotType } from './GeneratorSlotType';
import {
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeFromJSONTyped,
    GeneratorSlotTypeToJSON,
} from './GeneratorSlotType';

/**
 * 
 * @export
 * @interface RigPowerUsageSliceDto
 */
export interface RigPowerUsageSliceDto {
    /**
     * 
     * @type {number}
     * @memberof RigPowerUsageSliceDto
     */
    sliceValue: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerUsageSliceDto
     */
    positionalValue: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerUsageSliceDto
     */
    ghgEmissions: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerUsageSliceDto
     */
    position: number;
    /**
     * 
     * @type {GeneratorSlotType}
     * @memberof RigPowerUsageSliceDto
     */
    generator: GeneratorSlotType;
}



/**
 * Check if a given object implements the RigPowerUsageSliceDto interface.
 */
export function instanceOfRigPowerUsageSliceDto(value: object): value is RigPowerUsageSliceDto {
    if (!('sliceValue' in value) || value['sliceValue'] === undefined) return false;
    if (!('positionalValue' in value) || value['positionalValue'] === undefined) return false;
    if (!('ghgEmissions' in value) || value['ghgEmissions'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('generator' in value) || value['generator'] === undefined) return false;
    return true;
}

export function RigPowerUsageSliceDtoFromJSON(json: any): RigPowerUsageSliceDto {
    return RigPowerUsageSliceDtoFromJSONTyped(json, false);
}

export function RigPowerUsageSliceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigPowerUsageSliceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sliceValue': json['SliceValue'],
        'positionalValue': json['PositionalValue'],
        'ghgEmissions': json['GhgEmissions'],
        'position': json['Position'],
        'generator': GeneratorSlotTypeFromJSON(json['Generator']),
    };
}

export function RigPowerUsageSliceDtoToJSON(value?: RigPowerUsageSliceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'SliceValue': value['sliceValue'],
        'PositionalValue': value['positionalValue'],
        'GhgEmissions': value['ghgEmissions'],
        'Position': value['position'],
        'Generator': GeneratorSlotTypeToJSON(value['generator']),
    };
}

