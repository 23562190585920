import { AxisBottom, AxisRight } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { LinearGradient } from "@visx/gradient";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { LinePath, Stack } from "@visx/shape";
import type { GeneratorSlotType, RigPowerFactDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { Title } from "atoms/Typography";
import { AxisLocation, StandardTickComponent } from "components/Lenses/common/ChartElements";
import ChartLegend from "components/Lenses/common/ChartLegend";
import { useChartDateTimeRange } from "components/Lenses/common/useChartDateTimeRange";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { RotatedRightAxisLabel } from "components/Lenses/ContainerLens/common/RotatedRightAxisLabel";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import {
  CLIP_SERIES_ID as CLIP_ID,
  generatorColorsBySlotType,
  getGeneratorSlotTypeByNumber,
  getInternalAxisMargin,
  LATERAL_AXIS_WIDTH,
  X_AXIS_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { useRigPowerFactsFetcher } from "components/Lenses/ContainerLens/RigPower/common/fetcher";
import { StyledLensContainerFlex } from "components/Lenses/ContainerLens/RigPower/common/StyledComponents";
import { useGeneratorLegendItems } from "components/Lenses/ContainerLens/RigPower/common/useGeneratorLegendItems";
import { detailedLegendColumns } from "components/Lenses/ContainerLens/RigPower/common/utils";
import { getInvartiantBusinessRuleForAxisValue, UNIT_LABEL } from "components/Lenses/ContainerLens/RigPower/utils";
import type { RigPowerProps } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { curveMonotoneX, curveStepAfter } from "d3";
import { max, min } from "d3-array";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import useDiscontinuousTimeAxis from "hooks/useDiscontinuousTimeAxis";
import { useLensSize } from "hooks/useLensSize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import colors from "utils/colors";
import { defaultDateDto } from "utils/common";
import { Col, Row } from "utils/componentLibrary";
import { ALTERNATIVE_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { useAdditionalLegendItems } from "./useAdditionalLegendItems";
import { useRigPowerTooltip } from "./useRigPowerTooltip";

const LENS_TITLE = "Rig Power Usage";

export const RigPowerSimple: React.FC<RigPowerProps> = ({ lens, setLensDate }) => {
  const { request } = useRigPowerFactsFetcher({ lensId: lens?.id });
  const { data } = request;

  const [legend, setLegend] = useState<Array<GeneratorSlotType>>(data.activeGenerators);

  const series = useMemo(() => data?.facts || [], [data?.facts]);
  const summary = data?.summary;

  useEffect(() => {
    if (data?.lastUpdatedAt && setLensDate) setLensDate(data.lastUpdatedAt);
  }, [data.lastUpdatedAt, setLensDate]);

  const getIndex = (d: RigPowerFactDto) => d?.index ?? 0;
  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const plotWidth = getSVGNormalizedValue(chartWidth - 2 * LATERAL_AXIS_WIDTH);
  const plotHeight = getSVGNormalizedValue(chartHeight - X_AXIS_HEIGHT);
  const dataState = useMemo(() => data?.dataState, [data?.dataState]);

  const [width, height] = useLensSize(lens?.id);

  const uomValue = useUOM(DimensionType.Watts);

  const [isPointerInsideChart, setPointerInsideChart] = useState(false);
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const generatorLegendItems = useGeneratorLegendItems({
    generators: data.activeGenerators,
    legend,
    setLegend,
    summary,
    uomValue,
    lensId: lens?.id,
    lensTemplateId: lens?.lensTemplateId,
  });

  const capacityLegendItem = useAdditionalLegendItems({
    summary: data?.summary,
    uomValue,
    lensId: lens?.id,
    lensTemplateId: lens?.lensTemplateId,
  });

  const legendItems = useMemo(
    () => generatorLegendItems.concat(capacityLegendItem),
    [capacityLegendItem, generatorLegendItems],
  );

  const transformedData = useMemo(() => {
    if (!data) return [];
    const facts = data.facts;
    return (facts || []).map((e) => ({
      ...e,
      ...(e.slices || []).reduce(
        (a, slice) => ({
          ...a,
          [`${slice.position}-slice`]: legend.includes(slice.generator) ? slice.sliceValue : 0,
        }),
        {},
      ),
    }));
  }, [data, legend]);

  const xScale = useMemo(() => {
    const startX = LATERAL_AXIS_WIDTH;
    const timeSeries = series.map((fact) => fact.index);
    const [_min = 0, _max = 0] = [min(timeSeries), max(timeSeries)];

    return scaleLinear<number>({
      domain: [_min, _max],
      range: [startX, plotWidth + startX],
    });
  }, [plotWidth, series]);

  const { xScaleDate, chunksCount, getAverageChunkDate } = useDiscontinuousTimeAxis({
    plotWidth,
    xScaleDomain: xScale.domain(),
    series: series ?? [],
  });

  const internalAxisMargin = useMemo(() => getInternalAxisMargin(false), []);

  const yScale = useMemo(() => {
    const maxFromData =
      max(
        transformedData.map(
          (e) => max([(e.slices || []).reduce((acc, ee) => acc + ee.sliceValue, 0), e.availablePower]) || 0,
        ),
      ) || 0;
    const maxAdjusted = getInvartiantBusinessRuleForAxisValue(maxFromData);
    const [minValue, maxValue] = [0, maxAdjusted];
    return scaleLinear<number>({
      range: [plotHeight, internalAxisMargin],
      domain: [minValue, maxValue],
      nice: true,
    });
  }, [internalAxisMargin, plotHeight, transformedData]);

  const keys = useMemo(
    () => [
      ...new Set(
        (data?.facts || []).reduce<string[]>((acc, crt) => {
          return [...acc, ...(crt.slices || []).map((e) => `${e.position}-slice`)];
        }, []),
      ),
    ],
    [data?.facts],
  );

  const CLIP_SERIES_ID = useMemo(() => `${CLIP_ID}-${lens.id}-2`, [lens.id]);

  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });

  const handlePointerMove = useCallback((event: React.PointerEvent<SVGSVGElement>) => {
    const { left, top } = event.currentTarget.getBoundingClientRect();
    const x = ("clientX" in event ? event.clientX : 0) - left;
    const y = ("clientY" in event ? event.clientY : 0) - top;
    setPointerPosition({ x, y });
  }, []);

  const { tooltipElement } = useRigPowerTooltip({
    containerRef,
    legendItems,
    generatorLegendItems,
    xScale,
    yScale,
    series: transformedData,
    isPointerInsideChart,
    plotWidth: chartWidth,
    plotHeight,
    pointerPosition,
  });

  const { selectionRectangleElement, isDragging } = useChartDateTimeRange<RigPowerFactDto>({
    xScale,
    yScale,
    plotWidth,
    plotHeight,
    plotWidthOffset: LATERAL_AXIS_WIDTH,
    series: transformedData,
  });

  const { yDisplayOnlyScale, verticalAxisTicksCount, formatTickDecimals } = useYAxisDisplayScale({
    tickContainerHeight: plotHeight,
    originalScale: yScale,
    uom: uomValue,
  });

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={dataState}
      title={LENS_TITLE}
      isDetailed={false}
      LoadedComponent={
        <StyledLensContainerFlex>
          <Row justify="space-between">
            <Col style={{ padding: 24 }}>
              <Title variant="faded" level={3}>
                {LENS_TITLE}
              </Title>
            </Col>
          </Row>

          <StyledChartContainerFlexDiv>
            <div style={{ height: "auto", flexBasis: "50%", minHeight: 150, flexGrow: 1 }} ref={containerRef}>
              <svg
                onPointerMove={handlePointerMove}
                onMouseLeave={() => setPointerInsideChart(false)}
                onMouseEnter={() => setPointerInsideChart(true)}
                onMouseMove={() => setPointerInsideChart(true)}
                width={chartWidth}
                height="100%"
                style={{ overflow: "hidden", userSelect: "none" }}
              >
                <GridRows
                  scale={yDisplayOnlyScale}
                  width={chartWidth}
                  height={chartHeight}
                  stroke={themeColors.primary_chart_accent}
                  strokeWidth={1}
                  strokeOpacity={1}
                  clipPath={`url(#${CLIP_SERIES_ID})`}
                />
                <RectClipPath y={0} id={CLIP_SERIES_ID} height={chartHeight} width={plotWidth} x={LATERAL_AXIS_WIDTH} />

                <Group clipPath={`url(#${CLIP_SERIES_ID})`}>
                  <Stack
                    data={transformedData}
                    keys={keys || []}
                    x={(d) => xScale(getIndex(d?.data)) ?? 0}
                    y0={(d) => yScale(d[0]) ?? 0}
                    y1={(d) => yScale(d[1]) ?? 0}
                  >
                    {({ stacks, path }) =>
                      stacks.map((stack) => {
                        const gradientBaseColor = `${
                          generatorColorsBySlotType[getGeneratorSlotTypeByNumber(stack.index + 1)]
                        }`;
                        return (
                          <>
                            <LinearGradient
                              from={gradientBaseColor}
                              to={gradientBaseColor}
                              toOpacity={0.55}
                              fromOpacity={1}
                              id={`linear_gradient_${gradientBaseColor}_${lens.id}`}
                            />
                            <LinePath
                              key={`stack-${stack.key}`}
                              curve={curveMonotoneX}
                              d={path(stack) || ""}
                              stroke={`${colors.neutral}`}
                              strokeOpacity={0.1}
                              strokeWidth={1}
                              fill={`url(#linear_gradient_${gradientBaseColor}_${lens.id})`}
                            />
                          </>
                        );
                      })
                    }
                  </Stack>
                  {capacityLegendItem.isEnabled ? (
                    <LinePath
                      curve={curveStepAfter}
                      data={(data.facts || []).map((e) => ({ index: e.index, capacity: e.availablePower }))}
                      x={(d) => xScale(d.index) || 0}
                      y={(d) => yScale(d.capacity) || 0}
                      stroke={colors.aurora_magenta}
                      strokeWidth={1}
                    />
                  ) : null}
                </Group>

                <AxisRight
                  hideZero
                  scale={yDisplayOnlyScale}
                  hideTicks
                  numTicks={verticalAxisTicksCount}
                  left={(chartWidth ?? 0) - LATERAL_AXIS_WIDTH}
                  tickComponent={(props) => {
                    if (props.y === 0) return null;
                    return (
                      <StandardTickComponent rendererProps={props} axisLocation={AxisLocation.RIGHT}>
                        {(val) => formatTickDecimals(val)}
                      </StandardTickComponent>
                    );
                  }}
                  hideAxisLine
                />

                {/* VISIBLE TIME AXIS */}
                <AxisBottom
                  hideAxisLine
                  hideTicks
                  scale={xScaleDate}
                  top={plotHeight}
                  numTicks={chunksCount}
                  tickComponent={(props) => {
                    return (
                      <StandardTickComponent rendererProps={props} axisLocation={AxisLocation.BOTTOM}>
                        {(val) =>
                          formatTime(getAverageChunkDate(+val) || defaultDateDto.from, {
                            formatStr: ALTERNATIVE_DATE_FORMAT,
                          })
                        }
                      </StandardTickComponent>
                    );
                  }}
                />
                {!isDragging && tooltipElement}
                {selectionRectangleElement}
              </svg>

              <RotatedRightAxisLabel plotWidth={plotWidth} chartHeight={chartHeight} displayText={UNIT_LABEL} />
            </div>

            <ChartLegend
              legendItems={legendItems}
              isDetailed={lens.showDetailedLegend}
              lensGridSize={[width, height]}
              detailedColumns={detailedLegendColumns}
            />
          </StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
