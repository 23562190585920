import type { FeatureCollection, Geometry } from "@turf/turf";
import { booleanPointInPolygon, booleanWithin, circle } from "@turf/turf";
import type { RigDto } from "apis/oag";
import type { WellDrillingSummaryDto } from "apis/oag";
import type { LngLat } from "mapbox-gl";

interface GetGeoJsonFromDataParams {
  wells: WellDrillingSummaryDto[];
  rigs?: { list: RigDto[]; byId: { [key: number]: RigDto } } | null;
  selectionPolygonShape?: any;
  circleInfo?: { center: LngLat; radius: number };
  sourceLayer?: any;
}

const inRange = (x: number, min: number, max: number) => {
  return (x - min) * (x - max) <= 0;
};

export function getGeoJsonFromData({
  wells,
  rigs,
  selectionPolygonShape,
  circleInfo,
  sourceLayer,
}: GetGeoJsonFromDataParams) {
  const insideSelectionArea: GeoJSON.FeatureCollection<GeoJSON.Point> = {
    type: "FeatureCollection",
    features: [],
  };

  const outsideSelectionArea: GeoJSON.FeatureCollection<GeoJSON.Point> = {
    type: "FeatureCollection",
    features: [],
  };

  (wells ?? []).forEach((point) => {
    const isInRange = inRange(point?.longitude || 0, -180, 180) && inRange(point?.latitude || 0, -90, 90);

    const pointGeometry = {
      type: "Point" as GeoJSON.Point["type"],
      coordinates: [point.longitude || 0, point.latitude || 0],
    };

    let isInsideSelection = !selectionPolygonShape || booleanPointInPolygon(pointGeometry, selectionPolygonShape);

    if (circleInfo?.center && circleInfo?.radius) {
      const drawnCircle = circle([circleInfo.center.lng, circleInfo.center.lat], circleInfo.radius, {
        units: "meters",
      });
      isInsideSelection = booleanWithin(pointGeometry, drawnCircle);
    }
    if (sourceLayer?._data?.features?.[0]?.geometry) {
      isInsideSelection = booleanWithin(
        pointGeometry,
        (sourceLayer._data as FeatureCollection).features[0].geometry as Geometry,
      );
    }

    if (isInRange) {
      const featurePoint = {
        type: "Feature" as GeoJSON.Feature<GeoJSON.MultiPolygon>["type"],
        geometry: pointGeometry,
        properties: {
          id: point.id,
          title: point.name,
          rigs: (point?.jobs || [])
            .map((point) => point.rigId)
            .map((point) => rigs?.byId?.[point]?.shortName || "")
            .join(", "),
          isInside: isInsideSelection,
        },
      };

      if (isInsideSelection) {
        insideSelectionArea.features.push(featurePoint);
      } else {
        outsideSelectionArea.features.push(featurePoint);
      }
    }
  });

  return { insideSelectionArea, outsideSelectionArea };
}
