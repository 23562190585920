import { useMemo } from "react";
import { useAppSelector } from "reducers/store";

import { useTimelineEventsList } from "./useTimelineEventsList";

const NEXT_EVENT_DISTANCE = 500; // Distance in meters from the current position of the Active well to the next events

export const useFuturePlanProjections = () => {
  const { actualEvents, planEvents } = useAppSelector((state) => state.timeline.events);
  const { planTimelineEventsList } = useTimelineEventsList({ actualEvents, planEvents });
  const selectedWellDetails = useAppSelector((state) => state.timeline.currentWellDetails);

  return useMemo(() => {
    if (!selectedWellDetails || selectedWellDetails?.status !== "Active") {
      return [];
    }

    const endWellDepth = selectedWellDetails?.drillingDetails?.maxHoleDepth ?? 0;
    return (
      planTimelineEventsList
        ?.filter((event) => {
          if (!event.type) return false;
          return (
            event.holeDepth - (endWellDepth ?? -Infinity) <= NEXT_EVENT_DISTANCE &&
            event.holeDepth - (endWellDepth ?? Infinity) >= 0
          );
        })
        ?.map((element) => element.id) ?? []
    );
  }, [planTimelineEventsList, selectedWellDetails]);
};
