import styled from "styled-components";
import { css } from "styled-components";
import { zIndexLayer } from "utils/zIndex";

export const StyledModalContent = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.white_black};
  hr {
    margin: 0px;
    width: 100%;
    background: ${({ theme }) => theme.themeStyle.colors.delimiter};
    opacity: 0.3;
  }
`;

export const RecipientTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RecipientSelectorText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const RecipientName = styled.span`
  ${RecipientSelectorText};
  max-width: 140px;
  font-weight: 700;
  margin-right: 15px;
`;

export const RecipientEmail = styled.span`
  ${RecipientSelectorText};
  max-width: 180px;
`;

export const FooterContainer = styled.div`
  height: 65px;
  padding: 22px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};
`;

export const customStyles = {
  overlay: {
    zIndex: zIndexLayer.mars,
    backgroundColor: "rgba(32,43,48,0.6)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    borderRadius: 12,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 543,
    height: "auto",
    overflow: "hidden",
    border: "none",
  },
};
