/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StopWatchLapStep } from './StopWatchLapStep';
import {
    StopWatchLapStepFromJSON,
    StopWatchLapStepFromJSONTyped,
    StopWatchLapStepToJSON,
} from './StopWatchLapStep';

/**
 * 
 * @export
 * @interface StopWatchLapDto
 */
export interface StopWatchLapDto {
    /**
     * 
     * @type {number}
     * @memberof StopWatchLapDto
     */
    readonly seconds: number;
    /**
     * 
     * @type {string}
     * @memberof StopWatchLapDto
     */
    readonly description: string;
    /**
     * 
     * @type {Array<StopWatchLapStep>}
     * @memberof StopWatchLapDto
     */
    readonly innerSteps: Array<StopWatchLapStep>;
}

/**
 * Check if a given object implements the StopWatchLapDto interface.
 */
export function instanceOfStopWatchLapDto(value: object): value is StopWatchLapDto {
    if (!('seconds' in value) || value['seconds'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('innerSteps' in value) || value['innerSteps'] === undefined) return false;
    return true;
}

export function StopWatchLapDtoFromJSON(json: any): StopWatchLapDto {
    return StopWatchLapDtoFromJSONTyped(json, false);
}

export function StopWatchLapDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StopWatchLapDto {
    if (json == null) {
        return json;
    }
    return {
        
        'seconds': json['Seconds'],
        'description': json['Description'],
        'innerSteps': ((json['InnerSteps'] as Array<any>).map(StopWatchLapStepFromJSON)),
    };
}

export function StopWatchLapDtoToJSON(value?: Omit<StopWatchLapDto, 'Seconds'|'Description'|'InnerSteps'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

