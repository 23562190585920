import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Typography as AntTypogrphy } from "utils/componentLibrary";

type IStyle =
  | "faded"
  | "white"
  | "dark"
  | "title"
  | "cta"
  | "caption"
  | "error"
  | "torque_drag_legend_off"
  | "torque_drag_legend_on";

interface ITitle {
  level: 1 | 2 | 3 | 4 | 5;
  variant?: IStyle;
  className?: string;
  weight?: number;
  $lineHeight?: string;
}

interface IText {
  primary: "description" | "caption";
  variant: IStyle;
  fontSize?: number;
  className?: string;
}

const defaultStyle = css`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
`;

const fadedStyle = css`
  color: ${colors.gray} !important;
`;

const whiteStyle = css`
  color: ${colors.pale_grey} !important;
`;

const darkStyle = css`
  color: ${colors.neutral} !important;
`;

const titleStyle = css`
  color: ${colors.neutral} !important;
`;

const ctaStyle = css`
  color: ${colors.well_color} !important;
`;

const captionStyle = css`
  color: ${colors.off_state} !important;
`;

const errorStyle = css`
  color: ${colors.error_color} !important;
`;

const torque_off = css`
  color: ${({ theme }) => theme.themeStyle.colors.torque_and_drag_legend} !important;
`;
const torque_on = css`
  color: ${({ theme }) => theme.themeStyle.colors.alt_typography} !important;
`;

const getRightStyle = (style?: IStyle) => {
  switch (style) {
    case "faded":
      return fadedStyle;
    case "white":
      return whiteStyle;
    case "dark":
      return darkStyle;
    case "title":
      return titleStyle;
    case "cta":
      return ctaStyle;
    case "caption":
      return captionStyle;
    case "error":
      return errorStyle;
    case "torque_drag_legend_on":
      return torque_on;
    case "torque_drag_legend_off":
      return torque_off;
    default:
      return defaultStyle;
  }
};

export const Title = styled(AntTypogrphy.Title)<ITitle>`
  ${(props) => getRightStyle(props?.variant)}
  font-weight: ${(props) => props.weight || 400} !important;
  line-height: ${(props) => props.$lineHeight || "unset"} !important;
`;

export const Text = styled(AntTypogrphy.Text)<IText>`
  ${(props) => getRightStyle(props.variant)}
  font-size: ${({ fontSize, primary }) => {
    if (fontSize) return `${fontSize}px !important`;
    if (primary === "caption") return "12px !important";
    return "14px !important";
  }};
`;
