import * as Styled from "./style";

export const WebGLSupportBanner = () => {
  return (
    <Styled.UnsupportedFeature>
      <div>Map unavailable</div>
      <br />
      <div>WebGL is not supported on this browser.</div>
    </Styled.UnsupportedFeature>
  );
};
