import colors from "utils/colors";

export const HORIZONTAL_AXIS_OFFSET = 15;
export const RIGHT_AXIS_WIDTH = 90;
export const SUMMARY_HEIGHT_MINI = 88;
export const SUMMARY_HEIGHT_DETAILED = 98;
export const MINI_ZOOM_FACTOR = 4;
export const DETAILED_ZOOM_FACTOR = 2;

export const negativeThreshold = {
  fill: colors.tanzine,
  fillOpacity: 0.4,
  stroke: colors.tanzine,
  strokeWidth: 1,
};

export const positiveThreshold = {
  fill: colors.waterfall,
  fillOpacity: 0.4,
  stroke: colors.waterfall,
  strokeWidth: 1,
};
