import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import styled from "styled-components";

export const AddButton = styled(Button)`
  line-height: 14px;
  display: inline-flex;
  padding: 8px 15px;
  height: auto;
  font-size: 14px;
`;

export const AddTrackIcon = styled(PDComponent.SvgIcon)`
  font-size: 14px;
`;
