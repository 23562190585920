import type { SingleKpiProps } from "components/Lenses/interfaces";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";

import { SingleKPIComparison } from "./SingleKpiComparison";
import { SingleKPISimple } from "./SingleKpiSimple";

export const SingleKPI = (props: SingleKpiProps) => {
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);
  const isComparing = comparisonWells?.length > 0;

  return isComparing ? <SingleKPIComparison {...props} /> : <SingleKPISimple {...props} />;
};
