import { useIsFetching } from "@tanstack/react-query";
import { PDComponent } from "components/PDComponents";
import type { FormEvent } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import * as Styled from "./style";

export const CustomInput = ({ setCurrentMessage }: { setCurrentMessage: (message: string) => void }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const dispatch = useAppDispatch();

  const imperativelyPopulatedInput = useAppSelector(
    (state) => state.claraReducer.imperativelyPopulateInputWithThisMessage,
  );

  const isFetchingMessages = useIsFetching({
    queryKey: [{ uid: RequestUID.clara, type: PDQueryType.CLARA_MESSAGE }],
    exact: false,
  });

  const handleFormChangeValidity = useCallback(() => {
    if (isFetchingMessages) return false;
    if (formRef.current) {
      setIsFormValid(formRef.current.checkValidity());
    }
  }, [isFetchingMessages]);

  useEffect(() => {
    if (imperativelyPopulatedInput) {
      if (textareaRef.current) {
        textareaRef.current.value = imperativelyPopulatedInput;
        handleFormChangeValidity();
        dispatch({ type: "CLARA_POPULATE_INPUT", payload: null });
      }
    }
  }, [dispatch, handleFormChangeValidity, imperativelyPopulatedInput, setCurrentMessage]);

  const handleOnSubmit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      if (isFetchingMessages) return;
      ev.preventDefault();
      const inputValue = ((ev.target as HTMLFormElement).elements[0] as HTMLInputElement).value;
      setCurrentMessage(inputValue);
      (ev.target as HTMLFormElement).reset();
    },
    [isFetchingMessages, setCurrentMessage],
  );

  return (
    <Styled.ResizableContainer id="questionInput">
      <form onSubmit={handleOnSubmit} ref={formRef} onChange={handleFormChangeValidity}>
        <Styled.InputGroup>
          <Styled.CustomInput
            placeholder="Enter your question here"
            required
            ref={textareaRef}
            maxRows={6}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (!isFormValid || !!isFetchingMessages) return;
                textareaRef.current?.form?.requestSubmit();
              }
            }}
          />
          <Styled.SendButton
            trackingName="Send clara message"
            size="large"
            htmlType="submit"
            icon={<PDComponent.SvgIcon name="sendFilled" />}
            disabled={!isFormValid || !!isFetchingMessages}
          />
        </Styled.InputGroup>
      </form>
    </Styled.ResizableContainer>
  );
};
