/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Int32Int64FactIndicatorSetDto } from './Int32Int64FactIndicatorSetDto';
import {
    Int32Int64FactIndicatorSetDtoFromJSON,
    Int32Int64FactIndicatorSetDtoFromJSONTyped,
    Int32Int64FactIndicatorSetDtoToJSON,
} from './Int32Int64FactIndicatorSetDto';
import type { StackedStandKpiDetailByShiftDto } from './StackedStandKpiDetailByShiftDto';
import {
    StackedStandKpiDetailByShiftDtoFromJSON,
    StackedStandKpiDetailByShiftDtoFromJSONTyped,
    StackedStandKpiDetailByShiftDtoToJSON,
} from './StackedStandKpiDetailByShiftDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { StackedStandKpiSummaryDto } from './StackedStandKpiSummaryDto';
import {
    StackedStandKpiSummaryDtoFromJSON,
    StackedStandKpiSummaryDtoFromJSONTyped,
    StackedStandKpiSummaryDtoToJSON,
} from './StackedStandKpiSummaryDto';
import type { DrillingTaskSummary } from './DrillingTaskSummary';
import {
    DrillingTaskSummaryFromJSON,
    DrillingTaskSummaryFromJSONTyped,
    DrillingTaskSummaryToJSON,
} from './DrillingTaskSummary';
import type { StandKpiDetailsDto } from './StandKpiDetailsDto';
import {
    StandKpiDetailsDtoFromJSON,
    StandKpiDetailsDtoFromJSONTyped,
    StandKpiDetailsDtoToJSON,
} from './StandKpiDetailsDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { StackedStandKpiDetailByDateShiftDto } from './StackedStandKpiDetailByDateShiftDto';
import {
    StackedStandKpiDetailByDateShiftDtoFromJSON,
    StackedStandKpiDetailByDateShiftDtoFromJSONTyped,
    StackedStandKpiDetailByDateShiftDtoToJSON,
} from './StackedStandKpiDetailByDateShiftDto';
import type { StandKpiSliceDto } from './StandKpiSliceDto';
import {
    StandKpiSliceDtoFromJSON,
    StandKpiSliceDtoFromJSONTyped,
    StandKpiSliceDtoToJSON,
} from './StandKpiSliceDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface StackedStandKpiDto
 */
export interface StackedStandKpiDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof StackedStandKpiDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof StackedStandKpiDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StackedStandKpiDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {FiltersDto}
     * @memberof StackedStandKpiDto
     */
    availableFilters: FiltersDto;
    /**
     * 
     * @type {StackedStandKpiSummaryDto}
     * @memberof StackedStandKpiDto
     */
    summary: StackedStandKpiSummaryDto;
    /**
     * 
     * @type {DrillingTaskSummary}
     * @memberof StackedStandKpiDto
     */
    drillingTaskSummary: DrillingTaskSummary;
    /**
     * 
     * @type {Int32Int64FactIndicatorSetDto}
     * @memberof StackedStandKpiDto
     */
    indicators: Int32Int64FactIndicatorSetDto;
    /**
     * 
     * @type {Array<StandKpiSliceDto>}
     * @memberof StackedStandKpiDto
     */
    summaryByKpi?: Array<StandKpiSliceDto> | null;
    /**
     * 
     * @type {Array<StandKpiDetailsDto>}
     * @memberof StackedStandKpiDto
     */
    detailsByStandDepth?: Array<StandKpiDetailsDto> | null;
    /**
     * 
     * @type {Array<StackedStandKpiDetailByDateShiftDto>}
     * @memberof StackedStandKpiDto
     */
    detailsByDateShift?: Array<StackedStandKpiDetailByDateShiftDto> | null;
    /**
     * 
     * @type {StackedStandKpiDetailByShiftDto}
     * @memberof StackedStandKpiDto
     */
    detailsByShift: StackedStandKpiDetailByShiftDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof StackedStandKpiDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the StackedStandKpiDto interface.
 */
export function instanceOfStackedStandKpiDto(value: object): value is StackedStandKpiDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('availableFilters' in value) || value['availableFilters'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('drillingTaskSummary' in value) || value['drillingTaskSummary'] === undefined) return false;
    if (!('indicators' in value) || value['indicators'] === undefined) return false;
    if (!('detailsByShift' in value) || value['detailsByShift'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function StackedStandKpiDtoFromJSON(json: any): StackedStandKpiDto {
    return StackedStandKpiDtoFromJSONTyped(json, false);
}

export function StackedStandKpiDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackedStandKpiDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'availableFilters': FiltersDtoFromJSON(json['AvailableFilters']),
        'summary': StackedStandKpiSummaryDtoFromJSON(json['Summary']),
        'drillingTaskSummary': DrillingTaskSummaryFromJSON(json['DrillingTaskSummary']),
        'indicators': Int32Int64FactIndicatorSetDtoFromJSON(json['Indicators']),
        'summaryByKpi': json['SummaryByKpi'] == null ? undefined : ((json['SummaryByKpi'] as Array<any>).map(StandKpiSliceDtoFromJSON)),
        'detailsByStandDepth': json['DetailsByStandDepth'] == null ? undefined : ((json['DetailsByStandDepth'] as Array<any>).map(StandKpiDetailsDtoFromJSON)),
        'detailsByDateShift': json['DetailsByDateShift'] == null ? undefined : ((json['DetailsByDateShift'] as Array<any>).map(StackedStandKpiDetailByDateShiftDtoFromJSON)),
        'detailsByShift': StackedStandKpiDetailByShiftDtoFromJSON(json['DetailsByShift']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function StackedStandKpiDtoToJSON(value?: StackedStandKpiDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'AvailableFilters': FiltersDtoToJSON(value['availableFilters']),
        'Summary': StackedStandKpiSummaryDtoToJSON(value['summary']),
        'DrillingTaskSummary': DrillingTaskSummaryToJSON(value['drillingTaskSummary']),
        'Indicators': Int32Int64FactIndicatorSetDtoToJSON(value['indicators']),
        'SummaryByKpi': value['summaryByKpi'] == null ? undefined : ((value['summaryByKpi'] as Array<any>).map(StandKpiSliceDtoToJSON)),
        'DetailsByStandDepth': value['detailsByStandDepth'] == null ? undefined : ((value['detailsByStandDepth'] as Array<any>).map(StandKpiDetailsDtoToJSON)),
        'DetailsByDateShift': value['detailsByDateShift'] == null ? undefined : ((value['detailsByDateShift'] as Array<any>).map(StackedStandKpiDetailByDateShiftDtoToJSON)),
        'DetailsByShift': StackedStandKpiDetailByShiftDtoToJSON(value['detailsByShift']),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

