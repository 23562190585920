import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import type { RigPowerFactDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { AVAILABLE_POWER_LEGEND_ID } from "components/Lenses/ContainerLens/PowerConsumptionByOperation/utils";
import { getCumulativeVisibleGenerators, getTotalDisplayedPower } from "components/Lenses/ContainerLens/RigPower/utils";
import { bisector } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { useEffect, useMemo } from "react";
import type { OnRefChangeType } from "react-resize-detector/build/types/types";
import { ALTERNATIVE_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { AVAILABLE_POWER_ID } from "./useAdditionalLegendItems";

export const useRigPowerTooltip = ({
  containerRef,
  generatorLegendItems,
  legendItems,
  xScale,
  yScale,
  series,
  isPointerInsideChart,
  plotWidth,
  plotHeight,
  pointerPosition = { x: 0, y: 0 },
}: {
  containerRef: OnRefChangeType<HTMLDivElement> | React.MutableRefObject<HTMLDivElement>;
  legendItems: LegendItem[];
  xScale: ScaleLinear<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  series: RigPowerFactDto[];
  generatorLegendItems: LegendItem[];
  isPointerInsideChart: boolean;
  plotWidth: number;
  plotHeight: number;
  pointerPosition: { x: number; y: number };
}) => {
  const uomValue = useUOM(DimensionType.Watts);
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent: ({ tooltipData }: { tooltipData?: RigPowerFactDto }) => {
      return tooltipData ? (
        <>
          <TooltipHighlightValue style={{ fontWeight: "bold", fontSize: 12 }}>
            {uomValue.display(getTotalDisplayedPower(generatorLegendItems, tooltipData), {
              fractionDigits: 0,
            })}
          </TooltipHighlightValue>

          {generatorLegendItems
            .toReversed()
            .filter((legendItem) => legendItem.isEnabled)
            .map((legendItem) => {
              const matechedTooltipValue = tooltipData?.slices?.find((slice) => slice.generator === legendItem.id);
              return (
                <TooltipHighlightValue
                  key={legendItem.id}
                  style={{ fontSize: 12, color: themeColors.disabled_typography }}
                >
                  {legendItem.name}:{" "}
                  {uomValue.display(matechedTooltipValue ? matechedTooltipValue.sliceValue : 0, {
                    fractionDigits: 0,
                  })}
                </TooltipHighlightValue>
              );
            })}

          {legendItems.find((legendItem) => legendItem.id === AVAILABLE_POWER_ID)?.isEnabled ? (
            <TooltipHighlightValue style={{ fontSize: 12, color: themeColors.disabled_typography }}>
              Available Power:
              {uomValue.display(tooltipData.availablePower, {
                fractionDigits: 0,
              })}
            </TooltipHighlightValue>
          ) : null}

          {tooltipData?.at ? (
            <TooltipHighlightValue style={{ color: themeColors.disabled_typography, fontSize: 12 }}>
              {formatTime(tooltipData.at, { formatStr: ALTERNATIVE_DATE_FORMAT })}
            </TooltipHighlightValue>
          ) : null}
        </>
      ) : null;
    },
  });

  const tooltipPoint: RigPowerFactDto | null = useMemo(() => {
    if (!series) return null;

    if (pointerPosition.x > plotWidth) {
      return null;
    }

    const x0 = xScale.invert(pointerPosition.x);

    let extremitiesBisectFix = 0;

    if (pointerPosition.x < 30) {
      // To make sure we can select both points at the edge of the graph, normal  bisect leaves out either one or the other
      // Artificially adding mouse X if it's close to the plot edges to get correct edge index
      extremitiesBisectFix = -0.001;
    } else if (Math.abs(pointerPosition.x - plotWidth) < 10) {
      extremitiesBisectFix = 0.001;
    }

    const index = bisector<RigPowerFactDto, number>((p) => p.index).left(series, x0 + extremitiesBisectFix);

    if (index >= 0 && index < series.length && series[index].index) {
      return series[index];
    }

    return null;
  }, [plotWidth, pointerPosition.x, series, xScale]);

  const cumulativeVisibleGenerators = useMemo(
    () => getCumulativeVisibleGenerators(generatorLegendItems, tooltipPoint),
    [generatorLegendItems, tooltipPoint],
  );

  useEffect(() => {
    isPointerInsideChart && tooltipPoint
      ? showTooltip({
          tooltipLeft: xScale(tooltipPoint.index) || 0,
          tooltipTop:
            yScale(
              Math.min(
                Math.max(
                  legendItems.find((item) => item.id === AVAILABLE_POWER_LEGEND_ID)?.isEnabled &&
                    tooltipPoint.availablePower
                    ? tooltipPoint.availablePower
                    : 0,
                  ...cumulativeVisibleGenerators.map((slice) => slice?.positionalValue ?? 0),
                ),
                yScale.domain()[1],
              ),
            ) || 0,
          tooltipData: tooltipPoint,
        })
      : hideTooltip();
  }, [
    cumulativeVisibleGenerators,
    hideTooltip,
    isPointerInsideChart,
    legendItems,
    pointerPosition,
    showTooltip,
    tooltipPoint,
    xScale,
    yScale,
  ]);

  const Tooltip =
    tooltipPoint && isPointerInsideChart ? (
      <>
        {tooltipElement}
        <Group>
          <Line
            from={{
              x: xScale(tooltipPoint.index),
              y: plotHeight,
            }}
            to={{
              x: xScale(tooltipPoint.index),
              y: 0,
            }}
            strokeOpacity={0.3}
            stroke={themeColors.primary_typography}
            strokeWidth={2}
          />

          {cumulativeVisibleGenerators.map((gen) => {
            return (
              <circle
                key={gen?.legendItem.id}
                cx={xScale(tooltipPoint.index)}
                cy={yScale(gen?.positionalValue ?? 0)}
                r={3}
                fill={themeColors.black_white}
              />
            );
          })}

          {legendItems.find((item) => item.id === AVAILABLE_POWER_LEGEND_ID)?.isEnabled &&
          tooltipPoint.availablePower ? (
            <circle
              cx={xScale(tooltipPoint.index)}
              cy={yScale(tooltipPoint.availablePower)}
              r={3}
              fill={themeColors.black_white}
            />
          ) : null}
        </Group>
      </>
    ) : null;
  return { tooltipPoint, tooltipElement: Tooltip };
};
