/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotificationResultSetDto,
  NotificationStatusUpdateRequestDto,
} from '../models/index';
import {
    NotificationResultSetDtoFromJSON,
    NotificationResultSetDtoToJSON,
    NotificationStatusUpdateRequestDtoFromJSON,
    NotificationStatusUpdateRequestDtoToJSON,
} from '../models/index';

export interface ApiNotificationsDeletePutRequest {
    notificationStatusUpdateRequestDto?: NotificationStatusUpdateRequestDto;
}

export interface ApiNotificationsMarkAsArchivedPutRequest {
    notificationStatusUpdateRequestDto?: NotificationStatusUpdateRequestDto;
}

export interface ApiNotificationsMarkAsReadPutRequest {
    notificationStatusUpdateRequestDto?: NotificationStatusUpdateRequestDto;
}

export interface ApiNotificationsMarkAsUnarchivedPutRequest {
    notificationStatusUpdateRequestDto?: NotificationStatusUpdateRequestDto;
}

export interface ApiNotificationsMarkAsUnreadPutRequest {
    notificationStatusUpdateRequestDto?: NotificationStatusUpdateRequestDto;
}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     */
    async apiNotificationsActiveGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationResultSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Notifications/Active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationResultSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiNotificationsActiveGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationResultSetDto> {
        const response = await this.apiNotificationsActiveGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNotificationsArchivedCountGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Notifications/Archived/Count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiNotificationsArchivedCountGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiNotificationsArchivedCountGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNotificationsArchivedGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationResultSetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Notifications/Archived`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationResultSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiNotificationsArchivedGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationResultSetDto> {
        const response = await this.apiNotificationsArchivedGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNotificationsDeletePutRaw(requestParameters: ApiNotificationsDeletePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Notifications/Delete`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationStatusUpdateRequestDtoToJSON(requestParameters['notificationStatusUpdateRequestDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiNotificationsDeletePut(requestParameters: ApiNotificationsDeletePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiNotificationsDeletePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNotificationsMarkAsArchivedPutRaw(requestParameters: ApiNotificationsMarkAsArchivedPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Notifications/MarkAsArchived`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationStatusUpdateRequestDtoToJSON(requestParameters['notificationStatusUpdateRequestDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiNotificationsMarkAsArchivedPut(requestParameters: ApiNotificationsMarkAsArchivedPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiNotificationsMarkAsArchivedPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNotificationsMarkAsReadPutRaw(requestParameters: ApiNotificationsMarkAsReadPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Notifications/MarkAsRead`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationStatusUpdateRequestDtoToJSON(requestParameters['notificationStatusUpdateRequestDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiNotificationsMarkAsReadPut(requestParameters: ApiNotificationsMarkAsReadPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiNotificationsMarkAsReadPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNotificationsMarkAsUnarchivedPutRaw(requestParameters: ApiNotificationsMarkAsUnarchivedPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Notifications/MarkAsUnarchived`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationStatusUpdateRequestDtoToJSON(requestParameters['notificationStatusUpdateRequestDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiNotificationsMarkAsUnarchivedPut(requestParameters: ApiNotificationsMarkAsUnarchivedPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiNotificationsMarkAsUnarchivedPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNotificationsMarkAsUnreadPutRaw(requestParameters: ApiNotificationsMarkAsUnreadPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Notifications/MarkAsUnread`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationStatusUpdateRequestDtoToJSON(requestParameters['notificationStatusUpdateRequestDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiNotificationsMarkAsUnreadPut(requestParameters: ApiNotificationsMarkAsUnreadPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiNotificationsMarkAsUnreadPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
