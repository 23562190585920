/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DimensionType } from './DimensionType';
import {
    DimensionTypeFromJSON,
    DimensionTypeFromJSONTyped,
    DimensionTypeToJSON,
} from './DimensionType';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface StandKpiTypeDto
 */
export interface StandKpiTypeDto {
    /**
     * 
     * @type {StandKpiType}
     * @memberof StandKpiTypeDto
     */
    id: StandKpiType;
    /**
     * 
     * @type {string}
     * @memberof StandKpiTypeDto
     */
    categoryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandKpiTypeDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandKpiTypeDto
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandKpiTypeDto
     */
    description?: string | null;
    /**
     * 
     * @type {DimensionType}
     * @memberof StandKpiTypeDto
     */
    dimension: DimensionType;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiTypeDto
     */
    includesFlatTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiTypeDto
     */
    includesSlidingTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiTypeDto
     */
    includesRotatingTime: boolean;
}



/**
 * Check if a given object implements the StandKpiTypeDto interface.
 */
export function instanceOfStandKpiTypeDto(value: object): value is StandKpiTypeDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('dimension' in value) || value['dimension'] === undefined) return false;
    if (!('includesFlatTime' in value) || value['includesFlatTime'] === undefined) return false;
    if (!('includesSlidingTime' in value) || value['includesSlidingTime'] === undefined) return false;
    if (!('includesRotatingTime' in value) || value['includesRotatingTime'] === undefined) return false;
    return true;
}

export function StandKpiTypeDtoFromJSON(json: any): StandKpiTypeDto {
    return StandKpiTypeDtoFromJSONTyped(json, false);
}

export function StandKpiTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiTypeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': StandKpiTypeFromJSON(json['Id']),
        'categoryName': json['CategoryName'] == null ? undefined : json['CategoryName'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'shortName': json['ShortName'] == null ? undefined : json['ShortName'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'dimension': DimensionTypeFromJSON(json['Dimension']),
        'includesFlatTime': json['IncludesFlatTime'],
        'includesSlidingTime': json['IncludesSlidingTime'],
        'includesRotatingTime': json['IncludesRotatingTime'],
    };
}

export function StandKpiTypeDtoToJSON(value?: StandKpiTypeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': StandKpiTypeToJSON(value['id']),
        'CategoryName': value['categoryName'],
        'Name': value['name'],
        'ShortName': value['shortName'],
        'Description': value['description'],
        'Dimension': DimensionTypeToJSON(value['dimension']),
        'IncludesFlatTime': value['includesFlatTime'],
        'IncludesSlidingTime': value['includesSlidingTime'],
        'IncludesRotatingTime': value['includesRotatingTime'],
    };
}

