import type { RigViewmodel } from "pages/RigLeaderboard/components/RigLeaderboardList";
import { BenchmarkType, useScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";

export enum SortBy {
  Rig = "Rig",
  OverallScore = "Overall Score",
  Trend = "Trend",
  OpportunityTime = "Time vs Target",
  TimeSaved = "Time vs Base",
  Downtime = "Downtime",
  Compliance = "Compliance",
  ExceptionCount = "Exception Count",
  ExceptionTime = "Exception Time",
}
export const MODAL_MIN_WIDTH = 620;
export const MODAL_MIN_HEIGHT = 150;
export const MODAL_MAX_HEIGHT = 500;
export const BREAKOUT_BAR_WIDTH = 265;
export const BREAKOUT_BAR_HEIGHT = 16;
export const BREAKOUT_BAR_HEIGHT_SMALL = 8;

export const Columns = [
  {
    title: "Order",
    isSortable: false,
  },
  {
    title: "Rig",
    isSortable: true,
    sortKey: SortBy.Rig,
  },

  {
    title: "Overall Score",
    isSortable: true,
    sortKey: SortBy.OverallScore,
  },

  {
    title: "Trend",
    isSortable: true,
    sortKey: SortBy.Trend,
  },

  {
    title: "Time vs Target",
    isSortable: true,
    sortKey: SortBy.OpportunityTime,
  },

  {
    title: "Time vs Base",
    isSortable: true,
    sortKey: SortBy.TimeSaved,
  },

  {
    title: "# of Exceptions",
    isSortable: true,
    sortKey: SortBy.ExceptionCount,
  },

  {
    title: "Downtime %",
    isSortable: true,
    sortKey: SortBy.Downtime,
  },

  {
    title: "Exception Time",
    isSortable: true,
    sortKey: SortBy.ExceptionTime,
  },

  {
    title: "Scorecard Tasks",
    isSortable: true,
    sortKey: SortBy.Compliance,
  },
  {
    title: "Operator Target",
    isSortable: false,
  },
];

export const useScoreValues = () => {
  const { scoreBenchmark } = useScoreBenchmarkContext();
  function getValueFor(rig: RigViewmodel, key: "score" | "opportunityTime" | "timeSaved" | "trend" | "complianceRate") {
    if (key === "score") {
      const scoreKey = scoreBenchmark === BenchmarkType.OperatorTarget ? "operatorScore" : "rigScore";
      return rig[scoreKey];
    } else if (key === "opportunityTime") {
      const opportunityKey = scoreBenchmark === BenchmarkType.OperatorTarget ? "targetDeltaTime" : "invisibleLostTime";
      return rig[opportunityKey];
    } else if (key === "timeSaved") {
      const timeSavedKey = scoreBenchmark === BenchmarkType.OperatorTarget ? "savedTime" : "savedTime";
      return rig[timeSavedKey];
    } else if (key === "trend") {
      const trendKey = scoreBenchmark === BenchmarkType.OperatorTarget ? "operatorTrend" : "rigTrend";
      return rig[trendKey];
    } else if (key === "complianceRate") {
      const complianceKey =
        scoreBenchmark === BenchmarkType.OperatorTarget ? "operatorScoreComplianceRate" : "rigScoreComplianceRate";
      return rig[complianceKey];
    }
    return 0;
  }

  return getValueFor;
};
