import styled from "styled-components";

const StyledSvg = styled.div`
  height: 100px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

function Error401Svg() {
  return (
    <StyledSvg>
      <svg
        width="175px"
        height="74px"
        viewBox="0 0 175 74"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="401" stroke="none" strokeWidth="1" fill="currentColor">
          <g id="4" transform="translate(0.000000, 4.458000)">
            <path
              d="M0,44.454 L31.248,0 L36.363,0 L36.363,44.454 L51.615,44.454 L51.615,49.104 L36.363,49.104 L36.363,64.542 L31.248,64.542 L31.248,49.104 L0,49.104 L0,44.454 Z M6.324,44.454 L31.248,44.454 L31.248,9.021 L6.324,44.454 Z"
              id="Shape"
            ></path>
          </g>
          <g id="1" transform="translate(134.508500, 4.458000)">
            <polygon
              id="Path"
              points="21.204 0 21.204 64.542 16.089 64.542 16.089 6.789 0 20.46 0 14.229 16.089 0"
            ></polygon>
          </g>
          <g id="Tools-/-Instruments-/-radar-/-24" transform="translate(53.130500, 4.625000)">
            <path
              d="M64.7501734,3.27010625 L61.4798937,0 L30.7400625,30.7400625 C29.8636893,31.6474387 29.8762226,33.0897585 30.768232,33.981768 C31.6602415,34.8737774 33.1025613,34.8863107 34.0099375,34.0099375 L43.6761875,24.3455375 C47.3758219,29.5634881 47.0256915,36.6351612 42.8287159,41.4621374 C38.6317404,46.2891136 31.6773192,47.6184656 25.9959178,44.6797616 C20.3145164,41.7410576 17.3808434,35.2970903 18.8951218,29.0824895 C20.4094003,22.8678887 25.9785725,18.4957641 32.375,18.5 L32.375,13.875 C23.7519497,13.8631862 16.2610278,19.8025559 14.3063926,28.2011593 C12.3517574,36.5997626 16.4503561,45.2364004 24.1924883,49.0333105 C31.9346205,52.8302206 41.2727055,50.7832249 46.7165774,44.0958191 C52.1604494,37.4084134 52.2703541,27.8492323 46.981675,21.0384312 L53.5646687,14.4554375 C57.8221787,19.4529726 60.1494254,25.8098533 60.125,32.375 C60.125,47.7009018 47.7009018,60.125 32.375,60.125 C17.0490982,60.125 4.625,47.7009018 4.625,32.375 C4.625,17.0490982 17.0490982,4.625 32.375,4.625 L32.375,0 C14.4947812,0 0,14.4947812 0,32.375 C0,50.2552188 14.4947812,64.75 32.375,64.75 C50.2552188,64.75 64.7501734,50.2552188 64.7501734,32.375 C64.7756196,24.5841701 61.9620906,17.0508442 56.8357,11.184175 L64.7501734,3.27010625 Z"
              id="Fill"
            ></path>
          </g>
        </g>
      </svg>
    </StyledSvg>
  );
}

export default Error401Svg;
