import { useIsFetching } from "@tanstack/react-query";
import { ZoomChartContext, ZoomState } from "components/Lenses/common/LensZoom/ZoomChartContext";
import { StickSlipByDepthLoading } from "components/Lenses/ContainerLens/StickSlipByDepth/Parts/Loading/Loading";
import type { StickSlipByTimeProps } from "components/Lenses/interfaces";
import { PDComponent } from "components/PDComponents";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { RequestUID } from "utils/queryNamespaces";

import { Chart } from "./components/Chart/Chart";
import { LensHeader } from "./components/Header/LensHeader";
import * as Styled from "./styles";
import { TooltipDataProvider } from "./utils/useStickSlipByTimeTooltip";

export type Domain = [number, number];

export const StickSlipByTime: React.FC<StickSlipByTimeProps> = ({ lens, detailed, onLensUpdated }) => {
  const [initialDomain, setInitialDomain] = useState<Domain | null>([0, 0]);
  const [domain, setDomain] = useState<Domain | null>(initialDomain);
  const [zoomState, setZoomState] = useState(ZoomState.Zooming);
  const [currentDomain, setCurrentDomain] = useState<Domain | null>([0, 0]);
  const isFetchingData = useIsFetching({ queryKey: [{ uid: RequestUID.StickSlipByTime }] });

  useEffect(() => {
    if (!isFetchingData) setDomain(initialDomain);
  }, [initialDomain, isFetchingData]);

  const resetZoom = useCallback(() => {
    setDomain(initialDomain);
    setCurrentDomain([0, 0]);
    setZoomState(ZoomState.Zooming);
  }, [initialDomain, setDomain, setZoomState]);

  useEffect(() => {
    if (currentDomain?.some((domainPoint) => domainPoint !== 0)) {
      setDomain(currentDomain);
    }
  }, [currentDomain]);

  const ControlButtons = useCallback(
    () => (
      <Styled.ButtonContainer>
        <Styled.Button onClick={() => setZoomState(ZoomState.Zooming)}>
          <PDComponent.SvgIcon name="zoomInArea" />
        </Styled.Button>
        <Styled.Button onClick={() => setZoomState(ZoomState.Pan)}>
          <PDComponent.SvgIcon name="zoomPan" />
        </Styled.Button>
        <Styled.Button onClick={resetZoom}>
          <PDComponent.SvgIcon name="reset" />
        </Styled.Button>
      </Styled.ButtonContainer>
    ),
    [resetZoom],
  );

  const initialZoom = useMemo(
    () => ({
      zoomState,
      triggerZoomReset: resetZoom,
      domain,
      initialDomain,
      setDomain: setCurrentDomain,
    }),
    [zoomState, resetZoom, domain, initialDomain],
  );

  return (
    <Styled.Container>
      {!detailed ? <LensHeader ControlButtons={ControlButtons} /> : null}
      <Suspense
        fallback={
          <StickSlipByDepthLoading title={"..."} detailed={detailed} selectedTrackItems={[]} lensId={lens?.id} />
        }
      >
        <ZoomChartContext.Provider value={initialZoom}>
          <TooltipDataProvider>
            <Chart
              isDetailed={detailed}
              lens={lens}
              onLensUpdated={onLensUpdated}
              showPercentageDifference={lens.showPercentageDifference}
              showZTorque={lens.showZTorque}
              setInitialDomain={setInitialDomain}
              currentDomain={currentDomain}
              ControlButtons={detailed ? ControlButtons : null}
            />
          </TooltipDataProvider>
        </ZoomChartContext.Provider>
      </Suspense>
    </Styled.Container>
  );
};
