import * as Styled from "components/General/EmptyNotificationsList/style";
import { PDComponent } from "components/PDComponents";
import { zIndexLayer } from "utils/zIndex";

export const EmptyTasksList = () => {
  return (
    <Styled.Container $isLargeContainer>
      <Styled.WhiteFillContainer />
      {/* zIndex to cover white fill behind svg checkmark, in order to not add another icon just for the solid V shape */}
      <PDComponent.SvgIcon name="checkmarkFilled" style={{ fontSize: "118px", zIndex: zIndexLayer.elevated }} />
      <Styled.TitleFaint>No Pending Approvals</Styled.TitleFaint>
    </Styled.Container>
  );
};
