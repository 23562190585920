/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DashboardType = {
    System: 'System',
    Well: 'Well',
    Rig: 'Rig',
    EverGreen: 'EverGreen',
    RigFleetPerformance: 'RigFleetPerformance',
    RigScorecard: 'RigScorecard',
    PwaRig: 'PwaRig'
} as const;
export type DashboardType = typeof DashboardType[keyof typeof DashboardType];


export function instanceOfDashboardType(value: any): boolean {
    for (const key in DashboardType) {
        if (Object.prototype.hasOwnProperty.call(DashboardType, key)) {
            if (DashboardType[key as keyof typeof DashboardType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DashboardTypeFromJSON(json: any): DashboardType {
    return DashboardTypeFromJSONTyped(json, false);
}

export function DashboardTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardType {
    return json as DashboardType;
}

export function DashboardTypeToJSON(value?: DashboardType | null): any {
    return value as any;
}

