import type { DieselNatGasRatioStatsProps } from "components/Lenses/interfaces";

import { DieselNatGasRatioChart } from "./Chart";
import { useDieselNatGasRatioStatsFacts } from "./fetcher";

export const DieselNatGasRatioKpi: React.FC<DieselNatGasRatioStatsProps> = ({ lens }) => {
  const { data, isLoading } = useDieselNatGasRatioStatsFacts(lens);
  return (
    <DieselNatGasRatioChart
      dieselRatio={data?.dieselRatio}
      natgasRatio={data?.naturalGasRatio}
      lens={lens}
      dataState={data?.dataState}
      isLoading={isLoading}
    />
  );
};
