/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface StandKpiCommentDto
 */
export interface StandKpiCommentDto {
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof StandKpiCommentDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof StandKpiCommentDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StandKpiCommentDto
     */
    description?: string | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof StandKpiCommentDto
     */
    kpiType: StandKpiType;
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentDto
     */
    standId: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentDto
     */
    startRangeStandId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentDto
     */
    endRangeStandId?: number | null;
}



/**
 * Check if a given object implements the StandKpiCommentDto interface.
 */
export function instanceOfStandKpiCommentDto(value: object): value is StandKpiCommentDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('kpiType' in value) || value['kpiType'] === undefined) return false;
    if (!('standId' in value) || value['standId'] === undefined) return false;
    return true;
}

export function StandKpiCommentDtoFromJSON(json: any): StandKpiCommentDto {
    return StandKpiCommentDtoFromJSONTyped(json, false);
}

export function StandKpiCommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiCommentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': json['DeletedAtUtc'] == null ? undefined : (new Date(json['DeletedAtUtc'])),
        'wellId': json['WellId'],
        'title': json['Title'] == null ? undefined : json['Title'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'kpiType': StandKpiTypeFromJSON(json['KpiType']),
        'standId': json['StandId'],
        'startRangeStandId': json['StartRangeStandId'] == null ? undefined : json['StartRangeStandId'],
        'endRangeStandId': json['EndRangeStandId'] == null ? undefined : json['EndRangeStandId'],
    };
}

export function StandKpiCommentDtoToJSON(value?: StandKpiCommentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'DeletedAtUtc': value['deletedAtUtc'] == null ? undefined : ((value['deletedAtUtc'] as any).toISOString()),
        'WellId': value['wellId'],
        'Title': value['title'],
        'Description': value['description'],
        'KpiType': StandKpiTypeToJSON(value['kpiType']),
        'StandId': value['standId'],
        'StartRangeStandId': value['startRangeStandId'],
        'EndRangeStandId': value['endRangeStandId'],
    };
}

