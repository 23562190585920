import type { UseQueryOptions, UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type { NotificationResultSetDto } from "apis/oag";
import { NotificationsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const notificationsApi = new NotificationsApi(apiConfig);

export const ActiveNotificationsQueryKey: PDUniqueQueryKey = { uid: RequestUID.activeNotifications };
export function useActiveNotifications<T>(
  options?: Partial<UseSuspenseQueryOptions<NotificationResultSetDto, Error, T>>,
) {
  return useSuspenseQuery({
    queryKey: [ActiveNotificationsQueryKey],
    queryFn: () => notificationsApi.apiNotificationsActiveGet(),
    ...options,
  });
}

export const ArchivedNotificationsQueryKey: PDUniqueQueryKey = { uid: RequestUID.archivedNotifications };
export function useArchivedNotifications<T>(options?: Partial<UseQueryOptions<NotificationResultSetDto, Error, T>>) {
  return useQuery({
    queryKey: [ArchivedNotificationsQueryKey],
    queryFn: () => notificationsApi.apiNotificationsArchivedGet(),
    ...options,
  });
}

const ArchivedNotificationsCountQueryKey: PDUniqueQueryKey = { uid: RequestUID.archivedNotificationsCount };
export function useArchivedNotificationsCount(options?: UseSuspenseQueryOptions<number>) {
  return useSuspenseQuery<number>({
    queryKey: [ArchivedNotificationsCountQueryKey],
    queryFn: () => notificationsApi.apiNotificationsArchivedCountGet(),
    ...options,
  });
}
