import styled, { css } from "styled-components";

export const FormGroupContainer = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const UnitContainer = styled.div`
  margin-top: 48px;
  margin-bottom: 24px;
`;

export const FormLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const ToggleButton = styled.button<{ inactive?: boolean; $positionedOnTop?: boolean }>`
  outline: none;
  background: none;
  border: none;

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
  opacity: ${(props) => (props.inactive ? 0.4 : 1)};

  ${({ $positionedOnTop }) =>
    $positionedOnTop &&
    css`
      position: absolute;
      top: -28px;
      right: 0px;
      min-width: 95px;
    `}
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  width: 100%;
`;
