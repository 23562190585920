import { IconContainer } from "icons/styles";
import styled from "styled-components";
import colors from "utils/colors";
import { zIndexLayer } from "utils/zIndex";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: ${zIndexLayer.high};
  border-bottom: 1px solid ${colors.necron_compound}1A;
`;

export const InfoContainer = styled.div`
  ${IconContainer} {
    color: ${colors.necron_compound};
  }
`;

export const Text = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  padding-right: 4px;
  text-align: center;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const FocalText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;
