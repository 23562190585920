import { Title } from "atoms/Typography";
import styled from "styled-components";
import { Row, Space } from "utils/componentLibrary";

const Box = styled.div`
  width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  box-shadow: ${(props) => `0 1px 2px 0 ${props.theme.themeStyle.colors.primary_shadow}`};
  background-color: ${(props) => props.theme.themeStyle.colors.quaterniary_bg};
  color: ${(props) => props.theme.themeStyle.colors.primary_typography};
`;

const StyledRow = styled(Row)<{ $isSelected?: boolean }>`
  padding: 24px 15px;
  border-top: solid 1px ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background-color: ${({ $isSelected, theme }) => ($isSelected ? theme.themeStyle.colors.primary_accent : undefined)};
  cursor: pointer;
  :last-child {
    border-bottom: solid 1px ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
  ${Title} {
    cursor: pointer;
    pointer-events: none;
    @media screen and (max-width: 1300px) {
      font-size: 14px;
    }
  }
`;

export function LegendItem({
  title,
  legendKey,
  description,
  onClick,
  isSelected,
}: {
  title: string;
  legendKey: string;
  description?: string;
  onClick?: () => void;
  isSelected?: boolean;
}) {
  return (
    <StyledRow onClick={onClick} $isSelected={isSelected}>
      <Space direction="horizontal">
        <Box>{legendKey}</Box>
        <div>
          <Space direction="vertical">
            <Title level={3}>{title}</Title>
            {description ? (
              <Title level={3} variant="faded">
                {description}
              </Title>
            ) : null}
          </Space>
        </div>
      </Space>
    </StyledRow>
  );
}
