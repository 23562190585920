import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { isUndefined } from "lodash";
import { useRef } from "react";
import styled from "styled-components";
import { OPERATION_COUNT_HEIGHT } from "utils/constants";
import { useCustomTheme } from "utils/useTheme";

export const StyledOperationCountContainer = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "block" : "none")};
  height: ${OPERATION_COUNT_HEIGHT}px;
`;

const StyledText = styled(Text)`
  tspan {
    fill: ${({ theme }) => theme.themeStyle.colors.faint_typography};
    paint-order: stroke;
  }
`;

interface IOperationCountProps {
  x: number;
  width: number;
  topMargin?: number;
  index?: number;
  detailed?: boolean;
  value?: number | string;
}

export const OperationCount: React.FC<IOperationCountProps> = ({
  detailed,
  index = 0,
  topMargin = 0,
  x,
  width,
  value,
}) => {
  const textRef = useRef<SVGSVGElement>(null);
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  if (
    isUndefined(value) ||
    width < 6 ||
    (width < 34 && width >= 20 && index % 2 !== 0) ||
    (width < 20 && width >= 12 && index % 4 !== 0) ||
    (width < 12 && width >= 6 && index % 5 !== 0)
  ) {
    return null;
  }

  const pillWidth = (textRef?.current?.getBBox()?.width || 20) + 10;
  return (
    <Group>
      <rect
        textAnchor="middle"
        height={16}
        width={pillWidth}
        y={detailed ? -48 : -32 + topMargin}
        x={x + width / 2 - pillWidth / 2}
        fill={themeColors.primary_accent}
        rx={5}
      />
      <StyledText
        innerRef={textRef}
        x={x + width / 2}
        y={detailed ? -40 : -24 + topMargin}
        scaleToFit={false}
        textAnchor="middle"
        verticalAnchor="middle"
        fontSize={12}
      >
        {value}
      </StyledText>
    </Group>
  );
};
export default OperationCount;
