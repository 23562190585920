/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface BatteryStateFactDto
 */
export interface BatteryStateFactDto {
    /**
     * 
     * @type {number}
     * @memberof BatteryStateFactDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof BatteryStateFactDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof BatteryStateFactDto
     */
    index: number;
    /**
     * 
     * @type {number}
     * @memberof BatteryStateFactDto
     */
    realPower: number;
}

/**
 * Check if a given object implements the BatteryStateFactDto interface.
 */
export function instanceOfBatteryStateFactDto(value: object): value is BatteryStateFactDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('at' in value) || value['at'] === undefined) return false;
    if (!('index' in value) || value['index'] === undefined) return false;
    if (!('realPower' in value) || value['realPower'] === undefined) return false;
    return true;
}

export function BatteryStateFactDtoFromJSON(json: any): BatteryStateFactDto {
    return BatteryStateFactDtoFromJSONTyped(json, false);
}

export function BatteryStateFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatteryStateFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'at': DateDtoFromJSON(json['At']),
        'index': json['Index'],
        'realPower': json['RealPower'],
    };
}

export function BatteryStateFactDtoToJSON(value?: BatteryStateFactDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'At': DateDtoToJSON(value['at']),
        'Index': value['index'],
        'RealPower': value['realPower'],
    };
}

