import type { ParameterByDepthTrackValuesDto } from "apis/oag";
import { bisector } from "d3-array";

export const COMPLIANCE_BAR_HEIGHT = 6;
export const getParameterNameWidth = (detailed: boolean) => (detailed ? 72 : 64);
export const getParameterStatsWidth = (detailed: boolean) => (detailed ? 261 : 237);
export const getParameterAxisWidth = (detailed: boolean) => (detailed ? 60 : 46);

export const getParameterNameWidthStickSlip = (detailed: boolean) => (detailed ? 72 : 70);
export const getParameterStatsWidthStickSlip = (detailed: boolean) => (detailed ? 164 : 140);
export const getParameterAxisWidthStickSlip = (detailed: boolean) => (detailed ? 90 : 75);

export const axisMargins = {
  vertical: 12,
  horizontal: 10,
};

export const getChartWidth = (detailed: boolean, containerWidth: number) =>
  containerWidth - getParameterNameWidth(detailed) - getParameterStatsWidth(detailed) - getParameterAxisWidth(detailed);

export const getChartWidthStickSlip = (detailed: boolean, containerWidth: number) =>
  containerWidth -
  getParameterNameWidthStickSlip(detailed) -
  getParameterStatsWidthStickSlip(detailed) -
  getParameterAxisWidthStickSlip(detailed);

export function lerpHoleDepth(
  a: ParameterByDepthTrackValuesDto,
  b: ParameterByDepthTrackValuesDto,
  value: number,
): number {
  if (!a.value || !b.value || !a.holeDepth || !b.holeDepth) {
    return 0;
  }

  return (a.holeDepth * (b.value - value) + b.holeDepth * (value - a.value)) / (b.value - a.value);
}

// eslint-disable-next-line @typescript-eslint/unbound-method
export const bisectDepth = bisector<ParameterByDepthTrackValuesDto, number>((d) => d.holeDepth || 0).left;

export const TRACK_SIZE_HEIGHT_INCREMENT_PX = 14;
