import { DisplayOption } from "apis/oag";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useEffect } from "react";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";

// Logic to reset options when changing
export function useResetOptions() {
  const dispatch = useAppDispatch();
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);

  const displayOption = useAppSelector((state) => state.widgetOptions.display_options);

  // TODO Use better logic to change possible display options
  useEffect(() => {
    if (
      comparisonWells.length > 0 &&
      (displayOption === DisplayOption.DateShift || displayOption === DisplayOption.StandDepth)
    ) {
      dispatch({
        type: "SET_DISPLAY_OPTIONS_WIDGET",
        payload: {
          display_options: DisplayOption.Well,
        },
      });
    } else if (
      comparisonWells.length === 0 &&
      (displayOption === DisplayOption.HoleSection || displayOption === DisplayOption.Well)
    ) {
      dispatch({
        type: "SET_DISPLAY_OPTIONS_WIDGET",
        payload: {
          display_options: DisplayOption.StandDepth,
        },
      });
    }
  }, [comparisonWells, dispatch, displayOption]);
}
