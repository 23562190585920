import type { PowerConsumptionByOperationProps } from "components/Lenses/interfaces";
import React from "react";

import { Single } from "./Single";

export const PowerConsumptionByOperation: React.FC<PowerConsumptionByOperationProps> = ({
  lens,
  detailed,
  setLensDate,
}) => {
  return <Single lens={lens} detailed={detailed} setLensDate={setLensDate} />;
};
