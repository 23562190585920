/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ZoomType } from './ZoomType';
import {
    ZoomTypeFromJSON,
    ZoomTypeFromJSONTyped,
    ZoomTypeToJSON,
} from './ZoomType';
import type { LensTabWithVisibilityDto } from './LensTabWithVisibilityDto';
import {
    LensTabWithVisibilityDtoFromJSON,
    LensTabWithVisibilityDtoFromJSONTyped,
    LensTabWithVisibilityDtoToJSON,
} from './LensTabWithVisibilityDto';

/**
 * 
 * @export
 * @interface DashboardDto
 */
export interface DashboardDto {
    /**
     * 
     * @type {Array<LensTabWithVisibilityDto>}
     * @memberof DashboardDto
     */
    personalTabs: Array<LensTabWithVisibilityDto>;
    /**
     * 
     * @type {Array<LensTabWithVisibilityDto>}
     * @memberof DashboardDto
     */
    operatorTabs: Array<LensTabWithVisibilityDto>;
    /**
     * 
     * @type {ZoomType}
     * @memberof DashboardDto
     */
    defaultZoom: ZoomType;
}



/**
 * Check if a given object implements the DashboardDto interface.
 */
export function instanceOfDashboardDto(value: object): value is DashboardDto {
    if (!('personalTabs' in value) || value['personalTabs'] === undefined) return false;
    if (!('operatorTabs' in value) || value['operatorTabs'] === undefined) return false;
    if (!('defaultZoom' in value) || value['defaultZoom'] === undefined) return false;
    return true;
}

export function DashboardDtoFromJSON(json: any): DashboardDto {
    return DashboardDtoFromJSONTyped(json, false);
}

export function DashboardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardDto {
    if (json == null) {
        return json;
    }
    return {
        
        'personalTabs': ((json['PersonalTabs'] as Array<any>).map(LensTabWithVisibilityDtoFromJSON)),
        'operatorTabs': ((json['OperatorTabs'] as Array<any>).map(LensTabWithVisibilityDtoFromJSON)),
        'defaultZoom': ZoomTypeFromJSON(json['DefaultZoom']),
    };
}

export function DashboardDtoToJSON(value?: DashboardDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'PersonalTabs': ((value['personalTabs'] as Array<any>).map(LensTabWithVisibilityDtoToJSON)),
        'OperatorTabs': ((value['operatorTabs'] as Array<any>).map(LensTabWithVisibilityDtoToJSON)),
        'DefaultZoom': ZoomTypeToJSON(value['defaultZoom']),
    };
}

