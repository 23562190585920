import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { StickSlipByDepthUserLensDto, StickSlipByDepthUserLensTrackItemDto } from "apis/oag";
import { StickSlipByDepthUserLensesApi } from "apis/oag";
import type { UserLensDto } from "apis/oag/models/UserLensDto";
import { useTrackAddModal } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackAddModal";
import { useAvailableTrackIds } from "components/Lenses/ContainerLens/StickSlipByDepth/Parts/hooks/useAvailableTrackIds";
import { UserLensesQueryKey } from "hooks/useUserLenses";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { RequestUID } from "utils/queryNamespaces";

const api = new StickSlipByDepthUserLensesApi(apiConfig);

export function useTrackAddStickSlip({
  lens,
  selectedTrackItems,
  onAdd,
}: {
  lens: UserLensDto;
  selectedTrackItems: StickSlipByDepthUserLensTrackItemDto[];
  onAdd: (items: StickSlipByDepthUserLensTrackItemDto[]) => void;
}) {
  const queryClient = useQueryClient();
  const availableTrackIds = useAvailableTrackIds(selectedTrackItems);

  const updateTracksMutation = useMutation({
    mutationFn: ({ trackItems }: { trackItems: StickSlipByDepthUserLensTrackItemDto[] }) => {
      const { id } = lens;

      return api.apiStickSlipByDepthUserLensesIdPut({
        id,
        stickSlipByDepthUserLensDto: {
          ...(lens as StickSlipByDepthUserLensDto),
          userLensTrackItems: trackItems,
        },
      });
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [UserLensesQueryKey] });
      await queryClient.cancelQueries({ queryKey: [{ uid: RequestUID.stickSlipByDepthFacts }], exact: false });
    },
    onSettled: async (data) => {
      const opts = {
        refetchActive: true,
        refetchInactive: true,
        exact: false,
      };
      if (data?.userLensTrackItems) onAdd(data?.userLensTrackItems);
      await queryClient.invalidateQueries({ queryKey: [UserLensesQueryKey] });
      await queryClient.invalidateQueries({
        queryKey: [{ uid: RequestUID.stickSlipByDepthFacts, lensId: lens.id }],
        ...opts,
      });
    },
  });

  const { node: TrackAddModalElement, open: openTrackAddModal } = useTrackAddModal({
    availableTrackIds,
    onAdd: (track) => {
      const newTrackItem: StickSlipByDepthUserLensTrackItemDto = {
        position: selectedTrackItems.length + 1,
        isManualYaxis: false,
        isUomUnlocked: true,
        trackId: track.id,
        userLensId: lens.id,
        id: 0,
        label: false,
        systemOfMeasurementType: "Metric",
      };
      const newTrackItems = [...selectedTrackItems, newTrackItem];
      Track.interact("Add Track", { "Track Name": track.name });
      onAdd(newTrackItems);
      updateTracksMutation.mutate({ trackItems: newTrackItems });
    },
  });

  return { TrackAddModalElement, openTrackAddModal };
}
