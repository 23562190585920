import { AxisBottom, AxisLeft, AxisRight, Orientation } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { LinePath } from "@visx/shape";
import { Text as VisXText } from "@visx/text";
import type { UseTooltipParams } from "@visx/tooltip/lib/hooks/useTooltip";
import ChartLegend from "components/Lenses/common/ChartLegend";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import { useChartDateTimeRange } from "components/Lenses/common/useChartDateTimeRange";
import { RotatedAxisLabel } from "components/Lenses/ContainerLens/common/RotatedAxisLabel";
import {
  CLIP_SERIES_ID,
  getAxisFontSize,
  LATERAL_AXIS_LEFT_MARGIN,
  LATERAL_AXIS_LEFT_WIDTH,
  LATERAL_AXIS_WIDTH,
  SECONDARY_AXIS_PADDING,
  X_AXIS_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import type { ScaleLinear } from "d3";
import { curveMonotoneX } from "d3";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import useDiscontinuousTimeAxis from "hooks/useDiscontinuousTimeAxis";
import { useLensNameByTemplateId } from "hooks/useLensNameByTemplateId";
import { useLensSize } from "hooks/useLensSize";
import { useMemo, useState } from "react";
import type { OnRefChangeType } from "react-resize-detector/build/types/types";
import { Track } from "services/Mixpanel";
import { DEFAULT_DATE_FORMAT } from "utils/format";
import { formatTime, getFuelFractionalDigits } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { StyledChartWrapper } from "./styles";
import { useLineChartTooltip } from "./useLineChartTooltip";
import type { AxisDetails, CurveDetails, SeriesPoint, TooltipPoint } from "./utils";
import { CurveType } from "./utils";

export function LineChart({
  xScale,
  yScale,
  containerRef,
  chartWidth,
  chartHeight,
  lensId,
  lensTemplateId,
  rightAxis,
  secondaryRightAxis,
  leftAxis,
  curves,
  activeLegendItems,
  setActiveLegendItems,
  tooltipRenderer,
}: {
  xScale: ScaleLinear<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  lensId: number;
  lensTemplateId: number;
  secondaryRightAxis?: AxisDetails;
  chartWidth: number;
  chartHeight: number;
  curves: CurveDetails[];
  rightAxis?: AxisDetails;
  leftAxis?: AxisDetails;
  containerRef: OnRefChangeType<HTMLDivElement> | React.MutableRefObject<HTMLDivElement>;
  activeLegendItems?: string[];
  setActiveLegendItems?: React.Dispatch<React.SetStateAction<string[]>>;
  tooltipRenderer: (params: UseTooltipParams<TooltipPoint>) => React.ReactNode;
}) {
  const horizontalAxisSpace = useMemo(
    () =>
      (rightAxis ? LATERAL_AXIS_WIDTH : 0) +
      (secondaryRightAxis ? LATERAL_AXIS_WIDTH : 0) +
      (leftAxis ? LATERAL_AXIS_WIDTH : 0),
    [rightAxis, secondaryRightAxis, leftAxis],
  );
  const plotWidth = getSVGNormalizedValue(chartWidth - horizontalAxisSpace);
  const plotHeight = getSVGNormalizedValue(chartHeight - X_AXIS_HEIGHT);
  const filteredCurves = useMemo(() => curves.filter((curve) => !curve.disabled), [curves]);
  const availableSeries = useMemo(
    () => filteredCurves.find((curve) => curve.data.length > 0)?.data || [],
    [filteredCurves],
  );

  const [isPointerInsideChart, setPointerInsideChart] = useState(false);

  const { xScaleDate, chunksCount, getAverageChunkDate } = useDiscontinuousTimeAxis({
    plotWidth,
    xScaleDomain: xScale.domain(),
    series: availableSeries,
  });

  const { handlePointerMove, tooltipElement } = useLineChartTooltip({
    containerRef,
    xScale,
    isPointerInsideChart,
    plotWidth,
    plotHeight,
    renderContent: tooltipRenderer,
    curves: filteredCurves,
    availableSeries,
    legendActiveItemsMap: (pointValues) => {
      const newPointValues = { ...pointValues };
      Object.keys(newPointValues).forEach((key) => {
        if (!activeLegendItems?.includes(key)) {
          delete newPointValues[key as CurveType];
        }
      });
      return newPointValues;
    },
  });

  const { selectionRectangleElement, isDragging } = useChartDateTimeRange<SeriesPoint>({
    xScale,
    yScale,
    plotWidth: plotWidth,
    plotWidthOffset: LATERAL_AXIS_WIDTH,
    plotHeight,
    series: availableSeries.map((e, i) => ({ ...e, index: i })),
  });

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const [localActiveLegendItems, setLocalActiveLegendItems] = useState<string[]>(
    activeLegendItems || curves.filter((curve) => curve.type !== CurveType.AvailablePower).map((curve) => curve.key),
  );

  const {
    verticalAxisTicksCount,
    yDisplayOnlyScale: rightAxisScale,
    formatTickDecimals,
  } = useYAxisDisplayScale({
    originalScale: rightAxis?.scale,
    uom: rightAxis?.uom,
    tickContainerHeight: plotHeight,
  });

  const { yDisplayOnlyScale: secondaryRightAxisScale } = useYAxisDisplayScale({
    originalScale: secondaryRightAxis?.scale,
    uom: secondaryRightAxis?.uom,
    tickContainerHeight: plotHeight,
  });

  const { yDisplayOnlyScale: leftAxisScale } = useYAxisDisplayScale({
    originalScale: leftAxis?.scale ?? rightAxis?.scale,
    uom: leftAxis?.uom ?? rightAxis?.uom,
    tickContainerHeight: plotHeight,
  });

  const { yDisplayOnlyScale: gridScale } = useYAxisDisplayScale({
    originalScale: yScale,
    uom: leftAxis?.uom ?? rightAxis?.uom,
  });

  const [width, height] = useLensSize(lensId);
  const axisFontSize = useMemo(() => getAxisFontSize(false), []);
  const CLIP_ID = `${CLIP_SERIES_ID}_${lensId}`;
  const lensName = useLensNameByTemplateId(lensTemplateId);
  return (
    <StyledChartWrapper ref={containerRef}>
      <svg
        width={chartWidth}
        height={chartHeight}
        style={{ overflow: "hidden", userSelect: "none" }}
        onPointerMove={handlePointerMove}
        onMouseLeave={() => setPointerInsideChart(false)}
        onMouseEnter={() => setPointerInsideChart(true)}
        onMouseMove={() => setPointerInsideChart(true)}
      >
        <Group>
          <GridRows
            scale={gridScale}
            width={chartWidth}
            height={chartHeight}
            stroke={themeColors.primary_chart_accent}
            strokeWidth={1}
            strokeOpacity={1}
            clipPath={`url(#${CLIP_ID})`}
          />
          <RectClipPath y={0} id={CLIP_ID} height={chartHeight} width={plotWidth} x={LATERAL_AXIS_WIDTH} />
          <Group clipPath={`url(#${CLIP_ID})`}>
            {filteredCurves
              .filter((curve) => localActiveLegendItems.includes(curve.key))
              .map((curve) => (
                <LinePath
                  key={curve.key}
                  curve={curve.curve || curveMonotoneX}
                  data={curve.data}
                  x={(d) => xScale(d.index) || 0}
                  y={(d) => curve.yScale(d.value) || 0}
                  stroke={curve.color}
                  strokeWidth={1}
                />
              ))}
          </Group>
          {rightAxis ? (
            <>
              <AxisRight
                hideZero
                scale={rightAxisScale}
                hideTicks
                numTicks={verticalAxisTicksCount}
                left={chartWidth - LATERAL_AXIS_WIDTH}
                tickComponent={(props) => (
                  <text
                    x={props.x}
                    y={props.y}
                    dy={props.dy}
                    fontSize={axisFontSize}
                    letterSpacing={-0.2}
                    textAnchor="right"
                    fill={themeColors.disabled_typography}
                    pointerEvents="none"
                  >
                    {props.formattedValue}
                  </text>
                )}
                tickFormat={(value) => (+value).toFixed(getFuelFractionalDigits(rightAxisScale.domain()[1]))}
                hideAxisLine
              />

              <RotatedAxisLabel
                orientation={Orientation.right}
                chartHeight={chartHeight}
                chartWidth={chartWidth}
                displayText={rightAxis.label}
              />
            </>
          ) : null}
          {secondaryRightAxis ? (
            <>
              <AxisRight
                hideZero
                scale={secondaryRightAxisScale}
                hideTicks
                numTicks={verticalAxisTicksCount}
                left={chartWidth - LATERAL_AXIS_WIDTH * 2}
                tickComponent={(props) => (
                  <text
                    x={props.x}
                    y={props.y}
                    dy={props.dy}
                    fontSize={axisFontSize}
                    letterSpacing={-0.2}
                    textAnchor="right"
                    fill={themeColors.disabled_typography}
                    pointerEvents="none"
                  >
                    {props.formattedValue}
                  </text>
                )}
                tickFormat={(value) => (+value).toFixed(getFuelFractionalDigits(secondaryRightAxisScale.domain()[1]))}
                hideAxisLine
              />

              <RotatedAxisLabel
                orientation={Orientation.right}
                chartHeight={chartHeight}
                chartWidth={chartWidth}
                rightPadding={LATERAL_AXIS_WIDTH + SECONDARY_AXIS_PADDING}
                displayText={secondaryRightAxis.label}
              />
            </>
          ) : null}
          {leftAxis ? (
            <>
              <AxisLeft
                hideZero
                scale={leftAxisScale}
                hideTicks
                numTicks={verticalAxisTicksCount}
                orientation="right"
                left={LATERAL_AXIS_WIDTH - LATERAL_AXIS_LEFT_MARGIN}
                tickFormat={(value) =>
                  formatTickDecimals(+value, leftAxis.useFuelFormatting ? getFuelFractionalDigits(+value) : 0)
                }
                tickComponent={(props) => {
                  return (
                    <text
                      x={props.x}
                      y={props.y}
                      dy={props.dy}
                      fontSize={axisFontSize}
                      letterSpacing={-0.2}
                      textAnchor="end"
                      fill={themeColors.disabled_typography}
                      pointerEvents="none"
                    >
                      {props.formattedValue}
                    </text>
                  );
                }}
                hideAxisLine
              />

              <RotatedAxisLabel orientation={Orientation.left} chartHeight={chartHeight} displayText={leftAxis.label} />
            </>
          ) : null}
          <AxisBottom
            hideAxisLine
            hideTicks
            tickStroke="white"
            scale={xScaleDate}
            top={plotHeight}
            left={LATERAL_AXIS_LEFT_WIDTH}
            numTicks={chunksCount}
            tickFormat={(value) => {
              const chunkDate = getAverageChunkDate(value);
              if (chunkDate) return formatTime(chunkDate, { formatStr: DEFAULT_DATE_FORMAT });
            }}
            tickComponent={(props) => {
              return (
                <Group>
                  <VisXText
                    textAnchor="middle"
                    x={props.x}
                    y={props.y}
                    fontSize={axisFontSize}
                    fill={themeColors.disabled_typography}
                    pointerEvents="none"
                  >
                    {props.formattedValue}
                  </VisXText>
                </Group>
              );
            }}
          />
        </Group>

        <Group>{!isDragging ? tooltipElement : null}</Group>
        {selectionRectangleElement}
      </svg>

      <ChartLegend
        legendItems={filteredCurves.map((curve, index) => ({
          id: index,
          name: curve.legendTitle,
          additionalInfo: { curveType: curve.type, key: curve.key },
          color: curve.color,
          isEnabled: localActiveLegendItems.includes(curve.key),
          previewerType: curve.type === CurveType.AvailablePower ? LegendPreviewerType.LINE : LegendPreviewerType.BOX,
          onClick: (item: LegendItem) => {
            Track.interact("Evergreen Dashboard - Update lens legend", {
              lens: lensName,
              lensId: lensId,
              selectedItem: item.name,
              isEnabled: !item.isEnabled,
            });

            if (localActiveLegendItems.length === 1 && localActiveLegendItems[0] === item.additionalInfo?.key) {
              // If this generator is the only one enabled, we prevent clicking
              return false;
            }
            const newLegends = localActiveLegendItems.includes(item.additionalInfo?.key as string)
              ? localActiveLegendItems.filter((itemId) => itemId !== item.additionalInfo?.key)
              : [...localActiveLegendItems, item.additionalInfo?.key as string];

            setLocalActiveLegendItems(newLegends);
            if (setActiveLegendItems) {
              setActiveLegendItems(newLegends);
            }
          },
        }))}
        isDetailed={false}
        lensGridSize={[width, height]}
      />
    </StyledChartWrapper>
  );
}
