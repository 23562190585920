import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  ApiStickSlipByTimeUserLensesIdFactsPutRequest,
  ApiStickSlipByTimeUserLensesIdZTorqueSummaryPutRequest,
  StickSlipByTimeSetDto,
  ZTorqueSummaryDto,
} from "apis/oag";
import { StickSlipByTimeUserLensesApi } from "apis/oag";
import { useFilterParams } from "hooks/useFilterParams";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { PARAMETER_LENS_SAMPLE_COUNT } from "utils/common";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

export const useStickSlipByTimeFacts = (
  lensId: number,
  { zoomStartDepth, zoomEndDepth }: { zoomStartDepth: number; zoomEndDepth: number },
  options?: UseQueryOptions<StickSlipByTimeSetDto>,
) => {
  const api = new StickSlipByTimeUserLensesApi(apiConfig);
  const defaultFilterParams = useFilterParams();
  const dispatch = useAppDispatch();

  const filterParams = useMemo(
    () =>
      zoomStartDepth || zoomEndDepth
        ? { ...defaultFilterParams, zoomStartDepth: zoomStartDepth, zoomEndDepth: zoomEndDepth }
        : defaultFilterParams,
    [defaultFilterParams, zoomEndDepth, zoomStartDepth],
  );
  const requestParameters: ApiStickSlipByTimeUserLensesIdFactsPutRequest = useMemo(
    () => ({
      id: lensId,
      baseFocalQueryDto: {
        ...filterParams,
        sampleCount: PARAMETER_LENS_SAMPLE_COUNT,
      },
    }),
    [filterParams, lensId],
  );

  const queryKey: PDLensFactsQueryKey<ApiStickSlipByTimeUserLensesIdFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.StickSlipByTime,
    lensId,
  };

  const wellId = filterParams.wellId;

  const requestReturn = useQuery({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api.apiStickSlipByTimeUserLensesIdFactsPut(requestParameters, { signal }).then((data) => {
        if (data?.lastUpdatedAt) {
          dispatch({
            type: "SET_TVD_REFRESH_DATE",
            payload: { [wellId]: data?.lastUpdatedAt },
          });
        }
        return data;
      }),
    placeholderData: (previousData) => previousData,
    ...options,
  });

  const requestString = JSON.stringify(requestParameters);
  useEffect(() => {
    requestReturn.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestString, zoomEndDepth, zoomStartDepth]);
  return requestReturn;
};

export const useStickSlipByTimeSummary = (
  lensId: number,
  { zoomStartDepth, zoomEndDepth }: { zoomStartDepth: number; zoomEndDepth: number },
  options?: UseQueryOptions<ZTorqueSummaryDto>,
) => {
  const api = new StickSlipByTimeUserLensesApi(apiConfig);
  const defaultFilterParams = useFilterParams();

  const filterParams = useMemo(
    () =>
      zoomStartDepth || zoomEndDepth
        ? { ...defaultFilterParams, zoomStartDepth: zoomStartDepth, zoomEndDepth: zoomEndDepth }
        : defaultFilterParams,
    [defaultFilterParams, zoomEndDepth, zoomStartDepth],
  );

  const requestParameters: ApiStickSlipByTimeUserLensesIdZTorqueSummaryPutRequest = useMemo(
    () => ({
      id: lensId,
      baseFocalQueryDto: filterParams,
    }),
    [filterParams, lensId],
  );

  const queryKey: PDLensFactsQueryKey<ApiStickSlipByTimeUserLensesIdZTorqueSummaryPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.StickSlipByTimeSummary,
    lensId,
  };

  const query = useQuery({
    queryKey: [queryKey],
    queryFn: ({ signal }) => api.apiStickSlipByTimeUserLensesIdZTorqueSummaryPut(requestParameters, { signal }),
    placeholderData: (previousData) => previousData,
    ...options,
  });

  // Eliminate the zoomEndDepth and zoomStartDepth from the query key of react query
  // so it does not create different query objects each time the zoom changes
  // thus having a finer control over what is cached and how we update it
  // this was the solution for bug #15100
  const requestString = JSON.stringify(requestParameters);
  useEffect(() => {
    query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestString, zoomEndDepth, zoomStartDepth]);
  return query;
};
