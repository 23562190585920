import { AxisBottom } from "@visx/axis";
import { scaleLinear } from "@visx/scale";
import { Bar } from "@visx/shape";
import type { StickSlipByTimeUserLensDto } from "apis/oag";
import { DimensionType, ResultDataState, TrackFactType } from "apis/oag";
import { Title } from "atoms/Typography";
import { ZoomChartContext } from "components/Lenses/common/LensZoom/ZoomChartContext";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import type { Domain } from "components/Lenses/ContainerLens/StickSlipByTime";
import Summary from "components/Lenses/ContainerLens/StickSlipByTime/components/Summary/Summary";
import {
  useStickSlipByTimeFacts,
  useStickSlipByTimeSummary,
} from "components/Lenses/ContainerLens/StickSlipByTime/fetcher";
import * as Styled from "components/Lenses/ContainerLens/StickSlipByTime/styles";
import {
  APPROX_TICK_LENGTH,
  BOTTOM_AXIS_HEIGHT,
  DETAILED_HEADER_HEIGHT,
  HEADER_HEIGHT,
  LEFT_COLUMN_WIDTH,
  LEFT_COLUMN_WIDTH_DETAILED,
  MIN_TRACK_HEIGHT,
  SUMMARY_HEIGHT,
  TICK_GAP,
  ZT_BAR_HEIGHT,
  ZT_CHART_HEIGHT,
  ZT_TOOLTIP_TOP_OFFSET,
} from "components/Lenses/ContainerLens/StickSlipByTime/utils/constants";
import { useStickSlipByTimeContext } from "components/Lenses/ContainerLens/StickSlipByTime/utils/useStickSlipByTimeTooltip";
import type { ZtrackInterval } from "components/Lenses/interfaces";
import { compressTrackValues, getSVGNormalizedValue } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import colors from "utils/colors";
import { APPROX_CHAR_WIDTH } from "utils/constants";
import { ALTERNATIVE_DATE_FORMAT, useUOM, UtilDimensions } from "utils/format";
import { formatTime, ratioToPercent } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

import { LineTrack } from "./LineTrack/LineTrack";
import { BottomAxisContainer, ButtonsContainer, ScrollContainer, TracksContainer } from "./style";

const FALLBACK_DOMAIN = [0, 0];

export const Chart = ({
  lens,
  showPercentageDifference,
  showZTorque,
  isDetailed,
  setInitialDomain,
  ControlButtons,
  currentDomain,
  onLensUpdated,
}: {
  showPercentageDifference: boolean;
  showZTorque: boolean;
  isDetailed: boolean;
  lens: StickSlipByTimeUserLensDto;
  currentDomain: Domain | null;
  setInitialDomain: React.Dispatch<React.SetStateAction<[number, number] | null>>;
  ControlButtons?: (() => JSX.Element) | null;
  onLensUpdated: (lens: StickSlipByTimeUserLensDto) => void;
}) => {
  const depthUom = useUOM(DimensionType.Metres);
  const torqueUom = useUOM(DimensionType.NewtonMeters);
  const rpmUom = useUOM(DimensionType.RevolutionsPerSecond);
  const diffPressUom = useUOM(DimensionType.Pascals);
  const wobUom = useUOM(DimensionType.Newtons);
  const ropUom = useUOM(DimensionType.MetresPerSecond);
  const noOpUom = useUOM(
    UtilDimensions.NoOp,
    (value) => `${value?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} SSSI`,
  );

  const zoomChartContext = useContext(ZoomChartContext);
  if (!zoomChartContext) {
    throw new Error("ZoomChartContext is not defined");
  }

  const { domain } = zoomChartContext;
  const [depthDomain, setDepthDomain] = useState(FALLBACK_DOMAIN);

  const { data: facts, isLoading } = useStickSlipByTimeFacts(lens.id, {
    zoomStartDepth: depthDomain[0],
    zoomEndDepth: depthDomain[1],
  });

  const { data: zTorqueSummaries } = useStickSlipByTimeSummary(lens.id, {
    zoomStartDepth: depthDomain[0],
    zoomEndDepth: depthDomain[1],
  });

  const wellTracks = useMemo(() => facts?.facts?.[0]?.tracks || [], [facts]);
  const initialTrackValues = wellTracks?.[0]?.trackValues || [];

  useEffect(() => {
    if (!currentDomain || currentDomain?.every((domainPoint) => domainPoint === 0)) {
      setDepthDomain(FALLBACK_DOMAIN);
      return;
    }
    setDepthDomain([
      initialTrackValues[Math.floor(currentDomain[0])]?.holeDepth ?? 0,
      initialTrackValues[Math.floor(currentDomain[1])]?.holeDepth ?? 0,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDomain]);

  const getTracksByType = useCallback(
    (type: TrackFactType) => {
      const track = wellTracks.find((track) => track.type === type);
      if (track?.trackValues) {
        return track.trackValues;
      }
      return [];
    },
    [wellTracks],
  );

  const zTorqueTrackings = compressTrackValues(getTracksByType(TrackFactType.ZTorqueStatus));
  const summaries = useMemo(() => {
    return [
      {
        title: "%Z-Torque On",
        on: ratioToPercent(zTorqueSummaries?.onOffRatio?.onRatio, 0),
        off: ratioToPercent(zTorqueSummaries?.onOffRatio?.offRatio, 0),
      },
      {
        title: "Trq Std Dev",
        unit: torqueUom.abbr,
        on:
          zTorqueSummaries?.averageTorqueStdDev?.on === 0
            ? "--"
            : torqueUom.display(zTorqueSummaries?.averageTorqueStdDev?.on),
        off:
          zTorqueSummaries?.averageTorqueStdDev?.off === 0
            ? "--"
            : torqueUom.display(zTorqueSummaries?.averageTorqueStdDev?.off),
        percentageDifference:
          zTorqueSummaries?.dataState === ResultDataState.NoData
            ? "--"
            : ratioToPercent(zTorqueSummaries?.averageTorqueStdDev?.diffPct, 0),
      },
      {
        title: "ROP",
        unit: ropUom.abbr,
        on:
          zTorqueSummaries?.averageRateOfPenetration?.on === 0
            ? "--"
            : ropUom.display(zTorqueSummaries?.averageRateOfPenetration?.on, { unit: "" }),
        off:
          zTorqueSummaries?.averageRateOfPenetration?.off === 0
            ? "--"
            : ropUom.display(zTorqueSummaries?.averageRateOfPenetration?.off, { unit: "" }),
        percentageDifference:
          zTorqueSummaries?.dataState === ResultDataState.NoData
            ? "--"
            : ratioToPercent(zTorqueSummaries?.averageRateOfPenetration?.diffPct, 0),
      },
      {
        title: "SSSI",
        on:
          zTorqueSummaries?.averageSssi?.on === 0
            ? "--"
            : zTorqueSummaries?.averageSssi?.on.toFixed(zTorqueSummaries?.averageSssi?.on > 1 ? 2 : 3),
        off:
          zTorqueSummaries?.averageSssi?.off === 0
            ? "--"
            : zTorqueSummaries?.averageSssi?.off.toFixed(zTorqueSummaries?.averageSssi?.off > 1 ? 2 : 3),
        percentageDifference:
          zTorqueSummaries?.dataState === ResultDataState.NoData
            ? "--"
            : ratioToPercent(zTorqueSummaries?.averageSssi?.diffPct, 0),
      },
    ];
  }, [ropUom, torqueUom, zTorqueSummaries]);

  const getIndexByTimestamp = useCallback(
    (timestamp: number) => {
      const trackPointIndex = getTracksByType(TrackFactType.ZTorqueStatus)?.findIndex(
        (trackValue) => trackValue.timestamp.utc.getTime() === timestamp,
      );
      return Number.isFinite(trackPointIndex) ? trackPointIndex : null;
    },
    [getTracksByType],
  );

  const { height: heightFromHook, width: widthFromHook, ref: containerRef } = useResizeDetector();
  const containerHeight = getSVGNormalizedValue(heightFromHook);
  const containerWidth = getSVGNormalizedValue(widthFromHook);
  const trackHeight =
    (containerHeight - BOTTOM_AXIS_HEIGHT) / 4 >= MIN_TRACK_HEIGHT
      ? (containerHeight - BOTTOM_AXIS_HEIGHT) / 4
      : MIN_TRACK_HEIGHT;
  const isScrollable = (containerHeight - BOTTOM_AXIS_HEIGHT) / 4 < MIN_TRACK_HEIGHT;
  const calculatedContainerHeight = isScrollable ? MIN_TRACK_HEIGHT * 4 + BOTTOM_AXIS_HEIGHT : undefined;
  const leftColumnWidth = isDetailed ? LEFT_COLUMN_WIDTH_DETAILED : LEFT_COLUMN_WIDTH;
  const chartWidth = getSVGNormalizedValue(containerWidth - leftColumnWidth);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const xScale = useMemo(() => {
    const getDomain = () => {
      if (domain && (domain[0] || domain[1])) return domain;
      return [0, initialTrackValues.length];
    };
    return scaleLinear<number>({
      domain: getDomain(),
      range: [0, chartWidth],
    });
  }, [chartWidth, domain, initialTrackValues.length]);

  useEffect(() => {
    setInitialDomain([0, initialTrackValues.length]);
  }, [initialTrackValues.length, setInitialDomain]);

  const zTorqueTrackingPoints = useMemo(() => {
    return zTorqueTrackings?.map((zTorqueTracking) => ({
      startX: xScale(getIndexByTimestamp(zTorqueTracking.startDate.utc.getTime()) || 0),
      endX: xScale(getIndexByTimestamp(zTorqueTracking.endDate.utc.getTime()) || 0),
    }));
  }, [zTorqueTrackings, xScale, getIndexByTimestamp]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent: ({ tooltipData }: { tooltipData?: ZtrackInterval }) => {
      return (
        <>
          <TooltipHighlightValue>
            ZTorque: On <PDComponent.SvgIcon name="zTorqueIndicatorOn" />
          </TooltipHighlightValue>
          {tooltipData?.startDate && tooltipData.endDate ? (
            <TooltipHighlightValue style={{ color: themeColors.disabled_typography, fontSize: 12 }}>
              {formatTime(tooltipData.startDate, { formatStr: ALTERNATIVE_DATE_FORMAT }) +
                " - " +
                formatTime(tooltipData.endDate, { formatStr: ALTERNATIVE_DATE_FORMAT })}
            </TooltipHighlightValue>
          ) : null}
        </>
      );
    },
  });

  const handleOnShowTooltip = useCallback(
    (interval: ZtrackInterval, startX: number, endX: number) => {
      showTooltip({
        tooltipLeft: leftColumnWidth + startX + (endX - startX) / 2,
        tooltipTop: ZT_TOOLTIP_TOP_OFFSET,
        tooltipData: interval,
      });
    },
    [leftColumnWidth, showTooltip],
  );

  const [overlayVisible, setOverlayVisible] = useState(false);

  const toggleOverLay = useCallback(() => {
    setOverlayVisible((prev) => !prev);
  }, [setOverlayVisible]);

  const lineTracks = useMemo(() => {
    return [
      {
        title: "Torque / RPM",
        tracks: [
          {
            uom: torqueUom,
            color: colors.charybdis,
            fontColor: colors.charybdis,
            fill: "url(#linear-gradient-torque)",
            trackPoints: getTracksByType(TrackFactType.Torque),
            key: TrackFactType.Torque,
            label: "Torque",
            lensSettingsTrackId: 4,
            zIndex: zIndexLayer.ground,
          },
          {
            uom: rpmUom,
            color: colors.perrywinkle,
            fontColor: colors.perrywinkle,
            fill: "url(#rpm-gradient)",
            trackPoints: getTracksByType(TrackFactType.RevolutionsPerSecond),
            key: TrackFactType.RevolutionsPerSecond,
            label: "RPM",
            lensSettingsTrackId: 1,
            zIndex: zIndexLayer.base,
          },
        ],
      },
      {
        title: "Diff Press / WOB",
        tracks: [
          {
            uom: diffPressUom,
            color: colors.complex_blue,
            fontColor: colors.complex_blue,
            fill: "url(#linear-gradient-diff-pressure)",
            trackPoints: getTracksByType(TrackFactType.DifferentialPressure),
            key: TrackFactType.DifferentialPressure,
            label: "Diff Press",
            lensSettingsTrackId: 6,
            zIndex: zIndexLayer.ground,
          },
          {
            uom: wobUom,
            color: colors.exciting_orange,
            fontColor: colors.exciting_orange,
            fill: "url(#linear-gradient-wob)",
            trackPoints: getTracksByType(TrackFactType.WeightOnBit),
            key: TrackFactType.WeightOnBit,
            label: "WOB",
            lensSettingsTrackId: 2,
            zIndex: zIndexLayer.base,
          },
        ],
      },
      {
        title: "ROP",
        tracks: [
          {
            uom: ropUom,
            color: colors.aloha,
            fontColor: colors.aloha,
            fill: "url(#linear-gradient-rop)",
            trackPoints: getTracksByType(TrackFactType.RateOfPenetration),
            key: TrackFactType.RateOfPenetration,
            label: "ROP",
            lensSettingsTrackId: 3,
            zIndex: zIndexLayer.ground,
          },
        ],
      },
      {
        title: "SSSI",
        tracks: [
          {
            uom: noOpUom,
            unit: "SSSI",
            color: "linear-gradient(180deg, #F4B5AB 0%, #D69ACF 31.5%, #947FE4 57.5%, #13A9CA 82.5%, #24D3B5 100%)",
            fontColor: colors.gray,
            fill: "url(#linear-gradient-stick-slip)",
            trackPoints: getTracksByType(TrackFactType.Sssi),
            label: "SSSI",
            key: TrackFactType.Sssi,
            lensSettingsTrackId: 10,
            zIndex: zIndexLayer.ground,
          },
        ],
      },
    ];
  }, [torqueUom, getTracksByType, rpmUom, diffPressUom, wobUom, ropUom, noOpUom]);

  const { setPointerInsideChart } = useStickSlipByTimeContext();
  const headerHeight =
    (isDetailed ? DETAILED_HEADER_HEIGHT : HEADER_HEIGHT) +
    (showPercentageDifference ? SUMMARY_HEIGHT : 0) +
    (showZTorque ? ZT_CHART_HEIGHT : 0);

  const isFallbackDomain = useMemo(
    () => xScale.domain()[0] === FALLBACK_DOMAIN[0] && xScale.domain()[1] === FALLBACK_DOMAIN[1],
    [xScale],
  );

  if (isLoading) return null;

  return (
    <>
      {showPercentageDifference ? <Summary summaries={summaries} isDetailed={isDetailed} /> : null}
      {showZTorque ? (
        <Styled.ZTContainer>
          <Styled.ZTitle $isDetailed={isDetailed}>
            <Title level={3}>
              <strong>ZT On</strong>
            </Title>
          </Styled.ZTitle>
          <Styled.ZTChart $leftColumnWidth={leftColumnWidth}>
            <svg width={chartWidth} height={ZT_BAR_HEIGHT} fill={themeColors.primary_accent}>
              <Bar width={chartWidth} height={ZT_BAR_HEIGHT} fill={themeColors.primary_accent} />
              {zTorqueTrackingPoints.map(({ startX, endX }, index) => {
                return Number.isFinite(startX) && Number.isFinite(endX) ? (
                  <Bar
                    key={`${startX}-${endX}-${index}`}
                    x={startX}
                    y={0}
                    width={endX - startX}
                    height={8}
                    fill={themeColors.primary_button_bg}
                    cursor="pointer"
                    onClick={toggleOverLay}
                    onMouseOver={() => handleOnShowTooltip(zTorqueTrackings[index], startX, endX)}
                    onMouseLeave={() => hideTooltip()}
                  />
                ) : null;
              })}
              {tooltipElement}
            </svg>
          </Styled.ZTChart>
        </Styled.ZTContainer>
      ) : null}
      <ScrollContainer
        ref={containerRef}
        $headerHeight={headerHeight}
        $isScrollable={isScrollable}
        onScroll={() => {
          setPointerInsideChart(false);
        }}
      >
        <TracksContainer
          $headerHeight={headerHeight + (isScrollable ? BOTTOM_AXIS_HEIGHT : 0)}
          $height={calculatedContainerHeight}
        >
          {lineTracks.map(({ title, tracks }) => (
            <LineTrack
              key={title}
              xScale={xScale}
              containerWidth={containerWidth}
              height={trackHeight}
              title={title}
              tracks={tracks}
              isDetailed={isDetailed}
              lens={lens}
              onLensUpdated={onLensUpdated}
              onMouseOverChartArea={() => setPointerInsideChart(true)}
              onMouseLeaveChartArea={() => setPointerInsideChart(false)}
            />
          ))}
          <BottomAxisContainer $isScrollable={isScrollable}>
            <svg width={containerWidth} height={BOTTOM_AXIS_HEIGHT}>
              <linearGradient id="linear-gradient-stick-slip" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor={`${colors.pink_mimosa}A0`}></stop>
                <stop offset="25%" stopColor={"#D69ACFA0"}></stop>
                <stop offset="75%" stopColor={"#947FE4A0"}></stop>
                <stop offset="95%" stopColor={`${colors.charybdis}A0`}></stop>
                <stop offset="100%" stopColor={"#24D3B5A0"}></stop>
              </linearGradient>

              <linearGradient id="linear-gradient-torque" gradientTransform="rotate(90)">
                <stop offset="30%" stopColor={`${colors.charybdis}50`}></stop>
                <stop offset="100%" stopColor={`${colors.charybdis}00`}></stop>
              </linearGradient>
              <linearGradient id="rpm-gradient" gradientTransform="rotate(90)">
                <stop offset="10%" stopColor={`${colors.perrywinkle}50`}></stop>
                <stop offset="100%" stopColor={`${colors.perrywinkle}00`}></stop>
              </linearGradient>
              <linearGradient id="linear-gradient-wob" gradientTransform="rotate(90)">
                <stop offset="10%" stopColor={`${colors.exciting_orange}50`}></stop>
                <stop offset="100%" stopColor={`${colors.exciting_orange}00`}></stop>
              </linearGradient>
              <linearGradient id="linear-gradient-diff-pressure" gradientTransform="rotate(90)">
                <stop offset="10%" stopColor={`${colors.complex_blue}50`}></stop>
                <stop offset="100%" stopColor={`${colors.complex_blue}00`}></stop>
              </linearGradient>
              <linearGradient id="linear-gradient-rop" gradientTransform="rotate(90)">
                <stop offset="50%" stopColor={`${colors.aloha}5A`}></stop>
                <stop offset="100%" stopColor={`${colors.aloha}00`}></stop>
              </linearGradient>
              <AxisBottom
                scale={xScale}
                hideTicks
                hideZero={isFallbackDomain}
                hideAxisLine
                tickLabelProps={(value) => {
                  const xPosition = xScale(value);
                  const tickWidth = APPROX_TICK_LENGTH * APPROX_CHAR_WIDTH;
                  return {
                    fill: xPosition + tickWidth / 2 >= xScale.range()[1] ? "transparent" : colors.gray,
                    fontSize: 16,
                    textAnchor: "middle",
                  };
                }}
                tickFormat={(value) =>
                  depthUom.display(initialTrackValues[+value]?.holeDepth || 0, { fractionDigits: 0, unit: "" })
                }
                left={leftColumnWidth}
                numTicks={containerWidth / (APPROX_CHAR_WIDTH * (APPROX_TICK_LENGTH + TICK_GAP))}
              />
            </svg>
          </BottomAxisContainer>
          <svg
            width={chartWidth}
            height={getSVGNormalizedValue(containerHeight - BOTTOM_AXIS_HEIGHT)}
            fill={themeColors.primary_accent}
            style={{ position: "absolute", top: headerHeight, pointerEvents: "none", left: leftColumnWidth }}
          >
            {zTorqueTrackingPoints.map(({ startX, endX }, index) => {
              return Number.isFinite(startX) && Number.isFinite(endX) && overlayVisible ? (
                <Bar
                  key={`${startX}-${endX}-${index}`}
                  x={startX}
                  y={0}
                  width={endX - startX}
                  height={getSVGNormalizedValue(containerHeight - BOTTOM_AXIS_HEIGHT) || 0}
                  fill={themeColors.primary_button_bg}
                  opacity={0.25}
                  onClick={toggleOverLay}
                  pointerEvents={"none"}
                />
              ) : null;
            })}
          </svg>
        </TracksContainer>

        {ControlButtons ? (
          <ButtonsContainer $headerHeight={headerHeight}>
            <ControlButtons />
          </ButtonsContainer>
        ) : null}
      </ScrollContainer>
    </>
  );
};
