import { DashboardType } from "apis/oag";
import { Title } from "atoms/Typography";
import { Section } from "components/Layout";
import { LensList } from "components/Lenses";
import { Loader } from "components/Loader";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useDashboardType } from "hooks/useDashboardType";
import { useLensTemplates } from "hooks/useLensTemplates";
import { useOperationTimes } from "hooks/useOperationTimes";
import { useUserLenses } from "hooks/useUserLenses";
import { LensTabs } from "pages/WellDashboard/LensTabs";
import { StyledCol, StyledOverlay } from "pages/WellDashboard/style";
import { useMatchTab } from "pages/WellDashboard/useMatchTab";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/store";
import styled from "styled-components";
import colors from "utils/colors";
import { Col, Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

const DRILLING_SECTION_COLOR = {
  drilling: colors.well_color,
  tripping: colors.tripping,
  casing: colors.casing,
  other: colors.off_state,
  onBottom: colors.well_color,
  offBottom: colors.off_state,
  rotating: colors.well_color,
  sliding: colors.off_state,
};

const DRILLING_SECTION_COLOR_DARK = {
  ...DRILLING_SECTION_COLOR,
  other: colors.debonair,
  offBottom: colors.debonair,
};

const RigBreakdown = styled.div<{ layout: string; noPadding?: string }>`
  padding: ${(params) => params.noPadding || "16px"};
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.secondary_accent};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: ${(props) => props.layout};
  gap: 2px;
  overflow: hidden;
`;

function capitalizeFirstLetter(string: string) {
  if (string[0]) return string[0].toUpperCase() + string.slice(1);
  return "";
}
export const HoverCol = styled(Col)`
  &:hover h3 {
    color: ${colors.well_color} !important;
  }
  border-bottom: 1px solid ${colors.separation_line};
  margin-bottom: 0px;
  width: 100%;
  padding: 12px;
  min-height: 0px;
  cursor: pointer;
`;

const RigBreakDownContainer = styled.div`
  height: 40px;
  width: 100%;
  display: grid;
  place-items: center;
`;

const RigBreakDownBar = styled.div<{ hovered: string | null; sectionKey: string; sectionColor: string }>`
  box-shadow: ${(props) => props.hovered === props.sectionKey && "inset 0 0 0 1px #fff"};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 24px;
  border: ${(props) => props.hovered === props.sectionKey && `1px solid ${props.sectionColor}`};
  background-color: ${(props) => props.sectionColor};
  margin: ${(props) => props.hovered !== props.sectionKey && "1px 0"};
`;

export const TooltipTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LensView = ({
  refr,
  sectionRef,
}: {
  refr?: React.RefObject<HTMLDivElement>;
  sectionRef?: React.RefObject<HTMLDivElement>;
}) => {
  const params = useParams<{ rigId: string }>();

  const rigId = useMemo(() => params.rigId ? parseInt(params.rigId) : 0, [params.rigId]);
  const location = useLocation();
  const { isDark } = useCustomTheme();
  const drillingColors = isDark ? DRILLING_SECTION_COLOR_DARK : DRILLING_SECTION_COLOR;
  const dispatch = useAppDispatch();
  const focalWellColor = useAppSelector((state) => state.state.focalColor); // TODO change to the new color selector
  const [offsetRigs] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_RIGS, []);

  const prevSelectedTab = useRef<number | null>(null);
  const { data: lenses } = useUserLenses();
  const { data: templates } = useLensTemplates();
  const [hovered, setHovered] = useState<string | null>(null);
  const { data: details, isLoading } = useOperationTimes();
  const [selectedTabId, setSelectedTabId] = useState<number | null>(null);
  const { dashboardType } = useDashboardType();
  const selectedTab = useMatchTab(dashboardType, selectedTabId);

  const navigate = useNavigate();
  // TODO Save data for every size, by checking remove [currentLayout]
  const { themeStyle } = useCustomTheme();

  const sections = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return Object.keys(drillingColors)
      .map((sectionKey) => {
        if (details?.[`${sectionKey.toLowerCase()}Split` as keyof typeof details])
          return {
            key: sectionKey,
            percent: `${(details[`${sectionKey.toLowerCase()}Split` as keyof typeof details] * 100).toFixed(2)}% `,
            width: `${details[`${sectionKey.toLowerCase()}Split` as keyof typeof details] * 100}% `,
            color: drillingColors[sectionKey as keyof typeof drillingColors],
          };
        return null;
      })
      .filter((e) => e);
  }, [details, isLoading, drillingColors]);

  const [isEditingTabs, setIsEditingTabs] = useState(false);
  useEffect(() => {
    if (!selectedTabId) return;
    if (!prevSelectedTab.current) prevSelectedTab.current = selectedTabId;
    if (prevSelectedTab.current !== selectedTabId) {
      prevSelectedTab.current = selectedTabId;
      dispatch({
        type: "RESET_AVAILABLE_FILTERS",
      });
    }
  }, [selectedTabId, dispatch]);

  return (
    <>
      {isEditingTabs ? <StyledOverlay /> : null}
      <StyledCol $isEditingTabs={isEditingTabs} ref={refr}>
        <Section
          style={{
            backgroundColor: themeStyle.colors.active_tab_bg,
            padding: 0,
            display: "flex",
            overflowX: "scroll",
          }}
        >
          <LensTabs
            selectedTabId={selectedTabId}
            setSelectedTabId={setSelectedTabId}
            dashboardType={DashboardType.Rig}
            isEditingTabs={isEditingTabs}
            setIsEditingTabs={setIsEditingTabs}
          />
        </Section>
      </StyledCol>
      <Col>
        {sections.length === 0 ? (
          <RigBreakdown noPadding="true" layout={"100%"} ref={sectionRef}>
            <RigBreakDownContainer>
              <RigBreakDownBar
                key={"Loading"}
                sectionColor={drillingColors.other}
                hovered={"false"}
                sectionKey={"Loading..."}
              >
                <Title level={3} variant="white">
                  {isLoading ? "Loading..." : "No Data"}
                </Title>
              </RigBreakDownBar>
            </RigBreakDownContainer>
          </RigBreakdown>
        ) : (
          <RigBreakdown noPadding="true" layout={sections.map((e) => e?.width).join(" ")} ref={sectionRef}>
            {sections.map((section) => (
              <Tooltip
                key={`${section?.key}${rigId} `}
                title={
                  <TooltipTitle>
                    <div>{capitalizeFirstLetter(section?.key || "")}</div>
                    <div>{section?.percent}</div>
                  </TooltipTitle>
                }
                arrowPointAtCenter
              >
                <RigBreakDownContainer>
                  <RigBreakDownBar
                    key={`${section?.key}${rigId} `}
                    onMouseOver={() => {
                      setHovered(section?.key || null);
                    }}
                    onMouseOut={() => setHovered(null)}
                    sectionColor={section?.color || ""}
                    sectionKey={section?.key || ""}
                    hovered={hovered}
                  >
                    {section?.percent && Number.parseFloat(section?.percent) > 10 ? (
                      <Title level={3} variant="white">
                        {`${capitalizeFirstLetter(section?.key)} ${section?.percent}`}
                      </Title>
                    ) : null}
                  </RigBreakDownBar>
                </RigBreakDownContainer>
              </Tooltip>
            ))}
          </RigBreakdown>
        )}

        <Col span={24}>
          <Suspense fallback={<Loader withWrapper />}>
            <LensList
              selectedTab={selectedTab}
              focalWellColor={focalWellColor}
              isComparing={offsetRigs?.length > 0}
              displayOption={null}
              lenses={lenses}
              templates={templates}
              trackingName="Lens Library"
              trackingProps={{
                "Internal Rig Id": location.pathname.replace("/rig/", ""),
                "Tab Id": selectedTab,
              }}
              addLens={() => navigate(`${location.pathname}/lens-library?selectedTab=${selectedTab?.id}`)}
            />
          </Suspense>
        </Col>
      </Col>
    </>
  );
};

export default LensView;
