/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProfileOriginType } from './ProfileOriginType';
import {
    ProfileOriginTypeFromJSON,
    ProfileOriginTypeFromJSONTyped,
    ProfileOriginTypeToJSON,
} from './ProfileOriginType';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';

/**
 * 
 * @export
 * @interface DashboardCopyDto
 */
export interface DashboardCopyDto {
    /**
     * 
     * @type {Array<DashboardType>}
     * @memberof DashboardCopyDto
     */
    types: Array<DashboardType>;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardCopyDto
     */
    excludeHiddenTabs: boolean;
    /**
     * 
     * @type {Array<ProfileOriginType>}
     * @memberof DashboardCopyDto
     */
    sourceOriginType: Array<ProfileOriginType>;
    /**
     * 
     * @type {ProfileOriginType}
     * @memberof DashboardCopyDto
     */
    targetOriginType: ProfileOriginType;
    /**
     * 
     * @type {number}
     * @memberof DashboardCopyDto
     */
    sourceProfileId: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCopyDto
     */
    targetProfileId: number;
}



/**
 * Check if a given object implements the DashboardCopyDto interface.
 */
export function instanceOfDashboardCopyDto(value: object): value is DashboardCopyDto {
    if (!('types' in value) || value['types'] === undefined) return false;
    if (!('excludeHiddenTabs' in value) || value['excludeHiddenTabs'] === undefined) return false;
    if (!('sourceOriginType' in value) || value['sourceOriginType'] === undefined) return false;
    if (!('targetOriginType' in value) || value['targetOriginType'] === undefined) return false;
    if (!('sourceProfileId' in value) || value['sourceProfileId'] === undefined) return false;
    if (!('targetProfileId' in value) || value['targetProfileId'] === undefined) return false;
    return true;
}

export function DashboardCopyDtoFromJSON(json: any): DashboardCopyDto {
    return DashboardCopyDtoFromJSONTyped(json, false);
}

export function DashboardCopyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardCopyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'types': ((json['Types'] as Array<any>).map(DashboardTypeFromJSON)),
        'excludeHiddenTabs': json['ExcludeHiddenTabs'],
        'sourceOriginType': ((json['SourceOriginType'] as Array<any>).map(ProfileOriginTypeFromJSON)),
        'targetOriginType': ProfileOriginTypeFromJSON(json['TargetOriginType']),
        'sourceProfileId': json['SourceProfileId'],
        'targetProfileId': json['TargetProfileId'],
    };
}

export function DashboardCopyDtoToJSON(value?: DashboardCopyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Types': ((value['types'] as Array<any>).map(DashboardTypeToJSON)),
        'ExcludeHiddenTabs': value['excludeHiddenTabs'],
        'SourceOriginType': ((value['sourceOriginType'] as Array<any>).map(ProfileOriginTypeToJSON)),
        'TargetOriginType': ProfileOriginTypeToJSON(value['targetOriginType']),
        'SourceProfileId': value['sourceProfileId'],
        'TargetProfileId': value['targetProfileId'],
    };
}

