import type { ITimelineInfo } from "pages/WellDashboard/types";

import { WellOverviewTvD } from "./products/WellOverviewTvD";

export enum ChartEnum {
  "SnowFlake",
  "Evergreen",
}

export const TvDChart = ({
  isReport,
  timelineOverride,
  setTimelineOverride,
}: {
  isReport: boolean;
  timelineOverride: ITimelineInfo | null;
  setTimelineOverride: React.Dispatch<ITimelineInfo | null> | null;
}) => (
  <WellOverviewTvD isReport={isReport} timelineOverride={timelineOverride} setTimelineOverride={setTimelineOverride} />
);
