import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { ActualTimelineDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDWellActualTimelineQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { useDashboardType } from "./useDashboardType";
import { useSelectedWell } from "./useSelectedWell";

const wells = new WellsApi(apiConfig);

export function useWellActualTimeline(options?: UseQueryOptions<ActualTimelineDto>) {
  const wellDataID = useSelectedWell();
  const WellActualTimelineQueryKey: PDWellActualTimelineQueryKey = {
    uid: RequestUID.wellActualTimeline,
    type: PDQueryType.WELL_ACTUAL_TIMELINE,
    wellDataID,
  };

  const { dashboardType } = useDashboardType();
  return useQuery<ActualTimelineDto>({
    queryKey: [WellActualTimelineQueryKey],
    queryFn: () =>
      wells
        .apiWellsIdActualTimelineTypeGet({
          id: wellDataID,
          type: dashboardType,
        })
        .catch(() => {
          // TODO discuss better solutions across the app to handle 404s
          return {
            sections: [],
            phases: [],
            events: [],
          };
        }),
    enabled: wellDataID !== null,
    ...options,
  });
}
