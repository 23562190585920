import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { WellDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDWellDetailsQueryKey, PDWellLegendDetailsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { useEffectExceptOnMount } from "./useEffectExceptOnMount";

const wells = new WellsApi(apiConfig);

export function useWellDetails(
  id: number,
  options?: Omit<UseQueryOptions<WellDto>, "queryKey" | "queryFn">,
  legend?: boolean,
) {
  const WellDetailsQueryKey: PDWellDetailsQueryKey | PDWellLegendDetailsQueryKey = {
    uid: legend ? RequestUID.wellLegendDetails : RequestUID.wellDetails,
    type: legend ? PDQueryType.WELL_LEGEND_DETAILS : PDQueryType.WELL_DETAILS,
    wellId: id,
  };
  const navigate = useNavigate();
  if (isNaN(id)) navigate("/auth/404");
  const dispatch = useAppDispatch();
  const lastWellRefreshDate = useAppSelector((state) => state.dataState.lastWellRefreshDate);
  const enabled = (options?.enabled ?? true) && id !== -1 && !!id;
  const qResult = useQuery<WellDto>({
    queryKey: [WellDetailsQueryKey],
    queryFn: () => {
      return wells
        .apiWellsIdDetailsGet({ id })
        .then((data) => {
          if (data?.drillingDetails?.lastWellFactUpdateAt) {
            dispatch({
              type: "SET_WELL_REFRESH_DATE",
              payload: { [id]: data?.drillingDetails?.lastWellFactUpdateAt },
            });
          }
          return data;
        })
        .catch((error) => {
          // TODO create a 400 and 403 dedicate pages
          //if (error.response?.status === 401) navigate("/auth/401");
          if (error.response?.status === 403) navigate("/auth/401");
          if (error.response?.status === 404) navigate("/auth/404");
          return new Promise((resolve, reject) => reject(error));
        });
    },
    ...options,
    refetchOnMount: false,
    enabled,
  });

  useEffectExceptOnMount(() => {
    if (enabled && (lastWellRefreshDate === null || lastWellRefreshDate[id] === null)) {
      qResult.refetch();
    }
  }, [lastWellRefreshDate]);
  return qResult;
}
