import styled from "styled-components";
import { Col } from "utils/componentLibrary";

export const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const PageContent = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  padding: 44px 33px;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  height: calc(100vh - 119px - 64px);
`;

export const Inner = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  height: 100%;
  overflow-y: auto;
  max-height: 100%;
  overflow: visible;
  width: 100%;
  border-radius: 5px;
`;

export const Toolbar = styled.div`
  height: 84px;
  padding: 16px 28px;
  h2,
  h3 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
  h2 {
    font-size: 20px;
    font-weight: 700;
  }
  h3 {
    font-size: 16px;
  }
`;

export const FilterCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TableContainer = styled.div`
  padding: 0 48px;
`;
