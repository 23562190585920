/* eslint-disable react/no-multi-comp */
import type { InputProps, InputRef } from "antd/lib/input/Input";
import { PDComponent } from "components/PDComponents";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Input as AntInput, Tooltip } from "utils/componentLibrary";

const StyledInput = styled(AntInput)<{ $hasError?: boolean }>`
  &,
  input {
    background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg} !important;
    color: ${(props) => (props.$hasError ? colors.error : props.theme.themeStyle.colors.primary_typography)};
    border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
  ${(props) =>
    props.disabled &&
    css`
      &,
      input {
        background-color: ${props.theme.themeStyle.colors.secondary_bg} !important;
        border-color: ${props.theme.themeStyle.colors.soft_accent} !important;
        color: ${props.theme.themeStyle.colors.disabled_typography} !important
        user-select: none;

        &:hover {
          border-color: rgb(224, 232, 234);
        }
      }
    `}

  &::placeholder,
  & .ant-input-suffix, & .ant-input-suffix .pd-icon {
    color: ${(props) => (props.$hasError ? colors.error : props.theme.themeStyle.colors.primary_scroll_bg)};
  }
  border-radius: 4px;
  height: 36px;
`;

export const Input = React.forwardRef<InputRef, InputProps & { error?: string }>((props, ref) => {
  const suffix = props.error ? (
    <div>
      {props.suffix}{" "}
      <Tooltip placement="bottomRight" title={props.error} arrowPointAtCenter>
        <PDComponent.SvgIcon name="warning" />
      </Tooltip>
    </div>
  ) : (
    props.suffix
  );

  return <StyledInput ref={ref} size="large" {...props} suffix={suffix} $hasError={!!props.error} />;
});

// Version of input that validates and changes value on blur
export const InputBlur = React.forwardRef<InputRef, React.ComponentProps<typeof Input>>((props, ref) => {
  const [value, setValue] = useState(props.value || props.defaultValue || "");

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  return (
    <Input
      ref={ref}
      {...props}
      value={value}
      onChange={(e) => setValue(e.currentTarget.value)}
      onBlur={props.onChange}
    />
  );
});
