import colors from "utils/colors";

export const LegendItem = ({ isAlphaConnections }: { isAlphaConnections: boolean }) => {
    return (
      <svg
        height="5"
        width="10"
        style={{
          alignSelf: "center",
        }}
      >
        <g fill="none" stroke={isAlphaConnections ? colors.turtle_green : colors.off_state} strokeWidth="2">
          <path strokeWidth={4} d="M0 0 l215 0" />
        </g>
      </svg>
    );
  };