import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { TagBottomFingerprintUserLensDto } from "apis/oag";
import { DimensionType, TagBottomFingerprintUserLensesApi } from "apis/oag";
import { CustomSwitch } from "atoms/common";
import { Button, Input, Select } from "atoms/Form";
import { Title } from "atoms/Typography";
import { Text } from "atoms/Typography";
import type { ContainerLensSettingsModalProps } from "components/Lenses/ContainerLens/common/utils/LensSettings";
import { PDComponent } from "components/PDComponents";
import { round } from "lodash";
import { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { Col, Row } from "utils/componentLibrary";
import type { UOMHelper } from "utils/format";
import { useUOM } from "utils/format";
import { RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

export const selectRecent = [10, 15, 25, 50, null];
const getTwoDigitValue = (value: string) => {
  if (!value) return "";

  return round(+value, 2).toString();
};
const getInitialValue = (value: number | null | undefined, uom: UOMHelper) => {
  if (value === null || value === undefined) {
    return "";
  }
  return uom.fromSI(value).toString();
};
const StyledBottomComponent = styled.div`
  display: flex;
  width: 100%;
  padding: 14px 24px;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 0px;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  span {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;
const StyledContent = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  h3 {
    margin-bottom: 8px;
    padding: 0px 24px;
  }
  hr {
    margin: 16px 0px;
    width: 100%;
    background: ${({ theme }) => theme.themeStyle.colors.delimiter};
    opacity: 0.3;
  }
  ${Select} {
    margin: 0px 24px;
    width: calc(100% - 48px);
  }
  ${StyledBottomComponent} {
    margin-top: 24px;
  }
`;
enum EDisplayOptions {
  stand,
  overall,
}

const displayOptions = {
  [EDisplayOptions.stand]: "Tag Bottom Fingerprint by Stand",
  [EDisplayOptions.overall]: "Tag Bottom Fingerprint Overall",
};

const customStyles = {
  overlay: {
    zIndex: zIndexLayer.phobos,
    backgroundColor: colors.infinity_transparent,
  },
  content: {
    top: "20vh",
    left: "50%",
    overflow: "hidden",
    right: "auto",
    width: "444px",
    padding: "0px",
    borderRadius: 6,
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, 0)",
    border: "none",
  },
};

interface TagBottomFingerprintModalProps extends ContainerLensSettingsModalProps {
  lens: TagBottomFingerprintUserLensDto;
}
export const TagBottomFingerprintModal = ({
  lens,
  onLensUpdated,
  setIsVisible,
  isVisible,
}: TagBottomFingerprintModalProps) => {
  const tagBottomFingerPrintApi = new TagBottomFingerprintUserLensesApi(apiConfig);
  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);
  const [localDisplayOptions, setLocalDisplayOptions] = useState({
    displayOptionState: lens.showOverallView ? EDisplayOptions.overall : EDisplayOptions.stand,
    standCount: lens.standCount,
    showAlphaComparison: lens.showOverallView ? false : lens.showAlphaComparison,
    isBithDepthNormalized: lens.isBithDepthNormalized ?? false,
    normalizationReferenceBitDepth: lens.normalizationReferenceBitDepth ?? null,
  });
  const uom = useUOM(DimensionType.Metres);
  const [normalizationDepth, setNormalizationDepth] = useState(
    getInitialValue(lens.normalizationReferenceBitDepth, uom),
  );

  useEffect(() => {
    setNormalizationDepth(getInitialValue(lens.normalizationReferenceBitDepth, uom));
    return () => {
      setNormalizationDepth("");
    };
  }, [lens.normalizationReferenceBitDepth, uom]);

  const { themeStyle, atomThemeVariant } = useCustomTheme();
  const queryClient = useQueryClient();
  const handleDisplayOptionsUpdate = useMutation({
    mutationFn: ({
      displayOptionState,
      ...rest
    }: {
      displayOptionState: EDisplayOptions;
      standCount?: number | null;
      showAlphaComparison: boolean;
      normalizationReferenceBitDepth?: number | null;
      isBithDepthNormalized?: boolean;
    }) => {
      return tagBottomFingerPrintApi.apiTagBottomFingerprintUserLensesIdPut({
        id: lens.id,
        tagBottomFingerprintUserLensDto: {
          ...lens,
          ...rest,
          showOverallView: displayOptionState === EDisplayOptions.overall,
        },
      });
    },
    onSuccess(newLens) {
      onLensUpdated?.(newLens);
      queryClient.invalidateQueries({
        queryKey: [{ uid: RequestUID.tagBottomFingerprintFacts, lensId: lens.id }],
        exact: false,
      });
    },
  });

  return (
    <Modal
      isOpen={isVisible}
      shouldCloseOnOverlayClick
      onRequestClose={() => {
        setIsVisible(false);
      }}
      style={{
        content: { ...customStyles.content, backgroundColor: themeStyle.colors.secondary_bg },
        overlay: customStyles.overlay,
      }}
    >
      <StyledHeader>
        <Title
          variant={atomThemeVariant}
          level={2}
          weight={500}
          style={{
            paddingBottom: 21,
          }}
        >
          Lens Settings
        </Title>
        <span onClick={handleClose}>
          <PDComponent.SvgIcon name="close" />
        </span>
      </StyledHeader>
      <StyledContent>
        <Title level={3} variant={atomThemeVariant} weight={500}>
          Display Options
        </Title>
        <Select
          value={displayOptions[localDisplayOptions.displayOptionState]}
          onChange={(e) => {
            Track.interact("Lens - TagBottomFingerPrint - Display data options", {
              DisplayOption: e,
            });

            setLocalDisplayOptions((state) => ({
              ...state,
              displayOptionState:
                e === displayOptions[EDisplayOptions.overall] ? EDisplayOptions.overall : EDisplayOptions.stand,
              showAlphaComparison: e !== displayOptions[EDisplayOptions.overall] ? state.showAlphaComparison : false,
            }));
          }}
          placeholder="Select display option"
          $fullWidth
        >
          {[EDisplayOptions.stand, EDisplayOptions.overall].map((optionKey) => {
            return (
              <Select.Option key={+optionKey} value={displayOptions[optionKey]}>
                {displayOptions[optionKey]}
              </Select.Option>
            );
          })}
        </Select>
        {localDisplayOptions.displayOptionState !== EDisplayOptions.overall ? (
          <Row
            justify="space-between"
            style={{ width: "100%", alignItems: "center", margin: "16px 0px", paddingRight: 24, marginTop: 24 }}
          >
            <Col
              style={{
                display: "grid",
                placeItems: "center",
                gridTemplateColumns: "auto 1fr",
                marginLeft: 24,
              }}
            >
              <img src="/assets/icons/alpha_symbol.png" alt="alpha" height={19} width={19} />
              <Title
                level={3}
                variant={atomThemeVariant}
                weight={500}
                style={{
                  margin: 4,
                  padding: 0,
                }}
              >
                Alpha / Non-Alpha Comparison
              </Title>
            </Col>

            <Row gutter={[10, 0]}>
              <Col
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Text primary="description" variant="faded">
                  {localDisplayOptions.showAlphaComparison ? "ON" : "OFF"}
                </Text>
              </Col>
              <Col>
                <CustomSwitch
                  $isChecked={localDisplayOptions.showAlphaComparison}
                  checked={localDisplayOptions.showAlphaComparison}
                  onChange={(checked: boolean) =>
                    setLocalDisplayOptions((prev) => ({ ...prev, showAlphaComparison: checked }))
                  }
                />
              </Col>
            </Row>
          </Row>
        ) : null}
        <hr />
        <Row
          justify="space-between"
          style={{ width: "100%", alignItems: "center", margin: "16px 0px", paddingRight: 24, marginTop: 24 }}
        >
          <Col
            style={{
              display: "grid",
              placeItems: "center",
              gridTemplateColumns: "auto 1fr",
              marginLeft: 24,
            }}
          >
            <Title
              level={3}
              variant={atomThemeVariant}
              weight={500}
              style={{
                margin: 4,
                padding: 0,
              }}
            >
              Normalize Block Height
            </Title>
          </Col>

          <Row gutter={[10, 0]}>
            <Col
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Text primary="description" variant="faded">
                {localDisplayOptions.isBithDepthNormalized ? "ON" : "OFF"}
              </Text>
            </Col>
            <Col>
              <CustomSwitch
                $isChecked={localDisplayOptions.isBithDepthNormalized}
                checked={localDisplayOptions.isBithDepthNormalized}
                onChange={(checked: boolean) =>
                  setLocalDisplayOptions((prev) => ({ ...prev, isBithDepthNormalized: checked }))
                }
              />
            </Col>
          </Row>
        </Row>
        <Row
          justify="space-between"
          style={{
            width: "100%",
            alignItems: "center",
            margin: "24px 0px",
            padding: "0 24px",
            marginTop: 24,
            paddingLeft: 28,
          }}
        >
          <Col
            flex={2}
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Text
              primary="description"
              variant={!localDisplayOptions.isBithDepthNormalized ? "faded" : atomThemeVariant}
            >
              Starting Height at:
            </Text>
          </Col>
          <Col flex={1}>
            <Input
              suffix={uom.currentUOM === IUnitSystem.METRIC ? "m" : "ft"}
              value={!localDisplayOptions.isBithDepthNormalized ? "" : getTwoDigitValue(normalizationDepth)}
              disabled={!localDisplayOptions.isBithDepthNormalized}
              inputMode="numeric"
              onBlur={() => {
                if (normalizationDepth !== "")
                  setLocalDisplayOptions((prev) => ({
                    ...prev,
                    normalizationReferenceBitDepth: uom.toSI(Number.parseFloat(normalizationDepth)),
                  }));
              }}
              onChange={(e) => {
                if (!e.target.value) {
                  setNormalizationDepth("");
                  return;
                }
                if (Number.isNaN(Number.parseFloat(e.target.value))) return;
                setNormalizationDepth(e.target.value);
              }}
              type="number"
            />
          </Col>
        </Row>
        <Title level={3} variant={atomThemeVariant} weight={500}>
          Range
        </Title>
        <Select
          value={!localDisplayOptions.standCount ? "All" : `Most Recent ${localDisplayOptions.standCount}`}
          onChange={(e) => {
            setLocalDisplayOptions((state) => ({
              ...state,
              // eslint-disable-next-line @typescript-eslint/no-base-to-string
              standCount: e === "All" ? null : ((e ?? "").toString().match(/\d+/g) || []).map(Number)[0],
            }));
          }}
          placeholder="Select Wells"
          $fullWidth
        >
          {selectRecent.map((optionKey) => {
            return (
              <Select.Option key={optionKey} value={!optionKey ? "All" : `Most Recent ${optionKey}`}>
                {!optionKey ? "All" : `Most Recent ${optionKey}`}
              </Select.Option>
            );
          })}
        </Select>

        <StyledBottomComponent>
          <Button
            type="primary"
            size="large"
            loading={handleDisplayOptionsUpdate.isPending}
            disabled={!!localDisplayOptions.isBithDepthNormalized && !normalizationDepth}
            onClick={() => {
              handleDisplayOptionsUpdate.mutateAsync(localDisplayOptions).then((data) => {
                Track.interact("Lens - TagBottomFingerPrint - Display Wells Count", {
                  ...data,
                });
                setNormalizationDepth("");
                setLocalDisplayOptions({
                  displayOptionState: data.showOverallView ? EDisplayOptions.overall : EDisplayOptions.stand,
                  standCount: data.standCount,
                  showAlphaComparison: data.showOverallView ? false : data.showAlphaComparison,
                  normalizationReferenceBitDepth: data.normalizationReferenceBitDepth ?? null,
                  isBithDepthNormalized: localDisplayOptions.isBithDepthNormalized,
                });
                handleClose();
              });
            }}
          >
            Save Changes
          </Button>
        </StyledBottomComponent>
      </StyledContent>
    </Modal>
  );
};
