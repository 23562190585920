import { useQueryClient } from "@tanstack/react-query";
import { DashboardType, DisplayOption } from "apis/oag";
import { Section } from "components/Layout";
import { LensList } from "components/Lenses";
import { Loader } from "components/Loader";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useLensTemplates } from "hooks/useLensTemplates";
import { useUserLenses } from "hooks/useUserLenses";
import { useMatchTab } from "pages/WellDashboard/useMatchTab";
import { startTransition, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Col } from "utils/componentLibrary";
import { dashboardTypeToUrl } from "utils/helper";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import { LensTabs } from "./LensTabs";
import { StyledCol, StyledOverlay } from "./style";

export function LensView({
  refr,
  sectionRef,
  dashboardType = DashboardType.Well,
}: Readonly<{
  refr: React.MutableRefObject<HTMLDivElement | null>;
  sectionRef: React.MutableRefObject<HTMLDivElement | null>;
  isBess?: boolean;
  dashboardType?: DashboardType;
}>) {
  const [selectedTabId, setSelectedTabId] = useState<number | null>(null);
  const prevSelectedTab = useRef<number | null>(null);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const focalWellColor = useAppSelector((state) => state.state.focalColor);
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELL, []);
  const isComparing = comparisonWells.length > 0;
  const focalWellId = useAppSelector((state) => state.state.selectedWell);
  const displayOption = useAppSelector((state) => state.widgetOptions.display_options);

  const selectedTab = useMatchTab(dashboardType, selectedTabId);
  const { data: lenses, isLoading: isLoadingLenses } = useUserLenses();
  const { data: templates, isLoading: isLoadingTemplates } = useLensTemplates();

  const navigate = useNavigate();

  useEffect(() => {
    if (comparisonWells.length > 0 && displayOption !== DisplayOption.Well) {
      dispatch({
        type: "SET_DISPLAY_OPTIONS_WIDGET",
        payload: {
          display_options: DisplayOption.Well,
        },
      });
    } else if (comparisonWells.length === 0 && displayOption !== DisplayOption.StandDepth) {
      dispatch({
        type: "SET_DISPLAY_OPTIONS_WIDGET",
        payload: {
          display_options: DisplayOption.StandDepth,
        },
      });
    }
  }, [comparisonWells, dispatch, displayOption]);

  useEffect(() => {
    if (!selectedTabId) return;
    if (!prevSelectedTab.current) prevSelectedTab.current = selectedTabId;
    if (prevSelectedTab.current !== selectedTabId) {
      prevSelectedTab.current = selectedTabId;
      dispatch({
        type: "RESET_AVAILABLE_FILTERS",
      });
    }
  }, [selectedTabId, dispatch]);

  const [isEditingTabs, setIsEditingTabs] = useState(false);
  const { themeStyle } = useCustomTheme();

  const handleOnSelectTabId = useCallback(
    (tabId: number | null) => {
      if (selectedTabId === tabId) return;

      if (selectedTabId) {
        queryClient.cancelQueries({ queryKey: [{ type: PDQueryType.FACTS }] });
      }

      startTransition(() => {
        setSelectedTabId(tabId);
      });
    },
    [queryClient, selectedTabId],
  );

  return (
    <>
      {isEditingTabs ? <StyledOverlay /> : null}
      <StyledCol span={24} ref={refr} $isEditingTabs={isEditingTabs}>
        <Section
          style={{
            backgroundColor: themeStyle.colors.active_tab_bg,
            padding: 0,
            display: "flex",
            overflowX: "scroll",
          }}
        >
          <LensTabs
            selectedTabId={selectedTabId}
            setSelectedTabId={handleOnSelectTabId}
            dashboardType={dashboardType}
            isEditingTabs={isEditingTabs}
            setIsEditingTabs={setIsEditingTabs}
          />
        </Section>
      </StyledCol>
      <Col ref={sectionRef} span={24}>
        {!isLoadingLenses && !isLoadingTemplates ? (
          <Suspense fallback={<Loader withWrapper />}>
            <LensList
              selectedTab={selectedTab}
              focalWellColor={focalWellColor}
              isComparing={isComparing}
              displayOption={displayOption}
              lenses={lenses}
              templates={templates}
              trackingName="Lens Library"
              trackingProps={{
                "Internal Well Id": focalWellId,
                "Tab Id": selectedTabId,
              }}
              addLens={() =>
                navigate(
                  `/${dashboardTypeToUrl(dashboardType)}/${focalWellId}/lens-library?selectedTab=${selectedTab?.id}`,
                )
              }
            />
          </Suspense>
        ) : (
          <Loader withWrapper />
        )}
      </Col>
    </>
  );
}
