import { useDashboardType } from "hooks/useDashboardType";
import { useParams } from "react-router-dom";
import { useAppSelector } from "reducers/store";

export const useSelectedWell = (options?: { isReport?: boolean }) => {
  const { dashboardId, wellId } = useParams<{
    dashboardId: string;
    wellId: string;
  }>();

  const { isRig: isRigDashboard } = useDashboardType();

  const reduxWellId = useAppSelector(({ state, report }) =>
    location.pathname.includes("export") || options?.isReport ? report.selectedWell : state.selectedWell,
  );

  if (wellId) return Number.parseInt(wellId);
  if (dashboardId && !isRigDashboard) return Number.parseInt(dashboardId);
  if (reduxWellId) return reduxWellId;
  if (isRigDashboard) return -1;

  throw new Error("Could not find a well Id");
};
