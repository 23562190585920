/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanDrillingParameterDto
 */
export interface PlanDrillingParameterDto {
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    measuredDepth: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    wobMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    wobMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    revolutionPerSecondMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    revolutionPerSecondMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    ropMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    ropMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    diffPressureMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    diffPressureMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    torqueMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    torqueMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    flowInMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDto
     */
    flowInMax?: number | null;
}

/**
 * Check if a given object implements the PlanDrillingParameterDto interface.
 */
export function instanceOfPlanDrillingParameterDto(value: object): value is PlanDrillingParameterDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('measuredDepth' in value) || value['measuredDepth'] === undefined) return false;
    return true;
}

export function PlanDrillingParameterDtoFromJSON(json: any): PlanDrillingParameterDto {
    return PlanDrillingParameterDtoFromJSONTyped(json, false);
}

export function PlanDrillingParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDrillingParameterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'measuredDepth': json['MeasuredDepth'],
        'wobMin': json['WobMin'] == null ? undefined : json['WobMin'],
        'wobMax': json['WobMax'] == null ? undefined : json['WobMax'],
        'revolutionPerSecondMin': json['RevolutionPerSecondMin'] == null ? undefined : json['RevolutionPerSecondMin'],
        'revolutionPerSecondMax': json['RevolutionPerSecondMax'] == null ? undefined : json['RevolutionPerSecondMax'],
        'ropMin': json['RopMin'] == null ? undefined : json['RopMin'],
        'ropMax': json['RopMax'] == null ? undefined : json['RopMax'],
        'diffPressureMin': json['DiffPressureMin'] == null ? undefined : json['DiffPressureMin'],
        'diffPressureMax': json['DiffPressureMax'] == null ? undefined : json['DiffPressureMax'],
        'torqueMin': json['TorqueMin'] == null ? undefined : json['TorqueMin'],
        'torqueMax': json['TorqueMax'] == null ? undefined : json['TorqueMax'],
        'flowInMin': json['FlowInMin'] == null ? undefined : json['FlowInMin'],
        'flowInMax': json['FlowInMax'] == null ? undefined : json['FlowInMax'],
    };
}

export function PlanDrillingParameterDtoToJSON(value?: PlanDrillingParameterDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'MeasuredDepth': value['measuredDepth'],
        'WobMin': value['wobMin'],
        'WobMax': value['wobMax'],
        'RevolutionPerSecondMin': value['revolutionPerSecondMin'],
        'RevolutionPerSecondMax': value['revolutionPerSecondMax'],
        'RopMin': value['ropMin'],
        'RopMax': value['ropMax'],
        'DiffPressureMin': value['diffPressureMin'],
        'DiffPressureMax': value['diffPressureMax'],
        'TorqueMin': value['torqueMin'],
        'TorqueMax': value['torqueMax'],
        'FlowInMin': value['flowInMin'],
        'FlowInMax': value['flowInMax'],
    };
}

