import type { LensTabWithVisibilityDto } from "apis/oag";
import { useShareRecipients } from "hooks/useShareRecipients";
import { useCallback, useMemo } from "react";

export const useFilterTabsByText = (tabs: LensTabWithVisibilityDto[], filterByText: string) => {
  const { isLoading: isLoadingRecipients, data: shareRecipients } = useShareRecipients();

  const getRecipientName = useCallback(
    (id: number) => {
      return isLoadingRecipients ? null : shareRecipients?.find((recipient) => recipient.userId === id)?.displayName;
    },
    [isLoadingRecipients, shareRecipients],
  );

  const visibleTabs = useMemo(() => {
    return tabs.filter(
      (tab) =>
        tab?.name?.toLowerCase().includes(filterByText.toLowerCase()) ||
        (tab?.shortName ?? "").toLowerCase().includes(filterByText.toLowerCase()) ||
        (tab?.rigIds ?? []).toString().toLowerCase().includes(filterByText.toLowerCase()) ||
        (tab?.rigIds ?? []).toString().toLowerCase().includes(filterByText.toLowerCase()) ||
        (tab?.sharedById ? getRecipientName(tab?.sharedById) || "" : "")
          .toString()
          .toLowerCase()
          .includes(filterByText.toLowerCase()),
    );
  }, [filterByText, getRecipientName, tabs]);

  return visibleTabs;
};
