/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DimensionType } from './DimensionType';
import {
    DimensionTypeFromJSON,
    DimensionTypeFromJSONTyped,
    DimensionTypeToJSON,
} from './DimensionType';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface StatCardInfoDto
 */
export interface StatCardInfoDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof StatCardInfoDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof StatCardInfoDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StatCardInfoDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StatCardInfoDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof StatCardInfoDto
     */
    value?: number | null;
    /**
     * 
     * @type {DimensionType}
     * @memberof StatCardInfoDto
     */
    dimension: DimensionType;
    /**
     * 
     * @type {ResultDataState}
     * @memberof StatCardInfoDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the StatCardInfoDto interface.
 */
export function instanceOfStatCardInfoDto(value: object): value is StatCardInfoDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('dimension' in value) || value['dimension'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function StatCardInfoDtoFromJSON(json: any): StatCardInfoDto {
    return StatCardInfoDtoFromJSONTyped(json, false);
}

export function StatCardInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatCardInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'value': json['Value'] == null ? undefined : json['Value'],
        'dimension': DimensionTypeFromJSON(json['Dimension']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function StatCardInfoDtoToJSON(value?: StatCardInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'Value': value['value'],
        'Dimension': DimensionTypeToJSON(value['dimension']),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

