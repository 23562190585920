/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParameterHeatmapTrackUserLensDto } from './ParameterHeatmapTrackUserLensDto';
import {
    ParameterHeatmapTrackUserLensDtoFromJSON,
    ParameterHeatmapTrackUserLensDtoFromJSONTyped,
    ParameterHeatmapTrackUserLensDtoToJSON,
} from './ParameterHeatmapTrackUserLensDto';

/**
 * 
 * @export
 * @interface ParameterHeatmapUserLensDto
 */
export interface ParameterHeatmapUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    xTrackId: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    yTrackId: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    zTrackId: number;
    /**
     * 
     * @type {ParameterHeatmapTrackUserLensDto}
     * @memberof ParameterHeatmapUserLensDto
     */
    xTrack: ParameterHeatmapTrackUserLensDto;
    /**
     * 
     * @type {ParameterHeatmapTrackUserLensDto}
     * @memberof ParameterHeatmapUserLensDto
     */
    yTrack: ParameterHeatmapTrackUserLensDto;
    /**
     * 
     * @type {ParameterHeatmapTrackUserLensDto}
     * @memberof ParameterHeatmapUserLensDto
     */
    zTrack: ParameterHeatmapTrackUserLensDto;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    showActiveBin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    showFocalRecommendation: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    focalRecommendationDepthRange: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    showOffsetRecommendation: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    offsetRecommendationDepthRange: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterHeatmapUserLensDto
     */
    showOffsetData: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapUserLensDto
     */
    binDistanceThreshold: number;
}

/**
 * Check if a given object implements the ParameterHeatmapUserLensDto interface.
 */
export function instanceOfParameterHeatmapUserLensDto(value: object): value is ParameterHeatmapUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('xTrackId' in value) || value['xTrackId'] === undefined) return false;
    if (!('yTrackId' in value) || value['yTrackId'] === undefined) return false;
    if (!('zTrackId' in value) || value['zTrackId'] === undefined) return false;
    if (!('xTrack' in value) || value['xTrack'] === undefined) return false;
    if (!('yTrack' in value) || value['yTrack'] === undefined) return false;
    if (!('zTrack' in value) || value['zTrack'] === undefined) return false;
    if (!('showActiveBin' in value) || value['showActiveBin'] === undefined) return false;
    if (!('showFocalRecommendation' in value) || value['showFocalRecommendation'] === undefined) return false;
    if (!('focalRecommendationDepthRange' in value) || value['focalRecommendationDepthRange'] === undefined) return false;
    if (!('showOffsetRecommendation' in value) || value['showOffsetRecommendation'] === undefined) return false;
    if (!('offsetRecommendationDepthRange' in value) || value['offsetRecommendationDepthRange'] === undefined) return false;
    if (!('showOffsetData' in value) || value['showOffsetData'] === undefined) return false;
    if (!('binDistanceThreshold' in value) || value['binDistanceThreshold'] === undefined) return false;
    return true;
}

export function ParameterHeatmapUserLensDtoFromJSON(json: any): ParameterHeatmapUserLensDto {
    return ParameterHeatmapUserLensDtoFromJSONTyped(json, false);
}

export function ParameterHeatmapUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHeatmapUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'xTrackId': json['XTrackId'],
        'yTrackId': json['YTrackId'],
        'zTrackId': json['ZTrackId'],
        'xTrack': ParameterHeatmapTrackUserLensDtoFromJSON(json['XTrack']),
        'yTrack': ParameterHeatmapTrackUserLensDtoFromJSON(json['YTrack']),
        'zTrack': ParameterHeatmapTrackUserLensDtoFromJSON(json['ZTrack']),
        'showActiveBin': json['ShowActiveBin'],
        'showFocalRecommendation': json['ShowFocalRecommendation'],
        'focalRecommendationDepthRange': json['FocalRecommendationDepthRange'],
        'showOffsetRecommendation': json['ShowOffsetRecommendation'],
        'offsetRecommendationDepthRange': json['OffsetRecommendationDepthRange'],
        'showOffsetData': json['ShowOffsetData'],
        'binDistanceThreshold': json['BinDistanceThreshold'],
    };
}

export function ParameterHeatmapUserLensDtoToJSON(value?: ParameterHeatmapUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'XTrackId': value['xTrackId'],
        'YTrackId': value['yTrackId'],
        'ZTrackId': value['zTrackId'],
        'XTrack': ParameterHeatmapTrackUserLensDtoToJSON(value['xTrack']),
        'YTrack': ParameterHeatmapTrackUserLensDtoToJSON(value['yTrack']),
        'ZTrack': ParameterHeatmapTrackUserLensDtoToJSON(value['zTrack']),
        'ShowActiveBin': value['showActiveBin'],
        'ShowFocalRecommendation': value['showFocalRecommendation'],
        'FocalRecommendationDepthRange': value['focalRecommendationDepthRange'],
        'ShowOffsetRecommendation': value['showOffsetRecommendation'],
        'OffsetRecommendationDepthRange': value['offsetRecommendationDepthRange'],
        'ShowOffsetData': value['showOffsetData'],
        'BinDistanceThreshold': value['binDistanceThreshold'],
    };
}

