import { Title } from "atoms/Typography";
import styled from "styled-components";
import colors from "utils/colors";
import { zIndexLayer } from "utils/zIndex";

export const detailedModalStyles = {
  overlay: {
    zIndex: zIndexLayer.jupiter,
    backgroundColor: colors.faded_bg,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    borderRadius: 12,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: "auto",
    overflow: "hidden",
    border: "none",
  },
};

export const ChartWrapper = styled.div`
  padding: 4px;
  width: 100%;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
`;

export const SummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const LensTitle = styled(Title).attrs({ level: 4, variant: "faded" })`
  padding-top: 8px;
  text-align: center;
`;

export const ChartSummaryItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const ChartSummaryValue = styled.div<{ $color: string }>`
  color: ${({ $color }) => $color};
`;

export const ChartSvg = styled.svg`
  color: ${colors.white};
  overflow: visible;
`;

export const IconWrapperTasks = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => (theme.isDark ? colors.whisky : colors.hugo)};
  color: ${colors.black};
`;
