/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WellStatusType } from './WellStatusType';
import {
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface WellShortInfo
 */
export interface WellShortInfo {
    /**
     * 
     * @type {number}
     * @memberof WellShortInfo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WellShortInfo
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WellShortInfo
     */
    operatorId: number;
    /**
     * 
     * @type {WellStatusType}
     * @memberof WellShortInfo
     */
    status: WellStatusType;
    /**
     * 
     * @type {string}
     * @memberof WellShortInfo
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof WellShortInfo
     */
    padExternalId?: string | null;
    /**
     * 
     * @type {DateDto}
     * @memberof WellShortInfo
     */
    spudDateTime: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellShortInfo
     */
    lastWellFactUpdateAt: DateDto;
    /**
     * 
     * @type {boolean}
     * @memberof WellShortInfo
     */
    isEverGreen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WellShortInfo
     */
    isBess: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof WellShortInfo
     */
    rigIds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof WellShortInfo
     */
    jobExternalIds: Array<number>;
}



/**
 * Check if a given object implements the WellShortInfo interface.
 */
export function instanceOfWellShortInfo(value: object): value is WellShortInfo {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('operatorId' in value) || value['operatorId'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('externalId' in value) || value['externalId'] === undefined) return false;
    if (!('spudDateTime' in value) || value['spudDateTime'] === undefined) return false;
    if (!('lastWellFactUpdateAt' in value) || value['lastWellFactUpdateAt'] === undefined) return false;
    if (!('isEverGreen' in value) || value['isEverGreen'] === undefined) return false;
    if (!('isBess' in value) || value['isBess'] === undefined) return false;
    if (!('rigIds' in value) || value['rigIds'] === undefined) return false;
    if (!('jobExternalIds' in value) || value['jobExternalIds'] === undefined) return false;
    return true;
}

export function WellShortInfoFromJSON(json: any): WellShortInfo {
    return WellShortInfoFromJSONTyped(json, false);
}

export function WellShortInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellShortInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'operatorId': json['OperatorId'],
        'status': WellStatusTypeFromJSON(json['Status']),
        'externalId': json['ExternalId'],
        'padExternalId': json['PadExternalId'] == null ? undefined : json['PadExternalId'],
        'spudDateTime': DateDtoFromJSON(json['SpudDateTime']),
        'lastWellFactUpdateAt': DateDtoFromJSON(json['LastWellFactUpdateAt']),
        'isEverGreen': json['IsEverGreen'],
        'isBess': json['IsBess'],
        'rigIds': json['RigIds'],
        'jobExternalIds': json['JobExternalIds'],
    };
}

export function WellShortInfoToJSON(value?: WellShortInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'OperatorId': value['operatorId'],
        'Status': WellStatusTypeToJSON(value['status']),
        'ExternalId': value['externalId'],
        'PadExternalId': value['padExternalId'],
        'SpudDateTime': DateDtoToJSON(value['spudDateTime']),
        'LastWellFactUpdateAt': DateDtoToJSON(value['lastWellFactUpdateAt']),
        'IsEverGreen': value['isEverGreen'],
        'IsBess': value['isBess'],
        'RigIds': value['rigIds'],
        'JobExternalIds': value['jobExternalIds'],
    };
}

