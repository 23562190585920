/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SingleStandKpiDetailByDateShiftDto } from './SingleStandKpiDetailByDateShiftDto';
import {
    SingleStandKpiDetailByDateShiftDtoFromJSON,
    SingleStandKpiDetailByDateShiftDtoFromJSONTyped,
    SingleStandKpiDetailByDateShiftDtoToJSON,
} from './SingleStandKpiDetailByDateShiftDto';
import type { Int32Int64FactIndicatorSetDto } from './Int32Int64FactIndicatorSetDto';
import {
    Int32Int64FactIndicatorSetDtoFromJSON,
    Int32Int64FactIndicatorSetDtoFromJSONTyped,
    Int32Int64FactIndicatorSetDtoToJSON,
} from './Int32Int64FactIndicatorSetDto';
import type { SingleStandKpiDetailByDateDto } from './SingleStandKpiDetailByDateDto';
import {
    SingleStandKpiDetailByDateDtoFromJSON,
    SingleStandKpiDetailByDateDtoFromJSONTyped,
    SingleStandKpiDetailByDateDtoToJSON,
} from './SingleStandKpiDetailByDateDto';
import type { SingleStandKpiDetailByShiftDto } from './SingleStandKpiDetailByShiftDto';
import {
    SingleStandKpiDetailByShiftDtoFromJSON,
    SingleStandKpiDetailByShiftDtoFromJSONTyped,
    SingleStandKpiDetailByShiftDtoToJSON,
} from './SingleStandKpiDetailByShiftDto';
import type { SingleStandKpiSummaryDto } from './SingleStandKpiSummaryDto';
import {
    SingleStandKpiSummaryDtoFromJSON,
    SingleStandKpiSummaryDtoFromJSONTyped,
    SingleStandKpiSummaryDtoToJSON,
} from './SingleStandKpiSummaryDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { DrillingTaskSummary } from './DrillingTaskSummary';
import {
    DrillingTaskSummaryFromJSON,
    DrillingTaskSummaryFromJSONTyped,
    DrillingTaskSummaryToJSON,
} from './DrillingTaskSummary';
import type { StandKpiDetailsDto } from './StandKpiDetailsDto';
import {
    StandKpiDetailsDtoFromJSON,
    StandKpiDetailsDtoFromJSONTyped,
    StandKpiDetailsDtoToJSON,
} from './StandKpiDetailsDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface SingleStandKpiDto
 */
export interface SingleStandKpiDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof SingleStandKpiDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof SingleStandKpiDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof SingleStandKpiDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {FiltersDto}
     * @memberof SingleStandKpiDto
     */
    availableFilters: FiltersDto;
    /**
     * 
     * @type {SingleStandKpiSummaryDto}
     * @memberof SingleStandKpiDto
     */
    summary: SingleStandKpiSummaryDto;
    /**
     * 
     * @type {DrillingTaskSummary}
     * @memberof SingleStandKpiDto
     */
    drillingTaskSummary: DrillingTaskSummary;
    /**
     * 
     * @type {Array<StandKpiDetailsDto>}
     * @memberof SingleStandKpiDto
     */
    detailsByStandDepth?: Array<StandKpiDetailsDto> | null;
    /**
     * 
     * @type {Array<SingleStandKpiDetailByDateDto>}
     * @memberof SingleStandKpiDto
     */
    detailsByDate?: Array<SingleStandKpiDetailByDateDto> | null;
    /**
     * 
     * @type {Array<SingleStandKpiDetailByDateShiftDto>}
     * @memberof SingleStandKpiDto
     */
    detailsByDateShift?: Array<SingleStandKpiDetailByDateShiftDto> | null;
    /**
     * 
     * @type {SingleStandKpiDetailByShiftDto}
     * @memberof SingleStandKpiDto
     */
    detailsByShift: SingleStandKpiDetailByShiftDto;
    /**
     * 
     * @type {Int32Int64FactIndicatorSetDto}
     * @memberof SingleStandKpiDto
     */
    indicators: Int32Int64FactIndicatorSetDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof SingleStandKpiDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the SingleStandKpiDto interface.
 */
export function instanceOfSingleStandKpiDto(value: object): value is SingleStandKpiDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('availableFilters' in value) || value['availableFilters'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('drillingTaskSummary' in value) || value['drillingTaskSummary'] === undefined) return false;
    if (!('detailsByShift' in value) || value['detailsByShift'] === undefined) return false;
    if (!('indicators' in value) || value['indicators'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function SingleStandKpiDtoFromJSON(json: any): SingleStandKpiDto {
    return SingleStandKpiDtoFromJSONTyped(json, false);
}

export function SingleStandKpiDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleStandKpiDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'availableFilters': FiltersDtoFromJSON(json['AvailableFilters']),
        'summary': SingleStandKpiSummaryDtoFromJSON(json['Summary']),
        'drillingTaskSummary': DrillingTaskSummaryFromJSON(json['DrillingTaskSummary']),
        'detailsByStandDepth': json['DetailsByStandDepth'] == null ? undefined : ((json['DetailsByStandDepth'] as Array<any>).map(StandKpiDetailsDtoFromJSON)),
        'detailsByDate': json['DetailsByDate'] == null ? undefined : ((json['DetailsByDate'] as Array<any>).map(SingleStandKpiDetailByDateDtoFromJSON)),
        'detailsByDateShift': json['DetailsByDateShift'] == null ? undefined : ((json['DetailsByDateShift'] as Array<any>).map(SingleStandKpiDetailByDateShiftDtoFromJSON)),
        'detailsByShift': SingleStandKpiDetailByShiftDtoFromJSON(json['DetailsByShift']),
        'indicators': Int32Int64FactIndicatorSetDtoFromJSON(json['Indicators']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function SingleStandKpiDtoToJSON(value?: SingleStandKpiDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'AvailableFilters': FiltersDtoToJSON(value['availableFilters']),
        'Summary': SingleStandKpiSummaryDtoToJSON(value['summary']),
        'DrillingTaskSummary': DrillingTaskSummaryToJSON(value['drillingTaskSummary']),
        'DetailsByStandDepth': value['detailsByStandDepth'] == null ? undefined : ((value['detailsByStandDepth'] as Array<any>).map(StandKpiDetailsDtoToJSON)),
        'DetailsByDate': value['detailsByDate'] == null ? undefined : ((value['detailsByDate'] as Array<any>).map(SingleStandKpiDetailByDateDtoToJSON)),
        'DetailsByDateShift': value['detailsByDateShift'] == null ? undefined : ((value['detailsByDateShift'] as Array<any>).map(SingleStandKpiDetailByDateShiftDtoToJSON)),
        'DetailsByShift': SingleStandKpiDetailByShiftDtoToJSON(value['detailsByShift']),
        'Indicators': Int32Int64FactIndicatorSetDtoToJSON(value['indicators']),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

