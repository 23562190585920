import { useMsal } from "@azure/msal-react";
import { SystemOfMeasurementType, UserRoleType, UsersApi } from "apis/oag";
import { NotificationsButton } from "components/Header/RightContent/Notifications/NotificationsButton/NotificationsButton";
import { TasksButton } from "components/Header/RightContent/Tasks/TasksButton";
import { PDComponent } from "components/PDComponents";
import { useProfiles } from "hooks/admin/useProfile";
import { ProfileSelectorList } from "pages/Admin/Users/Editor/DashboardLayout/components/UserSelector";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { IThemeState } from "reducers/themeReducer";
import { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";

import { MenuItems } from "./MenuItem";
import {
  BoxShadowSurface,
  StyledButton,
  StyledButtonProfile,
  StyledDropDown,
  StyledHelpButton,
  StyledLink,
  StyledSpace,
} from "./style";

const USERSNAP_GLOBAL_ID = import.meta.env.VITE_REACT_APP_USERSNAP_GLOBAL_ID as string;

const users = new UsersApi(apiConfig);

enum SelectorThemes {
  LIGHT = "Light",
  DARK = "Dark",
}

export const transformToLocalUOM = (unit: SystemOfMeasurementType) =>
  unit === SystemOfMeasurementType.Imperial ? IUnitSystem.IMPERIAL : IUnitSystem.METRIC;

const RightContent = () => {
  const dispatch = useAppDispatch();

  const { instance } = useMsal();
  const [menuVisible, setMenuVisible] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);

  const handleLogout = () => {
    Track.event("Log Out");
    instance.logoutRedirect();
  };
  const { data: profiles } = useProfiles();

  const userInfo = useAppSelector((state) => state.global.userInfo);
  const [profile, setProfile] = useState(
    !profiles ? null : profiles.find((profile) => profile.profileId === userInfo?.activeProfileId),
  );
  useEffect(() => {
    if (!profiles || !userInfo?.activeProfileId) return;
    setProfile(profiles.find((profile) => profile.profileId === userInfo.activeProfileId));
  }, [profiles, userInfo?.activeProfileId]);

  useEffect(() => {
    setProfileVisible(false);
  }, [profile]);
  const currentTheme = useAppSelector((state) => state.theme);
  const currentSelectorTheme = useMemo(
    () => (currentTheme === IThemeState.DARK ? SelectorThemes.DARK : SelectorThemes.LIGHT),
    [currentTheme],
  );

  const localUOM = userInfo?.systemOfMeasurement ?? SystemOfMeasurementType.Metric;
  const setUom = useCallback(
    (unit: SystemOfMeasurementType) => {
      Track.interact("Change Unit Of Measurement", {
        "Selected Unit Of Measurement": unit,
      });
      dispatch({
        type: "SET_UNIT_OF_MEASURE",
        payload: transformToLocalUOM(unit),
      });
      // We don't actually care about of the UoM was saved
      users.apiUsersMeSystemOfMeasurementPut({
        body: unit,
      });
    },
    [dispatch],
  );

  const setTheme = useCallback(
    (themeValue: "Light" | "Dark") => {
      const themeType = themeValue === "Light" ? IThemeState.LIGHT : IThemeState.DARK;
      Track.interact("Change Theme", {
        "Selected Theme": themeType,
      });
      dispatch({
        type: "SET_THEME",
        payload: { theme: themeType },
      });
      localStorage.setItem("theme", themeType);
      users.apiUsersMeThemePut({
        body: themeValue,
      });
    },
    [dispatch],
  );

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
            window.onUsersnapCXLoad = function(api) {
              api.init({
                user: {
                  userId: "${userInfo?.firstName} ${userInfo?.lastName}",
                  email: "${userInfo?.userName}",
                },
              });
              window.Usersnap = api;
            }
            var script = document.createElement('script');
            script.defer = 1;
            script.src = 'https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_ID}?onload=onUsersnapCXLoad';
            document.getElementsByTagName('head')[0].appendChild(script);
          `}
        </script>
      </Helmet>
      <StyledSpace wrap size="small">
        {userInfo?.role === UserRoleType.Administrator && [
          <StyledLink to="/admin/accounts" key="accounts">
            <PDComponent.SvgIcon name="inventoryManagement" /> Account
          </StyledLink>,
          <StyledLink to="/admin/users" key="users">
            <PDComponent.SvgIcon name="events" /> Users
          </StyledLink>,
        ]}

        <StyledHelpButton
          ghost
          onClick={() => {
            Track.clickEvent("Raise issue");
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any)?.Usersnap?.logEvent?.("feedback");
          }}
        >
          <PDComponent.SvgIcon name="help" /> Help
        </StyledHelpButton>

        {userInfo?.role === UserRoleType.Administrator || userInfo?.isApprover ? <TasksButton /> : null}

        <NotificationsButton />

        {userInfo?.role === UserRoleType.Administrator ? (
          <>
            <StyledDropDown
              overlay={
                <BoxShadowSurface>
                  <ProfileSelectorList
                    selectedProfile={profile ?? null}
                    profileList={profiles || []}
                    otherProfileId={-1}
                    setSelectedProfile={(profile) => {
                      if (profile.profileId === userInfo?.activeProfileId) return;
                      setProfile(profile);
                      users
                        .apiUsersMeProfileProfileIdPut({
                          profileId: profile.profileId,
                        })
                        .then(() => {
                          location.reload();
                        });
                    }}
                  />
                </BoxShadowSurface>
              }
              placement="bottomLeft"
              arrow={false}
              open={profileVisible}
              onOpenChange={() => {
                setProfileVisible(!profileVisible);
              }}
              overlayStyle={{ marginTop: "-12px" }}
              trigger={["click"]}
            >
              <StyledButtonProfile ghost>
                <PDComponent.SvgIcon name="user" className="profile" />
                {!profile
                  ? "Profile"
                  : profiles?.find((profile) => profile.profileId === userInfo?.activeProfileId)?.displayName}
              </StyledButtonProfile>
            </StyledDropDown>
            <StyledDropDown
              overlay={
                <MenuItems
                  setMenuVisible={setMenuVisible}
                  handleLogout={handleLogout}
                  setUom={setUom}
                  localUOM={localUOM}
                  setTheme={setTheme}
                  currentSelectorTheme={currentSelectorTheme}
                  userInfo={userInfo}
                />
              }
              placement="bottomLeft"
              arrow={false}
              overlayStyle={{ marginTop: "-12px" }}
              trigger={["click"]}
              open={menuVisible}
              onOpenChange={() => {
                setMenuVisible(!menuVisible);
              }}
            >
              <StyledButton
                ghost
                style={{
                  marginLeft: 0,
                }}
              >
                {userInfo?.firstName} {userInfo?.lastName}
                <PDComponent.SvgIcon name="chevronDown" />
              </StyledButton>
            </StyledDropDown>
          </>
        ) : (
          <StyledDropDown
            overlay={
              <MenuItems
                setMenuVisible={setMenuVisible}
                handleLogout={handleLogout}
                setUom={setUom}
                localUOM={localUOM}
                setTheme={setTheme}
                currentSelectorTheme={currentSelectorTheme}
                userInfo={userInfo}
              />
            }
            placement="bottomLeft"
            arrow={false}
            overlayStyle={{ marginTop: "-12px" }}
            trigger={["click"]}
            open={menuVisible}
            onOpenChange={() => {
              setMenuVisible(!menuVisible);
            }}
          >
            <StyledButton
              ghost
              style={{
                marginLeft: 0,
              }}
            >
              <PDComponent.SvgIcon name="user" className="profile" />
              {userInfo?.firstName} {userInfo?.lastName}
              <PDComponent.SvgIcon name="chevronDown" />
            </StyledButton>
          </StyledDropDown>
        )}
      </StyledSpace>
    </>
  );
};

export default RightContent;
