/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WellStatusType } from './WellStatusType';
import {
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';
import type { ClaimPermissionType } from './ClaimPermissionType';
import {
    ClaimPermissionTypeFromJSON,
    ClaimPermissionTypeFromJSONTyped,
    ClaimPermissionTypeToJSON,
} from './ClaimPermissionType';

/**
 * 
 * @export
 * @interface ClaimWellInfo
 */
export interface ClaimWellInfo {
    /**
     * 
     * @type {number}
     * @memberof ClaimWellInfo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimWellInfo
     */
    name?: string | null;
    /**
     * 
     * @type {WellStatusType}
     * @memberof ClaimWellInfo
     */
    status: WellStatusType;
    /**
     * 
     * @type {ClaimPermissionType}
     * @memberof ClaimWellInfo
     */
    permission: ClaimPermissionType;
}



/**
 * Check if a given object implements the ClaimWellInfo interface.
 */
export function instanceOfClaimWellInfo(value: object): value is ClaimWellInfo {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('permission' in value) || value['permission'] === undefined) return false;
    return true;
}

export function ClaimWellInfoFromJSON(json: any): ClaimWellInfo {
    return ClaimWellInfoFromJSONTyped(json, false);
}

export function ClaimWellInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimWellInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'status': WellStatusTypeFromJSON(json['Status']),
        'permission': ClaimPermissionTypeFromJSON(json['Permission']),
    };
}

export function ClaimWellInfoToJSON(value?: ClaimWellInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'Status': WellStatusTypeToJSON(value['status']),
        'Permission': ClaimPermissionTypeToJSON(value['permission']),
    };
}

