import type { WellDto } from "apis/oag";
import { OperatorStatusType, UserStatusType } from "apis/oag";
import { Button, Input } from "atoms/Form";
import { Title } from "atoms/Typography";
import { AccountsTable } from "components/CustomTable";
import { ActionsHeader, OverviewHeaderContainer, PlanSectionLayout } from "components/Layout/Tabbed";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import { useAdminAccountList } from "hooks/admin/useAdminAccountList";
import { useRigs } from "hooks/useRigs";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "reducers/store";
import { useAppDispatch } from "reducers/store";
import { useMixpanel } from "services/Mixpanel";
import { Col, Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

const AccountsListPage = () => {
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();
  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("Accounts");
    }
  }, [viewPage]);

  const dispatch = useAppDispatch();
  const rigs = useRigs();
  const wells = useWellShortInfoSuspended();

  const operatorsAccounts = useAdminAccountList();

  const searchState = useAppSelector((state) => state.admin.accounts.search);
  const operatorStatus = useAppSelector((state) => state.admin.accounts.operatorStatus);
  const userStatus = useAppSelector((state) => state.admin.accounts.userStatus);
  const rigIdsState = useAppSelector((state) => state.admin.accounts.rigs);
  const filterWells = useAppSelector((state) => state.admin.accounts.wells);

  const [quickSearchValue, setQuickSearchValue] = useState<string | null>(searchState);

  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [showRolePopup, setShowRolePopup] = useState(false);
  const [showRigPopup, setShowRigPopup] = useState(false);
  const [showWellSelect, setShowWellSelect] = useState(false);

  const isLoading = useMemo(() => {
    return rigs.isLoading || wells.isLoading || operatorsAccounts.isLoading;
  }, [rigs.isLoading, wells.isLoading, operatorsAccounts.isLoading]);

  const updateSearch = () => {
    dispatch({
      type: "ADMIN_ACCOUNTS_SET_SEARCH",
      payload: {
        search: quickSearchValue,
      },
    });
  };

  // Reset search on clear
  useEffect(() => {
    if (!quickSearchValue) {
      dispatch({
        type: "ADMIN_ACCOUNTS_SET_SEARCH",
        payload: {
          search: null,
        },
      });
    }
  }, [dispatch, quickSearchValue]);

  const updateAccountStatuses = (f: OperatorStatusType[]) => {
    setShowStatusPopup(false);
    dispatch({
      type: "ADMIN_ACCOUNTS_SET_STATUSES",
      payload: {
        statuses: f,
      },
    });
  };

  const updateUserRoles = (f: UserStatusType[]) => {
    setShowRolePopup(false);
    dispatch({
      type: "ADMIN_ACCOUNTS_SET_USER_STATUSES",
      payload: {
        statuses: f,
      },
    });
  };

  const updateRigs = useCallback(
    (rigs: number[]) => {
      dispatch({
        type: "ADMIN_ACCOUNTS_SET_RIGS",
        payload: {
          rigs,
        },
      });
    },
    [dispatch],
  );

  const onWellsChange = useCallback(
    (wells: number[] = []) => {
      setShowWellSelect(false);
      dispatch({
        type: "ADMIN_ACCOUNTS_SET_WELLS",
        payload: {
          wells,
        },
      });
      return true;
    },
    [dispatch],
  );

  useEffect(() => {
    if (rigs.data) {
      updateRigs(rigs.data.list.map((e) => e.id));
    }
  }, [rigs.data, updateRigs]);

  useEffect(() => {
    if (wells.data) {
      onWellsChange(wells.data.list.map((e) => e.id));
    }
  }, [onWellsChange, wells.data]);

  const {
    atomThemeVariant,
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  return (
    <Row>
      <Col span={24}>
        <OverviewHeaderContainer style={{ background: themeColors.quaterniary_bg }}>
          <Row justify="space-between" align="middle" style={{ height: "119px" }}>
            <Col flex="0 auto">
              <Title level={1} variant={atomThemeVariant} weight={500} style={{ margin: "0 16px" }}>
                Accounts
              </Title>
              <Title level={3} variant="faded" style={{ margin: "8px 16px" }}>
                {isLoading ? "Loading..." : `${operatorsAccounts.data?.list?.length} results`}
              </Title>
            </Col>
          </Row>
        </OverviewHeaderContainer>
      </Col>
      <Col flex="1 auto">
        <PlanSectionLayout
          noTabs
          header={
            <ActionsHeader>
              <Row gutter={12} justify="space-between" align="middle" style={{ height: "64px" }}>
                <Col flex="0 auto">
                  <Space>
                    <Space direction="horizontal" size={4}>
                      <Input
                        allowClear
                        placeholder="Search by ID or Account Name"
                        style={{ width: "300px" }}
                        value={quickSearchValue || undefined}
                        onChange={(e) => setQuickSearchValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            updateSearch();
                          }
                        }}
                      />
                      <Button
                        size="large"
                        type="primary"
                        icon={<PDComponent.SvgIcon name="search" />}
                        onClick={updateSearch}
                      />
                    </Space>
                    <PDComponent.VerticalDivider />

                    <Tooltip title="Account Status">
                      <Popover
                        content={
                          <PDComponent.Pickoff
                            options={Object.values(OperatorStatusType).map((e) => ({ id: e, name: e }))}
                            values={operatorStatus || []}
                            onChange={(e) => updateAccountStatuses(e)}
                          />
                        }
                        trigger="click"
                        placement="bottom"
                        open={showStatusPopup}
                        onOpenChange={(e) => setShowStatusPopup(e)}
                        destroyTooltipOnHide
                      >
                        <Button
                          icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
                          type={
                            operatorStatus?.length !== Object.values(OperatorStatusType).length ? "primary" : "default"
                          }
                          ghost={operatorStatus?.length !== Object.values(OperatorStatusType).length}
                          $engaged={showStatusPopup}
                        />
                      </Popover>
                    </Tooltip>

                    <Tooltip title="Wells">
                      <Popover
                        content={
                          <PDComponent.ComboBoxMultiSelect
                            placeholder="Search"
                            width={400}
                            options={(wells?.data || { list: [] as WellDto[] }).list as IOption<number>[]}
                            values={filterWells || []}
                            onChange={onWellsChange}
                          />
                        }
                        trigger="click"
                        placement="bottom"
                        open={showWellSelect}
                        onOpenChange={(e) => setShowWellSelect(e)}
                        destroyTooltipOnHide
                      >
                        <Button
                          icon={<PDComponent.SvgIcon name="wellInfo" />}
                          $engaged={showWellSelect}
                          type={filterWells?.length !== wells?.data?.list.length ? "primary" : "default"}
                          ghost={filterWells?.length !== wells?.data?.list.length}
                        />
                      </Popover>
                    </Tooltip>

                    <Tooltip title="Rigs">
                      <Popover
                        content={
                          <PDComponent.ComboBoxMultiSelect
                            placeholder="Search Rigs"
                            options={(rigs.data?.list ?? []).map((e) => ({ id: e.id, name: e.shortName || "" }))}
                            values={rigIdsState || []}
                            onChange={(rigIds: number[]) => {
                              updateRigs(rigIds || []);
                              setShowRigPopup(false);
                            }}
                          />
                        }
                        trigger="click"
                        placement="bottom"
                        open={showRigPopup}
                        onOpenChange={(e) => setShowRigPopup(e)}
                        destroyTooltipOnHide
                      >
                        <Button
                          size="large"
                          icon={<PDComponent.SvgIcon name="rig" />}
                          type={rigIdsState?.length !== rigs.data?.list.length ? "primary" : "default"}
                          ghost={rigIdsState?.length !== rigs.data?.list.length}
                          $engaged={showRigPopup}
                        />
                      </Popover>
                    </Tooltip>

                    <Tooltip title="User Status">
                      <Popover
                        content={
                          <PDComponent.Pickoff
                            options={Object.values(UserStatusType).map((e) => ({ id: e, name: e }))}
                            values={userStatus || []}
                            onChange={(e) => updateUserRoles(e)}
                          />
                        }
                        trigger="click"
                        placement="bottom"
                        open={showRolePopup}
                        onOpenChange={(e) => setShowRolePopup(e)}
                        destroyTooltipOnHide
                      >
                        <Button
                          icon={<PDComponent.SvgIcon name="userAdmin" />}
                          type={userStatus?.length !== Object.keys(UserStatusType).length ? "primary" : "default"}
                          ghost={userStatus?.length !== Object.keys(UserStatusType).length}
                          $engaged={showRolePopup}
                        />
                      </Popover>
                    </Tooltip>
                  </Space>
                </Col>
              </Row>
            </ActionsHeader>
          }
          content={isLoading ? <Loader centered /> : <AccountsTable data={operatorsAccounts?.data?.list ?? []} />}
        />
      </Col>
    </Row>
  );
};

export default AccountsListPage;
