export const MockGenStatusBarSvg = () => {
  return (
    <svg width="326" height="62" viewBox="0 0 326 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#AA9C7C" d="M0 0h74.25v62H0z" />
      <path
        d="M33.867 28.374c0-.42.075-.798.224-1.134a2.55 2.55 0 0 1 .644-.854c.27-.243.583-.425.938-.546.364-.13.756-.196 1.176-.196.42 0 .812.07 1.176.21.373.13.695.317.966.56.28.243.495.532.644.868.159.327.238.686.238 1.078 0 .308-.06.588-.182.84a2.52 2.52 0 0 1-.406.63 2.25 2.25 0 0 1-.966.644c.168.047.355.135.56.266.205.13.392.299.56.504.177.205.327.448.448.728.121.27.182.565.182.882 0 .43-.084.817-.252 1.162a2.615 2.615 0 0 1-.7.868c-.29.243-.63.43-1.022.56-.383.13-.798.196-1.246.196-.467 0-.891-.065-1.274-.196a3.016 3.016 0 0 1-.966-.574 2.625 2.625 0 0 1-.616-.868 3.029 3.029 0 0 1-.21-1.148l1.162-.21c0 .616.168 1.101.504 1.456.345.355.807.532 1.386.532.29 0 .555-.037.798-.112.243-.084.453-.2.63-.35a1.68 1.68 0 0 0 .434-.56c.103-.224.154-.476.154-.756a1.7 1.7 0 0 0-.168-.77 1.612 1.612 0 0 0-.434-.588 1.83 1.83 0 0 0-.63-.364 2.166 2.166 0 0 0-.784-.14h-.98v-.994h.98c.523 0 .957-.145 1.302-.434.355-.29.532-.69.532-1.204 0-.233-.047-.453-.14-.658a1.502 1.502 0 0 0-.364-.532 1.755 1.755 0 0 0-.574-.378 1.911 1.911 0 0 0-.742-.14c-.252 0-.49.042-.714.126-.224.084-.42.205-.588.364-.159.159-.29.36-.392.602-.093.243-.14.523-.14.84l-1.148-.21z"
        fill="#fff"
      />
      <path fill="#726588" d="M74.25 0h74.25v62H74.25z" />
      <path
        d="m107.033 32.336 4.788-6.552h1.148v6.538h2.142v1.008h-2.142v2.17h-1.176v-2.17h-4.76v-.994zm1.358-.014h3.402v-4.718l-3.402 4.718z"
        fill="#fff"
      />
      <path fill="#828C91" d="M148.5 0h29v62h-29z" />
      <path
        d="M166.368 30.656c0 .728-.061 1.4-.182 2.016a4.689 4.689 0 0 1-.602 1.582 2.94 2.94 0 0 1-1.092 1.022c-.448.243-.999.364-1.652.364-.653 0-1.204-.121-1.652-.364a2.94 2.94 0 0 1-1.092-1.022 4.689 4.689 0 0 1-.602-1.582 10.47 10.47 0 0 1-.182-2.016c0-.737.061-1.41.182-2.016.121-.616.322-1.143.602-1.582.28-.448.644-.793 1.092-1.036.448-.252.999-.378 1.652-.378.653 0 1.204.126 1.652.378.448.243.812.588 1.092 1.036.28.439.481.966.602 1.582.121.607.182 1.279.182 2.016zm-1.246 0c0-1.353-.173-2.357-.518-3.01-.345-.663-.933-.994-1.764-.994-.849 0-1.447.331-1.792.994-.336.653-.504 1.657-.504 3.01 0 1.335.168 2.333.504 2.996.345.653.943.98 1.792.98.831 0 1.419-.327 1.764-.98.345-.663.518-1.661.518-2.996z"
        fill="#fff"
      />
      <path fill="#D5ABA4" d="M177.5 0h74.25v62H177.5z" />
      <path d="M215.811 25.784V35.5h-1.176v-8.288l-2.352 2.016v-1.344l2.38-2.1h1.148z" fill="#fff" />
      <path fill="#5DC0CD" d="M251.75 0H326v62h-74.25z" />
      <path
        d="M285.659 28.57c0-.476.084-.896.252-1.26a2.66 2.66 0 0 1 .672-.91c.28-.252.597-.439.952-.56.364-.13.737-.196 1.12-.196.457 0 .877.075 1.26.224.392.15.728.355 1.008.616a2.74 2.74 0 0 1 .896 2.044c0 .373-.065.723-.196 1.05a4.14 4.14 0 0 1-.462.882c-.177.27-.369.513-.574.728-.196.215-.373.397-.532.546l-2.898 2.744h4.802V35.5h-6.426v-1.022l3.612-3.472c.131-.121.28-.266.448-.434.168-.168.322-.355.462-.56.149-.215.275-.443.378-.686.103-.252.154-.518.154-.798a1.94 1.94 0 0 0-.49-1.288 1.663 1.663 0 0 0-.602-.42 1.987 1.987 0 0 0-.84-.168c-.233 0-.462.051-.686.154-.224.093-.42.233-.588.42-.168.187-.303.41-.406.672a2.388 2.388 0 0 0-.154.882l-1.162-.21z"
        fill="#fff"
      />
    </svg>
  );
};
