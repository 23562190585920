/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigCardFactSetDto } from './RigCardFactSetDto';
import {
    RigCardFactSetDtoFromJSON,
    RigCardFactSetDtoFromJSONTyped,
    RigCardFactSetDtoToJSON,
} from './RigCardFactSetDto';
import type { ScorecardKpiCommentInfoDto } from './ScorecardKpiCommentInfoDto';
import {
    ScorecardKpiCommentInfoDtoFromJSON,
    ScorecardKpiCommentInfoDtoFromJSONTyped,
    ScorecardKpiCommentInfoDtoToJSON,
} from './ScorecardKpiCommentInfoDto';

/**
 * 
 * @export
 * @interface WellKpiCommentDetailsDto
 */
export interface WellKpiCommentDetailsDto {
    /**
     * 
     * @type {ScorecardKpiCommentInfoDto}
     * @memberof WellKpiCommentDetailsDto
     */
    comment: ScorecardKpiCommentInfoDto;
    /**
     * 
     * @type {RigCardFactSetDto}
     * @memberof WellKpiCommentDetailsDto
     */
    factSet: RigCardFactSetDto;
}

/**
 * Check if a given object implements the WellKpiCommentDetailsDto interface.
 */
export function instanceOfWellKpiCommentDetailsDto(value: object): value is WellKpiCommentDetailsDto {
    if (!('comment' in value) || value['comment'] === undefined) return false;
    if (!('factSet' in value) || value['factSet'] === undefined) return false;
    return true;
}

export function WellKpiCommentDetailsDtoFromJSON(json: any): WellKpiCommentDetailsDto {
    return WellKpiCommentDetailsDtoFromJSONTyped(json, false);
}

export function WellKpiCommentDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellKpiCommentDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': ScorecardKpiCommentInfoDtoFromJSON(json['Comment']),
        'factSet': RigCardFactSetDtoFromJSON(json['FactSet']),
    };
}

export function WellKpiCommentDetailsDtoToJSON(value?: WellKpiCommentDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Comment': ScorecardKpiCommentInfoDtoToJSON(value['comment']),
        'FactSet': RigCardFactSetDtoToJSON(value['factSet']),
    };
}

