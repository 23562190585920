/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TagBottomFingerprintFactSetDto,
  TagBottomFingerprintUserLensDto,
  TagBottomFingerprintUserLensQueryDto,
} from '../models/index';
import {
    TagBottomFingerprintFactSetDtoFromJSON,
    TagBottomFingerprintFactSetDtoToJSON,
    TagBottomFingerprintUserLensDtoFromJSON,
    TagBottomFingerprintUserLensDtoToJSON,
    TagBottomFingerprintUserLensQueryDtoFromJSON,
    TagBottomFingerprintUserLensQueryDtoToJSON,
} from '../models/index';

export interface ApiTagBottomFingerprintUserLensesIdFactsPutRequest {
    id: number;
    tagBottomFingerprintUserLensQueryDto?: TagBottomFingerprintUserLensQueryDto;
}

export interface ApiTagBottomFingerprintUserLensesIdPutRequest {
    id: number;
    tagBottomFingerprintUserLensDto?: TagBottomFingerprintUserLensDto;
}

export interface ApiTagBottomFingerprintUserLensesPostRequest {
    tagBottomFingerprintUserLensDto?: TagBottomFingerprintUserLensDto;
}

/**
 * 
 */
export class TagBottomFingerprintUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiTagBottomFingerprintUserLensesIdFactsPutRaw(requestParameters: ApiTagBottomFingerprintUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagBottomFingerprintFactSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiTagBottomFingerprintUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/TagBottomFingerprintUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagBottomFingerprintUserLensQueryDtoToJSON(requestParameters['tagBottomFingerprintUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagBottomFingerprintFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTagBottomFingerprintUserLensesIdFactsPut(requestParameters: ApiTagBottomFingerprintUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagBottomFingerprintFactSetDto> {
        const response = await this.apiTagBottomFingerprintUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTagBottomFingerprintUserLensesIdPutRaw(requestParameters: ApiTagBottomFingerprintUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagBottomFingerprintUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiTagBottomFingerprintUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/TagBottomFingerprintUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagBottomFingerprintUserLensDtoToJSON(requestParameters['tagBottomFingerprintUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagBottomFingerprintUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTagBottomFingerprintUserLensesIdPut(requestParameters: ApiTagBottomFingerprintUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagBottomFingerprintUserLensDto> {
        const response = await this.apiTagBottomFingerprintUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTagBottomFingerprintUserLensesPostRaw(requestParameters: ApiTagBottomFingerprintUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagBottomFingerprintUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/TagBottomFingerprintUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagBottomFingerprintUserLensDtoToJSON(requestParameters['tagBottomFingerprintUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagBottomFingerprintUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTagBottomFingerprintUserLensesPost(requestParameters: ApiTagBottomFingerprintUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagBottomFingerprintUserLensDto> {
        const response = await this.apiTagBottomFingerprintUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
