/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotificationSubscriptionDto } from './NotificationSubscriptionDto';
import {
    NotificationSubscriptionDtoFromJSON,
    NotificationSubscriptionDtoFromJSONTyped,
    NotificationSubscriptionDtoToJSON,
} from './NotificationSubscriptionDto';

/**
 * 
 * @export
 * @interface NotificationSubscriptionRequestDto
 */
export interface NotificationSubscriptionRequestDto {
    /**
     * 
     * @type {Array<NotificationSubscriptionDto>}
     * @memberof NotificationSubscriptionRequestDto
     */
    subscriptions: Array<NotificationSubscriptionDto>;
}

/**
 * Check if a given object implements the NotificationSubscriptionRequestDto interface.
 */
export function instanceOfNotificationSubscriptionRequestDto(value: object): value is NotificationSubscriptionRequestDto {
    if (!('subscriptions' in value) || value['subscriptions'] === undefined) return false;
    return true;
}

export function NotificationSubscriptionRequestDtoFromJSON(json: any): NotificationSubscriptionRequestDto {
    return NotificationSubscriptionRequestDtoFromJSONTyped(json, false);
}

export function NotificationSubscriptionRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSubscriptionRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'subscriptions': ((json['Subscriptions'] as Array<any>).map(NotificationSubscriptionDtoFromJSON)),
    };
}

export function NotificationSubscriptionRequestDtoToJSON(value?: NotificationSubscriptionRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Subscriptions': ((value['subscriptions'] as Array<any>).map(NotificationSubscriptionDtoToJSON)),
    };
}

