/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseFuelQueryDto,
  BoilerFuelFlowRateResultDto,
  FuelFlowRateResultDto,
  FuelFlowRateUserLensDto,
} from '../models/index';
import {
    BaseFuelQueryDtoFromJSON,
    BaseFuelQueryDtoToJSON,
    BoilerFuelFlowRateResultDtoFromJSON,
    BoilerFuelFlowRateResultDtoToJSON,
    FuelFlowRateResultDtoFromJSON,
    FuelFlowRateResultDtoToJSON,
    FuelFlowRateUserLensDtoFromJSON,
    FuelFlowRateUserLensDtoToJSON,
} from '../models/index';

export interface ApiFuelFlowRateUserLensesIdFactsBoilersPutRequest {
    id: number;
    baseFuelQueryDto?: BaseFuelQueryDto;
}

export interface ApiFuelFlowRateUserLensesIdFactsGeneratorsPutRequest {
    id: number;
    baseFuelQueryDto?: BaseFuelQueryDto;
}

export interface ApiFuelFlowRateUserLensesIdPutRequest {
    id: number;
    fuelFlowRateUserLensDto?: FuelFlowRateUserLensDto;
}

export interface ApiFuelFlowRateUserLensesPostRequest {
    fuelFlowRateUserLensDto?: FuelFlowRateUserLensDto;
}

/**
 * 
 */
export class FuelFlowRateUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiFuelFlowRateUserLensesIdFactsBoilersPutRaw(requestParameters: ApiFuelFlowRateUserLensesIdFactsBoilersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BoilerFuelFlowRateResultDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiFuelFlowRateUserLensesIdFactsBoilersPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/FuelFlowRateUserLenses/{id}/Facts/Boilers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFuelQueryDtoToJSON(requestParameters['baseFuelQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BoilerFuelFlowRateResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFuelFlowRateUserLensesIdFactsBoilersPut(requestParameters: ApiFuelFlowRateUserLensesIdFactsBoilersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BoilerFuelFlowRateResultDto> {
        const response = await this.apiFuelFlowRateUserLensesIdFactsBoilersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFuelFlowRateUserLensesIdFactsGeneratorsPutRaw(requestParameters: ApiFuelFlowRateUserLensesIdFactsGeneratorsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FuelFlowRateResultDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiFuelFlowRateUserLensesIdFactsGeneratorsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/FuelFlowRateUserLenses/{id}/Facts/Generators`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFuelQueryDtoToJSON(requestParameters['baseFuelQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FuelFlowRateResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFuelFlowRateUserLensesIdFactsGeneratorsPut(requestParameters: ApiFuelFlowRateUserLensesIdFactsGeneratorsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FuelFlowRateResultDto> {
        const response = await this.apiFuelFlowRateUserLensesIdFactsGeneratorsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFuelFlowRateUserLensesIdPutRaw(requestParameters: ApiFuelFlowRateUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FuelFlowRateUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiFuelFlowRateUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/FuelFlowRateUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FuelFlowRateUserLensDtoToJSON(requestParameters['fuelFlowRateUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FuelFlowRateUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFuelFlowRateUserLensesIdPut(requestParameters: ApiFuelFlowRateUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FuelFlowRateUserLensDto> {
        const response = await this.apiFuelFlowRateUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFuelFlowRateUserLensesPostRaw(requestParameters: ApiFuelFlowRateUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FuelFlowRateUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/FuelFlowRateUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FuelFlowRateUserLensDtoToJSON(requestParameters['fuelFlowRateUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FuelFlowRateUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFuelFlowRateUserLensesPost(requestParameters: ApiFuelFlowRateUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FuelFlowRateUserLensDto> {
        const response = await this.apiFuelFlowRateUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
