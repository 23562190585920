import { Text } from "atoms/Typography";

import type { LegendItem } from "./interfaces";
import { LegendPreviewerType } from "./interfaces";
import { LegendDot, StyledLegendContainer, StyledLegendItem } from "./StyledComponentsLegend";
import { LEGEND_DISABLED_OPACITY } from "./utils";

interface SimpleLegendProps {
  legendItems: LegendItem[];
  lensGridSize: [number, number];
}

export const SimpleLegend: React.FC<SimpleLegendProps> = ({ legendItems, lensGridSize }) => {
  return (
    <StyledLegendContainer>
      {legendItems.map((legendItem, idx) => {
        const { isEnabled, name } = legendItem;

        const opacity = isEnabled ? 1 : LEGEND_DISABLED_OPACITY;

        return (
          <StyledLegendItem
            onClick={() => {
              if (legendItem.onClick) {
                legendItem.onClick(legendItem, legendItems);
              }
            }}
            key={name + idx}
          >
            <LegendDot
              opacity={opacity}
              background={legendItem.color}
              isLine={legendItem.previewerType === LegendPreviewerType.LINE}
              isDashedBox={legendItem.previewerType === LegendPreviewerType.BOX_DASHED}
            />
            <Text primary="description" variant="faded" fontSize={14} style={{ opacity, userSelect: "none" }}>
              {name}
            </Text>
          </StyledLegendItem>
        );
      })}
    </StyledLegendContainer>
  );
};
