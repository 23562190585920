import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { GeneratorSlotType } from "apis/oag";
import { RigPowerUserLensesApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const rigPowerApi = new RigPowerUserLensesApi(apiConfig);

const GeneratorsQueryKey: PDUniqueQueryKey = { uid: RequestUID.generatorsList };

export function useActiveGenerators(wellId: number, options?: UseQueryOptions<Array<GeneratorSlotType>>) {
  return useSuspenseQuery<Array<GeneratorSlotType>>({
    queryKey: [GeneratorsQueryKey, wellId],
    queryFn: () => rigPowerApi.apiRigPowerUserLensesActiveGeneratorsWellIdGet({ wellId }),
    ...options,
  });
}
