/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BinCoordinates } from './BinCoordinates';
import {
    BinCoordinatesFromJSON,
    BinCoordinatesFromJSONTyped,
    BinCoordinatesToJSON,
} from './BinCoordinates';

/**
 * 
 * @export
 * @interface ParameterHeatmapBin
 */
export interface ParameterHeatmapBin {
    /**
     * 
     * @type {BinCoordinates}
     * @memberof ParameterHeatmapBin
     */
    coordinates: BinCoordinates;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    y: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    z: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    distance: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapBin
     */
    factCount: number;
}

/**
 * Check if a given object implements the ParameterHeatmapBin interface.
 */
export function instanceOfParameterHeatmapBin(value: object): value is ParameterHeatmapBin {
    if (!('coordinates' in value) || value['coordinates'] === undefined) return false;
    if (!('x' in value) || value['x'] === undefined) return false;
    if (!('y' in value) || value['y'] === undefined) return false;
    if (!('z' in value) || value['z'] === undefined) return false;
    if (!('distance' in value) || value['distance'] === undefined) return false;
    if (!('duration' in value) || value['duration'] === undefined) return false;
    if (!('factCount' in value) || value['factCount'] === undefined) return false;
    return true;
}

export function ParameterHeatmapBinFromJSON(json: any): ParameterHeatmapBin {
    return ParameterHeatmapBinFromJSONTyped(json, false);
}

export function ParameterHeatmapBinFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHeatmapBin {
    if (json == null) {
        return json;
    }
    return {
        
        'coordinates': BinCoordinatesFromJSON(json['Coordinates']),
        'x': json['X'],
        'y': json['Y'],
        'z': json['Z'],
        'distance': json['Distance'],
        'duration': json['Duration'],
        'factCount': json['FactCount'],
    };
}

export function ParameterHeatmapBinToJSON(value?: ParameterHeatmapBin | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Coordinates': BinCoordinatesToJSON(value['coordinates']),
        'X': value['x'],
        'Y': value['y'],
        'Z': value['z'],
        'Distance': value['distance'],
        'Duration': value['duration'],
        'FactCount': value['factCount'],
    };
}

