import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Button, Menu } from "utils/componentLibrary";

export const StyledIcon = styled.div<{ up?: boolean }>`
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-left: 1px solid ${colors.light_gray};
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform: ${(props) => (props.up ? "rotate(180deg)" : "rotate(0deg)")};
    }
  }
`;

export const StyledButton = styled(Button)<{ isActive?: boolean; isDisabled?: boolean }>`
  margin-left: 20px;
  padding: 0;
  padding-left: 12px;
  gap: 12px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.ant-btn > .ant-btn-loading-icon span {
    padding-right: 0px;
  }

  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  background: ${(props) => {
    if (props.isActive || props.isDisabled) return props.theme.themeStyle.colors.secondary_bg;
    return props.theme.themeStyle.colors.tertiary_bg;
  }} !important;

  color: ${(props) =>
    props.isActive || props.isDisabled
      ? css`
          ${props.theme.themeStyle.colors.disabled_typography} !important
        `
      : props.theme.themeStyle.colors.primary_typography};

  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};

  :focus,
  &.ant-btn:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
    color: ${colors.gray};
  }
  :hover {
    background: ${(props) => (props.isActive || props.isDisabled ? colors.actions_bg : colors.white)};
    ${({ isDisabled, theme }) =>
      isDisabled
        ? css`
            border-color: ${theme.themeStyle.colors.primary_accent};
          `
        : undefined};
  }
  ${StyledIcon} {
    background: ${({ theme, isDisabled }) =>
      isDisabled ? theme.themeStyle.colors.primary_bg : theme.themeStyle.colors.tertiary_bg};
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
`;

export const StyledMenu = styled(Menu)`
  padding-top: 0;
  min-width: 320px;
  border-radius: 4px;
  &.ant-menu {
    box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  }
`;

export const StyledItem = styled.li<{ $isActive?: string }>`
  margin: 0px;
  margin-top: 1px;
  padding: 0px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  line-height: 38px;
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  border-left: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.themeStyle.colors.primary_bg};
    color: ${colors.well_color};
  }

  ${(props) =>
    props.$isActive === "true" &&
    `
      background: ${props.theme.themeStyle.colors.secondary_bg};
      color: ${colors.gray};
      cursor: auto;
      :hover{
        color: ${colors.gray};
      }
    `}
`;
