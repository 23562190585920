/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SingleStandKpiComparisonInfoDto } from './SingleStandKpiComparisonInfoDto';
import {
    SingleStandKpiComparisonInfoDtoFromJSON,
    SingleStandKpiComparisonInfoDtoFromJSONTyped,
    SingleStandKpiComparisonInfoDtoToJSON,
} from './SingleStandKpiComparisonInfoDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface SingleStandKpiComparisonWellDto
 */
export interface SingleStandKpiComparisonWellDto {
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonWellDto
     */
    wellId: number;
    /**
     * 
     * @type {boolean}
     * @memberof SingleStandKpiComparisonWellDto
     */
    isFocalWell: boolean;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonWellDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiComparisonWellDto
     */
    operationCount: number;
    /**
     * 
     * @type {DateDto}
     * @memberof SingleStandKpiComparisonWellDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {SingleStandKpiComparisonInfoDto}
     * @memberof SingleStandKpiComparisonWellDto
     */
    info: SingleStandKpiComparisonInfoDto;
    /**
     * 
     * @type {Array<SingleStandKpiComparisonInfoDto>}
     * @memberof SingleStandKpiComparisonWellDto
     */
    detailsByDisplayOption?: Array<SingleStandKpiComparisonInfoDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof SingleStandKpiComparisonWellDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the SingleStandKpiComparisonWellDto interface.
 */
export function instanceOfSingleStandKpiComparisonWellDto(value: object): value is SingleStandKpiComparisonWellDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('isFocalWell' in value) || value['isFocalWell'] === undefined) return false;
    if (!('average' in value) || value['average'] === undefined) return false;
    if (!('operationCount' in value) || value['operationCount'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('info' in value) || value['info'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function SingleStandKpiComparisonWellDtoFromJSON(json: any): SingleStandKpiComparisonWellDto {
    return SingleStandKpiComparisonWellDtoFromJSONTyped(json, false);
}

export function SingleStandKpiComparisonWellDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleStandKpiComparisonWellDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'isFocalWell': json['IsFocalWell'],
        'average': json['Average'],
        'operationCount': json['OperationCount'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'info': SingleStandKpiComparisonInfoDtoFromJSON(json['Info']),
        'detailsByDisplayOption': json['DetailsByDisplayOption'] == null ? undefined : ((json['DetailsByDisplayOption'] as Array<any>).map(SingleStandKpiComparisonInfoDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function SingleStandKpiComparisonWellDtoToJSON(value?: SingleStandKpiComparisonWellDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'IsFocalWell': value['isFocalWell'],
        'Average': value['average'],
        'OperationCount': value['operationCount'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'Info': SingleStandKpiComparisonInfoDtoToJSON(value['info']),
        'DetailsByDisplayOption': value['detailsByDisplayOption'] == null ? undefined : ((value['detailsByDisplayOption'] as Array<any>).map(SingleStandKpiComparisonInfoDtoToJSON)),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

