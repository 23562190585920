/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface WedgeBreakdownSlice
 */
export interface WedgeBreakdownSlice {
    /**
     * 
     * @type {string}
     * @memberof WedgeBreakdownSlice
     */
    name: string;
    /**
     * 
     * @type {Array<StandKpiType>}
     * @memberof WedgeBreakdownSlice
     */
    kpis: Array<StandKpiType>;
}

/**
 * Check if a given object implements the WedgeBreakdownSlice interface.
 */
export function instanceOfWedgeBreakdownSlice(value: object): value is WedgeBreakdownSlice {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('kpis' in value) || value['kpis'] === undefined) return false;
    return true;
}

export function WedgeBreakdownSliceFromJSON(json: any): WedgeBreakdownSlice {
    return WedgeBreakdownSliceFromJSONTyped(json, false);
}

export function WedgeBreakdownSliceFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeBreakdownSlice {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['Name'],
        'kpis': ((json['Kpis'] as Array<any>).map(StandKpiTypeFromJSON)),
    };
}

export function WedgeBreakdownSliceToJSON(value?: WedgeBreakdownSlice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Name': value['name'],
        'Kpis': ((value['kpis'] as Array<any>).map(StandKpiTypeToJSON)),
    };
}

