import type { StackedStandKpiComparisonDto } from "apis/oag";
import { DimensionType, DisplayOption, ResultDataState, StackingType } from "apis/oag";
import { PrimaryKpiSummary, SecondaryKpiSummary } from "components/Lenses/common/KpiSummaries";
import { LENS_KPI_HEIGHT_INNER, LOADING_KPI_STRING, NO_DATA_KPI_STRING } from "components/Lenses/constants";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import {
  StyledChartContainerFlexDiv,
  StyledLensContainerFlex,
} from "components/Lenses/ContainerLens/common/StyledComponents";
import type { StackedKpiProps } from "components/Lenses/interfaces";
import { InfoContainer } from "components/MiniLens/InfoContainer";
import { getLensTitle } from "components/MiniLens/MiniLens";
import { SecondaryKpiContainer, StyledKpiRow } from "components/MiniLens/style";
import { useKpiOptions } from "hooks/useKpiOptions";
import { useKpiTypes } from "hooks/useKpiTypes";
import { useLensTemplates } from "hooks/useLensTemplates";
import { usePreviousNonNullValue } from "hooks/usePreviousNonNullValue";
import { getDisplayValue, roundNumberToDecimal } from "pages/Lens/LensSummaryView";
import { useCallback, useEffect, useMemo } from "react";
import type { AppDispatch } from "reducers/store";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import { useUOMbyLens } from "utils/format";
import { transformKey } from "utils/helper";

import { StackedComparisonWellChart } from "./Chart/StackedComparisonWellChart";
import { StackedDayViewChart } from "./Chart/StackedDayViewChart";
import { StackedStandViewChart } from "./Chart/StackedStandViewChart";
import { useStackedKpiSimpleFacts } from "./fetcher";

export const StackedKpiSimple = (props: StackedKpiProps) => {
  const { lens, detailed } = props;
  const displayOption = useAppSelector((state) => state.widgetOptions.display_options);
  const { data, isLoading } = useStackedKpiSimpleFacts({ lensId: lens.id });
  const { data: templates } = useLensTemplates();
  const template = templates?.byId[lens.lensTemplateId];
  const uom = useUOMbyLens(props.dimension, lens);
  const { data: kpiTypes } = useKpiTypes();
  const _dispatch = useAppDispatch();
  const dispatch = useCallback(
    (action: ReturnType<AppDispatch>) => requestAnimationFrame(() => _dispatch(action)),
    [_dispatch],
  );
  // Stand KPI Fact is not sampled. So, BE compute available filters from the fact and send it in the same response.

  const previousData = usePreviousNonNullValue(data);

  useEffect(() => {
    if (detailed && data?.availableFilters)
      dispatch({
        type: "SET_AVAILABLE_FILTERS",
        payload: data?.availableFilters,
      });
  }, [data?.availableFilters, detailed, dispatch]);

  const mainKPIValues = useMemo(() => {
    const kpi = {
      name: "",
      main: NO_DATA_KPI_STRING,
      secondary: "",
      lastUpdatedAtUtc: "",
      templateName: "",
    };

    if (lens.stackingType === StackingType.Distribution && data?.summaryByKpi?.[0]?.kpiTypeId) {
      kpi.templateName = transformKey(
        kpiTypes?.byId[data.summaryByKpi.sort((a, b) => a.position - b.position)[0].kpiTypeId]?.name || "",
      );
    }

    if (isLoading) {
      kpi.main = LOADING_KPI_STRING;
      if (detailed && previousData) {
        kpi.name = template?.name || "Undefined";
        kpi.main = getDisplayValue(previousData, lens, uom);
      }
      return kpi;
    }

    if (data?.dataState === ResultDataState.Valid) {
      kpi.name = template?.name || "Undefined";
      kpi.main = getDisplayValue(data, lens, uom);
    }

    return kpi;
  }, [data, detailed, isLoading, kpiTypes?.byId, lens, previousData, template?.name, uom]);

  const selectedIndicators = useAppSelector((state) => state.widgetOptions.indicators);
  const indicators = useMemo(() => (detailed ? selectedIndicators : null), [detailed, selectedIndicators]);

  const secondaryKPIValues = useMemo(() => {
    const dataSource = detailed && previousData && isLoading ? previousData : data;
    if (isLoading && !detailed) {
      return [];
    }
    if (lens.stackingType === StackingType.Distribution) {
      return (dataSource?.summaryByKpi ?? [])
        .sort((a, b) => a.position - b.position)
        .slice(1)
        .map((fact: any) => {
          return {
            name: transformKey(kpiTypes?.byId[fact.kpiTypeId]?.name || ""),
            main: roundNumberToDecimal(fact.distribution * 100, 2) + "%",
            secondary: "",
          };
        });
    }

    return dataSource?.summaryByKpi?.map((fact: any) => ({
      name: kpiTypes?.byId[fact.kpiTypeId]?.name || "",
      main: uom.display(fact.average) + " avg",
      secondary: "",
    }));
  }, [data, detailed, isLoading, kpiTypes?.byId, lens.stackingType, previousData, uom]);

  const { kpiType, kpiGroup } = useKpiOptions(lens);

  const primaryKpiTitle = useMemo(() => {
    if (mainKPIValues.templateName) {
      return mainKPIValues.templateName;
    }
    return getLensTitle({ lens, template, kpiType, kpiGroup });
  }, [kpiType, kpiGroup, mainKPIValues, lens, template]);

  const SummaryComponent = useMemo(
    () => (
      <div style={{ height: detailed ? 300 : undefined }}>
        <InfoContainer
          size={6}
          direction="vertical"
          style={{
            height: "auto",
          }}
        >
          <StyledKpiRow gutter={34} height={LENS_KPI_HEIGHT_INNER} wrap={true}>
            {mainKPIValues ? (
              <PrimaryKpiSummary
                secondary={mainKPIValues.secondary}
                main={mainKPIValues.main}
                description={""}
                title={primaryKpiTitle}
                detailed={detailed}
              />
            ) : null}
            {secondaryKPIValues ? (
              <SecondaryKpiContainer>
                <SecondaryKpiSummary detailed={detailed} kpis={secondaryKPIValues?.slice(0)} />
              </SecondaryKpiContainer>
            ) : null}
          </StyledKpiRow>
        </InfoContainer>
      </div>
    ),
    [detailed, mainKPIValues, primaryKpiTitle, secondaryKPIValues],
  );

  const LoadedComponent = useMemo(() => {
    if (!data) return null;
    switch (displayOption) {
      case DisplayOption.DateShift:
        return (
          <StackedDayViewChart
            {...props}
            data={data}
            templateType={template?.type}
            valueUOM={uom}
            indicators={indicators || []}
            dimension={props.dimension || DimensionType.Undefined}
            enableTooltip
          />
        );
      case DisplayOption.StandDepth:
        return (
          <StackedStandViewChart
            {...props}
            data={data}
            templateType={template?.type}
            valueUOM={uom}
            indicators={indicators || []}
            dimension={props.dimension || DimensionType.Undefined}
            enableTooltip
          />
        );
      case DisplayOption.Shift:
        return (
          <StackedComparisonWellChart
            {...props}
            data={data as unknown as StackedStandKpiComparisonDto}
            templateType={template?.type}
            dimension={props.dimension || DimensionType.Undefined}
            displayOption={displayOption}
            enableTooltip
          />
        );
    }
  }, [data, displayOption, indicators, props, template?.type, uom]);

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={data?.dataState}
      isDetailed={detailed}
      SummaryComponent={SummaryComponent}
      LoadedComponent={
        <StyledLensContainerFlex>
          {SummaryComponent}
          <StyledChartContainerFlexDiv style={{ paddingLeft: 24 }}>{LoadedComponent}</StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
