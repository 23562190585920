import styled from "styled-components";
import colors from "utils/colors";

export const OuterContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin-right: 16px;
`;

export const Container = styled.div<{ $isActive: boolean }>`
  position: relative;
  color: ${({ $isActive }) => ($isActive ? colors.white : colors.gray)};
  svg {
    margin-right: 8px;
    font-size: 16px;
    color: ${({ $isActive }) => ($isActive ? colors.white : colors.gray)};
  }
`;

export const CircleCount = styled.div`
  color: ${colors.white};
  top: -13px;
  right: -22px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  font-size: 11px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.yellow_sumac};
`;
