import { Title } from "atoms/Typography";
import styled, { css } from "styled-components";

export const IconContainer = styled.div`
  font-size: 24px;
  display: none;
`;

export const FuelContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  font-size: 22px;
`;

export const ValueContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const ChartContainer = styled.div`
  display: hidden;
  height: 0;
  width: 100%;
`;

export const Value = styled(Title).attrs({
  level: 3,
  variant: "faded",
})<{ $color: string }>`
  font-size: 22px !important;
  color: ${({ $color }) => $color} !important;
  font-weight: 500 !important;
`;

export const NoDataText = styled(Title).attrs({
  level: 3,
  variant: "faded",
})`
  font-size: 12px !important;
  font-weight: 500 !important;
`;

export const LensTitle = styled(Title).attrs({
  level: 4,
  variant: "faded",
})`
  font-size: 12px !important;
`;

export const StatCard = styled.div<{
  height: number;
  width: number;
  $mouseIn: boolean;
  $isLoading?: boolean;
}>`
  display: flex;
  position: absolute;
  z-index: ${({ $mouseIn }) => ($mouseIn ? "0" : "2")};
  height: 100%;
  width: 100%;
  background-color: ${({ theme: { themeStyle } }) => themeStyle.colors.primary_chart_bg};
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  height: 100%;
  overflow: visible;
  padding-left: 12px;
  padding-top: 0px;
  transition: all 0.2s ease-in-out;

  ${({ height, width, $mouseIn, $isLoading }) => [
    height === 1 &&
      css`
        ${Value} {
          font-size: 22px !important;
        }
        height: calc(100% - ${$mouseIn ? 40 : 0}px);
      `,
    height > 1 &&
      css`
        gap: 15px;
        justify-content: center;
        ${Value} {
          font-size: ${width < 3 ? 22 : 34}px !important;
        }
        ${IconContainer} {
          display: block;
        }
      `,
    height > 2 &&
      css`
        height: calc(100% - ${$mouseIn ? 40 : 0}px);
        justify-content: ${$isLoading ? "center" : "space-between"};
        gap: 0;
        ${LensTitle} {
          padding-top: 24px;
        }
        ${ChartContainer} {
          display: block;
          height: calc(100% - 135px);
        }
      `,
    width > 2 &&
      css`
        align-items: center;
        ${LensTitle} {
          font-size: 14px !important;
        }
        ${ValueContainer} {
          gap: 0;
          width: 100%;
          justify-content: space-evenly;
        }
      `,
  ]}
`;
