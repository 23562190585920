import { NotificationRowVirtualizedWrapper } from "components/Header/RightContent/Notifications/NotificationsPopover/NotificationList/NotificationRow/NotificationRowVirtualizedWrapper";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { Row } from "utils/componentLibrary";
import type { BaseNotificationVm } from "utils/notifications/mappers";

export const NotificationList = ({
  notifications,
  selectedIdsList,
  setSelectedIdsList,
  handleOnMarkAsArchived,
  handleOnToggleMarkAsRead,
}: {
  notifications: BaseNotificationVm[];
  selectedIdsList: number[];
  setSelectedIdsList: (ids: number[]) => void;
  handleOnMarkAsArchived: (id: number) => void;
  handleOnToggleMarkAsRead: (id: number) => void;
}) => {
  return (
    <Row style={{ height: "100%", width: "100%" }}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <List
            height={Number.isNaN(height) ? "100%" : height}
            width={width}
            itemSize={110}
            itemCount={notifications.length}
            itemData={{
              notifications,
              selectedIdsList,
              setSelectedIdsList,
              handleOnMarkAsArchived,
              handleOnToggleMarkAsRead,
            }}
          >
            {NotificationRowVirtualizedWrapper}
          </List>
        )}
      </AutoSizer>
    </Row>
  );
};
