import type {
  KpiGroupUserLensDto,
  KpiTypeLensTemplateDto,
  KpiTypeUserLensDto,
  PivotOrderType,
  RigPowerUserLensDto,
  UserLensDto,
  WedgeLensTemplateDto,
  WedgeUserLensDto,
} from "apis/oag";
import {
  DimensionType,
  DrillingProductivityType,
  LensTemplateType,
  OutlierFlaggingType,
  PivotType,
  StackingType,
  TimeUnit,
  TorqueAndDragType,
  UserRoleType,
} from "apis/oag";
import { CustomSwitch, RadioButtonGroup } from "atoms/common";
import { Button, Input, Select } from "atoms/Form";
import { toast } from "atoms/toast";
import { Text } from "atoms/Typography";
import { FormGroup } from "components/General/FormGroup";
import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import { getAbilityMatrix } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";
import { PDComponent } from "components/PDComponents";
import { getDefaultPivotOrderTypeByPivot } from "components/RigComparisonSelectors/DisplayOptionsModal";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useKpiGroups } from "hooks/useKPIGroups";
import { useKpiTypes } from "hooks/useKpiTypes";
import {
  isDrillingProductivityLensTemplate,
  isMultipleKpiTemplate,
  isPivotKpiGroupLensTemplate,
  isPivotKpiTypeLensTemplate,
  isSingleKpiTemplate,
  isTorqueAndDrag,
  useLensTemplates,
} from "hooks/useLensTemplates";
import { addKpiTypeToLens, getKpiTypeByLens } from "hooks/useUserLenses";
import { round } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { drillingProductivityMap } from "utils/common";
import { Checkbox, Col, Radio, Row, Typography } from "utils/componentLibrary";
import { useUOMbyLens } from "utils/format";

import IncludeOutliers, { IncludeOutliersButton } from "./SettingComponents/IncludeOutliers";
import { CustomButtonRow, CustomIcon, Grid, ToggleButton } from "./style";

export const OrderedVisualAidType = {
  None: "None",
  Targets: "Targets",
  Average: "Average",
  Median: "Median",
} as const;

export type CommonLensSettingsModalContentProps = {
  selectedLens: UserLensDto;
  onSave: (lens: UserLensDto) => Promise<void> | void;
  setVisible: (value: boolean) => void;
  visible: boolean;
  isLocked: boolean;
};

type KpiOption = {
  id: number | string;
  name: string;
};

const KPIValueMapping = {
  Values: "Number",
  Distribution: "Percentage",
};

export function CommonLensSettingsModalContent({
  selectedLens,
  onSave,
  setVisible,
  visible,
  isLocked,
}: CommonLensSettingsModalContentProps) {
  const { data: lensTemplates } = useLensTemplates({ refetchOnMount: false });
  const { data: kpiTypes } = useKpiTypes({ refetchOnMount: false });
  const { data: kpiGroups } = useKpiGroups({ refetchOnMount: false });
  const [localLens, setLocalLens] = useState<UserLensDto>(selectedLens);
  const [isSaving, setIsSaving] = useState(false);
  const [localYMin, setLocalYMin] = useState("");
  const [localYMax, setLocalYMax] = useState("");
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);

  const [rigDisplayOptions] = useStateQuery<{
    pivot: Array<PivotType>;
    order: Array<PivotOrderType>;
  }>(URL_STATE_PARAM.DATA_GROUPS_WIDGET, {
    pivot: [PivotType.Day, PivotType.None],
    order: [PivotType.Day, PivotType.None].map((e) => getDefaultPivotOrderTypeByPivot(e)),
  });
  const pivotType = useMemo(() => {
    const filteredOptions = (rigDisplayOptions?.pivot ?? []).filter(
      (option) => option !== PivotType.None && option !== PivotType.Rig,
    );
    return filteredOptions.length > 0 ? filteredOptions[filteredOptions.length - 1] : PivotType.Rig;
  }, [rigDisplayOptions]);

  const lensTemplate = lensTemplates?.byId[selectedLens.lensTemplateId];
  const displayOption = useAppSelector((state) => state.widgetOptions.display_options);
  const userInfo = useAppSelector((state) => state.global.userInfo);
  const currentKpiId = useMemo(() => {
    const kpiId = getKpiTypeByLens({ lens: localLens, lensTemplateType: lensTemplate?.type });
    if (lensTemplate?.type === LensTemplateType.DrillingProductivity) {
      return drillingProductivityMap.find((e) => e.id === kpiId)?.name || "";
    }
    return kpiId;
  }, [localLens, lensTemplate?.type]);

  const currentDimension = useMemo(() => {
    if ("drillingProductivityType" in selectedLens) {
      if (selectedLens.drillingProductivityType === DrillingProductivityType.DrillingDistance)
        return DimensionType.Metres;
      else if (selectedLens.drillingProductivityType === DrillingProductivityType.DrillingRate)
        return DimensionType.MetresPerSecond;

      return DimensionType.Seconds;
    }
    if ((isSingleKpiTemplate(lensTemplate) || lensTemplate?.type === LensTemplateType.PivotKpiType) && kpiTypes)
      return kpiTypes.byId[currentKpiId as keyof typeof kpiTypes.byId]?.dimension;
    if (
      (isMultipleKpiTemplate(lensTemplate) || lensTemplate?.type === LensTemplateType.PivotKpiGroup) &&
      kpiGroups &&
      kpiTypes
    )
      return kpiTypes.byId[kpiGroups.byId[currentKpiId as keyof typeof kpiGroups.byId].kpiTypeIds[0]]?.dimension;
    return DimensionType.Undefined;
  }, [currentKpiId, kpiGroups, kpiTypes, lensTemplate, selectedLens]);

  const uom = useUOMbyLens(currentDimension, localLens);

  function changeTypeToKpiType(item: UserLensDto): item is KpiTypeUserLensDto & RigPowerUserLensDto {
    return item && "outlierMin" in item;
  }
  // Initially I wanted to do the nice implementation with isLensTemplate.* but that is failing :(
  const outlierMin = useMemo(() => (changeTypeToKpiType(localLens) ? localLens.outlierMin : 0), [localLens]);
  const outlierMax = useMemo(() => (changeTypeToKpiType(localLens) ? localLens.outlierMax : 0), [localLens]);
  const showsOutliers = useMemo(() => (changeTypeToKpiType(localLens) ? localLens.showsOutliers : false), [localLens]);
  const isManualYaxis = useMemo(() => (changeTypeToKpiType(localLens) ? localLens.isManualYaxis : false), [localLens]);
  const outlierFlaggingType = useMemo(
    () => (changeTypeToKpiType(localLens) ? localLens.outlierFlaggingType : null),
    [localLens],
  );
  const yaxisStart = useMemo(() => (changeTypeToKpiType(localLens) ? localLens.yaxisStart : 0), [localLens]);
  const yaxisEnd = useMemo(() => (changeTypeToKpiType(localLens) ? localLens.yaxisEnd : 0), [localLens]);
  const selectedVisualAids = useMemo(
    () => (changeTypeToKpiType(localLens) ? localLens.selectedVisualAids : []),
    [localLens],
  );
  const showDetailedLegend = useMemo(
    () => (changeTypeToKpiType(localLens) ? localLens.showDetailedLegend : false),
    [localLens],
  );
  const [localOutlierYMin, setLocalOutlierYMin] = useState(outlierMin ? uom.fromSI(outlierMin).toString() : "");
  const [localOutlierYMax, setLocalOutlierYMax] = useState(outlierMax ? uom.fromSI(outlierMax).toString() : "");

  const selectedLensYaxisStart = useMemo(() => (selectedLens as KpiTypeUserLensDto)?.yaxisStart || 0, [selectedLens]);
  const selectedLensYaxisEnd = useMemo(() => (selectedLens as KpiTypeUserLensDto)?.yaxisEnd || 0, [selectedLens]);
  const selectedLensOutlierMin = useMemo(
    () => (changeTypeToKpiType(selectedLens) ? selectedLens.outlierMin : 0),
    [selectedLens],
  );
  const selectedLensOutlierMax = useMemo(
    () => (changeTypeToKpiType(selectedLens) ? selectedLens.outlierMax : 0),
    [selectedLens],
  );

  const kpiOptions = useMemo<KpiOption[]>(() => {
    const undefinedKpi: KpiOption = {
      name: "Undefined KPI",
      id: -1,
    };
    if (isSingleKpiTemplate(lensTemplate) && kpiTypes) {
      return (lensTemplate.selectableKpiTypeIds || []).map((id) => {
        const kpiType = kpiTypes.byId[id];

        if (!kpiType) {
          return undefinedKpi;
        }

        return {
          id: kpiType.id,
          name: kpiType.name || "",
        };
      });
    }
    if (isPivotKpiTypeLensTemplate(lensTemplate) && kpiTypes) {
      return (lensTemplate.selectableKpiTypeIds || []).map((id) => {
        const kpiType = kpiTypes.byId[id];

        if (!kpiType) {
          return undefinedKpi;
        }

        return {
          id: kpiType.id,
          name: kpiType.name || "",
        };
      });
    }

    if (isMultipleKpiTemplate(lensTemplate) && kpiGroups && kpiTypes) {
      return (lensTemplate.selectableKpiGroupIds || [])?.map((id) => {
        const kpiGroup = kpiGroups.byId[id];

        if (!kpiGroup) {
          return undefinedKpi;
        }

        return {
          id: kpiGroup.id,
          name: kpiGroup.name || "",
        };
      });
    }

    if (isPivotKpiGroupLensTemplate(lensTemplate) && kpiGroups && kpiTypes) {
      return (lensTemplate.selectableKpiGroupIds || [])?.map((id) => {
        const kpiGroup = kpiGroups.byId[id];

        if (!kpiGroup) {
          return undefinedKpi;
        }

        return {
          id: kpiGroup.id,
          name: kpiGroup.name || "",
        };
      });
    }
    if (isTorqueAndDrag(lensTemplate)) {
      return Object.keys(TorqueAndDragType)
        .map((e, id) =>
          e !== TorqueAndDragType.Unknown
            ? {
                id,
                name: e,
              }
            : null,
        )
        .filter((e) => e) as KpiOption[];
    }
    if (isDrillingProductivityLensTemplate(lensTemplate)) {
      return drillingProductivityMap;
    }

    return [];
  }, [kpiGroups, kpiTypes, lensTemplate]);

  const breakdowns = useMemo(() => {
    return (lensTemplates?.byId[localLens.lensTemplateId] as WedgeLensTemplateDto).breakdowns;
  }, [lensTemplates?.byId, localLens.lensTemplateId]);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    try {
      if (localLens) {
        await onSave(localLens);
      }
      handleClose();
    } catch (err) {
      toast.error({ message: "Failed to save lens settings" });
      console.error(err);
    }
    setIsSaving(false);
  }, [localLens, handleClose, onSave]);

  const handleKpiChange = useCallback(
    (id: number | string) => {
      if (isDrillingProductivityLensTemplate(lensTemplate)) {
        setLocalYMax("");
        setLocalYMin("");
      }
      setLocalLens((prev) => {
        const newKpiInfo =
          addKpiTypeToLens({ lens: localLens, lensKpi: id, lensTemplateType: lensTemplate?.type }) ?? prev;
        return isDrillingProductivityLensTemplate(lensTemplate) ? { ...newKpiInfo, isManualYaxis: false } : newKpiInfo;
      });
    },
    [lensTemplate, localLens],
  );

  const ability = useMemo(() => {
    return getAbilityMatrix({
      type: lensTemplate?.type,
      isComparing: comparisonWells.length > 0,
      displayOption: displayOption,
      pivotType: pivotType,
      stackingType: (localLens as KpiGroupUserLensDto).stackingType,
      isSystem: selectedLens?.isSystem,
    });
  }, [lensTemplate?.type, comparisonWells.length, displayOption, pivotType, localLens, selectedLens?.isSystem]);
  useEffect(() => {
    if (selectedLens) {
      setLocalLens(selectedLens);
    }
  }, [selectedLens]);

  useEffect(() => {
    if (Number.isFinite(selectedLensYaxisStart) && uom) {
      setLocalYMin(round(uom.fromSI(+selectedLensYaxisStart), 2).toString());
    }
  }, [uom, selectedLensYaxisStart, lensTemplate, selectedLens, currentKpiId]);

  useEffect(() => {
    if (Number.isFinite(selectedLensYaxisEnd) && uom) {
      setLocalYMax(round(uom.fromSI(+selectedLensYaxisEnd), 2).toString());
    }
  }, [uom, selectedLensYaxisEnd, lensTemplate, currentKpiId]);

  useEffect(() => {
    if (selectedLensOutlierMin && Number.isFinite(selectedLensOutlierMin) && uom) {
      setLocalOutlierYMin(uom.fromSI(+selectedLensOutlierMin).toString());
    }
  }, [uom, selectedLensOutlierMin]);

  useEffect(() => {
    if (selectedLensOutlierMax && Number.isFinite(selectedLensOutlierMax) && uom) {
      setLocalOutlierYMax(uom.fromSI(+selectedLensOutlierMax).toString());
    }
  }, [uom, selectedLensOutlierMax]);

  useEffect(() => {
    if (Number.isFinite(+localYMin) && uom) {
      setLocalLens((prev) => ({ ...prev, yaxisStart: uom.toSI(+localYMin) }));
    }
  }, [uom, localYMin, lensTemplate, currentKpiId]);

  useEffect(() => {
    if (Number.isFinite(+localYMax) && uom) {
      setLocalLens((prev) => ({ ...prev, yaxisEnd: uom.toSI(+localYMax) }));
    }
  }, [uom, localYMax, lensTemplate, currentKpiId]);

  const localYMinError = useMemo(() => {
    if (!Number.isFinite(+localYMin)) return "Incorrect value";
    if (+localYMin < 0) return "Value shall be positive";

    if (+localYMin >= +localYMax) return `Value should be less than ${localYMax}`;
    return null;
  }, [localYMax, localYMin]);

  const localYMaxError = useMemo(() => {
    if (!Number.isFinite(+localYMax)) return "Incorrect value";
    if (+localYMax < 0) return "Value shall be positive";
    if (+localYMin >= +localYMax) return `Value should be more than ${localYMin}`;
    return null;
  }, [localYMax, localYMin]);

  useEffect(() => {
    if (
      Number.isFinite(+localYMin) &&
      isDrillingProductivityLensTemplate(lensTemplate) &&
      currentKpiId === DrillingProductivityType.DrillingRate
    ) {
      setLocalLens((prev) => ({
        ...prev,
        yaxisStart: +localYMin / (24 * 3600),
      }));
      return;
    }
    if (Number.isFinite(+localYMin) && uom) {
      setLocalLens((prev) => ({ ...prev, yaxisStart: uom.toSI(+localYMin) }));
    }
  }, [uom, localYMin, lensTemplate, currentKpiId]);

  useEffect(() => {
    if (
      Number.isFinite(+localYMax) &&
      isDrillingProductivityLensTemplate(lensTemplate) &&
      currentKpiId === DrillingProductivityType.DrillingRate
    ) {
      setLocalLens((prev) => ({
        ...prev,
        yaxisEnd: +localYMax / (24 * 3600),
      }));
      return;
    }
    if (Number.isFinite(+localYMax) && uom) {
      setLocalLens((prev) => ({ ...prev, yaxisEnd: uom.toSI(+localYMax) }));
    }
  }, [uom, localYMax, lensTemplate, currentKpiId]);

  useEffect(() => {
    if (Number.isFinite(+localOutlierYMin) && uom) {
      setLocalLens((prev) => ({ ...prev, outlierMin: uom.toSI(+localOutlierYMin) }));
    }
  }, [uom, localOutlierYMin]);

  useEffect(() => {
    if (Number.isFinite(+localOutlierYMax) && uom) {
      setLocalLens((prev) => ({ ...prev, outlierMax: uom.toSI(+localOutlierYMax) }));
    }
  }, [uom, localOutlierYMax]);

  const localOutlierYMinError = useMemo(() => {
    if (!Number.isFinite(+localOutlierYMin)) return "Incorrect value";
    if (+localOutlierYMin < 0) return "Value shall be positive";

    if (+localOutlierYMin >= +localOutlierYMax) return `Value should be less than ${localOutlierYMax}`;
    return null;
  }, [localOutlierYMax, localOutlierYMin]);

  const localOutlierYMaxError = useMemo(() => {
    if (!Number.isFinite(+localOutlierYMax)) return "Incorrect value";
    if (+localOutlierYMax < 0) return "Value shall be positive";
    if (+localOutlierYMin >= +localOutlierYMax) return `Value should be more than ${localOutlierYMin}`;
    return null;
  }, [localOutlierYMax, localOutlierYMin]);

  const hasInputError = isManualYaxis && (!!localYMinError || !!localYMaxError);
  const hasOutlierInputError =
    outlierFlaggingType === OutlierFlaggingType.Manual && (!!localOutlierYMinError || !!localOutlierYMaxError);

  const Content = useMemo(() => {
    const topLensSettings = [
      {
        label: (
          <CustomIcon>
            <PDComponent.SvgIcon name="tagGroup" /> <Typography>Labels</Typography>{" "}
          </CustomIcon>
        ),
        item: "label",
      },
      {
        label: (
          <CustomIcon>
            {showsOutliers ? (
              <PDComponent.SvgIcon name="outliersEnabled" />
            ) : (
              <PDComponent.SvgIcon name="outliersDisabled" />
            )}{" "}
            <Typography>Auto Crop</Typography>{" "}
          </CustomIcon>
        ),
        item: "showsOutliers",
      },
    ];

    const bottomLensSettings = [
      {
        label: (
          <CustomIcon>
            <PDComponent.SvgIcon name="summary" />
            <Typography>Summary</Typography>
          </CustomIcon>
        ),
        item: "showPercentageDifference",
      },
      {
        label: (
          <CustomIcon>
            <PDComponent.SvgIcon name="zTorque" />
            <Typography>Z-Torque ON</Typography>
          </CustomIcon>
        ),
        item: "showZTorque",
      },
      {
        label: (
          <CustomIcon>
            {(localLens as KpiTypeUserLensDto).squeezesDisplay ? (
              <PDComponent.SvgIcon name="expand" />
            ) : (
              <PDComponent.SvgIcon name="shrink" />
            )}{" "}
            <Typography>Shrink</Typography>
          </CustomIcon>
        ),
        item: "squeezesDisplay",
      },
      ...(userInfo?.canAccessRigScorecard
        ? [
            {
              label: (
                <CustomIcon>
                  <PDComponent.SvgIcon name="chat" />
                  <Typography>Driller Comments</Typography>{" "}
                </CustomIcon>
              ),
              item: "showStandKpiComments",
            },
          ]
        : []),
      {
        label: (
          <CustomIcon>
            <PDComponent.SvgIcon name="operationGauge" /> <Typography>Operation Count </Typography>{" "}
          </CustomIcon>
        ),
        item: "showOperationCount",
      },
      {
        label: (
          <CustomIcon>
            <Typography>DLS</Typography>{" "}
          </CustomIcon>
        ),
        item: "showSurvey",
      },
    ];
    const hasTrackKpi = ability.trackKPI === MatrixAvailability.AVAILABLE;
    return [
      hasTrackKpi ? (
        <FormGroup label="Track KPI" key={"Track KPI"}>
          <Select
            value={currentKpiId?.toString()}
            disabled={isLocked ? !isTorqueAndDrag(lensTemplate) : undefined}
            onChange={(value) => {
              function isNumeric(value: string) {
                return /^-?\d+$/.test(value);
              }
              const typedValue = value as string | number;
              isNumeric(typedValue.toString()) ? handleKpiChange(+typedValue) : handleKpiChange(typedValue.toString());
            }}
            placeholder="Select track KPI"
            $fullWidth
          >
            {kpiOptions.map((option) => (
              <Select.Option key={option.id} value={option.id.toString()}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </FormGroup>
      ) : null,

      ability.breakdowns === MatrixAvailability.AVAILABLE ? (
        <FormGroup label="Lens KPI" key="Lens KPI">
          <Select
            value={((localLens as WedgeUserLensDto)?.breakdownId || "").toString()}
            onChange={(value) => handleKpiChange(value as number)}
            placeholder="Select track KPI"
            disabled={isLocked}
            $fullWidth
          >
            {(breakdowns || []).map((option) => (
              <Select.Option key={option.id} value={option.id?.toString()}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </FormGroup>
      ) : null,
      ability.unitOfTimeHourDay === MatrixAvailability.AVAILABLE ? (
        <FormGroup
          key={"Unit of Time Hours or Days"}
          label={
            <CustomIcon>
              <PDComponent.SvgIcon name="time" />
              <Typography>Unit of Time</Typography>
            </CustomIcon>
          }
          rightElement={
            <RadioButtonGroup
              value={
                (localLens as KpiTypeUserLensDto)?.selectedTimeUnit === undefined ||
                (localLens as KpiTypeUserLensDto)?.selectedTimeUnit === TimeUnit.Unknown
                  ? TimeUnit.Hour
                  : (localLens as KpiTypeUserLensDto)?.selectedTimeUnit
              }
              onChange={(e) => {
                setLocalLens((prev) => ({
                  ...prev,
                  selectedTimeUnit: e.target.value,
                }));
              }}
            >
              <Radio.Button value={TimeUnit.Hour}>Hour</Radio.Button>
              <Radio.Button value={TimeUnit.Day}>Day</Radio.Button>
            </RadioButtonGroup>
          }
        />
      ) : null,
      (lensTemplate as KpiTypeLensTemplateDto).canSelectTimeUnit &&
      ability.unitOfTime === MatrixAvailability.AVAILABLE ? (
        <FormGroup
          key={"Unit of Time"}
          label={
            <CustomIcon>
              <PDComponent.SvgIcon name="time" />
              <Typography>Unit of Time</Typography>
            </CustomIcon>
          }
          rightElement={
            <RadioButtonGroup
              value={
                (localLens as KpiTypeUserLensDto)?.selectedTimeUnit === undefined ||
                (localLens as KpiTypeUserLensDto)?.selectedTimeUnit === TimeUnit.Unknown
                  ? TimeUnit.Hour
                  : (localLens as KpiTypeUserLensDto)?.selectedTimeUnit
              }
              onChange={(e) => {
                setLocalLens((prev) => ({
                  ...prev,
                  selectedTimeUnit: e.target.value,
                }));
              }}
            >
              <Radio.Button value={TimeUnit.Hour}>Hour</Radio.Button>
              <Radio.Button value={TimeUnit.Minute}>Minute</Radio.Button>
            </RadioButtonGroup>
          }
        />
      ) : null,
      ability.manual_axis === MatrixAvailability.AVAILABLE ? (
        <FormGroup
          key={"Y Axis Range"}
          label="Y Axis Range"
          rightElement={
            <div>
              <ToggleButton
                inactive={isManualYaxis}
                onClick={() => {
                  setLocalLens((prev) => ({ ...prev, isManualYaxis: false }));
                }}
              >
                Auto
              </ToggleButton>
              <ToggleButton
                inactive={!isManualYaxis}
                onClick={() => {
                  setLocalLens((prev) => ({ ...prev, isManualYaxis: true }));
                }}
              >
                Manual
              </ToggleButton>
            </div>
          }
        >
          <Grid>
            <Input
              suffix={uom?.abbr}
              disabled={!isManualYaxis}
              value={isManualYaxis ? localYMin : 0}
              onChange={(e) => setLocalYMin(e.currentTarget.value)}
              error={isManualYaxis ? localYMinError || "" : ""}
            />
            <Input
              suffix={uom?.abbr}
              disabled={!isManualYaxis}
              value={isManualYaxis ? localYMax : "Auto"}
              onChange={(e) => setLocalYMax(e.currentTarget.value)}
              error={isManualYaxis ? localYMaxError || "" : ""}
            />
          </Grid>
        </FormGroup>
      ) : null,

      ability.perceptual_kpi_value === MatrixAvailability.AVAILABLE &&
      userInfo?.role === UserRoleType.Administrator &&
      (selectedLens as KpiGroupUserLensDto).stackingType ? (
        <FormGroup
          key={"KPI Value"}
          label={
            <CustomIcon>
              <Typography>KPI Value</Typography>
            </CustomIcon>
          }
        >
          <Radio.Group
            value={(localLens as KpiGroupUserLensDto).stackingType}
            onChange={(e) => {
              setLocalLens((prev) => ({
                ...prev,
                stackingType: e.target.value,
              }));
            }}
            style={{
              width: "100%",
            }}
          >
            <CustomButtonRow>
              {Object.values(StackingType).map((type) => (
                <Button
                  key={type}
                  onClick={() =>
                    setLocalLens((prev) => ({
                      ...prev,
                      stackingType: type,
                    }))
                  }
                >
                  <Radio key={type} value={type}>
                    {KPIValueMapping[type]}
                  </Radio>
                </Button>
              ))}
            </CustomButtonRow>
          </Radio.Group>
        </FormGroup>
      ) : null,
      ...topLensSettings.map((setting) => {
        return ability[setting.item as keyof typeof ability] === MatrixAvailability.AVAILABLE ? (
          <FormGroup label={""} key={setting.item}>
            <Row justify="space-between" style={{ width: "100%", alignItems: "center" }}>
              <Col>{setting.label}</Col>
              <Col>
                <Row gutter={[10, 0]}>
                  <Col
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Text primary="description" variant="faded">
                      {localLens[setting.item as keyof typeof localLens] ? "ON" : "OFF"}
                    </Text>
                  </Col>
                  <Col>
                    <CustomSwitch
                      $isChecked={!!localLens[setting.item as keyof typeof localLens]}
                      checked={localLens[setting.item as keyof typeof localLens] as boolean}
                      onChange={(checked) => setLocalLens((prev) => ({ ...prev, [setting.item]: checked }))}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>
        ) : null;
      }),

      ability.outlierFlaggingType === MatrixAvailability.AVAILABLE ? (
        <FormGroup
          key={"Include Outliers"}
          hasBorder
          label={
            <CustomIcon>
              <PDComponent.SvgIcon name="includeOutliers" /> <Typography>Include Outliers</Typography>{" "}
            </CustomIcon>
          }
          rightElement={<IncludeOutliersButton localLens={localLens} setLocalLens={setLocalLens} />}
        >
          <IncludeOutliers
            localLens={localLens}
            setLocalLens={setLocalLens}
            abbr={uom?.abbr}
            yMin={localOutlierYMin}
            setYMin={setLocalOutlierYMin}
            yMinError={localOutlierYMinError || ""}
            yMax={localOutlierYMax}
            setYMax={setLocalOutlierYMax}
            yMaxError={localOutlierYMaxError || ""}
          />
        </FormGroup>
      ) : null,
      ...bottomLensSettings.map((setting) => {
        return ability[setting.item as keyof typeof ability] === MatrixAvailability.AVAILABLE ? (
          <FormGroup label={""} key={setting.item}>
            <Row justify="space-between" style={{ width: "100%", alignItems: "center" }}>
              <Col>{setting.label}</Col>
              <Col>
                <Row gutter={[10, 0]}>
                  <Col
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Text primary="description" variant="faded">
                      {localLens[setting.item as keyof typeof localLens] ? "ON" : "OFF"}
                    </Text>
                  </Col>
                  <Col>
                    <CustomSwitch
                      $isChecked={!!localLens[setting.item as keyof typeof localLens]}
                      checked={localLens[setting.item as keyof typeof localLens] as boolean}
                      onChange={(checked) => setLocalLens((prev) => ({ ...prev, [setting.item]: checked }))}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>
        ) : null;
      }),

      ability.selectedVisualAids === MatrixAvailability.AVAILABLE ? (
        <FormGroup
          key={"Visual Aids"}
          label={
            <CustomIcon>
              {" "}
              <PDComponent.SvgIcon name="cdArchive" /> <Typography>Visual Aids</Typography>{" "}
            </CustomIcon>
          }
        >
          <Row align={"middle"} justify={"start"}>
            <Checkbox.Group
              value={selectedVisualAids}
              onChange={(checkedVisualAids) => {
                Track.interact("Lens Settings - Visual Aids", {
                  Selection: checkedVisualAids,
                });
                setLocalLens((prev) => ({
                  ...prev,
                  selectedVisualAids: checkedVisualAids,
                }));
              }}
            >
              {Object.keys(OrderedVisualAidType)
                .filter((e) => e !== OrderedVisualAidType.None)
                .map((e) => {
                  return (
                    <Checkbox key={e} value={e}>
                      {e}
                    </Checkbox>
                  );
                })}
            </Checkbox.Group>
          </Row>
        </FormGroup>
      ) : null,

      ability.hasDetailedLegend === MatrixAvailability.AVAILABLE ? (
        <FormGroup label={""} key="detailedLegend">
          <Row justify="space-between" style={{ width: "100%", alignItems: "center" }}>
            <Col>Detailed Legend</Col>
            <Col>
              <Row gutter={[10, 0]}>
                <Col
                  style={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Text primary="description" variant="faded">
                    {showDetailedLegend ? "ON" : "OFF"}
                  </Text>
                </Col>
                <Col>
                  <CustomSwitch
                    disabled={isLocked}
                    $isChecked={!!showDetailedLegend}
                    checked={showDetailedLegend}
                    onChange={(isChecked) => setLocalLens((prev) => ({ ...prev, showDetailedLegend: isChecked }))}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </FormGroup>
      ) : null,
    ].filter((x) => x);
  }, [
    ability,
    breakdowns,
    currentKpiId,
    handleKpiChange,
    isLocked,
    isManualYaxis,
    kpiOptions,
    lensTemplate,
    localLens,
    localOutlierYMax,
    localOutlierYMaxError,
    localOutlierYMin,
    localOutlierYMinError,
    localYMax,
    localYMaxError,
    localYMin,
    localYMinError,
    selectedLens,
    selectedVisualAids,
    showDetailedLegend,
    showsOutliers,
    uom?.abbr,
    userInfo?.role,
  ]);

  if (!localLens || Content.length === 0) {
    return null;
  }

  return (
    <PDComponent.Modal
      onCancel={handleClose}
      title="Lens Settings"
      footer={
        <Row justify="end">
          <Col>
            <Button
              type="primary"
              disabled={hasInputError || hasOutlierInputError}
              onClick={() => {
                handleSave();
                Track.interact("Lens Detailed - Changing KPI", {
                  "Manual Y Axis": isManualYaxis,
                  "Y Axis Range": isManualYaxis ? [uom.fromSI(yaxisStart || 0), uom.fromSI(yaxisEnd || 0)] : "Auto",
                  "Track KPI Value": kpiOptions.find((kpi) => kpi.id === currentKpiId)?.name,
                  "Lens Name": lensTemplate?.name,
                });
              }}
              loading={isSaving}
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      }
      open={visible}
    >
      {Content}
    </PDComponent.Modal>
  );
}
