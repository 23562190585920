import CoreLayout, { Section, SectionLayout } from "components/Layout";
import { Loader } from "components/Loader";
import { RigPageSwitcher } from "components/RigPageSwitcher";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useSelectedRig } from "hooks/useSelectedRig";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { Track, usePageView } from "services/Mixpanel";

import { Header } from "./Header";
import LeftPane from "./LeftPane";
import RightPaneLegendTab from "./RightPane/RightPaneLegendTab";
import { RightPaneTasksTab } from "./RightPane/RightPaneTasksTab";
import * as Styled from "./style";
import { Tasks } from "./TasksHeader";

enum SelectedTab {
  Legend = "Legend",
  Tasks = "Tasks",
}

const RigScoreCardInner = () => {
  const rigId = useSelectedRig();
  const { data: currentUser, isLoading } = useCurrentUser();
  const [selectedTab, setSelectedTab] = useState(SelectedTab.Legend);

  usePageView({
    title: "Rig ScoreCard",
    enabled: !!rigId,
    options: {
      rigId,
    },
  });

  const handleOnTabClick = useCallback((activeKey: string) => {
    Track.interact("Right Pane - Tab Click", {
      "Selected Tab": activeKey,
    });
    setSelectedTab(activeKey as SelectedTab);
  }, []);

  const [notificationSelectedWellId] = useStateQuery<number | undefined>(URL_STATE_PARAM.ADDITIONAL_SELECTED_WELLS_RIG_SCORECARD, undefined);
  const [notificationCardId] = useStateQuery<number | undefined>(URL_STATE_PARAM.NOTIFICATION_SCORE_CARD_GROUP_ID, undefined);
  const [notificationKpiId] = useStateQuery<number | undefined>(URL_STATE_PARAM.NOTIFICATION_SCORE_CARD_KPI_ID, undefined);
  const hasTriggeredTabChange = useRef(false);

  useEffect(() => {
    if (notificationSelectedWellId !== undefined && notificationKpiId !== undefined && notificationCardId !== undefined && !hasTriggeredTabChange.current) {
      hasTriggeredTabChange.current = true;
      setSelectedTab(SelectedTab.Tasks);
    }
  }, [notificationCardId, notificationKpiId, notificationSelectedWellId])


  if (isLoading) return <Loader centered />;
  if (currentUser?.canAccessRigScorecard === false) {
    return <Navigate to={`/rig/${rigId}`} />;
  }
  return (
    <CoreLayout
      key={rigId}
      sidebar={
        <SectionLayout
          header={
            <>
              <Styled.RigTabs onTabClick={handleOnTabClick} activeKey={selectedTab} type="line" size="large"
                items={
                  [
                    {
                      key: SelectedTab.Legend,
                      label: SelectedTab.Legend,
                    },
                    {
                      key: SelectedTab.Tasks,
                      label: <Styled.TabContainer>
                        <div>Tasks</div>
                        <Suspense fallback={null}>
                          <Tasks />
                        </Suspense>
                      </Styled.TabContainer>
                    }
                  ]
                }

              />

              <RigPageSwitcher />
            </>
          }
        >
          <Suspense fallback={null}>
            {selectedTab === SelectedTab.Legend ? <RightPaneLegendTab /> : <RightPaneTasksTab />}
          </Suspense>
        </SectionLayout>
      }
    >
      <SectionLayout
        useScrollCol
        header={
          <Section>
            <Header />
          </Section>
        }
      >
        <Suspense fallback={<Loader centered />}>
          <LeftPane />
        </Suspense>
      </SectionLayout>
    </CoreLayout >
  );
};
export default RigScoreCardInner;
