/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseDisplayOptionUserLensQueryDto,
  KpiTypeUserLensDto,
  SingleStandKpiComparisonDto,
  SingleStandKpiDto,
} from '../models/index';
import {
    BaseDisplayOptionUserLensQueryDtoFromJSON,
    BaseDisplayOptionUserLensQueryDtoToJSON,
    KpiTypeUserLensDtoFromJSON,
    KpiTypeUserLensDtoToJSON,
    SingleStandKpiComparisonDtoFromJSON,
    SingleStandKpiComparisonDtoToJSON,
    SingleStandKpiDtoFromJSON,
    SingleStandKpiDtoToJSON,
} from '../models/index';

export interface ApiKpiTypeUserLensesIdComparisonFactsPutRequest {
    id: number;
    baseDisplayOptionUserLensQueryDto?: BaseDisplayOptionUserLensQueryDto;
}

export interface ApiKpiTypeUserLensesIdPutRequest {
    id: number;
    kpiTypeUserLensDto?: KpiTypeUserLensDto;
}

export interface ApiKpiTypeUserLensesIdSingleFactsPutRequest {
    id: number;
    baseDisplayOptionUserLensQueryDto?: BaseDisplayOptionUserLensQueryDto;
}

export interface ApiKpiTypeUserLensesPostRequest {
    kpiTypeUserLensDto?: KpiTypeUserLensDto;
}

/**
 * 
 */
export class KpiTypeUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiKpiTypeUserLensesIdComparisonFactsPutRaw(requestParameters: ApiKpiTypeUserLensesIdComparisonFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleStandKpiComparisonDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiKpiTypeUserLensesIdComparisonFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/KpiTypeUserLenses/{id}/ComparisonFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseDisplayOptionUserLensQueryDtoToJSON(requestParameters['baseDisplayOptionUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SingleStandKpiComparisonDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiKpiTypeUserLensesIdComparisonFactsPut(requestParameters: ApiKpiTypeUserLensesIdComparisonFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleStandKpiComparisonDto> {
        const response = await this.apiKpiTypeUserLensesIdComparisonFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiKpiTypeUserLensesIdPutRaw(requestParameters: ApiKpiTypeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiTypeUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiKpiTypeUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/KpiTypeUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KpiTypeUserLensDtoToJSON(requestParameters['kpiTypeUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiTypeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiKpiTypeUserLensesIdPut(requestParameters: ApiKpiTypeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiTypeUserLensDto> {
        const response = await this.apiKpiTypeUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiKpiTypeUserLensesIdSingleFactsPutRaw(requestParameters: ApiKpiTypeUserLensesIdSingleFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleStandKpiDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiKpiTypeUserLensesIdSingleFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/KpiTypeUserLenses/{id}/SingleFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseDisplayOptionUserLensQueryDtoToJSON(requestParameters['baseDisplayOptionUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SingleStandKpiDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiKpiTypeUserLensesIdSingleFactsPut(requestParameters: ApiKpiTypeUserLensesIdSingleFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleStandKpiDto> {
        const response = await this.apiKpiTypeUserLensesIdSingleFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiKpiTypeUserLensesPostRaw(requestParameters: ApiKpiTypeUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiTypeUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/KpiTypeUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KpiTypeUserLensDtoToJSON(requestParameters['kpiTypeUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiTypeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiKpiTypeUserLensesPost(requestParameters: ApiKpiTypeUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiTypeUserLensDto> {
        const response = await this.apiKpiTypeUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
