/* eslint-disable react/no-multi-comp */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ParameterHeatmapUserLensDto } from "apis/oag";
import { DimensionType, OutliersFilteringType, ParameterHeatmapUserLensesApi } from "apis/oag";
import { Button, Input } from "atoms/Form";
import { toast } from "atoms/toast";
import { Text } from "atoms/Typography";
import type { ContainerLensSettingsModalProps } from "components/Lenses/ContainerLens/common/utils/LensSettings";
import { PDComponent } from "components/PDComponents";
import { useTracks } from "hooks/useTracks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { Col, Divider, Row } from "utils/componentLibrary";
import { useUOM } from "utils/format";
import { RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import { FormGroup } from "./FormGroup";
import {
  BoldRowLabel,
  CustomSwitch,
  DepthGrid,
  Grid,
  RangeContainer,
  StyledDepthText,
  StyledDivider,
  SwitchRow,
  ToggleButton,
} from "./style";

interface ParameterHeatmapSettingsModalProps extends ContainerLensSettingsModalProps {
  lens: ParameterHeatmapUserLensDto;
}

const parameterHeatmapApi = new ParameterHeatmapUserLensesApi(apiConfig);

export function ParameterHeatmapSettingsModal({
  lens,
  onLensUpdated,
  setIsVisible,
  isVisible,
}: ParameterHeatmapSettingsModalProps) {
  const [localLens, setLocalLens] = useState(lens);
  const [isSaving, setIsSaving] = useState(false);
  const queryClient = useQueryClient();

  const [isThresholdPercentageBased, setIsThresholdPercentageBased] = useState(
    lens.zTrack.outliersType === OutliersFilteringType.Percentile,
  );
  const { data: tracks } = useTracks();

  const zTrack = (tracks?.byId ?? [])[localLens.zTrack.trackId];
  const zUom = useUOM(zTrack?.dimension);
  const depthUOM = useUOM(DimensionType.Metres);

  const defaultThresholdValues = useMemo(() => {
    return {
      min: {
        asPercentile: Math.round((lens?.zTrack?.outliersMinPercentile || 0) * 100) + "",
        asValue: zUom.fromSI(lens?.zTrack?.outliersMinValue || 0) || 0,
      },
      max: {
        asPercentile: Math.round((lens?.zTrack?.outliersMaxPercentile || 0) * 100) + "",
        asValue: zUom.fromSI(+(lens?.zTrack?.outliersMaxValue || 0)) || 0,
      },
    };
  }, [
    lens?.zTrack?.outliersMaxPercentile,
    lens?.zTrack?.outliersMaxValue,
    lens?.zTrack?.outliersMinPercentile,
    lens?.zTrack?.outliersMinValue,
    zUom,
  ]);

  const [thresholdMinValue, setThresholdMinValue] = useState<number | string>(defaultThresholdValues.min.asValue);
  const [thresholdMaxValue, setThresholdMaxValue] = useState<number | string>(defaultThresholdValues.max.asValue);

  const [thresholdMinPercentile, setThresholdMinPercentile] = useState(defaultThresholdValues.min.asPercentile);
  const [thresholdMaxPercentile, setThresholdMaxPercentile] = useState(defaultThresholdValues.max.asPercentile);

  const thresholdMin = isThresholdPercentageBased ? thresholdMinPercentile : thresholdMinValue;
  const thresholdMax = isThresholdPercentageBased ? thresholdMaxPercentile : thresholdMaxValue;

  const [weightThreshold, setWeightThreshold] = useState(depthUOM.toSI(lens?.binDistanceThreshold));

  const [localFocalDepthRange, setLocalFocalDepthRange] = useState(depthUOM.toSI(lens.focalRecommendationDepthRange));
  const [localOffsetDepthRange, setLocalOffsetDepthRange] = useState(
    depthUOM.toSI(lens.offsetRecommendationDepthRange),
  );

  const handleLensMutation = useMutation({
    mutationFn: (updatedLens: ParameterHeatmapUserLensDto) => {
      const newLens = updatedLens;

      return parameterHeatmapApi.apiParameterHeatmapUserLensesIdPut({
        id: lens.id,
        parameterHeatmapUserLensDto: newLens,
      });
    },
    onSettled: (lens) => {
      if (lens) {
        queryClient.invalidateQueries({
          queryKey: [{ uid: RequestUID.parameterHeatmapFacts, lensId: lens.id }],
          exact: false,
        });
        onLensUpdated(lens);
        setLocalLens(lens);
      }
    },
  });

  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    try {
      if (localLens) {
        await handleLensMutation.mutateAsync(localLens);
      }
      handleClose();
    } catch (err) {
      toast.error({ message: "Failed to save lens settings" });
      console.error(err);
    }
    setIsSaving(false);
  }, [handleClose, handleLensMutation, localLens]);

  useEffect(() => {
    if (lens) {
      setLocalLens(lens);
    }
  }, [lens]);

  const thresholdMinError = useMemo(() => {
    if (!Number.isFinite(+thresholdMin)) return "Incorrect value";
    if (+thresholdMin < 0) return "Value shall be positive";

    if (+thresholdMin > +thresholdMax) return `Value should be less than ${thresholdMax}`;
    return null;
  }, [thresholdMin, thresholdMax]);

  const thresholdMaxError = useMemo(() => {
    if (!Number.isFinite(+thresholdMax)) return "Incorrect value";
    if (+thresholdMax < 0) return "Value shall be positive";
    if (+thresholdMin > +thresholdMax) return `Value should be more than ${thresholdMin}`;
    if (isThresholdPercentageBased && +thresholdMax > 100) return "Value should be less than 100";
    return null;
  }, [isThresholdPercentageBased, thresholdMax, thresholdMin]);

  const hasInputError = useMemo(
    () => !!thresholdMinError || !!thresholdMaxError,
    [thresholdMinError, thresholdMaxError],
  );

  const handleOnClickPercentage = useCallback(() => {
    setLocalLens((lens) => ({ ...lens, zTrack: { ...lens.zTrack, outliersType: OutliersFilteringType.Percentile } }));
    setIsThresholdPercentageBased(true);
  }, []);

  const handleOnClickValue = useCallback(() => {
    setLocalLens((lens) => ({ ...lens, zTrack: { ...lens.zTrack, outliersType: OutliersFilteringType.Value } }));
    setIsThresholdPercentageBased(false);
  }, []);

  const handleOnOffsetDepthChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = +ev.target.value;
      setLocalOffsetDepthRange(value);

      if (Number.isFinite(value)) {
        setLocalLens((lens) => ({ ...lens, offsetRecommendationDepthRange: depthUOM.fromSI(value) }));
      }
    },
    [depthUOM],
  );

  const handleOnFocalDepthChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = +ev.target.value;
      setLocalFocalDepthRange(value);

      if (Number.isFinite(value)) {
        setLocalLens((lens) => ({ ...lens, focalRecommendationDepthRange: depthUOM.fromSI(value) }));
      }
    },
    [depthUOM],
  );

  const handleOnWeightThresholdChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number.isFinite(+ev.target.value) ? +ev.target.value : weightThreshold;
      setWeightThreshold(newValue);
      setLocalLens((lens) => ({ ...lens, binDistanceThreshold: depthUOM.fromSI(newValue) }));
    },

    [depthUOM, weightThreshold],
  );

  useEffect(() => {
    setLocalLens((lens) => ({
      ...lens,
      zTrack: {
        ...lens.zTrack,
        outliersType: isThresholdPercentageBased ? OutliersFilteringType.Percentile : OutliersFilteringType.Value,
      },
    }));
  }, [isThresholdPercentageBased]);

  useEffect(() => {
    if (zUom) {
      setLocalLens((lens) => ({
        ...lens,
        zTrack: {
          ...lens.zTrack,
          outliersMinPercentile: +thresholdMinPercentile / 100,
          outliersMaxPercentile: +thresholdMaxPercentile / 100,
          outliersMinValue: zUom.toSI(+thresholdMinValue),
          outliersMaxValue: zUom.toSI(+thresholdMaxValue),
        },
      }));
    }
  }, [zUom, thresholdMinValue, thresholdMaxValue, thresholdMinPercentile, thresholdMaxPercentile, lens.zTrack]);

  const theme = useCustomTheme();
  const { themeStyle, isDark } = theme;

  const ButtonStyleFocus = `
    color: ${themeStyle.colors.primary_button_bg} !important;
    border-color: ${themeStyle.colors.primary_button_bg} !important;
    background: ${themeStyle.colors.off_secondary_bg} !important;
  `;

  const ButtonStyleDisabled = `
    color: ${themeStyle.colors.disabled_typography} !important;
    border-color: ${themeStyle.colors.disabled_typography} !important;
  `;

  return (
    <PDComponent.Modal
      onCancel={handleClose}
      title="Lens Settings"
      footer={
        <Row justify="end">
          <Col>
            <Button type="primary" disabled={hasInputError} onClick={handleSave} loading={isSaving}>
              Save Changes
            </Button>
          </Col>
        </Row>
      }
      open={isVisible}
    >
      <FormGroup
        label="Z-Axis outlier threshold"
        rightElement={
          <div>
            <ToggleButton onClick={handleOnClickPercentage} inactive={!isThresholdPercentageBased}>
              Percentage
            </ToggleButton>
            <ToggleButton onClick={handleOnClickValue} inactive={isThresholdPercentageBased}>
              Value
            </ToggleButton>
          </div>
        }
      >
        <Grid>
          {isThresholdPercentageBased ? (
            <>
              <Input
                suffix={"%"}
                value={thresholdMinPercentile}
                onChange={(e) => setThresholdMinPercentile(e.currentTarget.value)}
                error={thresholdMinError || undefined}
                type="number"
              />
              <StyledDivider />
              <Input
                suffix={"%"}
                value={thresholdMaxPercentile}
                onChange={(e) => setThresholdMaxPercentile(e.currentTarget.value)}
                error={thresholdMaxError || undefined}
                type="number"
              />
            </>
          ) : (
            <>
              <Input
                suffix={zUom?.abbr}
                value={thresholdMinValue}
                onChange={(e) => setThresholdMinValue(e.currentTarget.value)}
                error={thresholdMinError || undefined}
                type="number"
              />
              <StyledDivider />
              <Input
                suffix={zUom?.abbr}
                value={thresholdMaxValue}
                onChange={(e) => setThresholdMaxValue(e.currentTarget.value)}
                error={thresholdMaxError || undefined}
                type="number"
              />
            </>
          )}
        </Grid>
      </FormGroup>

      <Divider />

      <SwitchRow>
        <Col>
          <BoldRowLabel>Bin Weight Threshold</BoldRowLabel>
        </Col>
        <Col>
          <StyledDepthText> Remove bins less than the entered value.</StyledDepthText>
        </Col>

        <RangeContainer>
          <Input
            suffix={depthUOM.abbr}
            value={Math.round(weightThreshold)}
            min={0}
            onChange={handleOnWeightThresholdChange}
          />
        </RangeContainer>
      </SwitchRow>

      <Divider />

      <SwitchRow>
        <Col>
          <BoldRowLabel>
            <PDComponent.SvgIcon
              name="heatmapActiveBin"
              svgStyle={{ marginRight: 10 }}
              width="14px"
              height="16px"
              color={colors.well_color}
            />
            Most Recent Value
          </BoldRowLabel>
        </Col>
        <Col>
          <Row gutter={[10, 0]}>
            <Col
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Text primary="description" variant="faded">
                {localLens.showActiveBin ? "ON" : "OFF"}
              </Text>
            </Col>
            <Col>
              <CustomSwitch
                checked={localLens.showActiveBin}
                onChange={(checked) => setLocalLens((lens) => ({ ...lens, showActiveBin: checked }))}
              />
            </Col>
          </Row>
        </Col>
      </SwitchRow>

      <Divider />

      <SwitchRow>
        <BoldRowLabel>
          <PDComponent.SvgIcon
            name="heatmapActualRecommendation"
            svgStyle={{ marginRight: 10 }}
            color={colors.well_color}
            width="18px"
            height="22px"
          />
          Well Recommendation
        </BoldRowLabel>
        <Col>
          <Row gutter={[10, 0]}>
            <Col
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Text primary="description" variant="faded">
                {localLens.showFocalRecommendation ? "ON" : "OFF"}
              </Text>
            </Col>
            <Col>
              <CustomSwitch
                checked={localLens.showFocalRecommendation}
                onChange={(checked) => setLocalLens((lens) => ({ ...lens, showFocalRecommendation: checked }))}
              />
            </Col>
          </Row>
        </Col>
      </SwitchRow>

      {localLens.showFocalRecommendation ? (
        <Row>
          <DepthGrid>
            <Col> Range:</Col>
            <Input
              suffix={depthUOM.abbr}
              value={Math.round(localFocalDepthRange)}
              min={0}
              onChange={handleOnFocalDepthChange}
            />
            <Col>
              <StyledDepthText> Ahead/behind from most recent hole depth.</StyledDepthText>
            </Col>
          </DepthGrid>
        </Row>
      ) : null}

      <Divider />

      <SwitchRow>
        <BoldRowLabel fill={isDark ? colors.white : colors.black}>
          <PDComponent.SvgIcon
            name="heatmapOffsetRecommendation"
            svgStyle={{ marginRight: 10 }}
            color={theme.isDark ? colors.white : colors.black}
            width="18px"
            height="22px"
          />
          Offset Recommendation
        </BoldRowLabel>
        <Col>
          <Row gutter={[10, 0]}>
            <Col
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Text primary="description" variant="faded">
                {localLens.showOffsetRecommendation ? "ON" : "OFF"}
              </Text>
            </Col>
            <Col>
              <CustomSwitch
                checked={localLens.showOffsetRecommendation}
                onChange={(checked) => setLocalLens((lens) => ({ ...lens, showOffsetRecommendation: checked }))}
              />
            </Col>
          </Row>
        </Col>
      </SwitchRow>

      {localLens.showOffsetRecommendation ? (
        <Row>
          <DepthGrid>
            <Col> Range:</Col>
            <Input
              suffix={depthUOM.abbr}
              value={Math.round(localOffsetDepthRange)}
              min={0}
              onChange={handleOnOffsetDepthChange}
            />
            <Col>
              <StyledDepthText> Ahead/behind from most recent hole depth.</StyledDepthText>
            </Col>
          </DepthGrid>
        </Row>
      ) : null}

      <Divider />

      <FormGroup
        label={<BoldRowLabel>Displayed data</BoldRowLabel>}
        rightElement={
          <Row gutter={[10, 8]}>
            <Col>
              <Button
                size="large"
                onClick={() => setLocalLens((lens) => ({ ...lens, showOffsetData: false }))}
                style={localLens.showOffsetData ? {} : { color: colors.well_color, borderColor: colors.well_color }}
                styleFocus={localLens.showOffsetData ? ButtonStyleDisabled : ButtonStyleFocus}
              >
                Selected Well
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                onClick={() => setLocalLens((lens) => ({ ...lens, showOffsetData: true }))}
                style={localLens.showOffsetData ? { color: colors.well_color, borderColor: colors.well_color } : {}}
                styleFocus={localLens.showOffsetData ? ButtonStyleFocus : ButtonStyleDisabled}
              >
                Offset
              </Button>
            </Col>
          </Row>
        }
      />
    </PDComponent.Modal>
  );
}
