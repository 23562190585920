import type { GeneratorSlotType } from "apis/oag";
import type { ReactElement } from "react";

export enum LegendPreviewerType {
  BOX = "BOX",
  LINE = "LINE",
  LINE_DASHED = "LINE_DASHED",
  BOX_DASHED = "BOX_DASHED",
}

export interface LegendItem {
  name: string;
  id: number | GeneratorSlotType;
  color: string;
  isEnabled: boolean;
  columnValues?: Record<string, string | number | ReactElement>;
  additionalInfo?: Record<string, string | number>;
  previewerType: LegendPreviewerType;
  onClick?: (item: LegendItem, items?: LegendItem[]) => void; // One way data-flow !
}
