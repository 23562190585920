export const MockSingleKpiSvg = () => {
  return (
    <svg width="326" height="126" viewBox="0 0 326 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_2588_68238"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="326"
        height="126"
      >
        <rect width="326" height="126" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2588_68238)">
        <g opacity="0.6">
          <rect opacity="0.08" y="62" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="41" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="20" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="83" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="104" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="125" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="125" width="326" height="1" fill="#131B1D" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 62C24 61.4477 24.4477 61 25 61H41C41.5523 61 42 61.4477 42 62V127.06H24V62Z"
          fill="#096C5B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50 72C50 71.4477 50.4477 71 51 71H67C67.5523 71 68 71.4477 68 72V127.06H50V72Z"
          fill="#096C5B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76 52C76 51.4477 76.4477 51 77 51H93C93.5523 51 94 51.4477 94 52V127.06H76V52Z"
          fill="#096C5B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102 62C102 61.4477 102.448 61 103 61H119C119.552 61 120 61.4477 120 62V127.06H102V62Z"
          fill="#0D9B83"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128 42C128 41.4477 128.448 41 129 41H145C145.552 41 146 41.4477 146 42V127.06H128V42Z"
          fill="#0D9B83"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M154 82C154 81.4477 154.448 81 155 81H171C171.552 81 172 81.4477 172 82V127.06H154V82Z"
          fill="#0D9B83"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180 72C180 71.4477 180.448 71 181 71H197C197.552 71 198 71.4477 198 72V127.06H180V72Z"
          fill="#0D9B83"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M206 21C206 20.4477 206.448 20 207 20H223C223.552 20 224 20.4477 224 21V127.06H206V21Z"
          fill="url(#paint0_linear_2588_68238)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M232 92C232 91.4477 232.448 91 233 91H249C249.552 91 250 91.4477 250 92V127.06H232V92Z"
          fill="#0D9B83"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258 82C258 81.4477 258.448 81 259 81H275C275.552 81 276 81.4477 276 82V127.06H258V82Z"
          fill="#0D9B83"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 42C284 41.4477 284.448 41 285 41H301C301.552 41 302 41.4477 302 42V127.06H284V42Z"
          fill="#0D9B83"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2588_68238"
          x1="215"
          y1="20"
          x2="215"
          y2="127.06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D9B83" stopOpacity="0" />
          <stop offset="0.125" stopColor="#0D9B83" />
        </linearGradient>
      </defs>
    </svg>
  );
};
