import styled from "styled-components";
import colors from "utils/colors";
import { Card } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";

export const FormationCard = styled(Card)`
  ${PdCardBody} {
    padding: 20px 0;
  }

  border-radius: 10px;
  border: solid 1px ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 2fr 2fr 2fr 2fr 36px;
  grid-gap: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
  padding: 10px 20px;
`;

export const KpiWithDropdown = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  h5 {
    color: ${colors.gray} !important;
  }
`;
