/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PivotOrderType = {
    Unknown: 'Unknown',
    PivotKeyAscending: 'PivotKeyAscending',
    PivotKeyDescending: 'PivotKeyDescending',
    PivotValueAscending: 'PivotValueAscending',
    PivotValueDescending: 'PivotValueDescending',
    LastUpdateAscending: 'LastUpdateAscending',
    LastUpdateDescending: 'LastUpdateDescending',
    WellSpudDateAscending: 'WellSpudDateAscending',
    WellSpudDateDescending: 'WellSpudDateDescending',
    WellLastActivityDateAscending: 'WellLastActivityDateAscending',
    WellLastActivityDateDescending: 'WellLastActivityDateDescending',
    UserInputAscending: 'UserInputAscending',
    UserInputDescending: 'UserInputDescending'
} as const;
export type PivotOrderType = typeof PivotOrderType[keyof typeof PivotOrderType];


export function instanceOfPivotOrderType(value: any): boolean {
    for (const key in PivotOrderType) {
        if (Object.prototype.hasOwnProperty.call(PivotOrderType, key)) {
            if (PivotOrderType[key as keyof typeof PivotOrderType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PivotOrderTypeFromJSON(json: any): PivotOrderType {
    return PivotOrderTypeFromJSONTyped(json, false);
}

export function PivotOrderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotOrderType {
    return json as PivotOrderType;
}

export function PivotOrderTypeToJSON(value?: PivotOrderType | null): any {
    return value as any;
}

