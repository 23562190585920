import Typography from "antd/lib/typography/Typography";
import type { ParameterByDepthUserLensTrackItemDto } from "apis/oag";
import { RadioButtonGroup } from "atoms/common";
import { Button, Select } from "atoms/Form";
import { Input } from "atoms/Form";
import { toast } from "atoms/toast";
import { CustomIcon } from "components/Lenses/CommonLensSettingsModalContents/style";
import { FormGroup } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/TrackSettingsModal/FormGroup";
import type { ITrackUnitTypes } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/utils/trackUnitTypes";
import { PDComponent } from "components/PDComponents";
import { useTracks } from "hooks/useTracks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { transformGlobalUOM } from "reducers/globalReducer";
import { defaultUnitSystem } from "utils/common";
import { Col, Radio, Row } from "utils/componentLibrary";
import type { IValueWithUnit } from "utils/interfaces/Well/interfaces";

import * as Styled from "./style";

export type TrackSettingsModalProps = {
  trackUnitTypes: ITrackUnitTypes;
  availableTrackIds: number[];
  selectedTrack: ParameterByDepthUserLensTrackItemDto;
  onSave: (result: { track: ParameterByDepthUserLensTrackItemDto }) => Promise<void> | void;
  onDelete: (trackId: number) => Promise<void> | void;
  setVisible: (value: boolean) => void;
  visible: boolean;
  selectedUnits: Record<number, IValueWithUnit>;
};

export function TrackSettingsModal({
  trackUnitTypes,
  selectedTrack,
  availableTrackIds,
  onSave,
  onDelete,
  setVisible,
  visible,
  selectedUnits,
}: TrackSettingsModalProps) {
  const [localTrack, setLocalTrack] = useState<ParameterByDepthUserLensTrackItemDto | null>(selectedTrack);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [localYMin, setLocalYMin] = useState("");
  const [localYMax, setLocalYMax] = useState("");

  const { data: tracks } = useTracks();

  const tracksWithSelfIds = [selectedTrack?.trackId].concat(availableTrackIds);
  const trackUnits = useMemo(() => {
    return trackUnitTypes[tracks?.byId?.[selectedTrack?.trackId || -1]?.name || ""];
  }, [selectedTrack?.trackId, tracks?.byId, trackUnitTypes]);

  const inputUnit =
    localTrack && trackUnits
      ? trackUnits[
          Object.keys(trackUnits ?? {}).find(
            (key) =>
              transformGlobalUOM(trackUnits[key].unitSystem || defaultUnitSystem) ===
              localTrack.systemOfMeasurementType,
          ) || ""
        ]
      : undefined;

  const handleClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleDelete = useCallback(async () => {
    setIsDeleting(true);
    try {
      if (selectedTrack) {
        await onDelete(selectedTrack.trackId);
      }
      handleClose();
    } catch (err) {
      toast.error({ message: "Failed to delete track" });
      console.error(err);
    }
    setIsDeleting(false);
  }, [onDelete, selectedTrack, handleClose]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    try {
      if (selectedTrack && localTrack) {
        await onSave({ track: localTrack });
      }
      handleClose();
    } catch (err) {
      toast.error({ message: "Failed to save track" });
      console.error(err);
    }
    setIsSaving(false);
  }, [selectedTrack, handleClose, onSave, localTrack]);

  useEffect(() => {
    if (selectedTrack && selectedUnits) {
      setLocalTrack((prev) => ({
        ...selectedTrack,
        ...prev,
        systemOfMeasurementType: transformGlobalUOM(
          selectedUnits[selectedTrack.trackId]?.unitSystem || defaultUnitSystem,
        ),
      }));
    }
  }, [selectedTrack, selectedUnits]);

  useEffect(() => {
    if (!visible) {
      setLocalTrack(null);
    }
  }, [visible]);

  useEffect(() => {
    if (Number.isFinite(selectedTrack?.yaxisStart) && inputUnit) {
      setLocalYMin(inputUnit.toString(selectedTrack?.yaxisStart || 0));
    }
  }, [inputUnit, selectedTrack?.yaxisStart]);

  useEffect(() => {
    if (Number.isFinite(selectedTrack?.yaxisEnd) && inputUnit) {
      setLocalYMax(inputUnit.toString(selectedTrack?.yaxisEnd || 0));
    }
  }, [inputUnit, selectedTrack?.yaxisEnd]);

  useEffect(() => {
    if (Number.isFinite(+localYMin) && inputUnit) {
      setLocalTrack((prev) => (prev ? { ...prev, yaxisStart: inputUnit.toSI(localYMin) } : null));
    }
  }, [inputUnit, localYMin]);

  useEffect(() => {
    if (Number.isFinite(+localYMax) && inputUnit) {
      setLocalTrack((prev) => (prev ? { ...prev, yaxisEnd: inputUnit.toSI(localYMax) } : null));
    }
  }, [inputUnit, localYMax]);

  const localYMinError = !Number.isFinite(+localYMin)
    ? "Incorrect value"
    : +localYMin >= +localYMax
      ? `Value should be less than ${localYMax}`
      : null;

  const localYMaxError = !Number.isFinite(+localYMax)
    ? "Incorrect value"
    : +localYMin >= +localYMax
      ? `Value should be more than ${localYMin}`
      : null;

  const hasInputError = localTrack?.isManualYaxis && (!!localYMinError || !!localYMaxError);

  return (
    <PDComponent.Modal
      onCancel={handleClose}
      title="Track Settings"
      footer={
        <Row justify="space-between">
          <Col>
            <Button onClick={handleDelete} loading={isDeleting}>
              Delete
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              disabled={localTrack === undefined || hasInputError}
              onClick={handleSave}
              loading={isSaving}
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      }
      open={visible}
    >
      {localTrack ? (
        <>
          <FormGroup label="Track KPI">
            <Select
              value={localTrack?.trackId?.toString()}
              onChange={(value) => setLocalTrack((prev) => (prev ? { ...prev, trackId: +(value || 0) } : null))}
              placeholder="Select track KPI"
              style={{ width: "100%" }}
            >
              {tracksWithSelfIds.map((trackId) => (
                <Select.Option key={trackId} value={trackId.toString()}>
                  {tracks?.byId[trackId || 0]?.description || "Unnamed"}
                </Select.Option>
              ))}
            </Select>
          </FormGroup>
          <Styled.UnitContainer>
            <FormGroup
              label={
                <CustomIcon>
                  <PDComponent.SvgIcon name="time" />
                  <Typography>Unit of Measure</Typography>
                </CustomIcon>
              }
              rightElement={
                <Col>
                  <Styled.ToggleButton
                    $positionedOnTop
                    onClick={() => {
                      setLocalTrack((prev) => (prev ? { ...prev, isUomUnlocked: !prev.isUomUnlocked } : null));
                    }}
                  >
                    {localTrack.isUomUnlocked ? "Unlocked" : "Locked"}{" "}
                    <span style={{ fontSize: "18px" }}>
                      {localTrack.isUomUnlocked ? (
                        <PDComponent.SvgIcon name="unlocked" />
                      ) : (
                        <PDComponent.SvgIcon name="locked" />
                      )}
                    </span>
                  </Styled.ToggleButton>
                  <RadioButtonGroup
                    value={localTrack.systemOfMeasurementType}
                    onChange={(e) => {
                      setLocalTrack((prev) =>
                        prev
                          ? {
                              ...prev,
                              systemOfMeasurementType: e.target.value,
                            }
                          : null,
                      );
                    }}
                  >
                    {Object.keys(trackUnits ?? {}).map((key) => {
                      const unit = trackUnits[key];
                      return (
                        <Radio.Button key={key} value={transformGlobalUOM(unit.unitSystem || defaultUnitSystem)}>
                          {unit.abv}
                        </Radio.Button>
                      );
                    })}
                  </RadioButtonGroup>
                </Col>
              }
            />
          </Styled.UnitContainer>

          <FormGroup
            label="Y Axis Range"
            rightElement={
              <div>
                <Styled.ToggleButton
                  inactive={localTrack.isManualYaxis}
                  onClick={() => {
                    setLocalTrack((prev) => (prev ? { ...prev, isManualYaxis: false } : null));
                  }}
                >
                  Auto
                </Styled.ToggleButton>
                <Styled.ToggleButton
                  inactive={!localTrack.isManualYaxis}
                  onClick={() => {
                    setLocalTrack((prev) => (prev ? { ...prev, isManualYaxis: true } : null));
                  }}
                >
                  Manual
                </Styled.ToggleButton>
              </div>
            }
          >
            <Styled.Grid>
              <Input
                suffix={inputUnit?.abv}
                disabled={!localTrack.isManualYaxis}
                value={localTrack.isManualYaxis ? localYMin : 0}
                onChange={(e) => setLocalYMin(e.currentTarget.value)}
                error={localTrack.isManualYaxis ? localYMinError || "" : ""}
              />
              <Input
                suffix={inputUnit?.abv}
                disabled={!localTrack.isManualYaxis}
                value={localTrack.isManualYaxis ? localYMax : "Auto"}
                onChange={(e) => setLocalYMax(e.currentTarget.value)}
                error={localTrack.isManualYaxis ? localYMaxError || "" : ""}
              />
            </Styled.Grid>
          </FormGroup>
        </>
      ) : null}
    </PDComponent.Modal>
  );
}
