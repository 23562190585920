/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TvdPhaseDto
 */
export interface TvdPhaseDto {
    /**
     * 
     * @type {number}
     * @memberof TvdPhaseDto
     */
    phasesId: number;
    /**
     * 
     * @type {number}
     * @memberof TvdPhaseDto
     */
    startDynamicDuration: number;
    /**
     * 
     * @type {number}
     * @memberof TvdPhaseDto
     */
    endDynamicDuration: number;
}

/**
 * Check if a given object implements the TvdPhaseDto interface.
 */
export function instanceOfTvdPhaseDto(value: object): value is TvdPhaseDto {
    if (!('phasesId' in value) || value['phasesId'] === undefined) return false;
    if (!('startDynamicDuration' in value) || value['startDynamicDuration'] === undefined) return false;
    if (!('endDynamicDuration' in value) || value['endDynamicDuration'] === undefined) return false;
    return true;
}

export function TvdPhaseDtoFromJSON(json: any): TvdPhaseDto {
    return TvdPhaseDtoFromJSONTyped(json, false);
}

export function TvdPhaseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TvdPhaseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'phasesId': json['PhasesId'],
        'startDynamicDuration': json['StartDynamicDuration'],
        'endDynamicDuration': json['EndDynamicDuration'],
    };
}

export function TvdPhaseDtoToJSON(value?: TvdPhaseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'PhasesId': value['phasesId'],
        'StartDynamicDuration': value['startDynamicDuration'],
        'EndDynamicDuration': value['endDynamicDuration'],
    };
}

