import { SCORECARD_PADDING } from "pages/RigScoreCard/LeftPane/utils";
import styled from "styled-components";

import { Grid, StyledLensGroup } from "./components/style";

export const RigScoreCardContainer = styled.div<{ height: number }>`
  background-color: ${({ theme }) => theme.themeStyle.colors.score_card_bg};
  height: ${({ height }) => height}px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;

export const CardWrapper = styled.div`
  padding: ${SCORECARD_PADDING}px 0px 0px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
`;

export const ChartWrapper = styled.div<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  position: relative;
`;

export const KpiInfoWrapper = styled.div`
  padding: 2px 4px;
  font-size: 14px;
  text-align: left;
`;

export const KpiInfoDetails = styled.span`
  font-size: 12px;
`;

export const LensGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${StyledLensGroup} {
    width: 50%;
  }
  ${Grid} {
    grid-template-columns: 0% calc(50% - 5px) calc(50% - 5px) 0%;
  }
`;
