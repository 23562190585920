import styled, { css } from "styled-components";
import { Checkbox } from "utils/componentLibrary";

export const ListContainer = styled.div`
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
  width: 268px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  border-radius: 4px;
`;

const Row = css`
  padding: 8px 12px;
  font-size: 16px;
`;

export const ListInfo = styled.div`
  ${Row};
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.faint_typography};
`;

export const ListRowsContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

export const CustomCheckbox = styled(Checkbox)`
  transform: translateY(-4px);
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.themeStyle.colors.disabled_typography}60;
    border: ${({ theme }) => theme.themeStyle.colors.disabled_typography}60;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${({ theme }) => theme.themeStyle.colors.disabled_typography}60;
    border: ${({ theme }) => theme.themeStyle.colors.disabled_typography}60;
  }
  border: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const ListRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  padding: 8px 12px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  ${Row};
`;
