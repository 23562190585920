import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useQueryClient } from "@tanstack/react-query";
import { type LensTabWithVisibilityDto, UserLensTabsApi } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { IconContainer } from "icons/styles";
import { truncate } from "lodash";
import { RigSpecificityDropdown } from "pages/WellDashboard/RigSpecificityDropdown/RigSpecificityDropdown";
import React, { useCallback, useEffect, useState } from "react";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import { DeletePrompt } from "./DeletePrompt";
import { InputEdit } from "./InputEdit";
import { LensTabButton, NoWrapText, StyledRigIcon } from "./style";
import { isRigSpecific } from "./utils";

// TODO maybe add some tracking at some point
const userLensTabsApi = new UserLensTabsApi(apiConfig);

export const SortableTab = ({
  tab,
  isEditingTabs,
  isOperatorTab,
  selectedTabId,
  setSelectedTabId,
}: {
  isEditingTabs: boolean;
  isOperatorTab?: boolean;
  tab: LensTabWithVisibilityDto;
  selectedTabId: number | null;
  setSelectedTabId: (tabId: number | null) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: tab.id.toString(),
  });
  const style = {
    transform: transform ? CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 }) : undefined,
    transition,
  };
  const queryClient = useQueryClient();
  // get the width auto based on the useLayoutEffect
  const [locked, setLocked] = useState(tab?.isLocked);
  useEffect(() => {
    setLocked(tab?.isLocked);
  }, [tab?.isLocked]);
  const [tabName, setTabName] = useState(tab?.name ?? "");
  useEffect(() => {
    if (tab?.name) setTabName(tab.name);
  }, [tab?.name]);

  const [isChangingName, setIsChangingName] = useState(false);
  useEffect(() => {
    if (!isEditingTabs) {
      setIsChangingName(false);
    }
  }, [isEditingTabs]);
  const { themeStyle } = useCustomTheme();

  const handleOnTabLockToggle = useCallback(async () => {
    setLocked(!locked);

    await userLensTabsApi.apiUserLensTabsIdLockIsLockedPut({
      id: tab.id,
      isLocked: !locked,
    });

    await queryClient.refetchQueries({ queryKey: [{ type: PDQueryType.USER_LENS_TABS }] });
  }, [locked, queryClient, tab.id]);

  return (
    <div
      key={tab.id.toString()}
      // eslint-disable-next-line react/forbid-dom-props
      style={{ ...style, display: tab.id === selectedTabId && !isEditingTabs ? "" : "flex" }}
      ref={setNodeRef}
    >
      <LensTabButton
        $active={tab.id === selectedTabId && !isEditingTabs}
        $isEditingTabs={isEditingTabs}
        $isOperatorTab={isOperatorTab}
        onClick={
          isEditingTabs
            ? Function.prototype()
            : () => {
                Track.interact("Change Lens Tab", {
                  "Tab Name": tab.name,
                });
                setSelectedTabId(tab.id);
              }
        }
      >
        {isEditingTabs && !isOperatorTab ? (
          <>
            <IconContainer {...attributes} {...listeners}>
              <PDComponent.SvgIcon name="dragTab" />
            </IconContainer>
            <IconContainer
              onClick={handleOnTabLockToggle}
              style={{
                color: locked ? themeStyle.colors.black_white : themeStyle.colors.disabled_typography,
              }}
            >
              {locked ? <PDComponent.SvgIcon name="locked" /> : <PDComponent.SvgIcon name="unlocked" />}
            </IconContainer>

            <RigSpecificityDropdown
              selectedRigs={tab?.rigIds || []}
              onRigIdsSelect={(rigIds) => {
                userLensTabsApi
                  .apiUserLensTabsIdVisibilitiesPut({
                    id: tab.id,
                    lensTabVisibilityUpdateDto: {
                      id: tab.id,
                      rigIds,
                    },
                  })
                  .then(() => {
                    queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.USER_LENS_TABS }] });
                  });
              }}
            >
              <StyledRigIcon isDisabled={!isRigSpecific(tab)} />
            </RigSpecificityDropdown>

            {isChangingName ? (
              <InputEdit
                localTabName={tabName}
                tabId={tab?.id}
                setTabNameCB={(newName) => {
                  setTabName(newName);
                  setIsChangingName(false);
                }}
              />
            ) : (
              <NoWrapText
                onClick={() => {
                  setIsChangingName(true);
                }}
              >
                {tabName}
              </NoWrapText>
            )}
            <DeletePrompt
              onClick={async () => {
                await userLensTabsApi.apiUserLensTabsIdDelete({ id: tab.id });
                await queryClient.refetchQueries({ queryKey: [{ type: PDQueryType.USER_LENS_TABS }], exact: false });
              }}
            />
          </>
        ) : (
          <>
            <IconContainer
              style={{
                color:
                  locked && selectedTabId === tab?.id
                    ? themeStyle.colors.black_white
                    : themeStyle.colors.disabled_typography,
              }}
            >
              {locked && selectedTabId === tab?.id ? <PDComponent.SvgIcon name="locked" /> : null}
            </IconContainer>
            {isRigSpecific(tab) ? (
              <RigSpecificityDropdown isReadOnly selectedRigs={tab?.rigIds || []}>
                <PDComponent.SvgIcon name="rig" />
              </RigSpecificityDropdown>
            ) : null}
            <NoWrapText title={tabName}>{truncate(tabName, { length: 25 })}</NoWrapText>
          </>
        )}
      </LensTabButton>
    </div>
  );
};
