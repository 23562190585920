import { Title } from "atoms/Typography";
import styled from "styled-components";
import { Button as FormButton } from "utils/componentLibrary";

import { HEADER_HEIGHT, LEFT_COLUMN_WIDTH, LEFT_COLUMN_WIDTH_DETAILED, ZT_CHART_HEIGHT } from "./utils/constants";

export const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
`;

export const HeaderContainer = styled.div`
  height: ${HEADER_HEIGHT}px;
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_quaterniary_bg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const Summary = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 12px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 4px;
`;

export const Button = styled(FormButton)`
  display: flex;
  padding: 9px;
  height: auto;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  :hover {
    background: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
  }
`;

export const ZTContainer = styled.div`
  height: ${ZT_CHART_HEIGHT}px;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  display: flex;
`;
export const ZTitle = styled.div<{ $isDetailed: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: ${({ $isDetailed }) => ($isDetailed ? LEFT_COLUMN_WIDTH_DETAILED : LEFT_COLUMN_WIDTH)}px;
  background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
  ${Title} {
    font-size: ${({ $isDetailed }) => ($isDetailed ? "18px !important" : null)};
  }
`;

export const ZTChart = styled.div<{ $leftColumnWidth: number }>`
  width: calc(100% - ${({ $leftColumnWidth }) => $leftColumnWidth}px);
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const SingleTrack = styled.div<{ $height: number }>`
  height: ${({ $height }) => $height}px;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  display: flex;
`;
