import { Title } from "atoms/Typography";
import styled from "styled-components";
import { Space } from "utils/componentLibrary";

export const ControllerBarHolder = styled.div`
  height: 64px;
  background: ${({ theme }) => theme.themeStyle.colors.off_bg};
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.soft_accent};
`;

export const StyledSpace = styled(Space)`
  width: 100%;
`;

export const StyledSpaceLeft = styled(Space)`
  padding-left: 16px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  ${Title} {
    font-size: 20px !important;
  }
  align-items: center;
`;
