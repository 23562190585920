/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClaraMessageSource } from './ClaraMessageSource';
import {
    ClaraMessageSourceFromJSON,
    ClaraMessageSourceFromJSONTyped,
    ClaraMessageSourceToJSON,
} from './ClaraMessageSource';

/**
 * 
 * @export
 * @interface ClaraAnswerDto
 */
export interface ClaraAnswerDto {
    /**
     * 
     * @type {string}
     * @memberof ClaraAnswerDto
     */
    message: string;
    /**
     * 
     * @type {ClaraMessageSource}
     * @memberof ClaraAnswerDto
     */
    source: ClaraMessageSource;
    /**
     * 
     * @type {number}
     * @memberof ClaraAnswerDto
     */
    id: number;
}



/**
 * Check if a given object implements the ClaraAnswerDto interface.
 */
export function instanceOfClaraAnswerDto(value: object): value is ClaraAnswerDto {
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('source' in value) || value['source'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function ClaraAnswerDtoFromJSON(json: any): ClaraAnswerDto {
    return ClaraAnswerDtoFromJSONTyped(json, false);
}

export function ClaraAnswerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaraAnswerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['Message'],
        'source': ClaraMessageSourceFromJSON(json['Source']),
        'id': json['Id'],
    };
}

export function ClaraAnswerDtoToJSON(value?: ClaraAnswerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Message': value['message'],
        'Source': ClaraMessageSourceToJSON(value['source']),
        'Id': value['id'],
    };
}

