import type { ApiParameterHeatmapUserLensesIdFactsPutRequest } from "apis/oag";
import { useParameterHeatmapFacts } from "hooks/lens-facts/useParameterHeatmapFacts";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useFilterParams } from "hooks/useFilterParams";
import { isEqual } from "lodash";
import { useObservable, useObservableGetState } from "observable-hooks";
import { useMemo } from "react";
import { debounceTime, distinctUntilChanged, map } from "rxjs";
import { PARAMETER_LENS_SAMPLE_COUNT_NULL } from "utils/common";

import { getHeatmapDimensionsForParent } from "./utils";

interface ParameterHeatmapFetcherProps {
  height: number;
  width: number;
  lensId: number;
  isDetailed?: boolean;
}

// To prevent network requests while the user is resizing the window. Too high and it will take too much to load
// since the call will be done later, too soon and we might get too many requests. Depends on how fast the user resizes

const DEBOUNCE_DOM_RESIZE_EVENTS_MS = 500;

export function useParameterHeatmapFetcher({ height, width, lensId, isDetailed }: ParameterHeatmapFetcherProps) {
  const [offsetWellsFromDetailed] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);
  const [offsetWellsGlobal] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELL, []);

  const offsetWellIds = useMemo(
    () => (isDetailed ? offsetWellsFromDetailed : offsetWellsGlobal),
    [isDetailed, offsetWellsFromDetailed, offsetWellsGlobal],
  );

  const { xBinCount, yBinCount } = getHeatmapDimensionsForParent({
    parentContainerHeight: height,
    parentContainerWidth: width,
  });

  const binCount$ = useObservable(
    (count$) =>
      count$.pipe(
        debounceTime(DEBOUNCE_DOM_RESIZE_EVENTS_MS),
        map(([x, y]) => ({ x, y })),
        distinctUntilChanged(isEqual),
      ),
    [xBinCount, yBinCount],
  );

  const binsCountRecord = useObservableGetState(binCount$, { x: 0, y: 0 });

  const hasValidBinCountRequest = useMemo(
    () => binsCountRecord.x > 0 && binsCountRecord.y > 0,
    [binsCountRecord.x, binsCountRecord.y],
  );

  const filterParams = useFilterParams();

  const requestParameters: ApiParameterHeatmapUserLensesIdFactsPutRequest = useMemo(
    () => ({
      id: lensId ?? -1,
      parameterHeatmapUserLensQueryDto: {
        ...filterParams,
        xBinCount: binsCountRecord.x || 1,
        yBinCount: binsCountRecord.y || 1,
        comparisonWellIds: [...offsetWellIds],
        sampleCount: PARAMETER_LENS_SAMPLE_COUNT_NULL,
        wellAggregationThreshold: 6,
      },
    }),
    [binsCountRecord?.x, binsCountRecord?.y, filterParams, lensId, offsetWellIds],
  );

  const heatmapBinsRequest = useParameterHeatmapFacts(lensId, requestParameters, {
    enabled: hasValidBinCountRequest,
  });

  return heatmapBinsRequest;
}
