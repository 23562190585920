import { createGlobalStyle, css } from "styled-components";
import colors from "utils/colors";
import { responsiveBreakpoints } from "utils/common";
import { zIndexLayer } from "utils/zIndex";

import { SCROLLBAR_WIDTH_PX } from "./components/Layout/LayoutUtils";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.themeStyle.colors.primary_bg}
  }

  @media screen and (max-width: ${responsiveBreakpoints.sm}px) {
  body, #root {
    overflow: hidden;
  }
}

  .ant-select-item-option {
    padding: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.separation_line};
  }
  .ant-select-open .ant-select-arrow {
    transform: rotate(180deg)!important;
  }
  .ant-tooltip-inner {
    background: ${colors.neutral};
    padding: 10px;
    border-radius: 6px;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.white};
  }
  
  div[data-theme="Dark"]{
    min-height: 100vh;
    background: ${colors.gluon_grey};
    .ant-result-title{
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography}
    }
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-divider {
    border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_wedge_bg};
  }

  .ant-table-small.ant-table-fixed-header{
    .ant-table-thead > tr > th {
      border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_chart_accent};
      border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_chart_accent};
    }
  }
  .ant-progress-circle .ant-progress-text {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography}
  }

  .ant-btn {
    > .pd-icon + span,
    > span + .pd-icon {
      margin-left: 8px;
    }
  }

  .ant-notification {
    .ant-notification-notice {
      border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
      padding: 10px;
      width: unset !important;
    }
    .ant-notification-notice-content {
      height: 32px;
    }
    .ant-notification-notice-icon {
      font-size: 32px;
      line-height: 32px;
      margin-left: 0;
    }
    .ant-notification-notice-message {
      color: white !important;
      margin-bottom: 0 !important;
      margin-right: 16px;
      line-height: 32px;
    }
    .ant-notification-notice-close{
      color: #828c91 !important;
      top:18px;
    }
  }

  .all-wells-autocomplete {
    .ant-select-item {
      height: 40px;
      align-items: center;
      color: ${colors.gray};
      border-bottom: solid 1px ${({ theme }) => theme.themeStyle.colors.primary_accent};

      &.ant-select-item-option-active {
        background-color: unset;
        color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
      }
      .ant-select-item-option-content {
        white-space: normal!important;
        overflow: auto!important;
        line-height: 20px;
      }
    }
  }

  *::-webkit-scrollbar {
    width: ${SCROLLBAR_WIDTH_PX}px;
    height: ${SCROLLBAR_WIDTH_PX}px;
    border-radius: 4px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-track-piece, *::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => (theme.isDark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)")};
    border-radius: 4px;
  }

  .ant-select-dropdown {
    background: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }

  .ant-popover {
    z-index: ${zIndexLayer.jupiter};
  }

  .ant-dropdown {
    z-index: ${zIndexLayer.mars};
  }
      
  .ant-checkbox-wrapper {
    &, span, label {
      color: ${({ theme }) => theme.themeStyle.colors.disabled_typography} !important;
    } 

    &.ant-checkbox-wrapper-checked{
      &, span, label {
        color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
      }
    }
  }

  .ant-radio-wrapper {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography}
  }

  .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    background: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
    span{
      color: ${({ theme }) => theme.themeStyle.colors.disabled_typography} !important;
    }
  }

  .ant-picker-input {
    color: ${colors.gray};
    box-shadow: none !important;
  }

  .ant-picker {
    background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
    .ant-picker-input:hover .ant-picker-clear {
      background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
    }
    .ant-picker-input input {
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    }
    .ant-picker-suffix,
    .anticon-close-circle {
      color: ${({ theme }) => theme.themeStyle.colors.light_typography};
    }
  }
  
  .ant-picker-cell-inner {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    background: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_accent} !important;
  }

  .ant-picker-panel-container .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover{
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_accent} !important;
  }

  ${({ theme }) =>
    theme.isDark
      ? css`
          .rc-virtual-list-scrollbar-thumb {
            background-color: ${({ theme }) =>
              theme.isDark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)"} !important;
          }
          .ant-select-item-option {
            color: ${colors.necron_compound};
          }

          .rdrCalendarWrapper {
            background-color: ${theme.themeStyle.colors.tertiary_bg} !important;
            color: ${theme.themeStyle.colors.primary_typography};
          }

          .ant-radio-button-wrapper {
            border-left-width: 1px !important;

            &:hover {
              background-color: ${theme.themeStyle.colors.secondary_bg};
            }
          }

          .ant-radio-button-wrapper, .ant-radio-button {
            background-color: ${theme.themeStyle.colors.secondary_bg};
            border-color: ${theme.themeStyle.colors.primary_accent} !important;
            color: ${theme.themeStyle.colors.primary_typography};
          }

          .ant-select-item {
            background: ${theme.themeStyle.colors.secondary_bg};

            &:hover {
              background:${theme.themeStyle.colors.primary_bg} !important;
            }
          }
          .ant-select-item-option:not(.ant-select-item-option-disabled),
          .ant-select-selection-item {
            color: ${theme.themeStyle.colors.primary_typography};
          }
          .ant-select-item-option-active:not(.ant-select-item-option-disabled){
            background: ${theme.themeStyle.colors.secondary_bg};
          }
          .ant-radio-button-wrapper:not(:first-child)::before {
            display:none;
          }
          
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            color: ${theme.themeStyle.colors.disabled_typography};
            background:${theme.themeStyle.colors.primary_bg} !important;
          }

          .ant-select-disabled {
            span {
              color: ${theme.themeStyle.colors.disabled_typography};
            }
          }

          .ant-select:not(.ant-select-disabled) {
            .ant-select-selector {
              background: ${colors.jaguar_gray} !important;
            }
          }

          .ant-select {
            .ant-select-selector {
              border-color: ${colors.limo_scene} !important;
              color: ${theme.themeStyle.colors.disabled_typography} !important;
              background: ${theme.themeStyle.colors.alt_tertiary_bg} !important;
            }
          }

          .ant-input {
            border-color: ${colors.limo_scene} !important;
            background: ${colors.jaguar_gray} !important;

            &.ant-input-disabled {
              color: ${theme.themeStyle.colors.disabled_typography} !important;
              background: ${theme.themeStyle.colors.alt_tertiary_bg} !important;
            }
          }

          .ant-select-clear {
            background: ${colors.black} !important;
            color: ${theme.themeStyle.colors.primary_typography} !important;
          }

          .ant-radio-button-wrapper-disabled:first-child, .ant-radio-button-wrapper-disabled:hover {
              background-color: transparent;
          }

          .ant-btn-ghost[disabled], .ant-btn-ghost[disabled]:hover {
            background-color: transparent;
            border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
          }
          
          .ant-table-thead > tr > th {
            color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
            border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
            border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
            
          }

          .ant-table .ant-table-tbody > tr > td {
            color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
          }

          .ant-btn-circle.ant-btn-lg
            border: 1px solid #4a4a50;
          }

          .ant-tabs-top > .ant-tabs-nav::before {
            border-bottom: 0;
          }

          .ant-menu-vertical { 
            border-color: ${theme.themeStyle.colors.primary_accent};
          }

          .ant-menu {
            background-color:${theme.themeStyle.colors.primary_accent};
          }

          .ant-popover-inner {
            background-color:${theme.themeStyle.colors.quaterniary_bg};
          }
          .ant-picker-panel-container {
            background-color: ${theme.themeStyle.colors.tertiary_bg};
            * {
              border-color: ${theme.themeStyle.colors.primary_accent} !important;
            }

            &, span, button, th, div,
            .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
              color: ${theme.themeStyle.colors.primary_typography};
            }
            .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
              color: ${colors.black}
            }
          }

          .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner, .ant-radio-inner {
            background-color: ${colors.soft_white} !important;
          }

          .ant-progress-circle-trail {
            stroke: ${theme.themeStyle.colors.primary_accent};
          }
        `
      : css`
          .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background: none !important;
          .ant-select-item-option {
            color: ${colors.gray};
          }
        `}
`;
