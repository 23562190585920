/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface WellRangesDto
 */
export interface WellRangesDto {
    /**
     * 
     * @type {number}
     * @memberof WellRangesDto
     */
    id: number;
    /**
     * 
     * @type {ResultDataState}
     * @memberof WellRangesDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {number}
     * @memberof WellRangesDto
     */
    startCumulativeDuration: number;
    /**
     * 
     * @type {number}
     * @memberof WellRangesDto
     */
    endCumulativeDuration: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WellRangesDto
     */
    startAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellRangesDto
     */
    endAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof WellRangesDto
     */
    startHoleDepth: number;
    /**
     * 
     * @type {number}
     * @memberof WellRangesDto
     */
    endHoleDepth: number;
}



/**
 * Check if a given object implements the WellRangesDto interface.
 */
export function instanceOfWellRangesDto(value: object): value is WellRangesDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('startCumulativeDuration' in value) || value['startCumulativeDuration'] === undefined) return false;
    if (!('endCumulativeDuration' in value) || value['endCumulativeDuration'] === undefined) return false;
    if (!('startAt' in value) || value['startAt'] === undefined) return false;
    if (!('endAt' in value) || value['endAt'] === undefined) return false;
    if (!('startHoleDepth' in value) || value['startHoleDepth'] === undefined) return false;
    if (!('endHoleDepth' in value) || value['endHoleDepth'] === undefined) return false;
    return true;
}

export function WellRangesDtoFromJSON(json: any): WellRangesDto {
    return WellRangesDtoFromJSONTyped(json, false);
}

export function WellRangesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellRangesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'startCumulativeDuration': json['StartCumulativeDuration'],
        'endCumulativeDuration': json['EndCumulativeDuration'],
        'startAt': DateDtoFromJSON(json['StartAt']),
        'endAt': DateDtoFromJSON(json['EndAt']),
        'startHoleDepth': json['StartHoleDepth'],
        'endHoleDepth': json['EndHoleDepth'],
    };
}

export function WellRangesDtoToJSON(value?: WellRangesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'DataState': ResultDataStateToJSON(value['dataState']),
        'StartCumulativeDuration': value['startCumulativeDuration'],
        'EndCumulativeDuration': value['endCumulativeDuration'],
        'StartAt': DateDtoToJSON(value['startAt']),
        'EndAt': DateDtoToJSON(value['endAt']),
        'StartHoleDepth': value['startHoleDepth'],
        'EndHoleDepth': value['endHoleDepth'],
    };
}

