/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BoilerFuelFlowRateFactDto } from './BoilerFuelFlowRateFactDto';
import {
    BoilerFuelFlowRateFactDtoFromJSON,
    BoilerFuelFlowRateFactDtoFromJSONTyped,
    BoilerFuelFlowRateFactDtoToJSON,
} from './BoilerFuelFlowRateFactDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface BoilerFuelFlowRateResultDto
 */
export interface BoilerFuelFlowRateResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof BoilerFuelFlowRateResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof BoilerFuelFlowRateResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof BoilerFuelFlowRateResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof BoilerFuelFlowRateResultDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {Array<BoilerFuelFlowRateFactDto>}
     * @memberof BoilerFuelFlowRateResultDto
     */
    boiler1DieselFacts?: Array<BoilerFuelFlowRateFactDto> | null;
    /**
     * 
     * @type {Array<BoilerFuelFlowRateFactDto>}
     * @memberof BoilerFuelFlowRateResultDto
     */
    boiler2DieselFacts?: Array<BoilerFuelFlowRateFactDto> | null;
    /**
     * 
     * @type {Array<BoilerFuelFlowRateFactDto>}
     * @memberof BoilerFuelFlowRateResultDto
     */
    boilerGasFacts?: Array<BoilerFuelFlowRateFactDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof BoilerFuelFlowRateResultDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the BoilerFuelFlowRateResultDto interface.
 */
export function instanceOfBoilerFuelFlowRateResultDto(value: object): value is BoilerFuelFlowRateResultDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function BoilerFuelFlowRateResultDtoFromJSON(json: any): BoilerFuelFlowRateResultDto {
    return BoilerFuelFlowRateResultDtoFromJSONTyped(json, false);
}

export function BoilerFuelFlowRateResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoilerFuelFlowRateResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'boiler1DieselFacts': json['Boiler1DieselFacts'] == null ? undefined : ((json['Boiler1DieselFacts'] as Array<any>).map(BoilerFuelFlowRateFactDtoFromJSON)),
        'boiler2DieselFacts': json['Boiler2DieselFacts'] == null ? undefined : ((json['Boiler2DieselFacts'] as Array<any>).map(BoilerFuelFlowRateFactDtoFromJSON)),
        'boilerGasFacts': json['BoilerGasFacts'] == null ? undefined : ((json['BoilerGasFacts'] as Array<any>).map(BoilerFuelFlowRateFactDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function BoilerFuelFlowRateResultDtoToJSON(value?: BoilerFuelFlowRateResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'Boiler1DieselFacts': value['boiler1DieselFacts'] == null ? undefined : ((value['boiler1DieselFacts'] as Array<any>).map(BoilerFuelFlowRateFactDtoToJSON)),
        'Boiler2DieselFacts': value['boiler2DieselFacts'] == null ? undefined : ((value['boiler2DieselFacts'] as Array<any>).map(BoilerFuelFlowRateFactDtoToJSON)),
        'BoilerGasFacts': value['boilerGasFacts'] == null ? undefined : ((value['boilerGasFacts'] as Array<any>).map(BoilerFuelFlowRateFactDtoToJSON)),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

