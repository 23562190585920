/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TvdSectionDto
 */
export interface TvdSectionDto {
    /**
     * 
     * @type {number}
     * @memberof TvdSectionDto
     */
    sectionId: number;
    /**
     * 
     * @type {number}
     * @memberof TvdSectionDto
     */
    startDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TvdSectionDto
     */
    endDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TvdSectionDto
     */
    startDynamicDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TvdSectionDto
     */
    endDynamicDepth: number;
}

/**
 * Check if a given object implements the TvdSectionDto interface.
 */
export function instanceOfTvdSectionDto(value: object): value is TvdSectionDto {
    if (!('sectionId' in value) || value['sectionId'] === undefined) return false;
    if (!('startDepth' in value) || value['startDepth'] === undefined) return false;
    if (!('endDepth' in value) || value['endDepth'] === undefined) return false;
    if (!('startDynamicDepth' in value) || value['startDynamicDepth'] === undefined) return false;
    if (!('endDynamicDepth' in value) || value['endDynamicDepth'] === undefined) return false;
    return true;
}

export function TvdSectionDtoFromJSON(json: any): TvdSectionDto {
    return TvdSectionDtoFromJSONTyped(json, false);
}

export function TvdSectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TvdSectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sectionId': json['SectionId'],
        'startDepth': json['StartDepth'],
        'endDepth': json['EndDepth'],
        'startDynamicDepth': json['StartDynamicDepth'],
        'endDynamicDepth': json['EndDynamicDepth'],
    };
}

export function TvdSectionDtoToJSON(value?: TvdSectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'SectionId': value['sectionId'],
        'StartDepth': value['startDepth'],
        'EndDepth': value['endDepth'],
        'StartDynamicDepth': value['startDynamicDepth'],
        'EndDynamicDepth': value['endDynamicDepth'],
    };
}

