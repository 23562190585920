import { Title } from "atoms/Typography";
import { ListItemContainer, StyledGroupedList, StyledTitleContainer } from "components/PDComponents/Search/style";
import type { IAllowedOptions, IOption } from "components/PDComponents/Search/utils";
import { useCallback } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Checkbox } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

export const GroupedOptions = <T extends IAllowedOptions>({
  filteredOptions,
  onChange,
  tempSelOptions,
}: {
  filteredOptions: IOption<T>[];
  tempSelOptions: T[];
  onChange: (newOptions: T[]) => void;
}) => {
  const groupedOptions = filteredOptions.reduce<Record<string, IOption<T>[]>>((acc, item) => {
    if (item.groupId) {
      if (acc[item.groupId]) {
        acc[item.groupId].push(item);
      } else {
        acc[item.groupId] = [item];
      }
    }
    return acc;
  }, {});

  const isAllChecked = useCallback(
    (options: IOption<T>[]) => {
      return options.every((option) => tempSelOptions.includes(option.id));
    },
    [tempSelOptions],
  );

  const { atomThemeVariant } = useCustomTheme();

  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <StyledGroupedList $height={Number.isNaN(height) ? "100%" : `${height}px`} $width={width}>
          {Object.entries(groupedOptions).map(([groupId, options]) => (
            <div key={groupId}>
              <ListItemContainer>
                <Checkbox
                  checked={isAllChecked(options)}
                  indeterminate={
                    !isAllChecked(options) && tempSelOptions.some((e) => options.map((option) => option.id).includes(e))
                  }
                  onChange={() => {
                    if (isAllChecked(options)) {
                      onChange(tempSelOptions.filter((e: T) => !options.map((option) => option.id).includes(e)));
                    } else {
                      onChange([...new Set([...tempSelOptions, ...options.map((option) => option.id)])]);
                    }
                  }}
                ></Checkbox>
              </ListItemContainer>
              {options.map((item) => (
                <ListItemContainer key={item.id} $marginLeft={25}>
                  <Checkbox
                    value={item.id}
                    checked={tempSelOptions.includes(item.id)}
                    onChange={(e) => {
                      const newData = e.target.checked
                        ? [...(tempSelOptions ?? []), item.id]
                        : (tempSelOptions ?? []).filter((e: unknown) => e !== item.id);
                      onChange(newData);
                    }}
                  >
                    <StyledTitleContainer>
                      <Title level={3} variant={tempSelOptions.includes(item.id) ? atomThemeVariant : "faded"}>
                        {item.name}
                      </Title>
                      <Title level={3} variant="faded">
                        {item.description}
                      </Title>
                    </StyledTitleContainer>
                  </Checkbox>
                </ListItemContainer>
              ))}
            </div>
          ))}
        </StyledGroupedList>
      )}
    </AutoSizer>
  );
};
