import { Input } from "atoms/Form";
import { EllipsisTextCSS } from "commonStyles";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Switch } from "utils/componentLibrary";

export const GridLayoutRow = styled.div<{
  $bordered?: boolean;
  $accepted?: boolean;
  $disabled?: boolean;
  $isPendingDelete?: boolean;
}>`
  display: grid;
  grid-template-columns: 2fr 6fr 5fr 2fr 1.5fr 1fr;
  column-gap: 10px;
  width: 100%;
  transition: all 0.5s;
  div {
    overflow: hidden;
  }

  ${({ $isPendingDelete }) =>
    $isPendingDelete
      ? css`
          background-color: ${colors.error}1A;
          text-decoration: line-through;
          text-decoration-color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
        `
      : null}

  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: 0.5;
          user-select: none;
          cursor: default;
        `
      : null};

  ${({ $accepted }) =>
    $accepted
      ? css`
          background-color: ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};
        `
      : null};

  ${({ $bordered, theme }) =>
    $bordered &&
    css`
      border-top: 1px solid ${theme.themeStyle.colors.primary_accent};
      padding: 2px 0;
    `}
`;

export const HeaderCol = styled.div<{ $isCentered?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  text-align: ${({ $isCentered }) => ($isCentered ? "center" : "left")};
  color: ${({ theme }) => theme.themeStyle.colors.faint_typography};
`;

export const CustomRow = styled.div`
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  padding: 2px 0;
`;

export const CustomCol = styled.div<{
  $isCentered?: boolean;
  $isSpaceBetween?: boolean;
  $isDisabled?: boolean;
  $paddingLeft?: number;
}>`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  padding-left: ${({ $paddingLeft }) => $paddingLeft || 0}px;
  ${({ $isCentered, $isSpaceBetween }) =>
    $isCentered
      ? css`
          justify-content: center;
        `
      : $isSpaceBetween
        ? css`
            justify-content: space-between;
          `
        : null}

  ${({ $isDisabled }) =>
    $isDisabled
      ? css`
          opacity: 0.7;
          user-select: none;
          cursor: default;
        `
      : null}
`;

export const IconShell = styled.div<{
  $color: string;
  $bgColor: string;
  $hoverBg?: string;
  $hoverColor?: string;
  $disabled?: boolean;
  $bordered?: boolean;
}>`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 100%;
  ${({ $bordered, theme }) =>
    $bordered
      ? css`
          border: 1px solid ${theme.themeStyle.colors.black_white}66;
        `
      : null}

  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};

  &:hover {
    cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
    background-color: ${({ $hoverBg, $bgColor }) => ($hoverBg ? $hoverBg : $bgColor)};
    color: ${({ $hoverColor, $color }) => ($hoverColor ? $hoverColor : $color)};
  }
`;

export const DraggableIcon = styled.div<{ $isOver: boolean; $isDragging: boolean; $isDisabled?: boolean }>`
  cursor: ${({ $isDragging }) => ($isDragging ? "grabbing" : "grab")};
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : undefined)};
`;

export const LowOpacityChildren = styled.div`
  width: 100%;
  height: 100%;
  > * {
    opacity: 0.5;
  }
`;

export const CustomInput = styled(Input)`
  height: 20px;
  width: 250px;
  font-size: 14px;
`;

export const Ellipsis = styled.span`
  ${EllipsisTextCSS};
`;

export const StyledSwitch = styled(Switch)<{ $isChecked?: boolean }>`
  ${({ theme, $isChecked }) =>
    theme.isDark && !$isChecked
      ? css`
          background-color: ${colors.widget_line};
        `
      : null}
`;
