import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";

export function useVisibleTracks<T extends { wellId: number }>(tracks: Array<T>) {
  const [focalTrack, comparisonTracks] = [tracks.slice(tracks.length - 1), tracks.slice(0, tracks.length - 1)];

  const [offsetSelection] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);

  return [...focalTrack, ...comparisonTracks]
    .sort((a, b) => {
      return offsetSelection.indexOf(a.wellId) - offsetSelection.indexOf(b.wellId);
    })
    .slice(0, 5);
}
