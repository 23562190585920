import styled from "styled-components";

const StyledSvg = styled.div`
  height: 100px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

function Error404Svg() {
  return (
    <StyledSvg>
      <svg
        width="175px"
        height="74px"
        viewBox="0 0 175 74"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
          <path d="M36.363,69 L36.363,53.562 L51.615,53.562 L51.615,48.912 L36.363,48.912 L36.363,4.458 L31.248,4.458 L0,48.912 L0,53.562 L31.248,53.562 L31.248,69 L36.363,69 Z M31.248,48.912 L6.324,48.912 L31.248,13.479 L31.248,48.912 Z"></path>
          <path d="M158.363,69 L158.363,53.562 L173.615,53.562 L173.615,48.912 L158.363,48.912 L158.363,4.458 L153.248,4.458 L122,48.912 L122,53.562 L153.248,53.562 L153.248,69 L158.363,69 Z M153.248,48.912 L128.324,48.912 L153.248,13.479 L153.248,48.912 Z"></path>
          <g transform="translate(48.505500, 0.000000)">
            <path d="M69.3751734,7.89510625 L66.1048937,4.625 L35.3650625,35.3650625 C34.4886893,36.2724387 34.5012226,37.7147585 35.393232,38.606768 C36.2852415,39.4987774 37.7275613,39.5113107 38.6349375,38.6349375 L48.3011875,28.9705375 C52.0008219,34.1884881 51.6506915,41.2601612 47.4537159,46.0871374 C43.2567404,50.9141136 36.3023192,52.2434656 30.6209178,49.3047616 C24.9395164,46.3660576 22.0058434,39.9220903 23.5201218,33.7074895 C25.0344003,27.4928887 30.6035725,23.1207641 37,23.125 L37,18.5 C28.3769497,18.4881862 20.8860278,24.4275559 18.9313926,32.8261593 C16.9767574,41.2247626 21.0753561,49.8614004 28.8174883,53.6583105 C36.5596205,57.4552206 45.8977055,55.4082249 51.3415774,48.7208191 C56.7854494,42.0334134 56.8953541,32.4742323 51.606675,25.6634312 L58.1896687,19.0804375 C62.4471787,24.0779726 64.7744254,30.4348533 64.75,37 C64.75,52.3259018 52.3259018,64.75 37,64.75 C21.6740982,64.75 9.25,52.3259018 9.25,37 C9.25,21.6740982 21.6740982,9.25 37,9.25 L37,4.625 C19.1197812,4.625 4.625,19.1197812 4.625,37 C4.625,54.8802188 19.1197812,69.375 37,69.375 C54.8802188,69.375 69.3751734,54.8802188 69.3751734,37 C69.4006196,29.2091701 66.5870906,21.6758442 61.4607,15.809175 L69.3751734,7.89510625 Z"></path>
          </g>
        </g>
      </svg>
    </StyledSvg>
  );
}

export default Error404Svg;
