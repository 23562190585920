import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Bar } from "@visx/shape";
import { DimensionType, type ParameterByDepthTrackValuesDto } from "apis/oag";
import { useTrackRanges } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackRanges";
import type { ParameterByDepthTrack, ParameterComparisonItemKPIProps } from "components/Lenses/interfaces";
import { max } from "d3-array";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import React, { useDeferredValue, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useState } from "react";
import colors from "utils/colors";
import { adjustDecimalPlaces, getDecimalsInterval, useUOM } from "utils/format";
import { formatTime, truncateMiddleString } from "utils/helper";
import { useColors } from "utils/useColors";

import { returnZeroIfInvalid } from "./RoadmapColoredSegment";
import { TooltipContainer, TooltipEntry, TooltipExtra, TooltipWellColor } from "./style";
import { bisectDepth } from "./utils";

export type TTooltipFWRef = {
  setPointerPosition: (position: { x: number; y: number }) => void;
  setChartHeight: (height: number) => void;
};

export const ParameterByDepthTooltip = React.forwardRef(
  (
    {
      focalWellColor,
      isTooltipEnabled,
      isPointerInsideChart,
      detailed,
      isRoadmap,
      depthScale,
      tracks,
      showGraph,
      onTooltipToggle,
      svgRef,
      selectedUnit,
      maxChartHeight,
      yMin,
      chartWidth,
      leftOffset,
      yMax,
      index,
    }: Omit<ParameterComparisonItemKPIProps, "onPositionChange"> & {
      onTooltipToggle: () => void;
      leftOffset: number;
      isPointerInsideChart: boolean;
      isTooltipEnabled: boolean;
      svgRef?: React.RefObject<SVGSVGElement>;
      index: number;
    },
    ref: React.Ref<TTooltipFWRef>,
  ) => {
    const tracksWithRanges = useTrackRanges(tracks);
    const [offsetSelection] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);
    const [isInView, setIsInView] = useState<boolean>(false);

    useLayoutEffect(() => {
      let observer: IntersectionObserver;
      setTimeout(() => {
        if (!svgRef?.current) return;
        const viewContainer = [...document.querySelectorAll(".lens-container")].find(
          (container) => svgRef?.current && container.contains(svgRef?.current),
        );
        const options = {
          root: viewContainer,
          rootMargin: "0px",
          threshold: 0.5,
        };
        observer = new IntersectionObserver((entries) => {
          setIsInView(entries.every((entry) => entry.isIntersecting));
        }, options);
        observer.observe(svgRef?.current as Element);
      }, 600);
      return () => {
        if (observer) observer.disconnect();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sortedTracks = useMemo(
      () =>
        tracksWithRanges.slice().sort((a, b) => {
          return offsetSelection.indexOf(a.wellId) - offsetSelection.indexOf(b.wellId);
        }),
      [offsetSelection, tracksWithRanges],
    );
    const [pointerPosition, setPointerPosition] = useState<{ x: number; y: number }>({
      x: 0,
      y: 0,
    });

    const [chartHeight, setChartHeight] = useState<number>(maxChartHeight);
    useImperativeHandle(ref, () => ({
      setPointerPosition,
      setChartHeight,
    }));

    const { data: wellData } = useWellShortInfoSuspended();
    const depthUOM = useUOM(DimensionType.Metres);
    const { setColor, getColor } = useColors();

    useEffect(() => {
      sortedTracks.forEach((track) => {
        setColor({ key: track.wellId.toString() });
      });
    }, [sortedTracks, setColor]);

    // Visible always in detailed view and only when pointer inside chart in mini view
    const isTooltipVisible = isTooltipEnabled && isInView && (detailed || isPointerInsideChart);
    const margin = useMemo(
      () =>
        detailed && chartHeight > 165
          ? { top: 24, bottom: 24, left: 0, right: 0 }
          : { top: 12, bottom: 12, left: 0, right: 0 },
      [chartHeight, detailed],
    );

    const valueScale = useMemo(() => {
      const maxRange = chartHeight - margin.top - margin.bottom;

      let values = tracks.flatMap((track) => track.trackValues?.map((item) => item.value ?? 0) ?? []);

      if (isRoadmap) {
        values = values.concat(
          tracks.flatMap(
            (track) => track.boundaries?.flatMap((boundary) => [boundary.max ?? 0, boundary.min ?? 0]) ?? 0,
          ),
        );
      }

      const minValue = yMin ?? 0;
      const maxValue = yMax ?? max(values) ?? 0;

      return scaleLinear<number>({
        range: [maxRange, 0],
        domain: [minValue, maxValue],
      });
    }, [chartHeight, isRoadmap, margin.bottom, margin.top, tracks, yMax, yMin]);

    const visibleTrackValues = useMemo(() => {
      const [maxRange, minRange] = valueScale.range();

      return sortedTracks.reduce(
        (acc, track) => {
          acc[track.wellId] = track.trackValues.filter((point) => {
            const value = valueScale(point.value ?? 0);
            return value > minRange && value < maxRange + margin.top + margin.bottom;
          });
          return acc;
        },
        {} as Record<number, ParameterByDepthTrackValuesDto[]>,
      );
    }, [margin.bottom, margin.top, sortedTracks, valueScale]);

    const tooltipInfoSync = useMemo(() => {
      const x0 = depthScale.invert(pointerPosition.x);
      let eyeTrack: ParameterByDepthTrack | null = null;
      const tooltipData: Record<string, ParameterByDepthTrackValuesDto & { top: number; left: number }> = {};

      for (const track of sortedTracks) {
        if (!track.trackId) {
          continue;
        }

        const trackValues = visibleTrackValues[track.wellId];

        if (!trackValues) {
          continue;
        }

        const index = bisectDepth(trackValues, x0);
        if (index > 0 && Number.isFinite(trackValues[index]?.value)) {
          // First track is the one the eye icon will be displayed
          if (eyeTrack === null) {
            eyeTrack = track;
          }

          const d0 = trackValues[index - 1];
          const d1 = trackValues[index];

          const data = x0 - (d0.holeDepth ?? 0) > (d1.holeDepth ?? 0) - x0 ? d1 : d0;
          const top = valueScale(data.value ?? 0) + margin.top;
          const left = depthScale(data.holeDepth ?? 0);

          tooltipData[track.wellId] = { ...data, top, left };
        }
      }

      if (!eyeTrack || !svgRef?.current) {
        return null;
      }
      const { top, left, holeDepth, timestamp } = tooltipData[eyeTrack.wellId];

      const containerRect = svgRef.current.getBoundingClientRect();
      if (!detailed && containerRect.top + containerRect.height > window.innerHeight + 20) return null;
      const pageX = left + window.scrollX;

      // was top + containerRect.top but because of overflowing we fixed the top position
      const pageY = containerRect.top + window.scrollY;

      const selectedBoundary = !sortedTracks?.length
        ? null
        : tracks[0]?.boundaries?.find((b) => (b.measuredDepth ?? 0) >= (holeDepth ?? 0));

      return { top, left, pageX, pageY, holeDepth, timestamp, data: tooltipData, selectedBoundary };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depthScale, detailed, margin.top, pointerPosition.x, sortedTracks, tracks, valueScale, visibleTrackValues]);

    const tooltipInfo = useDeferredValue(tooltipInfoSync);

    const focalTrack = tracks?.find((track) => track.isFocalWell);

    const displayScaleDomain = [
      parseFloat(selectedUnit?.toString(valueScale.domain()[1])),
      parseFloat(selectedUnit?.toString(valueScale.domain()[0])),
    ];
    const actualValue = adjustDecimalPlaces(
      selectedUnit?.toString(tooltipInfo?.data[focalTrack?.wellId || 0]?.value ?? 0),
      getDecimalsInterval(displayScaleDomain),
    );

    return (
      <div
        // eslint-disable-next-line react/forbid-dom-props
        style={{
          position: "absolute",
          top: index * (chartHeight + 1),
          left: leftOffset,
          width: chartWidth,
          height: chartHeight,
          pointerEvents: "none",
        }}
      >
        {tooltipInfo && Number.isFinite(tooltipInfo.top) && (isPointerInsideChart || detailed) ? (
          <svg style={{ width: "100%", height: "100%", pointerEvents: "none", position: "absolute", top: 0 }}>
            <Group cursor="pointer" onClick={onTooltipToggle}>
              <circle fill={focalWellColor} fillOpacity={0.4} cx={tooltipInfo.left} cy={tooltipInfo.top} r={12} />
              <circle fill="black" cx={tooltipInfo.left} cy={tooltipInfo.top} r={9} />

              {isTooltipVisible ? (
                <circle cx={tooltipInfo.left} cy={tooltipInfo.top} r={2.5} fill="white" />
              ) : (
                <svg
                  style={{ fontSize: "10px", color: "white" }}
                  focusable="false"
                  preserveAspectRatio="xMidYMid meet"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  width="1em"
                  height="1em"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                  x={tooltipInfo.left - 5}
                  y={tooltipInfo.top - 5}
                >
                  <circle cx="16" cy="16" r="4" />
                  <path d="M30.94,15.66A16.69,16.69,0,0,0,16,5,16.69,16.69,0,0,0,1.06,15.66a1,1,0,0,0,0,.68A16.69,16.69,0,0,0,16,27,16.69,16.69,0,0,0,30.94,16.34,1,1,0,0,0,30.94,15.66ZM16,22.5A6.5,6.5,0,1,1,22.5,16,6.51,6.51,0,0,1,16,22.5Z" />
                </svg>
              )}
            </Group>
          </svg>
        ) : null}
        {isTooltipVisible && showGraph && tooltipInfo ? (
          <TooltipContainer key={Math.random()} left={tooltipInfo.pageX} unstyled top={-10}>
            {isRoadmap && !detailed ? (
              <TooltipEntry>
                <div>
                  {focalTrack?.wellId && tooltipInfo.data[focalTrack?.wellId] ? (
                    <strong>{(actualValue || "") + " " + selectedUnit?.abv}</strong>
                  ) : (
                    <strong>N/A</strong>
                  )}
                </div>
                <div>
                  {tooltipInfo.selectedBoundary?.min && Number.isFinite(tooltipInfo.selectedBoundary?.min)
                    ? selectedUnit?.toString(tooltipInfo.selectedBoundary.min) + " " + selectedUnit?.abv
                    : "N/A"}{" "}
                  -{" "}
                  {tooltipInfo.selectedBoundary?.max && Number.isFinite(tooltipInfo.selectedBoundary?.max)
                    ? selectedUnit?.toString(tooltipInfo.selectedBoundary.max) + " " + selectedUnit?.abv
                    : "N/A"}
                </div>
              </TooltipEntry>
            ) : (
              sortedTracks.map((track) => {
                const tooltipData = tooltipInfo.data[track.wellId];
                const wellName = wellData?.byId[track.wellId]?.name;

                const formattedName =
                  sortedTracks.length === 1 ? "Actual" : wellName ? truncateMiddleString(wellName, 20) : "Comparison";

                return (
                  <TooltipEntry key={track.wellId}>
                    {sortedTracks.length > 1 && (
                      <TooltipWellColor
                        color={track.wellId === 0 ? colors.off_state : getColor({ key: track.wellId.toString() })}
                      />
                    )}
                    <div>{formattedName}</div>
                    <div>
                      {tooltipData?.value ? (
                        <strong>
                          {adjustDecimalPlaces(
                            selectedUnit?.toString(tooltipData.value),
                            getDecimalsInterval(displayScaleDomain),
                          )}{" "}
                          {selectedUnit?.abv}
                        </strong>
                      ) : (
                        <strong>No Data</strong>
                      )}
                    </div>
                  </TooltipEntry>
                );
              })
            )}
            {isRoadmap && tooltipInfo && detailed ? (
              <>
                <TooltipEntry>
                  <div>Roadmap Maximum</div>
                  <div>
                    <strong>
                      {tooltipInfo.selectedBoundary?.max && Number.isFinite(tooltipInfo.selectedBoundary?.max)
                        ? selectedUnit?.toString(tooltipInfo.selectedBoundary.max) + " " + selectedUnit?.abv
                        : "N/A"}
                    </strong>
                  </div>
                </TooltipEntry>
                <TooltipEntry>
                  <div>Roadmap Minimum</div>
                  <div>
                    <strong>
                      {tooltipInfo.selectedBoundary?.min && Number.isFinite(tooltipInfo.selectedBoundary?.min)
                        ? selectedUnit?.toString(tooltipInfo.selectedBoundary.min) + " " + selectedUnit?.abv
                        : "N/A"}
                    </strong>
                  </div>
                </TooltipEntry>
              </>
            ) : null}
            <TooltipExtra>
              <div>{depthUOM.display(tooltipInfo?.holeDepth ?? 0)}</div>
              <div>{formatTime(tooltipInfo.timestamp, { formatStr: "M/D HH:mm" })}</div>
            </TooltipExtra>
          </TooltipContainer>
        ) : null}

        {(isPointerInsideChart || detailed) && returnZeroIfInvalid(chartHeight) > 0 ? (
          <svg
            width={returnZeroIfInvalid(chartWidth)}
            height={returnZeroIfInvalid(chartHeight)}
            style={{ position: "absolute", top: 0 }}
          >
            <Bar
              style={{
                pointerEvents: "all",
              }}
              height={chartHeight - 1}
              width={2}
              fill="rgba(0, 0, 0, 0.3)"
              x={pointerPosition.x - 1}
              y={0}
              onClick={() => {
                onTooltipToggle();
              }}
              cursor="pointer"
            />
          </svg>
        ) : null}
      </div>
    );
  },
);
