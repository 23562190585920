import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ApiTagBottomFingerprintUserLensesIdFactsPutRequest, TagBottomFingerprintFactSetDto } from "apis/oag";
import { TagBottomFingerprintUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import { initialFilters, type IFiltersType, type IZoomData } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import { dateToDateDto } from "utils/helper";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

export const useTagBottomFingerprint = ({
  lensId,
  options,
}: {
  lensId: number;
  options?: UseQueryOptions<TagBottomFingerprintFactSetDto>;
}) => {
  const api = new TagBottomFingerprintUserLensesApi(apiConfig);
  const wellDataID = useSelectedWell();
  const dispatch = useAppDispatch();

  const [
    {
      sections: sectionIds,
      operationTime,
      directionalIntervals: directionalIntervalIds,
      phases: phaseIds,
      holeSizes: holeSizeIds,
    },
  ] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, initialFilters);
  const [zoomState] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData, [
    URL_STATE_PARAM.ZOOM_WIDGET,
  ]);

  const {
    ts_start: zoomStartDuration,
    ts_end: zoomEndDuration,
    depth_start: zoomStartDepth,
    depth_end: zoomEndDepth,
  } = zoomState ?? initialZoomData;

  const requestParameters: ApiTagBottomFingerprintUserLensesIdFactsPutRequest = {
    id: lensId ?? -1,
    tagBottomFingerprintUserLensQueryDto: {
      selectedFilters: {
        sectionIds,
        directionalIntervalIds,
        holeSizeIds,
        phaseIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNoneAlphaRigs: true,
      },
      zoomStartDepth,
      zoomEndDepth,
      zoomStartDuration,
      zoomEndDuration,
      wellId: wellDataID,
      sampleCount: TVD_SAMPLE_COUNT,
      useMockData: false,
      comparisonWellIds: null,
      from: dateToDateDto(zoomState.date_start) ?? defaultDateDto.from,
      to: dateToDateDto(zoomState.date_end) ?? defaultDateDto.to,
    },
  };

  const queryKey: PDLensFactsQueryKey<ApiTagBottomFingerprintUserLensesIdFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.tagBottomFingerprintFacts,
    params: requestParameters,
    lensId,
  };
  const requestReturn = useSuspenseQuery<TagBottomFingerprintFactSetDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api.apiTagBottomFingerprintUserLensesIdFactsPut(requestParameters, { signal }).then((data) => {
        if (data.lastUpdatedAt)
          dispatch({
            type: "SET_PER_STAND_REFRESH_DATE",
            payload: { [wellDataID]: data.lastUpdatedAt },
          });
        return data;
      }),
    ...options,
  });
  return requestReturn;
};
