import { Line } from "@visx/shape";
import { useCustomTheme } from "utils/useTheme";

function SeparatorLines({
  plotHeight,
  x,
  hidden,
  detailed,
}: {
  plotHeight: number;
  x: number;
  hidden?: boolean;
  detailed?: boolean;
}) {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  return hidden ? null : (
    <>
      <Line
        from={{
          x: x,
          y: plotHeight + (detailed ? 80 : 55),
        }}
        to={{
          x: x,
          y: plotHeight + (detailed ? 145 : 85),
        }}
        stroke={themeColors.primary_accent}
        strokeWidth={1}
      />
      <Line
        from={{
          x: x,
          y: 0,
        }}
        to={{
          x: x,
          y: plotHeight,
        }}
        stroke={themeColors.primary_accent}
        strokeWidth={1}
      />
    </>
  );
}

export default SeparatorLines;
