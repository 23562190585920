import { useMemo } from "react";
import styled from "styled-components";
import colors from "utils/colors";
export enum RealTimeDataEnum {
  ACTIVE,
  DISABLED,
  UNAVAILABLE,
}
export const RealTime = styled.div`
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  /* Defaulting to unavailable as most wells do not have real time data */
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_accent};

  border: 2.5px solid ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
  box-shadow: 0px 0px 0 3.5px ${({ theme }) => theme.themeStyle.colors.alt_accent};
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_scroll_bg};
  &[datatype="unavailable"] {
    border: 2.5px solid ${({ theme }) => theme.themeStyle.colors.off_secondary_bg}!important;
    box-shadow: 0px 0px 0 3.5px ${({ theme }) => theme.themeStyle.colors.alt_accent}!important;
    background-color: ${({ theme }) => theme.themeStyle.colors.primary_scroll_bg}!important;
  }
  &[datatype="active"] {
    height: 11px;
    width: 11px;
    border: 2.5px solid ${colors.well_color};
    animation: pulsate 2.5s infinite;
    @keyframes pulsate {
      0% {
        box-shadow: 0px 0px 0px 0px ${colors.well_color_transparent};
      }
      50% {
        box-shadow: 0px 0px 2.5px 3.5px ${colors.well_color_transparent};
      }
      100% {
        box-shadow: 0px 0px 0px 0px ${colors.well_color_transparent};
      }
    }
    background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
    margin: 0px 12px;
  }
  &[datatype="disabled"] {
    height: 13px;
    width: 13px;
    border: 4.5px solid ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.themeStyle.colors.alt_accent};
    background-color: ${({ theme }) => theme.themeStyle.colors.primary_scroll_bg};
  }
`;

export const RealTimeIndicator = ({ realTimeDataState }: { realTimeDataState: RealTimeDataEnum }) => {
  const datatype = useMemo(() => {
    switch (realTimeDataState) {
      case RealTimeDataEnum.ACTIVE:
        return "active";
      case RealTimeDataEnum.DISABLED:
        return "disabled";
      case RealTimeDataEnum.UNAVAILABLE:
        return "unavailable";
    }
  }, [realTimeDataState]);

  return <RealTime datatype={datatype} />;
};
