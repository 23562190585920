/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigLeaderboardScoreSubgroupAco } from './RigLeaderboardScoreSubgroupAco';
import {
    RigLeaderboardScoreSubgroupAcoFromJSON,
    RigLeaderboardScoreSubgroupAcoFromJSONTyped,
    RigLeaderboardScoreSubgroupAcoToJSON,
} from './RigLeaderboardScoreSubgroupAco';

/**
 * 
 * @export
 * @interface RigLeaderboardScoreGroupAco
 */
export interface RigLeaderboardScoreGroupAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreGroupAco
     */
    kpiGroupName: string;
    /**
     * 
     * @type {Array<RigLeaderboardScoreSubgroupAco>}
     * @memberof RigLeaderboardScoreGroupAco
     */
    children: Array<RigLeaderboardScoreSubgroupAco>;
}

/**
 * Check if a given object implements the RigLeaderboardScoreGroupAco interface.
 */
export function instanceOfRigLeaderboardScoreGroupAco(value: object): value is RigLeaderboardScoreGroupAco {
    if (!('kpiGroupName' in value) || value['kpiGroupName'] === undefined) return false;
    if (!('children' in value) || value['children'] === undefined) return false;
    return true;
}

export function RigLeaderboardScoreGroupAcoFromJSON(json: any): RigLeaderboardScoreGroupAco {
    return RigLeaderboardScoreGroupAcoFromJSONTyped(json, false);
}

export function RigLeaderboardScoreGroupAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardScoreGroupAco {
    if (json == null) {
        return json;
    }
    return {
        
        'kpiGroupName': json['KpiGroupName'],
        'children': ((json['Children'] as Array<any>).map(RigLeaderboardScoreSubgroupAcoFromJSON)),
    };
}

export function RigLeaderboardScoreGroupAcoToJSON(value?: RigLeaderboardScoreGroupAco | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'KpiGroupName': value['kpiGroupName'],
        'Children': ((value['children'] as Array<any>).map(RigLeaderboardScoreSubgroupAcoToJSON)),
    };
}

