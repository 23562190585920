import { NotificationType } from "apis/oag";
import { StyledTag } from "atoms/common";
import { Title as AtomTitle } from "atoms/Typography";
import { PLOT_WIDTH } from "components/Notification/utils";
import { IconContainer } from "icons/styles";
import { StyledContainer, StyledTagsSpace } from "pages/RigScoreCard/LeftPane/components/GroupTitle";
import styled from "styled-components";
import colors from "utils/colors";
import { Button as AntButton, Checkbox as AntCheckbox } from "utils/componentLibrary";

export const Controls = styled.div`
  display: none;
  place-items: center;
`;

export const IconWrapper = styled.div<{ $notificationType?: NotificationType }>`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  place-items: center;
  place-content: center;

  background: ${({ $notificationType, theme }) => {
    if ($notificationType !== NotificationType.ScorecardComment) {
      return theme.themeStyle.colors.primary_scroll_bg;
    }
    return theme.themeStyle.colors.alt_primary_accent;
  }};

  color: ${({ $notificationType, theme }) => {
    if ($notificationType !== NotificationType.ScorecardComment) {
      return theme.isDark ? colors.whisky : colors.hugo;
    }
    return colors.transparent_black;
  }};
`;

export const Checkbox = styled(AntCheckbox)`
  &,
  & .ant-checkbox,
  & .ant-checkbox-checked {
    height: 36px;
    width: 36px;
    top: 0;
    .ant-checkbox-input,
    .ant-checkbox-inner {
      margin-top: 6px;
      margin-left: 6px;
      height: 24px;
      width: 24px;
      ::after {
        width: 8px;
        height: 12px;
      }
    }
  }
  display: none;
  place-items: center;
  place-content: center;
`;

export const CheckboxVisible = styled(AntCheckbox)`
  transform: scale(1.4);
  height: 24px;
  width: 24px;
`;

export const NotificationRow = styled.div<{ $isRead: boolean; $editMode: boolean }>`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => (theme.isDark ? colors.limo_scene : colors.lunar_rays)};

  background-color: ${({ theme, $isRead }) =>
    $isRead ? theme.themeStyle.colors.notification_read_bg : theme.themeStyle.colors.notification_unread_bg};
  border-left: 5px solid ${({ $isRead }) => ($isRead ? "transparent" : colors.flamboyant_teal)};

  ${({ $editMode }) =>
    $editMode
      ? `
        ${IconWrapper} {
          display: none;
        }
        ${Checkbox} {
          display: flex;
        }
      `
      : null};

  :hover {
    background-color: ${({ theme }) => theme.themeStyle.colors.notification_hover_bg};
    ${Controls} {
      display: flex;
    }

    ${IconWrapper} {
      display: none;
    }
    ${Checkbox} {
      display: flex;
    }
  }
`;

export const NotificationInfoRow = styled.div`
  display: flex;
  cursor: pointer;
`;

export const RigTitleContainer = styled.div`
  display: flex;
  gap: 12px;
  place-items: center;
  place-content: start;
  padding-left: 4px;
`;

export const NotificationTitle = styled.div<{ $notificationType: NotificationType; $isRead: boolean }>`
  font-size: 16px;
  font-weight: ${({ $isRead }) => ($isRead ? 500 : 700)};
  color: ${({ $notificationType, $isRead }) => {
    if ($isRead) {
      return colors.gray;
    }
    if ($notificationType === NotificationType.ScorecardComment) {
      return colors.bluetiful;
    }
    return colors.coral_expression;
  }};
`;

export const TaskTitle = styled.div`
  color: ${colors.whisky};
  font-size: 14px;
  font-weight: 500;
`;

export const CheckboxContainer = styled(NotificationTitle)`
  font-size: 14px;
  display: flex;
  justify-content: end;
  padding-right: 4px;
  align-items: center;
  color: ${({ $notificationType, theme }) => {
    if ($notificationType !== NotificationType.ScorecardComment) {
      return theme.isDark ? colors.whisky : colors.hugo;
    }
    return undefined;
  }};
`;

export const WellContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const WellName = styled.span`
  max-width: 70%;
  & h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Job = styled.span`
  display: flex;
  gap: 8px;
  max-width: 30%;
  & h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ::before {
    font-weight: 900;
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    content: "·";
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  place-content: center;
`;
export const SeparatorLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => (theme.isDark ? colors.limo_scene : colors.widget_line)};
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
`;

export const Description = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  overflow-x: auto;
  overflow-y: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-height: 2lh;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: 450px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-left: 2px solid ${({ theme }) => (theme.isDark ? colors.necron_compound : colors.lunar_rays)};
`;

export const Time = styled.div`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  display: flex;
  font-size: 14px;
  place-items: center;
`;

export const Button = styled(AntButton)`
  &,
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  :hover {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const CheckmarkButton = styled(Button)<{ $isRead: boolean }>`
  color: ${({ $isRead, theme }) =>
    $isRead ? theme.themeStyle.colors.primary_button_bg : theme.themeStyle.colors.primary_typography} !important;

  :hover {
    color: ${({ $isRead, theme }) =>
      $isRead ? theme.themeStyle.colors.primary_button_bg : theme.themeStyle.colors.primary_typography} !important;
  }
`;

export const ApprovalStatus = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 4px;
  font-weight: 400;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const ApprovalActions = styled.div`
  display: inline-flex;
  column-gap: 4px;
`;

export const NoWrap = styled.div`
  white-space: nowrap;
`;

export const StatusText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  ${NoWrap} + ${NoWrap} {
    margin-left: 4px;
    padding-left: 4px;
    border-left: 1px solid ${({ theme }) => theme.themeStyle.colors.separation_line};
  }
`;

export const LensTitle = styled.div`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-size: 16px;
  font-weight: normal;
`;

export const ModalContainer = styled.div`
  padding: 0px;

  ${RigTitleContainer} {
    padding-left: 0px;
    flex-direction: column;
  }
  ${WellName} {
    padding-top: 8px;
    max-width: unset;
    & > h3 {
      text-overflow: unset;
      white-space: normal;
      overflow: visible;
    }
  }
  ${TitleContainer} {
    place-content: start;
    gap: 8px;
  }

  ${Description} {
    max-height: unset;
    -webkit-line-clamp: unset;
    padding-left: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
    border-left: 2px solid ${colors.lunar_rays};
  }

  ${LensTitle}, ${Description}, ${ApprovalStatus}, ${StatusText} {
    font-size: 14px;
  }
`;

export const JobDetailed = styled.span`
  display: inline-flex;
  ::before {
    font-weight: 900;
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    content: "·";
    padding-left: 6px;
    padding-right: 6px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  padding: 0px 20px 26px 20px;
`;

export const ModalLeft = styled.div`
  display: flex;
  min-width: 65px;
`;

export const ModalRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ModalTopContainer = styled.div`
  display: flex;
`;

export const InfoContainer = styled.div`
  display: flex;
  width: calc(100% - ${PLOT_WIDTH}px);

  ${StyledTag} {
    align-items: center;
    gap: 4px;
  }
  ${StyledTagsSpace} {
    gap: 0px 2px;
  }
  ${StyledContainer} {
    align-items: start;
    margin-top: -8px;
  }
`;

export const ModalChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${PLOT_WIDTH}px;
  align-items: center;
  ${StyledTag} {
    font-size: 10px;
    padding: 2px 4px;
    align-items: center;
    gap: 4px;
  }
  ${StyledTagsSpace} {
    gap: 0px 2px;
    margin-bottom: 8px;
  }
`;

export const ScorecardButton = styled(Button)`
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_button_bg} !important;
  a {
    color: white !important;
  }
  width: 165px;
  font-size: 14px;
  margin-top: 12px;
  svg {
    width: 16px !important;
    height: 16px !important;
  }
  &.ant-btn > a:only-child {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
`;

export const ModalCommentContainer = styled.div`
  display: flex;
`;

export const ModalHeader = styled.div`
  padding: 36px 24px 16px 24px;
  display: block;
  justify-content: end;
  align-items: center;
  position: relative;
  height: 16px;
`;

export const ModalFooter = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
`;

export const Title = styled(AtomTitle).attrs({ level: 2, weight: 500 })`
  font-size: 20px !important;
`;

export const CloseIcon = styled.span<{ $large?: boolean }>`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  cursor: pointer;
  ${IconContainer} {
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
    font-size: ${({ $large }) => ($large ? "30px" : "20px")};
    color: ${({ theme }) => (theme.isDark ? colors.white : colors.necron_compound)};
  }
`;

export const DetailedCloseIcon = styled.span<{ $large?: boolean }>`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  ${IconContainer} {
    font-size: ${({ $large }) => ($large ? "30px" : "24px")};
    color: ${({ theme }) => theme.themeStyle.colors.faint_typography};
  }
`;

export const CancelButton = styled(Button)`
  color: ${colors.transparent_black};
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background-color: ${colors.white};
  height: 34px;
`;

export const ConfirmButton = styled(Button)`
  height: 37px;
  background-color: ${colors.whisky};
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  color: ${colors.white};
`;

export const ListNoteExceptionStatus = styled.div`
  margin-top: 8px;
  width: 100%;
`;

export const AuthorName = styled.div`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-size: 12px;
  font-weight: 700;
`;

export const CommentDate = styled.div`
  margin-top: 4px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  font-size: 12px;
  font-weight: 400;
`;

export const ApproveButton = styled(Button)`
  background-color: ${colors.well_color};
  font-size: 14px;
  color: ${colors.white} !important;
  padding-left: 12px;
  padding-right: 12px;
  border-width: 1px;
  :hover {
    background-color: ${colors.well_color} !important;
  }
`;

export const RejectButton = styled(ApproveButton)`
  background-color: ${colors.coral_expression};
  border: none !important;
  outline: none !important;

  &:hover {
    background-color: ${colors.coral_expression} !important;
  }
`;

export const OverflowEllipsis = styled(NoWrap)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const KpiTitle = styled.div`
  color: ${colors.gray};
  font-size: 14px;
  font-weight: 400;
  padding-top: 4px;
`;

export const SummaryItem = styled.div`
  display: flex;
  justify-content: start;
  gap: 4px;
`;

export const KpiSummaryContainer = styled.div`
  display: flex;
  ${SummaryItem} + ${SummaryItem} {
    border-left: 1px solid ${colors.necron_compound_light};
    padding-left: 12px;
    margin-left: 12px;
  }
`;

export const SummaryTitle = styled(AtomTitle).attrs({ level: 4, variant: "faded" })`
  font-size: 14px;
`;

export const SummaryValue = styled(AtomTitle).attrs({ level: 4, variant: "faded" })`
  font-size: 14px;
`;
