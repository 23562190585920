import type { FiltersDto, PivotOrderType, StackingType, UserLensDto } from "apis/oag";
import { LensTemplateType, PivotType } from "apis/oag";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { ControllerBarHolder, StyledSpace, StyledSpaceLeft } from "components/DetailsTopBar/style";
import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import { getAbilityMatrix } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";
import { useLensSettingsModal } from "components/Lenses/useLensSettingsModal";
import { OptionUnavailableTooltip } from "components/MiniLens/OptionUnavailableTooltip";
import { PDComponent } from "components/PDComponents";
import { RealTimeDataEnum, RealTimeIndicator } from "components/RealTimeIndicator";
import {
  DisplayOptionsModal,
  getDefaultPivotOrderTypeByPivot,
  getDisplayOptions,
} from "components/RigComparisonSelectors/DisplayOptionsModal";
import { filtersDtoToFiltersType, initialFiltersState } from "components/RigDashboard/ControlHeader";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import Filters from "components/WellDashboard/ControlHeader/atoms/Filters";
import Zoom from "components/WellDashboard/ControlHeader/atoms/Zoom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { getCorrectOrder } from "hooks/pivotFacts/useStackedKpi";
import { useDashboardType } from "hooks/useDashboardType";
import { useFiltersLoading } from "hooks/useFiltersLoading";
import type { LensTemplate } from "hooks/useLensTemplates";
import { useLensUpdate } from "hooks/useLensUpdate";
import { useUserLensTabs } from "hooks/useUserLensTabs";
import { isEqual } from "lodash";
import type { Dict } from "mixpanel-browser";
import qs from "qs";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { initialFilters, type IFiltersType, type IZoomData } from "reducers/types";
import { Track } from "services/Mixpanel";
import { Col, Popover, Row, Tooltip } from "utils/componentLibrary";

import Rigs from "./Controls/Rigs";
import Well from "./Controls/Well";
import KpiSelect from "./KpiSelect";
import { getTitleRealTimeIndicator } from "./utils";

dayjs.extend(relativeTime);

const DetailsTopBar = ({
  template,
  trackingProps,
  lens,
  isSystem,
  onLensUpdated,
  lensUpdater,
}: {
  template: LensTemplate;
  trackingProps: Dict;
  isSystem?: boolean;
  lens: UserLensDto & { showsOutliers?: boolean; squeezesDisplay?: boolean };
  onLensUpdated?: (newLens: UserLensDto) => void;
  lensUpdater?: (newLens: UserLensDto) => Promise<UserLensDto>;
}) => {
  const dispatch = useAppDispatch();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleLensUpdate = useLensUpdate({ updateLens: lensUpdater, onLensUpdated });

  const { dashboardType } = useDashboardType();
  const [displayOptions, setDisplayOptions] = useStateQuery<{
    pivot: Array<PivotType>;
    order: Array<PivotOrderType>;
  }>(
    URL_STATE_PARAM.DATA_GROUPS_DASHBOARD,
    {
      pivot: [PivotType.Day, PivotType.None],
      order: [PivotType.Day, PivotType.None].map((e) => getDefaultPivotOrderTypeByPivot(e)),
    },
    [URL_STATE_PARAM.DATA_GROUPS_WIDGET],
  );

  const [offsetRigs] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET, []);
  useEffect(() => {
    const pivot = getDisplayOptions(offsetRigs.length > 0)(displayOptions.pivot);
    const order = getCorrectOrder(Object.values(pivot), displayOptions, offsetRigs.length > 0);
    setTimeout(
      () =>
        setDisplayOptions({
          pivot,
          order,
        }),
      100,
    );
    // Don't need to run this effect on setDisplayOptions change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetRigs.length]);
  const [displayOptionsModalVisible, setDisplayOptionsModalVisible] = useState(false);
  const { isWell: isWellDashboard, isRig: isRigDashboard } = useDashboardType();

  const { filtersLoading, availableFilters, directionalIntervals, holeSections, holeSizes, phases } =
    useFiltersLoading();
  const displayOption = useAppSelector((state) => state.widgetOptions.display_options);
  const outliersEnabled = "showsOutliers" in lens ? lens.showsOutliers : false;
  const squeezeState = "squeezesDisplay" in lens ? lens.squeezesDisplay : false;

  const [filtersStateWell, setFilters] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, initialFilters);

  const { data: tabs } = useUserLensTabs(dashboardType);
  const [zoomData, setZoom] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);
  const legendVisibilityState = useAppSelector((state) => state.widgetOptions.legendVisible);
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);

  const [zoomOpen, setZoomOpen] = useState<boolean>(false);
  const [realTimeDataState, setRealtimeData] = useStateQuery<RealTimeDataEnum>(
    URL_STATE_PARAM.REALTIME_DATA_WIDGET,
    RealTimeDataEnum.UNAVAILABLE,
  );

  const match = useParams<{ dashboardId: string; lensId: string }>();
  const isManualYAxis = "isManualYaxis" in lens ? (lens.isManualYaxis as boolean) : false;
  const location = useLocation();
  const selectedTabQuery = useMemo(
    () => parseInt(qs.parse(location.search, { ignoreQueryPrefix: true }).selectedTab as string, 10),
    [location.search],
  );
  const isLocked = useMemo(
    () => (tabs ?? []).find((tab) => tab.id === lens?.lensTabId)?.isLocked,
    [lens?.lensTabId, tabs],
  );

  const { open, node: lensSettingsModalNode } = useLensSettingsModal({
    lens,
    handleLensUpdate,
    onLensUpdated: onLensUpdated,
    templateType: template.type,
    isLocked: !!isLocked,
  });

  const abilities = useMemo(() => {
    return getAbilityMatrix({
      type: template.type,
      isComparing: comparisonWells.length > 0,
      displayOption,
      stackingType: "stackingType" in lens ? (lens.stackingType as StackingType) : undefined,
      isSystem: lens?.isSystem,
    });
  }, [comparisonWells.length, displayOption, lens, template.type]);

  const resetZoomData = useCallback(() => {
    setZoom(initialZoomData);
    dispatch({
      type: "RESET_REPORT_ZOOM",
    });
    setZoomOpen(false);
  }, [dispatch, setZoom]);

  const setZoomData = useCallback(
    (zoom: IZoomData, state: boolean) => {
      setZoom(zoom);
      if (zoom)
        dispatch({
          type: "SET_REPORT_ZOOM",
          payload: { zoom, state },
        });
      setZoomOpen(false);
    },
    [dispatch, setZoom],
  );

  const applyFilters = (obj: IFiltersType, state: boolean) => {
    if (isWellDashboard) {
      setFilters(obj);
      dispatch({
        type: "SET_REPORT_FILTERS",
        payload: {
          filters: obj,
          state,
        },
      });
    } else if (isRigDashboard) {
      setFilters(obj);
    }
  };

  const [previousAvailableFilters, setPreviousAvailableFilters] = useState<FiltersDto | null>(null);

  useEffect(() => {
    if (!availableFilters) return;
    if (previousAvailableFilters === null || isEqual(initialFiltersState, filtersStateWell)) {
      setPreviousAvailableFilters(availableFilters);
      return;
    }
    const isSame = isEqual(availableFilters, previousAvailableFilters);
    if (isSame) return;

    const newFilterValues = filtersStateWell;
    type FilterValuesKeys = keyof IFiltersType;
    const optionsPreviousAvailableFilter = previousAvailableFilters
      ? filtersDtoToFiltersType(previousAvailableFilters)
      : initialFiltersState;
    const newFilterOptions = filtersDtoToFiltersType(availableFilters);
    for (const untypedKey of Object.keys(initialFiltersState)) {
      const key = untypedKey as FilterValuesKeys;
      const filterValues = filtersStateWell[key];
      const optionsCurrentFilter = optionsPreviousAvailableFilter[key] ?? [];
      const optionsNewFilter = newFilterOptions[key] ?? [];

      const newOptions = optionsNewFilter.filter((e) => {
        return !(optionsCurrentFilter as Array<typeof e>).includes(e);
      });
      const currentFiltersByKey = newFilterValues[key] ?? [];
      const savedFiltersValues = !filterValues  ? null : [...filterValues, ...newOptions];
      (newFilterValues[key] as typeof currentFiltersByKey) = savedFiltersValues as typeof currentFiltersByKey;
    }

    setPreviousAvailableFilters(availableFilters);
    setFilters(newFilterValues);
    // we want to run this effect just based on the changes in available filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFilters]);
  return (
    <ControllerBarHolder>
      <Row
        justify="space-between"
        align="middle"
        style={{
          height: "64px",
          width: "100%",
        }}
      >
        <Col flex="0 auto">
          {template.type === LensTemplateType.StickSlipByTime ? (
            <StyledSpaceLeft direction="horizontal">
              <Title level={3}>Stick Slip by Time</Title>
              <Tooltip title="Only rotary drilling points included">
                <PDComponent.SvgIcon name="information" width="16" height="16" />
              </Tooltip>
            </StyledSpaceLeft>
          ) : null}
          {abilities.kpiSelect === MatrixAvailability.NOT_SUPPORTED ? null : (
            <OptionUnavailableTooltip isPassThrough={!isLocked}>
              <KpiSelect
                template={template}
                lens={lens}
                onLensUpdated={onLensUpdated}
                lensUpdater={lensUpdater}
                isDisabled={abilities.kpiSelect === MatrixAvailability.NOT_AVAILABLE || isLocked}
              />
            </OptionUnavailableTooltip>
          )}
        </Col>

        <Col
          flex="0 auto"
          style={{
            paddingRight: 28,
          }}
        >
          <StyledSpace>
            {/* Well filters */}
            {isWellDashboard ? (
              <Well
                abilities={abilities}
                lens={lens}
                onLensUpdated={onLensUpdated}
                setZoomOpen={setZoomOpen}
                isFiltersOpen={isFiltersOpen}
                handleLensUpdate={handleLensUpdate}
                setIsFiltersOpen={setIsFiltersOpen}
              />
            ) : null}
            <Suspense fallback={null}>
              {isRigDashboard ? (
                <Rigs
                  abilities={abilities}
                  lens={lens}
                  isFiltersOpen={isFiltersOpen}
                  handleLensUpdate={handleLensUpdate}
                  setIsFiltersOpen={setIsFiltersOpen}
                />
              ) : null}
            </Suspense>

            {/* Rig Filters */}
            <Popover
              content={
                isFiltersOpen && !filtersLoading ? (
                  <Filters
                    appliedState={filtersStateWell}
                    availableFilters={availableFilters}
                    directionalIntervals={directionalIntervals}
                    holeSections={holeSections}
                    holeSizes={holeSizes}
                    phases={phases}
                    hiddenFilters={abilities.hiddenFiltersDetailed}
                    lens
                    onCancel={() => setIsFiltersOpen(false)}
                    setFilterData={applyFilters}
                    trackingProps={trackingProps}
                    visible={isFiltersOpen}
                  />
                ) : null
              }
              trigger={["click", "scroll"]}
              placement="bottomRight"
              open={isFiltersOpen}
              onOpenChange={(e) => setIsFiltersOpen(e)}
              destroyTooltipOnHide
            />

            {isRigDashboard ? (
              <Tooltip title="Data Groups">
                <Button
                  icon={<PDComponent.SvgIcon name="layers" />}
                  $engaged={displayOptionsModalVisible}
                  onClick={() => {
                    Track.interact("Dashboard Rigs - Display Options", {
                      Action: "Open",
                    });
                    setDisplayOptionsModalVisible(true);
                  }}
                />
              </Tooltip>
            ) : null}

            {abilities.squeezesDisplay !== MatrixAvailability.NOT_SUPPORTED ? (
              <Tooltip title="Shrink">
                <Button
                  size="large"
                  icon={squeezeState ? <PDComponent.SvgIcon name="expand" /> : <PDComponent.SvgIcon name="shrink" />}
                  disabled={abilities.squeezesDisplay === MatrixAvailability.NOT_AVAILABLE}
                  onClick={() => {
                    Track.interact("Lens - Shrink", {
                      Selection: !squeezeState,
                    });
                    handleLensUpdate
                      .mutateAsync({
                        ...lens,
                        squeezesDisplay: !lens?.squeezesDisplay,
                      })
                      .catch((e) => console.error(e));
                  }}
                  $engaged={squeezeState}
                />
              </Tooltip>
            ) : null}

            {abilities.showsOutliers !== MatrixAvailability.NOT_SUPPORTED ? (
              <Tooltip title="Auto Crop">
                <Button
                  size="large"
                  icon={
                    outliersEnabled ? (
                      <PDComponent.SvgIcon name="outliersEnabled" />
                    ) : (
                      <PDComponent.SvgIcon name="outliersDisabled" />
                    )
                  }
                  disabled={abilities.squeezesDisplay === MatrixAvailability.NOT_AVAILABLE || isManualYAxis}
                  onClick={() => {
                    Track.interact("Lens - Outliers", {
                      Selection: !outliersEnabled,
                    });
                    handleLensUpdate
                      .mutateAsync({
                        ...lens,
                        showsOutliers: !lens.showsOutliers,
                      })
                      .catch((e) => console.error(e));
                  }}
                />
              </Tooltip>
            ) : null}
            <PDComponent.VerticalDivider />

            {isSystem ? null : (
              <Tooltip title={getTitleRealTimeIndicator(realTimeDataState)}>
                <Button
                  size="large"
                  onClick={() => {
                    if (realTimeDataState === RealTimeDataEnum.UNAVAILABLE) return;
                    Track.interact(`Detailed ${isRigDashboard ? "Rig" : "Well"} - Real Time`, {
                      State: realTimeDataState === RealTimeDataEnum.ACTIVE ? "Disabled" : "Active",
                    });
                    setRealtimeData(
                      realTimeDataState === RealTimeDataEnum.ACTIVE
                        ? RealTimeDataEnum.DISABLED
                        : RealTimeDataEnum.ACTIVE,
                    );
                  }}
                  icon={<RealTimeIndicator realTimeDataState={realTimeDataState} />}
                />
              </Tooltip>
            )}
            {isWellDashboard && abilities.hasExport === MatrixAvailability.AVAILABLE ? (
              <Tooltip title="Export">
                <Link
                  to={`/export/${match.dashboardId}/${match.lensId}${
                    selectedTabQuery ? `?selectedTab=${selectedTabQuery}` : ""
                  }`}
                >
                  <Button size="large" icon={<PDComponent.SvgIcon name="export" />} />
                </Link>
              </Tooltip>
            ) : null}

            {abilities.lensSettings !== MatrixAvailability.NOT_SUPPORTED ? (
              <Tooltip title="Lens Settings">
                <Button
                  size="large"
                  icon={<PDComponent.SvgIcon name="settings" />}
                  onClick={() => {
                    if (lens) {
                      open(lens);
                    }
                  }}
                />
              </Tooltip>
            ) : null}

            {abilities.legend !== MatrixAvailability.NOT_SUPPORTED ? (
              <>
                <PDComponent.VerticalDivider />

                {/* Legend Interaction */}
                <Tooltip title="Legend">
                  <Button
                    size="large"
                    icon={<PDComponent.SvgIcon name="list" />}
                    trackingName="Lens - Legend"
                    trackingProps={{
                      Active: !!legendVisibilityState,
                    }}
                    onClick={() => {
                      Track.clickEvent(`Lens Detailed - ${legendVisibilityState ? "Close" : "Open"} Legend`);
                      dispatch({
                        type: "SET_LEGEND_VISIBLE_WIDGET",
                        payload: {
                          legendVisible: !legendVisibilityState,
                        },
                      });
                    }}
                    $engaged={legendVisibilityState}
                  />
                </Tooltip>
              </>
            ) : null}
          </StyledSpace>
        </Col>
      </Row>
      <Suspense fallback={null}>
        {!isFiltersOpen && (
          <Zoom
            lens
            visible={zoomOpen}
            onCancel={() => setZoomOpen(false)}
            setZoomData={setZoomData}
            resetZoomData={resetZoomData}
            zoomData={zoomData}
            availableActions={{
              time: abilities.zoomByTime,
              date: abilities.zoomByDate,
              depth: MatrixAvailability.AVAILABLE,
            }}
            ignoreTvD
          />
        )}
      </Suspense>
      {lensSettingsModalNode}
      <Suspense fallback={null}>
        {isRigDashboard ? (
          <DisplayOptionsModal
            isOpen={displayOptionsModalVisible}
            prevDisplayOptions={displayOptions.pivot}
            prevDisplayOrderOptions={displayOptions.order}
            onClose={() => setDisplayOptionsModalVisible(false)}
            saveDisplayOptions={(pivot, order) => {
              setDisplayOptions({
                pivot,
                order,
              });
            }}
            lensTemplateId={lens.lensTemplateId}
            isComparing={offsetRigs.length > 0}
          />
        ) : null}
      </Suspense>
    </ControllerBarHolder>
  );
};

export default DetailsTopBar;
