import { ListSearch } from "components/ListSearch";
import { OptionItem } from "components/PDComponents/Search/ListWithSearch/OptionItemSimpleSearch";
import { StyledListSimpleSearch, StyledSpaceForLayoutSimpleSearch } from "components/PDComponents/Search/style";
import { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";

type IAllowedOptions = number | string;

export interface IOption<T extends IAllowedOptions> {
  id: T;
  name: string;
  component?: JSX.Element;
}

interface ICustomSearch<T extends IAllowedOptions> {
  placeholder: string;
  options: IOption<T>[];
  values: T[];
  width?: number;
  onClick: (item: IOption<T>) => void;
  selectedItem?: string;
}

export function _ListWithSearch<T extends IAllowedOptions>({
  placeholder = "Search",
  options = [],
  values = [],
  width = 250,
  onClick,
  selectedItem,
}: ICustomSearch<T>) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<IOption<T>[]>(options);
  const [selectedOptions, setSelectedOptions] = useState<T[]>(values);
  const [tempSelOptions, setTempSelOptions] = useState<T[]>(values);

  const deps = options.toString() + values.toString();
  useEffect(() => {
    if (!values) {
      setSelectedOptions((options ?? []).map((e) => e.id));
      setTempSelOptions((options ?? []).map((e) => e.id));
    } else setSelectedOptions(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);

  useEffect(() => {
    const tempFilter = options.filter((e) => e?.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);

    setFilteredOptions([
      ...tempFilter.filter((e) => (selectedOptions ?? options.map((i) => i.id)).includes(e.id)),
      ...tempFilter.filter((e) => !(selectedOptions ?? options.map((i) => i.id)).includes(e.id)),
    ]);
  }, [searchTerm, options, selectedOptions]);

  return (
    <StyledSpaceForLayoutSimpleSearch direction="vertical" size={0} width={width}>
      <ListSearch placeholder={placeholder} setSearchTerm={setSearchTerm} searchTerm={searchTerm}>
        <div style={{ minHeight: "257px", width: "100%" }}>
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <StyledListSimpleSearch
                height={Number.isNaN(height) ? "100%" : height}
                width={width}
                itemSize={(item) => {
                  return filteredOptions[item]?.name?.length > 45 ? 56 : 46;
                }}
                itemCount={filteredOptions.length}
                itemData={{
                  filteredOptions,
                  tempSelOptions,
                  onChange: (newOptions: T[]) => setTempSelOptions(newOptions),
                }}
              >
                {({ style, index, data }) => (
                  <OptionItem
                    selectedItem={selectedItem || ""}
                    {...{ style, index, width, onClick }}
                    data={
                      data as {
                        filteredOptions: IOption<T>[];
                      }
                    }
                  />
                )}
              </StyledListSimpleSearch>
            )}
          </AutoSizer>
        </div>
      </ListSearch>
    </StyledSpaceForLayoutSimpleSearch>
  );
}
