/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParameterHeatmapBin } from './ParameterHeatmapBin';
import {
    ParameterHeatmapBinFromJSON,
    ParameterHeatmapBinFromJSONTyped,
    ParameterHeatmapBinToJSON,
} from './ParameterHeatmapBin';
import type { DecimalRange } from './DecimalRange';
import {
    DecimalRangeFromJSON,
    DecimalRangeFromJSONTyped,
    DecimalRangeToJSON,
} from './DecimalRange';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { BinCoordinates } from './BinCoordinates';
import {
    BinCoordinatesFromJSON,
    BinCoordinatesFromJSONTyped,
    BinCoordinatesToJSON,
} from './BinCoordinates';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ParameterHeatmapResultSetDto
 */
export interface ParameterHeatmapResultSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof ParameterHeatmapResultSetDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof ParameterHeatmapResultSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapResultSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof ParameterHeatmapResultSetDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof ParameterHeatmapResultSetDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {Array<ParameterHeatmapBin>}
     * @memberof ParameterHeatmapResultSetDto
     */
    bins?: Array<ParameterHeatmapBin> | null;
    /**
     * 
     * @type {ParameterHeatmapBin}
     * @memberof ParameterHeatmapResultSetDto
     */
    focalRecommendationBins: ParameterHeatmapBin;
    /**
     * 
     * @type {ParameterHeatmapBin}
     * @memberof ParameterHeatmapResultSetDto
     */
    offsetRecommendationBins: ParameterHeatmapBin;
    /**
     * 
     * @type {BinCoordinates}
     * @memberof ParameterHeatmapResultSetDto
     */
    lastCapturedBin: BinCoordinates;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapResultSetDto
     */
    readonly xBinCountComputed: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapResultSetDto
     */
    readonly yBinCountComputed: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapResultSetDto
     */
    xBinCountRequested: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapResultSetDto
     */
    yBinCountRequested: number;
    /**
     * 
     * @type {DecimalRange}
     * @memberof ParameterHeatmapResultSetDto
     */
    xRange: DecimalRange;
    /**
     * 
     * @type {DecimalRange}
     * @memberof ParameterHeatmapResultSetDto
     */
    yRange: DecimalRange;
}



/**
 * Check if a given object implements the ParameterHeatmapResultSetDto interface.
 */
export function instanceOfParameterHeatmapResultSetDto(value: object): value is ParameterHeatmapResultSetDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('focalRecommendationBins' in value) || value['focalRecommendationBins'] === undefined) return false;
    if (!('offsetRecommendationBins' in value) || value['offsetRecommendationBins'] === undefined) return false;
    if (!('lastCapturedBin' in value) || value['lastCapturedBin'] === undefined) return false;
    if (!('xBinCountComputed' in value) || value['xBinCountComputed'] === undefined) return false;
    if (!('yBinCountComputed' in value) || value['yBinCountComputed'] === undefined) return false;
    if (!('xBinCountRequested' in value) || value['xBinCountRequested'] === undefined) return false;
    if (!('yBinCountRequested' in value) || value['yBinCountRequested'] === undefined) return false;
    if (!('xRange' in value) || value['xRange'] === undefined) return false;
    if (!('yRange' in value) || value['yRange'] === undefined) return false;
    return true;
}

export function ParameterHeatmapResultSetDtoFromJSON(json: any): ParameterHeatmapResultSetDto {
    return ParameterHeatmapResultSetDtoFromJSONTyped(json, false);
}

export function ParameterHeatmapResultSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHeatmapResultSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'bins': json['Bins'] == null ? undefined : ((json['Bins'] as Array<any>).map(ParameterHeatmapBinFromJSON)),
        'focalRecommendationBins': ParameterHeatmapBinFromJSON(json['FocalRecommendationBins']),
        'offsetRecommendationBins': ParameterHeatmapBinFromJSON(json['OffsetRecommendationBins']),
        'lastCapturedBin': BinCoordinatesFromJSON(json['LastCapturedBin']),
        'xBinCountComputed': json['XBinCountComputed'],
        'yBinCountComputed': json['YBinCountComputed'],
        'xBinCountRequested': json['XBinCountRequested'],
        'yBinCountRequested': json['YBinCountRequested'],
        'xRange': DecimalRangeFromJSON(json['XRange']),
        'yRange': DecimalRangeFromJSON(json['YRange']),
    };
}

export function ParameterHeatmapResultSetDtoToJSON(value?: Omit<ParameterHeatmapResultSetDto, 'XBinCountComputed'|'YBinCountComputed'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'DataState': ResultDataStateToJSON(value['dataState']),
        'Bins': value['bins'] == null ? undefined : ((value['bins'] as Array<any>).map(ParameterHeatmapBinToJSON)),
        'FocalRecommendationBins': ParameterHeatmapBinToJSON(value['focalRecommendationBins']),
        'OffsetRecommendationBins': ParameterHeatmapBinToJSON(value['offsetRecommendationBins']),
        'LastCapturedBin': BinCoordinatesToJSON(value['lastCapturedBin']),
        'XBinCountRequested': value['xBinCountRequested'],
        'YBinCountRequested': value['yBinCountRequested'],
        'XRange': DecimalRangeToJSON(value['xRange']),
        'YRange': DecimalRangeToJSON(value['yRange']),
    };
}

