import { useParams } from "react-router-dom";
import { useAppSelector } from "reducers/store";

import { useDashboardType } from "./useDashboardType";

export function useSelectedRig() {
  const { dashboardId, rigId } = useParams<{
    dashboardId: string;
    rigId: string;
  }>();

  const { isWell, isEvergreen } = useDashboardType();

  const isDashboardForwells = isWell || isEvergreen;

  const reduxRigId = useAppSelector((state) => state.rigDashboard.selectedRig);
  if (rigId) return Number.parseInt(rigId);
  if (dashboardId && !isDashboardForwells) return Number.parseInt(dashboardId);
  if (reduxRigId) return reduxRigId;
  if (isDashboardForwells) return -1;
  throw new Error("Cound not find a rig Id");
}
