import type { WellDrillingSummaryLensResultDto, WellDrillingSummaryUserLensDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { Title } from "atoms/Typography";
import NoData from "components/Lenses/common/NoData/index";
import { GRID_HEIGHT_QUARTER, GRID_WIDTH_QUARTER } from "components/Lenses/constants";
import type { ContainerLensProps } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { getLensOrientation } from "components/Lenses/ContainerLens/common/utils/utils";
import { LensGridContext } from "components/Lenses/utils";
import React, { useContext } from "react";
import { IUnitSystem } from "reducers/types";
import colors from "utils/colors";
import { useUOM } from "utils/format";

import * as Styled from "./styles";
interface WellDrillingProps extends ContainerLensProps {
  data: WellDrillingSummaryLensResultDto;
  lens: WellDrillingSummaryUserLensDto;
}
interface RowData {
  title: string;
  total?: string;
  split?: number;
  variant?: "primary" | "faded";
}

const percentDisplay = (val: number) => `${(val * 100).toFixed(2)}%`;

export const DataColumn: React.FC<RowData & { rows?: RowData[] }> = ({ title, total = "", rows }) => (
  <Styled.InfoGroup>
    <Styled.MainRow justify="space-between">
      <Styled.MainTitle level={3}>{title}</Styled.MainTitle>
      <Styled.Data>{total}</Styled.Data>
    </Styled.MainRow>
    {(rows?.length || 0) > 0 ? (
      <>
        {(rows || []).map(({ total, title, split, variant }) => (
          <Styled.DataRow key={title} justify="space-between">
            <Styled.TitleContainer>
              <Title level={3} variant={"faded"}>
                {title}
                {(split || 0) > 0 && `: ${percentDisplay(split || 0)}`}
              </Title>
              {split || split === 0 ? (
                <Styled.Progress
                  percent={split * 100}
                  strokeColor={variant === "primary" ? colors.well_color : colors.off_state}
                  trailColor={colors.widget_line}
                  showInfo={false}
                />
              ) : null}
            </Styled.TitleContainer>
            <Styled.DataContainer>
              {split || split === 0 ? (
                <Styled.Progress
                  percent={split * 100}
                  strokeColor={variant === "primary" ? colors.well_color : colors.off_state}
                  trailColor={colors.widget_line}
                  showInfo={false}
                />
              ) : null}
              <Styled.Data>{total}</Styled.Data>
            </Styled.DataContainer>
          </Styled.DataRow>
        ))}
      </>
    ) : (
      <>
        <Styled.NoDataText>No data found</Styled.NoDataText>
        <NoData />
      </>
    )}
  </Styled.InfoGroup>
);

export function WellDrillingSummaryChart({
  data: {
    drillingSplits: {
      rotatingDistance,
      slidingDistance,
      rotatingDuration,
      slidingDuration,
      rotatingDistanceSplit,
      slidingDistanceSplit,
      rotatingDurationSplit,
      slidingDurationSplit,
    },
    reamingConnectionCount,
    reamingConnectionRatio,
    reamingDuration,
    totalConnectionCount,
  },
  lens,
}: WellDrillingProps) {
  const { crtLayout, bp } = useContext(LensGridContext);
  const { w: lensWidth, h: lensHeight } = crtLayout?.[bp]?.find(({ i }) => +i === lens.id) || {
    w: GRID_WIDTH_QUARTER,
    h: GRID_HEIGHT_QUARTER,
  };
  const distanceUom = useUOM(DimensionType.Metres);
  const timeUom = useUOM(DimensionType.Seconds);
  const distanceDisplay = (val: number) => distanceUom.display(val, { unit: "" });
  const timeDisplay = (val: number) => timeUom.display(val, { unit: "", factor: 1 / 60 });
  const unit = timeUom.currentUOM === IUnitSystem.METRIC ? "m" : "ft";

  return (
    <Styled.Container
      $orientation={getLensOrientation(lensWidth, lensHeight)}
      // TODO not sure if the new grid breaks anything here
      $height={lensHeight}
      $width={lensWidth}
    >
      <DataColumn
        title={`Distance Drilled (${unit})`}
        total={distanceDisplay(rotatingDistance + slidingDistance)}
        rows={[
          {
            title: `Rotate`,
            total: distanceDisplay(rotatingDistance),
            split: rotatingDistanceSplit,
            variant: "primary",
          },
          {
            title: `Slide`,
            total: distanceDisplay(slidingDistance),
            split: slidingDistanceSplit,
          },
        ]}
      />
      <DataColumn
        title="Hours Drilled"
        total={timeDisplay(rotatingDuration + slidingDuration)}
        rows={[
          {
            title: `Rotate`,
            total: timeDisplay(rotatingDuration),
            split: rotatingDurationSplit,
            variant: "primary",
          },
          {
            title: `Slide`,
            total: timeDisplay(slidingDuration),
            split: slidingDurationSplit,
          },
        ]}
      />
      <DataColumn
        title="Drilling Connections"
        total={`${totalConnectionCount}`}
        rows={[
          {
            title: `With reaming: ${percentDisplay(reamingConnectionRatio)}`,
            total: `${reamingConnectionCount}`,
          },
          { title: `Reaming time (hr):`, total: timeDisplay(reamingDuration) },
        ]}
      />
    </Styled.Container>
  );
}
