/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanDetailsDto
 */
export interface PlanDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof PlanDetailsDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDetailsDto
     */
    startDepth: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDetailsDto
     */
    endDepth: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDetailsDto
     */
    totalDuration: number;
}

/**
 * Check if a given object implements the PlanDetailsDto interface.
 */
export function instanceOfPlanDetailsDto(value: object): value is PlanDetailsDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('startDepth' in value) || value['startDepth'] === undefined) return false;
    if (!('endDepth' in value) || value['endDepth'] === undefined) return false;
    if (!('totalDuration' in value) || value['totalDuration'] === undefined) return false;
    return true;
}

export function PlanDetailsDtoFromJSON(json: any): PlanDetailsDto {
    return PlanDetailsDtoFromJSONTyped(json, false);
}

export function PlanDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'startDepth': json['StartDepth'],
        'endDepth': json['EndDepth'],
        'totalDuration': json['TotalDuration'],
    };
}

export function PlanDetailsDtoToJSON(value?: PlanDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'StartDepth': value['startDepth'],
        'EndDepth': value['endDepth'],
        'TotalDuration': value['totalDuration'],
    };
}

