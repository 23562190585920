import type { IAction } from "./types";

// TODO use UIThemeType
export enum IThemeState {
  LIGHT = "Light",
  DARK = "Dark",
}

export const initialState: IThemeState = (localStorage.getItem("theme") as IThemeState) ?? IThemeState.LIGHT;

interface ISetThemeAction extends IAction {
  type: "SET_THEME";
  payload: {
    theme: IThemeState;
  };
}

type AvailableActions = ISetThemeAction;

function themeReducer(state: IThemeState = initialState, action: AvailableActions): IThemeState {
  switch (action.type) {
    case "SET_THEME":
      return action.payload.theme;
    default:
      return state;
  }
}

export default themeReducer;
