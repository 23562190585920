import { PDComponent } from "components/PDComponents";
import type { ICombinedEvents, IEventRef } from "components/TvDChart/types";
import type { ExtendedEventType } from "reducers/types";
import type { ITimelineEventList } from "utils/eventUtils";

export const eventIcon = (eventT: ExtendedEventType, isDeleted?: boolean) => {
  if (isDeleted) return <PDComponent.SvgIcon name="trashCan" />;
  switch (eventT) {
    case "EndOfTimeline":
      return <PDComponent.SvgIcon name="add" />;
    case "PhaseStart":
      return <PDComponent.SvgIcon name="dotMark" />;
    case "PhaseEnd":
      return <PDComponent.SvgIcon name="dotMark" />;
    case "Section":
      return <PDComponent.SvgIcon name="section" />;
    case "Note":
      return <PDComponent.SvgIcon name="chat" />;
    case "Instruction":
      return <PDComponent.SvgIcon name="task" />;
    case "Hazard":
      return <PDComponent.SvgIcon name="hazard" />;
    case "Warning":
      return <PDComponent.SvgIcon name="warningAlt" />;
    default:
      return null;
  }
};

export const TOP_MARGIN = 30;
export const LEFT_MARGIN = 26;
export const EVENT_SPACE = 20;
export const EVENT_HEIGHT = 28; // TODO declare this in event icon
export const FONT_SIZE = 30;
export const FONT_SIZE_SMALL = 15;
export const EVENT_SPACE_BORDER = 14;

const isSameEventType = ({
  event,
  eventCmp,
}: {
  event: ITimelineEventList | IEventRef | ICombinedEvents;
  eventCmp: ICombinedEvents;
}) => {
  const eventType = (event.eventType || event.type || "").toString();
  const eventCmpType = (eventCmp.eventType ?? eventCmp.type ?? "")?.toString();
  if (
    eventType === eventCmpType ||
    (eventType === "PhaseStart" && eventCmpType === "PhaseEnd") ||
    (eventType === "PhaseEnd" && eventCmpType === "PhaseStart")
  )
    return true;
  return false;
};
export const isSameEvent = ({
  event,
  eventCmp,
}: {
  event: ITimelineEventList | IEventRef | ICombinedEvents | null;
  eventCmp: ICombinedEvents;
}) => {
  if (!event || !eventCmp) return false;
  return (
    event.id === eventCmp.id &&
    isSameEventType({ event, eventCmp })
  );
};
