/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigLeaderboardAco } from './RigLeaderboardAco';
import {
    RigLeaderboardAcoFromJSON,
    RigLeaderboardAcoFromJSONTyped,
    RigLeaderboardAcoToJSON,
} from './RigLeaderboardAco';

/**
 * 
 * @export
 * @interface RigLeaderboardListAco
 */
export interface RigLeaderboardListAco {
    /**
     * 
     * @type {Array<RigLeaderboardAco>}
     * @memberof RigLeaderboardListAco
     */
    rigs: Array<RigLeaderboardAco>;
}

/**
 * Check if a given object implements the RigLeaderboardListAco interface.
 */
export function instanceOfRigLeaderboardListAco(value: object): value is RigLeaderboardListAco {
    if (!('rigs' in value) || value['rigs'] === undefined) return false;
    return true;
}

export function RigLeaderboardListAcoFromJSON(json: any): RigLeaderboardListAco {
    return RigLeaderboardListAcoFromJSONTyped(json, false);
}

export function RigLeaderboardListAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardListAco {
    if (json == null) {
        return json;
    }
    return {
        
        'rigs': ((json['Rigs'] as Array<any>).map(RigLeaderboardAcoFromJSON)),
    };
}

export function RigLeaderboardListAcoToJSON(value?: RigLeaderboardListAco | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Rigs': ((value['rigs'] as Array<any>).map(RigLeaderboardAcoToJSON)),
    };
}

