import type {
  DateDto,
  DirectionalIntervalDto,
  HoleSectionDto,
  KpiGroupUserLensDto,
  KpiPivotFactSetDto,
  SingleStandKpiDto,
  StackedStandKpiComparisonDto,
  UserLensDto,
  WedgeComparisonDto,
  WedgeLensTemplateDto,
  WedgeUserLensDto,
} from "apis/oag";
import { DimensionType, DisplayOption, ResultDataState, StackingType } from "apis/oag";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { useKpiOptions } from "hooks/useKpiOptions";
import type { KpiTypesData } from "hooks/useKpiTypes";
import type { LensTemplate } from "hooks/useLensTemplates";
import {
  isDrillingProductivityLensTemplate,
  isMultipleKpiTemplate,
  isParameterByDepthTemplate,
  isSingleKpiTemplate,
  isWedgeTemplate,
  useLensTemplates,
} from "hooks/useLensTemplates";
import { useUserLenses } from "hooks/useUserLenses";
import type { FC } from "react";
import { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useMixpanel } from "services/Mixpanel";
import styled from "styled-components";
import colors from "utils/colors";
import { Col, Row, Space, Tooltip } from "utils/componentLibrary";
import type { UOMHelper } from "utils/format";
import { dimensionMap } from "utils/format";
import { transformKey } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

const ShiftMapping: { [key: number]: string } = {
  1: "Day",
  2: "Night",
};

export const RotationLensName = "Rotate vs Slide";

export const roundNumberToDecimal = (number: number, decimalPlaces: number = 2) => {
  return number.toLocaleString("en", { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
};

export const LensName = styled.div`
  user-select: none;
  color: ${colors.gray} !important;
  * {
    cursor: pointer;
    user-select: none;
    color: ${colors.gray} !important;
  }
`;

export const LensValue = styled(Title).attrs({
  level: 1,
  weight: 500,
})`
  margin: 0 !important;
`;

export const StyledRow = styled(Row)`
  width: 100%;
  padding: 24px;
`;

interface ILensSummaryView {
  lensId: number;
  wellId: number;
  mainKPIValues: IKPIValue;
  secondaryKPIValues: IKPIValue[];
}

export interface IKPIValue {
  templateName?: string;
  name: string;
  main: string;
  secondary: string;
  lastUpdatedAtUtc?: DateDto | string;
}

export function getDisplayValue(kpi: any, item: UserLensDto, uom: UOMHelper) {
  if (!kpi?.summaryByKpi?.[0] && !kpi?.total && !kpi?.summary?.average) {
    return "0";
  }
  if ((item as KpiGroupUserLensDto).stackingType === StackingType.Distribution)
    return roundNumberToDecimal((kpi.summaryByKpi[0]?.distribution ?? 0) * 100, 2) + "%";

  if (uom.dimension === DimensionType.Undefined || kpi?.summary?.average === undefined) {
    return dimensionMap.Seconds.MetricView(kpi?.total || kpi?.summary?.average || 0, {
      factor: 1 / (60 * 24),
      unit: "days",
    });
  }
  return `${uom.display(kpi.summary?.average)} avg`;
}

export function getComparisonDisplayValue(kpi: any, item: UserLensDto, uom: UOMHelper, isWedge: boolean = false) {
  if (uom.dimension === DimensionType.Undefined || isWedge) {
    return {
      name: kpi?.text ?? "",
      main: dimensionMap.Seconds.MetricView(kpi?.focalAverage, {
        factor: 1 / (60 * 24),
        unit: "days",
      }),
      secondary: `${
        kpi?.comparisonAverageRelativeDiff !== undefined
          ? (kpi?.comparisonAverageRelativeDiff * 100).toFixed(2) + "% · "
          : "- -"
      }
          ${dimensionMap.Seconds.MetricView(kpi?.comparisonAverage, {
            factor: 1 / (60 * 24),
            unit: "days",
          })} avg`,
    };
  }

  return {
    name: kpi?.id ?? "",
    main:
      (item as KpiGroupUserLensDto).stackingType === StackingType.Distribution
        ? `${kpi?.focalDistribution ? roundNumberToDecimal(kpi?.focalDistribution * 100, 2) : "- -"}%`
        : `${uom.display(kpi?.focalAverage)} avg`,
    secondary:
      (item as KpiGroupUserLensDto).stackingType === StackingType.Distribution
        ? `${
            kpi?.comparisonAverageRelativeDiff !== undefined
              ? roundNumberToDecimal(kpi.comparisonDistributionRelativeDiff * 100, 2)
              : "- -"
          }% · ${roundNumberToDecimal((kpi?.comparisonDistribution ?? 0) * 100, 2)}% `
        : `${
            kpi?.comparisonAverageRelativeDiff !== undefined
              ? (kpi.comparisonAverageRelativeDiff * 100).toFixed(2)
              : "- -"
          }% · ${kpi?.comparisonAverage !== undefined ? uom.display(kpi?.comparisonAverage ?? 0) : "- -"} avg`,
  };
}

function getNonComparisonSecondaryValues(kpi: { text: string; value: number }) {
  return {
    name: kpi?.text ?? "",
    main: dimensionMap.Seconds.MetricView(kpi?.value, {
      factor: 1 / (60 * 24),
      unit: "days",
    }),
    secondary: "",
  };
}

// TODO refactoring target, put logic to each lens
export const getMainKPIValues = (
  facts: StackedStandKpiComparisonDto & KpiPivotFactSetDto,
  isComparing: boolean,
  template: LensTemplate,
  userLens: UserLensDto,
  uom: UOMHelper,
): IKPIValue => {
  const breakdownName = (template as WedgeLensTemplateDto)?.breakdowns
    ? ((template as WedgeLensTemplateDto)?.breakdowns || []).find(
        (breakdown) => breakdown.id === (userLens as WedgeUserLensDto).breakdownId,
      )?.name ?? ""
    : "";

  if (!facts) {
    return {
      name: "",
      main: "",
      secondary: "",
      lastUpdatedAtUtc: "",
    };
  }

  if (facts.dataState !== ResultDataState.Valid) {
    return {
      name: template.name || "Undefined",
      main: "- -",
      secondary: "",
      lastUpdatedAtUtc: "- -",
      templateName: isWedgeTemplate(template) ? breakdownName : undefined,
    };
  }

  if (isComparing) {
    if (isSingleKpiTemplate(template) || isDrillingProductivityLensTemplate(template)) {
      return getComparisonDisplayValue(facts.summaryByKpi, userLens, uom);
    }
  } else {
    if (isSingleKpiTemplate(template) || isDrillingProductivityLensTemplate(template)) {
      return {
        name: template?.name || "Undefined",
        main: getDisplayValue(facts, userLens, uom),
        secondary: "",
      };
    }

    if (isParameterByDepthTemplate(template)) {
      return {
        name: (template as LensTemplate)?.name || "Undefined",
        main: getDisplayValue(facts, userLens, uom),
        secondary: "",
      };
    }
  }

  return {
    name: "",
    main: "",
    secondary: "",
    lastUpdatedAtUtc: "",
  };
};

export const getSecondaryKPIValues = (
  facts: StackedStandKpiComparisonDto & KpiPivotFactSetDto & WedgeComparisonDto,
  isComparing: boolean,
  displayOption: DisplayOption | undefined,
  template: LensTemplate | undefined,
  userLens: UserLensDto,
  holeSections: HoleSectionDto[],
  directionalIntervals: DirectionalIntervalDto[],
  uom: UOMHelper,
  kpiTypes?: KpiTypesData,
): IKPIValue[] => {
  if (facts?.dataState !== ResultDataState.Valid || !facts) {
    return [];
  }
  if (isComparing) {
    if (
      isSingleKpiTemplate(template) ||
      isDrillingProductivityLensTemplate(template) ||
      isMultipleKpiTemplate(template)
    ) {
      if (
        template.name === RotationLensName &&
        kpiTypes &&
        (userLens as KpiGroupUserLensDto).stackingType === StackingType.Distribution
      )
        return (facts?.summaryByKpi || [])
          ?.filter((e) => !e.isAggregate)
          .sort((a, b) => a.position - b.position)
          ?.slice(1)
          .map((summary) => ({
            main: `${roundNumberToDecimal((summary?.focalDistribution || 0) * 100, 2)}%`,
            secondary: `${roundNumberToDecimal(
              (summary?.comparisonDistributionRelativeDiff || 0) * 100,
              2,
            )}% · ${roundNumberToDecimal((summary?.comparisonDistribution || 0) * 100, 2)}% `,
            name: transformKey(kpiTypes.byId[summary.id]?.name ?? ""),
          }));

      switch (displayOption) {
        case DisplayOption.HoleSection:
          return (facts.summaryByDisplayOption ?? [])
            .sort((a, b) => a.position - b.position)
            ?.slice(0)
            .map((f) => {
              return getComparisonDisplayValue(
                { ...f, id: holeSections.find((i) => i.id === f.id)?.name },
                userLens,
                uom,
              );
            });

        case DisplayOption.DirectionInterval:
          return (facts.summaryByDisplayOption ?? [])
            .sort((a, b) => a.position - b.position)
            ?.slice(0)
            .map((f) => {
              return getComparisonDisplayValue(
                { ...f, id: directionalIntervals.find((i) => i.id === f.id)?.name },
                userLens,
                uom,
              );
            });

        case DisplayOption.Well:
          if (isMultipleKpiTemplate(template) && kpiTypes) {
            return (facts.summaryByKpi || [])
              ?.filter?.((fact) => !fact.isAggregate)
              .sort((a, b) => a.position - b.position)
              ?.slice((userLens as KpiGroupUserLensDto).stackingType === StackingType.Distribution ? 1 : 0)
              .map((f) => {
                return getComparisonDisplayValue(
                  { ...f, id: transformKey(kpiTypes.byId[f.id]?.name || "") },
                  userLens,
                  uom,
                );
              });
          } else {
            return [];
          }

        case DisplayOption.Shift:
          return (facts.summaryByDisplayOption ?? [])
            .sort((a, b) => a.position - b.position)
            ?.slice(0)
            .map((f) => {
              return getComparisonDisplayValue({ ...f, id: ShiftMapping[f.id] }, userLens, uom);
            });
      }
    }

    if (isWedgeTemplate(template)) {
      return (facts.summaryByKpiNames || []).map((f) => {
        return getComparisonDisplayValue(f, userLens, uom, false);
      });
    }
  } else {
    if (isWedgeTemplate(template)) {
      return (facts.comparisons[0]?.values || []).map((f) => {
        return getNonComparisonSecondaryValues(f);
      });
    }
    if (isSingleKpiTemplate(template) && displayOption === DisplayOption.Shift) {
      return [
        {
          name: "Day",
          main: `${uom.display((facts as unknown as SingleStandKpiDto).detailsByShift.dayValue)} avg`,
          secondary: "",
        },
        {
          name: "Night",
          main: `${uom.display((facts as unknown as SingleStandKpiDto).detailsByShift.nightValue)} avg`,
          secondary: "",
        },
      ];
    }
  }
  return [];
};

const LensSummaryView: FC<ILensSummaryView> = ({ lensId, mainKPIValues, secondaryKPIValues }) => {
  const { data: lenses } = useUserLenses();
  const { data: templates } = useLensTemplates({ refetchOnMount: false });

  const userLens = lenses?.byId[lensId];
  const templateId = userLens?.lensTemplateId;
  const template = templates?.byId[templateId ?? -1];

  const { kpiType, kpiGroup } = useKpiOptions(userLens);
  const params = useParams<{ reportId: string }>();
  const isReport = !!params?.reportId;
  const kpiName = useMemo(() => {
    if (isWedgeTemplate(template)) {
      return (
        template?.breakdowns?.find((breakdown) => breakdown.id === (userLens as WedgeUserLensDto)?.breakdownId)?.name ||
        ""
      );
    }

    if (kpiType) {
      return kpiType?.name ?? "";
    }
    if (kpiGroup) {
      return kpiGroup?.name ?? "";
    }
    return "";
  }, [template, kpiType, kpiGroup, userLens]);

  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();

  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("Lens Detailed", {
        "KPI Name": kpiType?.name || kpiGroup?.name,
        "Lens Name": template?.name,
      });
    }
  }, [viewPage, kpiType?.name, kpiGroup?.name, template?.name]);
  const {
    atomThemeVariant,
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  return (
    <StyledRow gutter={32}>
      <Col flex="0 auto">
        <Space direction="vertical" size={6}>
          {isReport && template?.name !== RotationLensName ? (
            <LensName>{kpiName}</LensName>
          ) : (
            <LensName>{mainKPIValues?.templateName ? mainKPIValues?.templateName : template?.name ?? ""}</LensName>
          )}

          <Space direction="horizontal">
            <LensValue variant={atomThemeVariant}>{mainKPIValues?.main}</LensValue>
            {kpiType?.description ? (
              <Tooltip placement="right" title={kpiType?.description} arrowPointAtCenter>
                {" "}
                <PDComponent.SvgIcon
                  name="information"
                  color={themeColors.primary_typography}
                  style={{ color: themeColors.primary_typography }}
                  width="16"
                  height="16"
                />
              </Tooltip>
            ) : null}
          </Space>

          <LensName>{mainKPIValues?.secondary}</LensName>
        </Space>
      </Col>
      {secondaryKPIValues?.map((e) => (
        <Col flex="0 auto" key={e.name + e.secondary}>
          <Space direction="vertical" size={6}>
            <LensName>{e.name}</LensName>
            <LensValue variant={atomThemeVariant}>{e.main}</LensValue>
            <LensName>{e.secondary}</LensName>
          </Space>
        </Col>
      ))}
    </StyledRow>
  );
};

export default LensSummaryView;
