/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InspectionTvdPointDto } from './InspectionTvdPointDto';
import {
    InspectionTvdPointDtoFromJSON,
    InspectionTvdPointDtoFromJSONTyped,
    InspectionTvdPointDtoToJSON,
} from './InspectionTvdPointDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { StandDrillingInfoDto } from './StandDrillingInfoDto';
import {
    StandDrillingInfoDtoFromJSON,
    StandDrillingInfoDtoFromJSONTyped,
    StandDrillingInfoDtoToJSON,
} from './StandDrillingInfoDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface StandKpiInspectionResultDto
 */
export interface StandKpiInspectionResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof StandKpiInspectionResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof StandKpiInspectionResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof StandKpiInspectionResultDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {number}
     * @memberof StandKpiInspectionResultDto
     */
    wellId: number;
    /**
     * 
     * @type {Array<InspectionTvdPointDto>}
     * @memberof StandKpiInspectionResultDto
     */
    series?: Array<InspectionTvdPointDto> | null;
    /**
     * 
     * @type {StandDrillingInfoDto}
     * @memberof StandKpiInspectionResultDto
     */
    stand: StandDrillingInfoDto;
}



/**
 * Check if a given object implements the StandKpiInspectionResultDto interface.
 */
export function instanceOfStandKpiInspectionResultDto(value: object): value is StandKpiInspectionResultDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('stand' in value) || value['stand'] === undefined) return false;
    return true;
}

export function StandKpiInspectionResultDtoFromJSON(json: any): StandKpiInspectionResultDto {
    return StandKpiInspectionResultDtoFromJSONTyped(json, false);
}

export function StandKpiInspectionResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiInspectionResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'wellId': json['WellId'],
        'series': json['Series'] == null ? undefined : ((json['Series'] as Array<any>).map(InspectionTvdPointDtoFromJSON)),
        'stand': StandDrillingInfoDtoFromJSON(json['Stand']),
    };
}

export function StandKpiInspectionResultDtoToJSON(value?: StandKpiInspectionResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'DataState': ResultDataStateToJSON(value['dataState']),
        'WellId': value['wellId'],
        'Series': value['series'] == null ? undefined : ((value['series'] as Array<any>).map(InspectionTvdPointDtoToJSON)),
        'Stand': StandDrillingInfoDtoToJSON(value['stand']),
    };
}

