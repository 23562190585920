import React from "react";
import { Col, Row } from "utils/componentLibrary";

import * as Styled from "./style";

export const FormGroup: React.FC<{
  label: React.ReactNode;
  rightElement?: React.ReactNode;
  children?: React.ReactNode;
}> = ({ label, rightElement, children }) => {
  return (
    <Styled.FormGroupContainer>
      <Row justify="space-between" style={{ marginBottom: "12px" }}>
        <Col>{typeof label === "string" ? <Styled.FormLabel>{label}</Styled.FormLabel> : label}</Col>
        {rightElement ? <Col>{rightElement}</Col> : null}
      </Row>
      <Row>{children}</Row>
    </Styled.FormGroupContainer>
  );
};
