import type { PlanDto, PlanFormationDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { Button } from "atoms/Form";
import { ActionsHeader, PlanSectionLayout } from "components/Layout/Tabbed";
import { PDComponent } from "components/PDComponents";
import { useCopyFromModal } from "components/WellPlan/WellPlanCommun/CopyFromModal";
import { useFormations } from "hooks/useFormations";
import React from "react";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { Card, Row } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";
import type { IErrorPlanEditor } from "utils/wellplan/errors";

import { FormationRow } from "./FormationRow";

interface FormationProps {
  wellId: number;
  plan?: PlanDto;
  setPlan: React.Dispatch<React.SetStateAction<PlanDto | undefined>>;
  setPlanModified: React.Dispatch<React.SetStateAction<boolean>>;
  errors: IErrorPlanEditor["formation"];
}

const FormationCard = styled(Card)`
  ${PdCardBody} {
    padding: 20px 0px;
  }
  border-radius: 10px;
  border: solid 1px ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;
const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 1fr 1fr 36px;
  grid-gap: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
`;

export function Formation({ wellId, plan, setPlan, setPlanModified, errors }: Readonly<FormationProps>) {
  const { data: formations } = useFormations();

  const wellsApi = new WellsApi(apiConfig);

  const { node: copyFromModalElement, open: openCopyFrom } = useCopyFromModal({
    wellId,
    onCopy: (selectedWellId) => {
      wellsApi.apiWellsIdPlanFormationsGet({ id: selectedWellId }).then((set) => {
        set.formations.forEach((p, i) => (p.id = -(i + 1))); // BE will interpret it as a proper copy
        setPlan((prevPlan) => {
          if (prevPlan?.startFormationDepth) {
            prevPlan.startFormationDepth = set.startDepth;
          }
          return prevPlan ? { ...prevPlan, formations: set.formations } : undefined;
        });
        setPlanModified(true);
      });
    },
    type: "formations",
  });

  if (!plan) {
    return null;
  }

  return (
    <>
      <PlanSectionLayout
        header={
          <ActionsHeader>
            <Row gutter={12} justify="end" align="middle" style={{ height: "64px" }}>
              <Button
                trackingName="Copy From Formations"
                size="large"
                onClick={openCopyFrom}
                icon={<PDComponent.SvgIcon name="migrate" />}
              >
                Copy From
              </Button>
            </Row>
          </ActionsHeader>
        }
        content={
          <FormationCard>
            <HeaderGrid>
              <div>Measured Depth</div>
              <div>True Vertical Depth</div>
              <div>Formation Top</div>
              <div>Pore</div>
              <div>Frac</div>
            </HeaderGrid>
            <FormationRow
              errors={errors}
              formation={{ id: -1, measuredDepth: 0, formationId: undefined }}
              formationOptions={formations.list}
              setPlan={setPlan}
              setPlanModified={setPlanModified}
              plan={plan}
              isFirst
            />
            {plan.formations.map((formation, index, arr) => (
              <FormationRow
                errors={errors}
                key={formation.id}
                formation={formation}
                formationOptions={formations.list}
                setPlan={setPlan}
                setPlanModified={setPlanModified}
                plan={plan}
                prevDepthValue={index === 0 ? plan.startFormationDepth || undefined : arr[index - 1].measuredDepth}
                prevTrueVerticalDepth={index === 0 ? undefined : arr[index - 1].trueVerticalDepth || undefined}
              />
            ))}
            <Button
              size="large"
              trackingName="Add Formation"
              style={{ width: "calc(100% - 40px)", margin: "0px 20px", color: colors.well_color }}
              onClick={() => {
                Track.interact("Formations - Add Record");

                setPlan((prevPlan) => {
                  if (prevPlan) {
                    const minNegativeId = prevPlan.formations
                      .map((formation) => formation.id)
                      .filter((id) => id < 0)
                      .reduce((a: number, b: number) => Math.min(a, b), 0);

                    const newFormation: PlanFormationDto = {
                      id: minNegativeId - 1,
                      measuredDepth: 0,
                      trueVerticalDepth: null,
                      formationId: 1,
                    };
                    return {
                      ...prevPlan,
                      formations: [...prevPlan.formations, newFormation],
                    };
                  }
                });
              }}
            >
              <PDComponent.SvgIcon name="plusOutline" />
              Add record
            </Button>
          </FormationCard>
        }
      />
      {copyFromModalElement}
    </>
  );
}
