/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BasinAreaDto
 */
export interface BasinAreaDto {
    /**
     * 
     * @type {number}
     * @memberof BasinAreaDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BasinAreaDto
     */
    type: string;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof BasinAreaDto
     */
    coordinates: Array<Array<number>>;
}

/**
 * Check if a given object implements the BasinAreaDto interface.
 */
export function instanceOfBasinAreaDto(value: object): value is BasinAreaDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('coordinates' in value) || value['coordinates'] === undefined) return false;
    return true;
}

export function BasinAreaDtoFromJSON(json: any): BasinAreaDto {
    return BasinAreaDtoFromJSONTyped(json, false);
}

export function BasinAreaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasinAreaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'type': json['Type'],
        'coordinates': json['Coordinates'],
    };
}

export function BasinAreaDtoToJSON(value?: BasinAreaDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Type': value['type'],
        'Coordinates': value['coordinates'],
    };
}

