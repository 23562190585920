/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BasinAreaDto } from './BasinAreaDto';
import {
    BasinAreaDtoFromJSON,
    BasinAreaDtoFromJSONTyped,
    BasinAreaDtoToJSON,
} from './BasinAreaDto';

/**
 * 
 * @export
 * @interface BasinDto
 */
export interface BasinDto {
    /**
     * 
     * @type {number}
     * @memberof BasinDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BasinDto
     */
    name: string;
    /**
     * 
     * @type {Array<BasinAreaDto>}
     * @memberof BasinDto
     */
    basinAreas: Array<BasinAreaDto>;
}

/**
 * Check if a given object implements the BasinDto interface.
 */
export function instanceOfBasinDto(value: object): value is BasinDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('basinAreas' in value) || value['basinAreas'] === undefined) return false;
    return true;
}

export function BasinDtoFromJSON(json: any): BasinDto {
    return BasinDtoFromJSONTyped(json, false);
}

export function BasinDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasinDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'basinAreas': ((json['BasinAreas'] as Array<any>).map(BasinAreaDtoFromJSON)),
    };
}

export function BasinDtoToJSON(value?: BasinDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'BasinAreas': ((value['basinAreas'] as Array<any>).map(BasinAreaDtoToJSON)),
    };
}

