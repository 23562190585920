import type { UseMutationResult } from "@tanstack/react-query";
import { useIsFetching } from "@tanstack/react-query";
import type { UserLensDto, VisualAidType } from "apis/oag";
import { DisplayOption } from "apis/oag";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { indicatorNames } from "components/DetailsTopBar/utils";
import { OrderedVisualAidType } from "components/Lenses/CommonLensSettingsModalContents";
import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import type { AbilityMatrixType } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";
import { getContainerLens } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { checkActiveState } from "components/Lenses/utils";
import OffsetWellSelector from "components/OffsetWellSelector";
import { PDComponent } from "components/PDComponents";
import { initialFiltersState } from "components/RigDashboard/ControlHeader";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { ZoomButton } from "components/WellDashboard/ControlHeader/atoms/Zoom/ZoomButton";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useFiltersLoading } from "hooks/useFiltersLoading";
import { isDrillingProductivityLensTemplate, useLensTemplates } from "hooks/useLensTemplates";
import { isEqual, xor } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { initialFilters, type IFiltersType, type IZoomData } from "reducers/types";
import { comparisonDisplayOptions, IIndicators, singleWellDisplayOptions } from "reducers/widgetReducer";
import { Track } from "services/Mixpanel";
import { Checkbox, Col, Popover, Radio, Row, Space, Tooltip } from "utils/componentLibrary";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

function Well({
  abilities,
  lens,
  onLensUpdated,
  setZoomOpen,
  isFiltersOpen,
  setIsFiltersOpen,
  handleLensUpdate,
}: {
  abilities: AbilityMatrixType;
  lens: UserLensDto & {
    showsOutliers?: boolean;
    squeezesDisplay?: boolean;
    selectedVisualAids?: VisualAidType[];
  };
  onLensUpdated?: (newLens: UserLensDto) => void;
  setZoomOpen: (value: React.SetStateAction<boolean>) => void;
  isFiltersOpen: boolean;
  setIsFiltersOpen: (value: React.SetStateAction<boolean>) => void;
  handleLensUpdate: UseMutationResult<
    UserLensDto,
    unknown,
    UserLensDto & {
      showsOutliers?: boolean;
      squeezesDisplay?: boolean;
      selectedVisualAids?: VisualAidType[];
    },
    unknown
  >;
}) {
  const [visualAidsVisible, setVisualAidsVisible] = useState(false);
  const [indicatorsVisible, setIndicatorsVisible] = useState(false);
  const [displayOptionsVisible, setDisplayOptionsVisible] = useState(false);

  const visualAidsState = lens?.selectedVisualAids || [];
  const indicatorsState = useAppSelector((state) => state.widgetOptions.indicators);
  const displayOption = useAppSelector((state) => state.widgetOptions.display_options);
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);
  const [zoomData] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);
  const [filtersState] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, initialFilters);
  const { availableFilters, filtersLoading } = useFiltersLoading();

  const zoomActiveState = !isEqual(initialZoomData, zoomData);
  const filtersActiveState = checkActiveState(availableFilters, filtersState);
  const dispatch = useAppDispatch();

  const { data: templates } = useLensTemplates();
  const isFetchingZoom = useIsFetching({ queryKey: [{ type: PDQueryType.WELL_OVERVIEW_ZOOM }] });

  const { atomThemeVariant } = useCustomTheme();

  const ContainerLensInfo = useMemo(
    () => getContainerLens(templates?.byId[lens.lensTemplateId]?.type),
    [lens.lensTemplateId, templates?.byId],
  );

  const displayOptionToString = useCallback(
    (displayOption: DisplayOption) => {
      if (!lens?.lensTemplateId || !templates?.byId) return "";
      const template = templates.byId[lens.lensTemplateId];

      switch (displayOption) {
        case DisplayOption.Date:
          return "Day";
        case DisplayOption.DateShift:
          return "Day/Shift";
        case DisplayOption.DirectionInterval:
          return "Directional Intervals";
        case DisplayOption.HoleSection:
          return "Hole Sections";
        case DisplayOption.Shift:
          return isDrillingProductivityLensTemplate(template) ? "Well/Shift" : "Shift (Day/Night)";
        case DisplayOption.StandDepth:
          return isDrillingProductivityLensTemplate(template) ? "Date (Event)" : "Stand (Event)";
        case DisplayOption.Well:
          return "Well over Well";
        default:
          return "";
      }
    },
    [templates?.byId, lens?.lensTemplateId],
  );

  return (
    <Space>
      {abilities.comparison !== MatrixAvailability.NOT_SUPPORTED && <OffsetWellSelector lens />}

      {/* ------------------------------------------- */}
      {abilities.selectedVisualAids !== MatrixAvailability.NOT_SUPPORTED && (
        <Tooltip title="Visual Aids">
          <Popover
            content={
              <Space
                direction="vertical"
                style={{
                  padding: 12,
                }}
              >
                <Title level={3} variant={atomThemeVariant} weight={500}>
                  Visual Aids
                </Title>
                <Checkbox.Group
                  value={visualAidsState}
                  onChange={async (e) => {
                    Track.interact("Lens - Visual Aids", {
                      Selection: e,
                    });
                    // TODO check this
                    await handleLensUpdate.mutateAsync({
                      ...lens,
                      selectedVisualAids: e as VisualAidType[],
                    });
                  }}
                  style={{ maxWidth: "250px" }}
                >
                  <Row gutter={[0, 6]}>
                    {Object.keys(OrderedVisualAidType)
                      .filter((e) => e !== OrderedVisualAidType.None)
                      .map((e) => {
                        return (
                          <Col span={24} key={e}>
                            <Checkbox value={e}>{e}</Checkbox>
                          </Col>
                        );
                      })}
                  </Row>
                </Checkbox.Group>
              </Space>
            }
            trigger={abilities.selectedVisualAids === MatrixAvailability.NOT_AVAILABLE ? [] : ["click"]}
            placement="bottom"
            open={visualAidsVisible}
            onOpenChange={(e) => setVisualAidsVisible(e)}
            destroyTooltipOnHide
          >
            <Button
              icon={<PDComponent.SvgIcon name="cdArchive" />}
              disabled={abilities.selectedVisualAids === MatrixAvailability.NOT_AVAILABLE}
              $engaged={visualAidsVisible}
              type={visualAidsState?.length > 0 ? "primary" : "default"}
              ghost={visualAidsState?.length > 0}
            />
          </Popover>
        </Tooltip>
      )}

      {ContainerLensInfo?.SpecificTopbarSettings ? (
        <ContainerLensInfo.SpecificTopbarSettings lens={lens} setLocalLens={onLensUpdated} />
      ) : null}

      {abilities.indicators !== MatrixAvailability.NOT_SUPPORTED && (
        <Tooltip title="Indicators">
          <Popover
            content={
              <Space
                direction="vertical"
                style={{
                  padding: 12,
                }}
              >
                <Title level={3} variant={atomThemeVariant} weight={500}>
                  Indicators
                </Title>
                <Checkbox.Group
                  value={indicatorsState}
                  onChange={(e) => {
                    Track.interact("Lens - Indicators", {
                      Selection: (e as IIndicators[]).map((indicator) => indicatorNames[indicator]),
                    });
                    dispatch({
                      type: "SET_INDICATORS_WIDGET",
                      payload: {
                        indicators: e as IIndicators[],
                      },
                    });
                  }}
                  style={{ maxWidth: "250px" }}
                >
                  <Row gutter={[0, 6]}>
                    {Object.keys(IIndicators).map(
                      (e) =>
                        indicatorNames[e as IIndicators] && (
                          <Col span={24} key={e}>
                            <Checkbox value={e}>{indicatorNames[e as IIndicators]}</Checkbox>
                          </Col>
                        ),
                    )}
                  </Row>
                </Checkbox.Group>
              </Space>
            }
            trigger={abilities.indicators === MatrixAvailability.NOT_AVAILABLE ? [] : ["click"]}
            placement="bottom"
            open={indicatorsVisible}
            onOpenChange={(e) => setIndicatorsVisible(e)}
            destroyTooltipOnHide
          >
            <Button
              size="large"
              icon={<PDComponent.SvgIcon name="tagGroup" />}
              disabled={
                abilities.indicators === MatrixAvailability.NOT_AVAILABLE || displayOption === DisplayOption.Shift
              }
              $engaged={indicatorsVisible}
              type={indicatorsState?.length > 0 ? "primary" : "default"}
              ghost={indicatorsState?.length > 0}
            />
          </Popover>
        </Tooltip>
      )}

      {abilities.display_options !== MatrixAvailability.NOT_SUPPORTED && (
        <Tooltip title="Display Options">
          <Popover
            content={
              <Space
                direction="vertical"
                style={{
                  padding: "12px 16px",
                }}
              >
                <Title level={3} variant={atomThemeVariant} weight={500}>
                  Display Options
                </Title>
                <Radio.Group
                  onChange={(e) => {
                    Track.interact("Lens - Display Options", {
                      Selection:
                        e.target.value
                          ?.split?.("_")
                          ?.map?.((e: string) => `${e[0].toUpperCase()}${e.slice(1)}`)
                          ?.join?.(" ") ?? "",
                    });
                    dispatch({
                      type: "SET_DISPLAY_OPTIONS_WIDGET",
                      payload: {
                        display_options: e.target.value,
                      },
                    });
                  }}
                  value={displayOption}
                >
                  <Space direction="vertical" size={6}>
                    {(comparisonWells.length > 0 ? comparisonDisplayOptions : singleWellDisplayOptions).map(
                      (displayOption) => (
                        <Radio key={displayOption} value={displayOption}>
                          {displayOptionToString(displayOption)}
                        </Radio>
                      ),
                    )}
                  </Space>
                </Radio.Group>
              </Space>
            }
            trigger={abilities.display_options === MatrixAvailability.NOT_AVAILABLE ? [] : ["click"]}
            placement="bottom"
            open={displayOptionsVisible}
            onOpenChange={(e) => setDisplayOptionsVisible(e)}
            destroyTooltipOnHide
          >
            <Button
              size="large"
              icon={<PDComponent.SvgIcon name="view" />}
              disabled={abilities.display_options === MatrixAvailability.NOT_AVAILABLE}
              $engaged={displayOptionsVisible}
            />
          </Popover>
        </Tooltip>
      )}

      {abilities.zoom !== MatrixAvailability.NOT_SUPPORTED && (
        <Tooltip title="Zoom">
          <ZoomButton
            onClick={() => setZoomOpen(true)}
            disabled={isFetchingZoom !== 0 && abilities.zoom === MatrixAvailability.NOT_AVAILABLE}
            isActive={zoomActiveState}
          />
        </Tooltip>
      )}

      {abilities.filters !== MatrixAvailability.NOT_SUPPORTED ? (
        <Tooltip title="Filter">
          <Button
            size="large"
            icon={<PDComponent.SvgIcon name="filter" />}
            onClick={() => setIsFiltersOpen((fl: boolean) => !fl)}
            disabled={abilities.filters === MatrixAvailability.NOT_AVAILABLE}
            $engaged={isFiltersOpen}
            loading={filtersLoading}
            type={filtersActiveState ? "primary" : "default"}
            ghost={filtersActiveState}
          />
        </Tooltip>
      ) : null}
    </Space>
  );
}

export default Well;
