import { baseMatrixNotAvailable, MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import type { AbilityMatrixType } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export const getStickSlipByDepthAbilityMatrix = (): AbilityMatrixType => {
  return {
    ...baseMatrixNotAvailable,
    squeezesDisplay: MatrixAvailability.AVAILABLE,
    lensSettings: MatrixAvailability.AVAILABLE,
    showZTorque: MatrixAvailability.AVAILABLE,
    addUserTracks: MatrixAvailability.AVAILABLE,
    hasDetailedView: MatrixAvailability.AVAILABLE,
    legend: MatrixAvailability.AVAILABLE,
    filters: MatrixAvailability.AVAILABLE,
    comparison: MatrixAvailability.AVAILABLE,
    zoom: MatrixAvailability.AVAILABLE,
    zoomByTime: MatrixAvailability.AVAILABLE,
    zoomByDate: MatrixAvailability.AVAILABLE,
  };
};
