/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TagBottomFingerprintUserLensTrackItemDto } from './TagBottomFingerprintUserLensTrackItemDto';
import {
    TagBottomFingerprintUserLensTrackItemDtoFromJSON,
    TagBottomFingerprintUserLensTrackItemDtoFromJSONTyped,
    TagBottomFingerprintUserLensTrackItemDtoToJSON,
} from './TagBottomFingerprintUserLensTrackItemDto';

/**
 * 
 * @export
 * @interface TagBottomFingerprintUserLensDto
 */
export interface TagBottomFingerprintUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {Array<TagBottomFingerprintUserLensTrackItemDto>}
     * @memberof TagBottomFingerprintUserLensDto
     */
    userLensTrackItems: Array<TagBottomFingerprintUserLensTrackItemDto>;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensDto
     */
    standCount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    showOverallView: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    showAlphaComparison: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagBottomFingerprintUserLensDto
     */
    isBithDepthNormalized: boolean;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintUserLensDto
     */
    normalizationReferenceBitDepth?: number | null;
}

/**
 * Check if a given object implements the TagBottomFingerprintUserLensDto interface.
 */
export function instanceOfTagBottomFingerprintUserLensDto(value: object): value is TagBottomFingerprintUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('squeezesDisplay' in value) || value['squeezesDisplay'] === undefined) return false;
    if (!('userLensTrackItems' in value) || value['userLensTrackItems'] === undefined) return false;
    if (!('showOverallView' in value) || value['showOverallView'] === undefined) return false;
    if (!('showAlphaComparison' in value) || value['showAlphaComparison'] === undefined) return false;
    if (!('isBithDepthNormalized' in value) || value['isBithDepthNormalized'] === undefined) return false;
    return true;
}

export function TagBottomFingerprintUserLensDtoFromJSON(json: any): TagBottomFingerprintUserLensDto {
    return TagBottomFingerprintUserLensDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'userLensTrackItems': ((json['UserLensTrackItems'] as Array<any>).map(TagBottomFingerprintUserLensTrackItemDtoFromJSON)),
        'standCount': json['StandCount'] == null ? undefined : json['StandCount'],
        'showOverallView': json['ShowOverallView'],
        'showAlphaComparison': json['ShowAlphaComparison'],
        'isBithDepthNormalized': json['IsBithDepthNormalized'],
        'normalizationReferenceBitDepth': json['NormalizationReferenceBitDepth'] == null ? undefined : json['NormalizationReferenceBitDepth'],
    };
}

export function TagBottomFingerprintUserLensDtoToJSON(value?: TagBottomFingerprintUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'SqueezesDisplay': value['squeezesDisplay'],
        'UserLensTrackItems': ((value['userLensTrackItems'] as Array<any>).map(TagBottomFingerprintUserLensTrackItemDtoToJSON)),
        'StandCount': value['standCount'],
        'ShowOverallView': value['showOverallView'],
        'ShowAlphaComparison': value['showAlphaComparison'],
        'IsBithDepthNormalized': value['isBithDepthNormalized'],
        'NormalizationReferenceBitDepth': value['normalizationReferenceBitDepth'],
    };
}

