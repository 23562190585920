const colors = {
  transparent: "transparent",
  white: "#FFFFFF",
  black: "#000000",
  faded_bg: "rgba(32, 43, 48, 0.6)",
  default_bg: "#F1F1F3",
  default_bg_transparent: "#F1F1F366",
  black_bg_transparent: "#00000073",
  actions_bg: "#F7F7F9",
  soft_white: "rgba(255, 255, 255, 0.04)",
  neutral: "#131B1D",
  gray: "#828C91",
  light_gray: "#E0E8EA",
  pale_grey: "#EDF2F3",
  off_white: "#FAFBFB",
  off_grey: "#E8EBEC",
  gray_cta: "#8d9599",
  neutral_white: "#FDFDFD",
  evening_white: "#d9dbd8",
  tern_grey: "#D4CDBD",
  bauhaus: "#40413e",
  lady_nicole: "#D5D6CC",
  crystal_brooke: "#e4e5dc",
  ravenwood: "#484643",
  laid_back_grey: "#B3AFA8",
  volcanic_rock: "#6b6965",
  rampart: "#bdb9b3",
  hulett_ore: "#716f6b",
  private_black: "#4c4a48",
  gazebo_grey: "#d0d0cc",
  dry_mud: "#777572",
  subtle_touch: "#dadbd9",
  burnished_metal: "#cacac7",
  charadon_granite: "#4f4e4c",
  tuatara: "#454542",
  overdue_grey: "#c6c3be",
  positive_green_light: "#C6F2D8",
  negative_red_light: "#F2D6D3",
  positive_green_dark: "#78A389",
  negative_red_dark: "#98736E  ",
  off_state: "#C8CED0",
  complex_blue: "#5B5BEA",
  dark_green: "#0A7462",
  soft_green: "#82c9be",
  light_green: "#67BEB0",
  well_color: "#0D9B83",
  well_color_light: "#86CDC1",
  well_color_dark: "#057865",
  well_color_transparent: "#0D9b8366",
  separation_line: "#EBEEF1",
  buttons_clicked: "rgba(0, 0, 0, 0.12)",
  soft_shadow: "rgba(0, 0, 0, 0.16)",
  widget_line: "#E8E8EA",
  error_color: "#C32D2D",
  error: "#C32D2D",
  whisky: "#C4877D",
  tripping: "#18A6B8",
  casing: "#8E7E78",
  hover_secondary: "#D6DEE1",
  positive_light_hover: "rgba(137, 206, 194, 0.2)",
  positive_dark_hover: "rgba(137, 206, 194, 0.2)",
  error_transparent: "rgba(195, 45, 45, 0.4)",
  bottom_line_widget: "rgba(0, 0, 0, 0.06)",
  secondary_transparent_black: "rgba(0, 0, 0, 0.16)",
  outlier_light_transparent: "rgba(27, 27, 31, 0.25)",
  outliers_dark_transparent: "rgba(247, 247, 247, 0.05)",
  labels_light_transparent: "rgba(255, 255, 255, 0.5)",
  labels_dark_transparent: "rgba(38, 38, 42, 0.5)",
  dark_soft_shadow: "rgba(0, 0, 0, 0.12)",
  light_soft_shadow: "rgba(0, 0, 0, 0.5)",
  // https://color-name-generator.com/
  diva: "#C0A8FB",
  noble_black: "#212225",
  bluetiful: "#2E70E8",
  brilliant_white: "#edf3ff",
  violet_white: "#e1e3e9",
  livid: "#6982d7",
  pink_mimosa: "#F4B5AB",
  seagreen: "#2EA2A9",
  sunburnt_toes: "#d69985",
  battleship_grey: "#707277",
  liberty_blue: "#0f172a",
  midnight_express: "#1e293b",
  frozen_tundra: "#a3bec8",
  necron_compound: "#828c91",
  necron_compound_light: "rgba(130, 140, 145, 0.14)",
  spring_thaw: "#d8dcde",
  misty_gray: "#c0cacc",
  gluon_grey: "#1b1b1f",
  gluon_grey_transparent: "#1b1b1f66",
  bastille: "#2B2B32",
  castlerock: "#5f5f62",
  tahoe_snow: "#d7e1e4",
  weathervane: "#281e1b",
  black_cat: "#2e2e32",
  transparent_black: "rgba(0, 0, 0, 0.85)",
  shutter_black: "#26262a",
  revolver: "#36363d",
  limo_scene: "#4a4a50",
  black_bay: "#48484f",
  blood_burst: "#ff4d4f",
  luigi: "#52c41a",
  debonair: "#8e9ea4",
  orochimaru_grey: "#d9d9d9",
  thermocline_grey: "#8bb0ba",
  jaguar_gray: "#2a2a30",
  tanzine: "#8289D5",
  waterfall: "#3eaf9c",
  perrywinkle: "#9e83e1",
  girl_power: "#d399cf",
  balanced_beige: "#c0b1a2",
  aurora_magenta: "#943c5c",
  lunar_rays: "#c8ced0",
  park_picnic: "#439545",
  fresco_green: "#79dea2",
  marble_green_grey: "#879290",
  flickering_sea: "#5b5bea",
  infinity_transparent: "#202B304D",
  infinity_black: "#202b30",
  grey_heather: "#878792",
  exciting_orange: "#f2b07a",
  tanager_turquoise: "#8edbeb",
  helen_of_troy: "#c2b8a0",
  turtle_green: "#71BF4B",
  green_fluorite: "#56b9a8",
  perfect_pear: "#e9e7ba",
  prettiest_pink: "#e4a1c9",
  charybdis: "#13a9ca",
  green_chalk: "#bbdd90",
  coral_expression: "#d66e6b",
  flamboyant_teal: "#0d9b83",
  brilliance: "rgba(253, 253, 253, 0.5)",
  gunmetal_beige: "#918782",
  alpine_landing: "#117481",
  dryad_bark: "#32302d",
  shoe_wax: "#2c2a2c",
  chalk: "#edeae4",
  classic_chalk: "#f4f3f0",
  bleached_silk: "#F3F3F3",
  celestial_glow: "#E9EBEA",
  callisto: "#C9CED4",
  light_soft_celadon: "#CFDBD5",
  aswad_black: "#17171C",
  darth_vader: "#26262A",
  lilac_champagne: "#dfe1e6",
  bamboo_charcoal: "#454b48",
  sheet_metal: "#5e6064",
  coated: "#2d3130",
  hugo: "#E8CFCC",
  aphrodite_aqua: "#3FE3BC",
  lifeless_green: "#01D8A5",
  iridescent_turquoise: "#70fbc6",
  acid_pops: "#32ed72",
  aloha: "#17BB9F",
  yellow_sumac: "#E69448",
  golden_samovar: "#D8B22B",
};
export default colors;
