import type { ApiGhgEmissionsUserLensesIdFactsPutRequest } from "apis/oag/apis/GhgEmissionsUserLensesApi";
import { useGHGEmissionsFacts } from "hooks/lens-facts/useGHGEmissionsFacts";
import { useFilterParams } from "hooks/useFilterParams";
import { isNull } from "lodash";

interface UseGHGEmissionsFetcherParams {
  lensId: number;
}

export function useGHGEmissionsFetcher({ lensId }: UseGHGEmissionsFetcherParams) {
  const filterParams = useFilterParams();
  const requestParameters: ApiGhgEmissionsUserLensesIdFactsPutRequest = {
    id: lensId,
    ghgEmissionsQueryDto: filterParams,
  };

  const request = useGHGEmissionsFacts(lensId, requestParameters, {
    enabled: !isNull(lensId),
    refetchOnMount: false,
  });

  return { request };
}
