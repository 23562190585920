import type { ApiBatteryStateUserLensesIdFactsPutRequest } from "apis/oag";
import { useBatteryStateFacts } from "hooks/lens-facts/useBatteryStateFacts";
import { useFilterParams } from "hooks/useFilterParams";

interface UseBatteryStateFetcherParams {
  lensId: number;
}

export function useBatteryStateFetcher({ lensId }: UseBatteryStateFetcherParams) {
  const filterParams = useFilterParams();

  const requestParameters: ApiBatteryStateUserLensesIdFactsPutRequest = {
    id: lensId ?? -1,
    baseFocalQueryDto: { ...filterParams, comparisonWellIds: [] },
  };

  const request = useBatteryStateFacts(lensId, requestParameters, {
    enabled: !!lensId,
  });

  return request;
}
