import { useWellDetails } from "hooks/useWellDetails";
import moment from "moment";
import { msInMin } from "utils/helper";

import { useSelectedWell } from "./useSelectedWell";

export function useDisplayLocalWellTime() {
  const selectedWell = useSelectedWell();
  const { data: wellInfo } = useWellDetails(selectedWell);
  const fallbackOffset = wellInfo?.drillingDetails?.lastWellFactUpdateAt?.minutesOffset || 0;

  function displayInLocalWellTime(time: Date, offsetMinutes: number | null) {
    const fallbackOffsetMinutes = offsetMinutes === null ? fallbackOffset : offsetMinutes;
    return moment(time.getTime() + fallbackOffsetMinutes * msInMin + new Date().getTimezoneOffset() * msInMin);
  }

  function utcFromWellOffset(time: Date, offsetMinutes: number | null) {
    return moment(time.getTime() - (offsetMinutes ?? fallbackOffset) * msInMin - new Date().getTimezoneOffset() * msInMin);
  }

  return { displayInLocalWellTime, utcFromWellOffset };
}
