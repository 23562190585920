/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseFocalQueryDto,
  BatteryStateResultDto,
  BatteryStateUserLensDto,
} from '../models/index';
import {
    BaseFocalQueryDtoFromJSON,
    BaseFocalQueryDtoToJSON,
    BatteryStateResultDtoFromJSON,
    BatteryStateResultDtoToJSON,
    BatteryStateUserLensDtoFromJSON,
    BatteryStateUserLensDtoToJSON,
} from '../models/index';

export interface ApiBatteryStateUserLensesIdFactsPutRequest {
    id: number;
    baseFocalQueryDto?: BaseFocalQueryDto;
}

export interface ApiBatteryStateUserLensesIdPutRequest {
    id: number;
    batteryStateUserLensDto?: BatteryStateUserLensDto;
}

export interface ApiBatteryStateUserLensesPostRequest {
    batteryStateUserLensDto?: BatteryStateUserLensDto;
}

/**
 * 
 */
export class BatteryStateUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiBatteryStateUserLensesIdFactsPutRaw(requestParameters: ApiBatteryStateUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatteryStateResultDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiBatteryStateUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/BatteryStateUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFocalQueryDtoToJSON(requestParameters['baseFocalQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatteryStateResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiBatteryStateUserLensesIdFactsPut(requestParameters: ApiBatteryStateUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatteryStateResultDto> {
        const response = await this.apiBatteryStateUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBatteryStateUserLensesIdPutRaw(requestParameters: ApiBatteryStateUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatteryStateUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiBatteryStateUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/BatteryStateUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BatteryStateUserLensDtoToJSON(requestParameters['batteryStateUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatteryStateUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiBatteryStateUserLensesIdPut(requestParameters: ApiBatteryStateUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatteryStateUserLensDto> {
        const response = await this.apiBatteryStateUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBatteryStateUserLensesPostRaw(requestParameters: ApiBatteryStateUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BatteryStateUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/BatteryStateUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatteryStateUserLensDtoToJSON(requestParameters['batteryStateUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatteryStateUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiBatteryStateUserLensesPost(requestParameters: ApiBatteryStateUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BatteryStateUserLensDto> {
        const response = await this.apiBatteryStateUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
