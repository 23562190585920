import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import type { GeneratorLoadProfileFactDto, GeneratorSlotType } from "apis/oag";
import { DimensionType } from "apis/oag";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { addIndexToSeries, getGeneratorLabelsBySlotType } from "components/Lenses/ContainerLens/common/utils/utils";
import { bisector } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { OnRefChangeType } from "react-resize-detector/build/types/types";
import { ALTERNATIVE_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { CAPACITY_ID, GEN_POWER_ID } from "./utils";

type GeneratorLoadProfileFactDtoWithIndex = GeneratorLoadProfileFactDto & { index: number };

export const useGeneratorLoadProfileTooltip = ({
  containerRef,
  legendItems,
  capacityBottomY,
  capacityTopY,
  xScale,
  selectedGenerator,
  yScalePrimary,
  series: unorderedSeries,
  isPointerInsideChart,
  plotWidth,
  plotHeight,
}: {
  containerRef: OnRefChangeType<HTMLDivElement> | React.MutableRefObject<HTMLDivElement>;
  legendItems: LegendItem[];
  capacityBottomY: number;
  capacityTopY: number;
  xScale: ScaleLinear<number, number, never>;
  selectedGenerator: null | Array<GeneratorSlotType>;
  yScalePrimary: ScaleLinear<number, number, never>;
  series: GeneratorLoadProfileFactDto[];
  isPointerInsideChart: boolean;
  plotWidth: number;
  plotHeight: number;
}) => {
  const series = useMemo(() => addIndexToSeries(unorderedSeries), [unorderedSeries]);

  const [pointerPosition, setPointerPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const yScalePrimaryUOM = useUOM(DimensionType.Watts);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const handlePointerMove = useCallback((event: React.PointerEvent<SVGSVGElement>) => {
    const { left, top } = event.currentTarget.getBoundingClientRect();
    const x = ("clientX" in event ? event.clientX : 0) - left;
    const y = ("clientY" in event ? event.clientY : 0) - top;
    setPointerPosition({ x, y });
  }, []);

  const isGenPowerEnabled = legendItems.find((legendItem) => legendItem.id === GEN_POWER_ID)?.isEnabled;

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent: ({ tooltipData }: { tooltipData?: GeneratorLoadProfileFactDtoWithIndex }) => {
      return (
        <>
          {legendItems.find((legendItem) => legendItem.id === GEN_POWER_ID)?.isEnabled && tooltipData?.value ? (
            <TooltipHighlightValue style={{ fontSize: 12 }}>
              {selectedGenerator ? `${getGeneratorLabelsBySlotType(selectedGenerator)}: ` : ""}
              {yScalePrimaryUOM.display(tooltipData.value, {
                fractionDigits: 0,
              })}
            </TooltipHighlightValue>
          ) : null}

          {legendItems.find((legendItem) => legendItem.id === CAPACITY_ID)?.isEnabled && tooltipData?.availablePower ? (
            <TooltipHighlightValue style={{ fontSize: 12, color: themeColors.disabled_typography }}>
              60%-80% Capacity: {yScalePrimaryUOM.display(capacityBottomY, { fractionDigits: 0, unit: "" })}-{" "}
              {yScalePrimaryUOM.display(capacityTopY, { fractionDigits: 0 })}
            </TooltipHighlightValue>
          ) : null}

          {tooltipData?.at ? (
            <TooltipHighlightValue style={{ color: themeColors.disabled_typography, fontSize: 12 }}>
              {formatTime(tooltipData.at, { formatStr: ALTERNATIVE_DATE_FORMAT })}
            </TooltipHighlightValue>
          ) : null}
        </>
      );
    },
  });

  const tooltipPoint: GeneratorLoadProfileFactDtoWithIndex | null = useMemo(() => {
    if (!series) return null;

    if (pointerPosition.x > plotWidth) {
      return null;
    }

    const x0 = xScale.invert(pointerPosition.x);

    let extremitiesBisectFix = 0;

    if (pointerPosition.x < 30) {
      // To make sure we can select both points at the edge of the graph, normal  bisect leaves out either one or the other
      // Artificially adding mouse X if it's close to the plot edges to get correct edge index
      extremitiesBisectFix = -0.001;
    } else if (Math.abs(pointerPosition.x - plotWidth) < 10) {
      extremitiesBisectFix = 0.001;
    }

    const index = bisector<GeneratorLoadProfileFactDtoWithIndex, number>((p) => p.index).left(
      series,
      x0 + extremitiesBisectFix,
    );

    if (index >= 0 && index < series.length && series[index].index) {
      return series[index];
    }

    return null;
  }, [plotWidth, pointerPosition.x, series, xScale]);

  useEffect(() => {
    const top = Math.max(
      (Number.isFinite(tooltipPoint?.value)
        ? yScalePrimary((isGenPowerEnabled && tooltipPoint?.value) || 0)
        : Infinity) || Infinity, // protect against NAN
      0,
    );

    return isPointerInsideChart && tooltipPoint
      ? showTooltip({
          tooltipLeft: xScale(tooltipPoint.index) || 0,
          tooltipTop: top,
          tooltipData: tooltipPoint,
        })
      : hideTooltip();
  }, [
    hideTooltip,
    isGenPowerEnabled,
    isPointerInsideChart,
    pointerPosition,
    showTooltip,
    tooltipPoint,
    xScale,
    yScalePrimary,
  ]);

  const Tooltip =
    tooltipPoint && isPointerInsideChart ? (
      <>
        {tooltipElement}
        <Group>
          <Line
            from={{
              x: xScale(tooltipPoint.index),
              y: plotHeight,
            }}
            to={{
              x: xScale(tooltipPoint.index),
              y: 0,
            }}
            strokeOpacity={0.3}
            stroke={themeColors.primary_typography}
            strokeWidth={2}
          />

          {isGenPowerEnabled && tooltipPoint.value ? (
            <circle
              cx={xScale(tooltipPoint.index)}
              cy={yScalePrimary(tooltipPoint.value)}
              r={3}
              fill={themeColors.black_white}
            />
          ) : null}
        </Group>
      </>
    ) : null;
  return { handlePointerMove, tooltipPoint, tooltipElement: Tooltip };
};
