/* eslint-disable react/no-multi-comp */
import { GasType } from "apis/oag";
import { StyledCard, StyledDropDown, StyledOptionRow } from "components/Header/RightContent/style";
import NoData from "components/Lenses/common/NoData";
import * as Styled from "components/Lenses/ContainerLens/StatCard/Chart/GenericStatCard/styled";
import { PDComponent } from "components/PDComponents";
import { useLensMouseIn } from "hooks/useLensMouseIn";
import type { FC } from "react";
import { useMemo, useState } from "react";
import { useCustomTheme } from "utils/useTheme";

const emissionTypes = [
  { label: "Total", type: GasType.Unknown, icon: <PDComponent.SvgIcon name="gasTotal" /> },
  { label: "CO₂", type: GasType.Co2, icon: <PDComponent.SvgIcon name="gasCo2" /> },
  { label: "CH₄", type: GasType.Ch4, icon: <PDComponent.SvgIcon name="gasCh4" /> },
  { label: "N₂O", type: GasType.N2O, icon: <PDComponent.SvgIcon name="gasN2O" /> },
  { label: "CO", type: GasType.Co, icon: <PDComponent.SvgIcon name="gasCo" /> },
  { label: "NOₓ", type: GasType.Nox, icon: <PDComponent.SvgIcon name="gasNox" /> },
];

interface GhgEmissionsStatCardProps {
  gasType: GasType;
  value: string;
  unit?: string;
  height: number;
  width: number;
  hasData?: boolean;
  isLoading?: boolean;
  isLocked?: boolean;
  handleGasUpdate?: (gasType: GasType) => Promise<void>;
}

export const GhgEmissionsStatCard: FC<GhgEmissionsStatCardProps> = ({
  gasType = GasType.Unknown,
  value = "No data found",
  unit,
  hasData,
  isLoading,
  height = 1,
  width = 1,
  isLocked,
  handleGasUpdate,
}) => {
  const { atomThemeVariant } = useCustomTheme();
  const [menuVisible, setMenuVisible] = useState(false);

  const { mouseIn } = useLensMouseIn();

  const selectedEmissionType = useMemo(() => {
    return emissionTypes.find((ems) => ems.type === gasType);
  }, [gasType]);

  const gasText = `GHG Emissions /  ${selectedEmissionType?.label}`;

  const MenuItems = () => (
    <StyledCard>
      {emissionTypes.map((emissionType) => (
        <StyledOptionRow key={emissionType.label} justify="space-between" align="middle">
          <Styled.Option
            onClick={() => {
              setMenuVisible(false);
              handleGasUpdate?.(emissionType.type);
            }}
          >
            {emissionType.label}
          </Styled.Option>
        </StyledOptionRow>
      ))}
    </StyledCard>
  );

  return (
    <Styled.StatCard height={height} width={width} $mouseIn={mouseIn} $hasData={!!hasData} $isMenuOpen={menuVisible}>
      <Styled.DieselContainer>
        <StyledDropDown
          overlay={<MenuItems />}
          placement="bottomLeft"
          trigger={["click"]}
          open={isLocked ? false : menuVisible}
          onOpenChange={() => {
            if (!isLocked) setMenuVisible(!menuVisible);
          }}
        >
          <Styled.DieselType variant={"faded"}>
            {gasText}
            {isLocked ? null : <Styled.ChevronDownIcon />}
          </Styled.DieselType>
        </StyledDropDown>
        <Styled.IconContainer>{selectedEmissionType?.icon}</Styled.IconContainer>
      </Styled.DieselContainer>
      <Styled.ValueContainer>
        {!hasData && !isLoading ? (
          <>{height > 1 && width > 2 ? <NoData isStatic /> : <Styled.NoDataText>{value}</Styled.NoDataText>}</>
        ) : (
          <Styled.Value variant={atomThemeVariant}>{value}</Styled.Value>
        )}
        {hasData && unit ? <Styled.Unit variant={atomThemeVariant}>{unit}</Styled.Unit> : null}
      </Styled.ValueContainer>
    </Styled.StatCard>
  );
};
