import type { SpaceSize } from "antd/lib/space";
import { Title } from "atoms/Typography";
import type { ReactChild } from "react";
import { Col, Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

export function SummaryBlock({
  secondary,
  main,
  title,
  verticalSpacing = 6,
  titleLevel = 2,
}: {
  main: ReactChild;
  title: string;
  secondary: string;
  verticalSpacing?: SpaceSize;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
}) {
  const { atomThemeVariant } = useCustomTheme();
  return (
    <Col flex="0 auto">
      <Space direction="vertical" size={(verticalSpacing as number) - 2}>
        <Title
          ellipsis={{
            rows: 1,
          }}
          level={3}
          variant="faded"
          $lineHeight={"20px"}
          style={{
            zIndex: zIndexLayer.mesosphere,
            whiteSpace: "nowrap",
            fontSize: 18,
          }}
        >
          {title}
        </Title>
        <Title
          level={titleLevel}
          variant={atomThemeVariant}
          weight={500}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {main}
        </Title>
        <Title
          ellipsis={{
            rows: 1,
          }}
          level={3}
          variant="faded"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {secondary}
        </Title>
      </Space>
    </Col>
  );
}
