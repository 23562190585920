/* eslint-disable react/no-multi-comp */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ClaraFeedbackDto } from "apis/oag";
import { ClaraApi, ClaraResponseVerdictType } from "apis/oag";
import { FeedbackBox } from "components/Clara/Feedbacks/FeedbackBox";
import { CopyButton } from "components/Clara/MessageThread/CopyButton/CopyButton";
import { type Message, useResetFromMessage } from "hooks/clara/useClara";
import { useCallback, useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { apiConfig } from "utils/apiConfig";
import { Tooltip } from "utils/componentLibrary";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";
const CHUNKS_SIZE = 10;
const TIMEOUT = 50;

import { PDComponent } from "components/PDComponents";
import { useCustomTheme } from "utils/useTheme";

import * as Styled from "./style";
import { useAutoScrollEnabledContext } from "./utils/AutoScrollEnabledContext";

const claraApi = new ClaraApi(apiConfig);
export const IndividualResponse = ({ message }: { message: Message }) => {
  const feedbackRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    feedbackRef.current?.scrollIntoView({ behavior: "instant" });

    return () => {
      queryClient.setQueryData(
        [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CONVERSATION_HISTORY }],
        (oldData: Array<Message>) => {
          const messageIndex = oldData.findIndex((dataMsg) => dataMsg.id === message.id);
          if (oldData[messageIndex]) oldData[messageIndex].isTyped = true;
          return oldData;
        },
      );
    };
  }, [message, queryClient]);

  const [verdictReasons, setVerdictReasons] = useState<string[] | null>(null);

  const feedbackMutation = useMutation({
    mutationFn: (feedback: ClaraFeedbackDto) =>
      claraApi.apiClaraLeaderboardSessionFeedbackPost({
        claraFeedbackDto: feedback,
      }),
  });

  const resetVerdict = useCallback(() => {
    setVerdict(ClaraResponseVerdictType.Unknown);
    feedbackMutation.mutate({
      messageId: message.id,
      verdict: ClaraResponseVerdictType.Unknown,
      verdictReasons: null,
    });
    setIsFeedbackOpen(false);
    setVerdictReasons(null);
  }, [feedbackMutation, message.id]);

  const setVerdictMutate = useCallback(
    (verdict: ClaraResponseVerdictType) => {
      setVerdictReasons(null);
      setVerdict(verdict);
      feedbackMutation.mutate({
        messageId: message.id,
        verdict: verdict,
        verdictReasons: null,
      });
      setIsFeedbackOpen(true);
    },
    [feedbackMutation, message.id],
  );
  const messageRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);
  const { autoScrollEnabled } = useAutoScrollEnabledContext();
  useEffect(() => {
    // We observe the height of the message to scroll to the feedback box when the message grows
    const observeHeight = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.target === messageRef.current) {
          const newHeight = entry.contentRect.height;
          if (newHeight > height) {
            if (autoScrollEnabled && height !== 0) {
              feedbackRef.current?.scrollIntoView({ behavior: "smooth" });
            }
            setHeight(newHeight);
          }
        }
      }
    };

    const resizeObserver = new ResizeObserver(observeHeight);
    if (messageRef.current) {
      resizeObserver.observe(messageRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (messageRef.current) {
        resizeObserver.unobserve(messageRef.current);
      }
    };
  }, [height, autoScrollEnabled]);

  const { resetFromMessage } = useResetFromMessage();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [verdict, setVerdict] = useState<ClaraResponseVerdictType>(ClaraResponseVerdictType.Unknown);
  const [sequencedMessages, setSequencedMessages] = useState<string>("");

  useEffect(() => {
    if (!message.message) return;
    const constructMessage = async () => {
      const chunks = message.message.match(new RegExp(`(.|[\r\n]){1,${CHUNKS_SIZE}}`, "g")) ?? [];
      for (const messageLine of chunks) {
        setSequencedMessages((prev) => prev.concat(messageLine));
        await new Promise((resolve) => setTimeout(resolve, TIMEOUT));
      }
    };
    constructMessage();
  }, [message.message]);

  const { isDark } = useCustomTheme();

  return (
    <Styled.BoxInner>
      <Styled.Avatar $isResponse={true}>
        {isDark ? <PDComponent.SvgIcon name="claraDark" /> : <PDComponent.SvgIcon name="claraLight" />}
      </Styled.Avatar>
      <Styled.Message ref={messageRef} $isResponse={true}>
        {message.isTyped ? <Markdown>{message.message}</Markdown> : <Markdown>{sequencedMessages}</Markdown>}
      </Styled.Message>
      <Styled.FeedbackControls ref={feedbackRef}>
        <Styled.ControlsContainer>
          <Tooltip key={`good-${isFeedbackOpen}`} placement="bottom" title="Good response">
            <Styled.SimpleButtonWrapper
              onClick={() => {
                if (verdict === ClaraResponseVerdictType.Positive) {
                  resetVerdict();
                  return;
                }
                setVerdictMutate(ClaraResponseVerdictType.Positive);
              }}
              role="button"
            >
              {verdict === ClaraResponseVerdictType.Positive ? (
                <PDComponent.SvgIcon name="thumbsUpFilled" />
              ) : (
                <PDComponent.SvgIcon name="thumbsUp" />
              )}
            </Styled.SimpleButtonWrapper>
          </Tooltip>
          <Tooltip key={`bad-${isFeedbackOpen}`} placement="bottom" title="Bad response">
            <Styled.SimpleButtonWrapper
              onClick={() => {
                if (verdict === ClaraResponseVerdictType.Negative) {
                  resetVerdict();
                  return;
                }
                setVerdictMutate(ClaraResponseVerdictType.Negative);
              }}
              role="button"
            >
              {verdict === ClaraResponseVerdictType.Negative ? (
                <PDComponent.SvgIcon name="thumbsDownFilled" />
              ) : (
                <PDComponent.SvgIcon name="thumbsDown" width="25" height="25" />
              )}
            </Styled.SimpleButtonWrapper>
          </Tooltip>
          <Tooltip placement="bottom" title="Regenerate">
            <Styled.SimpleButtonWrapper role="button">
              <PDComponent.SvgIcon name="reset" onClick={() => resetFromMessage(message)} />
            </Styled.SimpleButtonWrapper>
          </Tooltip>
          <CopyButton message={message.message} />
        </Styled.ControlsContainer>
      </Styled.FeedbackControls>
      <FeedbackBox
        messageId={message.id}
        verdict={verdict}
        verdictReasons={verdictReasons}
        setVerdictReasons={setVerdictReasons}
        isOpen={isFeedbackOpen}
        onClose={() => {
          setIsFeedbackOpen(false);
        }}
        onSubmit={(feedback) => {
          setIsFeedbackOpen(false);
          feedbackMutation.mutate(feedback);
        }}
      />
    </Styled.BoxInner>
  );
};
