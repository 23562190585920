/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Int32Int64FactIndicatorDto } from './Int32Int64FactIndicatorDto';
import {
    Int32Int64FactIndicatorDtoFromJSON,
    Int32Int64FactIndicatorDtoFromJSONTyped,
    Int32Int64FactIndicatorDtoToJSON,
} from './Int32Int64FactIndicatorDto';

/**
 * 
 * @export
 * @interface Int32Int64FactIndicatorSetDto
 */
export interface Int32Int64FactIndicatorSetDto {
    /**
     * 
     * @type {Array<Int32Int64FactIndicatorDto>}
     * @memberof Int32Int64FactIndicatorSetDto
     */
    holeSections?: Array<Int32Int64FactIndicatorDto> | null;
    /**
     * 
     * @type {Array<Int32Int64FactIndicatorDto>}
     * @memberof Int32Int64FactIndicatorSetDto
     */
    directionIntervals?: Array<Int32Int64FactIndicatorDto> | null;
    /**
     * 
     * @type {Array<Int32Int64FactIndicatorDto>}
     * @memberof Int32Int64FactIndicatorSetDto
     */
    formations?: Array<Int32Int64FactIndicatorDto> | null;
}

/**
 * Check if a given object implements the Int32Int64FactIndicatorSetDto interface.
 */
export function instanceOfInt32Int64FactIndicatorSetDto(value: object): value is Int32Int64FactIndicatorSetDto {
    return true;
}

export function Int32Int64FactIndicatorSetDtoFromJSON(json: any): Int32Int64FactIndicatorSetDto {
    return Int32Int64FactIndicatorSetDtoFromJSONTyped(json, false);
}

export function Int32Int64FactIndicatorSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Int32Int64FactIndicatorSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'holeSections': json['HoleSections'] == null ? undefined : ((json['HoleSections'] as Array<any>).map(Int32Int64FactIndicatorDtoFromJSON)),
        'directionIntervals': json['DirectionIntervals'] == null ? undefined : ((json['DirectionIntervals'] as Array<any>).map(Int32Int64FactIndicatorDtoFromJSON)),
        'formations': json['Formations'] == null ? undefined : ((json['Formations'] as Array<any>).map(Int32Int64FactIndicatorDtoFromJSON)),
    };
}

export function Int32Int64FactIndicatorSetDtoToJSON(value?: Int32Int64FactIndicatorSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'HoleSections': value['holeSections'] == null ? undefined : ((value['holeSections'] as Array<any>).map(Int32Int64FactIndicatorDtoToJSON)),
        'DirectionIntervals': value['directionIntervals'] == null ? undefined : ((value['directionIntervals'] as Array<any>).map(Int32Int64FactIndicatorDtoToJSON)),
        'Formations': value['formations'] == null ? undefined : ((value['formations'] as Array<any>).map(Int32Int64FactIndicatorDtoToJSON)),
    };
}

