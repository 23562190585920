import styled from "styled-components";
import colors from "utils/colors";
import { zIndexLayer } from "utils/zIndex";

export const TooltipContainer = styled.div`
  position: fixed;
  transform: translate(-50%, -100%);
  width: fit-content;
  z-index: ${zIndexLayer.kuiper};

  display: flex;
  gap: 2px;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  padding: 8px 14px 8px;
  border-radius: 6px;
  background-color: ${colors.neutral};
  pointer-events: none;
  font-size: 12px;
  letter-spacing: -0.4px;
  color: ${colors.gray};
`;

export const TooltipHighlightValue = styled.div<{ fontWeight?: "medium" | "normal"; $highlight?: boolean }>`
  font-weight: ${(props) => props.fontWeight ?? "medium"};
  color: ${colors.white};
  margin-bottom: 2px;
  justify-content: center;
  line-height: 1;
  white-space: nowrap;
`;

export const TooltipFadedValue = styled(TooltipHighlightValue)`
  color: ${colors.gray};
`;

export const TooltipArrowDown = styled.div`
  position: fixed;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, -25%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${colors.neutral};
`;

export const TooltipDayGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  column-gap: 14px;
`;

export const TooltipInfo = styled.span`
  white-space: nowrap;
`;

export const TooltipGapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

export const TooltipDayBorder = styled.div`
  width: 1px;
  height: 100%;
  background-color: rgba(130, 140, 145, 0.1);
`;

export const TooltipGroup = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  white-space: nowrap;
`;
