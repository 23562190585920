import { PDComponent } from "components/PDComponents";
import type { CSSProperties, FC, PropsWithChildren } from "react";

import { StyledFormItem, StyledFormItemIcon } from "./style";

export const FormItem: FC<PropsWithChildren<{ validateStatus?: "error" | "success"; style?: CSSProperties }>> = ({
  validateStatus,
  children,
  style,
}) => {
  return (
    <StyledFormItem $validateStatus={validateStatus} style={style}>
      {children}
      {validateStatus === "error" && (
        <StyledFormItemIcon $validateStatus="error">
          <PDComponent.SvgIcon name="closeFilled" />
        </StyledFormItemIcon>
      )}
      {validateStatus === "success" && (
        <StyledFormItemIcon $validateStatus="success">
          <PDComponent.SvgIcon name="inputCheck" />
        </StyledFormItemIcon>
      )}
    </StyledFormItem>
  );
};
