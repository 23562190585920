/* eslint-disable react/no-multi-comp */
import type {
  DimensionType,
  PivotOrderType,
  PivotType,
  RigCardAverages,
  RigCardFactSeriesDto,
  RigFleetPerformanceCardUserLensDto,
} from "apis/oag";
import { TimeUnit } from "apis/oag";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import {
  NetTimeType,
  ScoreType,
  SelectedMetric,
  useFleetPerformanceMetricSelectionContext,
} from "pages/FleetPerformance/components/FleetPerformanceMetricSelectionContext";
import { HoveredDataProvider } from "pages/FleetPerformance/components/helpers/HoveredDataContext";
import {
  StyledAverage,
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
  StyledExpandButton,
  StyledKpiTitle,
  StyledModal,
  StyledModalBody,
  StyledSummary,
} from "pages/FleetPerformance/components/styled";
import {
  cardKpiTypesMap,
  cardKpiTypeToLabel,
  drillingProductivityTypes,
  surfaceTypes,
} from "pages/FleetPerformance/components/utils";
import { RigCardLayoutType, useFleetPerformanceOptions } from "pages/FleetPerformance/utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { Track } from "services/Mixpanel";
import { Space } from "utils/componentLibrary";
import { useMetresPerTimeUnitUom, useTimeUom, useUOM, UtilDimensions } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

import FleetPerformanceChart from "./Chart";
import type { FactWithLabel } from "./interfaces";
import NoDataCard from "./NoDataCard";
import FleetPerformanceTable from "./Table";
import type { DisplayedPivotTypes } from "./utils";
import { useGroupAndSort } from "./utils";

function CardContent({
  lens,
  facts,
  dimension,
  averages,
  cardId,
  isExpanded = false,
  handleOnExpandClick,
  order,
  isSingleOnGrid = false,
}: Readonly<{
  isExpanded?: boolean;
  isSingleOnGrid?: boolean;
  lens: RigFleetPerformanceCardUserLensDto;
  facts: FactWithLabel[];
  dimension: DimensionType;
  averages: RigCardAverages;
  cardId: number;
  order: PivotOrderType;
  handleOnExpandClick?: () => void;
}>) {
  const { rigOptions } = useFleetPerformanceOptions();
  const percentageUom = useUOM(UtilDimensions.Percentage);
  const defaultUom = useUOM(dimension);
  const hourUom = useTimeUom(TimeUnit.Hour);
  const dayUom = useTimeUom(TimeUnit.Day);
  const drillingProductivityUOM = useMetresPerTimeUnitUom(TimeUnit.Day);
  const { atomThemeVariant } = useCustomTheme();
  const fleetPerformanceTableRef = useRef<{ scrollToIndex: (index: number) => void }>(null);

  const { selectedNetTime, selectedScore, selectedMetric } = useFleetPerformanceMetricSelectionContext();
  const isNetTimes = useMemo(() => selectedMetric === SelectedMetric.NetTimes, [selectedMetric]);
  const isScores = useMemo(() => selectedMetric === SelectedMetric.Scores, [selectedMetric]);
  const isKpi = useMemo(() => selectedMetric === SelectedMetric.KPI, [selectedMetric]);

  const uom = useMemo(() => {
    let selectedUom = defaultUom;
    if (isScores) {
      selectedUom = percentageUom;
    } else if (isNetTimes) {
      selectedUom = hourUom;
    } else if (isKpi) {
      if (lens.cardKpiType === cardKpiTypesMap.LocationRigMoveTime) selectedUom = dayUom;
      else if (lens.cardKpiType === cardKpiTypesMap.DrillingProductivity) selectedUom = drillingProductivityUOM;
      else if (lens.cardKpiType === cardKpiTypesMap.DrillingProductivityDistance) selectedUom = defaultUom;
      else if (lens.cardKpiType === cardKpiTypesMap.DrillingProductivityOnBottomDuration) selectedUom = dayUom;
      else if (lens.cardKpiType === cardKpiTypesMap.DrillingProductivityOverallDuration) selectedUom = dayUom;
      else if (dimension === "Undefined") selectedUom = percentageUom;
      else if (surfaceTypes.includes(lens.cardKpiType)) selectedUom = hourUom;
    }

    return selectedUom;
  }, [
    dimension,
    isScores,
    percentageUom,
    isNetTimes,
    lens.cardKpiType,
    hourUom,
    isKpi,
    dayUom,
    drillingProductivityUOM,
    defaultUom,
  ]);

  const average = useMemo(() => {
    if (isNetTimes) {
      switch (selectedNetTime) {
        case NetTimeType.Target:
          return averages.targetDeltaTime;
        case NetTimeType.LostTime:
          return averages.invisibleLostTime;
        case NetTimeType.TimeSaved:
          return averages.savedTime;
      }
    } else if (selectedMetric === SelectedMetric.Scores) {
      switch (selectedScore) {
        case ScoreType.ImprovementScore:
          return averages.improvementScore;
        case ScoreType.OperatorScore:
          return averages.operatorScore;
        case ScoreType.RigScore:
          return averages.rigScore;
      }
    }

    return averages.kpiValue;
  }, [averages, isNetTimes, selectedNetTime, selectedMetric, selectedScore]);

  const kpiTitle = useMemo(() => cardKpiTypeToLabel[lens.cardKpiType] ?? "-", [lens.cardKpiType]);
  if (drillingProductivityTypes.includes(lens.cardKpiType) && isNetTimes) return null;

  const handleOnItemClick = (index: number) => {
    fleetPerformanceTableRef.current?.scrollToIndex(facts.length - index - 1);
  };

  return (
    <HoveredDataProvider>
      <StyledCard bordered={false} $isExpanded={isExpanded} $isSingleOnGrid={isSingleOnGrid}>
        <StyledCardHeader>
          <StyledSummary>
            <StyledKpiTitle>{kpiTitle}</StyledKpiTitle>
            {selectedMetric === SelectedMetric.NetTimes ? <StyledKpiTitle>{selectedNetTime}</StyledKpiTitle> : null}
            {selectedMetric === SelectedMetric.Scores ? <StyledKpiTitle>{selectedScore}</StyledKpiTitle> : null}

            <StyledAverage variant={atomThemeVariant}>{average ? uom.display(average) : ""} avg </StyledAverage>
          </StyledSummary>

          <Space>
            <StyledExpandButton
              type="ghost"
              icon={
                isExpanded ? (
                  <PDComponent.SvgIcon name="fleetPerformanceMinimizeView" />
                ) : (
                  <PDComponent.SvgIcon name="fleetPerformanceExpandView" height={14} />
                )
              }
              onClick={() => {
                Track.interact("Fleet Performance Cards - Expand View", {
                  "Kpi Title": kpiTitle,
                  "Card Id": cardId,
                });
                if (handleOnExpandClick) {
                  handleOnExpandClick();
                }
              }}
            />
          </Space>
        </StyledCardHeader>
        <StyledCardBody $isOnlyOne={rigOptions.layoutType !== RigCardLayoutType.Split}>
          {rigOptions.layoutType !== RigCardLayoutType.TableOny && (
            <FleetPerformanceChart
              key={`${cardId}-${rigOptions.layoutType}-${rigOptions.pivot}`}
              kpiType={lens.cardKpiType}
              pivotType={rigOptions.pivot as DisplayedPivotTypes}
              uom={uom}
              cardId={cardId}
              data={facts}
              selectedMetric={selectedMetric}
              isExpanded={isExpanded}
              layoutType={rigOptions.layoutType}
              onItemClick={handleOnItemClick}
              order={order}
            />
          )}
          {rigOptions.layoutType !== RigCardLayoutType.BarsOnly && (
            <FleetPerformanceTable
              pivotType={rigOptions.pivot}
              uom={uom}
              cardId={cardId}
              data={facts.toReversed()}
              order={order}
              isExpanded={isExpanded}
              selectedMetric={selectedMetric}
              ref={fleetPerformanceTableRef}
            />
          )}
        </StyledCardBody>
      </StyledCard>
    </HoveredDataProvider>
  );
}

export default function FleetPerformanceCard({
  data,
  cardId,
  lens,
  isSingleOnGrid = false,
  order,
  rigPivot,
}: {
  data: RigCardFactSeriesDto;
  cardId: number;
  lens: RigFleetPerformanceCardUserLensDto;
  isSingleOnGrid?: boolean;
  order: PivotOrderType;
  rigPivot: PivotType;
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalLoaded, setIsModalLoaded] = useState(false);
  const { selectedNetTime, selectedScore, selectedMetric } = useFleetPerformanceMetricSelectionContext();
  const { groupAndSortFacts } = useGroupAndSort();
  const facts = useMemo(() => {
    // change this function to get the sort order
    return groupAndSortFacts({
      selectedMetric,
      selectedNetTime,
      selectedScore,
      allFacts: data.facts,
      order,
      rigPivot,
    }).filter((d) => d.value !== null && d.value !== undefined);
  }, [data.facts, groupAndSortFacts, order, rigPivot, selectedMetric, selectedNetTime, selectedScore]);

  useEffect(() => {
    setTimeout(() => setIsModalLoaded(modalVisible), 500);
  }, [modalVisible]);

  return facts?.length ? (
    <>
      <StyledModal
        open={modalVisible}
        bodyStyle={{ padding: 0 }}
        onCancel={() => setModalVisible(false)}
        hasCloseIcon={false}
        width={900}
      >
        <StyledModalBody>
          {isModalLoaded ? (
            <CardContent
              isExpanded
              averages={data.averages}
              dimension={data.dimension}
              lens={lens}
              facts={facts}
              order={order}
              cardId={cardId + 1} // to render separate path clips for each card
              handleOnExpandClick={() => setModalVisible(false)}
            />
          ) : (
            <Loader withWrapper />
          )}
        </StyledModalBody>
      </StyledModal>

      <CardContent
        lens={lens}
        isSingleOnGrid={isSingleOnGrid}
        averages={data.averages}
        dimension={data.dimension}
        facts={facts}
        cardId={cardId}
        order={order}
        handleOnExpandClick={() => setModalVisible((visible) => !visible)}
      />
    </>
  ) : (
    <NoDataCard key={lens.id} lens={lens} isSingleOnGrid={isSingleOnGrid} />
  );
}
