/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutliersFilteringType } from './OutliersFilteringType';
import {
    OutliersFilteringTypeFromJSON,
    OutliersFilteringTypeFromJSONTyped,
    OutliersFilteringTypeToJSON,
} from './OutliersFilteringType';

/**
 * 
 * @export
 * @interface ParameterHeatmapTrackUserLensDto
 */
export interface ParameterHeatmapTrackUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    trackId: number;
    /**
     * 
     * @type {OutliersFilteringType}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    outliersType: OutliersFilteringType;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    outliersMinPercentile?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    outliersMaxPercentile?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    outliersMinValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParameterHeatmapTrackUserLensDto
     */
    outliersMaxValue?: number | null;
}



/**
 * Check if a given object implements the ParameterHeatmapTrackUserLensDto interface.
 */
export function instanceOfParameterHeatmapTrackUserLensDto(value: object): value is ParameterHeatmapTrackUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('trackId' in value) || value['trackId'] === undefined) return false;
    if (!('outliersType' in value) || value['outliersType'] === undefined) return false;
    return true;
}

export function ParameterHeatmapTrackUserLensDtoFromJSON(json: any): ParameterHeatmapTrackUserLensDto {
    return ParameterHeatmapTrackUserLensDtoFromJSONTyped(json, false);
}

export function ParameterHeatmapTrackUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterHeatmapTrackUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'trackId': json['TrackId'],
        'outliersType': OutliersFilteringTypeFromJSON(json['OutliersType']),
        'outliersMinPercentile': json['OutliersMinPercentile'] == null ? undefined : json['OutliersMinPercentile'],
        'outliersMaxPercentile': json['OutliersMaxPercentile'] == null ? undefined : json['OutliersMaxPercentile'],
        'outliersMinValue': json['OutliersMinValue'] == null ? undefined : json['OutliersMinValue'],
        'outliersMaxValue': json['OutliersMaxValue'] == null ? undefined : json['OutliersMaxValue'],
    };
}

export function ParameterHeatmapTrackUserLensDtoToJSON(value?: ParameterHeatmapTrackUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'TrackId': value['trackId'],
        'OutliersType': OutliersFilteringTypeToJSON(value['outliersType']),
        'OutliersMinPercentile': value['outliersMinPercentile'],
        'OutliersMaxPercentile': value['outliersMaxPercentile'],
        'OutliersMinValue': value['outliersMinValue'],
        'OutliersMaxValue': value['outliersMaxValue'],
    };
}

