import styled from "styled-components";
import { zIndexLayer } from "utils/zIndex";

export const Title = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-weight: 500;
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
`;

export const TitleFaint = styled(Title)`
  color: ${({ theme }) => theme.themeStyle.colors.faint_typography};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
`;

export const Subtitle = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-weight: 400;
  font-size: 14px;
`;

export const Container = styled.div<{ $isLargeContainer: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  ${({ $isLargeContainer }) =>
    $isLargeContainer
      ? `
          ${Title} {
            font-size: 22px;
            padding-top: 24px;
            padding-bottom: 16px;
          }
          ${Subtitle} {
            font-size: 20px;
          }
        `
      : null}
`;

export const WhiteFillContainer = styled.svg`
  width: 60px;
  height: 60px;
  top: 50%;
  transform: translateY(-100%);
  background: white;
  position: absolute;
  z-index: ${zIndexLayer.base};
`;
