/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KpiPivotFactSetDto,
  KpiTypeUserLensDto,
  PivotKpiTypeUserLensDto,
  RigKpiPivotQueryDto,
} from '../models/index';
import {
    KpiPivotFactSetDtoFromJSON,
    KpiPivotFactSetDtoToJSON,
    KpiTypeUserLensDtoFromJSON,
    KpiTypeUserLensDtoToJSON,
    PivotKpiTypeUserLensDtoFromJSON,
    PivotKpiTypeUserLensDtoToJSON,
    RigKpiPivotQueryDtoFromJSON,
    RigKpiPivotQueryDtoToJSON,
} from '../models/index';

export interface ApiPivotKpiTypeUserLensesIdPivotFactsPutRequest {
    id: number;
    rigKpiPivotQueryDto?: RigKpiPivotQueryDto;
}

export interface ApiPivotKpiTypeUserLensesIdPutRequest {
    id: number;
    pivotKpiTypeUserLensDto?: PivotKpiTypeUserLensDto;
}

export interface ApiPivotKpiTypeUserLensesPostRequest {
    pivotKpiTypeUserLensDto?: PivotKpiTypeUserLensDto;
}

/**
 * 
 */
export class PivotKpiTypeUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiPivotKpiTypeUserLensesIdPivotFactsPutRaw(requestParameters: ApiPivotKpiTypeUserLensesIdPivotFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiPivotFactSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiPivotKpiTypeUserLensesIdPivotFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PivotKpiTypeUserLenses/{id}/PivotFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigKpiPivotQueryDtoToJSON(requestParameters['rigKpiPivotQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiPivotFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPivotKpiTypeUserLensesIdPivotFactsPut(requestParameters: ApiPivotKpiTypeUserLensesIdPivotFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiPivotFactSetDto> {
        const response = await this.apiPivotKpiTypeUserLensesIdPivotFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPivotKpiTypeUserLensesIdPutRaw(requestParameters: ApiPivotKpiTypeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiTypeUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiPivotKpiTypeUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PivotKpiTypeUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PivotKpiTypeUserLensDtoToJSON(requestParameters['pivotKpiTypeUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiTypeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPivotKpiTypeUserLensesIdPut(requestParameters: ApiPivotKpiTypeUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiTypeUserLensDto> {
        const response = await this.apiPivotKpiTypeUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPivotKpiTypeUserLensesPostRaw(requestParameters: ApiPivotKpiTypeUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiTypeUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PivotKpiTypeUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PivotKpiTypeUserLensDtoToJSON(requestParameters['pivotKpiTypeUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiTypeUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPivotKpiTypeUserLensesPost(requestParameters: ApiPivotKpiTypeUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiTypeUserLensDto> {
        const response = await this.apiPivotKpiTypeUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
