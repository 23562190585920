import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Col, Typography } from "utils/componentLibrary";

export const StyledTitle = styled(Typography.Title).attrs({
  level: 5,
}) <{ $colorDisabled: string }>`
  font-size: 16px !important;
  font-weight: normal !important;
  margin-bottom: 0 !important;
  line-height: 1 !important;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  ${(props) =>
    props.$colorDisabled === "true" &&
    css`
      opacity: 0.6;
    `};
`;

export const StyledDescription = styled(Typography.Paragraph)`
  font-size: 12px !important;
  margin-top: 6px;
  margin-bottom: 0px !important;
  line-height: 12px !important;
  color: ${({ theme }) => theme.themeStyle.colors.faint_typography} !important;
  opacity: 60%;
`;
export const Box = styled.div<{ $transparent?: boolean }>`
  width: 36px;
  height: 36px;
  margin: 0 12px 0 0;
  display: grid;
  place-items: center;
  border-radius: 4px;
  box-shadow: ${(props) => (props.$transparent ? "none" : `0 1px 2px 0 ${colors.buttons_clicked}`)};
  background-color: ${(props) => (props.$transparent ? "transparent" : props.theme.themeStyle.colors.tertiary_bg)};
`;

export const Circle = styled.div<{ color: string }>`
  height: 13px;
  width: 13px;
  background-color: ${(props) => props.color};
  border-radius: 15px;
`;

export const Center = styled(Col) <{ selected: boolean }>`
  padding: 24px 0;
  padding-left: 16px;
  width: 100%;
  border-bottom: solid 1px ${({ theme }) => theme.themeStyle.colors.primary_accent};
  cursor: pointer;
  background-color: ${({ selected, theme }) => selected && theme.themeStyle.colors.legend_selected_bg};
`;

export const Textbox = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 80%;
`;
