import type { QueryObserverOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  ApiWellsIdInspectionDetailsPutRequest,
  DateDto,
  ScoreComplianceStateType,
  StandKpiInspectionResultDto,
  StandKpiType,
} from "apis/oag";
import { WellsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDWellInspectionDetailsKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { useSelectedWell } from "./useSelectedWell";

const INSPECTION_VIEW_SAMPLE_COUNT = 2000;

const wellsApi = new WellsApi(apiConfig);
export type StandDateRange = { from: DateDto; to: DateDto };

export type StandData = {
  standId: number;
  standKpiType: StandKpiType;
  standNumber?: number;
  startDepth?: number;
  endDepth?: number;
  startAt?: DateDto;
  endAt?: DateDto;
  complianceState?: ScoreComplianceStateType;
  targetValue: number | null | undefined;
  value?: number;
};

export function useWellInspectionDetails(
  selectedStand: StandData,
  tsZoomLimits: Readonly<[DateDto, DateDto]>,
  options?: QueryObserverOptions<StandKpiInspectionResultDto>,
) {
  const wellId = useSelectedWell();
  const requestQuery: ApiWellsIdInspectionDetailsPutRequest = {
    id: wellId,
    standKpiInspectionQueryDto: {
      wellId: wellId,
      sampleCount: INSPECTION_VIEW_SAMPLE_COUNT,
      standId: selectedStand.standId,
      standKpiType: selectedStand.standKpiType,
      useMockData: false,
      selectedFilters: {
        includeFlatTime: true,
        includeSlidingTime: true,
        includeRotatingTime: true,
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNoneAlphaRigs: true,
      },
      from: tsZoomLimits[0],
      to: tsZoomLimits[1],
    },
  };

  const wellInspectionDetails: PDWellInspectionDetailsKey = {
    uid: RequestUID.wellInspectionDetails,
    type: PDQueryType.WELLS_INSPECTION_DETAILS,
    params: requestQuery,
  };

  return useQuery<StandKpiInspectionResultDto>({
    queryKey: [wellInspectionDetails],
    queryFn: () => wellsApi.apiWellsIdInspectionDetailsPut(requestQuery),
    placeholderData: (previousData) => previousData,
    ...options,
  });
}
