import { StyledIcon } from "components/DetailsTopBar/KpiSelect/style";
import styled from "styled-components";
import { Button } from "utils/componentLibrary";

export const StyledButton = styled(Button)<{ isActive?: boolean }>`
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  padding-left: 25px;
  gap: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none !important;
  color: ${(props) =>
    props.isActive
      ? props.theme.themeStyle.colors.disabled_typography
      : props.theme.themeStyle.colors.primary_typography};

  border: none;

  ${StyledIcon} {
    background: none;
    border: none;
    outline: none;
  }
`;

export const Spacer = styled.hr`
  height: 1px;
  border: 0px;
  background-color: ${({ theme }) => theme.themeStyle.colors.separation_line};
`;

export const LineLegend = styled.hr<{ backgroundColor: string }>`
  width: 18px;
  margin-left: -8px;
  height: 1px;
  border: 0px;
  background-color: ${(props) => props.backgroundColor};
`;

export const GraphWrapper = styled.div<{ LEGEND_WIDTH: number }>`
  width: 100%;
  position: relative;
  overflow: visible;
  display: grid;
  grid-template-columns: 1fr ${(props) => props.LEGEND_WIDTH}px;
  user-select: "none";
`;

export const LegendWrapper = styled.div`
  padding: 25px 7px;
  height: 100%;
  position: relative;
  overflow: visible;
`;

export const Circle = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor};
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
`;

export const Col = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const ColLine = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  padding-left: 35px;
`;
