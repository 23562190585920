import { ResultDataState } from "apis/oag";
import type { ContainerLensProps } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { useMemo } from "react";

import { WellDrillingSummaryChart } from "./Chart";
import { WellDrillingSummaryLoading } from "./Chart/Loading";
import { useWellDrillingSummaryFacts } from "./fetcher";

export const WellDrillingSummaryKpi: React.FC<ContainerLensProps> = ({ detailed, graphKey, dimension, lens }) => {
  const { data, isLoading } = useWellDrillingSummaryFacts(lens.id);

  const dataState = useMemo(
    () => (data?.dataState === ResultDataState.NoData ? ResultDataState.NoData : ResultDataState.Valid),
    [data?.dataState],
  );

  return data?.dataState === ResultDataState.Valid && !isLoading ? (
    <WellDrillingSummaryChart detailed={detailed} graphKey={graphKey} dimension={dimension} lens={lens} data={data} />
  ) : (
    <WellDrillingSummaryLoading lensId={lens.id} dataState={dataState} />
  );
};
