import { Button as FormsButton } from "atoms/Form";
import { Title } from "atoms/Typography";
import styled, { css } from "styled-components";
import { Button, Col, InputNumber, Row, Space } from "utils/componentLibrary";

export const StyledInputNumber = styled(InputNumber)`
  height: 36px;
  border-radius: 0;

  .ant-input-number-input-wrap {
    height: 100%;
  }

  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  background: ${({ theme }) => theme.themeStyle.colors.primary_accent};

  input {
    height: 100%;
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
    border-color: transparent;
    border-radius: 0;
  }

  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};

  .ant-input-number-handler-wrap {
    background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
    svg {
      fill: ${({ theme }) => theme.themeStyle.colors.primary_typography};
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    }
  }

  .ant-input-number-handler-down {
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
`;

export const StyledRadiusButton = styled(Button)`
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg} !important;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography} !important;
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;
  min-width: 150px;
`;

export const StyledCircleButton = styled(FormsButton)`
  border-width: 1px;
`;

const padding = css`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const StyledCol = styled(Col)`
  ${padding}
  display: flex;
  min-width: 500px;
  align-items: center;
  gap: 8px;
`;

export const StyledTitle = styled(Title)`
  white-space: nowrap;
`;

export const StyledButton = styled(FormsButton)`
  min-width: 36px;
`;

export const PaddedSpace = styled(Space)`
  ${padding}
`;

export const HeaderContainer = styled(Row)`
  min-height: 68px;
`;

export const FilterTagsContainer = styled.div`
  max-width: 500px;
  ${padding}
`;

export const RightContainer = styled(Space)`
  justify-content: end;
`;
