/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrillingStandDto } from './DrillingStandDto';
import {
    DrillingStandDtoFromJSON,
    DrillingStandDtoFromJSONTyped,
    DrillingStandDtoToJSON,
} from './DrillingStandDto';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface RichStandKpiCommentInfoDto
 */
export interface RichStandKpiCommentInfoDto {
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof RichStandKpiCommentInfoDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof RichStandKpiCommentInfoDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RichStandKpiCommentInfoDto
     */
    description?: string | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof RichStandKpiCommentInfoDto
     */
    kpiType: StandKpiType;
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    standId: number;
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    startRangeStandId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    endRangeStandId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RichStandKpiCommentInfoDto
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof RichStandKpiCommentInfoDto
     */
    authorDisplayName?: string | null;
    /**
     * 
     * @type {DateDto}
     * @memberof RichStandKpiCommentInfoDto
     */
    createdAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof RichStandKpiCommentInfoDto
     */
    updatedAt: DateDto;
    /**
     * 
     * @type {DrillingStandDto}
     * @memberof RichStandKpiCommentInfoDto
     */
    drillingStand: DrillingStandDto;
    /**
     * 
     * @type {DrillingStandDto}
     * @memberof RichStandKpiCommentInfoDto
     */
    startRangeDrillingStand: DrillingStandDto;
    /**
     * 
     * @type {DrillingStandDto}
     * @memberof RichStandKpiCommentInfoDto
     */
    endRangeDrillingStand: DrillingStandDto;
}



/**
 * Check if a given object implements the RichStandKpiCommentInfoDto interface.
 */
export function instanceOfRichStandKpiCommentInfoDto(value: object): value is RichStandKpiCommentInfoDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('kpiType' in value) || value['kpiType'] === undefined) return false;
    if (!('standId' in value) || value['standId'] === undefined) return false;
    if (!('authorId' in value) || value['authorId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('drillingStand' in value) || value['drillingStand'] === undefined) return false;
    if (!('startRangeDrillingStand' in value) || value['startRangeDrillingStand'] === undefined) return false;
    if (!('endRangeDrillingStand' in value) || value['endRangeDrillingStand'] === undefined) return false;
    return true;
}

export function RichStandKpiCommentInfoDtoFromJSON(json: any): RichStandKpiCommentInfoDto {
    return RichStandKpiCommentInfoDtoFromJSONTyped(json, false);
}

export function RichStandKpiCommentInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RichStandKpiCommentInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': json['DeletedAtUtc'] == null ? undefined : (new Date(json['DeletedAtUtc'])),
        'wellId': json['WellId'],
        'title': json['Title'] == null ? undefined : json['Title'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'kpiType': StandKpiTypeFromJSON(json['KpiType']),
        'standId': json['StandId'],
        'startRangeStandId': json['StartRangeStandId'] == null ? undefined : json['StartRangeStandId'],
        'endRangeStandId': json['EndRangeStandId'] == null ? undefined : json['EndRangeStandId'],
        'authorId': json['AuthorId'],
        'authorDisplayName': json['AuthorDisplayName'] == null ? undefined : json['AuthorDisplayName'],
        'createdAt': DateDtoFromJSON(json['CreatedAt']),
        'updatedAt': DateDtoFromJSON(json['UpdatedAt']),
        'drillingStand': DrillingStandDtoFromJSON(json['DrillingStand']),
        'startRangeDrillingStand': DrillingStandDtoFromJSON(json['StartRangeDrillingStand']),
        'endRangeDrillingStand': DrillingStandDtoFromJSON(json['EndRangeDrillingStand']),
    };
}

export function RichStandKpiCommentInfoDtoToJSON(value?: RichStandKpiCommentInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'DeletedAtUtc': value['deletedAtUtc'] == null ? undefined : ((value['deletedAtUtc'] as any).toISOString()),
        'WellId': value['wellId'],
        'Title': value['title'],
        'Description': value['description'],
        'KpiType': StandKpiTypeToJSON(value['kpiType']),
        'StandId': value['standId'],
        'StartRangeStandId': value['startRangeStandId'],
        'EndRangeStandId': value['endRangeStandId'],
        'AuthorId': value['authorId'],
        'AuthorDisplayName': value['authorDisplayName'],
        'CreatedAt': DateDtoToJSON(value['createdAt']),
        'UpdatedAt': DateDtoToJSON(value['updatedAt']),
        'DrillingStand': DrillingStandDtoToJSON(value['drillingStand']),
        'StartRangeDrillingStand': DrillingStandDtoToJSON(value['startRangeDrillingStand']),
        'EndRangeDrillingStand': DrillingStandDtoToJSON(value['endRangeDrillingStand']),
    };
}

