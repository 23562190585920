/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigLeaderboardScoreGroupAco } from './RigLeaderboardScoreGroupAco';
import {
    RigLeaderboardScoreGroupAcoFromJSON,
    RigLeaderboardScoreGroupAcoFromJSONTyped,
    RigLeaderboardScoreGroupAcoToJSON,
} from './RigLeaderboardScoreGroupAco';

/**
 * 
 * @export
 * @interface RigLeaderboardScoreSummaryAco
 */
export interface RigLeaderboardScoreSummaryAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreSummaryAco
     */
    rigName: string;
    /**
     * 
     * @type {Array<RigLeaderboardScoreGroupAco>}
     * @memberof RigLeaderboardScoreSummaryAco
     */
    scorecardKpis: Array<RigLeaderboardScoreGroupAco>;
}

/**
 * Check if a given object implements the RigLeaderboardScoreSummaryAco interface.
 */
export function instanceOfRigLeaderboardScoreSummaryAco(value: object): value is RigLeaderboardScoreSummaryAco {
    if (!('rigName' in value) || value['rigName'] === undefined) return false;
    if (!('scorecardKpis' in value) || value['scorecardKpis'] === undefined) return false;
    return true;
}

export function RigLeaderboardScoreSummaryAcoFromJSON(json: any): RigLeaderboardScoreSummaryAco {
    return RigLeaderboardScoreSummaryAcoFromJSONTyped(json, false);
}

export function RigLeaderboardScoreSummaryAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardScoreSummaryAco {
    if (json == null) {
        return json;
    }
    return {
        
        'rigName': json['RigName'],
        'scorecardKpis': ((json['ScorecardKpis'] as Array<any>).map(RigLeaderboardScoreGroupAcoFromJSON)),
    };
}

export function RigLeaderboardScoreSummaryAcoToJSON(value?: RigLeaderboardScoreSummaryAco | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'RigName': value['rigName'],
        'ScorecardKpis': ((value['scorecardKpis'] as Array<any>).map(RigLeaderboardScoreGroupAcoToJSON)),
    };
}

