import type { AbilityMatrixType } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export enum MatrixAvailability {
  AVAILABLE, // Can use this filter
  NOT_AVAILABLE, // Filter option not available for this view due to set options
  NOT_SUPPORTED, // Filter not supported for the lens
}

export const baseMatrix: AbilityMatrixType = {
  hasExport: MatrixAvailability.AVAILABLE,
  manual_axis: MatrixAvailability.AVAILABLE,
  perceptual_kpi_value: MatrixAvailability.AVAILABLE,
  comparison: MatrixAvailability.AVAILABLE,
  selectedVisualAids: MatrixAvailability.AVAILABLE,
  indicators: MatrixAvailability.AVAILABLE,
  display_options: MatrixAvailability.AVAILABLE,
  filters: MatrixAvailability.AVAILABLE,
  kpiSelect: MatrixAvailability.AVAILABLE,
  zoom: MatrixAvailability.AVAILABLE,
  squeezesDisplay: MatrixAvailability.AVAILABLE,
  showOperationCount: MatrixAvailability.AVAILABLE,
  showsOutliers: MatrixAvailability.AVAILABLE,
  zoomByTime: MatrixAvailability.AVAILABLE,
  zoomByDate: MatrixAvailability.AVAILABLE,
  lensSettings: MatrixAvailability.AVAILABLE,
  label: MatrixAvailability.AVAILABLE,
  trackKPI: MatrixAvailability.AVAILABLE,
  group: MatrixAvailability.AVAILABLE,
  subGroup: MatrixAvailability.AVAILABLE,
  period: MatrixAvailability.AVAILABLE,
  hasDetailedView: MatrixAvailability.AVAILABLE,
  legend: MatrixAvailability.AVAILABLE,
  showSurvey: MatrixAvailability.NOT_SUPPORTED,
  outlierFlaggingType: MatrixAvailability.NOT_SUPPORTED,
  breakdowns: MatrixAvailability.NOT_SUPPORTED,
  hasDetailedLegend: MatrixAvailability.NOT_SUPPORTED,
  unitOfTime: MatrixAvailability.AVAILABLE,
  unitOfTimeHourDay: MatrixAvailability.NOT_SUPPORTED,
  hiddenFiltersDetailed: [],
  showZTorque: MatrixAvailability.NOT_SUPPORTED,
  showPercentageDifference: MatrixAvailability.NOT_SUPPORTED,
  addUserTracks: MatrixAvailability.NOT_SUPPORTED,
  showStandKpiComments: MatrixAvailability.NOT_SUPPORTED,
};

export const baseMatrixNotAvailable: AbilityMatrixType = {
  hasExport: MatrixAvailability.NOT_SUPPORTED,
  manual_axis: MatrixAvailability.NOT_SUPPORTED,
  perceptual_kpi_value: MatrixAvailability.NOT_SUPPORTED,
  comparison: MatrixAvailability.NOT_SUPPORTED,
  selectedVisualAids: MatrixAvailability.NOT_SUPPORTED,
  indicators: MatrixAvailability.NOT_SUPPORTED,
  display_options: MatrixAvailability.NOT_SUPPORTED,
  filters: MatrixAvailability.NOT_SUPPORTED,
  kpiSelect: MatrixAvailability.NOT_SUPPORTED,
  zoom: MatrixAvailability.NOT_SUPPORTED,
  squeezesDisplay: MatrixAvailability.NOT_SUPPORTED,
  showOperationCount: MatrixAvailability.NOT_SUPPORTED,
  showsOutliers: MatrixAvailability.NOT_SUPPORTED,
  zoomByTime: MatrixAvailability.NOT_SUPPORTED,
  zoomByDate: MatrixAvailability.NOT_SUPPORTED,
  lensSettings: MatrixAvailability.NOT_SUPPORTED,
  label: MatrixAvailability.NOT_SUPPORTED,
  trackKPI: MatrixAvailability.NOT_SUPPORTED,
  group: MatrixAvailability.NOT_SUPPORTED,
  subGroup: MatrixAvailability.NOT_SUPPORTED,
  period: MatrixAvailability.NOT_SUPPORTED,
  hasDetailedView: MatrixAvailability.NOT_SUPPORTED,
  legend: MatrixAvailability.NOT_SUPPORTED,
  showSurvey: MatrixAvailability.NOT_SUPPORTED,
  outlierFlaggingType: MatrixAvailability.NOT_SUPPORTED,
  breakdowns: MatrixAvailability.NOT_SUPPORTED,
  hasDetailedLegend: MatrixAvailability.NOT_SUPPORTED,
  unitOfTime: MatrixAvailability.NOT_SUPPORTED,
  unitOfTimeHourDay: MatrixAvailability.NOT_SUPPORTED,
  hiddenFiltersDetailed: [],
  showZTorque: MatrixAvailability.NOT_SUPPORTED,
  showPercentageDifference: MatrixAvailability.NOT_SUPPORTED,
  addUserTracks: MatrixAvailability.NOT_SUPPORTED,
  showStandKpiComments: MatrixAvailability.NOT_SUPPORTED,
};
