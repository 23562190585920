import { NoDataIcon } from "icons/custom/NoData";
import styled from "styled-components";
import colors from "utils/colors";

import { StyledTrackContainer } from "./Chart/Chart";
import { CenterText, ContainerDivider, StyledTrack } from "./Chart/Track";

export const NoDataContainer = styled.div`
  display: grid;
  grid-template-columns: 265px 1fr;
  height: 100%;
  width: 100%;
  #firstContainer {
    display: grid;
    place-items: center;
    div {
      display: grid;
      place-items: center;
      p {
        color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
        font-size: 12px;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
      }
      .pd-icon {
        font-size: 50px;
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  height: 44px;
  border-bottom: 1px solid ${colors.necron_compound_light};
  background: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
  display: flex;
  flex-direction: row;
`;
export const LensSettingsSelector = styled.div`
  display: flex;
  width: 266px;
  padding: 8px 16px;
  height: 100%;
  align-items: center;
  gap: 8px;
  border-right: 1px solid ${colors.necron_compound_light};
  h1 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
  }
  h2 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
  }
`;
export const StandCountSelector = styled.div`
  height: 100%;
  width: 265px;
`;

export const Option = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
  }
`;

export const BottomAxis = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  padding-top: 5px;
  gap: 20px;
  grid-template-columns: 366px 1fr;
  h1 {
    text-align: end;
    color: ${colors.necron_compound};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  tspan {
    fill: ${colors.necron_compound};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
  }
`;

export const ZoomSelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 8px;
  h1 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
  }
`;

export const NoDataComponent = () => {
  return (
    <NoDataContainer>
      <div id="firstContainer">
        <div>
          <NoDataIcon />
          <p>No Data Found</p>
        </div>
      </div>
      <StyledTrackContainer>
        {["Pump Output", "RPM", "Block Height"].map((e) => {
          return (
            <div
              key={e}
              style={{
                padding: 0,
                width: "100%",
                height: "100%",
                position: "relative",
                overflow: "visible",
                borderBottom: `1px solid ${colors.necron_compound_light}`,
              }}
            >
              <StyledTrack>
                <ContainerDivider
                  style={{
                    position: "relative",
                  }}
                >
                  <CenterText>
                    <div className="rotate">{e}</div>
                  </CenterText>
                </ContainerDivider>
              </StyledTrack>
            </div>
          );
        })}
      </StyledTrackContainer>
    </NoDataContainer>
  );
};

export const Legend = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 150px;
  span {
    color: ${colors.necron_compound};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
