/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserLensPositionDto } from './UserLensPositionDto';
import {
    UserLensPositionDtoFromJSON,
    UserLensPositionDtoFromJSONTyped,
    UserLensPositionDtoToJSON,
} from './UserLensPositionDto';
import type { DashboardBlockSize } from './DashboardBlockSize';
import {
    DashboardBlockSizeFromJSON,
    DashboardBlockSizeFromJSONTyped,
    DashboardBlockSizeToJSON,
} from './DashboardBlockSize';

/**
 * 
 * @export
 * @interface UserLensPositionSetDto
 */
export interface UserLensPositionSetDto {
    /**
     * 
     * @type {DashboardBlockSize}
     * @memberof UserLensPositionSetDto
     */
    dashboardWidth: DashboardBlockSize;
    /**
     * 
     * @type {Array<UserLensPositionDto>}
     * @memberof UserLensPositionSetDto
     */
    positions: Array<UserLensPositionDto>;
}



/**
 * Check if a given object implements the UserLensPositionSetDto interface.
 */
export function instanceOfUserLensPositionSetDto(value: object): value is UserLensPositionSetDto {
    if (!('dashboardWidth' in value) || value['dashboardWidth'] === undefined) return false;
    if (!('positions' in value) || value['positions'] === undefined) return false;
    return true;
}

export function UserLensPositionSetDtoFromJSON(json: any): UserLensPositionSetDto {
    return UserLensPositionSetDtoFromJSONTyped(json, false);
}

export function UserLensPositionSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLensPositionSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dashboardWidth': DashboardBlockSizeFromJSON(json['DashboardWidth']),
        'positions': ((json['Positions'] as Array<any>).map(UserLensPositionDtoFromJSON)),
    };
}

export function UserLensPositionSetDtoToJSON(value?: UserLensPositionSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'DashboardWidth': DashboardBlockSizeToJSON(value['dashboardWidth']),
        'Positions': ((value['positions'] as Array<any>).map(UserLensPositionDtoToJSON)),
    };
}

