import type { GeneratorSlotType } from "apis/oag";
import { getGeneratorNumberBySlotType } from "components/Lenses/ContainerLens/common/utils/utils";
import { PDComponent } from "components/PDComponents";
import type { TDropDownOptions } from "components/PDComponents/Dropdown";
import { useActiveGenerators } from "hooks/useActiveGenerators";
import { useSelectedWell } from "hooks/useSelectedWell";
import type { Dict } from "mixpanel-browser";
import { useMemo } from "react";

import {
  GeneratorAndBoilerOptions,
  TEXT_ALL_BOILERS,
  TEXT_ALL_GENERATORS,
  TEXT_DESCRIPTION_TOTAL,
  TEXT_TOTAL,
} from "./utils";

export default function GeneratorAndBoilerSelector({
  selectedOption = GeneratorAndBoilerOptions.AllGens,
  selectedGenerators,
  isDiesel,
  trackingText,
  isLocked,
  trackingData,
  handleGeneratorAndBoilerUpdate,
}: {
  selectedOption: GeneratorAndBoilerOptions;
  selectedGenerators?: GeneratorSlotType[];
  isDiesel: boolean;
  trackingText: string;
  trackingData: Dict;
  isLocked: boolean;
  handleGeneratorAndBoilerUpdate: (generatorAndBoiler: GeneratorAndBoilerOptions) => void;
}) {
  const wellId = useSelectedWell();

  const { data: activeGenerators } = useActiveGenerators(wellId);

  const activeBoilers = useMemo(
    () =>
      isDiesel
        ? [
            {
              value: GeneratorAndBoilerOptions.AllDieselBoilers,
              label: TEXT_ALL_BOILERS,
            },
            {
              value: GeneratorAndBoilerOptions.Boiler1Diesel,
              label: "Boiler 1",
            },
            {
              value: GeneratorAndBoilerOptions.Boiler2Diesel,
              label: "Boiler 2",
            },
          ]
        : [
            {
              value: GeneratorAndBoilerOptions.BoilerNatGas,
              label: "Boiler",
            },
          ],
    [isDiesel],
  );

  const generatorOptions: TDropDownOptions<GeneratorAndBoilerOptions>[] = useMemo(() => {
    return [
      {
        value: GeneratorAndBoilerOptions.Total,
        label: TEXT_TOTAL,
        labelDescription: TEXT_DESCRIPTION_TOTAL,
      },
      {
        value: GeneratorAndBoilerOptions.AllGens,
        label: TEXT_ALL_GENERATORS,
      },
      ...(activeGenerators || []).map((generator) => ({
        value: GeneratorAndBoilerOptions.SingleGen,
        optionData: generator,
        label: `Gen ${getGeneratorNumberBySlotType(generator)}`,
      })),
      ...activeBoilers,
    ];
  }, [activeGenerators, activeBoilers]);

  return (
    <PDComponent.Dropdown<GeneratorAndBoilerOptions>
      handleOptionUpdate={handleGeneratorAndBoilerUpdate}
      options={generatorOptions}
      isLocked={isLocked}
      selectedOption={selectedOption}
      optionData={selectedGenerators?.[0]}
      trackingText={trackingText}
      trackingData={trackingData}
    />
  );
}
