import type {
  KpiPivotFactSetDto,
  SingleStandKpiComparisonDto,
  SingleStandKpiDto,
  StackedStandKpiComparisonDto,
  WedgeComparisonDto,
} from "apis/oag";
import { DimensionType, DisplayOption, OutlierFlaggingType, TimeUnit } from "apis/oag";
import { PrimaryKpiSummary, SecondaryKpiSummary } from "components/Lenses/common/KpiSummaries";
import { LENS_KPI_HEIGHT_INNER } from "components/Lenses/constants";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import {
  StyledChartContainerFlexDiv,
  StyledLensContainerFlex,
} from "components/Lenses/ContainerLens/common/StyledComponents";
import { SingleComparisonCategoryChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleComparisonCategoryChart";
import { SingleComparisonWellChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleComparisonWellChart";
import { SingleComparisonWellShiftChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleComparisonWellShiftChart";
import { SingleDayViewChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleDayViewChart";
import { SingleShiftChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleShiftChart";
import { SingleStandViewChart } from "components/Lenses/ContainerLens/SingleKpi/Chart/SingleStandViewChart";
import type { DrillingProductivityStatsProps } from "components/Lenses/interfaces";
import { MiniLoadingChart } from "components/Lenses/MiniLoadingChart";
import { Loader } from "components/Loader";
import { InfoContainer } from "components/MiniLens/InfoContainer";
import { SecondaryKpiContainer, StyledKpiRow } from "components/MiniLens/style";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useDirectionalIntervals } from "hooks/useDirectionalIntervals";
import { useHoleSections } from "hooks/useHoleSections";
import { useKpiTypes } from "hooks/useKpiTypes";
import { useLensSize } from "hooks/useLensSize";
import { useLensTemplates } from "hooks/useLensTemplates";
import { getMainKPIValues, getSecondaryKPIValues } from "pages/Lens/LensSummaryView";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reducers/store";
import { drillingProductivityMap } from "utils/common";
import { useUOMbyLens } from "utils/format";

import { useDrillingProductivityStatsFacts } from "./fetcher";

export const DrillingProductivity: React.FC<DrillingProductivityStatsProps> = (props) => {
  const { displayOption, detailed, lens, squeeze } = props;

  const defaultLensUOM = useUOMbyLens(DimensionType.Undefined, lens);
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);
  const isComparing = comparisonWells?.length > 0;
  const realDisplayOptions = displayOption === "StandDepth" && !isComparing ? "Date" : displayOption;

  const { isLoading, data } = useDrillingProductivityStatsFacts(lens, realDisplayOptions);

  const selectedUnitOfMeasurement = useAppSelector((state) => state.global.unit);
  const [, height] = useLensSize(lens?.id);

  const [currentUOM, setCurrentUOM] = useState(defaultLensUOM);
  useEffect(() => {
    setCurrentUOM((prevUom) => {
      if (prevUom !== defaultLensUOM) return defaultLensUOM;
      return prevUom;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedUnitOfMeasurement]);

  const { data: kpiTypes } = useKpiTypes({ refetchOnMount: false });
  const { data: holeSections } = useHoleSections({ refetchOnMount: false });
  const { data: directionalIntervals } = useDirectionalIntervals({ refetchOnMount: false });
  const { data: templates } = useLensTemplates();
  const template = templates?.byId[lens.lensTemplateId];
  const mainKPIValues = useMemo<{
    main: string;
    secondary?: string;
  }>(
    () => ({
      ...getMainKPIValues(
        data as unknown as StackedStandKpiComparisonDto & KpiPivotFactSetDto,
        isComparing,
        template,
        lens,
        currentUOM,
      ),
    }),
    [data, isComparing, template, lens, currentUOM],
  );

  const secondaryKPIValues = useMemo(() => {
    return getSecondaryKPIValues(
      (isLoading ? {} : data) as unknown as StackedStandKpiComparisonDto & KpiPivotFactSetDto & WedgeComparisonDto,
      isComparing,
      displayOption,
      template,
      lens,
      holeSections ?? [],
      directionalIntervals || [],
      currentUOM,
      kpiTypes,
    );
  }, [
    isLoading,
    data,
    isComparing,
    displayOption,
    template,
    lens,
    holeSections,
    directionalIntervals,
    currentUOM,
    kpiTypes,
  ]);

  const title = useMemo(() => {
    return drillingProductivityMap.find((i) => i.id == lens?.drillingProductivityType)?.name;
  }, [lens?.drillingProductivityType]);
  if (isLoading) {
    if (detailed)
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Loader />
        </div>
      );
    return (
      <MiniLoadingChart
        id={lens.id}
        isSmallLens={height < 3}
        selectedTrackItems={[]}
        template={template.type}
        label={title}
        description="..."
        isLoading
      />
    );
  }
  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={data?.dataState}
      title={title}
      isDetailed={detailed}
      LoadedComponent={
        <StyledLensContainerFlex>
          <div
            style={{
              height: detailed ? 300 : undefined,
            }}
          >
            <InfoContainer
              size={6}
              direction="vertical"
              style={{
                height: "auto",
              }}
            >
              <StyledKpiRow gutter={34} height={LENS_KPI_HEIGHT_INNER} wrap={true}>
                {mainKPIValues ? (
                  <PrimaryKpiSummary
                    secondary={mainKPIValues.secondary ?? ""}
                    main={mainKPIValues.main}
                    description={""}
                    title={title}
                    detailed={detailed}
                  />
                ) : null}
                {secondaryKPIValues ? (
                  <SecondaryKpiContainer>
                    <SecondaryKpiSummary detailed={detailed} kpis={secondaryKPIValues?.slice(0)} />
                  </SecondaryKpiContainer>
                ) : null}
              </StyledKpiRow>
            </InfoContainer>
          </div>
          <StyledChartContainerFlexDiv
            style={{
              paddingLeft: squeeze ? 0 : 24,
            }}
          >
            {(() => {
              if (!data) return null;
              if (isComparing) {
                switch (realDisplayOptions) {
                  case DisplayOption.Well:
                    return (
                      <SingleComparisonWellChart
                        {...props}
                        valueUOM={currentUOM}
                        data={data as SingleStandKpiDto & SingleStandKpiComparisonDto}
                        lens={{
                          ...props.lens,
                          showsOutliers: false,
                          showOperationCount: false,
                          showStandKpiComments: false,
                          outlierFlaggingType: OutlierFlaggingType.None,
                          selectedVisualAids: [],
                          kpiTypeId: 0,
                          selectedTimeUnit: TimeUnit.Unknown,
                        }}
                      />
                    );
                  case DisplayOption.DirectionInterval:
                  case DisplayOption.HoleSection:
                    return (
                      <SingleComparisonCategoryChart
                        {...props}
                        valueUOM={currentUOM}
                        data={data as SingleStandKpiDto & SingleStandKpiComparisonDto}
                        lens={{
                          ...props.lens,
                          showsOutliers: false,
                          showStandKpiComments: false,
                          showOperationCount: false,
                          outlierFlaggingType: OutlierFlaggingType.None,
                          selectedVisualAids: [],
                          kpiTypeId: 0,
                          selectedTimeUnit: TimeUnit.Unknown,
                        }}
                      />
                    );
                  case DisplayOption.Shift:
                    return (
                      <SingleComparisonWellShiftChart
                        {...props}
                        valueUOM={currentUOM}
                        data={data as SingleStandKpiDto & SingleStandKpiComparisonDto}
                        lens={{
                          ...props.lens,
                          showStandKpiComments: false,
                          showsOutliers: false,
                          showOperationCount: false,
                          outlierFlaggingType: OutlierFlaggingType.None,
                          selectedVisualAids: [],
                          kpiTypeId: 0,
                          selectedTimeUnit: TimeUnit.Unknown,
                        }}
                      />
                    );
                }
              } else {
                switch (realDisplayOptions) {
                  case DisplayOption.DateShift:
                    return (
                      <SingleDayViewChart
                        {...props}
                        valueUOM={currentUOM}
                        data={data as SingleStandKpiDto & SingleStandKpiComparisonDto}
                        lens={{
                          ...props.lens,
                          showStandKpiComments: false,
                          showsOutliers: false,
                          showOperationCount: false,
                          outlierFlaggingType: OutlierFlaggingType.None,
                          selectedVisualAids: [],
                          kpiTypeId: 0,
                          selectedTimeUnit: TimeUnit.Unknown,
                        }}
                      />
                    );
                  case DisplayOption.Date:
                    return (
                      <SingleStandViewChart
                        {...props}
                        enableTooltip
                        valueUOM={currentUOM}
                        data={data as SingleStandKpiDto & SingleStandKpiComparisonDto}
                        lens={{
                          ...props.lens,
                          showStandKpiComments: false,
                          showsOutliers: false,
                          showOperationCount: false,
                          outlierFlaggingType: OutlierFlaggingType.None,
                          selectedVisualAids: [],
                          kpiTypeId: 0,
                          selectedTimeUnit: TimeUnit.Unknown,
                        }}
                      />
                    );
                  case DisplayOption.Shift:
                    return (
                      <SingleShiftChart
                        {...props}
                        valueUOM={currentUOM}
                        data={data as SingleStandKpiDto & SingleStandKpiComparisonDto}
                        lens={{
                          ...props.lens,
                          showStandKpiComments: false,
                          showsOutliers: false,
                          showOperationCount: false,
                          outlierFlaggingType: OutlierFlaggingType.None,
                          selectedVisualAids: [],
                          kpiTypeId: 0,
                          selectedTimeUnit: TimeUnit.Unknown,
                        }}
                      />
                    );
                }
              }
              return null;
            })()}
          </StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
