import { Stats } from "fast-stats";
import type { useKpiTypes } from "hooks/useKpiTypes";
import { uniq } from "lodash";

interface SliceMap {
  slices: { average?: number; kpiTypeId?: number }[];
  kpiTypes: ReturnType<typeof useKpiTypes>["data"];
  prefix?: string | number;
  kpiSuffix?: string | number;
  outlierThreshold?: number;
}

export function getSliceMap({ slices, kpiTypes, prefix = "", kpiSuffix = "", outlierThreshold }: SliceMap) {
  const totalValue = slices.reduce((acc, cur) => acc + (cur.average ?? 0), 0);
  const newSlices = slices.map((slice) => ({ ...slice, displayAverage: slice.average }));

  if (outlierThreshold) {
    let diff = totalValue - outlierThreshold;

    for (let i = newSlices.length - 1; i >= 0 && diff > 0; i -= 1) {
      const slice = newSlices[i];
      const subtract = (slice.average ?? 0) - diff;

      if (subtract >= 0) {
        slice.average = subtract;
        diff = 0;
      } else {
        slice.average = 0;
        diff = -subtract;
      }
    }
  }

  const bulletSliceId = newSlices.filter((slice) => !!slice.average).reverse()[0]?.kpiTypeId;

  return newSlices.reduce<Record<string, boolean | number | string>>((acc, i) => {
    const valueKey = `${prefix}${i.kpiTypeId}${kpiSuffix}_value`;

    if (acc[valueKey] === undefined) {
      acc[valueKey] = 0;
    }

    const kpiTypeId = i.kpiTypeId ?? -1;

    (acc[valueKey] as number) += i?.average ?? 0;

    acc[`${prefix}${kpiTypeId}${kpiSuffix}_displayValue`] = i.displayAverage ?? 0;
    acc[`${prefix}${kpiTypeId}${kpiSuffix}_label`] = kpiTypes?.byId[kpiTypeId].name ?? "Undefined";
    acc[`${prefix}${kpiTypeId}${kpiSuffix}_outlierDisabled`] =
      outlierThreshold && totalValue > outlierThreshold ? i.kpiTypeId !== bulletSliceId : true;
    return acc;
  }, {});
}

export function getAxisBreak(values: number[]) {
  if (!Array.isArray(values) || values.length === 0) {
    return null;
  }

  const stats = new Stats().push(values);
  const mean = stats.amean();
  const stddev = stats.stddev();
  const data = values.map((value) => ({ value, zScore: (value - mean) / stddev }));
  const maxZScoreItem = data.reduce((a, b) => (a.zScore > b.zScore ? a : b));

  const start = +(2 * stddev + mean).toFixed(3);
  const end = +maxZScoreItem.value.toFixed(3);

  if (maxZScoreItem.zScore > 2) {
    return { start, end };
  }

  return null;
}

export function getTrackOrder(tracks: { trackId?: number; position?: number }[] | undefined) {
  return uniq(
    tracks
      ?.filter((track) => !!track && Number.isFinite(track.trackId) && track.trackId !== -1)
      .map((track) => ({ trackId: track.trackId, position: track.position }))
      .slice()
      .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
      .map((track) => track.trackId ?? -1) ?? [],
  );
}
