import { StyledDropDown, StyledOptionRow } from "components/Header/RightContent/style";
import { operationTitlesByExternalId } from "components/Lenses/ContainerLens/PowerLoadEfficiency/utils";
import { useOperationClasses } from "hooks/useOperationClasses";
import type { Dict } from "mixpanel-browser";
import { useMemo } from "react";
import { useCallback } from "react";
import { Track } from "services/Mixpanel";
import { useCustomTheme } from "utils/useTheme";

import * as Styled from "./styles";

export function OperationClassSelector({
  selectedOperationClass = 0,
  operationClassMenuVisible,
  setOperationClassMenuVisible,
  handleOperationClassUpdate,
  trackingText,
  trackingData,
  isLocked,
}: {
  selectedOperationClass: number;
  operationClassMenuVisible: boolean;
  setOperationClassMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleOperationClassUpdate: (operationClass: number) => Promise<void>;
  trackingText: string;
  trackingData: Dict;
  isLocked: boolean;
}) {
  const { atomThemeVariant } = useCustomTheme();

  const { data: operationClasses } = useOperationClasses();
  const availableOperationClasses = operationClasses
    .filter((operation) => Object.keys(operationTitlesByExternalId).includes(operation.externalId || ""))
    .map((operation) => operation.id);
  const getOperationClassName = useCallback(
    (operationClassId: number) => {
      const foundOperationClass = operationClasses.find((operationClass) => operationClass.id === operationClassId);
      if (foundOperationClass) {
        return (
          operationTitlesByExternalId[
            +(foundOperationClass.externalId || "") as keyof typeof operationTitlesByExternalId
          ] || foundOperationClass.name
        );
      }
    },
    [operationClasses],
  );

  const operationOptions = useMemo(() => {
    return [
      {
        value: 0,
        label: "Total Power Load Efficiency",
      },
      ...operationClasses
        .filter((operation) => availableOperationClasses.includes(operation.id))
        .map((operation) => ({
          value: operation.id,
          label: getOperationClassName(operation.id) || operation.name,
        })),
    ];
  }, [operationClasses, availableOperationClasses, getOperationClassName]);

  const operationClass = useMemo(
    () => operationOptions.find((operation) => operation.value === selectedOperationClass)?.label,
    [selectedOperationClass, operationOptions],
  );

  const OperationMenuItems = useMemo(
    () => (
      <Styled.StyledCard>
        {operationOptions.map(({ value, label }) => (
          <StyledOptionRow justify="space-between" align="middle" key={value}>
            <Styled.Option
              onClick={() => {
                Track.interact(trackingText, {
                  ...trackingData,
                  selectedOperationClass: label,
                });
                setOperationClassMenuVisible(false);
                handleOperationClassUpdate(value);
              }}
            >
              {label}
            </Styled.Option>
          </StyledOptionRow>
        ))}
      </Styled.StyledCard>
    ),
    [handleOperationClassUpdate, operationOptions, setOperationClassMenuVisible, trackingData, trackingText],
  );

  return (
    <StyledDropDown
      overlay={OperationMenuItems}
      placement="bottomLeft"
      trigger={["click"]}
      open={isLocked ? false : operationClassMenuVisible}
      onOpenChange={() => {
        if (!isLocked) setOperationClassMenuVisible(!operationClassMenuVisible);
      }}
    >
      <Styled.SelectedOperator variant={atomThemeVariant}>
        {operationClass} {isLocked ? null : <Styled.ChevronDownIcon />}
      </Styled.SelectedOperator>
    </StyledDropDown>
  );
}
