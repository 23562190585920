import styled from "styled-components";

export const RadioGroupWrapper = styled.div`
  margin-top: 4px;
  max-height: 300px;
  overflow-y: scroll;
`;

export const RadioWrapper = styled.div`
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.soft_accent};
`;