import { useIsFetching } from "@tanstack/react-query";
import { DashboardType } from "apis/oag";
import { Button } from "atoms/Form";
import CoreLayout, { Section, SectionLayout } from "components/Layout";
import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import { Timeline } from "components/Timeline";
import { TvDChart } from "components/TvDChart";
import Stats from "components/TvDChart/components/Stats";
import ChartControls, { initialZoomDataEvergreen } from "components/WellDashboard/ChartControls";
import FiltersButton from "components/WellDashboard/ControlHeader/atoms/Filters/FiltersButton";
import { RealtimeIndicator } from "components/WellDashboard/ControlHeader/atoms/RealtimeIndicator";
import { WellSelector } from "components/WellDashboard/ControlHeader/atoms/WellSelector";
import Zoom from "components/WellDashboard/ControlHeader/atoms/Zoom";
import { ZoomButton } from "components/WellDashboard/ControlHeader/atoms/Zoom/ZoomButton";
import { DrillingDetailsHeader } from "components/WellDashboard/DrillingDetailsHeader";
import { ZeroStateTimeline } from "components/WellDashboard/ZeroStates";
import { URL_STATE_PARAM, useResetQueryState, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/useSelectedWell";
import { isEqual } from "lodash";
import { LensView } from "pages/WellDashboard/LensView";
import { StyledTabs } from "pages/WellDashboard/style";
import type { ITimelineInfo } from "pages/WellDashboard/types";
import type { FC } from "react";
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import type { TimelineStates } from "reducers/reportReducer";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import { type IFiltersType, initialFilters, type IZoomData } from "reducers/types";
import { Track, usePageView } from "services/Mixpanel";
import { Col, Row, Space, Tooltip } from "utils/componentLibrary";
import { getQuery } from "utils/helper";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

const Main: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ wellId: string }>();
  const wellId = useMemo(() => Number(params.wellId), [params]);

  usePageView({ title: "Evergreen Wells", options: { "Well Id": wellId }, enabled: true });
  const [zoomOpen, setZoomOpen] = useState<boolean>(false);

  const selectedWell = useSelectedWell();
  const selectedWellRedux = useAppSelector((state) => state.state.selectedWell);

  const [timelineOverride, setTimelineOverride] = useState<ITimelineInfo | null>(null);
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const loadingTvD = false;

  const [resetQ] = useResetQueryState([
    [URL_STATE_PARAM.ZOOM_WIDGET, URL_STATE_PARAM.ZOOM_WELL],
    [URL_STATE_PARAM.FILTERS_WIDGET, URL_STATE_PARAM.FILTERS_DASHBOARD],
    [URL_STATE_PARAM.OFFSET_WIDGET, URL_STATE_PARAM.OFFSET_WELL],
  ]);
  const [filters] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_DASHBOARD, initialFilters);

  const [zoom] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WELL, initialZoomDataEvergreen);
  const [zoomData, setZoom] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WELL, initialZoomDataEvergreen, [
    URL_STATE_PARAM.ZOOM_WIDGET,
  ]);

  useEffect(() => {
    resetQ();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timelineState = useAppSelector((state) => state.state.timeline_state);
  const wellInfo = useAppSelector((state) => state.timeline.currentWellDetails);
  const isFetchingWellOverview = useIsFetching({ queryKey: [{ type: PDQueryType.WELL_OVERVIEW }], exact: false });
  const isFetchingZoom = useIsFetching({ queryKey: [{ type: PDQueryType.WELL_OVERVIEW_ZOOM }] });
  const userInfo = useAppSelector((state) => state.global.userInfo);
  const isInDemoMode = userInfo?.isInDemoMode ?? false;

  const zoomActiveState = !isEqual(initialZoomDataEvergreen, zoomData);

  useEffect(() => {
    dispatch({
      type: "SET_ZOOM_WIDGET",
      payload: {
        zoom,
        state: true,
      },
    });
  }, [zoom, dispatch]);

  useEffect(() => {
    if (selectedWellRedux !== wellId) {
      // useMemo gives a different reference even if the value is the same, hence this check.
      dispatch({
        type: "SET_SELECTED_WELL",
        payload: {
          well: Number(wellId),
        },
      });
    }
  }, [dispatch, selectedWellRedux, wellId]);

  useEffect(() => {
    dispatch({
      type: "SET_FILTERS_WIDGET",
      payload: {
        filters,
        state: true,
      },
    });
    dispatch({
      type: "SET_REPORT_FILTERS",
      payload: {
        filters,
        state: true,
      },
    });
    dispatch({
      type: "SET_INDICATORS_WIDGET",
      payload: {
        indicators: [],
      },
    });
  }, [filters, dispatch]);

  useEffect(() => {
    dispatch({
      type: "SET_LEGEND_ALT_STYLE",
      payload: false,
    });
  }, [dispatch]);

  const refr = useRef<HTMLDivElement | null>(null);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const resetZoomData = useCallback(() => {
    setZoom(initialZoomDataEvergreen);
    dispatch({
      type: "RESET_ZOOM_WIDGET",
    });
    dispatch({
      type: "RESET_REPORT_ZOOM",
    });
    setZoomOpen(false);
  }, [dispatch, setZoom]);

  const setZoomData = useCallback(
    (zoom: IZoomData) => {
      setZoom(zoom);
      if (zoom)
        dispatch({
          type: "SET_REPORT_ZOOM",
          payload: { zoom, state: true },
        });
      setZoomOpen(false);
    },
    [dispatch, setZoom],
  );
  const navigate = useNavigate();

  const onWheel = useCallback(() => {
    if (sectionRef.current && refr.current) {
      if (sectionRef.current?.getBoundingClientRect()?.top < 190) {
        refr.current.style.position = "fixed";
        refr.current.style.top = "120px";
        refr.current.style.width = refr.current.parentElement?.clientWidth + "px";
      } else if (refr.current?.style) {
        refr.current.style.position = "relative";
        refr.current.style.top = "0px";
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (refr.current?.style) {
        refr.current.style.width = refr.current.parentElement?.clientWidth + "px";
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [refr.current?.parentElement?.clientWidth]);

  return (
    <>
      <CoreLayout
        onWheel={onWheel}
        sidebar={
          <SectionLayout
            additionalStyle={{
              zIndex: zIndexLayer.sky_plus,
            }}
            showScrollHints
            header={
              <StyledTabs
                onChange={(e) => {
                  Track.clickEvent(`Change Timeline - ${e}`);
                  dispatch({
                    type: "SET_TIMELINE_STATE",
                    payload: e as TimelineStates,
                  });
                  dispatch({
                    type: "SET_EDIT_EVENT",
                    payload: null,
                  });
                  dispatch({
                    type: "SET_ACTION_ENABLED",
                    payload: false,
                  });
                }}
                activeKey={timelineState.toString()}
                type="line"
                size="large"
                items={[
                  {
                    key: "Actual",
                    label: "Actual",
                  },
                  {
                    key: "Legend",
                    label: "Legend",
                  },
                ]}
              />
            }
          >
            <Row gutter={[0, 16]} style={{ height: "100%" }}>
              <Col span={24}>
                <Suspense fallback={<ZeroStateTimeline />}>
                  <Timeline timelineOverride={timelineOverride} setTimelineOverride={setTimelineOverride} />
                </Suspense>
              </Col>
            </Row>
          </SectionLayout>
        }
      >
        <SectionLayout
          useScrollCol
          header={
            <Section style={{ marginBottom: "14px" }}>
              <Suspense fallback={<Loader centered />}>
                <Row justify="space-between" align="middle">
                  <Col flex="1 auto">
                    <WellSelector
                      onWellChange={(well) => {
                        navigate(`/evergreen/${well}?${getQuery(well).join("&")}`);
                      }}
                      selectedWell={selectedWell}
                    />
                  </Col>
                  <Col flex="0 auto">
                    <Space>
                      {!isInDemoMode ? (
                        <Tooltip title="Well Overview">
                          <Link to={`/evergreen/${selectedWell}/edit`}>
                            <Button icon={<PDComponent.SvgIcon name="wellInfo" />} />
                          </Link>
                        </Tooltip>
                      ) : null}

                      <PDComponent.VerticalDivider />

                      <Tooltip title="Zoom">
                        <ZoomButton
                          onClick={() => setZoomOpen(true)}
                          disabled={isFetchingWellOverview !== 0 || isFetchingZoom !== 0}
                          isActive={zoomActiveState}
                        />
                      </Tooltip>
                      <FiltersButton />
                      <PDComponent.VerticalDivider />
                      <RealtimeIndicator />
                    </Space>
                  </Col>
                </Row>
              </Suspense>
            </Section>
          }
          additionalStyle={{
            zIndex: zIndexLayer.moon,
          }}
        >
          <Row style={{ overflow: "hidden", width: "100%" }}>
            <Col style={{ width: "100%" }}>
              <Row justify="space-between" style={{ background: themeColors.primary_bg }}>
                <Col style={{ padding: "16px 24px" }}>
                  <Row>
                    <Col>
                      <DrillingDetailsHeader />
                    </Col>
                    {!loadingTvD && <Stats />}
                  </Row>
                </Col>
                <Col>
                  <ChartControls />
                </Col>
              </Row>

              <TvDChart
                isReport={false}
                timelineOverride={timelineOverride}
                setTimelineOverride={setTimelineOverride}
              />
            </Col>
            <Suspense fallback={<Loader centered />}>
              <LensView
                refr={refr}
                sectionRef={sectionRef}
                dashboardType={DashboardType.EverGreen}
                isBess={wellInfo?.isBess}
              />
            </Suspense>
          </Row>
        </SectionLayout>
      </CoreLayout>
      <Suspense fallback={null}>
        <Zoom
          visible={zoomOpen}
          onCancel={() => {
            setZoomOpen(false);
          }}
          setZoomData={setZoomData}
          resetZoomData={resetZoomData}
          zoomData={zoomData}
          availableActions={{
            time: MatrixAvailability.AVAILABLE,
            date: MatrixAvailability.AVAILABLE,
            depth: MatrixAvailability.AVAILABLE,
          }}
        />
      </Suspense>
    </>
  );
};

export default Main;
