/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface GeneratorStatusBarFactDto
 */
export interface GeneratorStatusBarFactDto {
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatusBarFactDto
     */
    onlineGeneratorCount: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatusBarFactDto
     */
    indexStart: number;
    /**
     * 
     * @type {number}
     * @memberof GeneratorStatusBarFactDto
     */
    indexEnd: number;
    /**
     * 
     * @type {DateDto}
     * @memberof GeneratorStatusBarFactDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof GeneratorStatusBarFactDto
     */
    to: DateDto;
}

/**
 * Check if a given object implements the GeneratorStatusBarFactDto interface.
 */
export function instanceOfGeneratorStatusBarFactDto(value: object): value is GeneratorStatusBarFactDto {
    if (!('onlineGeneratorCount' in value) || value['onlineGeneratorCount'] === undefined) return false;
    if (!('indexStart' in value) || value['indexStart'] === undefined) return false;
    if (!('indexEnd' in value) || value['indexEnd'] === undefined) return false;
    if (!('from' in value) || value['from'] === undefined) return false;
    if (!('to' in value) || value['to'] === undefined) return false;
    return true;
}

export function GeneratorStatusBarFactDtoFromJSON(json: any): GeneratorStatusBarFactDto {
    return GeneratorStatusBarFactDtoFromJSONTyped(json, false);
}

export function GeneratorStatusBarFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratorStatusBarFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'onlineGeneratorCount': json['OnlineGeneratorCount'],
        'indexStart': json['IndexStart'],
        'indexEnd': json['IndexEnd'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
    };
}

export function GeneratorStatusBarFactDtoToJSON(value?: GeneratorStatusBarFactDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'OnlineGeneratorCount': value['onlineGeneratorCount'],
        'IndexStart': value['indexStart'],
        'IndexEnd': value['indexEnd'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
    };
}

