import * as Styled from "components/General/EmptyNotificationsList/style";
import { PDComponent } from "components/PDComponents";

export const EmptyNotificationsList = ({ size = "small" }: { size?: "small" | "large" }) => {
  return (
    <Styled.Container $isLargeContainer={size === "large"}>
      <PDComponent.SvgIcon name="bell" style={{ fontSize: size === "small" ? "78px" : "118px" }} />
      <Styled.Title>No notifications yet</Styled.Title>
      <Styled.Subtitle> We&apos;ll let you know when updates arrive!</Styled.Subtitle>
    </Styled.Container>
  );
};
