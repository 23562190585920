import type { KpiGroupUserLensDto } from "apis/oag";
import { useKpiGroupPivotFacts } from "hooks/pivotFacts/useStackedKpi";
import { isPivotKpiGroupLensTemplate, useLensTemplates } from "hooks/useLensTemplates";
import { useEffect } from "react";
import { useAppDispatch } from "reducers/store";

export function usePivotKpiFetcher(lens: KpiGroupUserLensDto) {
  const dispatch = useAppDispatch();
  const { data: templates } = useLensTemplates();
  const template = templates?.byId[lens.lensTemplateId];
  const kpiPivotFacts = useKpiGroupPivotFacts(lens.id, {}, isPivotKpiGroupLensTemplate(template));

  useEffect(() => {
    if (kpiPivotFacts.data?.availableFilters)
      dispatch({
        type: "SET_RIG_AVAILABLE_FILTERS",
        payload: kpiPivotFacts.data?.availableFilters,
      });
  }, [kpiPivotFacts.data?.availableFilters, dispatch]);

  return kpiPivotFacts;
}
