import { useMutation } from "@tanstack/react-query";
import type { NotificationSubscriptionDto } from "apis/oag";
import { NotificationSubscriptionsApi, NotificationType } from "apis/oag";
import { Button } from "atoms/Form";
import { toast } from "atoms/toast";
import { Title } from "atoms/Typography";
import { OverviewHeaderContainer } from "components/Layout/Tabbed";
import { Loader } from "components/Loader";
import { useNotificationSubscriptions } from "hooks/notifications/useNotificationSubscriptions";
import { useRigSummariesNotifications } from "hooks/notifications/useRigSummariesNotifications";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useRigSort } from "hooks/useRigSort";
import { cloneDeep } from "lodash";
import { AllFiltersDropdownButton } from "pages/NotificationSettings/AllFiltersDropdownButton/AllFiltersDropdownButton";
import { useNotificationRigFilterCategories } from "pages/NotificationSettings/AllFiltersDropdownButton/useNotificationRigFilterCategories";
import { RigFilterDropdownButton } from "pages/NotificationSettings/RigFilterDropdownButton/RigFilterDropdownButton";
import type { ChangedSubscription } from "pages/NotificationSettings/RigList/RigListTable";
import { RigListTable } from "pages/NotificationSettings/RigList/RigListTable";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Track, usePageView } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { Col, Row, Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

import * as Styled from "./style";

const notificationSubscriptionApi = new NotificationSubscriptionsApi(apiConfig);

export const NotificationSettingsPage = () => {
  const { data: rigSummaries, isLoading: isLoadingSummaries } = useRigSummariesNotifications();
  const { data: notificationSubscriptions, refetch: refetchSubscriptions } = useNotificationSubscriptions();
  const { resetFilters } = useNotificationRigFilterCategories();
  const [isLoading, setIsLoading] = React.useState(false);
  const sortByRigs = useRigSort();

  usePageView("Notification Settings");

  const user = useCurrentUser();

  useLayoutEffect(() => {
    resetFilters();
  }, [resetFilters]);

  const rigsList = useMemo(
    () => (rigSummaries ?? []).sort((r1, r2) => sortByRigs(r1.id, r2.id)),
    [rigSummaries, sortByRigs],
  );

  const [selectedRigIds, setSelectedRigIds] = useState(rigsList.map((rig) => rig.id));

  useEffect(() => {
    setSelectedRigIds(rigsList.map((rig) => rig.id));
  }, [rigsList]);

  const visibleRigsSubset = useMemo(
    () => (user.data?.canAccessRigScorecard ? rigsList.filter((rig) => selectedRigIds.includes(rig.id)) : []),
    [rigsList, selectedRigIds, user.data?.canAccessRigScorecard],
  );

  const [localSubscriptions, setLocalSubscriptions] =
    useState<NotificationSubscriptionDto[]>(notificationSubscriptions);

  const updateRigSubscriptions = useMutation({
    mutationFn: (subscriptions: NotificationSubscriptionDto[]) => {
      setIsLoading(true);
      return notificationSubscriptionApi.apiNotificationSubscriptionsPut({
        notificationSubscriptionRequestDto: {
          subscriptions,
        },
      });
    },
    onSuccess: () => {
      toast.success({ message: "Settings updated." });
    },
    onSettled: () => {
      refetchSubscriptions();
      setIsLoading(false);
    },
  });

  const handleOnRowChange = useCallback(
    (commentChanges: ChangedSubscription[], type: NotificationType) => {
      const updatedSubs = cloneDeep(localSubscriptions);

      commentChanges.forEach((change) => {
        const existingSubscription = updatedSubs.find((sub) => sub.rigId === change.rigId);

        if (existingSubscription) {
          if (change.checked) {
            if (!existingSubscription.type.includes(type)) {
              existingSubscription.type.push(type);
            }
          } else {
            existingSubscription.type = existingSubscription.type.filter((oldType) => oldType !== type);
          }
        } else if (change.checked) {
          updatedSubs.push({
            id: 0,
            rigId: change.rigId,
            type: [type],
          });
        }
      });
      setLocalSubscriptions(updatedSubs.filter((sub) => sub.type.length > 0));
    },
    [localSubscriptions],
  );

  const handleOnSave = useCallback(() => {
    Track.clickEvent("Notification Settings - Save Changes");
    updateRigSubscriptions.mutate(localSubscriptions);
  }, [localSubscriptions, updateRigSubscriptions]);

  const isSaveDisabled = useMemo(
    () => isLoadingSummaries || JSON.stringify(notificationSubscriptions) === JSON.stringify(localSubscriptions),
    [isLoadingSummaries, localSubscriptions, notificationSubscriptions],
  );

  const {
    atomThemeVariant,
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const subscribedRigsCount = useMemo(
    () =>
      localSubscriptions.reduce((count, sub) => {
        if (visibleRigsSubset.find((rig) => rig.id === sub.rigId)) {
          return count + 1;
        } else return count;
      }, 0),
    [localSubscriptions, visibleRigsSubset],
  );

  return (
    <Styled.PageContainer>
      <Row>
        <Col span={24}>
          <OverviewHeaderContainer style={{ background: themeColors.quaterniary_bg }}>
            <Row justify="space-between" align="middle" style={{ height: "119px" }}>
              <Col flex="0 auto">
                <Title level={1} variant={atomThemeVariant} weight={500} style={{ margin: "0 16px" }}>
                  Notification Settings
                </Title>
              </Col>
              <Col flex="0 auto">
                <Space>
                  <Button size="large" onClick={() => history.back()} loading={isLoading || isLoadingSummaries}>
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    size="large"
                    disabled={isSaveDisabled}
                    loading={isLoading || isLoadingSummaries}
                    onClick={handleOnSave}
                  >
                    Save Changes
                  </Button>
                </Space>
              </Col>
            </Row>
          </OverviewHeaderContainer>
        </Col>
      </Row>
      <Styled.PageContent>
        <Styled.Inner>
          {isLoadingSummaries ? (
            <Loader withWrapper />
          ) : (
            <>
              <Styled.Toolbar>
                {user?.data?.canAccessRigScorecard ? (
                  <Row>
                    <Col span={12}>
                      <h2>Subscribed Rigs ({subscribedRigsCount})</h2>
                      <h3>Select rigs to receive a notification for new comments and requests</h3>
                    </Col>

                    <Styled.FilterCol span={12}>
                      <Space>
                        <RigFilterDropdownButton
                          setSelectedRigIds={setSelectedRigIds}
                          selectedRigIds={selectedRigIds}
                          rigList={(rigSummaries ?? [])
                            .sort((r1, r2) => sortByRigs(r1.id, r2.id))
                            .map((rig) => ({
                              id: rig.id,
                              name: rig.shortName ?? "",
                            }))}
                        />
                        <AllFiltersDropdownButton />
                      </Space>
                    </Styled.FilterCol>
                  </Row>
                ) : null}
              </Styled.Toolbar>
              <Styled.TableContainer>
                <RigListTable
                  rigsList={visibleRigsSubset}
                  subscriptions={localSubscriptions}
                  onCommentsChange={(change) => handleOnRowChange(change, NotificationType.ScorecardComment)}
                  onExceptionsChange={(change) => handleOnRowChange(change, NotificationType.ExceptionRequest)}
                />
              </Styled.TableContainer>
            </>
          )}
        </Styled.Inner>
      </Styled.PageContent>
    </Styled.PageContainer>
  );
};
