/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StackedStandKpiSummaryDto
 */
export interface StackedStandKpiSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiSummaryDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiSummaryDto
     */
    median: number;
}

/**
 * Check if a given object implements the StackedStandKpiSummaryDto interface.
 */
export function instanceOfStackedStandKpiSummaryDto(value: object): value is StackedStandKpiSummaryDto {
    if (!('average' in value) || value['average'] === undefined) return false;
    if (!('median' in value) || value['median'] === undefined) return false;
    return true;
}

export function StackedStandKpiSummaryDtoFromJSON(json: any): StackedStandKpiSummaryDto {
    return StackedStandKpiSummaryDtoFromJSONTyped(json, false);
}

export function StackedStandKpiSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackedStandKpiSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'average': json['Average'],
        'median': json['Median'],
    };
}

export function StackedStandKpiSummaryDtoToJSON(value?: StackedStandKpiSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Average': value['average'],
        'Median': value['median'],
    };
}

