import AntDatePicker from "components/DatePicker";
import { RealTime } from "components/RealTimeIndicator";
import type { Dict } from "mixpanel-browser";
import React, { useCallback } from "react";
import { Track } from "services/Mixpanel";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import type { ButtonProps } from "utils/componentLibrary";
import {
  AutoComplete as AntAutoComplete,
  Button as AntButton,
  Checkbox,
  Input as AntInput,
} from "utils/componentLibrary";

export const StyledCheckboxElem = styled(Checkbox)`
  display: flex;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.neutral};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${colors.neutral};
  }
  span:not(.ant-checkbox) {
    flex: 1 auto;
  }
`;
interface IButtonProps {
  $border?: string;
  $engaged?: boolean;
  $secondary?: boolean;
  stylefocus?: string;
}
const StyledButton = styled(AntButton)<IButtonProps>`
  box-shadow: none !important;

  &.ant-btn-primary {
    :hover {
      color: ${({ theme }) => (theme.isDark ? colors.white : colors.well_color_dark)} !important;
      background-color: ${({ theme }) => (theme.isDark ? colors.shutter_black : colors.off_state)} !important;
    }
  }

  ${({
    // GOAL when styling - more themeColors, less colors
    // TODO look for ways in the future so maybe we can drop all of the custom sent styling
    $border,
    $secondary,
    $engaged,
    stylefocus: customFocusStyle,
    theme: {
      themeStyle: { colors: themeColors },
    },
  }) => {
    return css`
      border: ${$border};
      border-color: ${$border ? null : themeColors.primary_accent};

      &:not(.ant-btn-primary) {
        color: ${themeColors.primary_typography};
        background-color: ${themeColors.secondary_bg};

        ${$engaged && !$secondary
          ? css`
              filter: brightness(120%);
              background: ${themeColors.alt_primary_bg} !important;
              span {
                color: ${themeColors.primary_typography} !important;
              }
            `
          : null}
      }

      &[disabled] {
        color: ${themeColors.disabled_typography};
        background: ${themeColors.quaterniary_bg};
        border-color: ${themeColors.primary_accent};
      }

      &:focus {
        ${$secondary
          ? css`
              background: ${colors.bottom_line_widget};
              color: ${colors.gray};
              border-color: "transparent";
            `
          : css`
              border: 1px solid ${colors.orochimaru_grey};
            `}

        ${customFocusStyle}
      }

      &:hover {
        color: ${themeColors.primary_hover_typography};
        border-color: ${themeColors.tertiary_accent};

        &:not(.ant-btn-primary)[disabled] {
          background-color: ${themeColors.quaterniary_bg};
          color: ${themeColors.disabled_typography}a0;
        }

        &:not([disabled]) {
          ${RealTime} {
            animation: null;
            box-shadow: 0px 0px 0 3.5px ${themeColors.tertiary_accent};
            border: 2.5px solid ${themeColors.quaterniary_bg};
            background-color: ${colors.well_color};
          }
        }
      }
    `;
  }}
`;

interface LocalButtonProps extends ButtonProps {
  $border?: string;
  $engaged?: boolean;
  $secondary?: boolean;
  trackingName?: string;
  trackingProps?: Dict;
  styleFocus?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, LocalButtonProps>(function Button(
  { onClick, trackingName, trackingProps, styleFocus, children, ...rest },
  ref,
) {
  const clickAction = useCallback(
    (clickAction: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (trackingName) Track.clickEvent(trackingName, trackingProps);
      return onClick?.(clickAction);
    },
    [onClick, trackingName, trackingProps],
  );
  return (
    <StyledButton
      stylefocus={styleFocus}
      size="large"
      ref={ref}
      {...rest}
      onClick={(clickEvent) => clickAction(clickEvent)}
    >
      {children}
    </StyledButton>
  );
});

export const SearchInput = styled(AntInput.Search).attrs({
  size: "large",
})`
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);

  &,
  .ant-input-wrapper .ant-input-affix-wrapper {
    height: 36px !important;
  }

  .ant-input-affix-wrapper {
    background: unset;
  }

  & .ant-btn-lg {
    font-size: 20px;
    padding: 8px;
  }

  &,
  input {
    background-color: ${colors.actions_bg};
  }

  &::placeholder,
  & .ant-input-suffix {
    color: ${colors.gray};
  }

  &:disabled {
    background-color: ${colors.white};
  }
`;

export const AutoComplete = styled(AntAutoComplete).attrs({
  size: "large",
})`
  &,
  input {
    width: 100%;
  }
  .rc-virtual-list-scrollbar-thumb {
    display: none;
  }
  .ant-select-selector {
    background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg} !important;
    white-space: normal !important;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

export const AutoCompleteDropDown = styled.div`
  .rc-virtual-list-scrollbar-thumb,
  .rc-virtual-list-scrollbar {
    display: none !important;
  }
  .rc-virtual-list-holder {
    overflow-y: scroll !important;
    overflow-anchor: auto !important;
  }
`;

export const DateRangePicker = styled(AntDatePicker.RangePicker)`
  box-shadow: none !important;
`;

export const DatePicker = styled(AntDatePicker).attrs({
  size: "large",
  showTime: true,
})`
  &,
  ${DateRangePicker} {
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    height: 36px;
    box-shadow: none !important;
    &::placeholder,
    & .ant-picker-input {
      color: ${colors.gray};
      box-shadow: none !important;
    }
    &.ant-picker {
      background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
      .ant-picker-input:hover .ant-picker-clear {
        background-color: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
      }
      .ant-picker-input input {
        color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
      }
      .ant-picker-suffix,
      .anticon-close-circle {
        color: ${({ theme }) => theme.themeStyle.colors.light_typography};
      }
    }
  }
`;

export * from "./FormItem";
export * from "./Input";
export * from "./Select";
