/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KpiGroupUserLensDto,
  KpiPivotFactSetDto,
  PivotKpiGroupUserLensDto,
  RigKpiPivotQueryDto,
} from '../models/index';
import {
    KpiGroupUserLensDtoFromJSON,
    KpiGroupUserLensDtoToJSON,
    KpiPivotFactSetDtoFromJSON,
    KpiPivotFactSetDtoToJSON,
    PivotKpiGroupUserLensDtoFromJSON,
    PivotKpiGroupUserLensDtoToJSON,
    RigKpiPivotQueryDtoFromJSON,
    RigKpiPivotQueryDtoToJSON,
} from '../models/index';

export interface ApiPivotKpiGroupUserLensesIdPivotFactsPutRequest {
    id: number;
    rigKpiPivotQueryDto?: RigKpiPivotQueryDto;
}

export interface ApiPivotKpiGroupUserLensesIdPutRequest {
    id: number;
    pivotKpiGroupUserLensDto?: PivotKpiGroupUserLensDto;
}

export interface ApiPivotKpiGroupUserLensesPostRequest {
    pivotKpiGroupUserLensDto?: PivotKpiGroupUserLensDto;
}

/**
 * 
 */
export class PivotKpiGroupUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiPivotKpiGroupUserLensesIdPivotFactsPutRaw(requestParameters: ApiPivotKpiGroupUserLensesIdPivotFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiPivotFactSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiPivotKpiGroupUserLensesIdPivotFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PivotKpiGroupUserLenses/{id}/PivotFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigKpiPivotQueryDtoToJSON(requestParameters['rigKpiPivotQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiPivotFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPivotKpiGroupUserLensesIdPivotFactsPut(requestParameters: ApiPivotKpiGroupUserLensesIdPivotFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiPivotFactSetDto> {
        const response = await this.apiPivotKpiGroupUserLensesIdPivotFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPivotKpiGroupUserLensesIdPutRaw(requestParameters: ApiPivotKpiGroupUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiGroupUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiPivotKpiGroupUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PivotKpiGroupUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PivotKpiGroupUserLensDtoToJSON(requestParameters['pivotKpiGroupUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiGroupUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPivotKpiGroupUserLensesIdPut(requestParameters: ApiPivotKpiGroupUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiGroupUserLensDto> {
        const response = await this.apiPivotKpiGroupUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPivotKpiGroupUserLensesPostRaw(requestParameters: ApiPivotKpiGroupUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiGroupUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PivotKpiGroupUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PivotKpiGroupUserLensDtoToJSON(requestParameters['pivotKpiGroupUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiGroupUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPivotKpiGroupUserLensesPost(requestParameters: ApiPivotKpiGroupUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiGroupUserLensDto> {
        const response = await this.apiPivotKpiGroupUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
