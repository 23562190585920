import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import type { ClaraAnswerDto, ClaraPromptDto, ClaraSessionDto, WellStatusType } from "apis/oag";
import { ClaraApi, ClaraMessageSource, DashboardType, RigCardDataType } from "apis/oag";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { SelectedRigsContext } from "pages/FleetPerformance/RigList/SelectedRigsContext";
import { BenchmarkType, useScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { initialFilters, type IFiltersType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto } from "utils/helper";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

export interface Message {
  message: string;
  source: ClaraMessageSource;
  id: number;
  isError?: boolean;
  isFavorite?: boolean;
  isTyped?: boolean;
}

const claraApi = new ClaraApi(apiConfig);

export const useClaraPrompts = <T,>(options?: Partial<UseSuspenseQueryOptions<Array<ClaraPromptDto>, Error, T>>) => {
  return useSuspenseQuery({
    queryKey: [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CONVERSATION_PROMPTS }],
    queryFn: () => claraApi.apiClaraPromptsGet(),
    ...options,
    refetchOnMount: false,
  });
};

export const useClaraConversationHistory = () => {
  // local state temporary with set data based on the last message. Subsequent mutations is done via setQueryData
  const initialMessages: Array<Message> = [];
  return useSuspenseQuery({
    queryKey: [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CONVERSATION_HISTORY }],
    queryFn: () => Promise.resolve(initialMessages),
    refetchOnMount: false,
  });
};

export const useClaraRequestParameters = () => {
  const operatorsState = useAppSelector((state) => state.rigsCommon.operators);
  const rigIdsState = useAppSelector((state) => state.rigsCommon.rigIds);

  const periodState = useAppSelector((state) => state.rigsCommon.period);
  const filterState = useAppSelector((state) => state.rigsCommon.filters);
  const allWellsIncluded = useAppSelector((state) => state.rigsCommon.allWellsIncluded);
  const exceptions = useAppSelector((state) => state.rigsCommon.exceptions);
  const { scoreBenchmark } = useScoreBenchmarkContext();

  const selectedRigsContext = useContext(SelectedRigsContext);
  const [{ operationTime }] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, initialFilters);

  return useMemo(() => {
    return {
      scoreType:
        scoreBenchmark === BenchmarkType.OperatorTarget
          ? RigCardDataType.OperatorScore
          : RigCardDataType.PdBenchmarkValue,
      rigLeaderboardQueryDto: {
        highlightedRigIds: selectedRigsContext?.selectedRigIds,
        selectedFilters: {
          sectionIds: filterState?.holeSection.map((hs) => +(hs.id || -1)),
          directionalIntervalIds: filterState?.directionalInterval.map((hs) => +(hs.id || -1)),
          holeSizeIds: filterState?.holeSize.map((hs) => +(hs.id || -1)),
          includeFlatTime:
            operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
          includeSlidingTime:
            operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
          includeRotatingTime:
            operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
          includeNullHoleDepth: true,
          includeAlphaRigs: true,
          includeNoneAlphaRigs: true,
        },
        dashboard: DashboardType.RigFleetPerformance,
        operatorIds: operatorsState,
        rigIds: rigIdsState,
        from: dateToDateOnlyDto(periodState?.startDate || defaultDateDto.from.utc),
        to: dateToDateOnlyDto(periodState?.endDate || defaultDateDto.to.utc),
        includeFullWellFacts: allWellsIncluded,
        includeAlphaRigs: filterState?.technology.map((tech) => tech.id).includes(RigTechnology.Alpha) || false,
        includeNoneAlphaRigs: filterState?.technology.map((tech) => tech.id).includes(RigTechnology.NonAlpha) || false,
        selectedClasses: filterState?.type.map((t) => (t.id || -1).toString()),
        selectedCountryIds: filterState?.country.map((c) => +(c.id || -1)),
        selectedHorsePowers: filterState?.horsePower.map((hp) => +(hp.id || -1)),
        selectedOperatingCenters: filterState?.operatingCenter.map((oc) => (oc.id || -1).toString()),
        selectedWellStatusTypes: filterState?.wellStatus.map((ws) => ws.id) as WellStatusType[],
        useExceptions: exceptions,
      },
    };
  }, [
    scoreBenchmark,
    selectedRigsContext?.selectedRigIds,
    filterState?.holeSection,
    filterState?.directionalInterval,
    filterState?.holeSize,
    filterState?.technology,
    filterState?.type,
    filterState?.country,
    filterState?.horsePower,
    filterState?.operatingCenter,
    filterState?.wellStatus,
    operationTime,
    operatorsState,
    rigIdsState,
    periodState?.startDate,
    periodState?.endDate,
    allWellsIncluded,
    exceptions,
  ]);
};
export const useCreateClaraSession = (
  options?: Omit<UseSuspenseQueryOptions<ClaraSessionDto>, "queryKey" | "queyFn">,
) => {
  const requestQuery = useClaraRequestParameters();
  return useSuspenseQuery({
    queryKey: [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CREATE_SESSION, requestQuery }],
    queryFn: () => claraApi.apiClaraLeaderboardSessionCreateScoreTypePost(requestQuery),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: false,
    gcTime: Infinity,
    ...options,
  });
};

export const useClaraMessage = (
  message: string,
  options?: Omit<UseSuspenseQueryOptions<ClaraAnswerDto>, "queryKey" | "queyFn">,
) => {
  const { data } = useCreateClaraSession();
  return useSuspenseQuery({
    queryKey: [{ uid: RequestUID.clara, type: PDQueryType.CLARA_MESSAGE, message }],
    queryFn: () =>
      claraApi.apiClaraLeaderboardSessionSessionIdMessagePost({
        sessionId: data.sessionId,
        claraQuestionDto: {
          message,
          source: ClaraMessageSource.User,
        },
      }),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    gcTime: Infinity,
    retry: false,
    ...options,
  });
};

export const useResetFromMessage = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const resetFromMessage = (messageBeingReset: Message) => {
    queryClient.setQueryData(
      [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CONVERSATION_HISTORY }],
      (oldData: Array<Message>) => {
        const firstDeletableIndex = oldData.findIndex((message) => message.id === messageBeingReset.id) - 1;
        const removedUserMessage = oldData[firstDeletableIndex];
        const newData = oldData.slice(0, firstDeletableIndex);

        dispatch({
          type: "SET_PENDING_MESSAGE",
          payload: {
            message: removedUserMessage.message,
            id: removedUserMessage.id,
            favorite: removedUserMessage.isFavorite,
          },
        });
        return newData;
      },
    );
  };

  return { resetFromMessage };
};

export const useResetErrorMessage = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const resetErrorMessage = (messageBeingReset: Message) => {
    queryClient.setQueryData(
      [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CONVERSATION_HISTORY }],
      (oldData: Array<Message>) => {
        dispatch({ type: "RESET_PENDING_MESSAGE" });

        const firstDeletableIndex = oldData.findIndex((message) => message.id === messageBeingReset.id) - 1;
        const removedUserMessage = oldData[firstDeletableIndex].message;
        const newData = oldData.slice(0, firstDeletableIndex);
        dispatch({
          type: "SET_PENDING_MESSAGE",
          payload: { message: removedUserMessage, id: oldData[firstDeletableIndex].id },
        });
        return newData;
      },
    );
  };

  return { resetErrorMessage };
};
