import { toast } from "atoms/toast";
import { useEffect } from "react";
import { keepCheckingForReport } from "utils/reports";

export function ReportsFetcher(): JSX.Element {
  useEffect(() => {
    const reportID = localStorage.getItem("reports");
    if (reportID) {
      keepCheckingForReport(Number.parseInt(reportID)).finally(() => {
        toast.close("loading");
        window.localStorage.removeItem("reports");
      });
    }
  }, []);

  return <></>;
}
