/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StopWatchLapDto } from './StopWatchLapDto';
import {
    StopWatchLapDtoFromJSON,
    StopWatchLapDtoFromJSONTyped,
    StopWatchLapDtoToJSON,
} from './StopWatchLapDto';

/**
 * 
 * @export
 * @interface StopWatchDto
 */
export interface StopWatchDto {
    /**
     * 
     * @type {Array<StopWatchLapDto>}
     * @memberof StopWatchDto
     */
    readonly laps: Array<StopWatchLapDto>;
    /**
     * 
     * @type {number}
     * @memberof StopWatchDto
     */
    readonly totalSeconds: number;
}

/**
 * Check if a given object implements the StopWatchDto interface.
 */
export function instanceOfStopWatchDto(value: object): value is StopWatchDto {
    if (!('laps' in value) || value['laps'] === undefined) return false;
    if (!('totalSeconds' in value) || value['totalSeconds'] === undefined) return false;
    return true;
}

export function StopWatchDtoFromJSON(json: any): StopWatchDto {
    return StopWatchDtoFromJSONTyped(json, false);
}

export function StopWatchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StopWatchDto {
    if (json == null) {
        return json;
    }
    return {
        
        'laps': ((json['Laps'] as Array<any>).map(StopWatchLapDtoFromJSON)),
        'totalSeconds': json['TotalSeconds'],
    };
}

export function StopWatchDtoToJSON(value?: Omit<StopWatchDto, 'Laps'|'TotalSeconds'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

