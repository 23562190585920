/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PivotKey } from './PivotKey';
import {
    PivotKeyFromJSON,
    PivotKeyFromJSONTyped,
    PivotKeyToJSON,
} from './PivotKey';

/**
 * 
 * @export
 * @interface PivotLevelSummaryDto
 */
export interface PivotLevelSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof PivotLevelSummaryDto
     */
    allAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotLevelSummaryDto
     */
    focalAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotLevelSummaryDto
     */
    comparisonAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotLevelSummaryDto
     */
    comparisonAverageRelativeDiff?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotLevelSummaryDto
     */
    focalDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotLevelSummaryDto
     */
    comparisonDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotLevelSummaryDto
     */
    comparisonDistributionRelativeDiff?: number | null;
    /**
     * 
     * @type {PivotKey}
     * @memberof PivotLevelSummaryDto
     */
    key: PivotKey;
}

/**
 * Check if a given object implements the PivotLevelSummaryDto interface.
 */
export function instanceOfPivotLevelSummaryDto(value: object): value is PivotLevelSummaryDto {
    if (!('key' in value) || value['key'] === undefined) return false;
    return true;
}

export function PivotLevelSummaryDtoFromJSON(json: any): PivotLevelSummaryDto {
    return PivotLevelSummaryDtoFromJSONTyped(json, false);
}

export function PivotLevelSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotLevelSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'allAverage': json['AllAverage'] == null ? undefined : json['AllAverage'],
        'focalAverage': json['FocalAverage'] == null ? undefined : json['FocalAverage'],
        'comparisonAverage': json['ComparisonAverage'] == null ? undefined : json['ComparisonAverage'],
        'comparisonAverageRelativeDiff': json['ComparisonAverageRelativeDiff'] == null ? undefined : json['ComparisonAverageRelativeDiff'],
        'focalDistribution': json['FocalDistribution'] == null ? undefined : json['FocalDistribution'],
        'comparisonDistribution': json['ComparisonDistribution'] == null ? undefined : json['ComparisonDistribution'],
        'comparisonDistributionRelativeDiff': json['ComparisonDistributionRelativeDiff'] == null ? undefined : json['ComparisonDistributionRelativeDiff'],
        'key': PivotKeyFromJSON(json['Key']),
    };
}

export function PivotLevelSummaryDtoToJSON(value?: PivotLevelSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'AllAverage': value['allAverage'],
        'FocalAverage': value['focalAverage'],
        'ComparisonAverage': value['comparisonAverage'],
        'ComparisonAverageRelativeDiff': value['comparisonAverageRelativeDiff'],
        'FocalDistribution': value['focalDistribution'],
        'ComparisonDistribution': value['comparisonDistribution'],
        'ComparisonDistributionRelativeDiff': value['comparisonDistributionRelativeDiff'],
        'Key': PivotKeyToJSON(value['key']),
    };
}

