import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { UserLensDto } from "apis/oag";

import { UserLensesQueryKey } from "./useUserLenses";

export const useLensUpdate = ({
  updateLens,
  onLensUpdated,
  onMutate,
  onError,
}: {
  updateLens?: (lens: UserLensDto) => Promise<UserLensDto>;
  onLensUpdated?: (newItem: UserLensDto) => void;
  onMutate?: (variables: UserLensDto) => unknown;
  onError?: (error: Error, variables: UserLensDto, context: unknown) => unknown;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newLens: UserLensDto & { showsOutliers?: boolean; squeezesDisplay?: boolean }) => {
      if (newLens?.isSystem) {
        return new Promise<UserLensDto>((resolve) => {
          resolve(newLens);
        });
      }

      return updateLens ? updateLens(newLens) : new Promise<UserLensDto>((resolve) => resolve(newLens));
    },
    onMutate,
    onError,
    onSettled: (newLens) => {
      if (newLens) {
        if (!newLens?.isSystem) {
          queryClient.invalidateQueries({ queryKey: [UserLensesQueryKey] });
        }
        onLensUpdated?.(newLens);
      }
    },
  });
};
