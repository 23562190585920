/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface PivotSummaryByKpiDto
 */
export interface PivotSummaryByKpiDto {
    /**
     * 
     * @type {number}
     * @memberof PivotSummaryByKpiDto
     */
    allAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotSummaryByKpiDto
     */
    focalAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotSummaryByKpiDto
     */
    comparisonAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotSummaryByKpiDto
     */
    comparisonAverageRelativeDiff?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotSummaryByKpiDto
     */
    focalDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotSummaryByKpiDto
     */
    comparisonDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotSummaryByKpiDto
     */
    comparisonDistributionRelativeDiff?: number | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof PivotSummaryByKpiDto
     */
    id: StandKpiType;
    /**
     * 
     * @type {number}
     * @memberof PivotSummaryByKpiDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof PivotSummaryByKpiDto
     */
    median: number;
}



/**
 * Check if a given object implements the PivotSummaryByKpiDto interface.
 */
export function instanceOfPivotSummaryByKpiDto(value: object): value is PivotSummaryByKpiDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('median' in value) || value['median'] === undefined) return false;
    return true;
}

export function PivotSummaryByKpiDtoFromJSON(json: any): PivotSummaryByKpiDto {
    return PivotSummaryByKpiDtoFromJSONTyped(json, false);
}

export function PivotSummaryByKpiDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotSummaryByKpiDto {
    if (json == null) {
        return json;
    }
    return {
        
        'allAverage': json['AllAverage'] == null ? undefined : json['AllAverage'],
        'focalAverage': json['FocalAverage'] == null ? undefined : json['FocalAverage'],
        'comparisonAverage': json['ComparisonAverage'] == null ? undefined : json['ComparisonAverage'],
        'comparisonAverageRelativeDiff': json['ComparisonAverageRelativeDiff'] == null ? undefined : json['ComparisonAverageRelativeDiff'],
        'focalDistribution': json['FocalDistribution'] == null ? undefined : json['FocalDistribution'],
        'comparisonDistribution': json['ComparisonDistribution'] == null ? undefined : json['ComparisonDistribution'],
        'comparisonDistributionRelativeDiff': json['ComparisonDistributionRelativeDiff'] == null ? undefined : json['ComparisonDistributionRelativeDiff'],
        'id': StandKpiTypeFromJSON(json['Id']),
        'position': json['Position'],
        'median': json['Median'],
    };
}

export function PivotSummaryByKpiDtoToJSON(value?: PivotSummaryByKpiDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'AllAverage': value['allAverage'],
        'FocalAverage': value['focalAverage'],
        'ComparisonAverage': value['comparisonAverage'],
        'ComparisonAverageRelativeDiff': value['comparisonAverageRelativeDiff'],
        'FocalDistribution': value['focalDistribution'],
        'ComparisonDistribution': value['comparisonDistribution'],
        'ComparisonDistributionRelativeDiff': value['comparisonDistributionRelativeDiff'],
        'Id': StandKpiTypeToJSON(value['id']),
        'Position': value['position'],
        'Median': value['median'],
    };
}

