import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DashboardType, LensTabStateType, type ProfileInfoDto, UserLensTabsApi } from "apis/oag";
import { Button } from "atoms/Form";
import { toast } from "atoms/toast";
import { Title } from "atoms/Typography";
import { FormGroup } from "components/General/FormGroup";
import { PDComponent } from "components/PDComponents";
import { useDashboardType } from "hooks/useDashboardType";
import { useSelectedRig } from "hooks/useSelectedRig";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useShareRecipients } from "hooks/useShareRecipients";
import { useUserDashboard } from "hooks/useUserLensTabs";
import { useWellDetails } from "hooks/useWellDetails";
import { UserSelector } from "pages/Admin/Users/Editor/DashboardLayout/components/UserSelector";
import { MultipleChoiceSelect } from "pages/WellDashboard/ShareTabs/ShareTabsModal/Selector/MultipleChoiceSelect";
import { customStyles } from "pages/WellDashboard/ShareTabs/ShareTabsModal/style";
import { StyledCloseIcon } from "pages/WellDashboard/style";
import { useCallback, useMemo, useState } from "react";
import Modal from "react-modal";
import { apiConfig } from "utils/apiConfig";
import { Col, Row } from "utils/componentLibrary";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import * as Styled from "./style";

const lensTabs = new UserLensTabsApi(apiConfig);

export const ShareTabsModal = ({ isVisible, onCancel }: { isVisible: boolean; onCancel: () => void }) => {
  const { themeStyle, atomThemeVariant } = useCustomTheme();
  const { dashboardType } = useDashboardType();

  const { data: allTabs, isLoading: isLoadingTabs } = useUserDashboard(dashboardType);
  const focalWellId = useSelectedWell();
  const { data: selectedWellInfo } = useWellDetails(focalWellId);
  const selectedRigId = useSelectedRig();

  const rigIds = useMemo(
    () => (dashboardType === DashboardType.Rig ? [] : (selectedWellInfo?.jobs ?? []).map((e) => e.rigId)),
    [dashboardType, selectedWellInfo?.jobs],
  );

  const shareableTabs = useMemo(
    () =>
      (allTabs?.personalTabs ?? []).filter((tab) => {
        if (tab.state !== LensTabStateType.Visible && tab.state !== LensTabStateType.Hidden) return false;
        const uniqueRigIds = dashboardType === DashboardType.Rig ? [selectedRigId] : [...new Set(rigIds)];
        return uniqueRigIds.some((rigId) =>
          (tab.rigIds ?? []).length === 0 ? true : (tab.rigIds ?? []).includes(rigId),
        );
      }),
    [allTabs?.personalTabs, dashboardType, rigIds, selectedRigId],
  );

  const { isLoading: isLoadingRecipients, data: shareRecipients } = useShareRecipients();
  const [isLoadingSharing, setIsLoadingSharing] = useState<boolean>(false);
  const [selectedRecipient, setSelectedRecipient] = useState<ProfileInfoDto>();
  const [selectedTabIds, setSelectedTabIds] = useState<number[]>([]);
  const queryClient = useQueryClient();

  const handleOnRecipientSelect = useCallback(
    (rId: number) => {
      const matchedRecipient = shareRecipients?.find((rec) => rec.profileId === rId);
      if (!matchedRecipient) {
        throw new Error("PD Error: Recipient id not matched inside the list");
      }
      setSelectedRecipient(matchedRecipient);
    },
    [shareRecipients],
  );

  const handleOnTabsSelect = useCallback((tabIds: number[]) => {
    setSelectedTabIds(tabIds);
  }, []);

  const tabPlaceholder = "None";
  const currentTabsText = useMemo(
    () => (selectedTabIds?.length > 0 ? selectedTabIds.length : tabPlaceholder) + " selected",
    [selectedTabIds.length],
  );

  const handleLensUpdate = useMutation({
    mutationFn: ({
      tabIds,
      recipientProfileId,
      dashboardType,
    }: {
      tabIds: number[];
      recipientProfileId: number;
      dashboardType: DashboardType;
    }) =>
      lensTabs.apiUserLensTabsSharePost({
        userLensTabShareDto: {
          tabIds,
          recipientProfileId,
          dashboardType,
        },
      }),
  });

  const handleOnShare = useCallback(async () => {
    setIsLoadingSharing(true);
    await handleLensUpdate.mutateAsync(
      {
        tabIds: selectedTabIds,
        recipientProfileId: selectedRecipient?.profileId || -1,
        dashboardType,
      },
      {
        onSuccess: () => {
          onCancel();
          queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.USER_LENS_TABS }] });
          setIsLoadingSharing(false);
          toast.success({ message: "Your tabs have been shared successfully" });
        },
      },
    );
  }, [dashboardType, handleLensUpdate, onCancel, queryClient, selectedRecipient?.profileId, selectedTabIds]);

  const isSaveDisabled = useMemo(() => {
    return !selectedRecipient || selectedTabIds.length === 0;
  }, [selectedRecipient, selectedTabIds.length]);

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onCancel}
      style={{
        content: { ...customStyles.content, backgroundColor: themeStyle.colors.primary_bg },
        overlay: customStyles.overlay,
      }}
      ariaHideApp={false}
    >
      <Styled.StyledModalContent>
        <Row justify="space-between" align="middle" style={{ padding: "16px 24px" }}>
          <Col>
            <Title variant={atomThemeVariant} level={2} weight={500}>
              Share Tabs
            </Title>
          </Col>
          <StyledCloseIcon onClick={onCancel}>
            <PDComponent.SvgIcon name="close" />
          </StyledCloseIcon>
        </Row>

        <Row justify="space-between" align="middle" style={{ padding: "16px 24px" }}>
          <Col>
            <FormGroup
              label={
                <Title level={4}>
                  <b>Dashboard Name:</b> {dashboardType} Dashboard
                </Title>
              }
            />
          </Col>
        </Row>

        <hr />

        <Row justify="space-between" align="middle" style={{ padding: "16px 24px" }}>
          <FormGroup
            label={
              <Title level={4}>
                <b>Recipient</b>
              </Title>
            }
          />

          <UserSelector
            width={495}
            placeholder="User Search..."
            profileList={shareRecipients ?? []}
            setSelectedProfile={(profile: ProfileInfoDto) => {
              handleOnRecipientSelect(profile.profileId);
            }}
            otherProfileId={-1}
            selectedProfile={selectedRecipient ?? null}
          />
        </Row>

        <hr />

        <Row justify="space-between" align="middle" style={{ padding: "16px 24px" }}>
          <Col span={24}>
            <FormGroup
              label={
                <Title level={4}>
                  <b>Select Tabs for sharing</b>
                </Title>
              }
            />

            <MultipleChoiceSelect
              currentSelectionTitle={currentTabsText}
              placeholder={currentTabsText}
              width={495}
              listItems={shareableTabs}
              isLoading={isLoadingTabs}
              trackingTitle="Tab"
              onSelectionChange={handleOnTabsSelect}
            />
          </Col>
        </Row>

        <Styled.FooterContainer>
          <Row justify="space-between">
            <Col span={24}>
              <Button
                type="primary"
                disabled={isSaveDisabled}
                onClick={handleOnShare}
                loading={isLoadingRecipients || isLoadingTabs || isLoadingSharing}
              >
                Share
              </Button>
            </Col>
          </Row>
        </Styled.FooterContainer>
      </Styled.StyledModalContent>
    </Modal>
  );
};
