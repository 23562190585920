import { CHART_HORIZONTAL_PADDING } from "pages/FleetPerformance/components/utils";
import styled from "styled-components";

export const StyledChart = styled.svg`
  position: absolute;
  &,
  svg:not(:root)& {
    overflow: visible;
  }
`;

export const StyledChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
export const StyledPaddedContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-left: ${CHART_HORIZONTAL_PADDING}px;
`;
