import { useIsFetching } from "@tanstack/react-query";
import { Button } from "atoms/Form";
import { Spinner } from "components/Loader/Spinner";
import { PDComponent } from "components/PDComponents";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import type { FC } from "react";
import { Suspense } from "react";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import styled from "styled-components";
import colors from "utils/colors";
import { Col, Row, Space, Tooltip } from "utils/componentLibrary";
import { PdSpaceItem } from "utils/componentLibrary/Space";
import { PDQueryType } from "utils/queryNamespaces";

import DisplayOptions from "./atoms/DisplayOptions";
import { Indicators } from "./atoms/Indicators";

const ShiftIcon = styled(PDComponent.SvgIcon).attrs({ name: "macShift" })`
  padding-bottom: 3px;
  ::after {
    content: "";
    width: 12px;
    height: 3px;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ToggleButton = styled(Button)<{ $isActive: boolean; $wellColor: string; $loading: boolean }>`
  ${PdSpaceItem} {
    display: inline-flex;
    vertical-align: middle;
  }
  font-size: 0px;
  .pd-icon {
    position: relative;
    font-size: 18px;
    color: ${(props) =>
      !props.disabled && props.$isActive ? props.$wellColor : props.theme.themeStyle.colors.primary_scroll_bg};
  }
  ${ShiftIcon} {
    ::after {
      background-color: ${(props) =>
        !props.disabled && props.$isActive ? props.$wellColor : props.theme.themeStyle.colors.primary_scroll_bg};
    }
  }
  &:disabled {
    background-color: ${(props) => props.theme.themeStyle.colors.quaterniary_bg};
  }
`;

export const ZoomButton = styled(Button)`
  button {
    border: solid 1px transparent !important;
    color: ${colors.black}!important;
    outline: none !important;
  }
`;

export const CustomLoader = styled.div`
  height: 1.1em;
`;

export const initialZoomData: IZoomData = {
  date_start: null,
  date_start_well_offset: null,
  date_end: null,
  date_end_well_offset: null,
  ts_start: 0,
  ts_end: null,
  depth_start: 0,
  depth_end: null,
  internal_zoom: false,
  type: IZoomType.TIME,
};

export const initialZoomDataEvergreen: IZoomData = {
  ...initialZoomData,
  type: IZoomType.DATE,
};
const ChartControls: FC = () => {
  const isFetchingWellOverview = useIsFetching({ queryKey: [{ type: PDQueryType.WELL_OVERVIEW }], exact: false });
  const wellColor = colors.well_color;
  const [depthNormalized, setDepthNormalize] = useStateQuery<boolean>(URL_STATE_PARAM.WELL_NORMALIZED_DEPTH, false);

  return (
    <Col
      style={{
        width: "100%",
      }}
    >
      <Row
        justify="space-between"
        style={{
          padding: "16px 24px",
        }}
      >
        <Col span={24} style={{ textAlign: "right" }}>
          <Space>
            <Tooltip title="Normalize Depth">
              <ToggleButton
                $wellColor={wellColor}
                $isActive={depthNormalized}
                disabled={isFetchingWellOverview !== 0}
                $loading={isFetchingWellOverview !== 0}
                size="large"
                onClick={() => setDepthNormalize(!depthNormalized)}
                icon={
                  isFetchingWellOverview !== 0 ? (
                    <CustomLoader className="anticon">
                      <Spinner color={colors.white} />
                    </CustomLoader>
                  ) : (
                    <ShiftIcon />
                  )
                }
              />
            </Tooltip>

            <Indicators disabled={isFetchingWellOverview !== 0} icon={<PDComponent.SvgIcon name="tagGroup" />} />
            <Suspense fallback={null}>
              <DisplayOptions disabled={isFetchingWellOverview !== 0} icon={<PDComponent.SvgIcon name="view" />} />
            </Suspense>
          </Space>
        </Col>
      </Row>
    </Col>
  );
};

export default ChartControls;
