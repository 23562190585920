import type { Int32Int64FactIndicatorSetDto } from "apis/oag";
import type { ScaleOrdinal } from "d3-scale";
import { useDirectionalIntervals } from "hooks/useDirectionalIntervals";
import { useHoleSections } from "hooks/useHoleSections";
import { keyBy } from "lodash";
import { useMemo } from "react";
import { IIndicators } from "reducers/widgetReducer";

export type AggregatedIndicatorItem = { id: number; type: IIndicators; label: string };

export type UseAggregatedIndicatorsResult = {
  keys: string[];
  map: Record<number | string, AggregatedIndicatorItem[]>;
};

/**
 * Returns a list of indicators aggregated by value
 */
export function useAggregatedIndicators(
  data: Int32Int64FactIndicatorSetDto | undefined | null,
  scale?: ScaleOrdinal<number, number>,
): UseAggregatedIndicatorsResult {
  const { data: holeSections } = useHoleSections();

  const { data: intervals } = useDirectionalIntervals();

  return useMemo<UseAggregatedIndicatorsResult>(() => {
    if (!data) {
      return { map: {}, keys: [] };
    }

    const map: UseAggregatedIndicatorsResult["map"] = {};

    const holeSectionMap = keyBy(holeSections, "id");
    const intervalMap = keyBy(intervals, "id");

    if (data.directionIntervals) {
      for (const interval of data.directionIntervals) {
        const value = scale ? scale(interval.value) : interval.value;

        if (!map[value]) {
          map[value] = [];
        }

        const label = intervalMap[interval.id]?.name ?? "Undefined Interval";

        map[value].push({
          id: interval.id,
          type: IIndicators.DirectionalIntervals,
          label,
        });
      }
    }

    if (data.holeSections) {
      for (const holeSection of data.holeSections) {
        const value = scale ? scale(holeSection.value) : holeSection.value;

        if (!map[value]) {
          map[value] = [];
        }

        const label = holeSectionMap[holeSection.id]?.name ?? "Undefined Hole Section";

        map[value].push({
          id: holeSection.id,
          type: IIndicators.HoleSections,
          label,
        });
      }
    }

    return { map, keys: Object.keys(map) ?? [] };
  }, [data, holeSections, intervals, scale]);
}
