import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ParameterByDepthUserLensDto, ParameterByDepthUserLensTrackItemDto, UserLensDto } from "apis/oag";
import { ParameterByDepthUserLensesApi } from "apis/oag";
import { useTrackAddModal } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackAddModal";
import { useAvailableTrackIds } from "components/Lenses/ContainerLens/ParameterByDepthKPI/useAvailableTrackIds";
import { UserLensesQueryKey } from "hooks/useUserLenses";
import { useCallback } from "react";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { RequestUID } from "utils/queryNamespaces";

const api = new ParameterByDepthUserLensesApi(apiConfig);

export function useTrackAdd({
  lens,
  selectedTrackItems,
  onAdd,
}: {
  lens: UserLensDto;
  selectedTrackItems: ParameterByDepthUserLensTrackItemDto[];
  onAdd: (items: ParameterByDepthUserLensTrackItemDto[]) => void;
}) {
  const queryClient = useQueryClient();

  const availableTrackIds = useAvailableTrackIds(selectedTrackItems);
  const mutationFn = useCallback(({ trackItems }: { trackItems: ParameterByDepthUserLensTrackItemDto[] }) => {
    const { id } = lens;
    return api.apiParameterByDepthUserLensesIdPut({
      id,
      parameterByDepthUserLensDto: {
        ...lens as ParameterByDepthUserLensDto,
        userLensTrackItems: trackItems,
      },
    });
  }, [lens]);

  const updateTracksMutation = useMutation({
    mutationFn,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [UserLensesQueryKey] });
      await queryClient.cancelQueries({ queryKey: [{ uid: RequestUID.parameterByDepthFacts }], exact: false });
    },
    onSettled: async (data) => {
      const opts = {
        refetchActive: true,
        refetchInactive: true,
        exact: false,
      };
      if (data?.userLensTrackItems) {
        onAdd(data.userLensTrackItems);
      }
      await queryClient.invalidateQueries({ queryKey: [UserLensesQueryKey] });
      await queryClient.invalidateQueries({
        queryKey: [{ uid: RequestUID.parameterByDepthFacts, lensId: lens.id }],
        ...opts,
      });
    },
  });

  const { node: TrackAddModalElement, open: openTrackAddModal } = useTrackAddModal({
    availableTrackIds,
    onAdd: (track) => {
      const newTrackItem: ParameterByDepthUserLensTrackItemDto = {
        position: selectedTrackItems.length + 1,
        isManualYaxis: false,
        isUomUnlocked: true,
        trackId: track.id,
        userLensId: lens.id,
        id: 0,
        label: false,
        systemOfMeasurementType: "Metric",
      };
      const newTrackItems = [...selectedTrackItems, newTrackItem];
      Track.interact("Add Track", { "Track Name": track.name });
      onAdd(newTrackItems);
      updateTracksMutation.mutate({ trackItems: newTrackItems });
    },
  });

  return { TrackAddModalElement, openTrackAddModal };
}
