import styled from "styled-components";

export const RigListContainer = styled.div`
  height: calc(100% - 64px);
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
`;

export const RigListInner = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  border-radius: 4px;
  flex-grow: 1;
  padding: 16px;
  height: 100%;
`;
