import { Button } from "atoms/Form";
import { toast } from "atoms/toast";
import { Title } from "atoms/Typography";
import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import { checkActiveState } from "components/Lenses/utils";
import OffsetWellSelector from "components/OffsetWellSelector";
import { PDComponent } from "components/PDComponents";
import { initialFiltersState } from "components/RigDashboard/ControlHeader";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import Filters from "components/WellDashboard/ControlHeader/atoms/Filters";
import Zoom from "components/WellDashboard/ControlHeader/atoms/Zoom";
import { useAvailableFilters } from "hooks/useAvailableFilters";
import { useFiltersLoading } from "hooks/useFiltersLoading";
import { useRigs } from "hooks/useRigs";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useWellDetails } from "hooks/useWellDetails";
import { isEqual, xor } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { initialState } from "reducers/reportReducer";
import { useAppDispatch } from "reducers/store";
import { store, useAppSelector } from "reducers/store";
import type { IFiltersType, IZoomData } from "reducers/types";
import { Track } from "services/Mixpanel";
import { Col, Divider, Popover, Row, Tooltip } from "utils/componentLibrary";
import { createReport, keepCheckingForReport } from "utils/reports";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

const ExportTopBar = ({ selectedCount }: { selectedCount: number }) => {
  const dispatch = useAppDispatch();
  const rigs = useRigs();
  const wellId = useSelectedWell();
  const zoomRef = useRef<HTMLButtonElement>(null);
  const filterRef = useRef<HTMLButtonElement>(null);

  const [zoomOpen, setZoomOpen] = useState<boolean>(false);
  const filtersState = useAppSelector((state) => state.report.filters) ?? initialState.filters;
  const zoomData = useAppSelector((state) => state.report.zoom);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const { filtersLoading, directionalIntervals, holeSections, holeSizes } = useFiltersLoading();

  const { data: availableFilters } = useAvailableFilters(null, zoomData, filtersState);

  useEffect(() => {
    if (availableFilters) {
      dispatch({
        type: "SET_AVAILABLE_FILTERS",
        payload: availableFilters,
      });
    }
  }, [availableFilters, dispatch]);

  const uom = useAppSelector((state) => state.global.unit);
  const zoomActiveState = zoomData && !isEqual(initialZoomData, zoomData);

  const filtersActiveState = checkActiveState(availableFilters, filtersState);

  const [isLoading, setIsLoading] = useState(false);
  const { data: selectedWellInfo } = useWellDetails(wellId);
  useEffect(() => {
    dispatch({
      type: "SET_REPORT_SELECTED_WELL",
      payload: wellId,
    });
  }, [dispatch, wellId]);

  useEffect(() => {
    const reportID = window.localStorage.getItem("reports");
    setIsLoading(reportID !== null);
  }, []);

  const applyFilters = (filters: IFiltersType, state: boolean) => {
    dispatch({
      type: "SET_REPORT_FILTERS",
      payload: { filters, state },
    });
  };

  const resetZoomData = useCallback(() => {
    dispatch({
      type: "RESET_REPORT_ZOOM",
    });
    setZoomOpen(false);
  }, [dispatch]);

  const setZoomData = useCallback(
    (zoom: IZoomData) => {
      if (zoom)
        dispatch({
          type: "SET_REPORT_ZOOM",
          payload: { zoom, state: true },
        });
      setZoomOpen(false);
    },
    [dispatch],
  );

  const generateReport = () => {
    const reportState = store.getState().report;
    const payloadItems = [store.getState().report];
    return createReport({
      wellId: Number(reportState.selectedWell),
      lenses: reportState?.selectedLenses?.map((e) => +(e.id ?? 0)) || [],
      payloadItems,
      uom,
    }).then((e) => {
      setIsLoading(true);
      window.localStorage.setItem("reports", JSON.stringify(e));
      toast.loading({
        message: `Generating report`,
      });
      keepCheckingForReport(e).finally(() => {
        toast.close("loading");
        window.localStorage.removeItem("reports");
        setIsLoading(false);
      });
    });
  };

  useEffect(() => {
    if (zoomRef.current) zoomRef.current.blur();
    if (filterRef.current) filterRef.current.blur();
  }, [isFiltersOpen]);

  useEffect(() => {
    if (zoomRef.current) zoomRef.current.blur();
    if (filterRef.current) filterRef.current.blur();
  }, [zoomOpen]);
  const { atomThemeVariant } = useCustomTheme();

  const uniqueRigIdsLabel = useMemo(() => {
    const uniqueRigIds = [...new Set(selectedWellInfo?.jobs.map((e) => e.rigId))];
    return uniqueRigIds.map((e) => rigs?.data?.byId[e]?.shortName || "").join(", ");
  }, [selectedWellInfo?.jobs, rigs?.data]);

  return (
    <Row>
      <Col flex="1">
        <Row
          justify="space-between"
          align="middle"
          style={{
            padding: "0 24px",
          }}
        >
          <Col flex="1 auto">
            <Row>
              <Title
                level={3}
                variant={atomThemeVariant}
                title={selectedWellInfo?.name || ""}
                style={{ overflow: "hidden", textAlign: "left", maxWidth: "320px", textOverflow: "ellipsis" }}
              >
                {`${selectedWellInfo?.name} ·\u00A0`}
              </Title>
              <Title level={3} variant="faded" style={{ display: "inline-block" }}>
                {uniqueRigIdsLabel}
              </Title>
            </Row>
          </Col>
          <Col>
            <Row gutter={8}>
              <Col>
                <Tooltip title="Zoom">
                  <Button
                    size="large"
                    ref={zoomRef}
                    icon={<PDComponent.SvgIcon name="zoomIn" />}
                    onClick={() => setZoomOpen(true)}
                    style={{
                      // modal has 100 (+1 for the zoom button)
                      zIndex: zIndexLayer.sky_plus,
                    }}
                    type={zoomActiveState ? "primary" : "default"}
                    ghost={zoomActiveState}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Filter">
                  <Popover
                    content={
                      isFiltersOpen && !filtersLoading ? (
                        <Filters
                          appliedState={filtersState}
                          availableFilters={availableFilters}
                          directionalIntervals={directionalIntervals}
                          holeSections={holeSections}
                          holeSizes={holeSizes}
                          onCancel={() => setIsFiltersOpen(false)}
                          report
                          setFilterData={applyFilters}
                          trackingProps={{
                            Well: selectedWellInfo?.name,
                          }}
                          visible={isFiltersOpen}
                        />
                      ) : null
                    }
                    trigger={["click"]}
                    placement="bottomRight"
                    open={isFiltersOpen}
                    onOpenChange={(e) => setIsFiltersOpen(e)}
                    destroyTooltipOnHide
                  >
                    <Button
                      size="large"
                      ref={filterRef}
                      icon={<PDComponent.SvgIcon name="filter" />}
                      loading={filtersLoading}
                      onClick={() => setIsFiltersOpen((fl: boolean) => !fl)}
                      type={filtersActiveState ? "primary" : "default"}
                      ghost={filtersActiveState}
                    />
                  </Popover>
                </Tooltip>
              </Col>
              <Col>
                <OffsetWellSelector report />
              </Col>
              <Col>
                <Divider type="vertical" style={{ margin: 0, height: "90%" }} />
              </Col>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    Track.clickEvent("Report Builder - Create Report");
                    generateReport();
                  }}
                  loading={isLoading}
                  disabled={selectedCount === 0 || isLoading}
                >
                  Create Report
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Zoom
        report
        onCancel={() => setZoomOpen(false)}
        visible={zoomOpen}
        setZoomData={setZoomData}
        resetZoomData={resetZoomData}
        zoomData={zoomData ?? initialZoomData}
        availableActions={{
          time: MatrixAvailability.AVAILABLE,
          date: MatrixAvailability.AVAILABLE,
          depth: MatrixAvailability.AVAILABLE,
        }}
      />
    </Row>
  );
};

export default ExportTopBar;
