/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ActualTimelineEventDto } from './ActualTimelineEventDto';
import {
    ActualTimelineEventDtoFromJSON,
    ActualTimelineEventDtoFromJSONTyped,
    ActualTimelineEventDtoToJSON,
} from './ActualTimelineEventDto';
import type { TimelineSegmentDto } from './TimelineSegmentDto';
import {
    TimelineSegmentDtoFromJSON,
    TimelineSegmentDtoFromJSONTyped,
    TimelineSegmentDtoToJSON,
} from './TimelineSegmentDto';

/**
 * 
 * @export
 * @interface ActualTimelineDto
 */
export interface ActualTimelineDto {
    /**
     * 
     * @type {Array<TimelineSegmentDto>}
     * @memberof ActualTimelineDto
     */
    sections?: Array<TimelineSegmentDto> | null;
    /**
     * 
     * @type {Array<TimelineSegmentDto>}
     * @memberof ActualTimelineDto
     */
    phases?: Array<TimelineSegmentDto> | null;
    /**
     * 
     * @type {Array<ActualTimelineEventDto>}
     * @memberof ActualTimelineDto
     */
    events?: Array<ActualTimelineEventDto> | null;
}

/**
 * Check if a given object implements the ActualTimelineDto interface.
 */
export function instanceOfActualTimelineDto(value: object): value is ActualTimelineDto {
    return true;
}

export function ActualTimelineDtoFromJSON(json: any): ActualTimelineDto {
    return ActualTimelineDtoFromJSONTyped(json, false);
}

export function ActualTimelineDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActualTimelineDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sections': json['Sections'] == null ? undefined : ((json['Sections'] as Array<any>).map(TimelineSegmentDtoFromJSON)),
        'phases': json['Phases'] == null ? undefined : ((json['Phases'] as Array<any>).map(TimelineSegmentDtoFromJSON)),
        'events': json['Events'] == null ? undefined : ((json['Events'] as Array<any>).map(ActualTimelineEventDtoFromJSON)),
    };
}

export function ActualTimelineDtoToJSON(value?: ActualTimelineDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Sections': value['sections'] == null ? undefined : ((value['sections'] as Array<any>).map(TimelineSegmentDtoToJSON)),
        'Phases': value['phases'] == null ? undefined : ((value['phases'] as Array<any>).map(TimelineSegmentDtoToJSON)),
        'Events': value['events'] == null ? undefined : ((value['events'] as Array<any>).map(ActualTimelineEventDtoToJSON)),
    };
}

