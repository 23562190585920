/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandKpiSliceDto } from './StandKpiSliceDto';
import {
    StandKpiSliceDtoFromJSON,
    StandKpiSliceDtoFromJSONTyped,
    StandKpiSliceDtoToJSON,
} from './StandKpiSliceDto';

/**
 * 
 * @export
 * @interface StackedStandKpiDetailByShiftDto
 */
export interface StackedStandKpiDetailByShiftDto {
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    dayTargetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    nightTargetValue?: number | null;
    /**
     * 
     * @type {Array<StandKpiSliceDto>}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    daySlices?: Array<StandKpiSliceDto> | null;
    /**
     * 
     * @type {Array<StandKpiSliceDto>}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    nightSlices?: Array<StandKpiSliceDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    operationCount: number;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    dayOperationCount: number;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    nightOperationCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof StackedStandKpiDetailByShiftDto
     */
    isOutlier: boolean;
}

/**
 * Check if a given object implements the StackedStandKpiDetailByShiftDto interface.
 */
export function instanceOfStackedStandKpiDetailByShiftDto(value: object): value is StackedStandKpiDetailByShiftDto {
    if (!('operationCount' in value) || value['operationCount'] === undefined) return false;
    if (!('dayOperationCount' in value) || value['dayOperationCount'] === undefined) return false;
    if (!('nightOperationCount' in value) || value['nightOperationCount'] === undefined) return false;
    if (!('isOutlier' in value) || value['isOutlier'] === undefined) return false;
    return true;
}

export function StackedStandKpiDetailByShiftDtoFromJSON(json: any): StackedStandKpiDetailByShiftDto {
    return StackedStandKpiDetailByShiftDtoFromJSONTyped(json, false);
}

export function StackedStandKpiDetailByShiftDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackedStandKpiDetailByShiftDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dayTargetValue': json['DayTargetValue'] == null ? undefined : json['DayTargetValue'],
        'nightTargetValue': json['NightTargetValue'] == null ? undefined : json['NightTargetValue'],
        'daySlices': json['DaySlices'] == null ? undefined : ((json['DaySlices'] as Array<any>).map(StandKpiSliceDtoFromJSON)),
        'nightSlices': json['NightSlices'] == null ? undefined : ((json['NightSlices'] as Array<any>).map(StandKpiSliceDtoFromJSON)),
        'operationCount': json['OperationCount'],
        'dayOperationCount': json['DayOperationCount'],
        'nightOperationCount': json['NightOperationCount'],
        'isOutlier': json['IsOutlier'],
    };
}

export function StackedStandKpiDetailByShiftDtoToJSON(value?: StackedStandKpiDetailByShiftDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'DayTargetValue': value['dayTargetValue'],
        'NightTargetValue': value['nightTargetValue'],
        'DaySlices': value['daySlices'] == null ? undefined : ((value['daySlices'] as Array<any>).map(StandKpiSliceDtoToJSON)),
        'NightSlices': value['nightSlices'] == null ? undefined : ((value['nightSlices'] as Array<any>).map(StandKpiSliceDtoToJSON)),
        'OperationCount': value['operationCount'],
        'DayOperationCount': value['dayOperationCount'],
        'NightOperationCount': value['nightOperationCount'],
        'IsOutlier': value['isOutlier'],
    };
}

