import type {
  BatteryStateUserLensDto,
  DateDto,
  DieselNatGasRatioStatsUserLensDto,
  DimensionType,
  DrillingProductivityUserLensDto,
  FuelTankVolumeUserLensDto,
  GeneratorLoadProfileUserLensDto,
  GeneratorStatsUserLensDto,
  GhgEmissionsUserLensDto,
  KpiGroupUserLensDto,
  KpiTypeUserLensDto,
  NaturalGasStateUserLensDto,
  ParameterByDepthUserLensDto,
  ParameterByDepthUserLensTrackItemDto,
  PivotKpiGroupUserLensDto,
  PivotKpiTypeUserLensDto,
  PowerLoadEfficiencyUserLensDto,
  RigPowerUserLensDto,
  StickSlipByDepthUserLensDto,
  StickSlipByTimeUserLensDto,
  TagBottomFingerprintUserLensDto,
  TorqueAndDragUserLensDto,
  UserLensDto,
  WedgeUserLensDto,
} from "apis/oag";
import {
  BatteryStateUserLensesApi,
  DieselNatGasRatioStatsUserLensesApi,
  DrillingProductivityUserLensesApi,
  FuelTankVolumeUserLensesApi,
  GeneratorLoadProfileUserLensesApi,
  GeneratorStatsUserLensesApi,
  GhgEmissionsUserLensesApi,
  KpiGroupUserLensesApi,
  KpiTypeUserLensesApi,
  LensTemplateType,
  NaturalGasStateUserLensesApi,
  ParameterByDepthUserLensesApi,
  PivotKpiGroupUserLensesApi,
  PivotKpiTypeUserLensesApi,
  PowerLoadEfficiencyUserLensesApi,
  RigPowerUserLensesApi,
  StickSlipByDepthUserLensesApi,
  StickSlipByTimeUserLensesApi,
  TagBottomFingerprintUserLensesApi,
  TorqueAndDragUserLensesApi,
  WedgeUserLensesApi,
} from "apis/oag";
import BatteryState from "components/Lenses/ContainerLens/BatteryStateKpi";
import { getBatteryStateKpiAbilityMatrix } from "components/Lenses/ContainerLens/BatteryStateKpi/AbilityMatrix";
import type {
  AbilityMatrixProps,
  AbilityMatrixType,
} from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";
import { DieselNatGasRatioKpi } from "components/Lenses/ContainerLens/DieselNatGasRatio";
import { getDieselNatGasRatioAbilityMatrix } from "components/Lenses/ContainerLens/DieselNatGasRatio/AbilityMatrix";
import { DrillingProductivity } from "components/Lenses/ContainerLens/DrillingProductivity";
import { getDrillingProductivityAbilityMatrix } from "components/Lenses/ContainerLens/DrillingProductivity/AbilityMatrix";
import { FuelConsumptionByGeneratorKpi } from "components/Lenses/ContainerLens/FuelConsumptionByGenerator";
import { getFuelConsumptionByGeneratorAbilityMatrix } from "components/Lenses/ContainerLens/FuelConsumptionByGenerator/AbilityMatrix";
import { FuelFlowRateKpi } from "components/Lenses/ContainerLens/FuelFlowRate";
import { getFuelFlowRateAbilityMatrix } from "components/Lenses/ContainerLens/FuelFlowRate/AbilityMatrix";
import { getFuelTankVolumeAbilityMatrix } from "components/Lenses/ContainerLens/FuelTankVolume/AbilityMatrix";
import { FuelTankVolume } from "components/Lenses/ContainerLens/FuelTankVolume/index";
import { getGeneratorLoadProfileAbilityMatrix } from "components/Lenses/ContainerLens/GeneratorLoadProfile/AbilityMatrix";
import { GeneratorLoadProfile } from "components/Lenses/ContainerLens/GeneratorLoadProfile/index";
import { GeneratorStatsKpi } from "components/Lenses/ContainerLens/GeneratorStats";
import { getGeneratorStatsAbilityMatrix } from "components/Lenses/ContainerLens/GeneratorStats/AbilityMatrix";
import { GeneratorStatusBar } from "components/Lenses/ContainerLens/GeneratorStatusBar";
import { getGeneratorStatusBarAbilityMatrix } from "components/Lenses/ContainerLens/GeneratorStatusBar/AbilityMatrix";
import { GHGEmissions } from "components/Lenses/ContainerLens/GHGEmissions";
import { getGHGEmissionsAbilityMatrix } from "components/Lenses/ContainerLens/GHGEmissions/AbilityMatrix";
import { NaturalGasState } from "components/Lenses/ContainerLens/NaturalGasState";
import { getNaturalGasStateAbilityMatrix } from "components/Lenses/ContainerLens/NaturalGasState/AbilityMatrix";
import { ParameterByDepthKPI } from "components/Lenses/ContainerLens/ParameterByDepthKPI";
import {
  getParameterByDepthAbilityMatrix,
  getParameterRoadmapAbilityMatrix,
} from "components/Lenses/ContainerLens/ParameterByDepthKPI/AbilityMatrix";
import ParameterHeatmapKpi from "components/Lenses/ContainerLens/ParameterHeatmapKpi";
import { getParameterHeatmapKpiAbilityMatrix } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/AbilityMatrix";
import { ParameterHeatmapSettingsModal } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/ParameterHeatmapSettingsModal";
import { ParameterHeatmapSettings } from "components/Lenses/ContainerLens/ParameterHeatmapKpi/settings";
import PivotKpi from "components/Lenses/ContainerLens/PivotKpi";
import { getPivotKpiAbilityMatrix } from "components/Lenses/ContainerLens/PivotKpi/AbilityMatrix";
import { PowerConsumptionByOperation } from "components/Lenses/ContainerLens/PowerConsumptionByOperation";
import { getPowerConsumptionByOperationAbilityMatrix } from "components/Lenses/ContainerLens/PowerConsumptionByOperation/AbilityMatrix";
import { PowerLoadDistribution } from "components/Lenses/ContainerLens/PowerLoadDistribution";
import { getPowerLoadDistributionAbilityMatrix } from "components/Lenses/ContainerLens/PowerLoadDistribution/AbilityMatrix";
import { PowerLoadEfficiencyKpi } from "components/Lenses/ContainerLens/PowerLoadEfficiency";
import { getPowerLoadEfficiencyAbilityMatrix } from "components/Lenses/ContainerLens/PowerLoadEfficiency/AbilityMatrix";
import { RigPower } from "components/Lenses/ContainerLens/RigPower";
import { getRigPowerAbilityMatrix } from "components/Lenses/ContainerLens/RigPower/AbilityMatrix";
import { SingleKPI } from "components/Lenses/ContainerLens/SingleKpi";
import { getSingleKpiAbilityMatrix } from "components/Lenses/ContainerLens/SingleKpi/AbilityMatrix";
import StackedKPI from "components/Lenses/ContainerLens/StackedKpi";
import { getStackedKpiAbilityMatrix } from "components/Lenses/ContainerLens/StackedKpi/AbilityMatrix";
import { StatCard } from "components/Lenses/ContainerLens/StatCard";
import { getStatCardAbilityMatrix } from "components/Lenses/ContainerLens/StatCard/AbilityMatrix";
import { getStickSlipByDepthAbilityMatrix } from "components/Lenses/ContainerLens/StickSlipByDepth/AbilityMatrix";
import { StickSlipByDepth } from "components/Lenses/ContainerLens/StickSlipByDepth/StickSlipByDepth";
import { StickSlipByTime } from "components/Lenses/ContainerLens/StickSlipByTime";
import { getStickSlipByTimeAbilityMatrix } from "components/Lenses/ContainerLens/StickSlipByTime/AbilityMatrix";
import { TagBottomFingerprint } from "components/Lenses/ContainerLens/TagBottomFingerprint";
import { getTagBottomFingerprintAbilityMatrix } from "components/Lenses/ContainerLens/TagBottomFingerprint/AbilityMatrix";
import { TagBottomFingerprintModal } from "components/Lenses/ContainerLens/TagBottomFingerprint/TagBottomFingerprintModal";
import { TorqueAndDrag } from "components/Lenses/ContainerLens/TorqueAndDrag";
import { getTorqueAndDragAbilityMatrix } from "components/Lenses/ContainerLens/TorqueAndDrag/AbilityMatrix";
import { WedgeKpi } from "components/Lenses/ContainerLens/WedgeKpi";
import { getWedgeKpiAbilityMatrix } from "components/Lenses/ContainerLens/WedgeKpi/AbilityMatrix";
import { WellDrillingSummaryKpi } from "components/Lenses/ContainerLens/WellDrillingSummaryKpi";
import { getWellDrillingSummaryKpiAbilityMatrix } from "components/Lenses/ContainerLens/WellDrillingSummaryKpi/AbilityMatrix";
import { apiConfig } from "utils/apiConfig";

export interface ContainerLensProps {
  lens: UserLensDto;
  detailed: boolean;
  isMock?: boolean;
  isLocked?: boolean;
  dimension?: DimensionType;
  setLensDate?: (date: string | DateDto) => void;
  onLensUpdated?: (newItem: UserLensDto) => void;
  graphKey?: string;
  focalWellColor?: string;
  selectedTrackItems?: ParameterByDepthUserLensTrackItemDto[];
  setSelectedTrackItems?: React.Dispatch<React.SetStateAction<ParameterByDepthUserLensTrackItemDto[]>>;
}

interface ContainerComponentParts {
  Component: React.FC<any>;
  SpecificTopbarSettings?: React.FC<any>;
  // TODO remove this any
  LensSettingsModal?: any
  lensUpdater?: (newLens: any) => Promise<any>;
  settings?: {
    hasHeader?: boolean;
    hasHiddenToolbar?: boolean;
    defaultHeight?: string;
  };
  getAbilityMatrix?: (params?: AbilityMatrixProps) => AbilityMatrixType;
}

const pivotKpiTypeApi = new PivotKpiTypeUserLensesApi(apiConfig);
const pivotKpiGroupApi = new PivotKpiGroupUserLensesApi(apiConfig);
const parameterByDepthApi = new ParameterByDepthUserLensesApi(apiConfig);
const kpiTypeLensApi = new KpiTypeUserLensesApi(apiConfig);
const kpiGroupLensApi = new KpiGroupUserLensesApi(apiConfig);
const torqueAndDragApi = new TorqueAndDragUserLensesApi(apiConfig);
const wedgeApi = new WedgeUserLensesApi(apiConfig);
const rigPowerApi = new RigPowerUserLensesApi(apiConfig);
const batteryStateApi = new BatteryStateUserLensesApi(apiConfig);
const naturalGasStateApi = new NaturalGasStateUserLensesApi(apiConfig);
const generatorLoadProfileStateApi = new GeneratorLoadProfileUserLensesApi(apiConfig);
const generatorStatsApi = new GeneratorStatsUserLensesApi(apiConfig);
const GHGEmissionsApi = new GhgEmissionsUserLensesApi(apiConfig);
const fuelTankVolumeApi = new FuelTankVolumeUserLensesApi(apiConfig);
const powerLoadEfficiencyApi = new PowerLoadEfficiencyUserLensesApi(apiConfig);
const dieselNatGasRatioApi = new DieselNatGasRatioStatsUserLensesApi(apiConfig);
const drillingProductivityApi = new DrillingProductivityUserLensesApi(apiConfig);
const tagBottomFingerprintApi = new TagBottomFingerprintUserLensesApi(apiConfig);
const stickSlipByTimeApi = new StickSlipByTimeUserLensesApi(apiConfig);
const stickSlipByDepthApi = new StickSlipByDepthUserLensesApi(apiConfig);

export const getContainerLens = (templateType?: LensTemplateType): ContainerComponentParts | null => {
  switch (templateType) {
    case LensTemplateType.ParameterHeatmap:
      return {
        Component: ParameterHeatmapKpi,
        SpecificTopbarSettings: ParameterHeatmapSettings,
        LensSettingsModal: ParameterHeatmapSettingsModal,
        getAbilityMatrix: getParameterHeatmapKpiAbilityMatrix,
      };
    case LensTemplateType.ParameterByDepth:
      return {
        Component: ParameterByDepthKPI,
        settings: { defaultHeight: "100%" },
        getAbilityMatrix: getParameterByDepthAbilityMatrix,
        lensUpdater: (newLens: ParameterByDepthUserLensDto) =>
          parameterByDepthApi.apiParameterByDepthUserLensesIdPut({
            id: newLens.id,
            parameterByDepthUserLensDto: newLens,
          }),
      };
    case LensTemplateType.ParameterByDepthRoadmap:
      return {
        Component: ParameterByDepthKPI,
        settings: { defaultHeight: "100%" },
        getAbilityMatrix: getParameterRoadmapAbilityMatrix,
        lensUpdater: (newLens: ParameterByDepthUserLensDto) =>
          parameterByDepthApi.apiParameterByDepthUserLensesIdPut({
            id: newLens.id,
            parameterByDepthUserLensDto: newLens,
          }),
      };
    case LensTemplateType.ParameterByDepthUnified:
      return {
        Component: ParameterByDepthKPI,
        settings: { defaultHeight: "100%" },
      };

    case LensTemplateType.WellDrillingSummary:
      return {
        Component: WellDrillingSummaryKpi,
        settings: { defaultHeight: "100%" },
        getAbilityMatrix: getWellDrillingSummaryKpiAbilityMatrix,
      };

    case LensTemplateType.PivotKpiGroup:
      return {
        Component: PivotKpi,
        settings: { defaultHeight: "100%", hasHeader: true },
        getAbilityMatrix: getPivotKpiAbilityMatrix,
        lensUpdater: (newLens: PivotKpiGroupUserLensDto) =>
          pivotKpiGroupApi.apiPivotKpiGroupUserLensesIdPut({
            id: newLens.id,
            pivotKpiGroupUserLensDto: newLens,
          }),
      };

    case LensTemplateType.PivotKpiType:
      return {
        Component: PivotKpi,
        settings: { defaultHeight: "100%", hasHeader: true },
        getAbilityMatrix: getPivotKpiAbilityMatrix,
        lensUpdater: (newLens: PivotKpiTypeUserLensDto) =>
          pivotKpiTypeApi.apiPivotKpiTypeUserLensesIdPut({
            id: newLens.id,
            pivotKpiTypeUserLensDto: newLens,
          }),
      };

    case LensTemplateType.TorqueAndDrag:
      return {
        Component: TorqueAndDrag,
        settings: { defaultHeight: "100%", hasHeader: true },
        getAbilityMatrix: getTorqueAndDragAbilityMatrix,
        lensUpdater: (newLens: TorqueAndDragUserLensDto) =>
          torqueAndDragApi.apiTorqueAndDragUserLensesIdPut({
            id: newLens.id,
            torqueAndDragUserLensDto: newLens,
          }),
      };

    case LensTemplateType.GeneratorStatusBar:
      return {
        Component: GeneratorStatusBar,
        getAbilityMatrix: getGeneratorStatusBarAbilityMatrix,
      };

    case LensTemplateType.PowerConsumptionByOperation:
      return {
        Component: PowerConsumptionByOperation,
        getAbilityMatrix: getPowerConsumptionByOperationAbilityMatrix,
      };

    case LensTemplateType.RigPower:
      return {
        Component: RigPower,
        getAbilityMatrix: getRigPowerAbilityMatrix,
        lensUpdater: (newLens: RigPowerUserLensDto) =>
          rigPowerApi.apiRigPowerUserLensesIdPut({
            id: newLens.id,
            rigPowerUserLensDto: newLens,
          }),
      };

    case LensTemplateType.BatteryState:
      return {
        Component: BatteryState,
        settings: { hasHeader: true },
        getAbilityMatrix: getBatteryStateKpiAbilityMatrix,
        lensUpdater: (newLens: BatteryStateUserLensDto) =>
          batteryStateApi.apiBatteryStateUserLensesIdPut({
            id: newLens.id,
            batteryStateUserLensDto: newLens,
          }),
      };

    case LensTemplateType.StatCard:
      return {
        Component: StatCard,
        settings: { hasHiddenToolbar: true },
        getAbilityMatrix: getStatCardAbilityMatrix,
      };

    case LensTemplateType.PowerLoadDistribution:
      return {
        Component: PowerLoadDistribution,
        getAbilityMatrix: getPowerLoadDistributionAbilityMatrix,
      };

    case LensTemplateType.FuelFlowRate:
      return {
        Component: FuelFlowRateKpi,
        getAbilityMatrix: getFuelFlowRateAbilityMatrix,
      };

    case LensTemplateType.NaturalGasState:
      return {
        Component: NaturalGasState,
        getAbilityMatrix: getNaturalGasStateAbilityMatrix,
        lensUpdater: (newLens: NaturalGasStateUserLensDto) =>
          naturalGasStateApi.apiNaturalGasStateUserLensesIdPut({
            id: newLens.id,
            naturalGasStateUserLensDto: newLens,
          }),
      };

    case LensTemplateType.GeneratorLoadProfile:
      return {
        Component: GeneratorLoadProfile,
        getAbilityMatrix: getGeneratorLoadProfileAbilityMatrix,
        lensUpdater: (newLens: GeneratorLoadProfileUserLensDto) =>
          generatorLoadProfileStateApi.apiGeneratorLoadProfileUserLensesIdPut({
            id: newLens.id,
            generatorLoadProfileUserLensDto: newLens,
          }),
      };

    case LensTemplateType.GeneratorStats:
      return {
        Component: GeneratorStatsKpi,
        getAbilityMatrix: getGeneratorStatsAbilityMatrix,
        lensUpdater: (newLens: GeneratorStatsUserLensDto) =>
          generatorStatsApi.apiGeneratorStatsUserLensesIdPut({
            id: newLens.id,
            generatorStatsUserLensDto: newLens,
          }),
      };

    case LensTemplateType.GhgEmissions:
      return {
        Component: GHGEmissions,
        getAbilityMatrix: getGHGEmissionsAbilityMatrix,
        lensUpdater: (newLens: GhgEmissionsUserLensDto) =>
          GHGEmissionsApi.apiGhgEmissionsUserLensesIdPut({
            id: newLens.id,
            ghgEmissionsUserLensDto: newLens,
          }),
      };
    case LensTemplateType.FuelTankVolume:
      return {
        Component: FuelTankVolume,
        getAbilityMatrix: getFuelTankVolumeAbilityMatrix,
        lensUpdater: (newLens: FuelTankVolumeUserLensDto) =>
          fuelTankVolumeApi.apiFuelTankVolumeUserLensesIdPut({
            id: newLens.id,
            fuelTankVolumeUserLensDto: newLens,
          }),
      };
    case LensTemplateType.PowerLoadEfficiency:
      return {
        Component: PowerLoadEfficiencyKpi,
        getAbilityMatrix: getPowerLoadEfficiencyAbilityMatrix,
        lensUpdater: (newLens: PowerLoadEfficiencyUserLensDto) =>
          powerLoadEfficiencyApi.apiPowerLoadEfficiencyUserLensesIdPut({
            id: newLens.id,
            powerLoadEfficiencyUserLensDto: newLens,
          }),
      };
    case LensTemplateType.FuelConsumptionByGenerator:
      return {
        Component: FuelConsumptionByGeneratorKpi,
        getAbilityMatrix: getFuelConsumptionByGeneratorAbilityMatrix,
      };

    case LensTemplateType.DieselNatGasRatioStats:
      return {
        Component: DieselNatGasRatioKpi,
        settings: { hasHiddenToolbar: true },
        getAbilityMatrix: getDieselNatGasRatioAbilityMatrix,
        lensUpdater: (newLens: DieselNatGasRatioStatsUserLensDto) =>
          dieselNatGasRatioApi.apiDieselNatGasRatioStatsUserLensesIdPut({
            id: newLens.id,
            dieselNatGasRatioStatsUserLensDto: newLens,
          }),
      };

    case LensTemplateType.DrillingProductivity:
      return {
        Component: DrillingProductivity,
        getAbilityMatrix: getDrillingProductivityAbilityMatrix,
        lensUpdater: (newLens: DrillingProductivityUserLensDto) =>
          drillingProductivityApi.apiDrillingProductivityUserLensesIdPut({
            id: newLens.id,
            drillingProductivityUserLensDto: newLens,
          }),
      };
    case LensTemplateType.Wedge:
      return {
        Component: WedgeKpi,
        getAbilityMatrix: getWedgeKpiAbilityMatrix,
        lensUpdater: (newLens: WedgeUserLensDto) =>
          wedgeApi.apiWedgeUserLensesIdPut({
            id: newLens.id,
            wedgeUserLensDto: newLens,
          }),
      };

    case LensTemplateType.SingleKpi:
      return {
        Component: SingleKPI,
        getAbilityMatrix: getSingleKpiAbilityMatrix,
        lensUpdater: (newLens: KpiTypeUserLensDto) =>
          kpiTypeLensApi.apiKpiTypeUserLensesIdPut({
            id: newLens.id,
            kpiTypeUserLensDto: newLens,
          }),
      };
    case LensTemplateType.StackedKpis:
      return {
        Component: StackedKPI,
        getAbilityMatrix: getStackedKpiAbilityMatrix,
        lensUpdater: (newLens: KpiGroupUserLensDto) =>
          kpiGroupLensApi.apiKpiGroupUserLensesIdPut({
            id: newLens.id,
            kpiGroupUserLensDto: newLens,
          }),
      };
    case LensTemplateType.TagBottomFingerprint:
      return {
        Component: TagBottomFingerprint,
        LensSettingsModal: TagBottomFingerprintModal,
        getAbilityMatrix: getTagBottomFingerprintAbilityMatrix,
        lensUpdater: (newLens: TagBottomFingerprintUserLensDto) =>
          tagBottomFingerprintApi.apiTagBottomFingerprintUserLensesIdPut({
            id: newLens.id,
            tagBottomFingerprintUserLensDto: newLens,
          }),
      };
    case LensTemplateType.StickSlipByTime:
      return {
        Component: StickSlipByTime,
        getAbilityMatrix: getStickSlipByTimeAbilityMatrix,
        lensUpdater: (newLens: StickSlipByTimeUserLensDto) =>
          stickSlipByTimeApi.apiStickSlipByTimeUserLensesIdPut({
            id: newLens.id,
            stickSlipByTimeUserLensDto: newLens,
          }),
      };
    case LensTemplateType.StickSlipByDepth:
      return {
        Component: StickSlipByDepth,
        settings: { defaultHeight: "100%" },
        getAbilityMatrix: getStickSlipByDepthAbilityMatrix,
        lensUpdater: (newLens: StickSlipByDepthUserLensDto) =>
          stickSlipByDepthApi.apiStickSlipByDepthUserLensesIdPut({
            id: newLens.id,
            stickSlipByDepthUserLensDto: newLens,
          }),
      };

    // ! lens boilerplate
    default:
      return null;
  }
};
