/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrillingProductivityType } from './DrillingProductivityType';
import {
    DrillingProductivityTypeFromJSON,
    DrillingProductivityTypeFromJSONTyped,
    DrillingProductivityTypeToJSON,
} from './DrillingProductivityType';
import type { TimeUnit } from './TimeUnit';
import {
    TimeUnitFromJSON,
    TimeUnitFromJSONTyped,
    TimeUnitToJSON,
} from './TimeUnit';

/**
 * 
 * @export
 * @interface DrillingProductivityUserLensDto
 */
export interface DrillingProductivityUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {TimeUnit}
     * @memberof DrillingProductivityUserLensDto
     */
    selectedTimeUnit: TimeUnit;
    /**
     * 
     * @type {boolean}
     * @memberof DrillingProductivityUserLensDto
     */
    isManualYaxis: boolean;
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityUserLensDto
     */
    yaxisStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DrillingProductivityUserLensDto
     */
    yaxisEnd?: number | null;
    /**
     * 
     * @type {DrillingProductivityType}
     * @memberof DrillingProductivityUserLensDto
     */
    drillingProductivityType: DrillingProductivityType;
}



/**
 * Check if a given object implements the DrillingProductivityUserLensDto interface.
 */
export function instanceOfDrillingProductivityUserLensDto(value: object): value is DrillingProductivityUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('squeezesDisplay' in value) || value['squeezesDisplay'] === undefined) return false;
    if (!('selectedTimeUnit' in value) || value['selectedTimeUnit'] === undefined) return false;
    if (!('isManualYaxis' in value) || value['isManualYaxis'] === undefined) return false;
    if (!('drillingProductivityType' in value) || value['drillingProductivityType'] === undefined) return false;
    return true;
}

export function DrillingProductivityUserLensDtoFromJSON(json: any): DrillingProductivityUserLensDto {
    return DrillingProductivityUserLensDtoFromJSONTyped(json, false);
}

export function DrillingProductivityUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingProductivityUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedTimeUnit': TimeUnitFromJSON(json['SelectedTimeUnit']),
        'isManualYaxis': json['IsManualYaxis'],
        'yaxisStart': json['YaxisStart'] == null ? undefined : json['YaxisStart'],
        'yaxisEnd': json['YaxisEnd'] == null ? undefined : json['YaxisEnd'],
        'drillingProductivityType': DrillingProductivityTypeFromJSON(json['DrillingProductivityType']),
    };
}

export function DrillingProductivityUserLensDtoToJSON(value?: DrillingProductivityUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'SqueezesDisplay': value['squeezesDisplay'],
        'SelectedTimeUnit': TimeUnitToJSON(value['selectedTimeUnit']),
        'IsManualYaxis': value['isManualYaxis'],
        'YaxisStart': value['yaxisStart'],
        'YaxisEnd': value['yaxisEnd'],
        'DrillingProductivityType': DrillingProductivityTypeToJSON(value['drillingProductivityType']),
    };
}

