import { useQuery } from "@tanstack/react-query";
import type {
  ApiDrillingProductivityUserLensesIdFactsPutRequest,
  SingleStandKpiComparisonDto,
  SingleStandKpiDto,
} from "apis/oag";
import { DrillingProductivityUserLensesApi } from "apis/oag";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new DrillingProductivityUserLensesApi(apiConfig);

export const useDrillingProductivityFacts = (
  lensId: number,
  isComparing: boolean,
  requestParameters: ApiDrillingProductivityUserLensesIdFactsPutRequest,
) => {
  const selectedWell = useAppSelector((state) => state.state.selectedWell);
  const dispatch = useAppDispatch();

  const queryKey: PDLensFactsQueryKey<ApiDrillingProductivityUserLensesIdFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.drillingProductivityFacts,
    params: requestParameters,
    lensId,
  };

  // using use query so that we don t trigger the suspense, so isLoading can change the uom when the data is fetched
  return useQuery<SingleStandKpiDto | SingleStandKpiComparisonDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      (isComparing
        ? api.apiDrillingProductivityUserLensesIdComparisonFactsPut(requestParameters, { signal })
        : api.apiDrillingProductivityUserLensesIdFactsPut(requestParameters, { signal })
      ).then((data) => {
        if (data?.lastUpdatedAt && selectedWell) {
          dispatch({
            type: "SET_RIG_STATE_REFRESH_DATE",
            payload: { [selectedWell]: data?.lastUpdatedAt },
          });
        }
        return data;
      }),
  });
};
