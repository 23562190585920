/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PivotKeyPath
 */
export interface PivotKeyPath {
    /**
     * 
     * @type {string}
     * @memberof PivotKeyPath
     */
    valueLevel1: string;
    /**
     * 
     * @type {string}
     * @memberof PivotKeyPath
     */
    valueLevel2: string;
    /**
     * 
     * @type {string}
     * @memberof PivotKeyPath
     */
    valueLevel3: string;
}

/**
 * Check if a given object implements the PivotKeyPath interface.
 */
export function instanceOfPivotKeyPath(value: object): value is PivotKeyPath {
    if (!('valueLevel1' in value) || value['valueLevel1'] === undefined) return false;
    if (!('valueLevel2' in value) || value['valueLevel2'] === undefined) return false;
    if (!('valueLevel3' in value) || value['valueLevel3'] === undefined) return false;
    return true;
}

export function PivotKeyPathFromJSON(json: any): PivotKeyPath {
    return PivotKeyPathFromJSONTyped(json, false);
}

export function PivotKeyPathFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotKeyPath {
    if (json == null) {
        return json;
    }
    return {
        
        'valueLevel1': json['ValueLevel1'],
        'valueLevel2': json['ValueLevel2'],
        'valueLevel3': json['ValueLevel3'],
    };
}

export function PivotKeyPathToJSON(value?: PivotKeyPath | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ValueLevel1': value['valueLevel1'],
        'ValueLevel2': value['valueLevel2'],
        'ValueLevel3': value['valueLevel3'],
    };
}

