import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { RigCardFactSetDto, RigScorecardFactQueryDto } from "apis/oag";
import { DashboardType, PivotType } from "apis/oag";
import { RigScorecardUserLensesApi } from "apis/oag/";
import { useMemo } from "react";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import type { PDRigScorecardFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";

const rigCardApi = new RigScorecardUserLensesApi(apiConfig);

export function useRigScorecardCardFacts(
  selectedWellIds: number[],
  exceptions: boolean,
  options?: Omit<UseSuspenseQueryOptions<RigCardFactSetDto>, "queryKey" | "queryFn">,
) {
  const [operatorsState] = useStateQuery<Array<number> | null>(URL_STATE_PARAM.SELECTED_OPERATORS_RIG_SCORECARD, null);

  const requestQuery: RigScorecardFactQueryDto = useMemo(() => {
    return {
      highlightedRigIds: [],
      selectedWellIds: selectedWellIds,
      selectedFilters: {
        sectionIds: null,
        directionalIntervalIds: null,
        holeSizeIds: null,
        includeFlatTime: false,
        includeSlidingTime: false,
        includeRotatingTime: false,
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNoneAlphaRigs: true,
        selectedClasses: null,
        selectedCountryIds: null,
        selectedHorsePowers: null,
        selectedOperatingCenters: null,
        selectedWellStatusTypes: null,
      },
      from: defaultDateDto.from,
      to: defaultDateDto.to,
      includeFullWellFacts: false,
      dashboard: DashboardType.RigScorecard,
      operatorIds: operatorsState,
      rigIds: null,
      pivot: PivotType.Well,
      useExceptions: exceptions,
    };
  }, [operatorsState, selectedWellIds, exceptions]);

  const RigsCardQueryKey: PDRigScorecardFactsQueryKey = {
    uid: RequestUID.rigsCardFacts,
    type: PDQueryType.FACTS,
    params: requestQuery,
  };

  return useSuspenseQuery<RigCardFactSetDto>({
    queryKey: [RigsCardQueryKey],
    queryFn: ({ signal }) => {
      return rigCardApi.apiRigScorecardUserLensesAllFactsPut({ rigScorecardFactQueryDto: requestQuery }, { signal });
    },
    ...options,
  });
}

export const RigScorecardInvalidationKey = { uid: RequestUID.rigsCardFacts, type: PDQueryType.FACTS };
