import styled from "styled-components";
import colors from "utils/colors";

export const StyledWrapper = styled.div`
  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper-focused {
      background-color: ${colors.neutral_white}!important;
      input {
        background-color: ${colors.neutral_white}!important;
      }
    }
  }

  .ant-input-affix-wrapper-focused {
    border-color: ${colors.off_state}!important;
    input {
      background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg}!important;
    }
  }

  .ant-input-affix-wrapper {
    background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
    border-color: ${colors.off_state} !important;
    box-shadow: none;

    &:hover {
      border-color: ${colors.off_state} !important;
    }
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-radio-button-wrapper {
    height: 36px;
    line-height: 36px;
  }

  .ant-radio-button-wrapper-checked {
    color: ${colors.gray};
    background-color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};

    box-shadow: none !important;

    &::before {
      display: none;
    }
  }

  .ant-input-suffix {
    color: ${colors.gray};
  }

  .ant-radio-group {
    display: flex;
  }

  .ant-input-affix-wrapper {
    input {
      background-color: ${colors.actions_bg};
    }
  }
`;
