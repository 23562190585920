/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandKpiCommentInfoDto } from './StandKpiCommentInfoDto';
import {
    StandKpiCommentInfoDtoFromJSON,
    StandKpiCommentInfoDtoFromJSONTyped,
    StandKpiCommentInfoDtoToJSON,
} from './StandKpiCommentInfoDto';

/**
 * 
 * @export
 * @interface StandKpiCommentGroupDto
 */
export interface StandKpiCommentGroupDto {
    /**
     * 
     * @type {number}
     * @memberof StandKpiCommentGroupDto
     */
    standId: number;
    /**
     * 
     * @type {Array<StandKpiCommentInfoDto>}
     * @memberof StandKpiCommentGroupDto
     */
    comments: Array<StandKpiCommentInfoDto>;
}

/**
 * Check if a given object implements the StandKpiCommentGroupDto interface.
 */
export function instanceOfStandKpiCommentGroupDto(value: object): value is StandKpiCommentGroupDto {
    if (!('standId' in value) || value['standId'] === undefined) return false;
    if (!('comments' in value) || value['comments'] === undefined) return false;
    return true;
}

export function StandKpiCommentGroupDtoFromJSON(json: any): StandKpiCommentGroupDto {
    return StandKpiCommentGroupDtoFromJSONTyped(json, false);
}

export function StandKpiCommentGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiCommentGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'standId': json['StandId'],
        'comments': ((json['Comments'] as Array<any>).map(StandKpiCommentInfoDtoFromJSON)),
    };
}

export function StandKpiCommentGroupDtoToJSON(value?: StandKpiCommentGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StandId': value['standId'],
        'Comments': ((value['comments'] as Array<any>).map(StandKpiCommentInfoDtoToJSON)),
    };
}

