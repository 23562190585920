import { Select } from "atoms/Form";
import styled from "styled-components";
import colors from "utils/colors";
import { Radio } from "utils/componentLibrary";
import { Input as AntInput, Row } from "utils/componentLibrary";

export const StyledRadioButton = styled(Radio.Button)<{ disabled: boolean }>`
  &.ant-radio-button-wrapper {
    background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_button_accent} !important;
    ${(props) => props.disabled && `background-color: ${props.theme.themeStyle.colors.secondary_bg};`}

    .pd-icon {
      ${(props) => props.disabled && `color: ${props.theme.themeStyle.colors.light_typography_inverted};`}
    }
  }
  &.ant-radio-button-wrapper-checked {
    &:hover {
      background-color: ${(props) =>
        props.disabled ? props.theme.themeStyle.colors.secondary_bg : props.theme.themeStyle.colors.primary_bg};
    }
    .pd-icon {
      color: ${(props) =>
        props.disabled ? props.theme.themeStyle.colors.light_typography_inverted : colors.white} !important;
    }
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  ${StyledRadioButton} {
    .ant-radio-button {
      border-radius: inherit;
      background-color: ${({ theme }) => theme.themeStyle.colors.primary_button_bg};
    }
  }
`;

export const StyledButtonsRow = styled(Row)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const StyledRow = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${({ theme: { themeStyle } }) => themeStyle.colors.primary_accent};
`;

export const CustomInputGroup = styled(AntInput.Group)<{ disabled?: boolean }>`
  display: flex;
  flex-basis: 75%;
  .ant-input {
    color: ${(props) => (props.disabled ? colors.error_color : "")}!important;
  }

  .ant-picker:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ant-picker:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ant-picker-input {
    input:placeholder-shown {
      text-overflow: clip;
    }
  }

  .ant-picker {
    color: ${(props) =>
      props.disabled ? colors.error_color : props.theme.themeStyle.colors.primary_typography}!important;
    border: 1px solid ${colors.off_state} !important;
    background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg} !important;
    box-shadow: none;

    input {
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
    }
  }
  .ant-input-suffix {
    color: ${(props) => (props.disabled ? colors.error_color : "")}!important;
  }

  .ant-picker-suffix {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  }
`;

export const StyledDateSelectorContainer = styled.div`
  min-width: 620px;
  display: flex;
  flex-basis: 25%;
  column-gap: 6px;
  justify-content: flex-end;
  padding-left: 26px;
`;

export const StyledSelect = styled(Select)`
  display: flex;
  height: 100%;
  flex: 25%;
  margin-left: -6px;

  .ant-select-selector {
    background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg} !important;
    border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;

    &:active {
      background: ${({ theme }) => theme.themeStyle.colors.secondary_bg} !important;
      color: ${colors.gray} !important;
    }
  }
`;
