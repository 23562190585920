import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import Pie from "@visx/shape/lib/shapes/Pie";
import type { WedgeLensTemplateDto, WedgeUserLensDto } from "apis/oag";
import { ResultDataState } from "apis/oag";
import { PrimaryKpiSummary, SecondaryKpiSummary } from "components/Lenses/common/KpiSummaries";
import { TooltipGroup, TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { LENS_KPI_HEIGHT_INNER, NO_DATA_KPI_STRING } from "components/Lenses/constants";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { StyledLensContainerFlex } from "components/Lenses/ContainerLens/common/StyledComponents";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import type { ContainerLensProps } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { useDetailedLegendActiveState } from "components/Lenses/ContainerLens/hooks/useDetailedLegendActiveState";
import type { DataType } from "components/Lenses/ContainerLens/WedgeKpi/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { InfoContainer } from "components/MiniLens/InfoContainer";
import { SecondaryKpiContainer, StyledKpiRow } from "components/MiniLens/style";
import { useWedgeFacts } from "hooks/lens-facts/useWedgeFacts";
import { useLensTemplates } from "hooks/useLensTemplates";
import { usePreviousNonNullValue } from "hooks/usePreviousNonNullValue";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { useLayoutEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { useColors } from "utils/useColors";

import { AnimatedPie } from "./Chart/WedgeChart";
import { FlexGrowContainer, StyledContainer } from "./style";
import { getComparisonDisplayValue, getNonComparisonSecondaryValues, getShadeColor } from "./utils";

const inBetweenChartWhiteSpace = 5;

interface ITooltipData {
  label: string;
  index: number;
  value: number;
  proportion: number;
}

const dataRender = (data: Array<DataType>, index?: number) => {
  return data.map((name: DataType) => ({
    label: name.text,
    proportion: name.proportion,
    value: name.value,
    index: index,
    color: name.color,
    position: name.position,
  }));
};

// accessor functions
const value = (d: DataType) => d.value;

const defaultMargin = { top: 50, right: 20, bottom: 50, left: 20 };
const miniMargin = { top: 25, bottom: 0, right: 20, left: 20 };

const minDonutThickness = 5;
const maxDonutThickness = 128;

export const WedgeKpi: React.FC<ContainerLensProps> = ({ detailed, lens, focalWellColor }) => {
  const { data, isLoading } = useWedgeFacts(lens?.id);

  const { width, height, ref: containerRef } = useResizeDetector<HTMLDivElement>();
  const previousData = usePreviousNonNullValue(data);

  const legendAltStyle = useAppSelector((state) => state.state.legendAltStyle);
  const currentUom = useAppSelector((state) => state.global.unit);
  const [wellRecords, setWellRecords] = useState<DataType[][] | null>(null);
  const [wellRecordNames, setWellRecordNames] = useState<string[] | null>(null);

  const { data: wellData } = useWellShortInfoSuspended();
  const { setColor, getColor } = useColors();

  useLayoutEffect(() => {
    const dataSource = detailed && previousData && isLoading ? previousData : data;

    if (!dataSource?.values) {
      return;
    }

    const wellNames: Array<string> = [];
    const result = dataSource?.comparisons
      ?.filter((comparisonWell) => comparisonWell.dataState === ResultDataState.Valid)
      ?.map((comparisonWell) => {
        wellNames.push(
          comparisonWell?.aggregatedWellsCount > 1
            ? `${(data?.comparisons || [])[1].aggregatedWellsCount} Wells`
            : wellData?.byId[comparisonWell.wellId]?.name || "",
        );
        const wellValue =
          comparisonWell.values &&
          comparisonWell.values.map((item: DataType, index: number) => {
            const keyColor =
              (data?.comparisons || []).length === 1
                ? focalWellColor
                : getColor({ key: comparisonWell.wellId.toString() });

            const getItemColor = () => {
              if (legendAltStyle) {
                return setColor({ key: (item.position || 0).toString(), defaultPivot: "WedgeAlternative" });
              }
              if (comparisonWell?.aggregatedWellsCount > 1) {
                return getShadeColor(index + 1, colors.off_state);
              }
              return getShadeColor(item.position || 0, keyColor || "");
            };

            item["color"] = getItemColor();

            return {
              position: item.position,
              proportion: item.proportion,
              text: item.text,
              value: item.value,
              color: item.color,
            };
          });
        return wellValue || [];
      });
    if (result) {
      setWellRecords(result);
      setWellRecordNames(wellNames);
    }
  }, [
    detailed,
    focalWellColor,
    legendAltStyle,
    wellData?.byId,
    currentUom,
    getColor,
    previousData,
    isLoading,
    data,
    setColor,
  ]);

  const comparisonWells = useAppSelector((state) => state.widgetOptions.offsetWells);
  const isComparing = comparisonWells?.length > 0;

  const { data: templates } = useLensTemplates();
  const template = templates.byId[lens.lensTemplateId];

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<ITooltipData>({
    containerRef,
    renderContent: ({ tooltipData }) => {
      if (!tooltipData) return null;
      return (
        <>
          <TooltipHighlightValue>{tooltipData.label}</TooltipHighlightValue>
          <TooltipGroup>
            <span>{`${(tooltipData.value / 86400).toFixed(2)} days`}</span>
            <span>{`${(tooltipData.proportion * 100).toFixed(2)}%`}</span>
            <span>{`${tooltipData?.index ? wellRecordNames?.[tooltipData?.index] : wellRecordNames?.[0] || ""}`}</span>
          </TooltipGroup>
        </>
      );
    },
  });
  const mainKPIValues = useMemo<{
    main: string;
    secondary?: string;
    breakdownName?: string;
  }>(() => {
    const dataSource = detailed && previousData && isLoading ? previousData : data;
    const breakdownName = (template as WedgeLensTemplateDto)?.breakdowns
      ? ((template as WedgeLensTemplateDto)?.breakdowns || []).find(
          (breakdown) => breakdown.id === (lens as WedgeUserLensDto).breakdownId,
        )?.name ?? ""
      : "";

    if (isComparing) {
      return {
        ...getComparisonDisplayValue(dataSource?.summary),
        breakdownName: breakdownName,
      };
    } else {
      return {
        ...getComparisonDisplayValue(dataSource?.summary),
        secondary: "",
        breakdownName: breakdownName,
      };
    }
  }, [data, detailed, isComparing, isLoading, lens, previousData, template]);

  const secondaryKPIValues = useMemo(() => {
    const dataSource = detailed && previousData && isLoading ? previousData : data;

    if (!dataSource || dataSource?.dataState !== ResultDataState.Valid) {
      return [];
    }
    if (isComparing) {
      return dataSource?.summaryByKpiNames?.map((f) => {
        return getComparisonDisplayValue(f);
      });
    } else {
      return (dataSource?.comparisons || [])[0]?.values?.map((f) => {
        return getNonComparisonSecondaryValues(f);
      });
    }
  }, [data, detailed, isComparing, isLoading, previousData]);

  const mainKpi = useMemo(() => {
    return (detailed && previousData && isLoading ? previousData : data)?.dataState === ResultDataState.Valid
      ? mainKPIValues.main
      : NO_DATA_KPI_STRING;
  }, [data, detailed, isLoading, mainKPIValues.main, previousData]);

  useDetailedLegendActiveState({ comparisons: data?.comparisons || [], detailed, dataState: data?.dataState });

  const currentMargin = useMemo(() => (detailed ? defaultMargin : miniMargin), [detailed]);

  const innerWidth = useMemo(
    () => (width ?? 0) - currentMargin.left - currentMargin.right,
    [currentMargin.left, currentMargin.right, width],
  );
  const innerHeight = useMemo(
    () => (height ?? 0) - currentMargin.top - currentMargin.bottom,
    [currentMargin.bottom, currentMargin.top, height],
  );
  const radius = useMemo(() => Math.min(innerWidth / 2, innerHeight), [innerHeight, innerWidth]);

  const donutThickness = useMemo(
    () =>
      Math.min(
        maxDonutThickness,
        Math.max(minDonutThickness, (radius - maxDonutThickness / 2) / (wellRecords?.length || 1)),
      ),
    [radius, wellRecords?.length],
  );

  const centerY = useMemo(() => (height ?? 0) - currentMargin.top, [currentMargin.top, height]);
  const centerX = useMemo(() => innerWidth / 2, [innerWidth]);

  const SummaryComponent = useMemo(
    () => (
      <InfoContainer size={6} direction="vertical">
        <StyledKpiRow gutter={34} height={LENS_KPI_HEIGHT_INNER} wrap={true}>
          {mainKPIValues ? (
            <PrimaryKpiSummary
              secondary={mainKPIValues.secondary || ""}
              main={mainKpi}
              description={""}
              title={mainKPIValues.breakdownName}
              detailed={detailed}
            />
          ) : null}
          {secondaryKPIValues ? (
            <SecondaryKpiContainer>
              <SecondaryKpiSummary detailed={detailed} kpis={secondaryKPIValues?.slice(0)} />
            </SecondaryKpiContainer>
          ) : null}
        </StyledKpiRow>
      </InfoContainer>
    ),
    [detailed, mainKPIValues, mainKpi, secondaryKPIValues],
  );

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={data?.dataState}
      isDetailed={detailed}
      SummaryComponent={SummaryComponent}
      LoadedComponent={
        <StyledLensContainerFlex>
          {SummaryComponent}
          <StyledChartContainerFlexDiv>
            <FlexGrowContainer ref={containerRef}>
              {wellRecords && wellRecordNames ? (
                <StyledContainer>
                  <svg
                    width={width}
                    height={getSVGNormalizedValue(height)}
                    style={{
                      overflow: "visible",
                      userSelect: "none",
                    }}
                  >
                    {radius ? (
                      <Group
                        top={centerY + 15 - (detailed ? defaultMargin.bottom : 0)}
                        left={centerX + currentMargin.left}
                      >
                        {wellRecords.map((data, index) => {
                          const innerRadius = radius - (donutThickness + inBetweenChartWhiteSpace) * (index + 1);
                          const outerRadius =
                            radius - inBetweenChartWhiteSpace - (donutThickness + inBetweenChartWhiteSpace) * index;
                          return (
                            <Pie
                              key={`wedge_${index}`}
                              data={dataRender(data, index)}
                              pieValue={value}
                              pieSort={(a, b) => {
                                // TODO change this as the diff from 2 objects is undefined behavior
                                return +a - +b;
                              }}
                              outerRadius={outerRadius}
                              innerRadius={innerRadius}
                              startAngle={-(Math.PI / 2)}
                              endAngle={Math.PI / 2}
                            >
                              {(pie) => {
                                return (
                                  <AnimatedPie
                                    {...pie}
                                    labels={lens?.label}
                                    radius={(innerRadius + outerRadius) / 2}
                                    getKey={(arc) => `${arc.data.value.toString()}${index}${arc.data.label}`}
                                    getValueByKey={(arc) => {
                                      if (donutThickness > 24) {
                                        return ((arc.data.proportion ?? 0) * 100).toFixed(2);
                                      }
                                      return "";
                                    }}
                                    getColor={(arc) => arc.data?.color ?? ""}
                                    onMouseDatum={(event, d) => {
                                      const point = localPoint(event);

                                      if (!point) return;
                                      showTooltip({
                                        tooltipData: d.data as unknown as ITooltipData,
                                        tooltipTop: point.y,
                                        tooltipLeft: point.x,
                                      });
                                    }}
                                    thickness={outerRadius - innerRadius}
                                    onMouseLeaveDatum={() => hideTooltip()}
                                  />
                                );
                              }}
                            </Pie>
                          );
                        })}
                      </Group>
                    ) : null}
                  </svg>
                  {tooltipElement}
                </StyledContainer>
              ) : null}
            </FlexGrowContainer>
          </StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
