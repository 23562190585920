import { UserRoleType, UserStatusType } from "apis/oag";
import { Button, Input } from "atoms/Form";
import { Title } from "atoms/Typography";
import { UsersTable } from "components/CustomTable";
import { ActionsHeader, OverviewHeaderContainer, PlanSectionLayout } from "components/Layout/Tabbed";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import { useAdminUserList } from "hooks/admin/useAdminUserList";
import { useOperators } from "hooks/useOperators";
import { useRigs } from "hooks/useRigs";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "reducers/store";
import { useAppDispatch } from "reducers/store";
import { useMixpanel } from "services/Mixpanel";
import { Col, Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

import { useDashboardLayoutModal } from "./useDashboardLayoutModal";

const UsersListPage = () => {
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();
  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("Admin Users");
    }
  }, [viewPage]);
  const dispatch = useAppDispatch();

  const rigs = useRigs();
  const operators = useOperators();
  const wells = useWellShortInfoSuspended();

  const userList = useAdminUserList();

  const searchState = useAppSelector((state) => state.admin.users.search);
  const statusState = useAppSelector((state) => state.admin.users.userStatus || []);
  const rigIdsState = useAppSelector((state) => state.admin.users.rigs);
  const filterWells = useAppSelector((state) => state.admin.users.wells);
  const operatorsState = useAppSelector((state) => state.admin.users.operators);
  const userRoleState = useAppSelector((state) => state.admin.users.userRole);

  const [quickSearchValue, setQuickSearchValue] = useState<string>(searchState || "");

  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [showRolePopup, setShowRolePopup] = useState(false);
  const [showRigPopup, setShowRigPopup] = useState(false);
  const [showWellSelect, setShowWellSelect] = useState(false);
  const [showOperatorPopup, setShowOperatorPopup] = useState(false);

  useEffect(() => {
    dispatch({
      type: "ADMIN_WELL_ACCESS_RESET",
      payload: null,
    });
    dispatch({
      type: "ADMIN_SINGLE_ACCOUNT_RESET",
      payload: null,
    });
  }, [dispatch]);

  const isLoading = useMemo(() => {
    return rigs.isLoading || operators.isLoading || wells.isLoading || userList.isLoading;
  }, [operators.isLoading, rigs.isLoading, userList.isLoading, wells.isLoading]);

  const updateSearch = () => {
    dispatch({
      type: "ADMIN_USERS_SET_SEARCH",
      payload: {
        search: quickSearchValue,
      },
    });
    return true;
  };

  const updateUserStatuses = (f: UserStatusType[]) => {
    setShowStatusPopup(false);
    dispatch({
      type: "ADMIN_USERS_SET_USER_STATUS",
      payload: {
        userStatus: f,
      },
    });
    return true;
  };

  const updateUserRoles = (f: UserRoleType[]) => {
    setShowRolePopup(false);
    dispatch({
      type: "ADMIN_USERS_SET_USER_ROLE",
      payload: {
        userRole: f,
      },
    });
    return true;
  };

  const updateRigs = useCallback(
    (rigIds: number[]) => {
      dispatch({
        type: "ADMIN_USERS_SET_RIGS",
        payload: {
          rigs: rigIds,
        },
      });
      return true;
    },
    [dispatch],
  );

  const onWellsChange = useCallback(
    (selectedOffsetWells: number[] = []) => {
      setShowWellSelect(false);
      dispatch({
        type: "ADMIN_USERS_SET_WELLS",
        payload: {
          wells: selectedOffsetWells,
        },
      });
      return true;
    },
    [dispatch],
  );

  const updateOperators = useCallback(
    (e: number[]) => {
      dispatch({
        type: "ADMIN_USERS_SET_OPERATORS",
        payload: {
          operators: e,
        },
      });
      return true;
    },
    [dispatch],
  );

  useEffect(() => {
    if (rigs.data) {
      updateRigs(rigs.data.list.map((e) => e.id));
    }
  }, [rigs.data, updateRigs]);

  useEffect(() => {
    if (wells.data) {
      onWellsChange(wells.data.list.map((e) => e.id));
    }
  }, [onWellsChange, wells.data]);

  useEffect(() => {
    if (operators.data) {
      updateOperators(operators.data.list.map((e) => e.id));
    }
  }, [operators.data, updateOperators]);
  const {
    atomThemeVariant,
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  const { modalElement, openModal } = useDashboardLayoutModal();
  return (
    <Row>
      <Col span={24}>
        <OverviewHeaderContainer style={{ background: themeColors.quaterniary_bg }}>
          <Row justify="space-between" align="middle" style={{ height: "119px" }}>
            <Col flex="0 auto">
              <Title level={1} variant={atomThemeVariant} weight={500} style={{ margin: "0 16px" }}>
                Users
              </Title>
              <Title level={3} variant="faded" style={{ margin: "8px 16px" }}>
                {isLoading ? "Loading..." : `${userList?.data?.length ?? 0} results`}
              </Title>
            </Col>
          </Row>
        </OverviewHeaderContainer>
      </Col>
      <Col flex="1 auto">
        <PlanSectionLayout
          noTabs
          header={
            <ActionsHeader>
              <Row gutter={12} justify="space-between" align="middle" style={{ height: "64px" }}>
                <Col flex="0 auto">
                  <Space>
                    <Space direction="horizontal" size={4}>
                      <Input
                        allowClear
                        placeholder="Search by Name or Email"
                        style={{ width: "300px" }}
                        value={quickSearchValue}
                        onChange={(e) => setQuickSearchValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            updateSearch();
                          }
                        }}
                      />
                      <Button
                        size="large"
                        type="primary"
                        icon={<PDComponent.SvgIcon name="search" />}
                        onClick={updateSearch}
                      />
                    </Space>
                    <PDComponent.VerticalDivider />

                    <Tooltip title="User Status">
                      <Popover
                        content={
                          <PDComponent.Pickoff
                            options={Object.values(UserStatusType).map((e) => ({ id: e, name: e }))}
                            values={statusState}
                            onChange={(e) => updateUserStatuses(e)}
                          />
                        }
                        trigger="click"
                        placement="bottom"
                        open={showStatusPopup}
                        onOpenChange={(e) => setShowStatusPopup(e)}
                        destroyTooltipOnHide
                      >
                        <Button
                          icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
                          type={statusState?.length !== Object.keys(UserStatusType).length ? "primary" : "default"}
                          ghost={statusState?.length !== Object.keys(UserStatusType).length}
                          $engaged={showStatusPopup}
                        />
                      </Popover>
                    </Tooltip>

                    <Tooltip title="Wells">
                      <Popover
                        content={
                          <PDComponent.ComboBoxMultiSelect
                            placeholder="Search"
                            width={400}
                            options={(wells?.data?.list ?? []) as IOption<number>[]}
                            values={filterWells || []}
                            onChange={onWellsChange}
                          />
                        }
                        trigger="click"
                        placement="bottom"
                        open={showWellSelect}
                        onOpenChange={(e) => setShowWellSelect(e)}
                        destroyTooltipOnHide
                      >
                        <Button
                          icon={<PDComponent.SvgIcon name="wellInfo" />}
                          $engaged={showWellSelect}
                          type={filterWells?.length !== wells?.data.list.length ? "primary" : "default"}
                          ghost={filterWells?.length !== wells?.data.list.length}
                        />
                      </Popover>
                    </Tooltip>

                    <Tooltip title="Rigs">
                      <Popover
                        content={
                          <PDComponent.ComboBoxMultiSelect
                            placeholder="Search Rigs"
                            options={(rigs.data?.list ?? []).map((e) => ({ id: e.id, name: e.shortName || "" }))}
                            values={rigIdsState ?? []}
                            onChange={(rigIds: number[]) => {
                              updateRigs(rigIds);
                              setShowRigPopup(false);
                              return void 0;
                            }}
                          />
                        }
                        trigger="click"
                        placement="bottom"
                        open={showRigPopup}
                        onOpenChange={(e) => setShowRigPopup(e)}
                        destroyTooltipOnHide
                      >
                        <Button
                          size="large"
                          icon={<PDComponent.SvgIcon name="rig" />}
                          type={
                            rigIdsState === null || rigIdsState?.length !== rigs.data?.list.length
                              ? "primary"
                              : "default"
                          }
                          ghost={!!(rigIdsState && rigIdsState?.length !== rigs.data?.list.length)}
                          $engaged={showRigPopup}
                        />
                      </Popover>
                    </Tooltip>

                    <Tooltip title="User Role">
                      <Popover
                        content={
                          <PDComponent.Pickoff
                            options={Object.values(UserRoleType).map((e) => ({ id: e, name: e }))}
                            values={userRoleState || []}
                            onChange={(e) => updateUserRoles(e)}
                          />
                        }
                        trigger="click"
                        placement="bottom"
                        open={showRolePopup}
                        onOpenChange={(e) => setShowRolePopup(e)}
                        destroyTooltipOnHide
                      >
                        <Button
                          icon={<PDComponent.SvgIcon name="userIdentification" />}
                          type={userRoleState?.length !== Object.keys(UserRoleType).length ? "primary" : "default"}
                          ghost={userRoleState?.length !== Object.keys(UserRoleType).length}
                          $engaged={showRolePopup}
                        />
                      </Popover>
                    </Tooltip>

                    <Tooltip title="Operators / Accounts">
                      <Popover
                        content={
                          <PDComponent.ComboBoxMultiSelect
                            placeholder="Search"
                            options={operators.data?.list as IOption<number>[]}
                            values={operatorsState || []}
                            onChange={(e) => {
                              updateOperators(e);
                              setShowOperatorPopup(false);
                              return void 0;
                            }}
                          />
                        }
                        trigger="click"
                        placement="bottom"
                        open={showOperatorPopup}
                        onOpenChange={(e) => setShowOperatorPopup(e)}
                        destroyTooltipOnHide
                      >
                        <Button
                          size="large"
                          icon={<PDComponent.SvgIcon name="identification" />}
                          type={operatorsState?.length !== operators.data?.list.length ? "primary" : "default"}
                          ghost={operatorsState?.length !== operators.data?.list.length}
                          $engaged={showOperatorPopup}
                        />
                      </Popover>
                    </Tooltip>
                  </Space>
                </Col>
                <Col flex="0 auto">
                  <Space>
                    <Button type="primary" onClick={openModal}>
                      Copy dashboard layout
                    </Button>
                    <Link to="/admin/management/id/users/new/overview">
                      <Button type="primary">Add User</Button>
                    </Link>
                  </Space>
                </Col>
              </Row>
            </ActionsHeader>
          }
          content={isLoading ? <Loader centered /> : <UsersTable data={userList.data ?? []} />}
        />
        {modalElement}
      </Col>
    </Row>
  );
};

export default UsersListPage;
