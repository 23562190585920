import { AxisBottom, AxisRight } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { LinearGradient } from "@visx/gradient";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { AreaClosed } from "@visx/shape";
import { DimensionType } from "apis/oag/models/DimensionType";
import type { FuelTankVolumeFactDto } from "apis/oag/models/FuelTankVolumeFactDto";
import { Title } from "atoms/Typography";
import { AxisLocation, StandardTickComponent } from "components/Lenses/common/ChartElements";
import ChartLegend from "components/Lenses/common/ChartLegend";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import { useChartDateTimeRange } from "components/Lenses/common/useChartDateTimeRange";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { RotatedRightAxisLabel } from "components/Lenses/ContainerLens/common/RotatedRightAxisLabel";
import {
  StyledChartContainerFlexDiv,
  StyledLensContainerFlex,
} from "components/Lenses/ContainerLens/common/StyledComponents";
import {
  addIndexToSeries,
  CLIP_SERIES_ID as CLIP_ID,
  getInternalAxisMargin,
  LATERAL_AXIS_WIDTH,
  X_AXIS_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import type { FuelTankVolumeProps } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { curveMonotoneX } from "d3";
import { extent, max } from "d3-array";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import useDiscontinuousTimeAxis from "hooks/useDiscontinuousTimeAxis";
import { useLensSize } from "hooks/useLensSize";
import { useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import colors from "utils/colors";
import { Col, Row } from "utils/componentLibrary";
import { ALTERNATIVE_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { useFuelTankVolumeFetcher } from "./fetcher";
import { useFuelTankVolumeTooltip } from "./useFuelTankVolumeTooltip";
import { FUEL_TANK_VOLUME_ID } from "./utils";

const LENS_TITLE = "Fuel Tank Volume";

export const FuelTankVolume: React.FC<FuelTankVolumeProps> = ({ lens, setLensDate }) => {
  const {
    request: { data },
  } = useFuelTankVolumeFetcher({ lensId: lens?.id });

  const series = useMemo(() => data?.facts || [], [data?.facts]);
  const yScalePrimaryExtent = useMemo(() => {
    const [min, max] = extent([0, ...series.map((series) => series?.value)]);
    return [min ?? 0, max ?? 0];
  }, [series]);

  const yScalePrimaryUOM = useUOM(DimensionType.Litres);

  const [isPointerInsideChart, setPointerInsideChart] = useState(false);

  useEffect(() => {
    if (data?.lastUpdatedAt && setLensDate) setLensDate(data.lastUpdatedAt);
  }, [data.lastUpdatedAt, setLensDate]);

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const plotWidth = getSVGNormalizedValue(chartWidth - 2 * LATERAL_AXIS_WIDTH);
  const plotHeight = getSVGNormalizedValue(chartHeight - X_AXIS_HEIGHT);
  const dataState = useMemo(() => data?.dataState, [data?.dataState]);

  const lensGridSize = useLensSize(lens?.id);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const [cosmeticLegendItems, setCosmeticLegendItems] = useState<LegendItem[]>([
    {
      id: FUEL_TANK_VOLUME_ID,
      name: "Fuel Tank Level",
      color: colors.sunburnt_toes,
      isEnabled: true,
      previewerType: LegendPreviewerType.BOX,
      onClick: (item, items) => {
        if ((items || []).every((other) => !other.isEnabled || other.id === item.id)) {
          return false;
        }
        setCosmeticLegendItems((items) =>
          items.map((item) =>
            item.id === FUEL_TANK_VOLUME_ID
              ? {
                  ...item,
                  isEnabled: !item.isEnabled,
                }
              : item,
          ),
        );
      },
    },
  ]);

  const xScale = useMemo(() => {
    const chartSize = plotWidth;
    const startX = LATERAL_AXIS_WIDTH;

    const timeSeries = series.map((fact, i) => i);
    const [_min, _max = 0] = [0, max(timeSeries)];

    return scaleLinear<number>({
      domain: [_min, _max],
      range: [startX, chartSize + startX],
    });
  }, [plotWidth, series]);

  const { xScaleDate, chunksCount, getAverageChunkDate } = useDiscontinuousTimeAxis({
    plotWidth,
    xScaleDomain: xScale.domain(),
    series: addIndexToSeries(series),
  });

  const internalAxisMargin = useMemo(() => getInternalAxisMargin(false), []);

  const [yScalePrimary, isYScalePrimaryShown] = useMemo(() => {
    return [
      scaleLinear<number>({
        domain: yScalePrimaryExtent[0] !== yScalePrimaryExtent[1] ? yScalePrimaryExtent : [0, 30000],
        range: [plotHeight, internalAxisMargin],
        nice: true,
      }),

      yScalePrimaryExtent[0] !== yScalePrimaryExtent[1],
    ];
  }, [internalAxisMargin, plotHeight, yScalePrimaryExtent]);

  const CLIP_SERIES_ID = useMemo(() => `${CLIP_ID}-${lens.id}-2`, [lens.id]);

  const { verticalAxisTicksCount, yDisplayOnlyScale, formatTickDecimals } = useYAxisDisplayScale({
    originalScale: yScalePrimary,
    uom: yScalePrimaryUOM,
    tickContainerHeight: plotHeight,
  });

  const { selectionRectangleElement, isDragging } = useChartDateTimeRange<FuelTankVolumeFactDto>({
    xScale,
    yScale: yScalePrimary,
    plotWidth,
    plotWidthOffset: LATERAL_AXIS_WIDTH,
    plotHeight,
    series: addIndexToSeries(series),
  });

  const { tooltipElement, handlePointerMove } = useFuelTankVolumeTooltip({
    legendItems: cosmeticLegendItems,
    containerRef,
    yScalePrimary,
    xScale,
    series,
    plotWidth: chartWidth,
    plotHeight,
    isPointerInsideChart,
  });

  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={dataState}
      title={LENS_TITLE}
      isDetailed={false}
      LoadedComponent={
        <StyledLensContainerFlex>
          <Row justify="space-between">
            <Col style={{ padding: 24 }}>
              <Title variant="faded" level={3}>
                {LENS_TITLE}
              </Title>
            </Col>
          </Row>

          <StyledChartContainerFlexDiv>
            <div style={{ height: "auto", flexBasis: "50%", minHeight: 150, flexGrow: 1 }} ref={containerRef}>
              <svg
                onPointerMove={handlePointerMove}
                onMouseLeave={() => setPointerInsideChart(false)}
                onMouseEnter={() => setPointerInsideChart(true)}
                onMouseMove={() => setPointerInsideChart(true)}
                width={chartWidth}
                height="100%"
                style={{ overflow: "hidden", userSelect: "none" }}
              >
                <GridRows
                  scale={yDisplayOnlyScale}
                  width={chartWidth}
                  height={chartHeight}
                  stroke={themeColors.primary_chart_accent}
                  strokeWidth={1}
                  strokeOpacity={1}
                  clipPath={`url(#${CLIP_SERIES_ID})`}
                />
                <RectClipPath y={0} id={CLIP_SERIES_ID} height={plotHeight} width={plotWidth} x={LATERAL_AXIS_WIDTH} />

                <Group clipPath={`url(#${CLIP_SERIES_ID})`}>
                  <LinearGradient
                    from={cosmeticLegendItems[0].color}
                    to={cosmeticLegendItems[0].color}
                    toOpacity={0.55}
                    fromOpacity={1}
                    id={`linear_gradient_${cosmeticLegendItems[0].color}_${lens.id}`}
                  />

                  {/* Primary Y KPI */}
                  {cosmeticLegendItems[0].isEnabled ? (
                    <AreaClosed
                      data={series}
                      x={(d, i) => xScale(i)}
                      y={(d) => yScalePrimary(d.value)}
                      yScale={yScalePrimary}
                      curve={curveMonotoneX}
                      stroke={`${colors.neutral}`}
                      strokeOpacity={0.1}
                      strokeWidth={1}
                      fill={`url(#linear_gradient_${cosmeticLegendItems[0].color}_${lens.id})`}
                    />
                  ) : null}
                </Group>

                <AxisRight
                  hideZero
                  scale={yDisplayOnlyScale}
                  hideTicks
                  numTicks={verticalAxisTicksCount}
                  left={(chartWidth ?? 0) - LATERAL_AXIS_WIDTH}
                  tickComponent={(props) => {
                    if (props.y === 0) return null;
                    return (
                      <StandardTickComponent rendererProps={props}>
                        {(val) => formatTickDecimals(val, 0)}
                      </StandardTickComponent>
                    );
                  }}
                  hideAxisLine
                />

                {/* VISIBLE TIME AXIS */}
                <AxisBottom
                  hideAxisLine
                  hideTicks
                  scale={xScaleDate}
                  top={plotHeight}
                  numTicks={chunksCount}
                  tickComponent={(props) => (
                    <StandardTickComponent rendererProps={props} axisLocation={AxisLocation.BOTTOM}>
                      {(val) => {
                        const date = getAverageChunkDate(+val);
                        return date
                          ? formatTime(date, {
                              formatStr: ALTERNATIVE_DATE_FORMAT,
                            })
                          : "";
                      }}
                    </StandardTickComponent>
                  )}
                />
                {!isDragging && tooltipElement}
                {selectionRectangleElement}
              </svg>

              <RotatedRightAxisLabel
                plotWidth={plotWidth}
                chartHeight={chartHeight}
                displayText={isYScalePrimaryShown ? yScalePrimaryUOM.abbr : null}
              />
            </div>

            <ChartLegend legendItems={cosmeticLegendItems} lensGridSize={lensGridSize} />
          </StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
