import { Text } from "atoms/Typography";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import type { Alignments } from "utils/enums";

export const StyledLegendContainer = styled.div<{ height?: number; isDetailed?: boolean; maxHeight?: string }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 12px 16px;
  padding: 2px 12px;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight + "px" : null)};
  overflow-y: auto;
`;

export const StyledLegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLegendText = styled(Text).attrs({
  primary: "description",
  variant: "faded",
  fontSize: 10,
})`
  user-select: none;
`;

export const LegendDot = styled.div<{ opacity: number; background: string; isLine?: boolean; isDashedBox?: boolean }>`
  width: 10px;
  height: ${({ isLine }) => (isLine ? "2px" : "10px")};
  align-self: center;
  margin-right: 4px;
  opacity: ${(props) => props.opacity};
  background: ${(props) => props.background};
  border: ${(props) => (props.isDashedBox ? `2px dotted ${colors.necron_compound}` : "none")};
`;

export const LegendDotContainerSvg = styled.svg`
  height: 2px;
  width: 10px;
  align-self: center;
  margin-right: 4px;
`;

export const StyledTableHeader = styled.div<{ flexWidth: number; minWidth: number; align?: Alignments }>`
  width: 100%;
  flex: ${(props) => props.flexWidth};
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align || "flex-end"};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : undefined)};
`;

export const StyledLegendRow = styled.div<{ hideBorder?: boolean; alignCenter?: boolean; rowGap?: number }>`
  width: 100%;
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  padding: 4px;
  gap: ${({ rowGap }) => (rowGap ? `${rowGap}px` : undefined)};
  justify-content: ${({ alignCenter }) => (alignCenter ? `center` : undefined)};

  border-bottom: ${({ theme, hideBorder }) => {
    return hideBorder ? "none" : `1px solid ${theme.themeStyle.colors.primary_accent}`;
  }};
`;

export const StyledLegendColumn = styled.div<{
  flexWidth: number;
  minWidth: number;
  align?: Alignments;
  isDescriptionColumn?: boolean;
}>`
  flex: ${(props) => props.flexWidth};
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align || "flex-end"};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : undefined)};
`;

export const StyledTableContainer = styled.div<{ $clickable: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0 24px;
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};

  ${({ $clickable }) =>
    $clickable &&
    css`
    &, ${StyledLegendColumn}, ${StyledLegendItem} {
      cursor: pointer;
  `}
`;
