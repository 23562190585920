import type { ScorecardKpiCommentExceptionInfoDto } from "apis/oag";
import type { BaseNotificationVm } from "utils/notifications/mappers";

export const PLOT_HEIGHT = 90;
export const PLOT_WIDTH = 200;
export const RIGHT_AXIS_WIDTH = 36;

export const isBaseNotificationVm = (
  notification: BaseNotificationVm | ScorecardKpiCommentExceptionInfoDto | null,
): notification is BaseNotificationVm => {
  return (notification as BaseNotificationVm).type !== undefined;
};
