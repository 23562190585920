import type { RigLeaderboardDto } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { useLinkedFilters } from "components/RigsHeader/useLinkedFilters";
import { useOperators } from "hooks/useOperators";
import { useRigLeaderboard } from "hooks/useRigLeaderboard";
import { useRigSummaries } from "hooks/useRigSummaries";
import { partition, sortBy as _sort } from "lodash";
import {
  SortIcons,
  StyledChevron,
} from "pages/AllWells/components/WellsContainer/style";
import {
  Columns,
  SortBy,
  useScoreValues,
} from "pages/RigLeaderboard/components/utils";
import {
  BenchmarkType,
  useScoreBenchmarkContext,
} from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { SortDirections } from "utils/enums";
import { useCustomTheme } from "utils/useTheme";

import {
  RigLeaderboardListRow,
  RigLeaderboardListSkeleton,
} from "./RigLeaderboardListRow";
import * as Styled from "./styled";

const ROW_HEIGHT = 53 + 6;

export interface RigViewmodel extends RigLeaderboardDto {
  name: string;
  operatorName: string;
}

export const RigLeaderboardList = ({
  setTotalRigs,
}: {
  setTotalRigs: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { data: unfilteredMetrics, isLoading: isLoadingMetrics } =
    useRigLeaderboard({ staleTime: Infinity });
  const { data: rigSummaries, isLoading: isLoadingSummaries } = useRigSummaries(
    {
      staleTime: Infinity,
    },
  );

  const { options: filterAvailableOptions } = useLinkedFilters({
    keepEvengreenOnly: false,
    storeName: "rigsCommon",
  });

  const metricsLeaderboard = useMemo(() => {
    const visibleIds = filterAvailableOptions?.rigs.map((rig) => rig.id) ?? [];
    return visibleIds.length
      ? unfilteredMetrics?.rigLeaderboard.filter((metric) =>
        visibleIds?.includes(metric.rigId),
      )
      : unfilteredMetrics?.rigLeaderboard;
  }, [filterAvailableOptions?.rigs, unfilteredMetrics]);

  const { data: operators, isLoading: isLoadingOperators } = useOperators({
    staleTime: Infinity,
  });
  const getRigValueByKey = useScoreValues();

  const isLoading = useMemo(
    () => isLoadingMetrics || isLoadingSummaries || isLoadingOperators,
    [isLoadingMetrics, isLoadingOperators, isLoadingSummaries],
  );

  const exceptions = useAppSelector((state) => state.rigsCommon.exceptions);

  const rigsViewmodel = useMemo<RigViewmodel[]>(() => {
    const leaderboardData = metricsLeaderboard;
    if (!leaderboardData) return [];
    return leaderboardData?.map((metricsInfo) => {
      const matchedRig = rigSummaries?.find(
        (rig) => rig.id === metricsInfo.rigId,
      );
      const matchedComplianceSource = metricsLeaderboard.find(
        (matched) => matched.rigId === metricsInfo.rigId,
      );
      return {
        ...metricsInfo,
        rigScoreComplianceRate: matchedComplianceSource?.rigScoreComplianceState
          ? +matchedComplianceSource?.rigScoreComplianceState
          : undefined, // We force taking data from object without exceptions
        operatorScoreComplianceRate:
          matchedComplianceSource?.operatorScoreComplianceRate
            ? +matchedComplianceSource?.operatorScoreComplianceRate
            : undefined, // We force taking data from object without exceptions
        improvementScoreComplianceRate:
          matchedComplianceSource?.improvementScoreComplianceRate
            ? +matchedComplianceSource?.improvementScoreComplianceRate
            : undefined, // We force taking data from object without exceptions
        name: matchedRig?.shortName || "",
        operatorName:
          (matchedRig?.operatorId &&
            operators?.byId?.[matchedRig?.operatorId]?.name) ||
          "Unknown",
      };
    });
  }, [metricsLeaderboard, operators?.byId, rigSummaries]);

  const { scoreBenchmark, setScoreBenchmark } = useScoreBenchmarkContext();

  const [sortBy, setSortBy] = useState(SortBy.OverallScore);
  const [sortDirection, setSortDirection] = useState(SortDirections.Desc);

  const filteredAndSortedMetrics: RigViewmodel[] = useMemo(() => {
    const filtered = rigsViewmodel?.filter((rig) =>
      Number.isFinite(getRigValueByKey(rig, "score")),
    );
    let sorted = _sort(filtered, (a) => getRigValueByKey(a, "score"));

    if (sortBy === SortBy.Trend) {
      const [valid, empty] = partition(sorted, (a) =>
        Number.isFinite(getRigValueByKey(a, "trend")),
      );
      valid?.sort(
        (a, b) =>
          (getRigValueByKey(a, "trend") || 0) -
          (getRigValueByKey(b, "trend") || 0),
      );
      sorted =
        sortDirection === SortDirections.Asc
          ? valid.concat(empty)
          : empty.concat(valid);
    } else if (sortBy === SortBy.Rig) {
      sorted = _sort(sorted, (a) => a.name);
    } else if (sortBy === SortBy.OpportunityTime) {
      const [valid, empty] = partition(sorted, (a) =>
        Number.isFinite(getRigValueByKey(a, "opportunityTime")),
      );
      valid?.sort(
        (a, b) =>
          (getRigValueByKey(a, "opportunityTime") || 0) -
          (getRigValueByKey(b, "opportunityTime") || 0),
      );
      sorted =
        sortDirection === SortDirections.Asc
          ? valid.concat(empty)
          : empty.concat(valid);
    } else if (sortBy === SortBy.TimeSaved) {
      const [valid, empty] = partition(sorted, (a) =>
        Number.isFinite(getRigValueByKey(a, "timeSaved")),
      );
      valid?.sort(
        (a, b) =>
          (getRigValueByKey(a, "timeSaved") || 0) -
          (getRigValueByKey(b, "timeSaved") || 0),
      );
      sorted =
        sortDirection === SortDirections.Asc
          ? valid.concat(empty)
          : empty.concat(valid);
    } else if (sortBy === SortBy.Downtime) {
      const [valid, empty] = partition(sorted, (a) =>
        Number.isFinite(a.totalDowntime),
      );
      valid?.sort((a, b) => (a?.totalDowntime || 0) - (b?.totalDowntime || 0));
      sorted =
        sortDirection === SortDirections.Asc
          ? valid.concat(empty)
          : empty.concat(valid);
    } else if (sortBy === SortBy.Compliance) {
      const [valid, empty] = partition(sorted, (a) =>
        Number.isFinite(getRigValueByKey(a, "complianceRate")),
      );
      valid?.sort(
        (a, b) =>
          (getRigValueByKey(a, "complianceRate") || 0) -
          (getRigValueByKey(b, "complianceRate") || 0),
      );
      sorted =
        sortDirection === SortDirections.Asc
          ? valid.concat(empty)
          : empty.concat(valid);
    } else if (sortBy === SortBy.ExceptionCount) {
      sorted = _sort(filtered, (a) => a.totalExceptionCount);
    } else if (sortBy === SortBy.ExceptionTime) {
      sorted = _sort(filtered, (a) => a.approvedExceptionTime);
    }

    if (sortDirection === SortDirections.Desc) {
      sorted?.reverse();
    }

    return sorted || [];
  }, [getRigValueByKey, rigsViewmodel, sortBy, sortDirection]);
  useEffect(() => {
    setTotalRigs(filteredAndSortedMetrics.length);
  }, [filteredAndSortedMetrics.length]);

  const SortIcon = useCallback(
    (column: SortBy | undefined) => {
      return column ? (
        <SortIcons>
          <StyledChevron
            $isActive={
              sortBy === column && sortDirection === SortDirections.Asc
            }
            $isFaded={sortBy === column && sortDirection !== SortDirections.Asc}
          />
          <StyledChevron
            $isActive={
              sortBy === column && sortDirection === SortDirections.Desc
            }
            $isFaded={
              sortBy === column && sortDirection !== SortDirections.Desc
            }
          />
        </SortIcons>
      ) : null;
    },
    [sortBy, sortDirection],
  );

  const handleSort = useCallback(
    (newSortBy: SortBy | undefined) => {
      if (!newSortBy) return;
      Track.interact("Rig Metrics - Update sorting", {
        "Selected sort": newSortBy,
      });

      if (sortBy === newSortBy) {
        setSortDirection(
          sortDirection === SortDirections.Asc
            ? SortDirections.Desc
            : SortDirections.Asc,
        );
      } else {
        setSortDirection(SortDirections.Asc);
      }
      setSortBy(newSortBy);
    },
    [sortBy, sortDirection],
  );

  const { themeStyle } = useCustomTheme();

  return (
    <>
      <Styled.Header>
        <Styled.OrderColumn hasSmallFont>{Columns[0].title}</Styled.OrderColumn>
        <Styled.RigColumn
          isSortable
          hasSmallFont
          title={Columns[1].title}
          onClick={() => handleSort(Columns[1].sortKey)}
        >
          {Columns[1].title} {SortIcon(Columns[1].sortKey)}
        </Styled.RigColumn>

        {[2, 3, 4, 5, exceptions ? 6 : 7, exceptions ? 8 : 9].map((idx) => {
          return (
            <Styled.Column
              key={idx}
              title={
                scoreBenchmark === BenchmarkType.PDBenchmark &&
                  Columns[idx].title === "Time vs Target"
                  ? "Time vs Benchmark"
                  : Columns[idx].title
              }
              isSortable
              hasSmallFont
              onClick={() => handleSort(Columns[idx].sortKey)}
            >
              <Styled.CenteredWithTransform>
                {scoreBenchmark === BenchmarkType.PDBenchmark &&
                  Columns[idx].title === "Time vs Target"
                  ? "Time vs Benchmark"
                  : Columns[idx].title}
                {SortIcon(Columns[idx].sortKey)}
              </Styled.CenteredWithTransform>
            </Styled.Column>
          );
        })}
        <Styled.OperatorTarget>
          <PDComponent.Dropdown<BenchmarkType>
            options={[
              {
                label: BenchmarkType.OperatorTarget,
                value: BenchmarkType.OperatorTarget,
              },
              {
                label: BenchmarkType.PDBenchmark,
                value: BenchmarkType.PDBenchmark,
              },
            ]}
            variant="button"
            selectedOption={scoreBenchmark}
            handleOptionUpdate={(option) => {
              Track.interact("Rig Metrics - Change benchmark", {
                "Selected benchmark": option,
              });
              setScoreBenchmark(option);
            }}
            backgroundColor={themeStyle.colors.quaterniary_bg}
          />
        </Styled.OperatorTarget>
      </Styled.Header>

      <Styled.ListRowContainer>
        {isLoading ? (
          <RigLeaderboardListSkeleton />
        ) : (
          <PDComponent.VirtualizedList
            items={filteredAndSortedMetrics}
            itemSizePx={ROW_HEIGHT}
          >
            {(rig, idx) => <RigLeaderboardListRow rig={rig} order={idx + 1} />}
          </PDComponent.VirtualizedList>
        )}
      </Styled.ListRowContainer>
    </>
  );
};
