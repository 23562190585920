export const useImperativeTimelineEventScroll = () => {
  const getDomEventId = (eventId?: number | null) => {
    if (!Number.isFinite(eventId)) {
      return undefined;
    }
    return `timeline-event-indicator-${eventId}`;
  };

  return {
    getDomEventId,
    scrollToWithDelay: (eventId: number | null) => {
      setTimeout(() => {
        const domId = getDomEventId(eventId);
        if (!domId) return;
        const eventIcon = document.getElementById(domId);
        if (eventIcon) {
          eventIcon.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }, 300);
    },
  };
};
