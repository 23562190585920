import styled from "styled-components";

export const UnsupportedFeature = styled.div`
  height: 100%;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;
