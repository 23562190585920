import { notification } from "antd";
import { Spinner } from "components/Loader/Spinner";
import { PDComponent } from "components/PDComponents";
import React from "react";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";

// Ant Notification Options
notification.config({
  placement: "bottomLeft",
  closeIcon: (
    <PDComponent.SvgIcon
      name="closeOutline"
      onClick={() => {
        Track.interact("Close Report Download");
      }}
    />
  ),
});

interface IToast {
  message: string;
}

export const toast = {
  success: ({ message }: IToast) => {
    return notification.success({
      message,
      icon: <PDComponent.SvgIcon name="toastSuccess" />,
    });
  },
  error: ({ message }: IToast) => {
    return notification.error({
      message,
      icon: <PDComponent.SvgIcon name="toastError" />,
    });
  },
  loading: ({ message }: IToast) => {
    return notification.info({
      message,
      key: "loading",
      duration: 0,
      icon: <Spinner size={24} top={4} left={4} />,
    });
  },
  downloadDone: ({ message }: { message: React.ReactNode }) => {
    return notification.success({
      message,
      duration: 0,
      key: "download",
      icon: (
        <div
          style={{
            width: 32,
            height: 32,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 2,
            backgroundColor: colors.gray,
          }}
        >
          {" "}
          <PDComponent.SvgIcon name="download" />{" "}
        </div>
      ),
    });
  },
  close: (key: string) => notification.close(key),
};
