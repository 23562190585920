import { StyledTag } from "atoms/common";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import type { ReactElement } from "react";
import React from "react";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Space, Tooltip } from "utils/componentLibrary";
import { PdSpaceItem } from "utils/componentLibrary/Space";

import type { CustomTagProps } from "./CustomTag";
import { CustomTag } from "./CustomTag";

export const StyledTitle = styled(Title).attrs({ level: 3 })<{ $inner: string }>`
  font-weight: 500 !important;
  ${({ $inner }) =>
    $inner === "true"
      ? css`
          color: ${colors.necron_compound} !important;
          /* Paragraph/P2-Regular */
          font-size: 14px;
          font-style: normal;
          font-weight: 400 !important;
          line-height: normal;
        `
      : null}
`;

const StyledDescription = styled(Title).attrs({ level: 3 })`
  color: ${colors.necron_compound} !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const Container = styled.div`
  position: relative;
`;

const StyledIcon = styled(PDComponent.SvgIcon).attrs({ name: "book" })`
  position: absolute;
  right: 16px;
  top: 12px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  padding: 9px;
  color: ${({ theme }) => theme.themeStyle.colors.black_white};
`;

const StyledInformationIcon = styled.span`
  position: absolute;
  right: 8px;
  top: 0;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const StyledContainer = styled(Space).attrs({ direction: "vertical", align: "center", size: 8 })`
  display: flex;
`;

export const GroupTitle = React.forwardRef<
  HTMLDivElement,
  {
    title: string;
    description?: string;
    tags: CustomTagProps[];
    withTagSeparators?: boolean;
    inner?: boolean;
    hoverText?: ReactElement | null;
    openCommentModal?: () => void;
  }
>(({ title, tags, description, withTagSeparators, inner, hoverText, openCommentModal }, innerRef) => {
  return (
    <Container ref={innerRef}>
      {hoverText ? (
        <Tooltip title={hoverText} placement="top" overlayStyle={{ maxWidth: "740px" }}>
          <StyledInformationIcon>
            <PDComponent.SvgIcon name="information" />
          </StyledInformationIcon>
        </Tooltip>
      ) : null}
      <StyledContainer>
        <StyledTitle $inner={(!!inner).toString()}>{title}</StyledTitle>

        {description ? <StyledDescription>{description}</StyledDescription> : null}
        {tags.length > 0 ? (
          <StyledTagsSpace $withTagSeparators={withTagSeparators}>
            {tags.map((tag, index) => (
              <CustomTag key={index} {...tag} />
            ))}
          </StyledTagsSpace>
        ) : null}
      </StyledContainer>
      {inner ? null : <StyledIcon onClick={openCommentModal} />}
    </Container>
  );
});

export const StyledTagsSpace = styled(Space).attrs({ size: 6 })<{
  $withTagSeparators?: boolean;
  $withoutLeftPadding?: boolean;
}>`
  flex-wrap: wrap;
  justify-content: center;
  > ${PdSpaceItem} {
    display: flex;
    align-items: center;
    & + ${PdSpaceItem} {
      :before {
        content: "";
        display: ${({ $withTagSeparators }) => ($withTagSeparators ? "inline-block" : "none")};
        width: 1px;
        height: 22px;
        background: ${({ theme }) => (theme.isDark ? colors.lunar_rays : colors.necron_compound)};
        opacity: 0.12;
        @media screen and (max-width: 1280px) {
          display: none;
        }
      }
    }
  }
  ${({ $withoutLeftPadding }) =>
    $withoutLeftPadding &&
    css`
      ${StyledTag}:first-child {
        padding-left: 0 !important;
      }
    `}
`;
