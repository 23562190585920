import ButtonGroup from "antd/lib/button/button-group";
import { Title } from "atoms/Typography";
import styled, { css } from "styled-components";
import { Row, Space } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const HeaderContainerRow = styled(Row)`
  padding: 0 24px;
  min-height: 64px;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_chart_bg};
  border-bottom: ${({ theme }) => `1px solid ${theme.themeStyle.colors.primary_accent}`};
  z-index: ${zIndexLayer.high};
  overflow-x: auto;
`;

const padding = css`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const PaddedSpace = styled(Space)`
  ${padding}
`;

export const RightContainer = styled(Space)`
  justify-content: end;
`;

export const FilterTagsContainer = styled.div`
  max-width: 500px;
  ${padding}
`;

export const Buttons = styled(ButtonGroup)`
  a:first-child {
    .ant-btn {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  a:last-child {
    .ant-btn {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

export const StyledTitle = styled(Title).attrs({
  level: 3,
})`
  white-space: nowrap;
`;

export const StyledGap = styled.div`
  display: flex;
  gap: 5px;
`;