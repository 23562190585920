/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PromptType } from './PromptType';
import {
    PromptTypeFromJSON,
    PromptTypeFromJSONTyped,
    PromptTypeToJSON,
} from './PromptType';

/**
 * 
 * @export
 * @interface ClaraPromptDto
 */
export interface ClaraPromptDto {
    /**
     * 
     * @type {number}
     * @memberof ClaraPromptDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ClaraPromptDto
     */
    prompt: string;
    /**
     * 
     * @type {PromptType}
     * @memberof ClaraPromptDto
     */
    type: PromptType;
    /**
     * 
     * @type {number}
     * @memberof ClaraPromptDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof ClaraPromptDto
     */
    profileId: number;
}



/**
 * Check if a given object implements the ClaraPromptDto interface.
 */
export function instanceOfClaraPromptDto(value: object): value is ClaraPromptDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('prompt' in value) || value['prompt'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('profileId' in value) || value['profileId'] === undefined) return false;
    return true;
}

export function ClaraPromptDtoFromJSON(json: any): ClaraPromptDto {
    return ClaraPromptDtoFromJSONTyped(json, false);
}

export function ClaraPromptDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaraPromptDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'prompt': json['Prompt'],
        'type': PromptTypeFromJSON(json['Type']),
        'position': json['Position'],
        'profileId': json['ProfileId'],
    };
}

export function ClaraPromptDtoToJSON(value?: ClaraPromptDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Prompt': value['prompt'],
        'Type': PromptTypeToJSON(value['type']),
        'Position': value['position'],
        'ProfileId': value['profileId'],
    };
}

