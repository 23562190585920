/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagBottomFingerprintOverallFactDto
 */
export interface TagBottomFingerprintOverallFactDto {
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintOverallFactDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof TagBottomFingerprintOverallFactDto
     */
    stdDev: number;
}

/**
 * Check if a given object implements the TagBottomFingerprintOverallFactDto interface.
 */
export function instanceOfTagBottomFingerprintOverallFactDto(value: object): value is TagBottomFingerprintOverallFactDto {
    if (!('average' in value) || value['average'] === undefined) return false;
    if (!('stdDev' in value) || value['stdDev'] === undefined) return false;
    return true;
}

export function TagBottomFingerprintOverallFactDtoFromJSON(json: any): TagBottomFingerprintOverallFactDto {
    return TagBottomFingerprintOverallFactDtoFromJSONTyped(json, false);
}

export function TagBottomFingerprintOverallFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBottomFingerprintOverallFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'average': json['Average'],
        'stdDev': json['StdDev'],
    };
}

export function TagBottomFingerprintOverallFactDtoToJSON(value?: TagBottomFingerprintOverallFactDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Average': value['average'],
        'StdDev': value['stdDev'],
    };
}

