import type { TagVariants } from "atoms/common";
import { Cursor, StyledTag } from "atoms/common";
import { useMemo } from "react";
import { Tooltip } from "utils/componentLibrary";

export interface CustomTagProps {
  variant?: TagVariants;
  color: string;
  text: string;
  icon?: JSX.Element;
  tooltipText?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  size?: "default" | "large";
  disabled?: boolean;
}

export const CustomTag: React.FC<CustomTagProps> = ({
  variant = "default",
  color,
  icon,
  text,
  tooltipText,
  size = "default",
  onClick,
  disabled,
}) => {
  const cursorType = useMemo(() => {
    if (disabled) return Cursor.NotAllowed;
    if (onClick) return Cursor.Pointer;
    return Cursor.Default;
  }, [disabled, onClick]);

  return (
    <Tooltip title={tooltipText}>
      <StyledTag
        $variant={variant}
        $color={color}
        $isLarge={size === "large"}
        onClick={disabled ? undefined : onClick}
        $cursor={cursorType}
      >
        {icon ? icon : null}
        <span>{text}</span>
      </StyledTag>
    </Tooltip>
  );
};
