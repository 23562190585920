import styled, { css } from "styled-components";
import colors from "utils/colors";
import { zIndexLayer } from "utils/zIndex";

export const ClaraContainer = styled.div`
  position: fixed;
  bottom: 61px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  height: 68px;
  width: 68px;
  background-color: ${({ theme }) => theme.themeStyle.colors.clara_CTA_bg};
  &:hover {
    ${({ theme }) =>
      theme.isDark
        ? css`
            box-shadow:
              0px 4px 35px 0px ${colors.acid_pops}a0 inset,
              0px 0px 35px 0px ${colors.iridescent_turquoise}fa inset;
          `
        : css`
            box-shadow: 0px 0px 35px 0px ${colors.iridescent_turquoise}a4 inset;
          `}
    filter: drop-shadow(0px 0px 6px  ${colors.iridescent_turquoise}f0) blur(0.5px);
  }
  display: grid;
  place-items: center;
  right: 42px;
  z-index: ${zIndexLayer.moon};
  cursor: pointer;

  #messagePending {
    font-size: 39px;
    position: absolute;
    top: -15%;
    left: -35%;
  }
  #clara {
    font-size: 58px;
  }
`;

export const HeaderButtonContainer = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.themeStyle.colors.clara_sidebar_bg};
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 20px;
  svg {
    font-size: 16px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-areas:
    "sideMenu header"
    "sideMenu chatArea"
    "sideMenu questionInput";
  background-color: ${({ theme }) => theme.themeStyle.colors.clara_bg};
  height: 100%;
  width: 100%;
  #sideMenu {
    grid-area: sideMenu;
    font-size: 46px;
    display: flex;
    gap: 40px;
    align-items: center;
    flex-direction: column;
    padding-top: 26px;
    background-color: ${({ theme }) => theme.themeStyle.colors.clara_sidebar_bg};
  }
  #questionInput {
    grid-area: questionInput;
  }
  #chatArea {
    grid-area: chatArea;
  }
  #header {
    grid-area: header;
    color: ${({ theme }) => theme.themeStyle.colors.clara_text_color};
    padding: 40px 55px 0px 20px;
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: ${({ theme }) => theme.themeStyle.colors.clara_text_color};
    }
  }

  grid-template-columns: 72px 1fr;
  grid-template-rows: 96px minmax(150px, 1fr) 150px;
`;

export const AddClaraIcon = styled.div`
  font-size: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background-color: ${({ theme }) => (theme.isDark ? colors.limo_scene : colors.lunar_rays)};
  color: ${({ theme }) => (theme.isDark ? colors.lunar_rays : colors.limo_scene)};
`;

export const RightSideContainer = styled.div<{ $showScrollHintTop: boolean; $showScrollHintBottom: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  width: 740px;
  padding-right: 40px;
  row-gap: 40px;
  align-items: space-between;
  padding-bottom: 30px;
  overflow-y: auto;
  @media screen and (max-width: 1024px) {
    width: 470px;
  }
  --top-mask-size: 0px;
  --bottom-mask-size: 0px;

  ${(props) => props.$showScrollHintTop && `--top-mask-size: 60px;`}
  ${(props) => props.$showScrollHintBottom && `--bottom-mask-size: 60px;`}

  mask-image: linear-gradient(
    to bottom,
    transparent 0,
    black var(--top-mask-size, 0),
    black calc(100% - var(--bottom-mask-size, 0)),
    transparent 100%
  );
`;
