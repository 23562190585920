import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { FiltersDto } from "apis/oag";
import { DashboardType, WellsApi } from "apis/oag";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDWellReferenceFiltersQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";

const wellsApi = new WellsApi(apiConfig);

export function useReferenceFilters(options?: Omit<UseQueryOptions<FiltersDto>, "queryFn" | "queryKey">) {
  const location = useLocation();
  const dashboardType = useMemo(
    () => (location.pathname.includes("rig") ? DashboardType.Rig : DashboardType.Well),
    [location],
  );

  const [rigSelectedWells] = useStateQuery<Array<number> | null>(URL_STATE_PARAM.SELECTED_WELLS_RIG_WIDGET, null);
  const [offsetWellsWidget] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);

  const wellOffsetWells = useAppSelector((state) => {
    if (location.pathname.includes("export")) return state.report.offsetWells;
    return offsetWellsWidget;
  });

  const reduxWellId = useAppSelector(({ state, report }) =>
    location.pathname.includes("export") ? report.selectedWell : state.selectedWell,
  );

  const referenceFiltersApiQueryKey: PDWellReferenceFiltersQueryKey = {
    type: PDQueryType.REFERENCE_FILTERS,
    uid: RequestUID.referenceFilters,
    selectedWell: reduxWellId || -1,
    offsetWells: wellOffsetWells,
    rigSelectedWells: rigSelectedWells || [],
    dashboardType,
  };

  return useQuery<FiltersDto>({
    queryKey: [referenceFiltersApiQueryKey],
    queryFn: () => {
      return wellsApi.apiWellsReferenceFiltersPut({
        requestBody:
          dashboardType === DashboardType.Rig
            ? rigSelectedWells || []
            : [...(reduxWellId ? [reduxWellId] : []), ...offsetWellsWidget],
      });
    },
    ...options,
    enabled: dashboardType === DashboardType.Well ? reduxWellId !== null : true,
  });
}
