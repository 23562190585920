import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import {
  ChevronDownIcon as GeneratorChevronDownIcon,
  SelectedOption as SelectedGenerator,
} from "components/PDComponents/Dropdown/style";
import styled, { css } from "styled-components";
import { zIndexLayer } from "utils/zIndex";

export const IconContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 4px;
  font-size: 19px;
`;

export const DieselType = styled(Title).attrs({
  level: 5,
})`
  :hover {
    cursor: pointer;
  }
`;
export const DieselContainer = styled.div``;
export const ValueContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: baseline;
  gap: 5px;
`;
export const ChevronDownIcon = styled(PDComponent.SvgIcon).attrs({ name: "chevronDown" })`
  display: inline-block;
  opacity: 1;
`;

export const Value = styled(Title).attrs({
  level: 3,
})`
  font-size: 22px !important;
  font-weight: 500 !important;
`;

export const Unit = styled(Title).attrs({
  level: 3,
})`
  font-size: 16px !important;
  font-weight: 500 !important;
`;

export const NoDataText = styled(Title).attrs({
  level: 3,
  variant: "faded",
})`
  font-size: 12px !important;
  font-weight: 500 !important;
`;

export const StatCard = styled.div<{
  height: number;
  width: number;
  $mouseIn: boolean;
  $hasData: boolean;
  $isMenuOpen: boolean;
}>`
  display: flex;
  position: absolute;
  z-index: ${({ $mouseIn }) => ($mouseIn ? "0" : "2")};
  height: 100%;
  width: 100%;
  background-color: ${({ theme: { themeStyle } }) => themeStyle.colors.primary_chart_bg};
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  overflow: visible;
  padding-left: 12px;
  padding-top: 0px;
  transition: all 0.2s ease-in-out;
  ${SelectedGenerator} {
    position: absolute;
    bottom: 10px;
    right: 2px;
    transition: all 0.2s ease-in-out;
  }

  ${ChevronDownIcon}, ${GeneratorChevronDownIcon} {
    opacity: ${({ $isMenuOpen, $mouseIn }) => ($isMenuOpen || $mouseIn ? "1" : "0")};
  }

  :hover {
    z-index: ${zIndexLayer.ground};
    background-color: ${({ theme: { themeStyle } }) => themeStyle.colors.alt_quaterniary_bg};
    ${ChevronDownIcon}, ${GeneratorChevronDownIcon} {
      opacity: 1;
    }
  }

  ${({ height, width, $mouseIn, $hasData }) => [
    height === 1 &&
      css`
        gap: ${$hasData ? 0 : 5}px;
        ${Value} {
          font-size: ${!$hasData ? 18 : 22}px !important;
        }
        ${Unit} {
          font-size: ${!$hasData ? 14 : 18}px !important;
        }
        height: calc(100% - ${$mouseIn ? 40 : 0}px);
      `,
    height > 1 &&
      css`
        gap: 5px;
        ${DieselType} {
          font-size: 14px !important;
        }
        ${Value} {
          font-size: ${!$hasData || width < 3 ? 22 : 34}px !important;
        }
        ${Unit} {
          font-size: ${!$hasData || width < 3 ? 16 : 24}px !important;
        }
        ${SelectedGenerator} {
          position: static;
        }
        ${IconContainer} {
          font-size: 24px;
          right: 24px;
          top: 24px;
        }
      `,
    width > 2 &&
      css`
        align-items: center;
      `,
  ]}
`;

export const Option = styled.div`
  width: 100%;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  cursor: pointer;
`;

export const StyledIconSelectorContainer = styled.div<{ height: number; $mouseIn: boolean; hasIcon: boolean }>`
  ${({ height, hasIcon }) =>
    height === 1 &&
    css`
      overflow: visible;
      width: 100%;
      display: flex;
      z-index: ${zIndexLayer.below};
      height: 40px;
      flex-direction: column;
      align-items: flex-end;
      justify-content: ${hasIcon ? "space-between" : "flex-start"};
      padding-top: 4px;
      position: absolute;

      ${IconContainer} {
        position: static;
        margin-right: 24px;
      }

      ${SelectedGenerator} {
        position: static;
        margin-right: 16px;
      }
    `}
`;
