import { Button } from "atoms/Form";
import styled from "styled-components";
import colors from "utils/colors";

export const MessageBody = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 24px;
  flex-direction: column;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
`;

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 24px 100px;
  svg {
    font-size: 100px;
    color: ${({ theme }) => theme.themeStyle.colors.inverted_primary_typography};
  }
`;

export const MessageTitle = styled.h3`
  margin-bottom: 8px;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const Message = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding-top: 4px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const ResetButton = styled(Button)`
  border-radius: 4px;
  background: ${({ theme }) => (theme.isDark ? colors.actions_bg : colors.darth_vader)} !important;
  color: ${({ theme }) => theme.themeStyle.colors.inverted_primary_typography} !important;
`;
