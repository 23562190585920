import { Title } from "atoms/Typography";
import * as Styled from "components/Lenses/ContainerLens/StickSlipByTime/styles";
import { PDComponent } from "components/PDComponents";
import { Tooltip } from "utils/componentLibrary";

export const LensHeader = ({ ControlButtons }: { ControlButtons?: () => JSX.Element }) => {
  return (
    <Styled.HeaderContainer>
      <Styled.TitleContainer>
        <Title level={3}>Stick Slip by Time</Title>
        <Tooltip title="Only rotary drilling points included">
          <PDComponent.SvgIcon name="information" width="16" height="16" />
        </Tooltip>
      </Styled.TitleContainer>
      {ControlButtons ? <ControlButtons /> : null}
    </Styled.HeaderContainer>
  );
};
