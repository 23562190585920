import type { GeneratorSlotType } from "apis/oag";
import { BoilerSlotType } from "apis/oag";
import { GeneratorAndBoilerOptions } from "components/Lenses/common/GeneratorAndBoilerSelector/utils";
import { allGenerators } from "components/Lenses/utils";

export const getLensSettingsFromOptions: (props: {
  generatorAndBoilerOption: GeneratorAndBoilerOptions;
  selectedGenerator?: GeneratorSlotType;
  isDiesel: boolean;
}) => {
  selectedGenerators: GeneratorSlotType[];
  selectedBoilers: BoilerSlotType[];
} = ({ generatorAndBoilerOption, selectedGenerator, isDiesel }) => {
  switch (generatorAndBoilerOption) {
    case GeneratorAndBoilerOptions.AllGens:
      return {
        selectedGenerators: allGenerators,
        selectedBoilers: [],
      };
    case GeneratorAndBoilerOptions.AllDieselBoilers:
      return {
        selectedGenerators: [],
        selectedBoilers: [BoilerSlotType.Diesel1, BoilerSlotType.Diesel2],
      };
    case GeneratorAndBoilerOptions.Boiler1Diesel:
      return {
        selectedGenerators: [],
        selectedBoilers: [BoilerSlotType.Diesel1],
      };
    case GeneratorAndBoilerOptions.Boiler2Diesel:
      return {
        selectedGenerators: [],
        selectedBoilers: [BoilerSlotType.Diesel2],
      };
    case GeneratorAndBoilerOptions.BoilerNatGas:
      return {
        selectedGenerators: [],
        selectedBoilers: [BoilerSlotType.NatGas1],
      };
    case GeneratorAndBoilerOptions.Total:
      return {
        selectedGenerators: allGenerators,
        selectedBoilers: isDiesel ? [BoilerSlotType.Diesel1, BoilerSlotType.Diesel2] : [BoilerSlotType.NatGas1],
      };
    case GeneratorAndBoilerOptions.SingleGen:
      return {
        selectedGenerators: selectedGenerator ? [selectedGenerator] : [],
        selectedBoilers: [],
      };
    default:
      return {
        selectedGenerators: [],
        selectedBoilers: [],
      };
  }
};

export const getGeneratorAndBoilerOptionFromLensSettings: (
  selectedGenerators: GeneratorSlotType[],
  selectedBoilers: BoilerSlotType[],
) => GeneratorAndBoilerOptions = (selectedGenerators, selectedBoilers) => {
  if (selectedGenerators.length === 0 && selectedBoilers.length === 1) {
    if (selectedBoilers[0] === BoilerSlotType.Diesel1) {
      return GeneratorAndBoilerOptions.Boiler1Diesel;
    } else if (selectedBoilers[0] === BoilerSlotType.Diesel2) {
      return GeneratorAndBoilerOptions.Boiler2Diesel;
    } else if (selectedBoilers[0] === BoilerSlotType.NatGas1) {
      return GeneratorAndBoilerOptions.BoilerNatGas;
    }
  } else if (selectedGenerators.length === 0 && selectedBoilers.length === 2) {
    return GeneratorAndBoilerOptions.AllDieselBoilers;
  } else if (selectedGenerators.length === 1) {
    return GeneratorAndBoilerOptions.SingleGen;
  } else if (selectedGenerators.length === allGenerators.length) {
    if (selectedBoilers.length) {
      return GeneratorAndBoilerOptions.Total;
    }
    return GeneratorAndBoilerOptions.AllGens;
  }
  return GeneratorAndBoilerOptions.Total;
};
