import type { TvdHoleDirectionDto, TvdPhaseDto } from "apis/oag";
import { useDirectionalIntervals } from "hooks/useDirectionalIntervals";
import { usePhases } from "hooks/usePhases";
import { useCallback, useMemo } from "react";
import { useCustomTheme } from "utils/useTheme";

export type ColoredSegmentVm = (TvdPhaseDto | TvdHoleDirectionDto) & { color: string; label: string };

export const usePhasesAndIntervalsToSegments = (phases: TvdPhaseDto[], intervals: TvdHoleDirectionDto[]) => {
  const { data: phaseDefinitions, isLoading: isLoadingPhases } = usePhases();
  const { data: directionalIntervals, isLoading: isLoadingIntervals } = useDirectionalIntervals();

  const getPhaseName = useCallback(
    (phase: TvdPhaseDto) =>
      phaseDefinitions?.find((phaseDefinition) => phaseDefinition.id === phase.phasesId)?.name ?? "",
    [phaseDefinitions],
  );

  const getIntervalName = useCallback(
    (interval: TvdHoleDirectionDto) =>
      directionalIntervals?.find((intervalDefinition) => intervalDefinition.id === interval.directionalIntervalId)
        ?.name ?? "",
    [directionalIntervals],
  );

  const {
    themeStyle: {
      wells: { phases: colorWellPhases, directionalIntervals: colorDirectionalIntervals },
    },
  } = useCustomTheme();

  const phaseSegments: ColoredSegmentVm[] = useMemo(
    () =>
      phases.map((phase) => ({
        ...phase,
        color: colorWellPhases[getPhaseName(phase) as keyof typeof colorWellPhases],
        label: getPhaseName(phase),
      })),
    [colorWellPhases, getPhaseName, phases],
  );

  const intervalSegments: ColoredSegmentVm[] = useMemo(
    () =>
      intervals.map((interval) => ({
        ...interval,
        color: colorDirectionalIntervals[getIntervalName(interval) as keyof typeof colorDirectionalIntervals],
        label: getIntervalName(interval),
      })),
    [colorDirectionalIntervals, getIntervalName, intervals],
  );

  return { phaseSegments, intervalSegments, isLoading: isLoadingPhases || isLoadingIntervals };
};
