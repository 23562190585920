/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClaimWellInfo } from './ClaimWellInfo';
import {
    ClaimWellInfoFromJSON,
    ClaimWellInfoFromJSONTyped,
    ClaimWellInfoToJSON,
} from './ClaimWellInfo';

/**
 * 
 * @export
 * @interface AccountRigAccessDto
 */
export interface AccountRigAccessDto {
    /**
     * 
     * @type {number}
     * @memberof AccountRigAccessDto
     */
    rigId: number;
    /**
     * 
     * @type {number}
     * @memberof AccountRigAccessDto
     */
    rigExternalId: number;
    /**
     * 
     * @type {Array<ClaimWellInfo>}
     * @memberof AccountRigAccessDto
     */
    wells?: Array<ClaimWellInfo> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountRigAccessDto
     */
    futureWellAccess: boolean;
}

/**
 * Check if a given object implements the AccountRigAccessDto interface.
 */
export function instanceOfAccountRigAccessDto(value: object): value is AccountRigAccessDto {
    if (!('rigId' in value) || value['rigId'] === undefined) return false;
    if (!('rigExternalId' in value) || value['rigExternalId'] === undefined) return false;
    if (!('futureWellAccess' in value) || value['futureWellAccess'] === undefined) return false;
    return true;
}

export function AccountRigAccessDtoFromJSON(json: any): AccountRigAccessDto {
    return AccountRigAccessDtoFromJSONTyped(json, false);
}

export function AccountRigAccessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountRigAccessDto {
    if (json == null) {
        return json;
    }
    return {
        
        'rigId': json['RigId'],
        'rigExternalId': json['RigExternalId'],
        'wells': json['Wells'] == null ? undefined : ((json['Wells'] as Array<any>).map(ClaimWellInfoFromJSON)),
        'futureWellAccess': json['FutureWellAccess'],
    };
}

export function AccountRigAccessDtoToJSON(value?: AccountRigAccessDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'RigId': value['rigId'],
        'RigExternalId': value['rigExternalId'],
        'Wells': value['wells'] == null ? undefined : ((value['wells'] as Array<any>).map(ClaimWellInfoToJSON)),
        'FutureWellAccess': value['futureWellAccess'],
    };
}

