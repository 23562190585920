import { LinearGradient } from "@visx/gradient";
import { scaleBand, scaleLinear } from "@visx/scale";
import { BarRounded } from "@visx/shape";
import type { WellBarDto } from "apis/oag";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { max } from "d3";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { getColorByScore } from "pages/RigScoreCard/LeftPane/utils";
import { BenchmarkType, useScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";
import { Fragment, useCallback, useMemo, useRef } from "react";
import { useUOM, UtilDimensions } from "utils/format";

import * as Styled from "./styled";

export const plotWidth = 140;
export const plotHeight = 41;

interface WellbarViewmodel {
  score: number;
  id: number;
  name: string;
}

export const MiniWellChart = ({ wellBars }: { wellBars: WellBarDto[] }) => {
  const { scoreBenchmark } = useScoreBenchmarkContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const wells = useWellShortInfoSuspended();

  const bars: WellbarViewmodel[] = useMemo(() => {
    return wellBars
      .map((wellBar) => ({
        score: (scoreBenchmark === BenchmarkType.OperatorTarget ? wellBar.operatorScore : wellBar.rigScore) || 0,
        id: wellBar.id,
        name: wells.data?.byId[wellBar.id]?.name || "Unkown well",
      }))
      .filter((wellBar) => Number.isFinite(wellBar.score));
  }, [scoreBenchmark, wellBars, wells]);

  const categoryScale = useMemo(() => {
    return scaleBand<number>({
      domain: bars.map((bar) => bar.score),
      range: [0, plotWidth],
      paddingInner: 0.2,
    });
  }, [bars]);

  const valueScale = useMemo(() => {
    return scaleLinear<number>({
      domain: [max(bars.map((wellBar) => wellBar.score)) || 0, 0],
      range: [0, plotHeight],
    });
  }, [bars]);

  const percentageUom = useUOM(UtilDimensions.Percentage);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<WellbarViewmodel>({
    containerRef,
    renderContent: ({ tooltipData }) => {
      return (
        <>
          <TooltipHighlightValue>
            Score: {percentageUom.display(tooltipData?.score, { fractionDigits: 1 })}
          </TooltipHighlightValue>

          <span>{tooltipData?.name}</span>
        </>
      );
    },
  });

  const handleMouseOver = useCallback(
    (d: WellbarViewmodel) => {
      if (!d) return;

      const leftX = (categoryScale(d.score) || 0) + categoryScale.bandwidth() / 2;

      showTooltip({
        tooltipLeft: leftX,
        tooltipTop: Math.min(valueScale(d?.score ?? 0), plotHeight) + plotHeight - 35,
        tooltipData: d,
      });
    },
    [categoryScale, showTooltip, valueScale],
  );

  return (
    <Styled.Container ref={containerRef}>
      <svg width={plotWidth} height={plotHeight}>
        {bars.map((wellBar, index) => {
          const barHeight = Math.min(plotHeight - valueScale(wellBar.score), plotHeight);

          const barY = plotHeight - barHeight;
          const color = getColorByScore(wellBar.score ?? 0);
          const gradientToColor = `${color}70`;
          const gradientFromColor = `${color}`;

          return (
            <Fragment key={wellBar.id}>
              <LinearGradient
                from={gradientFromColor}
                to={gradientToColor}
                fromOpacity={1}
                toOpacity={0.9}
                id={`linear_gradient_${gradientToColor}_${wellBar.score}_${index}`}
              />

              <BarRounded
                radius={4}
                top
                x={categoryScale(wellBar.score) || 0}
                y={barY}
                onMouseOut={hideTooltip}
                onMouseOver={() => handleMouseOver(wellBar)}
                key={`${wellBar.score}-${index}`}
                width={categoryScale.bandwidth()}
                height={barHeight}
                fill={`url(#linear_gradient_${gradientToColor}_${wellBar.score}_${index}`}
              />
            </Fragment>
          );
        })}
        {tooltipElement}
      </svg>
    </Styled.Container>
  );
};
