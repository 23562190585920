import { useQueryClient } from "@tanstack/react-query";
import { LensTabStateType, UserLensTabsApi } from "apis/oag";
import { Button, Input, Select } from "atoms/Form";
import { Title } from "atoms/Typography";
import { Text } from "atoms/Typography";
import { FormGroup } from "components/General/FormGroup";
import { PDComponent } from "components/PDComponents";
import { useDashboardType } from "hooks/useDashboardType";
import { useRigsNotSuspended } from "hooks/useRigs";
import { useUserLensTabs } from "hooks/useUserLensTabs";
import { useCallback, useMemo, useRef, useState } from "react";
import React from "react";
import Modal from "react-modal";
import { apiConfig } from "utils/apiConfig";
import type { InputRef } from "utils/componentLibrary";
import { Col, Row } from "utils/componentLibrary";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

import { MultipleChoiceSelect } from "./ShareTabs/ShareTabsModal/Selector/MultipleChoiceSelect";
import { ButtonRow, RigCustomSwitch, StyledCloseIcon, StyledDepthText, StyledModalContent } from "./style";

const MODAL_MIN_WIDTH = 444;
const userLensTabsApi = new UserLensTabsApi(apiConfig);

const customStyles = {
  overlay: {
    zIndex: zIndexLayer.mars,
    backgroundColor: "rgba(32,43,48,0.6)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    borderRadius: 12,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: MODAL_MIN_WIDTH,
    height: "auto",
    overflow: "hidden",
    border: "none",
  },
};
export const TAB_NAME_MAX_LENGTH = 42;

export const AddTabModal = React.memo(({ isVisible, onCancel }: { isVisible: boolean; onCancel: () => void }) => {
  const { themeStyle, atomThemeVariant } = useCustomTheme();
  const [tabName, setTabName] = useState("");
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { dashboardType } = useDashboardType();
  const { data: lensTabsResponse } = useUserLensTabs(dashboardType);
  const [copyFromLensTab, setCopyFromLensTab] = useState<number | null>(null);
  const [selectRigs, setSelectRigs] = useState({
    on: false,
    selectedRigs: [] as number[],
  });
  const queryClient = useQueryClient();

  const { data: rigOptions, isLoading } = useRigsNotSuspended();
  const [error, setError] = useState("");

  const onModalClose = useCallback(() => {
    onCancel();
    setError("");
    setTabName("");
    setCopyFromLensTab(null);
    setSelectRigs({
      on: false,
      selectedRigs: [],
    });
  }, [onCancel]);

  const onDone = useCallback(async () => {
    if (!selectRigs) return;
    if (!tabName) {
      setError("Tab name is required");
      // maybe show a toast or something
      return;
    }

    setIsUpdating(true);
    await userLensTabsApi
      .apiUserLensTabsPost({
        lensTabCreationQueryDto: {
          dashboardType,
          name: tabName,
          copySourceTabId: !(copyFromLensTab && copyFromLensTab >= 0) ? null : copyFromLensTab,
          visibilityRigIds: selectRigs.on ? selectRigs.selectedRigs : [],
        },
      })
      .catch((e) => {
        setError(e.message);
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.USER_LENS_TABS }] });
        queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.LENS_POSITIONS }] });
        queryClient.invalidateQueries({ queryKey: [{ uid: RequestUID.allLens }] });
        onModalClose();
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }, [tabName, dashboardType, copyFromLensTab, selectRigs, queryClient, onModalClose]);

  const inputRef = useRef<InputRef>(null);
  const handleSelectionChange = useCallback(
    (e: number[]) => {
      if (
        selectRigs.on &&
        (e.length !== selectRigs.selectedRigs.length || !e.every((rigId) => selectRigs.selectedRigs.includes(rigId)))
      ) {
        setSelectRigs({ selectedRigs: e, on: true });
      }
    },
    [selectRigs],
  );

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onModalClose}
      style={{
        content: { ...customStyles.content, backgroundColor: themeStyle.colors.primary_bg },
        overlay: customStyles.overlay,
      }}
      ariaHideApp={false}
    >
      <StyledModalContent>
        <Row justify="space-between" align="middle" style={{ padding: "16px 24px" }}>
          <Col>
            <Title variant={atomThemeVariant} level={2} weight={500}>
              Create New Tab
            </Title>
          </Col>
          <StyledCloseIcon onClick={onModalClose}>
            <PDComponent.SvgIcon name="close" />
          </StyledCloseIcon>
        </Row>
        <Row>
          <FormGroup
            label={
              <Title level={4}>
                <b>Dashboard Name:</b> {dashboardType.toString()} Dashboard
              </Title>
            }
          />
        </Row>
        <hr />
        <FormGroup label="Enter Tab name" rightElement={<StyledDepthText>(REQUIRED)</StyledDepthText>}>
          <Input
            ref={inputRef}
            value={tabName}
            onChange={(e) => setTabName(e.currentTarget.value)}
            placeholder="Enter Tab Name"
            error={error}
            maxLength={TAB_NAME_MAX_LENGTH}
            onClick={() => {
              setError("");
              inputRef?.current?.focus();
            }}
          />
        </FormGroup>
        <hr />
        <FormGroup label="Copy From Existing Tab" rightElement={<StyledDepthText>(OPTIONAL)</StyledDepthText>}>
          <Select
            value={copyFromLensTab}
            onChange={(value) => {
              setCopyFromLensTab(value as number);
            }}
            placeholder="(None)"
            $fullWidth
          >
            {[
              { name: "(None)", id: -1 },
              ...lensTabsResponse.filter((tab) => tab.state === LensTabStateType.Visible),
            ].map((option) => (
              <Select.Option key={option.id} value={option.id.toString()}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </FormGroup>
        <hr />
        <FormGroup
          label="Rig Specific"
          rightElement={
            <Row gutter={[10, 0]}>
              <Col
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Text primary="description" variant="faded">
                  {selectRigs.on ? "ON" : "OFF"}
                </Text>
              </Col>
              <Col>
                <RigCustomSwitch
                  checked={selectRigs.on}
                  onChange={(checked) => {
                    setSelectRigs({ selectedRigs: [], on: checked });
                  }}
                />
              </Col>
            </Row>
          }
        >
          {selectRigs.on ? (
            <Row
              justify="space-between"
              style={{
                flex: 1,
              }}
            >
              <Col
                flex={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Title level={4}>
                  <b>Select Rig</b>
                </Title>
              </Col>

              <Col flex={3}>
                <MultipleChoiceSelect
                  noSelectAll
                  noTooltip
                  disabled={!selectRigs.on}
                  currentSelectionTitle={(() => {
                    if (selectRigs.selectedRigs.length === 0) return "(None)";
                    if (selectRigs.selectedRigs.length === 1)
                      return `${rigOptions?.byId[selectRigs.selectedRigs[0]]?.shortName} Rig`;
                    return `${selectRigs.selectedRigs.length} Rigs Selected`;
                  })()}
                  placeholder="None"
                  width={396}
                  listItems={(rigOptions?.list ?? []).map((rig) => ({
                    id: rig.id,
                    name: rig.shortName || "",
                  }))}
                  isLoading={isLoading}
                  trackingTitle="Tab"
                  onSelectionChange={handleSelectionChange}
                />
              </Col>
            </Row>
          ) : null}
        </FormGroup>
        <ButtonRow justify="end">
          <Col>
            <Button
              size="large"
              type="primary"
              loading={isUpdating}
              trackingName="Add New Tab"
              disabled={selectRigs.on ? selectRigs.selectedRigs.length === 0 : false}
              onClick={onDone}
            >
              Done
            </Button>
          </Col>
        </ButtonRow>
      </StyledModalContent>
    </Modal>
  );
});

export const useAddTabModal = () => {
  const [showAddTabModal, setShowAddTabModal] = useState(false);

  const openAddTabModal = () => {
    setShowAddTabModal(true);
  };
  const addTabModalElement = useMemo(() => {
    return showAddTabModal ? (
      <AddTabModal isVisible={showAddTabModal} onCancel={() => setShowAddTabModal(false)} />
    ) : null;
  }, [showAddTabModal]);
  return { addTabModalElement, openAddTabModal };
};
