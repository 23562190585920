/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import type { UserStatusType } from './UserStatusType';
import {
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface UserQueryDto
 */
export interface UserQueryDto {
    /**
     * 
     * @type {string}
     * @memberof UserQueryDto
     */
    search?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQueryDto
     */
    wellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {Array<UserStatusType>}
     * @memberof UserQueryDto
     */
    status?: Array<UserStatusType> | null;
    /**
     * 
     * @type {Array<UserRoleType>}
     * @memberof UserQueryDto
     */
    role?: Array<UserRoleType> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserQueryDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Date}
     * @memberof UserQueryDto
     */
    expireDateStart?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UserQueryDto
     */
    expireDateEnd?: Date | null;
}

/**
 * Check if a given object implements the UserQueryDto interface.
 */
export function instanceOfUserQueryDto(value: object): value is UserQueryDto {
    return true;
}

export function UserQueryDtoFromJSON(json: any): UserQueryDto {
    return UserQueryDtoFromJSONTyped(json, false);
}

export function UserQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'search': json['Search'] == null ? undefined : json['Search'],
        'wellIds': json['WellIds'] == null ? undefined : json['WellIds'],
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
        'status': json['Status'] == null ? undefined : ((json['Status'] as Array<any>).map(UserStatusTypeFromJSON)),
        'role': json['Role'] == null ? undefined : ((json['Role'] as Array<any>).map(UserRoleTypeFromJSON)),
        'operatorIds': json['OperatorIds'] == null ? undefined : json['OperatorIds'],
        'expireDateStart': json['ExpireDateStart'] == null ? undefined : (new Date(json['ExpireDateStart'])),
        'expireDateEnd': json['ExpireDateEnd'] == null ? undefined : (new Date(json['ExpireDateEnd'])),
    };
}

export function UserQueryDtoToJSON(value?: UserQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Search': value['search'],
        'WellIds': value['wellIds'],
        'RigIds': value['rigIds'],
        'Status': value['status'] == null ? undefined : ((value['status'] as Array<any>).map(UserStatusTypeToJSON)),
        'Role': value['role'] == null ? undefined : ((value['role'] as Array<any>).map(UserRoleTypeToJSON)),
        'OperatorIds': value['operatorIds'],
        'ExpireDateStart': value['expireDateStart'] == null ? undefined : ((value['expireDateStart'] as any).toISOString()),
        'ExpireDateEnd': value['expireDateEnd'] == null ? undefined : ((value['expireDateEnd'] as any).toISOString()),
    };
}

