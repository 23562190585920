import type { IKPIValue } from "pages/Lens/LensSummaryView";

import { SummaryBlock } from "./SummaryBlock";

export function SecondaryKpiSummary({ kpis, detailed }: { kpis: IKPIValue[]; detailed?: boolean }) {
  return (
    <>
      {kpis.map((kpi) => (
        <SummaryBlock
          key={kpi.name + kpi.main}
          title={kpi.name}
          main={kpi.main}
          secondary={kpi.secondary}
          titleLevel={detailed ? 1 : 2}
        />
      ))}
    </>
  );
}
