import { PDComponent } from "components/PDComponents";
import React from "react";
import styled from "styled-components";
import colors from "utils/colors";

const ChartContainer = styled.div`
  height: 126px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
  overflow: hidden;
`;
const LensWrapper = styled.div`
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.themeStyle.colors.alt_typography}20;
`;

const LensInfo = styled.div`
  padding: 20px 24px;
  background: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};

  height: 138px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const LensTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;
const LensDescription = styled.div`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  margin-bottom: 16px;
`;
const PillContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: -3px -5px;
  margin-bottom: 24px;
`;
const KpiTypePill = styled.div`
  height: 20px;
  padding: 4px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.themeStyle.colors.alt_typography}10;
  font-size: 12px;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  line-height: 1;
  margin: 3px 5px;
`;
const AddAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  font-size: 16px;
  padding-left: 24px;
  padding-right: 18px;
  letter-spacing: -0.2px;
  color: ${colors.well_color};
  line-height: 1;

  transition: color 0.1s ease-in-out;
`;
const ActionsContainer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  background-color: ${colors.neutral_white};
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_chart_bg};

  width: 100%;
  height: 57px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;

  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: ${colors.well_color};

    ${AddAction} {
      color: white;
    }
  }
`;

export function LensItem({
  name,
  description,
  chartElement,
  options,
  onAdd,
  tabName,
  id,
}: {
  id: number;
  name: string;
  description: string;
  chartElement: React.ReactNode;
  options?: string[];
  onAdd: () => void;
  tabName: string;
}) {
  return (
    <LensWrapper>
      <ChartContainer>{chartElement}</ChartContainer>
      <LensInfo>
        <LensTitle>{name}</LensTitle>
        <LensDescription>{description}</LensDescription>
        {options ? (
          <PillContainer>
            {options?.map((option) => (
              <KpiTypePill key={`${id}_${option}_${Math.trunc(Math.random() * 1000 + 100)}`}>{option}</KpiTypePill>
            ))}
          </PillContainer>
        ) : null}
      </LensInfo>
      <ActionsContainer>
        <AddAction role="button" onClick={onAdd}>
          Add to {tabName} <PDComponent.SvgIcon name="plusCircleOutline" />
        </AddAction>
      </ActionsContainer>
    </LensWrapper>
  );
}
