/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StandKpiCommentDto,
  StandKpiCommentGroupDto,
  WellIdListDto,
} from '../models/index';
import {
    StandKpiCommentDtoFromJSON,
    StandKpiCommentDtoToJSON,
    StandKpiCommentGroupDtoFromJSON,
    StandKpiCommentGroupDtoToJSON,
    WellIdListDtoFromJSON,
    WellIdListDtoToJSON,
} from '../models/index';

export interface ApiStandKpiCommentsIdDeleteRequest {
    id: number;
}

export interface ApiStandKpiCommentsIdPutRequest {
    id: number;
    standKpiCommentDto?: StandKpiCommentDto;
}

export interface ApiStandKpiCommentsIdRestorePutRequest {
    id: number;
}

export interface ApiStandKpiCommentsLensLensIdPutRequest {
    lensId: number;
    wellIdListDto?: WellIdListDto;
}

export interface ApiStandKpiCommentsPostRequest {
    standKpiCommentDto?: StandKpiCommentDto;
}

/**
 * 
 */
export class StandKpiCommentsApi extends runtime.BaseAPI {

    /**
     */
    async apiStandKpiCommentsIdDeleteRaw(requestParameters: ApiStandKpiCommentsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiStandKpiCommentsIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StandKpiComments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiStandKpiCommentsIdDelete(requestParameters: ApiStandKpiCommentsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiStandKpiCommentsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStandKpiCommentsIdPutRaw(requestParameters: ApiStandKpiCommentsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandKpiCommentDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiStandKpiCommentsIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StandKpiComments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StandKpiCommentDtoToJSON(requestParameters['standKpiCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandKpiCommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStandKpiCommentsIdPut(requestParameters: ApiStandKpiCommentsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandKpiCommentDto> {
        const response = await this.apiStandKpiCommentsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStandKpiCommentsIdRestorePutRaw(requestParameters: ApiStandKpiCommentsIdRestorePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiStandKpiCommentsIdRestorePut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StandKpiComments/{id}/Restore`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiStandKpiCommentsIdRestorePut(requestParameters: ApiStandKpiCommentsIdRestorePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiStandKpiCommentsIdRestorePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStandKpiCommentsLensLensIdPutRaw(requestParameters: ApiStandKpiCommentsLensLensIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StandKpiCommentGroupDto>>> {
        if (requestParameters['lensId'] == null) {
            throw new runtime.RequiredError(
                'lensId',
                'Required parameter "lensId" was null or undefined when calling apiStandKpiCommentsLensLensIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StandKpiComments/Lens/{lensId}`.replace(`{${"lensId"}}`, encodeURIComponent(String(requestParameters['lensId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellIdListDtoToJSON(requestParameters['wellIdListDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StandKpiCommentGroupDtoFromJSON));
    }

    /**
     */
    async apiStandKpiCommentsLensLensIdPut(requestParameters: ApiStandKpiCommentsLensLensIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StandKpiCommentGroupDto>> {
        const response = await this.apiStandKpiCommentsLensLensIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStandKpiCommentsPostRaw(requestParameters: ApiStandKpiCommentsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandKpiCommentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/StandKpiComments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StandKpiCommentDtoToJSON(requestParameters['standKpiCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandKpiCommentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStandKpiCommentsPost(requestParameters: ApiStandKpiCommentsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandKpiCommentDto> {
        const response = await this.apiStandKpiCommentsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
