import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { BarGroup, BarRounded } from "@visx/shape";
import type { SingleStandKpiComparisonInfoDto } from "apis/oag";
import { ResultDataState, VisualAidType } from "apis/oag";
import { AverageLabel, AverageLine } from "components/Lenses/common/AverageLine";
import { Chart } from "components/Lenses/common/Chart";
import { Label } from "components/Lenses/common/Label";
import { MedianLabel, MedianLine } from "components/Lenses/common/MedianLine";
import { StyledOperationCountContainer } from "components/Lenses/common/OperationCount";
import OperationCount from "components/Lenses/common/OperationCount";
import TargetLine from "components/Lenses/common/TargetLine";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { TooltipVisualAidInfo, useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { useOutlierThreshold } from "components/Lenses/common/useOutlierThreshold";
import type { SingleKpiChartComparisonProps } from "components/Lenses/ContainerLens/SingleKpi/interfaces";
import { getMinMax, getSVGNormalizedValue } from "components/Lenses/utils";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import type { CategoryTargetInfo } from "hooks/charting/useTargetSegments";
import { useTargetSegments } from "hooks/charting/useTargetSegments";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { shade } from "polished";
import React, { useCallback, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import colors from "utils/colors";
import { OPERATION_COUNT_HEIGHT } from "utils/constants";
import { useColors } from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";

export const ShiftKey: { [key: string]: number } = {
  Day: 1,
  Night: 2,
};

// Tooltip
interface IToolTipData {
  wellId: number;
  data: SingleStandKpiComparisonInfoDto & { isDayShift: boolean };
}

const keys = Object.values(ShiftKey);

export function SingleComparisonWellShiftChart({ detailed, valueUOM, data, lens }: SingleKpiChartComparisonProps) {
  const { getColor } = useColors();

  const { data: wellShortInfo } = useWellShortInfoSuspended();

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight: containerHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const chartHeight = getSVGNormalizedValue(containerHeight - (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0));
  const plotHeight = getSVGNormalizedValue(chartHeight - (detailed ? 136 : 36));
  const plotWidth = getSVGNormalizedValue(chartWidth - (detailed ? 76 : 44) - 10);

  interface IShiftBarGroupDataPoint {
    wellId: number;
    dayIsOutlier: boolean;
    nightIsOutlier: boolean;
    dayOperationCount?: number;
    nightOperationCount?: number;
    dayTarget?: number | null;
    nightTarget?: number | null;
    1?: number; // Day
    2?: number; // Night
  }

  const totalAverage = useMemo(() => data.summaryByKpi?.allAverage, [data.summaryByKpi?.allAverage]);

  const transformedData = useMemo<IShiftBarGroupDataPoint[]>(() => {
    return (data.comparisons ?? [])
      .filter((d) => d.dataState === ResultDataState.Valid)
      .map((e) => {
        const day = e.detailsByDisplayOption?.find((o) => o.id === 1);
        const night = e.detailsByDisplayOption?.find((o) => o.id === 2);
        return {
          wellId: e.wellId,
          dayIsOutlier: !!day?.isOutlier,
          dayOperationCount: day?.operationCount,
          nightIsOutlier: !!night?.isOutlier,
          nightOperationCount: night?.operationCount,
          dayTarget: day?.targetAverage,
          nightTarget: night?.targetAverage,
          1: day?.average,
          2: night?.average,
        };
      });
  }, [data.comparisons]);

  const transformedSegmentData = useMemo<CategoryTargetInfo[]>(() => {
    return transformedData.map((well) => ({
      ...well,
      id: well.wellId,
      list: [
        {
          id: ShiftKey.Day,
          targetValue: well.dayTarget,
        },
        {
          id: ShiftKey.Night,
          targetValue: well.nightTarget,
        },
      ],
    }));
  }, [transformedData]);

  // Axis X0
  const wellScale = useMemo(() => {
    return scaleBand({
      domain: transformedData.map((e) => e.wellId),
      // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
      range: [0, plotWidth],
      paddingInner: 0.2,
      paddingOuter: 0.1,
    });
  }, [plotWidth, transformedData]);

  // Axis X1
  const shiftScale = useMemo(() => {
    return scaleBand({
      domain: keys,
      paddingInner: 0.15,
      range: [0, wellScale.bandwidth()],
    });
  }, [wellScale]);

  const totalValues = useMemo(
    () =>
      transformedData?.flatMap((stand) => {
        if (!stand.dayIsOutlier) {
          return [stand[1] || 0];
        }
        return [stand[2] || 0];
      }),
    [transformedData],
  );

  const computedTargetSegments = useTargetSegments(transformedSegmentData, wellScale, shiftScale);

  const { outlierThreshold, gradientDefinition, gradientFill } = useOutlierThreshold({
    values: totalValues,
    enabled: lens?.showsOutliers,
    selectedVisualAids: lens?.selectedVisualAids,
    targetSegments: computedTargetSegments,
  });

  // Y Axis
  const valueScale = useMemo(() => {
    return scaleLinear<number>({
      domain: getMinMax(
        totalValues,
        computedTargetSegments,
        outlierThreshold,
        data.summaryByKpi?.median,
        lens?.selectedVisualAids,
        lens?.outlierFlaggingType,
        lens?.isManualYaxis,
        lens?.yaxisEnd,
        lens?.yaxisStart,
      ),
      range: [plotHeight, 0],
      clamp: true,
    });
  }, [
    totalValues,
    computedTargetSegments,
    outlierThreshold,
    data.summaryByKpi?.median,
    lens?.selectedVisualAids,
    lens?.outlierFlaggingType,
    lens?.isManualYaxis,
    lens?.yaxisEnd,
    lens?.yaxisStart,
    plotHeight,
  ]);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const valuesDomain = useMemo(() => valueScale.domain(), [valueScale]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<
    IToolTipData & {
      totalAverage: number;
    }
  >({
    containerRef,
    renderContent: ({ tooltipData }) => {
      return (
        <>
          <TooltipHighlightValue>
            {valueUOM.displayWithAutoDecimals(valuesDomain, tooltipData?.data?.average)}
          </TooltipHighlightValue>
          <TooltipVisualAidInfo
            selectedVisualAids={lens?.selectedVisualAids}
            display={(value) => valueUOM.displayWithAutoDecimals(valuesDomain, value)}
            targetValue={tooltipData?.data.targetAverage}
            averageValue={tooltipData?.totalAverage}
            median={data.summaryByKpi?.median}
          />
          {lens.showOperationCount && tooltipData?.data?.operationCount ? (
            <TooltipHighlightValue>Operation count: {tooltipData?.data.operationCount}</TooltipHighlightValue>
          ) : null}
          <span>{tooltipData?.data?.isDayShift ? "Day" : "Night"}</span>

          <span>{wellShortInfo?.byId[tooltipData?.wellId || -1]?.name ?? "Unnamed Well"}</span>
          {tooltipData?.data.isOutlier ? (
            <TooltipHighlightValue fontWeight="normal">OUTLIER</TooltipHighlightValue>
          ) : null}
        </>
      );
    },
  });

  const handleMouseOver = useCallback(
    (barData: {
      data: SingleStandKpiComparisonInfoDto;
      wellId: number;
      top: number;
      left: number;
      isDayShift: boolean;
    }) => {
      showTooltip({
        tooltipLeft: barData.left + shiftScale.bandwidth() / 2,
        tooltipTop: barData.top,
        tooltipData: {
          wellId: barData.wellId,
          totalAverage: totalAverage || 0,
          data: {
            ...barData.data,
            isDayShift: barData.isDayShift,
          },
        },
      });
    },
    [showTooltip, shiftScale, totalAverage],
  );

  // Bar coloring
  function fill(wellId: number, isNight: boolean): string {
    const color = getColor({ key: (wellId ?? "").toString() });
    const transparencyPct = isNight ? 0.5 : 0;
    return color ? shade(transparencyPct, color) : shade(transparencyPct, colors.off_state);
  }

  // Starting initialization only with div to calculate plot width & height
  if (Number.isNaN(plotWidth) || Number.isNaN(plotHeight)) {
    return (
      <div
        ref={containerRef}
        style={{
          padding: 0,
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "visible",
        }}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      style={{
        padding: 0,
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "visible",
      }}
    >
      <StyledOperationCountContainer visible={lens.showOperationCount} />
      <svg width={chartWidth} height={chartHeight} style={{ overflow: "visible", userSelect: "none" }}>
        {gradientDefinition}
        <Chart
          detailed={detailed}
          isManual={lens?.isManualYaxis}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          plotWidth={plotWidth}
          plotHeight={plotHeight}
          valueScale={valueScale}
          categoryScale={wellScale}
          valueUOM={valueUOM}
          bottomTickComponent={(e) => {
            return [
              <text {...e} key={`day-${e.id}`} x={e.x - wellScale.bandwidth() / 2 + shiftScale.bandwidth() / 2}>
                {shiftScale.bandwidth() > 27 ? "Day" : "D"}
              </text>,
              <text
                {...e}
                key={`night-${e.id}`}
                y={e.y}
                x={e.x + wellScale.bandwidth() / 2 - shiftScale.bandwidth() / 2}
              >
                {shiftScale.bandwidth() > 27 ? "Night" : "N"}
              </text>,
            ];
          }}
        >
          <BarGroup
            keys={keys}
            data={transformedData}
            x0={(d) => d.wellId}
            x0Scale={wellScale}
            x1Scale={shiftScale}
            yScale={valueScale}
            color={(e) => ""}
            height={plotHeight}
          >
            {(barGroups) => {
              return barGroups.map((barGroup) => (
                <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                  {barGroup.bars.map((bar, index) => {
                    const barHeight = Math.min(plotHeight - valueScale(bar.value ?? 0), plotHeight);
                    const barY = plotHeight - barHeight;
                    const wellId = transformedData[barGroup.index].wellId;
                    const barData = data.comparisons
                      ?.find((comparison) => comparison.wellId === wellId)
                      ?.detailsByDisplayOption?.find((i) => i.id === bar.key);
                    if (!barData) return null;
                    const value = valueUOM.displayWithAutoDecimals(valuesDomain, barData.average, { unit: "" });
                    return (
                      <React.Fragment key={`bar-group-${barGroup.index}-${bar.index}-${barGroup.x0}-bar-grp-${value}`}>
                        <rect
                          onMouseOver={() => {
                            handleMouseOver({
                              data: barData,
                              isDayShift: bar.key === ShiftKey.Day,
                              wellId,
                              top: barY,
                              left: barGroup.x0 + bar.x,
                            });
                          }}
                          onMouseOut={hideTooltip}
                          key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                          x={bar.x}
                          y={barY}
                          width={bar.width}
                          height={barHeight}
                          fill={
                            barData.isOutlier
                              ? themeColors.outlier_bars_transparent
                              : fill(wellId, bar.key === ShiftKey.Night)
                          }
                        />
                        {barData.isOutlier ? (
                          <BarRounded
                            radius={4}
                            top
                            x={bar.x}
                            y={barY - 1}
                            width={bar.width + 2}
                            onMouseOver={() => {
                              handleMouseOver({
                                data: barData,
                                wellId,
                                isDayShift: bar.key === ShiftKey.Day,
                                top: barY,
                                left: barGroup.x0 + bar.x,
                              });
                            }}
                            onMouseOut={hideTooltip}
                            height={barHeight}
                            fill={gradientFill}
                          />
                        ) : null}
                        <Label
                          barHeight={barHeight}
                          barX={bar.x}
                          barY={barY + (detailed ? 0 : 10)}
                          columnWidth={bar.width}
                          detailed={detailed}
                          index={index}
                          topLabel={barY !== plotHeight}
                          value={value}
                          label={lens?.label}
                        />
                        {lens.showOperationCount ? (
                          <OperationCount
                            x={bar.x}
                            width={bar.width}
                            index={index}
                            detailed={detailed}
                            value={barData?.operationCount}
                          />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </Group>
              ));
            }}
          </BarGroup>

          <AverageLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(VisualAidType.Average)}
            y={valueScale(totalAverage || 0)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          <MedianLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(VisualAidType.Median)}
            y={valueScale(data.summaryByKpi?.median)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
            computedTargetSegments.map(({ target, lineStart, lineEnd, showTag }, index) => {
              if (!target) return null;
              return (
                <TargetLine
                  key={`${index}-${target}-${lineStart}-${lineEnd}`}
                  start={lineStart}
                  end={lineEnd}
                  y={valueScale(target)}
                  label={valueUOM.displayWithAutoDecimals(valuesDomain, target, { unit: "" })}
                  showTag={!!showTag}
                  detailed={detailed}
                />
              );
            })}
        </Chart>
      </svg>

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Average) && (
        <AverageLabel
          style={{
            top: `${valueScale(totalAverage || 0) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)}px`,
          }}
        >
          Average: {valueUOM.displayWithAutoDecimals(valuesDomain, totalAverage || 0)}
        </AverageLabel>
      )}

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Median) && data.summaryByKpi?.median ? (
        <MedianLabel
          isDetailed={detailed}
          style={{
            top: `${
              valueScale(data.summaryByKpi?.median) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)
            }px`,
          }}
        >
          Median: {valueUOM.displayWithAutoDecimals(valuesDomain, data.summaryByKpi?.median)}
        </MedianLabel>
      ) : null}

      {tooltipElement}
    </div>
  );
}
