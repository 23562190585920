import React from "react";
import styled from "styled-components";

export type SizeType = "small" | "middle" | "large" | undefined;
export type SpaceSize = SizeType | number;

// No `stretch` since many components do not support that.
type SpaceAlign = "start" | "end" | "center" | "baseline";

const spaceSize = {
  small: 8,
  middle: 16,
  large: 24,
};

function getNumberSize(size: SpaceSize) {
  return typeof size === "string" ? spaceSize[size] : size || 0;
}

export interface SpaceProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: SpaceSize | [SpaceSize, SpaceSize];
  direction?: "horizontal" | "vertical";
  align?: SpaceAlign;
  wrap?: boolean;
}

export const PdSpace = styled.div<{
  align: SpaceAlign;
  $direction: "horizontal" | "vertical";
  $wrap: boolean;
  horizontalSize: number;
  verticalSize: number;
}>`
  display: inline-flex;

  flex-direction: ${({ $direction }) => ($direction === "vertical" ? "column" : "row")};
  align-items: ${({ align }) => align || undefined};
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "nowrap")};
  column-gap: ${({ $direction, horizontalSize }) => ($direction === "vertical" ? 0 : horizontalSize)}px;
  row-gap: ${({ $direction, verticalSize }) => ($direction === "vertical" ? verticalSize : 0)}px;
`;

export const PdSpaceItem = styled.div`
  :empty {
    display: none;
  }
`;

function toArray(children: React.ReactNode): React.ReactNode[] {
  if (children === undefined || children === null) {
    return [];
  }
  const allChildren = React.Children.map(children, (child) => {
    if (child === undefined || child === null) {
      return;
    }
    if (
      // Checks if the child is a react fragment
      Symbol("react.fragment").toString() === (child as React.ReactElement)?.type?.toString() &&
      (child as React.ReactElement)?.props
    ) {
      return toArray((child as React.ReactElement).props.children as React.ReactNode);
    }
    return child as React.ReactNode;
  });

  if (Array.isArray(allChildren)) {
    return allChildren;
  }
  return [];
}

export const Space = React.forwardRef<HTMLDivElement, SpaceProps>(
  ({ size = "small", align, direction = "horizontal", wrap = false, children, ...others }, ref) => {
    const [horizontalSize, verticalSize] = React.useMemo(
      () => ((Array.isArray(size) ? size : [size, size]) as [SpaceSize, SpaceSize]).map((item) => getNumberSize(item)),
      [size],
    );
    const defaultAlign = direction === "horizontal" ? "center" : "start";
    const childNodes = toArray(children);

    const nodes = childNodes.map((child, i) => {
      const key = (child as React.ReactElement)?.key || `pd-space-item-${i}`;
      return <PdSpaceItem key={key}>{child}</PdSpaceItem>;
    });
    return (
      <PdSpace
        ref={ref}
        align={align || defaultAlign}
        $wrap={wrap}
        $direction={direction}
        horizontalSize={horizontalSize}
        verticalSize={verticalSize}
        {...others}
      >
        {nodes}
      </PdSpace>
    );
  },
);

Space.displayName = "Space";
