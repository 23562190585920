/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WedgeSummaryDto
 */
export interface WedgeSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryDto
     */
    allAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryDto
     */
    focalAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryDto
     */
    comparisonAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryDto
     */
    comparisonAverageRelativeDiff?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryDto
     */
    focalDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryDto
     */
    comparisonDistribution?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WedgeSummaryDto
     */
    comparisonDistributionRelativeDiff?: number | null;
}

/**
 * Check if a given object implements the WedgeSummaryDto interface.
 */
export function instanceOfWedgeSummaryDto(value: object): value is WedgeSummaryDto {
    return true;
}

export function WedgeSummaryDtoFromJSON(json: any): WedgeSummaryDto {
    return WedgeSummaryDtoFromJSONTyped(json, false);
}

export function WedgeSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WedgeSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'allAverage': json['AllAverage'] == null ? undefined : json['AllAverage'],
        'focalAverage': json['FocalAverage'] == null ? undefined : json['FocalAverage'],
        'comparisonAverage': json['ComparisonAverage'] == null ? undefined : json['ComparisonAverage'],
        'comparisonAverageRelativeDiff': json['ComparisonAverageRelativeDiff'] == null ? undefined : json['ComparisonAverageRelativeDiff'],
        'focalDistribution': json['FocalDistribution'] == null ? undefined : json['FocalDistribution'],
        'comparisonDistribution': json['ComparisonDistribution'] == null ? undefined : json['ComparisonDistribution'],
        'comparisonDistributionRelativeDiff': json['ComparisonDistributionRelativeDiff'] == null ? undefined : json['ComparisonDistributionRelativeDiff'],
    };
}

export function WedgeSummaryDtoToJSON(value?: WedgeSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'AllAverage': value['allAverage'],
        'FocalAverage': value['focalAverage'],
        'ComparisonAverage': value['comparisonAverage'],
        'ComparisonAverageRelativeDiff': value['comparisonAverageRelativeDiff'],
        'FocalDistribution': value['focalDistribution'],
        'ComparisonDistribution': value['comparisonDistribution'],
        'ComparisonDistributionRelativeDiff': value['comparisonDistributionRelativeDiff'],
    };
}

