import type { StandKpiType } from "apis/oag";
import { cardKpiTypesMap } from "pages/FleetPerformance/components/utils";
import * as Styled from "pages/RigScoreCard/LeftPane/Styled";

export const KpiInfoPopupContent = ({ kpiType }: { kpiType: StandKpiType }) => {
  switch (kpiType) {
    case cardKpiTypesMap.LocationRigMoveTime:
      return (
        <Styled.KpiInfoWrapper>
          Time from Rig Release to Spud of the next well
          <br />
          <br />
          <Styled.KpiInfoDetails>
            • <b>Rig Release</b> starts immediately after Nipple Down on previous well, not Tour Sheet Rig Release{" "}
            <br />• <b>Spud</b> starts at 1st occurrence of Drilling on tour sheet/nipple up (if surface pre-set), not
            at the end of Code 1
          </Styled.KpiInfoDetails>
        </Styled.KpiInfoWrapper>
      );
    case cardKpiTypesMap.WalkSpudTime:
      return (
        <Styled.KpiInfoWrapper>
          Case 1: When batch drilling surface sections
          <br />
          <Styled.KpiInfoDetails>
            • Starts after surface casing is set/cut OR first mention of tearing down to walk. Ends at spud of next
            surface hole.
          </Styled.KpiInfoDetails>
          <br />
          <br />
          Case 2: When not batch drilling
          <br />
          <Styled.KpiInfoDetails>
            • Starts after nipple down is complete. Ends at spud of next surface hole.
          </Styled.KpiInfoDetails>
        </Styled.KpiInfoWrapper>
      );
    case cardKpiTypesMap.WalkTime:
      return (
        <Styled.KpiInfoWrapper>
          Case 1: When walking from a completed surface section to DO a following section on the next well
          <br />
          <Styled.KpiInfoDetails>
            • Starts after the surface casing is set/cut OR first mention of tearing down to move. Ends at the start of
            the nipple up on the next well.
          </Styled.KpiInfoDetails>
          <br />
          <br />
          Case 2: When walking from a completed intermediate or production section to DO a following section on the next
          well
          <br />
          <Styled.KpiInfoDetails>
            • Starts after nipple down is complete. Ends at the start of the nipple up on the next well.
          </Styled.KpiInfoDetails>
        </Styled.KpiInfoWrapper>
      );
    case cardKpiTypesMap.NippleTimeSurface:
      return (
        <Styled.KpiInfoWrapper>
          Time for completion of Nipple Up Operations
          <br />
          <br />
          <Styled.KpiInfoDetails>
            • Starts at time of the safety meeting prior to starting BOP operations
            <br />• Ends when all nipple up BOP components assembled and ready to test
          </Styled.KpiInfoDetails>
        </Styled.KpiInfoWrapper>
      );
    case cardKpiTypesMap.PressureTestTimeSurface:
      return (
        <Styled.KpiInfoWrapper>
          Time for completion of BOP Testing
          <br />
          <br />
          <Styled.KpiInfoDetails>
            • Starts at time of the safety meeting prior to beginning to rig-up testers and test BOP components
            <br />• Ends after BOP testing and function testing of BOP components, includes rigging down
          </Styled.KpiInfoDetails>
        </Styled.KpiInfoWrapper>
      );
    case cardKpiTypesMap.NippleTimeIntermediate:
      return (
        <Styled.KpiInfoWrapper>
          Time for completion of Nipple Up Operations
          <br />
          <br />
          <Styled.KpiInfoDetails>
            • Starts at time of the safety meeting prior to starting BOP operations
            <br />• Ends when all nipple up BOP components assembled and ready to test
          </Styled.KpiInfoDetails>
        </Styled.KpiInfoWrapper>
      );
    case cardKpiTypesMap.PressureTestTimeIntermediate:
      return (
        <Styled.KpiInfoWrapper>
          Time for completion of BOP Testing
          <br />
          <br />
          <Styled.KpiInfoDetails>
            • Starts at time of the safety meeting prior to beginning to rig-up testers and test BOP components
            <br />• Ends after BOP testing and function testing of BOP components, includes rigging down
          </Styled.KpiInfoDetails>
        </Styled.KpiInfoWrapper>
      );
    case cardKpiTypesMap.Downtime:
      return (
        <Styled.KpiInfoWrapper>
          Sum of all approved Code 8 time divided by the total operating time of the well
        </Styled.KpiInfoWrapper>
      );
    case cardKpiTypesMap.SlipCutTime:
      return <Styled.KpiInfoWrapper>Average time of Slip and Cut operations</Styled.KpiInfoWrapper>;
    default:
      return undefined;
  }
};
