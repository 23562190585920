import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { DrillingProductivityType, StandKpiGroup, StandKpiType, UserLensDto } from "apis/oag";
import { DashboardType, GenericUserLensesApi, LensTemplateType, TorqueAndDragType } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

type TLensKpi = number | string;
type UserLensWithKpiTypes = UserLensDto & {
  kpiGroupId?: StandKpiGroup;
  kpiTypeId?: StandKpiType;
  breakdownId?: TLensKpi;
  drillingProductivityType?: DrillingProductivityType;
  torqueAndDragType?: TorqueAndDragType;
};

export function addKpiTypeToLens({
  lensKpi,
  lens,
  lensTemplateType,
}: {
  lensKpi: TLensKpi;
  lens: UserLensDto;
  lensTemplateType: LensTemplateType;
}): UserLensWithKpiTypes {
  switch (lensTemplateType) {
    case LensTemplateType.StackedKpis:
      return { ...lens, kpiGroupId: lensKpi as StandKpiGroup };
    case LensTemplateType.SingleKpi:
      return { ...lens, kpiTypeId: lensKpi as StandKpiType };
    case LensTemplateType.PivotKpiGroup:
      return { ...lens, kpiGroupId: lensKpi as StandKpiGroup };
    case LensTemplateType.PivotKpiType:
      return { ...lens, kpiTypeId: lensKpi as StandKpiType };
    case LensTemplateType.Wedge:
      return { ...lens, breakdownId: lensKpi };
    case LensTemplateType.DrillingProductivity:
      return { ...lens, drillingProductivityType: lensKpi as DrillingProductivityType };
    case LensTemplateType.TorqueAndDrag:
      return {
        ...lens,
        torqueAndDragType:
          TorqueAndDragType[Object.keys(TorqueAndDragType)[lensKpi as number] as keyof typeof TorqueAndDragType],
      };
    default:
      return lens;
  }
}

export function getKpiTypeByLens({
  lens,
  lensTemplateType,
}: {
  lens: UserLensWithKpiTypes;
  lensTemplateType: LensTemplateType;
}) {
  switch (lensTemplateType) {
    case LensTemplateType.StackedKpis:
      return lens.kpiGroupId;
    case LensTemplateType.SingleKpi:
      return lens.kpiTypeId;
    case LensTemplateType.PivotKpiGroup:
      return lens.kpiGroupId;
    case LensTemplateType.PivotKpiType:
      return lens.kpiTypeId;
    case LensTemplateType.Wedge:
      return lens.breakdownId;
    case LensTemplateType.DrillingProductivity:
      return lens.drillingProductivityType;
    case LensTemplateType.TorqueAndDrag:
      return lens.torqueAndDragType;
    default:
      return lens.kpiTypeId;
  }
}

const api = new GenericUserLensesApi(apiConfig);

export const UserLensesQueryKey: PDUniqueQueryKey = { uid: RequestUID.allLens };

export type UserLensesResponse<T extends UserLensDto> = {
  byId: Record<number, T>;
  byTab: Record<number, T[]>;
  list: T[];
};

// Aggregating by tab id to retrieve selected lenses much easier
export async function fetchUserLenses<T extends UserLensDto>(): Promise<UserLensesResponse<T>> {
  const data = await api.apiGenericUserLensesPut({
    dashboardQueryDto: {
      dashboardTypes: [
        DashboardType.System,
        DashboardType.Well,
        DashboardType.Rig,
        DashboardType.EverGreen,
        DashboardType.RigFleetPerformance,
        DashboardType.RigScorecard,
      ],
    },
  });

  const allLenses = [
    ...(data?.batteryStateUserLenses ?? []),
    ...(data?.dieselNatGasRatioStatsUserLenses ?? []),
    ...(data?.drillingProductivityUserLenses ?? []),
    ...(data?.fuelConsumptionByGeneratorUserLenses ?? []),
    ...(data?.fuelFlowRateUserLenses ?? []),
    ...(data?.fuelTankVolumeUserLenses ?? []),
    ...(data?.generatorLoadProfileUserLenses ?? []),
    ...(data?.generatorStatsUserLenses ?? []),
    ...(data?.generatorStatusBarUserLenses ?? []),
    ...(data?.ghgEmissionsUserLenses ?? []),
    ...(data?.kpiGroupUserLenses ?? []),
    ...(data?.kpiTypeUserLenses ?? []),
    ...(data?.naturalGasStateUserLenses ?? []),
    ...(data?.parameterByDepthUserLenses ?? []),
    ...(data?.parameterHeatmapUserLenses ?? []),
    ...(data?.pivotKpiGroupUserLenses ?? []),
    ...(data?.pivotKpiTypeUserLenses ?? []),
    ...(data?.powerConsumptionByOperationUserLenses ?? []),
    ...(data?.powerLoadDistributionUserLenses ?? []),
    ...(data?.powerLoadEfficiencyUserLenses ?? []),
    ...(data?.rigFleetPerformanceCardUserLenses ?? []),
    ...(data?.rigPowerUserLenses ?? []),
    ...(data?.rigScorecardUserLenses ?? []),
    ...(data?.statCardUserLenses ?? []),
    ...(data?.torqueAndDragUserLenses ?? []),
    ...(data?.wedgeUserLenses ?? []),
    ...(data?.wellDrillingSummaryUserLenses ?? []),
    ...(data?.tagBottomFingerprintUserLenses ?? []),
    ...(data?.stickSlipByDepthUserLenses ?? []),
    ...(data?.stickSlipByTimeUserLenses ?? []),
    // ! lens boilerplate
  ] as T[];

  const byTab = allLenses.reduce<Record<number, T[]>>((acc, item) => {
    const tabId = item.lensTabId;
    if (!tabId) return acc;
    if (!acc[tabId]) {
      acc[tabId] = [];
    }

    acc[tabId].push(item);

    return acc;
  }, {});

  const byId = allLenses.reduce<Record<number, T>>((acc, item) => {
    const tabId = item.id;
    if (!tabId) return acc;
    acc[tabId] = item;
    return acc;
  }, {});

  return { byTab, byId, list: allLenses };
}

export type UserLensesQueryResult<T extends UserLensDto = UserLensDto> = {
  byTab: Record<number, T[]>;
  byId: Record<number, T>;
  list: T[];
};

export function useUserLenses<T extends UserLensDto = UserLensDto>(
  options?: Omit<UseQueryOptions<UserLensesQueryResult<T>>, "queryKey" | "queryFn">,
) {
  return useSuspenseQuery<UserLensesQueryResult<T>>({
    queryKey: [UserLensesQueryKey],
    queryFn: () => fetchUserLenses<T>(),
    ...options,
  });
}
