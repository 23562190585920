import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

interface HoveredDataValue {
  hoveredDataRank: number | null;
  setHoveredDataRank: Dispatch<SetStateAction<number | null>>;
}

export const HoveredDataContext = createContext<HoveredDataValue | undefined>(undefined);

export const useHoveredDataContext = () => {
  const context = useContext(HoveredDataContext);
  if (context === undefined) {
    throw new Error("useHoveredDataContext must be used within a HoveredDataContextProvider");
  }
  return context;
};

export const HoveredDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [hoveredDataRank, setHoveredDataRank] = useState<number | null>(null);

  return (
    <HoveredDataContext.Provider value={{ hoveredDataRank, setHoveredDataRank }}>
      {children}
    </HoveredDataContext.Provider>
  );
};
