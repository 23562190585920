/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { SurveyPointDto } from './SurveyPointDto';
import {
    SurveyPointDtoFromJSON,
    SurveyPointDtoFromJSONTyped,
    SurveyPointDtoToJSON,
} from './SurveyPointDto';

/**
 * 
 * @export
 * @interface SurveyDto
 */
export interface SurveyDto {
    /**
     * 
     * @type {Array<SurveyPointDto>}
     * @memberof SurveyDto
     */
    points?: Array<SurveyPointDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof SurveyDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the SurveyDto interface.
 */
export function instanceOfSurveyDto(value: object): value is SurveyDto {
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function SurveyDtoFromJSON(json: any): SurveyDto {
    return SurveyDtoFromJSONTyped(json, false);
}

export function SurveyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'points': json['Points'] == null ? undefined : ((json['Points'] as Array<any>).map(SurveyPointDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function SurveyDtoToJSON(value?: SurveyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Points': value['points'] == null ? undefined : ((value['points'] as Array<any>).map(SurveyPointDtoToJSON)),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

