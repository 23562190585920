import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Row } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

export const FormGroupContainer = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export const FormLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const FormattedRow = styled(Row)<{ $hasBorder?: boolean }>`
  margin-bottom: ${({ $hasBorder }) => $hasBorder ? "0px" : "12px"};
`;


export const FiltersBackground = styled.div<{
  numRows: number; hideSecondCol: boolean; numCols: number
  gridArea?: string;
}>`
  padding: 28px;
  background-color: ${({ theme: { themeStyle } }) => themeStyle.colors.tertiary_bg};
  display: grid;
  ${({ gridArea, numRows, numCols, hideSecondCol }) => gridArea ? css`
    grid-template-areas: ${gridArea};
  `: css`
  height: ${numRows * 215}px;
  grid-template-columns: repeat(${numCols - 1}, 1fr) ${hideSecondCol ? null : "1fr"};
  `
  }
  gap: 18px;
`;

export const Popup = styled(Row)`
  background-color: ${colors.white};
  z-index: ${zIndexLayer.shuttle};
  flex-direction: column;
`;

export const FilterControls = styled(Row)`
  background-color: ${({ theme: { themeStyle } }) => themeStyle.colors.off_secondary_bg};
  border-bottom: solid 1px ${({ theme: { themeStyle } }) => themeStyle.colors.soft_accent};
  padding: 12px 28px;
`;