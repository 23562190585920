/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface BarSliceDto
 */
export interface BarSliceDto {
    /**
     * 
     * @type {StandKpiType}
     * @memberof BarSliceDto
     */
    kpiTypeId: StandKpiType;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    distribution: number;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof BarSliceDto
     */
    minDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof BarSliceDto
     */
    maxDate: DateDto;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    minDepth: number;
    /**
     * 
     * @type {number}
     * @memberof BarSliceDto
     */
    maxDepth: number;
}



/**
 * Check if a given object implements the BarSliceDto interface.
 */
export function instanceOfBarSliceDto(value: object): value is BarSliceDto {
    if (!('kpiTypeId' in value) || value['kpiTypeId'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('distribution' in value) || value['distribution'] === undefined) return false;
    if (!('minDate' in value) || value['minDate'] === undefined) return false;
    if (!('maxDate' in value) || value['maxDate'] === undefined) return false;
    if (!('minDepth' in value) || value['minDepth'] === undefined) return false;
    if (!('maxDepth' in value) || value['maxDepth'] === undefined) return false;
    return true;
}

export function BarSliceDtoFromJSON(json: any): BarSliceDto {
    return BarSliceDtoFromJSONTyped(json, false);
}

export function BarSliceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BarSliceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'kpiTypeId': StandKpiTypeFromJSON(json['KpiTypeId']),
        'position': json['Position'],
        'value': json['Value'],
        'distribution': json['Distribution'],
        'targetValue': json['TargetValue'] == null ? undefined : json['TargetValue'],
        'minDate': DateDtoFromJSON(json['MinDate']),
        'maxDate': DateDtoFromJSON(json['MaxDate']),
        'minDepth': json['MinDepth'],
        'maxDepth': json['MaxDepth'],
    };
}

export function BarSliceDtoToJSON(value?: BarSliceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'KpiTypeId': StandKpiTypeToJSON(value['kpiTypeId']),
        'Position': value['position'],
        'Value': value['value'],
        'Distribution': value['distribution'],
        'TargetValue': value['targetValue'],
        'MinDate': DateDtoToJSON(value['minDate']),
        'MaxDate': DateDtoToJSON(value['maxDate']),
        'MinDepth': value['minDepth'],
        'MaxDepth': value['maxDepth'],
    };
}

