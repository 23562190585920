/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PowerLoadDistributionFactSetDto,
  PowerLoadDistributionUserLensDto,
  RigPowerQueryDto,
} from '../models/index';
import {
    PowerLoadDistributionFactSetDtoFromJSON,
    PowerLoadDistributionFactSetDtoToJSON,
    PowerLoadDistributionUserLensDtoFromJSON,
    PowerLoadDistributionUserLensDtoToJSON,
    RigPowerQueryDtoFromJSON,
    RigPowerQueryDtoToJSON,
} from '../models/index';

export interface ApiPowerLoadDistributionUserLensesIdFactsPutRequest {
    id: number;
    rigPowerQueryDto?: RigPowerQueryDto;
}

export interface ApiPowerLoadDistributionUserLensesIdPutRequest {
    id: number;
    powerLoadDistributionUserLensDto?: PowerLoadDistributionUserLensDto;
}

export interface ApiPowerLoadDistributionUserLensesPostRequest {
    powerLoadDistributionUserLensDto?: PowerLoadDistributionUserLensDto;
}

/**
 * 
 */
export class PowerLoadDistributionUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiPowerLoadDistributionUserLensesIdFactsPutRaw(requestParameters: ApiPowerLoadDistributionUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PowerLoadDistributionFactSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiPowerLoadDistributionUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PowerLoadDistributionUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigPowerQueryDtoToJSON(requestParameters['rigPowerQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerLoadDistributionFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPowerLoadDistributionUserLensesIdFactsPut(requestParameters: ApiPowerLoadDistributionUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PowerLoadDistributionFactSetDto> {
        const response = await this.apiPowerLoadDistributionUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPowerLoadDistributionUserLensesIdPutRaw(requestParameters: ApiPowerLoadDistributionUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PowerLoadDistributionUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiPowerLoadDistributionUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PowerLoadDistributionUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PowerLoadDistributionUserLensDtoToJSON(requestParameters['powerLoadDistributionUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerLoadDistributionUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPowerLoadDistributionUserLensesIdPut(requestParameters: ApiPowerLoadDistributionUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PowerLoadDistributionUserLensDto> {
        const response = await this.apiPowerLoadDistributionUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPowerLoadDistributionUserLensesPostRaw(requestParameters: ApiPowerLoadDistributionUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PowerLoadDistributionUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PowerLoadDistributionUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PowerLoadDistributionUserLensDtoToJSON(requestParameters['powerLoadDistributionUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerLoadDistributionUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPowerLoadDistributionUserLensesPost(requestParameters: ApiPowerLoadDistributionUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PowerLoadDistributionUserLensDto> {
        const response = await this.apiPowerLoadDistributionUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
