import type { WellDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import type { RealTimeDataEnum } from "components/RealTimeIndicator";
import { TvDChart } from "components/TvDChart/TvDChart";
import type { ICombinedEvents } from "components/TvDChart/types";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useDashboardType } from "hooks/useDashboardType";
import { useFetchTimelineEvents } from "hooks/useFetchTimelineEvents";
import { useFuturePlanProjections } from "hooks/useFuturePlanProjections";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useTimelineEventsList } from "hooks/useTimelineEventsList";
import { useTvds } from "hooks/useTvds";
import { useWellDetails } from "hooks/useWellDetails";
import { isNil } from "lodash";
import type { ITimelineInfo } from "pages/WellDashboard/types";
import { useCallback, useEffect } from "react";
import type { IndicatorsState, TimelineStates } from "reducers/stateReducer";
import { initialState } from "reducers/stateReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import type { IDisplayOptionsType, IZoomData } from "reducers/types";
import { CurvesEnum, initialDisplayOptions } from "reducers/types";
import { useUOM } from "utils/format";
import { useColors } from "utils/useColors";

export const WellOverviewTvD = ({
  isReport,
  timelineOverride,
  setTimelineOverride,
}: {
  isReport: boolean;
  timelineOverride: ITimelineInfo | null;
  setTimelineOverride: React.Dispatch<ITimelineInfo | null> | null;
}) => {
  const dispatch = useAppDispatch();
  useFetchTimelineEvents();
  const clickedEvent = useAppSelector((state) => state.timeline.selectedTimelineEvent);
  const wellId = useSelectedWell();
  const actionEnabledCB = useCallback(
    (action?: boolean) => {
      dispatch({
        type: "SET_ACTION_ENABLED",
        payload: action || false,
      });
    },
    [dispatch],
  );

  useEffect(() => {
    actionEnabledCB(false);
  }, [actionEnabledCB, wellId]);
  const setTimelineState = (state: TimelineStates) => {
    dispatch({
      type: "SET_TIMELINE_STATE",
      payload: state,
    });
  };

  const setHoveredSeries = (seriesId?: number) => {
    if (isNil(seriesId)) return;
    dispatch({
      type: "SET_HOVERED_SERIES_TVD",
      payload: seriesId,
    });
  };
  const setPlanId = useCallback(
    (planId: number) => {
      dispatch({
        type: "SET_PLAN_ID",
        payload: planId,
      });
    },
    [dispatch],
  );
  const setSelectedSeries = (seriesId: number) => {
    dispatch({
      type: "SET_SELECTED_SERIES",
      payload: seriesId,
    });
  };
  const setWellDetails = useCallback(
    (selectedWellDetails: WellDto) => {
      dispatch({
        type: "SET_WELL_DETAILS",
        payload: selectedWellDetails,
      });
    },
    [dispatch],
  );
  // TODO: maybe combine those in a single action?
  const setHoveredEventTimeline = (event: ICombinedEvents | null) => {
    dispatch({
      type: "SET_EVENT_HOVERED",
      payload: event,
    });
  };

  const { data: selectedWellDetails, isSuccess } = useWellDetails(wellId);
  useEffect(() => {
    if (isSuccess) {
      setPlanId(selectedWellDetails?.planDetails?.id ?? 0);
      setWellDetails(selectedWellDetails);
    }
  }, [isSuccess, selectedWellDetails, setPlanId, setWellDetails]);
  const { data } = useTvds({
    options: { refetchOnMount: false },
  });
  const futurePlanEvents = useFuturePlanProjections();
  const { actualEvents, planEvents } = useAppSelector((state) => state.timeline.events);
  const { planTimelineEventsList, actualTimelineEventsList } = useTimelineEventsList({ actualEvents, planEvents });
  const selectedIndicators: Set<IndicatorsState> = useAppSelector(
    (state) => state.state.selectedIndicators || initialState.selectedIndicators,
  );
  const actionEnabled = useAppSelector((state) => state.timeline.actionEnabled);
  const editedEvent = useAppSelector((state) => state.state.editedEvent);
  const wellDetails = useAppSelector((state) => state.dataState.wellDetails);
  const selectedSeries = useAppSelector((state) => state.state.selectedSeries);
  const hoveredSeriesTvd = useAppSelector((state) => state.state.hoveredSeriesTvd);
  const hoveredEvent = useAppSelector((state) => state.state.hoveredEvent);
  const timeline_state = useAppSelector((state) => state.state.timeline_state);
  const depthUOM = useUOM(DimensionType.Metres);
  const depthTransformer = useUOM(DimensionType.Metres);
  const [displayOptions] = useStateQuery<IDisplayOptionsType>(
    URL_STATE_PARAM.DISPLAY_OPTIONS_WELL,
    initialDisplayOptions,
  );

  const [zoomState, setZoomState] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WELL, initialZoomData, [
    URL_STATE_PARAM.ZOOM_WIDGET,
  ]);
  const [depthNormalized] = useStateQuery<boolean>(URL_STATE_PARAM.WELL_NORMALIZED_DEPTH, false);
  const [offsetSelection] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELL, []);
  const [realTimeDataState, setRealtimeData] = useStateQuery<RealTimeDataEnum | undefined>(
    URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    undefined,
    [URL_STATE_PARAM.REALTIME_DATA_WIDGET],
  );
  const [realTimeDataStateWidget, setRealtimeDataWidget] = useStateQuery<RealTimeDataEnum | undefined>(
    URL_STATE_PARAM.REALTIME_DATA_WIDGET,
    undefined,
  );
  const { setColor } = useColors();
  const { isEvergreen } = useDashboardType();
  const planActive =
    !isEvergreen && (displayOptions.curves === null || displayOptions.curves.includes(CurvesEnum.PLAN));
  const actualActive = displayOptions.curves === null || displayOptions.curves.includes(CurvesEnum.HOLE_DEPTH);

  const offsetWellsActive =
    !isEvergreen && (displayOptions.curves === null || displayOptions.curves.includes(CurvesEnum.OFFSET_WELLS));

  return (
    <TvDChart
      callbacks={{
        actionEnabledCB,
        setColor,
        setHoveredEventTimeline,
        setHoveredSeries,
        setRealtimeData,
        setRealtimeDataWidget,
        setSelectedSeries,
        setTimelineOverride,
        setTimelineState,
        setZoomState,
      }}
      isReport={isReport}
      data={data}
      state={{
        actionEnabled,
        actualTimelineEventsList,
        depthNormalized,
        depthTransformer,
        depthUOM,
        displayOptions,
        editedEvent,
        hoveredEvent,
        hoveredSeriesTvd,
        isLoading: !data,
        futurePlanEvents: futurePlanEvents,
        offsetSelection,
        planTimelineEventsList,
        realTimeDataState,
        realTimeDataStateWidget,
        selectedIndicators,
        selectedSeries,
        selectedWellDetails,
        timeline_state,
        timelineOverride,
        wellDetails,
        wellId,
        zoomState,
        planActive,
        actualActive,
        offsetWellsActive,
      }}
    />
  );
};
