/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LensTemplateType } from './LensTemplateType';
import {
    LensTemplateTypeFromJSON,
    LensTemplateTypeFromJSONTyped,
    LensTemplateTypeToJSON,
} from './LensTemplateType';
import type { StatCardDataType } from './StatCardDataType';
import {
    StatCardDataTypeFromJSON,
    StatCardDataTypeFromJSONTyped,
    StatCardDataTypeToJSON,
} from './StatCardDataType';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';

/**
 * 
 * @export
 * @interface StatCardLensTemplateDto
 */
export interface StatCardLensTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof StatCardLensTemplateDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StatCardLensTemplateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatCardLensTemplateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatCardLensTemplateDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {LensTemplateType}
     * @memberof StatCardLensTemplateDto
     */
    type: LensTemplateType;
    /**
     * 
     * @type {DashboardType}
     * @memberof StatCardLensTemplateDto
     */
    targetDashboardType: DashboardType;
    /**
     * 
     * @type {number}
     * @memberof StatCardLensTemplateDto
     */
    nbCondensedDataPoints: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatCardLensTemplateDto
     */
    useBitDepth: boolean;
    /**
     * 
     * @type {number}
     * @memberof StatCardLensTemplateDto
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatCardLensTemplateDto
     */
    hasGeneratorSelection: boolean;
    /**
     * 
     * @type {StatCardDataType}
     * @memberof StatCardLensTemplateDto
     */
    dataSource: StatCardDataType;
}



/**
 * Check if a given object implements the StatCardLensTemplateDto interface.
 */
export function instanceOfStatCardLensTemplateDto(value: object): value is StatCardLensTemplateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('targetDashboardType' in value) || value['targetDashboardType'] === undefined) return false;
    if (!('nbCondensedDataPoints' in value) || value['nbCondensedDataPoints'] === undefined) return false;
    if (!('useBitDepth' in value) || value['useBitDepth'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('hasGeneratorSelection' in value) || value['hasGeneratorSelection'] === undefined) return false;
    if (!('dataSource' in value) || value['dataSource'] === undefined) return false;
    return true;
}

export function StatCardLensTemplateDtoFromJSON(json: any): StatCardLensTemplateDto {
    return StatCardLensTemplateDtoFromJSONTyped(json, false);
}

export function StatCardLensTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatCardLensTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'type': LensTemplateTypeFromJSON(json['Type']),
        'targetDashboardType': DashboardTypeFromJSON(json['TargetDashboardType']),
        'nbCondensedDataPoints': json['NbCondensedDataPoints'],
        'useBitDepth': json['UseBitDepth'],
        'position': json['Position'],
        'hasGeneratorSelection': json['HasGeneratorSelection'],
        'dataSource': StatCardDataTypeFromJSON(json['DataSource']),
    };
}

export function StatCardLensTemplateDtoToJSON(value?: StatCardLensTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'Description': value['description'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Type': LensTemplateTypeToJSON(value['type']),
        'TargetDashboardType': DashboardTypeToJSON(value['targetDashboardType']),
        'NbCondensedDataPoints': value['nbCondensedDataPoints'],
        'UseBitDepth': value['useBitDepth'],
        'Position': value['position'],
        'HasGeneratorSelection': value['hasGeneratorSelection'],
        'DataSource': StatCardDataTypeToJSON(value['dataSource']),
    };
}

