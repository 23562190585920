import type { LabeledValue } from "antd/lib/select";
import { Button, Select } from "atoms/Form";
import { Backdrop } from "components/General/Backdrop";
import { MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import { PDComponent } from "components/PDComponents";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { StyledWrapper } from "components/WellDashboard/ChartControls/atoms/common";
import dayjs from "dayjs";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useDashboardType } from "hooks/useDashboardType";
import { useOverviewZoomData } from "hooks/useOverviewZoomData";
import { useWellDetails } from "hooks/useWellDetails";
import type { FC } from "react";
import React, { Suspense, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { useAppSelector } from "reducers/store";
import type { IDisplayOptionsType } from "reducers/types";
import type { IZoomData } from "reducers/types";
import {
  CurvesEnum,
  initialDisplayOptions,
  initialDisplayOptionsEvergreen,
  IUnitSystem,
  IZoomType,
} from "reducers/types";
import { ftInM } from "utils/common";
import { Col, Row, Space, Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

import { DataSelectorByType } from "./DataSelectorByType";
import type { IZoom } from "./interfaces";
import {
  StyledButtonsRow,
  StyledDateSelectorContainer,
  StyledRadioButton,
  StyledRadioGroup,
  StyledSelect,
} from "./StyledComponents";
import { predefinedTimeRangeList } from "./utils";
import { customPredefinedRange } from "./utils";
import ZoomSvg from "./ZoomSvg";

const customStyles = {
  overlay: {
    zIndex: zIndexLayer.phobos,
    backgroundColor: "transparent",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    borderRadius: 6,
    bottom: "auto",
    padding: "0px!important",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Zoom: FC<IZoom> = ({
  report,
  visible,
  onCancel: externalCancelAction,
  zoomData,
  setZoomData,
  resetZoomData,
  availableActions,
  ignoreTvD,
  lens,
}) => {
  const [localZoom, setLocalZoom] = useState<IZoomData>(zoomData);
  const localZoomType: IZoomType = useMemo(() => localZoom.type ?? IZoomType.TIME, [localZoom]);

  const { isEvergreen } = useDashboardType();
  const currentUOM = useAppSelector((state) => state.global.unit);
  const { tvdSeries, comparisonTvdSeries } = useOverviewZoomData({ report, lens });
  const [displayOptions] = useStateQuery<IDisplayOptionsType>(
    URL_STATE_PARAM.DISPLAY_OPTIONS_WELL,
    isEvergreen ? initialDisplayOptionsEvergreen : initialDisplayOptions,
  );
  const planActive = displayOptions.curves === null || displayOptions.curves.includes(CurvesEnum.PLAN);
  const offsetActive = displayOptions.curves === null || displayOptions.curves.includes(CurvesEnum.OFFSET_WELLS);
  const stateReducer = useAppSelector((state) => state.state);
  const { data: selectedWellInfo } = useWellDetails(stateReducer.selectedWell || -1);

  const isZoomByDateAvailable = useMemo(() => {
    if (
      availableActions.date === MatrixAvailability.NOT_AVAILABLE ||
      availableActions.time === MatrixAvailability.NOT_AVAILABLE
    )
      return false;
    if (offsetActive && comparisonTvdSeries?.length !== 0) return false;
    if (!selectedWellInfo?.planDetails) return true;
    if (lens) return true;
    if (planActive) return false;
    return true;
  }, [
    availableActions.date,
    availableActions.time,
    comparisonTvdSeries?.length,
    lens,
    offsetActive,
    planActive,
    selectedWellInfo?.planDetails,
  ]);
  const [timeAtDynamicSelected, setTimeAtDynamicSelected] = useState(+Date.now());

  useEffect(() => {
    if (visible)
      setLocalZoom({
        ...zoomData,
        type:
          !isZoomByDateAvailable && (zoomData?.type === IZoomType.DATE || zoomData?.type === IZoomType.DYNAMIC_WINDOW)
            ? IZoomType.TIME
            : zoomData?.type,
      });
  }, [isZoomByDateAvailable, visible, zoomData]);

  const onCancel = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    return externalCancelAction(event);
  };

  const uomRatio = useMemo(() => (currentUOM === IUnitSystem.METRIC ? 1 : ftInM), [currentUOM]);
  const disabled = useMemo(() => {
    if (!localZoom) return true;
    if (localZoom.type !== IZoomType.DEPTH) {
      if (localZoom.ts_end !== null && localZoom.ts_end - localZoom.ts_start < 0) {
        return true;
      }
    } else if (localZoom.depth_end !== null && localZoom.depth_end - localZoom.depth_start < 0) {
      return true;
    }
    return false;
  }, [localZoom]);

  const resetData = () => {
    resetZoomData();
    onCancel();
  };

  const onDone = () => {
    let updatedZoom: IZoomData;

    switch (localZoomType) {
      case IZoomType.DEPTH:
        updatedZoom = {
          ...initialZoomData,
          depth_start: localZoom.depth_start,
          depth_end: localZoom.depth_end,
          type: IZoomType.DEPTH,
        };
        break;

      default:
        updatedZoom = {
          ...initialZoomData,
          ...localZoom,
          depth_start: initialZoomData.depth_start, // Overwriting depth to prevent double filtering, even if the API allows it
          depth_end: initialZoomData.depth_end,
        };
    }

    setLocalZoom(updatedZoom);
    setZoomData(updatedZoom, true);
  };

  const currentTypeIsDateOrDynamic = useMemo(
    () => localZoomType === IZoomType.DYNAMIC_WINDOW || localZoomType === IZoomType.DATE,
    [localZoomType],
  );

  const currentPredefinedRange = useMemo(() => {
    let item: LabeledValue = customPredefinedRange;

    if (currentTypeIsDateOrDynamic) {
      const matchedStartingDate = predefinedTimeRangeList.find(
        (predefined) => predefined.value === -localZoom.ts_start,
      );

      if (localZoom.ts_end === null && matchedStartingDate) {
        item = matchedStartingDate;
      }
    }
    return item;
  }, [currentTypeIsDateOrDynamic, localZoom.ts_end, localZoom.ts_start]);

  useLayoutEffect(() => {
    if (
      currentPredefinedRange !== customPredefinedRange &&
      localZoomType !== IZoomType.DYNAMIC_WINDOW &&
      localZoomType !== IZoomType.DATE
    ) {
      setLocalZoom((prev) => ({ ...prev, type: IZoomType.DYNAMIC_WINDOW }));
    }
  }, [currentPredefinedRange, localZoomType]);

  const handleOnPredefinedOption = useCallback(
    (value: LabeledValue["value"]) => {
      const selectedOption = predefinedTimeRangeList.find((opt) => opt.value === value);
      const durationSeconds = selectedOption?.value ? -selectedOption.value : 0;
      // [negativeNumber, undefined] convention brings data from durationS to latest

      setTimeAtDynamicSelected(dayjs().add(durationSeconds, "second").toDate().getTime());
      setLocalZoom(
        durationSeconds
          ? {
              ...localZoom,
              ts_start: durationSeconds,
              ts_end: null,
              date_start: null,
              date_end: null,
              type: IZoomType.DYNAMIC_WINDOW,
            }
          : { ...initialZoomData },
      );
    },
    [localZoom],
  );

  const { themeStyle } = useCustomTheme();

  return (
    <>
      {visible ? <Backdrop /> : null}
      <Modal
        isOpen={visible}
        onRequestClose={onCancel}
        style={{
          content: { ...customStyles.content, backgroundColor: themeStyle.colors.primary_bg },
          overlay: customStyles.overlay,
        }}
        ariaHideApp={false}
      >
        <Suspense fallback={<div />}>
          <ZoomSvg
            series={tvdSeries?.series || []}
            ignoreTvD={!!ignoreTvD}
            lens={!!lens}
            localZoom={localZoom}
            report={!!report}
            setLocalZoom={setLocalZoom}
          />
        </Suspense>
        <StyledWrapper
          style={{
            padding: 10,
          }}
        >
          <Row gutter={6} wrap={false}>
            <Col
              flex="0 auto"
              style={{
                display:
                  availableActions.time === MatrixAvailability.NOT_SUPPORTED ||
                  availableActions.depth === MatrixAvailability.NOT_SUPPORTED
                    ? "none"
                    : undefined,
              }}
            >
              <StyledRadioGroup
                defaultValue={localZoom?.type}
                value={localZoom?.type}
                optionType="button"
                buttonStyle="solid"
                onChange={(e) => {
                  const { value } = e.target;
                  return setLocalZoom((prev) => ({ ...prev, type: value, internal_zoom: false }));
                }}
              >
                <StyledRadioButton
                  value={IZoomType.TIME}
                  disabled={availableActions.time === MatrixAvailability.NOT_AVAILABLE}
                >
                  <PDComponent.SvgIcon name="time" />
                </StyledRadioButton>
                <Tooltip title={isZoomByDateAvailable ? "" : "Not available when selecting multiple wells or plan."}>
                  <StyledRadioButton
                    value={localZoomType === IZoomType.DYNAMIC_WINDOW ? IZoomType.DYNAMIC_WINDOW : IZoomType.DATE}
                    disabled={!isZoomByDateAvailable}
                  >
                    <PDComponent.SvgIcon name="calendar" />
                  </StyledRadioButton>
                </Tooltip>
                )
                <StyledRadioButton
                  value={IZoomType.DEPTH}
                  disabled={availableActions.depth === MatrixAvailability.NOT_AVAILABLE}
                >
                  <PDComponent.SvgIcon name="depth" />
                </StyledRadioButton>
              </StyledRadioGroup>
            </Col>
            <Col flex="1 auto">
              <StyledDateSelectorContainer>
                {currentTypeIsDateOrDynamic ? (
                  <StyledSelect
                    placeholder="Custom time"
                    $fullWidth
                    value={currentPredefinedRange.label as string}
                    onChange={(value: unknown) => handleOnPredefinedOption(value as LabeledValue["value"])}
                  >
                    {predefinedTimeRangeList.map((tRange) => (
                      <Select.Option key={tRange.key} value={tRange.value}>
                        {tRange.label}
                      </Select.Option>
                    ))}
                  </StyledSelect>
                ) : null}

                <DataSelectorByType
                  seriesData={tvdSeries?.series || []}
                  disabled={disabled}
                  localZoom={localZoom}
                  setLocalZoom={setLocalZoom}
                  uomRatio={uomRatio}
                  timeAtDynamicSelected={timeAtDynamicSelected}
                />
              </StyledDateSelectorContainer>
            </Col>
          </Row>
          <StyledButtonsRow>
            <Space>
              <Button trackingName="Zoom - Reset Data" type="default" onClick={() => resetData()}>
                Reset
              </Button>
              <Tooltip title={disabled ? "Zoom couldn't be captured due to the small distance selected." : ""}>
                <Button
                  trackingName="Zoom - Apply"
                  htmlType="button"
                  type="primary"
                  disabled={disabled}
                  onClick={onDone}
                >
                  Apply
                </Button>
              </Tooltip>
            </Space>
          </StyledButtonsRow>
        </StyledWrapper>
      </Modal>
    </>
  );
};

export default Zoom;
