import { Button } from "atoms/Form";
import styled from "styled-components";
import colors from "utils/colors";

export const StyledButton = styled(Button)`
  &:active {
    background: ${colors.actions_bg} !important;
    color: ${colors.gray} !important;
    border-color: ${colors.light_gray};
  }
  width: 175px;
  justify-content: flex-start;
  display: flex;
  padding: 0px;
  padding-left: 15px;
`;

export const Arrow = styled.i<{ up?: boolean }>`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(${(props) => (props.up ? -135 : 45)}deg);
  -webkit-transform: rotate(${(props) => (props.up ? -135 : 45)}deg);
`;

export const StyledDropdownContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;