import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { PlanTimelineDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDWellPlanTimelineQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { useSelectedWell } from "./useSelectedWell";

const wells = new WellsApi(apiConfig);

export function useWellPlanTimeline(options?: UseQueryOptions<PlanTimelineDto>) {
  const wellDataID = useSelectedWell();

  const WellPlanTimelineQueryKey: PDWellPlanTimelineQueryKey = {
    uid: RequestUID.wellPlanTimeline,
    type: PDQueryType.WELL_PLAN_TIMELINE,
    wellDataID,
  };

  return useQuery<PlanTimelineDto>({
    queryKey: [WellPlanTimelineQueryKey],
    queryFn: () =>
      wells.apiWellsIdPlanTimelineGet({ id: wellDataID }).catch(() => ({
        sections: [],
        phases: [],
        events: [],
      })),
    enabled: wellDataID !== null,
    ...options,
  });
}
