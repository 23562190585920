import type { InputRef } from "antd";
import { Input } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Col, Row } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

export const NoScrollCol = styled(Col)`
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ListSearch: React.FC<{
  placeholder: string;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
}> = (props) => {
  const { searchTerm, setSearchTerm, placeholder } = props;
  const { themeStyle } = useCustomTheme();
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    inputRef?.current?.focus({ preventScroll: true });
  });

  return (
    <Row gutter={[0, 0]}>
      <Col
        span={24}
        style={{ background: themeStyle.colors.secondary_bg, color: themeStyle.colors.primary_typography }}
      >
        <Input
          value={searchTerm}
          placeholder={placeholder}
          prefix={<PDComponent.SvgIcon name="search" />}
          onChange={(e) => setSearchTerm(e.target.value)}
          bordered={false}
          ref={inputRef}
          style={{
            margin: "3px 0",
            background: themeStyle.colors.alt_secondary_bg,
            color: themeStyle.colors.primary_typography,
          }}
        />
      </Col>
      <NoScrollCol span={24} style={{ background: themeStyle.colors.tertiary_bg }}>
        {props.children}
      </NoScrollCol>
    </Row>
  );
};
