/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationCategoryType } from './OperationCategoryType';
import {
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface RigSummaryDto
 */
export interface RigSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof RigSummaryDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RigSummaryDto
     */
    externalId: number;
    /**
     * 
     * @type {string}
     * @memberof RigSummaryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RigSummaryDto
     */
    shortName: string;
    /**
     * 
     * @type {number}
     * @memberof RigSummaryDto
     */
    contractorRigNumber: number;
    /**
     * 
     * @type {string}
     * @memberof RigSummaryDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof RigSummaryDto
     */
    _class: string;
    /**
     * 
     * @type {number}
     * @memberof RigSummaryDto
     */
    countryId: number;
    /**
     * 
     * @type {string}
     * @memberof RigSummaryDto
     */
    operatingCenter: string;
    /**
     * 
     * @type {number}
     * @memberof RigSummaryDto
     */
    horsePower: number;
    /**
     * 
     * @type {boolean}
     * @memberof RigSummaryDto
     */
    isAlpha: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RigSummaryDto
     */
    isActive: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof RigSummaryDto
     */
    lastFactUpdatedAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof RigSummaryDto
     */
    lastActiveFactAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof RigSummaryDto
     */
    operatorId: number;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof RigSummaryDto
     */
    operationCategory: OperationCategoryType;
}



/**
 * Check if a given object implements the RigSummaryDto interface.
 */
export function instanceOfRigSummaryDto(value: object): value is RigSummaryDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('externalId' in value) || value['externalId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('shortName' in value) || value['shortName'] === undefined) return false;
    if (!('contractorRigNumber' in value) || value['contractorRigNumber'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('_class' in value) || value['_class'] === undefined) return false;
    if (!('countryId' in value) || value['countryId'] === undefined) return false;
    if (!('operatingCenter' in value) || value['operatingCenter'] === undefined) return false;
    if (!('horsePower' in value) || value['horsePower'] === undefined) return false;
    if (!('isAlpha' in value) || value['isAlpha'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('lastFactUpdatedAt' in value) || value['lastFactUpdatedAt'] === undefined) return false;
    if (!('lastActiveFactAt' in value) || value['lastActiveFactAt'] === undefined) return false;
    if (!('operatorId' in value) || value['operatorId'] === undefined) return false;
    if (!('operationCategory' in value) || value['operationCategory'] === undefined) return false;
    return true;
}

export function RigSummaryDtoFromJSON(json: any): RigSummaryDto {
    return RigSummaryDtoFromJSONTyped(json, false);
}

export function RigSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': json['ExternalId'],
        'name': json['Name'],
        'shortName': json['ShortName'],
        'contractorRigNumber': json['ContractorRigNumber'],
        'type': json['Type'],
        '_class': json['Class'],
        'countryId': json['CountryId'],
        'operatingCenter': json['OperatingCenter'],
        'horsePower': json['HorsePower'],
        'isAlpha': json['IsAlpha'],
        'isActive': json['IsActive'],
        'lastFactUpdatedAt': DateDtoFromJSON(json['LastFactUpdatedAt']),
        'lastActiveFactAt': DateDtoFromJSON(json['LastActiveFactAt']),
        'operatorId': json['OperatorId'],
        'operationCategory': OperationCategoryTypeFromJSON(json['OperationCategory']),
    };
}

export function RigSummaryDtoToJSON(value?: RigSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'ExternalId': value['externalId'],
        'Name': value['name'],
        'ShortName': value['shortName'],
        'ContractorRigNumber': value['contractorRigNumber'],
        'Type': value['type'],
        'Class': value['_class'],
        'CountryId': value['countryId'],
        'OperatingCenter': value['operatingCenter'],
        'HorsePower': value['horsePower'],
        'IsAlpha': value['isAlpha'],
        'IsActive': value['isActive'],
        'LastFactUpdatedAt': DateDtoToJSON(value['lastFactUpdatedAt']),
        'LastActiveFactAt': DateDtoToJSON(value['lastActiveFactAt']),
        'OperatorId': value['operatorId'],
        'OperationCategory': OperationCategoryTypeToJSON(value['operationCategory']),
    };
}

