/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrillingTaskSummary
 */
export interface DrillingTaskSummary {
    /**
     * 
     * @type {number}
     * @memberof DrillingTaskSummary
     */
    standWithTargetsCount: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingTaskSummary
     */
    standBelowTargetsCount: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingTaskSummary
     */
    standWithCommentCount: number;
    /**
     * 
     * @type {number}
     * @memberof DrillingTaskSummary
     */
    standRequiringCommentCount: number;
}

/**
 * Check if a given object implements the DrillingTaskSummary interface.
 */
export function instanceOfDrillingTaskSummary(value: object): value is DrillingTaskSummary {
    if (!('standWithTargetsCount' in value) || value['standWithTargetsCount'] === undefined) return false;
    if (!('standBelowTargetsCount' in value) || value['standBelowTargetsCount'] === undefined) return false;
    if (!('standWithCommentCount' in value) || value['standWithCommentCount'] === undefined) return false;
    if (!('standRequiringCommentCount' in value) || value['standRequiringCommentCount'] === undefined) return false;
    return true;
}

export function DrillingTaskSummaryFromJSON(json: any): DrillingTaskSummary {
    return DrillingTaskSummaryFromJSONTyped(json, false);
}

export function DrillingTaskSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrillingTaskSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'standWithTargetsCount': json['StandWithTargetsCount'],
        'standBelowTargetsCount': json['StandBelowTargetsCount'],
        'standWithCommentCount': json['StandWithCommentCount'],
        'standRequiringCommentCount': json['StandRequiringCommentCount'],
    };
}

export function DrillingTaskSummaryToJSON(value?: DrillingTaskSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StandWithTargetsCount': value['standWithTargetsCount'],
        'StandBelowTargetsCount': value['standBelowTargetsCount'],
        'StandWithCommentCount': value['standWithCommentCount'],
        'StandRequiringCommentCount': value['standRequiringCommentCount'],
    };
}

