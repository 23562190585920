import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useIsFetching } from "@tanstack/react-query";
import type { ParameterByDepthTrackDetailsDto, StickSlipByDepthUserLensTrackItemDto, UserLensDto } from "apis/oag";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { DETAIL_BAR_HEIGHT, GRID_WIDTH_QUARTER, HEIGHT_GRID_UNIT } from "components/Lenses/constants";
import { getChartWidthStickSlip } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Chart/utils";
import { BottomRowWithAxis } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/BottomRowWithAxis";
import { useTrackChangeOperations } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackChangeOperations";
import { useTrackUnits } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTrackUnits";
import { useTransformedData } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useTransformedData";
import { trackUnitTypesStickSlip } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/utils/trackUnitTypes";
import { useAvailableTrackIds } from "components/Lenses/ContainerLens/StickSlipByDepth/Parts/hooks/useAvailableTrackIds";
import { useTrackAddStickSlip } from "components/Lenses/ContainerLens/StickSlipByDepth/Parts/hooks/useTrackAddStickSlip";
import { LensHeader } from "components/Lenses/ContainerLens/StickSlipByDepth/Parts/LensHeader/LensHeader";
import {
  ZTorqueTrack,
  zTorqueTrackId,
} from "components/Lenses/ContainerLens/StickSlipByDepth/Parts/ZTorqueTrack/ZTorqueTrack";
import type { StickSlipByDepthKpiProps, ZtrackIntervalsWithWellId } from "components/Lenses/interfaces";
import { LensGridContext } from "components/Lenses/utils";
import { compressZTrackRanges } from "components/Lenses/utils";
import { Loader } from "components/Loader";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useLensSize } from "hooks/useLensSize";
import { useTracks } from "hooks/useTracks";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { IUnitSystem } from "reducers/types";
import { RequestUID } from "utils/queryNamespaces";
import { ZTData } from "utils/wellplan/utils";

import { StickSlipByDepthItem } from "./ParameterByDepthItem";
import { LensContainer, ScrollContainer } from "./style";

export const StickSlipByDepthChart = ({
  graphKey,
  isLoading,
  data,
  focalWellColor,
  detailed,
  lens,
  selectedTrackItems = [],
  setSelectedTrackItems,
  onLensUpdated,
}: StickSlipByDepthKpiProps) => {
  const { crtLayout, bp } = useContext(LensGridContext);
  const { width: containerWidth, height: containerHeight, ref: containerRef } = useResizeDetector<HTMLDivElement>();
  const { data: tracks } = useTracks();
  const [highlightedInterval, setHighlightedInterval] = useState<ZtrackIntervalsWithWellId | null>(null);
  const tracksCount = selectedTrackItems.length - (lens.showZTorque ? 0 : 1);

  const [maxChartHeight, setMaxChartHeight] = useState<number | null>(null);
  const [showGraph, setShowGraph] = useState(true);

  const isFetching = useIsFetching({
    queryKey: [{ uid: RequestUID.stickSlipByDepthFacts, lensId: lens.id }],
    exact: false,
  });

  const [scrollState, setScrollState] = useState<ScrollbarRange>({ startX: 0, endX: 0 });
  const [pointerPosition, setPointerPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [isTooltipEnabled, setTooltipEnabled] = useState(false);
  const [isPointerInsideChart, setPointerInsideChart] = useState(false);
  const [containerGridWidth, containerGridHeight] = useLensSize(lens.id);
  const sensors = useSensors(useSensor(PointerSensor));
  const chartWidth = getChartWidthStickSlip(detailed, containerWidth ?? 0);
  const stringIds = useMemo(() => selectedTrackItems.map((t) => t.trackId?.toString() ?? "-1"), [selectedTrackItems]);
  const availableTrackIds = useAvailableTrackIds(selectedTrackItems);

  const { selectedUnits, selectedUom } = useTrackUnits({
    trackUnitTypes: trackUnitTypesStickSlip,
    tracks,
    selectedTrackItems,
  });

  const { depthScale, flatTracks, tracksById } = useTransformedData({
    selectedTrackItems,
    lens,
    scrollState,
    chartWidth,
    data,
  });

  const [offsetSelection] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);

  const variant = useMemo(() => {
    return !detailed && offsetSelection.length > 2 && tracksCount > 2 ? "large" : "regular";
  }, [detailed, offsetSelection.length, tracksCount]);

  useEffect(() => {
    if (detailed) return;

    const numTrack = selectedTrackItems?.length;

    if (!containerGridHeight || !containerGridWidth) {
      return;
    }

    setShowGraph(containerGridWidth > GRID_WIDTH_QUARTER);
    const containerMargin = 15;
    const height =
      (HEIGHT_GRID_UNIT * containerGridHeight - DETAIL_BAR_HEIGHT - (detailed ? 0 : 72) + containerMargin) / numTrack;
    const defaultMaxHeight = variant === "large" ? 90 : 76;
    setMaxChartHeight(Number.isFinite(height) ? Math.max(defaultMaxHeight, Math.ceil(height)) : null);
  }, [
    variant,
    bp,
    crtLayout,
    detailed,
    containerGridHeight,
    offsetSelection.length,
    containerGridWidth,
    graphKey,
    selectedTrackItems,
  ]);

  const { handleChangeTracksOrderAndUpdateLens, openTrackSettingsModal, trackSettingsModalElement } =
    useTrackChangeOperations({
      selectedUnits,
      availableTrackIds,
      selectedUom,
      lens,
      tracks,
      stringIds,
      selectedTrackItems,
      setSelectedTrackItems,
      onLensUpdated,
      isStickSlip: true,
      trackUnitTypes: trackUnitTypesStickSlip,
    });

  const { TrackAddModalElement, openTrackAddModal } = useTrackAddStickSlip({
    lens,
    selectedTrackItems,
    onAdd: (tracks) => {
      onLensUpdated?.({ ...lens, userLensTrackItems: tracks } as UserLensDto);
      setSelectedTrackItems?.(tracks);
    },
  });

  const ztTracks = useMemo(() => {
    const zTracksWithWellId: { track: ParameterByDepthTrackDetailsDto; wellId: number }[] = [];

    data.facts?.map((fact) => {
      const zTrack = fact.tracks?.find((track) => track?.trackId === zTorqueTrackId);
      if (zTrack) zTracksWithWellId.push({ track: zTrack, wellId: fact.wellId });
    });

    return compressZTrackRanges(zTracksWithWellId);
  }, [data?.facts]);

  const handleOnZTOverlayWellChange = useCallback((outerInterval: ZtrackIntervalsWithWellId | null) => {
    setHighlightedInterval(outerInterval);
  }, []);

  const getUnitSystem = useCallback((track: StickSlipByDepthUserLensTrackItemDto) => {
    if (track.isUomUnlocked) {
      return undefined;
    } else return track.systemOfMeasurementType === "Imperial" ? IUnitSystem.IMPERIAL : IUnitSystem.METRIC;
  }, []);

  return (
    <>
      <LensContainer
        ref={containerRef}
        rows={detailed ? tracksCount : tracksCount ?? 3}
        detailed={detailed}
        onMouseLeave={() => setPointerInsideChart(false)}
        onMouseEnter={() => setPointerInsideChart(true)}
        onMouseMove={() => setPointerInsideChart(true)}
        variant={variant}
      >
        <LensHeader />

        <ScrollContainer $detailed={detailed} $rows={detailed ? tracksCount : tracksCount ?? 3}>
          {lens.showZTorque ? (
            <ZTorqueTrack
              onZTOverlayWellChange={handleOnZTOverlayWellChange}
              chartWidth={chartWidth}
              depthScale={depthScale}
              detailed={detailed}
              focalWellColor={focalWellColor}
              isPointerInsideChart={isPointerInsideChart}
              isTooltipEnabled={isTooltipEnabled}
              isTopMost={true}
              isUnitUnlocked={false}
              maxChartHeight={maxChartHeight || 0}
              pointerPosition={pointerPosition}
              selectedUnit={ZTData["ZTData"]}
              showGraph={showGraph}
              trackCount={offsetSelection.length + 1}
              ztTracks={ztTracks}
              tracks={(tracksById && tracksById?.[zTorqueTrackId]) || []}
            />
          ) : null}

          {tracks ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleChangeTracksOrderAndUpdateLens}
            >
              <SortableContext items={stringIds} strategy={verticalListSortingStrategy}>
                {selectedTrackItems
                  .filter((track) => track.trackId !== zTorqueTrackId)
                  .map((track, index) => {
                    if ((isFetching || isLoading) && !tracksById?.[track.trackId]) {
                      return (
                        <div
                          key={`${tracksCount}_${track.trackId}`}
                          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                          <Loader size={24} />
                        </div>
                      );
                    }
                    return (
                      <StickSlipByDepthItem
                        highlightedInterval={highlightedInterval}
                        chartWidth={chartWidth}
                        depthScale={depthScale}
                        detailed={detailed}
                        focalWellColor={focalWellColor}
                        isComparing={false}
                        isPointerInsideChart={isPointerInsideChart}
                        isTooltipEnabled={isTooltipEnabled}
                        isTopMost={index === 0}
                        isUnitUnlocked={track.isUomUnlocked}
                        forcedUnitSystem={getUnitSystem(track)}
                        key={`${tracksCount}_${track.trackId}`}
                        maxChartHeight={maxChartHeight || 0}
                        onPointerPositionChange={setPointerPosition}
                        onTooltipToggle={() => setTooltipEnabled((prev) => !prev)}
                        onTrackSettings={() => openTrackSettingsModal(track)}
                        pointerPosition={pointerPosition}
                        selectedUnit={selectedUnits[track.trackId]}
                        showGraph={showGraph}
                        trackCount={tracksCount}
                        tracks={(tracksById && tracksById?.[track.trackId]) || []}
                        yMax={track.isManualYaxis ? track.yaxisEnd || undefined : undefined}
                        yMin={track.isManualYaxis ? track.yaxisStart || undefined : undefined}
                        isRoadmap={false}
                      />
                    );
                  })}
              </SortableContext>
            </DndContext>
          ) : null}
        </ScrollContainer>
      </LensContainer>

      <>
        <BottomRowWithAxis
          setScrollState={setScrollState}
          availableTrackIds={availableTrackIds}
          detailed={detailed}
          openTrackAddModal={openTrackAddModal}
          chartWidth={chartWidth}
          containerHeight={containerHeight ? containerHeight : 0}
          flatTracks={flatTracks}
          depthScale={depthScale}
          lens={lens}
          isStickSlipByDepth
        />
        {TrackAddModalElement}
        {trackSettingsModalElement}
      </>
    </>
  );
};
