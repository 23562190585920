import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useRigs } from "hooks/useRigs";
import { useSelectedRig } from "hooks/useSelectedRig";
import { TSummary } from "pages/RigLeaderboard/components/ScoreBreakout/SummarizedComments";
import { useScoreBreakoutModal } from "pages/RigLeaderboard/components/ScoreBreakout/useScoreBreakoutModal";
import { useScoreCardTags } from "pages/RigScoreCard/LeftPane/utils";
import { BenchmarkType, useScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import { Row } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";

import { CustomTag } from "./CustomTag";
import { useRigScores } from "./useRigScores";

const StyledRow = styled(Row)`
  background: ${({ theme }) => theme.themeStyle.colors.alt_quaterniary_bg};
  position: sticky;
  top: 0;
  z-index: ${zIndexLayer.base};
  box-shadow: 0px 1px 0px ${({ theme }) => theme.themeStyle.colors.primary_box_shadow};
  padding: 20px 28px;
  justify-content: space-between;
`;

const StyledInfo = styled.span`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  & + & {
    margin-left: 8px;
    :before {
      content: "";
      display: inline-block;
      width: 1px;
      height: 22px;
      background: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
      opacity: 0.12;
    }
  }
`;

export default function PaneHeader() {
  const [selectedWells] = useStateQuery(URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD, []);
  const rigScores = useRigScores({ selectedWells });
  const {
    scoreSummary,
    averages,
    totalDowntime: downtime,
  } = rigScores ?? {
    scoreSummary: undefined,
    averages: undefined,
    totalDowntime: undefined,
  };

  const { scoreBenchmark, setScoreBenchmark } = useScoreBenchmarkContext();
  const { getTagsByAveragesOrFact } = useScoreCardTags();
  const scoreKey = scoreBenchmark === BenchmarkType.OperatorTarget ? "operatorScore" : "rigScore";
  const opportunityKey = scoreBenchmark === BenchmarkType.OperatorTarget ? "targetDeltaTime" : "invisibleLostTime";

  const { data: rigs } = useRigs();
  const rigId = useSelectedRig();
  const selectedRig = rigs?.byId[rigId];
  const { openScoreBreakout, scoreBreakoutElement } = useScoreBreakoutModal();
  const wellScoreInfo = useAppSelector((state) => state.rigDashboard.selectedWellsInfo);

  const tags = useMemo(
    () => getTagsByAveragesOrFact({ averages, downtime: downtime || 0 }),
    [averages, downtime, getTagsByAveragesOrFact],
  );

  if (!rigScores) return null;

  return (
    <StyledRow>
      <PDComponent.Dropdown<BenchmarkType>
        options={[
          { label: BenchmarkType.OperatorTarget, value: BenchmarkType.OperatorTarget },
          { label: BenchmarkType.PDBenchmark, value: BenchmarkType.PDBenchmark },
        ]}
        variant="button"
        selectedOption={scoreBenchmark}
        handleOptionUpdate={(option) => {
          Track.interact("Rig Scorecard - Change benchmark", { "Selected benchmark": option });
          setScoreBenchmark(option);
        }}
      />
      <Row>
        <StyledInfo>
          <Title variant="faded" level={4}>
            Overall Score
          </Title>
          <CustomTag
            {...tags.score}
            variant="filled"
            disabled={!averages?.[scoreKey]}
            onClick={() => {
              if (selectedRig) {
                openScoreBreakout({
                  name: selectedRig?.shortName || "",
                  id: selectedRig.id,
                  opportunityTime: averages?.[opportunityKey],
                  score: averages?.[scoreKey],
                  scoreSummary: scoreSummary,
                  summaryType: TSummary.Rig,
                  // no need for well info in here
                  wellInfo: wellScoreInfo.map((score) => ({
                    id: score.wellId,
                    rigScore: score.rigScore.value,
                    operatorScore: score.operatorScore.value,
                  })),
                });
              }
            }}
          />
        </StyledInfo>
        <StyledInfo>
          <Title variant="faded" level={4}>
            Overall Time vs {scoreBenchmark === BenchmarkType.OperatorTarget ? "Target" : "Benchmark"}
          </Title>
          <CustomTag {...tags.opportunityTime} />
        </StyledInfo>
        <StyledInfo>
          <Title variant="faded" level={4}>
            Overall Time vs Base
          </Title>
          <CustomTag {...tags.timeSaved} />
        </StyledInfo>
        <StyledInfo>
          <Title variant="faded" level={4}>
            Downtime
          </Title>
          <CustomTag {...tags.downtime} />
        </StyledInfo>
      </Row>
      {scoreBreakoutElement}
    </StyledRow>
  );
}
