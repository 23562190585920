import type { DetailedLegendColumn } from "./DetailedLegend";
import { DetailedLegend } from "./DetailedLegend";
import type { LegendItem } from "./interfaces";
import { SimpleLegend } from "./SimpleLegend";

interface LensLegendProps {
  legendItems: LegendItem[];
  detailedColumns?: DetailedLegendColumn[];
  isDetailed?: boolean;
  lensGridSize: [number, number];
  customStyle?: React.CSSProperties;
  clickable?: boolean;
}

const ChartLegend: React.FC<LensLegendProps> = (props) => {
  return props.isDetailed ? <DetailedLegend {...props} /> : <SimpleLegend {...props} />;
};

export default ChartLegend;
