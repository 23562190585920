/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PowerConsumptionByOperationUserLensDto
 */
export interface PowerConsumptionByOperationUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof PowerConsumptionByOperationUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PowerConsumptionByOperationUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof PowerConsumptionByOperationUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof PowerConsumptionByOperationUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PowerConsumptionByOperationUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PowerConsumptionByOperationUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PowerConsumptionByOperationUserLensDto
     */
    squeezesDisplay: boolean;
}

/**
 * Check if a given object implements the PowerConsumptionByOperationUserLensDto interface.
 */
export function instanceOfPowerConsumptionByOperationUserLensDto(value: object): value is PowerConsumptionByOperationUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('squeezesDisplay' in value) || value['squeezesDisplay'] === undefined) return false;
    return true;
}

export function PowerConsumptionByOperationUserLensDtoFromJSON(json: any): PowerConsumptionByOperationUserLensDto {
    return PowerConsumptionByOperationUserLensDtoFromJSONTyped(json, false);
}

export function PowerConsumptionByOperationUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowerConsumptionByOperationUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
    };
}

export function PowerConsumptionByOperationUserLensDtoToJSON(value?: PowerConsumptionByOperationUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'SqueezesDisplay': value['squeezesDisplay'],
    };
}

