import { useConfirmModal } from "components/ConfirmModal";
import { PDComponent } from "components/PDComponents";
import { IconContainer } from "icons/styles";

export const DeletePrompt = ({ onClick }: { onClick: () => void }) => {
  const { confirmModalElement, openConfirmModal } = useConfirmModal({
    title: "This tab will be permanently deleted",
    description: "Tab deletion is instant and irreversible. Confirm to proceed.",
    onConfirm: onClick,
  });
  return (
    <>
      <IconContainer onClick={openConfirmModal}>
        <PDComponent.SvgIcon name="delete" />
      </IconContainer>
      {confirmModalElement}
    </>
  );
};
