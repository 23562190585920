import styled from "styled-components";
import colors from "utils/colors";
import { zIndexLayer } from "utils/zIndex";

export const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: ${zIndexLayer.mars};
  width: 100%;
  height: 100%;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${colors.infinity_black};
  opacity: 0.9;
`;

export const MessageBox = styled.div`
  width: 70%;
  border-radius: 10px;
  opacity: 1;
  justify-content: left;
  padding: 28px 24px;
  display: flex;
  flex-direction: column;
  z-index: ${zIndexLayer.base};
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};

  h1 {
    font-size: 24px;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
  p {
    font-size: 16px;
  }
`;
