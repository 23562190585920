import { AxisBottom, AxisRight } from "@visx/axis";
import { Brush } from "@visx/brush";
import type { BaseBrushState, UpdateBrush } from "@visx/brush/lib/BaseBrush";
import type BaseBrush from "@visx/brush/lib/BaseBrush";
import type { Bounds } from "@visx/brush/lib/types";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { LinePath } from "@visx/shape";
import type { ActualTvdPointDto, PlanTvdPointDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { getAxisFontSize } from "components/Lenses/ContainerLens/common/utils/utils";
import { selectedBrushStyle } from "components/Lenses/utils";
import { bisector, extent, max, min } from "d3-array";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useDashboardType } from "hooks/useDashboardType";
import useDiscontinuousTimeAxis from "hooks/useDiscontinuousTimeAxis";
import { useOverviewZoomData } from "hooks/useOverviewZoomData";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import type { IDisplayOptionsType, IZoomData } from "reducers/types";
import { CurvesEnum, initialDisplayOptions, initialDisplayOptionsEvergreen, IZoomType } from "reducers/types";
import colors from "utils/colors";
import { PLAN_SERIES_ID, secondsInDay } from "utils/common";
import { useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useColors } from "utils/useColors";
import { useCustomTheme } from "utils/useTheme";

import { convertDateToDuration, convertDurationToDate, DatepickDateFormat } from "./utils";

const HEIGHT = 400;
const WIDTH = 794;
const ELEMENT_PADDING = 24;
const LETTER_WIDTH = 7.24;
const RIGHT_AXIS_WIDTH = 27;
const MAX_DEPTH_LABELS_COUNT = 15;
const AXIS_PADDING = 23;

// This is a voodoo constant i stumbled upon while trying to fix the visx Brush bounds reporting wrong numbers.
// Keep it for now, ideally the core problem would be solved instead
export const TEMP_BRUSH_FIX_CONST = 2;

interface IZoomCurveData {
  data: (PlanTvdPointDto | ActualTvdPointDto)[];
  id: number;
  color: string;
}

const ZoomSvg = ({
  lens,
  localZoom,
  report,
  setLocalZoom,
  series = [],
}: {
  ignoreTvD: boolean;
  lens: boolean;
  localZoom: IZoomData;
  report: boolean;
  series: ActualTvdPointDto[];
  setLocalZoom: React.Dispatch<React.SetStateAction<IZoomData>>;
}) => {
  const overviewData = useOverviewZoomData({
    report,
    lens,
  });

  const { isEvergreen } = useDashboardType();

  const [displayOptions] = useStateQuery<IDisplayOptionsType>(
    URL_STATE_PARAM.DISPLAY_OPTIONS_WELL,
    isEvergreen ? initialDisplayOptionsEvergreen : initialDisplayOptions,
  );
  const offsetActive = displayOptions.curves === null || displayOptions.curves.includes(CurvesEnum.OFFSET_WELLS);

  const planActive =
    !isEvergreen && (displayOptions.curves === null || displayOptions.curves.includes(CurvesEnum.PLAN));
  const { planTvdSeries, tvdSeries, comparisonTvdSeries } = isEvergreen
    ? { planTvdSeries: null, tvdSeries: overviewData?.tvdSeries, comparisonTvdSeries: null }
    : {
      ...overviewData,
      planTvdSeries: planActive ? overviewData?.planTvdSeries : null,
      comparisonTvdSeries: offsetActive ? overviewData?.comparisonTvdSeries : null,
    };

  const bitDepthActive = displayOptions.curves === null || displayOptions.curves.includes(CurvesEnum.BIT_DEPTH);

  const brushRef = useRef<BaseBrush | null>(null);
  const [data, setData] = useState<Array<IZoomCurveData>>([]);
  const { setColor } = useColors();
  const getBitDepth = (d: ActualTvdPointDto) => d?.bitDepth;

  const transformedData = useMemo(() => data?.flatMap((e) => e.data) ?? [], [data]);
  const transformedDataWithBitDepth = useMemo(
    () => transformedData.filter((p): p is ActualTvdPointDto => isFinite((p as ActualTvdPointDto).bitDepth ?? Number.NaN)),
    [transformedData],
  );

  useLayoutEffect(() => {
    setData([
      { data: planTvdSeries?.series ?? [], id: PLAN_SERIES_ID, color: colors.gray },
      { data: tvdSeries?.series ?? [], id: tvdSeries?.wellId, color: colors.well_color },

      ...(comparisonTvdSeries ?? []).map((data) => ({
        data: data?.series ?? [],
        id: data.wellId,
        color: setColor({ key: (data.wellId ?? "").toString() }),
      })),
    ]);
  }, [planTvdSeries?.series, tvdSeries?.series, comparisonTvdSeries, tvdSeries?.wellId, setColor]);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const depthTransformer = useUOM(DimensionType.Metres);
  const getCumulativeDuration = (d: { cumulativeDuration?: number }) =>
    Math.trunc(((d?.cumulativeDuration || 0) / secondsInDay) * 100) / 100;
  const getDepth = (d: { holeDepth?: number | null | undefined }) => d.holeDepth;
  const xMax = useMemo(() => max(data?.flatMap((e) => e.data) ?? [], getCumulativeDuration), [data]);

  const [minHoleDepth = 0, maxHoleDepth = 0] = useMemo(() => extent(transformedData, getDepth), [transformedData]);
  const [minHoleBitDepth = 0, maxHoleBitDepth = 0] = useMemo(
    () => extent(transformedDataWithBitDepth, getBitDepth),
    [transformedDataWithBitDepth],
  );

  const heightSvgContainer = HEIGHT;
  const widthSvgContainer = WIDTH;
  const widthChart = useMemo(
    () => widthSvgContainer - ELEMENT_PADDING * 2 - RIGHT_AXIS_WIDTH - AXIS_PADDING,
    [widthSvgContainer],
  );
  const heightChart = useMemo(() => heightSvgContainer - ELEMENT_PADDING, [heightSvgContainer]);

  const { xScale, xScaleDate, chunksCount } = useDiscontinuousTimeAxis<ActualTvdPointDto>({
    xScaleDomain: [0, xMax || 0],
    plotWidth: widthChart,
    series: tvdSeries?.series || [],
  });
  const [yMinDepth, yMax] = [min([minHoleDepth, minHoleBitDepth]), max([maxHoleDepth, maxHoleBitDepth])];
  const domain = useMemo(
    () => [
      +depthTransformer.display(yMinDepth, { unit: "", fractionDigits: 0 }).replace(",", ""),
      +depthTransformer.display(yMax, { unit: "", fractionDigits: 0 }).replace(",", ""),
    ],
    [depthTransformer, yMax, yMinDepth],
  );
  const yDisplayScale = useMemo(
    () =>
      scaleLinear<number>({
        domain,
        range: [0, heightChart - ELEMENT_PADDING],
        nice: true,
      }),
    [domain, heightChart],
  );
  const yScale = useMemo(() => {
    const maxDepthScale = scaleLinear<number>({
      domain,
      nice: true,
    });
    return scaleLinear<number>({
      domain: maxDepthScale.domain().map(depthTransformer.toSI),
      range: [0, heightChart - ELEMENT_PADDING],
    });
  }, [depthTransformer.toSI, domain, heightChart]);

  const onBrushChange = (bounds: Bounds | null) => {
    if (!bounds) return;

    // !-- Temporary hack until we understand why brush reports bad sizes
    // ---- HACK START
    bounds.x0 <= 0 ? (bounds.x0 = 0) : (bounds.x0 += xScale.invert(TEMP_BRUSH_FIX_CONST));
    bounds.x1 >= xScale.domain()[1]
      ? (bounds.x1 = xScale.domain()[1])
      : (bounds.x1 -= xScale.invert(TEMP_BRUSH_FIX_CONST));

    bounds.y0 += yScale.invert(TEMP_BRUSH_FIX_CONST);
    bounds.y1 -= yScale.invert(TEMP_BRUSH_FIX_CONST);
    // ---- HACK END

    setLocalZoom((prevZoom) => {
      let date_start = null;
      let date_end = null;

      if (bounds.x0 >= 0) {
        date_start =
          prevZoom.type === IZoomType.DEPTH
            ? prevZoom.date_start
            : new Date(convertDurationToDate(series)(bounds.x0 * secondsInDay));
        date_end =
          prevZoom.type === IZoomType.DEPTH
            ? prevZoom.date_end
            : new Date(convertDurationToDate(series)(bounds.x1 * secondsInDay));
      }

      const newZoomValues: IZoomData = {
        ts_start: prevZoom.type === IZoomType.DEPTH ? prevZoom.ts_start : Math.floor(bounds.x0 * secondsInDay),
        ts_end: prevZoom.type === IZoomType.DEPTH ? prevZoom.ts_end : Math.floor(bounds.x1 * secondsInDay),
        depth_start: prevZoom.type === IZoomType.DEPTH ? bounds.y0 : prevZoom.depth_start,
        depth_end: prevZoom.type === IZoomType.DEPTH ? bounds.y1 : prevZoom.depth_end,
        date_start,
        date_end,
        date_end_well_offset: overviewData.tvdSeries?.series?.[0]?.at.minutesOffset ?? null,
        date_start_well_offset: overviewData.tvdSeries?.series?.slice(-1)?.[0]?.at.minutesOffset ?? null,
        type: prevZoom.type === IZoomType.DYNAMIC_WINDOW ? IZoomType.DATE : prevZoom.type,
        internal_zoom: true
      };

      return {
        ...prevZoom,
        ...newZoomValues,
        internal_zoom: true,
      };
    });
  };

  useLayoutEffect(() => {
    let currentBrushLocation: { start: { x: number; y: number }; end: { x: number; y: number } };
    const fallbackPoint = series[series.length - 1]; // To set end and start both at this point in order to hide any sort of selection
    let seriesPointIndex, brushStart, start, end;
    if (!tvdSeries?.series) return;
    switch (localZoom.type) {
      case IZoomType.DEPTH:
        currentBrushLocation = {
          start: { x: 0, y: yScale(localZoom.depth_start) },
          end: { x: 0, y: yScale(localZoom.depth_end || 0) },
        };
        break;

      case IZoomType.DYNAMIC_WINDOW:
        seriesPointIndex = bisector<ActualTvdPointDto, number>((p) => +new Date(p.at.utc)).left(
          tvdSeries.series,
          +Date.now() + localZoom.ts_start * 1000,
        );

        brushStart = tvdSeries.series[seriesPointIndex]
          ? xScale(tvdSeries.series[seriesPointIndex]?.cumulativeDuration / secondsInDay)
          : xScale(fallbackPoint.cumulativeDuration);

        currentBrushLocation = {
          start: { x: brushStart, y: yScale(minHoleDepth) },
          end: { x: xScale(fallbackPoint.cumulativeDuration), y: yScale(minHoleDepth) },
        };
        break;

      case IZoomType.TIME:
        currentBrushLocation = {
          start: {
            x:
              localZoom.ts_start === null || localZoom.ts_start < 0
                ? xScale(fallbackPoint.cumulativeDuration)
                : xScale(localZoom.ts_start / secondsInDay),
            y: yScale(minHoleDepth),
          },
          end: {
            x:
              localZoom.ts_start !== 0 && localZoom.ts_end === null
                ? xScale(fallbackPoint.cumulativeDuration)
                : xScale((localZoom.ts_end || 0) / secondsInDay),
            y: yScale(minHoleDepth),
          },
        };
        break;

      case IZoomType.DATE:
        start = localZoom.date_start ? convertDateToDuration(series)(localZoom.date_start) / secondsInDay : null;
        end = localZoom.date_end ? convertDateToDuration(series)(localZoom.date_end) / secondsInDay : null;

        currentBrushLocation = {
          start: {
            x: xScale(start || fallbackPoint.cumulativeDuration),
            y: yScale(minHoleDepth),
          },
          end: {
            x: xScale(end || fallbackPoint.cumulativeDuration),
            y: yScale(minHoleDepth),
          },
        };
        break;

      default:
        currentBrushLocation = {
          start: {
            x:
              localZoom.ts_start === null
                ? xScale(fallbackPoint.cumulativeDuration)
                : xScale(localZoom.ts_start / secondsInDay),
            y: yScale(minHoleDepth),
          },
          end: {
            x:
              localZoom.ts_start !== 0 && localZoom.ts_end === null
                ? xScale(fallbackPoint.cumulativeDuration)
                : xScale((localZoom.ts_end || 0) / secondsInDay),
            y: yScale(minHoleDepth),
          },
        };
    }

    if (brushRef?.current) {
      const updater: UpdateBrush = (prevBrush) => {
        if (brushRef.current) {
          const newExtent = brushRef.current.getExtent(currentBrushLocation.start, currentBrushLocation.end);

          const newState: BaseBrushState = {
            ...prevBrush,
            start: { y: newExtent.y0, x: newExtent.x0 },
            end: { y: newExtent.y1, x: newExtent.x1 },
            extent: newExtent,
          };

          return newState;
        }
        return prevBrush;
      };

      brushRef.current.updateBrush(updater);
    }
  }, [localZoom, minHoleDepth, series, tvdSeries?.series, xMax, xScale, yScale]);

  const getDuration = (d: { dynamicDuration?: number }) => (d?.dynamicDuration ?? 0) / secondsInDay;

  return (
    <svg
      width={widthSvgContainer}
      height={heightSvgContainer}
      style={{
        backgroundColor: themeColors.primary_bg,
      }}
      viewBox={`0 0 ${widthSvgContainer < 0 ? 0 : widthSvgContainer} ${heightSvgContainer}`}
    >
      <GridRows
        top={ELEMENT_PADDING}
        scale={yScale}
        width={widthSvgContainer - ELEMENT_PADDING * 2 - RIGHT_AXIS_WIDTH}
        height={heightSvgContainer}
        stroke={themeColors.primary_chart_accent}
      />
      <Group left={ELEMENT_PADDING} top={ELEMENT_PADDING} width={widthChart}>
        <Group stroke={colors.bottom_line_widget}>
          {localZoom.type === IZoomType.DATE || localZoom.type === IZoomType.DYNAMIC_WINDOW ? (
            <AxisBottom
              // hideTicks
              numTicks={chunksCount}
              tickComponent={(props) => {
                if (!props.formattedValue) return null;
                const localData = overviewData.tvdSeries.series;
                if (!localData) return null;
                const timeAxisPosition = props.x;
                const cumulativeTimeForPosition = xScale.invert(timeAxisPosition);
                const index = bisector<ActualTvdPointDto, number>((d) => getDuration(d)).left(
                  localData,
                  cumulativeTimeForPosition,
                  1,
                );
                const d0 = localData[index - 1];
                const d1 = localData[index];

                let d = d0;
                if (d1 && getDuration(d1) && d0) {
                  d =
                    cumulativeTimeForPosition - getDuration(d0) > getDuration(d1) - cumulativeTimeForPosition ? d1 : d0;
                }

                const crtDate = formatTime((d as unknown as ActualTvdPointDto).at, {
                  formatStr: DatepickDateFormat,
                });
                return <text
                  x={props.x}
                  y={props.y}
                  dx={-40}
                  dy={-ELEMENT_PADDING}
                  fontSize={getAxisFontSize()}
                  letterSpacing={-0.2}
                  textAnchor="right"
                  fill={colors.gray}
                  pointerEvents="none"
                >
                  {crtDate}
                </text>
              }}

              hideAxisLine
              top={heightChart}
              scale={xScaleDate}
            />
          ) : (
            <AxisBottom
              hideTicks
              tickStroke={colors.gray}
              stroke={colors.gray}
              tickComponent={(props) => (
                <text
                  x={props.x}
                  y={props.y}
                  dx={-(LETTER_WIDTH * (props.formattedValue || "").length) / 2}
                  dy={-ELEMENT_PADDING}
                  fontSize={getAxisFontSize()}
                  letterSpacing={-0.2}
                  textAnchor="right"
                  fill={colors.gray}
                  pointerEvents="none"
                >
                  {props.formattedValue}
                </text>
              )}
              hideAxisLine
              top={heightChart}
              scale={xScale}
            />
          )}

          <AxisRight
            hideTicks
            tickStroke={colors.gray}
            stroke={colors.gray}
            axisClassName="VISX_AXIS" // TODO change this approach to the visx one when the lib evolves enough
            numTicks={MAX_DEPTH_LABELS_COUNT}
            tickComponent={(props) => (
              <text
                x={props.x}
                y={props.y}
                dy={props.dy}
                fontSize={12}
                letterSpacing={-0.2}
                textAnchor="right"
                fill={colors.gray}
                pointerEvents="none"
              >
                {props.formattedValue}
              </text>
            )}
            hideAxisLine
            left={widthChart + ELEMENT_PADDING + AXIS_PADDING}
            scale={yDisplayScale}
          />
        </Group>
        {data?.map((series) => {
          if (series.id === PLAN_SERIES_ID && !planActive) return null;
          return (
            <>
              {series.id === tvdSeries?.wellId && bitDepthActive ? (
                <LinePath
                  key={`key-${series.id}0-bitDepth`}
                  data={(series.data ?? []) as ActualTvdPointDto[]}
                  shapeRendering="geometricPrecision"
                  fill="none"
                  pointerEvents="none"
                  defined={(d) => d.bitDepth !== null && d.bitDepth !== undefined}
                  x={(d: ActualTvdPointDto) => xScale(getCumulativeDuration(d)) ?? 0}
                  y={(d: ActualTvdPointDto) => yScale(getBitDepth(d) ?? 0) ?? 0}
                  stroke={series.color}
                  strokeOpacity={1}
                  strokeDasharray="1 2"
                  strokeWidth={1}
                />
              ) : null}
              <LinePath
                key={`key-${series.id}`}
                data={series.data ?? []}
                defined={(d) => getDepth(d) !== null && getDepth(d) !== undefined}
                x={(d) => xScale(getCumulativeDuration(d))}
                y={(d) => yScale(getDepth(d) ?? minHoleDepth)}
                stroke={series.color}
                strokeWidth={2}
              />
            </>
          );
        })}
      </Group>

      <Group
        left={localZoom.type === IZoomType.DEPTH ? 0 : ELEMENT_PADDING}
        top={localZoom.type === IZoomType.DEPTH ? ELEMENT_PADDING : 0}
        width={widthChart}
      >
        <Brush
          brushRegion="chart"
          margin={{
            left: localZoom.type === IZoomType.DEPTH ? 0 : ELEMENT_PADDING,
            top: ELEMENT_PADDING,
            bottom: 0,
            right: localZoom.type === IZoomType.DEPTH ? 0 : ELEMENT_PADDING,
          }}
          innerRef={brushRef}
          xScale={xScale}
          yScale={yScale}
          width={localZoom.type === IZoomType.DEPTH ? widthChart + ELEMENT_PADDING : widthChart}
          height={heightChart - (localZoom.type === IZoomType.DEPTH ? ELEMENT_PADDING : 0)}
          handleSize={8}
          resizeTriggerAreas={localZoom.type === IZoomType.DEPTH ? ["top", "bottom"] : ["left", "right"]}
          brushDirection={localZoom.type === IZoomType.DEPTH ? "vertical" : "horizontal"}
          onBrushEnd={onBrushChange}
          selectedBoxStyle={selectedBrushStyle}
        />
      </Group>
    </svg>
  );
};

export default ZoomSvg;
