/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface TorqueAndDragPointDto
 */
export interface TorqueAndDragPointDto {
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    depth: number;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    measure: number;
    /**
     * 
     * @type {DateDto}
     * @memberof TorqueAndDragPointDto
     */
    startDate: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof TorqueAndDragPointDto
     */
    endDate: DateDto;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    startBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    endBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    readonly duration: number;
    /**
     * 
     * @type {number}
     * @memberof TorqueAndDragPointDto
     */
    readonly distance: number;
}

/**
 * Check if a given object implements the TorqueAndDragPointDto interface.
 */
export function instanceOfTorqueAndDragPointDto(value: object): value is TorqueAndDragPointDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('depth' in value) || value['depth'] === undefined) return false;
    if (!('measure' in value) || value['measure'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    if (!('startBitDepth' in value) || value['startBitDepth'] === undefined) return false;
    if (!('endBitDepth' in value) || value['endBitDepth'] === undefined) return false;
    if (!('duration' in value) || value['duration'] === undefined) return false;
    if (!('distance' in value) || value['distance'] === undefined) return false;
    return true;
}

export function TorqueAndDragPointDtoFromJSON(json: any): TorqueAndDragPointDto {
    return TorqueAndDragPointDtoFromJSONTyped(json, false);
}

export function TorqueAndDragPointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TorqueAndDragPointDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'depth': json['Depth'],
        'measure': json['Measure'],
        'startDate': DateDtoFromJSON(json['StartDate']),
        'endDate': DateDtoFromJSON(json['EndDate']),
        'startBitDepth': json['StartBitDepth'],
        'endBitDepth': json['EndBitDepth'],
        'duration': json['Duration'],
        'distance': json['Distance'],
    };
}

export function TorqueAndDragPointDtoToJSON(value?: Omit<TorqueAndDragPointDto, 'Duration'|'Distance'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Depth': value['depth'],
        'Measure': value['measure'],
        'StartDate': DateDtoToJSON(value['startDate']),
        'EndDate': DateDtoToJSON(value['endDate']),
        'StartBitDepth': value['startBitDepth'],
        'EndBitDepth': value['endBitDepth'],
    };
}

