/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActualTimelineDto,
  ActualTvdPointDto,
  AllWellsKpiDto,
  AllWellsListDto,
  BaseFocalQueryDto,
  BaseWellDto,
  DashboardType,
  FiltersDto,
  ListWellQueryDto,
  PlanActivitySetDto,
  PlanDrillingParameterSetDto,
  PlanDto,
  PlanFormationSetDto,
  PlanTimelineDto,
  RawTvdSeriesDto,
  StandKpiInspectionQueryDto,
  StandKpiInspectionResultDto,
  WellDto,
  WellInfoDto,
  WellRangesDto,
  WellShortInfo,
} from '../models/index';
import {
    ActualTimelineDtoFromJSON,
    ActualTimelineDtoToJSON,
    ActualTvdPointDtoFromJSON,
    ActualTvdPointDtoToJSON,
    AllWellsKpiDtoFromJSON,
    AllWellsKpiDtoToJSON,
    AllWellsListDtoFromJSON,
    AllWellsListDtoToJSON,
    BaseFocalQueryDtoFromJSON,
    BaseFocalQueryDtoToJSON,
    BaseWellDtoFromJSON,
    BaseWellDtoToJSON,
    DashboardTypeFromJSON,
    DashboardTypeToJSON,
    FiltersDtoFromJSON,
    FiltersDtoToJSON,
    ListWellQueryDtoFromJSON,
    ListWellQueryDtoToJSON,
    PlanActivitySetDtoFromJSON,
    PlanActivitySetDtoToJSON,
    PlanDrillingParameterSetDtoFromJSON,
    PlanDrillingParameterSetDtoToJSON,
    PlanDtoFromJSON,
    PlanDtoToJSON,
    PlanFormationSetDtoFromJSON,
    PlanFormationSetDtoToJSON,
    PlanTimelineDtoFromJSON,
    PlanTimelineDtoToJSON,
    RawTvdSeriesDtoFromJSON,
    RawTvdSeriesDtoToJSON,
    StandKpiInspectionQueryDtoFromJSON,
    StandKpiInspectionQueryDtoToJSON,
    StandKpiInspectionResultDtoFromJSON,
    StandKpiInspectionResultDtoToJSON,
    WellDtoFromJSON,
    WellDtoToJSON,
    WellInfoDtoFromJSON,
    WellInfoDtoToJSON,
    WellRangesDtoFromJSON,
    WellRangesDtoToJSON,
    WellShortInfoFromJSON,
    WellShortInfoToJSON,
} from '../models/index';

export interface ApiWellsAvailableFiltersPutRequest {
    baseFocalQueryDto?: BaseFocalQueryDto;
}

export interface ApiWellsIdActualTimelineTypeGetRequest {
    id: number;
    type: DashboardType;
}

export interface ApiWellsIdDetailsGetRequest {
    id: number;
}

export interface ApiWellsIdGetRequest {
    id: number;
}

export interface ApiWellsIdInspectionDetailsPutRequest {
    id: number;
    standKpiInspectionQueryDto?: StandKpiInspectionQueryDto;
}

export interface ApiWellsIdPlanActivitiesGetRequest {
    id: number;
}

export interface ApiWellsIdPlanDrillingParametersGetRequest {
    id: number;
}

export interface ApiWellsIdPlanFormationsGetRequest {
    id: number;
}

export interface ApiWellsIdPlanGetRequest {
    id: number;
}

export interface ApiWellsIdPlanTimelineGetRequest {
    id: number;
}

export interface ApiWellsIdRangesPutRequest {
    id: number;
    baseFocalQueryDto?: BaseFocalQueryDto;
}

export interface ApiWellsIdRawActualTvdGetRequest {
    id: number;
    sampleCount?: number;
}

export interface ApiWellsIdTvdsPutRequest {
    id: number;
    baseFocalQueryDto?: BaseFocalQueryDto;
}

export interface ApiWellsKpisAggregatedPutRequest {
    requestBody?: Array<number>;
}

export interface ApiWellsPendingIdMergeIntoTargetIdPutRequest {
    pendingId: number;
    targetId: number;
}

export interface ApiWellsPendingIdMergeRequestTargetIdPutRequest {
    pendingId: number;
    targetId: number;
}

export interface ApiWellsPostRequest {
    wellInfoDto?: WellInfoDto;
}

export interface ApiWellsPutRequest {
    wellInfoDto?: WellInfoDto;
}

export interface ApiWellsReferenceFiltersPutRequest {
    requestBody?: Array<number>;
}

export interface ApiWellsSearchFullTextGetRequest {
    search?: string;
    nonPendingOnly?: boolean;
}

export interface ApiWellsSummariesPutRequest {
    listWellQueryDto?: ListWellQueryDto;
}

/**
 * 
 */
export class WellsApi extends runtime.BaseAPI {

    /**
     */
    async apiWellsAvailableFiltersPutRaw(requestParameters: ApiWellsAvailableFiltersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FiltersDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/AvailableFilters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFocalQueryDtoToJSON(requestParameters['baseFocalQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FiltersDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsAvailableFiltersPut(requestParameters: ApiWellsAvailableFiltersPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FiltersDto> {
        const response = await this.apiWellsAvailableFiltersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsHasPlanActivitiesIdGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/HasPlanActivities/Id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiWellsHasPlanActivitiesIdGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apiWellsHasPlanActivitiesIdGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsHasPlanDrillingParametersIdGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/HasPlanDrillingParameters/Id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiWellsHasPlanDrillingParametersIdGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apiWellsHasPlanDrillingParametersIdGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsHasPlanFormationsIdGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/HasPlanFormations/Id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiWellsHasPlanFormationsIdGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apiWellsHasPlanFormationsIdGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdActualTimelineTypeGetRaw(requestParameters: ApiWellsIdActualTimelineTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActualTimelineDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdActualTimelineTypeGet().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling apiWellsIdActualTimelineTypeGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/ActualTimeline/{type}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActualTimelineDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdActualTimelineTypeGet(requestParameters: ApiWellsIdActualTimelineTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActualTimelineDto> {
        const response = await this.apiWellsIdActualTimelineTypeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdDetailsGetRaw(requestParameters: ApiWellsIdDetailsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdDetailsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/Details`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdDetailsGet(requestParameters: ApiWellsIdDetailsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellDto> {
        const response = await this.apiWellsIdDetailsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdGetRaw(requestParameters: ApiWellsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellInfoDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdGet(requestParameters: ApiWellsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellInfoDto> {
        const response = await this.apiWellsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdInspectionDetailsPutRaw(requestParameters: ApiWellsIdInspectionDetailsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StandKpiInspectionResultDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdInspectionDetailsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/InspectionDetails`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StandKpiInspectionQueryDtoToJSON(requestParameters['standKpiInspectionQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StandKpiInspectionResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdInspectionDetailsPut(requestParameters: ApiWellsIdInspectionDetailsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StandKpiInspectionResultDto> {
        const response = await this.apiWellsIdInspectionDetailsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdPlanActivitiesGetRaw(requestParameters: ApiWellsIdPlanActivitiesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlanActivitySetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdPlanActivitiesGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/PlanActivities`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanActivitySetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdPlanActivitiesGet(requestParameters: ApiWellsIdPlanActivitiesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlanActivitySetDto> {
        const response = await this.apiWellsIdPlanActivitiesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdPlanDrillingParametersGetRaw(requestParameters: ApiWellsIdPlanDrillingParametersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlanDrillingParameterSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdPlanDrillingParametersGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/PlanDrillingParameters`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanDrillingParameterSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdPlanDrillingParametersGet(requestParameters: ApiWellsIdPlanDrillingParametersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlanDrillingParameterSetDto> {
        const response = await this.apiWellsIdPlanDrillingParametersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdPlanFormationsGetRaw(requestParameters: ApiWellsIdPlanFormationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlanFormationSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdPlanFormationsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/PlanFormations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFormationSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdPlanFormationsGet(requestParameters: ApiWellsIdPlanFormationsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlanFormationSetDto> {
        const response = await this.apiWellsIdPlanFormationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdPlanGetRaw(requestParameters: ApiWellsIdPlanGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlanDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdPlanGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/Plan`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdPlanGet(requestParameters: ApiWellsIdPlanGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlanDto> {
        const response = await this.apiWellsIdPlanGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdPlanTimelineGetRaw(requestParameters: ApiWellsIdPlanTimelineGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlanTimelineDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdPlanTimelineGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/PlanTimeline`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanTimelineDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdPlanTimelineGet(requestParameters: ApiWellsIdPlanTimelineGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlanTimelineDto> {
        const response = await this.apiWellsIdPlanTimelineGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdRangesPutRaw(requestParameters: ApiWellsIdRangesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellRangesDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdRangesPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/Ranges`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFocalQueryDtoToJSON(requestParameters['baseFocalQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellRangesDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdRangesPut(requestParameters: ApiWellsIdRangesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellRangesDto> {
        const response = await this.apiWellsIdRangesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdRawActualTvdGetRaw(requestParameters: ApiWellsIdRawActualTvdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ActualTvdPointDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdRawActualTvdGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sampleCount'] != null) {
            queryParameters['sampleCount'] = requestParameters['sampleCount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/RawActualTvd`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActualTvdPointDtoFromJSON));
    }

    /**
     */
    async apiWellsIdRawActualTvdGet(requestParameters: ApiWellsIdRawActualTvdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ActualTvdPointDto>> {
        const response = await this.apiWellsIdRawActualTvdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsIdTvdsPutRaw(requestParameters: ApiWellsIdTvdsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RawTvdSeriesDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellsIdTvdsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{id}/Tvds`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFocalQueryDtoToJSON(requestParameters['baseFocalQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RawTvdSeriesDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsIdTvdsPut(requestParameters: ApiWellsIdTvdsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RawTvdSeriesDto> {
        const response = await this.apiWellsIdTvdsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsKpisAggregatedPutRaw(requestParameters: ApiWellsKpisAggregatedPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllWellsKpiDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/Kpis/Aggregated`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllWellsKpiDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsKpisAggregatedPut(requestParameters: ApiWellsKpisAggregatedPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllWellsKpiDto> {
        const response = await this.apiWellsKpisAggregatedPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsPendingIdMergeIntoTargetIdPutRaw(requestParameters: ApiWellsPendingIdMergeIntoTargetIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['pendingId'] == null) {
            throw new runtime.RequiredError(
                'pendingId',
                'Required parameter "pendingId" was null or undefined when calling apiWellsPendingIdMergeIntoTargetIdPut().'
            );
        }

        if (requestParameters['targetId'] == null) {
            throw new runtime.RequiredError(
                'targetId',
                'Required parameter "targetId" was null or undefined when calling apiWellsPendingIdMergeIntoTargetIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{pendingId}/MergeInto/{targetId}`.replace(`{${"pendingId"}}`, encodeURIComponent(String(requestParameters['pendingId']))).replace(`{${"targetId"}}`, encodeURIComponent(String(requestParameters['targetId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiWellsPendingIdMergeIntoTargetIdPut(requestParameters: ApiWellsPendingIdMergeIntoTargetIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiWellsPendingIdMergeIntoTargetIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsPendingIdMergeRequestTargetIdPutRaw(requestParameters: ApiWellsPendingIdMergeRequestTargetIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['pendingId'] == null) {
            throw new runtime.RequiredError(
                'pendingId',
                'Required parameter "pendingId" was null or undefined when calling apiWellsPendingIdMergeRequestTargetIdPut().'
            );
        }

        if (requestParameters['targetId'] == null) {
            throw new runtime.RequiredError(
                'targetId',
                'Required parameter "targetId" was null or undefined when calling apiWellsPendingIdMergeRequestTargetIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/{pendingId}/MergeRequest/{targetId}`.replace(`{${"pendingId"}}`, encodeURIComponent(String(requestParameters['pendingId']))).replace(`{${"targetId"}}`, encodeURIComponent(String(requestParameters['targetId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiWellsPendingIdMergeRequestTargetIdPut(requestParameters: ApiWellsPendingIdMergeRequestTargetIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiWellsPendingIdMergeRequestTargetIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsPostRaw(requestParameters: ApiWellsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WellInfoDtoToJSON(requestParameters['wellInfoDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsPost(requestParameters: ApiWellsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellInfoDto> {
        const response = await this.apiWellsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsPutRaw(requestParameters: ApiWellsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellInfoDtoToJSON(requestParameters['wellInfoDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsPut(requestParameters: ApiWellsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellInfoDto> {
        const response = await this.apiWellsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsReferenceFiltersPutRaw(requestParameters: ApiWellsReferenceFiltersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FiltersDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/ReferenceFilters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FiltersDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsReferenceFiltersPut(requestParameters: ApiWellsReferenceFiltersPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FiltersDto> {
        const response = await this.apiWellsReferenceFiltersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsSearchFullTextGetRaw(requestParameters: ApiWellsSearchFullTextGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BaseWellDto>>> {
        const queryParameters: any = {};

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['nonPendingOnly'] != null) {
            queryParameters['nonPendingOnly'] = requestParameters['nonPendingOnly'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/Search/FullText`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BaseWellDtoFromJSON));
    }

    /**
     */
    async apiWellsSearchFullTextGet(requestParameters: ApiWellsSearchFullTextGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BaseWellDto>> {
        const response = await this.apiWellsSearchFullTextGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsShortInfoGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WellShortInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/ShortInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WellShortInfoFromJSON));
    }

    /**
     */
    async apiWellsShortInfoGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WellShortInfo>> {
        const response = await this.apiWellsShortInfoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellsSummariesPutRaw(requestParameters: ApiWellsSummariesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllWellsListDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Wells/Summaries`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ListWellQueryDtoToJSON(requestParameters['listWellQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllWellsListDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellsSummariesPut(requestParameters: ApiWellsSummariesPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllWellsListDto> {
        const response = await this.apiWellsSummariesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
