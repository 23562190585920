/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigScorecardLensType } from './RigScorecardLensType';
import {
    RigScorecardLensTypeFromJSON,
    RigScorecardLensTypeFromJSONTyped,
    RigScorecardLensTypeToJSON,
} from './RigScorecardLensType';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';
import type { ScorecardExceptionStatusType } from './ScorecardExceptionStatusType';
import {
    ScorecardExceptionStatusTypeFromJSON,
    ScorecardExceptionStatusTypeFromJSONTyped,
    ScorecardExceptionStatusTypeToJSON,
} from './ScorecardExceptionStatusType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ScorecardKpiCommentInfoDto
 */
export interface ScorecardKpiCommentInfoDto {
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentInfoDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ScorecardKpiCommentInfoDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentInfoDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentInfoDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentInfoDto
     */
    description?: string | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof ScorecardKpiCommentInfoDto
     */
    kpiType: StandKpiType;
    /**
     * 
     * @type {RigScorecardLensType}
     * @memberof ScorecardKpiCommentInfoDto
     */
    scorecardType: RigScorecardLensType;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentInfoDto
     */
    exceptionTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentInfoDto
     */
    exceptionApproverId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentInfoDto
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentInfoDto
     */
    authorDisplayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentInfoDto
     */
    exceptionApproverDisplayName?: string | null;
    /**
     * 
     * @type {DateDto}
     * @memberof ScorecardKpiCommentInfoDto
     */
    createdAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof ScorecardKpiCommentInfoDto
     */
    updatedAt: DateDto;
    /**
     * 
     * @type {ScorecardExceptionStatusType}
     * @memberof ScorecardKpiCommentInfoDto
     */
    exceptionStatus: ScorecardExceptionStatusType;
}



/**
 * Check if a given object implements the ScorecardKpiCommentInfoDto interface.
 */
export function instanceOfScorecardKpiCommentInfoDto(value: object): value is ScorecardKpiCommentInfoDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('kpiType' in value) || value['kpiType'] === undefined) return false;
    if (!('scorecardType' in value) || value['scorecardType'] === undefined) return false;
    if (!('authorId' in value) || value['authorId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('exceptionStatus' in value) || value['exceptionStatus'] === undefined) return false;
    return true;
}

export function ScorecardKpiCommentInfoDtoFromJSON(json: any): ScorecardKpiCommentInfoDto {
    return ScorecardKpiCommentInfoDtoFromJSONTyped(json, false);
}

export function ScorecardKpiCommentInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScorecardKpiCommentInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': json['DeletedAtUtc'] == null ? undefined : (new Date(json['DeletedAtUtc'])),
        'wellId': json['WellId'],
        'title': json['Title'] == null ? undefined : json['Title'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'kpiType': StandKpiTypeFromJSON(json['KpiType']),
        'scorecardType': RigScorecardLensTypeFromJSON(json['ScorecardType']),
        'exceptionTime': json['ExceptionTime'] == null ? undefined : json['ExceptionTime'],
        'exceptionApproverId': json['ExceptionApproverId'] == null ? undefined : json['ExceptionApproverId'],
        'authorId': json['AuthorId'],
        'authorDisplayName': json['AuthorDisplayName'] == null ? undefined : json['AuthorDisplayName'],
        'exceptionApproverDisplayName': json['ExceptionApproverDisplayName'] == null ? undefined : json['ExceptionApproverDisplayName'],
        'createdAt': DateDtoFromJSON(json['CreatedAt']),
        'updatedAt': DateDtoFromJSON(json['UpdatedAt']),
        'exceptionStatus': ScorecardExceptionStatusTypeFromJSON(json['ExceptionStatus']),
    };
}

export function ScorecardKpiCommentInfoDtoToJSON(value?: ScorecardKpiCommentInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'DeletedAtUtc': value['deletedAtUtc'] == null ? undefined : ((value['deletedAtUtc'] as any).toISOString()),
        'WellId': value['wellId'],
        'Title': value['title'],
        'Description': value['description'],
        'KpiType': StandKpiTypeToJSON(value['kpiType']),
        'ScorecardType': RigScorecardLensTypeToJSON(value['scorecardType']),
        'ExceptionTime': value['exceptionTime'],
        'ExceptionApproverId': value['exceptionApproverId'],
        'AuthorId': value['authorId'],
        'AuthorDisplayName': value['authorDisplayName'],
        'ExceptionApproverDisplayName': value['exceptionApproverDisplayName'],
        'CreatedAt': DateDtoToJSON(value['createdAt']),
        'UpdatedAt': DateDtoToJSON(value['updatedAt']),
        'ExceptionStatus': ScorecardExceptionStatusTypeToJSON(value['exceptionStatus']),
    };
}

