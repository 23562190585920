import IconWrapper from "icons/HOC";
import React from "react";
import { useCustomTheme } from "utils/useTheme";

export const NoDataIcon = IconWrapper(() => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  return (
    <svg
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      width="1em"
      height="1em"
      viewBox="0 0 40 41"
      aria-hidden="true"
    >
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 0.5C8.95431 0.5 0 9.45431 0 20.5C0 31.5457 8.95431 40.5 20 40.5C26.4761 40.5 32.2332 37.422 35.8883 32.6492C33.7192 31.7736 31.8358 30.3393 30.4166 28.5257C27.9982 31.6163 24.2317 33.6053 20 33.6053C12.6992 33.6053 6.78068 27.6868 6.78068 20.386C6.78068 13.0851 12.6992 7.16667 20 7.16667C24.2317 7.16667 27.9982 9.1556 30.4166 12.2462C31.8065 10.4701 33.6416 9.05779 35.7543 8.17775C32.0933 3.5037 26.3974 0.5 20 0.5ZM39.1886 14.8426C36.7972 15.551 35.0526 17.7648 35.0526 20.386C35.0526 23.0293 36.8268 25.2582 39.2493 25.947C39.7383 24.2153 40 22.3882 40 20.5C40 18.5351 39.7167 16.6365 39.1886 14.8426ZM20 14.6053C16.8074 14.6053 14.2193 17.1934 14.2193 20.386C14.2193 23.5786 16.8074 26.1667 20 26.1667C23.1926 26.1667 25.7807 23.5786 25.7807 20.386C25.7807 17.1934 23.1926 14.6053 20 14.6053Z"
          fill={themeColors.primary_scroll_bg}
        />
      </g>
      <path
        d="M25.0625 18.8125V13.75H23.9375V14.875H22.25V16H23.9375V18.8125H22.25V19.9375H26.75V18.8125H25.0625Z"
        fill={themeColors.tertiary_typography}
      />
      <path
        d="M15.7813 14.875C16.1706 14.875 16.5513 14.9905 16.875 15.2068C17.1988 15.4231 17.4511 15.7306 17.6001 16.0903C17.7491 16.4501 17.7881 16.8459 17.7122 17.2278C17.6362 17.6097 17.4487 17.9605 17.1734 18.2359C16.898 18.5112 16.5472 18.6987 16.1653 18.7747C15.7834 18.8506 15.3876 18.8116 15.0278 18.6626C14.6681 18.5136 14.3606 18.2613 14.1443 17.9375C13.928 17.6138 13.8125 17.2331 13.8125 16.8438C13.8125 16.3216 14.0199 15.8208 14.3891 15.4516C14.7583 15.0824 15.2591 14.875 15.7813 14.875ZM15.7813 13.75C15.1694 13.75 14.5712 13.9314 14.0625 14.2714C13.5537 14.6113 13.1572 15.0945 12.923 15.6598C12.6888 16.2251 12.6276 16.8472 12.7469 17.4473C12.8663 18.0474 13.161 18.5987 13.5936 19.0314C14.0263 19.464 14.5776 19.7587 15.1777 19.8781C15.7778 19.9974 16.3999 19.9362 16.9652 19.702C17.5305 19.4678 18.0137 19.0713 18.3536 18.5625C18.6936 18.0538 18.875 17.4556 18.875 16.8438C18.875 16.0232 18.5491 15.2363 17.9689 14.6561C17.3887 14.0759 16.6018 13.75 15.7813 13.75Z"
        fill={themeColors.tertiary_typography}
      />
      <path
        d="M15.7813 22.75C16.1706 22.75 16.5513 22.8655 16.875 23.0818C17.1988 23.2981 17.4511 23.6056 17.6001 23.9653C17.7491 24.3251 17.7881 24.7209 17.7122 25.1028C17.6362 25.4847 17.4487 25.8355 17.1734 26.1109C16.898 26.3862 16.5472 26.5737 16.1653 26.6497C15.7834 26.7256 15.3876 26.6866 15.0278 26.5376C14.6681 26.3886 14.3606 26.1363 14.1443 25.8125C13.928 25.4888 13.8125 25.1081 13.8125 24.7188C13.8125 24.1966 14.0199 23.6958 14.3891 23.3266C14.7583 22.9574 15.2591 22.75 15.7813 22.75ZM15.7813 21.625C15.1694 21.625 14.5712 21.8064 14.0625 22.1464C13.5537 22.4863 13.1572 22.9695 12.923 23.5348C12.6888 24.1001 12.6276 24.7222 12.7469 25.3223C12.8663 25.9224 13.161 26.4737 13.5936 26.9064C14.0263 27.339 14.5776 27.6337 15.1777 27.7531C15.7778 27.8724 16.3999 27.8112 16.9652 27.577C17.5305 27.3428 18.0137 26.9463 18.3536 26.4375C18.6936 25.9288 18.875 25.3306 18.875 24.7188C18.875 23.8982 18.5491 23.1113 17.9689 22.5311C17.3887 21.9509 16.6018 21.625 15.7813 21.625Z"
        fill={themeColors.tertiary_typography}
      />
      <path
        d="M24.2188 22.75C24.6081 22.75 24.9888 22.8655 25.3125 23.0818C25.6363 23.2981 25.8886 23.6056 26.0376 23.9653C26.1867 24.3251 26.2256 24.7209 26.1497 25.1028C26.0737 25.4847 25.8862 25.8355 25.6109 26.1109C25.3355 26.3862 24.9847 26.5737 24.6028 26.6497C24.2209 26.7256 23.8251 26.6866 23.4653 26.5376C23.1056 26.3886 22.7981 26.1363 22.5818 25.8125C22.3655 25.4888 22.25 25.1081 22.25 24.7188C22.25 24.1966 22.4574 23.6958 22.8266 23.3266C23.1958 22.9574 23.6966 22.75 24.2188 22.75ZM24.2188 21.625C23.6069 21.625 23.0087 21.8064 22.5 22.1464C21.9912 22.4863 21.5947 22.9695 21.3605 23.5348C21.1263 24.1001 21.0651 24.7222 21.1844 25.3223C21.3038 25.9224 21.5985 26.4737 22.0311 26.9064C22.4638 27.339 23.0151 27.6337 23.6152 27.7531C24.2153 27.8724 24.8374 27.8112 25.4027 27.577C25.968 27.3428 26.4512 26.9463 26.7911 26.4375C27.1311 25.9288 27.3125 25.3306 27.3125 24.7188C27.3125 23.8982 26.9866 23.1113 26.4064 22.5311C25.8262 21.9509 25.0393 21.625 24.2188 21.625Z"
        fill={themeColors.tertiary_typography}
      />
    </svg>
  );
});
