import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar, BarGroup, BarRounded } from "@visx/shape";
import type { SingleStandKpiDetailByDateShiftDto } from "apis/oag";
import { VisualAidType } from "apis/oag";
import { AverageLabel, AverageLine } from "components/Lenses/common/AverageLine";
import { Chart } from "components/Lenses/common/Chart";
import { Label } from "components/Lenses/common/Label";
import { MedianLabel, MedianLine } from "components/Lenses/common/MedianLine";
import { StyledOperationCountContainer } from "components/Lenses/common/OperationCount";
import OperationCount from "components/Lenses/common/OperationCount";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { Scrollbar } from "components/Lenses/common/Scrollbar";
import TargetLine from "components/Lenses/common/TargetLine";
import {
  TooltipDayBorder,
  TooltipDayGrid,
  TooltipGapContainer,
  TooltipHighlightValue,
} from "components/Lenses/common/Tooltip";
import { TooltipVisualAidInfo, useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { useOutlierThreshold } from "components/Lenses/common/useOutlierThreshold";
import type { SingleKpiChartSimpleProps } from "components/Lenses/ContainerLens/SingleKpi/interfaces";
import { getMinMax, getSVGNormalizedValue } from "components/Lenses/utils";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import { useTargetSegments } from "hooks/charting/useTargetSegments";
import { shade } from "polished";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import colors from "utils/colors";
import { OPERATION_COUNT_HEIGHT } from "utils/constants";
import { formatDate } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { DETAILED_ZOOM_SCALER, MINI_ZOOM_SCALER } from "./SingleStandViewChart";

type Moment = "day" | "night";
export type PairItem = {
  operationCount: number;
  totalValue: number;
  target?: number | null;
  isOutlier: boolean;
  type: Moment;
};

type TransformedDataItem = {
  index: number;
  date: Date;
  day: PairItem;
  night: PairItem;
};

export const SingleDayViewChart = ({
  detailed,
  data,
  valueUOM,
  focalWellColor = "",
  lens,
}: SingleKpiChartSimpleProps) => {
  const outliersSettingsEnabled = lens?.showsOutliers;
  const isManual = !!lens?.isManualYaxis;
  const outliersEnabled = outliersSettingsEnabled || isManual;

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight: containerHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const chartHeight = getSVGNormalizedValue(containerHeight - (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0));

  const plotHeight = getSVGNormalizedValue(chartHeight - (detailed ? 136 : 36));
  const plotWidth = getSVGNormalizedValue(chartWidth - (detailed ? 76 : 44) - 10);

  const scrollbarPadding = detailed ? 28 : 4;
  const scrollbarWidth = getSVGNormalizedValue(plotWidth - scrollbarPadding * 2);
  const scrollbarHeight = 4;

  const transformedData = useMemo(() => {
    if (!data.detailsByDateShift) {
      return [];
    }

    return data.detailsByDateShift.map((detail, index) => {
      const totalDayValue = detail.dayValue;
      const totalNightValue = detail.nightValue;

      return {
        index,
        date: detail.date,
        day: {
          totalValue: totalDayValue,
          target: detail.dayTargetValue,
          operationCount: detail.dayOperationCount,
          isOutlier: detail.isOutlier,
          type: "day" as Moment,
        },
        night: {
          totalValue: totalNightValue,
          target: detail.nightTargetValue,
          operationCount: detail.nightOperationCount,
          isOutlier: detail.isOutlier,
          type: "night" as Moment,
        },
      };
    });
  }, [data?.detailsByDateShift]);

  const totalValues = useMemo(
    () => transformedData.flatMap((item) => [item.day?.totalValue ?? 0, item.night?.totalValue ?? 0]),
    [transformedData],
  );

  // A quarter of the chart by default
  const zoomRate = useMemo(() => {
    return lens?.squeezesDisplay
      ? 1
      : Math.ceil(transformedData.length / (detailed ? DETAILED_ZOOM_SCALER : MINI_ZOOM_SCALER));
  }, [detailed, lens?.squeezesDisplay, transformedData.length]);

  const [scrollbarRange, setScrollbarRange] = useState<ScrollbarRange>({ startX: 1 - 1 / zoomRate, endX: 1 });

  const dayScale = useMemo(() => {
    const chartSize = plotWidth * zoomRate;

    const startX = chartSize * scrollbarRange.startX;

    return scaleBand<Date>({
      domain: transformedData.map((i) => new Date(i.date)),
      // Offsetting the pixel range based on zoom, provides scrolling and zoom at the same time
      range: [-startX, chartSize - startX],
      paddingInner: 0.3,
      paddingOuter: 1,
    });
  }, [plotWidth, scrollbarRange.startX, transformedData, zoomRate]);

  const pairScaleValue = useMemo(() => {
    return scaleBand({
      domain: ["dayValue", "nightValue"],
      paddingInner: 0.15,
      range: [0, dayScale.bandwidth()],
    });
  }, [dayScale]);

  const computedTargetSegments = useTargetSegments(
    transformedData.map((td) => ({
      id: td.date,
      list: [
        {
          id: pairScaleValue.domain()[0],
          targetValue: td.day.target,
        },
        {
          id: pairScaleValue.domain()[1],
          targetValue: td.night.target,
        },
      ],
    })),
    dayScale,
    pairScaleValue,
  );

  const { outlierThreshold, gradientDefinition, gradientFill } = useOutlierThreshold({
    values: totalValues,
    enabled: outliersEnabled,
    selectedVisualAids: lens?.selectedVisualAids,
    targetSegments: computedTargetSegments,
  });

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const valueScale = useMemo(() => {
    return scaleLinear<number>({
      domain: getMinMax(
        transformedData.flatMap((item) => [
          item.day?.isOutlier ? 0 : item.day?.totalValue,
          item.night?.isOutlier ? 0 : item.night?.totalValue,
        ]),
        computedTargetSegments,
        outlierThreshold,
        data.summary?.median,
        lens?.selectedVisualAids,
        lens?.outlierFlaggingType,
        lens?.isManualYaxis,
        lens?.yaxisEnd,
        lens?.yaxisStart,
      ),
      range: [plotHeight, 0],
      clamp: true,
    });
  }, [
    transformedData,
    computedTargetSegments,
    outlierThreshold,
    data.summary?.median,
    lens?.selectedVisualAids,
    lens?.outlierFlaggingType,
    lens?.isManualYaxis,
    lens?.yaxisEnd,
    lens?.yaxisStart,
    plotHeight,
  ]);

  const valuesDomain = useMemo(() => valueScale.domain(), [valueScale]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<TransformedDataItem>({
    containerRef,
    renderContent: ({ tooltipData }) => {
      const [day, night] = (["day", "night"] as const).map((timeKey) => (
        <TooltipGapContainer key={timeKey}>
          <TooltipHighlightValue>
            {valueUOM.displayWithAutoDecimals(valuesDomain, tooltipData?.[timeKey].totalValue)}
          </TooltipHighlightValue>

          <TooltipVisualAidInfo
            selectedVisualAids={lens?.selectedVisualAids}
            display={(value) => valueUOM.displayWithAutoDecimals(valuesDomain, value)}
            targetValue={tooltipData?.[timeKey].target}
            averageValue={data.summary?.average}
            median={data.summary?.median}
          />

          {lens.showOperationCount ? (
            <TooltipHighlightValue fontWeight="normal">
              Operation count: {tooltipData?.[timeKey]?.operationCount}
            </TooltipHighlightValue>
          ) : null}
          <div>{tooltipData?.date ? formatDate(tooltipData?.date) : ""}</div>
          <div>{timeKey === "day" ? "Day" : "Night"}</div>

          {tooltipData?.[timeKey]?.isOutlier ? (
            <TooltipHighlightValue fontWeight="normal">OUTLIER</TooltipHighlightValue>
          ) : null}
        </TooltipGapContainer>
      ));

      return (
        <TooltipDayGrid>
          {day}
          <TooltipDayBorder />
          {night}
        </TooltipDayGrid>
      );
    },
  });

  // update if squeeze changed
  useEffect(() => {
    setScrollbarRange({ startX: 1 - 1 / zoomRate, endX: 1 });
  }, [zoomRate]);

  // A separate scale for scrollbar
  // Maps scrollbar pixel position to a [0, 1] range
  const scrollbarScale = useMemo(
    () => scaleLinear<number>({ domain: [0, 1], range: [0, scrollbarWidth] }),
    [scrollbarWidth],
  );

  const dayColumnWidth = dayScale.bandwidth();
  const getDate = (d: SingleStandKpiDetailByDateShiftDto) => d.date;
  const handleMouseOver = useCallback(
    (item: TransformedDataItem) => {
      showTooltip({
        tooltipLeft: item.date ? (dayScale(item?.date) || 0) + dayColumnWidth / 2 : 0,
        tooltipTop: valueScale(
          Math.min(Math.max(item.day.totalValue ?? 0, item.night.totalValue ?? 0), valueScale.domain()[1]),
        ),
        tooltipData: item,
      });
    },
    [dayColumnWidth, dayScale, showTooltip, valueScale],
  );

  // Starting initialization only with div to calculate plot width & height
  if (Number.isNaN(plotWidth) || Number.isNaN(plotHeight)) {
    return (
      <div
        ref={containerRef}
        style={{
          padding: 0,
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "visible",
        }}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      style={{
        padding: 0,
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <StyledOperationCountContainer visible={lens.showOperationCount} />
      <svg width={chartWidth} height={chartHeight} style={{ overflow: "visible", userSelect: "none" }}>
        {gradientDefinition}
        <Chart
          detailed={detailed}
          isManual={lens?.isManualYaxis}
          maxBottomTickWidth={80}
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          startX={plotWidth * zoomRate * scrollbarRange.startX}
          plotWidth={plotWidth}
          plotHeight={plotHeight}
          showOperationCount={lens.showOperationCount}
          valueScale={valueScale}
          categoryScale={dayScale}
          valueUOM={valueUOM}
          tickFormat={(value) => formatDate(value as Date, { formatStr: "MM-DD-YY", useUTC: true })}
        >
          <BarGroup
            keys={["dayValue", "nightValue"]}
            data={data?.detailsByDateShift || []}
            height={plotHeight}
            x0={getDate}
            x0Scale={dayScale}
            x1Scale={pairScaleValue}
            yScale={valueScale}
            color={() => focalWellColor}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                  {barGroup.bars.map((bar) => {
                    if (!bar.value || bar.x < -dayColumnWidth * 5 || bar.x > plotWidth + dayColumnWidth * 5) {
                      return null;
                    }

                    const maxValue = valueScale.domain()[1];
                    const threshold = isManual || outlierThreshold === null ? maxValue : outlierThreshold;
                    const thresholdValue = valueScale(maxValue);
                    const data = transformedData[barGroup.index];

                    let barY = bar.y;
                    let barHeight = bar.height;

                    const showGradient = bar.value > threshold;

                    if (showGradient) {
                      barHeight -= thresholdValue - barY;
                      barY = thresholdValue;
                    }

                    return (
                      <Group key={`bar-stack-${barGroup.index}-${bar.index}`}>
                        <BarRounded
                          radius={4}
                          top
                          key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                          x={bar.x}
                          y={barY}
                          onMouseOver={() => handleMouseOver(transformedData[barGroup.index])}
                          onMouseOut={hideTooltip}
                          width={bar.width}
                          height={barHeight}
                          fill={(() => {
                            if (data.day.isOutlier || data.night.isOutlier) return themeColors.outlier_bars_transparent;
                            if (bar?.key === "nightValue") return shade(0.5, bar.color || colors.well_color);
                            return bar.color || colors.well_color;
                          })()}
                        />

                        {data.day.isOutlier || data.night.isOutlier ? (
                          <Bar
                            x={bar.x - 1}
                            y={barY - 1}
                            width={bar.width + 2}
                            onMouseOver={() => handleMouseOver(transformedData[barGroup.index])}
                            onMouseOut={hideTooltip}
                            height={barHeight}
                            fill={gradientFill}
                          />
                        ) : null}
                        {showGradient ? (
                          <Bar x={bar.x - 1} y={barY - 1} width={bar.width + 2} height={50} fill={gradientFill} />
                        ) : null}
                      </Group>
                    );
                  })}
                </Group>
              ))
            }
          </BarGroup>
          <BarGroup
            keys={["dayValue", "nightValue"]}
            data={data.detailsByDateShift || []}
            height={plotHeight}
            x0={getDate}
            x0Scale={dayScale}
            x1Scale={pairScaleValue}
            yScale={valueScale}
            color={() => focalWellColor}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                  {barGroup.bars.map((bar, index) => {
                    if (!bar.value || bar.x < -dayColumnWidth * 5 || bar.x > plotWidth + dayColumnWidth * 5) {
                      return null;
                    }

                    const maxValue = valueScale.domain()[1];
                    const threshold = isManual || outlierThreshold === null ? maxValue : outlierThreshold;
                    const thresholdValue = valueScale(maxValue);
                    const data = transformedData[barGroup.index];

                    let barY = bar.y;
                    let barHeight = bar.height;

                    const showGradient = bar.value > threshold;

                    if (showGradient) {
                      barHeight -= thresholdValue - barY;
                      barY = thresholdValue;
                    }
                    const value = valueUOM.displayWithAutoDecimals(valuesDomain, bar.value, { unit: "" });
                    return (
                      <Group key={`bar-label-stack-${barGroup.index}-${bar.index}-${value}`}>
                        <Label
                          barHeight={barHeight}
                          barX={bar.x}
                          barY={barY + (detailed ? 0 : 10)}
                          columnWidth={bar.width}
                          detailed={detailed}
                          index={index}
                          topLabel={barY !== plotHeight}
                          value={value}
                          label={lens?.label}
                        />
                        {lens.showOperationCount ? (
                          <OperationCount
                            x={bar.x}
                            width={bar.width}
                            index={index}
                            detailed={detailed}
                            value={bar.key === "nightValue" ? data.night.operationCount : data.day.operationCount}
                          />
                        ) : null}
                      </Group>
                    );
                  })}
                </Group>
              ))
            }
          </BarGroup>
          <AverageLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(VisualAidType.Average)}
            y={valueScale(data.summary?.average ?? 0)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          <MedianLine
            isVisible={(lens?.selectedVisualAids ?? []).includes(VisualAidType.Median)}
            y={valueScale(data.summary?.median ?? 0)}
            x={-RIGHT_AXIS_PADDING}
            width={plotWidth + RIGHT_AXIS_PADDING}
          />

          {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Targets) &&
            computedTargetSegments.map(({ target, lineStart, lineEnd, showTag }, index) => {
              if (!target) return null;
              return (
                <TargetLine
                  key={`${index}-${target}-${lineStart}-${lineEnd}`}
                  start={lineStart}
                  end={lineEnd}
                  y={valueScale(target)}
                  label={valueUOM.displayWithAutoDecimals(valuesDomain, target, { unit: "" })}
                  showTag={!!showTag}
                  detailed={detailed}
                />
              );
            })}
        </Chart>

        {!lens?.squeezesDisplay && zoomRate > 1 && (
          <Group top={plotHeight + (detailed ? 62 : 26)} left={scrollbarPadding}>
            <Scrollbar
              width={scrollbarWidth}
              height={scrollbarHeight}
              scale={scrollbarScale}
              wheelCaptureContainer={containerRef}
              onScroll={setScrollbarRange}
              valueSpan={1 / zoomRate}
            />
          </Group>
        )}
      </svg>

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Average) && (
        <AverageLabel
          style={{
            top: `${
              valueScale(data.summary?.average ?? 0) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)
            }px`,
          }}
        >
          Average: {valueUOM.displayWithAutoDecimals(valuesDomain, data.summary?.average ?? 0)}
        </AverageLabel>
      )}

      {(lens?.selectedVisualAids ?? []).includes(VisualAidType.Median) && (
        <MedianLabel
          isDetailed={detailed}
          style={{
            top: `${
              valueScale(data.summary?.median ?? 0) - 16 + (lens.showOperationCount ? OPERATION_COUNT_HEIGHT : 0)
            }px`,
          }}
        >
          Median: {valueUOM.displayWithAutoDecimals(valuesDomain, data.summary?.median ?? 0)}
        </MedianLabel>
      )}
      {tooltipElement}
    </div>
  );
};
