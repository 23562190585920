export const MINI_ZOOM_SCALER = 125;
export const DETAILED_ZOOM_SCALER = 25;

export const DETAILED_VALUE_AXIS_WIDTH = 76;
export const MINI_VALUE_AXIS_WIDTH = 44;

export const DETAILED_SCROLLBAR_PADDING = 28;
export const MINI_SCROLLBAR_PADDING = 4;

export const DETAILED_SECONDARY_AXIS_HEIGHT = 80;
export const MINI_SECONDARY_AXIS_HEIGHT = 55;

export const PIVOT_SEPARATOR = "__";
