import { StyledTag } from "atoms/common";
import { EllipsisTextCSS } from "commonStyles";
import { PDComponent } from "components/PDComponents";
import { StyledRigDescriptionContainer } from "pages/FleetPerformance/RigList/styles";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import colors from "utils/colors";

export const Header = styled.div`
  height: 65px;
  width: calc(100% - 8px);
  padding-left: 6px;
  padding-right: 6px;
  display: grid;
  grid-template-columns: 50px minmax(110px, 0.75fr) repeat(6, minmax(80px, 1fr)) minmax(190px, 1fr);
  background-color: ${({ theme }) => (theme.isDark ? colors.limo_scene : colors.white)};
  border-radius: 4px;
  column-gap: 14px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-size: 16px;
`;

export const Column = styled.div<{ isSortable?: boolean; hasSmallFont?: boolean }>`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  font-size: ${({ hasSmallFont }) => (hasSmallFont ? "16px" : "20px")};
  @media screen and (max-width: 1300px) {
    font-size: ${({ hasSmallFont }) => (hasSmallFont ? "16px" : "18px")};
    ${StyledTag} {
      font-size: ${({ hasSmallFont }) => (hasSmallFont ? "16px" : "18px")};
    }
  }

  &:hover {
    cursor: ${({ isSortable }) => (isSortable ? "pointer" : "default")};
  }
  ${StyledTag} {
    cursor: pointer;
  }
`;

export const OrderColumn = styled(Column)`
  font-weight: 500;
`;

export const RigColumn = styled(Column)<{ sortable?: boolean }>`
  justify-content: flex-start;
  ${StyledRigDescriptionContainer} {
    max-width: 100%;
  }
`;

export const TrendColumn = styled(Column)`
  font-size: 20px;
  @media screen and (max-width: 1300px) {
    font-size: 18px;
  }
`;

export const OperatorTarget = styled(Column)`
  justify-content: flex-end;
  overflow-x: auto;
`;

export const OperatorTargetColumn = styled(Column)`
  justify-content: space-between;
  gap: 10px;
  margin: 0 18px;
`;

export const ComplianceColumn = styled(Column)`
  justify-self: center;
  justify-content: space-between;
  width: 80px;
  gap: 6px;
`;

export const ListRowContainer = styled.div`
  width: calc(100% + 8px);
  flex-direction: column;
  display: flex;
  row-gap: 3px;
  margin-top: 8px;
  overflow: hidden;
  height: 100%;
`;

export const ListRow = styled(Header)`
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  height: 53px;
  padding: 6px;
  width: calc(100% - 8px);
  overflow: hidden;
`;

export const SkeletonContainer = styled(ListRow)`
  display: flex;
  width: calc(100% - 8px);
  min-height: 53px;
  padding: 6px;

  .ant-skeleton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const OperatorName = styled.span`
  ${EllipsisTextCSS}
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
`;

export const StyledRigScoreIcon = styled(PDComponent.SvgIcon).attrs({ name: "rigScoreIcon" })`
  position: relative;
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  transition: color 0.1s ease-in-out;

  :hover {
    color: ${colors.well_color};
  }
`;

export const Link = styled(RouterLink)`
  &:hover {
    cursor: pointer;
  }
`;

export const Progress = styled.div<{ progress: number }>`
  width: 30px;
  height: 6px;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    ${colors.well_color} ${({ progress }) => progress}%,
    ${colors.error_color} ${({ progress }) => 1 - progress}%
  );
`;

export const CenteredWithTransform = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 4px;
  transform: translateX(9px); // Half of the width of the icon + gap eg. (14px + 4px) / 2
`;
