import styled from "styled-components";
import colors from "utils/colors";

const StyledCircle = styled.circle`
  @keyframes circleAnimation {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0.5;
      transform: scale(0.85);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  transform-origin: center;
  animation: circleAnimation 1.2s linear infinite;
`;

const StyledPath = styled.path`
  transform-origin: 50% 50%;
  transform-box: fill-box;
  @keyframes pathAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
  animation: pathAnimation 1.2s linear infinite;
`;

const LoaderContainer = styled.div<{ zIndex: number }>`
  width: 100%;
  height: 100%;
  min-width: 80px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${(props) => props.zIndex};
  position: ${(props) => (props.zIndex ? "relative" : undefined)};
  background-color: ${(props) => (props.zIndex ? "inherit" : undefined)};
`;

type CssPositions = "static" | "relative" | "absolute" | "sticky" | "fixed";

const StyledSvg = styled.svg<{
  centered?: boolean;
  position?: CssPositions;
  color?: string;
  size?: number;
}>`
  position: ${(props) => props.position || "static"};
  width: ${(props) => `${props.size}px`};
  color: ${(props) => props.color};
  ${(props) =>
    props.centered &&
    `
      position: ${props.position || "absolute"};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  `}
`;

export function Loader({
  centered,
  withWrapper,
  position,
  color = colors.well_color,
  size = 40,
  zIndex,
}: {
  centered?: boolean;
  withWrapper?: boolean;
  position?: CssPositions;
  color?: string;
  size?: number;
  zIndex?: number;
}) {
  const SvgLoader = () => (
    <StyledSvg
      position={position}
      centered={centered}
      color={color}
      size={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
      preserveAspectRatio="xMidYMid meet"
    >
      <StyledCircle cx="22.5" cy="22.5" r="6.5" fill="currentColor" fillOpacity="0.5" />
      <StyledPath
        fill="currentColor"
        fillOpacity="0.5"
        d=" M22.73,45 C29.97,45 36.43,41.64 40.59,36.42 C38.19,35.46 36.1,33.91 34.49,31.96 C31.71,35.34 27.48,37.5 22.73,37.5 C14.36,37.5 7.58,30.78 7.58,22.5 C7.58,14.22 14.36,7.5 22.73,7.5 C27.48,7.5 31.71,9.66 34.49,13.04 C36.1,11.09 38.19,9.54 40.59,8.58 C36.43,3.36 29.97,0 22.73,0 C10.18,0 0,10.07 0,22.5 C0,34.93 10.18,45 22.73,45 C22.73,45 22.73,45 22.73,45 C22.73,45 22.73,45 22.73,45 M39.47370147705078,22.315099716186523 C39.47370147705078,19.385299682617188 41.408199310302734,16.90719985961914 44.069698333740234,16.088699340820312 C44.67499923706055,18.12420082092285 45,20.280399322509766 45,22.512500762939453 C45,24.612199783325195 44.712398529052734,26.644699096679688 44.174400329589844,28.572799682617188 C41.45920181274414,27.787700653076172 39.47370147705078,25.283300399780273 39.47370147705078,22.315099716186523 C39.47370147705078,22.315099716186523 39.47370147705078,22.315099716186523 39.47370147705078,22.315099716186523 C39.47370147705078,22.315099716186523 39.47370147705078,22.315099716186523 39.47370147705078,22.315099716186523"
      ></StyledPath>
    </StyledSvg>
  );

  return withWrapper || zIndex ? (
    <LoaderContainer zIndex={zIndex || 0}>
      <SvgLoader />
    </LoaderContainer>
  ) : (
    <SvgLoader />
  );
}
