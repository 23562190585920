import { scaleLinear } from "@visx/scale";
import type { FuelConsumptionByGeneratorPowerUsageFactDto, FuelFlowRateFactDto } from "apis/oag";
import { DimensionType, FuelType } from "apis/oag";
import { LegendDot } from "components/Lenses/common/ChartLegend/StyledComponentsLegend";
import { LineChart } from "components/Lenses/common/LineChart";
import { CurveType } from "components/Lenses/common/LineChart/utils";
import { TooltipFadedValue, TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { NO_DATA_KPI_STRING } from "components/Lenses/constants";
import * as CommonStyles from "components/Lenses/ContainerLens/common/StyledComponents";
import { StyledChartContainerDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import type { WithIndex } from "components/Lenses/ContainerLens/common/utils/utils";
import {
  getFuelLabelsByFuelType,
  getGeneratorColorsBySlotType,
  getGeneratorLabelsBySlotType,
  getInternalAxisMargin,
  LATERAL_AXIS_WIDTH,
  X_AXIS_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { useFuelConsumptionByGeneratorFacts } from "components/Lenses/ContainerLens/FuelConsumptionByGenerator/common/fetcher";
import {
  curveColors,
  DECIMAL_THRESHOLD,
  LEGEND_HEIGHT,
} from "components/Lenses/ContainerLens/FuelConsumptionByGenerator/common/utils";
import { POWER_SCALE_THESHOLDS } from "components/Lenses/ContainerLens/FuelConsumptionByGenerator/utils";
import type { FuelConsumptionByGeneratorProps } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { curveStepAfter } from "d3";
import { max, min } from "d3-array";
import { useCallback, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { DEFAULT_DATE_FORMAT, useUOM, UtilDimensions } from "utils/format";
import { formatTime } from "utils/helper";

import * as Styled from "./style";

export type FuelFlowRateFactDtoWithIndex = WithIndex<FuelFlowRateFactDto>;
export type FuelConsumptionByGeneratorPowerUsageFactDtoWithIndex =
  WithIndex<FuelConsumptionByGeneratorPowerUsageFactDto>;

export const FuelConsumptionChart: React.FC<FuelConsumptionByGeneratorProps> = ({ lens, detailed, selectedFuel }) => {
  const { data } = useFuelConsumptionByGeneratorFacts(lens);

  const isDiesel = useMemo(() => selectedFuel === FuelType.Diesel, [selectedFuel]);
  const selectedCurveType = useMemo(
    () => (selectedFuel === FuelType.Diesel ? CurveType.Diesel : CurveType.NatGas),
    [selectedFuel],
  );

  const dieselConsumptionFacts = useMemo(() => data?.dieselConsumptionFacts || [], [data?.dieselConsumptionFacts]);
  const natGasConsumptionFacts = useMemo(() => data?.natGasConsumptionFacts || [], [data?.natGasConsumptionFacts]);

  const dieselConsumptionFactsWithIndex = useMemo<FuelFlowRateFactDtoWithIndex[]>(() => {
    return (dieselConsumptionFacts ?? []).map((fact, index) => ({
      ...fact,
      index,
    }));
  }, [dieselConsumptionFacts]);

  const natGasConsumptionFactsWithIndex = useMemo<FuelFlowRateFactDtoWithIndex[]>(() => {
    return (natGasConsumptionFacts ?? []).map((fact, index) => ({
      ...fact,
      index,
    }));
  }, [natGasConsumptionFacts]);

  const fuelConsumptionFacts = useMemo<FuelFlowRateFactDtoWithIndex[]>(() => {
    return ((isDiesel ? dieselConsumptionFacts : natGasConsumptionFacts) ?? []).map((fact, index) => ({
      ...fact,
      index,
    }));
  }, [dieselConsumptionFacts, natGasConsumptionFacts, isDiesel]);

  const generatorPowerUsageFacts = useMemo<FuelConsumptionByGeneratorPowerUsageFactDtoWithIndex[]>(() => {
    return (data?.generatorPowerUsageFacts ?? []).map((fact, index) => ({ ...fact, index }));
  }, [data?.generatorPowerUsageFacts]);

  const generatorAvailablePowerFacts = useMemo<FuelConsumptionByGeneratorPowerUsageFactDtoWithIndex[]>(() => {
    return (data?.generatorPowerUsageFacts ?? []).map((fact, index) => ({
      ...fact,
      value: fact.availablePower,
      index,
    }));
  }, [data?.generatorPowerUsageFacts]);

  // lens.selectedGenerators should probably only be lens.selectedGenerator (singular)
  const generatorColor = useMemo(
    () => getGeneratorColorsBySlotType(lens?.selectedGenerators),
    [lens?.selectedGenerators],
  );

  const natGasUom = useUOM(UtilDimensions.ALTERNATE_CubicMetresPerSecond);
  const dieselUom = useUOM(DimensionType.LitresPerSecond);
  const fuelUom = isDiesel ? dieselUom : natGasUom;
  const powerUom = useUOM(DimensionType.Watts);

  const availableSeries = useMemo<FuelFlowRateFactDtoWithIndex[]>(
    () => [...fuelConsumptionFacts, ...generatorPowerUsageFacts],
    [fuelConsumptionFacts, generatorPowerUsageFacts],
  );

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(Math.max((chartHeightHook ?? 0) - LEGEND_HEIGHT, 0)),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const plotWidth = getSVGNormalizedValue(chartWidth - 2 * LATERAL_AXIS_WIDTH);
  const plotHeight = getSVGNormalizedValue(chartHeight - X_AXIS_HEIGHT);
  const xScale = useMemo(() => {
    const startX = LATERAL_AXIS_WIDTH;
    const timeSeries = availableSeries.map(({ index }) => index);
    const [_min = 0, _max = 0] = [min(timeSeries), max(timeSeries)];

    return scaleLinear<number>({
      domain: [_min, _max],
      range: [startX, plotWidth + startX - (selectedFuel === FuelType.BiFuel ? LATERAL_AXIS_WIDTH : 0)],
    });
  }, [availableSeries, plotWidth, selectedFuel]);

  const internalAxisMargin = useMemo(() => getInternalAxisMargin(detailed), [detailed]);

  const dieselScale = useMemo(() => {
    const maxFromData = dieselConsumptionFacts ? max(dieselConsumptionFacts.map((e) => e.value)) || 0 : 0;
    return scaleLinear<number>({
      range: [plotHeight, internalAxisMargin],
      domain: [0, maxFromData],
      nice: true,
    });
  }, [dieselConsumptionFacts, internalAxisMargin, plotHeight]);

  const natGasScale = useMemo(() => {
    const maxFromData = natGasConsumptionFacts ? max(natGasConsumptionFacts.map((e) => e.value)) || 0 : 0;
    const [minValue, maxValue] = [0, maxFromData];
    return scaleLinear<number>({
      range: [plotHeight, internalAxisMargin],
      domain: [minValue, maxValue],
      nice: true,
    });
  }, [natGasConsumptionFacts, internalAxisMargin, plotHeight]);

  const powerScale = useMemo(() => {
    const maxFromData = generatorPowerUsageFacts
      ? max(generatorPowerUsageFacts.map((e) => max([e.value, e.availablePower]) || 0)) || 0
      : 0;

    const maxValue = POWER_SCALE_THESHOLDS.find((threshold) => threshold > maxFromData) ?? 0;

    return scaleLinear<number>({
      range: [plotHeight, internalAxisMargin],
      domain: [0, maxValue],
      nice: true,
    });
  }, [generatorPowerUsageFacts, internalAxisMargin, plotHeight]);

  const yScale = useMemo(() => {
    const allData = [...dieselConsumptionFacts, ...natGasConsumptionFacts, ...generatorPowerUsageFacts];
    const maxFromData = allData ? max(allData.map((e) => e.value)) || 0 : 0;

    const maxValue = POWER_SCALE_THESHOLDS.find((threshold) => threshold > maxFromData) ?? 0;

    return scaleLinear<number>({
      range: [plotHeight - 50, internalAxisMargin],
      domain: [0, maxValue],
      nice: true,
    });
  }, [dieselConsumptionFacts, natGasConsumptionFacts, generatorPowerUsageFacts, internalAxisMargin, plotHeight]);

  const getCurveColor = useCallback((selectedFuel: "Unknown" | "Diesel" | "NaturalGas"): string => {
    if (selectedFuel === "Diesel") {
      return curveColors.Diesel;
    }
    if (selectedFuel === "NaturalGas") {
      return curveColors.NatGas;
    }
    return curveColors.AvailablePower; // This is unused, here to bypass Unknown type
  }, []);

  const [activeLegendItems, setActiveLegendItems] = useState<string[]>(() => {
    const activeItems = [CurveType.Gen, CurveType.AvailablePower];
    if (selectedFuel !== FuelType.NaturalGas) {
      activeItems.push(CurveType.Diesel);
    }
    if (selectedFuel !== FuelType.Diesel) {
      activeItems.push(CurveType.NatGas);
    }
    return activeItems;
  });

  const curves = useMemo(
    () => [
      {
        legendTitle: "Gen Power Usage",
        type: CurveType.Gen,
        key: CurveType.Gen,
        data: generatorPowerUsageFacts,
        yScale: powerScale,
        color: generatorColor,
      },
      {
        legendTitle: "Diesel Consumption",
        type: CurveType.Fuel,
        key: CurveType.Diesel,
        data: dieselConsumptionFactsWithIndex,
        yScale: dieselScale,
        color: curveColors.Diesel,
        disabled: selectedFuel === FuelType.NaturalGas,
      },
      {
        legendTitle: "Natural Gas Consumption",
        type: CurveType.Fuel,
        key: CurveType.NatGas,
        data: natGasConsumptionFactsWithIndex,
        yScale: natGasScale,
        color: curveColors.NatGas,
        disabled: selectedFuel === FuelType.Diesel,
      },
      {
        legendTitle: "Available Power",
        type: CurveType.AvailablePower,
        key: CurveType.AvailablePower,
        curve: curveStepAfter,
        data: generatorAvailablePowerFacts,
        yScale: powerScale,
        color: curveColors.AvailablePower,
      },
    ],
    [
      dieselConsumptionFactsWithIndex,
      dieselScale,
      generatorAvailablePowerFacts,
      generatorColor,
      generatorPowerUsageFacts,
      natGasConsumptionFactsWithIndex,
      natGasScale,
      powerScale,
      selectedFuel,
    ],
  );

  return (
    <>
      <Styled.StyledTitlePlaceholder />
      <StyledChartContainerDiv>
        <LineChart
          curves={curves}
          lensId={lens?.id}
          lensTemplateId={lens?.lensTemplateId}
          xScale={xScale}
          yScale={yScale}
          leftAxis={{
            scale: powerScale,
            uom: powerUom,
            label: powerUom.abbr,
          }}
          activeLegendItems={activeLegendItems}
          setActiveLegendItems={setActiveLegendItems}
          rightAxis={{
            scale: selectedFuel === FuelType.Diesel ? dieselScale : natGasScale,
            uom: selectedFuel === FuelType.Diesel ? dieselUom : natGasUom,
            label: selectedFuel === FuelType.Diesel ? dieselUom.abbr : natGasUom.abbr,
          }}
          secondaryRightAxis={
            selectedFuel === FuelType.BiFuel
              ? {
                  scale: dieselScale,
                  uom: dieselUom,
                  label: dieselUom.abbr,
                }
              : undefined
          }
          chartWidth={chartWidth}
          chartHeight={chartHeight}
          containerRef={containerRef}
          tooltipRenderer={({ tooltipData }) =>
            tooltipData ? (
              <CommonStyles.TooltipContainer>
                {selectedFuel === FuelType.BiFuel ? (
                  <>
                    {activeLegendItems.includes(CurveType.Diesel) ? (
                      <CommonStyles.TooltipRow>
                        <CommonStyles.HighlightValue>
                          <CommonStyles.Indicator color={curveColors.Diesel} />
                          {`${getGeneratorLabelsBySlotType(lens?.selectedGenerators)} ${getFuelLabelsByFuelType(
                            FuelType.Diesel,
                          )} `}
                        </CommonStyles.HighlightValue>

                        <TooltipHighlightValue>
                          {dieselUom.display(tooltipData.values[CurveType.Diesel], {
                            fractionDigits:
                              dieselUom.fromSI(tooltipData.values[CurveType.Diesel]) > DECIMAL_THRESHOLD ? 2 : 3,
                            unit: dieselUom.abbr,
                          })}
                        </TooltipHighlightValue>
                      </CommonStyles.TooltipRow>
                    ) : null}

                    {activeLegendItems.includes(CurveType.NatGas) ? (
                      <CommonStyles.TooltipRow>
                        <CommonStyles.HighlightValue>
                          <CommonStyles.Indicator color={curveColors.NatGas} />
                          {`${getGeneratorLabelsBySlotType(lens?.selectedGenerators)} ${getFuelLabelsByFuelType(
                            FuelType.NaturalGas,
                          )} `}
                        </CommonStyles.HighlightValue>

                        <TooltipHighlightValue>
                          {natGasUom.display(tooltipData.values[CurveType.NatGas], {
                            fractionDigits:
                              natGasUom.fromSI(tooltipData.values[CurveType.NatGas]) > DECIMAL_THRESHOLD ? 2 : 3,
                            unit: natGasUom.abbr,
                          })}
                        </TooltipHighlightValue>
                      </CommonStyles.TooltipRow>
                    ) : null}

                    {activeLegendItems.includes(CurveType.Diesel) || activeLegendItems.includes(CurveType.NatGas) ? (
                      <CommonStyles.TooltipBorder />
                    ) : null}
                  </>
                ) : (
                  <>
                    {activeLegendItems.includes(selectedCurveType) ? (
                      <>
                        <CommonStyles.TooltipRow>
                          <CommonStyles.HighlightValue>
                            <CommonStyles.Indicator color={getCurveColor(selectedFuel)} />
                            {`${getGeneratorLabelsBySlotType(lens?.selectedGenerators)} ${getFuelLabelsByFuelType(
                              selectedFuel,
                            )}`}
                          </CommonStyles.HighlightValue>

                          <TooltipHighlightValue>
                            {fuelUom.display(tooltipData.values[selectedCurveType], {
                              fractionDigits:
                                fuelUom.fromSI(tooltipData.values[selectedCurveType]) > DECIMAL_THRESHOLD ? 2 : 3,
                              unit: fuelUom.abbr,
                            })}
                          </TooltipHighlightValue>
                        </CommonStyles.TooltipRow>
                        <CommonStyles.TooltipBorder />
                      </>
                    ) : null}
                  </>
                )}

                {/* POWER USAGE */}
                {activeLegendItems.includes(CurveType.Gen) ? (
                  <CommonStyles.TooltipRow>
                    <CommonStyles.HighlightValue>
                      <CommonStyles.Indicator color={generatorColor} />
                      Power Usage
                    </CommonStyles.HighlightValue>

                    <TooltipHighlightValue>
                      {powerUom.display(tooltipData.values[CurveType.Gen], {
                        fractionDigits: powerUom.fromSI(tooltipData.values[CurveType.Gen]) > DECIMAL_THRESHOLD ? 2 : 3,
                      })}
                    </TooltipHighlightValue>
                  </CommonStyles.TooltipRow>
                ) : null}

                {/* Available Power */}
                {activeLegendItems.includes(CurveType.AvailablePower) ? (
                  <>
                    <CommonStyles.TooltipRow>
                      <CommonStyles.HighlightValue>
                        <LegendDot
                          background={curveColors.AvailablePower}
                          isLine
                          opacity={1}
                          style={{
                            width: 8,
                          }}
                        />
                        Available Power
                      </CommonStyles.HighlightValue>

                      <TooltipHighlightValue>
                        {powerUom.display(tooltipData.values[CurveType.AvailablePower], {
                          fractionDigits:
                            powerUom.fromSI(tooltipData.values[CurveType.AvailablePower]) > DECIMAL_THRESHOLD ? 2 : 3,
                        })}
                      </TooltipHighlightValue>
                    </CommonStyles.TooltipRow>
                    <CommonStyles.TooltipBorder />
                  </>
                ) : null}

                <div />
                <TooltipFadedValue>
                  {tooltipData?.at
                    ? formatTime(tooltipData?.at, { formatStr: DEFAULT_DATE_FORMAT })
                    : NO_DATA_KPI_STRING}
                </TooltipFadedValue>
              </CommonStyles.TooltipContainer>
            ) : null
          }
        />
      </StyledChartContainerDiv>
    </>
  );
};
