/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClaraMessageSource = {
    Unknown: 'Unknown',
    Model: 'Model',
    User: 'User'
} as const;
export type ClaraMessageSource = typeof ClaraMessageSource[keyof typeof ClaraMessageSource];


export function instanceOfClaraMessageSource(value: any): boolean {
    for (const key in ClaraMessageSource) {
        if (Object.prototype.hasOwnProperty.call(ClaraMessageSource, key)) {
            if (ClaraMessageSource[key as keyof typeof ClaraMessageSource] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ClaraMessageSourceFromJSON(json: any): ClaraMessageSource {
    return ClaraMessageSourceFromJSONTyped(json, false);
}

export function ClaraMessageSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaraMessageSource {
    return json as ClaraMessageSource;
}

export function ClaraMessageSourceToJSON(value?: ClaraMessageSource | null): any {
    return value as any;
}

