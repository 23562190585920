import { Title } from "atoms/Typography";
import {
  GRID_HEIGHT_QUARTER,
  GRID_WIDTH_HALF,
  GRID_WIDTH_QUARTER,
  GRID_WIDTH_THREE_QUARTERS,
} from "components/Lenses/constants";
import styled from "styled-components";
import { Progress as AntProgress, Row, Typography } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";
import { PdRow } from "utils/componentLibrary/Row";

export const MainTitle = styled(Typography.Title)`
  &.ant-typography {
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

export const Progress = styled(AntProgress)`
  display: none;
`;

export const MainRow = styled(Row)`
  flex-direction: row;
  gap: 8px;
`;
export const DataRow = styled(Row)`
  display: flex;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DataContainer = styled.div`
  display: flex;
`;

export const InfoGroup = styled.div`
  padding-top: 16px;
  position: relative;
`;
export const Data = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const NoDataText = styled(Title).attrs({
  level: 3,
  variant: "faded",
})`
  font-size: 12px !important;
  font-weight: 500 !important;
`;

export const PlaceHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const Container = styled.div<{ $orientation: "vertical" | "horizontal"; $height: number; $width: number }>`
  padding: 28px;
  display: flex;
  flex-direction: ${(props) => (props.$orientation === "vertical" ? "column" : "row")};

  /* 1:1 square variant */
  ${(props) =>
    props.$height === GRID_HEIGHT_QUARTER &&
    props.$width === GRID_WIDTH_QUARTER &&
    `    
    ${PdCardBody} & ${PdRow} + ${PdRow} {
      border-top: none !important;
    }
  `}

  /* 2:1 vertical variant */
  ${(props) =>
    props.$orientation === "vertical" &&
    props.$height > GRID_HEIGHT_QUARTER &&
    `    
    ${Progress} {
      display: flex;
    }
    ${InfoGroup}+${InfoGroup} {
      border-top: 1px solid ${props.theme.themeStyle.colors.primary_accent};
      margin-top: 18px;
      padding-top: 18px;
    }

    ${MainRow}{
      flex-direction: column;
      padding-bottom: 4px;
      margin-bottom: 15px;
      ${Data}{
        margin-top: 8px;
      }
    }
    ${DataRow}{
      padding-top: 16px;
      ${TitleContainer}{
        width: 70%;
      }
      ${DataContainer}{
        flex-direction: column-reverse;
      }
    }
    ${DataContainer}{
      ${Progress} {
        display:none;
      }
    }
  `}

  /* 1:2 horizontal variant */
${(props) =>
    props.$orientation === "horizontal" &&
    props.$width > GRID_WIDTH_QUARTER &&
    `
    padding:32px;
    ${Progress} {
      display: flex;
    }
    ${InfoGroup} {
      width:100%;
      margin-top: 0;
      padding-top: 0;
    }
    ${InfoGroup}+${InfoGroup} {
      border-top: none;
      border-left: 1px solid ${props.theme.themeStyle.colors.primary_accent};
      margin-left: 18px;
      padding-left: 18px;
    }

    ${MainRow}{
      flex-direction: column;
      padding-bottom: 4px;
      margin-bottom: 25px;
      ${Data}{
        margin-top: 8px;
      }
    }
    ${DataRow}{
      padding-top: 16px;
      flex-direction: column;
      ${TitleContainer}{
        width: 70%;
        ${Progress} {
          display:none;
        }
      }
      ${DataContainer}{
        gap: 8px;
        padding-top: 4px;
        flex-direction: column-reverse;
        ${Progress} {
          display:flex;
        }
      }
    }
  `}

  /* 1:3 horizontal variant */
  ${(props) =>
    props.$orientation === "horizontal" &&
    props.$width > GRID_WIDTH_HALF &&
    `
    ${DataRow}{
      flex-direction: row;
      padding-top: 36px;
      ${TitleContainer}{
        width: 75%;
        ${Progress} {
          display:flex;
        }
      }
      ${DataContainer}{
        ${Progress} {
          display:none;
        }
      }
    }
  `}

  /* 1:4 horizontal variant */
  ${(props) =>
    props.$orientation === "horizontal" &&
    props.$width > GRID_WIDTH_THREE_QUARTERS &&
    `
    ${DataRow}{
      ${TitleContainer}{
        width: 80%;
      }
    }
  `}
`;
