import { NotificationType } from "apis/oag";
import { Title } from "atoms/Typography";
import { useConfirmModal } from "components/ConfirmModal";
import { useDetailedNotificationModal } from "components/Notification/useDetailedNotificationModal";
import { PDComponent } from "components/PDComponents";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useNotificationsActions } from "hooks/notifications/useNotificationsActions";
import { useRigs } from "hooks/useRigs";
import { useWellShortInfo } from "hooks/useWellShortInfo";
import { useCallback } from "react";
import React from "react";
import { Track } from "services/Mixpanel";
import { Tooltip } from "utils/componentLibrary";
import { READ_UNREAD_LABEL } from "utils/notifications/constants";
import type { BaseNotificationVm } from "utils/notifications/mappers";

import * as Styled from "./style";

dayjs.extend(relativeTime);

export const NotificationRow = React.memo(
  ({
    notification,
    isSelected,
    editMode,
    handleNotificationSelect,
  }: {
    notification: BaseNotificationVm;
    editMode: boolean;
    isSelected: boolean;
    handleNotificationSelect: () => void;
  }) => {
    const { data: rigs } = useRigs();
    const { data: wellShortInfo } = useWellShortInfo();
    const rig = rigs.byId[notification.rigId];
    const well = wellShortInfo?.byId[notification.wellId];
    const { archive, markRead, markUnread, unarchive, deleteNotification, isMutating } = useNotificationsActions();
    const { openDetailedNotification, detailedNotificationElement, isModalOpen } = useDetailedNotificationModal();

    const handleNotificationClick = useCallback(() => {
      if (!isModalOpen) {
        Track.clickEvent("Open Detailed Notification", {
          Id: notification.id,
          Type: notification.type,
          Title: notification.title,
        });
        openDetailedNotification(notification);
      }
    }, [isModalOpen, openDetailedNotification, notification]);

    const { confirmModalElement, openConfirmModal } = useConfirmModal({
      title: "Notification will be permanently deleted",
      description: "Record deletion is instant and irreversible. Confirm to proceed.",
      onConfirm: () => deleteNotification([notification.id]),
    });

    return (
      <Styled.NotificationRow $isRead={notification.isRead} $editMode={editMode}>
        <Styled.CheckboxContainer $isRead={notification.isRead} $notificationType={notification.type}>
          <Styled.Checkbox checked={isSelected} onClick={handleNotificationSelect} />
          <Styled.IconWrapper $notificationType={notification.type}>
            {notification.type === NotificationType.ScorecardComment ? (
              <PDComponent.SvgIcon name="chat" />
            ) : (
              <PDComponent.SvgIcon name="exceptionNote" width="42" height="46" />
            )}
          </Styled.IconWrapper>
        </Styled.CheckboxContainer>
        <Styled.NotificationInfoRow onClick={handleNotificationClick}>
          <Styled.RigTitleContainer>
            <Title level={3}>
              <strong>{rig.shortName}</strong>
            </Title>
          </Styled.RigTitleContainer>
          <Styled.TitleContainer>
            <Styled.NotificationTitle $isRead={notification.isRead} $notificationType={notification.type}>
              {notification.popupTitle}
            </Styled.NotificationTitle>
            <Styled.WellContainer>
              <Styled.WellName>
                <Title level={4} weight={500}>
                  {well?.name}
                </Title>
              </Styled.WellName>
              {notification.jobExternalIds.length > 1 ? (
                <Styled.Job>
                  <Title level={4} variant="faded">
                    Job: {notification.jobExternalIds.join(", ")}
                  </Title>
                </Styled.Job>
              ) : null}
            </Styled.WellContainer>

            <Title level={4}>{notification.title}</Title>
          </Styled.TitleContainer>
          <Styled.DescriptionContainer>
            <Styled.Description>{notification.description}</Styled.Description>
          </Styled.DescriptionContainer>

          <Styled.Time>
            {dayjs(notification.createdAt?.utc).add(notification.createdAt?.minutesOffset, "minutes").fromNow()}
          </Styled.Time>
        </Styled.NotificationInfoRow>
        <Styled.Controls>
          <Tooltip title={READ_UNREAD_LABEL}>
            <Styled.CheckmarkButton
              $isRead={notification.isRead}
              size="large"
              icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
              loading={isMutating}
              onClick={() => {
                if (notification.isRead) {
                  return markUnread([notification.id]);
                }
                markRead([notification.id]);
              }}
            />
          </Tooltip>
          {notification.isArchived ? (
            <>
              <Tooltip title={"Restore"}>
                <Styled.Button
                  size="large"
                  icon={<PDComponent.SvgIcon name="revert" />}
                  loading={isMutating}
                  onClick={() => {
                    unarchive([notification.id]);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Styled.Button
                  size="large"
                  icon={<PDComponent.SvgIcon name="delete" />}
                  loading={isMutating}
                  onClick={openConfirmModal}
                />
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Archive">
              <Styled.Button
                size="large"
                icon={<PDComponent.SvgIcon name="archive" height="24" />}
                loading={isMutating}
                onClick={() => archive([notification.id])}
              />
            </Tooltip>
          )}
        </Styled.Controls>
        {confirmModalElement}
        {detailedNotificationElement}
      </Styled.NotificationRow>
    );
  },
);
