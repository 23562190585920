import { baseMatrix, MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import type { AbilityMatrixType } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export const getStatCardAbilityMatrix = (): AbilityMatrixType => {
  return {
    ...baseMatrix,
    hasDetailedView: MatrixAvailability.NOT_SUPPORTED,
    lensSettings: MatrixAvailability.NOT_SUPPORTED,
    manual_axis: MatrixAvailability.NOT_SUPPORTED,
    perceptual_kpi_value: MatrixAvailability.NOT_SUPPORTED,
    comparison: MatrixAvailability.NOT_SUPPORTED,
    selectedVisualAids: MatrixAvailability.NOT_SUPPORTED,
    indicators: MatrixAvailability.NOT_SUPPORTED,
    display_options: MatrixAvailability.NOT_SUPPORTED,
    showOperationCount: MatrixAvailability.NOT_SUPPORTED,
    showsOutliers: MatrixAvailability.NOT_SUPPORTED,
    label: MatrixAvailability.NOT_SUPPORTED,
    trackKPI: MatrixAvailability.NOT_SUPPORTED,
    group: MatrixAvailability.NOT_SUPPORTED,
    subGroup: MatrixAvailability.NOT_SUPPORTED,
    legend: MatrixAvailability.NOT_SUPPORTED,
    hasExport: MatrixAvailability.NOT_SUPPORTED,
  };
};
