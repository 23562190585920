/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ExceptionRequestNotificationDto } from './ExceptionRequestNotificationDto';
import {
    ExceptionRequestNotificationDtoFromJSON,
    ExceptionRequestNotificationDtoFromJSONTyped,
    ExceptionRequestNotificationDtoToJSON,
} from './ExceptionRequestNotificationDto';
import type { ScorecardCommentNotificationDto } from './ScorecardCommentNotificationDto';
import {
    ScorecardCommentNotificationDtoFromJSON,
    ScorecardCommentNotificationDtoFromJSONTyped,
    ScorecardCommentNotificationDtoToJSON,
} from './ScorecardCommentNotificationDto';

/**
 * 
 * @export
 * @interface NotificationResultSetDto
 */
export interface NotificationResultSetDto {
    /**
     * 
     * @type {Array<ScorecardCommentNotificationDto>}
     * @memberof NotificationResultSetDto
     */
    scorecardCommentNotifications: Array<ScorecardCommentNotificationDto>;
    /**
     * 
     * @type {Array<ExceptionRequestNotificationDto>}
     * @memberof NotificationResultSetDto
     */
    exceptionRequestNotifications: Array<ExceptionRequestNotificationDto>;
}

/**
 * Check if a given object implements the NotificationResultSetDto interface.
 */
export function instanceOfNotificationResultSetDto(value: object): value is NotificationResultSetDto {
    if (!('scorecardCommentNotifications' in value) || value['scorecardCommentNotifications'] === undefined) return false;
    if (!('exceptionRequestNotifications' in value) || value['exceptionRequestNotifications'] === undefined) return false;
    return true;
}

export function NotificationResultSetDtoFromJSON(json: any): NotificationResultSetDto {
    return NotificationResultSetDtoFromJSONTyped(json, false);
}

export function NotificationResultSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationResultSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'scorecardCommentNotifications': ((json['ScorecardCommentNotifications'] as Array<any>).map(ScorecardCommentNotificationDtoFromJSON)),
        'exceptionRequestNotifications': ((json['ExceptionRequestNotifications'] as Array<any>).map(ExceptionRequestNotificationDtoFromJSON)),
    };
}

export function NotificationResultSetDtoToJSON(value?: NotificationResultSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ScorecardCommentNotifications': ((value['scorecardCommentNotifications'] as Array<any>).map(ScorecardCommentNotificationDtoToJSON)),
        'ExceptionRequestNotifications': ((value['exceptionRequestNotifications'] as Array<any>).map(ExceptionRequestNotificationDtoToJSON)),
    };
}

