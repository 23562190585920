import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { HoleSizeDto } from "apis/oag";
import { HoleSizesApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const holeSizes = new HoleSizesApi(apiConfig);

const HoleSizesQueryKey: PDUniqueQueryKey = { uid: RequestUID.holeSizes };

export function fetchHoleSizes() {
  return holeSizes.apiHoleSizesGet();
}

export function useHoleSizes(options?: Omit<UseQueryOptions<HoleSizeDto[]>, "queryFn" | "queryKey">) {
  return useSuspenseQuery<HoleSizeDto[]>({
    queryKey: [HoleSizesQueryKey],
    queryFn: () => fetchHoleSizes(),
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
