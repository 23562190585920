import claraReducer from "reducers/claraReducer";
import notificationsReducer from "reducers/notificationsReducer";
import { combineReducers } from "redux";

import adminReducer from "./adminReducer";
import allWellsReducer from "./allWellsReducer";
import autoRefreshReducer from "./autoRefreshReducer";
import evergreenWellsReducer from "./evergreenWellsReducer";
import globalReducer from "./globalReducer";
import legendColorReducer from "./legendColorReducer";
import lensDimensionsReducer from "./lensDimensionsReducer";
import reportReducer from "./reportReducer";
import rigDashboardReducer from "./rigDashboardReducer";
import rigsCommonReducer from "./rigsCommonReducer";
import stateReducer from "./stateReducer";
import themeReducer from "./themeReducer";
import timelineReducer from "./timelineReducer";
import widgetReducer from "./widgetReducer";

// TODO group reducers in folders by dashboard relationship

const appReducer = combineReducers({
  admin: adminReducer,
  rigsCommon: rigsCommonReducer,
  allWells: allWellsReducer,
  evergreenWells: evergreenWellsReducer,
  dataState: autoRefreshReducer,
  global: globalReducer,
  lensDimensions: lensDimensionsReducer,
  report: reportReducer,
  rigDashboard: rigDashboardReducer,
  state: stateReducer, // TODO shall rename to wellDashboard
  timeline: timelineReducer,
  widgetOptions: widgetReducer,
  theme: themeReducer,
  legendColor: legendColorReducer,
  notifications: notificationsReducer,
  claraReducer: claraReducer,
});

const rootReducer: typeof appReducer = (state, action: Parameters<typeof appReducer>[1]) => {
  return appReducer(state, action);
};

export { rootReducer };
