/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserClaimDto } from './UserClaimDto';
import {
    UserClaimDtoFromJSON,
    UserClaimDtoFromJSONTyped,
    UserClaimDtoToJSON,
} from './UserClaimDto';

/**
 * 
 * @export
 * @interface UserClaimsRequestDto
 */
export interface UserClaimsRequestDto {
    /**
     * 
     * @type {Array<UserClaimDto>}
     * @memberof UserClaimsRequestDto
     */
    claims: Array<UserClaimDto>;
}

/**
 * Check if a given object implements the UserClaimsRequestDto interface.
 */
export function instanceOfUserClaimsRequestDto(value: object): value is UserClaimsRequestDto {
    if (!('claims' in value) || value['claims'] === undefined) return false;
    return true;
}

export function UserClaimsRequestDtoFromJSON(json: any): UserClaimsRequestDto {
    return UserClaimsRequestDtoFromJSONTyped(json, false);
}

export function UserClaimsRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserClaimsRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'claims': ((json['Claims'] as Array<any>).map(UserClaimDtoFromJSON)),
    };
}

export function UserClaimsRequestDtoToJSON(value?: UserClaimsRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Claims': ((value['claims'] as Array<any>).map(UserClaimDtoToJSON)),
    };
}

