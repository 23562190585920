import { useQueryClient } from "@tanstack/react-query";
import type { RigScorecardUserLensDto, ScorecardKpiCommentGroupDto, ScorecardKpiCommentInfoDto } from "apis/oag";
import { DashboardType, RigScorecardLensType } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { CardCommentEdit } from "components/RigScoreCardCommentsModal/CardCommentEdit";
import SingleCommentBox from "components/RigScoreCardCommentsModal/SingleCommentBox/SingleCommentBox";
import * as CommonStyled from "components/RigScoreCardCommentsModal/styled";
import { CommentViewMode } from "components/RigScoreCardCommentsModal/utils";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useKpiTypes } from "hooks/useKpiTypes";
import { RigScorecardInvalidationKey } from "hooks/useRigScorecardCardFacts";
import { useRigToWellsSuspended } from "hooks/useRigToWells";
import { CommentsInvalidationKey, useScoreCardComments } from "hooks/useScoreCardComments";
import { useUserLenses } from "hooks/useUserLenses";
import { useUserLensTabs } from "hooks/useUserLensTabs";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { CustomTag } from "pages/RigScoreCard/LeftPane/components/CustomTag";
import { getColorByScore } from "pages/RigScoreCard/LeftPane/utils";
import { BenchmarkType, useScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { useUOM, UtilDimensions } from "utils/format";

import { CommentContainer, Container, Subtitle, Title } from "./styles";
import type { IPartialRig } from "./useScoreBreakoutModal";

export enum TSummary {
  Rig = "Rig",
  Well = "Well",
  Kpi = "Kpi",
}
export type TExtendedComment = ScorecardKpiCommentInfoDto & { lensId?: number };

const getCommentByWellId = (commentRequest: ScorecardKpiCommentGroupDto[]) => {
  if (!commentRequest) return {};
  const comments = commentRequest
    .map((comment) => [
      ...comment.comments.map((commentInner) =>
        commentInner.scorecardType !== RigScorecardLensType.OverallWellScores
          ? null
          : {
              ...commentInner,
              lensId: comment.scorecardLensId,
            },
      ),
    ])
    .flat()
    .filter((comment) => comment) as unknown as TExtendedComment[];
  const wellIds = comments.map((comment) => comment.wellId);
  const commentById = wellIds.reduce(
    (acc, wellId) => {
      acc[wellId] = comments.filter((comment) => comment.wellId === wellId);
      return acc;
    },
    {} as Record<number, TExtendedComment[]>,
  );
  return commentById;
};

const getCommentsByWellId = (commentGroup: ScorecardKpiCommentGroupDto) => {
  return (commentGroup?.comments ?? []).reduce(
    (acc, comment) => {
      if (!acc[comment.wellId]) acc[comment.wellId] = [];
      acc[comment.wellId].push({ ...comment, lensId: commentGroup.scorecardLensId });
      return acc;
    },
    {} as Record<number, TExtendedComment[]>,
  );
};

export const SummarizedComments = ({
  selectedRig,
  summaryType,
  onTaskFinished,
}: {
  selectedRig: IPartialRig;
  summaryType: TSummary;
  onTaskFinished: () => void;
}) => {
  const { scoreBenchmark } = useScoreBenchmarkContext();
  const queryClient = useQueryClient();
  // check filtering in here
  const { data: wellIdsData } = useRigToWellsSuspended({
    staleTime: Infinity,
    defaultRig: selectedRig.id,
  });
  const [wellIdsState] = useStateQuery<Array<number> | null>(URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD, null);
  const wellIds = (wellIdsState?.length ? wellIdsState : wellIdsData) || [];

  const { data: commentsRequest } = useScoreCardComments(wellIds, {
    staleTime: Infinity,
  });

  const { data: wellInfo } = useWellShortInfoSuspended({
    staleTime: Infinity,
  });

  const percentageUom = useUOM(UtilDimensions.Percentage);
  const [commentViewMode, setCommentViewMode] = useState(CommentViewMode.LIST);
  const [currentlyEditedComment, setCurrentlyEditedComment] = useState<TExtendedComment | null>(null);
  const handleOnEditModeClose = useCallback(
    (shouldRefresh: boolean) => {
      setCommentViewMode(CommentViewMode.LIST);
      if (shouldRefresh) {
        queryClient.invalidateQueries({ queryKey: [RigScorecardInvalidationKey], exact: false });
        queryClient.invalidateQueries({ queryKey: [CommentsInvalidationKey] });
        onTaskFinished();
      }
    },
    [onTaskFinished, queryClient],
  );

  const commentsById = useMemo(() => commentsRequest && getCommentByWellId(commentsRequest), [commentsRequest]);
  const { data: lenses } = useUserLenses<RigScorecardUserLensDto>();

  const getCanUseExceptions = useCallback(
    (kpiId: number) => {
      if (lenses?.byId) return lenses.byId[kpiId]?.acceptsExceptions;
    },
    [lenses?.byId],
  );

  const hasNoComments = useMemo(() => {
    switch (summaryType) {
      case TSummary.Rig:
        return commentsById && !(Object.keys(commentsById).length > 0);
      case TSummary.Kpi:
        return (selectedRig?.kpiInfo ?? []).every(
          (kpi) => !commentsRequest?.find((comment) => comment.scorecardLensId === kpi.id)?.comments.length,
        );
      case TSummary.Well:
        return (commentsRequest || [])
          .flatMap((comment) => comment.comments)
          .every((x) => x.wellId !== selectedRig.selectedWell);
    }
  }, [commentsById, commentsRequest, selectedRig?.kpiInfo, selectedRig.selectedWell, summaryType]);
  const commentsByWellId = useMemo(() => {
    if (!commentsRequest || !selectedRig?.selectedWell) return {};
    return commentsRequest.reduce(
      (acc, crt) => {
        const val = selectedRig.selectedWell ? getCommentsByWellId(crt)[selectedRig.selectedWell] : undefined;
        if (val) acc[crt.scorecardLensId] = val;
        return acc;
      },
      {} as Record<number, ScorecardKpiCommentInfoDto[]>,
    );
  }, [commentsRequest, selectedRig?.selectedWell]);
  // This should be accessible just for the well scorecard
  const maxValByLens = useAppSelector((state) => state.rigDashboard?.maxValueByKpi);
  const { data } = useKpiTypes();
  const { data: userLenses } = useUserLenses<RigScorecardUserLensDto>();
  const { data: lensTabs } = useUserLensTabs(DashboardType.RigScorecard, {
    staleTime: Infinity,
  });

  const getNameByKpiType = useCallback(
    (kpiType: number) => {
      const lens = data?.byId[kpiType];
      return lens?.shortName ?? lens?.name ?? "";
    },
    [data?.byId],
  );
  switch (summaryType) {
    case TSummary.Rig:
      return (
        <Container>
          <Title>Rig Comment Summary</Title>
          {hasNoComments ? (
            <CommonStyled.NoCommentsContainer>
              <CommonStyled.CommentIcon>
                <PDComponent.SvgIcon name="chat" />
              </CommonStyled.CommentIcon>
              <h1>No comments entered yet.</h1>
            </CommonStyled.NoCommentsContainer>
          ) : (
            Object.keys(commentsById ?? {})
              .sort((a, b) => wellIds.findIndex((x) => x === +a) - wellIds.findIndex((x) => x === +b))
              .map((wellId) => {
                // select benchmark type based on the selected benchmark
                const scoreValue = selectedRig?.wellInfo?.find((wellScore) => wellScore.id === +wellId)?.[
                  scoreBenchmark === BenchmarkType.PDBenchmark ? "rigScore" : "operatorScore"
                ];
                return (
                  <div key={wellId}>
                    <Subtitle>
                      #{wellIds.indexOf(+wellId) + 1}
                      {"  "}
                      {wellInfo?.byId[Number.parseInt(wellId)]?.name}
                      {scoreValue ? (
                        <CustomTag
                          icon={<PDComponent.SvgIcon name="meter" />}
                          color={getColorByScore(scoreValue)}
                          text={percentageUom.display(scoreValue, { fractionDigits: 1 })}
                          variant="default"
                          size="default"
                        />
                      ) : null}
                    </Subtitle>
                    <CommentContainer>
                      {commentsById?.[Number.parseInt(wellId)].map((comment, idx) => {
                        if (
                          currentlyEditedComment?.lensId &&
                          commentViewMode !== CommentViewMode.LIST &&
                          comment.id === currentlyEditedComment?.id
                        )
                          return [
                            <CardCommentEdit
                              editedComment={currentlyEditedComment}
                              onEditModeClose={handleOnEditModeClose}
                              wellId={currentlyEditedComment.wellId}
                              lensId={currentlyEditedComment.lensId}
                              paddingTop={idx > 0 ? 16 : 32}
                              kpiType={currentlyEditedComment.kpiType}
                              scorecardType={currentlyEditedComment.scorecardType}
                              key={comment.id ?? idx}
                              canUseExceptions={false}
                              canAddExceptions={false}
                            />,
                            idx !== commentsById[Number.parseInt(wellId)].length - 1 ? (
                              <hr
                                key={`${comment.id ?? idx}-line`}
                                style={{
                                  margin: 0,
                                  opacity: 0.4,
                                  marginTop: 16,
                                  borderColor: colors.necron_compound_light,
                                }}
                              />
                            ) : null,
                          ];
                        return [
                          <SingleCommentBox
                            comment={comment}
                            paddingTop={idx > 0 ? 16 : 32}
                            commentViewMode={commentViewMode}
                            key={comment.id ?? idx}
                            setCurrentlyEditedComment={setCurrentlyEditedComment}
                            setCommentViewMode={setCommentViewMode}
                          />,
                          idx !== commentsById[Number.parseInt(wellId)].length - 1 ? (
                            <hr
                              key={`${comment.id ?? idx}-line`}
                              style={{
                                margin: 0,
                                opacity: 0.4,
                                marginTop: 16,
                                borderColor: colors.necron_compound_light,
                              }}
                            />
                          ) : null,
                        ];
                      })}
                    </CommentContainer>
                  </div>
                );
              })
          )}
        </Container>
      );
    case TSummary.Kpi:
      return (
        <Container>
          <Title>KPI Comment Summary</Title>
          {hasNoComments ? (
            <CommonStyled.NoCommentsContainer>
              <CommonStyled.CommentIcon>
                <PDComponent.SvgIcon name="chat" />
              </CommonStyled.CommentIcon>
              <h1>No comments entered yet.</h1>
            </CommonStyled.NoCommentsContainer>
          ) : (
            (selectedRig?.kpiInfo || []).map((kpi) => {
              const commentsByLens = commentsRequest?.find((comment) => comment.scorecardLensId === kpi.id);
              if (!(commentsByLens?.comments?.length && commentsByLens?.comments?.length > 0)) return null;
              const commentsByWellId = getCommentsByWellId(commentsByLens);
              return (
                <div key={kpi.id}>
                  <Title>{kpi.name}</Title>
                  {wellIds.map((wellId) => {
                    const wellName = wellInfo?.byId[wellId]?.name;
                    const comments = commentsByWellId[wellId];
                    if (!comments) return null;
                    return (
                      <div key={`${wellId}-${kpi.id}`}>
                        <Subtitle>
                          #{wellIds.indexOf(wellId) + 1}
                          {"  "}
                          {wellName}
                        </Subtitle>
                        <CommentContainer>
                          {comments.map((comment, idx) => {
                            if (
                              currentlyEditedComment?.lensId &&
                              commentViewMode !== CommentViewMode.LIST &&
                              comment.id === currentlyEditedComment?.id
                            )
                              return [
                                <CardCommentEdit
                                  editedComment={currentlyEditedComment}
                                  onEditModeClose={handleOnEditModeClose}
                                  wellId={currentlyEditedComment.wellId}
                                  lensId={currentlyEditedComment.lensId}
                                  paddingTop={idx > 0 ? 16 : 32}
                                  kpiType={currentlyEditedComment.kpiType}
                                  scorecardType={currentlyEditedComment.scorecardType}
                                  key={comment.id ?? idx}
                                  canUseExceptions={getCanUseExceptions(kpi.id)}
                                  canAddExceptions={comments.every((c) => !c.exceptionStatus)}
                                  maximumExceptionTime={kpi.maxVal}
                                />,
                                idx !== comments.length - 1 ? (
                                  <hr
                                    style={{
                                      margin: 0,
                                      opacity: 0.4,
                                      marginTop: 16,
                                      borderColor: colors.necron_compound_light,
                                    }}
                                  />
                                ) : null,
                              ];
                            return [
                              <SingleCommentBox
                                comment={comment}
                                paddingTop={idx > 0 ? 16 : 32}
                                commentViewMode={commentViewMode}
                                key={comment.id ?? idx}
                                setCurrentlyEditedComment={setCurrentlyEditedComment}
                                setCommentViewMode={setCommentViewMode}
                              />,
                              idx !== comments.length - 1 ? (
                                <hr
                                  style={{
                                    margin: 0,
                                    opacity: 0.4,
                                    marginTop: 16,
                                    borderColor: colors.necron_compound_light,
                                  }}
                                />
                              ) : null,
                            ];
                          })}
                        </CommentContainer>
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}
        </Container>
      );
    case TSummary.Well:
      return (
        <Container>
          <Title>Well Comment Summary</Title>
          {hasNoComments ? (
            <CommonStyled.NoCommentsContainer>
              <CommonStyled.CommentIcon>
                <PDComponent.SvgIcon name="chat" />
              </CommonStyled.CommentIcon>
              <h1>No comments entered yet.</h1>
            </CommonStyled.NoCommentsContainer>
          ) : (
            Object.keys(commentsByWellId).map((kpiId) => {
              const currentLens = userLenses.byId[+kpiId];
              const comments = commentsByWellId[+kpiId];
              const currentLensTab = lensTabs.find((tab) => tab.id === currentLens.lensTabId);
              const title = currentLensTab?.name || "Well Scores";
              const description = currentLensTab?.description || "";
              return (
                <>
                  <Title>
                    {title}
                    <span>{description}</span>
                  </Title>
                  <Subtitle>{getNameByKpiType(currentLens.cardKpiType) || "Well Scores"}</Subtitle>
                  <CommentContainer>
                    {comments.map((comment, idx) => {
                      if (
                        currentlyEditedComment?.lensId &&
                        commentViewMode !== CommentViewMode.LIST &&
                        comment.id === currentlyEditedComment?.id
                      )
                        return [
                          <CardCommentEdit
                            editedComment={currentlyEditedComment}
                            onEditModeClose={handleOnEditModeClose}
                            wellId={currentlyEditedComment.wellId}
                            lensId={currentlyEditedComment.lensId}
                            paddingTop={idx > 0 ? 16 : 32}
                            kpiType={currentlyEditedComment.kpiType}
                            scorecardType={currentlyEditedComment.scorecardType}
                            key={comment.id ?? idx}
                            canUseExceptions={getCanUseExceptions(+kpiId)}
                            canAddExceptions={comments.every((c) => !c.exceptionStatus)}
                            maximumExceptionTime={maxValByLens[+currentLens.id]}
                          />,
                          idx !== comments.length - 1 ? (
                            <hr
                              style={{
                                margin: 0,
                                opacity: 0.4,
                                marginTop: 16,
                                borderColor: colors.necron_compound_light,
                              }}
                            />
                          ) : null,
                        ];
                      return [
                        <SingleCommentBox
                          comment={comment}
                          paddingTop={idx > 0 ? 16 : 32}
                          commentViewMode={commentViewMode}
                          key={comment.id ?? idx}
                          setCurrentlyEditedComment={setCurrentlyEditedComment}
                          setCommentViewMode={setCommentViewMode}
                        />,
                        idx !== comments.length - 1 ? (
                          <hr
                            style={{
                              margin: 0,
                              opacity: 0.4,
                              marginTop: 16,
                              borderColor: colors.necron_compound_light,
                            }}
                          />
                        ) : null,
                      ];
                    })}
                  </CommentContainer>
                </>
              );
            })
          )}
        </Container>
      );
    default:
      return null;
  }
};
