/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperatorStatusType } from './OperatorStatusType';
import {
    OperatorStatusTypeFromJSON,
    OperatorStatusTypeFromJSONTyped,
    OperatorStatusTypeToJSON,
} from './OperatorStatusType';
import type { UserStatusType } from './UserStatusType';
import {
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface AccountQueryDto
 */
export interface AccountQueryDto {
    /**
     * 
     * @type {string}
     * @memberof AccountQueryDto
     */
    search?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AccountQueryDto
     */
    wellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AccountQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {Array<OperatorStatusType>}
     * @memberof AccountQueryDto
     */
    status?: Array<OperatorStatusType> | null;
    /**
     * 
     * @type {Array<UserStatusType>}
     * @memberof AccountQueryDto
     */
    userStatus?: Array<UserStatusType> | null;
}

/**
 * Check if a given object implements the AccountQueryDto interface.
 */
export function instanceOfAccountQueryDto(value: object): value is AccountQueryDto {
    return true;
}

export function AccountQueryDtoFromJSON(json: any): AccountQueryDto {
    return AccountQueryDtoFromJSONTyped(json, false);
}

export function AccountQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'search': json['Search'] == null ? undefined : json['Search'],
        'wellIds': json['WellIds'] == null ? undefined : json['WellIds'],
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
        'status': json['Status'] == null ? undefined : ((json['Status'] as Array<any>).map(OperatorStatusTypeFromJSON)),
        'userStatus': json['UserStatus'] == null ? undefined : ((json['UserStatus'] as Array<any>).map(UserStatusTypeFromJSON)),
    };
}

export function AccountQueryDtoToJSON(value?: AccountQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Search': value['search'],
        'WellIds': value['wellIds'],
        'RigIds': value['rigIds'],
        'Status': value['status'] == null ? undefined : ((value['status'] as Array<any>).map(OperatorStatusTypeToJSON)),
        'UserStatus': value['userStatus'] == null ? undefined : ((value['userStatus'] as Array<any>).map(UserStatusTypeToJSON)),
    };
}

