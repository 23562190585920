/* eslint-disable react/no-multi-comp */
import type { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import type { OperatorClaimDto, UserClaimDto, UserRigAccessDto } from "apis/oag";
import { ClaimPermissionType, ClaimType } from "apis/oag";
import { StyledCheckboxElem } from "atoms/Form";
import { Title } from "atoms/Typography";
import { useRigs } from "hooks/useRigs";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Col, Collapse, Divider, Row, Space } from "utils/componentLibrary";
import { PdSpaceItem } from "utils/componentLibrary/Space";
import { CheckboxState } from "utils/enums";
import { useCustomTheme } from "utils/useTheme";

type Props = {
  disabled?: boolean;
  children: React.ReactNode;
  // All other props
  [x: string]: unknown;
};

const StyledCheckbox: React.FC<Props> = (props) => {
  if (props.disabled) return props.children;
  return <StyledCheckboxElem {...props}>{props.children}</StyledCheckboxElem>;
};

const StyledCollapse = styled(Collapse)`
  background: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  border-radius: 10px;

  .ant-collapse-header {
    padding: 20px !important;

    .ant-collapse-arrow {
      right: 0 !important;
      padding: 20px !important;
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;

    ${PdSpaceItem} {
      padding: 16px 20px;
      margin: 0 !important;
    }
  }
`;

const StyledSpace = styled(Space)`
  width: 100%;
  ${PdSpaceItem} {
    width: 100%;
  }
`;

const StyledWellContainerSpace = styled(StyledSpace).attrs({ direction: "vertical", size: 0 })`
  width: 100%;
  ${PdSpaceItem} {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0 !important;
`;
type TSingleRigAccess = { wells: { [key: string]: boolean }; rig: { future: boolean } };

const WellAccessSingle = memo(
  ({
    rigAccess,
    rigName,
    selector,
    claimCallback,
    mode,
    viewOnly,
    deselectBack,
    commonFuture,
    setCanUpdate,
  }: {
    rigAccess: UserRigAccessDto;
    rigName: string,
    selector: number;
    claimCallback: (x: any, y: any) => void;
    mode: "user" | "operator";
    viewOnly: boolean;
    deselectBack: (x: (y: boolean) => void) => void;
    commonFuture: boolean;
    setCanUpdate?: React.Dispatch<boolean>;
  }) => {
    const claims = useRef<UserClaimDto[] | OperatorClaimDto[]>([]);

    const [commonCheckbox, setCommonCheckbox] = useState(CheckboxState.None);

    const { atomThemeVariant } = useCustomTheme();
    useEffect(() => {
      deselectBack((target: boolean) => {
        onCheckAllChange({
          target: {
            checked: target,
          },
        });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deselectBack]);

    const [fields, setFields] = useState<TSingleRigAccess>({ wells: {}, rig: { future: false } });

    const onValuesChange = useCallback(
      (fields: TSingleRigAccess) => {
        setCanUpdate?.(true);
        setFields(fields);
        // @ts-ignore
        claims.current = Object.keys(fields.wells ?? {}).map((e) => ({
          [mode === "user" ? "userId" : "operatorId"]: selector,
          wellId: Number(e),
          rigId: rigAccess.rigId,
          type: ClaimType.Well,
          permission: fields.wells[e] === true ? ClaimPermissionType.All : ClaimPermissionType.None,
        }));

        if (rigAccess.futureWellAccess !== undefined)
          // @ts-ignore
          claims.current.push({
            [mode === "user" ? "userId" : "operatorId"]: selector,
            rigId: rigAccess.rigId,
            type: ClaimType.FutureWellAccess,
            permission: (fields.rig?.future || false) === false ? ClaimPermissionType.None : ClaimPermissionType.All,
          });
        claimCallback(rigAccess.rigId, claims.current);
        if (fields.wells) {
          const currentList: Array<boolean> = Array.from(new Set(Object.values(fields.wells)));
          if (currentList.length === 1) {
            if (rigAccess.futureWellAccess === undefined || fields.rig.future === currentList[0]) {
              setCommonCheckbox(currentList[0] ? CheckboxState.Selected : CheckboxState.None);
              return;
            }
            setCommonCheckbox(CheckboxState.Partially);
          } else {
            setCommonCheckbox(CheckboxState.Partially);
          }
        } else {
          setCommonCheckbox(
            rigAccess.futureWellAccess !== undefined && fields.rig?.future
              ? CheckboxState.Selected
              : CheckboxState.None,
          );
        }
      },
      [claimCallback, mode, rigAccess.rigId, selector, setCanUpdate, rigAccess.futureWellAccess],
    );

    const onCheckAllChange = (f: Partial<CheckboxChangeEvent>) => {
      const newFieldsValue = {
        wells: (rigAccess.wells ?? []).reduce<{ [key: string]: boolean }>(
          (prev, curr) => ({
            ...prev,
            [`${curr.id}`]: !!f?.target?.checked,
          }),
          {},
        ),
        rig: {
          future: rigAccess.futureWellAccess !== undefined ? !!f?.target?.checked : false,
        },
      };

      setCommonCheckbox(f?.target?.checked ? CheckboxState.Selected : CheckboxState.None);
      onValuesChange(newFieldsValue);
    };

    useEffect(() => {
      if (rigAccess.futureWellAccess === undefined || commonFuture === undefined) return;
      const newFields = {
        ...fields,
        rig: {
          future: commonFuture,
        },
      };

      onValuesChange(newFields);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonFuture, rigAccess.futureWellAccess]);

    useEffect(() => {
      if (rigAccess) {
        onValuesChange({
          ...fields,
          wells: (rigAccess.wells ?? []).reduce<{ [key: string]: boolean }>(
            (prev, curr) => ({
              ...prev,
              [`${curr.id}`]: curr.permission === ClaimPermissionType.All,
            }),
            {},
          ),
        });
        if (rigAccess.futureWellAccess !== undefined)
          if ((rigAccess.wells ?? [])?.length > 0) {
            onValuesChange({
              wells: (rigAccess.wells ?? []).reduce<{ [key: string]: boolean }>(
                (prev, curr) => ({
                  ...prev,
                  [`${curr.id}`]: curr.permission === ClaimPermissionType.All,
                }),
                {},
              ),
              rig: {
                future: !!rigAccess.futureWellAccess,
              },
            });
            const currentList = [...new Set((rigAccess.wells ?? []).map((e) => e.permission))];
            if (currentList.length === 1) {
              if (
                rigAccess.futureWellAccess === undefined ||
                rigAccess.futureWellAccess === (currentList[0] === ClaimPermissionType.All)
              ) {
                setCommonCheckbox(
                  currentList[0] === ClaimPermissionType.All ? CheckboxState.Selected : CheckboxState.None,
                );
                return;
              }
              setCommonCheckbox(CheckboxState.Partially);
            } else {
              setCommonCheckbox(CheckboxState.Partially);
            }
          } else {
            setCommonCheckbox(rigAccess.futureWellAccess ? CheckboxState.Selected : CheckboxState.None);
          }
        // @ts-ignore
        claims.current = rigAccess.wells.map((e) => ({
          [mode === "user" ? "userId" : "operatorId"]: selector,
          wellId: e.id,
          rigId: rigAccess.rigId,
          type: ClaimType.Well,
          permission: e.permission,
        }));
        if (rigAccess.futureWellAccess !== undefined)
          // @ts-ignore
          claims.current.push({
            [mode === "user" ? "userId" : "operatorId"]: selector,
            rigId: rigAccess.rigId,
            type: ClaimType.FutureWellAccess,
            permission: rigAccess.futureWellAccess === true ? ClaimPermissionType.All : ClaimPermissionType.None,
          });
        claimCallback(rigAccess.rigId, claims.current);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, rigAccess, selector]);

    return (
      <StyledCollapse ghost expandIconPosition="right">
        <StyledCollapse.Panel
          key={1}
          header={
            <StyledCheckbox
              style={{ display: "inline-flex" }}
              checked={commonCheckbox === CheckboxState.Selected}
              indeterminate={commonCheckbox === CheckboxState.Partially}
              onChange={onCheckAllChange}
              disabled={viewOnly}
            >
              <Title level={3} variant={atomThemeVariant}>
                Rig {rigName}
              </Title>
            </StyledCheckbox>
          }
          forceRender
        >
          <StyledDivider type="horizontal" />
          <StyledWellContainerSpace>
            {(rigAccess.wells ?? []).map((e) => {
              return (
                <StyledCheckbox
                  key={`wells${e.id}`}
                  disabled={viewOnly}
                  checked={fields.wells[`${e.id}`]}
                  onChange={() => {
                    onValuesChange({
                      ...fields,
                      wells: {
                        ...fields.wells,
                        [`${e.id}`]: !fields.wells[`${e.id}`],
                      },
                    });
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col flex="0 auto">
                      <Title level={3} variant={atomThemeVariant}>
                        {e.name}
                      </Title>
                    </Col>
                    <Col flex="0 auto">
                      <Title level={3} variant="faded">
                        {e.status}
                      </Title>
                    </Col>
                  </Row>
                </StyledCheckbox>
              );
            })}
            {rigAccess.futureWellAccess !== undefined && (
              <StyledCheckbox
                disabled={viewOnly}
                checked={fields.rig.future}
                onChange={() => {
                  onValuesChange({
                    ...fields,
                    rig: {
                      future: !fields.rig.future,
                    },
                  });
                }}
              >
                <Title level={3} variant={atomThemeVariant}>
                  All Future Wells
                </Title>
              </StyledCheckbox>
            )}
          </StyledWellContainerSpace>
        </StyledCollapse.Panel>
      </StyledCollapse>
    );
  },
);

export const WellAccessMultiple = memo(
  ({
    allRigAccesses: allRigAccesses,
    selector,
    allClaimsCallback = () => void 0,
    mode,
    viewOnly = false,
    deselectBack = () => void 0,
    commonFuture,
    setCanUpdate,
  }: {
    allRigAccesses: UserRigAccessDto[];
    selector: number;
    commonFuture?: boolean;
    allClaimsCallback?: (x: UserClaimDto[] | OperatorClaimDto[]) => void;
    mode: "user" | "operator";
    viewOnly?: boolean;
    deselectBack?: (x: (y: boolean) => void) => void;
    setCanUpdate?: React.Dispatch<boolean>;
  }) => {
    const rigs = useRigs();
    const allClaimRef = useRef<{ [id: string]: UserClaimDto[] | OperatorClaimDto[] }>({});
    const allDeselectFunc = useRef<{ [x: number]: (y: boolean) => void }>({});

    const saveClaims = useCallback(
      (rid: number, claims: any) => {
        allClaimRef.current[rid] = claims;

        // @ts-ignore
        allClaimsCallback(Object.values(allClaimRef.current).flat());
      },
      [allClaimsCallback],
    );
    const deselectBackFn = useCallback(
      (rigId: number) => (f: (y: boolean) => void) => (allDeselectFunc.current[rigId] = f),
      [allDeselectFunc],
    );

    useEffect(() => {
      let initialClaims: Array<UserClaimDto | OperatorClaimDto> = [];
      allClaimRef.current = {};
      Object.values(allRigAccesses ?? []).forEach((rig) => {
        const claimsPerRig: Array<UserClaimDto | OperatorClaimDto> = [];
        if (rig.futureWellAccess !== undefined) {
          // @ts-ignore
          claimsPerRig.push({
            [mode === "user" ? "userId" : "operatorId"]: selector,
            rigId: rig.rigId,
            type: ClaimType.FutureWellAccess,
            permission: rig.futureWellAccess === true ? ClaimPermissionType.All : ClaimPermissionType.None,
          });
        }

        (rig.wells ?? []).forEach((well) => {
          // @ts-ignore
          claimsPerRig.push({
            ...well,
            [mode === "user" ? "userId" : "operatorId"]: selector,
            wellId: Number(well.id),
            rigId: rig.rigId,
            type: ClaimType.Well,
          });
        });
        initialClaims = [...claimsPerRig, ...initialClaims];

        // @ts-ignore
        allClaimRef.current[rig.rigId] = claimsPerRig;
      });

      // @ts-ignore
      allClaimsCallback(initialClaims);
    }, [allClaimsCallback, allRigAccesses, mode, selector]);

    const deselectAll = (target: boolean) => {
      Object.keys(allDeselectFunc.current).forEach((k) => {
        allDeselectFunc.current[Number(k)](target);
      });
    };

    useEffect(() => {
      deselectBack(deselectAll);
    }, [deselectBack]);

    return (
      <StyledSpace direction="vertical" size={12}>
        {allRigAccesses?.filter(e => rigs.data?.byId[e?.rigId]).map((e) => (
          <WellAccessSingle
            setCanUpdate={setCanUpdate}
            rigName={rigs.data?.byId[e?.rigId]?.shortName || ""}
            key={e.rigId}
            rigAccess={e}
            selector={selector}
            claimCallback={saveClaims}
            mode={mode}
            viewOnly={viewOnly}
            commonFuture={!!commonFuture}
            deselectBack={deselectBackFn(e.rigId)}
          />
        ))}
      </StyledSpace>
    );
  },
);
