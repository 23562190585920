export const MockFuelTankVolumeSvg = () => {
  return (
    <svg width="326" height="126" viewBox="0 0 326 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1616_25426"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="326"
        height="126"
      >
        <rect width="326" height="126" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1616_25426)">
        <g opacity="0.6">
          <rect opacity="0.08" y="62" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="41" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="20" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="83" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="104" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="125" width="326" height="1" fill="#828C91" />
          <rect opacity="0.08" y="125" width="326" height="1" fill="#131B1D" />
        </g>
        <path
          d="M93.1429 95.0347L45.3249 70.0455L0 47V125H326V67.4225L275.464 47V95.0347L189.541 47V95.0347L144.822 71.5227L93.1429 47V95.0347Z"
          fill="url(#paint0_linear_1616_25426)"
        />
        <path
          d="M326 67.4077L275.464 47V95L189.541 47V95L144.822 71.505L93.1429 47V95L45.3249 70.0288L0 47"
          stroke="#D69985"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_1616_25426" x1="163" y1="47" x2="163" y2="125" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D69985" />
          <stop offset="1" stopColor="#D69985" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
