/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WellStatusType } from './WellStatusType';
import {
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';

/**
 * 
 * @export
 * @interface FiltersDto
 */
export interface FiltersDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    sectionIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    directionalIntervalIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    holeSizeIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    phaseIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    crewMembers?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    includeFlatTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    includeSlidingTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    includeRotatingTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    includeNullHoleDepth: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    includeAlphaRigs: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltersDto
     */
    includeNoneAlphaRigs: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltersDto
     */
    selectedClasses?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    selectedCountryIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersDto
     */
    selectedHorsePowers?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltersDto
     */
    selectedOperatingCenters?: Array<string> | null;
    /**
     * 
     * @type {Array<WellStatusType>}
     * @memberof FiltersDto
     */
    selectedWellStatusTypes?: Array<WellStatusType> | null;
}

/**
 * Check if a given object implements the FiltersDto interface.
 */
export function instanceOfFiltersDto(value: object): value is FiltersDto {
    if (!('includeFlatTime' in value) || value['includeFlatTime'] === undefined) return false;
    if (!('includeSlidingTime' in value) || value['includeSlidingTime'] === undefined) return false;
    if (!('includeRotatingTime' in value) || value['includeRotatingTime'] === undefined) return false;
    if (!('includeNullHoleDepth' in value) || value['includeNullHoleDepth'] === undefined) return false;
    if (!('includeAlphaRigs' in value) || value['includeAlphaRigs'] === undefined) return false;
    if (!('includeNoneAlphaRigs' in value) || value['includeNoneAlphaRigs'] === undefined) return false;
    return true;
}

export function FiltersDtoFromJSON(json: any): FiltersDto {
    return FiltersDtoFromJSONTyped(json, false);
}

export function FiltersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FiltersDto {
    if (json == null) {
        return json;
    }
    return {
        
        'sectionIds': json['SectionIds'] == null ? undefined : json['SectionIds'],
        'directionalIntervalIds': json['DirectionalIntervalIds'] == null ? undefined : json['DirectionalIntervalIds'],
        'holeSizeIds': json['HoleSizeIds'] == null ? undefined : json['HoleSizeIds'],
        'phaseIds': json['PhaseIds'] == null ? undefined : json['PhaseIds'],
        'crewMembers': json['CrewMembers'] == null ? undefined : json['CrewMembers'],
        'includeFlatTime': json['IncludeFlatTime'],
        'includeSlidingTime': json['IncludeSlidingTime'],
        'includeRotatingTime': json['IncludeRotatingTime'],
        'includeNullHoleDepth': json['IncludeNullHoleDepth'],
        'includeAlphaRigs': json['IncludeAlphaRigs'],
        'includeNoneAlphaRigs': json['IncludeNoneAlphaRigs'],
        'selectedClasses': json['SelectedClasses'] == null ? undefined : json['SelectedClasses'],
        'selectedCountryIds': json['SelectedCountryIds'] == null ? undefined : json['SelectedCountryIds'],
        'selectedHorsePowers': json['SelectedHorsePowers'] == null ? undefined : json['SelectedHorsePowers'],
        'selectedOperatingCenters': json['SelectedOperatingCenters'] == null ? undefined : json['SelectedOperatingCenters'],
        'selectedWellStatusTypes': json['SelectedWellStatusTypes'] == null ? undefined : ((json['SelectedWellStatusTypes'] as Array<any>).map(WellStatusTypeFromJSON)),
    };
}

export function FiltersDtoToJSON(value?: FiltersDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'SectionIds': value['sectionIds'],
        'DirectionalIntervalIds': value['directionalIntervalIds'],
        'HoleSizeIds': value['holeSizeIds'],
        'PhaseIds': value['phaseIds'],
        'CrewMembers': value['crewMembers'],
        'IncludeFlatTime': value['includeFlatTime'],
        'IncludeSlidingTime': value['includeSlidingTime'],
        'IncludeRotatingTime': value['includeRotatingTime'],
        'IncludeNullHoleDepth': value['includeNullHoleDepth'],
        'IncludeAlphaRigs': value['includeAlphaRigs'],
        'IncludeNoneAlphaRigs': value['includeNoneAlphaRigs'],
        'SelectedClasses': value['selectedClasses'],
        'SelectedCountryIds': value['selectedCountryIds'],
        'SelectedHorsePowers': value['selectedHorsePowers'],
        'SelectedOperatingCenters': value['selectedOperatingCenters'],
        'SelectedWellStatusTypes': value['selectedWellStatusTypes'] == null ? undefined : ((value['selectedWellStatusTypes'] as Array<any>).map(WellStatusTypeToJSON)),
    };
}

