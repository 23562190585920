import { AxisLeft } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { Threshold } from "@visx/threshold";
import type { TagBottomFingerprintFactSetDto } from "apis/oag";
import { DimensionType, TrackFactType } from "apis/oag";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { bisector, curveMonotoneX } from "d3";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import { useTracks } from "hooks/useTracks";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import styled, { useTheme } from "styled-components";
import colors from "utils/colors";
import { useUOM } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

import { TagBottomFingerPrintContext } from "./Chart";

const StyledCircle = styled.circle`
  fill: ${colors.white};
  stroke: ${colors.black};
  -webkit-filter: drop-shadow(0p 0px 2px rgba(0, 0, 0, 1));
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 1));
  stroke-width: 2.5px;
  z-index: ${zIndexLayer.sky};
`;
import { useZoom } from "components/Lenses/common/LensZoom/useZoom";
import { ZoomChartContext } from "components/Lenses/common/LensZoom/ZoomChartContext";
import { PDComponent } from "components/PDComponents";
import { omit } from "lodash";
import { Tooltip } from "utils/componentLibrary";
import { zIndexLayer } from "utils/zIndex";
const getMax = (arr: number[]) => {
  const max = arr.reduce((acc, curr) => Math.max(acc, curr), -Infinity);
  if (max > 0) return max;
  return 0;
};
const getMin = (arr: number[]) => {
  const min = arr.reduce((acc, curr) => Math.min(acc, curr), Infinity);
  if (min > 0) return min;
  return 0;
};

export const StyledTrack = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  grid-template-columns: 60px 46px 1fr;

  tspan {
    fill: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const ContainerDivider = styled.div`
  border-right: 1px solid ${colors.necron_compound_light};
  background: ${({ theme }) => theme.themeStyle.colors.off_secondary_bg};
`;

export const CenterText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;

  .rotate {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  }
  .gray {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    font-size: 14px;
    font-weight: 400;
  }
`;

const IndicatorContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  span {
    margin-top: 2.5px;
    margin-right: 5px;
    padding-right: 15px;
    color: "#3FE3BC";
    fill: "#3FE3BC";
  }
`;
export const Track = ({
  track,
  isOverall,
  lensId,
  showAlphaConnection,
  currentPointerPosition,
  setCurrentPointerPosition,
  setChartWidth,
  normalizeBlockHeight,
}: {
  track: TagBottomFingerprintFactSetDto["tracks"][number];
  isOverall: boolean;
  lensId: number;
  showAlphaConnection: boolean;
  currentPointerPosition: number | null;
  setCurrentPointerPosition: React.Dispatch<React.SetStateAction<number | null>>;
  setChartWidth: React.Dispatch<React.SetStateAction<number>>;
  normalizeBlockHeight: boolean;
}) => {
  const { alphaStands, outlierStands, selectedStand, hoveredStand } = useContext(TagBottomFingerPrintContext);
  const { data: tracks } = useTracks();

  const { width, height, ref: containerRef } = useResizeDetector();
  const chartHeightHook = (height || 110) - 5;
  const chartWidthHook = (width || 110) - 60 - 50;
  const valueUom = useUOM(tracks?.byId?.[track.trackId]?.dimension || DimensionType.Undefined);

  const zoomChartContext = useContext(ZoomChartContext);
  if (!zoomChartContext) {
    throw new Error("ZoomChartContext is not defined");
  }

  const { domain } = zoomChartContext;

  const valueScale = useMemo(() => {
    const trackValues = isOverall
      ? track.overallFacts.map((p) => p.average)
      : track.standSeries.flatMap((p) => p.facts);
    return scaleLinear({
      domain: [getMax(trackValues), getMin(trackValues)],
      range: [0, chartHeightHook],
    });
  }, [chartHeightHook, isOverall, track.overallFacts, track.standSeries]);

  const xScale = useMemo(() => {
    // need to add the scroll inside here
    return scaleLinear({
      domain: domain || [0, 0],
      range: [0, chartWidthHook],
    });
  }, [chartWidthHook, domain]);

  const { yDisplayOnlyScale, verticalAxisTicksCount } = useYAxisDisplayScale({
    originalScale: valueScale,
    uom: valueUom,
    tickContainerHeight: chartHeightHook - 10,
    isManual: false,
  });

  const trackInfo = tracks?.byId?.[track.trackId];
  const trackName = trackInfo?.name ?? "";
  const seriesLength = useMemo(
    () =>
      isOverall
        ? track.overallFacts.length
        : track.standSeries.reduce((acc, curr) => {
            return Math.max(acc, curr.facts.length);
          }, 0),
    [isOverall, track.overallFacts, track.standSeries],
  );

  const numValueTicks = useMemo(() => verticalAxisTicksCount, [verticalAxisTicksCount]);
  const theme = useTheme();
  const { selectionRectangleElement, pointerPosition, zoomEvents } = useZoom({
    xScale,
    yScaleBrush: valueScale,
    series: Array.from({ length: seriesLength }, (_, index) => ({ index })),
    plotWidth: chartWidthHook,
    plotHeight: chartHeightHook,
    plotWidthOffset: 38,
  });

  useEffect(() => {
    setChartWidth(chartWidthHook);
  }, [chartWidthHook, setChartWidth]);
  useEffect(() => {
    setCurrentPointerPosition(pointerPosition);
  }, [pointerPosition, setCurrentPointerPosition]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<{
    value: number;
    index: number;
    stdDev: number;
    isOverall: boolean;
  }>({
    containerRef,
    tbfTooltip: true,
    renderContent: ({ tooltipData }) => {
      return (
        <>
          <TooltipHighlightValue>
            {trackName}: {valueUom.display(tooltipData?.value)}
            {tooltipData?.isOverall ? " avg" : ""}
          </TooltipHighlightValue>
          {tooltipData?.isOverall ? (
            <>
              <span>
                2σ Range: {valueUom.display(Math.max(0, tooltipData.value - tooltipData.stdDev))} to{" "}
                {valueUom.display(tooltipData.value + tooltipData.stdDev)}
              </span>
              <span>{tooltipData.index + 1} Sec</span>
            </>
          ) : null}
        </>
      );
    },
  });

  const getPointForChartPosition = useCallback(
    (series: { index: number; value: number }[], pointIndexInSeries: number) => {
      if (!series) {
        hideTooltip();
        return null;
      }
      let selectedPoint: { index: number; value: number } | null = null;

      const index = bisector<{ index: number }, number>((p) => p.index).left(series, pointIndexInSeries);

      if (index > 0 && index < series.length && Number.isFinite(series[index - 1].index)) {
        selectedPoint = series[index - 1];
      }

      if (!selectedPoint) {
        hideTooltip();
        return null;
      }

      showTooltip({
        tooltipLeft: xScale(selectedPoint.index) + (isOverall ? 250 : 220),
        tooltipTop: valueScale(selectedPoint.value) + (isOverall ? 45 : 30),
        tooltipData: {
          value: selectedPoint.value,
          index: selectedPoint.index,
          isOverall,
          stdDev: track.overallFacts[selectedPoint.index]?.stdDev,
        },
      });
      return selectedPoint;
    },
    [hideTooltip, isOverall, showTooltip, track.overallFacts, valueScale, xScale],
  );
  const tooltipInfo = useMemo(() => {
    if (!currentPointerPosition) return null;
    const series = (
      isOverall
        ? track.overallFacts.map((x) => x.average)
        : track.standSeries.find((stand, index) =>
            selectedStand !== -1 ? stand.standId === selectedStand : index === 0,
          )?.facts || []
    ).map((x, index) => ({
      index,
      value: x,
    }));
    const point = getPointForChartPosition(series, xScale.invert(currentPointerPosition));
    if (!point) {
      setCurrentPointerPosition(null);
      return null;
    }
    return {
      x: xScale(point.index) + 36,
      y: valueScale(point.value),
      value: point.value,
      uom: valueUom,
    };
  }, [
    currentPointerPosition,
    isOverall,
    track.overallFacts,
    track.standSeries,
    getPointForChartPosition,
    xScale,
    valueScale,
    valueUom,
    selectedStand,
    setCurrentPointerPosition,
  ]);

  const { themeStyle } = useCustomTheme();
  useEffect(() => {
    const element = document.getElementById("scroll-container-id-fix-tbf");
    const onScroll = () => {
      hideTooltip();
      setCurrentPointerPosition(null);
    };
    element?.addEventListener("scroll", onScroll);
    return () => {
      element?.removeEventListener("scroll", onScroll);
    };
  }, [hideTooltip, setCurrentPointerPosition]);

  if (!tracks || !track) return null;
  return (
    <div
      style={{
        padding: 0,
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "visible",
        borderBottom: `1px solid ${colors.necron_compound_light}`,
      }}
      ref={containerRef}
    >
      <StyledTrack>
        <ContainerDivider
          style={{
            position: "relative",
          }}
        >
          <CenterText>
            <div className="rotate">{trackName}</div>
          </CenterText>
        </ContainerDivider>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "25px 1fr",
            paddingRight: 60,
            borderRight: `1px solid ${colors.necron_compound_light}`,
            background: themeStyle.colors.off_secondary_bg,
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <CenterText>
              <div className="rotate gray">{valueUom.abbr}</div>
            </CenterText>
          </div>
          <svg
            width={chartWidthHook}
            height={0}
            // this height calculation does not work
            style={{
              overflow: "visible",
              userSelect: "none",
              paddingTop: 5,
            }}
            {...zoomEvents}
          >
            <rect
              x={36}
              y={-5}
              width={chartWidthHook + 36}
              height={chartHeightHook + 5}
              fill={theme.themeStyle.colors.primary_scroll_bg}
              opacity={0.1}
              clipPath={`url(#${track.trackId}-${lensId}`}
            />
            <AxisLeft
              scale={yDisplayOnlyScale}
              hideTicks
              left={40}
              top={2.5}
              tickComponent={(props) => {
                if (+props.y + 5 >= chartHeightHook) return null;
                return (
                  <text
                    {...omit(props, ["formattedValue"])}
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      fill: themeStyle.colors.disabled_typography,
                    }}
                  >
                    {props.formattedValue}
                  </text>
                );
              }}
              numTicks={Math.max(numValueTicks, 5) - 1}
              hideAxisLine
            />
            <RectClipPath
              y={-5}
              id={`${track.trackId}-${lensId}`}
              height={chartHeightHook + 5}
              width={chartWidthHook + 36}
              x={0}
            />

            <Group left={36} clipPath={`url(#${track.trackId}-${lensId}`}>
              {(() => {
                if (isOverall) {
                  const facts = track.overallFacts.map((fact) => fact.average);
                  return (
                    <>
                      <Threshold
                        id={`threshold-${track.trackId}-${lensId}`}
                        data={track.overallFacts}
                        x={(d, i) => xScale(i)}
                        y0={(d) => {
                          return valueScale(d ? Math.max(0, d.average - d.stdDev) : 0);
                        }}
                        y1={(d) => {
                          return valueScale(d ? d.average + d.stdDev : 0);
                        }}
                        clipAboveTo={(d) => valueScale(d ? d.average + d.stdDev : 0)}
                        clipBelowTo={(d) => valueScale(d ? Math.max(0, d.average - d.stdDev) : 0)}
                        curve={curveMonotoneX}
                        belowAreaProps={{
                          fill: colors.well_color,
                          fillOpacity: 0.4,
                        }}
                        aboveAreaProps={{
                          fill: "none",
                          fillOpacity: 0.4,
                        }}
                      />
                      <LinePath
                        key={track.trackId}
                        curve={curveMonotoneX}
                        data={facts}
                        x={(d, i) => xScale(i)}
                        y={(d) => (d ? valueScale(d) : valueScale(0))}
                        stroke={colors.well_color}
                        strokeWidth={2}
                        opacity={1}
                      />
                    </>
                  );
                }
                return track.standSeries.map((fact, index) => {
                  const isAlphaConnectionView = showAlphaConnection && alphaStands.length > 0;
                  const isAlpha = isAlphaConnectionView && alphaStands.includes(fact.standId);
                  const isSelected = selectedStand === fact.standId || hoveredStand === fact.standId;
                  const isInteracted = selectedStand !== -1 || hoveredStand !== -1;
                  const isOutlierStand = outlierStands.includes(fact.standId);
                  const getColor = () => {
                    if (isInteracted && !isSelected) return colors.off_state;
                    if (isAlphaConnectionView) {
                      if (isAlpha) return colors.turtle_green;
                      return colors.off_state;
                    }
                    if (isOutlierStand) return colors.off_state;
                    return colors.well_color;
                  };
                  if (!isInteracted)
                    return (
                      <LinePath
                        key={fact.standId}
                        curve={curveMonotoneX}
                        data={fact.facts}
                        x={(_d, i) => (i ? xScale(i) : 0)}
                        y={(d) => {
                          return d ? valueScale(d) : valueScale(0);
                        }}
                        stroke={getColor()}
                        strokeWidth={index === 0 ? 2 : 1}
                        opacity={1 - (index >= 8 ? 8 : index) * 0.1}
                      />
                    );
                  return (
                    <LinePath
                      key={fact.standId}
                      curve={curveMonotoneX}
                      data={fact.facts}
                      x={(_d, i) => (i ? xScale(i) : 0)}
                      y={(d) => {
                        return d ? valueScale(d) : valueScale(0);
                      }}
                      stroke={getColor()}
                      strokeWidth={isSelected ? 2 : 1}
                      opacity={isSelected ? 1 : 0.2}
                    />
                  );
                });
              })()}
            </Group>
            {tooltipInfo ? (
              <>
                <Group left={tooltipInfo.x} top={0}>
                  <line
                    x1={0}
                    x2={0}
                    y1={0}
                    y2={chartHeightHook}
                    stroke={themeStyle.colors.black_white}
                    opacity={0.3}
                    strokeWidth={2}
                    id="last"
                  />
                  <StyledCircle cx={0} id="one" cy={tooltipInfo.y} r={3} />
                </Group>
                {tooltipElement}
              </>
            ) : null}
            {selectionRectangleElement}
          </svg>
        </div>
        {normalizeBlockHeight && trackInfo?.factType === TrackFactType.BlockHeight ? (
          <IndicatorContainer>
            <Tooltip placement="topRight" title="Block Height values have been normalized">
              <PDComponent.SvgIcon name="informationColored" />
            </Tooltip>
          </IndicatorContainer>
        ) : null}
      </StyledTrack>
    </div>
  );
};
