import styled, { css } from "styled-components";
import { Col } from "utils/componentLibrary";

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
`;

export const FallbackContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
`;

export const StyledColTransition = styled(Col)<{ width?: string }>`
  display: block !important;
  transition: all 0.4s ease;
  background: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  border-left: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;
