import { LinearGradient } from "@visx/gradient";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import { Pie } from "@visx/shape";
import type { DieselNatGasRatioStatsUserLensDto } from "apis/oag";
import { ResultDataState } from "apis/oag";
import NoData from "components/Lenses/common/NoData";
import { fuelColors } from "components/Lenses/ContainerLens/common/utils/utils";
import { CHART_MARGIN_RATIO, LENS_TITLE } from "components/Lenses/ContainerLens/DieselNatGasRatio/utils";
import { NoDataText } from "components/Lenses/ContainerLens/StatCard/Chart/GenericStatCard/styled";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { useLensMouseIn } from "hooks/useLensMouseIn";
import { useLensSize } from "hooks/useLensSize";
import { min } from "lodash";
import type { FC } from "react";
import { useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { ratioToPercent } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import * as Styled from "./styles";

interface DieselNatGasRatioChartProps {
  dieselRatio: number;
  natgasRatio: number;
  dataState: ResultDataState;
  lens: DieselNatGasRatioStatsUserLensDto;
  isLoading: boolean;
}

export const DieselNatGasRatioChart: FC<DieselNatGasRatioChartProps> = ({
  dieselRatio,
  natgasRatio,
  lens,
  dataState,
  isLoading,
}) => {
  const { mouseIn } = useLensMouseIn();
  const [width, height] = useLensSize(lens.id);

  const { atomThemeVariant } = useCustomTheme();

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const outerRadius = useMemo(() => {
    const chartMargin = (min([chartWidth, chartHeight]) || 0) / CHART_MARGIN_RATIO;
    const availableWidth = chartWidth / 2 - chartMargin;
    const availableHeight = chartHeight - chartMargin * 2;

    return min([availableWidth, availableHeight]);
  }, [chartWidth, chartHeight]);

  const color = scaleOrdinal<string, string>({
    domain: [fuelColors.diesel, fuelColors.natGas],
    range: ["url(#gradient_diesel)", "url(#gradient_natGas)"],
  });

  if (isLoading) {
    return (
      <Styled.StatCard height={height} width={width} $mouseIn={mouseIn} $isLoading>
        <Styled.LensTitle>Loading...</Styled.LensTitle>
        <Styled.TextContainer>
          <Styled.NoDataText variant={atomThemeVariant}>...</Styled.NoDataText>
        </Styled.TextContainer>
      </Styled.StatCard>
    );
  }

  return (
    <Styled.StatCard
      height={height}
      width={width}
      $mouseIn={mouseIn}
      $isLoading={isLoading || dataState !== ResultDataState.Valid}
    >
      <Styled.LensTitle>{LENS_TITLE}</Styled.LensTitle>
      {dataState !== ResultDataState.Valid ? (
        <Styled.TextContainer>
          {height > 1 && width > 2 ? <NoData isStatic /> : <NoDataText>No data found</NoDataText>}
        </Styled.TextContainer>
      ) : (
        <Styled.ValueContainer>
          <Styled.FuelContainer>
            <Styled.IconContainer>
              <PDComponent.SvgIcon name="diesel" />
            </Styled.IconContainer>
            <Styled.Value $color={fuelColors.diesel}>{ratioToPercent(dieselRatio)}</Styled.Value>
          </Styled.FuelContainer>
          <Styled.FuelContainer>
            <Styled.IconContainer>
              <PDComponent.SvgIcon name="naturalGas" />
            </Styled.IconContainer>
            <Styled.Value $color={fuelColors.natGas}>{ratioToPercent(natgasRatio)}</Styled.Value>
          </Styled.FuelContainer>
        </Styled.ValueContainer>
      )}
      {dataState === ResultDataState.Valid ? (
        <Styled.ChartContainer ref={containerRef}>
          <svg width={chartWidth} height={chartHeight} style={{ overflow: "hidden", userSelect: "none" }}>
            <LinearGradient
              id="gradient_diesel"
              from={fuelColors.diesel}
              to={fuelColors.diesel}
              fromOpacity={0.5}
              toOpacity={1}
            />
            <LinearGradient
              id="gradient_natGas"
              from={fuelColors.natGas}
              to={fuelColors.natGas}
              fromOpacity={0.5}
              toOpacity={1}
            />
            <Group>
              <Group top={chartHeight} left={chartWidth / 2}>
                <Pie
                  data={[
                    {
                      value: dieselRatio,
                      fill: fuelColors.diesel,
                    },
                    {
                      value: natgasRatio,
                      fill: fuelColors.natGas,
                    },
                  ]}
                  pieValue={(d) => d.value}
                  pieSort={null}
                  outerRadius={outerRadius}
                  innerRadius={(outerRadius || 0) / 2}
                  startAngle={-(Math.PI / 2)}
                  endAngle={Math.PI / 2}
                >
                  {(pie) => {
                    return pie.arcs.map((arc, index) => {
                      return (
                        <g key={`arc-${index}`}>
                          <path d={pie.path(arc) || undefined} fill={color(arc.data.fill)} />
                        </g>
                      );
                    });
                  }}
                </Pie>
              </Group>
            </Group>
          </svg>
        </Styled.ChartContainer>
      ) : null}
    </Styled.StatCard>
  );
};
