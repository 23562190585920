import { RigScorecardLensType, type RigScorecardUserLensDto } from "apis/oag";
import React from "react";

import { LensGroup } from "./LensGroup";

type TLensGroup = {
    title: string;
    id: number;
    lenses: RigScorecardUserLensDto[];
    description: string;
};

type TRef = {
    innerBoxRefArray: React.MutableRefObject<HTMLDivElement[]>;
    outerBoxRef: React.MutableRefObject<HTMLDivElement[]>;
}
type Props = {
    lensGroup: TLensGroup;
    lensesGroups: TLensGroup[];
    maxLen: number | undefined;
    index: number;
    refs: TRef
}

export const LensTabGroup: React.FC<Props> =
    ({
        lensGroup,
        index,
        lensesGroups,
        maxLen,
        refs
    }) => {
        const { outerBoxRef, innerBoxRefArray } = refs;
        return (
            <LensGroup
                ref={(el) => {
                    if (outerBoxRef.current && el) {
                        outerBoxRef.current[index] = el;
                    }
                }}
                description={lensGroup?.description ?? ""}
                innerBoxRefArray={innerBoxRefArray}
                key={lensGroup.id}
                title={lensGroup.title ?? ""}
                isOverAllWellScore={
                    lensGroup.lenses.some(
                        (lens) => lens.scorecardType === RigScorecardLensType.OverallWellScores,
                    )
                }
                maxLen={maxLen || 0}
                index={index}
                lensGroup={lensGroup}
                lensesGroups={lensesGroups}
            />
        );
    };