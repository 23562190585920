import { Title } from "atoms/Typography";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { PDComponent } from "components/PDComponents";
import styled from "styled-components";
import colors from "utils/colors";
import { Col } from "utils/componentLibrary";

export const StyledLensContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: visible;
  user-select: "none";
`;

export const StyledLensContainerFlex = styled.div`
  width: 100%;
  height: calc(100% - 25px);
  position: absolute;
  overflow: visible;
  user-select: "none";
  display: flex;
  flex-direction: column;
`;

export const StyledChartContainerDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const StyledChartContainerFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
`;

export const StyledSummaryCol = styled(Col)`
  display: flex;
  align-items: flex-end;
`;

export const StyledMenuItem = styled(Title).attrs({
  level: 4,
})`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledChevronDownIcon = styled(PDComponent.SvgIcon).attrs({ name: "chevronDown" })<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "inline-block" : "none")};
  width: 16px;
  font-size: 16px;
`;

export const StyledOption = styled.div`
  width: 100%;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  cursor: pointer;
`;

export const TooltipContainer = styled.div<{ $width?: number }>`
  width: ${({ $width = 250 }) => $width}px;
`;

export const TooltipRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TooltipBorder = styled(TooltipRow)`
  border-top: 1px solid ${colors.gray};
  margin-top: 4px;
  padding-top: 4px;
`;

export const Indicator = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  width: 8px;
  height: 7.356px;
  border-radius: 2px;
`;

export const HighlightValue = styled(TooltipHighlightValue)`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;
