/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClarityUiType } from './ClarityUiType';
import {
    ClarityUiTypeFromJSON,
    ClarityUiTypeFromJSONTyped,
    ClarityUiTypeToJSON,
} from './ClarityUiType';
import type { ClaraEndpointType } from './ClaraEndpointType';
import {
    ClaraEndpointTypeFromJSON,
    ClaraEndpointTypeFromJSONTyped,
    ClaraEndpointTypeToJSON,
} from './ClaraEndpointType';
import type { UiThemeType } from './UiThemeType';
import {
    UiThemeTypeFromJSON,
    UiThemeTypeFromJSONTyped,
    UiThemeTypeToJSON,
} from './UiThemeType';
import type { SystemOfMeasurementType } from './SystemOfMeasurementType';
import {
    SystemOfMeasurementTypeFromJSON,
    SystemOfMeasurementTypeFromJSONTyped,
    SystemOfMeasurementTypeToJSON,
} from './SystemOfMeasurementType';
import type { UserRoleType } from './UserRoleType';
import {
    UserRoleTypeFromJSON,
    UserRoleTypeFromJSONTyped,
    UserRoleTypeToJSON,
} from './UserRoleType';
import type { UserStatusType } from './UserStatusType';
import {
    UserStatusTypeFromJSON,
    UserStatusTypeFromJSONTyped,
    UserStatusTypeToJSON,
} from './UserStatusType';

/**
 * 
 * @export
 * @interface UserWithAccessCountDto
 */
export interface UserWithAccessCountDto {
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserWithAccessCountDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithAccessCountDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithAccessCountDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithAccessCountDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithAccessCountDto
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserWithAccessCountDto
     */
    expirationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    operatorId: number;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    activeProfileId: number;
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserWithAccessCountDto
     */
    role: UserRoleType;
    /**
     * 
     * @type {UserStatusType}
     * @memberof UserWithAccessCountDto
     */
    status: UserStatusType;
    /**
     * 
     * @type {SystemOfMeasurementType}
     * @memberof UserWithAccessCountDto
     */
    systemOfMeasurement: SystemOfMeasurementType;
    /**
     * 
     * @type {UiThemeType}
     * @memberof UserWithAccessCountDto
     */
    uiTheme: UiThemeType;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    canAccessCrew: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    canAccessRigScorecard: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    canSelectClaraEndpoint: boolean;
    /**
     * 
     * @type {ClarityUiType}
     * @memberof UserWithAccessCountDto
     */
    uiType: ClarityUiType;
    /**
     * 
     * @type {Date}
     * @memberof UserWithAccessCountDto
     */
    createdAtUtc?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    isMfaDisabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    isApprover: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    isInDemoMode: boolean;
    /**
     * 
     * @type {ClaraEndpointType}
     * @memberof UserWithAccessCountDto
     */
    claraEndpoint: ClaraEndpointType;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    focusOperatorId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithAccessCountDto
     */
    focusOperatorAsAccount: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    operatorWellCount: number;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    claimedWellCount: number;
    /**
     * 
     * @type {number}
     * @memberof UserWithAccessCountDto
     */
    claimedRigCount: number;
}



/**
 * Check if a given object implements the UserWithAccessCountDto interface.
 */
export function instanceOfUserWithAccessCountDto(value: object): value is UserWithAccessCountDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('userName' in value) || value['userName'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('operatorId' in value) || value['operatorId'] === undefined) return false;
    if (!('activeProfileId' in value) || value['activeProfileId'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('systemOfMeasurement' in value) || value['systemOfMeasurement'] === undefined) return false;
    if (!('uiTheme' in value) || value['uiTheme'] === undefined) return false;
    if (!('canAccessCrew' in value) || value['canAccessCrew'] === undefined) return false;
    if (!('canAccessRigScorecard' in value) || value['canAccessRigScorecard'] === undefined) return false;
    if (!('canSelectClaraEndpoint' in value) || value['canSelectClaraEndpoint'] === undefined) return false;
    if (!('uiType' in value) || value['uiType'] === undefined) return false;
    if (!('isMfaDisabled' in value) || value['isMfaDisabled'] === undefined) return false;
    if (!('isApprover' in value) || value['isApprover'] === undefined) return false;
    if (!('isInDemoMode' in value) || value['isInDemoMode'] === undefined) return false;
    if (!('claraEndpoint' in value) || value['claraEndpoint'] === undefined) return false;
    if (!('focusOperatorAsAccount' in value) || value['focusOperatorAsAccount'] === undefined) return false;
    if (!('operatorWellCount' in value) || value['operatorWellCount'] === undefined) return false;
    if (!('claimedWellCount' in value) || value['claimedWellCount'] === undefined) return false;
    if (!('claimedRigCount' in value) || value['claimedRigCount'] === undefined) return false;
    return true;
}

export function UserWithAccessCountDtoFromJSON(json: any): UserWithAccessCountDto {
    return UserWithAccessCountDtoFromJSONTyped(json, false);
}

export function UserWithAccessCountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWithAccessCountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': json['ExternalId'] == null ? undefined : json['ExternalId'],
        'userName': json['UserName'],
        'firstName': json['FirstName'],
        'lastName': json['LastName'],
        'jobTitle': json['JobTitle'] == null ? undefined : json['JobTitle'],
        'expirationDate': json['ExpirationDate'] == null ? undefined : (new Date(json['ExpirationDate'])),
        'operatorId': json['OperatorId'],
        'activeProfileId': json['ActiveProfileId'],
        'role': UserRoleTypeFromJSON(json['Role']),
        'status': UserStatusTypeFromJSON(json['Status']),
        'systemOfMeasurement': SystemOfMeasurementTypeFromJSON(json['SystemOfMeasurement']),
        'uiTheme': UiThemeTypeFromJSON(json['UiTheme']),
        'canAccessCrew': json['CanAccessCrew'],
        'canAccessRigScorecard': json['CanAccessRigScorecard'],
        'canSelectClaraEndpoint': json['CanSelectClaraEndpoint'],
        'uiType': ClarityUiTypeFromJSON(json['UiType']),
        'createdAtUtc': json['CreatedAtUtc'] == null ? undefined : (new Date(json['CreatedAtUtc'])),
        'isMfaDisabled': json['IsMfaDisabled'],
        'isApprover': json['IsApprover'],
        'isInDemoMode': json['IsInDemoMode'],
        'claraEndpoint': ClaraEndpointTypeFromJSON(json['ClaraEndpoint']),
        'focusOperatorId': json['FocusOperatorId'] == null ? undefined : json['FocusOperatorId'],
        'focusOperatorAsAccount': json['FocusOperatorAsAccount'],
        'operatorWellCount': json['OperatorWellCount'],
        'claimedWellCount': json['ClaimedWellCount'],
        'claimedRigCount': json['ClaimedRigCount'],
    };
}

export function UserWithAccessCountDtoToJSON(value?: UserWithAccessCountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'ExternalId': value['externalId'],
        'UserName': value['userName'],
        'FirstName': value['firstName'],
        'LastName': value['lastName'],
        'JobTitle': value['jobTitle'],
        'ExpirationDate': value['expirationDate'] == null ? undefined : ((value['expirationDate'] as any).toISOString()),
        'OperatorId': value['operatorId'],
        'ActiveProfileId': value['activeProfileId'],
        'Role': UserRoleTypeToJSON(value['role']),
        'Status': UserStatusTypeToJSON(value['status']),
        'SystemOfMeasurement': SystemOfMeasurementTypeToJSON(value['systemOfMeasurement']),
        'UiTheme': UiThemeTypeToJSON(value['uiTheme']),
        'CanAccessCrew': value['canAccessCrew'],
        'CanAccessRigScorecard': value['canAccessRigScorecard'],
        'CanSelectClaraEndpoint': value['canSelectClaraEndpoint'],
        'UiType': ClarityUiTypeToJSON(value['uiType']),
        'CreatedAtUtc': value['createdAtUtc'] == null ? undefined : ((value['createdAtUtc'] as any).toISOString()),
        'IsMfaDisabled': value['isMfaDisabled'],
        'IsApprover': value['isApprover'],
        'IsInDemoMode': value['isInDemoMode'],
        'ClaraEndpoint': ClaraEndpointTypeToJSON(value['claraEndpoint']),
        'FocusOperatorId': value['focusOperatorId'],
        'FocusOperatorAsAccount': value['focusOperatorAsAccount'],
        'OperatorWellCount': value['operatorWellCount'],
        'ClaimedWellCount': value['claimedWellCount'],
        'ClaimedRigCount': value['claimedRigCount'],
    };
}

