// TODO change the store at some point
import * as Sentry from "@sentry/react";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { rootReducer } from "reducers/rootReducer";
import { legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();
const composedEnhancers = composeWithDevTools(sentryReduxEnhancer);

export const store = createStore(rootReducer, undefined, composedEnhancers);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
