import Checkbox from "react-custom-checkbox";
import styled, { css } from "styled-components";
import colors from "utils/colors";

export const StyledCheckbox = styled(Checkbox)<{ $bgColor: string; $isIndeterminate: boolean }>`
  cursor: pointer;
  background: ${({ $bgColor }) => $bgColor};
  box-sizing: content-box;
  position: relative;
  transition: all 0.2s ease-in-out;
  ${({ $isIndeterminate }: { $isIndeterminate: boolean }) =>
    $isIndeterminate
      ? css`
          &:after {
            position: absolute;
            top: 20%;
            background-color: ${colors.flamboyant_teal};
            left: 20%;
            content: " ";
            width: 60%;
            height: 60%;
          }
        `
      : null}
`;

export const IconContainer = styled.div`
  display: flex;
  background-color: ${colors.flamboyant_teal};
  align-self: center;
  border: 0;
  color: ${colors.white};
`;
