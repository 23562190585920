import { useQueryClient } from "@tanstack/react-query";
import { Header } from "components/Clara/Header";
import { Grid } from "components/Clara/style";
import { PDComponent } from "components/PDComponents";
import { useCallback } from "react";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import * as Styled from "./style";

export const ErrorPage = ({ resetError, onClose }: { resetError: () => void; onClose: () => void }) => {
  const queryClient = useQueryClient();
  const restart = useCallback(() => {
    resetError();
    queryClient.refetchQueries({
      queryKey: [{ uid: RequestUID.clara, type: PDQueryType.CLARA_CREATE_SESSION }],
    });
  }, [queryClient, resetError]);

  return (
    <Grid>
      <div id="sideMenu"></div>
      <Header onClose={onClose} onMinimize={onClose} />

      <Styled.ErrorContainer>
        <Styled.MessageContainer>
          <PDComponent.SvgIcon name="claraError" />
          <Styled.MessageBody>
            <Styled.MessageTitle>Uh-oh! Something went wrong.</Styled.MessageTitle>
            <Styled.Message>
              We&apos;re currently experiencing technical difficulties with our page. Please click the button below to
              restart Clara.
            </Styled.Message>
          </Styled.MessageBody>
        </Styled.MessageContainer>
        <Styled.ResetButton onClick={restart}>Restart</Styled.ResetButton>
      </Styled.ErrorContainer>
    </Grid>
  );
};
