/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigScorecardLensType } from './RigScorecardLensType';
import {
    RigScorecardLensTypeFromJSON,
    RigScorecardLensTypeFromJSONTyped,
    RigScorecardLensTypeToJSON,
} from './RigScorecardLensType';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface ScorecardKpiCommentDto
 */
export interface ScorecardKpiCommentDto {
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ScorecardKpiCommentDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentDto
     */
    description?: string | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof ScorecardKpiCommentDto
     */
    kpiType: StandKpiType;
    /**
     * 
     * @type {RigScorecardLensType}
     * @memberof ScorecardKpiCommentDto
     */
    scorecardType: RigScorecardLensType;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentDto
     */
    exceptionTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentDto
     */
    exceptionApproverId?: number | null;
}



/**
 * Check if a given object implements the ScorecardKpiCommentDto interface.
 */
export function instanceOfScorecardKpiCommentDto(value: object): value is ScorecardKpiCommentDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('kpiType' in value) || value['kpiType'] === undefined) return false;
    if (!('scorecardType' in value) || value['scorecardType'] === undefined) return false;
    return true;
}

export function ScorecardKpiCommentDtoFromJSON(json: any): ScorecardKpiCommentDto {
    return ScorecardKpiCommentDtoFromJSONTyped(json, false);
}

export function ScorecardKpiCommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScorecardKpiCommentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': json['DeletedAtUtc'] == null ? undefined : (new Date(json['DeletedAtUtc'])),
        'wellId': json['WellId'],
        'title': json['Title'] == null ? undefined : json['Title'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'kpiType': StandKpiTypeFromJSON(json['KpiType']),
        'scorecardType': RigScorecardLensTypeFromJSON(json['ScorecardType']),
        'exceptionTime': json['ExceptionTime'] == null ? undefined : json['ExceptionTime'],
        'exceptionApproverId': json['ExceptionApproverId'] == null ? undefined : json['ExceptionApproverId'],
    };
}

export function ScorecardKpiCommentDtoToJSON(value?: ScorecardKpiCommentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'DeletedAtUtc': value['deletedAtUtc'] == null ? undefined : ((value['deletedAtUtc'] as any).toISOString()),
        'WellId': value['wellId'],
        'Title': value['title'],
        'Description': value['description'],
        'KpiType': StandKpiTypeToJSON(value['kpiType']),
        'ScorecardType': RigScorecardLensTypeToJSON(value['scorecardType']),
        'ExceptionTime': value['exceptionTime'],
        'ExceptionApproverId': value['exceptionApproverId'],
    };
}

