import { useQuery } from "@tanstack/react-query";
import { UsersApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDAdminUsersAccessInfoQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const users = new UsersApi(apiConfig);

export function useAdminSingleUserAccessInfo({ userId }: { userId: number }, enabled = true) {
  const queryKey: PDAdminUsersAccessInfoQueryKey = {
    type: PDQueryType.ADMIN_USERS_ACCESS_INFO,
    uid: RequestUID.adminUsersAccessInfo,
    userId,
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: () =>
      users.apiUsersIdAccessInfoGet({
        id: userId,
      }),
    enabled,
  });
}
