/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GhgEmissionsFactSetDto,
  GhgEmissionsQueryDto,
  GhgEmissionsUserLensDto,
} from '../models/index';
import {
    GhgEmissionsFactSetDtoFromJSON,
    GhgEmissionsFactSetDtoToJSON,
    GhgEmissionsQueryDtoFromJSON,
    GhgEmissionsQueryDtoToJSON,
    GhgEmissionsUserLensDtoFromJSON,
    GhgEmissionsUserLensDtoToJSON,
} from '../models/index';

export interface ApiGhgEmissionsUserLensesIdFactsPutRequest {
    id: number;
    ghgEmissionsQueryDto?: GhgEmissionsQueryDto;
}

export interface ApiGhgEmissionsUserLensesIdPutRequest {
    id: number;
    ghgEmissionsUserLensDto?: GhgEmissionsUserLensDto;
}

export interface ApiGhgEmissionsUserLensesPostRequest {
    ghgEmissionsUserLensDto?: GhgEmissionsUserLensDto;
}

/**
 * 
 */
export class GhgEmissionsUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiGhgEmissionsUserLensesIdFactsPutRaw(requestParameters: ApiGhgEmissionsUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GhgEmissionsFactSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiGhgEmissionsUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GhgEmissionsUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GhgEmissionsQueryDtoToJSON(requestParameters['ghgEmissionsQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GhgEmissionsFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGhgEmissionsUserLensesIdFactsPut(requestParameters: ApiGhgEmissionsUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GhgEmissionsFactSetDto> {
        const response = await this.apiGhgEmissionsUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGhgEmissionsUserLensesIdPutRaw(requestParameters: ApiGhgEmissionsUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GhgEmissionsUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiGhgEmissionsUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GhgEmissionsUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GhgEmissionsUserLensDtoToJSON(requestParameters['ghgEmissionsUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GhgEmissionsUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGhgEmissionsUserLensesIdPut(requestParameters: ApiGhgEmissionsUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GhgEmissionsUserLensDto> {
        const response = await this.apiGhgEmissionsUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGhgEmissionsUserLensesPostRaw(requestParameters: ApiGhgEmissionsUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GhgEmissionsUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GhgEmissionsUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GhgEmissionsUserLensDtoToJSON(requestParameters['ghgEmissionsUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GhgEmissionsUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGhgEmissionsUserLensesPost(requestParameters: ApiGhgEmissionsUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GhgEmissionsUserLensDto> {
        const response = await this.apiGhgEmissionsUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
