/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigScorecardLensType } from './RigScorecardLensType';
import {
    RigScorecardLensTypeFromJSON,
    RigScorecardLensTypeFromJSONTyped,
    RigScorecardLensTypeToJSON,
} from './RigScorecardLensType';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';
import type { ScorecardExceptionStatusType } from './ScorecardExceptionStatusType';
import {
    ScorecardExceptionStatusTypeFromJSON,
    ScorecardExceptionStatusTypeFromJSONTyped,
    ScorecardExceptionStatusTypeToJSON,
} from './ScorecardExceptionStatusType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ScorecardKpiCommentExceptionInfoDto
 */
export interface ScorecardKpiCommentExceptionInfoDto {
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    deletedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    description?: string | null;
    /**
     * 
     * @type {StandKpiType}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    kpiType: StandKpiType;
    /**
     * 
     * @type {RigScorecardLensType}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    scorecardType: RigScorecardLensType;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    exceptionTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    exceptionApproverId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    authorId: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    authorDisplayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    exceptionApproverDisplayName?: string | null;
    /**
     * 
     * @type {DateDto}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    createdAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    updatedAt: DateDto;
    /**
     * 
     * @type {ScorecardExceptionStatusType}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    exceptionStatus: ScorecardExceptionStatusType;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    rigId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    jobExternalIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    kpiTime: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardKpiCommentExceptionInfoDto
     */
    kpiFullDisplayName: string;
}



/**
 * Check if a given object implements the ScorecardKpiCommentExceptionInfoDto interface.
 */
export function instanceOfScorecardKpiCommentExceptionInfoDto(value: object): value is ScorecardKpiCommentExceptionInfoDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('kpiType' in value) || value['kpiType'] === undefined) return false;
    if (!('scorecardType' in value) || value['scorecardType'] === undefined) return false;
    if (!('authorId' in value) || value['authorId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('exceptionStatus' in value) || value['exceptionStatus'] === undefined) return false;
    if (!('rigId' in value) || value['rigId'] === undefined) return false;
    if (!('jobExternalIds' in value) || value['jobExternalIds'] === undefined) return false;
    if (!('kpiTime' in value) || value['kpiTime'] === undefined) return false;
    if (!('kpiFullDisplayName' in value) || value['kpiFullDisplayName'] === undefined) return false;
    return true;
}

export function ScorecardKpiCommentExceptionInfoDtoFromJSON(json: any): ScorecardKpiCommentExceptionInfoDto {
    return ScorecardKpiCommentExceptionInfoDtoFromJSONTyped(json, false);
}

export function ScorecardKpiCommentExceptionInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScorecardKpiCommentExceptionInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'deletedAtUtc': json['DeletedAtUtc'] == null ? undefined : (new Date(json['DeletedAtUtc'])),
        'wellId': json['WellId'],
        'title': json['Title'] == null ? undefined : json['Title'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'kpiType': StandKpiTypeFromJSON(json['KpiType']),
        'scorecardType': RigScorecardLensTypeFromJSON(json['ScorecardType']),
        'exceptionTime': json['ExceptionTime'] == null ? undefined : json['ExceptionTime'],
        'exceptionApproverId': json['ExceptionApproverId'] == null ? undefined : json['ExceptionApproverId'],
        'authorId': json['AuthorId'],
        'authorDisplayName': json['AuthorDisplayName'] == null ? undefined : json['AuthorDisplayName'],
        'exceptionApproverDisplayName': json['ExceptionApproverDisplayName'] == null ? undefined : json['ExceptionApproverDisplayName'],
        'createdAt': DateDtoFromJSON(json['CreatedAt']),
        'updatedAt': DateDtoFromJSON(json['UpdatedAt']),
        'exceptionStatus': ScorecardExceptionStatusTypeFromJSON(json['ExceptionStatus']),
        'rigId': json['RigId'],
        'jobExternalIds': json['JobExternalIds'],
        'kpiTime': json['KpiTime'],
        'kpiFullDisplayName': json['KpiFullDisplayName'],
    };
}

export function ScorecardKpiCommentExceptionInfoDtoToJSON(value?: ScorecardKpiCommentExceptionInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'DeletedAtUtc': value['deletedAtUtc'] == null ? undefined : ((value['deletedAtUtc'] as any).toISOString()),
        'WellId': value['wellId'],
        'Title': value['title'],
        'Description': value['description'],
        'KpiType': StandKpiTypeToJSON(value['kpiType']),
        'ScorecardType': RigScorecardLensTypeToJSON(value['scorecardType']),
        'ExceptionTime': value['exceptionTime'],
        'ExceptionApproverId': value['exceptionApproverId'],
        'AuthorId': value['authorId'],
        'AuthorDisplayName': value['authorDisplayName'],
        'ExceptionApproverDisplayName': value['exceptionApproverDisplayName'],
        'CreatedAt': DateDtoToJSON(value['createdAt']),
        'UpdatedAt': DateDtoToJSON(value['updatedAt']),
        'ExceptionStatus': ScorecardExceptionStatusTypeToJSON(value['exceptionStatus']),
        'RigId': value['rigId'],
        'JobExternalIds': value['jobExternalIds'],
        'KpiTime': value['kpiTime'],
        'KpiFullDisplayName': value['kpiFullDisplayName'],
    };
}

