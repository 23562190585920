/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TorqueAndDragActivityType } from './TorqueAndDragActivityType';
import {
    TorqueAndDragActivityTypeFromJSON,
    TorqueAndDragActivityTypeFromJSONTyped,
    TorqueAndDragActivityTypeToJSON,
} from './TorqueAndDragActivityType';
import type { TorqueAndDragPointDto } from './TorqueAndDragPointDto';
import {
    TorqueAndDragPointDtoFromJSON,
    TorqueAndDragPointDtoFromJSONTyped,
    TorqueAndDragPointDtoToJSON,
} from './TorqueAndDragPointDto';

/**
 * 
 * @export
 * @interface TorqueAndDragCurveDto
 */
export interface TorqueAndDragCurveDto {
    /**
     * 
     * @type {TorqueAndDragActivityType}
     * @memberof TorqueAndDragCurveDto
     */
    activity: TorqueAndDragActivityType;
    /**
     * 
     * @type {Array<TorqueAndDragPointDto>}
     * @memberof TorqueAndDragCurveDto
     */
    points?: Array<TorqueAndDragPointDto> | null;
}



/**
 * Check if a given object implements the TorqueAndDragCurveDto interface.
 */
export function instanceOfTorqueAndDragCurveDto(value: object): value is TorqueAndDragCurveDto {
    if (!('activity' in value) || value['activity'] === undefined) return false;
    return true;
}

export function TorqueAndDragCurveDtoFromJSON(json: any): TorqueAndDragCurveDto {
    return TorqueAndDragCurveDtoFromJSONTyped(json, false);
}

export function TorqueAndDragCurveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TorqueAndDragCurveDto {
    if (json == null) {
        return json;
    }
    return {
        
        'activity': TorqueAndDragActivityTypeFromJSON(json['Activity']),
        'points': json['Points'] == null ? undefined : ((json['Points'] as Array<any>).map(TorqueAndDragPointDtoFromJSON)),
    };
}

export function TorqueAndDragCurveDtoToJSON(value?: TorqueAndDragCurveDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Activity': TorqueAndDragActivityTypeToJSON(value['activity']),
        'Points': value['points'] == null ? undefined : ((value['points'] as Array<any>).map(TorqueAndDragPointDtoToJSON)),
    };
}

