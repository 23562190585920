/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GeneratorSlotType,
  RigPowerQueryDto,
  RigPowerResultDto,
  RigPowerUserLensDto,
} from '../models/index';
import {
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeToJSON,
    RigPowerQueryDtoFromJSON,
    RigPowerQueryDtoToJSON,
    RigPowerResultDtoFromJSON,
    RigPowerResultDtoToJSON,
    RigPowerUserLensDtoFromJSON,
    RigPowerUserLensDtoToJSON,
} from '../models/index';

export interface ApiRigPowerUserLensesActiveGeneratorsWellIdGetRequest {
    wellId: number;
}

export interface ApiRigPowerUserLensesIdFactsPutRequest {
    id: number;
    rigPowerQueryDto?: RigPowerQueryDto;
}

export interface ApiRigPowerUserLensesIdPutRequest {
    id: number;
    rigPowerUserLensDto?: RigPowerUserLensDto;
}

export interface ApiRigPowerUserLensesPostRequest {
    rigPowerUserLensDto?: RigPowerUserLensDto;
}

/**
 * 
 */
export class RigPowerUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiRigPowerUserLensesActiveGeneratorsWellIdGetRaw(requestParameters: ApiRigPowerUserLensesActiveGeneratorsWellIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GeneratorSlotType>>> {
        if (requestParameters['wellId'] == null) {
            throw new runtime.RequiredError(
                'wellId',
                'Required parameter "wellId" was null or undefined when calling apiRigPowerUserLensesActiveGeneratorsWellIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RigPowerUserLenses/ActiveGenerators/{wellId}`.replace(`{${"wellId"}}`, encodeURIComponent(String(requestParameters['wellId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GeneratorSlotTypeFromJSON));
    }

    /**
     */
    async apiRigPowerUserLensesActiveGeneratorsWellIdGet(requestParameters: ApiRigPowerUserLensesActiveGeneratorsWellIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GeneratorSlotType>> {
        const response = await this.apiRigPowerUserLensesActiveGeneratorsWellIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRigPowerUserLensesIdFactsPutRaw(requestParameters: ApiRigPowerUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RigPowerResultDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiRigPowerUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RigPowerUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigPowerQueryDtoToJSON(requestParameters['rigPowerQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RigPowerResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiRigPowerUserLensesIdFactsPut(requestParameters: ApiRigPowerUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RigPowerResultDto> {
        const response = await this.apiRigPowerUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRigPowerUserLensesIdPutRaw(requestParameters: ApiRigPowerUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RigPowerUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiRigPowerUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RigPowerUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigPowerUserLensDtoToJSON(requestParameters['rigPowerUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RigPowerUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiRigPowerUserLensesIdPut(requestParameters: ApiRigPowerUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RigPowerUserLensDto> {
        const response = await this.apiRigPowerUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRigPowerUserLensesPostRaw(requestParameters: ApiRigPowerUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RigPowerUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RigPowerUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RigPowerUserLensDtoToJSON(requestParameters['rigPowerUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RigPowerUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiRigPowerUserLensesPost(requestParameters: ApiRigPowerUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RigPowerUserLensDto> {
        const response = await this.apiRigPowerUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
