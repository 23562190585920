import { DimensionType } from "apis/oag";
import RigAtom from "components/Legend/RigAtom";
import { useImperativeScrollOnLegendItemSelected } from "components/Legend/useImperativeScrollOnLegendItemSelected";
import { useWellDetails } from "hooks/useWellDetails";
import { useEffect, useMemo, useRef } from "react";
import { useAppDispatch } from "reducers/store";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { RIG_LEGEND_IDS, secondsInDay } from "utils/common";
import { Row } from "utils/componentLibrary";
import { useUOM } from "utils/format";

import { BitDepth } from "./BitDepth";
import { Box, Center, Circle, StyledDescription, StyledTitle, Textbox } from "./styles";

export const LegendAtom = ({
  bitDepth,
  color,
  disabled,
  id,
  selected,
  wellInfo,
  wellName,
}: {
  color: string;
  disabled?: boolean;
  id: number;
  selected: boolean;
  bitDepth?: boolean;
  wellInfo?: string;
  wellName?: string;
  rigIds?: number[];
}) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const { data, isLoading } = useWellDetails(id, { enabled: id >= 0 }, true);
  const depthUOM = useUOM(DimensionType.Metres);
  useEffect(() => {
    if (data)
      dispatch({
        type: "SET_WELL_DETAILS_OFFSET",
        payload: { [id]: data },
      });
  }, [data, data?.drillingDetails?.lastWellFactUpdateAt, dispatch, id]);

  const setSelected = () => {
    if (id === RIG_LEGEND_IDS) return;
    Track.interact("Well Dashboard - Select Series", {
      "Series Name": wellName ?? data?.name ?? "",
    });
    dispatch({
      type: "SET_SELECTED_SERIES",
      payload: id,
    });
  };

  const days = ((data?.drillingDetails?.cumulativeDuration || 0) / secondsInDay).toFixed(2);
  const uniqueRigIds = useMemo(() => [...new Set(data?.jobs ? data?.jobs.map((e) => e.rigId) : [])], [data?.jobs]);

  useImperativeScrollOnLegendItemSelected(selected, id, () => {
    containerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  });

  return (
    <Row onClick={setSelected} ref={containerRef}>
      <Center
        onMouseOver={() => {
          if (id !== RIG_LEGEND_IDS)
            dispatch({
              type: "SET_HOVERED_SERIES_TVD",
              payload: id,
            });
        }}
        onMouseOut={() => {
          if (id !== RIG_LEGEND_IDS)
            dispatch({
              type: "SET_HOVERED_SERIES_TVD",
              payload: null,
            });
        }}
        selected={id !== RIG_LEGEND_IDS && selected}
      >
        <Row wrap={false}>
          <Box>
            <Circle color={disabled ? colors.pale_grey : color} />
          </Box>
          <Textbox>
            {/* TODO: This rig Atom is a Warning for HTML */}
            <Row>
              <StyledTitle $colorDisabled={(disabled ?? false).toString()}>{wellName ?? data?.name ?? ""}</StyledTitle>
              <RigAtom key={uniqueRigIds.join("-")} uniqueRigIds={uniqueRigIds} />
            </Row>
            {(wellInfo || id >= 0) && !isLoading ? (
              <StyledDescription>
                {wellInfo ?? `${days} days · ${depthUOM.display(data?.drillingDetails?.lastHoleDepth || 0)}`}
              </StyledDescription>
            ) : null}
          </Textbox>
        </Row>
        {bitDepth ? (
          <Row
            wrap={false}
            style={{
              paddingTop: 24,
              alignItems: "center",
              display: "grid",
              gridTemplateColumns: "36px 12px 1fr",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BitDepth />
            </div>
            <div />
            <Textbox>
              <StyledTitle $colorDisabled={(disabled ?? false).toString()}>Bit depth</StyledTitle>
            </Textbox>
          </Row>
        ) : null}
      </Center>
    </Row>
  );
};
