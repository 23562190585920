import type { UseQueryOptions } from "@tanstack/react-query";
import type { SingleStandKpiComparisonDto, SingleStandKpiDto } from "apis/oag";
import { useKpiTypeComparisonFacts } from "hooks/lens-facts/useKpiTypeComparisonFacts";
import { useKpiTypeFacts } from "hooks/lens-facts/useKpiTypeFacts";

export function useSingleKpiFetcher({ lensId, opts }: { lensId: number; opts?: UseQueryOptions<SingleStandKpiDto> }) {
  const request = useKpiTypeFacts(lensId, opts);
  return { request };
}

export function useComparisonKpiFetcher({
  lensId,
  opts,
}: {
  lensId: number;
  opts?: UseQueryOptions<SingleStandKpiComparisonDto>;
}) {
  const request = useKpiTypeComparisonFacts(lensId, opts);
  return { request };
}
