import { PDComponent } from "components/PDComponents";
import React from "react";
import styled from "styled-components";
import { useCustomTheme } from "utils/useTheme";

const IconWrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${(props) => (props.isDisabled ? "none" : "all")};
  cursor: pointer;
  height: 36px;
  width: 36px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.themeStyle.colors.primary_accent};
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  font-size: 18px;
`;

const IconComponent = ({
  onClick,
  disabled,
  active,
  name,
  iconProps,
}: {
  name: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  iconProps?: any;
}) => {
  const { themeStyle } = useCustomTheme();

  return (
    <IconWrapper onKeyDown={() => {}} role="button" tabIndex={0} onClick={onClick} isDisabled={disabled}>
      <PDComponent.SvgIcon
        name={name}
        {...iconProps}
        style={{
          color: (() => {
            if (disabled) return themeStyle.colors.disabled_typography;
            if (active) return themeStyle.colors.alt_typography;
            return themeStyle.colors.primary_typography;
          })(),
        }}
      />
    </IconWrapper>
  );
};

IconComponent.defaultProps = {
  onClick: () => {},
  disabled: false,
};

export default IconComponent;
