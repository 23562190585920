import useWindowSize from "hooks/useWindowSize";
import ReactDOM from "react-dom";
import { responsiveBreakpoints } from "utils/common";

import * as Styled from "./Styled";

export const MobileModal = () => {
  const windowSize = useWindowSize();
  if ((windowSize.width || 0) > responsiveBreakpoints.sm) {
    return null;
  } else
    return ReactDOM.createPortal(
      <Styled.ModalContainer>
        <Styled.Backdrop />
        <Styled.MessageBox>
          <h1>We&apos;ve hit a bottleneck</h1>
          <p>
            Clarity is not compatible with your screen size. Please enlarge your screen or switch to a larger device.
          </p>
        </Styled.MessageBox>
      </Styled.ModalContainer>,
      document.getElementById("portal-root") || document.body,
    );
};
