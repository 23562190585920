/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScoreSummaryLensDto } from './ScoreSummaryLensDto';
import {
    ScoreSummaryLensDtoFromJSON,
    ScoreSummaryLensDtoFromJSONTyped,
    ScoreSummaryLensDtoToJSON,
} from './ScoreSummaryLensDto';

/**
 * 
 * @export
 * @interface ScoreSummaryTabDto
 */
export interface ScoreSummaryTabDto {
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryTabDto
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryTabDto
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryTabDto
     */
    tabId: number;
    /**
     * 
     * @type {Array<ScoreSummaryLensDto>}
     * @memberof ScoreSummaryTabDto
     */
    children: Array<ScoreSummaryLensDto>;
    /**
     * 
     * @type {string}
     * @memberof ScoreSummaryTabDto
     */
    description?: string | null;
}

/**
 * Check if a given object implements the ScoreSummaryTabDto interface.
 */
export function instanceOfScoreSummaryTabDto(value: object): value is ScoreSummaryTabDto {
    if (!('tabId' in value) || value['tabId'] === undefined) return false;
    if (!('children' in value) || value['children'] === undefined) return false;
    return true;
}

export function ScoreSummaryTabDtoFromJSON(json: any): ScoreSummaryTabDto {
    return ScoreSummaryTabDtoFromJSONTyped(json, false);
}

export function ScoreSummaryTabDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreSummaryTabDto {
    if (json == null) {
        return json;
    }
    return {
        
        'targetDeltaTime': json['TargetDeltaTime'] == null ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': json['InvisibleLostTime'] == null ? undefined : json['InvisibleLostTime'],
        'tabId': json['TabId'],
        'children': ((json['Children'] as Array<any>).map(ScoreSummaryLensDtoFromJSON)),
        'description': json['Description'] == null ? undefined : json['Description'],
    };
}

export function ScoreSummaryTabDtoToJSON(value?: ScoreSummaryTabDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'TargetDeltaTime': value['targetDeltaTime'],
        'InvisibleLostTime': value['invisibleLostTime'],
        'TabId': value['tabId'],
        'Children': ((value['children'] as Array<any>).map(ScoreSummaryLensDtoToJSON)),
        'Description': value['description'],
    };
}

