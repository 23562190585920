/* eslint-disable react/forbid-dom-props */
import type { TimelineEventType } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import AddAction from "components/Timeline/components/AddAction";
import { EventBox, EventButton, EventIconSelector, Separator } from "components/Timeline/style";
import { eventIcon, FONT_SIZE, FONT_SIZE_SMALL, LEFT_MARGIN, TOP_MARGIN } from "components/Timeline/utils";
import { AddIconWrapper } from "components/TvDChart/components/Icons";
import { TimelineEventIndicator } from "components/TvDChart/components/TimelineEventIndicator/TimelineEventIndicator";
import type { ITimelineInfo } from "pages/WellDashboard/types";
import React from "react";
import { useAppDispatch } from "reducers/store";
import type { ExtendedEventType } from "reducers/types";
import { Track } from "services/Mixpanel";
import { Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

export const AddIconTimeline: React.FC<{
  resetRef: () => void;
  addActionRef: React.Ref<HTMLDivElement>;
  info: { top: number; duration: number; depth: number | null; label: string } | null;
  crtLocation: number | null;
  currentEvent: {
    type: TimelineEventType;
    icon: JSX.Element;
  } | null;
  setCurrentEvent: React.Dispatch<
    React.SetStateAction<{
      type: TimelineEventType;
      icon: JSX.Element;
    } | null>
  >;
  actionEnabled: boolean;
  setTimelineOverride: React.Dispatch<ITimelineInfo | null>;
  eventTypes: Array<{ type: TimelineEventType; icon: JSX.Element }>;
}> = ({
  info,
  addActionRef,
  setTimelineOverride,
  resetRef,
  crtLocation,
  currentEvent,
  setCurrentEvent,
  actionEnabled,
  eventTypes,
}) => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  const dispatch = useAppDispatch();

  if (!info && !actionEnabled) return null;
  const { top } = info ?? { top: 0, label: "" };
  const actionEnabledCB = (action?: boolean) => {
    if (action === undefined) return;
    dispatch({
      type: "SET_ACTION_ENABLED",
      payload: action,
    });
  };
  const addNewEvent = (event: { type: TimelineEventType; icon: JSX.Element }) => {
    setCurrentEvent(event);
  };
  return (
    <div
      ref={addActionRef}
      style={{
        position: "absolute",
        left: LEFT_MARGIN - (actionEnabled ? FONT_SIZE : FONT_SIZE_SMALL) / 2,
        // TODO: do not understand this calculation
        top: TOP_MARGIN + (actionEnabled ? crtLocation || 0 : top),
      }}
    >
      {/* ant tooltip is slow find something better */}
      {currentEvent ? (
        <TimelineEventIndicator key={"AddingEvent"}>
          {eventIcon(currentEvent.type as ExtendedEventType, false)}
        </TimelineEventIndicator>
      ) : (
        <AddIconWrapper
          backgroundColor={themeColors.primary_button_bg}
          isAnimated={actionEnabled?.toString()}
          isTimelineAdd={actionEnabled?.toString()}
          top={"0px"}
          style={{
            height: actionEnabled ? FONT_SIZE : FONT_SIZE_SMALL,
            width: actionEnabled ? FONT_SIZE : FONT_SIZE_SMALL,
          }}
          hasPointerEvents={actionEnabled ? "true" : ""}
          onClick={() => {
            if (!currentEvent) {
              info = null;
              resetRef();
            }
          }}
        >
          <PDComponent.SvgIcon
            name="add"
            style={{
              fontSize: actionEnabled ? FONT_SIZE : FONT_SIZE_SMALL,
              color: actionEnabled ? themeColors.primary_bg : "white",
            }}
          />
        </AddIconWrapper>
      )}
      <div
        style={{
          marginLeft: currentEvent ? "40px" : "30px",
        }}
      >
        {currentEvent ? (
          <AddAction
            addItemType={currentEvent.type}
            editedEvent={null}
            depth={info?.depth ?? null}
            cumulativeDuration={info?.duration || 0}
            onSave={() => {
              resetRef();
              setCurrentEvent(null);
              setTimelineOverride(null);
              actionEnabledCB(false);
            }}
          />
        ) : actionEnabled && eventTypes ? (
          <EventBox width={42 * eventTypes.length}>
            {eventTypes?.map((event, index) => (
              <EventIconSelector key={event.type}>
                <Tooltip placement="bottom" title={event?.type}>
                  <EventButton
                    onClick={() => {
                      Track.clickEvent("Select Event", {
                        "Event Type": event?.type,
                      });
                      addNewEvent(event);
                    }}
                  >
                    {event.icon}
                  </EventButton>
                </Tooltip>
                {index < eventTypes.length - 1 ? <Separator /> : null}
              </EventIconSelector>
            ))}
          </EventBox>
        ) : null}
      </div>
    </div>
  );
};
