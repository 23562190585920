import { AxisRight } from "@visx/axis";
import { LinearGradient } from "@visx/gradient";
import { GridRows } from "@visx/grid";
import { scaleBand, scaleLinear } from "@visx/scale";
import { BarRounded, Line } from "@visx/shape";
import type { RigCardFactDto, RigCardFactSetDto } from "apis/oag";
import { max } from "d3";
import { GroupTitle } from "pages/RigScoreCard/LeftPane/components/GroupTitle";
import { getColorByScore, useScoreCardTags } from "pages/RigScoreCard/LeftPane/utils";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import colors from "utils/colors";
import type { UOMHelper } from "utils/format";
import { formatTickDecimalsByDomain } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { ChartSvg, ChartWrapper, LensTitle, SummaryWrapper } from "./style";
import { PLOT_HEIGHT, RIGHT_AXIS_WIDTH } from "./utils";
const defaultItem = "default";
export const Chart = ({
  facts,
  notificationId,
  lensName,
  uom,
  isWellScoreCard,
}: {
  facts?: RigCardFactSetDto;
  notificationId: number;
  lensName: string;
  isWellScoreCard: boolean;
  uom: UOMHelper;
}) => {
  const transformedData = useMemo(() => {
    if (!facts) return null;
    return facts.cardGroups?.[0]?.facts?.[0].facts ?? null;
  }, [facts]);

  const containerRef = useRef<HTMLDivElement>(null);
  const { themeStyle } = useCustomTheme();
  const [chartWidth, setchartWidth] = useState(0);
  const plotWidth = chartWidth - RIGHT_AXIS_WIDTH;

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setchartWidth(entries[0].contentRect.width);
    });
    containerRef.current && observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const getValue = useCallback(
    (d: RigCardFactDto) => {
      if (isWellScoreCard) {
        return d.operatorScore?.value;
      }
      return d.kpiValue?.value;
    },
    [isWellScoreCard],
  );

  const yScale = useMemo(() => {
    const values = !transformedData
      ? []
      : transformedData.flatMap((e) =>
          isWellScoreCard ? [getValue(e) || 0] : [getValue(e) || 0, e?.baseValue || 0, e?.targetValue || 0],
        );
    return scaleLinear<number>({
      domain: [max(values) || 0, 0],
      range: [0, PLOT_HEIGHT],
    });
  }, [transformedData, getValue, isWellScoreCard]);

  const axisScale = useMemo(() => {
    const values = !transformedData
      ? []
      : transformedData.flatMap((e) =>
          isWellScoreCard
            ? [uom.fromSI(getValue(e) || 0)]
            : [uom.fromSI(getValue(e) || 0), uom.fromSI(e?.baseValue || 0), uom.fromSI(e?.targetValue || 0)],
        );
    return scaleLinear<number>({
      domain: [max(values) || 0, 0],
      range: [0, PLOT_HEIGHT],
    });
  }, [transformedData, getValue, uom, isWellScoreCard]);

  const xScale = useMemo(() => {
    return scaleBand({
      domain: [defaultItem],
      range: [0, plotWidth],
      padding: 0.3,
    });
  }, [plotWidth]);

  const chartColor = useMemo(() => getColorByScore(transformedData?.[0]?.operatorScore?.value || 0), [transformedData]);

  const { getTagsByAveragesOrFact } = useScoreCardTags({ borderless: true });
  const tags = useMemo(() => {
    const tags = getTagsByAveragesOrFact({ averages: facts?.cardGroups?.[0]?.averages });
    return isWellScoreCard ? [tags.score, tags.opportunityTime] : [tags.score, tags.opportunityTime, tags.timeSaved];
  }, [getTagsByAveragesOrFact, facts?.cardGroups, isWellScoreCard]);

  return (
    <ChartWrapper ref={containerRef}>
      <LensTitle>{lensName}</LensTitle>
      <SummaryWrapper>
        <GroupTitle inner title={""} withTagSeparators tags={tags} />
      </SummaryWrapper>
      <ChartSvg width={chartWidth} height={PLOT_HEIGHT}>
        {transformedData?.map((d, index) => {
          const barHeight = Math.min(PLOT_HEIGHT - yScale(getValue(d) || 0), PLOT_HEIGHT);
          const barY = PLOT_HEIGHT - barHeight;
          const gradientToColor = `${chartColor}70`;
          const gradientFromColor = `${chartColor}`;
          return (
            <React.Fragment key={`${d?.label}-bar`}>
              <LinearGradient
                from={gradientFromColor}
                to={gradientToColor}
                fromOpacity={1}
                toOpacity={0.9}
                id={`linear_gradient_${gradientToColor}_${notificationId}`}
              />

              <GridRows
                scale={axisScale}
                width={plotWidth}
                numTicks={4}
                stroke={colors.necron_compound}
                strokeOpacity={0.12}
              />
              <BarRounded
                radius={4}
                top
                x={xScale(defaultItem) || 0}
                y={barY}
                key={`single-kpi-${d?.label}-${index}`}
                width={xScale.bandwidth() || 0}
                height={barHeight}
                fill={`url(#linear_gradient_${gradientToColor}_${notificationId}`}
              />
              {d.targetValue && !isWellScoreCard ? (
                <Line
                  from={{ x: 0, y: yScale(d.targetValue) }}
                  to={{ x: plotWidth, y: yScale(d.targetValue) }}
                  strokeWidth={3}
                  stroke={themeStyle.colors.primary_typography}
                />
              ) : null}

              {d.baseValue && !isWellScoreCard ? (
                <Line
                  from={{ x: 0, y: yScale(d.baseValue) }}
                  to={{ x: plotWidth, y: yScale(d.baseValue) }}
                  strokeWidth={3}
                  stroke={themeStyle.colors.primary_typography}
                  strokeDasharray={"4 4"}
                />
              ) : null}

              <AxisRight
                scale={axisScale}
                top={0}
                left={plotWidth}
                numTicks={4}
                hideTicks
                hideZero
                hideAxisLine
                tickLabelProps={{
                  fill: colors.necron_compound,
                  fontSize: 12,
                }}
                tickFormat={(value) => {
                  return formatTickDecimalsByDomain(+value, 1, axisScale.domain());
                }}
              />
            </React.Fragment>
          );
        })}
      </ChartSvg>
    </ChartWrapper>
  );
};
