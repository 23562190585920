import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { TagBottomFingerprintUserLensDto } from "apis/oag";
import { TagBottomFingerprintUserLensesApi } from "apis/oag";
import { ZoomState } from "components/Lenses/common/LensZoom/ZoomChartContext";
import type { Domain } from "components/Lenses/ContainerLens/StickSlipByTime";
import { StyledControlsButton } from "components/Lenses/ContainerLens/TorqueAndDrag/styledComponents";
import { PDComponent } from "components/PDComponents";
import { useEffect, useState } from "react";
import { apiConfig } from "utils/apiConfig";
import { Popover, Tooltip } from "utils/componentLibrary";
import { RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import { HeaderContainer, LensSettingsSelector, Option, StandCountSelector, ZoomSelectorContainer } from "./style";
import { selectRecent } from "./TagBottomFingerprintModal";

export const Header = ({
  lens,
  setZoomState,
  resetZoom,
  onLensUpdated,
  domain,
}: {
  lens: TagBottomFingerprintUserLensDto;
  setZoomState: React.Dispatch<React.SetStateAction<ZoomState>>;
  resetZoom: () => void;
  onLensUpdated: (newItem: unknown) => void;
  domain: Domain | null;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const tagBottomFingerPrintApi = new TagBottomFingerprintUserLensesApi(apiConfig);
  const queryClient = useQueryClient();
  const [standCount, setStandCount] = useState(lens.standCount);
  useEffect(() => {
    setStandCount(lens.standCount);
  }, [lens.standCount]);
  const handleDisplayOptionsUpdate = useMutation({
    mutationFn: ({ standCount }: { standCount: number | null }) => {
      return tagBottomFingerPrintApi.apiTagBottomFingerprintUserLensesIdPut({
        id: lens.id,
        tagBottomFingerprintUserLensDto: {
          ...lens,
          standCount,
        },
      });
    },
    onSuccess(newLens) {
      setStandCount(newLens.standCount);
      onLensUpdated(newLens);
      queryClient.invalidateQueries({
        queryKey: [{ uid: RequestUID.tagBottomFingerprintFacts, lensId: lens.id }],
        exact: false,
      });
    },
  });
  const { themeStyle } = useCustomTheme();

  useEffect(() => {
    const element = document.getElementById("scroll-container-id-fix-tbf");
    const onScroll = () => {
      setIsVisible(false);
    };
    element?.addEventListener("scroll", onScroll);
    return () => {
      element?.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <HeaderContainer>
      <StandCountSelector>
        <LensSettingsSelector>
          <h1>S2B</h1>
          <Popover
            placement="bottomLeft"
            open={isVisible}
            content={selectRecent
              .filter((e) => e !== standCount)
              .map((optionKey) => {
                return (
                  <Option
                    style={{
                      backgroundColor: themeStyle.colors.primary_bg,
                      color: themeStyle.colors.primary_typography,
                    }}
                    onClick={() => {
                      handleDisplayOptionsUpdate.mutate({
                        standCount: optionKey,
                      });
                      setIsVisible(false);
                    }}
                    key={optionKey}
                  >
                    {!optionKey ? "All" : `Most Recent ${optionKey}`}
                  </Option>
                );
              })}
          >
            <h2
              onClick={() => {
                setIsVisible((prev) => !prev);
              }}
            >
              {!standCount ? "All" : `Most Recent ${standCount}`}{" "}
              <PDComponent.SvgIcon
                name="chevronDown"
                style={{
                  transform: isVisible ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                  marginLeft: "auto",
                }}
              />
            </h2>
          </Popover>
        </LensSettingsSelector>
      </StandCountSelector>
      <ZoomSelectorContainer>
        <h1>Tag Bottom Fingerprint {lens.showOverallView ? "Overall" : "By Stand"}</h1>
        <div>
          <Tooltip title="Enable zoom">
            <StyledControlsButton
              size="large"
              disabled={Math.abs((domain?.[0] ?? 0) - (domain?.[1] ?? 0)) < 10}
              icon={<PDComponent.SvgIcon name="zoomInAreaSecondary" />}
              onClick={() => setZoomState(ZoomState.Zooming)}
            />
          </Tooltip>

          <Tooltip title="Pan">
            <StyledControlsButton
              onClick={() => setZoomState(ZoomState.Pan)}
              size="large"
              icon={<PDComponent.SvgIcon name="zoomPanSecondary" />}
            />
          </Tooltip>

          <Tooltip title="Reset zoom">
            <StyledControlsButton
              size="large"
              onClick={() => resetZoom()}
              icon={<PDComponent.SvgIcon name="reset" />}
            />
          </Tooltip>
        </div>
      </ZoomSelectorContainer>
    </HeaderContainer>
  );
};
