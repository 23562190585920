import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { ApiKpiTypeUserLensesIdSingleFactsPutRequest, DateDto, SingleStandKpiComparisonDto } from "apis/oag";
import { DisplayOption, KpiTypeUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls/index";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/useSelectedWell";
import type { WellShortInfoResult } from "hooks/useWellShortInfo";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { initialFilters, type IFiltersType, type IZoomData } from "reducers/types";
import { comparisonDisplayOptions } from "reducers/widgetReducer";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new KpiTypeUserLensesApi(apiConfig);

export function singleComparisonWellTransformation({
  facts,
  wellData,
}: {
  facts: SingleStandKpiComparisonDto;
  wellData: WellShortInfoResult | undefined;
}) {
  return {
    ...facts,
    comparisons:
      facts.comparisons?.map((item) => ({
        ...item,
        target: item.info?.targetAverage ?? 0,
        name: wellData?.byId[item.wellId ?? -1]?.name ?? "Undefined",
        globalAverage: facts.summaryByKpi?.allAverage ?? 0,
      })) ?? [],
  };
}

export function useKpiTypeComparisonFacts(lensId: number, options?: UseQueryOptions<SingleStandKpiComparisonDto>) {
  const wellDataID = useSelectedWell();
  const dispatch = useAppDispatch();
  const [
    {
      sections: sectionIds,
      operationTime,
      directionalIntervals: directionalIntervalIds,
      phases: phaseIds,
      holeSizes: holeSizeIds,
    },
  ] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, initialFilters);
  const [
    { ts_start: zoomStartDuration, ts_end: zoomEndDuration, depth_start: zoomStartDepth, depth_end: zoomEndDepth },
  ] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);
  const [offsetWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);
  const displayOption = useAppSelector((state) => state.widgetOptions.display_options);

  const requestParameters: ApiKpiTypeUserLensesIdSingleFactsPutRequest = {
    id: lensId ?? -1,
    baseDisplayOptionUserLensQueryDto: {
      selectedFilters: {
        sectionIds,
        directionalIntervalIds,
        holeSizeIds,
        phaseIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNoneAlphaRigs: true,
      },
      zoomStartDepth,
      zoomEndDepth,
      zoomStartDuration,
      zoomEndDuration,
      wellId: wellDataID,
      displayOption: comparisonDisplayOptions.includes(displayOption) ? displayOption : DisplayOption.Well,
      comparisonWellIds: offsetWells.map((id) => +id),
      useMockData: false,
      sampleCount: TVD_SAMPLE_COUNT,
      from: defaultDateDto.from,
      to: defaultDateDto.to,
    },
  };

  const queryKey: PDLensFactsQueryKey<ApiKpiTypeUserLensesIdSingleFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.kpiTypeFacts,
    params: requestParameters,
    lensId,
    comparisonType: "comparison",
  };

  const result = useQuery<SingleStandKpiComparisonDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api.apiKpiTypeUserLensesIdComparisonFactsPut(requestParameters, { signal }).then((data) => {
        if (data?.comparisons) {
          const payload: { [key: number]: DateDto } = {};
          data?.comparisons.forEach((e) => (payload[e.wellId] = e.lastUpdatedAt));
          dispatch({
            type: "SET_PER_STAND_REFRESH_DATE",
            payload: { ...payload },
          });
        }
        return data;
      }),
    ...options,
  });

  return result;
}
