import { EllipsisTextCSS } from "commonStyles";
import { PDComponent } from "components/PDComponents";
import TextareaAutosize from "react-textarea-autosize";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Dropdown } from "utils/componentLibrary";

const ROW_PADDING_PX = 6;

export const QuestionGroup = styled(PDComponent.ScrollHintContainer)`
  width: 726px;
  padding-right: 26px;
  left: 13px;
  margin: auto;
  position: relative;
  height: calc(100% - 30px);
  overflow: scroll;

  @media screen and (max-width: 1024px) {
    width: 470px;
  }

  h1,
  h2,
  h3 {
    font-size: 32px;
    font-weight: 500;
    color: ${({ theme: { isDark } }) => (isDark ? colors.lunar_rays : colors.necron_compound)};
    margin-bottom: 12px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 16px;
  }
  h1 {
    background: linear-gradient(180deg, ${colors.aphrodite_aqua} 28.54%, #5b5b5b 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  svg {
    color: ${colors.lifeless_green};
  }
  span {
    color: ${({ theme }) => theme.themeStyle.colors.black_white};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const QuestionBox = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  border-radius: 8px;
  padding: 12px 10px 12px 16px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 8px;
`;

export const QuestionRow = styled.div`
  width: 100%;
  padding: 8px;
  height: 32px;
  gap: 6px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.black_white};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: calc(100% - ${ROW_PADDING_PX}px);

  &:hover {
    background-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  }
`;

export const DotsSimpleButton = styled.div<{ $forceShow?: boolean }>`
  display: ${({ $forceShow }) => ($forceShow ? "inline-block" : "none")};
  right: 18px;
  border-radius: 100%;

  svg {
    color: ${colors.limo_scene};
  }

  &:hover {
    background-color: ${({ theme: { isDark } }) => (isDark ? colors.revolver : colors.lunar_rays)};
  }
`;

export const DotsIcon = styled(PDComponent.SvgIcon).attrs({ name: "overflowMenu" })`
  transform: rotate(90deg);
  svg {
    color: ${({ theme }) => theme.themeStyle.colors.black_white};
  }
`;

export const FavoriteQuestionRow = styled(QuestionRow)<{ $forceHideButton: boolean }>`
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ $forceHideButton }) =>
    $forceHideButton
      ? null
      : css`
          &:hover {
            ${DotsSimpleButton} {
              display: inline-block;
            }
          }
        `}
`;

export const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding-bottom: 8px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-weight: 500;
`;

export const QuestionRowContainer = styled.div`
  max-height: 152px; // corresponding to 4 visible rows
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DragIcon = styled(PDComponent.SvgIcon).attrs({ name: "drag" })<{ $isDragging: boolean }>`
  padding-right: 8px;
  padding-left: 4px;
  cursor: ${({ $isDragging }) => ($isDragging ? "grabbing" : "grab")};
  svg {
    color: ${({ theme }) => theme.themeStyle.colors.black_white};
  }
`;

export const PromptMessage = styled.div`
  ${EllipsisTextCSS};
  display: inline-block;
  width: 100%;
`;

export const StyledDropDown = styled(Dropdown)``;

export const OptionsMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_bg};
  width: 120px;
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.12);
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-size: 14px;
  cursor: pointer;
`;

export const OptionRow = styled.div`
  padding: 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 30px;

  &:hover {
    background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  }
`;

export const FavoriteQuestionEditTextarea = styled(TextareaAutosize)`
  overflow: hidden;
  height: 100%;
  width: 100%;
  resize: none;
  max-height: 100%;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 40px;
  border-width: 1px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  line-height: 26px;

  &:focus {
    opacity: 1;
    outline: none;
  }
`;

export const CloseIcon = styled(PDComponent.SvgIcon).attrs({ name: "close" })`
  border-radius: 100%;
  position: absolute;
  right: 8px;
  top: 8px;
  height: 16px;
  width: 16px;
  cursor: pointer;
  background-color: ${colors.limo_scene};
  svg {
    color: ${colors.white};
  }
`;

export const TextAreaWithButton = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;
