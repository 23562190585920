export enum SetterEnum {
  "Timeline",
  "TimeVsDepth",
}
export interface ITimelineInfo {
  cumulativeDuration: number;
  depth: number | null;
  type: SetterEnum;
}
export interface ITimelineOverride {
  timelineOverride: ITimelineInfo | null;
  setTimelineOverride: React.Dispatch<ITimelineInfo | null> | null;
}
