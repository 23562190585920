import type {
  RigFleetPerformanceCardUserLensDto,
  ScoreSummaryLensDto,
  ScoreSummaryRootDto,
  ScoreSummaryTabDto,
  ScoreSummaryTabGroupDto,
  UserLensTabDto,
} from "apis/oag";
import { DashboardType, TimeUnit } from "apis/oag";
import { Title } from "atoms/Typography";
import { Collapsible } from "components/Collapsible";
import { useKpiTypes } from "hooks/useKpiTypes";
import { useUserLenses } from "hooks/useUserLenses";
import { useUserLensTabs } from "hooks/useUserLensTabs";
import {
  BREAKOUT_BAR_HEIGHT,
  BREAKOUT_BAR_HEIGHT_SMALL,
  BREAKOUT_BAR_WIDTH,
} from "pages/RigLeaderboard/components/utils";
import { BenchmarkType, ScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";
import { Fragment, useCallback, useContext, useMemo } from "react";
import { Space } from "utils/componentLibrary";
import { useTimeUom } from "utils/format";

import { HorizontalBars } from "./HorizontalBars";
import * as Styled from "./styles";
import type { IPartialRig } from "./useScoreBreakoutModal";

const TabsInner = ({
  barRatio,
  hourDisplay,
  lens,
}: {
  lens: ScoreSummaryLensDto;
  hourDisplay: string;
  barRatio: number;
}) => {
  const { data: userLenses } = useUserLenses<RigFleetPerformanceCardUserLensDto>({
    staleTime: Infinity,
  });
  const { data: standKpiTypes } = useKpiTypes();

  const getLabelByLensId = (lensId: number) => {
    if (!userLenses) return "";
    const userLens = userLenses.byId[lensId];
    return standKpiTypes?.byId[userLens.cardKpiType]?.shortName || "";
  };

  return (
    <Styled.TabInner key={lens.lensId}>
      <Title level={4}>{getLabelByLensId(lens.lensId) || " - - "}</Title>

      <Space size={12} key={lens.lensId}>
        <Title level={5}>{hourDisplay} </Title>
        <HorizontalBars height={BREAKOUT_BAR_HEIGHT_SMALL} width={BREAKOUT_BAR_WIDTH} ratio={barRatio} />
      </Space>
    </Styled.TabInner>
  );
};

const CollapsibleInner = ({
  tab,
  getMaxTimeByBenchmarkType,
  getTimeByBenchmarkType,
  scoreSummary,
  lensTabs,
}: {
  tab: ScoreSummaryTabDto;
  getMaxTimeByBenchmarkType: (group: ScoreSummaryRootDto) => number;
  getTimeByBenchmarkType: (group: ScoreSummaryTabGroupDto | ScoreSummaryLensDto) => number;
  scoreSummary?: ScoreSummaryRootDto;
  lensTabs: UserLensTabDto[];
}) => {
  const hourUom = useTimeUom(TimeUnit.Hour);
  const hasData = tab.children?.some((lens) => getTimeByBenchmarkType(lens));
  const lensTab = lensTabs.find(({ id }) => id === tab.tabId);
  const name = lensTab?.shortName || "";
  const description = lensTab?.description || "";
  return hasData ? (
    <Styled.CollapsibleInner key={tab.tabId}>
      <Styled.InnerTitle>
        {name}
        {description ? <span> {description}</span> : null}
      </Styled.InnerTitle>
      <Styled.TabContainer>
        {tab.children?.map((lens) => (
          <Fragment key={lens.lensId}>
            {getTimeByBenchmarkType(lens) && scoreSummary ? (
              <TabsInner
                barRatio={getTimeByBenchmarkType(lens) / getMaxTimeByBenchmarkType(scoreSummary)}
                hourDisplay={`${getTimeByBenchmarkType(lens) > 0 ? "+" : ""}${hourUom.display(
                  getTimeByBenchmarkType(lens),
                )}`}
                lens={lens}
                key={lens.lensId}
              />
            ) : null}
          </Fragment>
        ))}
      </Styled.TabContainer>
    </Styled.CollapsibleInner>
  ) : null;
};

export const ScorecardBreakout = ({ selectedRig }: { selectedRig: IPartialRig }) => {
  const hourUom = useTimeUom(TimeUnit.Hour);
  const benchmarkType = useContext(ScoreBenchmarkContext);
  const scoreSelectors = useMemo<{
    timeSelector: "targetDeltaTime" | "invisibleLostTime";
    maxTimeSelector: "targetDeltaTimeMaxRange" | "invisibleLostTimeMaxRange";
  }>(() => {
    return benchmarkType?.scoreBenchmark === BenchmarkType.OperatorTarget
      ? ({
          timeSelector: "targetDeltaTime",
          maxTimeSelector: "targetDeltaTimeMaxRange",
        } as const)
      : ({
          timeSelector: "invisibleLostTime",
          maxTimeSelector: "invisibleLostTimeMaxRange",
        } as const);
  }, [benchmarkType]);
  const { data: lensTabs } = useUserLensTabs(DashboardType.RigScorecard, {
    staleTime: Infinity,
  });
  const getTimeByBenchmarkType = useCallback(
    (group: ScoreSummaryTabGroupDto | ScoreSummaryLensDto) => {
      return group[scoreSelectors.timeSelector] || 0;
    },
    [scoreSelectors],
  );

  const getMaxTimeByBenchmarkType = useCallback(
    (group: ScoreSummaryRootDto) => {
      return group[scoreSelectors.maxTimeSelector];
    },
    [scoreSelectors],
  );

  return (
    <Styled.CollapsibleContainer>
      {selectedRig.scoreSummary?.children?.map((group) => (
        <Collapsible
          key={group.groupKey}
          title={group.groupKey || ""}
          rightContent={
            <Space size={12}>
              <Title level={3}>
                {(getTimeByBenchmarkType(group) || 0) > 0 ? "+" : ""}
                {hourUom.display(getTimeByBenchmarkType(group))}{" "}
              </Title>
              <HorizontalBars
                height={BREAKOUT_BAR_HEIGHT}
                width={BREAKOUT_BAR_WIDTH}
                ratio={
                  !selectedRig.scoreSummary
                    ? 0
                    : (getTimeByBenchmarkType(group) || 0) / getMaxTimeByBenchmarkType(selectedRig.scoreSummary)
                }
              />
            </Space>
          }
        >
          {group.children?.map((tab) => (
            <CollapsibleInner
              key={tab.tabId}
              getMaxTimeByBenchmarkType={getMaxTimeByBenchmarkType}
              getTimeByBenchmarkType={getTimeByBenchmarkType}
              scoreSummary={selectedRig.scoreSummary}
              lensTabs={lensTabs}
              tab={tab}
            />
          ))}
        </Collapsible>
      ))}
    </Styled.CollapsibleContainer>
  );
};
