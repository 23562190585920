/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotificationType } from './NotificationType';
import {
    NotificationTypeFromJSON,
    NotificationTypeFromJSONTyped,
    NotificationTypeToJSON,
} from './NotificationType';

/**
 * 
 * @export
 * @interface NotificationSubscriptionDto
 */
export interface NotificationSubscriptionDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationSubscriptionDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationSubscriptionDto
     */
    rigId: number;
    /**
     * 
     * @type {Array<NotificationType>}
     * @memberof NotificationSubscriptionDto
     */
    type: Array<NotificationType>;
}

/**
 * Check if a given object implements the NotificationSubscriptionDto interface.
 */
export function instanceOfNotificationSubscriptionDto(value: object): value is NotificationSubscriptionDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('rigId' in value) || value['rigId'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function NotificationSubscriptionDtoFromJSON(json: any): NotificationSubscriptionDto {
    return NotificationSubscriptionDtoFromJSONTyped(json, false);
}

export function NotificationSubscriptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSubscriptionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'rigId': json['RigId'],
        'type': ((json['Type'] as Array<any>).map(NotificationTypeFromJSON)),
    };
}

export function NotificationSubscriptionDtoToJSON(value?: NotificationSubscriptionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'RigId': value['rigId'],
        'Type': ((value['type'] as Array<any>).map(NotificationTypeToJSON)),
    };
}

