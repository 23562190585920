import { useIsAuthenticated } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { transformToLocalUOM } from "components/Header/RightContent";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useEffect } from "react";
import { useAppDispatch } from "reducers/store";
import type { IThemeState } from "reducers/themeReducer";

export const useInitializeUser = () => {
  const isAuthenticated = useIsAuthenticated();
  const user = useCurrentUser(isAuthenticated);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated && !user.isLoading && user.data) {
      Sentry.setUser({
        username: user.data.userName,
        email: user.data.userName,
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        jobTitle: user.data.jobTitle,
        expirationDate: user.data.expirationDate,
        operatorId: user.data.operatorId,
        role: user.data.role,
        status: user.data.status,
        createdAtUtc: user.data.createdAtUtc,
      });
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (user.data?.uiTheme) {
      dispatch({
        type: "SET_THEME",
        payload: { theme: user.data.uiTheme as IThemeState },
      });
      localStorage.setItem("theme", user.data.uiTheme);
    }
  }, [dispatch, user?.data, user?.data?.uiTheme]);

  useEffect(() => {
    if (isAuthenticated && !user.isLoading && user.data) {
      dispatch({
        type: "SET_USER_INFO",
        payload: user.data,
      });
      dispatch({
        type: "SET_UNIT_OF_MEASURE",
        payload: transformToLocalUOM(user.data.systemOfMeasurement),
      });
    }
  }, [dispatch, isAuthenticated, user.data, user.isLoading]);
};
