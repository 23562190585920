import { PDComponent } from "components/PDComponents";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useMemo } from "react";
import { Tooltip } from "utils/componentLibrary";

import * as Styled from "./style";

export const LensHeader = () => {
  const description = "Only rotary drilling points included";
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);

  const showFocalTextDisclaimer = useMemo(() => {
    return comparisonWells.length > 4;
  }, [comparisonWells.length]);

  return (
    <Styled.Container>
      <Styled.InfoContainer>
        <Styled.Text>Stick Slip by Depth</Styled.Text>
        <Tooltip placement="top" title={description} arrowPointAtCenter>
          <PDComponent.SvgIcon name="information" />
        </Tooltip>
      </Styled.InfoContainer>

      {showFocalTextDisclaimer ? (
        <Styled.FocalText>Focal well and 4 most recent Offset wells only.</Styled.FocalText>
      ) : null}
    </Styled.Container>
  );
};
