/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BoilerSlotType } from './BoilerSlotType';
import {
    BoilerSlotTypeFromJSON,
    BoilerSlotTypeFromJSONTyped,
    BoilerSlotTypeToJSON,
} from './BoilerSlotType';
import type { GeneratorSlotType } from './GeneratorSlotType';
import {
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeFromJSONTyped,
    GeneratorSlotTypeToJSON,
} from './GeneratorSlotType';
import type { FuelType } from './FuelType';
import {
    FuelTypeFromJSON,
    FuelTypeFromJSONTyped,
    FuelTypeToJSON,
} from './FuelType';
import type { GasType } from './GasType';
import {
    GasTypeFromJSON,
    GasTypeFromJSONTyped,
    GasTypeToJSON,
} from './GasType';

/**
 * 
 * @export
 * @interface StatCardUserLensDto
 */
export interface StatCardUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof StatCardUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StatCardUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof StatCardUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatCardUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatCardUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatCardUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatCardUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {Array<GeneratorSlotType>}
     * @memberof StatCardUserLensDto
     */
    selectedGenerators: Array<GeneratorSlotType>;
    /**
     * 
     * @type {FuelType}
     * @memberof StatCardUserLensDto
     */
    selectedFuel: FuelType;
    /**
     * 
     * @type {Array<BoilerSlotType>}
     * @memberof StatCardUserLensDto
     */
    selectedBoilers: Array<BoilerSlotType>;
    /**
     * 
     * @type {GasType}
     * @memberof StatCardUserLensDto
     */
    selectedGas: GasType;
}



/**
 * Check if a given object implements the StatCardUserLensDto interface.
 */
export function instanceOfStatCardUserLensDto(value: object): value is StatCardUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('squeezesDisplay' in value) || value['squeezesDisplay'] === undefined) return false;
    if (!('selectedGenerators' in value) || value['selectedGenerators'] === undefined) return false;
    if (!('selectedFuel' in value) || value['selectedFuel'] === undefined) return false;
    if (!('selectedBoilers' in value) || value['selectedBoilers'] === undefined) return false;
    if (!('selectedGas' in value) || value['selectedGas'] === undefined) return false;
    return true;
}

export function StatCardUserLensDtoFromJSON(json: any): StatCardUserLensDto {
    return StatCardUserLensDtoFromJSONTyped(json, false);
}

export function StatCardUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatCardUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedGenerators': ((json['SelectedGenerators'] as Array<any>).map(GeneratorSlotTypeFromJSON)),
        'selectedFuel': FuelTypeFromJSON(json['SelectedFuel']),
        'selectedBoilers': ((json['SelectedBoilers'] as Array<any>).map(BoilerSlotTypeFromJSON)),
        'selectedGas': GasTypeFromJSON(json['SelectedGas']),
    };
}

export function StatCardUserLensDtoToJSON(value?: StatCardUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'SqueezesDisplay': value['squeezesDisplay'],
        'SelectedGenerators': ((value['selectedGenerators'] as Array<any>).map(GeneratorSlotTypeToJSON)),
        'SelectedFuel': FuelTypeToJSON(value['selectedFuel']),
        'SelectedBoilers': ((value['selectedBoilers'] as Array<any>).map(BoilerSlotTypeToJSON)),
        'SelectedGas': GasTypeToJSON(value['selectedGas']),
    };
}

