import type { ScorecardKpiCommentExceptionInfoDto } from "apis/oag";
import {
  type ExceptionRequestNotificationDto,
  type NotificationResultSetDto,
  type ScorecardCommentNotificationDto,
  ScorecardExceptionStatusType,
} from "apis/oag";

export interface ExceptionNotificationVm extends ExceptionRequestNotificationDto {
  popupTitle: string;
}

export interface CommentNotificationVm extends ScorecardCommentNotificationDto {
  popupTitle: string;
}

export type BaseNotificationVm = ExceptionNotificationVm | CommentNotificationVm;

export type NotificationResultSetWithTitleVm = {
  exceptionRequestNotifications: Array<ExceptionNotificationVm>;
  scorecardCommentNotifications: Array<CommentNotificationVm>;
  getNotificationList?: () => BaseNotificationVm[];
  getUnreadUnarchivedCount: (notifications: BaseNotificationVm[]) => number;
};

export const IdempotentMapper = (result: NotificationResultSetDto): NotificationResultSetDto => result;
const exceptionToExceptionVm = (notification: ExceptionRequestNotificationDto) => {
  const getTitle = () => {
    if (notification.exceptionStatus === ScorecardExceptionStatusType.Approved) {
      return "Approved Exception Request";
    }
    if (notification.exceptionStatus === ScorecardExceptionStatusType.Rejected) {
      return "Rejected Exception Request";
    }
    if (notification.isUpdated) {
      return "Updated Exception Request";
    } else {
      return "New Exception Request";
    }
  };
  return {
    ...notification,
    popupTitle: getTitle(),
  };
};

const commentToCommentVm = (notification: ScorecardCommentNotificationDto) => {
  return {
    ...notification,
    popupTitle: notification.isUpdated ? "Updated Scorecard Comment" : "New Scorecard Comment",
  };
};

export const ActiveNotificationsSelector = (result: NotificationResultSetDto): NotificationResultSetWithTitleVm => {
  return {
    exceptionRequestNotifications: result.exceptionRequestNotifications.map(exceptionToExceptionVm),
    scorecardCommentNotifications: result.scorecardCommentNotifications.map(commentToCommentVm),
    getNotificationList: () => {
      return [
        ...(result.exceptionRequestNotifications ?? []).map(exceptionToExceptionVm),
        ...(result.scorecardCommentNotifications ?? []).map(commentToCommentVm),
      ].sort((a, b) => b.createdAt.utc.getTime() - a.createdAt.utc.getTime());
    },
    getUnreadUnarchivedCount: (notifications: BaseNotificationVm[]) => {
      return notifications.filter((notification) => !notification.isRead).filter((n) => !n.isArchived).length;
    },
  };
};
