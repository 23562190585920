import type { ClaraFeedbackDto } from "apis/oag";
import { ClaraResponseVerdictType } from "apis/oag";
import { Title } from "atoms/Typography/index";
import { PDComponent } from "components/PDComponents";
import type { Dispatch, SetStateAction } from "react";
import { useLayoutEffect, useMemo, useRef, useState } from "react";

import { FeedbackContainer, InitialTags, OtherText, StyledCloseIcon, Submit } from "./style";

const favorableTags = ["Correct", "Easy to understand", "Complete"];
const unfavorableTags = ["Incorrect", "Not helpful", "Too long"];

export const FeedbackBox = ({
  verdict,
  verdictReasons,
  setVerdictReasons,
  isOpen,
  messageId,
  onClose,
  onSubmit,
}: {
  verdict: ClaraResponseVerdictType;
  verdictReasons: string[] | null;
  setVerdictReasons: Dispatch<SetStateAction<string[] | null>>;
  isOpen: boolean;
  messageId: number;
  onClose: () => void;
  onSubmit: (feedback: ClaraFeedbackDto) => void;
}) => {
  const tags = useMemo(
    () => (verdict === ClaraResponseVerdictType.Positive ? favorableTags : unfavorableTags),
    [verdict],
  );
  const ref = useRef<HTMLDivElement>(null);
  const [input, setInput] = useState("");
  useLayoutEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <FeedbackContainer ref={ref}>
      <Title level={3}>
        <strong> Please tell us why you chose this rating</strong> (optional)
      </Title>
      <div>
        {tags.map((tag) => (
          <InitialTags
            $isActive={verdictReasons?.[0] === tag}
            key={tag}
            type="button"
            onClick={() => {
              setVerdictReasons([tag]);
              setInput("");
            }}
          >
            {tag}
          </InitialTags>
        ))}
      </div>
      <StyledCloseIcon onClick={onClose}>
        <PDComponent.SvgIcon name="close" />
      </StyledCloseIcon>
      <OtherText
        value={input}
        placeholder="Other (Please feel free to add specific details)"
        onChange={(ev) => {
          setInput(ev.target.value);
          if (ev.target.value.length > 0) {
            setVerdictReasons([ev.target.value]);
          } else {
            setVerdictReasons(null);
          }
        }}
      />
      <Submit
        disabled={verdictReasons === null}
        onClick={() => {
          onSubmit({ messageId, verdict, verdictReasons });
        }}
      >
        Submit
      </Submit>
    </FeedbackContainer>
  );
};
