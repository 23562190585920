import { Line } from "@visx/shape";
import styled from "styled-components";
import colors from "utils/colors";
import { useCustomTheme } from "utils/useTheme";

export interface MedianLineProps {
  isVisible?: boolean;
  x?: number;
  y: number;
  width: number;
  strokeWidth?: number;
}

export const MedianLine: React.VFC<MedianLineProps> = ({ isVisible, x = 0, y, width, strokeWidth = 2 }) => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  if (!isVisible) {
    return null;
  }

  return (
    <Line
      from={{ x, y }}
      to={{ x: x + width, y }}
      strokeWidth={strokeWidth}
      stroke={themeColors.primary_typography}
      strokeDasharray="3,3"
    />
  );
};

export const MedianLabel = styled.div<{ isDetailed: boolean }>`
  position: absolute;
  right: ${({ isDetailed }) => (isDetailed ? "120px" : "80px")};
  height: 32px;
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_chart_bg};
  padding: 9px 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.2px;
  text-align: center;
  color: ${colors.white};
`;
