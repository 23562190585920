import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useRigs } from "hooks/useRigs";
import { useSelectedRig } from "hooks/useSelectedRig";
import { useEffect, useState } from "react";
import { useAppDispatch } from "reducers/store";
import { Track } from "services/Mixpanel";
import { Popover, Tooltip } from "utils/componentLibrary";

// TODO make offset selector agnostic from well or rig

const OffsetRigSelector = ({ detailed }: { detailed?: boolean }) => {
  const dispatch = useAppDispatch();

  const [showSelect, setShowSelect] = useState(false);

  const selectedRig = useSelectedRig();
  const [offsetRigs, setOffsetRigs] = useStateQuery<Array<number>>(
    detailed ? URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET : URL_STATE_PARAM.OFFSET_RIGS,
    [],
    detailed ? [] : [URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET],
  );
  const { data: rigs } = useRigs();

  const onOffsetRigsChange = (selectedOffsetWells: number[] = []) => {
    // TODO add errors too many selected
    Track.interact("Rig - Offset Rig Selector", {
      "Selected Rigs": selectedOffsetWells,
    });
    setOffsetRigs(selectedOffsetWells);
    setShowSelect(false);
    return true;
  };

  useEffect(() => {
    if (offsetRigs.includes(selectedRig)) {
      setOffsetRigs(offsetRigs.filter((e) => e !== selectedRig));
    }
  }, [dispatch, offsetRigs, selectedRig, setOffsetRigs]);

  return (
    <Tooltip title="Offset Rigs">
      <Popover
        content={
          <PDComponent.ComboBoxMultiSelect
            placeholder="Search"
            width={400}
            options={
              (rigs?.list ?? [])
                .filter((e) => e.id !== selectedRig)
                .map((rig) => ({ ...rig, name: rig.shortName })) as IOption<number>[]
            }
            values={offsetRigs}
            onChange={onOffsetRigsChange}
            noSelectAll
          />
        }
        trigger="click"
        placement="bottom"
        open={showSelect}
        onOpenChange={(e) => setShowSelect(e)}
        destroyTooltipOnHide
      >
        <Button
          trackingName="Add Offset Rigs"
          size="large"
          icon={<PDComponent.SvgIcon name="compare" />}
          $engaged={showSelect}
        >
          {offsetRigs.length > 0 ? `${offsetRigs.length} Rigs` : null}
        </Button>
      </Popover>
    </Tooltip>
  );
};

export default OffsetRigSelector;
