import { useMsal } from "@azure/msal-react";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import type { FC } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import { Card, Col, Row } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

import Error401Svg from "./Error401Svg";

const Holder401 = styled.div`
  text-align: center;
  margin-top: 160px;

  .mainHeadline {
    max-width: 380px;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 52px;
  }
  .subline {
    margin-bottom: 40px;
  }
`;

const Error401: FC = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { atomThemeVariant } = useCustomTheme();

  useEffect(() => {
    const isUnauthorized = JSON.parse(localStorage.getItem("unauthorizedApi") || "false");
    if (isUnauthorized) {
      localStorage.removeItem("unauthorizedApi");
      Track.event("Log Out", { reason: "Error - 401" });
      instance.logoutRedirect();
    }
  }, [instance]);

  const { errorId } = useParams();

  return (
    <Row align="middle" justify="center">
      <Col style={{ width: "480px" }}>
        <Holder401>
          <Error401Svg />
          <Title level={1} variant={atomThemeVariant} weight={500} className="mainHeadline">
            Missing permissions
          </Title>
          <Title level={3} variant={atomThemeVariant} className="subline">
            Looks like you do not have access to this content. You can go back to the All Wells page.
            { errorId ? ` (${errorId})` : null }
          </Title>
          <Card bordered style={{ borderRadius: "10px" }}>
            <Button block type="primary" onClick={() => navigate(`/`)}>
              Go to All Wells
            </Button>
          </Card>
        </Holder401>
      </Col>
    </Row>
  );
};

export default Error401;
