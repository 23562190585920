import { Title } from "atoms/Typography";
import { SCROLLBAR_WIDTH_PX } from "components/Layout/LayoutUtils";
import styled, { css } from "styled-components";
import colors from "utils/colors";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  min-width: 0;
`;

export const RowContainer = styled.div`
  height: calc(100vh - 119px - 64px - 152px - 45px - 56px);
  margin-top: 12px;
`;

const RowCss = css`
  height: 56px !important;
  padding: 17px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr 1.8fr;
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  width: calc(100% - ${SCROLLBAR_WIDTH_PX * 2}px);
  ${RowCss};
`;

export const Row = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  width: calc(100% - ${SCROLLBAR_WIDTH_PX}px) !important;
  ${RowCss};
`;

export const Col = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`;

export const RigName = styled(Title)`
  font-size: 16px !important;
  font-weight: 500;
`;

export const CheckboxWithLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  gap: 8px;
  user-select: none;

  label:hover {
    cursor: pointer;
  }
`;
