/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigPowerDistributionBarDto } from './RigPowerDistributionBarDto';
import {
    RigPowerDistributionBarDtoFromJSON,
    RigPowerDistributionBarDtoFromJSONTyped,
    RigPowerDistributionBarDtoToJSON,
} from './RigPowerDistributionBarDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { PowerLoadDistributionType } from './PowerLoadDistributionType';
import {
    PowerLoadDistributionTypeFromJSON,
    PowerLoadDistributionTypeFromJSONTyped,
    PowerLoadDistributionTypeToJSON,
} from './PowerLoadDistributionType';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface PowerLoadDistributionFactSetDto
 */
export interface PowerLoadDistributionFactSetDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof PowerLoadDistributionFactSetDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof PowerLoadDistributionFactSetDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PowerLoadDistributionFactSetDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof PowerLoadDistributionFactSetDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {PowerLoadDistributionType}
     * @memberof PowerLoadDistributionFactSetDto
     */
    distributionType: PowerLoadDistributionType;
    /**
     * 
     * @type {Array<RigPowerDistributionBarDto>}
     * @memberof PowerLoadDistributionFactSetDto
     */
    bars?: Array<RigPowerDistributionBarDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PowerLoadDistributionFactSetDto
     */
    availableDistributionReferenceIds?: Array<number> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof PowerLoadDistributionFactSetDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the PowerLoadDistributionFactSetDto interface.
 */
export function instanceOfPowerLoadDistributionFactSetDto(value: object): value is PowerLoadDistributionFactSetDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('distributionType' in value) || value['distributionType'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function PowerLoadDistributionFactSetDtoFromJSON(json: any): PowerLoadDistributionFactSetDto {
    return PowerLoadDistributionFactSetDtoFromJSONTyped(json, false);
}

export function PowerLoadDistributionFactSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowerLoadDistributionFactSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'distributionType': PowerLoadDistributionTypeFromJSON(json['DistributionType']),
        'bars': json['Bars'] == null ? undefined : ((json['Bars'] as Array<any>).map(RigPowerDistributionBarDtoFromJSON)),
        'availableDistributionReferenceIds': json['AvailableDistributionReferenceIds'] == null ? undefined : json['AvailableDistributionReferenceIds'],
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function PowerLoadDistributionFactSetDtoToJSON(value?: PowerLoadDistributionFactSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'DistributionType': PowerLoadDistributionTypeToJSON(value['distributionType']),
        'Bars': value['bars'] == null ? undefined : ((value['bars'] as Array<any>).map(RigPowerDistributionBarDtoToJSON)),
        'AvailableDistributionReferenceIds': value['availableDistributionReferenceIds'],
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

