/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountRigAccessDto } from './AccountRigAccessDto';
import {
    AccountRigAccessDtoFromJSON,
    AccountRigAccessDtoFromJSONTyped,
    AccountRigAccessDtoToJSON,
} from './AccountRigAccessDto';

/**
 * 
 * @export
 * @interface AccountRigAccessSetDto
 */
export interface AccountRigAccessSetDto {
    /**
     * 
     * @type {boolean}
     * @memberof AccountRigAccessSetDto
     */
    futureRigAccess: boolean;
    /**
     * 
     * @type {Array<AccountRigAccessDto>}
     * @memberof AccountRigAccessSetDto
     */
    wellAccess?: Array<AccountRigAccessDto> | null;
}

/**
 * Check if a given object implements the AccountRigAccessSetDto interface.
 */
export function instanceOfAccountRigAccessSetDto(value: object): value is AccountRigAccessSetDto {
    if (!('futureRigAccess' in value) || value['futureRigAccess'] === undefined) return false;
    return true;
}

export function AccountRigAccessSetDtoFromJSON(json: any): AccountRigAccessSetDto {
    return AccountRigAccessSetDtoFromJSONTyped(json, false);
}

export function AccountRigAccessSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountRigAccessSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'futureRigAccess': json['FutureRigAccess'],
        'wellAccess': json['WellAccess'] == null ? undefined : ((json['WellAccess'] as Array<any>).map(AccountRigAccessDtoFromJSON)),
    };
}

export function AccountRigAccessSetDtoToJSON(value?: AccountRigAccessSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'FutureRigAccess': value['futureRigAccess'],
        'WellAccess': value['wellAccess'] == null ? undefined : ((value['wellAccess'] as Array<any>).map(AccountRigAccessDtoToJSON)),
    };
}

