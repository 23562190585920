/* eslint-disable react/no-multi-comp */
import { Title } from "atoms/Typography";
import CoreLayout from "components/Layout";
import { PDComponent } from "components/PDComponents";
import { filterBaseOptions } from "components/WellDashboard/ControlHeader/atoms/Filters";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useDirectionalIntervals } from "hooks/useDirectionalIntervals";
import { useHoleSections } from "hooks/useHoleSections";
import { useOperators } from "hooks/useOperators";
import { useRigs } from "hooks/useRigs";
import { useWellDetails } from "hooks/useWellDetails";
import { useWellRanges } from "hooks/useWellRanges";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "reducers/store";
import { initialFilters, type IFiltersType, type IUnitSystem } from "reducers/types";
import styled from "styled-components";
import { Col, Divider, Row, Space } from "utils/componentLibrary";
import { dimensionMap } from "utils/format";
import { formatTime } from "utils/helper";

import mainLogo from "./report-symbol.png";

const SidebarContainer = styled.div`
  background: url("/assets/bg/report-side.jpg") no-repeat;
  background-position: 25% -550px;
  background-size: cover;
  height: 100%;
  padding: 78px 78px 104px 78px;
`;

const MainPageContainer = styled.div`
  background-color: #141b1d;
  padding: 88px 88px 104px 88px;
  height: 100%;
`;

const StyledDivider = styled(Divider)`
  border-top-width: 4px;
`;

const SingleCell = ({ title, main }: { title: string; main: string }) => {
  return (
    <Space size={8} direction="vertical">
      <Title level={3} variant="faded">
        {title}
      </Title>
      <Title level={2} variant="white" weight={500}>
        {main}
      </Title>
    </Space>
  );
};

const SingleDesc = ({ title, main }: { title: string; main: string }) => {
  return (
    <Space size={8} direction="vertical">
      <Title level={3} variant="faded">
        {title}
      </Title>
      <Title level={3} variant="white" weight={500}>
        {main}
      </Title>
    </Space>
  );
};

const formatDepth = (val: number, unit: IUnitSystem) => {
  if (typeof val !== "number") return;
  return dimensionMap.Metres[unit](val);
};

const FrontPage = () => {
  const params = useParams<{ wellId: string }>();
  const rigs = useRigs();

  const typeUnit = useAppSelector((state) => state.global.unit);

  const { data: wellData } = useWellDetails(Number(params.wellId));
  const { data: operatorData } = useOperators();
  const { data: holeSectionsData } = useHoleSections();
  const { data: rangeData } = useWellRanges();
  const { data: directionalIntervals } = useDirectionalIntervals();
  const [filtersState] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, initialFilters);
  const [offsetWells] = useStateQuery<number[]>(URL_STATE_PARAM.OFFSET_WIDGET, []);

  const uniqueRigIdsLabel = useMemo(() => {
    const uniqueRigIds = [...new Set(wellData?.jobs?.map((e) => e.rigId))];
    return uniqueRigIds.map((e) => rigs?.data?.byId[e]?.shortName || "").join(", ");
  }, [wellData?.jobs, rigs?.data]);

  return (
    <CoreLayout
      sidebarColor="#0F1517"
      sidebarwidth={"31%"}
      sidebar={
        <SidebarContainer>
          <Row justify="end" style={{ flexFlow: "column wrap", height: "100%" }}>
            <Col flex="0 auto">
              <Space direction="vertical" size={32}>
                <SingleDesc
                  title="Hole Section"
                  main={
                    holeSectionsData
                      ? (filtersState?.sections ? filtersState.sections : holeSectionsData.map((e) => e.id))
                          .map((e) => holeSectionsData.find((f) => f.id === e)?.name)
                          .join(", ")
                      : ""
                  }
                />
                <SingleDesc
                  title="Directional Intervals"
                  main={
                    directionalIntervals
                      ? (filtersState?.directionalIntervals
                          ? filtersState.directionalIntervals
                          : directionalIntervals.map((e) => e.id) ?? []
                        )
                          .map((e) => directionalIntervals.find((interval) => interval.id === e)?.name)
                          .join(", ") ?? directionalIntervals.map((e) => e.name).join(", ")
                      : ""
                  }
                />
                <SingleDesc
                  title="Operations"
                  main={(filtersState?.operationTime
                    ? filtersState.operationTime
                    : filterBaseOptions?.operationTime.options.map((e) => e.value) ?? []
                  ).join(", ")}
                />
                <SingleDesc title="Offset Wells" main={`${offsetWells.length} Wells`} />
              </Space>
            </Col>
          </Row>
        </SidebarContainer>
      }
    >
      <MainPageContainer>
        <Row justify="space-between" style={{ flexFlow: "column wrap", height: "100%" }}>
          <Col flex="0 auto">
            <Space>
              <img
                src={mainLogo}
                style={{
                  height: 48,
                  width: 48,
                }}
                alt="fireSpot"
              />
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Title level={1} variant="white" weight={500}>
                  Clarity
                </Title>
              </Col>
            </Space>
          </Col>
          <Col flex="0 auto">
            <Title level={3} variant="faded">
              {localStorage.getItem("reportTime")}
            </Title>
            <Title level={1} variant="white" style={{ fontSize: "52px", marginTop: "16px" }} weight={500}>
              {/* the simple hyphen is replaced with a non-breaking hyphen (U+2011). they look the same */}
              {wellData?.name?.replaceAll("-", "‑")}
            </Title>
            <StyledDivider
              style={{
                marginTop: "24px",
                marginBottom: "48px",
                borderColor: "white",
                opacity: 0.06,
              }}
            />
            <Row justify="space-between">
              <Col flex="0 auto">
                <Space direction="vertical" size={32}>
                  <SingleCell title="Rig(s)" main={uniqueRigIdsLabel} />
                  {wellData?.operatorId ? (
                    <SingleCell title="Operator" main={operatorData?.byId[wellData?.operatorId]?.name || ""} />
                  ) : null}
                </Space>
              </Col>
              <Col flex="0 auto" style={{ textAlign: "right" }}>
                <Space direction="vertical" size={32}>
                  <SingleCell
                    title="Duration"
                    main={`${formatTime(rangeData?.startAt, {
                      formatStr: "MM/DD h:mm A",
                    })} – ${formatTime(rangeData?.endAt, { formatStr: "MM/DD h:mm A" })}`}
                  />
                  <SingleCell
                    title="Measured Depth"
                    main={`${formatDepth(rangeData?.startHoleDepth, typeUnit)} – ${formatDepth(
                      rangeData?.endHoleDepth,
                      typeUnit,
                    )}`}
                  />
                </Space>
              </Col>
            </Row>
            <StyledDivider style={{ marginTop: "24px", marginBottom: "48px", borderColor: "white", opacity: 0.06 }} />
            <Space>
              <Row>
                <Col>
                  <Title level={1} variant="white">
                    <PDComponent.SvgIcon name="pdLogo" />
                  </Title>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 12.5,
                  }}
                >
                  <Title level={3} variant="white">
                    By Precision Drilling © {new Date().getFullYear()}
                  </Title>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </MainPageContainer>
    </CoreLayout>
  );
};

export default FrontPage;
