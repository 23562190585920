import type { ActualTvdPointDto } from "apis/oag";
import { InputBlur } from "atoms/Form";
import { initialZoomData } from "components/WellDashboard/ChartControls/index";
import dayjs from "dayjs";
import { useDisplayLocalWellTime } from "hooks/useLocalWellTime";
import moment from "moment";
import type { ReactNode } from "react";
import { useCallback } from "react";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import { defaultDateDto, secondsInDay } from "utils/common";
import { DatePicker } from "utils/componentLibrary";
import { msInMin } from "utils/helper";

import { CustomInputGroup } from "./StyledComponents";
import { convertDateToDuration, convertDurationToDate, DatepickDateFormat, displayDate, Suffix } from "./utils";

enum INPUT_LIMITS {
  START = "start",
  END = "end",
}

dayjs.locale("en");

const LOWERCASE_E_KEYCODE = 69;
const getClosestOffset = (seriesData: ActualTvdPointDto[], dateMs: number) => {
  if (!seriesData?.length) return 0;
  const pointIndex = seriesData.findIndex((point) => point.at.utc.getTime() - point.at.minutesOffset * msInMin >= dateMs);
  if (pointIndex === -1) {
    return seriesData[seriesData.length - 1].at.minutesOffset;
  }
  if (pointIndex === 0) {
    return seriesData[0].at.minutesOffset;
  }
  const currentPoint = seriesData[pointIndex].at;
  const prevPoint = seriesData[pointIndex - 1].at;
  const difference = Math.abs(currentPoint.utc.getTime() - (currentPoint.minutesOffset * msInMin) - dateMs);
  const differencePrevPoint = Math.abs(prevPoint.utc.getTime() - (prevPoint.minutesOffset * msInMin) - dateMs);
  return difference < differencePrevPoint ? currentPoint.minutesOffset : prevPoint.minutesOffset;
};


export const DataSelectorByType = ({
  disabled,
  localZoom,
  setLocalZoom,
  uomRatio,
  seriesData,
  timeAtDynamicSelected,
}: {
  disabled: boolean;
  localZoom: IZoomData;
  setLocalZoom: (value: React.SetStateAction<IZoomData>) => void;
  uomRatio: number;
  seriesData: ActualTvdPointDto[];
  timeAtDynamicSelected: number;
}) => {
  const handleOnKeydown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === LOWERCASE_E_KEYCODE) {
      event.preventDefault();
    }
  }, []);

  const { displayInLocalWellTime, utcFromWellOffset } = useDisplayLocalWellTime();

  if (!localZoom) return null;

  function renderPickerBasedOnType(type: IZoomType): ReactNode {
    switch (type) {
      case IZoomType.DEPTH:
        return (
          <>
            {Object.values(INPUT_LIMITS).map((limit) => {
              const value: number = +(localZoom[`${IZoomType.DEPTH}_${limit}`] || 0);

              return (
                <InputBlur
                  key={limit}
                  suffix={<Suffix disabled={disabled} type={IZoomType.DEPTH} />}
                  type="number"
                  onKeyDown={handleOnKeydown}
                  min={0}
                  step={0.01}
                  value={Number.isFinite(value) ? Math.floor(value * uomRatio).toFixed(0) : undefined}
                  onChange={(e) => {
                    const newValue = +e.target.value;
                    if (!newValue) return;

                    return setLocalZoom((prev) => {
                      const newLocalZoom = {
                        ...prev,
                      };

                      newLocalZoom[`${IZoomType.DEPTH}_${limit}`] = Number.isFinite(newValue)
                        ? +newValue / uomRatio
                        : 0;

                      return {
                        ...newLocalZoom,
                        internal_zoom: false,
                      };
                    });
                  }}
                />
              );
            })}
          </>
        );

      case IZoomType.TIME:
        return (
          <>
            {Object.values(INPUT_LIMITS).map((limit) => {
              let displayValue;
              const timeValue: number = +(localZoom[`${IZoomType.TIME}_${limit}`] || 0);

              if (timeValue && timeValue > 0) {
                displayValue = (timeValue / secondsInDay).toFixed(2);
              } else if (localZoom[`${IZoomType.DATE}_${limit}`]) {
                displayValue = (
                  convertDateToDuration(seriesData)(
                    localZoom[`${IZoomType.DATE}_${limit}`] || defaultDateDto.from.utc,
                  ) / secondsInDay
                ).toFixed(2);
              } else if (limit === INPUT_LIMITS.START && localZoom[`ts_start`] < 0) {
                // Todo implement later improvement to translate last X period
                displayValue = null;
              } else {
                displayValue = null;
              }

              return (
                <InputBlur
                  key={limit}
                  suffix={<Suffix disabled={disabled} type={IZoomType.TIME} />}
                  type="number"
                  onKeyDown={handleOnKeydown}
                  min={0}
                  step={0.01}
                  value={displayValue || ""}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (!newValue) return;

                    return setLocalZoom((prev) => {
                      const newLocalZoom = {
                        ...prev,
                      };

                      newLocalZoom[
                        `${IZoomType.TIME}_${limit}` as keyof Omit<
                          IZoomData,
                          "type" | "date_start" | "date_end" | "internal_zoom"
                        >
                      ] = Math.round(secondsInDay * +newValue);

                      newLocalZoom[`${IZoomType.DATE}_${limit}`] = new Date(
                        convertDurationToDate(seriesData)(secondsInDay * +newValue),
                      );

                      return {
                        ...newLocalZoom,
                        internal_zoom: false,
                      };
                    });
                  }}
                />
              );
            })}
          </>
        );

      case IZoomType.DATE:
        return (
          <>
            {Object.values(INPUT_LIMITS).map((limit) => {
              return (
                <DatePicker
                  showTime
                  key={limit}
                  placeholder=""
                  format={DatepickDateFormat}
                  suffixIcon={<span>{`${limit[0].toUpperCase()}${limit.slice(1)}`}</span>}
                  onChange={(momentDate) => {
                    return setLocalZoom((prev) => {
                      const newLocalZoom: IZoomData = {
                        ...initialZoomData,
                        ...prev,
                        type: IZoomType.DATE,
                      };
                      const crtTime = (momentDate?.toDate().getTime() ?? 0) - (new Date().getTimezoneOffset() * msInMin);
                      const calculatedOffset = getClosestOffset(seriesData, crtTime);

                      const userSelectedDate = momentDate ? utcFromWellOffset(momentDate.toDate(), calculatedOffset).toDate() : null;

                      if (userSelectedDate) {
                        if (limit === INPUT_LIMITS.START) {
                          newLocalZoom['date_start'] = userSelectedDate;
                          newLocalZoom['ts_start'] = convertDateToDuration(seriesData)(userSelectedDate);
                          newLocalZoom['date_start_well_offset'] = calculatedOffset;
                        } else {
                          newLocalZoom[`date_end`] = userSelectedDate;
                          newLocalZoom[`ts_end`] = convertDateToDuration(seriesData)(userSelectedDate);
                          newLocalZoom['date_end_well_offset'] = calculatedOffset;
                        }
                      }
                      return { ...newLocalZoom, internal_zoom: false };
                    });
                  }}
                  value={(() => {
                    const userSelectedDate = localZoom[`date_${limit}`];
                    if (userSelectedDate) {
                      return displayInLocalWellTime(userSelectedDate, localZoom[`date_${limit}_well_offset`]);
                    } else if (localZoom[`ts_${limit}`]) {
                      return moment(displayDate({ data: seriesData, zoomInfo: localZoom })(localZoom[`ts_${limit}`]));
                    } else {
                      return null;
                    }
                  })()}
                  allowClear={false}
                />
              );
            })}
          </>
        );

      case IZoomType.DYNAMIC_WINDOW:
        return (
          <>
            {Object.values(INPUT_LIMITS).map((limit) => {
              const initialValue: number | null = localZoom[`ts_${limit}`];
              return (
                <DatePicker
                  showTime
                  key={limit}
                  placeholder={DatepickDateFormat}
                  format={DatepickDateFormat}
                  suffixIcon={<span>{`${limit[0].toUpperCase()}${limit.slice(1)}`}</span>}
                  onChange={(momentDate) => {
                    return setLocalZoom((prev) => {
                      const newLocalZoom: IZoomData = {
                        ...initialZoomData,
                        ...prev,
                        type: IZoomType.DATE,
                      };

                      const userSelectedDate = momentDate ? utcFromWellOffset(momentDate.toDate(), localZoom[
                        `date_${limit}_well_offset`
                      ]).toDate() : null;

                      const newDateStart = new Date(timeAtDynamicSelected);
                      if (userSelectedDate) {
                        if (limit === INPUT_LIMITS.END) {
                          newLocalZoom[`date_start`] = newDateStart;
                          newLocalZoom[`date_end`] = userSelectedDate;
                          newLocalZoom[`ts_start`] = convertDateToDuration(seriesData)(newDateStart);
                          newLocalZoom[`ts_end`] = convertDateToDuration(seriesData)(userSelectedDate);
                        } else {
                          newLocalZoom[`date_start`] = userSelectedDate;
                          newLocalZoom[`ts_start`] = convertDateToDuration(seriesData)(userSelectedDate);
                        }
                      }

                      return { ...newLocalZoom, internal_zoom: false };
                    });
                  }}
                  value={(() => {
                    return initialValue ? displayInLocalWellTime(new Date(timeAtDynamicSelected), localZoom[
                      `date_${limit}_well_offset`
                    ]) : null;
                  })()}
                  allowClear={false}
                />
              );
            })}
          </>
        );

      default:
        <span>No match</span>;
    }
  }

  return <CustomInputGroup>{renderPickerBasedOnType(localZoom.type)}</CustomInputGroup>;
};
