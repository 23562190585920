/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface RigLeaderboardQueryDto
 */
export interface RigLeaderboardQueryDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof RigLeaderboardQueryDto
     */
    formationIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigLeaderboardQueryDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigLeaderboardQueryDto
     */
    rigIds?: Array<number> | null;
    /**
     * 
     * @type {DateDto}
     * @memberof RigLeaderboardQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof RigLeaderboardQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {boolean}
     * @memberof RigLeaderboardQueryDto
     */
    includeFullWellFacts: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof RigLeaderboardQueryDto
     */
    selectedWellIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof RigLeaderboardQueryDto
     */
    selectedFilters: FiltersDto;
    /**
     * 
     * @type {boolean}
     * @memberof RigLeaderboardQueryDto
     */
    useExceptions: boolean;
}

/**
 * Check if a given object implements the RigLeaderboardQueryDto interface.
 */
export function instanceOfRigLeaderboardQueryDto(value: object): value is RigLeaderboardQueryDto {
    if (!('from' in value) || value['from'] === undefined) return false;
    if (!('to' in value) || value['to'] === undefined) return false;
    if (!('includeFullWellFacts' in value) || value['includeFullWellFacts'] === undefined) return false;
    if (!('selectedFilters' in value) || value['selectedFilters'] === undefined) return false;
    if (!('useExceptions' in value) || value['useExceptions'] === undefined) return false;
    return true;
}

export function RigLeaderboardQueryDtoFromJSON(json: any): RigLeaderboardQueryDto {
    return RigLeaderboardQueryDtoFromJSONTyped(json, false);
}

export function RigLeaderboardQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'formationIds': json['FormationIds'] == null ? undefined : json['FormationIds'],
        'operatorIds': json['OperatorIds'] == null ? undefined : json['OperatorIds'],
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'includeFullWellFacts': json['IncludeFullWellFacts'],
        'selectedWellIds': json['SelectedWellIds'] == null ? undefined : json['SelectedWellIds'],
        'selectedFilters': FiltersDtoFromJSON(json['SelectedFilters']),
        'useExceptions': json['UseExceptions'],
    };
}

export function RigLeaderboardQueryDtoToJSON(value?: RigLeaderboardQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'FormationIds': value['formationIds'],
        'OperatorIds': value['operatorIds'],
        'RigIds': value['rigIds'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'IncludeFullWellFacts': value['includeFullWellFacts'],
        'SelectedWellIds': value['selectedWellIds'],
        'SelectedFilters': FiltersDtoToJSON(value['selectedFilters']),
        'UseExceptions': value['useExceptions'],
    };
}

