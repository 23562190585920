/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigPowerUsageSliceDto } from './RigPowerUsageSliceDto';
import {
    RigPowerUsageSliceDtoFromJSON,
    RigPowerUsageSliceDtoFromJSONTyped,
    RigPowerUsageSliceDtoToJSON,
} from './RigPowerUsageSliceDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface RigPowerFactDto
 */
export interface RigPowerFactDto {
    /**
     * 
     * @type {number}
     * @memberof RigPowerFactDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof RigPowerFactDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof RigPowerFactDto
     */
    index: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerFactDto
     */
    availablePower: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerFactDto
     */
    operationClassId: number;
    /**
     * 
     * @type {Array<RigPowerUsageSliceDto>}
     * @memberof RigPowerFactDto
     */
    slices?: Array<RigPowerUsageSliceDto> | null;
    /**
     * 
     * @type {number}
     * @memberof RigPowerFactDto
     */
    readonly totalPower: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerFactDto
     */
    readonly totalGhgEmissions: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerFactDto
     */
    stateOfCharge?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigPowerFactDto
     */
    bessOutput?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigPowerFactDto
     */
    readonly rigDemand: number;
}

/**
 * Check if a given object implements the RigPowerFactDto interface.
 */
export function instanceOfRigPowerFactDto(value: object): value is RigPowerFactDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('at' in value) || value['at'] === undefined) return false;
    if (!('index' in value) || value['index'] === undefined) return false;
    if (!('availablePower' in value) || value['availablePower'] === undefined) return false;
    if (!('operationClassId' in value) || value['operationClassId'] === undefined) return false;
    if (!('totalPower' in value) || value['totalPower'] === undefined) return false;
    if (!('totalGhgEmissions' in value) || value['totalGhgEmissions'] === undefined) return false;
    if (!('rigDemand' in value) || value['rigDemand'] === undefined) return false;
    return true;
}

export function RigPowerFactDtoFromJSON(json: any): RigPowerFactDto {
    return RigPowerFactDtoFromJSONTyped(json, false);
}

export function RigPowerFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigPowerFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'at': DateDtoFromJSON(json['At']),
        'index': json['Index'],
        'availablePower': json['AvailablePower'],
        'operationClassId': json['OperationClassId'],
        'slices': json['Slices'] == null ? undefined : ((json['Slices'] as Array<any>).map(RigPowerUsageSliceDtoFromJSON)),
        'totalPower': json['TotalPower'],
        'totalGhgEmissions': json['TotalGhgEmissions'],
        'stateOfCharge': json['StateOfCharge'] == null ? undefined : json['StateOfCharge'],
        'bessOutput': json['BessOutput'] == null ? undefined : json['BessOutput'],
        'rigDemand': json['RigDemand'],
    };
}

export function RigPowerFactDtoToJSON(value?: Omit<RigPowerFactDto, 'TotalPower'|'TotalGhgEmissions'|'RigDemand'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'At': DateDtoToJSON(value['at']),
        'Index': value['index'],
        'AvailablePower': value['availablePower'],
        'OperationClassId': value['operationClassId'],
        'Slices': value['slices'] == null ? undefined : ((value['slices'] as Array<any>).map(RigPowerUsageSliceDtoToJSON)),
        'StateOfCharge': value['stateOfCharge'],
        'BessOutput': value['bessOutput'],
    };
}

