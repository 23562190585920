import { Portal, useTooltip } from "@visx/tooltip";
import type { UseTooltipParams } from "@visx/tooltip/lib/hooks/useTooltip";
import { VisualAidType } from "apis/oag";
import { noop } from "lodash";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import type { OnRefChangeType } from "react-resize-detector/build/types/types";

import { TooltipArrowDown, TooltipContainer, TooltipHighlightValue } from "./Tooltip";

export interface UseChartTooltipParams<T> {
  containerRef: OnRefChangeType<HTMLDivElement> | React.MutableRefObject<HTMLDivElement | undefined | null>;
  tvdTooltip?: boolean;
  rightTooltip?: boolean;
  currentPadding?: number;
  tbfTooltip?: boolean;
  paddingTop?: number;
  hideArrow?: boolean;
  renderContent: (params: UseTooltipParams<T>) => React.ReactNode;
}
enum TooltipPosition {
  BOTTOM,
  LEFT,
}

const TOOLTIP_BORDER_WIDTH = 2;

export const TooltipVisualAidInfo = ({
  selectedVisualAids,
  display,
  targetValue,
  averageValue,
  median,
}: {
  selectedVisualAids: VisualAidType[];
  display: (value: number) => string;
  targetValue?: number | null;
  averageValue?: number | null;
  median?: number | null;
}) => {
  return (
    <>
      {(selectedVisualAids ?? []).includes(VisualAidType.Targets) ? (
        <TooltipHighlightValue fontWeight="normal">
          Target: {targetValue && targetValue > 0 ? display(targetValue) : "-"}
        </TooltipHighlightValue>
      ) : null}
      {(selectedVisualAids ?? []).includes(VisualAidType.Average) ? (
        <TooltipHighlightValue fontWeight="normal">
          Average: {averageValue && averageValue > 0 ? display(averageValue) : "-"}
        </TooltipHighlightValue>
      ) : null}
      {(selectedVisualAids ?? []).includes(VisualAidType.Median) ? (
        <TooltipHighlightValue fontWeight="normal">
          Median: {median && median > 0 ? display(median) : "-"}
        </TooltipHighlightValue>
      ) : null}
    </>
  );
};

export function useChartTooltip<T>({
  containerRef,
  tvdTooltip,
  currentPadding = 0,
  paddingTop = 0,
  renderContent,
  rightTooltip,
  tbfTooltip,
  hideArrow,
}: UseChartTooltipParams<T>) {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [containerBounds, setContainerBounds] = useState<DOMRect | undefined>();
  const [tooltipDirection, setTooltipDirection] = useState<TooltipPosition>(TooltipPosition.LEFT);

  const tooltipParams = useTooltip<T>();
  const { tooltipData, tooltipLeft = 0, tooltipTop = 0, tooltipOpen, showTooltip, hideTooltip } = tooltipParams;

  const { width: tooltipWidth, height: tooltipHeight } = tooltipRef?.current?.getBoundingClientRect() ?? {
    width: 0,
    height: 0,
  };

  useLayoutEffect(() => {
    if (containerRef?.current && tooltipOpen) {
      setContainerBounds(containerRef.current.getBoundingClientRect());
    }

    const hideTooltipIfVisible = () => {
      if (tooltipOpen && tooltipRef.current) {
        hideTooltip();
      }
    };

    window.addEventListener("scroll", hideTooltipIfVisible, true);
    return () => {
      window.removeEventListener("scroll", hideTooltipIfVisible, true);
    };
  }, [containerRef, hideTooltip, tooltipOpen]);

  const halfTooltip = useMemo(() => {
    return tooltipWidth / 2 - TOOLTIP_BORDER_WIDTH;
  }, [tooltipWidth]);

  const top = useMemo(() => {
    const prevTop = Math.min(
      document.documentElement.clientHeight,
      Math.max(tooltipHeight, (containerBounds?.top ?? 0 + paddingTop) + tooltipTop - 15),
    );

    if (tvdTooltip) {
      const top = (containerBounds?.top ?? 0 + paddingTop) + tooltipTop - 10;
      if (tooltipDirection === TooltipPosition.LEFT) {
        // Tooltip to the left
        return top + tooltipHeight / 2 + 10;
      }
      return top + tooltipHeight + 25;
      // Tooltip down
    }
    if (rightTooltip) return prevTop + tooltipHeight;
    return prevTop;
  }, [containerBounds?.top, paddingTop, rightTooltip, tooltipDirection, tooltipHeight, tooltipTop, tvdTooltip]);

  const left = useMemo(() => {
    const leftPrev = Math.min(
      document.documentElement.clientWidth - tooltipWidth / 2 - TOOLTIP_BORDER_WIDTH,
      Math.max(halfTooltip, (containerBounds?.left ?? 0) + tooltipLeft),
    );

    if (tvdTooltip) {
      const left = (containerBounds?.left ?? 0) + tooltipLeft;
      if (left - tooltipWidth - currentPadding - 15 < 0) {
        // Tooltip to the left
        if (tooltipDirection !== TooltipPosition.BOTTOM) setTooltipDirection(TooltipPosition.BOTTOM);
        return Math.max(halfTooltip, left);
      }
      if (tooltipDirection !== TooltipPosition.LEFT) setTooltipDirection(TooltipPosition.LEFT);
      return Math.max(halfTooltip, left - tooltipWidth / 2 - 18);
      // Tooltip down
    }
    return leftPrev;
  }, [tooltipWidth, halfTooltip, containerBounds?.left, tooltipLeft, tvdTooltip, currentPadding, tooltipDirection]);

  const containerLeft = useMemo(() => {
    if (rightTooltip) return left + tooltipWidth / 2 + 15;

    if (left + tooltipWidth / 2 >= (containerBounds?.right || 0)) {
      return (containerBounds?.right || 0) - tooltipWidth / 2 + 5;
    }
    return left;
  }, [rightTooltip, containerBounds?.right, tooltipWidth, left]);

  const arrowLeft = useMemo(() => {
    if (rightTooltip) return left + 12.5;
    if (
      tooltipLeft + halfTooltip >= (containerBounds?.right || 0) - (containerBounds?.left || 0) ||
      tooltipLeft < tooltipWidth / 2
    ) {
      return tooltipLeft + (containerBounds?.left ?? 0);
    }
    return left;
  }, [rightTooltip, tooltipLeft, containerBounds?.left, containerBounds?.right, halfTooltip, tooltipWidth, left]);

  const tooltipElement =
    Number.isNaN(top) || Number.isNaN(tooltipHeight) ? null : (
      <Portal>
        {tooltipOpen ? (
          <>
            <TooltipContainer
              ref={tooltipRef}
              style={{
                pointerEvents: "none",
                top,
                width: (tooltipData as { isOverall: boolean })?.isOverall ? 250 : tbfTooltip ? 170 : "auto",
                opacity: tvdTooltip ? 0.85 : 1,
                left: tbfTooltip ? left : containerLeft,
              }}
            >
              {renderContent(tooltipParams)}
            </TooltipContainer>
            {rightTooltip && !tbfTooltip && !hideArrow ? (
              <TooltipArrowDown
                style={{
                  pointerEvents: "none",
                  top: top - (tooltipHeight * 3) / 4,
                  transform: "translate(-50%, -50%) rotate(90deg)",
                  left: arrowLeft,
                }}
              />
            ) : null}
            {tvdTooltip || rightTooltip || tbfTooltip || hideArrow ? null : (
              <TooltipArrowDown
                style={{
                  top,
                  transform: "",
                  left: arrowLeft,
                }}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </Portal>
    );

  return {
    tooltipData,
    showTooltip,
    hideTooltip: tooltipOpen ? hideTooltip : noop,
    tooltipElement,
    tooltipOpen,
  };
}
