import type { PhaseDto } from "apis/oag";
import {
  type ActualTimelineDto,
  type ActualTimelineEventDto,
  DashboardType,
  type PlanTimelineDto,
  type PlanTimelineEventDto,
  type TimelineSegmentDto,
} from "apis/oag";
import type { ExtendedEventType } from "reducers/types";

import { sortEventsTimeline } from "./common";

// TODO why the need for some many event types?
export type TypeEventMarkers = "Section" | "PhaseStart" | "PhaseEnd" | "TimelineEvent" | "EndOfTimeline" | "Event";

export interface ITimelineEventList extends TimelineSegmentDto, PlanTimelineEventDto, ActualTimelineEventDto {
  holeDepth: number;
  compressible: boolean;
  combinedEvents: any[];
  // TODO why the need for so many event types?
  eventType: ExtendedEventType;
  phaseId?: number;
  key: string;
  isStartOfPhase?: boolean;
}

export const END_TIMELINE_ID = Infinity;
export const toTimelineView = (
  isPlanTimeline: boolean,
  phaseData: PhaseDto[] | undefined,
  timelineEvents: ActualTimelineDto | PlanTimelineDto,
  endEventDuration: number,
  endEventDepth: number,
  options?: any,
) => {
  if (options?.timelineHidden || !timelineEvents) return [];

  const { sections, phases, events } = timelineEvents;
  const eventsList: Array<ITimelineEventList> = [];

  const isEmptyPlan =
    isPlanTimeline &&
    timelineEvents?.events?.length === 0 &&
    timelineEvents?.sections?.length === 0 &&
    timelineEvents?.phases?.length === 0;

  if (!isPlanTimeline)
    eventsList.push(
      // @ts-ignore
      ...(sections ?? []).map((section) => ({
        holeDepth: null,
        ...section,
        type: null,
        authorId: 0,
        isSetByDepth: true,
        planId: null,
        isFutureEvent: false,
        wellId: null,
        dashboardType: null,
        key: `Section_${section.id}_${Math.floor(Math.random() * 100)}`,
        isPlanEventProjection: false,
        compressible: false,
        eventType: "Section" as ExtendedEventType,
        sectionId: section.id,
        startCumulativeDuration: section.startCumulativeDuration,
        cumulativeDuration: section.startCumulativeDuration,
        combinedEvents: [],
      })),
    );

  if (phaseData) {
    let id = 0;
    let prevSectionId: number = -1;
    eventsList.push(
      // @ts-ignore
      ...(phases ?? []).map((phase) => {
        const currentSectionId = phaseData.find((x) => x.id === phase.id)?.sectionId ?? -1;
        let isStartOfPhase = false;
        if (prevSectionId === null || prevSectionId !== currentSectionId) {
          prevSectionId = currentSectionId;
          isStartOfPhase = true;
        }
        return {
          isFutureEvent: false,
          wellId: null,
          dashboardType: null,
          type: null,
          authorId: 0,
          isSetByDepth: true,
          planId: null,
          ...phase,
          id,
          key: `PhaseStart${phase.id}_${id++}`,
          isPlanEventProjection: false,
          compressible: true,
          eventType: "PhaseStart" as ExtendedEventType,
          phaseId: phase.id,
          isStartOfPhase,
          cumulativeDuration: phase.startCumulativeDuration,
          holeDepth: phase.startHoleDepth,
          combinedEvents: [],
        };
      }),
    );
  }
  eventsList.push(
    // @ts-ignore
    ...((events as Array<ActualTimelineEventDto>) ?? []).map((ev) => {
      return {
        planId: null,
        isFutureEvent: false,
        startCumulativeDuration: 0,
        startHoleDepth: 0,
        holeDepth: null,
        ...ev,
        key: `${ev.type}_${ev.id}`,
        eventType: ev.type as ExtendedEventType,
        compressible: true,
        combinedEvents: [],
      };
    }),
  );

  eventsList.sort(sortEventsTimeline);
  if (!isEmptyPlan) {
    eventsList.push({
      // @ts-ignore
      type: null,
      // @ts-ignore
      at: null,
      planId: -1,
      wellId: -1,
      dashboardType: DashboardType.Well,
      authorId: 0,
      isSetByDepth: true,
      isFutureEvent: false,
      startHoleDepth: 0,
      isPlanEventProjection: false,
      id: END_TIMELINE_ID,
      key: `EndOfTimeline_${END_TIMELINE_ID}`,
      compressible: false,
      eventType: "EndOfTimeline",
      startCumulativeDuration: endEventDuration, // the start of the last button on the Timeline is the end of the last phase
      endCumulativeDuration: endEventDuration, // the start of the last button on the Timeline is the end of the last phase
      cumulativeDuration: Math.floor(endEventDuration),
      holeDepth: endEventDepth,
      combinedEvents: [],
    });
  }

  return eventsList;
};
