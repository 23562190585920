/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataFeedRefreshInfoDto
 */
export interface DataFeedRefreshInfoDto {
    /**
     * 
     * @type {number}
     * @memberof DataFeedRefreshInfoDto
     */
    wellId: number;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresWellRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresTvdRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresPerStandRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresRigStateRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresByDepthRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresBatteryStateRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresPowerManagementRefresh: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataFeedRefreshInfoDto
     */
    requiresFuelManagementRefresh: boolean;
}

/**
 * Check if a given object implements the DataFeedRefreshInfoDto interface.
 */
export function instanceOfDataFeedRefreshInfoDto(value: object): value is DataFeedRefreshInfoDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('requiresWellRefresh' in value) || value['requiresWellRefresh'] === undefined) return false;
    if (!('requiresTvdRefresh' in value) || value['requiresTvdRefresh'] === undefined) return false;
    if (!('requiresPerStandRefresh' in value) || value['requiresPerStandRefresh'] === undefined) return false;
    if (!('requiresRigStateRefresh' in value) || value['requiresRigStateRefresh'] === undefined) return false;
    if (!('requiresByDepthRefresh' in value) || value['requiresByDepthRefresh'] === undefined) return false;
    if (!('requiresBatteryStateRefresh' in value) || value['requiresBatteryStateRefresh'] === undefined) return false;
    if (!('requiresPowerManagementRefresh' in value) || value['requiresPowerManagementRefresh'] === undefined) return false;
    if (!('requiresFuelManagementRefresh' in value) || value['requiresFuelManagementRefresh'] === undefined) return false;
    return true;
}

export function DataFeedRefreshInfoDtoFromJSON(json: any): DataFeedRefreshInfoDto {
    return DataFeedRefreshInfoDtoFromJSONTyped(json, false);
}

export function DataFeedRefreshInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataFeedRefreshInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'requiresWellRefresh': json['RequiresWellRefresh'],
        'requiresTvdRefresh': json['RequiresTvdRefresh'],
        'requiresPerStandRefresh': json['RequiresPerStandRefresh'],
        'requiresRigStateRefresh': json['RequiresRigStateRefresh'],
        'requiresByDepthRefresh': json['RequiresByDepthRefresh'],
        'requiresBatteryStateRefresh': json['RequiresBatteryStateRefresh'],
        'requiresPowerManagementRefresh': json['RequiresPowerManagementRefresh'],
        'requiresFuelManagementRefresh': json['RequiresFuelManagementRefresh'],
    };
}

export function DataFeedRefreshInfoDtoToJSON(value?: DataFeedRefreshInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'RequiresWellRefresh': value['requiresWellRefresh'],
        'RequiresTvdRefresh': value['requiresTvdRefresh'],
        'RequiresPerStandRefresh': value['requiresPerStandRefresh'],
        'RequiresRigStateRefresh': value['requiresRigStateRefresh'],
        'RequiresByDepthRefresh': value['requiresByDepthRefresh'],
        'RequiresBatteryStateRefresh': value['requiresBatteryStateRefresh'],
        'RequiresPowerManagementRefresh': value['requiresPowerManagementRefresh'],
        'RequiresFuelManagementRefresh': value['requiresFuelManagementRefresh'],
    };
}

