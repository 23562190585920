/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface NaturalGasStateFactDto
 */
export interface NaturalGasStateFactDto {
    /**
     * 
     * @type {number}
     * @memberof NaturalGasStateFactDto
     */
    wellId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof NaturalGasStateFactDto
     */
    at: DateDto;
    /**
     * 
     * @type {number}
     * @memberof NaturalGasStateFactDto
     */
    pressure: number;
    /**
     * 
     * @type {number}
     * @memberof NaturalGasStateFactDto
     */
    temperature: number;
}

/**
 * Check if a given object implements the NaturalGasStateFactDto interface.
 */
export function instanceOfNaturalGasStateFactDto(value: object): value is NaturalGasStateFactDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('at' in value) || value['at'] === undefined) return false;
    if (!('pressure' in value) || value['pressure'] === undefined) return false;
    if (!('temperature' in value) || value['temperature'] === undefined) return false;
    return true;
}

export function NaturalGasStateFactDtoFromJSON(json: any): NaturalGasStateFactDto {
    return NaturalGasStateFactDtoFromJSONTyped(json, false);
}

export function NaturalGasStateFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NaturalGasStateFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'at': DateDtoFromJSON(json['At']),
        'pressure': json['Pressure'],
        'temperature': json['Temperature'],
    };
}

export function NaturalGasStateFactDtoToJSON(value?: NaturalGasStateFactDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'At': DateDtoToJSON(value['at']),
        'Pressure': value['pressure'],
        'Temperature': value['temperature'],
    };
}

