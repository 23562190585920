import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { CountryDto } from "apis/oag";
import { CountriesApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";

import type { PDUniqueQueryKey } from "./../utils/queryNamespaces";
import { RequestUID } from "./../utils/queryNamespaces";

const countries = new CountriesApi(apiConfig);

const CountriesQueryKey: PDUniqueQueryKey = { uid: RequestUID.countries };

export async function fetchTracks(): Promise<CountryDto[]> {
  return await countries.apiCountriesGet();
}

export function useCountries(options?: UseQueryOptions<CountryDto[]>) {
  const queryOptions: UseQueryOptions<CountryDto[]> = {
    queryKey: [CountriesQueryKey],
    queryFn: () => fetchTracks(),
    ...options,
    refetchOnMount: false
  }
  return useSuspenseQuery<CountryDto[]>(queryOptions);
}
