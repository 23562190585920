import type { StackedKpiProps } from "components/Lenses/interfaces";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import React from "react";

import { StackedKpiComparison } from "./StackedKpiComparison";
import { StackedKpiSimple } from "./StackedKpiSimple";

export const StackedKPI = (props: StackedKpiProps) => {
  const [comparisonWells] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WIDGET, []);
  const isComparing = comparisonWells?.length > 0;

  return isComparing ? <StackedKpiComparison {...props} /> : <StackedKpiSimple {...props} />;
};

export default StackedKPI;
