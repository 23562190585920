import NoData from "components/Lenses/common/NoData";

import { GroupTitle } from "./components/GroupTitle";
import * as Styled from "./Styled";
import { SCORECARD_HEIGHT } from "./utils";

export function NoDataScoreCard({ title }: { title: string }) {
    return (
      <Styled.RigScoreCardContainer height={SCORECARD_HEIGHT}>
        <Styled.CardWrapper>
          <GroupTitle inner title={title} withTagSeparators tags={[]} />
          <NoData />
        </Styled.CardWrapper>
      </Styled.RigScoreCardContainer>
    );
  }
  