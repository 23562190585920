import { PDComponent } from "components/PDComponents";
import styled from "styled-components";
import colors from "utils/colors";
import { Button, Col, Row } from "utils/componentLibrary";

export const Container = styled.div`
  height: 700px;
  width: 540px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  box-shadow: 0px 0px 2px 0px ${({ theme }) => theme.themeStyle.colors.alt_primary_accent};

  h3 {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
`;

export const StyledRow = styled(Row)`
  padding: 16px;
`;

export const CustomCol = styled(Col)`
  display: flex;
  align-items: center;
  column-gap: 12px;
  &,
  h3 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

export const CloseButton = styled(Button)`
  border-radius: 50%;
  color: ${({ theme }) => theme.themeStyle.colors.faint_typography};
`;

export const StyledButton = styled(Button)`
  border: 0;
`;

export const UnreadText = styled.span<{ $isActive: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.themeStyle.colors.primary_typography : theme.themeStyle.colors.faint_typography};
`;

export const StyledTab = styled.div<{ $isActive: boolean }>`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px 16px 16px;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.themeStyle.colors.primary_typography : theme.themeStyle.colors.disabled_typography};

  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.themeStyle.colors.primary_button_bg : "transparent"};
    border-radius: 5px 5px 0 0;
  }
`;

export const TabBar = styled.div`
  display: flex;
  height: 40px;
  padding: 0 9px;
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const StyledGear = styled(PDComponent.SvgIcon).attrs({ name: "settings" })`
  font-size: 18px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const CountPill = styled.div`
  border-radius: 10px;
  height: 20px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_button_bg};
  min-width: 20px;
`;

export const StyledTabCounter = styled.div`
  background-color: ${({ theme }) => (theme.isDark ? colors.limo_scene : colors.widget_line)};
  padding: 4px 12px;
  font-size: 12px;
  color: ${({ theme }) => (theme.isDark ? theme.themeStyle.colors.primary_typography : colors.necron_compound)};
  border-radius: 10px;
`;

export const ListContainer = styled.div`
  height: calc(100% - 100px - 52px); //-hearder -footer
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
`;

export const Footer = styled.div`
  position: relative;
  height: 52px;
  width: 100%;
  padding: 0 24px;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const SeeAllText = styled.div`
  color: ${colors.well_color};
  font-weight: 500;
  font-size: 16px;
  display: flex;
  height: 100%;
  align-items: center;
  position: absolute;
  right: 12px;
`;

export const SelectContainer = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;
  height: 100%;
  justify-content: flex-start;
  padding-left: 20px;
  vertical-align: middle;
  font-weight: 500;

  &,
  label {
    cursor: pointer;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
  label {
    line-height: 2;
    font-weight: 500;
  }
`;

export const Controls = styled.div`
  position: absolute;
  height: 100%;
  display: inline-flex;
  gap: 26px;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  right: 24px;
  cursor: pointer;
`;
