import { StyledDropdownButton } from "atoms/common";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { EllipsisTextCSS } from "commonStyles";
import { VariableSizeList as List } from "react-window";
import styled from "styled-components";
import colors from "utils/colors";
import { Col, Space } from "utils/componentLibrary";

export const StyledButton = styled(Button)`
  width: 100%;
  justify-content: flex-start;
  display: flex;
  padding: 0;
  padding-left: 15px;
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg} !important;
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;

  &:active {
    background: ${({ theme }) => theme.themeStyle.colors.secondary_bg} !important;
    color: ${colors.gray} !important;
  }

  &:hover {
    filter: brightness(100%);
    &:not([disabled]) {
      filter: brightness(100%);
    }
  }
`;

export const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const SelectTitle = styled(Title)<{ $maxWidth: number; $isPlaceholder?: boolean }>`
  overflow: hidden;
  text-align: left;
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  text-overflow: ellipsis;
  color: ${({ $isPlaceholder, theme: { themeStyle } }) =>
    $isPlaceholder ? themeStyle.colors.faint_typography : themeStyle.colors.primary_typography} !important;
`;

export const DropdownButton = styled(StyledDropdownButton)<{ $showSelect: boolean }>`
  padding-top: ${({ $showSelect }) => ($showSelect ? 10 : 7)}px;
`;

export const SpaceForLayout = styled(Space)<{ $width?: number }>`
  border-radius: 4px;
  overflow: hidden;
  background: ${({ theme }) => theme.themeStyle.colors.secondary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  width: ${(props) => props.$width || 350}px;

  & > * {
    width: ${(props) => props.$width || 350}px;
  }
`;

export const CustomList = styled(List)`
  outline: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  h3 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  }
  .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  }
`;

export const HoverCol = styled(Col)`
  &:hover h3 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  }
  &:hover .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  }
  .pd-icon {
    color: ${colors.gray} !important;
  }
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.soft_accent};
  margin-bottom: 0px;
  width: 100%;
  padding: 12px;
  min-height: 0px;
  cursor: pointer;
`;

export const ItemCol = styled(Col)`
  ${EllipsisTextCSS}
`;
