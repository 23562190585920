import type { AllWellsKpiDto } from "apis/oag";
import { Loader } from "components/Loader";
import { useAllWellsKPI } from "hooks/useAllWellsKPI";
import type { FC } from "react";
import { useAppSelector } from "reducers/store";
import styled from "styled-components";
import { dimensionMap } from "utils/format";

import SingleKPI from "./SingleKPI";

const KPINames = {
  drillingProductivity: "Drilling Productivity",
  avgWellDuration: "Avg. Well Duration",
  avgTotalDrillingDistance: "Avg. Drilling Distance",
  avgWeightToWeight: "Avg. Weight to Weight",
  avgSlipsToSlips: "Avg. Slips to Slips",
  avgOnBottomRop: "Avg. On-Bottom ROP",
  avgTrippingRunningSpeed: "Avg. Tripping Running Speed",
};

const Grid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  & > div:first-child {
    grid-column: span 2;
  }
`;

const KPIsContainer: FC = () => {
  const allKpis = useAllWellsKPI();
  const currentUom = useAppSelector((state) => state.global.unit);
  return allKpis.isLoading || !allKpis.data ? (
    <div
      style={{
        height: 400,
        width: 390,
        display: "grid",
        placeItems: "center",
      }}
    >
      <Loader />
    </div>
  ) : (
    <Grid>
      {Object.keys(allKpis?.data || {}).map((kpiName) => {
        if (kpiName === "avgTotalDepth") return null;

        const getFactor = () => {
          if (kpiName === "avgWellDuration") {
            return { factor: 1 / (60 * 24), unit: "days" };
          } else if (kpiName === "drillingProductivity") {
            return { factor: 24 };
          }
        };
        const result = (() => {
          return dimensionMap[allKpis.data[kpiName as keyof AllWellsKpiDto].dimension]
            [currentUom](allKpis.data[kpiName as keyof AllWellsKpiDto].value, getFactor())
            .replaceAll(
              kpiName === "drillingProductivity" ? "hr" : "",
              kpiName === "drillingProductivity" ? "day" : "",
            );
        })();
        return <SingleKPI key={kpiName} title={KPINames[kpiName as keyof typeof KPINames]} value={result} />;
      })}
    </Grid>
  );
};

export default KPIsContainer;
