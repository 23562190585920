import {
  ChevronDownIcon as GeneratorChevronDownIcon,
  SelectedOption as SelectedGenerator,
} from "components/PDComponents/Dropdown/style";
import styled, { css } from "styled-components";
import { zIndexLayer } from "utils/zIndex";

import { ChevronDownIcon, SelectedOperator } from "./OperationTypeSelector/styles";

export const ChartContainer = styled.div`
  height: 100%;
  position: relative;
`;

export const ChartFlexContainer = styled.div<{ $height: number }>`
  height: ${({ $height }) => ($height < 3 ? "calc(100% - 40px)" : "100%")}; // todo why do we have 2 heights?
  height: calc(100% - 40px);
`;

export const PowerLoadLensContainer = styled.div<{ $width: number; $height: number }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  justify-content: end;

  ${ChevronDownIcon}, ${GeneratorChevronDownIcon} {
    transition: all 0.2s ease-in-out;
  }
  ${SelectedOperator} {
    font-size: 11px !important;
    position: relative;
    z-index: ${zIndexLayer.base};
  }

  ${SelectedGenerator} {
    padding-top: 5px;
    position: relative;
    z-index: ${zIndexLayer.base};
  }

  ${({ $width }) => [
    $width > 2 &&
      css`
        ${SelectedOperator} {
          font-size: 12px !important;
          position: absolute;
          top: 20px;
          left: 24px;
          transition: all 0.2s ease-in-out;
        }

        ${SelectedGenerator} {
          position: absolute;
          top: 15px;
          right: 20px;
          transition: all 0.2s ease-in-out;
        }
      `,
    $width > 3 &&
      css`
        ${SelectedOperator} {
          font-size: 14px !important;
          top: 18px;
        }
      `,
  ]}

  :hover {
    ${ChevronDownIcon}, ${GeneratorChevronDownIcon} {
      opacity: 1;
    }
  }
`;
