import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  ApiStatCardUserLensesIdFactsPutRequest,
  StatCardInfoDto,
  StatCardLensTemplateDto,
  StatCardUserLensDto,
} from "apis/oag";
import { StatCardDataType, StatCardUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useActiveGenerators } from "hooks/useActiveGenerators";
import { useFilterParams } from "hooks/useFilterParams";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useCallback } from "react";
import { useAppDispatch } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateDto } from "utils/helper";
import type { PDLensStatCardFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const statCardUserLensesApi = new StatCardUserLensesApi(apiConfig);

export function useStatCardFacts(
  lens: StatCardUserLensDto,
  template: StatCardLensTemplateDto,
  options?: UseQueryOptions<StatCardInfoDto>,
) {
  const getLensLastUpdateStateDispatcher = useCallback(() => {
    if (!template?.dataSource) return "";
    if (
      (
        [
          StatCardDataType.FuelConsumption,
          StatCardDataType.FuelFlow,
          StatCardDataType.GhgEmissions,
        ] as StatCardDataType[]
      ).includes(template.dataSource)
    ) {
      return "SET_FUEL_MANAGEMENT_REFRESH_DATE";
    }
    return "SET_POWER_MANAGEMENT_REFRESH_DATE";
  }, [template?.dataSource]);
  const wellDataID = useSelectedWell();
  const { data: activeGenerators } = useActiveGenerators(wellDataID);
  const filterParams = useFilterParams();
  const dispatch = useAppDispatch();

  const [zoomData] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WELL, initialZoomData, [
    URL_STATE_PARAM.ZOOM_WIDGET,
  ]);

  const requestParameters: ApiStatCardUserLensesIdFactsPutRequest = {
    id: lens.id,
    statCardQueryDto: {
      ...filterParams,
      selectedBoilers: lens.selectedBoilers || [],
      selectedGenerators: lens.selectedGenerators ? lens.selectedGenerators : activeGenerators,
    },
  };

  let requestParametersWithZoom: ApiStatCardUserLensesIdFactsPutRequest = requestParameters;
  if (zoomData.date_start && zoomData.type === IZoomType.DATE) {
    requestParametersWithZoom = {
      ...requestParameters,
      statCardQueryDto: requestParameters.statCardQueryDto
        ? {
            ...requestParameters.statCardQueryDto,
            from: dateToDateDto(zoomData.date_start) || defaultDateDto.from,
            to: dateToDateDto(zoomData.date_end) || defaultDateDto.to,
          }
        : undefined,
    };
  }

  const queryKey: PDLensStatCardFactsQueryKey<ApiStatCardUserLensesIdFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.statCardFacts,
    params: requestParametersWithZoom,
    lensId: lens.id,
    selectedFuel: lens?.selectedFuel,
    selectedGas: lens?.selectedGas,
  };

  const request = useQuery<StatCardInfoDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      statCardUserLensesApi.apiStatCardUserLensesIdFactsPut(requestParametersWithZoom, { signal }).then((data) => {
        const dispatcherName = getLensLastUpdateStateDispatcher();
        if (data?.lastUpdatedAt && dispatcherName) {
          dispatch({
            type: dispatcherName,
            payload: {
              [wellDataID]: data?.lastUpdatedAt,
            },
          });
        }

        return data;
      }),
    ...options,
  });

  return request;
}
