import type { DetailedLegendColumn } from "components/Lenses/common/ChartLegend/DetailedLegend";
import { LEGEND_EMPTY_COLUMN_KEY } from "components/Lenses/common/ChartLegend/utils";
import { Alignments } from "utils/enums";

export const LENS_TITLE = "Generator Stats";

export const TITLE_TOTAL_ENERGY = "Total kWh";
export const TITLE_RUN_HOURS = "Run Hrs";
export const TITLE_MAX_LOAD = "Max Load kW";

export const detailedLegendColumns: DetailedLegendColumn[] = [
  {
    title: "Gen #",
    widthPercent: 1,
    key: LEGEND_EMPTY_COLUMN_KEY,
    align: Alignments.Left,
    minWidth: 60,
  },
  {
    title: "Model",
    widthPercent: 50,
    key: "Model",
    align: Alignments.Left,
  },
  {
    title: "Rating",
    widthPercent: 1,
    minWidth: 70,
    key: "Rating",
    align: Alignments.Left,
  },
  {
    title: "Fuel",
    widthPercent: 1,
    key: "Fuel",
    minWidth: 40,
    align: Alignments.Center,
  },
];
