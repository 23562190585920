import type { UseSuspenseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ScorecardKpiCommentGroupDto } from "apis/oag";
import { ScorecardKpiCommentsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDWellCommentsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const commentsApi = new ScorecardKpiCommentsApi(apiConfig);

export const CommentsInvalidationKey = { uid: RequestUID.wellsComments, type: PDQueryType.WELLS_COMMENTS };

export function useScoreCardComments(
  selectedWellIds: number[],
  options?: Omit<UseSuspenseQueryOptions<ScorecardKpiCommentGroupDto[]>, "queryKey" | "queryFn">,
) {
  const RigCommentsQueryKey: PDWellCommentsQueryKey = {
    uid: CommentsInvalidationKey.uid,
    type: CommentsInvalidationKey.type as PDQueryType.WELLS_COMMENTS,
    params: selectedWellIds,
  };

  return useSuspenseQuery<ScorecardKpiCommentGroupDto[]>({
    queryKey: [RigCommentsQueryKey],
    queryFn: () => commentsApi.apiScorecardKpiCommentsPut({ wellIdListDto: { wellIds: selectedWellIds } }),
    ...options,
  });
}
