import { ResultDataState } from "apis/oag";
import GeneratorSelector from "components/Lenses/common/GeneratorSelector";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import type { PowerLoadEfficiencyProps } from "components/Lenses/interfaces";
import { useLensNameByTemplateId } from "hooks/useLensNameByTemplateId";
import { useLensSize } from "hooks/useLensSize";
import React, { useEffect, useState } from "react";

import PowerEfficiencyChart from "./Chart";
import { OperationClassSelector } from "./Chart/OperationTypeSelector";
import { ChartFlexContainer, PowerLoadLensContainer } from "./Chart/styles";
import { usePowerLoadEfficiencyFetcher, usePowerLoadEfficiencyLensUpdate } from "./fetcher";
import { LENS_TITLE } from "./utils";

export const PowerLoadEfficiencyKpi: React.FC<PowerLoadEfficiencyProps> = ({
  lens,
  isLocked = false,
  setLensDate,
  onLensUpdated,
}) => {
  const { data } = usePowerLoadEfficiencyFetcher(lens);

  const [operationMenuVisible, setOperationMenuVisible] = useState(false);
  const { handleGeneratorUpdate, handleOperationClassUpdate } = usePowerLoadEfficiencyLensUpdate(lens, onLensUpdated);
  const [width, height] = useLensSize(lens.id);
  const lensName = useLensNameByTemplateId(lens?.lensTemplateId);

  useEffect(() => {
    if (data?.lastUpdatedAt && setLensDate) {
      if (width > 2) setLensDate(data.lastUpdatedAt);
    }
  }, [data?.lastUpdatedAt, width, setLensDate]);
  return (
    <PowerLoadLensContainer $height={height} $width={width}>
      <OperationClassSelector
        selectedOperationClass={lens.selectedOperationClassId || 0}
        operationClassMenuVisible={operationMenuVisible}
        setOperationClassMenuVisible={setOperationMenuVisible}
        handleOperationClassUpdate={handleOperationClassUpdate}
        isLocked={isLocked}
        trackingText="Evergreen Dashboard - Update Operator Class"
        trackingData={{
          lensId: lens.id,
          lens: lensName,
        }}
      />
      <GeneratorSelector
        selectedGenerators={lens.selectedGenerators}
        isLocked={isLocked}
        handleGeneratorUpdate={handleGeneratorUpdate}
        trackingText="Evergreen Dashboard - Update Generator"
        trackingData={{
          lensId: lens.id,
          lens: lensName,
        }}
      />
      <ChartFlexContainer $height={height}>
        <LensLoadingContainer
          smallSizeLens
          key={lens.id}
          dataState={data?.dataState ?? ResultDataState.NoData}
          title={data?.dataState !== ResultDataState.Valid ? "" : LENS_TITLE}
          isDetailed={false}
          LoadedComponent={<PowerEfficiencyChart lens={lens} data={data.fact} />}
        />
      </ChartFlexContainer>
    </PowerLoadLensContainer>
  );
};
