/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { RigLeaderboardDto } from './RigLeaderboardDto';
import {
    RigLeaderboardDtoFromJSON,
    RigLeaderboardDtoFromJSONTyped,
    RigLeaderboardDtoToJSON,
} from './RigLeaderboardDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface RigLeaderboardFactListsDto
 */
export interface RigLeaderboardFactListsDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof RigLeaderboardFactListsDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {ResultDataState}
     * @memberof RigLeaderboardFactListsDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {Array<RigLeaderboardDto>}
     * @memberof RigLeaderboardFactListsDto
     */
    rigLeaderboard: Array<RigLeaderboardDto>;
}



/**
 * Check if a given object implements the RigLeaderboardFactListsDto interface.
 */
export function instanceOfRigLeaderboardFactListsDto(value: object): value is RigLeaderboardFactListsDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('rigLeaderboard' in value) || value['rigLeaderboard'] === undefined) return false;
    return true;
}

export function RigLeaderboardFactListsDtoFromJSON(json: any): RigLeaderboardFactListsDto {
    return RigLeaderboardFactListsDtoFromJSONTyped(json, false);
}

export function RigLeaderboardFactListsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardFactListsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'rigLeaderboard': ((json['RigLeaderboard'] as Array<any>).map(RigLeaderboardDtoFromJSON)),
    };
}

export function RigLeaderboardFactListsDtoToJSON(value?: RigLeaderboardFactListsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'DataState': ResultDataStateToJSON(value['dataState']),
        'RigLeaderboard': ((value['rigLeaderboard'] as Array<any>).map(RigLeaderboardDtoToJSON)),
    };
}

