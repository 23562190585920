/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateDto
 */
export interface DateDto {
    /**
     * 
     * @type {Date}
     * @memberof DateDto
     */
    utc: Date;
    /**
     * 
     * @type {number}
     * @memberof DateDto
     */
    minutesOffset: number;
    /**
     * 
     * @type {boolean}
     * @memberof DateDto
     */
    isDateOnly: boolean;
}

/**
 * Check if a given object implements the DateDto interface.
 */
export function instanceOfDateDto(value: object): value is DateDto {
    if (!('utc' in value) || value['utc'] === undefined) return false;
    if (!('minutesOffset' in value) || value['minutesOffset'] === undefined) return false;
    if (!('isDateOnly' in value) || value['isDateOnly'] === undefined) return false;
    return true;
}

export function DateDtoFromJSON(json: any): DateDto {
    return DateDtoFromJSONTyped(json, false);
}

export function DateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'utc': (new Date(json['Utc'])),
        'minutesOffset': json['MinutesOffset'],
        'isDateOnly': json['IsDateOnly'],
    };
}

export function DateDtoToJSON(value?: DateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Utc': ((value['utc']).toISOString()),
        'MinutesOffset': value['minutesOffset'],
        'IsDateOnly': value['isDateOnly'],
    };
}

