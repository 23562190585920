// eslint-disable-next-line local-rules/styles-location
import { css } from "styled-components";
import colors from "utils/colors";

export const ElevatedSurfaceBoxShadowCSS = css`
  /* PopOver-Shadow */
  box-shadow: 0px 8px 12px 0px ${colors.dark_soft_shadow};
`;

export const EllipsisTextCSS = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
