/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TorqueAndDragType } from './TorqueAndDragType';
import {
    TorqueAndDragTypeFromJSON,
    TorqueAndDragTypeFromJSONTyped,
    TorqueAndDragTypeToJSON,
} from './TorqueAndDragType';
import type { BroomstickCurveDto } from './BroomstickCurveDto';
import {
    BroomstickCurveDtoFromJSON,
    BroomstickCurveDtoFromJSONTyped,
    BroomstickCurveDtoToJSON,
} from './BroomstickCurveDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface BroomstickCurveSetDto
 */
export interface BroomstickCurveSetDto {
    /**
     * 
     * @type {string}
     * @memberof BroomstickCurveSetDto
     */
    modelName?: string | null;
    /**
     * 
     * @type {TorqueAndDragType}
     * @memberof BroomstickCurveSetDto
     */
    torqueAndDragType: TorqueAndDragType;
    /**
     * 
     * @type {Array<BroomstickCurveDto>}
     * @memberof BroomstickCurveSetDto
     */
    curves?: Array<BroomstickCurveDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof BroomstickCurveSetDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the BroomstickCurveSetDto interface.
 */
export function instanceOfBroomstickCurveSetDto(value: object): value is BroomstickCurveSetDto {
    if (!('torqueAndDragType' in value) || value['torqueAndDragType'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function BroomstickCurveSetDtoFromJSON(json: any): BroomstickCurveSetDto {
    return BroomstickCurveSetDtoFromJSONTyped(json, false);
}

export function BroomstickCurveSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BroomstickCurveSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'modelName': json['ModelName'] == null ? undefined : json['ModelName'],
        'torqueAndDragType': TorqueAndDragTypeFromJSON(json['TorqueAndDragType']),
        'curves': json['Curves'] == null ? undefined : ((json['Curves'] as Array<any>).map(BroomstickCurveDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function BroomstickCurveSetDtoToJSON(value?: BroomstickCurveSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ModelName': value['modelName'],
        'TorqueAndDragType': TorqueAndDragTypeToJSON(value['torqueAndDragType']),
        'Curves': value['curves'] == null ? undefined : ((value['curves'] as Array<any>).map(BroomstickCurveDtoToJSON)),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

