export enum CheckboxState {
  "Selected" = "Selected",
  "Partially" = "Partially",
  "None" = "None",
  "Disabled" = "Disabled",
}

export enum FilterType {
  Rig = "Rig",
  Operator = "Operator",
  Well = "Well",
  Formation = "Formation",
}

export enum PeriodType {
  All = "All Dates",
  Today = "Today",
  Days3 = "Last 3 days",
  Days7 = "Last 7 days",
  Days30 = "Last 30 days",
  Days90 = "Last 90 days",
  Months6 = "Last 6 Months",
  Months12 = "Last 12 Months",
  Custom = "Custom date range",
}

export enum SortDirections {
  Asc = "asc",
  Desc = "desc",
}

export enum Alignments {
  Left = "start",
  Right = "end",
  Center = "center",
}

export enum SortBy {
  Wells = "wells",
  Rigs = "rigs",
  Start = "start",
  End = "end",
  Days = "days",
  Hole = "hole",
  Bit = "bit",
}

export enum OperationClasses {
  DrillingFormation = 2,
  TrippingIn = 3,
  TrippingOut = 4,
  Casing = 5,
  LDDP = 6,
  PUDP = 7,
  MakeUpBHA = 8,
  BreakDownBHA = 9,
  DrillingCement = 10,
  FIT = 11,
  DrillingShoetrack = 12,
  CirculationAfterDrilling = 13,
  Logging = 14,
  SCRSPR = 15,
  ShortTrip = 16,
  WaitingonCement = 17,
  SlipandCut = 18,
  RigService = 19,
  Maintenance = 20,
  CementCasing = 21,
  BOPWork = 22,
  Surface = 23,
  Drilling = 24,
  WellConditioningTreatment = 25,
  CementingandBOPWork = 26,
  Liner = 27,
  TrippingwithLiner = 28,
  InsufficientData = 29,
  DataGap = 30,
  Unknown = 31,
}

export enum RigTechnology {
  Alpha = "Alpha Rig",
  NonAlpha = "Non-Alpha Rig",
}

export enum DashboardTypeSlugs {
  Well = "well",
  Evergreen = "evergreen",
  Rig = "rig",
}
