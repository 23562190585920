import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import styled from "styled-components";
import colors from "utils/colors";
import { Button, Col, Row, Switch } from "utils/componentLibrary";

import * as Styled from "./components/style";

export const StyledTab = styled.div<{ $isActive: boolean }>`
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.themeStyle.colors.white_gray : theme.themeStyle.colors.disabled_typography};
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px 16px 16px;
  position: relative;
  cursor: pointer;
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.themeStyle.colors.primary_button_bg : "transparent"};
    border-radius: 5px 5px 0 0;
  }
`;

export const NotificationHeader = styled.div`
  padding: 12px 24px;
  display: flex;
  gap: 48px;
  box-shadow: 0px 4px 6px 0px ${colors.buttons_clicked};
  height: 80px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
`;

export const CustomCol = styled(Col)`
  display: flex;
  align-items: center;
  column-gap: 12px;
  &,
  h3 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;
export const CustomRow = styled(Row)`
  width: 100%;
  gap: 24px;
`;

export const CloseButton = styled(Button)`
  border-radius: 50%;
  color: ${({ theme }) => theme.themeStyle.colors.faint_typography};
`;

export const StyledGear = styled(PDComponent.SvgIcon).attrs({ name: "settings" })`
  font-size: 24px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const UnreadText = styled.span<{ $isActive: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.themeStyle.colors.primary_typography : theme.themeStyle.colors.faint_typography};
  white-space: nowrap;
`;

export const CustomSwitch = styled(Switch)<{ $isChecked?: boolean }>`
  width: 44px !important;
  height: 24px !important;
  border-radius: 4px !important;
  padding: 2px 2px 2px 22px !important;
  border-radius: 4px !important;
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.alt_primary_bg};

  .ant-switch-handle:before {
    left: ${(props) => (props.$isChecked ? "calc(100% - 18px)" : "0px")}!important;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  place-items: center;
  padding-bottom: 16px;
  h3 {
    cursor: pointer;
    :hover {
      color: ${({ theme }) => theme.themeStyle.colors.primary_button_bg} !important;
    }
  }
`;

export const SelectAllContainer = styled.div`
  display: flex;
  cursor: pointer;
  place-content: center;
  place-items: center;
`;

export const NotificationPage = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_tertiary_bg};
  height: 100%;
`;

export const TitleBar = styled.div`
  padding: 15px 24px;
  height: 68px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};
  display: flex;
  place-items: center;
  justify-content: space-between;
  ${Title} {
    font-weight: 700 !important;
  }
`;

export const StyledTabCounter = styled.div`
  color: ${({ theme }) => theme.themeStyle.colors.white_gray};
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_button_accent};
  padding: 2px 8px;
  border-radius: 16px;
`;

export const StyledNotificationContainer = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_chart_bg};
  padding: 20px;
  margin: 4px 16px 16px 16px;
  border-radius: 8px;

  height: calc(100vh - 68px - 64px - 64px - 16px - 16px - 16px);
  overflow-y: scroll;
  ${Styled.NotificationRow} {
    min-height: 72px;
  }
  ${Styled.CheckboxContainer} {
    min-width: 5%;
    width: 5%;
  }
  ${Styled.NotificationInfoRow} {
    min-width: calc(87% - 24px);
    width: calc(87% - 24px);
    gap: 8px;
  }
  ${Styled.Controls} {
    min-width: 8%;
    width: 8%;
  }
  ${Styled.RigTitleContainer} {
    min-width: 10%;
    width: 10%;
  }
  ${Styled.TitleContainer} {
    min-width: 36%;
    width: 36%;
  }
  ${Styled.DescriptionContainer} {
    min-width: 40%;
    width: 40%;
  }
  ${Styled.Time} {
    min-width: 12%;
    width: 12%;
  }
`;

export const TabName = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-style: normal;
  font-weight: 700;
`;
