/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HoleSectionDto
 */
export interface HoleSectionDto {
    /**
     * 
     * @type {number}
     * @memberof HoleSectionDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof HoleSectionDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof HoleSectionDto
     */
    position: number;
}

/**
 * Check if a given object implements the HoleSectionDto interface.
 */
export function instanceOfHoleSectionDto(value: object): value is HoleSectionDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    return true;
}

export function HoleSectionDtoFromJSON(json: any): HoleSectionDto {
    return HoleSectionDtoFromJSONTyped(json, false);
}

export function HoleSectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HoleSectionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'position': json['Position'],
    };
}

export function HoleSectionDtoToJSON(value?: HoleSectionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'Position': value['position'],
    };
}

