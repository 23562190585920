import { Title } from "atoms/Typography";
import { ControllerBarHolder } from "components/DetailsTopBar/style";
import { PDComponent } from "components/PDComponents";
import { useWellDetails } from "hooks/useWellDetails";
import { Col, Row, Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

const NameBar = ({ wellId }: { wellId: number }) => {
  const { data: wellData } = useWellDetails(wellId);

  const { atomThemeVariant } = useCustomTheme();

  return (
    <ControllerBarHolder>
      <Row justify="space-between" align="middle" style={{ height: "64px" }}>
        <Col flex="1 auto" style={{ margin: "0 24px" }}>
          <Space direction="horizontal">
            <Title level={2} variant="dark" weight={500} style={{ fontSize: "28px" }}>
              <PDComponent.SvgIcon name="clarityLogoInverted" />
            </Title>
            <Title level={3} variant={atomThemeVariant} weight={500}>
              {wellData?.name}
            </Title>
          </Space>
        </Col>
      </Row>
    </ControllerBarHolder>
  );
};

export default NameBar;
