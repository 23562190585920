import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import type { IconName } from "icons/autogeneratedTypes";
import { Popover, Tooltip } from "utils/componentLibrary";


export const SelectorButton = ({
    onReset,
    options,
    placeholder,
    selectedValues,
    isSelected,
    onChange,
    open,
    onOpenChange,
    name,
    tooltipName,
}: {
    onReset: () => void;
    options: IOption<number>[];
    placeholder: string;
    selectedValues: number[];
    isSelected: boolean;
    onChange: (e: number[]) => void;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    name: IconName,
    tooltipName: string,
}) => {
    return (
        <Tooltip title={tooltipName}>
            <Popover
                content={
                    <PDComponent.ComboBoxMultiSelect
                        placeholder={placeholder}
                        options={options}
                        values={selectedValues}
                        onReset={onReset}
                        onChange={onChange}
                    />
                }
                trigger="click"
                placement="bottom"
                open={open}
                onOpenChange={onOpenChange}
                destroyTooltipOnHide
            >
                <Button
                    size="large"
                    icon={<PDComponent.SvgIcon name={name} />}
                    type={
                        isSelected ? "primary" : "default"
                    }
                    ghost={isSelected}
                />
            </Popover>
        </Tooltip>
    )
}
