import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Button } from "atoms/Form";
import FiltersPopup from "components/General/FiltersPopup";
import SelectFilter from "components/General/SelectFilter";
import { PDComponent } from "components/PDComponents";
import { useEffect, useMemo, useState } from "react";
import type { IFilterOptions } from "reducers/rigsCommonReducer";
import { Popover, Tooltip } from "utils/componentLibrary";

import type { FilterTypes } from "./useFiltersCategories";
import { useFiltersCategories } from "./useFiltersCategories";

export const AllFilters = ({
  filters,
  setFilters,
  scrollElement,
  disabledFilters,
}: {
  filters: IFilterOptions | null;
  setFilters: (filters: IFilterOptions | null) => void;
  scrollElement?: HTMLElement | Document | null;
  disabledFilters?: (keyof IFilterOptions)[];
}) => {
  const [filterVisible, setFilterPopupVisible] = useState(false);
  const { initialFilters, localFilters, setLocalFilters, allFilters, toggleFilter } = useFiltersCategories(
    filters,
    setFilters,
  );

  const isAllSelected = useMemo(() => {
    return localFilters
      ? Object.keys(localFilters ?? []).every(
          (key) => localFilters[key as FilterTypes].length === allFilters[key as FilterTypes].options.length,
        )
      : false;
  }, [allFilters, localFilters]);

  useEffect(() => {
    const onScroll = () => {
      setFilterPopupVisible(false);
    };

    scrollElement?.addEventListener("scroll", onScroll);
    return () => {
      scrollElement?.removeEventListener("scroll", onScroll);
    };
  }, [scrollElement]);

  const toggleAll = (filterType: FilterTypes) => (e: CheckboxChangeEvent) => {
    setLocalFilters((prev) => {
      const newFilters: IFilterOptions = prev
        ? { ...prev }
        : {
            type: [],
            horsePower: [],
            country: [],
            operatingCenter: [],
            technology: [],
            holeSection: [],
            directionalInterval: [],
            holeSize: [],
            wellStatus: [],
            phases: [],
          };

      if (e.target.checked) {
        newFilters[filterType] = allFilters[filterType].options;
      } else {
        newFilters[filterType] = [];
      }
      return newFilters;
    });
  };

  const deselectAll = () => {
    if (isAllSelected) {
      setLocalFilters({
        type: [],
        horsePower: [],
        country: [],
        operatingCenter: [],
        technology: [],
        holeSection: [],
        directionalInterval: [],
        holeSize: [],
        wellStatus: [],
        phases: [],
      });
    } else {
      setLocalFilters(initialFilters);
    }
  };

  const onCancel = () => {
    setFilterPopupVisible(false);
  };

  const applyCustomization = () => {
    setFilterPopupVisible(false);
    setFilters(localFilters);
  };

  const visibleFilterOptions = useMemo(
    () => (Object.keys(allFilters) as FilterTypes[]).filter((filterType) => !disabledFilters?.includes(filterType)),
    [allFilters, disabledFilters],
  );

  return (
    <Tooltip title="Filter">
      <Popover
        content={
          <FiltersPopup
            isAllSelected={isAllSelected}
            trackingProps={{ page: "All Rigs" }}
            deselectAll={deselectAll}
            onCancel={onCancel}
            applyCustomization={applyCustomization}
            numRows={Math.ceil(visibleFilterOptions.length / 4)}
            numCols={4}
          >
            {visibleFilterOptions.map((filterType) => (
              <SelectFilter
                key={filterType}
                label={allFilters[filterType].categoryLabel}
                filterValues={localFilters ? localFilters[filterType].map((filterOption) => filterOption.id || "") : []}
                options={allFilters[filterType].options.map((option) => ({
                  label: (option.value || "").toString(),
                  value: option.id || "",
                }))}
                onChangeGroup={(e) => {
                  toggleAll(filterType)(e);
                }}
                onChange={(option) => {
                  toggleFilter(filterType, option as string);
                }}
              />
            ))}
          </FiltersPopup>
        }
        trigger={["click", "scroll"]}
        placement="bottomRight"
        open={filterVisible}
        onOpenChange={(e) => setFilterPopupVisible(e)}
        destroyTooltipOnHide
      >
        <Button
          size="large"
          icon={<PDComponent.SvgIcon name="filter" />}
          onClick={() => setFilterPopupVisible((fl: boolean) => !fl)}
          $engaged={filterVisible}
          type={isAllSelected ? "default" : "primary"}
          ghost={!isAllSelected}
        />
      </Popover>
    </Tooltip>
  );
};
