/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperatorClaimDto } from './OperatorClaimDto';
import {
    OperatorClaimDtoFromJSON,
    OperatorClaimDtoFromJSONTyped,
    OperatorClaimDtoToJSON,
} from './OperatorClaimDto';

/**
 * 
 * @export
 * @interface OperatorClaimsQueryDto
 */
export interface OperatorClaimsQueryDto {
    /**
     * 
     * @type {Array<OperatorClaimDto>}
     * @memberof OperatorClaimsQueryDto
     */
    claims: Array<OperatorClaimDto>;
}

/**
 * Check if a given object implements the OperatorClaimsQueryDto interface.
 */
export function instanceOfOperatorClaimsQueryDto(value: object): value is OperatorClaimsQueryDto {
    if (!('claims' in value) || value['claims'] === undefined) return false;
    return true;
}

export function OperatorClaimsQueryDtoFromJSON(json: any): OperatorClaimsQueryDto {
    return OperatorClaimsQueryDtoFromJSONTyped(json, false);
}

export function OperatorClaimsQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorClaimsQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'claims': ((json['Claims'] as Array<any>).map(OperatorClaimDtoFromJSON)),
    };
}

export function OperatorClaimsQueryDtoToJSON(value?: OperatorClaimsQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Claims': ((value['claims'] as Array<any>).map(OperatorClaimDtoToJSON)),
    };
}

