/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseFuelQueryDto,
  NaturalGasStateResultDto,
  NaturalGasStateUserLensDto,
} from '../models/index';
import {
    BaseFuelQueryDtoFromJSON,
    BaseFuelQueryDtoToJSON,
    NaturalGasStateResultDtoFromJSON,
    NaturalGasStateResultDtoToJSON,
    NaturalGasStateUserLensDtoFromJSON,
    NaturalGasStateUserLensDtoToJSON,
} from '../models/index';

export interface ApiNaturalGasStateUserLensesIdFactsPutRequest {
    id: number;
    baseFuelQueryDto?: BaseFuelQueryDto;
}

export interface ApiNaturalGasStateUserLensesIdPutRequest {
    id: number;
    naturalGasStateUserLensDto?: NaturalGasStateUserLensDto;
}

export interface ApiNaturalGasStateUserLensesPostRequest {
    naturalGasStateUserLensDto?: NaturalGasStateUserLensDto;
}

/**
 * 
 */
export class NaturalGasStateUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiNaturalGasStateUserLensesIdFactsPutRaw(requestParameters: ApiNaturalGasStateUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NaturalGasStateResultDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiNaturalGasStateUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/NaturalGasStateUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFuelQueryDtoToJSON(requestParameters['baseFuelQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NaturalGasStateResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiNaturalGasStateUserLensesIdFactsPut(requestParameters: ApiNaturalGasStateUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NaturalGasStateResultDto> {
        const response = await this.apiNaturalGasStateUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNaturalGasStateUserLensesIdPutRaw(requestParameters: ApiNaturalGasStateUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NaturalGasStateUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiNaturalGasStateUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/NaturalGasStateUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NaturalGasStateUserLensDtoToJSON(requestParameters['naturalGasStateUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NaturalGasStateUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiNaturalGasStateUserLensesIdPut(requestParameters: ApiNaturalGasStateUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NaturalGasStateUserLensDto> {
        const response = await this.apiNaturalGasStateUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiNaturalGasStateUserLensesPostRaw(requestParameters: ApiNaturalGasStateUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NaturalGasStateUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/NaturalGasStateUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NaturalGasStateUserLensDtoToJSON(requestParameters['naturalGasStateUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NaturalGasStateUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiNaturalGasStateUserLensesPost(requestParameters: ApiNaturalGasStateUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NaturalGasStateUserLensDto> {
        const response = await this.apiNaturalGasStateUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
