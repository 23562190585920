import type { TvdSectionDto } from "apis/oag";
import { LEFT_PADDING_SECTIONS, RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import type { ScaleLinear } from "d3-scale";
import { useHoleSections } from "hooks/useHoleSections";
import { useAppDispatch, useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { useCustomTheme } from "utils/useTheme";

const Section = ({
  section,
  yScale,
  chartWidth,
}: {
  section: TvdSectionDto;
  yScale: ScaleLinear<number, number, never>;
  chartWidth: number;
}) => {
  const { data: hs, isLoading } = useHoleSections();
  const dispatch = useAppDispatch();
  const name = (isLoading ? [] : hs || []).find((s) => section.sectionId === s.id)?.name;
  // Feeling bad about hardcoded the values in here but the lack of an equally spaced fount makes it difficult to create a formula for this width
  const widthByName: { [key: string]: number } = {
    Surface: 63,
    Unknown: 71,
    Intermediate: 90,
    Production: 80,
  };
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  const width = widthByName[name || "Unknown"];
  const height = 8;
  const arrow = 7;
  const hoveredEvent = useAppSelector((state) => state.state.hoveredEvent);
  const isHovered =
    hoveredEvent?.type === "Section" &&
    hoveredEvent?.id === section.sectionId &&
    hoveredEvent.holeDepth === section.startDepth;
  const y = yScale(section.startDepth);

  return (
    <svg y={Number.isNaN(y) ? 0 : y}>
      <line
        x1={width}
        y1={height}
        x2={(chartWidth ?? 0) + LEFT_PADDING_SECTIONS - RIGHT_AXIS_PADDING}
        y2={height}
        stroke={colors.bottom_line_widget}
        strokeWidth={isHovered ? 2 : 1}
      />
      <svg
        onMouseEnter={() => {
          dispatch({
            type: "SET_EVENT_HOVERED",
            payload: {
              id: section.sectionId,
              type: "Section",
              isPlanEventProjection: false,
              holeDepth: section.startDepth,
            },
          });
        }}
        onMouseLeave={() => {
          dispatch({
            type: "SET_EVENT_HOVERED",
            payload: null,
          });
        }}
        style={{
          filter: `drop-shadow(0 1px 2px ${colors.buttons_clicked}`,
        }}
      >
        <path
          d={`M 0 0 L ${width} 0 L ${width + arrow} ${height} L ${width} ${height * 2} L 0 ${height * 2} L 0 0`}
          fill={isHovered ? themeColors.primary_button_bg : themeColors.quaterniary_bg}
        />
        <text
          fill={isHovered ? colors.pale_grey : themeColors.primary_typography}
          fontSize={12}
          letterSpacing={-0.2}
          fontWeight="500"
          pointerEvents="none"
          textAnchor="right"
          x={5}
          y={12}
          style={{
            userSelect: "none",
          }}
        >
          {name ?? ""}
        </text>
      </svg>
    </svg>
  );
};
export const Sections = ({
  sections,
  yScale,
  chartWidth,
}: {
  sections: TvdSectionDto[];
  yScale: ScaleLinear<number, number, never>;
  chartWidth: number;
}) => {
  return (
    <>
      {sections.map((section, idx) => (
        <Section
          section={section}
          yScale={yScale}
          chartWidth={chartWidth}
          key={`${section.sectionId}-${section.startDepth}-${idx}`}
        />
      ))}
    </>
  );
};
