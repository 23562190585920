/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TrackFactType = {
    RateOfPenetration: 'RateOfPenetration',
    WeightOnBit: 'WeightOnBit',
    RevolutionsPerSecond: 'RevolutionsPerSecond',
    DifferentialPressure: 'DifferentialPressure',
    Torque: 'Torque',
    FlowIn: 'FlowIn',
    StandpipePressure: 'StandpipePressure',
    BlockHeight: 'BlockHeight',
    PumpOutput: 'PumpOutput',
    Sssi: 'Sssi',
    CumulativeSssi: 'CumulativeSssi',
    ZTorqueStatus: 'ZTorqueStatus'
} as const;
export type TrackFactType = typeof TrackFactType[keyof typeof TrackFactType];


export function instanceOfTrackFactType(value: any): boolean {
    for (const key in TrackFactType) {
        if (Object.prototype.hasOwnProperty.call(TrackFactType, key)) {
            if (TrackFactType[key as keyof typeof TrackFactType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TrackFactTypeFromJSON(json: any): TrackFactType {
    return TrackFactTypeFromJSONTyped(json, false);
}

export function TrackFactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackFactType {
    return json as TrackFactType;
}

export function TrackFactTypeToJSON(value?: TrackFactType | null): any {
    return value as any;
}

