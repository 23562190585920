/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SingleStandKpiSummaryDto
 */
export interface SingleStandKpiSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiSummaryDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiSummaryDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiSummaryDto
     */
    median: number;
}

/**
 * Check if a given object implements the SingleStandKpiSummaryDto interface.
 */
export function instanceOfSingleStandKpiSummaryDto(value: object): value is SingleStandKpiSummaryDto {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('average' in value) || value['average'] === undefined) return false;
    if (!('median' in value) || value['median'] === undefined) return false;
    return true;
}

export function SingleStandKpiSummaryDtoFromJSON(json: any): SingleStandKpiSummaryDto {
    return SingleStandKpiSummaryDtoFromJSONTyped(json, false);
}

export function SingleStandKpiSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleStandKpiSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['Count'],
        'average': json['Average'],
        'median': json['Median'],
    };
}

export function SingleStandKpiSummaryDtoToJSON(value?: SingleStandKpiSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Count': value['count'],
        'Average': value['average'],
        'Median': value['median'],
    };
}

