import type { ParameterByDepthUserLensTrackItemDto } from "apis/oag";
import { useTracks } from "hooks/useTracks";
import { difference, indexOf } from "lodash";
import { useMemo } from "react";

export const useAvailableTrackIds = (selectedTrackItems: ParameterByDepthUserLensTrackItemDto[]) => {
  const { data: tracks } = useTracks();

  const acceptableTracks = useMemo(() => {
    const acceptableTrackNames = ["RPM", "WOB", "ROP", "Diff-Pressure", "FlowIn", "SPP", "Torque"];

    return tracks
      ? tracks.list
          ?.filter((track) => acceptableTrackNames.includes(track?.name || ""))
          .sort((a, b) => indexOf(acceptableTrackNames, a.name) - indexOf(acceptableTrackNames, b.name))
          .map((track) => track.id)
      : [];
  }, [tracks]);

  return difference(
    acceptableTracks ?? [],
    selectedTrackItems.map((item) => item.trackId ?? -1),
  );
};
