/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScorecardKpiCommentInfoDto } from './ScorecardKpiCommentInfoDto';
import {
    ScorecardKpiCommentInfoDtoFromJSON,
    ScorecardKpiCommentInfoDtoFromJSONTyped,
    ScorecardKpiCommentInfoDtoToJSON,
} from './ScorecardKpiCommentInfoDto';
import type { RichStandKpiCommentInfoDto } from './RichStandKpiCommentInfoDto';
import {
    RichStandKpiCommentInfoDtoFromJSON,
    RichStandKpiCommentInfoDtoFromJSONTyped,
    RichStandKpiCommentInfoDtoToJSON,
} from './RichStandKpiCommentInfoDto';

/**
 * 
 * @export
 * @interface ScorecardKpiCommentGroupDto
 */
export interface ScorecardKpiCommentGroupDto {
    /**
     * 
     * @type {number}
     * @memberof ScorecardKpiCommentGroupDto
     */
    scorecardLensId: number;
    /**
     * 
     * @type {Array<ScorecardKpiCommentInfoDto>}
     * @memberof ScorecardKpiCommentGroupDto
     */
    comments: Array<ScorecardKpiCommentInfoDto>;
    /**
     * 
     * @type {Array<RichStandKpiCommentInfoDto>}
     * @memberof ScorecardKpiCommentGroupDto
     */
    standComments: Array<RichStandKpiCommentInfoDto>;
}

/**
 * Check if a given object implements the ScorecardKpiCommentGroupDto interface.
 */
export function instanceOfScorecardKpiCommentGroupDto(value: object): value is ScorecardKpiCommentGroupDto {
    if (!('scorecardLensId' in value) || value['scorecardLensId'] === undefined) return false;
    if (!('comments' in value) || value['comments'] === undefined) return false;
    if (!('standComments' in value) || value['standComments'] === undefined) return false;
    return true;
}

export function ScorecardKpiCommentGroupDtoFromJSON(json: any): ScorecardKpiCommentGroupDto {
    return ScorecardKpiCommentGroupDtoFromJSONTyped(json, false);
}

export function ScorecardKpiCommentGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScorecardKpiCommentGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'scorecardLensId': json['ScorecardLensId'],
        'comments': ((json['Comments'] as Array<any>).map(ScorecardKpiCommentInfoDtoFromJSON)),
        'standComments': ((json['StandComments'] as Array<any>).map(RichStandKpiCommentInfoDtoFromJSON)),
    };
}

export function ScorecardKpiCommentGroupDtoToJSON(value?: ScorecardKpiCommentGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ScorecardLensId': value['scorecardLensId'],
        'Comments': ((value['comments'] as Array<any>).map(ScorecardKpiCommentInfoDtoToJSON)),
        'StandComments': ((value['standComments'] as Array<any>).map(RichStandKpiCommentInfoDtoToJSON)),
    };
}

