import * as CommonStyles from "pages/RigScoreCard/RightPane/StyledComponents";
import styled from "styled-components";

export const ExceptionRow = styled(CommonStyles.RowPadding)`
  padding: 12px 24px;
  display: grid;
  grid-template-columns: 1fr 16px;
  gap: 8px;
  align-items: flex-end;
`;

export const WellTitleException = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 8px;
`;

export const WellTitleScores = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const FaintText = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const Info = styled.div`
  min-width: 0;
`;

export const ExceptionMetricsRow = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  display: flex;
  justify-content: space-between;
  margin: 4px;
  gap: 4px;
`;

export const ExceptionMetricsRowBigFont = styled(ExceptionMetricsRow)`
  font-size: 14px;
`;

export const OverflowEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Bolder = styled.div`
  font-weight: 500;
`;

export const JobsList = styled.div`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  font-size: 12px;
  font-weight: 400;
`;
