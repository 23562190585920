import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import type { ScaleLinear } from "d3-scale";
import React, { useLayoutEffect, useRef, useState } from "react";
import type { OnRefChangeType } from "react-resize-detector/build/types/types";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

export interface ScrollbarRange {
  startX: number;
  endX: number;
}

interface ScrollbarProps {
  width: number;
  height: number;
  scale: ScaleLinear<number, number>;
  onScroll: (range: ScrollbarRange) => void;
  valueSpan?: number; // how much to span (in real values, not pixels)
  disabled?: boolean;
  wheelCaptureContainer?: OnRefChangeType<HTMLDivElement> | React.MutableRefObject<HTMLDivElement>;
}

const MIN_SCROLL_SIZE = 20;

export function Scrollbar({
  width,
  height,
  scale,
  onScroll,
  valueSpan,
  disabled,
  wheelCaptureContainer,
}: ScrollbarProps) {
  const [{ isDragging, x }, setDragState] = useState({ startX: 0, startClientX: 0, x: 0, isDragging: false });
  const trackRef = useRef<SVGRectElement>(null);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const maxValue = scale.domain()[1];

  let trackWidth = 0;

  if (valueSpan) {
    trackWidth = scale(maxValue) - scale(maxValue - valueSpan);
  } else {
    trackWidth = scale(maxValue) - scale((maxValue / 4) * 3);
  }

  if (trackWidth > width) {
    trackWidth = width;
  }

  useLayoutEffect(() => {
    setDragState((prev) => ({ ...prev, x: width - trackWidth }));
  }, [trackWidth, width]);

  // We do this every time the mouse is up, no need to check for dragging
  useLayoutEffect(() => {
    if (disabled) {
      return () => {};
    }

    const mouseUpHandler = () => {
      setDragState((prev) => ({ ...prev, startX: 0, startClientX: 0, isDragging: false }));
    };
    window.addEventListener("mouseup", mouseUpHandler);

    return () => {
      window.removeEventListener("mouseup", mouseUpHandler);
    };
  }, [disabled]);

  useLayoutEffect(() => {
    const mouseMoveHandler = (e: MouseEvent) => {
      if (isDragging && trackRef.current) {
        const { clientX } = e;
        setDragState((prev) => {
          const dx = clientX - prev.startClientX;
          const newX = Math.min(Math.max(0, prev.startX + dx), width - trackWidth);

          return { ...prev, x: newX };
        });
      }
    };

    window.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      window.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, [isDragging, trackWidth, width]);

  useLayoutEffect(() => {
    document.body.style.cursor = isDragging ? "grabbing" : "default";
  }, [isDragging]);

  useLayoutEffect(() => {
    onScroll({ startX: scale.invert(x), endX: scale.invert(x + trackWidth) });
  }, [onScroll, scale, trackWidth, x]);

  useLayoutEffect(() => {
    const element = wheelCaptureContainer?.current;
    if (element) {
      const wheelEventHandler = (e: WheelEvent) => {
        const { deltaX } = e;

        if (deltaX === 0) {
          return;
        }

        setDragState((prev) => {
          const newX = Math.min(Math.max(0, prev.x + deltaX), width - trackWidth);
          return { ...prev, x: newX };
        });

        e.preventDefault();
      };

      element.addEventListener("wheel", wheelEventHandler);

      return () => {
        element?.removeEventListener("wheel", wheelEventHandler);
      };
    }
  }, [trackWidth, wheelCaptureContainer, width]);

  return (
    <Group
      style={{
        zIndex: zIndexLayer.mars,
      }}
    >
      <Bar width={width > 0 ? width : 0} height={height} rx={4} fill={themeColors.primary_bg} />
      {!disabled && (
        <Bar
          innerRef={trackRef}
          width={Number.isFinite(trackWidth) && trackWidth > MIN_SCROLL_SIZE ? trackWidth : MIN_SCROLL_SIZE}
          height={height}
          rx={4}
          x={Number.isFinite(x) ? x : 0}
          fill={themeColors.primary_scroll_bg}
          style={{ userSelect: "none" }}
          cursor={isDragging ? "grabbing" : "grab"}
          onMouseDown={(e) => {
            const { clientX: startClientX } = e;
            setDragState((prev) => ({
              ...prev,
              startX: x,
              startClientX,
              isDragging: true,
            }));
          }}
        />
      )}
    </Group>
  );
}
