import { Title } from "atoms/Typography";
import styled from "styled-components";
import { Row } from "utils/componentLibrary";

export const HeaderContainerRow = styled(Row).attrs({
  align: "middle",
  justify: "space-between",
})`
  padding: 0;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_chart_bg};
`;
export const StyledTitle = styled(Title).attrs({
  level: 3,
})`
  white-space: nowrap;
`;
