import { useIsFetching } from "@tanstack/react-query";
import { Button } from "atoms/Form";
import OffsetWellSelector from "components/OffsetWellSelector";
import { PDComponent } from "components/PDComponents";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { useEffectExceptOnMount } from "hooks/useEffectExceptOnMount";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useWellDetails } from "hooks/useWellDetails";
import { useWellShortInfo } from "hooks/useWellShortInfo";
import { isEqual } from "lodash";
import qs from "qs";
import { useEffect, useMemo, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { initialFilters } from "reducers/types";
import { Track, useMixpanel } from "services/Mixpanel";
import { Col, Row, Space, Tooltip } from "utils/componentLibrary";
import { getQuery, highPrivilegeRoles } from "utils/helper";
import { PDQueryType } from "utils/queryNamespaces";

import FiltersButton from "./atoms/Filters/FiltersButton";
import { RealtimeIndicator } from "./atoms/RealtimeIndicator";
import { WellSelector } from "./atoms/WellSelector";
import { ZoomButton } from "./atoms/Zoom/ZoomButton";

type TZoomData = typeof initialZoomData;

const ControlHeader = ({ setZoomOpen, zoomData }: { setZoomOpen: React.Dispatch<boolean>; zoomData: TZoomData }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isFetchingZoom = useIsFetching({ queryKey: [{ type: PDQueryType.WELL_OVERVIEW_ZOOM }] });
  const isFetchingWellOverview = useIsFetching({ queryKey: [{ type: PDQueryType.WELL_OVERVIEW }], exact: false });

  const selectedTabQuery = useMemo(
    () => parseInt(qs.parse(location.search, { ignoreQueryPrefix: true }).selectedTab as string, 10),
    [location.search],
  );

  const selectedWell = useSelectedWell();
  const userInfo = useAppSelector((state) => state.global.userInfo);
  const isInDemoMode = userInfo?.isInDemoMode ?? false;

  const { isLoading: isLoadingWellData } = useWellShortInfo();
  const { data: selectedWellInfo, isLoading: isLoadingSelectedWellInfo } = useWellDetails(selectedWell);
  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();

  const zoomActiveState = !isEqual(initialZoomData, zoomData);

  useEffectExceptOnMount(() => {
    Track.interact("Well Dashboard - Zoom", {
      Enabled: zoomActiveState,
    });
  }, [zoomActiveState]);

  useEffect(() => {
    if (isLoadingSelectedWellInfo) return;
    if (!selectedWellInfo?.name) return;
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("Well Dashboard", {
        "Well Status": selectedWellInfo.status,
        "Well Name": selectedWellInfo.name,
        "Well Snowflake ID": selectedWellInfo.externalId,
        Latitude: selectedWellInfo.latitude,
        Longitude: selectedWellInfo.longitude,
      });
    }
  }, [viewPage, selectedWellInfo, isLoadingSelectedWellInfo]);
  useEffect(() => {
    if (isLoadingSelectedWellInfo) return;
    if (!selectedWellInfo) return;
    dispatch({
      type: "SET_WELL_DETAILS",
      payload: selectedWellInfo,
    });
  }, [dispatch, isLoadingSelectedWellInfo, selectedWellInfo]);

  const onWellChange = (well: number) => {
    dispatch({
      type: "RESET_REPORT_ZOOM",
    });
    dispatch({
      type: "SET_REPORT_FILTERS",
      payload: {
        filters: initialFilters,
        state: false,
      },
    });

    navigate(`/well/${well}?${getQuery(well).join("&")}`);
  };

  if (isLoadingSelectedWellInfo || isLoadingWellData) return null;

  return (
    <Row justify="space-between" align="middle">
      <Col flex="1 auto">
        <WellSelector onWellChange={onWellChange} selectedWell={selectedWell} />
      </Col>
      <Col flex="0 auto">
        <Row justify="end" align="middle" gutter={8}>
          <Col flex="1 auto">
            <OffsetWellSelector />
          </Col>
          <Col flex="0 auto">
            <Space>
              {!isInDemoMode ? (
                <Tooltip title="Well Overview">
                  {/* TODO links do not have trackers, but you have a tracker on the view page for the other page... is a tracker even needed? */}
                  <Link to={`/well/${selectedWell}/edit`}>
                    <Button
                      onClick={() => {
                        Track.clickEvent("Open Well Overview");
                      }}
                      icon={<PDComponent.SvgIcon name="wellInfo" />}
                    />
                  </Link>
                </Tooltip>
              ) : null}
              {userInfo?.role && highPrivilegeRoles.includes(userInfo?.role) && !isInDemoMode ? (
                <Tooltip title="Plan Editor">
                  <Link to={`/well/${selectedWell}/plan`}>
                    <Button icon={<PDComponent.SvgIcon name="textLinkAnalysis" />} />
                  </Link>
                </Tooltip>
              ) : null}
              <PDComponent.VerticalDivider />
              <Tooltip title="Zoom">
                <ZoomButton
                  onClick={() => setZoomOpen(true)}
                  disabled={isFetchingWellOverview !== 0 || isFetchingZoom !== 0}
                  isActive={zoomActiveState}
                />
              </Tooltip>
              <FiltersButton />
              <PDComponent.VerticalDivider />
              <RealtimeIndicator />
              <Tooltip title="Export">
                <Link
                  to={`/export/${selectedWell}/wellDashboard${
                    selectedTabQuery ? `?selectedTab=${selectedTabQuery}` : ""
                  }`}
                >
                  <Button size="large" icon={<PDComponent.SvgIcon name="export" />} />
                </Link>
              </Tooltip>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ControlHeader;
