/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParameterByDepthRoadmapBoundariesDto } from './ParameterByDepthRoadmapBoundariesDto';
import {
    ParameterByDepthRoadmapBoundariesDtoFromJSON,
    ParameterByDepthRoadmapBoundariesDtoFromJSONTyped,
    ParameterByDepthRoadmapBoundariesDtoToJSON,
} from './ParameterByDepthRoadmapBoundariesDto';
import type { TrackFactType } from './TrackFactType';
import {
    TrackFactTypeFromJSON,
    TrackFactTypeFromJSONTyped,
    TrackFactTypeToJSON,
} from './TrackFactType';
import type { ParameterByDepthTrackValuesDto } from './ParameterByDepthTrackValuesDto';
import {
    ParameterByDepthTrackValuesDtoFromJSON,
    ParameterByDepthTrackValuesDtoFromJSONTyped,
    ParameterByDepthTrackValuesDtoToJSON,
} from './ParameterByDepthTrackValuesDto';

/**
 * 
 * @export
 * @interface ParameterByDepthTrackDetailsDto
 */
export interface ParameterByDepthTrackDetailsDto {
    /**
     * 
     * @type {Array<ParameterByDepthTrackValuesDto>}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    trackValues: Array<ParameterByDepthTrackValuesDto>;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    trackId: number;
    /**
     * 
     * @type {TrackFactType}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    type: TrackFactType;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    position: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    average: number;
    /**
     * 
     * @type {Array<ParameterByDepthRoadmapBoundariesDto>}
     * @memberof ParameterByDepthTrackDetailsDto
     */
    boundaries?: Array<ParameterByDepthRoadmapBoundariesDto> | null;
}



/**
 * Check if a given object implements the ParameterByDepthTrackDetailsDto interface.
 */
export function instanceOfParameterByDepthTrackDetailsDto(value: object): value is ParameterByDepthTrackDetailsDto {
    if (!('trackValues' in value) || value['trackValues'] === undefined) return false;
    if (!('trackId' in value) || value['trackId'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('average' in value) || value['average'] === undefined) return false;
    return true;
}

export function ParameterByDepthTrackDetailsDtoFromJSON(json: any): ParameterByDepthTrackDetailsDto {
    return ParameterByDepthTrackDetailsDtoFromJSONTyped(json, false);
}

export function ParameterByDepthTrackDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterByDepthTrackDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'trackValues': ((json['TrackValues'] as Array<any>).map(ParameterByDepthTrackValuesDtoFromJSON)),
        'trackId': json['TrackId'],
        'type': TrackFactTypeFromJSON(json['Type']),
        'position': json['Position'],
        'average': json['Average'],
        'boundaries': json['Boundaries'] == null ? undefined : ((json['Boundaries'] as Array<any>).map(ParameterByDepthRoadmapBoundariesDtoFromJSON)),
    };
}

export function ParameterByDepthTrackDetailsDtoToJSON(value?: ParameterByDepthTrackDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'TrackValues': ((value['trackValues'] as Array<any>).map(ParameterByDepthTrackValuesDtoToJSON)),
        'TrackId': value['trackId'],
        'Type': TrackFactTypeToJSON(value['type']),
        'Position': value['position'],
        'Average': value['average'],
        'Boundaries': value['boundaries'] == null ? undefined : ((value['boundaries'] as Array<any>).map(ParameterByDepthRoadmapBoundariesDtoToJSON)),
    };
}

