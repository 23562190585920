import type { RigPowerSummaryDto } from "apis/oag";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import { parameterCurveColors } from "components/Lenses/ContainerLens/RigPower/common/utils";
import { useLensNameByTemplateId } from "hooks/useLensNameByTemplateId";
import { useCallback, useEffect, useState } from "react";
import { Track } from "services/Mixpanel";
import type { UOMHelper } from "utils/format";

export const STATE_OF_CHARGE_ID = -1;
export const RIG_DEMAND_ID = -2;
export const BESS_OUTPUT_ID = -3;

export function useAdditionalBessLegendItems({
  summary,
  lensId,
  lensTemplateId,
  uomValue,
}: {
  summary: RigPowerSummaryDto;
  lensId: number;
  lensTemplateId: number;
  uomValue: UOMHelper;
}) {
  const lensName = useLensNameByTemplateId(lensTemplateId);

  const displayValue = useCallback(
    (val: number) =>
      uomValue.display(val, {
        fractionDigits: 0,
        unit: "kW",
      }),
    [uomValue],
  );

  const displayValuePercentage = (val: number) => (Number.isFinite(val) ? `${(val * 100).toFixed(0)} %` : "--");

  const [cosmeticLegendItems, setCosmeticLegendItems] = useState<LegendItem[]>([]);

  useEffect(() => {
    setCosmeticLegendItems([
      {
        // This one is purely cosmetic, not enabling and disabling it on server
        id: STATE_OF_CHARGE_ID,
        name: "State of Charge (%)",
        color: parameterCurveColors.stateOfCharge,
        isEnabled: true,
        previewerType: LegendPreviewerType.LINE,
        columnValues: {
          Min: displayValuePercentage(summary?.stateOfCharge?.min || 0),
          Max: displayValuePercentage(summary?.stateOfCharge?.max || 0),
          Mean: displayValuePercentage(summary?.stateOfCharge?.mean || 0),
        },
        onClick: () => {
          setCosmeticLegendItems((items) => {
            Track.interact("Evergreen Dashboard - Update legend", {
              lensId: lensId,
              lens: lensName,
              selectedItem: "State of Charge (%)",
              isEnabled: !items.find((item) => item.id === STATE_OF_CHARGE_ID)?.isEnabled,
            });
            return items.map((item) =>
              item.id === STATE_OF_CHARGE_ID
                ? {
                    ...item,
                    isEnabled: !item.isEnabled,
                  }
                : item,
            );
          });
        },
      },
      {
        // This one is purely cosmetic, not enabling and disabling it on server
        id: RIG_DEMAND_ID,
        name: "Rig Demand",
        color: parameterCurveColors.rigDemand,
        isEnabled: true,
        previewerType: LegendPreviewerType.LINE,
        columnValues: {
          Min: displayValue(summary.rigDemand?.min || 0),
          Max: displayValue(summary.rigDemand?.max || 0),
          Mean: displayValue(summary.rigDemand?.mean || 0),
        },
        onClick: () => {
          setCosmeticLegendItems((items) => {
            Track.interact("Evergreen Dashboard - Update legend", {
              lens: lensName,
              lensId: lensId,
              selectedItem: "Rig Demand",
              isEnabled: !items.find((item) => item.id === RIG_DEMAND_ID)?.isEnabled,
            });
            return items.map((item) =>
              item.id === RIG_DEMAND_ID
                ? {
                    ...item,
                    isEnabled: !item.isEnabled,
                  }
                : item,
            );
          });
        },
      },
      {
        // This one is purely cosmetic, not enabling and disabling it on server
        id: BESS_OUTPUT_ID,
        name: "BESS Output",
        color: parameterCurveColors.bessOutput,
        isEnabled: true,
        previewerType: LegendPreviewerType.LINE_DASHED,
        columnValues: {
          Min: displayValue(summary.bessOutput?.min || 0),
          Max: displayValue(summary.bessOutput?.max || 0),
          Mean: displayValue(summary.bessOutput?.mean || 0),
        },
        onClick: () => {
          setCosmeticLegendItems((items) => {
            Track.interact("Evergreen Dashboard - Update legend", {
              lens: lensName,
              lensId: lensId,
              selectedItem: "BESS Output",
              isEnabled: !items.find((item) => item.id === BESS_OUTPUT_ID)?.isEnabled,
            });
            return items.map((item) =>
              item.id === BESS_OUTPUT_ID
                ? {
                    ...item,
                    isEnabled: !item.isEnabled,
                  }
                : item,
            );
          });
        },
      },
    ]);
  }, [displayValue, summary, lensName, lensId]);

  return cosmeticLegendItems;
}
