import { localPoint } from "@visx/event";
import { scaleLinear } from "@visx/scale";
import { Line } from "@visx/shape";
import { DimensionType, TimelineEventType, UserRoleType } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { AddIconTimeline } from "components/Timeline/components/AddIconTimeline/AddIconTimeline";
import { Event } from "components/Timeline/components/Event/Event";
import { FullHeight } from "components/Timeline/components/TimelineDrawing/style";
import {
  EVENT_HEIGHT,
  EVENT_SPACE_BORDER,
  FONT_SIZE,
  isSameEvent,
  LEFT_MARGIN,
  TOP_MARGIN,
} from "components/Timeline/utils";
import type { ScaleLinear } from "d3-scale";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useSelectedWell } from "hooks/useSelectedWell";
import { isNil, throttle } from "lodash";
import type { ITimelineInfo } from "pages/WellDashboard/types";
import { SetterEnum } from "pages/WellDashboard/types";
import type { FC } from "react";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import type { TimelineStates } from "reducers/reportReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { PLAN_SERIES_ID, secondsInDay } from "utils/common";
import type { ITimelineEventList } from "utils/eventUtils";
import { useUOM } from "utils/format";

const EVENT_EDIT_GAP = 120;
const LINE_LENGTH = 20;
const LINE_HEIGHT = 25;
const getLineLength = (event: ITimelineEventList) => {
  return Math.max(((event.description ?? "").length / LINE_LENGTH) * LINE_HEIGHT, EVENT_EDIT_GAP);
};
const reducer = (accumulator: number, event: ITimelineEventList) => accumulator + getLineLength(event);

export const TimelineDrawing: FC<{
  events: ITimelineEventList[];
  timelineOverride: ITimelineInfo | null;
  selectedTimeline: TimelineStates;
  setTimelineOverride: React.Dispatch<ITimelineInfo | null>;
}> = ({ selectedTimeline, events, timelineOverride, setTimelineOverride }) => {
  const { data: user } = useCurrentUser();
  const eventTypes: Array<{ type: TimelineEventType; icon: JSX.Element }> = useMemo(() => {
    const noteEvent = {
      type: TimelineEventType.Note,
      icon: <PDComponent.SvgIcon name="chat" />,
    };

    const instructionEvent = {
      type: TimelineEventType.Instruction,
      icon: <PDComponent.SvgIcon name="task" />,
    };

    const warningEvent = {
      type: TimelineEventType.Warning,
      icon: <PDComponent.SvgIcon name="warningAlt" />,
    };

    const hazardEvent = {
      type: TimelineEventType.Hazard,
      icon: <PDComponent.SvgIcon name="hazard" />,
    };

    if (user?.role === UserRoleType.General) return [noteEvent];

    return selectedTimeline === "Actual" ? [noteEvent, hazardEvent] : [noteEvent, instructionEvent, warningEvent];
  }, [selectedTimeline, user?.role]);
  const [addEndOfTimeline, setAddEndOfTimeline] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const dispatch = useAppDispatch();
  const actionEnabled = useAppSelector((state) => state.timeline.actionEnabled);
  const wellId = useSelectedWell();
  const [editedEvent, setEditedEvent] = useState<ITimelineEventList | null>(null);
  const selectedSeries = useAppSelector((state) => state.state.selectedSeries);
  const actionCallbackBlock = useRef(false);
  const setSelectedSeries = useCallback(
    (seriesId: number) => {
      dispatch({
        type: "SET_SELECTED_SERIES",
        payload: seriesId,
      });
    },
    [dispatch],
  );
  const actionEnabledCB = useCallback(
    async (action?: boolean) => {
      actionCallbackBlock.current = true;
      if (action) {
        if (selectedSeries !== PLAN_SERIES_ID && selectedTimeline === "Plan") {
          setSelectedSeries(PLAN_SERIES_ID);
          await new Promise((resolve) => setTimeout(resolve, 200));
        }
        if (selectedSeries !== wellId && selectedTimeline === "Actual") {
          setSelectedSeries(wellId);
          await new Promise((resolve) => setTimeout(resolve, 200));
        }
      }
      if (!isNil(action)) {
        dispatch({
          type: "SET_ACTION_ENABLED",
          payload: action,
        });
      }
      actionCallbackBlock.current = false;
    },
    [dispatch, selectedSeries, selectedTimeline, setSelectedSeries, wellId],
  );
  useEffect(() => {
    if (actionCallbackBlock) return;
    actionEnabledCB(false);
    plusRef.current = null;
    setCrtLocation(null);
    forceUpdate();
  }, [actionEnabledCB, wellId, selectedTimeline]);
  const isOut = useRef<boolean | null>(null);
  const plusRef = useRef<{
    top: number;
    duration: number;
    depth: number | null;
    label: string;
  } | null>(null);
  const addActionRef = useRef<HTMLDivElement>(null);
  const [currentEvent, setCurrentEvent] = useState<{ type: TimelineEventType; icon: JSX.Element } | null>(null);

  // TODO: What is crt location ??
  // last location that the adding target has
  // save this in the top scope and get it in here
  const [crtLocation, setCrtLocation] = useState<number | null>(plusRef.current?.top || 0);

  const depthUOM = useUOM(DimensionType.Metres);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledDispatchTimelineOverride = useCallback(
    throttle((cumulativeDuration?: number) => {
      if (isOut.current || !cumulativeDuration) {
        setTimelineOverride(null);
        return;
      }

      setTimelineOverride({ cumulativeDuration, depth: null, type: SetterEnum.Timeline });
    }, 150),
    [setTimelineOverride],
  );
  const yScale = useMemo<{
    [index: number | string]: {
      cumulativeDuration: ScaleLinear<number, number, never>;
      depth: ScaleLinear<number, number, never>;
    };
  }>(() => {
    const res = events.slice(0, events.length - 1).reduce((acc, crt, index) => {
      const pastEvents = events.slice(0, index);
      return {
        ...acc,
        [index]: {
          cumulativeDuration: scaleLinear<number>({
            domain: [
              crt.cumulativeDuration || crt.startCumulativeDuration,
              crt.cumulativeDuration || crt.startCumulativeDuration,
              events[index + 1].cumulativeDuration || events[index + 1].startCumulativeDuration,
              events[index + 1].cumulativeDuration || events[index + 1].startCumulativeDuration,
            ],
            range: [
              TOP_MARGIN + pastEvents.reduce(reducer, 0),
              TOP_MARGIN + pastEvents.reduce(reducer, 0) + EVENT_SPACE_BORDER,
              TOP_MARGIN + [...pastEvents, crt].reduce(reducer, 0) - EVENT_SPACE_BORDER,
              TOP_MARGIN + [...pastEvents, crt].reduce(reducer, 0),
            ],
            nice: false,
          }),
          depth: scaleLinear<number>({
            domain: [
              crt.holeDepth || crt.startHoleDepth,
              crt.holeDepth || crt.startHoleDepth,
              events[index + 1].holeDepth || events[index + 1].startHoleDepth,
              events[index + 1].holeDepth || events[index + 1].startHoleDepth,
            ],
            range: [
              TOP_MARGIN + pastEvents.reduce(reducer, 0),
              TOP_MARGIN + pastEvents.reduce(reducer, 0) + EVENT_SPACE_BORDER,
              TOP_MARGIN + [...pastEvents, crt].reduce(reducer, 0) - EVENT_SPACE_BORDER,
              TOP_MARGIN + [...pastEvents, crt].reduce(reducer, 0),
            ],
            nice: false,
            clamp: true,
          }),
        },
        [`${index}-TvD`]: {
          cumulativeDuration: scaleLinear<number>({
            domain: [
              crt.cumulativeDuration || crt.startCumulativeDuration,
              events[index + 1].cumulativeDuration || events[index + 1].startCumulativeDuration,
            ],
            range: [
              TOP_MARGIN + pastEvents.reduce(reducer, 0) + EVENT_SPACE_BORDER,
              TOP_MARGIN + [...pastEvents, crt].reduce(reducer, 0) - EVENT_SPACE_BORDER / 2,
            ],
            nice: false,
          }),
          depth: scaleLinear<number>({
            domain: [
              crt.holeDepth || crt.startHoleDepth,
              events[index + 1].holeDepth || events[index + 1].startHoleDepth,
            ],
            range: [
              TOP_MARGIN + pastEvents.reduce(reducer, 0) + EVENT_SPACE_BORDER,
              TOP_MARGIN + [...pastEvents, crt].reduce(reducer, 0) - EVENT_SPACE_BORDER,
            ],
            nice: false,
          }),
        },
      };
    }, {});
    return res;
  }, [events]);
  useEffect(() => {
    if (addEndOfTimeline && editedEvent?.eventType === "EndOfTimeline") return;
    const eventIndex = events.findIndex((eventCmp) => isSameEvent({ eventCmp, event: editedEvent }));
    setCrtLocation(events.slice(0, eventIndex).reduce(reducer, 0) + TOP_MARGIN);
  }, [addEndOfTimeline, editedEvent, events]);
  const calculateTopByDuration = useCallback(
    (duration: number) => {
      const scaleKey = `${
        events.findIndex((event) => (event?.cumulativeDuration || event?.startCumulativeDuration) >= duration) - 1
      }-TvD`;
      const currentScale = yScale[scaleKey].cumulativeDuration;
      const position = currentScale(duration);

      return {
        top: position - FONT_SIZE / 2 - TOP_MARGIN,
        duration: duration,
        depth: null,
        label: "",
      };
    },
    [events, yScale],
  );
  useEffect(() => {
    if (addEndOfTimeline && editedEvent?.eventType === "EndOfTimeline") {
      plusRef.current = null;
      setCrtLocation(null);
      forceUpdate();
      return;
    }
    if (editedEvent) return;
    if (actionEnabled) {
      if (timelineOverride && plusRef.current && timelineOverride.cumulativeDuration !== plusRef.current.duration) {
        const newRef = calculateTopByDuration(timelineOverride.cumulativeDuration);
        setCrtLocation(newRef.top);
        plusRef.current = newRef;
        forceUpdate();
      } else {
        setCrtLocation(plusRef.current?.top || null);
      }
      return;
    }

    if (timelineOverride?.type === SetterEnum.Timeline) {
      forceUpdate();
      return;
    }

    if (!timelineOverride) {
      plusRef.current = null;
      setCrtLocation(null);
      forceUpdate();
      return;
    }
    const scaleKey = `${
      events.findIndex(
        (event) => (event?.cumulativeDuration || event?.startCumulativeDuration) >= timelineOverride.cumulativeDuration,
      ) - 1
    }-TvD`;

    if (!yScale[scaleKey]?.cumulativeDuration) {
      plusRef.current = null;
      setCrtLocation(null);
      forceUpdate();
      return;
    }

    plusRef.current = calculateTopByDuration(timelineOverride.cumulativeDuration);
    forceUpdate();
  }, [
    actionEnabled,
    addEndOfTimeline,
    calculateTopByDuration,
    editedEvent,
    editedEvent?.eventType,
    events,
    timelineOverride,
    yScale,
  ]);

  const handleTooltip = useCallback(
    (index: number) => (event: React.MouseEvent<SVGLineElement>) => {
      if (isOut.current) return;
      if (actionEnabled) return;
      if (!yScale[index]) {
        setCrtLocation(null);
        return;
      }
      const { y } = localPoint(event) || { y: 0 };

      const cumulativeDuration = yScale[index].cumulativeDuration.invert(y);
      const days = (cumulativeDuration / secondsInDay).toFixed(2);
      const holeDepth = depthUOM.display(yScale[index].depth.invert(y));

      plusRef.current = {
        top: y - TOP_MARGIN - FONT_SIZE / 2,
        duration: cumulativeDuration,
        depth: null,
        label: `Day ${days} ${holeDepth}`,
      };
      throttledDispatchTimelineOverride(cumulativeDuration);
    },
    [actionEnabled, depthUOM, throttledDispatchTimelineOverride, yScale],
  );

  const hidePlus = () => {
    if (actionEnabled || editedEvent) return;
    isOut.current = true;
    throttledDispatchTimelineOverride(undefined);
    plusRef.current = null;
    setCrtLocation(null);
  };

  const resetRef = useCallback(() => {
    setCrtLocation(null);
    actionEnabledCB(false);
    plusRef.current = null;
    forceUpdate();
  }, [actionEnabledCB]);
  const eventTopCb = useCallback(
    (index: number) => {
      const eventTop = events.slice(0, index).reduce(reducer, 0);
      return TOP_MARGIN + eventTop;
    },
    [events],
  );

  useLayoutEffect(() => {
    if (addActionRef.current && actionEnabled) {
      setTimeout(() => {
        addActionRef.current?.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }, 100);
    }
  }, [actionEnabled, addActionRef]);
  const isPhaseEvent = (event: ITimelineEventList) =>
    event.eventType === "PhaseEnd" || event.eventType === "PhaseStart";
  const { width, ref } = useResizeDetector();
  return (
    <FullHeight ref={ref}>
      <svg
        height={TOP_MARGIN + events.reduce(reducer, 0) + EVENT_HEIGHT}
        width={60}
        style={{
          display: "inline-block",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        {events.map((event, index) => {
          if (index === 0) return null;
          const start = eventTopCb(index) - EVENT_HEIGHT / 2;
          const end = eventTopCb(index - 1) + EVENT_HEIGHT / 2;
          return [
            <Line
              key={event.id}
              from={{
                x: LEFT_MARGIN,
                y: start + (isPhaseEvent(event) || (index !== 0 && isPhaseEvent(events[index - 1])) ? 10 : 0),
              }}
              to={{
                x: LEFT_MARGIN,
                y: end - (isPhaseEvent(event) || (index !== 0 && isPhaseEvent(events[index - 1])) ? 10 : 0),
              }}
              stroke={colors.light_green}
              opacity={0.6}
              strokeWidth={2}
            />,
            <Line
              key={`${event.id}-hover`}
              from={{
                x: LEFT_MARGIN,
                y: start,
              }}
              to={{
                x: LEFT_MARGIN,
                y: end,
              }}
              onMouseMove={handleTooltip(index - 1)}
              onMouseLeave={hidePlus}
              onMouseEnter={() => {
                if (actionEnabled || editedEvent) return;
                isOut.current = false;
              }}
              onClick={() => {
                setAddEndOfTimeline(false);
                if (!actionEnabled) setCrtLocation(plusRef.current?.top || 0);
                actionEnabledCB(!actionEnabled);
              }}
              strokeWidth={20}
              stroke={"transparent"}
              strokeOpacity={0}
            />,
          ];
        })}
      </svg>
      {events.map((event, index) => {
        return (
          <Event
            width={width || 0}
            actionEnabled={actionEnabled}
            setCurrentEvent={setCurrentEvent}
            resetRef={resetRef}
            eventTypes={eventTypes}
            editedEvent={editedEvent}
            setEditedEvent={setEditedEvent}
            key={`${event.id}-${selectedTimeline}-${event.eventType}-${Math.random()}`}
            addEndOfTimeline={addEndOfTimeline}
            setAddEndOfTimeline={setAddEndOfTimeline}
            event={event}
            selectedTimeline={selectedTimeline}
            isStartOfPhase={event.isStartOfPhase}
            top={eventTopCb(index)}
          />
        );
      })}
      <AddIconTimeline
        currentEvent={currentEvent}
        setCurrentEvent={setCurrentEvent}
        info={
          plusRef.current
            ? {
                ...plusRef.current,
                depth: plusRef.current?.depth ?? timelineOverride?.depth ?? null,
              }
            : null
        }
        addActionRef={addActionRef}
        actionEnabled={actionEnabled}
        crtLocation={crtLocation}
        resetRef={resetRef}
        setTimelineOverride={setTimelineOverride}
        eventTypes={eventTypes}
      />
    </FullHeight>
  );
};
