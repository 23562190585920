import { useSuspenseQuery } from "@tanstack/react-query";
import { WellsApi } from "apis/oag";
import { Button, Input } from "atoms/Form";
import { toast } from "atoms/toast";
import { PDComponent } from "components/PDComponents";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Track } from "services/Mixpanel";
import { useDebounce } from "use-debounce";
import { apiConfig } from "utils/apiConfig";
import { Col, Radio, Row, Typography } from "utils/componentLibrary";
import type { PDCopyFromModalQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { RadioGroupWrapper, RadioWrapper } from "./style";


export type CopyFromModalProps = {
  wellId: number;
  setVisible: (value: boolean) => void;
  visible: boolean;
  onCopy: (sourceWellId: number) => Promise<void> | void;
  type: "formations" | "drillingParameters" | "overview";
};

const wellsApi = new WellsApi(apiConfig);

export function CopyFromModal({
  wellId,
  type,
  onCopy,
  setVisible,
  visible,
}: Readonly<CopyFromModalProps>) {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebounce(search, 500);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const { data: wellData } = useWellShortInfoSuspended();

  const queryKey: PDCopyFromModalQueryKey = {
    type: PDQueryType.COPY_FROM_MODAL,
    uid: RequestUID.copyFromModal,
    queryType: type,
  };

  const { data: availableIds } = useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: () => {
      if (type === "formations") {
        return wellsApi.apiWellsHasPlanFormationsIdGet();
      }

      if (type === "drillingParameters") {
        return wellsApi.apiWellsHasPlanDrillingParametersIdGet();
      }

      if (type === "overview") {
        return wellsApi.apiWellsHasPlanActivitiesIdGet();
      }

      return Promise.resolve([]);
    },
  });

  const availableWells = useMemo(
    () => (availableIds ?? []).filter((id) => id !== wellId).map((id) => wellData.byId[id]),
    [availableIds, wellData.byId, wellId],
  );

  const wellOptions = useMemo(
    () =>
      availableWells
        .filter((option) => option.name?.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase()))
        .map((well) => ({ label: well.name, value: well.id })),
    [availableWells, debouncedSearch],
  );

  useEffect(() => {
    if (!visible) {
      setSelectedId(undefined);
    }
  }, [visible]);

  const noOptions = wellOptions.length === 0;
  const getMessage = (type: "formations" | "drillingParameters" | "overview") => {
    switch (type) {
      case "formations":
        return "Formations";
      case "drillingParameters":
        return "Drilling parameters";
      case "overview":
        return "Overview";
      default:
        return "";
    }
  }
  const copyFrom = (selectedId: number) => {
    Track.interact(`Copy ${type[0].toLocaleUpperCase()}${type.slice(1)} From ${wellData.byId[selectedId].name}`);
    toast.success({
      message: `${getMessage(type)} were copied from ${wellData.byId[selectedId].name}`,
    });
    onCopy(selectedId);
  };
  return (
    <PDComponent.Modal
      onCancel={handleCancel}
      title="Copy from Well"
      footer={
        <Row justify="space-between">
          <Col>
            <Button trackingName="Cancel Copy Modal" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          {!noOptions && (
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  if (selectedId) copyFrom(selectedId);
                }}
              >
                Execute
              </Button>
            </Col>
          )}
        </Row>
      }
      open={visible}
    >
      <Typography style={{ marginBottom: "24px" }}>
        Select a well to copy the {getMessage(type)} plan from. Notice, all
        your existing records will be overwritten.
      </Typography>

      <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.currentTarget.value)} />

      <RadioGroupWrapper>
        {noOptions ? <Typography>No wells available.</Typography> : null}
        <Radio.Group
          value={selectedId}
          onChange={(e) => {
            setSelectedId(e.target.value);
          }}
          style={{ width: "100%" }}
        >
          {wellOptions.map((option) => (
            <RadioWrapper key={option.value}>
              <Radio value={option.value}>{option.label}</Radio>
            </RadioWrapper>
          ))}
        </Radio.Group>
      </RadioGroupWrapper>
    </PDComponent.Modal>
  );
}

export function useCopyFromModal({
  wellId,
  onCopy,
  type,
}: {
  wellId: number;
  onCopy: (selectedWellId: number) => Promise<void> | void;
  type: "formations" | "drillingParameters" | "overview";
}) {
  const [visible, setVisible] = useState(false);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  const open = useCallback(() => {
    setVisible(true);
  }, []);

  const node = (
    <CopyFromModal
      setVisible={setVisible}
      visible={visible}
      onCopy={(selectedWellId: number) => {
        onCopy(selectedWellId);
        close();
      }}
      wellId={wellId}
      type={type}
    />
  );

  return { visible, close, open, node };
}
