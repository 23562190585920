import React, { createContext } from "react";
import styled from "styled-components";
import type { ResponsiveBreakpoints } from "utils/common";

type RowAligns = "top" | "middle" | "bottom" | "stretch";
type RowJustify = "start" | "end" | "center" | "space-around" | "space-between" | "space-evenly";

type ResponsiveLike<T> = {
  [key in ResponsiveBreakpoints]?: T;
};

type ResponsiveAligns = ResponsiveLike<RowAligns>;
type ResponsiveJustify = ResponsiveLike<RowJustify>;

type Gutter = number;

export interface RowContextState {
  gutter?: [number, number];
  wrap?: boolean;
}

export const RowContext = createContext<RowContextState>({});
export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  gutter?: Gutter | [Gutter, Gutter];
  align?: RowAligns | ResponsiveAligns;
  justify?: RowJustify | ResponsiveJustify;
  wrap?: boolean;
}

const rowAlignsToCssAligns: Record<RowAligns, string> = {
  top: "flex-start",
  middle: "center",
  bottom: "flex-end",
  stretch: "stretch",
};

const rowAlignsToStyles = (align?: RowAligns | ResponsiveAligns) => {
  if (!align || !rowAlignsToCssAligns) {
    return undefined;
  }
  if (typeof align === "string") {
    return `align-items: ${rowAlignsToCssAligns[align]};`;
  }

  return (Object.keys(align) as ResponsiveBreakpoints[])
    .map((size) => {
      const alignBySize = align[size];
      return size && alignBySize
        ? `@media (min-width: ${size}) {
          align-items: ${rowAlignsToCssAligns[alignBySize]};
        } `
        : "";
    })
    .join("");
};

const rowJustifyToStyles = (justify?: RowJustify | ResponsiveJustify) => {
  if (!justify) {
    return undefined;
  }
  if (typeof justify === "string") {
    return `justify-content: ${justify};`;
  }

  return (Object.keys(justify) as ResponsiveBreakpoints[])
    .map((size) => {
      return `@media (min-width: ${size}) {
      justify-content: ${justify[size]};
    } `;
    })
    .join("");
};

export const PdRow = styled.div<{
  horizontalGutter: number;
  verticalGutter: number;
  align?: RowAligns | ResponsiveAligns;
  justify?: RowJustify | ResponsiveJustify;
  $wrap?: boolean;
}>`
  display: flex;
  flex-flow: row wrap;
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "nowrap")};
  ${({ align }) => rowAlignsToStyles(align)};
  ${({ justify }) => rowJustifyToStyles(justify)};

  margin-left: ${({ horizontalGutter }) => -horizontalGutter / 2}px;
  margin-right: ${({ horizontalGutter }) => -horizontalGutter / 2}px;
  row-gap: ${({ verticalGutter }) => verticalGutter}px;
`;

export const Row = React.forwardRef<HTMLDivElement, RowProps>(
  ({ gutter = 0, align, justify, wrap = true, children, ...others }, ref) => {
    const gutters = Array.isArray(gutter) ? gutter : [gutter, gutter];

    const horizontalGutter = gutters[0] > 0 ? gutters[0] : 0;
    const verticalGutter = gutters[1] > 0 ? gutters[1] : 0;

    const rowContext = React.useMemo(
      () => ({ gutter: [horizontalGutter, verticalGutter] as [number, number], wrap }),
      [horizontalGutter, verticalGutter, wrap],
    );
    return (
      <RowContext.Provider value={rowContext}>
        <PdRow
          ref={ref}
          align={align}
          justify={justify}
          $wrap={wrap}
          horizontalGutter={horizontalGutter}
          verticalGutter={verticalGutter}
          {...others}
        >
          {children}
        </PdRow>
      </RowContext.Provider>
    );
  },
);

Row.displayName = "Row";
