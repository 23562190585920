/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotificationType } from './NotificationType';
import {
    NotificationTypeFromJSON,
    NotificationTypeFromJSONTyped,
    NotificationTypeToJSON,
} from './NotificationType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface ScorecardCommentNotificationDto
 */
export interface ScorecardCommentNotificationDto {
    /**
     * 
     * @type {number}
     * @memberof ScorecardCommentNotificationDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardCommentNotificationDto
     */
    title?: string | null;
    /**
     * 
     * @type {NotificationType}
     * @memberof ScorecardCommentNotificationDto
     */
    type: NotificationType;
    /**
     * 
     * @type {boolean}
     * @memberof ScorecardCommentNotificationDto
     */
    isRead: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScorecardCommentNotificationDto
     */
    isArchived: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof ScorecardCommentNotificationDto
     */
    createdAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof ScorecardCommentNotificationDto
     */
    kpiCommentId: number;
    /**
     * 
     * @type {number}
     * @memberof ScorecardCommentNotificationDto
     */
    wellId: number;
    /**
     * 
     * @type {number}
     * @memberof ScorecardCommentNotificationDto
     */
    rigId: number;
    /**
     * 
     * @type {string}
     * @memberof ScorecardCommentNotificationDto
     */
    description: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScorecardCommentNotificationDto
     */
    jobExternalIds: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ScorecardCommentNotificationDto
     */
    isUpdated: boolean;
}



/**
 * Check if a given object implements the ScorecardCommentNotificationDto interface.
 */
export function instanceOfScorecardCommentNotificationDto(value: object): value is ScorecardCommentNotificationDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('isRead' in value) || value['isRead'] === undefined) return false;
    if (!('isArchived' in value) || value['isArchived'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('kpiCommentId' in value) || value['kpiCommentId'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('rigId' in value) || value['rigId'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('jobExternalIds' in value) || value['jobExternalIds'] === undefined) return false;
    if (!('isUpdated' in value) || value['isUpdated'] === undefined) return false;
    return true;
}

export function ScorecardCommentNotificationDtoFromJSON(json: any): ScorecardCommentNotificationDto {
    return ScorecardCommentNotificationDtoFromJSONTyped(json, false);
}

export function ScorecardCommentNotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScorecardCommentNotificationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'title': json['Title'] == null ? undefined : json['Title'],
        'type': NotificationTypeFromJSON(json['Type']),
        'isRead': json['IsRead'],
        'isArchived': json['IsArchived'],
        'createdAt': DateDtoFromJSON(json['CreatedAt']),
        'kpiCommentId': json['KpiCommentId'],
        'wellId': json['WellId'],
        'rigId': json['RigId'],
        'description': json['Description'],
        'jobExternalIds': json['JobExternalIds'],
        'isUpdated': json['IsUpdated'],
    };
}

export function ScorecardCommentNotificationDtoToJSON(value?: ScorecardCommentNotificationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Title': value['title'],
        'Type': NotificationTypeToJSON(value['type']),
        'IsRead': value['isRead'],
        'IsArchived': value['isArchived'],
        'CreatedAt': DateDtoToJSON(value['createdAt']),
        'KpiCommentId': value['kpiCommentId'],
        'WellId': value['wellId'],
        'RigId': value['rigId'],
        'Description': value['description'],
        'JobExternalIds': value['jobExternalIds'],
        'IsUpdated': value['isUpdated'],
    };
}

