import type { UserDto } from "apis/oag";
import { UserRoleType } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import * as CommonStyles from "components/Timeline/style";
import React from "react";
import type { TimelineStates } from "reducers/reportReducer";
import { Col, Row } from "utils/componentLibrary";
import type { ITimelineEventList } from "utils/eventUtils";

export const EventHover = ({
  event,
  disableHover,
  user,
  restoreEvent,
  editEvent,
  deleteEvent,
  selectedTimeline,
}: {
  event: ITimelineEventList;
  disableHover?: boolean;
  user: UserDto;
  restoreEvent: () => void;
  deleteEvent: () => void;
  editEvent: () => void;
  selectedTimeline: TimelineStates;
}) => {
  const isDeletedEvent = !!event?.deletedAtUtc;

  if (isDeletedEvent)
    return (
      <Row gutter={2} style={{ maxHeight: "22px", marginTop: "-6px", marginLeft: "-2px" }}>
        <Col>
          <CommonStyles.StyledButton trackingName="Restore Event" type="link" onClick={restoreEvent}>
            Restore
            <PDComponent.SvgIcon name="restart" />
          </CommonStyles.StyledButton>
        </Col>
      </Row>
    );

  if (!disableHover && (user.role === UserRoleType.Administrator || user.id === event.authorId)) {
    return (
      <Row gutter={2} align="middle" style={{ height: "22px", marginTop: "-6px", marginLeft: "-2px" }}>
        {selectedTimeline !== "Plan" || user.role === UserRoleType.Administrator ? (
          <>
            <Col>
              <CommonStyles.StyledButton trackingName="Delete Event" type="link" onClick={deleteEvent}>
                Delete
                <PDComponent.SvgIcon name="trashCan" />
              </CommonStyles.StyledButton>
            </Col>
            <Col>
              <CommonStyles.StyledSubtitle>{"  "}</CommonStyles.StyledSubtitle>
            </Col>
          </>
        ) : null}
        <Col>
          <CommonStyles.StyledButton trackingName="Edit Event" type="link" onClick={editEvent}>
            Edit <PDComponent.SvgIcon name="edit" />
          </CommonStyles.StyledButton>
        </Col>
      </Row>
    );
  }
  return null;
};
