/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClaimPermissionType } from './ClaimPermissionType';
import {
    ClaimPermissionTypeFromJSON,
    ClaimPermissionTypeFromJSONTyped,
    ClaimPermissionTypeToJSON,
} from './ClaimPermissionType';
import type { ClaimType } from './ClaimType';
import {
    ClaimTypeFromJSON,
    ClaimTypeFromJSONTyped,
    ClaimTypeToJSON,
} from './ClaimType';

/**
 * 
 * @export
 * @interface UserClaimDto
 */
export interface UserClaimDto {
    /**
     * 
     * @type {number}
     * @memberof UserClaimDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserClaimDto
     */
    rigId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserClaimDto
     */
    wellId?: number | null;
    /**
     * 
     * @type {ClaimType}
     * @memberof UserClaimDto
     */
    type: ClaimType;
    /**
     * 
     * @type {ClaimPermissionType}
     * @memberof UserClaimDto
     */
    permission: ClaimPermissionType;
    /**
     * 
     * @type {number}
     * @memberof UserClaimDto
     */
    userId: number;
}



/**
 * Check if a given object implements the UserClaimDto interface.
 */
export function instanceOfUserClaimDto(value: object): value is UserClaimDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('permission' in value) || value['permission'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    return true;
}

export function UserClaimDtoFromJSON(json: any): UserClaimDto {
    return UserClaimDtoFromJSONTyped(json, false);
}

export function UserClaimDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserClaimDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'rigId': json['RigId'] == null ? undefined : json['RigId'],
        'wellId': json['WellId'] == null ? undefined : json['WellId'],
        'type': ClaimTypeFromJSON(json['Type']),
        'permission': ClaimPermissionTypeFromJSON(json['Permission']),
        'userId': json['UserId'],
    };
}

export function UserClaimDtoToJSON(value?: UserClaimDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'RigId': value['rigId'],
        'WellId': value['wellId'],
        'Type': ClaimTypeToJSON(value['type']),
        'Permission': ClaimPermissionTypeToJSON(value['permission']),
        'UserId': value['userId'],
    };
}

