/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TorqueAndDragType } from './TorqueAndDragType';
import {
    TorqueAndDragTypeFromJSON,
    TorqueAndDragTypeFromJSONTyped,
    TorqueAndDragTypeToJSON,
} from './TorqueAndDragType';

/**
 * 
 * @export
 * @interface BroomstickDto
 */
export interface BroomstickDto {
    /**
     * 
     * @type {number}
     * @memberof BroomstickDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BroomstickDto
     */
    externalId: number;
    /**
     * 
     * @type {number}
     * @memberof BroomstickDto
     */
    frictionFactor: number;
    /**
     * 
     * @type {TorqueAndDragType}
     * @memberof BroomstickDto
     */
    type: TorqueAndDragType;
    /**
     * 
     * @type {string}
     * @memberof BroomstickDto
     */
    section?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BroomstickDto
     */
    activity?: string | null;
}



/**
 * Check if a given object implements the BroomstickDto interface.
 */
export function instanceOfBroomstickDto(value: object): value is BroomstickDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('externalId' in value) || value['externalId'] === undefined) return false;
    if (!('frictionFactor' in value) || value['frictionFactor'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function BroomstickDtoFromJSON(json: any): BroomstickDto {
    return BroomstickDtoFromJSONTyped(json, false);
}

export function BroomstickDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BroomstickDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': json['ExternalId'],
        'frictionFactor': json['FrictionFactor'],
        'type': TorqueAndDragTypeFromJSON(json['Type']),
        'section': json['Section'] == null ? undefined : json['Section'],
        'activity': json['Activity'] == null ? undefined : json['Activity'],
    };
}

export function BroomstickDtoToJSON(value?: BroomstickDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'ExternalId': value['externalId'],
        'FrictionFactor': value['frictionFactor'],
        'Type': TorqueAndDragTypeToJSON(value['type']),
        'Section': value['section'],
        'Activity': value['activity'],
    };
}

