/* eslint-disable react/no-multi-comp */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { BarRounded, BarStack } from "@visx/shape";
import type {
  GeneratorSlotType,
  PowerLoadDistributionUserLensDto,
  RigPowerDistributionBarDto,
  RigPowerDistributionBarSliceDto,
} from "apis/oag";
import { DimensionType, PowerLoadDistributionType, PowerLoadDistributionUserLensesApi, TimeUnit } from "apis/oag";
import { Title } from "atoms/Typography";
import { StyledCard, StyledDropDown, StyledOptionRow } from "components/Header/RightContent/style";
import { AxisLocation, StandardTickComponent } from "components/Lenses/common/ChartElements";
import ChartLegend from "components/Lenses/common/ChartLegend";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import { Scrollbar } from "components/Lenses/common/Scrollbar";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartScrollbar } from "components/Lenses/common/useChartScrollbar";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import {
  CLIP_SERIES_ID as CLIP_ID,
  generatorColorsByActiveCount,
  generatorColorsBySlotType,
  getAxisFontSize,
  getGeneratorSlotTypeByNumber,
  getInternalAxisMargin,
  LATERAL_AXIS_TEXT_WIDTH,
  LATERAL_AXIS_WIDTH,
  LATERAL_LABEL_POSITION,
  X_AXIS_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { StyledLensContainerFlex } from "components/Lenses/ContainerLens/RigPower/RigPowerSimple/StyledComponents";
import type { PowerLoadDistributionProps } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { max, min } from "d3-array";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import { useLensNameByTemplateId } from "hooks/useLensNameByTemplateId";
import { useLensSize } from "hooks/useLensSize";
import { useOperationClasses } from "hooks/useOperationClasses";
import { useOperationColors } from "hooks/useOperationColors";
import { range } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import { useResizeDetector } from "react-resize-detector";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import { apiConfig } from "utils/apiConfig";
import { secondsInHour } from "utils/common";
import { Col, Row } from "utils/componentLibrary";
import { useTimeUom, useUOM } from "utils/format";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import { usePowerLoadDistributionFetcher } from "./fetcher";
import { AVG_UNIT_LABEL, tickValues, UNIT_LABEL } from "./utils";

const Option = styled.div`
  width: 100%;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  cursor: pointer;
`;
const powerLoadUserLensApi = new PowerLoadDistributionUserLensesApi(apiConfig);

export const Single: React.FC<PowerLoadDistributionProps> = ({ lens, detailed, setLensDate, isLocked }) => {
  const [legend, setLegend] = useState<Array<GeneratorSlotType | number> | null>(null);
  const [selectedDistribution, setSelectedDistribution] = useState(lens.distributionType);
  const [generatorMenuVisible, setGeneratorMenuVisible] = useState(false);
  const { request } = usePowerLoadDistributionFetcher({
    lensId: lens?.id,
  });
  const { data } = request;

  const uomTransformer = useUOM(DimensionType.Watts);
  const hourUom = useTimeUom(TimeUnit.Hour);

  useEffect(() => {
    if (data?.lastUpdatedAt && setLensDate) setLensDate(data.lastUpdatedAt);
  }, [data, setLensDate]);

  useEffect(() => {
    setLegend(null);
  }, [selectedDistribution]);

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const plotWidth = getSVGNormalizedValue(chartWidth - 2 * LATERAL_AXIS_WIDTH);
  const plotHeight = getSVGNormalizedValue(chartHeight - X_AXIS_HEIGHT - 6);
  const dataState = useMemo(() => data?.dataState, [data?.dataState]);
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const { data: operationClassesNames } = useOperationClasses();

  const kpiKeys = useMemo(
    () => data?.availableDistributionReferenceIds ?? [],
    [data?.availableDistributionReferenceIds],
  );

  const { data: operationClasses } = useOperationClasses();
  const { getOperationColor } = useOperationColors();

  const getCorrectColor = useCallback(
    (internalId: number) => {
      if (data?.distributionType === PowerLoadDistributionType.OperationClasses) {
        return getOperationColor(internalId);
      } else if (data?.distributionType === PowerLoadDistributionType.GeneratorIndex) {
        return generatorColorsBySlotType[getGeneratorSlotTypeByNumber(internalId)];
      } else {
        return generatorColorsByActiveCount[internalId as keyof typeof generatorColorsByActiveCount];
      }
    },
    [data?.distributionType, getOperationColor],
  );
  const lensName = useLensNameByTemplateId(lens?.lensTemplateId);

  const legendItems = useMemo<LegendItem[]>(() => {
    if (!data?.availableDistributionReferenceIds) return [];
    return data.availableDistributionReferenceIds
      .sort((a, b) => {
        if (data?.distributionType === PowerLoadDistributionType.OperationClasses) {
          const aIndex = operationClasses.findIndex((operationClass) => operationClass.id === a);
          const bIndex = operationClasses.findIndex((operationClass) => operationClass.id === b);
          return aIndex - bIndex;
        }
        return a - b;
      })
      .map((availableDistributionReferenceId) => {
        let distributionName = availableDistributionReferenceId.toString();
        if (data.distributionType === PowerLoadDistributionType.OperationClasses) {
          distributionName =
            operationClasses.find((opClass) => opClass.id === availableDistributionReferenceId)?.name ?? "NO NAME";
        } else if (data.distributionType === PowerLoadDistributionType.GeneratorIndex) {
          distributionName = `Gen ${availableDistributionReferenceId}`;
        }

        return {
          id: availableDistributionReferenceId,
          name: distributionName,
          color: operationClassesNames.find((op) => op.id === availableDistributionReferenceId)?.name
            ? getCorrectColor(availableDistributionReferenceId)
            : "",
          isEnabled: legend === null ? true : legend?.includes(availableDistributionReferenceId),
          columnValues: undefined,
          previewerType: LegendPreviewerType.BOX,
          onClick: (item: LegendItem) => {
            setLegend((currentLegend) => {
              Track.interact("Evergreen Dashboard - Update Power load distribution legend", {
                lensId: lens.id,
                lens: lensName,
                selectedItem: item.name,
                isEnabled: !item.isEnabled,
              });
              if (currentLegend === null) {
                return kpiKeys.filter((key) => key !== item.id);
              }
              if (currentLegend.length === 1 && item.isEnabled) return currentLegend;

              return item.isEnabled
                ? currentLegend.filter((element) => element !== item.id)
                : [...currentLegend, item.id];
            });
          },
        };
      });
  }, [
    lensName,
    lens.id,
    data?.availableDistributionReferenceIds,
    data?.distributionType,
    getCorrectColor,
    kpiKeys,
    legend,
    operationClasses,
    operationClassesNames,
  ]);

  const { scrollbarScale, scrollbarRange, scrollbarPadding, scrollbarWidth, zoomRate, setScrollbarRange } =
    useChartScrollbar({
      plotWidth,
      detailed,
      zoomRate: 1,
    });

  const xScale = useMemo(() => {
    const chartSize = plotWidth;

    const startX = LATERAL_AXIS_WIDTH + Math.floor(chartSize * scrollbarRange.startX);
    return scaleBand<string>({
      domain: range(0, 3600000, 100000).map((x) => `${x}`),
      range: [startX, chartSize + startX],
      paddingInner: 0.3,
      paddingOuter: 0.2,
    });
  }, [plotWidth, scrollbarRange.startX]);

  const [width, height] = useLensSize(lens?.id);
  const internalAxisMargin = useMemo(() => getInternalAxisMargin(detailed), [detailed]);

  const yScale = useMemo(() => {
    const facts = data.bars || [];
    const [minValue = 0, maxValue = 0] = [
      min(facts.map((bar) => bar.totalActiveSeconds)),
      max(facts.map((bar) => bar.totalActiveSeconds)),
    ];
    return scaleLinear<number>({
      range: [plotHeight, internalAxisMargin],
      domain: [minValue, maxValue],
      nice: true,
    });
  }, [data.bars, internalAxisMargin, plotHeight]);

  const { yDisplayOnlyScale, verticalAxisTicksCount, formatTickDecimals } = useYAxisDisplayScale({
    tickContainerHeight: plotHeight,
    originalScale: yScale,
    uom: hourUom,
  });

  const CLIP_SERIES_ID = useMemo(() => `${CLIP_ID}-${lens.id}`, [lens.id]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<
    RigPowerDistributionBarSliceDto & { powerFrom: number; powerTo: number }
  >({
    containerRef,
    rightTooltip: true,
    renderContent: ({ tooltipData }) => {
      return tooltipData ? (
        <>
          <TooltipHighlightValue>{(tooltipData.activeSeconds / secondsInHour).toFixed(2)} Hrs</TooltipHighlightValue>
          <span>
            {data.distributionType === PowerLoadDistributionType.ActiveGeneratorCount
              ? `# Gens Online: ${tooltipData.id}`
              : data.distributionType === PowerLoadDistributionType.GeneratorIndex
                ? `Gen ${tooltipData.id}`
                : operationClasses.find((operationClass) => operationClass.id === tooltipData.id)?.name}
          </span>
          <span>
            {`${uomTransformer.display(tooltipData.powerFrom, {
              unit: "",
              fractionDigits: 0,
            })} - ${uomTransformer.display(tooltipData.powerTo, { unit: "", fractionDigits: 0 })} ${
              uomTransformer.abbr
            }`}
          </span>
        </>
      ) : null;
    },
  });

  const handleMouseOver = useCallback(
    (barData: RigPowerDistributionBarSliceDto & { powerFrom: number; powerTo: number }, top: number, left: number) => {
      showTooltip({
        tooltipLeft: left,
        tooltipTop: top,
        tooltipData: barData,
      });
    },
    [showTooltip],
  );
  const handleLensUpdate = useMutation({
    mutationFn: (lens: PowerLoadDistributionUserLensDto) => {
      if (!lens) {
        return new Promise((resolve) => resolve(null));
      }

      return powerLoadUserLensApi.apiPowerLoadDistributionUserLensesIdPut({
        id: lens.id,
        powerLoadDistributionUserLensDto: lens,
      });
    },
  });
  const queryClient = useQueryClient();

  const LENS_TITLE = "Power Load Distribution";
  const handlePowerLoadTypeUpdate = async (powerLoadDistributionType: PowerLoadDistributionType) => {
    Track.interact("Evergreen Dashboard - Update Power load distribution type ", {
      lens: lensName,
      lensId: lens.id,
      distributionType: powerLoadDistributionType,
    });

    await handleLensUpdate.mutateAsync({ ...lens, distributionType: powerLoadDistributionType });
    queryClient.invalidateQueries({
      queryKey: [{ type: PDQueryType.FACTS, uid: RequestUID.powerLoadDistributionFacts, lensId: lens.id }],
      exact: false,
    });
    setSelectedDistribution(powerLoadDistributionType);
  };
  const GeneratorMenuItems = () => (
    <StyledCard>
      <StyledOptionRow justify="space-between" align="middle">
        <Option
          onClick={() => {
            setGeneratorMenuVisible(false);
            handlePowerLoadTypeUpdate(PowerLoadDistributionType.ActiveGeneratorCount);
          }}
        >
          Count of Generators Online
        </Option>
      </StyledOptionRow>
      <StyledOptionRow justify="space-between" align="middle">
        <Option
          onClick={() => {
            setGeneratorMenuVisible(false);
            handlePowerLoadTypeUpdate(PowerLoadDistributionType.OperationClasses);
          }}
        >
          Operation Type
        </Option>
      </StyledOptionRow>
      <StyledOptionRow justify="space-between" align="middle">
        <Option
          onClick={() => {
            setGeneratorMenuVisible(false);
            handlePowerLoadTypeUpdate(PowerLoadDistributionType.GeneratorIndex);
          }}
        >
          Generator Number
        </Option>
      </StyledOptionRow>
    </StyledCard>
  );

  const bars = useMemo(() => {
    if (!data?.bars) return null;

    const bars = data.bars.map((bar) => {
      const slices = bar.slices.filter((slice) => (legend === null ? true : legend.includes(slice.id)));
      return {
        ...bar,
        slices,
      };
    });
    return bars;
  }, [data.bars, legend]);
  const axisFontSize = useMemo(() => getAxisFontSize(detailed), [detailed]);
  return (
    <LensLoadingContainer
      key={lens.id}
      dataState={dataState}
      title={LENS_TITLE}
      isDetailed={false}
      LoadedComponent={
        <StyledLensContainerFlex>
          <Row justify="space-between">
            <Col style={{ padding: 24 }}>
              <Row>
                <Title variant="faded" level={3}>
                  {LENS_TITLE}
                </Title>
                <span>&nbsp;</span>
                <StyledDropDown
                  overlay={<GeneratorMenuItems />}
                  placement="bottomLeft"
                  trigger={["click"]}
                  open={isLocked ? false : generatorMenuVisible}
                  onOpenChange={() => {
                    if (!isLocked) setGeneratorMenuVisible(!generatorMenuVisible);
                  }}
                >
                  <Title variant="faded" level={3}>
                    {selectedDistribution === PowerLoadDistributionType.GeneratorIndex
                      ? "/ Gen Number"
                      : selectedDistribution === PowerLoadDistributionType.ActiveGeneratorCount
                        ? "/ # Gens Online"
                        : "/ Operations"}{" "}
                    {isLocked ? null : <PDComponent.SvgIcon name="chevronDown" />}
                  </Title>
                </StyledDropDown>
              </Row>
            </Col>
          </Row>
          <StyledChartContainerFlexDiv>
            <div style={{ height: "auto", flexBasis: "50%", minHeight: 150, flexGrow: 1 }} ref={containerRef}>
              <svg width={chartWidth} height="100%" style={{ overflow: "hidden", userSelect: "none" }}>
                <Grid
                  xScale={xScale}
                  yScale={yDisplayOnlyScale}
                  columnTickValues={tickValues}
                  width={plotWidth}
                  height={chartHeight}
                  stroke={themeColors.primary_chart_accent}
                  strokeWidth={1}
                  strokeOpacity={1}
                  left={LATERAL_AXIS_TEXT_WIDTH * 2}
                  xOffset={-LATERAL_AXIS_TEXT_WIDTH * 3}
                  clipPath={`url(#${CLIP_SERIES_ID})`}
                />
                <RectClipPath
                  y={0}
                  id={CLIP_SERIES_ID}
                  height={plotHeight}
                  width={plotWidth}
                  x={LATERAL_AXIS_WIDTH - 10}
                />

                <Group clipPath={`url(#${CLIP_SERIES_ID})`}>
                  <BarStack<RigPowerDistributionBarDto, number>
                    x={(d) => d.powerFrom.toString()}
                    keys={kpiKeys}
                    value={(d, id) => d.slices.find((slice) => slice.id === id)?.activeSeconds || 0}
                    data={bars ?? []}
                    xScale={xScale}
                    yScale={yScale}
                    color={() => "transparent"}
                  >
                    {(barStacks) => {
                      return barStacks.map((barStack) => (
                        <React.Fragment key={`bar-stack-${barStack.index}`}>
                          {barStack.bars.map((bar) => {
                            if (bar.bar.some((e) => Number.isNaN(e))) return null;
                            const isRounded = barStack.index === barStacks.length - 1;
                            return (
                              <React.Fragment key={`bar-stack-${barStack.index}-${bar.index}-fragment`}>
                                <BarRounded
                                  key={`bar-stack-${barStack.index}-${bar.index}`}
                                  x={bar.x}
                                  y={bar.y}
                                  topLeft={isRounded}
                                  topRight={isRounded}
                                  radius={isRounded ? 2 : 0}
                                  height={bar.height}
                                  width={bar.width}
                                  onMouseOver={() => {
                                    if (!bar?.bar?.data?.slices) return;
                                    const barData = bar.bar.data;
                                    const slices = barData.slices;
                                    const barSliceInfo = slices.find((slice) => slice.id === bar.key);
                                    const barHeight = barSliceInfo?.activeSeconds;
                                    const heightPrev = slices.reduce((accumulator, current) => {
                                      if (current.position < (barSliceInfo?.position || 0)) {
                                        return current.activeSeconds + accumulator;
                                      }
                                      return accumulator;
                                    }, 0);

                                    const top = yScale(heightPrev + (barHeight || 0) / 2);
                                    const left = xScale(barData.powerFrom.toString());
                                    if (barSliceInfo)
                                      handleMouseOver(
                                        {
                                          ...barSliceInfo,
                                          powerFrom: bar.bar.data.powerFrom,
                                          powerTo: bar.bar.data.powerTo,
                                        },
                                        top,
                                        left || 0,
                                      );
                                    return;
                                  }}
                                  onMouseOut={hideTooltip}
                                  fill={getCorrectColor(barStack.key)}
                                />
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      ));
                    }}
                  </BarStack>
                </Group>
                <AxisLeft
                  label={UNIT_LABEL}
                  labelOffset={LATERAL_LABEL_POSITION - LATERAL_AXIS_TEXT_WIDTH}
                  scale={yDisplayOnlyScale}
                  hideTicks
                  hideZero
                  labelProps={{ fill: themeColors.disabled_typography, fontSize: axisFontSize }}
                  left={LATERAL_AXIS_WIDTH - LATERAL_AXIS_TEXT_WIDTH}
                  numTicks={verticalAxisTicksCount}
                  tickComponent={(props) => {
                    if (props.y === 0) return null;
                    return (
                      <StandardTickComponent rendererProps={props} axisLocation={AxisLocation.LEFT}>
                        {(val) => formatTickDecimals(val)}
                      </StandardTickComponent>
                    );
                  }}
                  hideAxisLine
                />

                {/* VISIBLE TIME AXIS */}
                <AxisBottom
                  hideAxisLine
                  hideTicks
                  scale={xScale}
                  top={plotHeight}
                  tickValues={tickValues}
                  left={LATERAL_AXIS_TEXT_WIDTH - LATERAL_AXIS_WIDTH}
                  tickComponent={(props) => {
                    return (
                      <StandardTickComponent rendererProps={props} axisLocation={AxisLocation.BOTTOM}>
                        {(val) => uomTransformer.display(+val, { fractionDigits: 0, unit: "" })}
                      </StandardTickComponent>
                    );
                  }}
                />
                {!lens?.squeezesDisplay && zoomRate > 1 && (
                  <Group top={plotHeight + (detailed ? 62 : 26)} left={scrollbarPadding}>
                    <Scrollbar
                      width={scrollbarWidth}
                      height={4}
                      scale={scrollbarScale}
                      wheelCaptureContainer={containerRef}
                      onScroll={setScrollbarRange}
                      valueSpan={1 / zoomRate}
                    />
                  </Group>
                )}
                {tooltipElement}
              </svg>

              <div
                style={{
                  position: "absolute",
                  top: chartHeight + X_AXIS_HEIGHT + 20,
                  width: chartWidth,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: themeColors.disabled_typography,
                    fontSize: axisFontSize,
                  }}
                >
                  {AVG_UNIT_LABEL}
                </span>
              </div>
            </div>

            <ChartLegend legendItems={legendItems} lensGridSize={[width, height]} />
          </StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
