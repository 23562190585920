import styled from "styled-components";
import colors from "utils/colors";

export const LottieContainer = styled.div`
  display: flex;
  pointer-events: none;
`;

export const LandingPageContainer = styled(LottieContainer)`
  width: 100%;
  height: 100%;
`;

export const BgContainer = styled(LottieContainer)<{ $isWide: boolean }>`
  width: ${(props) => (!props.$isWide ? "100%" : undefined)};
  height: ${(props) => (!props.$isWide ? undefined : "100%")};
  aspect-ratio: 250 / 178;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
`;

export const LogoContainer = styled(LottieContainer)`
  width: 80%;
  height: 100%;
  position: absolute;
  top: -20%;
  left: 35%;
`;

export const TextBox = styled.div`
  position: absolute;
  padding-top: 40px;
  padding-left: 90px;
  max-width: 50%;
  font-family: "Roboto", sans-serif;
`;

export const TopLogo = styled.p`
  color: ${colors.off_state};
  font-size: 1.125em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const PrimaryTitle = styled.p`
  color: ${colors.white};
  font-size: 3.125em;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  padding-top: 50px;
  padding-left: 50px;
  @media screen and (max-width: 1024px) {
    font-size: 2.5em;
    line-height: 120%;
    padding-top: 30px;
    margin-bottom: 20px;
  }
`;

export const SecondaryTitle = styled.p`
  color: ${colors.white};
  font-size: 1.375em;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding-left: 50px;
  @media screen and (max-width: 1024px) {
    font-size: 1.125em;
  }
`;
