import type { StickSlipByDepthUserLensDto } from "apis/oag";
import type { ContainerLensProps } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { useLegendAutoUpdate } from "components/Lenses/ContainerLens/ParameterByDepthKPI/Parts/hooks/useLegendAutoUpdate";
import { StickSlipByDepthChart } from "components/Lenses/ContainerLens/StickSlipByDepth/Chart/StickSlipByDepthChart";
import { useStickSlipByDepthFacts } from "components/Lenses/ContainerLens/StickSlipByDepth/fetcher";
import { Suspense } from "react";
import colors from "utils/colors";

import { StickSlipByDepthLoading } from "./Parts/Loading/Loading";
export const StickSlipByDepth: React.FC<ContainerLensProps> = ({
  detailed,
  graphKey,
  focalWellColor,
  dimension,
  lens,
  selectedTrackItems,
  setSelectedTrackItems,
  onLensUpdated,
}) => {
  const parameterLens = lens;
  const { data, isLoading } = useStickSlipByDepthFacts(parameterLens.id);

  useLegendAutoUpdate({ detailed, data });

  return (
    <Suspense
      fallback={
        <StickSlipByDepthLoading
          title={"..."}
          detailed={detailed}
          selectedTrackItems={selectedTrackItems || []}
          lensId={lens?.id}
        />
      }
    >
      <StickSlipByDepthChart
        detailed={detailed}
        graphKey={graphKey}
        isLoading={isLoading}
        focalWellColor={focalWellColor || colors.well_color}
        dimension={dimension}
        lens={lens as StickSlipByDepthUserLensDto}
        data={data}
        onLensUpdated={onLensUpdated}
        selectedTrackItems={selectedTrackItems}
        setSelectedTrackItems={setSelectedTrackItems}
      />
      
    </Suspense>
  );
};
