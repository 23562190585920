/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WellDrillingSummaryDto } from './WellDrillingSummaryDto';
import {
    WellDrillingSummaryDtoFromJSON,
    WellDrillingSummaryDtoFromJSONTyped,
    WellDrillingSummaryDtoToJSON,
} from './WellDrillingSummaryDto';

/**
 * 
 * @export
 * @interface AllWellsListDto
 */
export interface AllWellsListDto {
    /**
     * 
     * @type {Array<WellDrillingSummaryDto>}
     * @memberof AllWellsListDto
     */
    wells?: Array<WellDrillingSummaryDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllWellsListDto
     */
    operatorIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllWellsListDto
     */
    rigIds?: Array<number> | null;
}

/**
 * Check if a given object implements the AllWellsListDto interface.
 */
export function instanceOfAllWellsListDto(value: object): value is AllWellsListDto {
    return true;
}

export function AllWellsListDtoFromJSON(json: any): AllWellsListDto {
    return AllWellsListDtoFromJSONTyped(json, false);
}

export function AllWellsListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllWellsListDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wells': json['Wells'] == null ? undefined : ((json['Wells'] as Array<any>).map(WellDrillingSummaryDtoFromJSON)),
        'operatorIds': json['OperatorIds'] == null ? undefined : json['OperatorIds'],
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
    };
}

export function AllWellsListDtoToJSON(value?: AllWellsListDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Wells': value['wells'] == null ? undefined : ((value['wells'] as Array<any>).map(WellDrillingSummaryDtoToJSON)),
        'OperatorIds': value['operatorIds'],
        'RigIds': value['rigIds'],
    };
}

