import { useQuery } from "@tanstack/react-query";
import type { UserQueryDto } from "apis/oag";
import { UsersApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDAdminUsersListQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const users = new UsersApi(apiConfig);

export function useAdminUserList() {
  const search = useAppSelector((state) => state.admin.users.search);
  const userStatus = useAppSelector((state) => state.admin.users.userStatus);
  const wells = useAppSelector((state) => state.admin.users.wells);
  const rigs = useAppSelector((state) => state.admin.users.rigs);
  const userRole = useAppSelector((state) => state.admin.users.userRole);
  const operators = useAppSelector((state) => state.admin.users.operators);

  const requestQuery: UserQueryDto = {
    search: search,
    status: userStatus,
    wellIds: wells,
    rigIds: rigs,
    operatorIds: operators,
    role: userRole,
  };

  const queryKey: PDAdminUsersListQueryKey = {
    type: PDQueryType.ADMIN_USERS_LIST,
    uid: RequestUID.adminUsersList,
    params: requestQuery,
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: () =>
      users.apiUsersPut({
        userQueryDto: requestQuery,
      }),
  });
}
