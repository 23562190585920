import type { ApiGeneratorLoadProfileUserLensesIdFactsPutRequest } from "apis/oag/apis/GeneratorLoadProfileUserLensesApi";
import type { GeneratorLoadProfileUserLensDto } from "apis/oag/models/GeneratorLoadProfileUserLensDto";
import { allGenerators } from "components/Lenses/utils";
import { useGeneratorLoadProfileFacts } from "hooks/lens-facts/useGeneratorLoadProfileFacts";
import { useFilterParams } from "hooks/useFilterParams";
import { isNull } from "lodash";

interface UseGeneratorLoadProfileFetcherParams {
  lens: GeneratorLoadProfileUserLensDto;
}

export function useGeneratorLoadProfileFetcher({ lens }: UseGeneratorLoadProfileFetcherParams) {
  const filterParams = useFilterParams();

  const requestParameters: ApiGeneratorLoadProfileUserLensesIdFactsPutRequest = {
    id: lens.id,
    rigPowerQueryDto: {
      ...filterParams,
      selectedGenerators: lens.selectedGenerators ? lens.selectedGenerators : allGenerators,
    },
  };

  const request = useGeneratorLoadProfileFacts(lens.id, requestParameters, {
    enabled: !isNull(lens.id),
    refetchOnMount: false,
  });

  return { request };
}
