import { GRID_HEIGHT_QUARTER, GRID_WIDTH_QUARTER } from "components/Lenses/constants";
import { LensGridContext } from "components/Lenses/utils";
import { useContext } from "react";

export function useLensSize(lensId: number) {
  const { crtLayout, bp } = useContext(LensGridContext);

  const { w: lensWidth, h: lensHeight } = crtLayout?.[bp]?.find(({ i }) => +i === lensId) || {
    w: GRID_WIDTH_QUARTER,
    h: GRID_HEIGHT_QUARTER,
  };

  return [lensWidth, lensHeight] as [number, number];
}
