import type { RigSummaryDto } from "apis/oag";
import { useRigSummaries } from "hooks/useRigSummaries";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

interface SelectedRigsContextValue {
  rigs: RigSummaryDto[] | undefined;
  selectedRigIds: number[];
  setSelectedRigIds: Dispatch<SetStateAction<number[]>>;
  isDataAnonymity: boolean;
  setIsDataAnonymity: Dispatch<SetStateAction<boolean>>;
}

export const SelectedRigsContext = createContext<SelectedRigsContextValue | undefined>(undefined);

export const useSelectedRigsContext = () => {
  const context = useContext(SelectedRigsContext);
  if (context === undefined) {
    throw new Error("useSelectedRigsContext must be used within a SelectedRigsContextProvider");
  }
  return context;
};

export const SelectedRigsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedRigIds, setSelectedRigIds] = useState<number[]>([]);
  const [isDataAnonymity, setIsDataAnonymity] = useState(false);
  const { data: rigs } = useRigSummaries({ staleTime: Infinity });

  return (
    <SelectedRigsContext.Provider value={{ rigs, selectedRigIds, setSelectedRigIds, isDataAnonymity, setIsDataAnonymity }}>
      {children}
    </SelectedRigsContext.Provider>
  );
};
