/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandKpiSliceDto } from './StandKpiSliceDto';
import {
    StandKpiSliceDtoFromJSON,
    StandKpiSliceDtoFromJSONTyped,
    StandKpiSliceDtoToJSON,
} from './StandKpiSliceDto';

/**
 * 
 * @export
 * @interface StackedStandKpiComparisonInfoDto
 */
export interface StackedStandKpiComparisonInfoDto {
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    id: number;
    /**
     * 
     * @type {Array<StandKpiSliceDto>}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    slices?: Array<StandKpiSliceDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    targetAverage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    operationCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof StackedStandKpiComparisonInfoDto
     */
    isOutlier: boolean;
}

/**
 * Check if a given object implements the StackedStandKpiComparisonInfoDto interface.
 */
export function instanceOfStackedStandKpiComparisonInfoDto(value: object): value is StackedStandKpiComparisonInfoDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('average' in value) || value['average'] === undefined) return false;
    if (!('operationCount' in value) || value['operationCount'] === undefined) return false;
    if (!('isOutlier' in value) || value['isOutlier'] === undefined) return false;
    return true;
}

export function StackedStandKpiComparisonInfoDtoFromJSON(json: any): StackedStandKpiComparisonInfoDto {
    return StackedStandKpiComparisonInfoDtoFromJSONTyped(json, false);
}

export function StackedStandKpiComparisonInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackedStandKpiComparisonInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'slices': json['Slices'] == null ? undefined : ((json['Slices'] as Array<any>).map(StandKpiSliceDtoFromJSON)),
        'average': json['Average'],
        'targetAverage': json['TargetAverage'] == null ? undefined : json['TargetAverage'],
        'operationCount': json['OperationCount'],
        'isOutlier': json['IsOutlier'],
    };
}

export function StackedStandKpiComparisonInfoDtoToJSON(value?: StackedStandKpiComparisonInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Slices': value['slices'] == null ? undefined : ((value['slices'] as Array<any>).map(StandKpiSliceDtoToJSON)),
        'Average': value['average'],
        'TargetAverage': value['targetAverage'],
        'OperationCount': value['operationCount'],
        'IsOutlier': value['isOutlier'],
    };
}

