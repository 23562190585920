import { StyledTabs } from "components/Layout/Tabbed";
import { PDComponent } from "components/PDComponents";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Checkbox } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 24px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const ModalWrapper = styled(PDComponent.Modal)`
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_bg};

  ${PdCardBody} {
    width: 600px;
  }
`;

export const SpinContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Description = styled.div`
  width: 100%;
  min-height: 67px;
  border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  line-height: 130%;
`;

export const WellNumber = styled.span`
  font-size: 14px;
  font-weight: 500;
  padding-right: 8px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;
export const WellName = styled.span`
  font-size: 16px;
  font-weight: 500;
  padding-right: 8px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;
export const RigName = styled.span`
  padding-left: 8px;
  font-size: 16px;
  font-weight: 400;
`;

export const Kpi = styled.span<{ $isWhite?: string }>`
  font-size: 14px;
  ${({ $isWhite }) =>
    $isWhite
      ? css`
          color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
        `
      : ""}
`;

export const Scores = styled.div`
  font-size: 12px;
  line-height: 140%;
`;

export const TabsContainer = styled.div`
  overflow: visible;
  .ant-tabs-tab {
    background-color: transparent !important;
    border-color: transparent !important;
    &.ant-tabs-tab-active {
      border-bottom: 4px solid ${({ theme }) => theme.themeStyle.colors.primary_button_bg} !important;
    }
  }
  ${StyledTabs} {
    margin-top: 12px;
  }
`;

export const KpiGroup = styled.div<{ isNegative?: boolean }>`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ theme, isNegative }) =>
    isNegative ? colors.coral_expression : theme.themeStyle.colors.primary_typography};
`;

export const CommentListContainer = styled.div`
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100%);

  ::-webkit-scrollbar {
    width: 4px;
  }
`;

export const CommentIconContainer = styled.div`
  padding: 3px;
`;

export const CommentIcon = styled.div`
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  border-radius: 50%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const StandCommentMain = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

export const DateText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const FadedText = styled.span`
  font-size: 12px;
  padding-left: 6px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CommentHeaderDescription = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
`;
export const CommentHeaderDescriptionRight = styled(CommentHeaderDescription)`
  text-align: right;
`;

export const CommentHeaderControls = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 125px;
  font-size: 12px;
  gap: 11px;
`;

export const Author = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  font-size: 14px;
  font-weight: 500;
`;
export const Text = styled(Author)`
  font-size: 12px;
`;

export const CommentContainer = styled.div<{ $paddingTop: number }>`
  min-height: 50px;
  display: flex;
  padding-top: ${({ $paddingTop }) => $paddingTop}px;
  padding-right: 12px;
  gap: 14px;

  :last-child {
    padding-bottom: 32px;
  }
`;

export const ExistingCommentContents = styled.div`
  white-space: wrap;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  margin-top: 8px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.2px;
`;

export const ExistingComment = styled.div`
  overflow: hidden;
  flex-grow: 1;
`;

export const NewComment = styled.div`
  width: 100%;

  textarea.ant-input {
    height: 77px;
    resize: none;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

export const AddComment = styled.div`
  color: ${colors.well_color} !important;
  font-size: 14px;
  min-height: 40px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const CommentActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  padding-top: 8px;
`;

export const PlusIcon = styled(PDComponent.SvgIcon)`
  height: 24px;
  font-size: 24px;

  &:hover {
    cursor: pointer;
  }
`;

export const AddNoteText = styled.span`
  line-height: 22px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const CommentButton = styled.div<{ color?: string }>`
  user-select: none;
  height: 12px;
  display: flex;
  font-size: 12px;
  border: 0;
  gap: 5px;
  color: ${({ theme, color }) => color || theme.themeStyle.colors.primary_typography};

  &:hover {
    cursor: pointer;
  }
`;

export const CommentActionsLeft = styled.div<{ $isDisabled?: boolean }>`
  ${({ $isDisabled }) => ($isDisabled ? `opacity: 0.35;` : null)}
  width: 100%;
`;

export const CommentActionsRight = styled.div`
  display: flex;
  gap: 4px;
  align-self: flex-end;
`;

export const ExceptionLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  grid-column: 1 / 3;

  &:hover {
    cursor: pointer;
  }
`;

export const ExceptionCheckbox = styled(Checkbox)`
  padding-right: 4px;

  .ant-checkbox {
    top: 0;
  }
`;

export const ExceptionContainer = styled.div<{ $isDisabled?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 80px auto;
  grid-template-rows: auto 1fr;
  padding-right: 16px;
  gap: 8px;
`;

export const InputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DropdownLabel = styled.label`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const ExceptionInfoAboutCheckbox = styled.span`
  font-size: 12px;
  grid-column: 1/3;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const TooltipText = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography} !important;
  width: 300px;
`;

export const NoCommentsContainer = styled.div`
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  h1 {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.2px;
  }
`;

export const StyledSeparator = styled.div`
  ${Kpi}:last-of-type::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 4px;
    background: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    opacity: 0.12;
  }
  ${Kpi}:first-of-type:last-of-type::before {
    display: none;
  }
`;
