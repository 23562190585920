/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';

/**
 * 
 * @export
 * @interface UserLensTabShareDto
 */
export interface UserLensTabShareDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof UserLensTabShareDto
     */
    tabIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UserLensTabShareDto
     */
    recipientProfileId: number;
    /**
     * 
     * @type {DashboardType}
     * @memberof UserLensTabShareDto
     */
    dashboardType: DashboardType;
}



/**
 * Check if a given object implements the UserLensTabShareDto interface.
 */
export function instanceOfUserLensTabShareDto(value: object): value is UserLensTabShareDto {
    if (!('tabIds' in value) || value['tabIds'] === undefined) return false;
    if (!('recipientProfileId' in value) || value['recipientProfileId'] === undefined) return false;
    if (!('dashboardType' in value) || value['dashboardType'] === undefined) return false;
    return true;
}

export function UserLensTabShareDtoFromJSON(json: any): UserLensTabShareDto {
    return UserLensTabShareDtoFromJSONTyped(json, false);
}

export function UserLensTabShareDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLensTabShareDto {
    if (json == null) {
        return json;
    }
    return {
        
        'tabIds': json['TabIds'],
        'recipientProfileId': json['RecipientProfileId'],
        'dashboardType': DashboardTypeFromJSON(json['DashboardType']),
    };
}

export function UserLensTabShareDtoToJSON(value?: UserLensTabShareDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'TabIds': value['tabIds'],
        'RecipientProfileId': value['recipientProfileId'],
        'DashboardType': DashboardTypeToJSON(value['dashboardType']),
    };
}

