/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';

/**
 * 
 * @export
 * @interface LensTabCreationQueryDto
 */
export interface LensTabCreationQueryDto {
    /**
     * 
     * @type {DashboardType}
     * @memberof LensTabCreationQueryDto
     */
    dashboardType: DashboardType;
    /**
     * 
     * @type {string}
     * @memberof LensTabCreationQueryDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LensTabCreationQueryDto
     */
    copySourceTabId?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LensTabCreationQueryDto
     */
    visibilityRigIds?: Array<number> | null;
}



/**
 * Check if a given object implements the LensTabCreationQueryDto interface.
 */
export function instanceOfLensTabCreationQueryDto(value: object): value is LensTabCreationQueryDto {
    if (!('dashboardType' in value) || value['dashboardType'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function LensTabCreationQueryDtoFromJSON(json: any): LensTabCreationQueryDto {
    return LensTabCreationQueryDtoFromJSONTyped(json, false);
}

export function LensTabCreationQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LensTabCreationQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dashboardType': DashboardTypeFromJSON(json['DashboardType']),
        'name': json['Name'],
        'copySourceTabId': json['CopySourceTabId'] == null ? undefined : json['CopySourceTabId'],
        'visibilityRigIds': json['VisibilityRigIds'] == null ? undefined : json['VisibilityRigIds'],
    };
}

export function LensTabCreationQueryDtoToJSON(value?: LensTabCreationQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'DashboardType': DashboardTypeToJSON(value['dashboardType']),
        'Name': value['name'],
        'CopySourceTabId': value['copySourceTabId'],
        'VisibilityRigIds': value['visibilityRigIds'],
    };
}

