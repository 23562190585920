import { Button } from "atoms/Form";
import styled from "styled-components";
import colors from "utils/colors";
import { Radio, Typography } from "utils/componentLibrary";

export const StyledButton = styled(Button)`
  &,
  &:not(.ant-btn-primary) {
    background-color: transparent !important;
    border: none !important;
    padding: 0px;
  }
  span {
    font-size: 12px;
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
    padding: 1px;
  }
  &:hover {
    span {
      color: ${({ theme }) => theme.themeStyle.colors.black_white};
    }
  }
  .pd-icon {
    font-size: 12px;
    margin-left: 0px !important;
  }
`;

export const StyledTitle = styled(Typography.Title).attrs({ level: 5 })`
  &.ant-typography {
    font-size: 16px;
    color: ${(props) => props.theme.themeStyle.colors.tertiary_typography};
  }
  margin-top: 0px;
  margin-bottom: 0 !important;
  line-height: 24px !important;
`;

export const StyledSubtitle = styled(Typography.Text)`
  cursor: default;
  &.ant-typography {
    font-size: 12px;
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  }
`;

export const StyledDescription = styled(Typography.Paragraph)<{ $isDeactivated: boolean }>`
  &.ant-typography {
    font-size: 14px;
    color: ${(props) =>
      props.$isDeactivated
        ? props.theme.themeStyle.colors.disabled_typography
        : props.theme.themeStyle.colors.tertiary_typography};
  }
  margin: 0 !important;
  line-height: 26px !important;
`;
export const ButtonWrapper = styled(Button)`
  height: 31px !important;
  width: 42px !important;
  margin: 0px !important;
  background-color: red !important;
  padding: 0px !important;
  border-radius: 100%;
  .pd-icon {
    font-size: 14px;
  }
  border: none;
`;

export const StyledForm = styled.form<{ error: string }>`
  margin-bottom: 24px !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: ${(props) => (props.error === "null" ? "none" : "8px")};
  width: calc(100% - 20px);
  .ant-picker {
    background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
    .ant-picker-input > input {
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    }
  }

  textarea {
    border-radius: 8px 8px 0 0 !important;
    border: none;
    padding-left: 12px;
    padding-top: 12px;
    height: 80px;
    resize: none;
    border: none !important;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.quaterniary_bg} !important;
    box-shadow: none !important;
  }

  & .ant-form-item-control-input-content > .ant-input-affix-wrapper {
    border-radius: 0 0 8px 8px !important;
    border: none !important;
    border-bottom: 1px solid ${({ theme }) => theme.themeStyle.colors.quaterniary_bg} !important;
    box-shadow: none !important;
    padding-bottom: 8px;
    &,
    & .ant-input {
      background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg} !important;
      color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
    }
  }
  .ant-radio-button-wrapper {
    background: transparent !important;
    border-color: transparent !important;
    outline: none !important;
    padding: 0 8px !important;

    &:focus-within {
      box-shadow: none !important;
    }
    &::before {
      background-color: unset !important;
    }
  }
  .ant-radio-button {
    margin-top: 2px;
  }
`;

export const RadioButtonGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper,
  .ant-radio-button {
    background-color: transparent !important;
  }

  .ant-radio-button-wrapper .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.primary_scroll_bg} !important;
  }
  .ant-radio-button-wrapper-checked .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  }
`;

export const EventBoxRelative = styled.div<{ width: number }>`
  position: relative;
  width: ${({ width }) => width - 50}px;
`;

export const EventIconSelector = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Separator = styled.div`
  height: 14px;
  align-self: center;
  border-left: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
`;

export const EventButton = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

export const EventBox = styled.div<{ width?: number }>`
  width: ${({ width }) => width}px;
  height: 31px;
  margin: 0 0 0 10px;
  border-radius: 8px;
  display: flex;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_chart_bg};
  box-shadow: 0 1px 2px 0 ${colors.buttons_clicked};
`;

export const EditedEventContainer = styled.div`
  position: absolute;
  width: calc(100% - 10px);
  top: -15px;
  left: 22px;
`;
