/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlanDrillingParameterDto } from './PlanDrillingParameterDto';
import {
    PlanDrillingParameterDtoFromJSON,
    PlanDrillingParameterDtoFromJSONTyped,
    PlanDrillingParameterDtoToJSON,
} from './PlanDrillingParameterDto';

/**
 * 
 * @export
 * @interface PlanDrillingParameterDtoListAuditTrailVersionDto
 */
export interface PlanDrillingParameterDtoListAuditTrailVersionDto {
    /**
     * 
     * @type {Date}
     * @memberof PlanDrillingParameterDtoListAuditTrailVersionDto
     */
    asOf: Date;
    /**
     * 
     * @type {number}
     * @memberof PlanDrillingParameterDtoListAuditTrailVersionDto
     */
    authorId: number;
    /**
     * 
     * @type {Array<PlanDrillingParameterDto>}
     * @memberof PlanDrillingParameterDtoListAuditTrailVersionDto
     */
    value?: Array<PlanDrillingParameterDto> | null;
}

/**
 * Check if a given object implements the PlanDrillingParameterDtoListAuditTrailVersionDto interface.
 */
export function instanceOfPlanDrillingParameterDtoListAuditTrailVersionDto(value: object): value is PlanDrillingParameterDtoListAuditTrailVersionDto {
    if (!('asOf' in value) || value['asOf'] === undefined) return false;
    if (!('authorId' in value) || value['authorId'] === undefined) return false;
    return true;
}

export function PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSON(json: any): PlanDrillingParameterDtoListAuditTrailVersionDto {
    return PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSONTyped(json, false);
}

export function PlanDrillingParameterDtoListAuditTrailVersionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDrillingParameterDtoListAuditTrailVersionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'asOf': (new Date(json['AsOf'])),
        'authorId': json['AuthorId'],
        'value': json['Value'] == null ? undefined : ((json['Value'] as Array<any>).map(PlanDrillingParameterDtoFromJSON)),
    };
}

export function PlanDrillingParameterDtoListAuditTrailVersionDtoToJSON(value?: PlanDrillingParameterDtoListAuditTrailVersionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'AsOf': ((value['asOf']).toISOString()),
        'AuthorId': value['authorId'],
        'Value': value['value'] == null ? undefined : ((value['value'] as Array<any>).map(PlanDrillingParameterDtoToJSON)),
    };
}

