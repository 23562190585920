/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScoreComplianceStateType } from './ScoreComplianceStateType';
import {
    ScoreComplianceStateTypeFromJSON,
    ScoreComplianceStateTypeFromJSONTyped,
    ScoreComplianceStateTypeToJSON,
} from './ScoreComplianceStateType';

/**
 * 
 * @export
 * @interface WellBarDto
 */
export interface WellBarDto {
    /**
     * 
     * @type {number}
     * @memberof WellBarDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WellBarDto
     */
    operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellBarDto
     */
    rigScore?: number | null;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof WellBarDto
     */
    operatorScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof WellBarDto
     */
    rigScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof WellBarDto
     */
    improvementScoreComplianceState: ScoreComplianceStateType;
}



/**
 * Check if a given object implements the WellBarDto interface.
 */
export function instanceOfWellBarDto(value: object): value is WellBarDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('operatorScoreComplianceState' in value) || value['operatorScoreComplianceState'] === undefined) return false;
    if (!('rigScoreComplianceState' in value) || value['rigScoreComplianceState'] === undefined) return false;
    if (!('improvementScoreComplianceState' in value) || value['improvementScoreComplianceState'] === undefined) return false;
    return true;
}

export function WellBarDtoFromJSON(json: any): WellBarDto {
    return WellBarDtoFromJSONTyped(json, false);
}

export function WellBarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellBarDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'operatorScore': json['OperatorScore'] == null ? undefined : json['OperatorScore'],
        'rigScore': json['RigScore'] == null ? undefined : json['RigScore'],
        'operatorScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['OperatorScoreComplianceState']),
        'rigScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['RigScoreComplianceState']),
        'improvementScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['ImprovementScoreComplianceState']),
    };
}

export function WellBarDtoToJSON(value?: WellBarDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'OperatorScore': value['operatorScore'],
        'RigScore': value['rigScore'],
        'OperatorScoreComplianceState': ScoreComplianceStateTypeToJSON(value['operatorScoreComplianceState']),
        'RigScoreComplianceState': ScoreComplianceStateTypeToJSON(value['rigScoreComplianceState']),
        'ImprovementScoreComplianceState': ScoreComplianceStateTypeToJSON(value['improvementScoreComplianceState']),
    };
}

