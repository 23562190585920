import type { PlanActivityDto, PlanDrillingParameterDto, PlanFormationDto } from "apis/oag";
import { IUnitSystem } from "reducers/types";
import { secondsInDay, secondsInHour } from "utils/common";
import type { ISelectData, IValueWithUnit } from "utils/interfaces/Well/interfaces";

export const timeData: { [index: string]: ISelectData["time"] } = {
  hours: {
    value: "Hours",
    abv: "h",
    toSI: (timeInSeconds: string) => Math.round(Number(timeInSeconds) * secondsInHour),
    toString: (timeInSeconds: number) => (timeInSeconds / secondsInHour)?.toFixed(2) || "",
  },
  days: {
    value: "Days",
    abv: "Days",
    toSI: (timeInSeconds: string) => Math.round(Number(timeInSeconds) * secondsInDay),
    toString: (timeInSeconds: number) => (timeInSeconds / secondsInDay)?.toFixed(2) || "",
  },
};

export const depthData: { [index: string]: IValueWithUnit } = {
  centimeters: {
    value: "Centimeters",
    abv: "cm",
    unitSystem: IUnitSystem.METRIC,
    toSI: (depthInCentimeters: string) => Number(depthInCentimeters) / 100,
    toString: (depthInCentimeters: number) => (depthInCentimeters * 100)?.toFixed(2) || "",
  },
  meters: {
    value: "Meters",
    abv: "m",
    unitSystem: IUnitSystem.METRIC,
    toSI: (depthInMeters: string) => Number(depthInMeters),
    toString: (depthInMeters: number) => depthInMeters?.toFixed(2) || "",
  },
  kilometers: {
    value: "Kilometers",
    abv: "km",
    unitSystem: IUnitSystem.METRIC,
    toSI: (depthInKilometers: string) => Number(depthInKilometers) * 1000,
    toString: (depthInKilometers: number) => (depthInKilometers / 1000)?.toFixed(2) || "",
  },
  feet: {
    value: "Feet",
    abv: "ft",
    unitSystem: IUnitSystem.IMPERIAL,
    toSI: (depthInFeet: string) => Number(depthInFeet) * 0.3048,
    toString: (depthInMeters: number) => (depthInMeters / 0.3048)?.toFixed(2) || "",
  },
};

export const wobData: { [index: string]: IValueWithUnit } = {
  klbs: {
    value: "Kilopounds",
    abv: "klbsf",
    unitSystem: IUnitSystem.IMPERIAL,
    toSI: (klbsf: string) => Number(klbsf) * 4448.2216,
    toString: (klbsf: number) => (klbsf / 4448.2216)?.toFixed(2) || "",
  },
  kdan: {
    value: "Kilodekanewtons",
    abv: "kDaN",
    unitSystem: IUnitSystem.METRIC,
    toSI: (kdan: string) => Number(kdan) * 1e4,
    toString: (kdan: number) => (kdan / 1e4)?.toFixed(2) || "",
  },
};

export const rotationData: { [index: string]: IValueWithUnit } = {
  rpm: {
    value: "Rotations per minute",
    abv: "rpm",
    toSI: (rpm: string) => Number(rpm) / 60,
    toString: (rpm: number) => (rpm * 60)?.toFixed(2) ?? "",
  },
};

export const speedData: { [index: string]: IValueWithUnit } = {
  mhr: {
    value: "Meters per hour",
    abv: "m/hr",
    unitSystem: IUnitSystem.METRIC,
    toSI: (mhr: string) => Number(mhr) / 3600,
    toString: (mhr: number) => (mhr * 3600)?.toFixed(2) || "" || "",
  },
  fthr: {
    value: "Feet per hour",
    abv: "ft/hr",
    unitSystem: IUnitSystem.IMPERIAL,
    toSI: (fthr: string) => Number(fthr) / 11811.023622047,
    toString: (fthr: number) => (fthr * 11811.023622047)?.toFixed(2) || "",
  },
};

export const pressureData: { [index: string]: IValueWithUnit } = {
  psi: {
    value: "Pound per square inch",
    abv: "psi",
    unitSystem: IUnitSystem.IMPERIAL,
    toSI: (psi: string) => Number(psi) * 6894.7572931783,
    toString: (psi: number) => (psi / 6894.7572931783)?.toFixed(2) || "",
  },
  kpa: {
    value: "Kilopascals",
    abv: "kPa",
    unitSystem: IUnitSystem.METRIC,
    toSI: (kpa: string) => Number(kpa) * 1000,
    toString: (kpa: number) => (kpa / 1000)?.toFixed(2) || "",
  },
};

export const torqueData: { [index: string]: IValueWithUnit } = {
  ftlb: {
    value: "Feet per pound",
    abv: "ft-lbf",
    unitSystem: IUnitSystem.IMPERIAL,
    toSI: (ftlb: string) => Number(ftlb) * 1.3558179483,
    toString: (ftlb: number) => (ftlb / 1.3558179483)?.toFixed(2) || "",
  },
  nm: {
    value: "Newton meter",
    abv: "Nm",
    unitSystem: IUnitSystem.METRIC,
    toSI: (nm: string) => Number(nm),
    toString: (nm: number) => nm?.toFixed(2) || "",
  },
};

export const flowData: { [index: string]: IValueWithUnit } = {
  m3min: {
    value: "Cubic meters per minute",
    abv: "m3/min",
    unitSystem: IUnitSystem.METRIC,
    toSI: (val: string) => Number(val) / 60,
    toString: (val: number) => (val * 60)?.toFixed(2) || "",
  },
  gpm: {
    value: "Gallons per minute",
    abv: "gpm",
    unitSystem: IUnitSystem.IMPERIAL,
    toSI: (val: string) => Number(val) / 15850.323141489,
    toString: (val: number) => (val * 15850.323141489)?.toFixed(2) || "",
  },
};

export const SSSIData: { [index: string]: IValueWithUnit } = {
  SSSI: {
    value: "SSSI",
    abv: "SSSI",
    toSI: (val: string) => +val,
    toString: (val: number) => val?.toFixed(3) || "",
  },
};

export const SSSICumulativeData: { [index: string]: IValueWithUnit } = {
  SSSICumulative: {
    value: "SSSI Cumulative",
    abv: "SSSI",
    toSI: (val: string) => +val,
    toString: (val: number) => val?.toFixed(2) || "",
    measureType: "Sum",
  },
};

// For now just as a placeholder to not crash
export const ZTData: { [index: string]: IValueWithUnit } = {
  ZTData: {
    value: "ZT On",
    abv: "%",
    toSI: (val: string) => +val,
    toString: (val: number) => (val * 100)?.toFixed(2) || "",
    measureType: "%",
  },
};

// Catching NaN as well
export const isNullIshValueNotZero = (n: string | number) => n !== 0 && !n;
export const isNotEmptyRow = (
  obj: PlanFormationDto | PlanDrillingParameterDto | PlanActivityDto,
  { defaultValues, ignoredValues }: { defaultValues?: unknown; ignoredValues?: Array<string> },
) => {
  let empty = false;
  Object.keys(obj).forEach((key) => {
    if (ignoredValues?.includes(key)) return;

    if (
      defaultValues &&
      Object.keys(defaultValues).includes(key) &&
      defaultValues[key as keyof typeof defaultValues] === obj[key as keyof typeof obj]
    )
      return;
    if (key !== "id" && !isNullIshValueNotZero(obj[key as keyof typeof obj] as string | number) && obj[key as keyof typeof obj] > 0) {
      empty = true;
    }
  });
  return empty;
};
