/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DecimalRange
 */
export interface DecimalRange {
    /**
     * 
     * @type {number}
     * @memberof DecimalRange
     */
    min: number;
    /**
     * 
     * @type {number}
     * @memberof DecimalRange
     */
    max: number;
}

/**
 * Check if a given object implements the DecimalRange interface.
 */
export function instanceOfDecimalRange(value: object): value is DecimalRange {
    if (!('min' in value) || value['min'] === undefined) return false;
    if (!('max' in value) || value['max'] === undefined) return false;
    return true;
}

export function DecimalRangeFromJSON(json: any): DecimalRange {
    return DecimalRangeFromJSONTyped(json, false);
}

export function DecimalRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecimalRange {
    if (json == null) {
        return json;
    }
    return {
        
        'min': json['Min'],
        'max': json['Max'],
    };
}

export function DecimalRangeToJSON(value?: DecimalRange | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Min': value['min'],
        'Max': value['max'],
    };
}

