/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParameterByDepthUserLensTrackItemDto } from './ParameterByDepthUserLensTrackItemDto';
import {
    ParameterByDepthUserLensTrackItemDtoFromJSON,
    ParameterByDepthUserLensTrackItemDtoFromJSONTyped,
    ParameterByDepthUserLensTrackItemDtoToJSON,
} from './ParameterByDepthUserLensTrackItemDto';

/**
 * 
 * @export
 * @interface ParameterByDepthUserLensDto
 */
export interface ParameterByDepthUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterByDepthUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterByDepthUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterByDepthUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterByDepthUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterByDepthUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {Array<ParameterByDepthUserLensTrackItemDto>}
     * @memberof ParameterByDepthUserLensDto
     */
    userLensTrackItems: Array<ParameterByDepthUserLensTrackItemDto>;
}

/**
 * Check if a given object implements the ParameterByDepthUserLensDto interface.
 */
export function instanceOfParameterByDepthUserLensDto(value: object): value is ParameterByDepthUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('squeezesDisplay' in value) || value['squeezesDisplay'] === undefined) return false;
    if (!('userLensTrackItems' in value) || value['userLensTrackItems'] === undefined) return false;
    return true;
}

export function ParameterByDepthUserLensDtoFromJSON(json: any): ParameterByDepthUserLensDto {
    return ParameterByDepthUserLensDtoFromJSONTyped(json, false);
}

export function ParameterByDepthUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterByDepthUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'userLensTrackItems': ((json['UserLensTrackItems'] as Array<any>).map(ParameterByDepthUserLensTrackItemDtoFromJSON)),
    };
}

export function ParameterByDepthUserLensDtoToJSON(value?: ParameterByDepthUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'SqueezesDisplay': value['squeezesDisplay'],
        'UserLensTrackItems': ((value['userLensTrackItems'] as Array<any>).map(ParameterByDepthUserLensTrackItemDtoToJSON)),
    };
}

