import type { UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { UserDto } from "apis/oag";
import { ClarityUiType, UserRoleType, UsersApi, UserStatusType } from "apis/oag";
import { CustomSwitch } from "atoms/common";
import { Button, DatePicker, FormItem, Input } from "atoms/Form";
import { Select } from "atoms/Form";
import { toast } from "atoms/toast";
import { Title } from "atoms/Typography";
import { ActionsHeader, PlanSectionLayout } from "components/Layout/Tabbed";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import Pane from "components/WellEditor/Pane";
import dayjs from "dayjs";
import { useAdminSingleUserAccessInfo } from "hooks/admin/useAdminSingleUserAccessInfo";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useOperators } from "hooks/useOperators";
import type { UserFormValues } from "pages/Admin/Users/Editor";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { apiConfig } from "utils/apiConfig";
import { Col, Row, Space } from "utils/componentLibrary";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

const users = new UsersApi(apiConfig);

const InputContainer = styled(Row).attrs({
  gutter: 8,
  align: "middle",
  wrap: false,
})`
  width: 100%;
`;

const InfoContainer = styled(Space).attrs({
  size: 12,
  direction: "vertical",
})`
  width: 100%;
  > * {
    width: 100%;
  }
`;

const Overview = ({
  mode,
  singleUser,
  userId,
  formValues,
  hasFeedback,
  setFormValues,
}: {
  mode: "edit" | "create";
  singleUser: UseQueryResult<UserDto, unknown>;
  hasFeedback: boolean;
  userId: number;
  formValues: UserFormValues | null;
  setFormValues: (values: UserFormValues) => void;
}) => {
  const operators = useOperators();
  const queryClient = useQueryClient();

  const accessInfo = useAdminSingleUserAccessInfo({ userId }, mode === "edit");
  const { data: userData } = useCurrentUser();

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  const isLoading = useMemo(
    () => operators.isLoading || (mode === "edit" ? accessInfo.isLoading || singleUser.isLoading : false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessInfo.isLoading, operators.isLoading, singleUser.isLoading],
  );
  const { atomThemeVariant } = useCustomTheme();

  const toggleActiveStatus = useMutation({
    mutationFn: () => {
      return users.apiUsersIdStatusPut({
        id: singleUser.data?.id ?? 0,
        body: singleUser.data?.status === UserStatusType.Active ? UserStatusType.Inactive : UserStatusType.Active,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.ADMIN_USERS_SINGLE }], exact: false });
    },
    onSuccess() {
      toast.success({ message: "User updated." });
    },
    onError() {
      toast.error({ message: "Could not update user!" });
    },
  });

  const getInvitationLink = useMutation({
    mutationFn: () => {
      return users.apiUsersEmailWelcomeLinkGet({
        userId: singleUser.data?.id,
      });
    },
    onSuccess(link) {
      navigator.clipboard.writeText(link);
      toast.success({ message: "Link copied in your clipboard." });
    },
    onError() {
      toast.error({ message: "Could not get invitation link!" });
    },
  });

  const sendInvitations = useMutation({
    mutationFn: () => {
      return users.apiUsersEmailWelcomeResendGet({
        userId: singleUser.data?.id,
      });
    },
    ...{
      onSuccess() {
        toast.success({ message: "Invitation sent." });
      },
      onError() {
        toast.error({ message: "Could not send invitation!" });
      },
    },
  });

  const validateStatus = useCallback(
    (field?: string | number) => {
      if (!hasFeedback) return undefined;
      if (!field) return "error";
      return "success";
    },
    [hasFeedback],
  );

  const canEditUser = useMemo(() => {
    if (mode === "edit" && singleUser.data?.role !== UserRoleType.Administrator && userData?.id !== singleUser.data?.id)
      return true;
    return false;
  }, [mode, singleUser.data, userData?.id]);

  const canSendEmail = useMemo(() => {
    if (singleUser.data?.status === UserStatusType.Active && mode === "edit" && userData?.id !== singleUser.data?.id)
      return true;
    return false;
  }, [mode, singleUser.data?.id, userData?.id, singleUser.data?.status]);

  const canSelectClaraEndpoint = useMemo(() => {
    return formValues?.access.canSelectClaraEndpoint && formValues?.access.canAccessRigScorecard;
  }, [formValues?.access.canAccessRigScorecard, formValues?.access.canSelectClaraEndpoint]);

  return (
    <PlanSectionLayout
      header={
        <ActionsHeader>
          <Row gutter={12} justify="space-between" align="middle" style={{ height: "64px" }}>
            <Col flex="0 auto">
              <Title level={3} variant={atomThemeVariant}>
                {mode === "create" && "Create User"}
                {mode === "edit" && singleUser.data?.createdAtUtc
                  ? `Created on ${dayjs(singleUser.data.createdAtUtc).format("MM/DD/YYYY · h:mm A")}`
                  : ""}
              </Title>
            </Col>
            <Col flex="0 auto">
              {!singleUser.isLoading && (
                <Space>
                  {canSendEmail ? (
                    <Button
                      icon={<PDComponent.SvgIcon name="copy" />}
                      loading={getInvitationLink.isPending}
                      onClick={() => getInvitationLink.mutate()}
                    >
                      Copy Invitation Link
                    </Button>
                  ) : null}
                  {canSendEmail ? (
                    <Button
                      icon={<PDComponent.SvgIcon name="email" />}
                      loading={sendInvitations.isPending}
                      onClick={() => sendInvitations.mutate()}
                    >
                      Resend Invitation
                    </Button>
                  ) : null}
                  {canEditUser ? (
                    <Button
                      icon={
                        singleUser.data?.status === UserStatusType.Active ? (
                          <PDComponent.SvgIcon name="stopFilled" />
                        ) : (
                          <PDComponent.SvgIcon name="playFilled" />
                        )
                      }
                      loading={toggleActiveStatus.isPending}
                      onClick={() => toggleActiveStatus.mutate()}
                    >
                      Set User {singleUser.data?.status === UserStatusType.Active ? "Inactive" : "Active"}
                    </Button>
                  ) : null}
                </Space>
              )}
            </Col>
          </Row>
        </ActionsHeader>
      }
      content={
        <form>
          {isLoading || formValues === null ? (
            <Loader centered />
          ) : (
            <Row gutter={[16, 16]} wrap={false}>
              <Col span={14}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Pane title="Main Information">
                      <InfoContainer>
                        <Col span={24}>
                          <InputContainer>
                            <Col span={4}>
                              <Title level={3} variant={atomThemeVariant}>
                                Name
                              </Title>
                            </Col>
                            <Col span={10}>
                              <FormItem validateStatus={validateStatus(formValues.account.firstName)}>
                                <Input
                                  value={formValues.account.firstName}
                                  onChange={(ev) => {
                                    setFormValues({
                                      ...formValues,
                                      account: {
                                        ...formValues.account,
                                        firstName: ev.target.value,
                                      },
                                    });
                                  }}
                                  placeholder={isLoading ? "Loading.." : "First Name"}
                                />
                              </FormItem>
                            </Col>
                            <Col span={10}>
                              <FormItem validateStatus={validateStatus(formValues.account.lastName)}>
                                <Input
                                  value={formValues.account.lastName}
                                  onChange={(ev) => {
                                    setFormValues({
                                      ...formValues,
                                      account: {
                                        ...formValues.account,
                                        lastName: ev.target.value,
                                      },
                                    });
                                  }}
                                  placeholder={isLoading ? "Loading.." : "Last Name"}
                                />
                              </FormItem>
                            </Col>
                          </InputContainer>
                        </Col>
                        <Col span={24}>
                          <InputContainer>
                            <Col span={4}>
                              <Title level={3} variant={atomThemeVariant}>
                                Email
                              </Title>
                            </Col>
                            <Col span={20}>
                              <FormItem
                                validateStatus={
                                  mode === "edit" ? undefined : validateStatus(formValues.account.userName)
                                }
                              >
                                <Input
                                  placeholder="Email"
                                  disabled={mode === "edit"}
                                  value={formValues.account.userName}
                                  onChange={(ev) => {
                                    setFormValues({
                                      ...formValues,
                                      account: {
                                        ...formValues.account,
                                        userName: ev.target.value,
                                      },
                                    });
                                  }}
                                />
                              </FormItem>
                            </Col>
                          </InputContainer>
                        </Col>
                        <Col span={24}>
                          <InputContainer>
                            <Col span={4}>
                              <Title level={3} variant={atomThemeVariant}>
                                Account
                              </Title>
                            </Col>
                            <Col span={20}>
                              <FormItem validateStatus={validateStatus(formValues.account.operatorId)}>
                                <Select
                                  placeholder="Account"
                                  options={(operators.data?.list ?? []).map((e) => ({ value: e.id, label: e.name }))}
                                  showSearch
                                  optionFilterProp="label"
                                  value={formValues.account.operatorId}
                                  onChange={(ev) => {
                                    setFormValues({
                                      ...formValues,
                                      account: {
                                        ...formValues.account,
                                        operatorId: ev as number,
                                      },
                                    });
                                  }}
                                />
                              </FormItem>
                            </Col>
                          </InputContainer>
                        </Col>
                        <Col span={24}>
                          <InputContainer>
                            <Col span={4}>
                              <Title level={3} variant={atomThemeVariant}>
                                Role
                              </Title>
                            </Col>
                            <Col span={20}>
                              <FormItem validateStatus={validateStatus(formValues.account.role)}>
                                <Select
                                  placeholder="Role"
                                  options={Object.keys(UserRoleType)
                                    .filter((e) => e === "OptimizationEngineer" || e === "General")
                                    .map((e) => ({
                                      value: e,
                                      label: e === "OptimizationEngineer" ? "Optimization Engineer" : e,
                                    }))}
                                  value={formValues.account.role}
                                  onChange={(ev) => {
                                    setFormValues({
                                      ...formValues,
                                      account: {
                                        ...formValues.account,
                                        role: ev as UserRoleType,
                                      },
                                    });
                                  }}
                                />
                              </FormItem>
                            </Col>
                          </InputContainer>
                        </Col>
                        <Col span={24}>
                          <InputContainer>
                            <Col span={4}>
                              <Title level={3} variant={atomThemeVariant}>
                                Job Title
                              </Title>
                            </Col>
                            <Col span={20}>
                              <Input
                                placeholder="Job Title"
                                value={formValues.account.jobTitle}
                                onChange={(ev) => {
                                  setFormValues({
                                    ...formValues,
                                    account: {
                                      ...formValues.account,
                                      jobTitle: ev.target.value,
                                    },
                                  });
                                }}
                              />
                            </Col>
                          </InputContainer>
                        </Col>
                      </InfoContainer>
                    </Pane>
                  </Col>
                  <Col span={24}>
                    <Pane title="Invitation Link">
                      <InfoContainer>
                        <Col span={24}>
                          <InputContainer>
                            <Col span={4}>
                              <Title level={3} variant={atomThemeVariant}>
                                First Access
                              </Title>
                            </Col>
                            <Col span={20}>
                              <FormItem>
                                <Select
                                  placeholder="Preferred Client"
                                  options={Object.keys(ClarityUiType).map((e) => ({
                                    value: e,
                                    label: e === "Pwa" ? "Clarity Mobile" : "Clarity Web",
                                  }))}
                                  value={formValues.account.uiType}
                                  onChange={(ev) => {
                                    setFormValues({
                                      ...formValues,
                                      account: {
                                        ...formValues.account,
                                        uiType: ev as ClarityUiType,
                                      },
                                    });
                                  }}
                                />
                              </FormItem>
                            </Col>
                          </InputContainer>
                        </Col>
                      </InfoContainer>
                    </Pane>
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Pane title="Access Information">
                  <InfoContainer>
                    <Col span={24}>
                      <InputContainer>
                        <Col span={8}>
                          <Title level={3} variant={atomThemeVariant}>
                            Status
                          </Title>
                        </Col>
                        <Col span={16}>
                          <FormItem>
                            <Input placeholder="Status" value={formValues.account.status} disabled />
                          </FormItem>
                        </Col>
                      </InputContainer>
                    </Col>
                    <Col span={24}>
                      <InputContainer>
                        <Col span={8}>
                          <Title level={3} variant={atomThemeVariant}>
                            Expiration Date
                          </Title>
                        </Col>
                        <Col span={16}>
                          <FormItem
                            style={{
                              border: `1px solid ${themeColors.primary_accent}`,
                              borderRadius: "4px",
                            }}
                          >
                            <DatePicker
                              value={
                                formValues.account.expirationDate ? dayjs(formValues.account.expirationDate) : undefined
                              }
                              onChange={(ev) => {
                                setFormValues({
                                  ...formValues,
                                  account: {
                                    ...formValues.account,
                                    expirationDate: ev ? ev.toDate() : null,
                                  },
                                });
                              }}
                            />
                          </FormItem>
                        </Col>
                      </InputContainer>
                    </Col>
                    <Col span={24}>
                      <InputContainer>
                        <Col span={8}>
                          <Title level={3} variant={atomThemeVariant}>
                            Rig Access
                          </Title>
                        </Col>
                        <Col span={16}>
                          <FormItem>
                            <Input placeholder="Rig Access" value={formValues.access.claimedRigCount} disabled />
                          </FormItem>
                        </Col>
                      </InputContainer>
                    </Col>
                    <Col span={24}>
                      <InputContainer>
                        <Col span={8}>
                          <Title level={3} variant={atomThemeVariant}>
                            Well Access
                          </Title>
                        </Col>
                        <Col span={16}>
                          <FormItem>
                            <Input placeholder="Well Access" value={formValues.access.claimedWellCount} disabled />
                          </FormItem>
                        </Col>
                      </InputContainer>
                    </Col>
                    <Col span={24}>
                      <InputContainer>
                        <Col span={8}>
                          <Title level={3} variant={atomThemeVariant}>
                            Driller Names
                          </Title>
                        </Col>
                        <Col span={2}>
                          <FormItem>
                            <CustomSwitch
                              $isChecked={!!formValues.access.canAccessCrew}
                              checked={formValues.access.canAccessCrew}
                              onChange={(e) =>
                                setFormValues({ ...formValues, access: { ...formValues.access, canAccessCrew: e } })
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col
                          span={12}
                          style={{
                            paddingLeft: 16,
                          }}
                        >
                          <Title level={5} variant={formValues.access.canAccessCrew ? atomThemeVariant : "faded"}>
                            {formValues.access.canAccessCrew ? "ALLOWED" : "NOT ALLOWED"}
                          </Title>
                        </Col>
                      </InputContainer>
                    </Col>

                    <Col span={24}>
                      <InputContainer>
                        <Col span={8}>
                          <Title level={3} variant={atomThemeVariant}>
                            Enable MFA
                          </Title>
                        </Col>
                        <Col span={2}>
                          <FormItem>
                            {/* WARNING: the display is inverted from the boolean value.
                                We show a notion of "Is MFA Enable" while the data/dto holds "Is MFA DISabled"
                                That's on purpose: to make UI more friendly.*/}
                            <CustomSwitch
                              $isChecked={!formValues.access.isMfaDisabled}
                              checked={!formValues.access.isMfaDisabled}
                              onChange={(e) =>
                                setFormValues({ ...formValues, access: { ...formValues.access, isMfaDisabled: !e } })
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col
                          span={12}
                          style={{
                            paddingLeft: 16,
                          }}
                        >
                          <Title level={5} variant={formValues.access.isMfaDisabled ? "faded" : atomThemeVariant}>
                            {formValues.access.isMfaDisabled ? "NO MFA" : "SMS REQUIRED"}
                          </Title>
                        </Col>
                      </InputContainer>
                    </Col>
                    <Col span={24}>
                      <InputContainer>
                        <Col span={8}>
                          <Title level={3} variant={atomThemeVariant}>
                            Can access Rig Scorecard
                          </Title>
                        </Col>
                        <Col span={2}>
                          <CustomSwitch
                            $isChecked={!!formValues.access.canAccessRigScorecard}
                            checked={formValues.access.canAccessRigScorecard}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                access: { ...formValues.access, canAccessRigScorecard: e },
                              })
                            }
                          />
                        </Col>
                        <Col
                          span={12}
                          style={{
                            paddingLeft: 16,
                          }}
                        >
                          <Title
                            level={5}
                            variant={formValues.access.canAccessRigScorecard ? atomThemeVariant : "faded"}
                          >
                            {formValues.access.canAccessRigScorecard ? "ALLOWED" : "NOT ALLOWED"}
                          </Title>
                        </Col>
                      </InputContainer>
                    </Col>
                    <Col span={24}>
                      <InputContainer>
                        <Col span={8}>
                          <Title level={3} variant={atomThemeVariant}>
                            Can select Clara Env
                          </Title>
                        </Col>
                        <Col span={2}>
                          <CustomSwitch
                            disabled={!formValues.access.canAccessRigScorecard}
                            $isChecked={canSelectClaraEndpoint}
                            checked={canSelectClaraEndpoint}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                access: { ...formValues.access, canSelectClaraEndpoint: e },
                              })
                            }
                          />
                        </Col>
                        <Col
                          span={12}
                          style={{
                            paddingLeft: 16,
                          }}
                        >
                          <Title level={5} variant={canSelectClaraEndpoint ? atomThemeVariant : "faded"}>
                            {canSelectClaraEndpoint ? "ALLOWED" : "NOT ALLOWED"}
                          </Title>
                        </Col>
                      </InputContainer>
                    </Col>

                    <Col span={24}>
                      <InputContainer>
                        <Col span={8}>
                          <Title level={3} variant={atomThemeVariant}>
                            Exception Approver
                          </Title>
                        </Col>
                        <Col span={2}>
                          <FormItem>
                            <CustomSwitch
                              $isChecked={!!formValues.access.isApprover}
                              checked={formValues.access.isApprover}
                              onChange={(e) =>
                                setFormValues({ ...formValues, access: { ...formValues.access, isApprover: e } })
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col
                          span={12}
                          style={{
                            paddingLeft: 16,
                          }}
                        >
                          <Title level={5} variant={formValues.access.isApprover ? atomThemeVariant : "faded"}>
                            {formValues.access.isApprover ? "YES" : "NO"}
                          </Title>
                        </Col>
                      </InputContainer>
                    </Col>
                  </InfoContainer>
                </Pane>
              </Col>
            </Row>
          )}
        </form>
      }
    />
  );
};

export default Overview;
