import { Line } from "@visx/shape";
import { eventIcon } from "components/Timeline/utils";
import { TimelineEventIndicator } from "components/TvDChart/components/TimelineEventIndicator/TimelineEventIndicator";
import { EVENT_ICON_SIZE, TOP_PADDING } from "components/TvDChart/constants";
import type { ICombinedEvents } from "components/TvDChart/types";
import type { ScaleLinear } from "d3";
import { isEqual } from "lodash";
import React, { useCallback } from "react";
import type { TimelineStates } from "reducers/stateReducer";
import { useAppDispatch } from "reducers/store";
import { PLAN_SERIES_ID, secondsInDay } from "utils/common";
import { Tooltip, Typography } from "utils/componentLibrary";
import type { UOMHelper } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

export const PlannedFutureEvents = ({
  event,
  depthUOM,
  hoveredEvent,
  hoveredEventTvD,
  brushActiveRef,
  setHoveredEventTimeline,
  setHoveredEventTvD,
  paddingTop,
  xScale,
  yScale,
  currentPadding,
  dynamicHoleDepth,
  selectedEvent,
  setTimelineState,
  setSelectedSeries,
}: {
  event: ICombinedEvents;
  depthUOM: UOMHelper;
  hoveredEvent: ICombinedEvents | null;
  hoveredEventTvD: ICombinedEvents | null | undefined;
  brushActiveRef: React.MutableRefObject<boolean | null>;
  setHoveredEventTimeline: (event: ICombinedEvents | null) => void;
  setHoveredEventTvD: (value: React.SetStateAction<ICombinedEvents | null | undefined>) => void;
  paddingTop: number;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  currentPadding: number;
  selectedEvent: number | null;
  dynamicHoleDepth?: number | null;
  setTimelineState: (state: TimelineStates) => void;
  setSelectedSeries: (seriesId: number) => void;
}) => {
  const {
    themeStyle: { colors: themeColors },
    isDark,
  } = useCustomTheme();
  const getCumulativeDuration = (d: { cumulativeDuration?: number }) =>
    Math.trunc(((d?.cumulativeDuration ?? 0) / secondsInDay) * 100) / 100;
  const getHoleDepthDisplay = useCallback(
    (d: { holeDepth?: number | null }) => depthUOM.display(d?.holeDepth ?? 0),
    [depthUOM],
  );

  const dispatch = useAppDispatch();

  const getDuration = (d: { dynamicDuration?: number }) => (d?.dynamicDuration ?? 0) / secondsInDay;

  const getIcon = (event: ICombinedEvents) => {
    if ((event.combinedEvents ?? []).length === 0 && event.type) return eventIcon(event.type);
    return (
      <Typography style={{ color: themeColors.primary_typography }}>
        {(event.combinedEvents || []).length + 1}
      </Typography>
    );
  };

  const tooltipMessage =
    event && (event.combinedEvents || []).length === 0
      ? `${event.type}\n${event.holeDepth === null || event.holeDepth === undefined ? `- - ${depthUOM.abbr}` : getHoleDepthDisplay(event)}\n${getCumulativeDuration(event)} day${
          getCumulativeDuration(event) === 1 ? "" : "s"
        }`
      : `${(event?.combinedEvents || []).length} events`;

  const isHovered =
    event?.id === hoveredEvent?.id && hoveredEvent?.type === event?.type && isEqual(event, hoveredEventTvD);

  const topIconY = paddingTop + TOP_PADDING - EVENT_ICON_SIZE;

  return (
    <>
      <Tooltip
        key={event?.id}
        placement="top"
        overlayStyle={{ whiteSpace: "pre-line" }}
        title={tooltipMessage}
        arrowPointAtCenter
        destroyTooltipOnHide
        open={isHovered}
      >
        <TimelineEventIndicator
          isHovered={"true"}
          isEdited={"false"}
          isFuturePlanEvent={"false"}
          eventId={event?.id ? +event?.id : undefined}
          onClick={() => {
            if (brushActiveRef?.current || !event) return;
            setHoveredEventTimeline({
              id: event?.id,
              type: event?.type,
            });
            setHoveredEventTvD(event);
            setTimelineState("Plan");
          }}
          onClickOutside={() => {
            setHoveredEventTimeline(null);
            setHoveredEventTvD(null);
          }}
          onMouseEnter={() => {
            if (!selectedEvent) {
              if (brushActiveRef?.current) return;
              setHoveredEventTimeline({
                id: event?.id,
                type: event?.type,
              });
              setHoveredEventTvD(event);
            }
          }}
          onMouseLeave={() => {
            if (!selectedEvent) {
              if (brushActiveRef?.current) return;
              setHoveredEventTimeline(null);
              setHoveredEventTvD(null);
            }
          }}
          top={topIconY}
          left={(event ? xScale(getDuration(event)) : 0) + currentPadding}
          translateX="-50%"
          translateY="-50%"
          style={{ opacity: isHovered ? 1 : 0 }}
        >
          {event ? getIcon(event) : null}
        </TimelineEventIndicator>
      </Tooltip>

      {/* Second TimelineEventIndicator is just for the glowing effect to always remain in sync */}
      <TimelineEventIndicator
        isHovered={"false"}
        isEdited={"false"}
        isFuturePlanEvent={"true"}
        eventId={event?.id ? +event?.id : undefined}
        onClick={() => {
          if (brushActiveRef?.current || !event) return;
          setHoveredEventTimeline({
            id: event?.id,
            type: event?.type,
          });
          setHoveredEventTvD(event);
          setTimelineState("Plan");
          setSelectedSeries(PLAN_SERIES_ID);
          if (selectedEvent === event.id) {
            dispatch({
              type: "SET_SELECTED_TIMELINE_EVENT",
              payload: null,
            });
            dispatch({
              type: "SET_EVENT_HOVERED",
              payload: null,
            });
          }
        }}
        preventResetState
        onMouseEnter={() => {
          if (!selectedEvent) {
            if (brushActiveRef?.current) return;
            setHoveredEventTimeline({
              id: event?.id,
              type: event?.type,
            });
            setHoveredEventTvD(event);
          }
        }}
        onMouseLeave={() => {
          if (!selectedEvent) {
            if (brushActiveRef?.current) return;
            setHoveredEventTimeline(null);
            setHoveredEventTvD(null);
          }
        }}
        top={topIconY}
        left={(event ? xScale(getDuration(event)) : 0) + currentPadding}
        translateX="-50%"
        translateY="-50%"
        style={{ opacity: isHovered ? 0 : 1 }}
      >
        {event ? getIcon(event) : null}
      </TimelineEventIndicator>
      <svg
        width={2}
        height={500}
        style={{
          position: "absolute",
          top: topIconY + EVENT_ICON_SIZE / 2,
          left: (event ? xScale(getDuration(event)) : 0) + currentPadding,
        }}
      >
        <Line
          x1={0}
          y1={0}
          x2={0}
          y2={393}
          stroke={themeColors.future_plan_line}
          strokeWidth={isDark ? 1 : 2}
          strokeDasharray={"2 4"}
        />
      </svg>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        style={{
          position: "absolute",
          top: paddingTop + TOP_PADDING + yScale(dynamicHoleDepth ?? 0),
          left: (event ? xScale(getDuration(event)) : 0) + currentPadding,
          transform: "translate(-50%, -100%)",
        }}
      >
        <defs>
          <filter
            id="plan_future_event"
            x="0"
            y="0"
            width="18"
            height="18"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_12559_143937" />
          </filter>
        </defs>
        <path
          d="M12 9C12 7.34315 10.6569 6 9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12C10.6569 12 12 10.6569 12 9Z"
          fill={themeColors.future_plan_line}
        />
        <g opacity="0.5" filter="url(#plan_future_event)">
          <path
            d="M14 9C14 6.23858 11.7614 4 9 4C6.23858 4 4 6.23858 4 9C4 11.7614 6.23858 14 9 14C11.7614 14 14 11.7614 14 9Z"
            fill={themeColors.future_plan_line}
          />
        </g>
      </svg>
    </>
  );
};
