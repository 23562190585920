/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SingleStandKpiDetailByDateShiftDto
 */
export interface SingleStandKpiDetailByDateShiftDto {
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByDateShiftDto
     */
    dayTargetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByDateShiftDto
     */
    nightTargetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByDateShiftDto
     */
    dayValue: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByDateShiftDto
     */
    nightValue: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByDateShiftDto
     */
    operationCount: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByDateShiftDto
     */
    dayOperationCount: number;
    /**
     * 
     * @type {number}
     * @memberof SingleStandKpiDetailByDateShiftDto
     */
    nightOperationCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof SingleStandKpiDetailByDateShiftDto
     */
    isOutlier: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SingleStandKpiDetailByDateShiftDto
     */
    date: Date;
}

/**
 * Check if a given object implements the SingleStandKpiDetailByDateShiftDto interface.
 */
export function instanceOfSingleStandKpiDetailByDateShiftDto(value: object): value is SingleStandKpiDetailByDateShiftDto {
    if (!('dayValue' in value) || value['dayValue'] === undefined) return false;
    if (!('nightValue' in value) || value['nightValue'] === undefined) return false;
    if (!('operationCount' in value) || value['operationCount'] === undefined) return false;
    if (!('dayOperationCount' in value) || value['dayOperationCount'] === undefined) return false;
    if (!('nightOperationCount' in value) || value['nightOperationCount'] === undefined) return false;
    if (!('isOutlier' in value) || value['isOutlier'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    return true;
}

export function SingleStandKpiDetailByDateShiftDtoFromJSON(json: any): SingleStandKpiDetailByDateShiftDto {
    return SingleStandKpiDetailByDateShiftDtoFromJSONTyped(json, false);
}

export function SingleStandKpiDetailByDateShiftDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleStandKpiDetailByDateShiftDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dayTargetValue': json['DayTargetValue'] == null ? undefined : json['DayTargetValue'],
        'nightTargetValue': json['NightTargetValue'] == null ? undefined : json['NightTargetValue'],
        'dayValue': json['DayValue'],
        'nightValue': json['NightValue'],
        'operationCount': json['OperationCount'],
        'dayOperationCount': json['DayOperationCount'],
        'nightOperationCount': json['NightOperationCount'],
        'isOutlier': json['IsOutlier'],
        'date': (new Date(json['Date'])),
    };
}

export function SingleStandKpiDetailByDateShiftDtoToJSON(value?: SingleStandKpiDetailByDateShiftDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'DayTargetValue': value['dayTargetValue'],
        'NightTargetValue': value['nightTargetValue'],
        'DayValue': value['dayValue'],
        'NightValue': value['nightValue'],
        'OperationCount': value['operationCount'],
        'DayOperationCount': value['dayOperationCount'],
        'NightOperationCount': value['nightOperationCount'],
        'IsOutlier': value['isOutlier'],
        'Date': ((value['date']).toISOString().substring(0,10)),
    };
}

