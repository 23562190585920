import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { AccountDto, ApiOperatorsAccountsPutRequest } from "apis/oag";
import { OperatorsApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDOperatorsAccountsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const operators = new OperatorsApi(apiConfig);

export function useAdminAccountList(): UseQueryResult<{ list: AccountDto[]; byId: { [key: number]: AccountDto } }> {
  const search = useAppSelector((state) => state.admin.accounts.search || undefined);
  const operatorStatuses = useAppSelector((state) => state.admin.accounts.operatorStatus);
  const wells = useAppSelector((state) => state.admin.accounts.wells);
  const rigs = useAppSelector((state) => state.admin.accounts.rigs);
  const userStatuses = useAppSelector((state) => state.admin.accounts.userStatus);

  const parameters: ApiOperatorsAccountsPutRequest = {
    accountQueryDto: {
      search,
      wellIds: wells,
      rigIds: rigs,
      status: operatorStatuses,
      userStatus: userStatuses,
    },
  };

  const queryKey: PDOperatorsAccountsQueryKey = {
    type: PDQueryType.OPERATORS_ACCOUNTS,
    uid: RequestUID.operatorsAccounts,
    params: parameters,
  };

  return useQuery({
    queryKey: [queryKey],
    queryFn: () =>
      operators.apiOperatorsAccountsPut(parameters).then((data) => {
        return {
          list: data,
          byId: data.reduce<Record<number, AccountDto>>((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {}),
        };
      }),
  });
}
