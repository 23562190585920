import type {
  BarSliceDto,
  KpiGroupUserLensDto,
  KpiPivotFactSetDto,
  PivotBarDto,
  PivotTree1Dto,
  PivotTree2Dto,
  PivotTree3Dto,
} from "apis/oag";
import { DimensionType, PivotDepthType, PivotType, StackingType } from "apis/oag";
import type { UserLensDto } from "apis/oag/models/UserLensDto";
import { usePivotLabels } from "hooks/usePivotLabels";
import { uniqBy } from "lodash";
import { useCallback, useMemo } from "react";
import { useUOM } from "utils/format";

import { PIVOT_SEPARATOR } from "./constants";

export const usePivotHelpers = (lens: Partial<UserLensDto>, data: KpiPivotFactSetDto) => {
  const depthUOM = useUOM(DimensionType.Metres);

  type IStandNumbersMap = Record<number, number>;
  const standNumbersMap = useMemo<IStandNumbersMap>(() => {
    if (data.depth === PivotDepthType.One && data.pivotLevel1 === PivotType.StandNumber) {
      return data.dataDepth1?.children?.reduce(
        (acc, curr) => ({ ...acc, [+curr.key.value]: +curr.key.name }),
        {} as IStandNumbersMap,
      );
    }
    if (data.depth === PivotDepthType.Two) {
      if (data.pivotLevel1 === PivotType.StandNumber) {
        return data.dataDepth2?.children?.reduce(
          (acc, curr) => ({ ...acc, [+curr.key.value]: +curr.key.name }),
          {} as IStandNumbersMap,
        );
      }
      if (data.pivotLevel2 === PivotType.StandNumber) {
        return data.dataDepth2?.children?.reduce(
          (acc, curr) => ({
            ...acc,
            ...curr.children?.reduce((acc2, curr2) => ({ ...acc2, [+curr2.key.value]: +curr2.key.name }), {}),
          }),
          {} as IStandNumbersMap,
        );
      }
      return {};
    }
    return {};
  }, [data]);
  const { getLabelFromKey } = usePivotLabels();
  const getLabelFromPivotKey = useCallback(
    (value: string, pivotType: PivotType, format: "short" | "long" = "short") => {
      const key = value.split(PIVOT_SEPARATOR)[0];
      switch (pivotType) {
        case PivotType.StandNumber:
          return depthUOM
            .display(standNumbersMap[+key] ? standNumbersMap[+key] : +key, { unit: "", fractionDigits: 0 })
            .replaceAll(",", "");
        default:
          return getLabelFromKey(key, pivotType, format);
      }
    },
    [depthUOM, standNumbersMap, getLabelFromKey],
  );

  const getData = useCallback(
    (d: BarSliceDto | undefined, isDistribution?: boolean) => {
      if (!d) return 0;
      if ((lens as KpiGroupUserLensDto)?.stackingType === StackingType.Distribution) return d.distribution;
      return isDistribution ? d.distribution : d.value;
    },
    [lens],
  );

  const level2Reducer = (d: PivotTree2Dto, cb: (x: PivotTree1Dto) => Array<number>) => {
    return d.children.flatMap(cb);
  };
  const level3Reducer = (d: PivotTree3Dto, cb: (x: PivotTree2Dto) => Array<number>) => {
    return d.children.flatMap(cb);
  };
  /* Total Value */
  const getTotalValueLevel1AsArr: (d: PivotTree1Dto, withOutliers: boolean) => number[] = useCallback(
    (d: PivotTree1Dto, withOutliers: boolean) => {
      return (d?.children ?? [])
        .filter((item) => (withOutliers ? true : !item.isOutlier))
        .map((item) => uniqBy(item.slices, (i) => i.kpiTypeId).reduce((acc, item) => acc + getData(item), 0));
    },
    [getData],
  );
  const getTotalValueLevel2AsArr = useCallback(
    (d: PivotTree2Dto, withOutliers: boolean) => {
      return level2Reducer(d, (child) => getTotalValueLevel1AsArr(child, withOutliers));
    },
    [getTotalValueLevel1AsArr],
  );
  const getTotalValueLevel3AsArr = useCallback(
    (d: PivotTree3Dto, withOutliers: boolean) => {
      return level3Reducer(d, (child) => getTotalValueLevel2AsArr(child, withOutliers));
    },
    [getTotalValueLevel2AsArr],
  );

  const getValue = (d: PivotBarDto, key: number) => {
    return getData((d?.slices ?? []).find((e) => e.kpiTypeId === key)) ?? 0;
  };

  return {
    getLabelFromKey: getLabelFromPivotKey,
    getData,
    getTotalValueLevel1AsArr,
    getTotalValueLevel2AsArr,
    getTotalValueLevel3AsArr,
    getValue,
  };
};
