import type { RigPowerSummaryDto, RigPowerSummaryValuesDto } from "apis/oag";
import { GeneratorSlotType } from "apis/oag";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import {
  generatorColorsBySlotType,
  generatorLabelsBySlotType,
  getGeneratorNumberBySlotType,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { useLensNameByTemplateId } from "hooks/useLensNameByTemplateId";
import { useMemo } from "react";
import { Track } from "services/Mixpanel";
import type { UOMHelper } from "utils/format";

export function useGeneratorLegendItems({
  generators,
  uomValue,
  legend,
  setLegend,
  summary,
  lensId,
  lensTemplateId,
}: {
  generators: GeneratorSlotType[];
  uomValue: UOMHelper;
  legend: GeneratorSlotType[];
  setLegend: React.Dispatch<React.SetStateAction<GeneratorSlotType[]>>;
  summary: RigPowerSummaryDto;
  lensId: number;
  lensTemplateId: number;
}) {
  const lensName = useLensNameByTemplateId(lensTemplateId);

  const generatorLegendItems = useMemo<LegendItem[]>(() => {
    return generators.map((genId) => ({
      id: genId,
      name: generatorLabelsBySlotType[genId || GeneratorSlotType.None],
      color: generatorColorsBySlotType[genId],
      isEnabled: legend?.includes(genId),
      previewerType: LegendPreviewerType.BOX,
      columnValues: {
        Min: uomValue.display(
          (
            summary[
              `generator${getGeneratorNumberBySlotType(genId)}Summary` as keyof typeof summary
            ] as RigPowerSummaryValuesDto
          )?.min,
          {
            fractionDigits: 0,
            unit: "kW",
          },
        ),
        Max: uomValue.display(
          (
            summary[
              `generator${getGeneratorNumberBySlotType(genId)}Summary` as keyof typeof summary
            ] as RigPowerSummaryValuesDto
          )?.max,
          {
            fractionDigits: 0,
            unit: "kW",
          },
        ),
        Mean: uomValue.display(
          (
            summary[
              `generator${getGeneratorNumberBySlotType(genId)}Summary` as keyof typeof summary
            ] as RigPowerSummaryValuesDto
          )?.mean,
          {
            fractionDigits: 0,
            unit: "kW",
          },
        ),
      },
      onClick: (item: LegendItem) => {
        if (legend && legend.length === 1 && legend[0] === item.id) {
          // If this generator is the only one enabled, we prevent clicking
          return false;
        }
        setLegend((currentLegend) => {
          Track.interact("Evergreen Dashboard - Update legend", {
            lensId,
            lens: lensName,
            selectedItem: item.name,
            isEnabled: !item.isEnabled,
          });
          if (item.isEnabled) {
            return currentLegend.filter((itemId) => itemId !== item.id);
          } else {
            return [...currentLegend, item.id as GeneratorSlotType];
          }
        });
      },
    }));
  }, [generators, legend, setLegend, summary, lensId, lensName, uomValue]);

  return generatorLegendItems;
}
