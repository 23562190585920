/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StackedStandKpiComparisonInfoDto } from './StackedStandKpiComparisonInfoDto';
import {
    StackedStandKpiComparisonInfoDtoFromJSON,
    StackedStandKpiComparisonInfoDtoFromJSONTyped,
    StackedStandKpiComparisonInfoDtoToJSON,
} from './StackedStandKpiComparisonInfoDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface StackedStandKpiComparisonWellDto
 */
export interface StackedStandKpiComparisonWellDto {
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonWellDto
     */
    wellId: number;
    /**
     * 
     * @type {boolean}
     * @memberof StackedStandKpiComparisonWellDto
     */
    isFocalWell: boolean;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonWellDto
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonWellDto
     */
    operationCount: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StackedStandKpiComparisonWellDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonWellDto
     */
    targetAverage?: number | null;
    /**
     * 
     * @type {Array<StackedStandKpiComparisonInfoDto>}
     * @memberof StackedStandKpiComparisonWellDto
     */
    detailsByDisplayOption?: Array<StackedStandKpiComparisonInfoDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof StackedStandKpiComparisonWellDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the StackedStandKpiComparisonWellDto interface.
 */
export function instanceOfStackedStandKpiComparisonWellDto(value: object): value is StackedStandKpiComparisonWellDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('isFocalWell' in value) || value['isFocalWell'] === undefined) return false;
    if (!('average' in value) || value['average'] === undefined) return false;
    if (!('operationCount' in value) || value['operationCount'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function StackedStandKpiComparisonWellDtoFromJSON(json: any): StackedStandKpiComparisonWellDto {
    return StackedStandKpiComparisonWellDtoFromJSONTyped(json, false);
}

export function StackedStandKpiComparisonWellDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackedStandKpiComparisonWellDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'isFocalWell': json['IsFocalWell'],
        'average': json['Average'],
        'operationCount': json['OperationCount'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'targetAverage': json['TargetAverage'] == null ? undefined : json['TargetAverage'],
        'detailsByDisplayOption': json['DetailsByDisplayOption'] == null ? undefined : ((json['DetailsByDisplayOption'] as Array<any>).map(StackedStandKpiComparisonInfoDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function StackedStandKpiComparisonWellDtoToJSON(value?: StackedStandKpiComparisonWellDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'IsFocalWell': value['isFocalWell'],
        'Average': value['average'],
        'OperationCount': value['operationCount'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'TargetAverage': value['targetAverage'],
        'DetailsByDisplayOption': value['detailsByDisplayOption'] == null ? undefined : ((value['detailsByDisplayOption'] as Array<any>).map(StackedStandKpiComparisonInfoDtoToJSON)),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

