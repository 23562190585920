import { Button as AtomButton } from "atoms/Form";
import styled, { css } from "styled-components";
import colors from "utils/colors";
import { Card, Row, Space } from "utils/componentLibrary";
import { PdCardBody } from "utils/componentLibrary/Card";
import { zIndexLayer } from "utils/zIndex";

export const StyledLensShell = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledKpiRow = styled(Row)<{ height: number }>`
  row-gap: 99999px !important; // To hide overflowing content with wrap
  max-height: ${({ height }) => height}px;
`;

export const SecondaryKpiContainer = styled(Row).attrs({ gutter: [34, 0], align: "middle" })`
  display: flex;
  flex-direction: row;
  margin: 0;
`;

export const Button = styled(AtomButton)`
  z-index: ${zIndexLayer.above};
  &.ant-btn,
  &.ant-btn.ant-btn-lg {
    border: none !important;
    background-color: transparent !important;
    font-size: 14px;
  }
  &.ant-btn-icon-only.ant-btn-lg > * {
    font-size: 14px;
  }
  &:disabled {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography} !important;
  }
`;

export const StyledBottomRow = styled(Row).attrs({
  justify: "space-between",
  align: "middle",
})`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  height: 25px;
  overflow: hidden;
`;
export const StyledInner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  height: 40px;
  border-top: none;
`;

export const StyledCard = styled(Card)<{ $hasHiddenToolbar: boolean }>`
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0;
  width: 100%;
  height: 100%;
  background: ${({
    theme: {
      themeStyle: { colors: themeColors },
    },
  }) => themeColors.primary_chart_bg};
  ${PdCardBody} {
    position: relative;
    padding: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  ${({ $hasHiddenToolbar, theme }) =>
    $hasHiddenToolbar &&
    css`
      ${StyledInner}, ${StyledBottomRow} {
        background-color: transparent !important;
        border-top: none;
        div {
          background-color: transparent !important;
          border-top: none;
        }
      }
      :hover {
        &,
        ${StyledInner}, ${StyledBottomRow} {
          background-color: ${theme.themeStyle.colors.alt_quaterniary_bg}!important;
          div {
            background-color: ${theme.themeStyle.colors.alt_quaterniary_bg}!important;
            border-top: none;
          }
        }
      }
    `}
`;

export const InfoContainer = styled(Space)`
  padding: 24px;
`;

export const StyledButton = styled(Button).attrs({ type: "primary", block: true, $engaged: false })`
  height: 44px !important;
  width: 44px !important;
  border-radius: 50%;
  padding: 0px !important;
  margin: 0px !important;

  .pd-icon {
    pointer-events: none;
  }
`;

export const StyledLensContainer = styled.div<{ $height: string; $padding: number }>`
  padding-left: ${({ $padding }) => $padding}px;
  height: ${({ $height }) => $height};
  width: 100%;
`;

export const TooltipRow = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  }
`;

export const StyledBottomRowContent = styled.div`
  height: 25px;
  width: 100%;
  padding-left: 12px;
  display: flex;
  align-items: center;
  background: ${({ theme: { isDark, themeStyle } }) =>
    isDark ? colors.shutter_black : themeStyle.colors.alt_secondary_bg};
  z-index: ${zIndexLayer.elevated};
`;

export const FallbackContainer = styled.div`
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
`;

export const FallbackButtonContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
`;
