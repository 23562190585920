import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type { WellStatusType } from "apis/oag";
import { FiltersApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";

import { useRigsPeriodState } from "./useRigsPeriodState";

const filters = new FiltersApi(apiConfig);

export const useFilterOptions = ({
  keepEvengreenOnly,
  wellIds,
  targetFormationIds,
  operatorIds,
  rigIds,
  wellStatuses,
  storeName,
}: {
  storeName: "allWells" | "evergreenWells";
  keepEvengreenOnly: boolean;
  wellIds: number[] | null;
  targetFormationIds: number[] | null;
  operatorIds: number[] | null;
  rigIds: number[] | null;
  wellStatuses: WellStatusType[] | null;
}) => {
  const periodState = useRigsPeriodState();
  const startDate = useAppSelector((state) => state[storeName]?.startDate);
  const endDate = useAppSelector((state) => state[storeName]?.endDate);

  return useQuery({
    queryKey: [
      periodState,
      wellIds,
      targetFormationIds,
      operatorIds,
      rigIds,
      wellStatuses,
      startDate,
      endDate,
    ],
    queryFn: () => {
      return filters.apiFiltersLinkedPut({
        linkedFiltersSetDto: {
          keepEvengreenOnly,
          from: startDate
            ? {
                utc: startDate,
                minutesOffset: 0,
                isDateOnly: true,
              }
            : periodState.from,
          to: endDate
            ? {
                utc: endDate,
                minutesOffset: 0,
                isDateOnly: true,
              }
            : periodState.to,
          wellIds,
          targetFormationIds,
          operatorIds,
          rigIds,
          wellStatuses,
        },
      });
    },
    placeholderData: keepPreviousData,
  });
};
