import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

export enum SelectedMetric {
  KPI,
  Scores,
  NetTimes,
}

export enum NetTimeType {
  Target = "Time vs Target",
  LostTime = "Time vs Benchmark",
  TimeSaved = "Time vs Base",
}

export enum ScoreType {
  OperatorScore = "Operator Score",
  RigScore = "Rig Score",
  ImprovementScore = "Improvement Score",
}

interface FleetPerformanceMetricSelectionContextValue {
  selectedMetric: SelectedMetric;
  setSelectedMetric: Dispatch<SetStateAction<SelectedMetric>>;

  selectedNetTime?: NetTimeType;
  setSelectedNetTime: Dispatch<SetStateAction<NetTimeType | undefined>>;

  selectedScore?: ScoreType;
  setSelectedScore: Dispatch<SetStateAction<ScoreType | undefined>>;
}

export const FleetPerformanceMetricSelectionContext = createContext<
  FleetPerformanceMetricSelectionContextValue | undefined
>(undefined);

export const useFleetPerformanceMetricSelectionContext = () => {
  const context = useContext(FleetPerformanceMetricSelectionContext);
  if (context === undefined) {
    throw new Error(
      "useFleetPerformanceMetricSelectionContext must be used within a FleetPerformanceMetricSelectionContextProvider",
    );
  }
  return context;
};

export const FleetPerformanceMetricSelectionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedMetric, setSelectedMetric] = useState(SelectedMetric.KPI);
  const [selectedNetTime, setSelectedNetTime] = useState<NetTimeType | undefined>();
  const [selectedScore, setSelectedScore] = useState<ScoreType | undefined>();

  return (
    <FleetPerformanceMetricSelectionContext.Provider
      value={{
        selectedMetric,
        setSelectedMetric,
        selectedNetTime,
        setSelectedNetTime,
        selectedScore,
        setSelectedScore,
      }}
    >
      {children}
    </FleetPerformanceMetricSelectionContext.Provider>
  );
};
