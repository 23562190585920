import type { ParameterByDepthUserLensTrackItemDto } from "apis/oag";
import { LensTemplateType } from "apis/oag";

import { ParameterByDepthLoading } from "./ContainerLens/ParameterByDepthKPI/Parts/Loading/Loading";
import { LoadingChart } from "./LoadingChart";

export function MiniLoadingChart({
  id,
  template,
  label = "Loading",
  description = "",
  detailed,
  isSmallLens,
  selectedTrackItems,
  isLoading,
}: {
  isLoading: boolean;
  id: number;
  template?: LensTemplateType;
  label?: string;
  description?: string;
  detailed?: boolean;
  isSmallLens?: boolean;
  selectedTrackItems?: ParameterByDepthUserLensTrackItemDto[];
}) {
  if (
    template === LensTemplateType.ParameterByDepth ||
    template === LensTemplateType.ParameterByDepthRoadmap ||
    template === LensTemplateType.ParameterByDepthUnified
  )
    return (
      <ParameterByDepthLoading
        title={"..."}
        detailed={!!detailed}
        selectedTrackItems={selectedTrackItems}
        lensId={id}
      />
    );
  return (
    <LoadingChart
      isLoading={isLoading}
      title={label}
      isSmallLens={isSmallLens}
      description={description}
      detailed={detailed}
    />
  );
}
