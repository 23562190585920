import { DimensionType, WellStatusType } from "apis/oag";
import { Title } from "atoms/Typography";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import { useRigSort } from "hooks/useRigSort";
import { useWellSummaries } from "hooks/useWellSummaries";
import { useFilteredAllWellsContext } from "pages/AllWells/useFilteredAllWells";
import { LoaderContainer } from "pages/RigLeaderboard/components/ScoreBreakout/styles";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Space } from "utils/componentLibrary";
import { SortBy, SortDirections } from "utils/enums";
import { useUOM } from "utils/format";
import { sortByRigs, sortByValue, sortWellsByEnd } from "utils/helper";

import SingleWell from "./SingleWell";
import { IconHolder, InfoContainer, LimitedContainer, SortIcons, StyledChevron, StyledHeader, StyledRow, StyledTitle, TitleContainer, WellContainer } from "./style";

const WellsContainer: FC = () => {
  const wellSummaries = useWellSummaries(false);

  const { setVisibleWellsList, visibleWellsList } = useFilteredAllWellsContext();
  const [sortBy, setSortBy] = useState(SortBy.End);
  const [sortDirection, setSortDirection] = useState(SortDirections.Desc);

  const SortIcon = useCallback(({ column }: { column?: SortBy }) => {
    return (
      <SortIcons>
        <StyledChevron
          $isActive={sortBy === column && sortDirection === SortDirections.Asc}
          $isFaded={sortBy === column && sortDirection !== SortDirections.Asc}
        />
        <StyledChevron
          $isActive={sortBy === column && sortDirection === SortDirections.Desc}
          $isFaded={sortBy === column && sortDirection !== SortDirections.Desc}
        />
      </SortIcons>
    );
  }, [sortBy, sortDirection]);

  const handleSort = useCallback((newSortBy: SortBy) => {
    if (sortBy === newSortBy) {
      setSortDirection(sortDirection === SortDirections.Asc ? SortDirections.Desc : SortDirections.Asc);
    } else {
      setSortDirection(SortDirections.Asc);
    }
    setSortBy(newSortBy);
  }, [sortBy, sortDirection]);
  const rigSort = useRigSort();

  const unit = useUOM(DimensionType.Metres);
  const sortedWellSummaries = useMemo(
    () =>
      [...(wellSummaries?.data?.wells ?? [])]?.sort((a, b) => {
        switch (sortBy) {
          case SortBy.Rigs:
            return sortByRigs(a, b, sortDirection, rigSort);
          case SortBy.Start:
            if (a.status === WellStatusType.Pending) {
              return sortDirection === SortDirections.Asc ? 1 : -1;
            } else if (b.status === WellStatusType.Pending) {
              return sortDirection === SortDirections.Asc ? -1 : 1;
            }

            return sortByValue(a.spudDateTime?.utc.getTime(), b.spudDateTime?.utc.getTime(), sortDirection);

          case SortBy.End:
            return sortWellsByEnd(a, b, sortDirection, rigSort);

          case SortBy.Days:
            return sortByValue(+a.cumulativeDuration, +b.cumulativeDuration, sortDirection);

          case SortBy.Hole:
            return sortByValue(+a.lastHoleDepth, +b.lastHoleDepth, sortDirection);

          case SortBy.Bit:
            return sortByValue(+a.lastBitDepth, +b.lastBitDepth, sortDirection);

          case SortBy.Wells:
          default:
            return sortDirection === SortDirections.Asc
              ? (a.name ?? "").localeCompare(b.name ?? "")
              : (b.name ?? "").localeCompare(a.name ?? "");
        }
      }),
    [sortBy, rigSort, sortDirection, wellSummaries?.data?.wells],
  );

  // TODO For some reason sorted well summaries changes too often
  const sortedWellSummariesIds = useMemo(() => sortedWellSummaries.map((well) => well.id), [sortedWellSummaries]);
  const itemData = useMemo(
    () => sortedWellSummaries.filter((well) => (visibleWellsList ?? []).includes(well.id)) ?? [],
    [sortedWellSummaries, visibleWellsList],
  );

  useEffect(() => {
    if (sortedWellSummariesIds?.length) {
      setVisibleWellsList(sortedWellSummariesIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVisibleWellsList, JSON.stringify(sortedWellSummariesIds)]);


  const ColumnTitle = useCallback(({ column, title, width }: { column?: SortBy; title: string; width?: number }) => {
    return (
      <TitleContainer
        width={width}
        onClick={() => {
          if (column) handleSort(column);
        }}
      >
        <StyledTitle>{title}</StyledTitle>
        {column ? <SortIcon column={column} /> : null}
      </TitleContainer>
    );
  }, [SortIcon, handleSort]);

  if (wellSummaries.isLoading || !wellSummaries.data) {
    return (
      <LoaderContainer>
        <Loader withWrapper />
      </LoaderContainer>
    );
  }

  const noResults =
    !wellSummaries.isLoading && !(wellSummaries.data?.wells?.length && wellSummaries.data?.wells?.length >= 1);

  if (noResults) {
    return (
      <Row justify="center" align="middle" style={{ marginTop: "120px" }}>
        <Col>
          <Space
            direction="vertical"
            style={{
              alignItems: "center",
            }}
          >
            <IconHolder>
              <PDComponent.SvgIcon name="list" />
            </IconHolder>
            <Title level={3} variant="faded">
              No Results
            </Title>
          </Space>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <StyledHeader>
        <WellContainer>
          <ColumnTitle column={SortBy.Wells} title="Wells" />
        </WellContainer>
        <InfoContainer>
          <ColumnTitle column={SortBy.Rigs} title="Rigs" width={96} />
          <ColumnTitle column={SortBy.Start} title="Start date" width={95} />
          <ColumnTitle column={SortBy.End} title="End date" width={85} />
          <ColumnTitle column={SortBy.Days} title="Days" width={58} />
          <ColumnTitle column={SortBy.Hole} title={`Hole(${unit.abbr})`} />
          <ColumnTitle column={SortBy.Bit} title={`Bit(${unit.abbr})`} />
          <ColumnTitle title={`TvD`} />
          <div style={{ width: "calc(52px)" }} />
        </InfoContainer>
      </StyledHeader>

      <LimitedContainer key={sortBy + sortDirection}>
        {!wellSummaries.isLoading && wellSummaries.data ? (
          <PDComponent.VirtualizedList items={itemData} itemSizePx={67} scrollbarDistance={4} overscanCount={2}>
            {(well, idx) => (
              <StyledRow key={`${well.id}-${idx}`}>
                <SingleWell wellDetails={well} />
              </StyledRow>
            )}
          </PDComponent.VirtualizedList>
        ) : null}
      </LimitedContainer>
    </>
  );
};

export default WellsContainer;
