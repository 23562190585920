import { CommentsGroup } from "pages/RigScoreCard/RightPane/CommentsGroups/CommentsGroup";
import { useRequiredComments } from "pages/RigScoreCard/RightPane/useRequiredComments";

import * as Styled from "./StyledComponents";

export const RightPaneTasksTab = () => {
  const { percentageCompleted } = useRequiredComments();

  return (
    <Styled.Container>
      <Styled.TrackerRow>
        <Styled.RowPadding>
          <Styled.Heading>Completed</Styled.Heading>
          <Styled.ProgressOuter>
            <Styled.ProgressInner progress={percentageCompleted} />
          </Styled.ProgressOuter>
          <Styled.Heading>{percentageCompleted}%</Styled.Heading>
        </Styled.RowPadding>
      </Styled.TrackerRow>
      <CommentsGroup />
    </Styled.Container>
  );
};
