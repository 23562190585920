/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScoreSummaryTabDto } from './ScoreSummaryTabDto';
import {
    ScoreSummaryTabDtoFromJSON,
    ScoreSummaryTabDtoFromJSONTyped,
    ScoreSummaryTabDtoToJSON,
} from './ScoreSummaryTabDto';

/**
 * 
 * @export
 * @interface ScoreSummaryTabGroupDto
 */
export interface ScoreSummaryTabGroupDto {
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryTabGroupDto
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryTabGroupDto
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ScoreSummaryTabGroupDto
     */
    groupKey?: string | null;
    /**
     * 
     * @type {Array<ScoreSummaryTabDto>}
     * @memberof ScoreSummaryTabGroupDto
     */
    children: Array<ScoreSummaryTabDto>;
}

/**
 * Check if a given object implements the ScoreSummaryTabGroupDto interface.
 */
export function instanceOfScoreSummaryTabGroupDto(value: object): value is ScoreSummaryTabGroupDto {
    if (!('children' in value) || value['children'] === undefined) return false;
    return true;
}

export function ScoreSummaryTabGroupDtoFromJSON(json: any): ScoreSummaryTabGroupDto {
    return ScoreSummaryTabGroupDtoFromJSONTyped(json, false);
}

export function ScoreSummaryTabGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreSummaryTabGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'targetDeltaTime': json['TargetDeltaTime'] == null ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': json['InvisibleLostTime'] == null ? undefined : json['InvisibleLostTime'],
        'groupKey': json['GroupKey'] == null ? undefined : json['GroupKey'],
        'children': ((json['Children'] as Array<any>).map(ScoreSummaryTabDtoFromJSON)),
    };
}

export function ScoreSummaryTabGroupDtoToJSON(value?: ScoreSummaryTabGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'TargetDeltaTime': value['targetDeltaTime'],
        'InvisibleLostTime': value['invisibleLostTime'],
        'GroupKey': value['groupKey'],
        'Children': ((value['children'] as Array<any>).map(ScoreSummaryTabDtoToJSON)),
    };
}

