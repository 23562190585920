import { AxisBottom } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Text as VisXText } from "@visx/text";
import type { GeneratorStatusBarFactDto } from "apis/oag";
import { Title } from "atoms/Typography";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { StyledChartContainerFlexDiv } from "components/Lenses/ContainerLens/common/StyledComponents";
import {
  CLIP_SERIES_ID as CLIP_ID,
  getAxisFontSize,
  getManualAxisLabelSize,
  LATERAL_AXIS_WIDTH,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { StyledLensContainerFlex } from "components/Lenses/ContainerLens/RigPower/RigPowerSimple/StyledComponents";
import type { GeneratorStatusBarProps } from "components/Lenses/interfaces";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { max, min, range as generateRange } from "d3-array";
import dayjs from "dayjs";
import { clamp, inRange } from "lodash";
import { useCallback, useEffect, useId, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import colors from "utils/colors";
import { defaultDateDto } from "utils/common";
import { Col, Row } from "utils/componentLibrary";
import { DEFAULT_DATE_FORMAT } from "utils/format";
import { formatTime, toLocalWellTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { useGeneratorStatusBarFetcher } from "./fetcher";

export const Single: React.FC<GeneratorStatusBarProps> = ({ lens, detailed, setLensDate }) => {
  const { request } = useGeneratorStatusBarFetcher({ lensId: lens?.id });
  const { data } = request;
  useEffect(() => {
    if (data?.lastUpdatedAt && setLensDate) setLensDate(data.lastUpdatedAt);
  }, [data, setLensDate]);

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };
  const plotWidth = getSVGNormalizedValue(chartWidth - 2 * LATERAL_AXIS_WIDTH);
  const plotHeight = getSVGNormalizedValue(chartHeight - 30);
  const dataState = useMemo(() => data?.dataState, [data?.dataState]);

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const colorsOperations = useMemo(
    () => ({
      0: colors.necron_compound,
      1: "#d5aba4",
      2: "#5dc0cd",
      3: "#aa9c7c",
      4: "#726588",
    }),
    [],
  );

  const chunksCount = Math.floor(plotWidth / getManualAxisLabelSize());

  const xScale = useMemo(() => {
    const chartSize = plotWidth;

    const startX = LATERAL_AXIS_WIDTH;
    const timeSeries = (data?.facts ?? []).map((fact) => fact.indexEnd);
    const [_min = 0, _max = 0] = [min((data?.facts ?? []).map((fact) => fact.indexStart)), max(timeSeries)];

    return scaleLinear<number>({
      domain: [_min, _max],
      range: [startX, chartSize + startX],
      clamp: true,
    });
  }, [plotWidth, data]);

  const xScaleDate = useMemo(() => {
    return scaleBand<number>({
      domain: generateRange(chunksCount - 1),
      range: [0, plotWidth],
      paddingInner: 0,
      paddingOuter: 0,
    });
  }, [chunksCount, plotWidth]);

  const getAverageChunkDate = useCallback(
    (chunkIndex: number) => {
      const ratio = (chunkIndex + 1) / chunksCount; /// with +1 =>  [ --- 1 --- 2 --- 3 ---] -> 1/4 = 0.25 for first date point
      const pointIndex = Math.floor(xScale.domain()[0] + (xScale.domain()[1] - xScale.domain()[0]) * ratio); // Point index inside continuous scale
      const block = (data?.facts ?? []).find((point) => inRange(pointIndex, point.indexStart, point.indexEnd + 1)); // corresponding block

      const [startDate, endDate] = [
        toLocalWellTime(block?.from || defaultDateDto.from),
        toLocalWellTime(block?.to || defaultDateDto.to),
      ];
      const insideBlockRatio = clamp(
        (pointIndex - (block?.indexStart || 0)) / ((block?.indexEnd || 0) - (block?.indexStart || 0)),
        0,
        1,
      ); // if point is 50 inside a block of range [40,60]
      const date = Math.floor(+startDate + (+endDate - +startDate) * insideBlockRatio); // interpolate halfway date based on position inside block
      return date;
    },
    [chunksCount, data?.facts, xScale],
  );

  const CLIP_SERIES_ID = useMemo(() => `${CLIP_ID}-${lens.id}-2`, [lens.id]);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent: ({ tooltipData }: { tooltipData?: GeneratorStatusBarFactDto }) => {
      return tooltipData ? (
        <>
          <TooltipHighlightValue style={{ fontWeight: "bold", fontSize: 12 }}>
            Gens online: {tooltipData.onlineGeneratorCount}
          </TooltipHighlightValue>
          <TooltipHighlightValue style={{ color: themeColors.disabled_typography, fontSize: 12 }}>
            {formatTime(tooltipData?.from, { formatStr: DEFAULT_DATE_FORMAT })} -{" "}
            {formatTime(tooltipData?.to, { formatStr: DEFAULT_DATE_FORMAT })}
          </TooltipHighlightValue>
        </>
      ) : null;
    },
  });

  const LENS_TITLE = "Generators Online";
  const id = useId();

  const axisFontSize = useMemo(() => getAxisFontSize(detailed), [detailed]);
  return (
    <LensLoadingContainer
      key={lens.id}
      smallSizeLens
      dataState={dataState}
      title={LENS_TITLE}
      isDetailed={false}
      LoadedComponent={
        <StyledLensContainerFlex>
          <Row justify="space-between">
            <Col style={{ padding: 24, paddingBottom: 8 }}>
              <Title variant="faded" level={3}>
                {LENS_TITLE}
              </Title>
            </Col>
          </Row>

          <StyledChartContainerFlexDiv>
            <div style={{ height: "100%", flexGrow: 1 }} ref={containerRef}>
              <svg width={chartWidth} height={chartHeight} style={{ overflow: "hidden", userSelect: "none" }}>
                <RectClipPath y={0} id={CLIP_SERIES_ID} height={chartHeight} width={plotWidth} />

                {/* VISIBLE TIME AXIS */}
                <AxisBottom
                  hideAxisLine
                  hideTicks
                  scale={xScaleDate}
                  top={plotHeight}
                  numTicks={chunksCount}
                  tickComponent={(props) => {
                    return (
                      <Group>
                        <VisXText
                          x={props.x}
                          y={props.y}
                          dx={LATERAL_AXIS_WIDTH - 40}
                          fontSize={axisFontSize}
                          fill={themeColors.disabled_typography}
                          pointerEvents="none"
                        >
                          {dayjs(getAverageChunkDate(+(props.formattedValue || "").replaceAll(",", ""))).format(
                            DEFAULT_DATE_FORMAT,
                          )}
                        </VisXText>
                      </Group>
                    );
                  }}
                />
                {(data?.facts ?? []).map((fact, index, arr) => {
                  const start = xScale(index === 0 ? fact.indexStart : arr[index - 1].indexEnd);
                  const end = xScale(fact.indexEnd);
                  const width = end - start;
                  const color = colorsOperations[fact.onlineGeneratorCount as keyof typeof colorsOperations];
                  return (
                    <Group
                      onMouseLeave={() => hideTooltip()}
                      onMouseEnter={() =>
                        showTooltip({
                          tooltipLeft: start + width / 2,
                          tooltipTop: 0,
                          tooltipData: fact,
                        })
                      }
                      key={`${fact.indexEnd}-${fact.indexEnd}-${fact.onlineGeneratorCount}-${id}`}
                    >
                      <rect height={plotHeight} width={width} x={start} y={0} stroke={color} fill={color} />
                      {width > 15 ? (
                        <text
                          x={width / 2 + start}
                          y={plotHeight / 2}
                          dx={-4}
                          dy={5}
                          stroke="white"
                          strokeWidth={0.01}
                          fontWeight="normal"
                          fill="white"
                          fontSize={14}
                        >
                          {fact.onlineGeneratorCount}
                        </text>
                      ) : null}
                    </Group>
                  );
                })}
                {tooltipElement}
              </svg>
            </div>
          </StyledChartContainerFlexDiv>
        </StyledLensContainerFlex>
      }
    />
  );
};
