import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { ApiKpiGroupUserLensesIdStackedFactsPutRequest, StackedStandKpiDto } from "apis/oag";
import { DisplayOption, KpiGroupUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { initialFilters, type IFiltersType, type IZoomData } from "reducers/types";
import { singleWellDisplayOptions } from "reducers/widgetReducer";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto, TVD_SAMPLE_COUNT } from "utils/common";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new KpiGroupUserLensesApi(apiConfig);

export function useKpiGroupStackedFacts(lensId: number, options?: UseQueryOptions<StackedStandKpiDto>) {
  const wellDataID = useSelectedWell();
  const dispatch = useAppDispatch();

  const [
    {
      sections: sectionIds,
      operationTime,
      directionalIntervals: directionalIntervalIds,
      holeSizes: holeSizeIds,
      phases: phaseIds,
    },
  ] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, initialFilters);
  const [
    { ts_start: zoomStartDuration, ts_end: zoomEndDuration, depth_start: zoomStartDepth, depth_end: zoomEndDepth },
  ] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WIDGET, initialZoomData);
  const displayOption = useAppSelector((state) => state.widgetOptions.display_options);

  const requestParameters: ApiKpiGroupUserLensesIdStackedFactsPutRequest = {
    id: lensId ?? -1,
    baseDisplayOptionUserLensQueryDto: {
      selectedFilters: {
        sectionIds,
        directionalIntervalIds,
        holeSizeIds,
        phaseIds,
        includeFlatTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNoneAlphaRigs: true,
      },
      zoomStartDepth,
      zoomEndDepth,
      zoomStartDuration,
      zoomEndDuration,
      wellId: wellDataID,
      displayOption: singleWellDisplayOptions.includes(displayOption) ? displayOption : DisplayOption.StandDepth,
      sampleCount: TVD_SAMPLE_COUNT,
      useMockData: false,
      comparisonWellIds: null,
      from: defaultDateDto.from,
      to: defaultDateDto.to,
    },
  };

  const queryKey: PDLensFactsQueryKey<ApiKpiGroupUserLensesIdStackedFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.kpiGroupStackedFacts,
    lensId,
    params: requestParameters,
    comparisonType: "single",
  };

  const result = useQuery<StackedStandKpiDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api.apiKpiGroupUserLensesIdStackedFactsPut(requestParameters, { signal }).then((data) => {
        if (data?.lastUpdatedAt)
          dispatch({
            type: "SET_PER_STAND_REFRESH_DATE",
            payload: { [wellDataID]: data?.lastUpdatedAt },
          });
        return data;
      }),
    ...options,
  });

  return result;
}
