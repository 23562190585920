import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import type { ScaleLinear } from "d3-scale";
import { useMemo } from "react";
import { secondsInDay } from "utils/common";
import { Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

import type { ColoredSegmentVm } from "./segmentDefinitions";

const BAR_HEIGHT = 38;
const APPROX_LETTER_WIDTH_PX = 6.8;
const TEXT_PADDING = 10;
const SEPARATOR_BAR_LENGTH_PX = 3;

export const WellSegmentBand = ({
  segments,
  paddingLeft,
  xScale,
  isLoading: isLoadingTvd,
  separatorColor,
  isReport,
  deduplicationKey,
  isAbove,
}: {
  segments: ColoredSegmentVm[];
  paddingLeft: number;
  xScale: ScaleLinear<number, number, never>;
  isLoading: boolean;
  separatorColor: string;
  deduplicationKey: string;
  isReport?: boolean;
  isAbove?: boolean | null; // To add more height so the two displayed bands don't overlap
}) => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const textY = useMemo(() => {
    if (isReport) {
      return "98.5%";
    } else return isAbove ? "89.5%" : "97.5%";
  }, [isAbove, isReport]);

  const fillerStartX = useMemo(
    () => (segments?.length ? segments[segments.length - 1].endDynamicDuration / secondsInDay : 0),
    [segments],
  );

  const fillerWidth = useMemo(() => xScale.domain()[1] - fillerStartX, [fillerStartX, xScale]);

  return (
    <Group>
      {!isLoadingTvd &&
        segments.map((segment, i) => {
          const isLast = i === segments.length - 1;

          const addedPadding = i === 0 ? paddingLeft : 0;
          const segmentLength = xScale((segment.endDynamicDuration - segment.startDynamicDuration) / secondsInDay);

          const width = Math.max(addedPadding + segmentLength, 0);

          const x = (i === 0 ? 0 : paddingLeft) + (xScale((segment.startDynamicDuration ?? 0) / secondsInDay) ?? 0);

          const shouldShowText = width > APPROX_LETTER_WIDTH_PX * segment.label.length + TEXT_PADDING;

          const textX = x + width / 2;

          return (
            <Tooltip
              key={`${segment.startDynamicDuration}-${segment.endDynamicDuration}-${deduplicationKey}-${i}-tooltip`}
              placement="top"
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={segment.label}
              arrowPointAtCenter
              destroyTooltipOnHide
            >
              <Group
                key={`${segment.startDynamicDuration}-${segment.endDynamicDuration}-${deduplicationKey}-${i}-group`}
              >
                <Bar
                  width={Number.isNaN(width) ? 0 : width}
                  x={Number.isNaN(x) ? 0 : x}
                  height={BAR_HEIGHT}
                  fill={segment.color}
                  y={`calc(100% - ${BAR_HEIGHT * (isAbove ? 2 : 1)}px)`}
                />

                {isLast ? (
                  <Bar
                    width={SEPARATOR_BAR_LENGTH_PX}
                    x={(Number.isNaN(x) ? 0 : x) + (Number.isNaN(width) ? 0 : width)}
                    height={BAR_HEIGHT}
                    fill={separatorColor}
                    y={`calc(100% - ${BAR_HEIGHT * (isAbove ? 2 : 1)}px)`}
                  />
                ) : null}

                {shouldShowText ? (
                  <text
                    fill={themeColors.primary_typography}
                    fontSize={14}
                    letterSpacing={-0.2}
                    pointerEvents="none"
                    textAnchor="middle"
                    x={Number.isNaN(textX) ? 0 : textX}
                    style={{ userSelect: "none" }}
                    y={textY}
                  >
                    {segment.label}
                  </text>
                ) : null}
              </Group>
            </Tooltip>
          );
        })}

      <Bar
        width={xScale(fillerWidth) + RIGHT_AXIS_PADDING * 2 - SEPARATOR_BAR_LENGTH_PX}
        x={xScale(fillerStartX) + paddingLeft + SEPARATOR_BAR_LENGTH_PX}
        height={BAR_HEIGHT}
        fill="transparent"
        y={`calc(100% - ${BAR_HEIGHT * (isAbove ? 2 : 1)}px)`}
      />
    </Group>
  );
};

WellSegmentBand.displayName = "WellSegmentBand";
