import { OperationClasses } from "utils/enums";

export const LENS_TITLE = "Power Load Efficiency";

export const operationTitlesByExternalId = {
  [OperationClasses.Drilling]: "Drilling Load Efficiency",
  [OperationClasses.Casing]: "Casing Load Efficiency",
  [OperationClasses.TrippingOut]: "Trip Out Load Efficiency",
  [OperationClasses.TrippingIn]: "Trip In Load Efficiency",
  [OperationClasses.Surface]: "Surface Load Efficiency",
  [OperationClasses.CementingandBOPWork]: "Cementing/BOP Load Efficiency",
  [OperationClasses.PUDP]: "PUDP Load Efficiency",
  [OperationClasses.LDDP]: "LDDP Load Efficiency",
  [OperationClasses.WellConditioningTreatment]: "Well Conditioning Load Efficiency",
};
