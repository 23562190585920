/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AllRigsQueryDto,
  OperationTimeDto,
  RigDto,
  RigSummaryDto,
} from '../models/index';
import {
    AllRigsQueryDtoFromJSON,
    AllRigsQueryDtoToJSON,
    OperationTimeDtoFromJSON,
    OperationTimeDtoToJSON,
    RigDtoFromJSON,
    RigDtoToJSON,
    RigSummaryDtoFromJSON,
    RigSummaryDtoToJSON,
} from '../models/index';

export interface ApiRigsOperationTimesPutRequest {
    allRigsQueryDto?: AllRigsQueryDto;
}

export interface ApiRigsSummariesPutRequest {
    allRigsQueryDto?: AllRigsQueryDto;
}

export interface ApiRigsWellIdsPutRequest {
    allRigsQueryDto?: AllRigsQueryDto;
}

/**
 * 
 */
export class RigsApi extends runtime.BaseAPI {

    /**
     */
    async apiRigsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RigDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Rigs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RigDtoFromJSON));
    }

    /**
     */
    async apiRigsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RigDto>> {
        const response = await this.apiRigsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRigsOperationTimesPutRaw(requestParameters: ApiRigsOperationTimesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperationTimeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Rigs/OperationTimes`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AllRigsQueryDtoToJSON(requestParameters['allRigsQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationTimeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiRigsOperationTimesPut(requestParameters: ApiRigsOperationTimesPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperationTimeDto> {
        const response = await this.apiRigsOperationTimesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRigsSummariesPutRaw(requestParameters: ApiRigsSummariesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RigSummaryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Rigs/Summaries`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AllRigsQueryDtoToJSON(requestParameters['allRigsQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RigSummaryDtoFromJSON));
    }

    /**
     */
    async apiRigsSummariesPut(requestParameters: ApiRigsSummariesPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RigSummaryDto>> {
        const response = await this.apiRigsSummariesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRigsWellIdsPutRaw(requestParameters: ApiRigsWellIdsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Rigs/WellIds`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AllRigsQueryDtoToJSON(requestParameters['allRigsQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiRigsWellIdsPut(requestParameters: ApiRigsWellIdsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apiRigsWellIdsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
