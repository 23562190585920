import { useAllRigsDataFeed } from "hooks/dataFeed/useAllRigsDataFeed";
import { useAllWellsDataFeed } from "hooks/dataFeed/useAllWellsDataFeed";
import { useRigsDataFeed } from "hooks/dataFeed/useRigsDataFeed";
import { useWellsDataFeed } from "hooks/dataFeed/useWellsDataFeed";
import { useDashboardType } from "hooks/useDashboardType";
import React from "react";
import { useMatch } from "react-router-dom";
import { PDRoutesMapping } from "routes";

export const BackgroundFeedFetcher = React.memo(() => {
  // TODO fragile implementation of route names, should be refactored
  const {
    isRig: isRigDashboard,
    isWell: isWellDashboard,
    isEvergreen: isEvergreenDashboard,
    isRootDashboardPage,
  } = useDashboardType();
  const matchesADetailedRoute = useMatch(PDRoutesMapping.private.lensDetailed);
  const matchesRigLeaderboard = useMatch(PDRoutesMapping.private.rigsLeaderboard);

  const isDetailed = matchesADetailedRoute !== null;
  const isRigLeaderboard = matchesRigLeaderboard !== null;
  useAllWellsDataFeed(location.pathname === PDRoutesMapping.private.allWells.path);
  useAllRigsDataFeed(isRigDashboard);
  useRigsDataFeed(isRigLeaderboard || isRigDashboard, isDetailed);
  useWellsDataFeed(isDetailed, (isWellDashboard || isEvergreenDashboard) && isRootDashboardPage);
  return null;
});
