import type { PieArcDatum, ProvidedProps } from "@visx/shape/lib/shapes/Pie";
import type { DataType } from "components/Lenses/ContainerLens/WedgeKpi/interfaces";
import { type MouseEvent } from "react";
import { animated, interpolate, useTransition } from "react-spring";
import colors from "utils/colors";
import { APPROX_CHAR_WIDTH } from "utils/constants";
const defaultMargin = { top: 50, right: 20, bottom: 50, left: 20 };

export type PieProps = {
  datasetNames: string[];
  dataset: DataType[][];
  width: number;
  height: number;
  margin?: typeof defaultMargin;
  labels?: boolean;
  animate?: boolean;
  detailedView?: boolean;
};

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<unknown>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<unknown>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  labels?: boolean;
  getValueByKey: (d: PieArcDatum<Datum>) => string | number;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onMouseDatum: (e: MouseEvent, d: PieArcDatum<Datum>) => void;
  onMouseLeaveDatum: (d: PieArcDatum<Datum>) => void;
  delay?: number;
  radius?: number;
  thickness?: number;
};

export function AnimatedPie<Datum>({
  animate,
  labels,
  radius,
  arcs,
  path,
  getKey,
  getValueByKey,
  getColor,
  onMouseDatum,
  onMouseLeaveDatum,
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const meanArcAngle = ((arc.startAngle + arc.endAngle) / 2) * (180 / Math.PI);
    const arcAngle = ((arc.endAngle - arc.startAngle) / 2) * (180 / Math.PI);
    const arcLength = (2 * Math.PI * (radius || 0) * arcAngle) / 360;
    const hasSpaceForLabel = arcLength > getValueByKey(arc)?.toLocaleString().length * APPROX_CHAR_WIDTH;
    return (
      <g key={key}>
        <animated.path
          // TODO remove this as it will be deprecated
          // compute interpolated path d attribute from intermediate angle values
          d={interpolate([props.startAngle, props.endAngle], (startAngle: number, endAngle: number) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            }),
          )}
          fill={getColor(arc)}
          onMouseMove={(e) => onMouseDatum(e, arc)}
          onMouseLeave={() => onMouseLeaveDatum(arc)}
        />

        {labels && getValueByKey(arc) && hasSpaceForLabel ? (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill={colors.white}
              x={centroidX}
              y={centroidY}
              dy=".33em"
              fontSize={`14px`}
              textAnchor="middle"
              pointerEvents="none"
              transform={`rotate(${meanArcAngle}, ${centroidX}, ${centroidY})`}
              fontWeight={500}
            >
              {`${getValueByKey(arc)}%`}
            </text>
          </animated.g>
        ) : null}
      </g>
    );
  });
}

