import type { FiltersDto, RigCardAverages, RigCardFactDto } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { URL_STATE_PARAM } from "hooks/navigation/useQueryState";
import { BenchmarkType, useScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";
import superjson from "superjson";
import colors from "utils/colors";

import type { CustomTagProps } from "./components/CustomTag";

export const scoreColors = {
  success: colors.green_fluorite,
  danger: colors.coral_expression,
  invalid: colors.marble_green_grey,
  warning: colors.helen_of_troy,
} as const;

const SCORE_LOWER_LIMIT = 0.85;
const SCORE_UPPER_LIMIT = 1;

export const SCORECARD_HEIGHT = 182;
export const SCORECARD_HEIGHT_WELL = 210;
export const SCORECARD_PADDING = 8;
export const SCORECARD_AXIS_HEIGHT = 30;
export const SCORECARD_HORIZONTAL_PADDING = 40;
export const SCORECARD_MIN_TITLE_HEIGHT = 47;

export const barOpacity = {
  default: 1,
  transparent: 0.2,
};

export const constructDetailedUrl = (
  wellId: number,
  targetLensId?: number,
  filters?: FiltersDto,
  rigId?: string,
  crtFilters?: string,
) => {
  const filtersCurrent = (crtFilters ?? "")
    .split("&")
    .filter((e) =>
      [URL_STATE_PARAM.SELECTED_WELLS_RIG_SCORECARD, URL_STATE_PARAM.SELECTED_OPERATORS_RIG_SCORECARD].includes(
        e.split("=")[0] as URL_STATE_PARAM,
      ),
    )
    .join("&");
  // We need the system path to wire the back-button
  return `/well/${wellId}/${targetLensId}/${rigId}/system?filtersWW=${window.btoa(
    superjson.stringify({
      directionalIntervals: filters?.directionalIntervalIds ?? null,
      holeSizes: filters?.holeSizeIds ?? null,
      sections: filters?.sectionIds ?? null,
      operationTime: [
        filters?.includeFlatTime ? "Flat Time" : null,
        filters?.includeSlidingTime ? "Sliding Time" : null,
        filters?.includeRotatingTime ? "Rotating Time" : null,
      ].filter((e) => e),
    }),
  )}${filtersCurrent ? `&${filtersCurrent}` : ""}`;
};

export const getColorByScore: (score: number) => string = (score) => {
  if (score === undefined || score <= 0) {
    return scoreColors.invalid;
  }
  if (score >= SCORE_LOWER_LIMIT && score <= SCORE_UPPER_LIMIT) {
    return scoreColors.warning;
  }
  if (score >= SCORE_UPPER_LIMIT) {
    return scoreColors.success;
  }
  if (score < SCORE_LOWER_LIMIT) {
    return scoreColors.danger;
  }
  return scoreColors.invalid;
};

export const getPercentByScore: (score: number, decimals?: number) => string = (score, decimals = 0) => {
  return Number.isFinite(score) ? `${(score * 100).toFixed(decimals)}%` : "- -";
};

export const getHoursByNetTime: (time: number) => string = (time) => {
  return time ? `${(time / 3600).toFixed(1)} hr` : "- -";
};

export const getColorByNetTime: (value: number) => keyof typeof scoreColors = (value) => {
  if (!value) {
    return "invalid";
  }
  if (value >= 0) {
    return "success";
  }
  return "danger";
};

export const useScoreCardTags = (options?: { borderless?: boolean }) => {
  const { scoreBenchmark } = useScoreBenchmarkContext();
  const scoreKey = scoreBenchmark === BenchmarkType.OperatorTarget ? "operatorScore" : "rigScore";
  const opportunityKey = scoreBenchmark === BenchmarkType.OperatorTarget ? "targetDeltaTime" : "invisibleLostTime";
  const timeSavedKey = scoreBenchmark === BenchmarkType.OperatorTarget ? "savedTime" : "savedTime";
  const getTagsByAveragesOrFact: ({
    averages,
    downtime,
    fact,
  }: {
    averages?: RigCardAverages;
    downtime?: number;
    fact?: RigCardFactDto;
  }) => {
    score: CustomTagProps;
    opportunityTime: CustomTagProps;
    timeSaved: CustomTagProps;
    downtime: CustomTagProps;
  } = ({ averages, downtime, fact }) => {
    return {
      score: {
        color: getColorByScore((averages ? averages[scoreKey] : fact?.[scoreKey]?.value) || 0),
        icon: <PDComponent.SvgIcon name="meter" />,
        text: getPercentByScore((averages ? averages[scoreKey] : fact?.[scoreKey]?.value) || 0),
        variant: options?.borderless ? "borderless" : "default",
        tooltipText: "Score",
      },
      opportunityTime: {
        color:
          scoreColors[getColorByNetTime((averages ? averages[opportunityKey] : fact?.[opportunityKey]?.value) || 0)],
        icon: <PDComponent.SvgIcon name="chartWinLoss" />,
        text: getHoursByNetTime((averages ? averages[opportunityKey] : fact?.[opportunityKey]?.value) || 0),
        variant: options?.borderless ? "borderless" : "default",
        tooltipText: scoreBenchmark === BenchmarkType.PDBenchmark ? "Time vs Benchmark" : "Time vs Target",
      },
      timeSaved: {
        color: scoreColors[getColorByNetTime((averages ? averages[timeSavedKey] : fact?.[timeSavedKey]?.value) || 0)],
        icon: <PDComponent.SvgIcon name="time" />,
        text: getHoursByNetTime((averages ? averages[timeSavedKey] : fact?.[timeSavedKey]?.value) || 0),
        variant: options?.borderless ? "borderless" : "default",
        tooltipText: "Time vs Base",
      },
      downtime: {
        color: scoreColors["invalid"],
        icon: <PDComponent.SvgIcon name="pause" />,
        text: getPercentByScore(downtime || 0, 1),
        variant: options?.borderless ? "borderless" : "default",
        tooltipText: "Downtime",
      },
    };
  };
  return { getTagsByAveragesOrFact };
};
