/* eslint-disable react/no-multi-comp */
import { useMutation } from "@tanstack/react-query";
import type { ProfileInfoDto } from "apis/oag";
import { DashboardsApi, DashboardType, ProfileOriginType } from "apis/oag";
import { CustomSwitch } from "atoms/common";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { useConfirmModal } from "components/ConfirmModal";
import { FormGroup } from "components/General/FormGroup";
import { ActionsHeader, PlanSectionLayout } from "components/Layout/Tabbed";
import { PDComponent } from "components/PDComponents";
import Pane from "components/WellEditor/Pane";
import { useProfiles } from "hooks/admin/useProfile";
import { useState } from "react";
import styled from "styled-components";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { Col, Divider, Row, Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

import { Loading } from "./components/Loading";
import { UserSelector } from "./components/UserSelector";

const dashboardsApi = new DashboardsApi(apiConfig);

const StyledCheckIcon = styled(PDComponent.SvgIcon).attrs({ name: "checkmarkOutline" })`
  font-size: 24px;
  color: ${colors.turtle_green};
`;

const StyledCrossIcon = styled(PDComponent.SvgIcon).attrs({ name: "closeFilled" })`
  font-size: 24px;
  color: ${colors.coral_expression};
`;

const StyledOptions = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
`;

const StyledSpace = styled(Space)`
  display: flex;
  justify-content: end;
  padding-top: 12px;
`;

export const DashboardLayout = ({ onCancel }: { onCancel: () => void }) => {
  const { atomThemeVariant } = useCustomTheme();
  const { data: allProfiles, isLoading } = useProfiles();
  const [excludeHiddenTabs, setExcludeHiddenTabs] = useState(true);

  const availableDashboards: DashboardType[] = [DashboardType.Well, DashboardType.Rig, DashboardType.EverGreen];
  const [selectedDashboards, setSelectedDashboards] = useState<DashboardType[]>([]);

  const [selectedProfiles, setSelectedProfiles] = useState<{
    from: ProfileInfoDto | null;
    to: ProfileInfoDto | null;
  }>({
    to: null,
    from: null,
  });

  const handleCopyLayout = useMutation({
    mutationFn: () =>
      dashboardsApi.apiDashboardsCopyPut({
        dashboardCopyDto: {
          sourceOriginType: [ProfileOriginType.Personal, ProfileOriginType.Shared],
          targetOriginType: ProfileOriginType.Personal,
          sourceProfileId: selectedProfiles.from?.profileId || -1,
          targetProfileId: selectedProfiles.to?.profileId || -1,
          types: selectedDashboards,
          excludeHiddenTabs: excludeHiddenTabs,
        },
      }),
  });

  const { confirmModalElement, openConfirmModal } = useConfirmModal({
    title: "Proceed with action?",
    description:
      "By confirming this action, you’ll permanently change this user’s dashboards layout. This action is irreversible.",
    onConfirm: () => {
      handleCopyLayout.mutate();
    },
    hasSuccessColor: true,
  });

  if (isLoading) {
    return null;
  }

  const OptionsSection = () => {
    if (handleCopyLayout.status === "error") {
      return (
        <Row justify="space-between" gutter={[12, 12]} align="middle">
          <Col>
            <Row justify="start" gutter={[12, 12]} align="middle">
              <Col>
                <StyledCrossIcon />
              </Col>
              <Col>
                <Title level={3}>Something went wrong</Title>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row justify="end" gutter={[12, 12]} align="middle">
              <Col>
                <Button
                  onClick={() => {
                    handleCopyLayout.reset();
                    setSelectedDashboards([]);
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col>
                <Button onClick={() => handleCopyLayout.mutate()} type="primary">
                  Retry
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }

    if (handleCopyLayout.status === "success") {
      return (
        <Row justify="space-between" gutter={[12, 12]} align="middle">
          <Col>
            <Row justify="start" gutter={[12, 12]} align="middle">
              <Col>
                <StyledCheckIcon />
              </Col>
              <Col>
                <Title level={3}>Changes applied successfully</Title>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              onClick={() => {
                handleCopyLayout.reset();
                setSelectedDashboards([]);
              }}
            >
              Back
            </Button>
          </Col>
        </Row>
      );
    }

    if (handleCopyLayout.status === "pending") {
      return <Loading />;
    }

    return (
      <>
        <Row
          style={{
            display: "flex",
            gap: 24,
            flexDirection: "row",
          }}
        >
          <div
            style={{
              marginTop: 0,
            }}
          >
            <FormGroup label="From">
              <UserSelector
                width={550}
                profileList={allProfiles || []}
                selectedProfile={selectedProfiles.from}
                otherProfileId={-1}
                setSelectedProfile={(profile: ProfileInfoDto) =>
                  setSelectedProfiles((prev) => ({ ...prev, from: profile }))
                }
              />
            </FormGroup>
          </div>
          <FormGroup label="To">
            <UserSelector
              width={550}
              profileList={allProfiles || []}
              selectedProfile={selectedProfiles.to}
              otherProfileId={selectedProfiles.from?.profileId || -1}
              setSelectedProfile={(profile: ProfileInfoDto) =>
                setSelectedProfiles((prev) => ({ ...prev, to: profile }))
              }
            />
          </FormGroup>
        </Row>
        <Divider />
        {availableDashboards.map((dashboard) => {
          const isChecked = selectedDashboards.includes(dashboard);
          return (
            <StyledOptions key={dashboard}>
              <Col>{dashboard} Dashboard</Col>
              <Col>
                <Row gutter={[12, 0]} align="middle" justify="end">
                  <CustomSwitch
                    $isChecked={isChecked}
                    checked={isChecked}
                    onChange={() =>
                      setSelectedDashboards(
                        isChecked
                          ? selectedDashboards.filter((d) => d !== dashboard)
                          : [...selectedDashboards, dashboard],
                      )
                    }
                  />
                  <Col>
                    <Title level={3}>{isChecked ? "YES" : "NO"}</Title>
                  </Col>
                </Row>
              </Col>
            </StyledOptions>
          );
        })}
        <StyledOptions>
          <Col>Exclude hidden tabs</Col>
          <Col>
            <Row gutter={[12, 0]} align="middle" justify="end">
              <CustomSwitch
                $isChecked={excludeHiddenTabs}
                checked={excludeHiddenTabs}
                onChange={() => setExcludeHiddenTabs(!excludeHiddenTabs)}
              />
              <Col>
                <Title level={3}>{excludeHiddenTabs ? "YES" : "NO"}</Title>
              </Col>
            </Row>
          </Col>
        </StyledOptions>
        <StyledSpace size={12}>
          <Button trackingName="Cancel copy dashboard" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            trackingName="Copy dashboard applied"
            onClick={openConfirmModal}
            disabled={
              [selectedProfiles.from, selectedProfiles.to].some((x) => x === null) || selectedDashboards.length === 0
            }
          >
            Apply
          </Button>
        </StyledSpace>
      </>
    );
  };
  return (
    <PlanSectionLayout
      header={
        <ActionsHeader>
          <Row gutter={12} justify="space-between" align="middle" style={{ height: "64px" }}>
            <Col flex="0 auto">
              <Title level={3} variant={atomThemeVariant}>
                Choose a user dashboard layout to apply. This Action is Irreversible after confirmation.
              </Title>
            </Col>
          </Row>
        </ActionsHeader>
      }
      content={
        <>
          <Pane title="Copy Dashboard Layout" childrenSpan={16}>
            <OptionsSection />
          </Pane>

          {confirmModalElement}
        </>
      }
    />
  );
};
