export const LEFT_TITLE_WIDTH = 64;
export const LEFT_AXIS_WIDTH = 60;
export const LEFT_AXIS_WIDTH_DETAILED = 80;
export const LEFT_COLUMN_WIDTH = LEFT_TITLE_WIDTH + LEFT_AXIS_WIDTH * 2;
export const LEFT_COLUMN_WIDTH_DETAILED = LEFT_TITLE_WIDTH + LEFT_AXIS_WIDTH_DETAILED * 2;
export const LEFT_AXIS_OFFSET = 44;
export const LEFT_AXIS_OFFSET_DETAILED = 60;

export const MIN_TRACK_HEIGHT = 88;
export const BOTTOM_AXIS_HEIGHT = 32;
export const APPROX_TICK_LENGTH = 16;
export const TICK_GAP = 8;
export const CHART_TOP_PADDING = 20;
export const CHART_BOTTOM_PADDING = 10;

export const VERTICAL_UNIT_HEIGHT = 18;

export const HEADER_HEIGHT = 44;
export const DETAILED_HEADER_HEIGHT = 64;
export const SUMMARY_HEIGHT = 78;
export const ZT_CHART_HEIGHT = 46;
export const ZT_BAR_HEIGHT = 8;
export const ZT_TOOLTIP_TOP_OFFSET = -20;

export const TOOLTIP_WIDTH = 250;
export const TOOLTIP_HORIZONTAL_PADDING = 28;
export const TOOLTIP_HEIGHT = 66;
