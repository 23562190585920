import type { PivotOrderType } from "apis/oag";
import { PivotType, WellStatusType } from "apis/oag";
import { LegendAtom } from "components/Legend/LegendAtom";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { getDefaultPivotOrderTypeByPivot } from "components/RigComparisonSelectors/DisplayOptionsModal";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useCrewMembers } from "hooks/useCrewMembers";
import { useDirectionalIntervals } from "hooks/useDirectionalIntervals";
import { useHoleSections } from "hooks/useHoleSections";
import { useHoleSizes } from "hooks/useHoleSizes";
import { useRigs } from "hooks/useRigs";
import { useRigToWells } from "hooks/useRigToWells";
import { useSelectedRig } from "hooks/useSelectedRig";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { useWellSummaries } from "hooks/useWellSummaries";
import { useCallback, useEffect, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { RIG_LEGEND_IDS } from "utils/common";
import { useCustomHoleSizeUom } from "utils/format";
import { getCurrentPivot, isPivotColor, useColors } from "utils/useColors";

export const Legend = () => {
  const { getColor } = useColors();

  const selectedRig = useSelectedRig();

  const [offsetRigs] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET, []);
  const [{ pivot: displayOptions }] = useStateQuery<{
    pivot: Array<PivotType>;
    order: Array<PivotOrderType>;
  }>(URL_STATE_PARAM.DATA_GROUPS_WIDGET, {
    pivot: [PivotType.Day, PivotType.None],
    order: [PivotType.Day, PivotType.None].map((e) => getDefaultPivotOrderTypeByPivot(e)),
  });
  const [realtimeDataDashboard, setRealTime] = useStateQuery(
    URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.UNAVAILABLE,
    [URL_STATE_PARAM.REALTIME_DATA_WIDGET],
  );

  const legendColorList = useAppSelector((state) => state.legendColor.colorById);
  const { data: availableWellsRig } = useRigToWells();
  const { data: wellInfo } = useWellShortInfoSuspended();

  useWellSummaries(true); // Added just to have the last update date in the state

  useEffect(() => {
    if (!wellInfo?.byId) return;
    if (realtimeDataDashboard !== RealTimeDataEnum.UNAVAILABLE) return;
    if (availableWellsRig?.some((wellId) => wellInfo?.byId[wellId]?.status === WellStatusType.Active))
      setRealTime(RealTimeDataEnum.ACTIVE);
  }, [availableWellsRig, realtimeDataDashboard, setRealTime, wellInfo]);

  const { data: crewMembers } = useCrewMembers();
  const { data: holeSizes } = useHoleSizes();
  const { data: holeSections } = useHoleSections();
  const { data: directionalIntervals } = useDirectionalIntervals();
  const { data: rigs } = useRigs({});
  const holeSizeUom = useCustomHoleSizeUom();
  const legendName = useCallback(
    (key: string) => {
      if (offsetRigs.length > 0) {
        const rig = (rigs?.list ?? []).find((e) => e.id === +key);
        return rig?.shortName;
      }
      // TODO this assignment of positions 0 and 1 can be detrimental in the future; check again
      const pivotType = getCurrentPivot({
        group: displayOptions[0],
        subGroup: displayOptions[1],
        isComparing: offsetRigs.length > 0,
      });
      switch (pivotType) {
        case PivotType.Rig:
          return (rigs?.list ?? []).find((e) => e.id === +key)?.shortName;
        case PivotType.Driller:
          return (crewMembers ?? []).find((e) => e.id === +key)?.name;
        case PivotType.HoleSize: {
          const holeSize = (holeSizes ?? []).find((e) => e.id === +key)?.value;
          return holeSize && holeSize >= 0 ? holeSizeUom.display(+holeSize, { metricOption: true }) : "Unknown";
        }
        case PivotType.HoleSection:
          return (holeSections ?? []).find((e) => e.id === +key)?.name;
        case PivotType.DirectionalInterval:
          return (directionalIntervals ?? []).find((e) => e.id === +key)?.name;
        default:
          return null;
      }
    },
    [
      offsetRigs.length,
      displayOptions,
      rigs?.list,
      crewMembers,
      holeSizes,
      holeSizeUom,
      holeSections,
      directionalIntervals,
    ],
  );
  const rigsArr = useMemo(() => [selectedRig, ...offsetRigs], [offsetRigs, selectedRig]);

  return (
    <>
      {Array.from(legendColorList.keys())
        .filter((e) => {
          if (offsetRigs.length > 0) return rigsArr.includes(+e);
          // TODO this assignment of positions 0 and 1 can be detrimental in the future; check again
          const pivotType: PivotType = getCurrentPivot({
            group: displayOptions[0],
            subGroup: displayOptions[1],
            isComparing: offsetRigs.length > 0,
          });
          if (isPivotColor(pivotType) && pivotType === PivotType.Rig) return e === selectedRig.toString();
          return true;
        })
        .sort((a, b) => {
          if (a === selectedRig.toString()) return -1;
          if (b === selectedRig.toString()) return 1;
          return offsetRigs.indexOf(+a) - offsetRigs.indexOf(+b);
        })
        .map((key) => {
          return (
            legendName(key) && (
              <LegendAtom
                key={key}
                wellName={legendName(key) || ""}
                wellInfo={""}
                disabled={!legendColorList.get(key)?.enabled}
                selected={false}
                id={RIG_LEGEND_IDS}
                color={getColor({
                  key,
                  currentPivot: getCurrentPivot({
                    group: displayOptions[0],
                    subGroup: displayOptions[1],
                    isComparing: offsetRigs.length > 0,
                  }),
                })}
              />
            )
          );
        })}
    </>
  );
};
