import type { DashboardType } from "apis/oag";
import { useUserDashboard } from "hooks/useUserLensTabs";
import { useMemo } from "react";

export const useMatchTab = (dashboardType: DashboardType, tabId: number | null) => {
  const { data: lensTabsDashboard } = useUserDashboard(dashboardType);

  const personalTabs = useMemo(() => lensTabsDashboard?.personalTabs || [], [lensTabsDashboard]);
  const operatorTabs = useMemo(() => lensTabsDashboard?.operatorTabs || [], [lensTabsDashboard]);
  const flatLensTabs = useMemo(() => [...operatorTabs, ...personalTabs], [operatorTabs, personalTabs]);

  const selectedTab = flatLensTabs.find((tab) => tab.id === tabId);
  return selectedTab;
};
