/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RigScorecardComplianceRate
 */
export interface RigScorecardComplianceRate {
    /**
     * 
     * @type {number}
     * @memberof RigScorecardComplianceRate
     */
    readonly operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigScorecardComplianceRate
     */
    readonly rigScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigScorecardComplianceRate
     */
    readonly improvementScore?: number | null;
}

/**
 * Check if a given object implements the RigScorecardComplianceRate interface.
 */
export function instanceOfRigScorecardComplianceRate(value: object): value is RigScorecardComplianceRate {
    return true;
}

export function RigScorecardComplianceRateFromJSON(json: any): RigScorecardComplianceRate {
    return RigScorecardComplianceRateFromJSONTyped(json, false);
}

export function RigScorecardComplianceRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigScorecardComplianceRate {
    if (json == null) {
        return json;
    }
    return {
        
        'operatorScore': json['OperatorScore'] == null ? undefined : json['OperatorScore'],
        'rigScore': json['RigScore'] == null ? undefined : json['RigScore'],
        'improvementScore': json['ImprovementScore'] == null ? undefined : json['ImprovementScore'],
    };
}

export function RigScorecardComplianceRateToJSON(value?: Omit<RigScorecardComplianceRate, 'OperatorScore'|'RigScore'|'ImprovementScore'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

