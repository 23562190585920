/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ZTorqueOnOffPair
 */
export interface ZTorqueOnOffPair {
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    on: number;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    off: number;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    readonly onRatio: number;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    readonly offRatio: number;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    readonly diff: number;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueOnOffPair
     */
    readonly diffPct: number;
}

/**
 * Check if a given object implements the ZTorqueOnOffPair interface.
 */
export function instanceOfZTorqueOnOffPair(value: object): value is ZTorqueOnOffPair {
    if (!('on' in value) || value['on'] === undefined) return false;
    if (!('off' in value) || value['off'] === undefined) return false;
    if (!('onRatio' in value) || value['onRatio'] === undefined) return false;
    if (!('offRatio' in value) || value['offRatio'] === undefined) return false;
    if (!('diff' in value) || value['diff'] === undefined) return false;
    if (!('diffPct' in value) || value['diffPct'] === undefined) return false;
    return true;
}

export function ZTorqueOnOffPairFromJSON(json: any): ZTorqueOnOffPair {
    return ZTorqueOnOffPairFromJSONTyped(json, false);
}

export function ZTorqueOnOffPairFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZTorqueOnOffPair {
    if (json == null) {
        return json;
    }
    return {
        
        'on': json['On'],
        'off': json['Off'],
        'onRatio': json['OnRatio'],
        'offRatio': json['OffRatio'],
        'diff': json['Diff'],
        'diffPct': json['DiffPct'],
    };
}

export function ZTorqueOnOffPairToJSON(value?: Omit<ZTorqueOnOffPair, 'OnRatio'|'OffRatio'|'Diff'|'DiffPct'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'On': value['on'],
        'Off': value['off'],
    };
}

