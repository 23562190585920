import { usePreviousNonNullValue } from "hooks/usePreviousNonNullValue";
import { useLayoutEffect } from "react";
import { useAppSelector } from "reducers/store";

export const useImperativeScrollOnLegendItemSelected = (selected: boolean, selectedId: number, cb: () => void) => {
  const scrolledSeries = useAppSelector((state) => state.timeline.scrollToLegendSeries);
  const previouslyScrolledSeries = usePreviousNonNullValue(scrolledSeries);

  useLayoutEffect(() => {
    if (selected && selectedId === scrolledSeries && scrolledSeries !== previouslyScrolledSeries) {
      cb();
    }
  }, [cb, previouslyScrolledSeries, scrolledSeries, selected, selectedId]);
};
