import type { PropsWithChildren } from "react";
import React, { useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useState } from "react";

export interface ScrollHintContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  maxScrollHintSpread?: number;
  scrollbarClipWidth?: number;
  disabled?: boolean;
}

export const _ScrollHintContainer = React.forwardRef<HTMLDivElement, PropsWithChildren<ScrollHintContainerProps>>(
  ({ children, maxScrollHintSpread = 80, scrollbarClipWidth = 6, disabled, ...restProps }, outerRef) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [pxToTopAndBottom, setPxToTopAndBottom] = useState({ pxToTop: 0, pxToBottom: 0 });

    // To preserve the ability to add ref to the container, just as you would on any other div
    useImperativeHandle(outerRef, () => containerRef.current!, []);

    const adjustScrollHints = useCallback(() => {
      if (typeof containerRef === "function" || disabled) return;

      if (containerRef?.current) {
        setPxToTopAndBottom({
          pxToTop: containerRef.current.scrollTop,
          pxToBottom:
            containerRef.current.scrollHeight - containerRef.current.clientHeight - containerRef.current.scrollTop,
        });
      }
    }, [disabled]);

    const [topMaskSize, bottomMaskSize] = useMemo(
      () => [
        Math.min(maxScrollHintSpread, pxToTopAndBottom.pxToTop),
        Math.min(maxScrollHintSpread, pxToTopAndBottom.pxToBottom),
      ],
      [maxScrollHintSpread, pxToTopAndBottom.pxToBottom, pxToTopAndBottom.pxToTop],
    );

    useLayoutEffect(() => {
      adjustScrollHints();
    }, [adjustScrollHints, containerRef.current?.clientHeight]);

    useEffect(() => {
      if (disabled) return;
      window.addEventListener("resize", adjustScrollHints);
      () => window.removeEventListener("resize", adjustScrollHints);
    }, [adjustScrollHints, disabled]);

    return disabled ? (
      <div {...restProps} ref={containerRef}>
        {children}
      </div>
    ) : (
      <div
        {...restProps}
        ref={containerRef}
        onScroll={(ev) => {
          adjustScrollHints();
          restProps.onScroll?.(ev);
        }}
        // eslint-disable-next-line react/forbid-dom-props
        style={{
          // Inline because otherwise react generates a new class name on every change
          ...(restProps.style ? restProps.style : {}),
          maskImage: `linear-gradient(to bottom, transparent 0%, black ${topMaskSize}px, black calc(100% - ${bottomMaskSize}px), transparent 100%), linear-gradient(to right, transparent calc(100% - ${scrollbarClipWidth}px), white ${scrollbarClipWidth}px)`,
        }}
      >
        {children}
      </div>
    );
  },
);
