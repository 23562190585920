import type { ApiRigPowerUserLensesIdFactsPutRequest } from "apis/oag";
import { useRigPowerFacts } from "hooks/lens-facts/useRigPowerFacts";
import { useFilterParams } from "hooks/useFilterParams";

import { RIG_POWER_SAMPLE_COUNT } from "./utils";

interface UseRigPowerStateFetcherParams {
  lensId: number;
  comparisonWellIds?: number[];
}

export function useRigPowerFactsFetcher({ lensId }: UseRigPowerStateFetcherParams) {
  const filterParams = useFilterParams();
  filterParams.sampleCount = RIG_POWER_SAMPLE_COUNT;

  const requestParameters: ApiRigPowerUserLensesIdFactsPutRequest = {
    id: lensId ?? -1,
    rigPowerQueryDto: filterParams,
  };
  const request = useRigPowerFacts(lensId, requestParameters, {
    enabled: !!lensId,
    refetchOnMount: false,
  });

  return { request };
}
