import { Button } from "atoms/Form";
import type { FC } from "react";
import styled from "styled-components";
import colors from "utils/colors";

const StyledKPIContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  .ant-btn-primary {
    :hover {
      color: ${colors.white} !important;
    }
  }
`;

const StyledRig = styled.img`
  width: 219px;
  height: 212px;
  margin-top: 32px;
`;

const StyledEvergreenLogo = styled.img`
  margin-top: 32px;
  max-width: 100%;
  height: 18px;
`;

const StyledTagline = styled.div`
  margin-top: 16px;
  font-size: 24px;
  font-weight: 500;
  color: ${colors.park_picnic};
`;
const StyledText = styled.div`
  margin: 16px 0;
  font-size: 14px;
  color: ${colors.gray};
`;

const KPIsContainer: FC = () => {
  return (
    <StyledKPIContainer>
      <StyledRig src="/assets/images/evergreen-rig.png" alt="evergreen rig" />
      <div>
        <StyledEvergreenLogo src="/assets/logos/evergreen-full.webp" alt="evergreen logo" />
        <StyledTagline>Making Your Operation More Sustainable</StyledTagline>
        <StyledText>
          Discover how EverGreen™ environmental solutions can help facilitate your company's environmental stewardship.
        </StyledText>
        <a href="https://www.precisiondrilling.com/drilling-technology/evergreen/" target="_blank" rel="noreferrer">
          <Button type="primary">Learn More</Button>
        </a>
      </div>
    </StyledKPIContainer>
  );
};

export default KPIsContainer;
