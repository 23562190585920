/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlanActivityDto } from './PlanActivityDto';
import {
    PlanActivityDtoFromJSON,
    PlanActivityDtoFromJSONTyped,
    PlanActivityDtoToJSON,
} from './PlanActivityDto';

/**
 * 
 * @export
 * @interface PlanActivitySetDto
 */
export interface PlanActivitySetDto {
    /**
     * 
     * @type {number}
     * @memberof PlanActivitySetDto
     */
    startDepth: number;
    /**
     * 
     * @type {Array<PlanActivityDto>}
     * @memberof PlanActivitySetDto
     */
    activities: Array<PlanActivityDto>;
}

/**
 * Check if a given object implements the PlanActivitySetDto interface.
 */
export function instanceOfPlanActivitySetDto(value: object): value is PlanActivitySetDto {
    if (!('startDepth' in value) || value['startDepth'] === undefined) return false;
    if (!('activities' in value) || value['activities'] === undefined) return false;
    return true;
}

export function PlanActivitySetDtoFromJSON(json: any): PlanActivitySetDto {
    return PlanActivitySetDtoFromJSONTyped(json, false);
}

export function PlanActivitySetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanActivitySetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'startDepth': json['StartDepth'],
        'activities': ((json['Activities'] as Array<any>).map(PlanActivityDtoFromJSON)),
    };
}

export function PlanActivitySetDtoToJSON(value?: PlanActivitySetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StartDepth': value['startDepth'],
        'Activities': ((value['activities'] as Array<any>).map(PlanActivityDtoToJSON)),
    };
}

