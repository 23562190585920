import type { ScorecardKpiCommentExceptionInfoDto } from "apis/oag";
import { ScoreBenchmarkProvider } from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useCallback, useState } from "react";
import type { BaseNotificationVm } from "utils/notifications/mappers";

import { DetailedModal } from "./DetailedModal";
export function useDetailedNotificationModal() {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [notification, setNotification] = useState<BaseNotificationVm | ScorecardKpiCommentExceptionInfoDto | null>(
    null,
  );

  const openDetailedNotification = useCallback(
    (notification: BaseNotificationVm | ScorecardKpiCommentExceptionInfoDto) => {
      setIsVisible(true);
      setNotification(notification);
    },
    [],
  );

  const detailedNotificationElement = (
    <ScoreBenchmarkProvider>
      <DetailedModal
        key={notification?.id || -1}
        onCancel={() => {
          setIsVisible(false);
          setNotification(null);
        }}
        isVisible
        notification={notification}
      />
    </ScoreBenchmarkProvider>
  );

  return { openDetailedNotification, detailedNotificationElement, isModalOpen: isVisible };
}
