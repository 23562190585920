/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScoreSummaryLensDto
 */
export interface ScoreSummaryLensDto {
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryLensDto
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryLensDto
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryLensDto
     */
    lensId: number;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryLensDto
     */
    operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScoreSummaryLensDto
     */
    rigScore?: number | null;
}

/**
 * Check if a given object implements the ScoreSummaryLensDto interface.
 */
export function instanceOfScoreSummaryLensDto(value: object): value is ScoreSummaryLensDto {
    if (!('lensId' in value) || value['lensId'] === undefined) return false;
    return true;
}

export function ScoreSummaryLensDtoFromJSON(json: any): ScoreSummaryLensDto {
    return ScoreSummaryLensDtoFromJSONTyped(json, false);
}

export function ScoreSummaryLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreSummaryLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'targetDeltaTime': json['TargetDeltaTime'] == null ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': json['InvisibleLostTime'] == null ? undefined : json['InvisibleLostTime'],
        'lensId': json['LensId'],
        'operatorScore': json['OperatorScore'] == null ? undefined : json['OperatorScore'],
        'rigScore': json['RigScore'] == null ? undefined : json['RigScore'],
    };
}

export function ScoreSummaryLensDtoToJSON(value?: ScoreSummaryLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'TargetDeltaTime': value['targetDeltaTime'],
        'InvisibleLostTime': value['invisibleLostTime'],
        'LensId': value['lensId'],
        'OperatorScore': value['operatorScore'],
        'RigScore': value['rigScore'],
    };
}

