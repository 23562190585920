import { ErrorBoundary } from "@sentry/react";
import { ClaraMessageSource } from "apis/oag";
import { IndividualMessage } from "components/Clara/MessageThread/IndividualMessage";
import {
  IndividualMessageFallback,
  IndividualMessageLoading,
} from "components/Clara/MessageThread/MessageFallbacks";
import { ModelResponse } from "components/Clara/MessageThread/ModelResponse";
import { useClaraPrompts } from "hooks/clara/useClara";
import { Fragment, Suspense } from "react";
import { useAppSelector } from "reducers/store";

export const LastMessage = () => {
  const { pendingMessage: message, favorite, id } = useAppSelector((state) => state.claraReducer);
  const { data: prompts } = useClaraPrompts({
    select: (data) => data.filter((prompt) => prompt.type === "Favorite"),
  });
  const isFavorite = favorite || !!prompts?.find((prompt) => prompt.prompt === message);

  if (!message) return null;
  return (
    <Fragment>
      <IndividualMessage
        message={{
          message,
          source: ClaraMessageSource.User,
          id: id ?? Math.trunc(Math.random() * 1000),
          isFavorite,
        }}
      />

      <Suspense fallback={<IndividualMessageLoading />}>
        <ErrorBoundary
          fallback={({ resetError }) => (
            <IndividualMessageFallback
              message={{
                message,
                source: ClaraMessageSource.User,
                id: Math.trunc(Math.random() * 1000),
              }}
              resetError={() => {
                resetError();
              }}
            />
          )}
        >
          <ModelResponse />
        </ErrorBoundary>
      </Suspense>
    </Fragment>
  );
};
