/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScoreComplianceStateType } from './ScoreComplianceStateType';
import {
    ScoreComplianceStateTypeFromJSON,
    ScoreComplianceStateTypeFromJSONTyped,
    ScoreComplianceStateTypeToJSON,
} from './ScoreComplianceStateType';
import type { StandKpiSliceDto } from './StandKpiSliceDto';
import {
    StandKpiSliceDtoFromJSON,
    StandKpiSliceDtoFromJSONTyped,
    StandKpiSliceDtoToJSON,
} from './StandKpiSliceDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface StandKpiDetailsDto
 */
export interface StandKpiDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    standNumber: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    standId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StandKpiDetailsDto
     */
    startAt: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof StandKpiDetailsDto
     */
    endAt: DateDto;
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    startDepth: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    endDepth: number;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiDetailsDto
     */
    isDayShift: boolean;
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiDetailsDto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiDetailsDto
     */
    isOutlier: boolean;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof StandKpiDetailsDto
     */
    complianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {Array<StandKpiSliceDto>}
     * @memberof StandKpiDetailsDto
     */
    slices?: Array<StandKpiSliceDto> | null;
}



/**
 * Check if a given object implements the StandKpiDetailsDto interface.
 */
export function instanceOfStandKpiDetailsDto(value: object): value is StandKpiDetailsDto {
    if (!('standNumber' in value) || value['standNumber'] === undefined) return false;
    if (!('standId' in value) || value['standId'] === undefined) return false;
    if (!('startAt' in value) || value['startAt'] === undefined) return false;
    if (!('endAt' in value) || value['endAt'] === undefined) return false;
    if (!('startDepth' in value) || value['startDepth'] === undefined) return false;
    if (!('endDepth' in value) || value['endDepth'] === undefined) return false;
    if (!('isDayShift' in value) || value['isDayShift'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('isOutlier' in value) || value['isOutlier'] === undefined) return false;
    if (!('complianceState' in value) || value['complianceState'] === undefined) return false;
    return true;
}

export function StandKpiDetailsDtoFromJSON(json: any): StandKpiDetailsDto {
    return StandKpiDetailsDtoFromJSONTyped(json, false);
}

export function StandKpiDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiDetailsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'standNumber': json['StandNumber'],
        'standId': json['StandId'],
        'startAt': DateDtoFromJSON(json['StartAt']),
        'endAt': DateDtoFromJSON(json['EndAt']),
        'startDepth': json['StartDepth'],
        'endDepth': json['EndDepth'],
        'isDayShift': json['IsDayShift'],
        'value': json['Value'],
        'targetValue': json['TargetValue'] == null ? undefined : json['TargetValue'],
        'isOutlier': json['IsOutlier'],
        'complianceState': ScoreComplianceStateTypeFromJSON(json['ComplianceState']),
        'slices': json['Slices'] == null ? undefined : ((json['Slices'] as Array<any>).map(StandKpiSliceDtoFromJSON)),
    };
}

export function StandKpiDetailsDtoToJSON(value?: StandKpiDetailsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StandNumber': value['standNumber'],
        'StandId': value['standId'],
        'StartAt': DateDtoToJSON(value['startAt']),
        'EndAt': DateDtoToJSON(value['endAt']),
        'StartDepth': value['startDepth'],
        'EndDepth': value['endDepth'],
        'IsDayShift': value['isDayShift'],
        'Value': value['value'],
        'TargetValue': value['targetValue'],
        'IsOutlier': value['isOutlier'],
        'ComplianceState': ScoreComplianceStateTypeToJSON(value['complianceState']),
        'Slices': value['slices'] == null ? undefined : ((value['slices'] as Array<any>).map(StandKpiSliceDtoToJSON)),
    };
}

