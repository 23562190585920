/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BarSliceDto } from './BarSliceDto';
import {
    BarSliceDtoFromJSON,
    BarSliceDtoFromJSONTyped,
    BarSliceDtoToJSON,
} from './BarSliceDto';
import type { PivotKey } from './PivotKey';
import {
    PivotKeyFromJSON,
    PivotKeyFromJSONTyped,
    PivotKeyToJSON,
} from './PivotKey';
import type { PivotType } from './PivotType';
import {
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';
import type { ShiftType } from './ShiftType';
import {
    ShiftTypeFromJSON,
    ShiftTypeFromJSONTyped,
    ShiftTypeToJSON,
} from './ShiftType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface PivotBarDto
 */
export interface PivotBarDto {
    /**
     * 
     * @type {PivotKey}
     * @memberof PivotBarDto
     */
    key: PivotKey;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotBarDto
     */
    pivotType: PivotType;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    averageValue: number;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    focalAverage: number;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    comparisonAverage: number;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    comparisonAverageRelativeDiff: number;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    operationCount: number;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {Array<BarSliceDto>}
     * @memberof PivotBarDto
     */
    slices: Array<BarSliceDto>;
    /**
     * 
     * @type {number}
     * @memberof PivotBarDto
     */
    readonly totalValue: number;
    /**
     * 
     * @type {boolean}
     * @memberof PivotBarDto
     */
    isOutlier: boolean;
    /**
     * 
     * @type {ShiftType}
     * @memberof PivotBarDto
     */
    shift: ShiftType;
    /**
     * 
     * @type {DateDto}
     * @memberof PivotBarDto
     */
    referenceDate: DateDto;
}



/**
 * Check if a given object implements the PivotBarDto interface.
 */
export function instanceOfPivotBarDto(value: object): value is PivotBarDto {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('pivotType' in value) || value['pivotType'] === undefined) return false;
    if (!('averageValue' in value) || value['averageValue'] === undefined) return false;
    if (!('focalAverage' in value) || value['focalAverage'] === undefined) return false;
    if (!('comparisonAverage' in value) || value['comparisonAverage'] === undefined) return false;
    if (!('comparisonAverageRelativeDiff' in value) || value['comparisonAverageRelativeDiff'] === undefined) return false;
    if (!('operationCount' in value) || value['operationCount'] === undefined) return false;
    if (!('slices' in value) || value['slices'] === undefined) return false;
    if (!('totalValue' in value) || value['totalValue'] === undefined) return false;
    if (!('isOutlier' in value) || value['isOutlier'] === undefined) return false;
    if (!('shift' in value) || value['shift'] === undefined) return false;
    if (!('referenceDate' in value) || value['referenceDate'] === undefined) return false;
    return true;
}

export function PivotBarDtoFromJSON(json: any): PivotBarDto {
    return PivotBarDtoFromJSONTyped(json, false);
}

export function PivotBarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotBarDto {
    if (json == null) {
        return json;
    }
    return {
        
        'key': PivotKeyFromJSON(json['Key']),
        'pivotType': PivotTypeFromJSON(json['PivotType']),
        'averageValue': json['AverageValue'],
        'focalAverage': json['FocalAverage'],
        'comparisonAverage': json['ComparisonAverage'],
        'comparisonAverageRelativeDiff': json['ComparisonAverageRelativeDiff'],
        'operationCount': json['OperationCount'],
        'targetValue': json['TargetValue'] == null ? undefined : json['TargetValue'],
        'slices': ((json['Slices'] as Array<any>).map(BarSliceDtoFromJSON)),
        'totalValue': json['TotalValue'],
        'isOutlier': json['IsOutlier'],
        'shift': ShiftTypeFromJSON(json['Shift']),
        'referenceDate': DateDtoFromJSON(json['ReferenceDate']),
    };
}

export function PivotBarDtoToJSON(value?: Omit<PivotBarDto, 'TotalValue'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Key': PivotKeyToJSON(value['key']),
        'PivotType': PivotTypeToJSON(value['pivotType']),
        'AverageValue': value['averageValue'],
        'FocalAverage': value['focalAverage'],
        'ComparisonAverage': value['comparisonAverage'],
        'ComparisonAverageRelativeDiff': value['comparisonAverageRelativeDiff'],
        'OperationCount': value['operationCount'],
        'TargetValue': value['targetValue'],
        'Slices': ((value['slices'] as Array<any>).map(BarSliceDtoToJSON)),
        'IsOutlier': value['isOutlier'],
        'Shift': ShiftTypeToJSON(value['shift']),
        'ReferenceDate': DateDtoToJSON(value['referenceDate']),
    };
}

