import { Title } from "atoms/Typography";
import { ListItemContainerSimpleSearch } from "components/PDComponents/Search/style";
import type { CSSProperties } from "react";
import { useCustomTheme } from "utils/useTheme";

interface ItemProps<T extends IAllowedOptions> {
  width?: number;
  selectedItem: string;
  onClick: (item: IOption<T>) => void;
  index: number;
  style: CSSProperties;
  data: {
    filteredOptions: IOption<T>[];
  };
  isScrolling?: boolean | undefined;
}

type IAllowedOptions = number | string;

export interface IOption<T extends IAllowedOptions> {
  id: T;
  name: string;
  component?: JSX.Element;
}

export function OptionItem<T extends IAllowedOptions>({
  style,
  index,
  data,
  width,
  onClick,
  selectedItem,
}: ItemProps<T>) {
  const item = data.filteredOptions[index];
  const { isDark } = useCustomTheme();
  return (
    <ListItemContainerSimpleSearch
      style={style}
      key={`${item.id}_${index}`}
      onClick={() => {
        onClick(item);
      }}
    >
      <Title
        level={3}
        variant={(() => {
          if (selectedItem === item.name) return "cta";
          if (isDark) return "white";
          return "dark";
        })()}
        style={{
          display: "inline-block",
          width: width ? width - 50 : "100%",
        }}
      >
        {item.component ?? item.name}
      </Title>
    </ListItemContainerSimpleSearch>
  );
}
