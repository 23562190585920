import styled from "styled-components";
import colors from "utils/colors";
import { Row } from "utils/componentLibrary";
import { PdCol } from "utils/componentLibrary/Col";
import { PdRow } from "utils/componentLibrary/Row";
import { zIndexLayer } from "utils/zIndex";

export const StyledContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  height: 20vh;

  ${PdCol} {
    width: 450px;
    border: solid 1px ${colors.widget_line};

    ${PdRow} {
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 20px 24px 24px;
      align-items: center;
      justify-content: space-between;

      .ant-typography {
        width: 250px;
        font-size: 16px !important;
      }
    }
  }
`;

export const ResizeContainer = styled.div`
  position: absolute;
  background: transparent;
  padding: 0;
  right: 4px;
  bottom: 4px;
  color: ${colors.gray};
  cursor: not-allowed;
  z-index: ${zIndexLayer.above};
`;
