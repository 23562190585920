import type { NotificationSubscriptionDto, RigSummaryDto } from "apis/oag";
import { NotificationType } from "apis/oag";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import { CheckboxCheckedState } from "components/PDComponents/Checkbox/Checkbox";
import { useCurrentUser } from "hooks/useCurrentUser";
import { NoContent } from "pages/NotificationSettings/NoContent/NoContent";
import { VirtualizedRigRow } from "pages/NotificationSettings/RigList/VirtualizedRigRow";
import { Suspense, useCallback, useMemo } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

import * as Styled from "./style";

export interface ChangedSubscription {
  rigId: number;
  checked: boolean;
}

export const RigListTable = ({
  rigsList,
  subscriptions,
  onCommentsChange,
  onExceptionsChange,
}: {
  rigsList: RigSummaryDto[];
  subscriptions: NotificationSubscriptionDto[];
  onCommentsChange: (changeSubs: ChangedSubscription[]) => void;
  onExceptionsChange: (changeSubs: ChangedSubscription[]) => void;
}) => {
  const { data: currentUser } = useCurrentUser();
  const allCommentsCheckboxState = useMemo(() => {
    const rigsWithCommentsCount = rigsList.reduce(
      (acc, rig) =>
        subscriptions.some(
          (subscription) =>
            subscription.rigId === rig.id && subscription.type.includes(NotificationType.ScorecardComment),
        )
          ? acc + 1
          : acc,

      0,
    );

    const isAllCommentsEnabled = rigsWithCommentsCount === rigsList.length;
    const isPartialCommentsEnabled = rigsWithCommentsCount > 0;

    if (isAllCommentsEnabled) return CheckboxCheckedState.Checked;
    if (isPartialCommentsEnabled) return CheckboxCheckedState.Indeterminate;
    return CheckboxCheckedState.Unchecked;
  }, [rigsList, subscriptions]);

  const allExceptionsCheckboxState = useMemo(() => {
    const rigsWithExceptionsCount = rigsList.reduce(
      (acc, rig) =>
        subscriptions.some(
          (subscription) =>
            subscription.rigId === rig.id && subscription.type.includes(NotificationType.ExceptionRequest),
        )
          ? acc + 1
          : acc,
      0,
    );

    const isAllCommentsEnabled = rigsWithExceptionsCount === rigsList.length;
    const isPartialCommentsEnabled = rigsWithExceptionsCount > 0;

    if (isAllCommentsEnabled) return CheckboxCheckedState.Checked;
    if (isPartialCommentsEnabled) return CheckboxCheckedState.Indeterminate;
    return CheckboxCheckedState.Unchecked;
  }, [rigsList, subscriptions]);

  const handleOnToggleCommentForRig = useCallback(
    (rigId: number, checked: boolean) => {
      onCommentsChange([{ rigId, checked }]);
    },
    [onCommentsChange],
  );

  const handleOnToggleExceptionForRig = useCallback(
    (rigId: number, checked: boolean) => {
      onExceptionsChange([{ rigId, checked }]);
    },
    [onExceptionsChange],
  );

  const handleOnSelectAllComments = useCallback(
    (checked: boolean) => {
      onCommentsChange(
        rigsList.map((rig) => ({
          rigId: rig.id,
          checked,
        })),
      );
    },
    [onCommentsChange, rigsList],
  );

  const handleOnSelectAllExceptions = useCallback(
    (checked: boolean) => {
      onExceptionsChange(
        rigsList.map((rig) => ({
          rigId: rig.id,
          checked,
        })),
      );
    },
    [onExceptionsChange, rigsList],
  );

  const rigIdsSubscribedComments = useMemo(() => {
    return rigsList
      .filter((rig) =>
        subscriptions.some((s) => s.rigId === rig.id && s.type.includes(NotificationType.ScorecardComment)),
      )
      .map((rig) => rig.id);
  }, [rigsList, subscriptions]);

  const rigIdsSubscribedExceptions = useMemo(() => {
    return rigsList
      .filter((rig) =>
        subscriptions.some((s) => s.rigId === rig.id && s.type.includes(NotificationType.ExceptionRequest)),
      )
      .map((rig) => rig.id);
  }, [rigsList, subscriptions]);

  return (
    <Styled.Container>
      {!rigsList?.length ? (
        <NoContent
          text={currentUser?.canAccessRigScorecard ? "No rigs matching filters" : "No notifications available"}
        />
      ) : (
        <>
          <Styled.Header>
            <Styled.Col />
            <Styled.Col>
              <Styled.CheckboxWithLabel>
                <label htmlFor="comments-select-all">
                  <b>Select All</b>
                </label>
                <PDComponent.Checkbox
                  id="comments-select-all"
                  checkedState={allCommentsCheckboxState}
                  onChange={handleOnSelectAllComments}
                  size={14}
                />
              </Styled.CheckboxWithLabel>
            </Styled.Col>

            <Styled.Col>
              <Styled.CheckboxWithLabel>
                <label htmlFor="exceptions-select-all">
                  <b>Select All</b>
                </label>
                <PDComponent.Checkbox
                  id="exceptions-select-all"
                  checkedState={allExceptionsCheckboxState}
                  onChange={handleOnSelectAllExceptions}
                  size={14}
                />
              </Styled.CheckboxWithLabel>
            </Styled.Col>
          </Styled.Header>

          <Styled.RowContainer>
            <Suspense fallback={<Loader withWrapper />}>
              <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                  <List
                    height={Number.isNaN(height) ? "100%" : height}
                    width={width}
                    itemSize={68}
                    itemCount={rigsList.length ?? 0}
                    itemData={{
                      rigsList,
                      handleOnToggleCommentForRig,
                      handleOnToggleExceptionForRig,
                      rigIdsSubscribedComments,
                      rigIdsSubscribedExceptions,
                    }}
                  >
                    {VirtualizedRigRow}
                  </List>
                )}
              </AutoSizer>
            </Suspense>
          </Styled.RowContainer>
        </>
      )}
    </Styled.Container>
  );
};
