/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClaraResponseVerdictType } from './ClaraResponseVerdictType';
import {
    ClaraResponseVerdictTypeFromJSON,
    ClaraResponseVerdictTypeFromJSONTyped,
    ClaraResponseVerdictTypeToJSON,
} from './ClaraResponseVerdictType';

/**
 * 
 * @export
 * @interface ClaraFeedbackDto
 */
export interface ClaraFeedbackDto {
    /**
     * 
     * @type {number}
     * @memberof ClaraFeedbackDto
     */
    messageId: number;
    /**
     * 
     * @type {ClaraResponseVerdictType}
     * @memberof ClaraFeedbackDto
     */
    verdict: ClaraResponseVerdictType;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClaraFeedbackDto
     */
    verdictReasons?: Array<string> | null;
}



/**
 * Check if a given object implements the ClaraFeedbackDto interface.
 */
export function instanceOfClaraFeedbackDto(value: object): value is ClaraFeedbackDto {
    if (!('messageId' in value) || value['messageId'] === undefined) return false;
    if (!('verdict' in value) || value['verdict'] === undefined) return false;
    return true;
}

export function ClaraFeedbackDtoFromJSON(json: any): ClaraFeedbackDto {
    return ClaraFeedbackDtoFromJSONTyped(json, false);
}

export function ClaraFeedbackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaraFeedbackDto {
    if (json == null) {
        return json;
    }
    return {
        
        'messageId': json['MessageId'],
        'verdict': ClaraResponseVerdictTypeFromJSON(json['Verdict']),
        'verdictReasons': json['VerdictReasons'] == null ? undefined : json['VerdictReasons'],
    };
}

export function ClaraFeedbackDtoToJSON(value?: ClaraFeedbackDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'MessageId': value['messageId'],
        'Verdict': ClaraResponseVerdictTypeToJSON(value['verdict']),
        'VerdictReasons': value['verdictReasons'],
    };
}

