/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StandKpiType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28,
    NUMBER_29: 29,
    NUMBER_30: 30,
    NUMBER_31: 31,
    NUMBER_32: 32,
    NUMBER_33: 33,
    NUMBER_34: 34,
    NUMBER_35: 35,
    NUMBER_36: 36,
    NUMBER_37: 37,
    NUMBER_38: 38,
    NUMBER_39: 39,
    NUMBER_40: 40,
    NUMBER_41: 41,
    NUMBER_42: 42,
    NUMBER_43: 43,
    NUMBER_44: 44,
    NUMBER_45: 45,
    NUMBER_46: 46,
    NUMBER_47: 47,
    NUMBER_48: 48,
    NUMBER_1000: 1000,
    NUMBER_1001: 1001,
    NUMBER_1002: 1002,
    NUMBER_1003: 1003,
    NUMBER_2000: 2000,
    NUMBER_2001: 2001,
    NUMBER_2002: 2002,
    NUMBER_2003: 2003,
    NUMBER_2004: 2004,
    NUMBER_2005: 2005,
    NUMBER_2006: 2006,
    NUMBER_2007: 2007,
    NUMBER_2008: 2008,
    NUMBER_2009: 2009,
    NUMBER_2010: 2010
} as const;
export type StandKpiType = typeof StandKpiType[keyof typeof StandKpiType];


export function instanceOfStandKpiType(value: any): boolean {
    for (const key in StandKpiType) {
        if (Object.prototype.hasOwnProperty.call(StandKpiType, key)) {
            if (StandKpiType[key as keyof typeof StandKpiType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StandKpiTypeFromJSON(json: any): StandKpiType {
    return StandKpiTypeFromJSONTyped(json, false);
}

export function StandKpiTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiType {
    return json as StandKpiType;
}

export function StandKpiTypeToJSON(value?: StandKpiType | null): any {
    return value as any;
}

