/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PivotOrderType } from './PivotOrderType';
import {
    PivotOrderTypeFromJSON,
    PivotOrderTypeFromJSONTyped,
    PivotOrderTypeToJSON,
} from './PivotOrderType';
import type { PivotType } from './PivotType';
import {
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';

/**
 * 
 * @export
 * @interface PivotTypeSortingOptionsDto
 */
export interface PivotTypeSortingOptionsDto {
    /**
     * 
     * @type {PivotType}
     * @memberof PivotTypeSortingOptionsDto
     */
    key: PivotType;
    /**
     * 
     * @type {Array<PivotOrderType>}
     * @memberof PivotTypeSortingOptionsDto
     */
    options: Array<PivotOrderType>;
}



/**
 * Check if a given object implements the PivotTypeSortingOptionsDto interface.
 */
export function instanceOfPivotTypeSortingOptionsDto(value: object): value is PivotTypeSortingOptionsDto {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function PivotTypeSortingOptionsDtoFromJSON(json: any): PivotTypeSortingOptionsDto {
    return PivotTypeSortingOptionsDtoFromJSONTyped(json, false);
}

export function PivotTypeSortingOptionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotTypeSortingOptionsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'key': PivotTypeFromJSON(json['Key']),
        'options': ((json['Options'] as Array<any>).map(PivotOrderTypeFromJSON)),
    };
}

export function PivotTypeSortingOptionsDtoToJSON(value?: PivotTypeSortingOptionsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Key': PivotTypeToJSON(value['key']),
        'Options': ((value['options'] as Array<any>).map(PivotOrderTypeToJSON)),
    };
}

