export const MockStatCardFlowRateSvg = () => {
  return (
    <svg width="326" height="126" viewBox="0 0 326 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="78.8381" y="81.5" width="23.6571" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="74.5" y="37.5" width="32" height="32" rx="16" fill="#D69985" />
      <path
        d="M95.5 53.5C95.5 52.6955 95.3046 51.9031 94.9304 51.1909L94.1824 51.939C94.3925 52.4325 94.5006 52.9635 94.5 53.5H95.5Z"
        fill="white"
      />
      <path
        d="M94.2071 50.5L93.5 49.793L91.1427 52.1503C90.9424 52.0526 90.7228 52.0012 90.5 52C90.2033 52 89.9133 52.088 89.6666 52.2528C89.42 52.4176 89.2277 52.6519 89.1142 52.926C89.0007 53.2001 88.9709 53.5017 89.0288 53.7926C89.0867 54.0836 89.2296 54.3509 89.4393 54.5607C89.6491 54.7704 89.9164 54.9133 90.2074 54.9712C90.4983 55.0291 90.7999 54.9994 91.074 54.8858C91.3481 54.7723 91.5824 54.58 91.7472 54.3334C91.912 54.0867 92 53.7967 92 53.5C91.9988 53.2772 91.9474 53.0576 91.8497 52.8574L94.2071 50.5ZM90.5 54C90.4011 54 90.3044 53.9707 90.2222 53.9157C90.14 53.8608 90.0759 53.7827 90.0381 53.6913C90.0002 53.6 89.9903 53.4994 90.0096 53.4025C90.0289 53.3055 90.0765 53.2164 90.1464 53.1465C90.2164 53.0765 90.3055 53.0289 90.4025 53.0096C90.4994 52.9903 90.6 53.0002 90.6913 53.0381C90.7827 53.0759 90.8608 53.14 90.9157 53.2222C90.9707 53.3044 91 53.4011 91 53.5C90.9998 53.6326 90.9471 53.7596 90.8534 53.8534C90.7596 53.9471 90.6326 53.9998 90.5 54Z"
        fill="white"
      />
      <path
        d="M90.5 49.5C91.0365 49.4995 91.5675 49.6075 92.0612 49.8177L92.8092 49.0696C92.0477 48.6709 91.1961 48.4755 90.3369 48.5024C89.4777 48.5293 88.6401 48.7776 87.905 49.2232C87.1699 49.6687 86.5622 50.2965 86.1409 51.0458C85.7196 51.7951 85.4988 52.6404 85.5 53.5H86.5C86.5012 52.4395 86.923 51.4228 87.6729 50.6729C88.4228 49.923 89.4395 49.5012 90.5 49.5Z"
        fill="white"
      />
      <path
        d="M90.5 60.5C89.1155 60.5 87.7622 60.0895 86.611 59.3203C85.4599 58.5511 84.5627 57.4579 84.0328 56.1788C83.503 54.8997 83.3644 53.4922 83.6345 52.1344C83.9046 50.7765 84.5713 49.5292 85.5503 48.5503C86.5292 47.5713 87.7765 46.9046 89.1344 46.6345C90.4922 46.3644 91.8997 46.503 93.1788 47.0328C94.4579 47.5627 95.5511 48.4599 96.3203 49.611C97.0895 50.7622 97.5 52.1155 97.5 53.5C97.4979 55.3559 96.7597 57.1351 95.4474 58.4474C94.1351 59.7597 92.3559 60.4979 90.5 60.5ZM90.5 47.5C89.3133 47.5 88.1533 47.8519 87.1666 48.5112C86.1799 49.1705 85.4109 50.1075 84.9567 51.2039C84.5026 52.3003 84.3838 53.5067 84.6153 54.6705C84.8468 55.8344 85.4182 56.9035 86.2574 57.7426C87.0965 58.5818 88.1656 59.1532 89.3295 59.3847C90.4933 59.6162 91.6997 59.4974 92.7961 59.0433C93.8925 58.5892 94.8295 57.8201 95.4888 56.8334C96.1481 55.8467 96.5 54.6867 96.5 53.5C96.4982 51.9093 95.8655 50.3842 94.7406 49.2594C93.6158 48.1345 92.0907 47.5018 90.5 47.5Z"
        fill="white"
      />
      <rect x="63.7747" y="88.4741" width="53.6229" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <path
        d="M144.5 30.5V29.5C144.887 29.4666 145.249 29.2978 145.523 29.0234C145.798 28.749 145.967 28.3866 146 28H147C146.968 28.6527 146.694 29.2699 146.232 29.732C145.77 30.194 145.153 30.4677 144.5 30.5Z"
        fill="#D69985"
      />
      <path
        d="M144.5 32.5C143.307 32.4985 142.163 32.024 141.32 31.1804C140.476 30.3368 140.002 29.1931 140 28C140.027 27.1228 140.285 26.2682 140.747 25.5223L144.076 20.2184C144.124 20.1509 144.188 20.0958 144.262 20.0578C144.335 20.0198 144.417 20 144.5 20C144.583 20 144.665 20.0198 144.738 20.0578C144.812 20.0958 144.876 20.1509 144.924 20.2184L148.238 25.4966C148.708 26.2493 148.971 27.113 149 28C148.998 29.1931 148.524 30.3368 147.68 31.1804C146.837 32.024 145.693 32.4985 144.5 32.5ZM144.5 21.4242L141.609 26.0281C141.237 26.6206 141.027 27.3007 141 28C141 28.9283 141.369 29.8185 142.025 30.4749C142.682 31.1313 143.572 31.5 144.5 31.5C145.428 31.5 146.318 31.1313 146.975 30.4749C147.631 29.8185 148 28.9283 148 28C147.972 27.2908 147.756 26.6017 147.376 26.0024L144.5 21.4242Z"
        fill="#D69985"
      />
      <rect x="22" y="12" width="137" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="223.838" y="81.5" width="23.6571" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="219.5" y="37.5" width="32" height="32" rx="16" fill="#5B5BEA" />
      <path
        d="M240.5 53.5C240.5 52.6955 240.305 51.9031 239.93 51.1909L239.182 51.939C239.393 52.4325 239.501 52.9635 239.5 53.5H240.5Z"
        fill="white"
      />
      <path
        d="M239.207 50.5L238.5 49.793L236.143 52.1503C235.942 52.0526 235.723 52.0012 235.5 52C235.203 52 234.913 52.088 234.667 52.2528C234.42 52.4176 234.228 52.6519 234.114 52.926C234.001 53.2001 233.971 53.5017 234.029 53.7926C234.087 54.0836 234.23 54.3509 234.439 54.5607C234.649 54.7704 234.916 54.9133 235.207 54.9712C235.498 55.0291 235.8 54.9994 236.074 54.8858C236.348 54.7723 236.582 54.58 236.747 54.3334C236.912 54.0867 237 53.7967 237 53.5C236.999 53.2772 236.947 53.0576 236.85 52.8574L239.207 50.5ZM235.5 54C235.401 54 235.304 53.9707 235.222 53.9157C235.14 53.8608 235.076 53.7827 235.038 53.6913C235 53.6 234.99 53.4994 235.01 53.4025C235.029 53.3055 235.077 53.2164 235.146 53.1465C235.216 53.0765 235.305 53.0289 235.402 53.0096C235.499 52.9903 235.6 53.0002 235.691 53.0381C235.783 53.0759 235.861 53.14 235.916 53.2222C235.971 53.3044 236 53.4011 236 53.5C236 53.6326 235.947 53.7596 235.853 53.8534C235.76 53.9471 235.633 53.9998 235.5 54Z"
        fill="white"
      />
      <path
        d="M235.5 49.5C236.036 49.4995 236.568 49.6075 237.061 49.8177L237.809 49.0696C237.048 48.6709 236.196 48.4755 235.337 48.5024C234.478 48.5293 233.64 48.7776 232.905 49.2232C232.17 49.6687 231.562 50.2965 231.141 51.0458C230.72 51.7951 230.499 52.6404 230.5 53.5H231.5C231.501 52.4395 231.923 51.4228 232.673 50.6729C233.423 49.923 234.44 49.5012 235.5 49.5Z"
        fill="white"
      />
      <path
        d="M235.5 60.5C234.116 60.5 232.762 60.0895 231.611 59.3203C230.46 58.5511 229.563 57.4579 229.033 56.1788C228.503 54.8997 228.364 53.4922 228.635 52.1344C228.905 50.7765 229.571 49.5292 230.55 48.5503C231.529 47.5713 232.777 46.9046 234.134 46.6345C235.492 46.3644 236.9 46.503 238.179 47.0328C239.458 47.5627 240.551 48.4599 241.32 49.611C242.089 50.7622 242.5 52.1155 242.5 53.5C242.498 55.3559 241.76 57.1351 240.447 58.4474C239.135 59.7597 237.356 60.4979 235.5 60.5ZM235.5 47.5C234.313 47.5 233.153 47.8519 232.167 48.5112C231.18 49.1705 230.411 50.1075 229.957 51.2039C229.503 52.3003 229.384 53.5067 229.615 54.6705C229.847 55.8344 230.418 56.9035 231.257 57.7426C232.096 58.5818 233.166 59.1532 234.329 59.3847C235.493 59.6162 236.7 59.4974 237.796 59.0433C238.892 58.5892 239.83 57.8201 240.489 56.8334C241.148 55.8467 241.5 54.6867 241.5 53.5C241.498 51.9093 240.865 50.3842 239.741 49.2594C238.616 48.1345 237.091 47.5018 235.5 47.5Z"
        fill="white"
      />
      <rect x="208.775" y="88.4741" width="53.6229" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="167" y="12" width="137" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <path
        d="M293.916 25.9844C293.78 25.6611 293.625 25.2944 293.474 24.8418C293.079 23.6577 294.342 22.3657 294.354 22.3535L293.646 21.6465C293.576 21.7168 291.935 23.3852 292.526 25.1582C292.689 25.6472 292.851 26.0327 292.995 26.3732C293.328 27.0324 293.501 27.7612 293.5 28.5C293.441 29.0955 293.213 29.6617 292.841 30.1309C292.47 30.6002 291.972 30.9527 291.405 31.1465C291.584 30.4453 291.582 29.7101 291.397 29.0103C291.213 28.3104 290.854 27.669 290.354 27.1465L289.831 26.6243L289.541 27.303C288.622 29.445 287.53 30.38 286.887 30.77C286.493 30.5284 286.162 30.1967 285.92 29.8021C285.679 29.4074 285.535 28.9612 285.5 28.5C285.534 27.8706 285.692 27.2541 285.964 26.6855C286.287 26.0002 286.469 25.257 286.5 24.5V23.6108C286.937 23.7908 287.5 24.2627 287.5 25.5V26.8018L288.372 25.8347C289.928 24.1074 289.603 22.0508 288.975 20.6806C289.452 20.8399 289.863 21.1553 290.139 21.576C290.416 21.9967 290.543 22.4983 290.5 23H291.5C291.5 20.2315 289.211 19.5 288 19.5H287L287.6 20.2993C287.668 20.3921 289.031 22.2632 288.276 24.1433C288.108 23.6708 287.801 23.2605 287.394 22.967C286.988 22.6735 286.501 22.5106 286 22.5H285.5V24.5C285.466 25.1294 285.308 25.7459 285.036 26.3145C284.713 26.9998 284.531 27.743 284.5 28.5C284.5 30.4239 286.412 32.5 289.5 32.5C292.588 32.5 294.5 30.4239 294.5 28.5C294.501 27.6278 294.301 26.7671 293.916 25.9844ZM287.918 31.2632C288.84 30.4996 289.585 29.5444 290.101 28.4639C290.376 28.9088 290.533 29.4171 290.556 29.94C290.578 30.4628 290.466 30.9827 290.229 31.4497C289.988 31.4822 289.744 31.499 289.5 31.5C288.963 31.5038 288.43 31.4239 287.918 31.2632Z"
        fill="#5B5BEA"
      />
    </svg>
  );
};

export const MockStatCardFuelConsumptionSvg = () => {
  return (
    <svg width="326" height="126" viewBox="0 0 326 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="78.838" y="81.5" width="23.6571" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="74.5" y="37.5" width="32" height="32" rx="16" fill="#5B5BEA" />
      <path
        d="M85 49.5C85 48.6716 85.6716 48 86.5 48H90.0858C90.2184 48 90.3456 48.0527 90.4393 48.1464L93.1464 50.8536L95.8536 53.5607C95.9473 53.6544 96 53.7816 96 53.9142V59.5C96 59.7761 95.7761 60 95.5 60H85.5C85.2239 60 85 59.7761 85 59.5V49.5Z"
        stroke="white"
        strokeLinejoin="round"
      />
      <rect
        x="93.6855"
        y="48.9355"
        width="2.29272"
        height="1.67668"
        transform="rotate(45 93.6855 48.9355)"
        stroke="white"
        strokeLinejoin="round"
      />
      <rect
        x="94.6016"
        y="47.5703"
        width="2.92776"
        height="1.61294"
        transform="rotate(45 94.6016 47.5703)"
        stroke="white"
        strokeLinejoin="round"
      />
      <path d="M90 50L87 53V50H90Z" stroke="white" strokeLinejoin="round" />
      <rect x="63.7747" y="88.4741" width="53.6229" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="22" y="12" width="137" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <path
        d="M148.916 25.9844C148.78 25.6611 148.625 25.2944 148.474 24.8418C148.079 23.6577 149.342 22.3657 149.354 22.3535L148.646 21.6465C148.576 21.7168 146.935 23.3852 147.526 25.1582C147.689 25.6472 147.851 26.0327 147.995 26.3732C148.328 27.0324 148.501 27.7612 148.5 28.5C148.441 29.0955 148.213 29.6617 147.841 30.1309C147.47 30.6002 146.972 30.9527 146.405 31.1465C146.584 30.4453 146.582 29.7101 146.397 29.0103C146.213 28.3104 145.854 27.669 145.354 27.1465L144.831 26.6243L144.541 27.303C143.622 29.445 142.53 30.38 141.887 30.77C141.493 30.5284 141.162 30.1967 140.92 29.8021C140.679 29.4074 140.535 28.9612 140.5 28.5C140.534 27.8706 140.692 27.2541 140.964 26.6855C141.287 26.0002 141.469 25.257 141.5 24.5V23.6108C141.937 23.7908 142.5 24.2627 142.5 25.5V26.8018L143.372 25.8347C144.928 24.1074 144.603 22.0508 143.975 20.6806C144.452 20.8399 144.863 21.1553 145.139 21.576C145.416 21.9967 145.543 22.4983 145.5 23H146.5C146.5 20.2315 144.211 19.5 143 19.5H142L142.6 20.2993C142.668 20.3921 144.031 22.2632 143.276 24.1433C143.108 23.6708 142.801 23.2605 142.394 22.967C141.988 22.6735 141.501 22.5106 141 22.5H140.5V24.5C140.466 25.1294 140.308 25.7459 140.036 26.3145C139.713 26.9998 139.531 27.743 139.5 28.5C139.5 30.4239 141.412 32.5 144.5 32.5C147.588 32.5 149.5 30.4239 149.5 28.5C149.501 27.6278 149.301 26.7671 148.916 25.9844ZM142.918 31.2632C143.84 30.4996 144.585 29.5444 145.101 28.4639C145.376 28.9088 145.533 29.4171 145.556 29.94C145.578 30.4628 145.466 30.9827 145.229 31.4497C144.988 31.4822 144.744 31.499 144.5 31.5C143.963 31.5038 143.43 31.4239 142.918 31.2632Z"
        fill="#5B5BEA"
      />
      <rect x="223.838" y="81.5" width="23.6571" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="219.5" y="37.5" width="32" height="32" rx="16" fill="#D69985" />
      <path
        d="M230 49.5C230 48.6716 230.672 48 231.5 48H235.086C235.218 48 235.346 48.0527 235.439 48.1464L238.146 50.8536L240.854 53.5607C240.947 53.6544 241 53.7816 241 53.9142V59.5C241 59.7761 240.776 60 240.5 60H230.5C230.224 60 230 59.7761 230 59.5V49.5Z"
        stroke="white"
        strokeLinejoin="round"
      />
      <rect
        x="238.686"
        y="48.9355"
        width="2.29272"
        height="1.67668"
        transform="rotate(45 238.686 48.9355)"
        stroke="white"
        strokeLinejoin="round"
      />
      <rect
        x="239.602"
        y="47.5703"
        width="2.92776"
        height="1.61294"
        transform="rotate(45 239.602 47.5703)"
        stroke="white"
        strokeLinejoin="round"
      />
      <path d="M235 50L232 53V50H235Z" stroke="white" strokeLinejoin="round" />
      <rect x="208.775" y="88.4741" width="53.6229" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <path
        d="M289.5 30.5V29.5C289.887 29.4666 290.249 29.2978 290.523 29.0234C290.798 28.749 290.967 28.3866 291 28H292C291.968 28.6527 291.694 29.2699 291.232 29.732C290.77 30.194 290.153 30.4677 289.5 30.5Z"
        fill="#D69985"
      />
      <path
        d="M289.5 32.5C288.307 32.4985 287.163 32.024 286.32 31.1804C285.476 30.3368 285.002 29.1931 285 28C285.027 27.1228 285.285 26.2682 285.747 25.5223L289.076 20.2184C289.124 20.1509 289.188 20.0958 289.262 20.0578C289.335 20.0198 289.417 20 289.5 20C289.583 20 289.665 20.0198 289.738 20.0578C289.812 20.0958 289.876 20.1509 289.924 20.2184L293.238 25.4966C293.708 26.2493 293.971 27.113 294 28C293.998 29.1931 293.524 30.3368 292.68 31.1804C291.837 32.024 290.693 32.4985 289.5 32.5ZM289.5 21.4242L286.609 26.0281C286.237 26.6206 286.027 27.3007 286 28C286 28.9283 286.369 29.8185 287.025 30.4749C287.682 31.1313 288.572 31.5 289.5 31.5C290.428 31.5 291.318 31.1313 291.975 30.4749C292.631 29.8185 293 28.9283 293 28C292.972 27.2908 292.756 26.6017 292.376 26.0024L289.5 21.4242Z"
        fill="#D69985"
      />
      <rect x="167" y="12" width="137" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
    </svg>
  );
};

export const MockStatCardOperationDaysSvg = () => {
  return (
    <svg width="326" height="126" viewBox="0 0 326 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="58" y="29.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="39" y="37.0066" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="21.5" y="12" width="104" height="50" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="58" y="83.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="39" y="90.5649" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="21.5" y="67" width="104" height="47" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="202" y="81.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="183" y="88.4741" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="202" y="38.5" width="32" height="32" rx="16" fill="#9E83E1" />
      <path
        d="M221.991 58.7167C222.295 58.9002 222.644 58.9981 223 59C223.53 58.9994 224.039 58.7885 224.414 58.4136C224.788 58.0386 224.999 57.5303 225 57C224.998 56.827 224.973 56.6551 224.925 56.4886L223.707 57.7071C223.614 57.7999 223.504 57.8732 223.383 57.9235C223.261 57.9737 223.131 57.9996 223 57.9996C222.869 57.9996 222.739 57.9737 222.617 57.9235C222.496 57.8732 222.386 57.7996 222.293 57.7068C222.2 57.614 222.127 57.5038 222.077 57.3825C222.026 57.2612 222 57.1313 222 57C222 56.8687 222.026 56.7388 222.077 56.6175C222.127 56.4962 222.2 56.386 222.293 56.2932L223.511 55.0745C223.345 55.0274 223.173 55.0023 223 55C222.47 55.0006 221.961 55.2115 221.586 55.5864C221.212 55.9614 221.001 56.4697 221 57C221.002 57.3559 221.1 57.7047 221.283 58.0096L218.5 60.793L219.207 61.5L221.991 58.7167Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223 48.5H221V47.5H220V48.5H216V47.5H215V48.5H213C212.735 48.5 212.48 48.6054 212.293 48.7929C212.105 48.9804 212 49.2348 212 49.5V59.5C212 59.7652 212.105 60.0196 212.293 60.2071C212.48 60.3946 212.735 60.5 213 60.5H217V59.5H213V49.5H215V50.5H216V49.5H220V50.5H221V49.5H223V53.5H224V49.5C224 49.2348 223.895 48.9804 223.707 48.7929C223.52 48.6054 223.265 48.5 223 48.5Z"
        fill="white"
      />
      <rect x="130.5" y="12" width="174" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
    </svg>
  );
};

export const MockStatCardPowerConsumedPerDaySvg = () => {
  return (
    <svg width="326" height="126" viewBox="0 0 326 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="58.5" y="29.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="39.5" y="37.0066" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="22" y="12" width="104" height="50" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="58.5" y="83.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="39.5" y="90.5649" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="22" y="67" width="104" height="47" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="201.5" y="81.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="182.5" y="88.4741" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="201.5" y="38.5" width="32" height="32" rx="16" fill="#13A9CA" />
      <path
        d="M223.5 49.5C223.5 48.95 223.05 48.5 222.5 48.5H220.5V47.5H219.5V48.5H215.5V47.5H214.5V48.5H212.5C211.95 48.5 211.5 48.95 211.5 49.5V59.5C211.5 60.05 211.95 60.5 212.5 60.5H214.5V59.5H212.5V49.5H214.5V50.5H215.5V49.5H219.5V50.5H220.5V49.5H222.5V53.5H223.5V49.5Z"
        fill="white"
      />
      <path d="M220.5 60.5V59.5H222.5V53.5H223.5V59.5C223.5 60.3 222.833 60.5 222.5 60.5H220.5Z" fill="white" />
      <path
        d="M215.818 62.4602C215.716 62.417 215.631 62.341 215.577 62.2441C215.522 62.1472 215.502 62.035 215.518 61.9252L216.428 58.0002H215.013C214.937 58.0022 214.861 57.9867 214.791 57.9547C214.722 57.9227 214.66 57.8752 214.612 57.8158C214.564 57.7563 214.53 57.6866 214.513 57.612C214.496 57.5373 214.496 57.4597 214.513 57.3852L216.013 52.8852C216.04 52.7733 216.104 52.674 216.195 52.6038C216.286 52.5337 216.399 52.4971 216.513 52.5002H219.513C219.588 52.4999 219.662 52.5164 219.729 52.5484C219.797 52.5804 219.856 52.6272 219.903 52.6852C219.951 52.7438 219.985 52.8126 220.002 52.8862C220.02 52.9598 220.02 53.0364 220.003 53.1102L219.138 55.0002H220.513C220.607 55 220.699 55.0261 220.779 55.0756C220.858 55.1252 220.922 55.196 220.963 55.2802C220.999 55.3609 221.013 55.4498 221.003 55.5376C220.994 55.6254 220.961 55.7091 220.908 55.7802L216.408 62.2802C216.365 62.3453 216.306 62.3992 216.237 62.4374C216.169 62.4756 216.092 62.4971 216.013 62.5002C215.947 62.4989 215.88 62.4854 215.818 62.4602ZM217.888 56.0002L218.888 53.5002H216.913L215.643 57.0002H217.598L216.803 60.1402L219.513 56.0002H217.888Z"
        fill="white"
      />
      <rect x="130" y="12" width="174" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
    </svg>
  );
};

export const MockStatCardPowerConsumedSvg = () => {
  return (
    <svg width="326" height="126" viewBox="0 0 326 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="58.5" y="29.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="64.5" y="48.5" width="18" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="39.5" y="37.0066" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="22" y="12" width="104" height="50" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="58.5" y="83.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="39.5" y="90.5649" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="64.5" y="103.5" width="18" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="22" y="67" width="104" height="47" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="201.5" y="81.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="182.5" y="88.4741" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="201.5" y="38.5" width="32" height="32" rx="16" fill="#13A9CA" />
      <path
        d="M215.305 61.4599C215.203 61.4167 215.118 61.3407 215.063 61.2438C215.009 61.147 214.989 61.0348 215.005 60.9249L215.915 54.9999H213.5C213.423 55.002 213.347 54.9864 213.278 54.9545C213.208 54.9225 213.147 54.875 213.099 54.8155C213.05 54.7561 213.016 54.6864 212.999 54.6117C212.982 54.5371 212.982 54.4595 213 54.3849L214.5 47.8849C214.526 47.7731 214.591 47.6737 214.682 47.6036C214.773 47.5335 214.885 47.4969 215 47.4999H220C220.075 47.4997 220.149 47.5162 220.216 47.5482C220.284 47.5802 220.343 47.6269 220.39 47.6849C220.438 47.7436 220.472 47.8123 220.489 47.8859C220.506 47.9596 220.507 48.0361 220.49 48.1099L219.625 51.9999H222C222.094 51.9997 222.186 52.0259 222.265 52.0754C222.345 52.1249 222.409 52.1958 222.45 52.2799C222.486 52.3607 222.5 52.4495 222.49 52.5374C222.48 52.6252 222.448 52.7089 222.395 52.7799L215.895 61.2799C215.851 61.3451 215.792 61.3989 215.724 61.4371C215.655 61.4754 215.579 61.4969 215.5 61.4999C215.433 61.4987 215.367 61.4851 215.305 61.4599ZM218.375 52.9999L219.375 48.4999H215.4L214.13 53.9999H217.085L216.29 59.1399L221 52.9999H218.375Z"
        fill="white"
      />
      <rect x="208.5" y="99.5" width="18" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="130" y="12" width="174" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
    </svg>
  );
};

export const MockPowerAvailableStatCardSvg = () => {
  return (
    <svg width="326" height="126" viewBox="0 0 326 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="58.5" y="29.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="64.5" y="48.5" width="18" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="39.5" y="37.0066" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="22" y="12" width="104" height="50" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="58.5" y="83.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="39.5" y="90.5649" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="64.5" y="103.5" width="18" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="22" y="67" width="104" height="47" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="201.5" y="81.5" width="30" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="182.5" y="88.4741" width="68" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="201.5" y="38.5" width="32" height="32" rx="16" fill="#943C5C" />
      <path
        d="M215.305 61.4599C215.203 61.4167 215.118 61.3407 215.063 61.2438C215.009 61.147 214.989 61.0348 215.005 60.9249L215.915 54.9999H213.5C213.423 55.002 213.347 54.9864 213.278 54.9545C213.208 54.9225 213.147 54.875 213.099 54.8155C213.05 54.7561 213.016 54.6864 212.999 54.6117C212.982 54.5371 212.982 54.4595 213 54.3849L214.5 47.8849C214.526 47.7731 214.591 47.6737 214.682 47.6036C214.773 47.5335 214.885 47.4969 215 47.4999H220C220.075 47.4997 220.149 47.5162 220.216 47.5482C220.284 47.5802 220.343 47.6269 220.39 47.6849C220.438 47.7436 220.472 47.8123 220.489 47.8859C220.506 47.9596 220.507 48.0361 220.49 48.1099L219.625 51.9999H222C222.094 51.9997 222.186 52.0259 222.265 52.0754C222.345 52.1249 222.409 52.1958 222.45 52.2799C222.486 52.3607 222.5 52.4495 222.49 52.5374C222.48 52.6252 222.448 52.7089 222.395 52.7799L215.895 61.2799C215.851 61.3451 215.792 61.3989 215.724 61.4371C215.655 61.4754 215.579 61.4969 215.5 61.4999C215.433 61.4987 215.367 61.4851 215.305 61.4599ZM218.375 52.9999L219.375 48.4999H215.4L214.13 53.9999H217.085L216.29 59.1399L221 52.9999H218.375Z"
        fill="white"
      />
      <rect x="208.5" y="99.5" width="18" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="130" y="12" width="174" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
    </svg>
  );
};

export const MockGhgEmissionsStatcardSvg = () => {
  return (
    <svg width="326" height="126" viewBox="0 0 326 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="51" y="37.5" width="32" height="32" rx="16" fill="#13A9CA" />
      <path
        d="M67.7551 48.075C67.5065 48.0251 67.2536 48 67.0001 48C66.0665 47.9949 65.1605 48.3165 64.4391 48.9092C63.7177 49.5018 63.2263 50.3282 63.0501 51.245L62.9201 51.92L62.2501 52.04C61.5683 52.1573 60.9558 52.5272 60.5346 53.0759C60.1135 53.6247 59.9146 54.3121 59.9777 55.001C60.0408 55.6899 60.3612 56.3297 60.875 56.7929C61.3888 57.256 62.0584 57.5085 62.7501 57.5H63.8116V58.5H62.7451C61.8093 58.498 60.9081 58.1461 60.2184 57.5136C59.5288 56.8811 59.1005 56.0135 59.0179 55.0814C58.9352 54.1492 59.204 53.2198 59.7715 52.4758C60.339 51.7317 61.1643 51.2267 62.0851 51.06C62.3039 49.9171 62.9145 48.8862 63.8116 48.1451C64.7088 47.404 65.8364 46.999 67.0001 47C67.3139 47.0014 67.6268 47.0315 67.9351 47.09C68.9182 47.2783 69.8221 47.7574 70.5299 48.4652C71.2377 49.173 71.7168 50.0769 71.9051 51.06C72.8259 51.2267 73.6512 51.7317 74.2187 52.4758C74.5132 52.8618 74.8738 53.5397 75.0001 54H73.9184C73.9184 54 73.8717 53.6247 73.4506 53.0759C73.0294 52.5272 72.4169 52.1573 71.7351 52.04L71.0601 51.92L70.9301 51.245C70.7784 50.4598 70.3948 49.738 69.8289 49.1729C69.2629 48.6079 68.5406 48.2254 67.7551 48.075Z"
        fill="white"
      />
      <path
        d="M66.4822 59.5843C66.1882 59.5843 65.9199 59.5399 65.6772 59.4513C65.4392 59.3626 65.2339 59.2203 65.0612 59.0243C64.8932 58.8283 64.7602 58.5716 64.6622 58.2543C64.5689 57.9369 64.5222 57.5496 64.5222 57.0923C64.5222 56.6349 64.5689 56.2453 64.6622 55.9233C64.7602 55.5966 64.8932 55.3306 65.0612 55.1253C65.2339 54.9199 65.4392 54.7706 65.6772 54.6773C65.9199 54.5793 66.1882 54.5303 66.4822 54.5303C66.9209 54.5303 67.2662 54.6259 67.5182 54.8173C67.7749 55.0039 67.9849 55.2886 68.1482 55.6713L67.2802 56.1193C67.2522 56.0166 67.2196 55.9209 67.1822 55.8323C67.1496 55.7436 67.1029 55.6689 67.0422 55.6083C66.9816 55.5476 66.9046 55.5009 66.8112 55.4683C66.7226 55.4309 66.6129 55.4123 66.4822 55.4123C66.2022 55.4123 65.9829 55.4963 65.8242 55.6643C65.6656 55.8323 65.5862 56.0843 65.5862 56.4203V57.6943C65.5862 58.0303 65.6656 58.2823 65.8242 58.4503C65.9829 58.6183 66.2022 58.7023 66.4822 58.7023C66.6129 58.7023 66.7249 58.6836 66.8182 58.6463C66.9162 58.6043 66.9979 58.5506 67.0632 58.4853C67.1286 58.4153 67.1822 58.3359 67.2242 58.2473C67.2662 58.1539 67.3036 58.0536 67.3362 57.9463L68.1622 58.4223C68.0782 58.6043 67.9849 58.7676 67.8822 58.9123C67.7796 59.0569 67.6606 59.1806 67.5252 59.2833C67.3946 59.3813 67.2429 59.4559 67.0702 59.5073C66.8976 59.5586 66.7016 59.5843 66.4822 59.5843Z"
        fill="white"
      />
      <path
        d="M70.2554 59.5843C69.9614 59.5843 69.6954 59.5399 69.4574 59.4513C69.2194 59.3579 69.0164 59.2109 68.8484 59.0103C68.6804 58.8049 68.5498 58.5436 68.4564 58.2263C68.3631 57.9043 68.3164 57.5146 68.3164 57.0573C68.3164 56.5999 68.3631 56.2126 68.4564 55.8953C68.5498 55.5733 68.6804 55.3119 68.8484 55.1113C69.0164 54.9059 69.2194 54.7589 69.4574 54.6703C69.6954 54.5769 69.9614 54.5303 70.2554 54.5303C70.5494 54.5303 70.8154 54.5769 71.0534 54.6703C71.2914 54.7589 71.4944 54.9059 71.6624 55.1113C71.8304 55.3119 71.9611 55.5733 72.0544 55.8953C72.1478 56.2126 72.1944 56.5999 72.1944 57.0573C72.1944 57.5146 72.1478 57.9043 72.0544 58.2263C71.9611 58.5436 71.8304 58.8049 71.6624 59.0103C71.4944 59.2109 71.2914 59.3579 71.0534 59.4513C70.8154 59.5399 70.5494 59.5843 70.2554 59.5843ZM70.2554 58.7023C70.5354 58.7023 70.7501 58.6183 70.8994 58.4503C71.0534 58.2823 71.1304 58.0303 71.1304 57.6943V56.4203C71.1304 56.0843 71.0534 55.8323 70.8994 55.6643C70.7501 55.4963 70.5354 55.4123 70.2554 55.4123C69.9754 55.4123 69.7584 55.4963 69.6044 55.6643C69.4551 55.8323 69.3804 56.0843 69.3804 56.4203V57.6943C69.3804 58.0303 69.4551 58.2823 69.6044 58.4503C69.7584 58.6183 69.9754 58.7023 70.2554 58.7023Z"
        fill="white"
      />
      <path
        d="M75.7056 59.5003H72.5696V58.5903L73.9066 57.2813C74.1493 57.0433 74.3196 56.8403 74.4176 56.6723C74.5203 56.4996 74.5716 56.3176 74.5716 56.1263V55.9023C74.5716 55.7436 74.5203 55.6153 74.4176 55.5173C74.3196 55.4193 74.182 55.3703 74.0046 55.3703C73.902 55.3703 73.811 55.3913 73.7316 55.4333C73.657 55.4706 73.5916 55.5219 73.5356 55.5873C73.4843 55.6526 73.44 55.7296 73.4026 55.8183C73.37 55.9023 73.3443 55.9933 73.3256 56.0913L72.4576 55.7623C72.5043 55.5943 72.5673 55.4356 72.6466 55.2863C72.7306 55.1369 72.8356 55.0063 72.9616 54.8943C73.0923 54.7823 73.2486 54.6936 73.4306 54.6283C73.6173 54.5629 73.839 54.5303 74.0956 54.5303C74.3756 54.5303 74.6113 54.5629 74.8026 54.6283C74.9986 54.6936 75.1573 54.7869 75.2786 54.9083C75.4 55.0249 75.4863 55.1719 75.5376 55.3493C75.5936 55.5219 75.6216 55.7156 75.6216 55.9303C75.6216 56.0983 75.6053 56.2523 75.5726 56.3923C75.54 56.5323 75.484 56.6723 75.4046 56.8123C75.3253 56.9476 75.218 57.0923 75.0826 57.2463C74.9473 57.4003 74.777 57.5753 74.5716 57.7713L73.6406 58.6603H75.7056V59.5003Z"
        fill="white"
      />
      <rect x="59.5632" y="88.3667" width="15.0857" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="49.9578" y="95.3406" width="34.1943" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="23.5" y="12" width="87" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="147" y="37.5" width="32" height="32" rx="16" fill="#D66E6B" />
      <path
        d="M163.755 48.075C163.507 48.0251 163.254 48 163 48C162.066 47.9949 161.16 48.3165 160.439 48.9092C159.718 49.5018 159.226 50.3282 159.05 51.245L158.92 51.92L158.25 52.04C157.568 52.1573 156.956 52.5272 156.535 53.0759C156.113 53.6247 155.915 54.3121 155.978 55.001C156.041 55.6899 156.361 56.3297 156.875 56.7929C157.389 57.256 158.058 57.5085 158.75 57.5H159.812V58.5H158.745C157.809 58.498 156.908 58.1461 156.218 57.5136C155.529 56.8811 155.101 56.0135 155.018 55.0814C154.935 54.1492 155.204 53.2198 155.772 52.4758C156.339 51.7317 157.164 51.2267 158.085 51.06C158.304 49.9171 158.914 48.8862 159.812 48.1451C160.709 47.404 161.836 46.999 163 47C163.314 47.0014 163.627 47.0315 163.935 47.09C164.918 47.2783 165.822 47.7574 166.53 48.4652C167.238 49.173 167.717 50.0769 167.905 51.06C168.826 51.2267 169.651 51.7317 170.219 52.4758C170.513 52.8618 170.874 53.5397 171 54H169.918C169.918 54 169.872 53.6247 169.451 53.0759C169.029 52.5272 168.417 52.1573 167.735 52.04L167.06 51.92L166.93 51.245C166.778 50.4598 166.395 49.738 165.829 49.1729C165.263 48.6079 164.541 48.2254 163.755 48.075Z"
        fill="white"
      />
      <path
        d="M162.345 59.5843C162.051 59.5843 161.783 59.5399 161.54 59.4513C161.302 59.3626 161.097 59.2203 160.924 59.0243C160.756 58.8283 160.623 58.5716 160.525 58.2543C160.432 57.9369 160.385 57.5496 160.385 57.0923C160.385 56.6349 160.432 56.2453 160.525 55.9233C160.623 55.5966 160.756 55.3306 160.924 55.1253C161.097 54.9199 161.302 54.7706 161.54 54.6773C161.783 54.5793 162.051 54.5303 162.345 54.5303C162.784 54.5303 163.129 54.6259 163.381 54.8173C163.638 55.0039 163.848 55.2886 164.011 55.6713L163.143 56.1193C163.115 56.0166 163.083 55.9209 163.045 55.8323C163.013 55.7436 162.966 55.6689 162.905 55.6083C162.845 55.5476 162.768 55.5009 162.674 55.4683C162.586 55.4309 162.476 55.4123 162.345 55.4123C162.065 55.4123 161.846 55.4963 161.687 55.6643C161.529 55.8323 161.449 56.0843 161.449 56.4203V57.6943C161.449 58.0303 161.529 58.2823 161.687 58.4503C161.846 58.6183 162.065 58.7023 162.345 58.7023C162.476 58.7023 162.588 58.6836 162.681 58.6463C162.779 58.6043 162.861 58.5506 162.926 58.4853C162.992 58.4153 163.045 58.3359 163.087 58.2473C163.129 58.1539 163.167 58.0536 163.199 57.9463L164.025 58.4223C163.941 58.6043 163.848 58.7676 163.745 58.9123C163.643 59.0569 163.524 59.1806 163.388 59.2833C163.258 59.3813 163.106 59.4559 162.933 59.5073C162.761 59.5586 162.565 59.5843 162.345 59.5843Z"
        fill="white"
      />
      <path
        d="M167.021 57.4633H165.397V59.5003H164.396V54.6143H165.397V56.5953H167.021V54.6143H168.022V59.5003H167.021V57.4633Z"
        fill="white"
      />
      <path
        d="M170.313 59.5003V58.5763H168.402V57.7223L169.984 54.6143H171.258V57.7923H171.818V58.5763H171.258V59.5003H170.313ZM169.158 57.7923H170.313V55.6153H170.201L169.158 57.7923Z"
        fill="white"
      />
      <rect x="155.563" y="88.3667" width="15.0857" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="145.958" y="95.3406" width="34.1943" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="119.5" y="12" width="87" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
      <rect x="243" y="37.5" width="32" height="32" rx="16" fill="#9E83E1" />
      <path
        d="M259.755 48.075C259.507 48.0251 259.254 48 259 48C258.066 47.9949 257.16 48.3165 256.439 48.9092C255.718 49.5018 255.226 50.3282 255.05 51.245L254.92 51.92L254.25 52.04C253.568 52.1573 252.956 52.5272 252.535 53.0759C252.113 53.6247 251.915 54.3121 251.978 55.001C252.041 55.6899 252.361 56.3297 252.875 56.7929C253.389 57.256 254.058 57.5085 254.75 57.5H255.812V58.5H254.745C253.809 58.498 252.908 58.1461 252.218 57.5136C251.529 56.8811 251.101 56.0135 251.018 55.0814C250.935 54.1492 251.204 53.2198 251.772 52.4758C252.339 51.7317 253.164 51.2267 254.085 51.06C254.304 49.9171 254.914 48.8862 255.812 48.1451C256.709 47.404 257.836 46.999 259 47C259.314 47.0014 259.627 47.0315 259.935 47.09C260.918 47.2783 261.822 47.7574 262.53 48.4652C263.238 49.173 263.717 50.0769 263.905 51.06C264.826 51.2267 265.651 51.7317 266.219 52.4758C266.513 52.8618 266.874 53.5397 267 54H265.918C265.918 54 265.872 53.6247 265.451 53.0759C265.029 52.5272 264.417 52.1573 263.735 52.04L263.06 51.92L262.93 51.245C262.778 50.4598 262.395 49.738 261.829 49.1729C261.263 48.6079 260.541 48.2254 259.755 48.075Z"
        fill="white"
      />
      <path
        d="M258.487 56.8823L258.179 56.1053H258.067L258.123 56.9243V59.5003H257.185V54.6143H258.277L259.509 57.2323L259.817 58.0093H259.929L259.873 57.1903V54.6143H260.811V59.5003H259.719L258.487 56.8823Z"
        fill="white"
      />
      <path
        d="M264.495 59.5003H261.359V58.5903L262.696 57.2813C262.938 57.0433 263.109 56.8403 263.207 56.6723C263.309 56.4996 263.361 56.3176 263.361 56.1263V55.9023C263.361 55.7436 263.309 55.6153 263.207 55.5173C263.109 55.4193 262.971 55.3703 262.794 55.3703C262.691 55.3703 262.6 55.3913 262.521 55.4333C262.446 55.4706 262.381 55.5219 262.325 55.5873C262.273 55.6526 262.229 55.7296 262.192 55.8183C262.159 55.9023 262.133 55.9933 262.115 56.0913L261.247 55.7623C261.293 55.5943 261.356 55.4356 261.436 55.2863C261.52 55.1369 261.625 55.0063 261.751 54.8943C261.881 54.7823 262.038 54.6936 262.22 54.6283C262.406 54.5629 262.628 54.5303 262.885 54.5303C263.165 54.5303 263.4 54.5629 263.592 54.6283C263.788 54.6936 263.946 54.7869 264.068 54.9083C264.189 55.0249 264.275 55.1719 264.327 55.3493C264.383 55.5219 264.411 55.7156 264.411 55.9303C264.411 56.0983 264.394 56.2523 264.362 56.3923C264.329 56.5323 264.273 56.6723 264.194 56.8123C264.114 56.9476 264.007 57.0923 263.872 57.2463C263.736 57.4003 263.566 57.5753 263.361 57.7713L262.43 58.6603H264.495V59.5003Z"
        fill="white"
      />
      <path
        d="M266.756 59.5843C266.462 59.5843 266.196 59.5399 265.958 59.4513C265.72 59.3579 265.517 59.2109 265.349 59.0103C265.181 58.8049 265.05 58.5436 264.957 58.2263C264.864 57.9043 264.817 57.5146 264.817 57.0573C264.817 56.5999 264.864 56.2126 264.957 55.8953C265.05 55.5733 265.181 55.3119 265.349 55.1113C265.517 54.9059 265.72 54.7589 265.958 54.6703C266.196 54.5769 266.462 54.5303 266.756 54.5303C267.05 54.5303 267.316 54.5769 267.554 54.6703C267.792 54.7589 267.995 54.9059 268.163 55.1113C268.331 55.3119 268.462 55.5733 268.555 55.8953C268.648 56.2126 268.695 56.5999 268.695 57.0573C268.695 57.5146 268.648 57.9043 268.555 58.2263C268.462 58.5436 268.331 58.8049 268.163 59.0103C267.995 59.2109 267.792 59.3579 267.554 59.4513C267.316 59.5399 267.05 59.5843 266.756 59.5843ZM266.756 58.7023C267.036 58.7023 267.251 58.6183 267.4 58.4503C267.554 58.2823 267.631 58.0303 267.631 57.6943V56.4203C267.631 56.0843 267.554 55.8323 267.4 55.6643C267.251 55.4963 267.036 55.4123 266.756 55.4123C266.476 55.4123 266.259 55.4963 266.105 55.6643C265.956 55.8323 265.881 56.0843 265.881 56.4203V57.6943C265.881 58.0303 265.956 58.2823 266.105 58.4503C266.259 58.6183 266.476 58.7023 266.756 58.7023Z"
        fill="white"
      />
      <rect x="251.563" y="88.3667" width="15.0857" height="4" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="241.958" y="95.3408" width="34.1943" height="8" rx="2" fill="#828C91" fillOpacity="0.4" />
      <rect x="215.5" y="12" width="87" height="102" rx="3.5" stroke="#828C91" strokeOpacity="0.4" />
    </svg>
  );
};
