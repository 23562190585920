/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseFocalQueryDto,
  WellDrillingSummaryLensResultDto,
  WellDrillingSummaryUserLensDto,
} from '../models/index';
import {
    BaseFocalQueryDtoFromJSON,
    BaseFocalQueryDtoToJSON,
    WellDrillingSummaryLensResultDtoFromJSON,
    WellDrillingSummaryLensResultDtoToJSON,
    WellDrillingSummaryUserLensDtoFromJSON,
    WellDrillingSummaryUserLensDtoToJSON,
} from '../models/index';

export interface ApiWellDrillingSummaryUserLensesIdPutRequest {
    id: number;
    wellDrillingSummaryUserLensDto?: WellDrillingSummaryUserLensDto;
}

export interface ApiWellDrillingSummaryUserLensesIdSingleFactsPutRequest {
    id: number;
    baseFocalQueryDto?: BaseFocalQueryDto;
}

export interface ApiWellDrillingSummaryUserLensesPostRequest {
    wellDrillingSummaryUserLensDto?: WellDrillingSummaryUserLensDto;
}

/**
 * 
 */
export class WellDrillingSummaryUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiWellDrillingSummaryUserLensesIdPutRaw(requestParameters: ApiWellDrillingSummaryUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellDrillingSummaryUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellDrillingSummaryUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/WellDrillingSummaryUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WellDrillingSummaryUserLensDtoToJSON(requestParameters['wellDrillingSummaryUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellDrillingSummaryUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellDrillingSummaryUserLensesIdPut(requestParameters: ApiWellDrillingSummaryUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellDrillingSummaryUserLensDto> {
        const response = await this.apiWellDrillingSummaryUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellDrillingSummaryUserLensesIdSingleFactsPutRaw(requestParameters: ApiWellDrillingSummaryUserLensesIdSingleFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellDrillingSummaryLensResultDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiWellDrillingSummaryUserLensesIdSingleFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/WellDrillingSummaryUserLenses/{id}/SingleFacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BaseFocalQueryDtoToJSON(requestParameters['baseFocalQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellDrillingSummaryLensResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellDrillingSummaryUserLensesIdSingleFactsPut(requestParameters: ApiWellDrillingSummaryUserLensesIdSingleFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellDrillingSummaryLensResultDto> {
        const response = await this.apiWellDrillingSummaryUserLensesIdSingleFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiWellDrillingSummaryUserLensesPostRaw(requestParameters: ApiWellDrillingSummaryUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WellDrillingSummaryUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/WellDrillingSummaryUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WellDrillingSummaryUserLensDtoToJSON(requestParameters['wellDrillingSummaryUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WellDrillingSummaryUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiWellDrillingSummaryUserLensesPost(requestParameters: ApiWellDrillingSummaryUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WellDrillingSummaryUserLensDto> {
        const response = await this.apiWellDrillingSummaryUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
