import { useSuspenseQuery } from "@tanstack/react-query";
import type { PlanActivityDto, PlanDto } from "apis/oag";
import { WellsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDWellPlanQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const wellsApi = new WellsApi(apiConfig);

export function useWellPlan({ id }: { id: number }) {
  const queryKey: PDWellPlanQueryKey = { uid: RequestUID.wellPlan, type: PDQueryType.WELL_PLAN, id };

  return useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: () =>
      wellsApi
        .apiWellsIdPlanGet({ id })
        .then((plan) => ({
          ...plan,
          activities: (plan.activities ?? []).sort(
            (a: PlanActivityDto, b: PlanActivityDto) => (a?.position ?? 0) - (b?.position ?? 0),
          ),
        }))
        .catch((): PlanDto => {
          return {
            id: 0,
            totalDuration: 0,
            wellId: id,
            activities: [],
            formations: [],
            drillingParameters: [],
          };
        }),
  });
}
