/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface Int32Int64FactIndicatorDto
 */
export interface Int32Int64FactIndicatorDto {
    /**
     * 
     * @type {number}
     * @memberof Int32Int64FactIndicatorDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Int32Int64FactIndicatorDto
     */
    value: number;
    /**
     * 
     * @type {DateDto}
     * @memberof Int32Int64FactIndicatorDto
     */
    date: DateDto;
}

/**
 * Check if a given object implements the Int32Int64FactIndicatorDto interface.
 */
export function instanceOfInt32Int64FactIndicatorDto(value: object): value is Int32Int64FactIndicatorDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    return true;
}

export function Int32Int64FactIndicatorDtoFromJSON(json: any): Int32Int64FactIndicatorDto {
    return Int32Int64FactIndicatorDtoFromJSONTyped(json, false);
}

export function Int32Int64FactIndicatorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Int32Int64FactIndicatorDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'value': json['Value'],
        'date': DateDtoFromJSON(json['Date']),
    };
}

export function Int32Int64FactIndicatorDtoToJSON(value?: Int32Int64FactIndicatorDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Value': value['value'],
        'Date': DateDtoToJSON(value['date']),
    };
}

