import type { ClaraPromptDto } from "apis/oag";
import { PromptType } from "apis/oag";
import { FavoriteQuestionRowContainer } from "components/Clara/QuestionList/FavoriteQuestionRowContainer";
import { PDComponent } from "components/PDComponents";
import { useClaraPrompts } from "hooks/clara/useClara";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useAppDispatch } from "reducers/store";

import * as Styled from "./style";

type PromptsInfoType = {
  icon: JSX.Element;
  title: string;
  promptType: PromptType;
};
const promptsInfo: Array<PromptsInfoType> = [
  {
    title: "Favorite Prompts",
    icon: <PDComponent.SvgIcon name="starFilled" style={{ fontSize: 23 }} />,
    promptType: PromptType.Favorite,
  },
  {
    title: "Rig Comments Overview",
    icon: <PDComponent.SvgIcon name="rig" style={{ fontSize: 23 }} />,
    promptType: PromptType.RigCommentsOverview,
  },
  {
    title: "Rig Performance Metrics",
    icon: <PDComponent.SvgIcon name="trendAnalysis" style={{ fontSize: 23 }} />,
    promptType: PromptType.RigPerformanceMetrics,
  },
];
type ClaraPromptVm = {
  [key in PromptType]: Array<ClaraPromptDto>;
};

export const PromptsSelector = (result: Array<ClaraPromptDto>): ClaraPromptVm => {
  return result.reduce((acc, prompt) => {
    if (acc[prompt.type]) {
      acc[prompt.type].push(prompt);
    } else {
      acc[prompt.type] = [prompt];
    }
    return acc;
  }, {} as ClaraPromptVm);
};

export const QuestionList = () => {
  const { data } = useClaraPrompts({
    select: PromptsSelector,
  });

  const { data: user } = useCurrentUser();
  const dispatch = useAppDispatch();

  const populateInput = (message: string) => {
    dispatch({ type: "CLARA_POPULATE_INPUT", payload: message });
  };

  return (
    <div id="chatArea">
      <Styled.QuestionGroup>
        <h1>Hello, {user?.firstName}!</h1>
        <h2>How can I assist you today?</h2>
        <h3>You can ask me like this</h3>
        <Styled.Box>
          {promptsInfo.map((promptInfo) => {
            const prompts = data[promptInfo.promptType];
            if (!prompts) return null;
            return (
              <Styled.QuestionBox key={promptInfo.promptType}>
                <Styled.QuestionHeader>
                  {promptInfo.icon}
                  {promptInfo.title}
                </Styled.QuestionHeader>
                {promptInfo.promptType === PromptType.Favorite ? (
                  <FavoriteQuestionRowContainer prompts={prompts} />
                ) : (
                  <Styled.QuestionRowContainer>
                    {prompts?.map((prompt) => {
                      return (
                        <Styled.QuestionRow
                          key={prompt.id}
                          onClick={() => {
                            populateInput(prompt.prompt);
                          }}
                        >
                          <Styled.PromptMessage>&quot;{prompt.prompt}&quot;</Styled.PromptMessage>
                        </Styled.QuestionRow>
                      );
                    })}
                  </Styled.QuestionRowContainer>
                )}
              </Styled.QuestionBox>
            );
          })}
        </Styled.Box>
      </Styled.QuestionGroup>
    </div>
  );
};
