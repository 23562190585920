import { PDComponent } from "components/PDComponents";
import { max, min } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { clamp } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

import { StyledControlsButton } from "./styledComponents";
import { HORIZONTAL_AXIS_HEIGHT, LEFT_AXIS_WIDTH } from "./utils";

enum ZoomState {
  Neutral,
  Zoomed,
}

enum InteractionState {
  Neutral,
  CanZoom,
  Zooming,
  Panning,
}

export const useTorqueDragZoom = ({
  xScale,
  yScale,
  isHoveringPoint,
  chartHeight,
}: {
  xScale: ScaleLinear<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  isHoveringPoint: boolean;
  chartHeight: number;
}) => {
  const [zoomRectBounds, setZoomRectBounds] = useState([
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ]);

  const [newXDomain, setNewXDomain] = useState([xScale.domain()[0], xScale.domain()[1]]);
  const [newYDomain, setNewDepthDomain] = useState([yScale.domain()[0], yScale.domain()[1]]);
  const [panOriginPoint, setPanOriginPoint] = useState({ x: 0, y: 0 });
  const [interactionState, setInteractionState] = useState(InteractionState.Neutral);
  const [zoomState, setZoomState] = useState(ZoomState.Neutral);

  const clampedEventBounds = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      const { left, top } = event.currentTarget.getBoundingClientRect();
      const x = clamp(("clientX" in event ? event.clientX : 0) - left - LEFT_AXIS_WIDTH, 0, +Infinity);
      const y = clamp(("clientY" in event ? event.clientY : 0) - top - HORIZONTAL_AXIS_HEIGHT, 0, chartHeight);
      return { x, y };
    },
    [chartHeight],
  );

  const { isDark } = useCustomTheme();

  const handleOnMouseDown = useCallback(
    (event: React.PointerEvent<SVGSVGElement & SVGPathElement>) => {
      const { x, y } = clampedEventBounds(event as unknown as React.PointerEvent<HTMLDivElement>);

      if (zoomState === ZoomState.Zoomed && interactionState === InteractionState.Neutral) {
        setPanOriginPoint({ x, y });
        setInteractionState(InteractionState.Panning);
      } else if (interactionState === InteractionState.CanZoom) {
        setInteractionState(InteractionState.Zooming);
        setZoomRectBounds(() => [
          { x, y },
          { x, y },
        ]);
      }
    },
    [clampedEventBounds, interactionState, zoomState],
  );

  const handleOnPointerMove = useCallback(
    (event: React.PointerEvent<SVGSVGElement | SVGPathElement>) => {
      const { x, y } = clampedEventBounds(event as unknown as React.PointerEvent<HTMLDivElement>);

      const domainBounds = [xScale.domain(), yScale.domain()];

      if (interactionState === InteractionState.Panning) {
        const dx =
          (panOriginPoint.x - x) * Math.abs((newXDomain[0] - newXDomain[1]) / (xScale.range()[0] - xScale.range()[1]));

        const dy =
          (panOriginPoint.y - y) * Math.abs((newYDomain[0] - newYDomain[1]) / (yScale.range()[0] - yScale.range()[1]));

        // Prevent panning outside of bounds
        setNewXDomain(([min, max]) => {
          if (min + dx >= domainBounds[0][0] && max + dx <= domainBounds[0][1]) {
            return [min + dx, max + dx];
          } else return [min, max];
        });

        setNewDepthDomain(([min, max]) => {
          if (min + dy >= domainBounds[1][0] && max + dy <= domainBounds[1][1]) {
            return [min + dy, max + dy];
          } else return [min, max];
        });

        setPanOriginPoint({ x, y });
      } else if (interactionState === InteractionState.Zooming) {
        setZoomRectBounds((prev) => [prev[0], { x, y }]);
      }
    },
    [clampedEventBounds, xScale, yScale, interactionState, panOriginPoint.x, panOriginPoint.y, newXDomain, newYDomain],
  );

  const resetZoom = useCallback(() => {
    setNewXDomain(xScale.domain());
    setNewDepthDomain(yScale.domain());

    setZoomState(ZoomState.Neutral);
    setInteractionState(InteractionState.Neutral);
  }, [xScale, yScale]);

  const handleOnDoubleClick = useCallback(resetZoom, [resetZoom]);

  const handleOnMouseUp = useCallback(() => {
    if (interactionState === InteractionState.Neutral) {
      return null;
    } else if (interactionState === InteractionState.Zooming) {
      if (
        // This condition prevents drawing of rectangles that are too small and might seem like a bug
        // for example, double clicking with a 1px offset should not trigger zoom in on that portion, hence the magic number
        Math.abs(zoomRectBounds[0].x - zoomRectBounds[1].x) > 20 &&
        Math.abs(zoomRectBounds[0].y - zoomRectBounds[1].y) > 20
      ) {
        setNewXDomain([
          xScale.invert(min(zoomRectBounds.map((r) => r.x)) || 0),
          xScale.invert(max(zoomRectBounds.map((r) => r.x)) || 0),
        ]);

        setNewDepthDomain([
          yScale.invert(min(zoomRectBounds.map((r) => r.y)) || 0),
          yScale.invert(max(zoomRectBounds.map((r) => r.y)) || 0),
        ]);

        setInteractionState(InteractionState.Neutral);
        setZoomState(ZoomState.Zoomed);
      }
    } else if (interactionState === InteractionState.Panning) {
      setInteractionState(InteractionState.Neutral);
    }

    setZoomRectBounds(() => [
      { x: 0, y: 0 },
      { x: 0, y: 0 },
    ]);
  }, [interactionState, xScale, yScale, zoomRectBounds]);

  useEffect(() => {
    window.addEventListener("mouseup", handleOnMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleOnMouseUp);
    };
  }, [handleOnMouseUp]);

  const zoomContainerProps = useMemo(() => {
    let cursor = "default";
    const suffix = isDark ? "_light" : "";

    if (isHoveringPoint) {
      cursor = "pointer";
    } else {
      if (zoomState === ZoomState.Zoomed) {
        cursor = "pointer";
      }
      if (interactionState === InteractionState.CanZoom || interactionState === InteractionState.Zooming) {
        cursor = `url(/assets/zoom_in_area${suffix}.svg), auto`;
      } else if (interactionState === InteractionState.Panning) {
        cursor = `url(/assets/zoom_pan${suffix}.svg), auto`;
      }
    }

    return {
      onMouseDown: handleOnMouseDown,
      onPointerMove: handleOnPointerMove,
      onDoubleClick: handleOnDoubleClick,
      style: {
        overflow: "visible",
        userSelect: "none",
        cursor,
      },
    };
  }, [
    handleOnDoubleClick,
    handleOnMouseDown,
    handleOnPointerMove,
    interactionState,
    isDark,
    isHoveringPoint,
    zoomState,
  ]);

  const handleOnZoomClick = useCallback(() => {
    if (interactionState === InteractionState.CanZoom) {
      setInteractionState(InteractionState.Neutral);
    } else if (interactionState === InteractionState.Neutral) {
      setInteractionState(InteractionState.CanZoom);
    }
  }, [interactionState]);

  const cantZoom = interactionState !== InteractionState.Neutral;

  const ZoomRect = cantZoom && (
    <rect
      strokeDasharray="5 10"
      strokeWidth={1}
      strokeOpacity={1}
      stroke="white"
      width={Math.abs(zoomRectBounds[1].x - zoomRectBounds[0].x)}
      height={Math.abs(zoomRectBounds[1].y - zoomRectBounds[0].y)}
      x={(min(zoomRectBounds.map((bound) => bound.x)) || 0) + LEFT_AXIS_WIDTH}
      y={(min(zoomRectBounds.map((bound) => bound.y)) || 0) + HORIZONTAL_AXIS_HEIGHT}
      fill="black"
      fillOpacity={0.3}
    />
  );

  const ZoomControls = () => {
    return (
      <>
        <Tooltip title="Enable zoom">
          <StyledControlsButton
            size="large"
            icon={<PDComponent.SvgIcon name="zoomInAreaSecondary" />}
            disabled={zoomState !== ZoomState.Neutral}
            onClick={handleOnZoomClick}
          />
        </Tooltip>

        <Tooltip title="Pan">
          <StyledControlsButton
            disabled={zoomState === ZoomState.Neutral}
            size="large"
            icon={<PDComponent.SvgIcon name="zoomPanSecondary" />}
          />
        </Tooltip>

        <Tooltip title="Reset zoom">
          <StyledControlsButton
            disabled={zoomState === ZoomState.Neutral}
            size="large"
            onClick={resetZoom}
            icon={<PDComponent.SvgIcon name="reset" />}
          />
        </Tooltip>
      </>
    );
  };

  return { newXDomain, newYDomain, zoomContainerProps, ZoomRect, ZoomControls, resetZoom };
};
