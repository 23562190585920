/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FiltersDto,
  ParameterHeatmapResultSetDto,
  ParameterHeatmapUserLensDto,
  ParameterHeatmapUserLensQueryDto,
} from '../models/index';
import {
    FiltersDtoFromJSON,
    FiltersDtoToJSON,
    ParameterHeatmapResultSetDtoFromJSON,
    ParameterHeatmapResultSetDtoToJSON,
    ParameterHeatmapUserLensDtoFromJSON,
    ParameterHeatmapUserLensDtoToJSON,
    ParameterHeatmapUserLensQueryDtoFromJSON,
    ParameterHeatmapUserLensQueryDtoToJSON,
} from '../models/index';

export interface ApiParameterHeatmapUserLensesAvailableFiltersPutRequest {
    parameterHeatmapUserLensQueryDto?: ParameterHeatmapUserLensQueryDto;
}

export interface ApiParameterHeatmapUserLensesIdFactsPutRequest {
    id: number;
    parameterHeatmapUserLensQueryDto?: ParameterHeatmapUserLensQueryDto;
}

export interface ApiParameterHeatmapUserLensesIdPutRequest {
    id: number;
    parameterHeatmapUserLensDto?: ParameterHeatmapUserLensDto;
}

export interface ApiParameterHeatmapUserLensesPostRequest {
    parameterHeatmapUserLensDto?: ParameterHeatmapUserLensDto;
}

/**
 * 
 */
export class ParameterHeatmapUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiParameterHeatmapUserLensesAvailableFiltersPutRaw(requestParameters: ApiParameterHeatmapUserLensesAvailableFiltersPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FiltersDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ParameterHeatmapUserLenses/AvailableFilters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ParameterHeatmapUserLensQueryDtoToJSON(requestParameters['parameterHeatmapUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FiltersDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiParameterHeatmapUserLensesAvailableFiltersPut(requestParameters: ApiParameterHeatmapUserLensesAvailableFiltersPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FiltersDto> {
        const response = await this.apiParameterHeatmapUserLensesAvailableFiltersPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiParameterHeatmapUserLensesIdFactsPutRaw(requestParameters: ApiParameterHeatmapUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParameterHeatmapResultSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiParameterHeatmapUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ParameterHeatmapUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ParameterHeatmapUserLensQueryDtoToJSON(requestParameters['parameterHeatmapUserLensQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParameterHeatmapResultSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiParameterHeatmapUserLensesIdFactsPut(requestParameters: ApiParameterHeatmapUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParameterHeatmapResultSetDto> {
        const response = await this.apiParameterHeatmapUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiParameterHeatmapUserLensesIdPutRaw(requestParameters: ApiParameterHeatmapUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParameterHeatmapUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiParameterHeatmapUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ParameterHeatmapUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ParameterHeatmapUserLensDtoToJSON(requestParameters['parameterHeatmapUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParameterHeatmapUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiParameterHeatmapUserLensesIdPut(requestParameters: ApiParameterHeatmapUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParameterHeatmapUserLensDto> {
        const response = await this.apiParameterHeatmapUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiParameterHeatmapUserLensesPostRaw(requestParameters: ApiParameterHeatmapUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParameterHeatmapUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ParameterHeatmapUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParameterHeatmapUserLensDtoToJSON(requestParameters['parameterHeatmapUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParameterHeatmapUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiParameterHeatmapUserLensesPost(requestParameters: ApiParameterHeatmapUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParameterHeatmapUserLensDto> {
        const response = await this.apiParameterHeatmapUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
