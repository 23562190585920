import { WebGLSupportBanner } from "components/WebGLSupportBanner/WebGLSupportBanner";
import mapboxgl from "mapbox-gl";
import type { FC } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

const MapContainer = styled.div`
  height: 250px;
  border-radius: 4px;

  * {
    outline: none;
  }
`;

interface IMap {
  lat?: number;
  lng?: number;
}

const Map: FC<IMap> = ({ lat = 0, lng = 0 }) => {
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainer = useRef<HTMLDivElement>(null);
  const [waitForMapLoad, setWaitForMapLoad] = useState(false);

  const isMapboxGLSupported = useMemo(() => mapboxgl.supported(), []);

  useEffect(() => {
    if (!isMapboxGLSupported) return;
    if (mapRef.current && lat !== null && lng !== null && waitForMapLoad) {
      mapRef.current.setCenter([lng, lat]);
      mapRef.current.setZoom(9);

      (mapRef.current.getSource("wells") as mapboxgl.GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [
          {
            properties: [],
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [lng, lat],
            },
          },
        ],
      });
    }
  }, [isMapboxGLSupported, lat, lng, waitForMapLoad]);

  useEffect(() => {
    if (!isMapboxGLSupported) return;
    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current || "",
      style: "mapbox://styles/precisiondrilling/ckkx37upp5icd17orl1e3gai7",
      center: [-79.4512, 43.6568],
      zoom: 3,
      minZoom: 2,
      maxZoom: 23,
      // attributionControl: false,
    })
      .addControl(
        new mapboxgl.ScaleControl({
          maxWidth: 100,
          unit: "metric",
        }),
      )
      .addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
        "top-right",
      )
      .on("load", () => {
        const map = mapRef.current;
        const images = [
          {
            url: "/assets/icons/white_pin.png",
            id: "white_pin",
          },
        ];
        map?.addSource("wells", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });
        Promise.all(
          images.map(
            (img) =>
              new Promise((resolve, reject) => {
                map?.loadImage(img.url, (error, res) => {
                  if (error) reject();
                  if (res) map?.addImage(img.id, res);
                  resolve(img.id);
                });
              }),
          ),
        ).then(() => {
          map?.addLayer({
            id: "points",
            type: "symbol",
            source: "wells",
            layout: {
              "icon-image": "white_pin",
              "icon-size": 0.4,
            },
          });

          setTimeout(() => setWaitForMapLoad(true), 550);
        });
      });

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, [isMapboxGLSupported]);

  return <MapContainer ref={mapContainer}>{isMapboxGLSupported ? null : <WebGLSupportBanner />}</MapContainer>;
};

export default Map;
