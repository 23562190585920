import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css"; // theme css file

import { PDComponent } from "components/PDComponents";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { Range } from "react-date-range";
import InputMask from "react-input-mask";
import colors from "utils/colors";
import { Popover, Space, Tooltip } from "utils/componentLibrary";
import { PeriodType } from "utils/enums";
import { last30Days, today } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import * as Styled from "./styles";
import { StyledButton } from "./styles";

export type RangeType = { startDate?: Date | null; endDate?: Date | null };

export const DatePicker = ({
  selection,
  onApply,
  title = "Date",
  allowAllDates,
}: {
  selection: RangeType;
  onApply: (selection: RangeType) => void;
  title?: string;
  allowAllDates?: boolean;
}) => {
  const isAllDates = allowAllDates && selection.startDate === null;
  const formatDate = (date: Date | number | undefined) => (date ? dayjs(date).format("MM/DD/YYYY") : "");
  const initialRange: RangeType = { startDate: selection.startDate, endDate: selection.endDate };
  const [range, setRange] = useState<Range>({
    startDate: initialRange.startDate || undefined,
    endDate: initialRange.endDate || undefined,
  });

  const initialStart = useMemo(
    () => (isAllDates || !selection.startDate ? "" : formatDate(selection.startDate)),
    [isAllDates, selection.startDate],
  );

  const initialEnd = useMemo(
    () => (isAllDates || !selection.endDate ? "" : formatDate(selection.endDate)),
    [isAllDates, selection.endDate],
  );

  useEffect(() => {
    setStartInput(initialStart);
    setEndInput(initialEnd);
    setRange({ startDate: selection.startDate ?? undefined, endDate: selection.endDate ?? undefined });
  }, [initialEnd, initialStart, selection.endDate, selection.startDate]);

  const [presetVisible, setPresetVisible] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);

  const [startInput, setStartInput] = useState(initialStart);
  const [endInput, setEndInput] = useState(initialEnd);
  const [presetDate, setPresetDate] = useState<PeriodType>(PeriodType.Days90);

  const minDate = dayjs(new Date(2016, 0, 1)).toDate();
  const presetDates = useMemo(
    () => ({
      Today: today.getTime(),
      Days3: dayjs(today).subtract(2, "day").toDate().getTime(),
      Days7: dayjs(today).subtract(6, "day").toDate().getTime(),
      Days30: dayjs(today).subtract(29, "day").toDate().getTime(),
      Days90: dayjs(today).subtract(89, "day").toDate().getTime(),
      Months6: dayjs(today).subtract(6, "month").toDate().getTime(),
      Months12: dayjs(today).subtract(12, "month").toDate().getTime(),
    }),
    [],
  );

  const getPresetByDate = useCallback(
    (dateRange: Range) => {
      let preset: PeriodType;
      if (allowAllDates && dateRange.startDate === null) {
        preset = PeriodType.All;
      } else if (dateRange.endDate?.getTime() === presetDates.Today) {
        const key = (Object.keys(presetDates) as (keyof typeof presetDates)[]).find(
          (key) => presetDates[key] === dateRange.startDate?.getTime(),
        );
        preset = key ? PeriodType[key] : PeriodType.Custom;
      } else {
        preset = PeriodType.Custom;
      }
      return preset;
    },
    [presetDates, allowAllDates],
  );

  useEffect(() => {
    const newPreset = getPresetByDate(range);
    if (presetDate !== newPreset) {
      setPresetDate(newPreset);
    }
  }, [getPresetByDate, range, presetDate]);

  const hideCalendar = useCallback(() => {
    setCalendarVisible(false);
  }, [setCalendarVisible]);

  useEffect(() => {
    window.addEventListener("scroll", hideCalendar);
    return () => window.removeEventListener("scroll", hideCalendar);
  }, [hideCalendar]);

  const { themeStyle } = useCustomTheme();

  return (
    <Tooltip title={title}>
      <Popover
        content={
          <Space
            direction="vertical"
            style={{
              padding: "12px",
              background: themeStyle.colors.tertiary_bg,
            }}
          >
            <Styled.Container>
              <Popover
                content={
                  <Styled.Menu>
                    {allowAllDates ? (
                      <Styled.Item
                        key={"option-all"}
                        isActive={range.startDate === null}
                        onClick={() => {
                          setRange({
                            startDate: undefined,
                            endDate: today,
                          });
                          setStartInput("");
                          setEndInput("");
                          setPresetVisible(false);
                        }}
                      >
                        {"All Dates"}
                      </Styled.Item>
                    ) : null}
                    {(Object.keys(PeriodType) as (keyof typeof PeriodType)[])
                      .filter((option) => ![PeriodType.Custom, PeriodType.All].includes(PeriodType[option]))
                      .map((option) => (
                        <Styled.Item
                          key={option}
                          isActive={PeriodType[option] === presetDate}
                          onClick={
                            PeriodType[option] === presetDate
                              ? undefined
                              : () => {
                                  const startDate = dayjs(presetDates[option as keyof typeof presetDates]).toDate();
                                  const endDate = dayjs(today).toDate();
                                  setRange({
                                    startDate,
                                    endDate,
                                  });
                                  setStartInput(formatDate(startDate));
                                  setEndInput(formatDate(endDate));
                                  setPresetVisible(false);
                                  setPresetDate(PeriodType[option]);
                                }
                          }
                        >
                          {PeriodType[option]}
                        </Styled.Item>
                      ))}
                  </Styled.Menu>
                }
                open={presetVisible}
                onOpenChange={(visible) => {
                  setPresetVisible(visible);
                }}
                placement="bottomLeft"
                trigger={["click"]}
              >
                <Styled.Button isActive={presetVisible}>
                  {presetDate}
                  <Styled.Icon up={presetVisible}>
                    <PDComponent.SvgIcon name="chevronDown" />
                  </Styled.Icon>
                </Styled.Button>
              </Popover>
              <Styled.DateInputContainer>
                <InputMask
                  mask="99/99/9999"
                  maskPlaceholder="mm/dd/yyyy"
                  placeholder="mm/dd/yyyy"
                  value={startInput}
                  onChange={(e) => {
                    setStartInput(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "NumpadEnter") {
                      e.currentTarget.blur();
                    }
                  }}
                  onBlur={() => {
                    let newDate = +dayjs(startInput, "MM/DD/YYYY");
                    if (Number.isNaN(new Date(startInput).getTime())) {
                      setStartInput(endInput);
                      setRange({ ...range, startDate: range.endDate });
                      return;
                    } else if (newDate < minDate.getTime()) {
                      setStartInput(formatDate(minDate));
                      newDate = +dayjs(minDate);
                    } else if (+newDate > presetDates.Today) {
                      setStartInput(formatDate(today));
                      newDate = +dayjs(today);
                    }
                    if (range?.endDate && +range?.endDate < newDate) {
                      setEndInput(formatDate(newDate));
                      setRange({ ...range, endDate: new Date(newDate), startDate: new Date(newDate) });
                    } else {
                      setRange({ ...range, startDate: new Date(newDate) });
                    }
                  }}
                />
                <Styled.DateSeparator>-</Styled.DateSeparator>
                <InputMask
                  mask="99/99/9999"
                  maskPlaceholder=" "
                  placeholder="mm/dd/yyyy"
                  value={endInput}
                  onChange={(e) => {
                    setEndInput(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "NumpadEnter") {
                      e.currentTarget.blur();
                    }
                  }}
                  onBlur={() => {
                    let newDate = +dayjs(endInput, "MM/DD/YYYY");
                    if (Number.isNaN(new Date(endInput).getTime())) {
                      setEndInput(startInput);
                      setRange({ ...range, endDate: range.startDate });
                      return;
                    } else if (newDate < minDate.getTime()) {
                      setEndInput(formatDate(minDate));
                      newDate = +dayjs(minDate);
                    } else if (+newDate > presetDates.Today) {
                      setEndInput(formatDate(today));
                      newDate = +dayjs(today);
                    }
                    if (range.startDate && +range.startDate > newDate) {
                      setStartInput(formatDate(newDate));
                      setRange({ ...range, startDate: new Date(newDate), endDate: new Date(newDate) });
                    } else {
                      setRange({ ...range, endDate: new Date(newDate) });
                    }
                  }}
                />
              </Styled.DateInputContainer>

              <Styled.DateRange
                locale={{
                  formatLong: {
                    date: (date) => dayjs(date).format("MMMM D, YYYY"),
                    time: (time) => dayjs(time).format("h:mm A"),
                    dateTime: (dateTime) => dayjs(dateTime).format("MMMM D, YYYY h:mm A"),
                  },
                  localize: {
                    ordinalNumber: (n) => n.toString(),
                    era: () => "",
                    quarter: () => "",
                    month: (n) => dayjs().month(n).format("MMMM"),
                    day: (n) => dayjs().day(n).format("ddd"),
                    dayPeriod: () => "",
                  },
                }}
                onChange={({ selection }) => {
                  const startDate = selection.startDate;
                  const endDate = selection.endDate;
                  setStartInput(formatDate(startDate));
                  setEndInput(formatDate(endDate));
                  setRange({
                    startDate,
                    endDate,
                  });
                }}
                preventSnapRefocus
                startDatePlaceholder="mm/dd/yyyy"
                endDatePlaceholder="mm/dd/yyyy"
                dateDisplayFormat="MM/dd/yyyy"
                monthDisplayFormat="MMMM yyyy"
                showDateDisplay={false}
                months={1}
                scroll={{ enabled: true, monthHeight: 230, longMonthHeight: 266 }}
                ranges={[{ ...range, key: "selection" }]}
                rangeColors={[colors.well_color]}
                maxDate={today}
                minDate={minDate}
              />
              <Styled.Footer>
                <Styled.ResetButton
                  onClick={() => {
                    setRange({
                      startDate: initialRange.startDate || undefined,
                      endDate: initialRange.endDate || undefined,
                    });
                    setStartInput(isAllDates || !initialRange.startDate ? "" : formatDate(initialRange.startDate));
                    setEndInput(isAllDates || !initialRange.endDate ? "" : formatDate(initialRange.endDate));
                  }}
                >
                  Reset
                </Styled.ResetButton>
                <Styled.ApplyButton
                  type="primary"
                  onClick={() => {
                    onApply({
                      startDate: range.startDate || last30Days,
                      endDate: range.endDate || today,
                    });
                    setPresetVisible(false);
                    setCalendarVisible(false);
                  }}
                >
                  Apply
                </Styled.ApplyButton>
              </Styled.Footer>
            </Styled.Container>
          </Space>
        }
        trigger="click"
        placement="bottom"
        open={calendarVisible}
        onOpenChange={(e) => setCalendarVisible(e)}
        destroyTooltipOnHide
      >
        <StyledButton
          size="large"
          icon={<PDComponent.SvgIcon name="calendar" />}
          $engaged={calendarVisible}
          $isActive={!isAllDates}
        >
          {isAllDates ? null : (
            <>
              {getPresetByDate({
                startDate: initialRange.startDate || undefined,
                endDate: initialRange.endDate || undefined,
              }) === PeriodType.Custom
                ? `${dayjs(initialRange.startDate).format("M/D/YYYY")} - ${dayjs(initialRange.endDate).format(
                    "M/D/YYYY",
                  )}`
                : getPresetByDate({
                    startDate: initialRange.startDate || undefined,
                    endDate: initialRange.endDate || undefined,
                  })}
            </>
          )}
        </StyledButton>
      </Popover>
    </Tooltip>
  );
};
