import { AxisBottom } from "@visx/axis";
import { LinearGradient } from "@visx/gradient";
import { Group } from "@visx/group";
import { PatternLines } from "@visx/pattern";
import { scaleBand, scaleLinear } from "@visx/scale";
import { BarRounded, Circle } from "@visx/shape";
import type { RigCardFactDto, RigCardFactSeriesDto, UserLensDto } from "apis/oag";
import { DashboardType, TimeUnit } from "apis/oag";
import { Chart } from "components/Lenses/common/Chart";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { getSVGNormalizedValue } from "components/Lenses/utils";
import { RigScoreCardCommentsModal } from "components/RigScoreCardCommentsModal";
import { max } from "d3-array";
import { useRigs } from "hooks/useRigs";
import { useSelectedRig } from "hooks/useSelectedRig";
import { useUserLensTabs } from "hooks/useUserLensTabs";
import { useWellShortInfoSuspended } from "hooks/useWellShortInfo";
import { TSummary } from "pages/RigLeaderboard/components/ScoreBreakout/SummarizedComments";
import { useScoreBreakoutModal } from "pages/RigLeaderboard/components/ScoreBreakout/useScoreBreakoutModal";
import type { CommentsModalInfoType } from "pages/RigScoreCard/LeftPane/RigScoreCard";
import { CardWrapper, ChartWrapper, RigScoreCardContainer } from "pages/RigScoreCard/LeftPane/Styled";
import {
  barOpacity,
  getColorByScore,
  SCORECARD_AXIS_HEIGHT,
  SCORECARD_HEIGHT_WELL,
  SCORECARD_HORIZONTAL_PADDING,
  SCORECARD_MIN_TITLE_HEIGHT,
  SCORECARD_PADDING,
  useScoreCardTags,
} from "pages/RigScoreCard/LeftPane/utils";
import { BenchmarkType, useScoreBenchmarkContext } from "pages/RigScoreCard/ScoreBenchmarkContext";
import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { FALLBACK_NUM_TICKS_Y_AXIS } from "utils/constants";
import { useTimeUom, useUOM, UtilDimensions } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

import CommentCircle from "./CommentCircle";
import { CustomTag } from "./CustomTag";
import { GroupTitle, StyledTitle } from "./GroupTitle";

const WELL_SCORECARD_PADDING = 16;
const MAX_WELLS_PILLS = 15;

const WellScorecard = memo(
  ({
    rigCardScore,
    showNavigationButton,
    lens,
    getDetailedViewUrl,
  }: {
    rigCardScore: RigCardFactSeriesDto;
    showNavigationButton: boolean;
    lens: UserLensDto;
    getDetailedViewUrl: (wellId: number) => string;
  }) => {
    const { openScoreBreakout, scoreBreakoutElement } = useScoreBreakoutModal();

    const { width: chartWidthHook, ref: containerRef } = useResizeDetector({
      refreshOptions: { leading: true, trailing: true },
    });
    const chartWidth = getSVGNormalizedValue((chartWidthHook || 0) - 2);
    const chartHeight = SCORECARD_HEIGHT_WELL - SCORECARD_PADDING - SCORECARD_MIN_TITLE_HEIGHT;
    const plotHeight = chartHeight - SCORECARD_AXIS_HEIGHT - SCORECARD_PADDING * 2 - WELL_SCORECARD_PADDING;
    const plotWidth = chartWidth > SCORECARD_HORIZONTAL_PADDING ? chartWidth - SCORECARD_HORIZONTAL_PADDING : 0;
    const { scoreBenchmark } = useScoreBenchmarkContext();
    const [hoveredWellId, setHoveredWellId] = useState<number | null>(null);
    const hourUom = useTimeUom(TimeUnit.Hour);
    const uom = useUOM(UtilDimensions.Percentage);
    const { data: wellShortInfo } = useWellShortInfoSuspended({ staleTime: Infinity });
    const titleRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip<RigCardFactDto>({
      containerRef,
      renderContent: ({ tooltipData }) => {
        return (
          <>
            <TooltipHighlightValue>
              Well scores:{" "}
              {uom.display(
                scoreBenchmark === BenchmarkType.OperatorTarget
                  ? tooltipData?.operatorScore?.value
                  : tooltipData?.rigScore?.value,
              )}
            </TooltipHighlightValue>
            <span>
              Time vs {scoreBenchmark === BenchmarkType.OperatorTarget ? "Target" : "Benchmark"}:{" "}
              {(() => {
                const opTime =
                  scoreBenchmark === BenchmarkType.OperatorTarget
                    ? tooltipData?.targetDeltaTime?.value
                    : tooltipData?.invisibleLostTime?.value;
                if (!opTime) return `-`;
                return hourUom.display(opTime);
              })()}
            </span>
            {tooltipData?.label ? <span>{wellShortInfo?.byId?.[+tooltipData?.label]?.name ?? ""}</span> : null}
            <span>
              #
              {scoreBenchmark === BenchmarkType.OperatorTarget
                ? tooltipData?.operatorScore.rank
                : tooltipData?.rigScore?.rank}
            </span>
          </>
        );
      },
    });
    const [commentsModalInfo, setCommentsModalInfo] = useState<CommentsModalInfoType | null>(null);

    const transformedData = useMemo(() => {
      if (!rigCardScore || !lens) return null;
      return rigCardScore.facts ?? null;
    }, [lens, rigCardScore]);
    const isEmptyLens = useMemo(
      () =>
        transformedData?.every(
          (e) =>
            (scoreBenchmark === BenchmarkType.OperatorTarget ? e.operatorScore?.value : e.rigScore?.value) ===
            undefined,
        ),
      [scoreBenchmark, transformedData],
    );
    const valueScale = useMemo(() => {
      const values = !transformedData
        ? []
        : transformedData.map((e) =>
            scoreBenchmark === BenchmarkType.OperatorTarget ? e.operatorScore?.value || 0 : e.rigScore?.value || 0,
          );

      const retVal = isEmptyLens ? [1] : values;

      // Check if we can extend the values to get nice increments
      return scaleLinear<number>({
        domain: [max(retVal) || 0, 0],
        range: [0, plotHeight],
      });
    }, [scoreBenchmark, transformedData, plotHeight, isEmptyLens]);
    const categoryScale = useMemo(() => {
      return scaleBand<number>({
        domain: !transformedData
          ? []
          : transformedData
              .map((i) => (scoreBenchmark === BenchmarkType.OperatorTarget ? i.operatorScore.rank : i.rigScore?.rank))
              .reverse(),
        range: [0, plotWidth],
        paddingInner: 0.2,
        paddingOuter: 0.5,
      });
    }, [plotWidth, scoreBenchmark, transformedData]);
    const {
      themeStyle: { colors: themeColors },
    } = useCustomTheme();
    const getValue = useCallback(
      (d: RigCardFactDto) => {
        return valueScale(
          scoreBenchmark === BenchmarkType.OperatorTarget ? d.operatorScore?.value || 0 : d.rigScore?.value || 0,
        );
      },
      [scoreBenchmark, valueScale],
    );
    const { getTagsByAveragesOrFact } = useScoreCardTags({ borderless: true });

    const getWellTags = useCallback(
      (fact: RigCardFactDto) => {
        const tags = getTagsByAveragesOrFact({ fact });
        return [tags.score, tags.opportunityTime];
      },
      [getTagsByAveragesOrFact],
    );

    const handleMouseOver = useCallback(
      (d: RigCardFactDto) => {
        if (!d) return;
        const leftX =
          (categoryScale(
            (scoreBenchmark === BenchmarkType.OperatorTarget ? d.operatorScore.rank : d.rigScore?.rank) ?? 0,
          ) ?? 0) +
          categoryScale.bandwidth() / 2;
        const barHeight = Math.min(plotHeight - getValue(d), plotHeight);
        const barYException = plotHeight - barHeight;
        setHoveredWellId(+d.label);
        showTooltip({
          tooltipLeft: leftX,
          tooltipTop: barYException + SCORECARD_PADDING + 3 * WELL_SCORECARD_PADDING,
          tooltipData: d,
        });
      },
      [categoryScale, scoreBenchmark, plotHeight, getValue, showTooltip],
    );

    const handleOnMouseClick = useCallback(
      (d: RigCardFactDto) => {
        Track.clickEvent("Rig Scorecard - Bar Clicked", {
          "Well Rank": scoreBenchmark === BenchmarkType.OperatorTarget ? d.operatorScore.rank : d.rigScore?.rank,
          "Well Id": +d.label,
        });
        setCommentsModalInfo({
          d,
          wellNumber: scoreBenchmark === BenchmarkType.OperatorTarget ? d.operatorScore?.rank : d.rigScore?.rank,
          wellId: +d.label,
          kpiValue: scoreBenchmark === BenchmarkType.OperatorTarget ? d.operatorScore : d.rigScore,
          tags: getWellTags(d),
          lensId: lens.id,
          wellName: wellShortInfo?.byId?.[+d?.label]?.name ?? "",
          targetValue: null,
          baseValue: null,
          value: scoreBenchmark === BenchmarkType.OperatorTarget ? d.operatorScore?.value : d.rigScore?.value,
          exception: d.kpiValue.exception,
        });
      },
      [getWellTags, lens.id, scoreBenchmark, wellShortInfo?.byId],
    );
    const selectedRig = useSelectedRig();
    const { data: rigs } = useRigs({ staleTime: Infinity });
    const factInfo = useAppSelector((state) => state.rigDashboard.factInfo);

    const { data: lensTabs } = useUserLensTabs(DashboardType.RigScorecard, { staleTime: Infinity });

    const lensGroupName = useMemo(
      () => lensTabs?.find((tab) => tab.id === lens.lensTabId)?.name ?? "",
      [lens.lensTabId, lensTabs],
    );
    const lensGroupDescription = useMemo(
      () => lensTabs?.find((tab) => tab.id === lens.lensTabId)?.description ?? "",
      [lens.lensTabId, lensTabs],
    );
    const rigName = useMemo(() => {
      return rigs?.byId[selectedRig]?.shortName || "";
    }, [rigs?.byId, selectedRig]);
    const navigateToDetailedView = useCallback(
      (d: RigCardFactDto) => {
        if (showNavigationButton) return getDetailedViewUrl(+d.label);
        return "";
      },
      [getDetailedViewUrl, showNavigationButton],
    );

    const CommentsModalElement = useMemo(() => {
      return commentsModalInfo ? (
        <RigScoreCardCommentsModal
          kpiType={rigCardScore.kpiType}
          wellNumber={commentsModalInfo.wellNumber}
          rigName={rigName}
          wellId={commentsModalInfo.wellId}
          wellName={commentsModalInfo.wellName || "Unknown Well"}
          kpiName={lensGroupName}
          kpiDescription={lensGroupDescription}
          kpiLabels={[
            <span key={`adj-well-scores`}>
              <b>Well Scores:</b> {uom.display(commentsModalInfo.value)}
            </span>,
          ]}
          unformattedValue={(commentsModalInfo.value || 0) + (commentsModalInfo.exception || 0)}
          baseValue={null}
          targetValue={null}
          lensId={lens?.id}
          tags={commentsModalInfo.tags}
          navigateToDetailedView={() => navigateToDetailedView(commentsModalInfo.d)}
          hasDrillerComments={false}
          onClose={() => {
            setCommentsModalInfo(null);
            // TODO invalidate query to resolve item please!
          }}
        />
      ) : null;
    }, [
      commentsModalInfo,
      lens?.id,
      lensGroupDescription,
      lensGroupName,
      navigateToDetailedView,
      rigCardScore.kpiType,
      rigName,
      uom,
    ]);

    const getBarSelected = useCallback(
      (d: RigCardFactDto) => {
        const thisWellId = +d.label;
        if (!Number.isFinite(factInfo?.cardGroupId) && !Number.isFinite(factInfo?.kpiId)) {
          return factInfo?.wellId === thisWellId || factInfo?.wellId === undefined;
        } else {
          return (
            factInfo?.cardGroupId === lens.lensTabId &&
            factInfo?.kpiId === rigCardScore.kpiType &&
            factInfo?.wellId === thisWellId
          );
        }
      },
      [factInfo, lens.lensTabId, rigCardScore.kpiType],
    );

    const getOpacity = useCallback(
      (d: RigCardFactDto) => {
        return getBarSelected(d) ? barOpacity.default : barOpacity.transparent;
      },
      [getBarSelected],
    );
    const scorecardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (transformedData?.some(getBarSelected) && factInfo?.cardGroupId !== undefined && factInfo?.kpiId !== undefined)
        setTimeout(() => scorecardRef.current?.scrollIntoView({ behavior: "smooth", block: "center" }), 100);
    }, [factInfo, getBarSelected, transformedData]);
    useLayoutEffect(() => {
      if (rigCardScore?.facts) {
        dispatch({
          type: "SET_SELECTED_WELLS_INFO",
          payload: rigCardScore.facts.map((fact) => {
            return {
              wellId: +fact.label,
              rigScore: fact.rigScore,
              operatorScore: fact.operatorScore,
            };
          }),
        });
      }
    }, [dispatch, rigCardScore?.facts]);

    return (
      <RigScoreCardContainer height={SCORECARD_HEIGHT_WELL} ref={scorecardRef}>
        <CardWrapper>
          <GroupTitle inner ref={titleRef} title="Well Scores" tags={[]} />
          <ChartWrapper
            height={
              SCORECARD_HEIGHT_WELL -
              SCORECARD_PADDING -
              Math.max(titleRef?.current?.clientHeight ?? 0, SCORECARD_MIN_TITLE_HEIGHT)
            }
            ref={containerRef}
          >
            {categoryScale?.domain()?.length > 0
              ? categoryScale.domain().map((id, index) => {
                  const d = transformedData?.find(
                    (i) =>
                      (scoreBenchmark === BenchmarkType.OperatorTarget ? i.operatorScore.rank : i.rigScore?.rank) ===
                      id,
                  );
                  if (!chartWidthHook) return null;
                  if (categoryScale.domain().length > MAX_WELLS_PILLS && index % 2 === 1) return null;
                  return (
                    <div
                      key={`score-label-kpi-${d?.label}`}
                      style={{
                        position: "absolute",
                        top: WELL_SCORECARD_PADDING,
                        left:
                          (categoryScale(
                            scoreBenchmark === BenchmarkType.OperatorTarget
                              ? d?.operatorScore?.rank || 0
                              : d?.rigScore?.rank || 0,
                          ) || 0) +
                          categoryScale.bandwidth() / 2,
                        transform: "translateX(-50%)",
                      }}
                    >
                      <CustomTag
                        color={getColorByScore(
                          scoreBenchmark === BenchmarkType.OperatorTarget
                            ? d?.operatorScore?.value || 0
                            : d?.rigScore?.value || 0,
                        )}
                        text={uom.display(
                          scoreBenchmark === BenchmarkType.OperatorTarget
                            ? d?.operatorScore?.value
                            : d?.rigScore?.value,
                          { fractionDigits: 0 },
                        )}
                        variant="filled"
                        size="default"
                        onClick={() => {
                          const opportunityKey =
                            scoreBenchmark === BenchmarkType.OperatorTarget ? "targetDeltaTime" : "invisibleLostTime";
                          const opportunityTime = d?.scoreSummary?.[opportunityKey];
                          openScoreBreakout({
                            name: `#${d?.operatorScore.rank} ${wellShortInfo?.byId?.[+(d?.label || "")]?.name ?? ""}`,
                            id: selectedRig,
                            selectedWell: +(d?.label || ""),
                            opportunityTime: opportunityTime || 0,
                            score:
                              scoreBenchmark === BenchmarkType.OperatorTarget
                                ? d?.operatorScore?.value || 0
                                : d?.rigScore?.value || 0,
                            scoreSummary: d?.scoreSummary,
                            summaryType: TSummary.Well,
                            // maybe we need the well bars inside here as well
                            wellInfo: rigCardScore.facts.map((fact) => {
                              return {
                                wellId: +fact.label,
                                rigScore: fact.rigScore?.value || 0,
                                operatorScore: fact.operatorScore?.value || 0,
                              };
                            }),
                          });
                        }}
                      />
                    </div>
                  );
                })
              : null}
            {isEmptyLens ? (
              <StyledTitle
                $inner={"true"}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  fontSize: "12px",
                  transform: "translate(-50%, -50%)",
                }}
              >
                No score data available{" "}
              </StyledTitle>
            ) : null}
            <svg
              width={chartWidth}
              height={chartHeight}
              style={{
                overflow: "visible",
                userSelect: "none",
                paddingTop: SCORECARD_PADDING * 7,
              }}
            >
              <Chart
                detailed={false}
                isManual={false}
                chartWidth={plotWidth}
                chartHeight={chartHeight}
                plotWidth={plotWidth}
                plotHeight={plotHeight}
                valueScale={valueScale}
                showOperationCount={false}
                categoryScale={categoryScale}
                maxNumFractionalDigits={1}
                valueUOM={uom}
                disableValueAxis={isEmptyLens}
                tickLabelFontSizeDefault="12px"
                bottomTickComponent={() => ""}
                hideZeroValues
                showRightLine={false}
                defaultNumTicks={FALLBACK_NUM_TICKS_Y_AXIS - 1}
              >
                <AxisBottom
                  top={plotHeight}
                  scale={categoryScale}
                  numTicks={Math.min(
                    categoryScale.domain().length,
                    Math.max(5, (categoryScale.bandwidth() / 60) * categoryScale.domain().length),
                  )}
                  tickLabelProps={() => ({
                    fontSize: "12px",
                    fill: themeColors.disabled_typography,
                    letterSpacing: "-0.2px",
                    textAnchor: "middle",
                  })}
                  tickFormat={(id) => `#${id}`}
                  hideAxisLine
                  hideTicks
                />
                {transformedData?.map((d, index) => {
                  if (d?.label === undefined) return null;
                  const x =
                    categoryScale(
                      scoreBenchmark === BenchmarkType.OperatorTarget ? d.operatorScore.rank : d.rigScore?.rank,
                    ) || 0;
                  if (!Number.isFinite(x) || x < 0) return null;
                  const barHeight = Math.min(plotHeight - getValue(d), plotHeight);
                  const barY = plotHeight - barHeight;
                  const color = getColorByScore(
                    scoreBenchmark === BenchmarkType.OperatorTarget
                      ? d?.operatorScore?.value || 0
                      : d?.rigScore?.value || 0,
                  );
                  const gradientToColor = `${color}70`;
                  const gradientFromColor = `${color}`;
                  return (
                    <React.Fragment key={`${d?.label}-bar`}>
                      <LinearGradient
                        from={gradientFromColor}
                        to={gradientToColor}
                        fromOpacity={1}
                        toOpacity={0.9}
                        id={`linear_gradient_${gradientToColor}_${lens.id}`}
                      />
                      <PatternLines
                        id={`pattern_lines_${gradientToColor}_${lens.id}`}
                        height={5}
                        width={5}
                        stroke={`url(#linear_gradient_${gradientToColor}_${lens.id})`}
                        strokeWidth={1.5}
                        orientation={["diagonalRightToLeft"]}
                      />

                      <BarRounded
                        radius={4}
                        top
                        x={x}
                        y={barY}
                        key={`single-kpi-${d?.label}-${index}`}
                        width={categoryScale.bandwidth()}
                        height={barHeight}
                        opacity={getOpacity(d)}
                        cursor="pointer"
                        onMouseOver={() => {
                          handleMouseOver(d);
                        }}
                        onClick={() => handleOnMouseClick(d)}
                        onMouseOut={() => {
                          hideTooltip();
                          setHoveredWellId(null);
                        }}
                        fill={`url(#linear_gradient_${gradientToColor}_${lens.id})`}
                      />

                      {!(scoreBenchmark === BenchmarkType.OperatorTarget
                        ? d.operatorScore.value
                        : d.rigScore?.value) ? null : (
                        <Group pointerEvents={"none"} key={`${d.label}+i`}>
                          <CommentCircle
                            fact={d}
                            cx={
                              (categoryScale(
                                scoreBenchmark === BenchmarkType.OperatorTarget
                                  ? d.operatorScore.rank
                                  : d.rigScore?.rank,
                              ) || 0) +
                              categoryScale.bandwidth() / 2
                            }
                            cy={valueScale(valueScale.domain()[1])}
                            r={7.5}
                            lensId={lens?.id}
                          />

                          {/* Change this one */}
                        </Group>
                      )}
                      {hoveredWellId === +d?.label && !isEmptyLens && (
                        <Group
                          left={
                            (categoryScale(
                              scoreBenchmark === BenchmarkType.OperatorTarget ? d.operatorScore.rank : d.rigScore?.rank,
                            ) || 0) +
                            categoryScale.bandwidth() / 2 -
                            7
                          }
                          top={valueScale(valueScale.domain()[1]) - 4}
                          pointerEvents={"none"}
                        >
                          <Circle cursor="pointer" cx={7} cy={7} r={14} z={12} fill={colors.well_color}></Circle>
                          <svg
                            z={1}
                            focusable="false"
                            fill={colors.white}
                            width="1em"
                            height="1em"
                            viewBox="0 0 32 32"
                            aria-hidden="true"
                          >
                            <path d="M17.74,30,16,29l4-7h6a2,2,0,0,0,2-2V8a2,2,0,0,0-2-2H6A2,2,0,0,0,4,8V20a2,2,0,0,0,2,2h9v2H6a4,4,0,0,1-4-4V8A4,4,0,0,1,6,4H26a4,4,0,0,1,4,4V20a4,4,0,0,1-4,4H21.16Z" />
                            <path d="M8 10H24V12H8zM8 16H18V18H8z" />
                          </svg>
                        </Group>
                      )}
                    </React.Fragment>
                  );
                })}
              </Chart>
            </svg>
            {tooltipElement}
            {scoreBreakoutElement}
            {CommentsModalElement}
          </ChartWrapper>
        </CardWrapper>
      </RigScoreCardContainer>
    );
  },
);

export { WellScorecard };
