import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ScorecardKpiCommentsApi, UserRoleType } from "apis/oag";
import { PDComponent } from "components/PDComponents";
import { CommentStatus } from "components/RigScoreCardCommentsModal/CommentStatus/CommentStatus";
import * as CommonStyled from "components/RigScoreCardCommentsModal/styled";
import { CommentViewMode } from "components/RigScoreCardCommentsModal/utils";
import dayjs from "dayjs";
import { RigScorecardInvalidationKey } from "hooks/useRigScorecardCardFacts";
import { CommentsInvalidationKey } from "hooks/useScoreCardComments";
import type { TExtendedComment } from "pages/RigLeaderboard/components/ScoreBreakout/SummarizedComments";
import React, { useCallback, useEffect } from "react";
import { useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { apiConfig } from "utils/apiConfig";
import { SHORT_DATE_FORMAT } from "utils/format";
import { useCustomTheme } from "utils/useTheme";

interface SingleCommentBoxProps {
  comment: TExtendedComment;
  commentViewMode: CommentViewMode;
  setCurrentlyEditedComment: React.Dispatch<React.SetStateAction<TExtendedComment | null>>;
  setCommentViewMode: React.Dispatch<React.SetStateAction<CommentViewMode>>;
  paddingTop?: number;
}

const commentsApi = new ScorecardKpiCommentsApi(apiConfig);

export const SingleCommentBox: React.FC<SingleCommentBoxProps> = ({
  comment,
  commentViewMode,
  setCurrentlyEditedComment,
  setCommentViewMode,
  paddingTop = 32,
}) => {
  const user = useAppSelector((state) => state.global.userInfo);

  const removeComment = useMutation({
    mutationFn: (commentId: number) => {
      if (!commentId) return Promise.reject("Invalid comment id");
      return commentsApi.apiScorecardKpiCommentsIdDelete({ id: commentId });
    },
  });

  useEffect(() => {
    if (commentViewMode === CommentViewMode.POST || commentViewMode === CommentViewMode.LIST) {
      setCurrentlyEditedComment(null);
    }
  }, [commentViewMode, setCurrentlyEditedComment]);

  const handleOnEditCommentClick = useCallback(
    (comment: TExtendedComment) => {
      Track.clickEvent("Rig Scorecard - Edit Comment", { commentId: comment.id });
      setCurrentlyEditedComment(comment);
      setCommentViewMode(CommentViewMode.EDIT);
    },
    [setCommentViewMode, setCurrentlyEditedComment],
  );

  const queryClient = useQueryClient();

  const handleOnCommentDeleteClick = useCallback(
    async (commentId: number) => {
      Track.interact("Rig Scorecard - Delete comment", { commentId: commentId });
      await removeComment.mutateAsync(commentId);
      queryClient.invalidateQueries({ queryKey: [RigScorecardInvalidationKey] });
      queryClient.invalidateQueries({ queryKey: [CommentsInvalidationKey] });
    },
    [queryClient, removeComment],
  );

  const { themeStyle } = useCustomTheme();

  return (
    <CommonStyled.CommentContainer $paddingTop={paddingTop}>
      <CommonStyled.CommentIconContainer>
        <CommonStyled.CommentIcon>
          <PDComponent.SvgIcon name="chat" />
        </CommonStyled.CommentIcon>
      </CommonStyled.CommentIconContainer>
      <CommonStyled.ExistingComment>
        <CommonStyled.CommentHeader>
          <CommonStyled.CommentHeaderDescription>
            <CommonStyled.DateText>{dayjs(comment.updatedAt.utc).format(SHORT_DATE_FORMAT)}</CommonStyled.DateText>
            <CommonStyled.Author>{comment.authorDisplayName}</CommonStyled.Author>
          </CommonStyled.CommentHeaderDescription>
          <CommonStyled.CommentHeaderControls>
            {user?.role === UserRoleType.Administrator || user?.id === comment.authorId ? (
              <>
                <CommonStyled.CommentButton
                  color={themeStyle.colors.disabled_typography}
                  onClick={() => handleOnCommentDeleteClick(comment.id)}
                >
                  <span>Delete </span> <PDComponent.SvgIcon name="trashCan" />
                </CommonStyled.CommentButton>
                <CommonStyled.CommentButton onClick={() => handleOnEditCommentClick(comment)}>
                  <span>Edit </span> <PDComponent.SvgIcon name="edit" />
                </CommonStyled.CommentButton>
              </>
            ) : null}
          </CommonStyled.CommentHeaderControls>
        </CommonStyled.CommentHeader>
        <CommonStyled.ExistingCommentContents>{comment.description}</CommonStyled.ExistingCommentContents>
        {comment.exceptionStatus ? <CommentStatus comment={comment} /> : null}
      </CommonStyled.ExistingComment>
    </CommonStyled.CommentContainer>
  );
};

export default SingleCommentBox;
