/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VisualAidType } from './VisualAidType';
import {
    VisualAidTypeFromJSON,
    VisualAidTypeFromJSONTyped,
    VisualAidTypeToJSON,
} from './VisualAidType';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';
import type { TimeUnit } from './TimeUnit';
import {
    TimeUnitFromJSON,
    TimeUnitFromJSONTyped,
    TimeUnitToJSON,
} from './TimeUnit';
import type { OutlierFlaggingType } from './OutlierFlaggingType';
import {
    OutlierFlaggingTypeFromJSON,
    OutlierFlaggingTypeFromJSONTyped,
    OutlierFlaggingTypeToJSON,
} from './OutlierFlaggingType';
import type { PivotType } from './PivotType';
import {
    PivotTypeFromJSON,
    PivotTypeFromJSONTyped,
    PivotTypeToJSON,
} from './PivotType';

/**
 * 
 * @export
 * @interface PivotKpiTypeUserLensDto
 */
export interface PivotKpiTypeUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof PivotKpiTypeUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiTypeUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiTypeUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {TimeUnit}
     * @memberof PivotKpiTypeUserLensDto
     */
    selectedTimeUnit: TimeUnit;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeUserLensDto
     */
    isManualYaxis: boolean;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiTypeUserLensDto
     */
    yaxisStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiTypeUserLensDto
     */
    yaxisEnd?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeUserLensDto
     */
    showsOutliers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeUserLensDto
     */
    showOperationCount: boolean;
    /**
     * 
     * @type {OutlierFlaggingType}
     * @memberof PivotKpiTypeUserLensDto
     */
    outlierFlaggingType: OutlierFlaggingType;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiTypeUserLensDto
     */
    outlierMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PivotKpiTypeUserLensDto
     */
    outlierMax?: number | null;
    /**
     * 
     * @type {Array<VisualAidType>}
     * @memberof PivotKpiTypeUserLensDto
     */
    selectedVisualAids: Array<VisualAidType>;
    /**
     * 
     * @type {StandKpiType}
     * @memberof PivotKpiTypeUserLensDto
     */
    kpiTypeId: StandKpiType;
    /**
     * 
     * @type {boolean}
     * @memberof PivotKpiTypeUserLensDto
     */
    showStandKpiComments: boolean;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotKpiTypeUserLensDto
     */
    defaultPivotLevel1: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotKpiTypeUserLensDto
     */
    defaultPivotLevel2: PivotType;
    /**
     * 
     * @type {PivotType}
     * @memberof PivotKpiTypeUserLensDto
     */
    defaultPivotLevel3: PivotType;
}



/**
 * Check if a given object implements the PivotKpiTypeUserLensDto interface.
 */
export function instanceOfPivotKpiTypeUserLensDto(value: object): value is PivotKpiTypeUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('squeezesDisplay' in value) || value['squeezesDisplay'] === undefined) return false;
    if (!('selectedTimeUnit' in value) || value['selectedTimeUnit'] === undefined) return false;
    if (!('isManualYaxis' in value) || value['isManualYaxis'] === undefined) return false;
    if (!('showsOutliers' in value) || value['showsOutliers'] === undefined) return false;
    if (!('showOperationCount' in value) || value['showOperationCount'] === undefined) return false;
    if (!('outlierFlaggingType' in value) || value['outlierFlaggingType'] === undefined) return false;
    if (!('selectedVisualAids' in value) || value['selectedVisualAids'] === undefined) return false;
    if (!('kpiTypeId' in value) || value['kpiTypeId'] === undefined) return false;
    if (!('showStandKpiComments' in value) || value['showStandKpiComments'] === undefined) return false;
    if (!('defaultPivotLevel1' in value) || value['defaultPivotLevel1'] === undefined) return false;
    if (!('defaultPivotLevel2' in value) || value['defaultPivotLevel2'] === undefined) return false;
    if (!('defaultPivotLevel3' in value) || value['defaultPivotLevel3'] === undefined) return false;
    return true;
}

export function PivotKpiTypeUserLensDtoFromJSON(json: any): PivotKpiTypeUserLensDto {
    return PivotKpiTypeUserLensDtoFromJSONTyped(json, false);
}

export function PivotKpiTypeUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotKpiTypeUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedTimeUnit': TimeUnitFromJSON(json['SelectedTimeUnit']),
        'isManualYaxis': json['IsManualYaxis'],
        'yaxisStart': json['YaxisStart'] == null ? undefined : json['YaxisStart'],
        'yaxisEnd': json['YaxisEnd'] == null ? undefined : json['YaxisEnd'],
        'showsOutliers': json['ShowsOutliers'],
        'showOperationCount': json['ShowOperationCount'],
        'outlierFlaggingType': OutlierFlaggingTypeFromJSON(json['OutlierFlaggingType']),
        'outlierMin': json['OutlierMin'] == null ? undefined : json['OutlierMin'],
        'outlierMax': json['OutlierMax'] == null ? undefined : json['OutlierMax'],
        'selectedVisualAids': ((json['SelectedVisualAids'] as Array<any>).map(VisualAidTypeFromJSON)),
        'kpiTypeId': StandKpiTypeFromJSON(json['KpiTypeId']),
        'showStandKpiComments': json['ShowStandKpiComments'],
        'defaultPivotLevel1': PivotTypeFromJSON(json['DefaultPivotLevel1']),
        'defaultPivotLevel2': PivotTypeFromJSON(json['DefaultPivotLevel2']),
        'defaultPivotLevel3': PivotTypeFromJSON(json['DefaultPivotLevel3']),
    };
}

export function PivotKpiTypeUserLensDtoToJSON(value?: PivotKpiTypeUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'SqueezesDisplay': value['squeezesDisplay'],
        'SelectedTimeUnit': TimeUnitToJSON(value['selectedTimeUnit']),
        'IsManualYaxis': value['isManualYaxis'],
        'YaxisStart': value['yaxisStart'],
        'YaxisEnd': value['yaxisEnd'],
        'ShowsOutliers': value['showsOutliers'],
        'ShowOperationCount': value['showOperationCount'],
        'OutlierFlaggingType': OutlierFlaggingTypeToJSON(value['outlierFlaggingType']),
        'OutlierMin': value['outlierMin'],
        'OutlierMax': value['outlierMax'],
        'SelectedVisualAids': ((value['selectedVisualAids'] as Array<any>).map(VisualAidTypeToJSON)),
        'KpiTypeId': StandKpiTypeToJSON(value['kpiTypeId']),
        'ShowStandKpiComments': value['showStandKpiComments'],
        'DefaultPivotLevel1': PivotTypeToJSON(value['defaultPivotLevel1']),
        'DefaultPivotLevel2': PivotTypeToJSON(value['defaultPivotLevel2']),
        'DefaultPivotLevel3': PivotTypeToJSON(value['defaultPivotLevel3']),
    };
}

