import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ApiParameterHeatmapUserLensesIdFactsPutRequest, BatteryStateResultDto } from "apis/oag";
import { BatteryStateUserLensesApi } from "apis/oag";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new BatteryStateUserLensesApi(apiConfig);

export const useBatteryStateFacts = (
  lensId: number,
  requestParameters: ApiParameterHeatmapUserLensesIdFactsPutRequest,
  options?: Omit<UseQueryOptions<BatteryStateResultDto>, "queryKey" | "queryFn">,
) => {
  const crtWellId = useSelectedWell();
  const dispatch = useAppDispatch();

  const queryKey: PDLensFactsQueryKey<ApiParameterHeatmapUserLensesIdFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.batteryStateFacts,
    params: requestParameters,
    lensId,
  };

  return useSuspenseQuery<BatteryStateResultDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api.apiBatteryStateUserLensesIdFactsPut(requestParameters, { signal }).then((data) => {
        if (data?.lastUpdatedAt) {
          dispatch({
            type: "SET_BATTERY_STATE_REFRESH_DATE",
            payload: {
              [crtWellId]: data?.lastUpdatedAt,
            },
          });
        }
        return data;
      }),
    ...options,
  });
};
