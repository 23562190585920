import { Loader } from "components/Loader";
import { uniqBy } from "lodash";
import qs from "qs";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import type { IRigsDashboardState } from "reducers/rigDashboardReducer";
import { useAppDispatch } from "reducers/store";
import type { IWidgetState } from "reducers/widgetReducer";
import superjson from "superjson";

const LensPrepare = () => {
  const params = useParams<{ dashboardType: string; dashboardId: string; lensId: string; data: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const parsedData = params.data ? superjson.parse<[IWidgetState, unknown]>(window.atob(params.data)) : null;
    if (parsedData) {
      dispatch({
        type: "RAW_WIDGET_STATE_SET",
        payload: parsedData[0],
      });

      // Todo: the following 3 dispatches don't make sense. `parsedData[1]` cannot be both `IRigsDashboardState` and `number[]`
      dispatch({
        type: "RAW_SET_RIG_DASHBOARD",
        payload: parsedData[1] as IRigsDashboardState,
      });

      dispatch({
        type: "SET_OFFSET_WELLS_WIDGET",
        payload: {
          wells: parsedData[1] as number[],
        },
      });

      dispatch({
        type: "SET_REPORT_OFFSET_WELLS",
        payload: {
          wells: parsedData[1] as number[],
        },
      });
    }
    const selectedTabQuery = parseInt(qs.parse(location.search, { ignoreQueryPrefix: true }).selectedTab as string, 10);
    const query = uniqBy((location?.search ?? "").replace("?", "").split("&"), (e) => e.split("=")?.[0])
      .map((e) => {
        if (e.startsWith("selectedTab=")) {
          return `selectedTab=${selectedTabQuery}`;
        }
        return e;
      })
      .filter((e) => e)
      .join("&");

    navigate(`/${params.dashboardType}/${params.dashboardId}/${params.lensId}/${query.length > 0 ? `?${query}` : ""}`, {
      replace: true,
    });
  }, [
    dispatch,
    location.search,
    navigate,
    params,
    params.dashboardId,
    params.dashboardType,
    params.data,
    params.lensId,
  ]);

  return <Loader centered />;
};

export default LensPrepare;
