import type { UserDto } from "apis/oag";
import { SystemOfMeasurementType } from "apis/oag";

import { RealTimeDataEnum } from "./../components/RealTimeIndicator/index";
import type { IAction } from "./types";
import { IUnitSystem } from "./types";

interface IGlobalState {
  realTimeData: RealTimeDataEnum;
  reportIncludeLegend: boolean;
  unit: IUnitSystem;
  userInfo: UserDto | null;
}

const initialState: IGlobalState = {
  realTimeData: RealTimeDataEnum.UNAVAILABLE,
  reportIncludeLegend: true,
  unit: IUnitSystem.METRIC,
  userInfo: null,
};

// TODO: remove real time when defaulting to realtime in the URL
interface ISetRealTimeData extends IAction {
  type: "SET_REAL_TIME_DATA";
  payload: IGlobalState["realTimeData"];
}

interface ISeUserInfo extends IAction {
  type: "SET_USER_INFO";
  payload: IGlobalState["userInfo"];
}
interface ISetUOMAction extends IAction {
  type: "SET_UNIT_OF_MEASURE";
  payload: IGlobalState["unit"];
}

interface IReportIncludeLegend extends IAction {
  type: "REPORT_INCLUDE_LEGEND";
  payload: IGlobalState["reportIncludeLegend"];
}

type AvailableActions = IReportIncludeLegend | ISeUserInfo | ISetRealTimeData | ISetUOMAction;

export const transformGlobalUOM = (unit: IUnitSystem) =>
  unit === IUnitSystem.IMPERIAL ? SystemOfMeasurementType.Imperial : SystemOfMeasurementType.Metric;

function globalReducer(state: IGlobalState = initialState, action: AvailableActions): IGlobalState {
  switch (action.type) {
    case "SET_REAL_TIME_DATA":
      return {
        ...state,
        realTimeData: action.payload,
      };
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "SET_UNIT_OF_MEASURE":
      return {
        ...state,
        userInfo: state.userInfo
          ? {
              ...state.userInfo,
              systemOfMeasurement: transformGlobalUOM(action.payload),
            }
          : null,
        unit: action.payload,
      };
    case "REPORT_INCLUDE_LEGEND":
      return {
        ...state,
        reportIncludeLegend: action.payload,
      };
    default:
      return state;
  }
}

export default globalReducer;
