import { useMutation } from "@tanstack/react-query";
import { AxisBottom, AxisRight } from "@visx/axis";
import { RectClipPath } from "@visx/clip-path";
import { LinearGradient } from "@visx/gradient";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { AreaClosed, Bar, Line } from "@visx/shape";
import type { ApiGeneratorLoadProfileUserLensesIdPutRequest, GeneratorSlotType } from "apis/oag";
import { GeneratorLoadProfileUserLensesApi } from "apis/oag";
import { DimensionType } from "apis/oag/models/DimensionType";
import type { GeneratorLoadProfileFactDto } from "apis/oag/models/GeneratorLoadProfileFactDto";
import { Title } from "atoms/Typography";
import { StyledCard, StyledDropDown, StyledOptionRow } from "components/Header/RightContent/style";
import { AxisLocation, StandardTickComponent } from "components/Lenses/common/ChartElements";
import ChartLegend from "components/Lenses/common/ChartLegend";
import type { LegendItem } from "components/Lenses/common/ChartLegend/interfaces";
import { LegendPreviewerType } from "components/Lenses/common/ChartLegend/interfaces";
import { useChartDateTimeRange } from "components/Lenses/common/useChartDateTimeRange";
import { LensLoadingContainer } from "components/Lenses/ContainerLens/common/LensLoadingContainer";
import { RotatedRightAxisLabel } from "components/Lenses/ContainerLens/common/RotatedRightAxisLabel";
import {
  StyledChartContainerFlexDiv,
  StyledChevronDownIcon,
  StyledLensContainerFlex,
  StyledMenuItem,
  StyledOption,
} from "components/Lenses/ContainerLens/common/StyledComponents";
import {
  addIndexToSeries,
  CLIP_SERIES_ID as CLIP_ID,
  generatorLabelsBySlotType,
  getGeneratorColorsBySlotType,
  getGeneratorLabelsBySlotType,
  getInternalAxisMargin,
  LATERAL_AXIS_WIDTH,
  X_AXIS_HEIGHT,
} from "components/Lenses/ContainerLens/common/utils/utils";
import type { GeneratorLoadProfileProps } from "components/Lenses/interfaces";
import { allGenerators, getSVGNormalizedValue } from "components/Lenses/utils";
import { curveMonotoneX } from "d3";
import { extent, max } from "d3-array";
import { useYAxisDisplayScale } from "hooks/charting/useYAxisDisplayScale";
import { useActiveGenerators } from "hooks/useActiveGenerators";
import useDiscontinuousTimeAxis from "hooks/useDiscontinuousTimeAxis";
import { useLensNameByTemplateId } from "hooks/useLensNameByTemplateId";
import { useLensSize } from "hooks/useLensSize";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import { apiConfig } from "utils/apiConfig";
import colors from "utils/colors";
import { defaultDateDto } from "utils/common";
import { Col, Row } from "utils/componentLibrary";
import { ALTERNATIVE_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { useGeneratorLoadProfileFetcher } from "./fetcher";
import { useGeneratorLoadProfileTooltip } from "./useGeneratorLoadProfileTooltip";
import { CAPACITY_ID, GEN_POWER_ID, getInvartiantBusinessRuleForAxisValue } from "./utils";

const generatorLoadProfileApi = new GeneratorLoadProfileUserLensesApi(apiConfig);

const LENS_TITLE = "Generator Load Profile";

const StyledCol = styled(Col)`
  padding: 24px 10px 24px 24px;
  margin-right: 24px;
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const GeneratorLoadProfile: React.FC<GeneratorLoadProfileProps> = ({
  lens,
  setLensDate,
  onLensUpdated,
  isLocked,
}) => {
  const {
    request: { data },
  } = useGeneratorLoadProfileFetcher({ lens });

  const series = useMemo(() => data?.facts || [], [data?.facts]);

  const extentFromData = useMemo(() => {
    const [min = 0, max = 0] = extent([0, ...series.map((series) => series?.availablePower)]);
    return [min, max];
  }, [series]);

  const lensName = useLensNameByTemplateId(lens?.lensTemplateId);

  const currentGeneratorColor = useMemo(() => {
    if (!lens.selectedGenerators) {
      return colors.marble_green_grey;
    } else {
      return getGeneratorColorsBySlotType(lens.selectedGenerators);
    }
  }, [lens.selectedGenerators]);

  const yScalePrimaryExtent = useMemo(() => {
    const normalizedUpperBound = getInvartiantBusinessRuleForAxisValue(extentFromData[1]);
    return [0, normalizedUpperBound];
  }, [extentFromData]);

  const wellId = useSelectedWell();
  const { data: activeGenerators } = useActiveGenerators(wellId);
  const yScalePrimaryUOM = useUOM(DimensionType.Watts);
  const [isMenuChevronVisible, setIsMenuChevronVisible] = useState(false);

  const [isPointerInsideChart, setPointerInsideChart] = useState(false);

  useEffect(() => {
    if (data?.lastUpdatedAt && setLensDate) setLensDate(data.lastUpdatedAt);
  }, [data.lastUpdatedAt, setLensDate]);

  const { width: chartWidthHook, height: chartHeightHook, ref: containerRef } = useResizeDetector();
  const { chartWidth, chartHeight } = {
    chartHeight: getSVGNormalizedValue(chartHeightHook),
    chartWidth: getSVGNormalizedValue(chartWidthHook),
  };

  const plotWidth = getSVGNormalizedValue(chartWidth - 2 * LATERAL_AXIS_WIDTH);
  const plotHeight = getSVGNormalizedValue(chartHeight - X_AXIS_HEIGHT);
  const dataState = useMemo(() => data?.dataState, [data?.dataState]);
  const lensGridSize = useLensSize(lens?.id);
  const [menuVisible, setMenuVisible] = useState(false);
  const handleLensUpdate = useMutation({
    mutationFn: (request: ApiGeneratorLoadProfileUserLensesIdPutRequest) => {
      return generatorLoadProfileApi.apiGeneratorLoadProfileUserLensesIdPut(request);
    },
    onSettled: (lens) => {
      if (lens && onLensUpdated) {
        onLensUpdated(lens);
      }
    },
  });
  const handleGeneratorUpdate = useCallback(
    async (generators: GeneratorSlotType[]) => {
      await handleLensUpdate.mutateAsync({
        id: lens.id,
        generatorLoadProfileUserLensDto: { ...lens, selectedGenerators: generators },
      });
      setMenuVisible(false);
    },
    [handleLensUpdate, lens],
  );

  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  useEffect(() => {
    setCosmeticLegendItems((prevCosmeticItems) =>
      prevCosmeticItems.map((item) => (item.id === GEN_POWER_ID ? { ...item, color: currentGeneratorColor } : item)),
    );
  }, [currentGeneratorColor]);

  const [cosmeticLegendItems, setCosmeticLegendItems] = useState<LegendItem[]>([
    {
      id: GEN_POWER_ID,
      name: "Gen Power",
      color: currentGeneratorColor,
      isEnabled: true,
      previewerType: LegendPreviewerType.BOX,
      onClick: (item, items) => {
        if ((items || []).every((other) => !other.isEnabled || other.id === item.id)) {
          return false;
        }
        Track.interact("Evergreen Dashboard - Update legend", {
          lensId: lens.id,
          lens: lensName,
          selectedItem: item.name,
          isEnabled: !item.isEnabled,
        });
        setCosmeticLegendItems((items) =>
          items.map((item) =>
            item.id === GEN_POWER_ID
              ? {
                  ...item,
                  isEnabled: !item.isEnabled,
                }
              : item,
          ),
        );
      },
    },
    {
      id: CAPACITY_ID,
      name: "60%-80% Capacity",
      color: `${colors.grey_heather}03`,
      isEnabled: true,
      previewerType: LegendPreviewerType.BOX_DASHED,
      onClick: (item, items) => {
        if ((items || []).every((other) => !other.isEnabled || other.id === item.id)) {
          return false;
        }
        Track.interact("Evergreen Dashboard - Update legend", {
          lensId: lens.id,
          lens: lensName,
          selectedItem: item.name,
          isEnabled: !item.isEnabled,
        });
        setCosmeticLegendItems((items) =>
          items.map((item) =>
            item.id === CAPACITY_ID
              ? {
                  ...item,
                  isEnabled: !item.isEnabled,
                }
              : item,
          ),
        );
      },
    },
  ]);

  const xScale = useMemo(() => {
    const chartSize = plotWidth;
    const startX = LATERAL_AXIS_WIDTH;

    const timeSeries = series.map((fact, i) => i);
    const [_min = 0, _max = 0] = [0, max(timeSeries)];

    return scaleLinear<number>({
      domain: [_min, _max],
      range: [startX, chartSize + startX],
    });
  }, [plotWidth, series]);

  const { xScaleDate, chunksCount, getAverageChunkDate } = useDiscontinuousTimeAxis({
    plotWidth,
    xScaleDomain: xScale.domain(),
    series: addIndexToSeries(series),
  });

  const internalAxisMargin = useMemo(() => getInternalAxisMargin(false), []);

  const yScalePrimary = useMemo(() => {
    return scaleLinear<number>({
      domain: yScalePrimaryExtent,
      range: [plotHeight, internalAxisMargin],
      nice: true,
    });
  }, [internalAxisMargin, plotHeight, yScalePrimaryExtent]);

  const CLIP_SERIES_ID = useMemo(() => `${CLIP_ID}-${lens.id}-2`, [lens.id]);

  const { selectionRectangleElement, isDragging } = useChartDateTimeRange<GeneratorLoadProfileFactDto>({
    xScale,
    yScale: yScalePrimary,
    plotWidth: plotWidth,
    plotWidthOffset: LATERAL_AXIS_WIDTH,
    plotHeight,
    series: addIndexToSeries(series),
  });

  const capacityBottomY = useMemo(() => extentFromData[1] * 0.6, [extentFromData]);
  const capacityTopY = useMemo(() => extentFromData[1] * 0.8, [extentFromData]);

  const { tooltipElement, handlePointerMove } = useGeneratorLoadProfileTooltip({
    legendItems: cosmeticLegendItems,
    containerRef,
    capacityBottomY,
    capacityTopY,
    selectedGenerator: lens.selectedGenerators,
    yScalePrimary,
    xScale,
    series,
    plotWidth: chartWidth,
    plotHeight,
    isPointerInsideChart,
  });

  const { yDisplayOnlyScale, verticalAxisTicksCount, formatTickDecimals } = useYAxisDisplayScale({
    tickContainerHeight: plotHeight,
    originalScale: yScalePrimary,
    uom: yScalePrimaryUOM,
  });

  const MenuItems = useMemo(
    () => (
      <>
        <StyledCard>
          <StyledOptionRow justify="space-between" align="middle">
            <StyledOption
              onClick={() => {
                Track.interact("Evergreen Dashboard - Update generator", {
                  lensId: lens.id,
                  lens: lensName,
                  selectedGenerators: getGeneratorLabelsBySlotType(allGenerators),
                });

                handleGeneratorUpdate(allGenerators);
              }}
            >
              All gens
            </StyledOption>
          </StyledOptionRow>
        </StyledCard>

        {activeGenerators.map((gen) => (
          <StyledCard key={gen}>
            <StyledOptionRow justify="space-between" align="middle">
              <StyledOption
                onClick={() => {
                  Track.interact("Evergreen Dashboard - Update generator", {
                    lensId: lens.id,
                    lens: lensName,
                    selectedGenerators: getGeneratorLabelsBySlotType([gen]),
                  });

                  handleGeneratorUpdate([gen]);
                }}
              >
                {generatorLabelsBySlotType[gen]}
              </StyledOption>
            </StyledOptionRow>
          </StyledCard>
        ))}
      </>
    ),
    [activeGenerators, handleGeneratorUpdate, lens.id, lensName],
  );

  return (
    <>
      <LensLoadingContainer
        key={lens.id}
        dataState={dataState}
        title={LENS_TITLE}
        isDetailed={false}
        LoadedComponent={
          <StyledLensContainerFlex
            onPointerEnter={() => (isLocked ? null : setIsMenuChevronVisible(true))}
            onPointerLeave={() => {
              if (!menuVisible) {
                setIsMenuChevronVisible(false);
              }
            }}
          >
            <Row justify="space-between">
              <Col style={{ padding: 24 }}>
                <Title variant="faded" level={3}>
                  {LENS_TITLE}
                </Title>
              </Col>
            </Row>

            <StyledChartContainerFlexDiv>
              <div style={{ height: "auto", flexBasis: "50%", minHeight: 150, flexGrow: 1 }} ref={containerRef}>
                <svg
                  onPointerMove={handlePointerMove}
                  onMouseLeave={() => setPointerInsideChart(false)}
                  onMouseEnter={() => setPointerInsideChart(true)}
                  onMouseMove={() => setPointerInsideChart(true)}
                  width={chartWidth}
                  height="100%"
                  style={{ overflow: "hidden", userSelect: "none" }}
                >
                  <GridRows
                    scale={yDisplayOnlyScale}
                    width={chartWidth}
                    height={chartHeight}
                    stroke={themeColors.primary_chart_accent}
                    strokeWidth={1}
                    strokeOpacity={1}
                    clipPath={`url(#${CLIP_SERIES_ID})`}
                  />
                  <RectClipPath
                    y={0}
                    id={CLIP_SERIES_ID}
                    height={plotHeight}
                    width={plotWidth}
                    x={LATERAL_AXIS_WIDTH}
                  />

                  <Group clipPath={`url(#${CLIP_SERIES_ID})`}>
                    <LinearGradient
                      from={currentGeneratorColor}
                      to={currentGeneratorColor}
                      toOpacity={0.55}
                      fromOpacity={1}
                      id={`linear_gradient_${currentGeneratorColor}_${lens.id}`}
                    />

                    {/* Primary Y KPI */}
                    {cosmeticLegendItems[0].isEnabled ? (
                      <AreaClosed
                        data={series}
                        x={(d, i) => xScale(i)}
                        y={(d) => yScalePrimary(d.value)}
                        yScale={yScalePrimary}
                        curve={curveMonotoneX}
                        stroke={`${colors.neutral}`}
                        strokeOpacity={0.1}
                        strokeWidth={1}
                        fill={`url(#linear_gradient_${currentGeneratorColor}_${lens.id})`}
                      />
                    ) : null}

                    {/* Secondary Y KPI */}
                    {cosmeticLegendItems[1].isEnabled ? (
                      <Group>
                        <Line
                          x1={0}
                          x2={chartWidth}
                          y1={yScalePrimary(capacityTopY)}
                          y2={yScalePrimary(capacityTopY)}
                          stroke={colors.necron_compound}
                          strokeWidth={1}
                          strokeDasharray="1 2"
                        />
                        <Bar
                          height={(plotHeight * 0.2 * extentFromData[1]) / yScalePrimary.domain()[1]}
                          y={yScalePrimary(capacityTopY)}
                          fill={colors.grey_heather}
                          opacity={0.06}
                          width={chartWidth}
                          stroke={cosmeticLegendItems[1].color}
                          strokeWidth={1}
                        />

                        <Line
                          x1={0}
                          x2={chartWidth}
                          y1={yScalePrimary(capacityBottomY)}
                          y2={yScalePrimary(capacityBottomY)}
                          stroke={colors.necron_compound}
                          strokeWidth={1}
                          strokeDasharray="1 2"
                        />
                      </Group>
                    ) : null}
                  </Group>

                  <AxisRight
                    hideZero
                    scale={yDisplayOnlyScale}
                    hideTicks
                    numTicks={verticalAxisTicksCount}
                    left={(chartWidth ?? 0) - LATERAL_AXIS_WIDTH}
                    tickComponent={(props) => {
                      if (props.y === 0) return null;
                      return (
                        <StandardTickComponent rendererProps={props}>
                          {(val) => formatTickDecimals(val, 0)}
                        </StandardTickComponent>
                      );
                    }}
                    hideAxisLine
                  />

                  {/* VISIBLE TIME AXIS */}
                  <AxisBottom
                    hideAxisLine
                    hideTicks
                    scale={xScaleDate}
                    top={plotHeight}
                    numTicks={chunksCount}
                    tickComponent={(props) => {
                      return (
                        <StandardTickComponent rendererProps={props} axisLocation={AxisLocation.BOTTOM}>
                          {(val) =>
                            formatTime(getAverageChunkDate(+val) || defaultDateDto.from, {
                              formatStr: ALTERNATIVE_DATE_FORMAT,
                            })
                          }
                        </StandardTickComponent>
                      );
                    }}
                  />
                  {!isDragging && tooltipElement}
                  {selectionRectangleElement}
                </svg>

                <RotatedRightAxisLabel
                  plotWidth={plotWidth}
                  chartHeight={chartHeight}
                  displayText={yScalePrimaryUOM.abbr}
                />
              </div>

              <ChartLegend legendItems={cosmeticLegendItems} lensGridSize={lensGridSize} />
            </StyledChartContainerFlexDiv>
          </StyledLensContainerFlex>
        }
      />
      <StyledCol>
        <StyledDropDown
          overlay={MenuItems}
          placement="bottomRight"
          trigger={["click"]}
          open={isLocked ? false : menuVisible}
          onOpenChange={() => {
            if (!isLocked) setMenuVisible(!menuVisible);
          }}
        >
          <StyledMenuItem variant={"faded"}>
            {getGeneratorLabelsBySlotType(lens?.selectedGenerators)}
            <StyledChevronDownIcon isVisible={isMenuChevronVisible} />
          </StyledMenuItem>
        </StyledDropDown>
      </StyledCol>
    </>
  );
};
