import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type { RigDto } from "apis/oag";
import { RigsApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const rigs = new RigsApi(apiConfig);

export type RigsResponse = {
  list: RigDto[];
  byId: Record<number, RigDto>;
};

export async function fetchRigs(): Promise<RigsResponse> {
  const data = await rigs.apiRigsGet();

  return {
    list: data,
    byId: data.reduce<Record<number, RigDto>>((acc, cur) => {
      acc[cur.id ?? 0] = cur;
      return acc;
    }, {}),
  };
}

const RigsQueryKey: PDUniqueQueryKey = { uid: RequestUID.rigsList };

export function useRigs(options?: Omit<UseQueryOptions<RigsResponse>, "queryKey" | "queryFn">) {
  return useSuspenseQuery<RigsResponse>({
    queryKey: [RigsQueryKey],
    queryFn: () => fetchRigs(),
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useRigsNotSuspended(options?: Omit<UseQueryOptions<RigsResponse>, "queryKey" | "queryFn">) {
  return useQuery<RigsResponse>({
    queryKey: [RigsQueryKey],
    queryFn: () => fetchRigs(),
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
