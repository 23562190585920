import type { StandKpiCommentInfoDto,StandKpiDetailsDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { Title } from "atoms/Typography";
import NoData from "components/Lenses/common/NoData";
import { PDComponent } from "components/PDComponents";
import dayjs from "dayjs";
import { useStandComments } from "hooks/comments/useStandComments";
import { useSelectedWell } from "hooks/useSelectedWell";
import type { StandData } from "hooks/useWellInspectionDetails";
import type { FC, PropsWithChildren } from "react";
import React, { useCallback } from "react";
import type { UOMHelper } from "utils/format";
import { FULL_YEAR_DATE_FORMAT, SHORTER_DATE_FORMAT, useUOM } from "utils/format";
import { formatTime, toLocalWellTime } from "utils/helper";

import * as Styled from "./style";

export const DrillerComments: FC<
  PropsWithChildren<{
    selectedStand: StandData;
    lensTitle: string;
    lensId: number;
    standList: StandKpiDetailsDto[];
    valueUOM: UOMHelper;
  }>
> = ({ selectedStand, lensTitle, lensId, standList, valueUOM }) => {
  const depthUOM = useUOM(DimensionType.Metres);

  const getMultiStandText = useCallback(
    (comment: StandKpiCommentInfoDto) => {
      const matchedStartStand = standList.find((s) => s.standId === comment.startRangeStandId);
      const matchedEndStand = standList.find((s) => s.standId === comment.endRangeStandId);
      if (!matchedStartStand || !matchedEndStand) {
        throw new Error("Stand not found in collection, make sure it exists in the list");
      }
      return `${depthUOM.display(matchedStartStand?.standNumber ?? 0, {
        fractionDigits: 0,
      })} to ${depthUOM.display(matchedEndStand?.standNumber ?? 0, {
        fractionDigits: 0,
      })} ${dayjs(toLocalWellTime(matchedStartStand?.startAt)).format(SHORTER_DATE_FORMAT).toString()} to ${dayjs(toLocalWellTime(matchedEndStand?.endAt)).format(SHORTER_DATE_FORMAT).toString()}`;
    },
    [depthUOM, standList],
  );

  const wellId = useSelectedWell();
  const { data: allStandsData } = useStandComments(lensId, [wellId]);
  const comments = allStandsData?.find((stand) => stand.standId === selectedStand.standId)?.comments;

  return (
    <Styled.CommentSection>
      <Styled.HorizontalDivider />
      <Title level={3}>
        <strong>Driller Comments ({comments?.length ?? 0})</strong>
      </Title>
      <Styled.CommentInfoHeader>
        <Styled.CommentInfoRow>
          <Styled.CommentInfoMetric>Stand: </Styled.CommentInfoMetric>
          <Styled.CommentInfoResult $isMain>
            {depthUOM.display(selectedStand.standNumber ?? 0, { fractionDigits: 0 })}
          </Styled.CommentInfoResult>
          <Styled.Divider />
          <Styled.CommentInfoMetric>{lensTitle}: </Styled.CommentInfoMetric>
          <Styled.CommentInfoResult>{valueUOM.display(selectedStand.value)}</Styled.CommentInfoResult>
          <Styled.Divider />
          <Styled.CommentInfoMetric>Target: </Styled.CommentInfoMetric>{" "}
          <Styled.CommentInfoResult>{valueUOM.display(selectedStand.targetValue)}</Styled.CommentInfoResult>
        </Styled.CommentInfoRow>

        <Styled.CommentInfoRow>
          <Styled.CommentInfoResult>
            {depthUOM.display(selectedStand.startDepth, { fractionDigits: 0 })} to{" "}
            {depthUOM.display(selectedStand.endDepth, { fractionDigits: 0 })}
          </Styled.CommentInfoResult>
          <Styled.Divider />
          <Styled.CommentInfoResult>
            {selectedStand.startAt ? formatTime(selectedStand.startAt, { formatStr: "MM/D HH:mm" }) : "Unknown date"} to{" "}
            {selectedStand.endAt ? formatTime(selectedStand.endAt, { formatStr: "MM/D HH:mm" }) : "Unknown date"}
          </Styled.CommentInfoResult>
        </Styled.CommentInfoRow>
      </Styled.CommentInfoHeader>

      {comments && comments.length > 0 ? (
        <Styled.CommentsContainer>
          {comments.map((comment) => (
            <Styled.Comment key={comment.id}>
              <Styled.CommentTitle>
                <Styled.Author>
                  <strong>{comment.authorDisplayName}</strong>
                </Styled.Author>
                <Title variant="faded" level={4}>
                  {dayjs(comment.updatedAt?.utc).format(FULL_YEAR_DATE_FORMAT)}
                </Title>
              </Styled.CommentTitle>
              <Styled.CommentBody>{comment.description}</Styled.CommentBody>

              {Number.isFinite(comment.startRangeStandId) ? (
                <Styled.MultiStandContainer>
                  <Styled.MultiStandInfo>
                    <PDComponent.SvgIcon name="multiStand" />
                    Multi stand
                  </Styled.MultiStandInfo>
                  <Styled.MultiStandDepthDate>{getMultiStandText(comment)}</Styled.MultiStandDepthDate>
                </Styled.MultiStandContainer>
              ) : null}
            </Styled.Comment>
          ))}
        </Styled.CommentsContainer>
      ) : (
        <Styled.NoCommentsContainer>
          <NoData isStatic message="No comments yet." icon={<PDComponent.SvgIcon name="chat" />} />
        </Styled.NoCommentsContainer>
      )}
    </Styled.CommentSection>
  );
};
