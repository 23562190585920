import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Row, Typography } from "utils/componentLibrary";

export interface RestoreDataModalProps {
  setVisible: (value: boolean) => void;
  visible: boolean;
  onRestore: () => Promise<void> | void;
  onCancel: () => Promise<void> | void;
}
const CustomModal = styled(PDComponent.Modal)`
  top: calc(50% - 180px);
`;
export function RestoreDataModal({ onRestore, onCancel, setVisible, visible }: RestoreDataModalProps) {
  const handleCancel = useCallback(() => {
    // TODO change restore modal for tracking
    onCancel();
    setVisible(false);
  }, [onCancel, setVisible]);

  return (
    <CustomModal
      onCancel={handleCancel}
      title="Restore data?"
      footer={
        <Row justify="space-between" align="middle">
          <Button trackingName="Cancel Restore Data" onClick={handleCancel}>
            Cancel
          </Button>
          <Button trackingName="Restore Data" type="primary" onClick={() => onRestore()}>
            Yes, restore data
          </Button>
        </Row>
      }
      open={visible}
    >
      <Typography>
        It seems like you have lost your previous draft. Would you like to restore the plan data from your previous
        session?
      </Typography>
    </CustomModal>
  );
}

export function useRestoreDataModal({
  onRestore,
  onCancel,
}: {
  onRestore: (data: any) => void;
  onCancel?: () => void;
}) {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any>(undefined);

  const open = useCallback((data: any) => {
    setData(data);
    setVisible(true);
  }, []);

  const close = useCallback(() => {
    setData(undefined);
    setVisible(false);
  }, []);

  const handleCancel = useCallback(() => {
    onCancel?.();
    setVisible(false);
  }, [onCancel]);

  const handleRestore = useCallback(() => {
    onRestore(data);
    close();
  }, [close, data, onRestore]);

  const node = (
    <RestoreDataModal setVisible={setVisible} visible={visible} onRestore={handleRestore} onCancel={handleCancel} />
  );

  return { visible, open, close, node };
}
