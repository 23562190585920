/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PromptType = {
    Unknown: 'Unknown',
    Favorite: 'Favorite',
    RigCommentsOverview: 'RigCommentsOverview',
    RigPerformanceMetrics: 'RigPerformanceMetrics'
} as const;
export type PromptType = typeof PromptType[keyof typeof PromptType];


export function instanceOfPromptType(value: any): boolean {
    for (const key in PromptType) {
        if (Object.prototype.hasOwnProperty.call(PromptType, key)) {
            if (PromptType[key as keyof typeof PromptType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PromptTypeFromJSON(json: any): PromptType {
    return PromptTypeFromJSONTyped(json, false);
}

export function PromptTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptType {
    return json as PromptType;
}

export function PromptTypeToJSON(value?: PromptType | null): any {
    return value as any;
}

