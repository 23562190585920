import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

export enum BenchmarkType {
  OperatorTarget = "Operator Target",
  PDBenchmark = "PD Benchmark",
}

interface ScoreBenchmarkValue {
  scoreBenchmark: BenchmarkType;
  setScoreBenchmark: Dispatch<SetStateAction<BenchmarkType>>;
}

export const ScoreBenchmarkContext = createContext<ScoreBenchmarkValue | undefined>(undefined);

export const useScoreBenchmarkContext = () => {
  const scoreValues = useContext(ScoreBenchmarkContext);
  if (scoreValues === undefined) {
    throw new Error("useScoreBenchmarkContext must be used within a ScoreBenchmarkProvider");
  }
  return scoreValues;
};

export const ScoreBenchmarkProvider = ({ children }: { children: React.ReactNode }) => {
  const [scoreBenchmark, setScoreBenchmark] = useState<BenchmarkType>(BenchmarkType.OperatorTarget);

  return (
    <ScoreBenchmarkContext.Provider value={{ scoreBenchmark, setScoreBenchmark }}>
      {children}
    </ScoreBenchmarkContext.Provider>
  );
};
