import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar, LinePath } from "@visx/shape";
import type { LensTemplateType } from "apis/oag";
import type { StatCardDataType } from "apis/oag/models/StatCardDataType";
import { PDComponent } from "components/PDComponents";
import styled from "styled-components";
import colors from "utils/colors";
import { useCustomTheme } from "utils/useTheme";

import { MockBatteryStateSvg } from "./mockBatteryStateSvg";
import { MockBoilerFuelFlowRateSvg } from "./mockBoilerFuelFlowRateSvg";
import { parameterByDepthMockDepthScale, parameterByDepthMockTrack, parameterByDepthMockValueScale } from "./mockData";
import { MockDieselNatGasRatio } from "./mockDieselNatGasRatio";
import { MockFuelConsumptionByGenSvg } from "./mockFuelConsumptionByGen";
import { MockFuelFlowRateSvg } from "./mockFuelFlowRateSvg";
import { MockFuelTankVolumeSvg } from "./mockFuelTankVolume";
import { MockGeneratorLoadProfileSvg } from "./mockGeneratorLoadProfileSvg";
import { MockGenStatusBarSvg } from "./mockGenStatusBarSvg";
import { MockGHGEmissionsSvg } from "./mockGHGEmissionsSvg";
import { MockHexbinSvg } from "./mockHexbinSvg";
import { MockNaturalGasStateSvg } from "./mockNaturalGasStateSvg";
import { MockPowerConsumptionSvg } from "./mockPowerConsumptionSvg";
import { MockPowerLoadDistributionSvg } from "./mockPowerLoadDistributionSvg";
import { MockPowerLoadEfficiencySvg } from "./mockPowerLoadEfficiencySvg";
import { MockRigPowerBESSSvg } from "./mockRigPowerBessSvg";
import { MockRigPowerSvg } from "./mockRigPowerSvg";
import { MockSingleKpiSvg } from "./mockSingleKpiSvg";
import { MockStackedKpiSvg } from "./mockStackedKpiSvg";
import {
  MockGhgEmissionsStatcardSvg,
  MockPowerAvailableStatCardSvg,
  MockStatCardFlowRateSvg,
  MockStatCardFuelConsumptionSvg,
  MockStatCardOperationDaysSvg,
  MockStatCardPowerConsumedPerDaySvg,
  MockStatCardPowerConsumedSvg,
} from "./mockStatCardSvg";
import { MockStickSlipByDepthSvg } from "./mockStickSlipByDepthSvg";
import { MockStickSlipByTimeSvg } from "./mockStickSlipByTimeSvg";
import { MockTagBottomFingerprintSvg } from "./mockTagBottomFingerprintSvg";
import { MockTorqueAndDrag } from "./mockTorqeAndDragSvg";
import { MockWedgeKpiSvg } from "./mockWedgeKpiSvg";

const WellDrilling = styled.div`
  position: relative;
  height: 126px;
  > * {
    position: absolute;
  }
  > span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 68px;
      height: 68px;
    }
  }
`;

// TODO replace chart background color with div with background
const parameterByDepth = (
  <svg width={326} height={126}>
    <Group top={10}>
      <LinePath
        data={parameterByDepthMockTrack.trackValues}
        x={(d) => parameterByDepthMockDepthScale(d.holeDepth) || 0}
        y={(d) => parameterByDepthMockValueScale(d.value) || 0}
        stroke={colors.well_color}
        strokeWidth={2}
      />
    </Group>
  </svg>
);

const parameterRoadmap = (
  <svg width={326} height={126}>
    <Group top={10}>
      <LinePath
        data={parameterByDepthMockTrack.trackValues}
        x={(d) => parameterByDepthMockDepthScale(d.holeDepth) || 0}
        y={(d) => parameterByDepthMockValueScale(d.value) || 0}
        stroke={colors.well_color}
        strokeWidth={2}
      />
      <Bar y={110} height={6} x={0} width={326} fill={colors.well_color} fillOpacity={0.4} />
    </Group>
  </svg>
);

const WellDrillingSummaryElement = () => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  return (
    <WellDrilling>
      <svg width={326} height={126}>
        <Grid
          stroke={themeColors.primary_chart_accent}
          xScale={scaleBand<string>({
            range: [0, 326],
            domain: [],
            padding: 0,
          })}
          yScale={scaleLinear<number>({
            range: [16, 126],
          })}
          width={326}
          height={126}
          numTicksRows={6}
        />
      </svg>
      <PDComponent.SvgIcon name="report" />
    </WellDrilling>
  );
};

const StandardSvgContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const StandardImgContainer = styled(StandardSvgContainer)`
  padding: 16px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const NotImplementedContainer = styled.div`
  color: ${colors.gray};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const powerLoadDistributionElement = (
  <StandardSvgContainer
    style={{
      alignItems: "flex-end",
    }}
  >
    <MockPowerLoadDistributionSvg />
  </StandardSvgContainer>
);
const powerLoadEfficiencyElement = (
  <StandardSvgContainer>
    <MockPowerLoadEfficiencySvg />
  </StandardSvgContainer>
);

const powerConsumptionElement = (
  <StandardSvgContainer>
    <MockPowerConsumptionSvg />
  </StandardSvgContainer>
);

const powerEnergyCapacity = (
  <StandardSvgContainer>
    <MockPowerAvailableStatCardSvg />
  </StandardSvgContainer>
);

const fuelConsumptionByGeneratorElement = (
  <StandardSvgContainer>
    <MockFuelConsumptionByGenSvg />
  </StandardSvgContainer>
);

const parameterHeatmapElement = (
  <StandardSvgContainer>
    <MockHexbinSvg />
  </StandardSvgContainer>
);

const statCardFlowRateElement = (
  <StandardSvgContainer>
    <MockStatCardFlowRateSvg />
  </StandardSvgContainer>
);
const statCardFuelConsumptionElement = (
  <StandardSvgContainer>
    <MockStatCardFuelConsumptionSvg />
  </StandardSvgContainer>
);
const statCardOperationDaysElement = (
  <StandardSvgContainer>
    <MockStatCardOperationDaysSvg />
  </StandardSvgContainer>
);
const statCardPowerConsumedElementPerDay = (
  <StandardSvgContainer>
    <MockStatCardPowerConsumedPerDaySvg />
  </StandardSvgContainer>
);

const statCardPowerConsumedElement = (
  <StandardSvgContainer>
    <MockStatCardPowerConsumedSvg />
  </StandardSvgContainer>
);

const statCardGhgEmissions = (
  <StandardSvgContainer>
    <MockGhgEmissionsStatcardSvg />
  </StandardSvgContainer>
);

const dieselNatGasRatio = (
  <StandardSvgContainer>
    <MockDieselNatGasRatio />
  </StandardSvgContainer>
);

const generatorStats = (
  <StandardImgContainer>
    <img src="/assets/images/generator-illustration.png" alt="Generator Model Illustration" />
  </StandardImgContainer>
);

const genStatusBarElement = (
  <StandardSvgContainer
    style={{
      alignItems: "flex-end",
    }}
  >
    <MockGenStatusBarSvg />
  </StandardSvgContainer>
);
const batteryState = (
  <StandardSvgContainer>
    <MockBatteryStateSvg />
  </StandardSvgContainer>
);

const rigPower = (
  <StandardSvgContainer>
    <MockRigPowerSvg />
  </StandardSvgContainer>
);
const rigPowerBESS = (
  <StandardSvgContainer>
    <MockRigPowerBESSSvg />
  </StandardSvgContainer>
);

const fuelRate = (
  <StandardSvgContainer>
    <MockFuelFlowRateSvg />
  </StandardSvgContainer>
);
const boilerFuelFlowRate = (
  <StandardSvgContainer>
    <MockBoilerFuelFlowRateSvg />
  </StandardSvgContainer>
);

const naturalGasState = (
  <StandardSvgContainer>
    <MockNaturalGasStateSvg />
  </StandardSvgContainer>
);

const generatorLoadProfile = (
  <StandardSvgContainer>
    <MockGeneratorLoadProfileSvg />
  </StandardSvgContainer>
);

const GHGEmissions = (
  <StandardSvgContainer>
    <MockGHGEmissionsSvg />
  </StandardSvgContainer>
);

const fuelTankVolume = (
  <StandardSvgContainer>
    <MockFuelTankVolumeSvg />
  </StandardSvgContainer>
);

const wedgeKpiElement = (
  <StandardSvgContainer>
    <MockWedgeKpiSvg />
  </StandardSvgContainer>
);

const singleKpiElement = (
  <StandardSvgContainer>
    <MockSingleKpiSvg />
  </StandardSvgContainer>
);

const stackedKpiElement = (
  <StandardSvgContainer>
    <MockStackedKpiSvg />
  </StandardSvgContainer>
);

const tagBottomFingerprintElement = (
  <StandardSvgContainer>
    <MockTagBottomFingerprintSvg />
  </StandardSvgContainer>
);

const stickSlipByTimeElement = (
  <StandardSvgContainer>
    <MockStickSlipByTimeSvg />
  </StandardSvgContainer>
);

const stickSlipByDepthElement = (
  <StandardSvgContainer>
    <MockStickSlipByDepthSvg />
  </StandardSvgContainer>
);
export const mockElements: Record<
  LensTemplateType | StatCardDataType | "RigPowerBESS" | "GhgEmissionsStatCard" | "BoilerFuelFlowRate",
  JSX.Element
> = {
  AveragePowerConsumptionPerSecond: statCardPowerConsumedElementPerDay,
  BatteryState: batteryState,
  BoilerFuelFlowRate: boilerFuelFlowRate,
  DieselNatGasRatioStats: dieselNatGasRatio,
  DrillingProductivity: singleKpiElement,
  FuelConsumption: statCardFuelConsumptionElement,
  FuelConsumptionByGenerator: fuelConsumptionByGeneratorElement,
  FuelFlow: statCardFlowRateElement,
  FuelFlowRate: fuelRate,
  FuelTankVolume: fuelTankVolume,
  GeneratorLoadProfile: generatorLoadProfile,
  GeneratorStats: generatorStats,
  GeneratorStatusBar: genStatusBarElement,
  GhgEmissions: GHGEmissions,
  GhgEmissionsStatCard: statCardGhgEmissions,
  NaturalGasState: naturalGasState,
  ParameterByDepth: parameterByDepth,
  ParameterByDepthRoadmap: parameterRoadmap,
  ParameterByDepthUnified: parameterByDepth,
  ParameterHeatmap: parameterHeatmapElement,
  PivotKpiGroup: stackedKpiElement,
  PivotKpiType: singleKpiElement,
  PowerConsumptionByOperation: powerConsumptionElement,
  PowerLoadDistribution: powerLoadDistributionElement,
  PowerLoadEfficiency: powerLoadEfficiencyElement,
  RigFleetPerformanceCard: <NotImplementedContainer>To Do</NotImplementedContainer>,
  RigPower: rigPower,
  RigPowerBESS: rigPowerBESS,
  RigScorecard: <NotImplementedContainer>To Do</NotImplementedContainer>,
  SingleKpi: singleKpiElement,
  StackedKpis: stackedKpiElement,
  StatCard: <NotImplementedContainer>To Do</NotImplementedContainer>,
  TorqueAndDrag: <MockTorqueAndDrag />,
  TotalActiveDuration: statCardOperationDaysElement,
  TotalEnergyCapacity: powerEnergyCapacity,
  TotalEnergyConsumed: statCardPowerConsumedElement,
  Undefined: <NotImplementedContainer>Undefined Template Type</NotImplementedContainer>,
  Unknown: <NotImplementedContainer>Unknown Template Type</NotImplementedContainer>,
  Wedge: wedgeKpiElement,
  WellDrillingSummary: <WellDrillingSummaryElement />,
  TagBottomFingerprint: tagBottomFingerprintElement,
  StickSlipByTime: stickSlipByTimeElement,
  StickSlipByDepth: stickSlipByDepthElement,
};
