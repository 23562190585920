/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PlanTimelineEventDto,
} from '../models/index';
import {
    PlanTimelineEventDtoFromJSON,
    PlanTimelineEventDtoToJSON,
} from '../models/index';

export interface ApiPlanTimelineEventsIdDeleteRequest {
    id: number;
}

export interface ApiPlanTimelineEventsIdPutRequest {
    id: number;
    planTimelineEventDto?: PlanTimelineEventDto;
}

export interface ApiPlanTimelineEventsIdRestorePutRequest {
    id: number;
}

export interface ApiPlanTimelineEventsPostRequest {
    planTimelineEventDto?: PlanTimelineEventDto;
}

/**
 * 
 */
export class PlanTimelineEventsApi extends runtime.BaseAPI {

    /**
     */
    async apiPlanTimelineEventsIdDeleteRaw(requestParameters: ApiPlanTimelineEventsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiPlanTimelineEventsIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PlanTimelineEvents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiPlanTimelineEventsIdDelete(requestParameters: ApiPlanTimelineEventsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiPlanTimelineEventsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPlanTimelineEventsIdPutRaw(requestParameters: ApiPlanTimelineEventsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlanTimelineEventDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiPlanTimelineEventsIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PlanTimelineEvents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlanTimelineEventDtoToJSON(requestParameters['planTimelineEventDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanTimelineEventDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPlanTimelineEventsIdPut(requestParameters: ApiPlanTimelineEventsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlanTimelineEventDto> {
        const response = await this.apiPlanTimelineEventsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPlanTimelineEventsIdRestorePutRaw(requestParameters: ApiPlanTimelineEventsIdRestorePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiPlanTimelineEventsIdRestorePut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PlanTimelineEvents/{id}/Restore`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apiPlanTimelineEventsIdRestorePut(requestParameters: ApiPlanTimelineEventsIdRestorePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiPlanTimelineEventsIdRestorePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPlanTimelineEventsPostRaw(requestParameters: ApiPlanTimelineEventsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlanTimelineEventDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/PlanTimelineEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlanTimelineEventDtoToJSON(requestParameters['planTimelineEventDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanTimelineEventDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiPlanTimelineEventsPost(requestParameters: ApiPlanTimelineEventsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlanTimelineEventDto> {
        const response = await this.apiPlanTimelineEventsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
