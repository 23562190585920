import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Checkbox, Row, Typography } from "utils/componentLibrary";

const CustomModal = styled(PDComponent.Modal)`
  top: calc(50% - 200px);
`;

export interface DeleteModalProps {
  wellName: string;
  setVisible: (value: boolean) => void;
  visible: boolean;
  onDelete: (remember?: boolean) => Promise<void> | void;
}

export function DeleteModal({ wellName, onDelete, setVisible, visible }: DeleteModalProps) {
  const [checked, setChecked] = useState(false);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <CustomModal
      centered
      onCancel={handleCancel}
      title="Delete this phase record?"
      footer={
        <Row justify="space-between" align="middle">
          <Checkbox
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
          >
            Don’t show this again
          </Checkbox>
          <div>
            <Button trackingName="Cancel Delete Well Plan" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              trackingName="Delete Well Plan Point"
              trackingProps={{
                "Well Name": wellName,
              }}
              type="primary"
              onClick={() => onDelete(checked)}
            >
              Delete
            </Button>
          </div>
        </Row>
      }
      open={visible}
    >
      <Typography>
        {`By deleting this phase record, you’re removing a data point from ${wellName}’s plan curve on the Time vs Depth graph.`}
      </Typography>
    </CustomModal>
  );
}

export function useDeleteModal({ wellName, onDelete }: { wellName: string; onDelete: (activityId: number) => void }) {
  const [visible, setVisible] = useState(false);
  const [remember, setRemember] = useState(false);
  const [activityId, setActivityId] = useState<number | undefined>(undefined);

  const close = useCallback(() => {
    setVisible(false);
    setActivityId(undefined);
  }, []);

  const handleModalDelete = useCallback(
    (remember?: boolean) => {
      setRemember(!!remember);
      if (activityId) {
        onDelete(activityId);
      }
      close();
    },
    [activityId, close, onDelete],
  );

  const deleteActivity = useCallback(
    ({ activityId }: { activityId: number }) => {
      setActivityId(activityId);

      if (remember) {
        onDelete(activityId);
        close();
      } else {
        setVisible(true);
      }
    },
    [close, onDelete, remember],
  );

  const node = (
    <DeleteModal setVisible={setVisible} visible={visible} onDelete={handleModalDelete} wellName={wellName} />
  );

  return { visible, deleteActivity, close, node };
}
