/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StackedStandKpiComparisonWellDto } from './StackedStandKpiComparisonWellDto';
import {
    StackedStandKpiComparisonWellDtoFromJSON,
    StackedStandKpiComparisonWellDtoFromJSONTyped,
    StackedStandKpiComparisonWellDtoToJSON,
} from './StackedStandKpiComparisonWellDto';
import type { StandGroupSummaryDto } from './StandGroupSummaryDto';
import {
    StandGroupSummaryDtoFromJSON,
    StandGroupSummaryDtoFromJSONTyped,
    StandGroupSummaryDtoToJSON,
} from './StandGroupSummaryDto';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface StackedStandKpiComparisonDto
 */
export interface StackedStandKpiComparisonDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof StackedStandKpiComparisonDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof StackedStandKpiComparisonDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof StackedStandKpiComparisonDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof StackedStandKpiComparisonDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {FiltersDto}
     * @memberof StackedStandKpiComparisonDto
     */
    availableFilters: FiltersDto;
    /**
     * 
     * @type {Array<StackedStandKpiComparisonWellDto>}
     * @memberof StackedStandKpiComparisonDto
     */
    comparisons: Array<StackedStandKpiComparisonWellDto>;
    /**
     * 
     * @type {Array<StandGroupSummaryDto>}
     * @memberof StackedStandKpiComparisonDto
     */
    summaryByDisplayOption?: Array<StandGroupSummaryDto> | null;
    /**
     * 
     * @type {Array<StandGroupSummaryDto>}
     * @memberof StackedStandKpiComparisonDto
     */
    summaryByKpi?: Array<StandGroupSummaryDto> | null;
    /**
     * 
     * @type {ResultDataState}
     * @memberof StackedStandKpiComparisonDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the StackedStandKpiComparisonDto interface.
 */
export function instanceOfStackedStandKpiComparisonDto(value: object): value is StackedStandKpiComparisonDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('availableFilters' in value) || value['availableFilters'] === undefined) return false;
    if (!('comparisons' in value) || value['comparisons'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function StackedStandKpiComparisonDtoFromJSON(json: any): StackedStandKpiComparisonDto {
    return StackedStandKpiComparisonDtoFromJSONTyped(json, false);
}

export function StackedStandKpiComparisonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StackedStandKpiComparisonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'availableFilters': FiltersDtoFromJSON(json['AvailableFilters']),
        'comparisons': ((json['Comparisons'] as Array<any>).map(StackedStandKpiComparisonWellDtoFromJSON)),
        'summaryByDisplayOption': json['SummaryByDisplayOption'] == null ? undefined : ((json['SummaryByDisplayOption'] as Array<any>).map(StandGroupSummaryDtoFromJSON)),
        'summaryByKpi': json['SummaryByKpi'] == null ? undefined : ((json['SummaryByKpi'] as Array<any>).map(StandGroupSummaryDtoFromJSON)),
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function StackedStandKpiComparisonDtoToJSON(value?: StackedStandKpiComparisonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'AvailableFilters': FiltersDtoToJSON(value['availableFilters']),
        'Comparisons': ((value['comparisons'] as Array<any>).map(StackedStandKpiComparisonWellDtoToJSON)),
        'SummaryByDisplayOption': value['summaryByDisplayOption'] == null ? undefined : ((value['summaryByDisplayOption'] as Array<any>).map(StandGroupSummaryDtoToJSON)),
        'SummaryByKpi': value['summaryByKpi'] == null ? undefined : ((value['summaryByKpi'] as Array<any>).map(StandGroupSummaryDtoToJSON)),
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

