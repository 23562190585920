import { Button } from "atoms/Form";
import { VariableSizeList as List } from "react-window";
import styled from "styled-components";
import colors from "utils/colors";
import { Col } from "utils/componentLibrary";

export const StyledList = styled(List)`
  outline: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  h3 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  }
  .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  }
`;

export const HoverCol = styled.div`
display: flex;
  &:hover h3 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  }
  .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.faint_typography} !important;
  }
  &:hover .pd-icon {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography} !important;
  }
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  width: 464px;
  justify-content: flex-start;
  display: flex;
  padding: 0;
  padding-left: 15px;
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg} !important;
  border-color: ${({ theme }) => theme.themeStyle.colors.primary_accent} !important;

  &:active {
    background: ${({ theme }) => theme.themeStyle.colors.secondary_bg} !important;
    color: ${colors.gray} !important;
  }

  &:hover {
    filter: brightness(100%);
    &:not([disabled]) {
      filter: brightness(100%);
    }
  }
`;
