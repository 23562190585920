import { Title } from "atoms/Typography";
import type { FC } from "react";
import React from "react";
import styled from "styled-components";
import { Card } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

const StyledCard = styled(Card)`
  height: 154px;
  display: grid;
  align-items: center;
`;

const SingleKPI: FC<{ title: string; value: string }> = ({ title, value }) => {
  const { atomThemeVariant } = useCustomTheme();
  return (
    <StyledCard>
      <Title level={4} variant="faded" style={{ textAlign: "center" }}>
        {title}
      </Title>
      <Title level={2} variant={atomThemeVariant} weight={500} style={{ textAlign: "center" }}>
        {value}
      </Title>
    </StyledCard>
  );
};

export default SingleKPI;
