import { InnerIcon } from "components/PDComponents/Checkbox/InnerIcon";
import { StyledCheckbox } from "components/PDComponents/Checkbox/style";
import React, { useMemo } from "react";
import colors from "utils/colors";

export enum CheckboxCheckedState {
  Unchecked,
  Checked,
  Indeterminate,
}

interface PDCheckboxProps
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "onChange" | "checked"
  > {
  checkedState: CheckboxCheckedState;
  onChange: (checked: boolean, ev: React.ChangeEvent<HTMLInputElement>) => void;
  size?: number;
  labelId?: string;
}

export const _PDCheckbox = React.forwardRef((props: PDCheckboxProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const { checkedState, onChange, labelId, size = 18, ...rest } = props;
  const isChecked = useMemo(() => checkedState === CheckboxCheckedState.Checked, [checkedState]);
  const isIndeterminate = useMemo(() => checkedState === CheckboxCheckedState.Indeterminate, [checkedState]);

  return (
    <StyledCheckbox
      ref={ref}
      borderColor={isChecked ? colors.flamboyant_teal : colors.lunar_rays}
      borderRadius={3}
      id={labelId}
      icon={<InnerIcon size={size} />}
      size={size}
      $isIndeterminate={isIndeterminate}
      {...rest}
      checked={isChecked}
      onChange={onChange}
    />
  );
});
