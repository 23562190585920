import { useIsFetching } from "@tanstack/react-query";
import type { KpiGroupUserLensDto, KpiTypeUserLensDto, PivotKpiGroupUserLensDto } from "apis/oag";
import { LensTemplateType, ResultDataState, StackingType } from "apis/oag";
import { PrimaryKpiSummary, SecondaryKpiSummary } from "components/Lenses/common/KpiSummaries";
import { LENS_RIG_KPI_HEIGHT_INNER } from "components/Lenses/constants";
import type { ContainerLensProps } from "components/Lenses/ContainerLens/common/utils/getContainerLens";
import { LoadingChart } from "components/Lenses/LoadingChart";
import { SecondaryKpiContainer, StyledKpiRow } from "components/MiniLens/style";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useKpiGroups } from "hooks/useKPIGroups";
import { useKpiTypes } from "hooks/useKpiTypes";
import { useLensTemplates } from "hooks/useLensTemplates";
import { useSelectedRig } from "hooks/useSelectedRig";
import { roundNumberToDecimal } from "pages/Lens/LensSummaryView";
import { useCallback, useMemo } from "react";
import { DisableColorForIdAction } from "reducers/legendColorReducer";
import { useAppDispatch } from "reducers/store";
import styled from "styled-components";
import { Space } from "utils/componentLibrary";
import { useUOMbyLens } from "utils/format";
import { transformKey } from "utils/helper";
import { RequestUID } from "utils/queryNamespaces";

import PivotChart from "./Chart";
import { usePivotKpiFetcher } from "./fetcher";

const InfoContainer = styled(Space)<{ $detailed: boolean }>`
  padding: 24px;
  height: ${({ $detailed }) => ($detailed ? "300px" : "120px")};
`;

const PivotKpi: React.FC<ContainerLensProps> = ({ detailed, graphKey, dimension, lens }) => {
  const kpiPivotFacts = usePivotKpiFetcher(lens as KpiGroupUserLensDto);
  const uom = useUOMbyLens(dimension, lens);
  const [offsetRigs] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET, []);
  const { data: templates } = useLensTemplates();
  const { data: kpiTypes } = useKpiTypes();
  const template = templates?.byId[lens.lensTemplateId];
  const avgRig = useMemo(() => {
    const summaryKpis = kpiPivotFacts.data?.summaryByKpi ?? [];
    const currentSummaryKpis = summaryKpis.filter((e) =>
      (lens as KpiGroupUserLensDto).stackingType === StackingType.Distribution ||
      template?.type === LensTemplateType.PivotKpiType
        ? e.id !== 0
        : e.id === 0,
    );
    const sortedSummaryKpis = currentSummaryKpis.slice().sort((a, b) => a.id - b.id);
    return sortedSummaryKpis[0];
  }, [kpiPivotFacts.data?.summaryByKpi, lens, template?.type]);
  const { data: kpiGroups } = useKpiGroups();

  const isComparing = offsetRigs.length > 0;
  const isRotateLens = (lens as KpiGroupUserLensDto).stackingType === StackingType.Distribution;
  const kpiType = useMemo(() => kpiTypes?.byId[avgRig?.id], [kpiTypes?.byId, avgRig]);

  const isFetching = useIsFetching({
    predicate: (query) => {
      return query.queryKey[3] === lens.id;
    },
  });

  const getComparisonSummary = useCallback(
    (relativeDiff: number, comparisonAverage: number, isComparing: boolean) => {
      if (relativeDiff) {
        return `${roundNumberToDecimal(relativeDiff * 100)}% · ${uom.display(comparisonAverage)} avg`;
      }
      if (isComparing) {
        return "- -";
      }
      return "";
    },
    [uom],
  );

  const mainKPIValues = useMemo<{
    main: string;
    secondary?: string;
    templateName?: string;
  }>(() => {
    if (isFetching) {
      return {
        main: "Loading...",
      };
    }
    return {
      main: isRotateLens
        ? roundNumberToDecimal((avgRig?.focalDistribution || 0) * 100, 2) + " %"
        : uom.display(avgRig?.focalAverage || 0) + " avg",
      secondary: getComparisonSummary(
        avgRig?.comparisonAverageRelativeDiff || 0,
        avgRig?.comparisonAverage || 0,
        isComparing,
      ),
      ...(isRotateLens && {
        templateName: transformKey(kpiType?.name ?? ""),
      }),
    };
  }, [avgRig, isFetching, getComparisonSummary, isRotateLens, isComparing, kpiType?.name, uom]);

  const lensTitle = useMemo(() => {
    if (mainKPIValues?.templateName) return mainKPIValues?.templateName;
    if (detailed) {
      return template?.name ?? "";
    }
    if (!kpiGroups?.byId || !kpiTypes?.byId) return "";
    const kpiType = kpiTypes?.byId[(lens as KpiTypeUserLensDto).kpiTypeId ?? -1];
    const kpiGroup = kpiGroups?.byId[(lens as KpiGroupUserLensDto).kpiGroupId ?? -1];

    if (kpiType) {
      return kpiType.name;
    }

    if (kpiGroup) {
      return kpiGroup.name;
    }

    return template?.name ?? "";
  }, [detailed, kpiGroups?.byId, kpiTypes?.byId, lens, template?.name, mainKPIValues?.templateName]);

  const secondaryKPIValues = useMemo(() => {
    const summaries = (kpiPivotFacts.data?.summaryByKpi ?? [])
      .filter((e) => e.id !== 0)
      .sort((a, b) => a.position - b.position);

    return (template?.type === LensTemplateType.PivotKpiType || isRotateLens ? summaries.slice(1) : summaries).map(
      (summary) => ({
        main:
          (lens as KpiGroupUserLensDto).stackingType === StackingType.Distribution
            ? `${roundNumberToDecimal((summary?.focalDistribution || 0) * 100, 2)}%`
            : uom.display(summary?.focalAverage || 0),
        secondary: getComparisonSummary(
          summary?.comparisonAverageRelativeDiff || 0,
          summary?.comparisonAverage || 0,
          isComparing,
        ),
        name: transformKey(kpiTypes?.byId[summary.id]?.name ?? ""),
      }),
    );
  }, [
    lens,
    getComparisonSummary,
    uom,
    isComparing,
    isRotateLens,
    kpiPivotFacts.data?.summaryByKpi,
    kpiTypes?.byId,
    template?.type,
  ]);

  const selectedRig = useSelectedRig();
  const rigsArr = useMemo(() => [selectedRig, ...offsetRigs], [offsetRigs, selectedRig]);
  const dispatch = useAppDispatch();
  const isFetchingWells = useIsFetching({
    queryKey: [
      {
        uid: RequestUID.rigsWells,
      },
    ],
    exact: false,
  });
  // when wells are fetching we still show a loading chart; business logic
  if (isFetchingWells) {
    return <LoadingChart isLoading title={lensTitle || ""} description="..." detailed={detailed} hasHeader />;
  }
  if (kpiPivotFacts.data?.dataState !== ResultDataState.Valid) {
    rigsArr.forEach((key) => {
      dispatch(DisableColorForIdAction(key + ""));
    });
    return <LoadingChart isLoading={false} title={lensTitle || ""} description="- -" detailed={detailed} hasHeader />;
  }

  return (
    <>
      <InfoContainer $detailed={detailed} size={6} direction="vertical">
        <StyledKpiRow gutter={34} height={LENS_RIG_KPI_HEIGHT_INNER}>
          <PrimaryKpiSummary
            secondary={mainKPIValues.secondary || ""}
            main={mainKPIValues.main}
            description={kpiType?.description || ""}
            title={lensTitle || ""}
            detailed={detailed}
          />
          {!isFetching && (
            <SecondaryKpiContainer>
              <SecondaryKpiSummary detailed={detailed} kpis={secondaryKPIValues.slice(0)} />
            </SecondaryKpiContainer>
          )}
        </StyledKpiRow>
      </InfoContainer>

      <PivotChart
        detailed={detailed}
        graphKey={graphKey}
        dimension={dimension}
        lens={lens as PivotKpiGroupUserLensDto}
        data={kpiPivotFacts.data}
      />
    </>
  );
};

export default PivotKpi;
