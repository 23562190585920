import { Group } from "@visx/group";
import { Text as VisXText } from "@visx/text";
import {
  getAxisFontSize,
  LATERAL_AXIS_TEXT_WIDTH,
  LATERAL_AXIS_WIDTH,
  TEXT_WIDTH_WHEN_VERTICAL,
} from "components/Lenses/ContainerLens/common/utils/utils";
import { useMemo } from "react";
import { useCustomTheme } from "utils/useTheme";

interface RotatedRightAxisLabelProps {
  plotWidth: number;
  chartHeight: number;
  displayText: string | null;
}

export const RotatedRightAxisLabel = ({ plotWidth, chartHeight, displayText }: RotatedRightAxisLabelProps) => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const axisFontSize = useMemo(() => getAxisFontSize(false), []);

  return (
    <div>
      <VisXText
        textAnchor="middle"
        transform={`rotate(-90,${plotWidth + LATERAL_AXIS_WIDTH + 50},${-chartHeight / 2 - TEXT_WIDTH_WHEN_VERTICAL})`}
        y={-chartHeight / 2}
        x={plotWidth + LATERAL_AXIS_WIDTH + LATERAL_AXIS_TEXT_WIDTH * 2 + TEXT_WIDTH_WHEN_VERTICAL}
        fill={themeColors.disabled_typography}
        fontSize={axisFontSize}
        opacity={1}
      >
        {displayText ? displayText : ""}
      </VisXText>
    </div>
  );
};
