/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlanTvdSeriesDto } from './PlanTvdSeriesDto';
import {
    PlanTvdSeriesDtoFromJSON,
    PlanTvdSeriesDtoFromJSONTyped,
    PlanTvdSeriesDtoToJSON,
} from './PlanTvdSeriesDto';
import type { ActualTvdSeriesDto } from './ActualTvdSeriesDto';
import {
    ActualTvdSeriesDtoFromJSON,
    ActualTvdSeriesDtoFromJSONTyped,
    ActualTvdSeriesDtoToJSON,
} from './ActualTvdSeriesDto';

/**
 * 
 * @export
 * @interface RawTvdSeriesDto
 */
export interface RawTvdSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof RawTvdSeriesDto
     */
    wellId: number;
    /**
     * 
     * @type {ActualTvdSeriesDto}
     * @memberof RawTvdSeriesDto
     */
    tvdSeries: ActualTvdSeriesDto;
    /**
     * 
     * @type {PlanTvdSeriesDto}
     * @memberof RawTvdSeriesDto
     */
    planTvdSeries: PlanTvdSeriesDto;
    /**
     * 
     * @type {Array<ActualTvdSeriesDto>}
     * @memberof RawTvdSeriesDto
     */
    comparisonTvdSeries?: Array<ActualTvdSeriesDto> | null;
    /**
     * 
     * @type {number}
     * @memberof RawTvdSeriesDto
     */
    actualToPlanDiffTime?: number | null;
}

/**
 * Check if a given object implements the RawTvdSeriesDto interface.
 */
export function instanceOfRawTvdSeriesDto(value: object): value is RawTvdSeriesDto {
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('tvdSeries' in value) || value['tvdSeries'] === undefined) return false;
    if (!('planTvdSeries' in value) || value['planTvdSeries'] === undefined) return false;
    return true;
}

export function RawTvdSeriesDtoFromJSON(json: any): RawTvdSeriesDto {
    return RawTvdSeriesDtoFromJSONTyped(json, false);
}

export function RawTvdSeriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RawTvdSeriesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'wellId': json['WellId'],
        'tvdSeries': ActualTvdSeriesDtoFromJSON(json['TvdSeries']),
        'planTvdSeries': PlanTvdSeriesDtoFromJSON(json['PlanTvdSeries']),
        'comparisonTvdSeries': json['ComparisonTvdSeries'] == null ? undefined : ((json['ComparisonTvdSeries'] as Array<any>).map(ActualTvdSeriesDtoFromJSON)),
        'actualToPlanDiffTime': json['ActualToPlanDiffTime'] == null ? undefined : json['ActualToPlanDiffTime'],
    };
}

export function RawTvdSeriesDtoToJSON(value?: RawTvdSeriesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'WellId': value['wellId'],
        'TvdSeries': ActualTvdSeriesDtoToJSON(value['tvdSeries']),
        'PlanTvdSeries': PlanTvdSeriesDtoToJSON(value['planTvdSeries']),
        'ComparisonTvdSeries': value['comparisonTvdSeries'] == null ? undefined : ((value['comparisonTvdSeries'] as Array<any>).map(ActualTvdSeriesDtoToJSON)),
        'ActualToPlanDiffTime': value['actualToPlanDiffTime'],
    };
}

