import { baseMatrix, MatrixAvailability } from "components/Lenses/ContainerLens/common/utils/constants";
import type { AbilityMatrixType } from "components/Lenses/ContainerLens/common/utils/getAbilityMatrix";

export const getPowerConsumptionByOperationAbilityMatrix = (): AbilityMatrixType => {
  return {
    ...baseMatrix,
    hasDetailedView: MatrixAvailability.NOT_SUPPORTED,
    lensSettings: MatrixAvailability.NOT_SUPPORTED,
  };
};
