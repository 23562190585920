import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import colors from "utils/colors";

interface IndicatorBarProps {
  x: number;
  height: number;
  labels: string[];
  showLabels?: boolean;
  alignment: "center" | "left" | "right";
}

export const maxIndicatorWidth = 130;

export function IndicatorBar({ x, height, labels, showLabels = true, alignment }: IndicatorBarProps) {
  let alignedX = x - maxIndicatorWidth + 10; // "right";
  if (alignment === "center") {
    alignedX = x - maxIndicatorWidth / 2;
  } else if (alignment === "left") {
    alignedX = x - 5;
  }

  return (
    <Group>
      <Bar
        x={x}
        y={showLabels ? 5 : 0}
        width={4}
        height={showLabels ? height - 5 : height}
        fill="black"
        fillOpacity={0.3}
      />
      {showLabels
        ? labels.map((label, index) => (
            <foreignObject
              key={label}
              className="node"
              x={alignedX}
              y={5 + index * 40}
              width={maxIndicatorWidth}
              height={35}
            >
              <div
                style={{
                  padding: "8px 10px",
                  background: colors.white,
                  boxShadow: `0 1px 2px 0 ${colors.buttons_clicked}`,
                  fontSize: "14px",
                  height: "32px",
                  borderRadius: "4px",
                  textAlign: "center",
                  width: "fit-content",
                  marginLeft: alignment === "left" ? 2 : "auto",
                  marginRight: alignment === "right" ? 2 : "auto",
                }}
              >
                {label}
              </div>
            </foreignObject>
          ))
        : null}
    </Group>
  );
}
