/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PivotType = {
    None: 'None',
    StandNumber: 'StandNumber',
    Day: 'Day',
    Week: 'Week',
    Month: 'Month',
    Quarter: 'Quarter',
    Semester: 'Semester',
    Year: 'Year',
    Rig: 'Rig',
    Well: 'Well',
    HoleSection: 'HoleSection',
    HoleSize: 'HoleSize',
    DirectionalInterval: 'DirectionalInterval',
    Driller: 'Driller',
    Shift: 'Shift',
    Operator: 'Operator'
} as const;
export type PivotType = typeof PivotType[keyof typeof PivotType];


export function instanceOfPivotType(value: any): boolean {
    for (const key in PivotType) {
        if (Object.prototype.hasOwnProperty.call(PivotType, key)) {
            if (PivotType[key as keyof typeof PivotType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PivotTypeFromJSON(json: any): PivotType {
    return PivotTypeFromJSONTyped(json, false);
}

export function PivotTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PivotType {
    return json as PivotType;
}

export function PivotTypeToJSON(value?: PivotType | null): any {
    return value as any;
}

