import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type { WellStatusType } from "apis/oag";
import { RigsApi } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateOnlyDto, last30Days, today } from "utils/helper";
import type { PDRigsWellsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";
import { useDashboardType } from "./useDashboardType";
import { useSelectedRig } from "./useSelectedRig";

const rigs = new RigsApi(apiConfig);

const useQueryParameters = (
  options?: Omit<UseQueryOptions<Array<number>>, "queryKey" | "queryFn"> & {
    isFilteringIgnored?: boolean;
    defaultRig?: number;
  },
) => {
  const [rigPeriodState] = useStateQuery<RangeType>(URL_STATE_PARAM.PERIOD_RIG_WIDGET, {
    startDate: last30Days,
    endDate: today,
  });

  const rigsCommonPeriodState = useAppSelector((state) => state.rigsCommon.period);

  const { isRig: isRigDashboard, isRootDashboardPage } = useDashboardType();
  const periodState = (isRigDashboard && isRootDashboardPage ? rigPeriodState : rigsCommonPeriodState) || {
    startDate: last30Days,
    endDate: today,
  };

  const [offsetRigs] = useStateQuery<Array<number>>(URL_STATE_PARAM.OFFSET_WELLS_RIGS_WIDGET, []);

  const dashboardRigId = useSelectedRig();
  const selectedRig = options?.defaultRig || dashboardRigId;

  const filterState = useAppSelector((state) => state.rigsCommon.filters);

  const RigsWellsQueryKey: PDRigsWellsQueryKey = {
    uid: RequestUID.rigsWells,
    type: PDQueryType.RIGS_WELLS,
    selectedRig,
    offsetRigs: options?.defaultRig ? [] : offsetRigs,
    from: dateToDateOnlyDto(periodState?.startDate || defaultDateDto.from.utc),
    to: dateToDateOnlyDto(periodState?.endDate || defaultDateDto.to.utc),
    isFilteringIgnored: !!options?.isFilteringIgnored,
  };
  return {
    queryKey: [RigsWellsQueryKey],
    queryFn: async () => {
      const data = await rigs.apiRigsWellIdsPut({
        allRigsQueryDto: {
          rigIds: [selectedRig, ...(options?.defaultRig ? [] : offsetRigs)],
          from: options?.isFilteringIgnored
            ? defaultDateDto.from
            : dateToDateOnlyDto(periodState?.startDate || defaultDateDto.from.utc),
          to: options?.isFilteringIgnored
            ? defaultDateDto.to
            : dateToDateOnlyDto(periodState?.endDate || defaultDateDto.to.utc),
          includeFullWellFacts: false,
          selectedFilters: {
            directionalIntervalIds: null,
            includeFlatTime: true,
            includeSlidingTime: true,
            includeRotatingTime: true,
            includeNullHoleDepth: true,
            holeSizeIds: null,
            sectionIds: null,
            includeAlphaRigs: true,
            includeNoneAlphaRigs: true,
            selectedWellStatusTypes: options?.isFilteringIgnored
              ? null
              : (filterState?.wellStatus.map((ws) => ws.id) as WellStatusType[]),
          },
        },
      });

      return data;
    },
    ...options,
  };
};

export function useRigToWellsSuspended(
  options?: Omit<UseQueryOptions<Array<number>>, "queryKey" | "queryFn"> & {
    isFilteringIgnored?: boolean;
    defaultRig?: number;
  },
) {
  const queryParameters = useQueryParameters(options);
  return useSuspenseQuery<Array<number>>(queryParameters);
}

export function useRigToWells(
  options?: Omit<UseQueryOptions<Array<number>>, "queryKey" | "queryFn"> & {
    isFilteringIgnored?: boolean;
    defaultRig?: number;
  },
) {
  const queryParameters = useQueryParameters(options);
  return useQuery<Array<number>>(queryParameters);
}
