import type { Configuration, RedirectRequest } from "@azure/msal-browser";
import { BrowserCacheLocation } from "@azure/msal-browser";

const tenantSubDomain = import.meta.env.VITE_REACT_APP_B2C_TENANT_SUB_DOMAIN as string;
const clientId = import.meta.env.VITE_REACT_APP_B2C_TENANT_CLIENT_ID as string;
const tenant = `${tenantSubDomain}.onmicrosoft.com`;
const policy = "B2C_1A_SIGNIN";
const resetPolicy = "B2C_1A_PASSWORDRESET";

export const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: `https://${tenantSubDomain}.b2clogin.com/tfp/${tenant}/${policy}`,
    postLogoutRedirectUri: import.meta.env.VITE_REACT_APP_REDIRECT_URL as string,
    redirectUri: import.meta.env.VITE_REACT_APP_REDIRECT_URL as string,
    knownAuthorities: [`${tenantSubDomain}.b2clogin.com`],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    secureCookies: true,
  },
};

export const forgotPasswordRequest = {
  scopes: [`https://${tenant}/api/all`],
  authority: `https://${tenantSubDomain}.b2clogin.com/tfp/${tenant}/${resetPolicy}`,
};

export const loginRequest: RedirectRequest = {
  scopes: [`https://${tenant}/api/all`],
  
  // default is "NA" and can trigger an infinite loop because browsers are becoming more and more picky with third party cookies
  // "none" removes the ability to do a fresh login altogether
 // prompt: "login",
};
