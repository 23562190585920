import { Title } from "atoms/Typography";
import React from "react";
import styled from "styled-components";
import { Col } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

const BehindSchedule = styled(Col)<{ muted: boolean }>`
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  border-left: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
  &:last-of-type {
    border-radius: 0 4px 4px 0;
  }
  &:first-of-type {
    border-radius: 4px 0 0 4px;
    border-left: none;
  }
  background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};

  ${({ muted }) =>
    muted &&
    `
    border-left: none;
    padding-left: 0;
  `};
`;

const Stats: React.FC<{ label: string; muted: boolean }> = ({ label, muted }) => {
  const { atomThemeVariant } = useCustomTheme();
  return (
    <BehindSchedule muted={muted}>
      <Title level={3} variant={muted ? "faded" : atomThemeVariant}>
        {label}
      </Title>
    </BehindSchedule>
  );
};

export default Stats;
