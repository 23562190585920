import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type {
  ApiFuelFlowRateUserLensesIdFactsGeneratorsPutRequest,
  BoilerFuelFlowRateResultDto,
  FuelFlowRateResultDto,
  FuelFlowRateUserLensDto,
} from "apis/oag";
import { FuelFlowRateUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useFilterParams } from "hooks/useFilterParams";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateDto } from "utils/helper";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const fuelFlowRateUserLensesApi = new FuelFlowRateUserLensesApi(apiConfig);

export function useRequestParams(lensId: number) {
  const filterParams = useFilterParams();

  const [zoomData] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WELL, initialZoomData, [
    URL_STATE_PARAM.ZOOM_WIDGET,
  ]);

  const requestParameters: ApiFuelFlowRateUserLensesIdFactsGeneratorsPutRequest = {
    id: lensId,
    baseFuelQueryDto: filterParams,
  };

  let requestParametersWithZoom: ApiFuelFlowRateUserLensesIdFactsGeneratorsPutRequest = requestParameters;
  if (zoomData.date_start && zoomData.type === IZoomType.DATE) {
    requestParametersWithZoom = {
      ...requestParameters,
      baseFuelQueryDto: requestParameters.baseFuelQueryDto
        ? {
            ...requestParameters.baseFuelQueryDto,
            from: dateToDateDto(zoomData.date_start) || defaultDateDto.from,
            to: dateToDateDto(zoomData.date_end) || defaultDateDto.to,
          }
        : undefined,
    };
  }

  return requestParametersWithZoom;
}

export function useFuelFlowRateFacts(lens: FuelFlowRateUserLensDto, options?: UseQueryOptions<FuelFlowRateResultDto>) {
  const requestParametersWithZoom: ApiFuelFlowRateUserLensesIdFactsGeneratorsPutRequest = useRequestParams(lens.id);
  const crtWellId = useSelectedWell();
  const dispatch = useAppDispatch();

  const queryKey: PDLensFactsQueryKey<ApiFuelFlowRateUserLensesIdFactsGeneratorsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.fuelRateFacts,
    params: requestParametersWithZoom,
    lensId: lens.id,
  };

  const request = useSuspenseQuery<FuelFlowRateResultDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      fuelFlowRateUserLensesApi
        .apiFuelFlowRateUserLensesIdFactsGeneratorsPut(requestParametersWithZoom, { signal })
        .then((data) => {
          if (data?.lastUpdatedAt) {
            dispatch({
              type: "SET_FUEL_MANAGEMENT_REFRESH_DATE",
              payload: {
                [crtWellId]: data?.lastUpdatedAt,
              },
            });
          }
          return data;
        }),
    ...options,
  });

  return request;
}

export function useBoilerFuelFlowRateFacts(
  lens: FuelFlowRateUserLensDto,
  options?: UseQueryOptions<BoilerFuelFlowRateResultDto>,
) {
  const requestParametersWithZoom: ApiFuelFlowRateUserLensesIdFactsGeneratorsPutRequest = useRequestParams(lens.id);

  const queryKey: PDLensFactsQueryKey<ApiFuelFlowRateUserLensesIdFactsGeneratorsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.boilerFuelRateFacts,
    params: requestParametersWithZoom,
    lensId: lens.id,
  };

  const crtWellId = useSelectedWell();
  const dispatch = useAppDispatch();

  const request = useSuspenseQuery<BoilerFuelFlowRateResultDto>({
    queryKey: [queryKey],
    queryFn: () =>
      fuelFlowRateUserLensesApi.apiFuelFlowRateUserLensesIdFactsBoilersPut(requestParametersWithZoom).then((data) => {
        if (data?.lastUpdatedAt) {
          dispatch({
            type: "SET_FUEL_MANAGEMENT_REFRESH_DATE",
            payload: {
              [crtWellId]: data?.lastUpdatedAt,
            },
          });
        }
        return data;
      }),
    ...options,
  });

  return request;
}
