/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RigCardAverages
 */
export interface RigCardAverages {
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    kpiValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    operatorScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    rigScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    improvementScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    targetDeltaTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    invisibleLostTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    savedTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    targetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    pdBenchmarkValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardAverages
     */
    baseValue?: number | null;
}

/**
 * Check if a given object implements the RigCardAverages interface.
 */
export function instanceOfRigCardAverages(value: object): value is RigCardAverages {
    return true;
}

export function RigCardAveragesFromJSON(json: any): RigCardAverages {
    return RigCardAveragesFromJSONTyped(json, false);
}

export function RigCardAveragesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardAverages {
    if (json == null) {
        return json;
    }
    return {
        
        'kpiValue': json['KpiValue'] == null ? undefined : json['KpiValue'],
        'operatorScore': json['OperatorScore'] == null ? undefined : json['OperatorScore'],
        'rigScore': json['RigScore'] == null ? undefined : json['RigScore'],
        'improvementScore': json['ImprovementScore'] == null ? undefined : json['ImprovementScore'],
        'targetDeltaTime': json['TargetDeltaTime'] == null ? undefined : json['TargetDeltaTime'],
        'invisibleLostTime': json['InvisibleLostTime'] == null ? undefined : json['InvisibleLostTime'],
        'savedTime': json['SavedTime'] == null ? undefined : json['SavedTime'],
        'targetValue': json['TargetValue'] == null ? undefined : json['TargetValue'],
        'pdBenchmarkValue': json['PdBenchmarkValue'] == null ? undefined : json['PdBenchmarkValue'],
        'baseValue': json['BaseValue'] == null ? undefined : json['BaseValue'],
    };
}

export function RigCardAveragesToJSON(value?: RigCardAverages | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'KpiValue': value['kpiValue'],
        'OperatorScore': value['operatorScore'],
        'RigScore': value['rigScore'],
        'ImprovementScore': value['improvementScore'],
        'TargetDeltaTime': value['targetDeltaTime'],
        'InvisibleLostTime': value['invisibleLostTime'],
        'SavedTime': value['savedTime'],
        'TargetValue': value['targetValue'],
        'PdBenchmarkValue': value['pdBenchmarkValue'],
        'BaseValue': value['baseValue'],
    };
}

