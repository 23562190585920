/**
 * Errors factories for well plan
 */

import type { PlanActivityDto, PlanDto } from "apis/oag";
import { omit } from "lodash";
import type { UOMHelper } from "utils/format";

import { isNotEmptyRow } from "./utils";

export interface IErrorPlanEditor {
  [tab: string]: {
    startDepth?: string;
    [id: number]: { [key: string]: string };
  } | null;
}
export interface IErrorLocation {
  tab: string;
  id?: number;
  fields: Array<string>;
}

export const valueInvalid = ({
  prevErr,
  location,
  message,
}: {
  prevErr: IErrorPlanEditor;
  location: IErrorLocation;
  message?: string;
}) => {
  const newErr = { ...prevErr };
  location.fields.forEach((field) => {
    newErr[location.tab] = newErr[location.tab] || {};
    const locationTab = newErr[location.tab];
    if (location.id && locationTab) {
      newErr[location.tab] = {
        ...locationTab,
        [location.id]: {
          ...locationTab[location.id],
          [field]: message || `Value shall not be ${message}.`,
        },
      };
    }
  });
  return { ...newErr };
};

export const valueRequired = ({
  prevErr,
  location,
  message,
}: {
  prevErr: IErrorPlanEditor;
  location: IErrorLocation;
  message?: string;
}) => {
  const newErr = { ...prevErr };
  location.fields.forEach((field) => {
    newErr[location.tab] = newErr[location.tab] || {};
    const locationTab = newErr[location.tab];
    if (location.id === undefined) {
      if (field === "startDepth" && locationTab) {
        newErr[location.tab] = {
          ...locationTab,
          [field]: message || "This field is required",
        };
      }
    } else {
      if (locationTab) {
        newErr[location.tab] = {
          ...locationTab,
          [location.id]: {
            ...locationTab[location.id],
            [field]: message || `Value shall not be ${message}.`,
          },
        };
      }
    }
  });
  return { ...newErr };
};

export const valueDuplicated = ({
  prevErr,
  location,
  message,
}: {
  prevErr: IErrorPlanEditor;
  location: IErrorLocation;
  message?: string;
}) => {
  const newErr = { ...prevErr };
  location.fields.forEach((field) => {
    newErr[location.tab] = newErr[location.tab] || {};
    const locationTab = newErr[location.tab];
    if (location.id && locationTab) {
      newErr[location.tab] = {
        ...locationTab,
        [location.id]: {
          ...locationTab[location.id],
          [field]: message || `Value shall not be ${message}.`,
        },
      };
    }
  });
  return { ...newErr };
};
export interface IPlanActivities extends PlanActivityDto {
  sectionId?: number;
}
export const checkErrors = ({
  setComponentLevelErrors,
  plan,
  depthData,
}: {
  setComponentLevelErrors: React.Dispatch<React.SetStateAction<IErrorPlanEditor>>;
  plan?: PlanDto;
  depthData: UOMHelper;
}) => {
  setComponentLevelErrors({ overview: {}, drillingParameter: {}, formation: {} });
  const errors: Error[] = [];
  let formationsErrors = false;
  let drillingParametersErrors = false;
  let overviewErrors = false;
  const activities = (plan?.activities || [])
    .map((activity) => omit(activity, "startHoleDepth", "startCumulativeDuration"))
    // @ts-expect-error TODO Manage empty rows (nullable properties) with proper typing (wrap/unwrap)
    .filter((activity) => isNotEmptyRow(activity, { ignoredValues: ["position"] }));
  const formations = (plan?.formations || []).filter((formation) =>
    isNotEmptyRow(formation, { defaultValues: { formationId: 1 } }),
  );
  const drillingParameters = (plan?.drillingParameters || []).filter((drillingParameter) =>
    isNotEmptyRow(drillingParameter, {}),
  );

  if ((activities.length !== 0 && plan?.startHoleDepth === undefined) || plan?.startHoleDepth === -1) {
    setComponentLevelErrors((prevErr) =>
      valueRequired({
        prevErr,
        location: {
          tab: "overview",
          id: undefined,
          fields: ["startDepth"],
        },
      }),
    );
    errors.push(Error("Overview - invalid values"));
  }
  if (formations.length !== 0 && plan?.startFormationDepth === undefined) {
    setComponentLevelErrors((prevErr) =>
      valueRequired({
        prevErr,
        location: {
          tab: "formation",
          id: undefined,
          fields: ["startDepth"],
        },
      }),
    );
    errors.push(Error("Formations - invalid values"));
  }

  if (drillingParameters.length !== 0 && plan?.startParameterDepth === undefined) {
    setComponentLevelErrors((prevErr) =>
      valueRequired({
        prevErr,
        location: {
          tab: "drillingParameter",
          id: undefined,
          fields: ["startDepth"],
        },
      }),
    );
    errors.push(Error("Drilling Parameters - invalid values"));
  }

  activities.forEach((activity) => {
    if (!activity.duration || activity.duration < 0) {
      setComponentLevelErrors((prevErr) =>
        valueInvalid({
          prevErr,
          location: {
            tab: "overview",
            id: activity.id,
            fields: ["duration"],
          },
          message: `Value shall not be ${activity.duration === 0 ? "zero" : "empty"}.`,
        }),
      );

      if (!overviewErrors) {
        overviewErrors = true;
        errors.push(Error("Overview - invalid values"));
      }
    }

    if (!activity.phaseId) {
      setComponentLevelErrors((prevErr) =>
        valueRequired({
          prevErr,
          location: {
            tab: "overview",
            id: activity.id,
            fields: ["sectionId"],
          },
        }),
      );
      if (!overviewErrors) {
        overviewErrors = true;
        errors.push(Error("Overview - invalid values"));
      }
    }
    if (!activity.phaseId) {
      setComponentLevelErrors((prevErr) =>
        valueRequired({
          prevErr,
          location: {
            tab: "overview",
            id: activity.id,
            fields: ["phaseId"],
          },
        }),
      );
      if (!overviewErrors) {
        overviewErrors = true;
        errors.push(Error("Overview - invalid values"));
      }
    }
    if (activity.endHoleDepth === undefined || activity.endHoleDepth < 0) {
      setComponentLevelErrors((prevErr) =>
        valueRequired({
          prevErr,
          location: {
            tab: "overview",
            id: activity.id,
            fields: ["endHoleDepth"],
          },
        }),
      );
      if (!overviewErrors) {
        overviewErrors = true;
        errors.push(Error("Overview - end depth is required"));
      }
    }
  });

  formations.forEach((formation, index, arr) => {
    // Relax typing to check for both null and undefined use != instead of !==
    if (formation.measuredDepth === undefined) {
      setComponentLevelErrors((prevErr) =>
        valueRequired({
          prevErr,
          location: {
            tab: "formation",
            id: formation.id,
            fields: ["measuredDepth"],
          },
        }),
      );
      if (!formationsErrors) {
        formationsErrors = true;
        errors.push(Error("Formations - Invalid values"));
      }
    } else if (formation.formationId === undefined) {
      setComponentLevelErrors((prevErr) =>
        valueRequired({
          prevErr,
          location: {
            tab: "formation",
            id: formation.id,
            fields: ["formationId"],
          },
        }),
      );
      if (!formationsErrors) {
        formationsErrors = true;
        errors.push(Error("Formations - Invalid values"));
      }
    }
    const formationDuplicatedValue = formations.find(
      (formationRow) =>
        formationRow.id !== formation.id &&
        formationRow.trueVerticalDepth === formation.trueVerticalDepth &&
        formationRow.trueVerticalDepth !== undefined &&
        formationRow.trueVerticalDepth !== null,
    );
    if (formationDuplicatedValue) {
      setComponentLevelErrors((prevErr) =>
        valueDuplicated({
          prevErr,
          location: {
            tab: "formation",
            id: formation.id,
            fields: ["trueVerticalDepth"],
          },
          message: "True vertical depth",
        }),
      );
      if (!formationsErrors) {
        formationsErrors = true;
        errors.push(Error("Formations - Invalid values"));
      }
    }
    const formationDuplicatedValueMeasureDepth = formations.find(
      (formationRow) =>
        formationRow.id !== formation.id &&
        formationRow.measuredDepth === formation.measuredDepth &&
        formationRow.measuredDepth !== undefined &&
        formationRow.measuredDepth !== null,
    );
    if (formationDuplicatedValueMeasureDepth) {
      setComponentLevelErrors((prevErr) =>
        valueDuplicated({
          prevErr,
          location: {
            tab: "formation",
            id: formation.id,
            fields: ["measuredDepth"],
          },
          message: "Measured depth",
        }),
      );
      if (!formationsErrors) {
        formationsErrors = true;
        errors.push(Error("Formations - Invalid values"));
      }
    }

    const prevMeasuredDepth = index === 0 ? plan?.startFormationDepth || 0 : +arr[index - 1].measuredDepth;
    const prevTrueVerticalDepth = index === 0 ? -Infinity : +(arr[index - 1].trueVerticalDepth || 0);
    if (prevMeasuredDepth > formation.measuredDepth) {
      setComponentLevelErrors((prevErr) =>
        valueInvalid({
          prevErr,
          location: {
            tab: "formation",
            id: formation.id,
            fields: ["measuredDepth"],
          },
          message: `Measured depth cannot be below ${depthData.display(prevMeasuredDepth)}.`,
        }),
      );
      if (!formationsErrors) {
        formationsErrors = true;
        errors.push(Error("Formations - Invalid depth values"));
      }
    }
    if (prevTrueVerticalDepth > (formation?.trueVerticalDepth || 0)) {
      setComponentLevelErrors((prevErr) =>
        valueInvalid({
          prevErr,
          location: {
            tab: "formation",
            id: formation.id,
            fields: ["trueVerticalDepth"],
          },
          message: `True vertical depth cannot be below ${depthData.display(prevTrueVerticalDepth)}.`,
        }),
      );
      if (!formationsErrors) {
        formationsErrors = true;
        errors.push(Error("Formations - Invalid depth values"));
      }
    }
  });

  drillingParameters.forEach((param, index, arr) => {
    if (param.measuredDepth === undefined) {
      setComponentLevelErrors((prevErr) =>
        valueRequired({
          prevErr,
          location: {
            tab: "drillingParameter",
            id: param.id,
            fields: ["measuredDepth"],
          },
        }),
      );
      if (!drillingParametersErrors) {
        drillingParametersErrors = true;
        errors.push(Error("Drilling Parameters - Invalid values"));
      }
    }

    const prevMeasuredDepth = index === 0 ? plan?.startParameterDepth || 0 : +arr[index - 1].measuredDepth;
    if (prevMeasuredDepth >= param.measuredDepth) {
      setComponentLevelErrors((prevErr) =>
        valueInvalid({
          prevErr,
          location: {
            tab: "drillingParameter",
            id: param.id,
            fields: ["measuredDepth"],
          },
          message: `Measured depth cannot be below ${depthData.display(prevMeasuredDepth)}.`,
        }),
      );
      if (!drillingParametersErrors) {
        drillingParametersErrors = true;
        errors.push(Error("Drilling Parameters - Invalid depth values"));
      }
    }
    Object.keys(param).forEach((key) => {
      const column = key.replace("Max", "").replace("Min", "");
      if (key.includes("Max") || key.includes("Min")) {
        const isMax = key.replace(column, "") === "Max";
        if (
          param[`${column}Min` as keyof typeof param] === undefined ||
          param[`${column}Max` as keyof typeof param] === undefined ||
          param[`${column}Min` as keyof typeof param] === null ||
          param[`${column}Max` as keyof typeof param] === null
        ) {
          return;
        }
        if (
          isMax
            ? (param[key as keyof typeof param] || 0) <= (param[`${column}Min` as keyof typeof param] || 0)
            : (param[key as keyof typeof param] || 0) >= (param[`${column}Max` as keyof typeof param] || 0)
        ) {
          setComponentLevelErrors((prevErr) =>
            valueInvalid({
              prevErr,
              location: {
                tab: "drillingParameter",
                id: param.id,
                fields: [key],
              },
              message: `Value should be ${isMax ? "higher" : "lower"} than ${column}${!isMax ? "Max" : "Min"}.`,
            }),
          );
          if (!drillingParametersErrors) {
            drillingParametersErrors = true;
            errors.push(Error("Drilling Parameters - parameter values"));
          }
        }
      }
    });
  });
  return errors;
};
