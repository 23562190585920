import { ErrorBoundary } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { Title } from "atoms/Typography";
import CoreLayout, { SectionLayout } from "components/Layout";
import { WebGLSupportBanner } from "components/WebGLSupportBanner/WebGLSupportBanner";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "reducers/store";
import { useMixpanel } from "services/Mixpanel";
import { PDQueryType } from "utils/queryNamespaces";
import { useCustomTheme } from "utils/useTheme";

import AllWellsHeader from "./components/AllWellsHeader";
import KPIsContainer from "./components/KPIsContainer";
import MapView from "./components/MapView";
import { WellsMapViewProvider } from "./components/MapView/useWellsMapView";
import WellsContainer from "./components/WellsContainer";
import {
  PaddedContainer,
  StyledContainer,
  StyledHeaderContainer,
  StyledMapviewWrapper,
  StyledScrollMask,
  StyledSidebarWrapper,
  StyledSpace,
} from "./StyledComponents";

export const ALL_WELLS_DEFAULT_HEADER_HEIGHT = 64;

const Main: FC = () => {
  const queryClient = useQueryClient();

  const pageSeen = useRef(false);
  const { viewPage } = useMixpanel();

  useEffect(() => {
    if (viewPage && !pageSeen.current) {
      pageSeen.current = true;
      viewPage("All Wells");
    }
  }, [viewPage]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [{ type: PDQueryType.WELL_TVD_POINTS }], exact: false });
    };
  }, [queryClient]);

  const [headerHeight, setHeaderHeight] = useState(ALL_WELLS_DEFAULT_HEADER_HEIGHT);
  const wellFilters = useAppSelector((state) => state.allWells.filters);

  const { atomThemeVariant } = useCustomTheme();
  return (
    <WellsMapViewProvider>
      <StyledContainer>
        <AllWellsHeader setHeaderHeight={setHeaderHeight} wellFilters={wellFilters} />
      </StyledContainer>

      <StyledMapviewWrapper $headerHeight={headerHeight}>
        <ErrorBoundary fallback={<WebGLSupportBanner />}>
          <MapView />
        </ErrorBoundary>
      </StyledMapviewWrapper>
      <PaddedContainer>
        <CoreLayout
          sidebar={
            <SectionLayout
              CustomChildrenWrapper={StyledSidebarWrapper}
              header={
                <StyledHeaderContainer>
                  <Title level={3} variant={atomThemeVariant} weight={600}>
                    Wells KPI&apos;s
                  </Title>
                  <StyledScrollMask />
                </StyledHeaderContainer>
              }
            >
              <StyledSpace align="start">
                <KPIsContainer />
              </StyledSpace>
            </SectionLayout>
          }
        >
          <WellsContainer />
        </CoreLayout>
      </PaddedContainer>
    </WellsMapViewProvider>
  );
};

export default Main;
