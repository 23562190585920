/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigPowerDistributionBarSliceDto } from './RigPowerDistributionBarSliceDto';
import {
    RigPowerDistributionBarSliceDtoFromJSON,
    RigPowerDistributionBarSliceDtoFromJSONTyped,
    RigPowerDistributionBarSliceDtoToJSON,
} from './RigPowerDistributionBarSliceDto';

/**
 * 
 * @export
 * @interface RigPowerDistributionBarDto
 */
export interface RigPowerDistributionBarDto {
    /**
     * 
     * @type {number}
     * @memberof RigPowerDistributionBarDto
     */
    powerFrom: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerDistributionBarDto
     */
    powerTo: number;
    /**
     * 
     * @type {number}
     * @memberof RigPowerDistributionBarDto
     */
    readonly totalActiveSeconds: number;
    /**
     * 
     * @type {Array<RigPowerDistributionBarSliceDto>}
     * @memberof RigPowerDistributionBarDto
     */
    slices: Array<RigPowerDistributionBarSliceDto>;
}

/**
 * Check if a given object implements the RigPowerDistributionBarDto interface.
 */
export function instanceOfRigPowerDistributionBarDto(value: object): value is RigPowerDistributionBarDto {
    if (!('powerFrom' in value) || value['powerFrom'] === undefined) return false;
    if (!('powerTo' in value) || value['powerTo'] === undefined) return false;
    if (!('totalActiveSeconds' in value) || value['totalActiveSeconds'] === undefined) return false;
    if (!('slices' in value) || value['slices'] === undefined) return false;
    return true;
}

export function RigPowerDistributionBarDtoFromJSON(json: any): RigPowerDistributionBarDto {
    return RigPowerDistributionBarDtoFromJSONTyped(json, false);
}

export function RigPowerDistributionBarDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigPowerDistributionBarDto {
    if (json == null) {
        return json;
    }
    return {
        
        'powerFrom': json['PowerFrom'],
        'powerTo': json['PowerTo'],
        'totalActiveSeconds': json['TotalActiveSeconds'],
        'slices': ((json['Slices'] as Array<any>).map(RigPowerDistributionBarSliceDtoFromJSON)),
    };
}

export function RigPowerDistributionBarDtoToJSON(value?: Omit<RigPowerDistributionBarDto, 'TotalActiveSeconds'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'PowerFrom': value['powerFrom'],
        'PowerTo': value['powerTo'],
        'Slices': ((value['slices'] as Array<any>).map(RigPowerDistributionBarSliceDtoToJSON)),
    };
}

