import Lottie from "react-lottie";

import animationData from "./chat.json";
import { LottieContainer } from "./style";

export const ChatLoader = () => {
  return (
    <LottieContainer>
      <Lottie
        isPaused={false}
        isStopped={false}
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={40}
        width={40}
        style={{ margin: "0" }}
      />
    </LottieContainer>
  );
};
