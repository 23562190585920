/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DrillingProductivityLensTemplateDto } from './DrillingProductivityLensTemplateDto';
import {
    DrillingProductivityLensTemplateDtoFromJSON,
    DrillingProductivityLensTemplateDtoFromJSONTyped,
    DrillingProductivityLensTemplateDtoToJSON,
} from './DrillingProductivityLensTemplateDto';
import type { WedgeLensTemplateDto } from './WedgeLensTemplateDto';
import {
    WedgeLensTemplateDtoFromJSON,
    WedgeLensTemplateDtoFromJSONTyped,
    WedgeLensTemplateDtoToJSON,
} from './WedgeLensTemplateDto';
import type { PowerLoadDistributionLensTemplateDto } from './PowerLoadDistributionLensTemplateDto';
import {
    PowerLoadDistributionLensTemplateDtoFromJSON,
    PowerLoadDistributionLensTemplateDtoFromJSONTyped,
    PowerLoadDistributionLensTemplateDtoToJSON,
} from './PowerLoadDistributionLensTemplateDto';
import type { KpiGroupLensTemplateDto } from './KpiGroupLensTemplateDto';
import {
    KpiGroupLensTemplateDtoFromJSON,
    KpiGroupLensTemplateDtoFromJSONTyped,
    KpiGroupLensTemplateDtoToJSON,
} from './KpiGroupLensTemplateDto';
import type { PowerConsumptionByOperationLensTemplateDto } from './PowerConsumptionByOperationLensTemplateDto';
import {
    PowerConsumptionByOperationLensTemplateDtoFromJSON,
    PowerConsumptionByOperationLensTemplateDtoFromJSONTyped,
    PowerConsumptionByOperationLensTemplateDtoToJSON,
} from './PowerConsumptionByOperationLensTemplateDto';
import type { RigPowerLensTemplateDto } from './RigPowerLensTemplateDto';
import {
    RigPowerLensTemplateDtoFromJSON,
    RigPowerLensTemplateDtoFromJSONTyped,
    RigPowerLensTemplateDtoToJSON,
} from './RigPowerLensTemplateDto';
import type { PowerLoadEfficiencyLensTemplateDto } from './PowerLoadEfficiencyLensTemplateDto';
import {
    PowerLoadEfficiencyLensTemplateDtoFromJSON,
    PowerLoadEfficiencyLensTemplateDtoFromJSONTyped,
    PowerLoadEfficiencyLensTemplateDtoToJSON,
} from './PowerLoadEfficiencyLensTemplateDto';
import type { WellDrillingSummaryLensTemplateDto } from './WellDrillingSummaryLensTemplateDto';
import {
    WellDrillingSummaryLensTemplateDtoFromJSON,
    WellDrillingSummaryLensTemplateDtoFromJSONTyped,
    WellDrillingSummaryLensTemplateDtoToJSON,
} from './WellDrillingSummaryLensTemplateDto';
import type { FuelConsumptionByGeneratorLensTemplateDto } from './FuelConsumptionByGeneratorLensTemplateDto';
import {
    FuelConsumptionByGeneratorLensTemplateDtoFromJSON,
    FuelConsumptionByGeneratorLensTemplateDtoFromJSONTyped,
    FuelConsumptionByGeneratorLensTemplateDtoToJSON,
} from './FuelConsumptionByGeneratorLensTemplateDto';
import type { FuelFlowRateLensTemplateDto } from './FuelFlowRateLensTemplateDto';
import {
    FuelFlowRateLensTemplateDtoFromJSON,
    FuelFlowRateLensTemplateDtoFromJSONTyped,
    FuelFlowRateLensTemplateDtoToJSON,
} from './FuelFlowRateLensTemplateDto';
import type { RigScorecardLensTemplateDto } from './RigScorecardLensTemplateDto';
import {
    RigScorecardLensTemplateDtoFromJSON,
    RigScorecardLensTemplateDtoFromJSONTyped,
    RigScorecardLensTemplateDtoToJSON,
} from './RigScorecardLensTemplateDto';
import type { FuelTankVolumeLensTemplateDto } from './FuelTankVolumeLensTemplateDto';
import {
    FuelTankVolumeLensTemplateDtoFromJSON,
    FuelTankVolumeLensTemplateDtoFromJSONTyped,
    FuelTankVolumeLensTemplateDtoToJSON,
} from './FuelTankVolumeLensTemplateDto';
import type { NaturalGasStateLensTemplateDto } from './NaturalGasStateLensTemplateDto';
import {
    NaturalGasStateLensTemplateDtoFromJSON,
    NaturalGasStateLensTemplateDtoFromJSONTyped,
    NaturalGasStateLensTemplateDtoToJSON,
} from './NaturalGasStateLensTemplateDto';
import type { RigFleetPerformanceCardLensTemplateDto } from './RigFleetPerformanceCardLensTemplateDto';
import {
    RigFleetPerformanceCardLensTemplateDtoFromJSON,
    RigFleetPerformanceCardLensTemplateDtoFromJSONTyped,
    RigFleetPerformanceCardLensTemplateDtoToJSON,
} from './RigFleetPerformanceCardLensTemplateDto';
import type { PivotKpiGroupLensTemplateDto } from './PivotKpiGroupLensTemplateDto';
import {
    PivotKpiGroupLensTemplateDtoFromJSON,
    PivotKpiGroupLensTemplateDtoFromJSONTyped,
    PivotKpiGroupLensTemplateDtoToJSON,
} from './PivotKpiGroupLensTemplateDto';
import type { DieselNatGasRatioStatsLensTemplateDto } from './DieselNatGasRatioStatsLensTemplateDto';
import {
    DieselNatGasRatioStatsLensTemplateDtoFromJSON,
    DieselNatGasRatioStatsLensTemplateDtoFromJSONTyped,
    DieselNatGasRatioStatsLensTemplateDtoToJSON,
} from './DieselNatGasRatioStatsLensTemplateDto';
import type { GhgEmissionsLensTemplateDto } from './GhgEmissionsLensTemplateDto';
import {
    GhgEmissionsLensTemplateDtoFromJSON,
    GhgEmissionsLensTemplateDtoFromJSONTyped,
    GhgEmissionsLensTemplateDtoToJSON,
} from './GhgEmissionsLensTemplateDto';
import type { TagBottomFingerprintLensTemplateDto } from './TagBottomFingerprintLensTemplateDto';
import {
    TagBottomFingerprintLensTemplateDtoFromJSON,
    TagBottomFingerprintLensTemplateDtoFromJSONTyped,
    TagBottomFingerprintLensTemplateDtoToJSON,
} from './TagBottomFingerprintLensTemplateDto';
import type { TorqueAndDragLensTemplateDto } from './TorqueAndDragLensTemplateDto';
import {
    TorqueAndDragLensTemplateDtoFromJSON,
    TorqueAndDragLensTemplateDtoFromJSONTyped,
    TorqueAndDragLensTemplateDtoToJSON,
} from './TorqueAndDragLensTemplateDto';
import type { GeneratorLoadProfileLensTemplateDto } from './GeneratorLoadProfileLensTemplateDto';
import {
    GeneratorLoadProfileLensTemplateDtoFromJSON,
    GeneratorLoadProfileLensTemplateDtoFromJSONTyped,
    GeneratorLoadProfileLensTemplateDtoToJSON,
} from './GeneratorLoadProfileLensTemplateDto';
import type { GeneratorStatsLensTemplateDto } from './GeneratorStatsLensTemplateDto';
import {
    GeneratorStatsLensTemplateDtoFromJSON,
    GeneratorStatsLensTemplateDtoFromJSONTyped,
    GeneratorStatsLensTemplateDtoToJSON,
} from './GeneratorStatsLensTemplateDto';
import type { ParameterHeatmapLensTemplateDto } from './ParameterHeatmapLensTemplateDto';
import {
    ParameterHeatmapLensTemplateDtoFromJSON,
    ParameterHeatmapLensTemplateDtoFromJSONTyped,
    ParameterHeatmapLensTemplateDtoToJSON,
} from './ParameterHeatmapLensTemplateDto';
import type { GeneratorStatusBarLensTemplateDto } from './GeneratorStatusBarLensTemplateDto';
import {
    GeneratorStatusBarLensTemplateDtoFromJSON,
    GeneratorStatusBarLensTemplateDtoFromJSONTyped,
    GeneratorStatusBarLensTemplateDtoToJSON,
} from './GeneratorStatusBarLensTemplateDto';
import type { ParameterByDepthLensTemplateDto } from './ParameterByDepthLensTemplateDto';
import {
    ParameterByDepthLensTemplateDtoFromJSON,
    ParameterByDepthLensTemplateDtoFromJSONTyped,
    ParameterByDepthLensTemplateDtoToJSON,
} from './ParameterByDepthLensTemplateDto';
import type { StatCardLensTemplateDto } from './StatCardLensTemplateDto';
import {
    StatCardLensTemplateDtoFromJSON,
    StatCardLensTemplateDtoFromJSONTyped,
    StatCardLensTemplateDtoToJSON,
} from './StatCardLensTemplateDto';
import type { BatteryStateLensTemplateDto } from './BatteryStateLensTemplateDto';
import {
    BatteryStateLensTemplateDtoFromJSON,
    BatteryStateLensTemplateDtoFromJSONTyped,
    BatteryStateLensTemplateDtoToJSON,
} from './BatteryStateLensTemplateDto';
import type { KpiTypeLensTemplateDto } from './KpiTypeLensTemplateDto';
import {
    KpiTypeLensTemplateDtoFromJSON,
    KpiTypeLensTemplateDtoFromJSONTyped,
    KpiTypeLensTemplateDtoToJSON,
} from './KpiTypeLensTemplateDto';
import type { PivotKpiTypeLensTemplateDto } from './PivotKpiTypeLensTemplateDto';
import {
    PivotKpiTypeLensTemplateDtoFromJSON,
    PivotKpiTypeLensTemplateDtoFromJSONTyped,
    PivotKpiTypeLensTemplateDtoToJSON,
} from './PivotKpiTypeLensTemplateDto';
import type { StickSlipLensTemplateDto } from './StickSlipLensTemplateDto';
import {
    StickSlipLensTemplateDtoFromJSON,
    StickSlipLensTemplateDtoFromJSONTyped,
    StickSlipLensTemplateDtoToJSON,
} from './StickSlipLensTemplateDto';

/**
 * 
 * @export
 * @interface LensTemplateSetDto
 */
export interface LensTemplateSetDto {
    /**
     * 
     * @type {Array<KpiTypeLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    kpiTypeLensTemplates?: Array<KpiTypeLensTemplateDto> | null;
    /**
     * 
     * @type {Array<PivotKpiTypeLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    pivotKpiTypeLensTemplates?: Array<PivotKpiTypeLensTemplateDto> | null;
    /**
     * 
     * @type {Array<KpiGroupLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    kpiGroupLensTemplates?: Array<KpiGroupLensTemplateDto> | null;
    /**
     * 
     * @type {Array<PivotKpiGroupLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    pivotKpiGroupLensTemplates?: Array<PivotKpiGroupLensTemplateDto> | null;
    /**
     * 
     * @type {Array<WedgeLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    wedgeLensTemplates?: Array<WedgeLensTemplateDto> | null;
    /**
     * 
     * @type {Array<ParameterByDepthLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    parameterByDepthLensTemplates?: Array<ParameterByDepthLensTemplateDto> | null;
    /**
     * 
     * @type {Array<WellDrillingSummaryLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    wellDrillingSummaryTemplates?: Array<WellDrillingSummaryLensTemplateDto> | null;
    /**
     * 
     * @type {Array<ParameterHeatmapLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    parameterHeatmapLensTemplates?: Array<ParameterHeatmapLensTemplateDto> | null;
    /**
     * 
     * @type {Array<TorqueAndDragLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    torqueAndDragLensTemplates?: Array<TorqueAndDragLensTemplateDto> | null;
    /**
     * 
     * @type {Array<DrillingProductivityLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    drillingProductivityLensTemplates?: Array<DrillingProductivityLensTemplateDto> | null;
    /**
     * 
     * @type {Array<BatteryStateLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    batteryStateLensTemplates?: Array<BatteryStateLensTemplateDto> | null;
    /**
     * 
     * @type {Array<RigPowerLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    rigPowerLensTemplates?: Array<RigPowerLensTemplateDto> | null;
    /**
     * 
     * @type {Array<PowerConsumptionByOperationLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    powerConsumptionByOperationLensTemplates?: Array<PowerConsumptionByOperationLensTemplateDto> | null;
    /**
     * 
     * @type {Array<StatCardLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    statCardLensTemplates?: Array<StatCardLensTemplateDto> | null;
    /**
     * 
     * @type {Array<NaturalGasStateLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    naturalGasStateLensTemplates?: Array<NaturalGasStateLensTemplateDto> | null;
    /**
     * 
     * @type {Array<GeneratorStatusBarLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    generatorStatusBarLensTemplates?: Array<GeneratorStatusBarLensTemplateDto> | null;
    /**
     * 
     * @type {Array<FuelFlowRateLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    fuelFlowRateLensTemplates?: Array<FuelFlowRateLensTemplateDto> | null;
    /**
     * 
     * @type {Array<GeneratorStatsLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    generatorStatsLensTemplates?: Array<GeneratorStatsLensTemplateDto> | null;
    /**
     * 
     * @type {Array<GeneratorLoadProfileLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    generatorLoadProfileLensTemplates?: Array<GeneratorLoadProfileLensTemplateDto> | null;
    /**
     * 
     * @type {Array<PowerLoadDistributionLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    powerLoadDistributionLensTemplates?: Array<PowerLoadDistributionLensTemplateDto> | null;
    /**
     * 
     * @type {Array<GhgEmissionsLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    ghgEmissionsLensTemplates?: Array<GhgEmissionsLensTemplateDto> | null;
    /**
     * 
     * @type {Array<FuelTankVolumeLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    fuelTankVolumeLensTemplates?: Array<FuelTankVolumeLensTemplateDto> | null;
    /**
     * 
     * @type {Array<PowerLoadEfficiencyLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    powerLoadEfficiencyLensTemplates?: Array<PowerLoadEfficiencyLensTemplateDto> | null;
    /**
     * 
     * @type {Array<FuelConsumptionByGeneratorLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    fuelConsumptionByGeneratorLensTemplates?: Array<FuelConsumptionByGeneratorLensTemplateDto> | null;
    /**
     * 
     * @type {Array<DieselNatGasRatioStatsLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    dieselNatGasRatioStatsLensTemplates?: Array<DieselNatGasRatioStatsLensTemplateDto> | null;
    /**
     * 
     * @type {Array<RigFleetPerformanceCardLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    rigFleetPerformanceCardLensTemplates?: Array<RigFleetPerformanceCardLensTemplateDto> | null;
    /**
     * 
     * @type {Array<RigScorecardLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    rigScorecardLensTemplates?: Array<RigScorecardLensTemplateDto> | null;
    /**
     * 
     * @type {Array<TagBottomFingerprintLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    tagBottomFingerprintLensTemplates?: Array<TagBottomFingerprintLensTemplateDto> | null;
    /**
     * 
     * @type {Array<StickSlipLensTemplateDto>}
     * @memberof LensTemplateSetDto
     */
    stickSlipLensTemplates?: Array<StickSlipLensTemplateDto> | null;
}

/**
 * Check if a given object implements the LensTemplateSetDto interface.
 */
export function instanceOfLensTemplateSetDto(value: object): value is LensTemplateSetDto {
    return true;
}

export function LensTemplateSetDtoFromJSON(json: any): LensTemplateSetDto {
    return LensTemplateSetDtoFromJSONTyped(json, false);
}

export function LensTemplateSetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LensTemplateSetDto {
    if (json == null) {
        return json;
    }
    return {
        
        'kpiTypeLensTemplates': json['KpiTypeLensTemplates'] == null ? undefined : ((json['KpiTypeLensTemplates'] as Array<any>).map(KpiTypeLensTemplateDtoFromJSON)),
        'pivotKpiTypeLensTemplates': json['PivotKpiTypeLensTemplates'] == null ? undefined : ((json['PivotKpiTypeLensTemplates'] as Array<any>).map(PivotKpiTypeLensTemplateDtoFromJSON)),
        'kpiGroupLensTemplates': json['KpiGroupLensTemplates'] == null ? undefined : ((json['KpiGroupLensTemplates'] as Array<any>).map(KpiGroupLensTemplateDtoFromJSON)),
        'pivotKpiGroupLensTemplates': json['PivotKpiGroupLensTemplates'] == null ? undefined : ((json['PivotKpiGroupLensTemplates'] as Array<any>).map(PivotKpiGroupLensTemplateDtoFromJSON)),
        'wedgeLensTemplates': json['WedgeLensTemplates'] == null ? undefined : ((json['WedgeLensTemplates'] as Array<any>).map(WedgeLensTemplateDtoFromJSON)),
        'parameterByDepthLensTemplates': json['ParameterByDepthLensTemplates'] == null ? undefined : ((json['ParameterByDepthLensTemplates'] as Array<any>).map(ParameterByDepthLensTemplateDtoFromJSON)),
        'wellDrillingSummaryTemplates': json['WellDrillingSummaryTemplates'] == null ? undefined : ((json['WellDrillingSummaryTemplates'] as Array<any>).map(WellDrillingSummaryLensTemplateDtoFromJSON)),
        'parameterHeatmapLensTemplates': json['ParameterHeatmapLensTemplates'] == null ? undefined : ((json['ParameterHeatmapLensTemplates'] as Array<any>).map(ParameterHeatmapLensTemplateDtoFromJSON)),
        'torqueAndDragLensTemplates': json['TorqueAndDragLensTemplates'] == null ? undefined : ((json['TorqueAndDragLensTemplates'] as Array<any>).map(TorqueAndDragLensTemplateDtoFromJSON)),
        'drillingProductivityLensTemplates': json['DrillingProductivityLensTemplates'] == null ? undefined : ((json['DrillingProductivityLensTemplates'] as Array<any>).map(DrillingProductivityLensTemplateDtoFromJSON)),
        'batteryStateLensTemplates': json['BatteryStateLensTemplates'] == null ? undefined : ((json['BatteryStateLensTemplates'] as Array<any>).map(BatteryStateLensTemplateDtoFromJSON)),
        'rigPowerLensTemplates': json['RigPowerLensTemplates'] == null ? undefined : ((json['RigPowerLensTemplates'] as Array<any>).map(RigPowerLensTemplateDtoFromJSON)),
        'powerConsumptionByOperationLensTemplates': json['PowerConsumptionByOperationLensTemplates'] == null ? undefined : ((json['PowerConsumptionByOperationLensTemplates'] as Array<any>).map(PowerConsumptionByOperationLensTemplateDtoFromJSON)),
        'statCardLensTemplates': json['StatCardLensTemplates'] == null ? undefined : ((json['StatCardLensTemplates'] as Array<any>).map(StatCardLensTemplateDtoFromJSON)),
        'naturalGasStateLensTemplates': json['NaturalGasStateLensTemplates'] == null ? undefined : ((json['NaturalGasStateLensTemplates'] as Array<any>).map(NaturalGasStateLensTemplateDtoFromJSON)),
        'generatorStatusBarLensTemplates': json['GeneratorStatusBarLensTemplates'] == null ? undefined : ((json['GeneratorStatusBarLensTemplates'] as Array<any>).map(GeneratorStatusBarLensTemplateDtoFromJSON)),
        'fuelFlowRateLensTemplates': json['FuelFlowRateLensTemplates'] == null ? undefined : ((json['FuelFlowRateLensTemplates'] as Array<any>).map(FuelFlowRateLensTemplateDtoFromJSON)),
        'generatorStatsLensTemplates': json['GeneratorStatsLensTemplates'] == null ? undefined : ((json['GeneratorStatsLensTemplates'] as Array<any>).map(GeneratorStatsLensTemplateDtoFromJSON)),
        'generatorLoadProfileLensTemplates': json['GeneratorLoadProfileLensTemplates'] == null ? undefined : ((json['GeneratorLoadProfileLensTemplates'] as Array<any>).map(GeneratorLoadProfileLensTemplateDtoFromJSON)),
        'powerLoadDistributionLensTemplates': json['PowerLoadDistributionLensTemplates'] == null ? undefined : ((json['PowerLoadDistributionLensTemplates'] as Array<any>).map(PowerLoadDistributionLensTemplateDtoFromJSON)),
        'ghgEmissionsLensTemplates': json['GhgEmissionsLensTemplates'] == null ? undefined : ((json['GhgEmissionsLensTemplates'] as Array<any>).map(GhgEmissionsLensTemplateDtoFromJSON)),
        'fuelTankVolumeLensTemplates': json['FuelTankVolumeLensTemplates'] == null ? undefined : ((json['FuelTankVolumeLensTemplates'] as Array<any>).map(FuelTankVolumeLensTemplateDtoFromJSON)),
        'powerLoadEfficiencyLensTemplates': json['PowerLoadEfficiencyLensTemplates'] == null ? undefined : ((json['PowerLoadEfficiencyLensTemplates'] as Array<any>).map(PowerLoadEfficiencyLensTemplateDtoFromJSON)),
        'fuelConsumptionByGeneratorLensTemplates': json['FuelConsumptionByGeneratorLensTemplates'] == null ? undefined : ((json['FuelConsumptionByGeneratorLensTemplates'] as Array<any>).map(FuelConsumptionByGeneratorLensTemplateDtoFromJSON)),
        'dieselNatGasRatioStatsLensTemplates': json['DieselNatGasRatioStatsLensTemplates'] == null ? undefined : ((json['DieselNatGasRatioStatsLensTemplates'] as Array<any>).map(DieselNatGasRatioStatsLensTemplateDtoFromJSON)),
        'rigFleetPerformanceCardLensTemplates': json['RigFleetPerformanceCardLensTemplates'] == null ? undefined : ((json['RigFleetPerformanceCardLensTemplates'] as Array<any>).map(RigFleetPerformanceCardLensTemplateDtoFromJSON)),
        'rigScorecardLensTemplates': json['RigScorecardLensTemplates'] == null ? undefined : ((json['RigScorecardLensTemplates'] as Array<any>).map(RigScorecardLensTemplateDtoFromJSON)),
        'tagBottomFingerprintLensTemplates': json['TagBottomFingerprintLensTemplates'] == null ? undefined : ((json['TagBottomFingerprintLensTemplates'] as Array<any>).map(TagBottomFingerprintLensTemplateDtoFromJSON)),
        'stickSlipLensTemplates': json['StickSlipLensTemplates'] == null ? undefined : ((json['StickSlipLensTemplates'] as Array<any>).map(StickSlipLensTemplateDtoFromJSON)),
    };
}

export function LensTemplateSetDtoToJSON(value?: LensTemplateSetDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'KpiTypeLensTemplates': value['kpiTypeLensTemplates'] == null ? undefined : ((value['kpiTypeLensTemplates'] as Array<any>).map(KpiTypeLensTemplateDtoToJSON)),
        'PivotKpiTypeLensTemplates': value['pivotKpiTypeLensTemplates'] == null ? undefined : ((value['pivotKpiTypeLensTemplates'] as Array<any>).map(PivotKpiTypeLensTemplateDtoToJSON)),
        'KpiGroupLensTemplates': value['kpiGroupLensTemplates'] == null ? undefined : ((value['kpiGroupLensTemplates'] as Array<any>).map(KpiGroupLensTemplateDtoToJSON)),
        'PivotKpiGroupLensTemplates': value['pivotKpiGroupLensTemplates'] == null ? undefined : ((value['pivotKpiGroupLensTemplates'] as Array<any>).map(PivotKpiGroupLensTemplateDtoToJSON)),
        'WedgeLensTemplates': value['wedgeLensTemplates'] == null ? undefined : ((value['wedgeLensTemplates'] as Array<any>).map(WedgeLensTemplateDtoToJSON)),
        'ParameterByDepthLensTemplates': value['parameterByDepthLensTemplates'] == null ? undefined : ((value['parameterByDepthLensTemplates'] as Array<any>).map(ParameterByDepthLensTemplateDtoToJSON)),
        'WellDrillingSummaryTemplates': value['wellDrillingSummaryTemplates'] == null ? undefined : ((value['wellDrillingSummaryTemplates'] as Array<any>).map(WellDrillingSummaryLensTemplateDtoToJSON)),
        'ParameterHeatmapLensTemplates': value['parameterHeatmapLensTemplates'] == null ? undefined : ((value['parameterHeatmapLensTemplates'] as Array<any>).map(ParameterHeatmapLensTemplateDtoToJSON)),
        'TorqueAndDragLensTemplates': value['torqueAndDragLensTemplates'] == null ? undefined : ((value['torqueAndDragLensTemplates'] as Array<any>).map(TorqueAndDragLensTemplateDtoToJSON)),
        'DrillingProductivityLensTemplates': value['drillingProductivityLensTemplates'] == null ? undefined : ((value['drillingProductivityLensTemplates'] as Array<any>).map(DrillingProductivityLensTemplateDtoToJSON)),
        'BatteryStateLensTemplates': value['batteryStateLensTemplates'] == null ? undefined : ((value['batteryStateLensTemplates'] as Array<any>).map(BatteryStateLensTemplateDtoToJSON)),
        'RigPowerLensTemplates': value['rigPowerLensTemplates'] == null ? undefined : ((value['rigPowerLensTemplates'] as Array<any>).map(RigPowerLensTemplateDtoToJSON)),
        'PowerConsumptionByOperationLensTemplates': value['powerConsumptionByOperationLensTemplates'] == null ? undefined : ((value['powerConsumptionByOperationLensTemplates'] as Array<any>).map(PowerConsumptionByOperationLensTemplateDtoToJSON)),
        'StatCardLensTemplates': value['statCardLensTemplates'] == null ? undefined : ((value['statCardLensTemplates'] as Array<any>).map(StatCardLensTemplateDtoToJSON)),
        'NaturalGasStateLensTemplates': value['naturalGasStateLensTemplates'] == null ? undefined : ((value['naturalGasStateLensTemplates'] as Array<any>).map(NaturalGasStateLensTemplateDtoToJSON)),
        'GeneratorStatusBarLensTemplates': value['generatorStatusBarLensTemplates'] == null ? undefined : ((value['generatorStatusBarLensTemplates'] as Array<any>).map(GeneratorStatusBarLensTemplateDtoToJSON)),
        'FuelFlowRateLensTemplates': value['fuelFlowRateLensTemplates'] == null ? undefined : ((value['fuelFlowRateLensTemplates'] as Array<any>).map(FuelFlowRateLensTemplateDtoToJSON)),
        'GeneratorStatsLensTemplates': value['generatorStatsLensTemplates'] == null ? undefined : ((value['generatorStatsLensTemplates'] as Array<any>).map(GeneratorStatsLensTemplateDtoToJSON)),
        'GeneratorLoadProfileLensTemplates': value['generatorLoadProfileLensTemplates'] == null ? undefined : ((value['generatorLoadProfileLensTemplates'] as Array<any>).map(GeneratorLoadProfileLensTemplateDtoToJSON)),
        'PowerLoadDistributionLensTemplates': value['powerLoadDistributionLensTemplates'] == null ? undefined : ((value['powerLoadDistributionLensTemplates'] as Array<any>).map(PowerLoadDistributionLensTemplateDtoToJSON)),
        'GhgEmissionsLensTemplates': value['ghgEmissionsLensTemplates'] == null ? undefined : ((value['ghgEmissionsLensTemplates'] as Array<any>).map(GhgEmissionsLensTemplateDtoToJSON)),
        'FuelTankVolumeLensTemplates': value['fuelTankVolumeLensTemplates'] == null ? undefined : ((value['fuelTankVolumeLensTemplates'] as Array<any>).map(FuelTankVolumeLensTemplateDtoToJSON)),
        'PowerLoadEfficiencyLensTemplates': value['powerLoadEfficiencyLensTemplates'] == null ? undefined : ((value['powerLoadEfficiencyLensTemplates'] as Array<any>).map(PowerLoadEfficiencyLensTemplateDtoToJSON)),
        'FuelConsumptionByGeneratorLensTemplates': value['fuelConsumptionByGeneratorLensTemplates'] == null ? undefined : ((value['fuelConsumptionByGeneratorLensTemplates'] as Array<any>).map(FuelConsumptionByGeneratorLensTemplateDtoToJSON)),
        'DieselNatGasRatioStatsLensTemplates': value['dieselNatGasRatioStatsLensTemplates'] == null ? undefined : ((value['dieselNatGasRatioStatsLensTemplates'] as Array<any>).map(DieselNatGasRatioStatsLensTemplateDtoToJSON)),
        'RigFleetPerformanceCardLensTemplates': value['rigFleetPerformanceCardLensTemplates'] == null ? undefined : ((value['rigFleetPerformanceCardLensTemplates'] as Array<any>).map(RigFleetPerformanceCardLensTemplateDtoToJSON)),
        'RigScorecardLensTemplates': value['rigScorecardLensTemplates'] == null ? undefined : ((value['rigScorecardLensTemplates'] as Array<any>).map(RigScorecardLensTemplateDtoToJSON)),
        'TagBottomFingerprintLensTemplates': value['tagBottomFingerprintLensTemplates'] == null ? undefined : ((value['tagBottomFingerprintLensTemplates'] as Array<any>).map(TagBottomFingerprintLensTemplateDtoToJSON)),
        'StickSlipLensTemplates': value['stickSlipLensTemplates'] == null ? undefined : ((value['stickSlipLensTemplates'] as Array<any>).map(StickSlipLensTemplateDtoToJSON)),
    };
}

