/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RigLeaderboardScoreKpiAco
 */
export interface RigLeaderboardScoreKpiAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreKpiAco
     */
    kpiName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreKpiAco
     */
    kpiDescription: string;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardScoreKpiAco
     */
    timeVsTargetHrs: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardScoreKpiAco
     */
    kpiScorePercentage: number | null;
}

/**
 * Check if a given object implements the RigLeaderboardScoreKpiAco interface.
 */
export function instanceOfRigLeaderboardScoreKpiAco(value: object): value is RigLeaderboardScoreKpiAco {
    if (!('kpiName' in value) || value['kpiName'] === undefined) return false;
    if (!('kpiDescription' in value) || value['kpiDescription'] === undefined) return false;
    if (!('timeVsTargetHrs' in value) || value['timeVsTargetHrs'] === undefined) return false;
    if (!('kpiScorePercentage' in value) || value['kpiScorePercentage'] === undefined) return false;
    return true;
}

export function RigLeaderboardScoreKpiAcoFromJSON(json: any): RigLeaderboardScoreKpiAco {
    return RigLeaderboardScoreKpiAcoFromJSONTyped(json, false);
}

export function RigLeaderboardScoreKpiAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardScoreKpiAco {
    if (json == null) {
        return json;
    }
    return {
        
        'kpiName': json['KpiName'],
        'kpiDescription': json['KpiDescription'],
        'timeVsTargetHrs': json['TimeVsTargetHrs'],
        'kpiScorePercentage': json['KpiScorePercentage'],
    };
}

export function RigLeaderboardScoreKpiAcoToJSON(value?: RigLeaderboardScoreKpiAco | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'KpiName': value['kpiName'],
        'KpiDescription': value['kpiDescription'],
        'TimeVsTargetHrs': value['timeVsTargetHrs'],
        'KpiScorePercentage': value['kpiScorePercentage'],
    };
}

