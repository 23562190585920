/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LensTabStateType } from './LensTabStateType';
import {
    LensTabStateTypeFromJSON,
    LensTabStateTypeFromJSONTyped,
    LensTabStateTypeToJSON,
} from './LensTabStateType';
import type { OperationCategoryType } from './OperationCategoryType';
import {
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';
import type { ProfileOriginType } from './ProfileOriginType';
import {
    ProfileOriginTypeFromJSON,
    ProfileOriginTypeFromJSONTyped,
    ProfileOriginTypeToJSON,
} from './ProfileOriginType';
import type { DashboardType } from './DashboardType';
import {
    DashboardTypeFromJSON,
    DashboardTypeFromJSONTyped,
    DashboardTypeToJSON,
} from './DashboardType';

/**
 * 
 * @export
 * @interface LensTabWithVisibilityDto
 */
export interface LensTabWithVisibilityDto {
    /**
     * 
     * @type {number}
     * @memberof LensTabWithVisibilityDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LensTabWithVisibilityDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LensTabWithVisibilityDto
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LensTabWithVisibilityDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LensTabWithVisibilityDto
     */
    isLocked: boolean;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof LensTabWithVisibilityDto
     */
    operationCategoryType: OperationCategoryType;
    /**
     * 
     * @type {ProfileOriginType}
     * @memberof LensTabWithVisibilityDto
     */
    origin: ProfileOriginType;
    /**
     * 
     * @type {DashboardType}
     * @memberof LensTabWithVisibilityDto
     */
    dashboardType: DashboardType;
    /**
     * 
     * @type {number}
     * @memberof LensTabWithVisibilityDto
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof LensTabWithVisibilityDto
     */
    group?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LensTabWithVisibilityDto
     */
    profileId: number;
    /**
     * 
     * @type {LensTabStateType}
     * @memberof LensTabWithVisibilityDto
     */
    state: LensTabStateType;
    /**
     * 
     * @type {Date}
     * @memberof LensTabWithVisibilityDto
     */
    sharedAtUtc?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof LensTabWithVisibilityDto
     */
    sharedById?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LensTabWithVisibilityDto
     */
    rigIds?: Array<number> | null;
}



/**
 * Check if a given object implements the LensTabWithVisibilityDto interface.
 */
export function instanceOfLensTabWithVisibilityDto(value: object): value is LensTabWithVisibilityDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('isLocked' in value) || value['isLocked'] === undefined) return false;
    if (!('operationCategoryType' in value) || value['operationCategoryType'] === undefined) return false;
    if (!('origin' in value) || value['origin'] === undefined) return false;
    if (!('dashboardType' in value) || value['dashboardType'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('profileId' in value) || value['profileId'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    return true;
}

export function LensTabWithVisibilityDtoFromJSON(json: any): LensTabWithVisibilityDto {
    return LensTabWithVisibilityDtoFromJSONTyped(json, false);
}

export function LensTabWithVisibilityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LensTabWithVisibilityDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'shortName': json['ShortName'] == null ? undefined : json['ShortName'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'isLocked': json['IsLocked'],
        'operationCategoryType': OperationCategoryTypeFromJSON(json['OperationCategoryType']),
        'origin': ProfileOriginTypeFromJSON(json['Origin']),
        'dashboardType': DashboardTypeFromJSON(json['DashboardType']),
        'position': json['Position'],
        'group': json['Group'] == null ? undefined : json['Group'],
        'profileId': json['ProfileId'],
        'state': LensTabStateTypeFromJSON(json['State']),
        'sharedAtUtc': json['SharedAtUtc'] == null ? undefined : (new Date(json['SharedAtUtc'])),
        'sharedById': json['SharedById'] == null ? undefined : json['SharedById'],
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
    };
}

export function LensTabWithVisibilityDtoToJSON(value?: LensTabWithVisibilityDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'ShortName': value['shortName'],
        'Description': value['description'],
        'IsLocked': value['isLocked'],
        'OperationCategoryType': OperationCategoryTypeToJSON(value['operationCategoryType']),
        'Origin': ProfileOriginTypeToJSON(value['origin']),
        'DashboardType': DashboardTypeToJSON(value['dashboardType']),
        'Position': value['position'],
        'Group': value['group'],
        'ProfileId': value['profileId'],
        'State': LensTabStateTypeToJSON(value['state']),
        'SharedAtUtc': value['sharedAtUtc'] == null ? undefined : ((value['sharedAtUtc'] as any).toISOString()),
        'SharedById': value['sharedById'],
        'RigIds': value['rigIds'],
    };
}

