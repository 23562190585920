import type { DateDto } from "apis/oag";
import { WellStatusType } from "apis/oag";
import { today } from "utils/helper";

import type { IFilterOptions } from "./rigsCommonReducer";
import type { IAction } from "./types";

interface IAllWellsState {
  search: string;
  operators: number[] | null;
  rigIds: number[] | null;
  status: WellStatusType[];
  startDate: Date | null;
  endDate: Date | null;
  exclusions: number[];
  lastAllWellRefreshDate: DateDto | null;
  formationIds: number[] | null;
  filters: IFilterOptions | null;
}

const initialState: IAllWellsState = {
  search: "",
  operators: null,
  rigIds: null,
  status: Object.keys(WellStatusType) as WellStatusType[],
  lastAllWellRefreshDate: null,
  startDate: null,
  endDate: today,
  exclusions: [],
  formationIds: null,
  filters: null,
};

interface ISetFiltersAction extends IAction {
  type: "EVERGREEN_WELLS_SET_FILTERS";
  payload: IAllWellsState["filters"];
}
interface ISetSearchAction extends IAction {
  type: "EVERGREEN_WELLS_SET_SEARCH";
  payload: {
    search: IAllWellsState["search"];
  };
}

interface ISetOperatorsAction extends IAction {
  type: "EVERGREEN_WELLS_SET_OPERATORS";
  payload: {
    operators: IAllWellsState["operators"];
  };
}

interface ISetRigsAction extends IAction {
  type: "EVERGREEN_WELLS_SET_RIGS";
  payload: {
    rigIds: IAllWellsState["rigIds"];
  };
}

interface ISetStatusesAction extends IAction {
  type: "EVERGREEN_WELLS_SET_STATUSES";
  payload: {
    status: IAllWellsState["status"];
  };
}

interface ISetFormationsAction extends IAction {
  type: "EVERGREEN_WELLS_FORMATIONS_IDS";
  payload: IAllWellsState["formationIds"];
}

interface ISetTimeRangeAction extends IAction {
  type: "EVERGREEN_WELLS_SET_TIMERANGE";
  payload: {
    startDate: IAllWellsState["startDate"];
    endDate: IAllWellsState["endDate"];
  };
}

interface IExclusionAddAction extends IAction {
  type: "EVERGREEN_WELLS_EXCLUSION_ADD";
  payload: {
    well: number;
  };
}

interface IExclusionRemoveAction extends IAction {
  type: "EVERGREEN_WELLS_EXCLUSION_REMOVE";
  payload: {
    well: number;
  };
}
interface ILastUpdateDateAction extends IAction {
  type: "EVERGREEN_WELLS_SET_LAST_UPDATE_DATE";
  payload: IAllWellsState["lastAllWellRefreshDate"];
}

interface IResetDefaultAction extends IAction {
  type: "RESET_EVERGREEN_ALL_WELLS";
}

type AvailableActions =
  | ISetFiltersAction
  | ISetSearchAction
  | ISetOperatorsAction
  | ISetRigsAction
  | ISetStatusesAction
  | ISetTimeRangeAction
  | IExclusionAddAction
  | IExclusionRemoveAction
  | IResetDefaultAction
  | ILastUpdateDateAction
  | ISetFormationsAction;

function allWellsReducer(state: IAllWellsState = initialState, action: AvailableActions): IAllWellsState {
  switch (action.type) {
    case "EVERGREEN_WELLS_SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "EVERGREEN_WELLS_SET_LAST_UPDATE_DATE":
      return {
        ...state,
        lastAllWellRefreshDate: action.payload,
      };
    case "EVERGREEN_WELLS_SET_SEARCH":
      return {
        ...state,
        search: action.payload.search,
      };
    case "EVERGREEN_WELLS_SET_OPERATORS":
      return {
        ...state,
        operators: action.payload.operators,
      };
    case "EVERGREEN_WELLS_SET_RIGS":
      return {
        ...state,
        rigIds: action.payload.rigIds,
      };
    case "EVERGREEN_WELLS_SET_STATUSES":
      return {
        ...state,
        status: action.payload.status,
      };
    case "EVERGREEN_WELLS_SET_TIMERANGE":
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case "EVERGREEN_WELLS_EXCLUSION_ADD":
      return {
        ...state,
        exclusions: [...state.exclusions, action.payload.well],
      };
    case "EVERGREEN_WELLS_EXCLUSION_REMOVE":
      return {
        ...state,
        exclusions: state.exclusions.filter((e) => e !== action.payload.well),
      };
    case "EVERGREEN_WELLS_FORMATIONS_IDS":
      return {
        ...state,
        formationIds: action.payload,
      };
    case "RESET_EVERGREEN_ALL_WELLS":
      return initialState;
    default:
      return state;
  }
}

export default allWellsReducer;
