import styled from "styled-components";
import colors from "utils/colors";
import { Button } from "utils/componentLibrary";

export const ListNoteExceptionStatus = styled.div`
  white-space: wrap;
  margin-top: 8px;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 1.75fr;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  font-size: 12px;
  font-weight: 500;
  column-gap: 12px;
`;

export const ApprovalStatus = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 4px;
  font-weight: 400;
`;

export const ApprovalActions = styled.div`
  display: inline-flex;
  column-gap: 4px;
`;

export const StatusText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 4px;
  overflow: hidden;
`;

export const ApproveButton = styled(Button)`
  font-size: 14px;
  color: ${colors.white} !important;
  padding-left: 12px;
  padding-right: 12px;
  border-width: 1px;
`;

export const RejectButton = styled(ApproveButton)`
  background-color: ${colors.coral_expression};
  border: none !important;
  outline: none !important;

  &:hover {
    background-color: ${colors.coral_expression} !important;
  }
`;

export const NoWrap = styled.div`
  white-space: nowrap;
`;

export const OverflowEllipsis = styled(NoWrap)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
