/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ZTorqueOnOffPair } from './ZTorqueOnOffPair';
import {
    ZTorqueOnOffPairFromJSON,
    ZTorqueOnOffPairFromJSONTyped,
    ZTorqueOnOffPairToJSON,
} from './ZTorqueOnOffPair';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';

/**
 * 
 * @export
 * @interface ZTorqueSummaryDto
 */
export interface ZTorqueSummaryDto {
    /**
     * 
     * @type {ResultDataState}
     * @memberof ZTorqueSummaryDto
     */
    dataState: ResultDataState;
    /**
     * 
     * @type {number}
     * @memberof ZTorqueSummaryDto
     */
    wellId: number;
    /**
     * 
     * @type {ZTorqueOnOffPair}
     * @memberof ZTorqueSummaryDto
     */
    onOffRatio: ZTorqueOnOffPair;
    /**
     * 
     * @type {ZTorqueOnOffPair}
     * @memberof ZTorqueSummaryDto
     */
    averageTorqueStdDev: ZTorqueOnOffPair;
    /**
     * 
     * @type {ZTorqueOnOffPair}
     * @memberof ZTorqueSummaryDto
     */
    averageRateOfPenetration: ZTorqueOnOffPair;
    /**
     * 
     * @type {ZTorqueOnOffPair}
     * @memberof ZTorqueSummaryDto
     */
    averageSssi: ZTorqueOnOffPair;
}



/**
 * Check if a given object implements the ZTorqueSummaryDto interface.
 */
export function instanceOfZTorqueSummaryDto(value: object): value is ZTorqueSummaryDto {
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('onOffRatio' in value) || value['onOffRatio'] === undefined) return false;
    if (!('averageTorqueStdDev' in value) || value['averageTorqueStdDev'] === undefined) return false;
    if (!('averageRateOfPenetration' in value) || value['averageRateOfPenetration'] === undefined) return false;
    if (!('averageSssi' in value) || value['averageSssi'] === undefined) return false;
    return true;
}

export function ZTorqueSummaryDtoFromJSON(json: any): ZTorqueSummaryDto {
    return ZTorqueSummaryDtoFromJSONTyped(json, false);
}

export function ZTorqueSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZTorqueSummaryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dataState': ResultDataStateFromJSON(json['DataState']),
        'wellId': json['WellId'],
        'onOffRatio': ZTorqueOnOffPairFromJSON(json['OnOffRatio']),
        'averageTorqueStdDev': ZTorqueOnOffPairFromJSON(json['AverageTorqueStdDev']),
        'averageRateOfPenetration': ZTorqueOnOffPairFromJSON(json['AverageRateOfPenetration']),
        'averageSssi': ZTorqueOnOffPairFromJSON(json['AverageSssi']),
    };
}

export function ZTorqueSummaryDtoToJSON(value?: ZTorqueSummaryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'DataState': ResultDataStateToJSON(value['dataState']),
        'WellId': value['wellId'],
        'OnOffRatio': ZTorqueOnOffPairToJSON(value['onOffRatio']),
        'AverageTorqueStdDev': ZTorqueOnOffPairToJSON(value['averageTorqueStdDev']),
        'AverageRateOfPenetration': ZTorqueOnOffPairToJSON(value['averageRateOfPenetration']),
        'AverageSssi': ZTorqueOnOffPairToJSON(value['averageSssi']),
    };
}

