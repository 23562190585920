import { createContext, useContext } from "react";

export const MouseInContext = createContext<
  { mouseIn: boolean; setMouseIn: React.Dispatch<React.SetStateAction<boolean>> } | undefined
>(undefined);

export function useLensMouseIn() {
  const mouseInState = useContext(MouseInContext);

  if (mouseInState === undefined) {
    throw new Error("useLensMouseIn must be within context of MouseInContext.");
  }

  return mouseInState;
}
