/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigLeaderboardScoreKpiAco } from './RigLeaderboardScoreKpiAco';
import {
    RigLeaderboardScoreKpiAcoFromJSON,
    RigLeaderboardScoreKpiAcoFromJSONTyped,
    RigLeaderboardScoreKpiAcoToJSON,
} from './RigLeaderboardScoreKpiAco';

/**
 * 
 * @export
 * @interface RigLeaderboardScoreSubgroupAco
 */
export interface RigLeaderboardScoreSubgroupAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreSubgroupAco
     */
    kpiSubgroupName: string;
    /**
     * 
     * @type {Array<RigLeaderboardScoreKpiAco>}
     * @memberof RigLeaderboardScoreSubgroupAco
     */
    kpis: Array<RigLeaderboardScoreKpiAco>;
}

/**
 * Check if a given object implements the RigLeaderboardScoreSubgroupAco interface.
 */
export function instanceOfRigLeaderboardScoreSubgroupAco(value: object): value is RigLeaderboardScoreSubgroupAco {
    if (!('kpiSubgroupName' in value) || value['kpiSubgroupName'] === undefined) return false;
    if (!('kpis' in value) || value['kpis'] === undefined) return false;
    return true;
}

export function RigLeaderboardScoreSubgroupAcoFromJSON(json: any): RigLeaderboardScoreSubgroupAco {
    return RigLeaderboardScoreSubgroupAcoFromJSONTyped(json, false);
}

export function RigLeaderboardScoreSubgroupAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardScoreSubgroupAco {
    if (json == null) {
        return json;
    }
    return {
        
        'kpiSubgroupName': json['KpiSubgroupName'],
        'kpis': ((json['Kpis'] as Array<any>).map(RigLeaderboardScoreKpiAcoFromJSON)),
    };
}

export function RigLeaderboardScoreSubgroupAcoToJSON(value?: RigLeaderboardScoreSubgroupAco | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'KpiSubgroupName': value['kpiSubgroupName'],
        'Kpis': ((value['kpis'] as Array<any>).map(RigLeaderboardScoreKpiAcoToJSON)),
    };
}

