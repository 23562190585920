/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScorecardExceptionStatusType } from './ScorecardExceptionStatusType';
import {
    ScorecardExceptionStatusTypeFromJSON,
    ScorecardExceptionStatusTypeFromJSONTyped,
    ScorecardExceptionStatusTypeToJSON,
} from './ScorecardExceptionStatusType';

/**
 * 
 * @export
 * @interface RigLeaderboardScoreCardCommentAco
 */
export interface RigLeaderboardScoreCardCommentAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    authorDisplayName: string | null;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    exceptionApproverName: string | null;
    /**
     * 
     * @type {ScorecardExceptionStatusType}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    exceptionStatus: ScorecardExceptionStatusType;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    exceptionTimeHrs: number | null;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    kpiGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    kpiSubgroupName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    kpiName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    kpiDescription: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    wellName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    curatedUidWellEdr: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    commentDateTime: Date;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    factScorecardCommentsKey: number;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    primaryCategoryClassification: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardScoreCardCommentAco
     */
    secondaryCategoryClassification: string;
}



/**
 * Check if a given object implements the RigLeaderboardScoreCardCommentAco interface.
 */
export function instanceOfRigLeaderboardScoreCardCommentAco(value: object): value is RigLeaderboardScoreCardCommentAco {
    if (!('authorDisplayName' in value) || value['authorDisplayName'] === undefined) return false;
    if (!('exceptionApproverName' in value) || value['exceptionApproverName'] === undefined) return false;
    if (!('exceptionStatus' in value) || value['exceptionStatus'] === undefined) return false;
    if (!('exceptionTimeHrs' in value) || value['exceptionTimeHrs'] === undefined) return false;
    if (!('kpiGroupName' in value) || value['kpiGroupName'] === undefined) return false;
    if (!('kpiSubgroupName' in value) || value['kpiSubgroupName'] === undefined) return false;
    if (!('kpiName' in value) || value['kpiName'] === undefined) return false;
    if (!('kpiDescription' in value) || value['kpiDescription'] === undefined) return false;
    if (!('wellName' in value) || value['wellName'] === undefined) return false;
    if (!('curatedUidWellEdr' in value) || value['curatedUidWellEdr'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('commentDateTime' in value) || value['commentDateTime'] === undefined) return false;
    if (!('factScorecardCommentsKey' in value) || value['factScorecardCommentsKey'] === undefined) return false;
    if (!('primaryCategoryClassification' in value) || value['primaryCategoryClassification'] === undefined) return false;
    if (!('secondaryCategoryClassification' in value) || value['secondaryCategoryClassification'] === undefined) return false;
    return true;
}

export function RigLeaderboardScoreCardCommentAcoFromJSON(json: any): RigLeaderboardScoreCardCommentAco {
    return RigLeaderboardScoreCardCommentAcoFromJSONTyped(json, false);
}

export function RigLeaderboardScoreCardCommentAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardScoreCardCommentAco {
    if (json == null) {
        return json;
    }
    return {
        
        'authorDisplayName': json['AuthorDisplayName'],
        'exceptionApproverName': json['ExceptionApproverName'],
        'exceptionStatus': ScorecardExceptionStatusTypeFromJSON(json['ExceptionStatus']),
        'exceptionTimeHrs': json['ExceptionTimeHrs'],
        'kpiGroupName': json['KpiGroupName'],
        'kpiSubgroupName': json['KpiSubgroupName'],
        'kpiName': json['KpiName'],
        'kpiDescription': json['KpiDescription'],
        'wellName': json['WellName'],
        'curatedUidWellEdr': json['CuratedUidWellEdr'],
        'description': json['Description'],
        'commentDateTime': (new Date(json['CommentDateTime'])),
        'factScorecardCommentsKey': json['FactScorecardCommentsKey'],
        'primaryCategoryClassification': json['PrimaryCategoryClassification'],
        'secondaryCategoryClassification': json['SecondaryCategoryClassification'],
    };
}

export function RigLeaderboardScoreCardCommentAcoToJSON(value?: RigLeaderboardScoreCardCommentAco | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'AuthorDisplayName': value['authorDisplayName'],
        'ExceptionApproverName': value['exceptionApproverName'],
        'ExceptionStatus': ScorecardExceptionStatusTypeToJSON(value['exceptionStatus']),
        'ExceptionTimeHrs': value['exceptionTimeHrs'],
        'KpiGroupName': value['kpiGroupName'],
        'KpiSubgroupName': value['kpiSubgroupName'],
        'KpiName': value['kpiName'],
        'KpiDescription': value['kpiDescription'],
        'WellName': value['wellName'],
        'CuratedUidWellEdr': value['curatedUidWellEdr'],
        'Description': value['description'],
        'CommentDateTime': ((value['commentDateTime']).toISOString()),
        'FactScorecardCommentsKey': value['factScorecardCommentsKey'],
        'PrimaryCategoryClassification': value['primaryCategoryClassification'],
        'SecondaryCategoryClassification': value['secondaryCategoryClassification'],
    };
}

