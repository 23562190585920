/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigLeaderboardScoreSummaryAco } from './RigLeaderboardScoreSummaryAco';
import {
    RigLeaderboardScoreSummaryAcoFromJSON,
    RigLeaderboardScoreSummaryAcoFromJSONTyped,
    RigLeaderboardScoreSummaryAcoToJSON,
} from './RigLeaderboardScoreSummaryAco';
import type { RigLeaderboardScoreCardCommentAco } from './RigLeaderboardScoreCardCommentAco';
import {
    RigLeaderboardScoreCardCommentAcoFromJSON,
    RigLeaderboardScoreCardCommentAcoFromJSONTyped,
    RigLeaderboardScoreCardCommentAcoToJSON,
} from './RigLeaderboardScoreCardCommentAco';
import type { RigLeaderboardWellAco } from './RigLeaderboardWellAco';
import {
    RigLeaderboardWellAcoFromJSON,
    RigLeaderboardWellAcoFromJSONTyped,
    RigLeaderboardWellAcoToJSON,
} from './RigLeaderboardWellAco';

/**
 * 
 * @export
 * @interface RigLeaderboardAco
 */
export interface RigLeaderboardAco {
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    rigName: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    rigType: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    horsepowerRating: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    operatingCenter: string;
    /**
     * 
     * @type {string}
     * @memberof RigLeaderboardAco
     */
    technology: string;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    overallRigScorePercentage: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    rigTrendPercentage: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    timeVsTargetHrs: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    timeVsBaseHrs: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    rigDowntimePercentage: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    totalExceptionCount: number;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    totalExceptionTimeHrs: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigLeaderboardAco
     */
    scorecardTasksPercentage: number | null;
    /**
     * 
     * @type {RigLeaderboardScoreSummaryAco}
     * @memberof RigLeaderboardAco
     */
    scoreSummary: RigLeaderboardScoreSummaryAco;
    /**
     * 
     * @type {Array<RigLeaderboardWellAco>}
     * @memberof RigLeaderboardAco
     */
    wells: Array<RigLeaderboardWellAco>;
    /**
     * 
     * @type {Array<RigLeaderboardScoreCardCommentAco>}
     * @memberof RigLeaderboardAco
     */
    scorecardComments: Array<RigLeaderboardScoreCardCommentAco>;
}

/**
 * Check if a given object implements the RigLeaderboardAco interface.
 */
export function instanceOfRigLeaderboardAco(value: object): value is RigLeaderboardAco {
    if (!('rigName' in value) || value['rigName'] === undefined) return false;
    if (!('rigType' in value) || value['rigType'] === undefined) return false;
    if (!('horsepowerRating' in value) || value['horsepowerRating'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('operatingCenter' in value) || value['operatingCenter'] === undefined) return false;
    if (!('technology' in value) || value['technology'] === undefined) return false;
    if (!('overallRigScorePercentage' in value) || value['overallRigScorePercentage'] === undefined) return false;
    if (!('rigTrendPercentage' in value) || value['rigTrendPercentage'] === undefined) return false;
    if (!('timeVsTargetHrs' in value) || value['timeVsTargetHrs'] === undefined) return false;
    if (!('timeVsBaseHrs' in value) || value['timeVsBaseHrs'] === undefined) return false;
    if (!('rigDowntimePercentage' in value) || value['rigDowntimePercentage'] === undefined) return false;
    if (!('totalExceptionCount' in value) || value['totalExceptionCount'] === undefined) return false;
    if (!('totalExceptionTimeHrs' in value) || value['totalExceptionTimeHrs'] === undefined) return false;
    if (!('scorecardTasksPercentage' in value) || value['scorecardTasksPercentage'] === undefined) return false;
    if (!('scoreSummary' in value) || value['scoreSummary'] === undefined) return false;
    if (!('wells' in value) || value['wells'] === undefined) return false;
    if (!('scorecardComments' in value) || value['scorecardComments'] === undefined) return false;
    return true;
}

export function RigLeaderboardAcoFromJSON(json: any): RigLeaderboardAco {
    return RigLeaderboardAcoFromJSONTyped(json, false);
}

export function RigLeaderboardAcoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigLeaderboardAco {
    if (json == null) {
        return json;
    }
    return {
        
        'rigName': json['RigName'],
        'rigType': json['RigType'],
        'horsepowerRating': json['HorsepowerRating'],
        'country': json['Country'],
        'operatingCenter': json['OperatingCenter'],
        'technology': json['Technology'],
        'overallRigScorePercentage': json['OverallRigScorePercentage'],
        'rigTrendPercentage': json['RigTrendPercentage'],
        'timeVsTargetHrs': json['TimeVsTargetHrs'],
        'timeVsBaseHrs': json['TimeVsBaseHrs'],
        'rigDowntimePercentage': json['RigDowntimePercentage'],
        'totalExceptionCount': json['TotalExceptionCount'],
        'totalExceptionTimeHrs': json['TotalExceptionTimeHrs'],
        'scorecardTasksPercentage': json['ScorecardTasksPercentage'],
        'scoreSummary': RigLeaderboardScoreSummaryAcoFromJSON(json['ScoreSummary']),
        'wells': ((json['Wells'] as Array<any>).map(RigLeaderboardWellAcoFromJSON)),
        'scorecardComments': ((json['ScorecardComments'] as Array<any>).map(RigLeaderboardScoreCardCommentAcoFromJSON)),
    };
}

export function RigLeaderboardAcoToJSON(value?: RigLeaderboardAco | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'RigName': value['rigName'],
        'RigType': value['rigType'],
        'HorsepowerRating': value['horsepowerRating'],
        'Country': value['country'],
        'OperatingCenter': value['operatingCenter'],
        'Technology': value['technology'],
        'OverallRigScorePercentage': value['overallRigScorePercentage'],
        'RigTrendPercentage': value['rigTrendPercentage'],
        'TimeVsTargetHrs': value['timeVsTargetHrs'],
        'TimeVsBaseHrs': value['timeVsBaseHrs'],
        'RigDowntimePercentage': value['rigDowntimePercentage'],
        'TotalExceptionCount': value['totalExceptionCount'],
        'TotalExceptionTimeHrs': value['totalExceptionTimeHrs'],
        'ScorecardTasksPercentage': value['scorecardTasksPercentage'],
        'ScoreSummary': RigLeaderboardScoreSummaryAcoToJSON(value['scoreSummary']),
        'Wells': ((value['wells'] as Array<any>).map(RigLeaderboardWellAcoToJSON)),
        'ScorecardComments': ((value['scorecardComments'] as Array<any>).map(RigLeaderboardScoreCardCommentAcoToJSON)),
    };
}

