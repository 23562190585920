import type { GeneratorSlotType } from "apis/oag";
import { generatorLabelsBySlotType } from "components/Lenses/ContainerLens/common/utils/utils";
import { allGenerators } from "components/Lenses/utils";
import { PDComponent } from "components/PDComponents";
import { useActiveGenerators } from "hooks/useActiveGenerators";
import { useSelectedWell } from "hooks/useSelectedWell";
import type { Dict } from "mixpanel-browser";
import { useMemo } from "react";

import { TEXT_ALL_GENERATORS } from "./utils";

export default function GeneratorSelector({
  selectedGenerators = allGenerators,
  setGeneratorMenuVisible,
  handleGeneratorUpdate,
  trackingText,
  isLocked,
  trackingData,
}: {
  selectedGenerators: GeneratorSlotType[];
  setGeneratorMenuVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  handleGeneratorUpdate: (generators: GeneratorSlotType[]) => Promise<void>;
  trackingText: string;
  trackingData: Dict;
  isLocked: boolean;
}) {
  const wellId = useSelectedWell();
  const { data: activeGenerators } = useActiveGenerators(wellId);

  const generatorOptions = useMemo(() => {
    return [
      {
        value: allGenerators,
        label: TEXT_ALL_GENERATORS,
      },
      ...(activeGenerators || []).map((generator) => ({
        value: [generator],
        label: generatorLabelsBySlotType[generator],
      })),
    ];
  }, [activeGenerators]);

  return (
    <PDComponent.Dropdown
      handleOptionUpdate={handleGeneratorUpdate}
      isLocked={isLocked}
      options={generatorOptions}
      selectedOption={selectedGenerators}
      onMenuVisibilityChange={(isVisible) => setGeneratorMenuVisible?.(isVisible)}
      trackingText={trackingText}
      trackingData={trackingData}
    />
  );
}
