import { convertArea, convertLength } from "@turf/turf";
import type mapboxgl from "mapbox-gl";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import { useAppSelector } from "reducers/store";
import { IUnitSystem } from "reducers/types";

export enum AreaSelectionType {
  None = "None",
  Basin = "Basin",
  Circle = "Circle",
  Polygon = "Polygon",
}
interface FilteredWellsContext {
  mapBoxInstance: mapboxgl.Map | undefined;
  areaSelectionType: AreaSelectionType;
  setAreaSelectionType: Dispatch<SetStateAction<AreaSelectionType>>;
  handleOnCircleAreaPress?: () => void;
  handleOnPolygonAreaPress?: () => void;
  handleOnTrashPress?: () => void;
  registerOnCircleAreaPress: (cb: () => void) => any;
  registerOnPolygonAreaPress: (cb: () => void) => any;
  registerOnTrashPress: (cb: () => void) => any;
  areaText: string;
  area: number;
  radius: number;
  setArea: Dispatch<SetStateAction<number>>;
  setRadius: Dispatch<SetStateAction<number>>;
  radiusMaxBound: number;
  basinName: string;
  setBasinName: Dispatch<SetStateAction<string>>;
  setMapBoxInstance: (map: mapboxgl.Map) => void;
  setCenter: (location: [number, number]) => void;
}

export const WellsMapViewContext = createContext<FilteredWellsContext | undefined>(undefined);

export const useWellsMapViewContext = () => {
  const context = useContext(WellsMapViewContext);
  if (!context) {
    throw new Error("useWellsMapViewContext must be used within a WellsMapViewProvider");
  }
  return context;
};

export const INITIAL_MAP_ZOOM = 3;
export const MAX_CIRCLE_RADIUS_METERS = 1000000;
const DEFAULT_ZOOM = 11.5;

export const WellsMapViewProvider = ({ children }: { children: React.ReactNode }) => {
  const currentUom = useAppSelector((state) => state.global.unit);
  const [mapBoxInstance, setMapBoxInstance] = useState<FilteredWellsContext["mapBoxInstance"] | undefined>(undefined);
  const [areaSelectionType, setAreaSelectionType] = useState(AreaSelectionType.None);
  const [area, setArea] = useState(0);
  const [radius, setRadius] = useState(0);
  const [basinName, setBasinName] = useState("");

  const radiusInCurrentUnit = useMemo(() => {
    return currentUom === IUnitSystem.METRIC
      ? +convertLength(radius, "meters", "kilometers").toFixed(0)
      : +convertLength(radius, "meters", "miles").toFixed(0);
  }, [currentUom, radius]);

  const circlePressCbRef = useRef<() => void>();
  const polygonPressCbRef = useRef<() => void>();
  const trashPressCbRef = useRef<() => void>();

  const registerOnCircleAreaPress = useCallback((cb: () => void) => (circlePressCbRef.current = cb), []);
  const registerOnPolygonAreaPress = useCallback((cb: () => void) => (polygonPressCbRef.current = cb), []);
  const registerOnTrashPress = useCallback((cb: () => void) => (trashPressCbRef.current = cb), []);

  const radiusMaxBound = useMemo(() => {
    return currentUom === IUnitSystem.METRIC
      ? +convertLength(MAX_CIRCLE_RADIUS_METERS, "meters", "kilometers").toFixed(0)
      : +convertLength(MAX_CIRCLE_RADIUS_METERS, "meters", "miles").toFixed(0);
  }, [currentUom]);

  const setCenter = useCallback(
    (location: [number, number]) => {
      if (mapBoxInstance && location && location.length && !mapBoxInstance.isMoving()) {
        mapBoxInstance.flyTo({
          center: location,
          zoom: DEFAULT_ZOOM,
          duration: 3000,
        });
      }
    },
    [mapBoxInstance],
  );

  const areaText = useMemo(() => {
    if (areaSelectionType === AreaSelectionType.Polygon) {
      return currentUom === IUnitSystem.METRIC
        ? convertArea(area, "meters", "kilometers").toLocaleString("en-US", { maximumFractionDigits: 0 }) + " Km²"
        : convertArea(area, "meters", "miles").toLocaleString("en-US", { maximumFractionDigits: 0 }) + " Mi²";
    } else if (areaSelectionType === AreaSelectionType.Circle) {
      // TODO this is not used
      return currentUom === IUnitSystem.METRIC
        ? convertLength(radius, "meters", "kilometers").toLocaleString("en-US", { maximumFractionDigits: 0 }) +
            " Km Range"
        : convertLength(radius, "meters", "miles").toLocaleString("en-US", { maximumFractionDigits: 0 }) +
            " Miles Range";
    } else return null;
  }, [area, areaSelectionType, currentUom, radius]);

  return (
    <WellsMapViewContext.Provider
      value={{
        mapBoxInstance,
        setMapBoxInstance,
        setCenter,
        areaSelectionType,
        setAreaSelectionType,
        areaText: areaText || "",
        setArea,
        area,
        setRadius,
        radiusMaxBound,
        radius: radiusInCurrentUnit,
        registerOnCircleAreaPress,
        registerOnPolygonAreaPress,
        registerOnTrashPress,
        basinName,
        setBasinName,
        handleOnCircleAreaPress: circlePressCbRef.current,
        handleOnPolygonAreaPress: polygonPressCbRef.current,
        handleOnTrashPress: trashPressCbRef.current,
      }}
    >
      {children}
    </WellsMapViewContext.Provider>
  );
};
