import { WellStatusType } from "apis/oag";
import { CustomSwitch } from "atoms/common";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { getTitleRealTimeIndicator } from "components/DetailsTopBar/utils";
import { AllFilters } from "components/Filters/AllFilters";
import { FilterTags } from "components/Filters/FilterTags";
import { useFiltersCategories } from "components/Filters/useFiltersCategories";
import { PDComponent } from "components/PDComponents";
import { RealTimeDataEnum, RealTimeIndicator } from "components/RealTimeIndicator";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useFilterTags } from "hooks/useFilterTags";
import { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import type { IFilterOptions, IFilterOptionValue } from "reducers/rigsCommonReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { routePaths } from "routePaths";
import { Track } from "services/Mixpanel";
import colors from "utils/colors";
import { Col, Space, Tooltip } from "utils/componentLibrary";
import { stripSlashes } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import { LinkedSelectors } from "./LinkedSelectors";
import * as Styled from "./styled";
import { StyledTitle } from "./styled";
import { useLinkedFilters } from "./useLinkedFilters";

const isAnyLoading = (isLoading: boolean[]) => isLoading.some((loading) => loading);

function Header({
  filters,
  setFilters,
  totalRigs,
}: Readonly<{
  totalRigs: number;
  filters: IFilterOptions | null;
  setFilters: (localFilters: IFilterOptions | null) => void;
}>) {
  const dispatch = useAppDispatch();

  const { data: currentUser } = useCurrentUser();

  const realTimeDataState = useAppSelector((state) => state.global.realTimeData);
  const periodState = useAppSelector((state) => state.rigsCommon.period);
  const allWellsIncluded = useAppSelector((state) => state.rigsCommon.allWellsIncluded);

  const exceptions = useAppSelector((state) => state.rigsCommon.exceptions);

  const { initialFilters } = useFiltersCategories(filters, setFilters);

  const {
    updateFilters,
    selectedValues,
    options,
    isLoading: isLoadingObject,
  } = useLinkedFilters({
    keepEvengreenOnly: false,
    storeName: "rigsCommon",
  });
  const isLoading = isAnyLoading(Object.values(isLoadingObject));
  const tags = useFilterTags({
    updateFilters,
    isLoadingArr: Object.values(isLoadingObject),
    filters,
    setFilters,
    selectedValues,
    options,
  });

  const updatePeriod = useCallback(
    (period: RangeType) => {
      Track.interact("Rigs - Update Period", {
        "Start Date": period.startDate?.toISOString?.() ?? "",
        "End Date": period.endDate?.toISOString?.() ?? "",
      });

      dispatch({
        type: "RIGS_COMMON_SET_PERIOD",
        payload: period,
      });
      return true;
    },
    [dispatch],
  );

  const { atomThemeVariant } = useCustomTheme();

  const location = useLocation();

  const isLeaderboard = stripSlashes(location.pathname) === stripSlashes(routePaths.rigLeaderboard);
  const isFleetPerformance = stripSlashes(location.pathname) === stripSlashes(routePaths.fleetPerformance);

  return (
    <Styled.HeaderContainerRow justify="space-between" align="middle" wrap={false}>
      <Col span={3}>
        <Title level={3} variant={atomThemeVariant} weight={500}>
          Rigs ({totalRigs})
        </Title>
      </Col>

      <Col>
        <Styled.RightContainer wrap>
          <Styled.FilterTagsContainer>
            <FilterTags tags={tags} />
          </Styled.FilterTagsContainer>

          <Styled.PaddedSpace>
            <Title level={3} variant={"faded"} weight={400}>
              Filter by
            </Title>
            <AllFilters filters={filters} setFilters={setFilters} />
            <LinkedSelectors keepEvengreenOnly={false} storeName={"rigsCommon"} />

            {/* Full Wells Included */}
            <Tooltip title="Full Wells Included">
              <Button
                size="large"
                loading={isLoading}
                style={{
                  border: !isLoading && allWellsIncluded ? `1px solid ${colors.well_color}` : undefined,
                }}
                icon={
                  <PDComponent.SvgIcon
                    name="allWellsIncluded"
                    style={{
                      color: !isLoading && allWellsIncluded ? colors.well_color : undefined,
                    }}
                  />
                }
                onClick={() => {
                  Track.interact("Rigs - Full Wells Included", {
                    State: !allWellsIncluded ? "Active" : "Disabled",
                  });
                  dispatch({
                    type: "RIGS_COMMON_FULL_WELLS_INCLUDED",
                    payload: !allWellsIncluded,
                  });
                }}
              />
            </Tooltip>
            {/* Period Filter */}
            <DatePicker selection={periodState} onApply={updatePeriod} />
            <PDComponent.VerticalDivider />
          </Styled.PaddedSpace>

          <Styled.PaddedSpace>
            <Tooltip title={getTitleRealTimeIndicator(realTimeDataState)}>
              <Button
                size="large"
                onClick={() => {
                  Track.interact("Rigs - Real Time", {
                    State: realTimeDataState === RealTimeDataEnum.ACTIVE ? "Disabled" : "Active",
                  });
                  dispatch({
                    type: "SET_REAL_TIME_DATA",
                    payload:
                      realTimeDataState === RealTimeDataEnum.ACTIVE
                        ? RealTimeDataEnum.DISABLED
                        : RealTimeDataEnum.ACTIVE,
                  });
                }}
                icon={<RealTimeIndicator realTimeDataState={realTimeDataState} />}
              />
            </Tooltip>
            {currentUser?.canAccessRigScorecard ? (
              <>
                <PDComponent.VerticalDivider />
                <Space>
                  <StyledTitle variant={atomThemeVariant}>Exceptions</StyledTitle>
                  <CustomSwitch
                    $isChecked={exceptions}
                    checked={exceptions}
                    onChange={(e) => {
                      dispatch({
                        type: "RIGS_COMMON_EXCEPTIONS_INCLUDED",
                        payload: e,
                      });
                      Track.interact("Rigs - Exceptions", { Value: e ? "On" : "Off" });
                    }}
                  />
                </Space>
                <PDComponent.VerticalDivider />

                <Styled.Buttons size="large">
                  <Link to={routePaths.rigLeaderboard}>
                    <Button
                      shape="round"
                      size="large"
                      type={isLeaderboard ? "primary" : "default"}
                      onClick={() => {
                        Track.clickEvent("Leaderboard Button");

                        dispatch({
                          type: "RESET_RIGS_COMMON_FILTERS_90_DAYS",
                          payload: {
                            ...initialFilters,
                            wellStatus: [
                              ...initialFilters.wellStatus.filter(
                                (option: IFilterOptionValue) => option.value === WellStatusType.Completed,
                              ),
                            ],
                          },
                        });
                      }}
                    >
                      Leaderboard
                    </Button>
                  </Link>

                  <Link to={routePaths.fleetPerformance}>
                    <Button
                      shape="round"
                      size="large"
                      type={isFleetPerformance ? "primary" : "default"}
                      onClick={() => {
                        Track.clickEvent("Fleet Performance Button");
                        dispatch({ type: "RESET_RIGS_COMMON_FILTERS_90_DAYS" });
                      }}
                    >
                      Fleet Performance
                    </Button>
                  </Link>
                </Styled.Buttons>
              </>
            ) : null}
          </Styled.PaddedSpace>
        </Styled.RightContainer>
      </Col>
    </Styled.HeaderContainerRow>
  );
}

export default Header;
