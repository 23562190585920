import { LensTemplateType } from "apis/oag";
export const DETAIL_BAR_HEIGHT = 40;
export const GRID_HEIGHT_ONE_EIGHTH = 2;
export const GRID_HEIGHT_STAT = 2;
export const GRID_WIDTH_STAT = 2;
// GRID
export const GRID_WIDTH_FACTOR = 4;
export const GRID_HEIGHT_FACTOR = 4;
export const HEIGHT_GRID_UNIT = 308 / GRID_HEIGHT_FACTOR;

// GRID WIDTH
export const GRID_WIDTH_QUARTER = GRID_WIDTH_FACTOR;
export const GRID_WIDTH_HALF = 2 * GRID_WIDTH_FACTOR;
export const GRID_WIDTH_THREE_QUARTERS = 3 * GRID_WIDTH_FACTOR;
export const GRID_WIDTH_FULL = 4 * GRID_WIDTH_FACTOR;

// GRID HEIGHT
export const GRID_HEIGHT_QUARTER = GRID_HEIGHT_FACTOR;
export const GRID_HEIGHT_QUARTER_AND_EIGHTH = GRID_HEIGHT_QUARTER + GRID_HEIGHT_ONE_EIGHTH;
export const GRID_HEIGHT_HALF = 2 * GRID_HEIGHT_FACTOR;
export const GRID_HEIGHT_THREE_QUARTERs = 3 * GRID_HEIGHT_FACTOR;
export const GRID_HEIGHT_FULL = 4 * GRID_HEIGHT_FACTOR;

// SPECIFIC LENS SETTINGS
export const TITLE_FONT_SIZE = 16;
export const TITLE_PADDING = 24;
export const TITLE_HEIGHT = TITLE_PADDING * 2 + TITLE_FONT_SIZE;

export const LENS_KPI_HEIGHT = 86;
export const LENS_KPI_HEIGHT_INNER = 62;
export const LENS_RIG_KPI_HEIGHT_INNER = 52;

const DEFAULT_LENS_SIZE_BOUNDS = {
  minH: GRID_HEIGHT_QUARTER,
  minW: GRID_WIDTH_QUARTER,
  maxH: GRID_HEIGHT_THREE_QUARTERs,
  maxW: GRID_WIDTH_FULL,
};
const GENERATOR_STATUS_BAR_BOUNDS = {
  minH: 2,
  minW: GRID_WIDTH_QUARTER,
  maxH: GRID_HEIGHT_THREE_QUARTERs,
  maxW: GRID_WIDTH_FULL,
};

export const getLensGridBounds = (lensTemplate: LensTemplateType) => {
  switch (lensTemplate) {
    case LensTemplateType.GeneratorStatusBar:
      return GENERATOR_STATUS_BAR_BOUNDS;
    // TODO this is just a baseline example, each template type can implement their own settings
    // ! lens boilerplate
    case LensTemplateType.StatCard:
    case LensTemplateType.DieselNatGasRatioStats:
      return {
        minH: 1,
        minW: 2,
        maxH: GRID_HEIGHT_THREE_QUARTERs,
        maxW: GRID_WIDTH_FULL,
      };
    case LensTemplateType.PowerLoadEfficiency:
      return {
        minH: GRID_HEIGHT_STAT,
        minW: GRID_WIDTH_STAT,
        maxH: GRID_HEIGHT_THREE_QUARTERs,
        maxW: GRID_WIDTH_FULL,
      };
    case LensTemplateType.StickSlipByTime:
      return {
        minH: GRID_HEIGHT_QUARTER,
        minW: GRID_WIDTH_HALF,
        maxH: GRID_HEIGHT_THREE_QUARTERs,
        maxW: GRID_WIDTH_FULL,
      };
    case LensTemplateType.TagBottomFingerprint:
      return {
        minH: GRID_HEIGHT_QUARTER,
        minW: GRID_HEIGHT_HALF,
        maxH: GRID_HEIGHT_THREE_QUARTERs,
        maxW: GRID_WIDTH_FULL,
      };
    case LensTemplateType.StickSlipByDepth:
      return {
        minW: GRID_WIDTH_HALF,
        maxW: GRID_WIDTH_FULL,
        minH: GRID_HEIGHT_QUARTER,
        maxH: GRID_WIDTH_FULL,
      };
    default:
      return DEFAULT_LENS_SIZE_BOUNDS;
  }
};

export const LOADING_KPI_STRING = "...";
export const NO_DATA_KPI_STRING = "- -";
