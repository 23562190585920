import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ApiPowerLoadDistributionUserLensesIdFactsPutRequest, PowerLoadDistributionFactSetDto } from "apis/oag";
import { PowerLoadDistributionUserLensesApi } from "apis/oag";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new PowerLoadDistributionUserLensesApi(apiConfig);

export const usePowerLoadDistributionFacts = (
  lensId: number,
  requestParameters: ApiPowerLoadDistributionUserLensesIdFactsPutRequest,
  options?: Omit<UseQueryOptions<PowerLoadDistributionFactSetDto>, "queryKey" | "queryFn">,
) => {
  const crtWellId = useSelectedWell();
  const dispatch = useAppDispatch();

  const queryKey: PDLensFactsQueryKey<ApiPowerLoadDistributionUserLensesIdFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.powerLoadDistributionFacts,
    lensId,
    params: requestParameters,
  };

  const request = useSuspenseQuery<PowerLoadDistributionFactSetDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api.apiPowerLoadDistributionUserLensesIdFactsPut(requestParameters, { signal }).then((data) => {
        if (data?.lastUpdatedAt) {
          dispatch({
            type: "SET_POWER_MANAGEMENT_REFRESH_DATE",
            payload: {
              [crtWellId]: data?.lastUpdatedAt,
            },
          });
        }

        return data;
      }),
    ...options,
  });

  return request;
};
