import * as Styled from "components/Clara/MessageThread/style";
import { PDComponent } from "components/PDComponents";
import { startTransition, useCallback, useState } from "react";
import { Tooltip } from "utils/componentLibrary";

export const CopyButton = ({ message }: { message: string }) => {
  const [tooltipMessage, setTooltipMessage] = useState("Copy");

  const handleOnCopy = useCallback(() => {
    navigator.clipboard.writeText(message);
    setTooltipMessage("Copied to clipboard");
  }, [message]);

  const handleOnCopyTooltipOpen = useCallback((isOpen: boolean) => {
    startTransition(() => {
      if (isOpen) {
        setTooltipMessage("Copy");
      }
    });
  }, []);

  return (
    <Tooltip
      placement="bottom"
      title={tooltipMessage}
      onOpenChange={handleOnCopyTooltipOpen}
      mouseLeaveDelay={0}
      mouseEnterDelay={0}
    >
      <Styled.SimpleButtonWrapper role="button" onClick={handleOnCopy}>
        <PDComponent.SvgIcon name="copy" />
      </Styled.SimpleButtonWrapper>
    </Tooltip>
  );
};
