/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClaimPermissionType } from './ClaimPermissionType';
import {
    ClaimPermissionTypeFromJSON,
    ClaimPermissionTypeFromJSONTyped,
    ClaimPermissionTypeToJSON,
} from './ClaimPermissionType';
import type { ClaimType } from './ClaimType';
import {
    ClaimTypeFromJSON,
    ClaimTypeFromJSONTyped,
    ClaimTypeToJSON,
} from './ClaimType';

/**
 * 
 * @export
 * @interface OperatorClaimDto
 */
export interface OperatorClaimDto {
    /**
     * 
     * @type {number}
     * @memberof OperatorClaimDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorClaimDto
     */
    rigId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OperatorClaimDto
     */
    wellId?: number | null;
    /**
     * 
     * @type {ClaimType}
     * @memberof OperatorClaimDto
     */
    type: ClaimType;
    /**
     * 
     * @type {ClaimPermissionType}
     * @memberof OperatorClaimDto
     */
    permission: ClaimPermissionType;
    /**
     * 
     * @type {number}
     * @memberof OperatorClaimDto
     */
    operatorId: number;
}



/**
 * Check if a given object implements the OperatorClaimDto interface.
 */
export function instanceOfOperatorClaimDto(value: object): value is OperatorClaimDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('permission' in value) || value['permission'] === undefined) return false;
    if (!('operatorId' in value) || value['operatorId'] === undefined) return false;
    return true;
}

export function OperatorClaimDtoFromJSON(json: any): OperatorClaimDto {
    return OperatorClaimDtoFromJSONTyped(json, false);
}

export function OperatorClaimDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorClaimDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'rigId': json['RigId'] == null ? undefined : json['RigId'],
        'wellId': json['WellId'] == null ? undefined : json['WellId'],
        'type': ClaimTypeFromJSON(json['Type']),
        'permission': ClaimPermissionTypeFromJSON(json['Permission']),
        'operatorId': json['OperatorId'],
    };
}

export function OperatorClaimDtoToJSON(value?: OperatorClaimDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'RigId': value['rigId'],
        'WellId': value['wellId'],
        'Type': ClaimTypeToJSON(value['type']),
        'Permission': ClaimPermissionTypeToJSON(value['permission']),
        'OperatorId': value['operatorId'],
    };
}

