import styled from "styled-components";
import { Row, Space } from "utils/componentLibrary";

export const InputContainer = styled(Row).attrs({
    gutter: 8,
    align: "middle",
    wrap: false,
  })`
    width: 100%;
  `;

export const InfoContainer = styled(Space).attrs({
    size: 12,
    direction: "vertical",
  })`
    width: 100%;
    > * {
      width: 100%;
    }
  `;