import { WellStatusType } from "apis/oag";
import type { FilterTag } from "components/Filters/FilterTags";
import type { FilterTypes } from "components/Filters/useFiltersCategories";
import { useFiltersCategories } from "components/Filters/useFiltersCategories";
import type { IOption } from "components/PDComponents/Search/utils";
import type { IFilterOptions } from "reducers/rigsCommonReducer";

export const linkedFilters = ["rigs", "wells", "operators", "formations"] as const;
// have tags live as a global variable to avoid re-rendering
let tags: FilterTag[] = [];

export const useFilterTags = ({
    updateFilters,
    options,
    isLoadingArr,
    selectedValues,
    filters,
    setFilters,
    statusState,
    setStatusState,
}: {
    updateFilters: (filter: (typeof linkedFilters)[number]) => (value: number[] | null) => void;
    selectedValues: Record<(typeof linkedFilters)[number], number[] | null>;
    options: Record<(typeof linkedFilters)[number], IOption<number>[] | null> | null;
    filters: IFilterOptions | null;
    setFilters: (localFilters: IFilterOptions | null) => void;
    isLoadingArr?: boolean[];
    statusState?: WellStatusType[];
    setStatusState?: (status: WellStatusType[]) => void;
}) => {
    const { localFilters, setLocalFilters, allFilters } = useFiltersCategories(filters, setFilters);
    // I think we can simplify this as well
    if (isLoadingArr?.some((loading) => loading)) return tags;
    tags = [
        ...(() => {
            if (!statusState ||
                statusState.length === 4
            ) return [];
            return [{
                onRemove: () => {
                    setStatusState?.(
                        Object.keys(WellStatusType) as WellStatusType[]
                    );
                },
                text: statusState.length > 1 ? `Status +${statusState.length}` : statusState[0],
            }];
        })(),
        ...[...linkedFilters].reduce((acc, filter) => {
            const selectedCount = selectedValues[filter]?.length;
            if (!selectedCount) return acc;
            const currentSelection = (options?.[filter] ?? []).filter((x) => selectedValues[filter]?.includes(x.id));
            if (!currentSelection?.length) return acc;
            const filterNameCapitalized = filter.charAt(0).toUpperCase() + filter.slice(1);
            const text =
                currentSelection?.length > 1
                    ? `${filterNameCapitalized} +${currentSelection.length}`
                    : currentSelection[0].name;
            return [
                ...acc,
                {
                    onRemove: () => {
                        updateFilters(filter)(null);
                    },
                    text,
                },
            ];
        }, [] as FilterTag[]),
        ...(Object.keys(allFilters) as Array<FilterTypes>).reduce((acc, filterType) => {
            const selectedFilterOptions = localFilters?.[filterType];
            const allFilterItems = allFilters?.[filterType]?.options;
            if (selectedFilterOptions?.length && allFilterItems && selectedFilterOptions?.length !== allFilterItems?.length) {
                const label = selectedFilterOptions[0].value;

                const finalFilterText =
                    (selectedFilterOptions?.length > 1 ? label + ` +${selectedFilterOptions?.length - 1}` : label) + "";

                return [
                    ...acc,
                    {
                        onRemove: () =>
                            setLocalFilters((prev) => {
                                if (prev) {
                                    const updatedFilters = { ...prev, [filterType]: allFilterItems };
                                    setFilters(updatedFilters);
                                    return updatedFilters;
                                }
                                return prev;
                            }),
                        text: finalFilterText,
                    },
                ];
            }
            return acc;
        }, [] as FilterTag[]),
    ];
    return tags;
};
