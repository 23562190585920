import styled from "styled-components";
import colors from "utils/colors";
import { zIndexLayer } from "utils/zIndex";

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: ${zIndexLayer.sky};
  background-color: ${colors.faded_bg};
`;

export const maskStyle = {
  top: 128,
};

export const Backdrop: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
};
