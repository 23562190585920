import "./style.css";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import type { Geometry } from "@turf/turf";
import { area, convertLength, distance } from "@turf/turf";
import type { WellDrillingSummaryDto } from "apis/oag";
import { WebGLSupportBanner } from "components/WebGLSupportBanner/WebGLSupportBanner";
import { useGetBasins } from "hooks/useBasins";
import { useDashboardType } from "hooks/useDashboardType";
import type { RigsResponse } from "hooks/useRigs";
import { useRigs } from "hooks/useRigs";
import { useWellSummaries } from "hooks/useWellSummaries";
import type { DebouncedFunc } from "lodash";
import { clamp, debounce, throttle } from "lodash";
import type { LngLatBounds, LngLatLike } from "mapbox-gl";
import mapboxgl from "mapbox-gl";
import MapboxCircle from "mapbox-gl-circle";
import { useFilteredAllWellsContext } from "pages/AllWells/useFilteredAllWells";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "reducers/store";
import { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import { useTheme } from "styled-components";
import colors from "utils/colors";

import { StyledResizeHandle } from "./StyledComponents";
import { AreaSelectionType, INITIAL_MAP_ZOOM, useWellsMapViewContext } from "./useWellsMapView";
import { getGeoJsonFromData } from "./utils";

const EMPTY_DRAG_GHOST_IMAGE = new Image(0, 0);
EMPTY_DRAG_GHOST_IMAGE.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

const WELLS_SELECTED_SOURCE_KEY = "wells-selected";
const WELLS_UNSELECTED_SOURCE_KEY = "wells-unselecteed";

enum MapFeatureIds {
  Clusters = "clusters",
  UnclusteredPoint = "unclustered-point",
  ClusterCount = "cluster-count",
}

const MapView: FC = () => {
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const circleRef = useRef<any>(null);
  const wells = useRef<WellDrillingSummaryDto[] | null>(null);
  const mapContainer = useRef<HTMLDivElement>(null);
  const bottomScrollRef = useRef<number | null>(null);
  const rawRigs = useRigs();
  const [crtSize, setCrtSize] = useState(430);

  const { isEvergreen } = useDashboardType();

  const trackingTitle = isEvergreen ? "Evergreen Wells" : "All Wells";

  const {
    setMapBoxInstance,
    setArea,
    setRadius,
    setAreaSelectionType,
    registerOnCircleAreaPress,
    registerOnPolygonAreaPress,
    registerOnTrashPress,
    radius,
    basinName,
    setBasinName,
  } = useWellsMapViewContext();
  const { data: basins } = useGetBasins();
  const polygon = useMemo(
    () => (basins ? basins.find((b) => b.name === basinName)?.basinAreas ?? [] : []),
    [basinName, basins],
  );
  const [previousBasinName, setPreviousBasinName] = useState(basinName);

  const wellSummaries = useWellSummaries(false);
  const drawRef = useRef<any>(null);

  const { setVisibleWellsList } = useFilteredAllWellsContext();
  const currentUom = useAppSelector((state) => state.global.unit);

  const rigs = useRef<RigsResponse | null>(null);

  const updateWellSelection = useCallback(() => {
    if (!mapRef.current) return;
    const map = mapRef.current;

    let circleInfo;
    if (circleRef.current) {
      circleInfo = { center: circleRef.current?.getCenter(), radius: circleRef.current?.getRadius() };
    }
    let selectionPolygonShape = drawRef.current?.getAll()?.features?.[0];
    const sourceLayer = mapRef.current?.getSource(basinName);
    if (
      // https://github.com/mapbox/mapbox-gl-draw/issues/774
      !selectionPolygonShape?.geometry?.coordinates?.every((coordinate: Array<LngLatLike>) =>
        coordinate.every((latLng) => latLng !== null),
      )
    ) {
      selectionPolygonShape = null;
    }

    const { insideSelectionArea, outsideSelectionArea } = getGeoJsonFromData({
      wells: wells.current || [],
      rigs: rigs.current,
      selectionPolygonShape,
      circleInfo,
      sourceLayer,
    });

    (map.getSource(WELLS_SELECTED_SOURCE_KEY) as mapboxgl.GeoJSONSource)?.setData(insideSelectionArea);
    (map.getSource(WELLS_UNSELECTED_SOURCE_KEY) as mapboxgl.GeoJSONSource)?.setData(outsideSelectionArea);

    if (selectionPolygonShape || circleInfo || (basinName !== "" ? sourceLayer : undefined)) {
      setVisibleWellsList(insideSelectionArea.features.map((feature) => feature?.properties?.id));
    } else {
      setVisibleWellsList(wellSummaries.data?.wells?.map((well) => well.id) ?? []);
    }
  }, [basinName, setVisibleWellsList, wellSummaries.data?.wells]);

  useEffect(() => {
    wells.current = wellSummaries.data?.wells || [];
    rigs.current = rawRigs.data;
    updateWellSelection();
  }, [rawRigs.data, rigs, updateWellSelection, wellSummaries.data]);

  const updatePolygon = useCallback(() => {
    if (!drawRef.current) {
      setArea(0);
      setAreaSelectionType(AreaSelectionType.None);
      return;
    }

    const data = drawRef.current?.getAll();

    if (data.features?.length) {
      const newArea = area(data);

      if (data.features[0]) {
        setAreaSelectionType(AreaSelectionType.Polygon);
        setArea(newArea);
        Track.interact(`${trackingTitle} - Draw Polygon`, {
          polygonCoordinates: data.features[0]?.geometry?.coordinates,
        });
      } else {
        setArea(0);
        setAreaSelectionType(AreaSelectionType.None);
      }

      setArea(newArea);
    } else {
      setArea(0);
    }

    updateWellSelection();
  }, [setArea, setAreaSelectionType, trackingTitle, updateWellSelection]);

  useEffect(() => {
    const value =
      currentUom === IUnitSystem.METRIC
        ? +convertLength(radius, "kilometers", "meters").toFixed(0)
        : +convertLength(radius, "miles", "meters").toFixed(0);

    circleRef.current?.setRadius(value); // converting to meters
  }, [currentUom, radius]);

  const addCircleInViewportCenter = useCallback(() => {
    if (!mapRef.current) {
      return;
    }
    const bounds: LngLatBounds = mapRef.current?.getBounds();
    const topPoint = [bounds.getCenter().lng, bounds.getNorth()];
    const bottomPoint = [bounds.getCenter().lng, bounds.getSouth()];
    const distBetweenMapBounds = distance(topPoint, bottomPoint);
    const radius = clamp((distBetweenMapBounds / 2) * 700, 0, 1000000); // 700 just to make sure there's some space between screen map bounds

    const circle = new MapboxCircle(mapRef.current?.getCenter(), radius, {
      editable: true,
      minRadius: 1000,
      maxRadius: 1000000,
      strokeColor: "white",
      strokeWeight: 3,
      fillColor: colors.well_color,
    })
      .on("centerchanged", (shape: any) => {
        updateWellSelection();
        setRadius(shape.getRadius());
      })
      .on("radiuschanged", (shape: any) => {
        updateWellSelection();
        setRadius(shape.getRadius());
      })
      .addTo(mapRef.current);

    setAreaSelectionType(AreaSelectionType.Circle);
    setRadius(radius);

    circleRef.current = circle;

    updateWellSelection();
  }, [setAreaSelectionType, setRadius, updateWellSelection]);

  const removeSelectionCircle = useCallback(() => {
    circleRef.current?.remove();
    circleRef.current = null;
    setArea(0);
    setRadius(0);
    setAreaSelectionType(AreaSelectionType.None);
    updateWellSelection();
  }, [setArea, setAreaSelectionType, setRadius, updateWellSelection]);
  const removeSelectionBasin = useCallback(() => {
    if (!previousBasinName) return;
    setBasinName("");
    if (mapRef?.current?.getLayer(previousBasinName)) {
      mapRef.current.removeLayer(previousBasinName);
      mapRef.current.removeLayer(`${previousBasinName}-outline`);
    }
    if (mapRef?.current?.getSource(previousBasinName)) {
      mapRef.current.removeSource(previousBasinName);
    }
    setArea(0);
    setAreaSelectionType(AreaSelectionType.None);
    setTimeout(() => updateWellSelection(), 500);
  }, [previousBasinName, setArea, setAreaSelectionType, setBasinName, updateWellSelection]);

  const removeSelectionPolygon = useCallback(() => {
    drawRef.current?.deleteAll();
    setAreaSelectionType(AreaSelectionType.None);
    setArea(0);

    // hack to make sure the map is updated after the data is set
    setTimeout(() => {
      updateWellSelection();
    }, 100);
  }, [setArea, setAreaSelectionType, updateWellSelection]);

  const handleOnCirclePress = useCallback(() => {
    removeSelectionPolygon();
    removeSelectionBasin();
    addCircleInViewportCenter();
  }, [addCircleInViewportCenter, removeSelectionBasin, removeSelectionPolygon]);

  const handleOnPolygonPress = useCallback(() => {
    if (!drawRef.current) return;
    removeSelectionCircle();
    removeSelectionBasin();
    drawRef.current?.changeMode("draw_polygon");
    setAreaSelectionType(AreaSelectionType.Polygon);
  }, [removeSelectionBasin, removeSelectionCircle, setAreaSelectionType]);

  const handleOnTrashPress = useCallback(() => {
    removeSelectionCircle();
    removeSelectionPolygon();
    removeSelectionBasin();
    drawRef.current?.changeMode("simple_select");
  }, [removeSelectionBasin, removeSelectionCircle, removeSelectionPolygon]);
  useEffect(() => {
    if (!basinName) return;
    removeSelectionCircle();
    removeSelectionPolygon();

    setPreviousBasinName((id) => {
      if (id) {
        if (mapRef?.current?.getLayer(id)) {
          mapRef.current.removeLayer(id);
          mapRef.current.removeLayer(`${id}-outline`);
        }
        if (mapRef?.current?.getSource(id)) {
          mapRef.current.removeSource(id);
        }
      }
      const newArea = area({
        type: "FeatureCollection",
        features: [
          {
            id: basinName,
            type: "Feature",
            properties: {},
            geometry: {
              coordinates: [polygon],
              type: "Polygon",
            },
          },
        ],
      });
      if (polygon?.[0].coordinates?.[0]) {
        mapRef?.current?.setCenter(polygon[0].coordinates[0] as unknown as [number, number]);
      }

      mapRef?.current?.addSource(basinName, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: polygon.map((p) => ({
            type: "Feature",
            properties: {},
            geometry: {
              coordinates: [p.coordinates],
              type: "Polygon",
            },
          })),
        },
      });
      // Add a new layer to visualize the polygon.
      mapRef?.current?.addLayer({
        id: basinName,
        type: "fill",
        source: basinName, // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0D9B83",
          "fill-opacity": 0.2,
        },
      });
      // Add a black outline around the polygon.
      mapRef?.current?.addLayer({
        id: `${basinName}-outline`,
        type: "line",
        source: basinName,
        layout: {},
        paint: {
          "line-color": "#fff",
          "line-width": 2,
        },
      });
      setArea(newArea);
      setAreaSelectionType(AreaSelectionType.Basin);
      updateWellSelection();
      return basinName;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeSelectionCircle, removeSelectionPolygon]);

  useEffect(() => {
    registerOnCircleAreaPress(handleOnCirclePress);
    registerOnPolygonAreaPress(handleOnPolygonPress);
    registerOnTrashPress(handleOnTrashPress);
  }, [
    handleOnCirclePress,
    handleOnPolygonPress,
    handleOnTrashPress,
    registerOnCircleAreaPress,
    registerOnPolygonAreaPress,
    registerOnTrashPress,
  ]);

  const forceOnlyOneShape = useCallback(() => {
    // ALLOWS ONLY DRAWING ONE POLYGON AT A TIME
    const data = drawRef.current.getAll();
    const pids: any = [];
    // ID of the added template empty feature
    const lid = data.features[data.features.length - 1]?.id;
    if (lid) {
      data.features.forEach((f: any) => {
        if (f.id !== lid) {
          pids.push(f.id);
        }
      });

      if (pids.length) {
        drawRef.current.delete(pids);
      }
    }
  }, []);

  const initMapbox = useCallback(() => {
    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
    });

    setAreaSelectionType(AreaSelectionType.None);

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current || "",
      style: "mapbox://styles/precisiondrilling/ckkx37upp5icd17orl1e3gai7",
      minZoom: 1,
      maxZoom: 23,
      attributionControl: false,
      center: [-79.4512, 43.6568] as LngLatLike,
      zoom: INITIAL_MAP_ZOOM,
    })
      .addControl(
        new mapboxgl.ScaleControl({
          maxWidth: 100,
          unit: "metric",
        }),
      )
      .addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
        "top-right",
      )
      .addControl(drawRef.current)
      .on("load", () => {
        const map = mapRef.current;
        const images = [
          {
            url: `/assets/icons/white_${isEvergreen ? "evergreen" : "pin"}.png`,
            id: "white_pin",
          },
          {
            url: "/assets/icons/white_blank.png",
            id: "white_blank",
          },
          {
            url: `/assets/icons/dark_${isEvergreen ? "evergreen" : "pin"}.png`,
            id: "dark_pin",
          },
          {
            url: "/assets/icons/dark_blank.png",
            id: "dark_blank",
          },
        ];

        const { insideSelectionArea, outsideSelectionArea } = getGeoJsonFromData({
          rigs: rigs.current,
          wells: wells.current || [],
        });

        const sourceProperties: mapboxgl.AnySourceData = {
          type: "geojson",
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        };

        map?.addSource(WELLS_SELECTED_SOURCE_KEY, {
          data: insideSelectionArea,
          ...sourceProperties,
        });

        map?.addSource(WELLS_UNSELECTED_SOURCE_KEY, {
          data: outsideSelectionArea,
          ...sourceProperties,
        });

        Promise.all(
          images.map(
            (img) =>
              new Promise((resolve, reject) => {
                map?.loadImage(img.url, (error, res) => {
                  if (error) reject();
                  if (res) map?.addImage(img.id, res);
                  resolve(img.id);
                });
              }),
          ),
        ).then(() => {
          [WELLS_SELECTED_SOURCE_KEY, WELLS_UNSELECTED_SOURCE_KEY].forEach((sourceKey) => {
            map?.addLayer({
              id: MapFeatureIds.Clusters + sourceKey,
              source: sourceKey,
              type: "circle",
              filter: ["has", "point_count"],
              paint: {
                "circle-color": ["step", ["get", "point_count"], "rgba(0,0,0,0)", 100, "rgba(0,0,0,0)"],
                "circle-radius": ["step", ["get", "point_count"], 24, 100, 24],
                "circle-opacity": sourceKey === WELLS_SELECTED_SOURCE_KEY ? 1 : 0.5,
              },
            });

            map?.addLayer({
              id: MapFeatureIds.ClusterCount + sourceKey,
              type: "symbol",
              source: sourceKey,
              filter: ["has", "point_count"],
              layout: {
                "text-field": "{point_count_abbreviated}",
                "text-size": 12,
                "icon-image": "white_blank",
                "icon-size": ["step", ["get", "point_count"], 0.4, 10, 0.5],
              },
              paint: {
                "icon-opacity": sourceKey === WELLS_SELECTED_SOURCE_KEY ? 1 : 0.5,
              },
            });

            map?.addLayer({
              id: MapFeatureIds.UnclusteredPoint + sourceKey,
              type: "symbol",
              source: sourceKey,
              filter: ["!", ["has", "point_count"]],
              layout: {
                "icon-image": "white_pin",
                "icon-size": 0.4,
              },
              paint: {
                "icon-opacity": sourceKey === WELLS_SELECTED_SOURCE_KEY ? 1 : 0.5,
              },
            });
          });

          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            maxWidth: "268px",
            offset: 20,
          });

          map?.on("click", MapFeatureIds.Clusters + WELLS_SELECTED_SOURCE_KEY, (e) => {
            const features = map?.queryRenderedFeatures(e.point, {
              layers: [MapFeatureIds.Clusters + WELLS_SELECTED_SOURCE_KEY],
            });
            const clusterId = features[0]?.properties?.cluster_id;

            (map?.getSource(WELLS_SELECTED_SOURCE_KEY) as mapboxgl.GeoJSONSource).getClusterExpansionZoom(
              clusterId,
              (err, zoom) => {
                if (err) return;
                map?.easeTo({
                  center: (features[0].geometry as Geometry).coordinates as LngLatLike,
                  zoom,
                });
              },
            );
          });

          map?.on("mouseenter", MapFeatureIds.Clusters + WELLS_SELECTED_SOURCE_KEY, (e) => {
            map.getCanvas().style.cursor = "pointer";

            const coordinates = (e?.features?.[0]?.geometry as Geometry).coordinates.slice();
            const { point_count_abbreviated } = e?.features?.[0]?.properties || { point_count_abbreviated: 0 };
            popup
              .setLngLat(coordinates as LngLatLike)
              .setHTML(`<span>${point_count_abbreviated} Wells</span>`)
              .addTo(map);
          });

          map?.on("mouseleave", MapFeatureIds.Clusters + WELLS_SELECTED_SOURCE_KEY, () => {
            map.getCanvas().style.cursor = "";
            popup.remove();
          });

          map?.on("mouseenter", MapFeatureIds.UnclusteredPoint + WELLS_SELECTED_SOURCE_KEY, (e) => {
            map.getCanvas().style.cursor = "pointer";
            const coordinates = (e?.features?.[0]?.geometry as GeoJSON.Polygon).coordinates.slice();
            const { title, rigs } = e?.features?.[0]?.properties || { title: "", rigs: "" };

            popup
              .setLngLat(coordinates as unknown as [number, number])
              .setHTML(`<center><span>${title}</span><br><span class='grayed'>${rigs}</span></center>`)
              .addTo(map);
          });

          map?.on("mouseleave", MapFeatureIds.UnclusteredPoint + WELLS_SELECTED_SOURCE_KEY, () => {
            map.getCanvas().style.cursor = "";
            popup.remove();
          });

          map?.on("draw.render", forceOnlyOneShape);
          map?.on("draw.create", updatePolygon);
          map?.on("draw.update", updatePolygon);
          map?.on("draw.delete", updatePolygon);

          map?.resize();
          const resizer = new ResizeObserver(
            debounce(() => {
              map?.resize();
            }, 25),
          );
          if (mapContainer.current) resizer.observe(mapContainer.current);
        });
      });

    setMapBoxInstance(mapRef.current);
  }, [forceOnlyOneShape, isEvergreen, rigs, setAreaSelectionType, setMapBoxInstance, updatePolygon, wells]);

  const isMapboxGLSupported = useMemo(() => mapboxgl.supported(), []);

  useEffect(() => {
    if (isMapboxGLSupported) {
      initMapbox();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapboxGLSupported]);

  const [initialPos, setInitialPos] = useState<number>(0);
  const [initialSize, setInitialSize] = useState<number>(0);
  const handleOnDragStart = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setDragImage(EMPTY_DRAG_GHOST_IMAGE, 0, 0);
    const resizable = mapContainer.current;
    if (!resizable) return;
    bottomScrollRef.current = window.pageYOffset;
    setInitialPos(e?.clientY || 0);
    setInitialSize(resizable?.offsetHeight || 0);
  }, []);

  const debouncedSetResize = useRef<DebouncedFunc<(size: number) => void> | null>(null);

  useEffect(() => {
    debouncedSetResize.current = throttle(
      (size: number) => {
        setCrtSize(size);
      },
      5,
      { leading: true },
    );
  }, [crtSize, setCrtSize]);

  const handleOnResize = (e: React.DragEvent<HTMLDivElement>) => {
    const resizable = mapContainer.current;
    if (!resizable) return;
    const size = initialSize + e.clientY + -initialPos;
    if (size <= 231 || size >= 661) return;

    if (bottomScrollRef?.current) {
      window.scrollTo(0, bottomScrollRef?.current);
    }
    debouncedSetResize.current?.(size);
  };

  const { isDark } = useTheme();

  return (
    <div
      style={{
        position: "relative",
        padding: 16,
        paddingBottom: 32,
      }}
    >
      <StyledResizeHandle
        draggable="true"
        onDragStart={handleOnDragStart}
        onDrag={handleOnResize}
        onDragOver={(e) => {
          e.dataTransfer.dropEffect = "move";
          e.preventDefault();
        }}
        onDragEnter={(e) => {
          e.preventDefault();
        }}
      >
        {[1, 2, 3].map((i) => {
          return (
            <div
              key={i}
              style={{
                height: 8,
                width: 8,
                backgroundColor: isDark ? colors.limo_scene : colors.lunar_rays,
                borderRadius: "50%",
                margin: "0px 4px",
              }}
            />
          );
        })}
      </StyledResizeHandle>

      <div
        ref={mapContainer}
        style={{
          height: crtSize,
        }}
      >
        {!isMapboxGLSupported ? <WebGLSupportBanner /> : null}
      </div>
    </div>
  );
};

export default MapView;
