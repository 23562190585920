/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DimensionType } from './DimensionType';
import {
    DimensionTypeFromJSON,
    DimensionTypeFromJSONTyped,
    DimensionTypeToJSON,
} from './DimensionType';

/**
 * 
 * @export
 * @interface RigPowerSummaryValuesDto
 */
export interface RigPowerSummaryValuesDto {
    /**
     * 
     * @type {DimensionType}
     * @memberof RigPowerSummaryValuesDto
     */
    dimension: DimensionType;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryValuesDto
     */
    min?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryValuesDto
     */
    mean?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigPowerSummaryValuesDto
     */
    max?: number | null;
}



/**
 * Check if a given object implements the RigPowerSummaryValuesDto interface.
 */
export function instanceOfRigPowerSummaryValuesDto(value: object): value is RigPowerSummaryValuesDto {
    if (!('dimension' in value) || value['dimension'] === undefined) return false;
    return true;
}

export function RigPowerSummaryValuesDtoFromJSON(json: any): RigPowerSummaryValuesDto {
    return RigPowerSummaryValuesDtoFromJSONTyped(json, false);
}

export function RigPowerSummaryValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigPowerSummaryValuesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dimension': DimensionTypeFromJSON(json['Dimension']),
        'min': json['Min'] == null ? undefined : json['Min'],
        'mean': json['Mean'] == null ? undefined : json['Mean'],
        'max': json['Max'] == null ? undefined : json['Max'],
    };
}

export function RigPowerSummaryValuesDtoToJSON(value?: RigPowerSummaryValuesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Dimension': DimensionTypeToJSON(value['dimension']),
        'Min': value['min'],
        'Mean': value['mean'],
        'Max': value['max'],
    };
}

