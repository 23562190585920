import { Title } from "atoms/Typography";
import { Spinner } from "components/Loader/Spinner";
import styled from "styled-components";
import { Col, Row } from "utils/componentLibrary";

const StyledText = styled(Title).attrs({ level: 3 })`
  line-height: 24px !important;
`;

const StyledFadedText = styled(Title).attrs({ level: 3, variant: "faded" })`
  margin-top: 24px;
`;

export function Loading() {
  return (
    <>
      <Row justify="start" gutter={[12, 12]}>
        <Col>
          <Spinner size={24} />
        </Col>
        <Col>
          <StyledText>Applying Changes</StyledText>
        </Col>
      </Row>
      <StyledFadedText>This usually takes a minute or less.</StyledFadedText>
    </>
  );
}
