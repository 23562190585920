import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { PhaseDto } from "apis/oag";
import { PhasesApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import type { PDUniqueQueryKey } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const phasesApi = new PhasesApi(apiConfig);

const PhasesQueryKey: PDUniqueQueryKey = { uid: RequestUID.phases };

export function fetchPhases() {
  return phasesApi.apiPhasesGet() ?? [];
}

export function usePhases(options?: UseQueryOptions<PhaseDto[]>) {
  return useQuery<PhaseDto[]>({
    queryKey: [PhasesQueryKey],
    queryFn: () => fetchPhases(),
    ...options,
    refetchOnMount: false,
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
