import { ResultDataState } from "apis/oag";
import { Title } from "atoms/Typography";
import RigAtom from "components/Legend/RigAtom";
import { useWellDetails } from "hooks/useWellDetails";
import type { FC } from "react";
import { Col } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

import { Box, Circle, LegendRow, StyledTitle } from "./style";

export const SingleLegend: FC<{ title: string; wellId?: number; color: string; dataState?: ResultDataState }> = ({
  title,
  wellId = -1,
  color,
  dataState = ResultDataState.Valid,
}) => {
  const { data } = useWellDetails(wellId, { enabled: wellId >= 0 }, true);
  // TODO ADD MP Tracking
  const { atomThemeVariant } = useCustomTheme();
  return (
    <LegendRow $opacity={dataState === ResultDataState.Valid ? 1 : 0.5}>
      <Col flex="0 auto">
        <Box>
          <Circle color={color} />
        </Box>
      </Col>
      <Col flex="1 auto">
        <StyledTitle variant={atomThemeVariant}>{title}</StyledTitle>
        {data?.jobs ? <RigAtom uniqueRigIds={[...new Set(data.jobs.map((e) => e.rigId))]}></RigAtom> : null}

        {dataState === ResultDataState.NoData && (
          <Title level={4} variant={atomThemeVariant} style={{ marginTop: "4px" }}>
            - -
          </Title>
        )}
      </Col>
    </LegendRow>
  );
};
