/* eslint-disable react/no-multi-comp */
import { HiddenScrollbarColumn } from "components/Layout/HiddenScrollbarColumn";
import type { ITheme } from "CustomThemeProvider";
import type { FC, ReactNode } from "react";
import React from "react";
import styled, { css } from "styled-components";
import { Col, Layout, Row } from "utils/componentLibrary";
import { Content, Sider } from "utils/componentLibrary/Layout";
import { SIDEBAR_DEFAULT_WIDTH_PERCENT, SIDEBAR_MAX_WIDTH, SIDEBAR_MIN_WIDTH } from "utils/constants";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

import { SCROLLBAR_GAP_PX, SCROLLBAR_WIDTH_PX } from "./LayoutUtils";
const StyledSidebar = styled(Sider)<{ sidebarcolor: string; sidebarwidth: number | string }>`
  background: ${(props) => props.sidebarcolor || (props.theme as ITheme).themeStyle.colors.secondary_bg};
  ${({ sidebarwidth }) => {
    if (sidebarwidth)
      return css`
        width: ${Number.isInteger(sidebarwidth) ? `${sidebarwidth}px` : sidebarwidth};
        min-width: ${Number.isInteger(sidebarwidth) ? `${sidebarwidth}px` : sidebarwidth};
        max-width: ${Number.isInteger(sidebarwidth) ? `${sidebarwidth}px` : sidebarwidth};
      `;
    else
      return css`
        width: clamp(${SIDEBAR_MIN_WIDTH}px, ${SIDEBAR_DEFAULT_WIDTH_PERCENT}%, ${SIDEBAR_MAX_WIDTH}px) !important;
        min-width: clamp(${SIDEBAR_MIN_WIDTH}px, ${SIDEBAR_DEFAULT_WIDTH_PERCENT}%, ${SIDEBAR_MAX_WIDTH}px) !important;
        max-width: clamp(${SIDEBAR_MIN_WIDTH}px, ${SIDEBAR_DEFAULT_WIDTH_PERCENT}%, ${SIDEBAR_MAX_WIDTH}px) !important;
      `;
  }}

  &::after {
    top: 0;
    bottom: 0;
    position: absolute;
    background: ${(props) => props.sidebarcolor || (props.theme as ITheme).themeStyle.colors.primary_bg};
    border-left: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_accent};
    content: "";
  }
`;

const CoreLayout: FC<{
  children: ReactNode;
  sidebar?: ReactNode;
  bgColor?: string;
  sidebarwidth?: number | string;
  sidebarColor?: string;
  onWheel?: (event: React.WheelEvent<HTMLDivElement>) => void;
}> = ({ children, sidebar, sidebarwidth, sidebarColor, bgColor, onWheel }) => {
  const { themeStyle } = useCustomTheme();
  return (
    <Layout hasSideBar={!!sidebar} style={{ height: "100%" }} onWheel={onWheel}>
      <Content style={{ background: bgColor ? bgColor : themeStyle.colors.primary_bg, overflow: "auto" }}>
        {children}
      </Content>
      {sidebar ? (
        <StyledSidebar sidebarwidth={sidebarwidth || 0} sidebarcolor={sidebarColor || ""}>
          {sidebar}
        </StyledSidebar>
      ) : null}
    </Layout>
  );
};

const ScrollCol = styled(Col)<{ hz: number; $isReport: boolean; $isReportTvD: boolean }>`
  height: calc(100vh - ${(props) => props.hz}px - ${(props) => (props.$isReport ? "0px" : "64px - 1px")});
  overflow-y: scroll;
  width: 100%;
  padding-right: calc((${SCROLLBAR_GAP_PX}px - ${SCROLLBAR_WIDTH_PX}px) / 2);
  margin-right: calc((${SCROLLBAR_GAP_PX}px - ${SCROLLBAR_WIDTH_PX}px) / 2);
`;

export const SectionLayout: FC<{
  ref?: React.RefObject<HTMLDivElement>;
  children: ReactNode;
  header: ReactNode;
  headerPadding?: number;
  headerSize?: number;
  isReport?: boolean;
  isReportTvD?: boolean;
  additionalStyle?: React.CSSProperties;
  useScrollCol?: boolean;
  CustomChildrenWrapper?: React.FC<{
    children: React.ReactNode;
  }>;
  showScrollHints?: boolean;
  id?: string;
}> = ({
  ref,
  children,
  header,
  headerSize = 64,
  headerPadding = 0,
  isReport = false,
  isReportTvD = false,
  useScrollCol = false,
  CustomChildrenWrapper,
  showScrollHints = false,
  additionalStyle = {},
  id = "",
}) => {
  const { themeStyle } = useCustomTheme();

  return (
    <Row gutter={[0, 0]} ref={ref}>
      <Col
        span={24}
        style={{
          padding: isReportTvD ? "0" : "14px 0",
          backgroundColor: themeStyle.colors.tertiary_chart_bg,
          height: `${headerSize}px`,
          zIndex: zIndexLayer.high,
          borderBottom: `1px solid ${themeStyle.colors.primary_accent}`,
          ...(isReportTvD
            ? {
                display: "flex",
                padding: "0 24px",
              }
            : {}),
          ...additionalStyle,
        }}
      >
        {header}
      </Col>
      {CustomChildrenWrapper ? (
        <CustomChildrenWrapper>{children}</CustomChildrenWrapper>
      ) : useScrollCol ? (
        <ScrollCol id={id} hz={headerPadding + headerSize} $isReport={isReport} $isReportTvD={isReportTvD}>
          {children}
        </ScrollCol>
      ) : (
        <HiddenScrollbarColumn
          headerPadding={headerPadding}
          headerSize={headerSize}
          isReport={isReport}
          isReportTvD={isReportTvD}
          showScrollHints={showScrollHints}
        >
          {children}
        </HiddenScrollbarColumn>
      )}
    </Row>
  );
};

export const Section = styled.div`
  min-height: 100%;
  padding: 0 24px;
`;

export default CoreLayout;
