import type { UseQueryOptions } from "@tanstack/react-query";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import type { UserLensPositionDto, UserLensPositionSetDto } from "apis/oag";
import { GenericUserLensesApi } from "apis/oag/apis";
import type { DashboardBlockSize } from "apis/oag/models/DashboardBlockSize";
import type { DashboardType } from "apis/oag/models/DashboardType";
import { apiConfig } from "utils/apiConfig";
import type { PDLensPositionsByDashboardTypeQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { useDashboardType } from "./useDashboardType";

const api = new GenericUserLensesApi(apiConfig);

export async function fetchUserAllLensPosition(
  dashboardType: DashboardType,
  signal: AbortSignal,
): Promise<UserLensPositionSetDto[]> {
  const data = await api.apiGenericUserLensesPositionsDashboardTypeGet({ dashboardType}, { signal });
  return data;
}

export function useAllLensGridPosition(options?: UseQueryOptions<UserLensPositionSetDto[]>) {
  const { dashboardType } = useDashboardType();
  const queryClient = useQueryClient();

  const QueryKey: PDLensPositionsByDashboardTypeQueryKey = {
    dashboardType,
    uid: RequestUID.lensPositions,
    type: PDQueryType.LENS_POSITIONS,
  };

  return {
    query: useSuspenseQuery<UserLensPositionSetDto[]>({
      queryKey: [QueryKey],
      queryFn: ({ signal }) => fetchUserAllLensPosition(dashboardType, signal),
      ...options,
    }),
    setQueryPositionData: (newPositionData: UserLensPositionDto[], dashboardWidth: DashboardBlockSize) => {
      queryClient.setQueriesData(
        {
          queryKey: [QueryKey],
        },
        (old: UserLensPositionSetDto[] | undefined) => {
          if (!old) return old;
          const dashboardIndex = old.findIndex((size) => size.dashboardWidth === dashboardWidth);
          const oldPositions = old[dashboardIndex]?.positions ?? [];
          const newDashboard = { ...old[dashboardIndex] };
          const existingLensInOldDashboard = oldPositions.map((lens) => lens.lensId);

          newPositionData.forEach((newPosData) => {
            if (existingLensInOldDashboard.includes(newPosData.lensId)) {
              const idx = newDashboard.positions.findIndex((someLens) => someLens.lensId === newPosData.lensId);
              newDashboard.positions[idx] = newPosData;
            } else {
              if (!newDashboard.positions) newDashboard.positions = [];
              newDashboard.positions.push(newPosData);
            }
          });

          const newEntry = [...old.slice(0, dashboardIndex), newDashboard, ...old.slice(dashboardIndex + 1)];

          return newEntry;
        },
      );
    },
  };
}
