/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RigScorecardLensType } from './RigScorecardLensType';
import {
    RigScorecardLensTypeFromJSON,
    RigScorecardLensTypeFromJSONTyped,
    RigScorecardLensTypeToJSON,
} from './RigScorecardLensType';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';

/**
 * 
 * @export
 * @interface RigScorecardUserLensDto
 */
export interface RigScorecardUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof RigScorecardUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RigScorecardUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof RigScorecardUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {StandKpiType}
     * @memberof RigScorecardUserLensDto
     */
    cardKpiType: StandKpiType;
    /**
     * 
     * @type {boolean}
     * @memberof RigScorecardUserLensDto
     */
    acceptsExceptions: boolean;
    /**
     * 
     * @type {RigScorecardLensType}
     * @memberof RigScorecardUserLensDto
     */
    scorecardType: RigScorecardLensType;
}



/**
 * Check if a given object implements the RigScorecardUserLensDto interface.
 */
export function instanceOfRigScorecardUserLensDto(value: object): value is RigScorecardUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('cardKpiType' in value) || value['cardKpiType'] === undefined) return false;
    if (!('acceptsExceptions' in value) || value['acceptsExceptions'] === undefined) return false;
    if (!('scorecardType' in value) || value['scorecardType'] === undefined) return false;
    return true;
}

export function RigScorecardUserLensDtoFromJSON(json: any): RigScorecardUserLensDto {
    return RigScorecardUserLensDtoFromJSONTyped(json, false);
}

export function RigScorecardUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigScorecardUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'cardKpiType': StandKpiTypeFromJSON(json['CardKpiType']),
        'acceptsExceptions': json['AcceptsExceptions'],
        'scorecardType': RigScorecardLensTypeFromJSON(json['ScorecardType']),
    };
}

export function RigScorecardUserLensDtoToJSON(value?: RigScorecardUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'CardKpiType': StandKpiTypeToJSON(value['cardKpiType']),
        'AcceptsExceptions': value['acceptsExceptions'],
        'ScorecardType': RigScorecardLensTypeToJSON(value['scorecardType']),
    };
}

