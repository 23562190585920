import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import { Text } from "@visx/text";
import { useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import styled from "styled-components";
import { useCustomTheme } from "utils/useTheme";

export const TARGET_PADDING = 5;
export const TARGET_TAG_PILL_WIDTH = 24;
export const TARGET_DEFAULT_PILL_WIDTH = 20;
export const TARGET_PILL_TRIANGLE_WIDTH = 10;
export const TARGET_FULL_PILL_WIDTH = 2 * (TARGET_DEFAULT_PILL_WIDTH + TARGET_PILL_TRIANGLE_WIDTH);

const StyledText = styled(Text)`
  tspan {
    fill: ${({ theme }) => theme.themeStyle.colors.light_typography_inverted};
    paint-order: stroke;
  }
`;

function Triangle({ x, y, inverted }: { x: number; y: number; inverted?: boolean }) {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  return (
    <svg
      width="9"
      x={x}
      y={y}
      height="20"
      viewBox="3.536 0 9.464 20.029"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ color: themeColors.primary_typography }}
    >
      <g transform={inverted ? "matrix(-1.00,0.00,0.00,1.00,17,0)" : ""}>
        <path
          d="M 4.761 20.032 L 4.798 20.032 C 5.371 20.032 5.914 19.754 6.272 19.28 L 12.79 10.64 C 13.065 10.274 13.065 9.753 12.79 9.389 L 6.272 0.748 C 5.914 0.271 5.371 -0.004 4.798 -0.004 L 4.761 -0.004 L 4.761 20.032 Z"
          fill="currentColor"
          transform="matrix(-1, 0, 0, -1, 17.757251, 20.027999)"
        ></path>
      </g>
    </svg>
  );
}

function TargetLine({
  start,
  end,
  y,
  label,
  showTag,
  strokeDasharray,
}: {
  start: number;
  end: number;
  y: number;
  label: string;
  detailed: boolean;
  showTag?: boolean;
  strokeDasharray?: string;
}) {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const { width, ref: textRef } = useResizeDetector({ refreshOptions: { leading: true, trailing: true } });
  const refPillWidth = (width || TARGET_DEFAULT_PILL_WIDTH) + TARGET_PILL_TRIANGLE_WIDTH;
  const [pillWidth, setPillWidth] = useState(refPillWidth);

  useEffect(() => {
    if (refPillWidth > pillWidth) setPillWidth(refPillWidth);
  }, [refPillWidth, pillWidth]);

  const columnWidth = end - start;
  const targetPillWidth = TARGET_TAG_PILL_WIDTH;

  return (
    <Group>
      <Line
        from={{ x: start - TARGET_PADDING, y: y }}
        to={{ x: end + TARGET_PADDING, y: y }}
        strokeWidth={3}
        strokeDasharray={strokeDasharray}
        stroke={themeColors.primary_typography}
      />
      {label && columnWidth > pillWidth ? (
        <>
          <rect
            textAnchor="middle"
            height={20}
            width={pillWidth}
            y={y - 10}
            x={end - pillWidth}
            fill={themeColors.primary_typography}
            rx={2}
          />

          <StyledText
            innerRef={textRef}
            x={end - pillWidth / 2}
            y={y}
            textAnchor="middle"
            verticalAnchor="middle"
            fontSize={12}
          >
            {label}
          </StyledText>
          <Triangle x={end - pillWidth - 8} y={y - 10}></Triangle>
        </>
      ) : null}
      {showTag && columnWidth >= pillWidth + targetPillWidth ? (
        <Group>
          <rect
            textAnchor="middle"
            height={20}
            width={targetPillWidth}
            y={y - 10}
            x={start}
            fill={themeColors.primary_typography}
            rx={2}
          />
          <StyledText x={start + targetPillWidth / 2} y={y} textAnchor="middle" verticalAnchor="middle" fontSize={12}>
            TGT
          </StyledText>
          <Triangle x={start + targetPillWidth - 1} y={y - 10} inverted></Triangle>
        </Group>
      ) : null}
    </Group>
  );
}
export default TargetLine;
