import { Grid } from "@visx/grid";
import { scaleLinear } from "@visx/scale";
import { useCustomTheme } from "utils/useTheme";

export const MockTorqueAndDrag = () => {
  const {
    themeStyle: { colors: themeColors },
  } = useCustomTheme();
  const categoryScale = scaleLinear({
    domain: [0, 100],
    range: [0, 326],
  });
  const valueScale = scaleLinear({
    domain: [0, 100],
    range: [0, 124],
  });
  return (
    <svg width="326" height="134" viewBox="0 0 326 134" xmlns="http://www.w3.org/2000/svg">
      <Grid
        xScale={categoryScale}
        yScale={valueScale}
        top={0}
        left={0}
        width={326}
        height={135}
        stroke={themeColors.primary_chart_accent}
        rowTickValues={[0, 10, 30, 50, 70, 90]}
        columnTickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90]}
      />
      <g fill="none" fillRule="evenodd" transform={`translate(${(326 - 128) / 2}, 0)`}>
        <g opacity=".6" stroke="#0D9B83">
          <path d="m19 1 12.5 21.12 13 22.704L51 66.472l-3.5 22.704L35 111.88 1 133" />
          <path d="m20 2 23 31.694 8.5 12.677L58 66.444v24.298l-6.5 20.6L31.5 133" />
          <path d="m96 133-7.046-21.12-6.039-22.176L75.366 67 64.797 46.408 41.157 22.12 20 1l18.627 22.176 20.131 23.232 7.55 21.12 4.025 22.176v23.232L66.307 133" />
          <path d="m20 1 30.638 26.176 19.1 18.176 16.577 22.176L98.37 89.704l13.563 21.648L126 133" />
        </g>
        <g transform="translate(28 20)">
          <circle fill="#0D9B83" cx="34" cy="28" r="4" />
          <circle fill="#0D9B83" cx="36" cy="17" r="4" />
          <circle fill="#0D9B83" cx="55" cy="35" r="4" />
          <circle fill="#097563" cx="15" cy="28" r="4" />
          <circle fill="#097563" cx="4" cy="4" r="4" />
          <circle fill="#0D9B83" cx="64" cy="99" r="4" />
          <circle fill="#097563" cx="11" cy="79" r="4" />
          <circle fill="#097563" cx="23" cy="42" r="4" />
          <circle fill="#0D9B83" cx="51" cy="53" r="4" />
          <circle fill="#0D9B83" cx="58" cy="79" r="4" />
        </g>
      </g>
    </svg>
  );
};
