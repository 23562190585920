/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClarityUiType = {
    Web: 'Web',
    Pwa: 'Pwa'
} as const;
export type ClarityUiType = typeof ClarityUiType[keyof typeof ClarityUiType];


export function instanceOfClarityUiType(value: any): boolean {
    for (const key in ClarityUiType) {
        if (Object.prototype.hasOwnProperty.call(ClarityUiType, key)) {
            if (ClarityUiType[key as keyof typeof ClarityUiType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ClarityUiTypeFromJSON(json: any): ClarityUiType {
    return ClarityUiTypeFromJSONTyped(json, false);
}

export function ClarityUiTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClarityUiType {
    return json as ClarityUiType;
}

export function ClarityUiTypeToJSON(value?: ClarityUiType | null): any {
    return value as any;
}

