/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RigCardFactKpiValue
 */
export interface RigCardFactKpiValue {
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    value?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    highlightedValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    readonly rank: number;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    readonly trend: number;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    exception?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactKpiValue
     */
    highlightedException?: number | null;
}

/**
 * Check if a given object implements the RigCardFactKpiValue interface.
 */
export function instanceOfRigCardFactKpiValue(value: object): value is RigCardFactKpiValue {
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('trend' in value) || value['trend'] === undefined) return false;
    return true;
}

export function RigCardFactKpiValueFromJSON(json: any): RigCardFactKpiValue {
    return RigCardFactKpiValueFromJSONTyped(json, false);
}

export function RigCardFactKpiValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardFactKpiValue {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['Value'] == null ? undefined : json['Value'],
        'highlightedValue': json['HighlightedValue'] == null ? undefined : json['HighlightedValue'],
        'rank': json['Rank'],
        'trend': json['Trend'],
        'exception': json['Exception'] == null ? undefined : json['Exception'],
        'highlightedException': json['HighlightedException'] == null ? undefined : json['HighlightedException'],
    };
}

export function RigCardFactKpiValueToJSON(value?: Omit<RigCardFactKpiValue, 'Rank'|'Trend'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Value': value['value'],
        'HighlightedValue': value['highlightedValue'],
        'Exception': value['exception'],
        'HighlightedException': value['highlightedException'],
    };
}

