import { scaleBand, scaleLinear } from "@visx/scale";
import type { DateDto } from "apis/oag/models/DateDto";
import type { ScrollbarRange } from "components/Lenses/common/Scrollbar";
import { getManualAxisLabelSize } from "components/Lenses/ContainerLens/common/utils/utils";
import type { Numeric } from "d3-array";
import { quantile, range as generateRange } from "d3-array";
import { useCallback, useMemo } from "react";

interface useDiscountTimeAxisParams<T> {
  plotWidth: number;
  zoomRate?: number;
  scrollbarRange?: ScrollbarRange;
  xScaleDomain: number[];
  overrideAxisLabelSize?: number;
  series: T[];
}

function useDiscontinuousTimeAxis<T extends { index?: number; at?: DateDto }>({
  plotWidth,
  zoomRate = 1,
  scrollbarRange,
  xScaleDomain,
  overrideAxisLabelSize,
  series,
}: useDiscountTimeAxisParams<T>) {
  const chunksCount = Math.floor((plotWidth * zoomRate) / (overrideAxisLabelSize ?? getManualAxisLabelSize())) - 1;
  const [xScale, xScaleDate] = useMemo(() => {
    const chartSize = plotWidth * zoomRate;
    const scrolledStartX = scrollbarRange ? Math.floor(chartSize * scrollbarRange.startX) : 0;
    const range: [Numeric, Numeric] & number[] = [-scrolledStartX, chartSize - scrolledStartX];

    return [
      scaleLinear<number>({
        domain: xScaleDomain,
        range,
        clamp: true,
      }),

      scaleBand<number>({
        domain: generateRange(chunksCount),
        range,
        paddingInner: 0,
        paddingOuter: 0,
      }),
    ];
  }, [chunksCount, plotWidth, scrollbarRange, xScaleDomain, zoomRate]);

  const chunkLength = useMemo(() => series?.length / chunksCount, [chunksCount, series]);

  const getAverageChunkDate = useCallback(
    (chunkIndex: number) => {
      const start =
        quantile(
          (series ?? []).map((v, i) => i),
          chunkIndex / chunksCount,
        ) || 0;
      const idx = Math.floor(start + chunkLength / 2);

      return series[idx]?.at;
    },
    [chunkLength, chunksCount, series],
  );
  return { xScale, xScaleDate, chunksCount, getAverageChunkDate };
}

export default useDiscontinuousTimeAxis;
