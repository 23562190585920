import { Title } from "atoms/Typography";
import styled from "styled-components";
import colors from "utils/colors";
import { Col, Row } from "utils/componentLibrary";

// TODO remove unused styles

export const StyledTab = styled.div<{ $isActive: boolean }>`
  color: ${({ theme }) => theme.themeStyle.colors.white_gray};
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px 16px 16px;
  position: relative;
  cursor: pointer;

  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.themeStyle.colors.primary_button_bg : "transparent"};
    border-radius: 5px 5px 0 0;
  }
`;

export const NotificationHeader = styled.div`
  padding: 12px 24px;
  display: flex;
  gap: 48px;
  height: 80px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_quaterniary_bg};
`;

export const CustomCol = styled(Col)`
  display: flex;
  align-items: center;
  column-gap: 12px;
  &,
  h3 {
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;
export const CustomRow = styled(Row)`
  width: 100%;
  gap: 24px;
`;

export const TasksPage = styled.div`
  background-color: ${({ theme }) => (theme.isDark ? colors.gluon_grey : theme.themeStyle.colors.primary_accent)};
  height: 100%;
`;

export const TitleBar = styled.div`
  padding: 15px 24px;
  height: 68px;
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_quaterniary_bg};
  display: flex;
  place-items: center;
  justify-content: space-between;
  ${Title} {
    font-weight: 700 !important;
  }
`;

export const StyledTabCounter = styled.div`
  color: ${({ theme }) => theme.themeStyle.colors.white_gray};
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  padding: 2px 8px;
  border-radius: 16px;
`;

export const StyledNotificationContainer = styled.div`
  background-color: ${({ theme }) => theme.themeStyle.colors.secondary_chart_bg};
  padding: 20px;
  margin: 4px 16px 16px 16px;
  border-radius: 8px;

  height: calc(100vh - 68px - 64px - 64px - 16px - 16px - 16px);
  overflow-y: scroll;
`;

export const TabName = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-style: normal;
  font-weight: 700;
`;
