/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ServiceMessageType } from './ServiceMessageType';
import {
    ServiceMessageTypeFromJSON,
    ServiceMessageTypeFromJSONTyped,
    ServiceMessageTypeToJSON,
} from './ServiceMessageType';

/**
 * 
 * @export
 * @interface ServiceMessageDto
 */
export interface ServiceMessageDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceMessageDto
     */
    text?: string | null;
    /**
     * 
     * @type {ServiceMessageType}
     * @memberof ServiceMessageDto
     */
    type: ServiceMessageType;
}



/**
 * Check if a given object implements the ServiceMessageDto interface.
 */
export function instanceOfServiceMessageDto(value: object): value is ServiceMessageDto {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function ServiceMessageDtoFromJSON(json: any): ServiceMessageDto {
    return ServiceMessageDtoFromJSONTyped(json, false);
}

export function ServiceMessageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMessageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['Text'] == null ? undefined : json['Text'],
        'type': ServiceMessageTypeFromJSON(json['Type']),
    };
}

export function ServiceMessageDtoToJSON(value?: ServiceMessageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Text': value['text'],
        'Type': ServiceMessageTypeToJSON(value['type']),
    };
}

