/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GeneratorLoadProfileFactSetDto,
  GeneratorLoadProfileUserLensDto,
  RigPowerQueryDto,
} from '../models/index';
import {
    GeneratorLoadProfileFactSetDtoFromJSON,
    GeneratorLoadProfileFactSetDtoToJSON,
    GeneratorLoadProfileUserLensDtoFromJSON,
    GeneratorLoadProfileUserLensDtoToJSON,
    RigPowerQueryDtoFromJSON,
    RigPowerQueryDtoToJSON,
} from '../models/index';

export interface ApiGeneratorLoadProfileUserLensesIdFactsPutRequest {
    id: number;
    rigPowerQueryDto?: RigPowerQueryDto;
}

export interface ApiGeneratorLoadProfileUserLensesIdPutRequest {
    id: number;
    generatorLoadProfileUserLensDto?: GeneratorLoadProfileUserLensDto;
}

export interface ApiGeneratorLoadProfileUserLensesPostRequest {
    generatorLoadProfileUserLensDto?: GeneratorLoadProfileUserLensDto;
}

/**
 * 
 */
export class GeneratorLoadProfileUserLensesApi extends runtime.BaseAPI {

    /**
     */
    async apiGeneratorLoadProfileUserLensesIdFactsPutRaw(requestParameters: ApiGeneratorLoadProfileUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneratorLoadProfileFactSetDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiGeneratorLoadProfileUserLensesIdFactsPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GeneratorLoadProfileUserLenses/{id}/Facts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RigPowerQueryDtoToJSON(requestParameters['rigPowerQueryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorLoadProfileFactSetDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGeneratorLoadProfileUserLensesIdFactsPut(requestParameters: ApiGeneratorLoadProfileUserLensesIdFactsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneratorLoadProfileFactSetDto> {
        const response = await this.apiGeneratorLoadProfileUserLensesIdFactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGeneratorLoadProfileUserLensesIdPutRaw(requestParameters: ApiGeneratorLoadProfileUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneratorLoadProfileUserLensDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling apiGeneratorLoadProfileUserLensesIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GeneratorLoadProfileUserLenses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GeneratorLoadProfileUserLensDtoToJSON(requestParameters['generatorLoadProfileUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorLoadProfileUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGeneratorLoadProfileUserLensesIdPut(requestParameters: ApiGeneratorLoadProfileUserLensesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneratorLoadProfileUserLensDto> {
        const response = await this.apiGeneratorLoadProfileUserLensesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGeneratorLoadProfileUserLensesPostRaw(requestParameters: ApiGeneratorLoadProfileUserLensesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GeneratorLoadProfileUserLensDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/GeneratorLoadProfileUserLenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GeneratorLoadProfileUserLensDtoToJSON(requestParameters['generatorLoadProfileUserLensDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneratorLoadProfileUserLensDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiGeneratorLoadProfileUserLensesPost(requestParameters: ApiGeneratorLoadProfileUserLensesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GeneratorLoadProfileUserLensDto> {
        const response = await this.apiGeneratorLoadProfileUserLensesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
