import { useImperativeTimelineEventScroll } from "components/TvDChart/components/TimelineEventIndicator/useImperativeTimelineEventScroll";
import type { CSSProperties } from "react";
import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";

import type { IEventProps } from "./style";
import * as Styled from "./style";

type TimelineEventIndicatorProps = React.HTMLAttributes<HTMLDivElement> &
  IEventProps & {
    onClickOutside?: () => void;
    eventId?: number;
    style?: CSSProperties;
    preventResetState?: boolean;
    isPlacedInTimeline?: boolean;
  };

export const TimelineEventIndicator = (props: TimelineEventIndicatorProps) => {
  const { onClickOutside, eventId, style, preventResetState = false, onClick, ...restProps } = props;
  const eventIconRef = useRef<HTMLDivElement>(null);
  const { scrollToWithDelay, getDomEventId } = useImperativeTimelineEventScroll();

  const selectedEvent = useAppSelector((state) => state.timeline.selectedTimelineEvent);
  const dispatch = useAppDispatch();

  const handleOnClick = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev.stopPropagation(); // allowing bubbling would cause deselection of the event via the effect below
      onClick?.(ev);
      if (selectedEvent && selectedEvent === eventId && !preventResetState) {
        dispatch({
          type: "SET_SELECTED_TIMELINE_EVENT",
          payload: null,
        });
      } else if (Number.isFinite(eventId) && eventId !== undefined) {
        dispatch({
          type: "SET_SELECTED_TIMELINE_EVENT",
          payload: eventId,
        });
        scrollToWithDelay(eventId);
      }
    },
    [dispatch, eventId, onClick, preventResetState, scrollToWithDelay, selectedEvent],
  );

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (eventIconRef.current && !eventIconRef.current.contains(e.target as Node)) {
        onClickOutside?.();
        dispatch({
          type: "SET_SELECTED_TIMELINE_EVENT",
          payload: null,
        });
      }
    };

    if (!onClickOutside) {
      return;
    } else {
      document.addEventListener("click", listener);
      return () => {
        document.removeEventListener("click", listener);
      };
    }
  }, [dispatch, onClickOutside]);

  return (
    <Styled.EventIcon
      {...restProps}
      ref={eventIconRef}
      onClick={handleOnClick}
      style={style}
      id={props.isPlacedInTimeline ? getDomEventId(eventId) : undefined}
    />
  );
};
