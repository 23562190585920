import styled from "styled-components";
import colors from "utils/colors";

export const OuterContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin-right: 16px;
`;

export const Container = styled.div<{ $isActive: boolean }>`
  position: relative;
  svg {
    font-size: 20px;
    fill: ${({ $isActive }) => ($isActive ? colors.white : "currentColor")};
    color: ${({ $isActive }) => ($isActive ? colors.white : "currentColor")};
  }
`;

export const CircleCount = styled.div`
  color: ${colors.white};
  top: -10px;
  right: -10px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  font-size: 11px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.error};
`;
