/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PlanDrillingParameterDto } from './PlanDrillingParameterDto';
import {
    PlanDrillingParameterDtoFromJSON,
    PlanDrillingParameterDtoFromJSONTyped,
    PlanDrillingParameterDtoToJSON,
} from './PlanDrillingParameterDto';
import type { PlanFormationDto } from './PlanFormationDto';
import {
    PlanFormationDtoFromJSON,
    PlanFormationDtoFromJSONTyped,
    PlanFormationDtoToJSON,
} from './PlanFormationDto';
import type { PlanActivityDto } from './PlanActivityDto';
import {
    PlanActivityDtoFromJSON,
    PlanActivityDtoFromJSONTyped,
    PlanActivityDtoToJSON,
} from './PlanActivityDto';

/**
 * 
 * @export
 * @interface PlanDto
 */
export interface PlanDto {
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    wellId: number;
    /**
     * 
     * @type {string}
     * @memberof PlanDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    startHoleDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    startFormationDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    startParameterDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanDto
     */
    totalDuration: number;
    /**
     * 
     * @type {Array<PlanActivityDto>}
     * @memberof PlanDto
     */
    activities: Array<PlanActivityDto>;
    /**
     * 
     * @type {Array<PlanFormationDto>}
     * @memberof PlanDto
     */
    formations: Array<PlanFormationDto>;
    /**
     * 
     * @type {Array<PlanDrillingParameterDto>}
     * @memberof PlanDto
     */
    drillingParameters: Array<PlanDrillingParameterDto>;
}

/**
 * Check if a given object implements the PlanDto interface.
 */
export function instanceOfPlanDto(value: object): value is PlanDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('totalDuration' in value) || value['totalDuration'] === undefined) return false;
    if (!('activities' in value) || value['activities'] === undefined) return false;
    if (!('formations' in value) || value['formations'] === undefined) return false;
    if (!('drillingParameters' in value) || value['drillingParameters'] === undefined) return false;
    return true;
}

export function PlanDtoFromJSON(json: any): PlanDto {
    return PlanDtoFromJSONTyped(json, false);
}

export function PlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'wellId': json['WellId'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'startHoleDepth': json['StartHoleDepth'] == null ? undefined : json['StartHoleDepth'],
        'startFormationDepth': json['StartFormationDepth'] == null ? undefined : json['StartFormationDepth'],
        'startParameterDepth': json['StartParameterDepth'] == null ? undefined : json['StartParameterDepth'],
        'totalDuration': json['TotalDuration'],
        'activities': ((json['Activities'] as Array<any>).map(PlanActivityDtoFromJSON)),
        'formations': ((json['Formations'] as Array<any>).map(PlanFormationDtoFromJSON)),
        'drillingParameters': ((json['DrillingParameters'] as Array<any>).map(PlanDrillingParameterDtoFromJSON)),
    };
}

export function PlanDtoToJSON(value?: PlanDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'WellId': value['wellId'],
        'Description': value['description'],
        'StartHoleDepth': value['startHoleDepth'],
        'StartFormationDepth': value['startFormationDepth'],
        'StartParameterDepth': value['startParameterDepth'],
        'TotalDuration': value['totalDuration'],
        'Activities': ((value['activities'] as Array<any>).map(PlanActivityDtoToJSON)),
        'Formations': ((value['formations'] as Array<any>).map(PlanFormationDtoToJSON)),
        'DrillingParameters': ((value['drillingParameters'] as Array<any>).map(PlanDrillingParameterDtoToJSON)),
    };
}

