import { linkedFilters } from "hooks/useFilterTags";
import React, { useImperativeHandle, useState } from "react";
import { Track } from "services/Mixpanel";

import { SelectorButton } from "./SelectorButton";
import { StyledGap } from "./styled";
import { useLinkedFilters } from "./useLinkedFilters";
import { linkedFiltersIconMap, popupInitialState } from "./utils";

export type LinkedSelectorRef = {
  closePopups: () => void;
};

export const LinkedSelectors = React.forwardRef(
  (
    {
      storeName,
      keepEvengreenOnly,
    }: {
      keepEvengreenOnly: boolean;
      storeName: "rigsCommon" | "allWells" | "evergreenWells";
    },
    ref: React.ForwardedRef<LinkedSelectorRef>,
  ) => {
    const { options, resetFilters, selectedValues, updateFilters, isSelected } =
      useLinkedFilters({
        keepEvengreenOnly,
        storeName,
        closePopups: () => {
          setPopupState(popupInitialState);
        },
      });
    const [popupState, setPopupState] =
      useState<Record<(typeof linkedFilters)[number], boolean>>(
        popupInitialState,
      );

    useImperativeHandle(
      ref,
      () => ({
        closePopups: () => {
          setPopupState(popupInitialState);
        },
      }),
      [],
    );

    return (
      <StyledGap>
        {[...linkedFilters]
          .filter((x) =>
            storeName === "allWells" || storeName === "evergreenWells"
              ? x !== "wells"
              : true,
          )
          .map((filterName) => {
            const filterNameCapitalized =
              filterName.charAt(0).toUpperCase() + filterName.slice(1);
            return (
              <SelectorButton
                key={filterName}
                onReset={() => {
                  Track.clickEvent(`All Rigs - Reset ${filterNameCapitalized}`);
                  resetFilters();
                }}
                options={options?.[filterName] ?? []}
                placeholder={`Search ${filterNameCapitalized}`}
                selectedValues={
                  selectedValues[filterName] ??
                  (options?.[filterName] ?? []).map((option) => option.id)
                }
                onChange={(e) => {
                  updateFilters(filterName)(e);
                  Track.clickEvent(
                    `All Rigs - Select ${filterNameCapitalized}`,
                  );
                  setPopupState(popupInitialState);
                }}
                open={popupState[filterName]}
                onOpenChange={(e) => {
                  setPopupState((prev) => ({
                    ...prev,
                    [filterName]: e,
                  }));
                }}
                isSelected={isSelected[filterName]}
                tooltipName={filterNameCapitalized}
                name={linkedFiltersIconMap[filterName]}
              />
            );
          })}
      </StyledGap>
    );
  },
);
