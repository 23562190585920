import type { BinCoordinates, DecimalRange, ParameterHeatmapBin } from "apis/oag";
import {
  ACTIVE_HEX_DOM_ID,
  getHeatmapDimensionsForParent,
  GLOWING_FILTER_ID,
} from "components/Lenses/ContainerLens/ParameterHeatmapKpi/utils";
import * as d3 from "d3";
import { HexBin as d3Hexbin } from "libs/HexBin";
import type { RefObject } from "react";
import colors from "utils/colors";
import type { IValueWithUnit } from "utils/interfaces/Well/interfaces";

import { AXIS_TRANSLATE, BIN_PADDING_X, DEFAULT_TRANSLATE, LINEAR_GRADIENT_WIDTH } from "./HoneyCombChart";

export type ChartBin = { [key: string]: any } & ParameterHeatmapBin;
export enum HoveredBinType {
  GRID,
  ACTIVE,
  PRIMARY,
  SECONDARY,
}
interface RenderHoneycombParams {
  bins: ChartBin[];
  xAxisValueRange: DecimalRange;
  yAxisValueRange: DecimalRange;
  containerInfo: {
    svgEl: RefObject<SVGElement>;
    parentContainerWidth: number;
    parentContainerHeight: number;
  };
  isLoading: boolean;
  isDarkTheme: boolean;
  emptyBinColor: string;
  trackLabels: { x: string; y: string; z: string };
  axesUnitsTransformer: { x: IValueWithUnit; y: IValueWithUnit; z: IValueWithUnit };
  onBinHover: (e: MouseEvent, hoveredBinType: HoveredBinType, binData?: ChartBin) => void;
  onBinHoverOut: () => void;
  activeBinSettings?: { bin: BinCoordinates; isRealtime: boolean };
  overlayBinSettings?: { primary?: BinCoordinates; secondary?: BinCoordinates };
  title?: string;
}

export const render = (params: RenderHoneycombParams) => {
  const {
    bins,
    xAxisValueRange,
    yAxisValueRange,
    containerInfo: { svgEl, parentContainerWidth, parentContainerHeight },
    isLoading,
    isDarkTheme,
    onBinHover,
    onBinHoverOut,
    emptyBinColor,
    trackLabels,
    axesUnitsTransformer,
    activeBinSettings,
    overlayBinSettings,
    title,
  } = params;

  const gradientColors = [
    "#0b6e57",
    "#248f79",
    "#3eaf9c",
    "#5f9bb8",
    "#8289d5",
    "#9e83e1",
    "#b98ed8",
    "#d399cf",
    "#e5aecb",
    "#f5c3c6",
  ];

  const dimensions = getHeatmapDimensionsForParent({
    parentContainerWidth,
    parentContainerHeight,
  });

  const activeBin = activeBinSettings?.bin || { x: 0, y: 0 };

  // Create the visual hexbin provider (order of the calls matters)

  const hexbin = new d3Hexbin<ChartBin>()
    .bottomYOrigin(true)
    .x((d) => d.coordinates.x)
    .y((d) => d.coordinates.y)
    .minBinCounts(dimensions.xBinCount, dimensions.yBinCount)
    .maxSize(dimensions.width.withPaddingAndLegend, dimensions.height.widthPadding)
    .setActiveBinCoords(activeBin)
    .setOverlayBinsCoords(overlayBinSettings?.primary, overlayBinSettings?.secondary)
    .bins(bins);

  const [computedWidth, computedHeight] = hexbin.computedSize();

  // Go SVG!
  const svg = d3
    .select(svgEl.current)
    .attr("width", dimensions.width.widthPadding)
    .attr("height", dimensions.height.widthPadding)
    .attr("transform", `translate(${BIN_PADDING_X})`);

  // Data scales are related to actual values with a potentially random domain based on the dataset

  const xDataScale = d3
    .scaleLinear()
    .domain([
      +axesUnitsTransformer.x.toString(xAxisValueRange.min),
      +axesUnitsTransformer.x.toString(xAxisValueRange.max),
    ])
    .range([AXIS_TRANSLATE, computedWidth]);

  const yDataScale = d3
    .scaleLinear()
    .domain([
      +axesUnitsTransformer.y.toString(yAxisValueRange.min),
      +axesUnitsTransformer.y.toString(yAxisValueRange.max),
    ])
    .range([computedHeight - AXIS_TRANSLATE, AXIS_TRANSLATE]);

  const zScale = d3
    .scaleLinear()
    .domain([
      d3.max(bins, (d) => +axesUnitsTransformer.z.toString(d.z)) || 0,
      d3.min(bins, (d) => +axesUnitsTransformer.z.toString(d.z)) || 0,
    ])
    .range([0, 9]);

  // ---------------------------------------------------------------------------------------------- DISPLAY AXIS

  const axisLabelDeltaY = -45;
  const xAxis = d3
    .axisBottom(xDataScale)
    .ticks(Math.floor((dimensions.width.widthPadding * 15) / 1400))
    .tickSizeInner(0)
    .tickSizeOuter(0)
    .tickPadding(12)
    .tickFormat((d) => `${+d}`);

  svg
    .append("text")
    .attr(
      "transform",
      `translate(${dimensions.width.widthPadding / 2},${dimensions.height.widthPadding - axisLabelDeltaY})`,
    )
    .style("text-anchor", "middle")
    .attr("font-weight", "bold")
    .text(trackLabels.x || "X Kpi");

  const yAxis = d3
    .axisLeft(yDataScale)
    .tickSizeInner(0)
    .tickSizeOuter(0)
    .ticks(Math.floor((dimensions.height.widthPadding * 5) / 250))
    .tickPadding(12)
    .tickFormat((d) => `${+d}`);

  svg
    .append("text")
    .attr("y", axisLabelDeltaY)
    .attr("x", -30)
    .attr("font-weight", "bold")
    .attr("dy", "1em")
    .style("text-anchor", "middle")
    .text(trackLabels.y || "Y Kpi");

  if (title) {
    svg
      .append("text")
      .attr("x", dimensions.width.widthPadding / 2)
      .attr("y", axisLabelDeltaY)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("fill", colors.gray)
      .text(title);
  }

  svg
    .append("text")
    .attr("y", axisLabelDeltaY)
    .attr("x", dimensions.width.widthPadding + LINEAR_GRADIENT_WIDTH / 2)
    .attr("font-weight", "bold")
    .attr("dy", "1em")
    .style("text-anchor", "middle")
    .text(trackLabels.z || "Z Kpi");

  svg
    .append("g")
    .attr("transform", `translate(0 ${parentContainerHeight || 0})`)
    .call(xAxis)
    .select(".domain")
    .remove();

  svg.append("g").attr("class", "axis y-axis").call(yAxis).select(".domain").remove();

  // ---------------------------------------------------------------------------------------------- DRAW REST

  const svgContainer = svg
    .append("svg")
    .attr("style", "overflow: visible")
    .attr("width", dimensions.width.withPaddingAndLegend)
    .attr("height", dimensions.height.widthPadding);

  const hexbinsAndMeshLayer = svgContainer.append("g").attr("transform", `translate(${dimensions.defaultTranslate} 0)`);
  const visualAidsLayer = svgContainer.append("g").attr("transform", `translate(${dimensions.defaultTranslate} 0)`);

  hexbinsAndMeshLayer
    .append("path")
    .attr("class", "mesh")
    .attr("d", hexbin.mesh())
    .attr("fill", emptyBinColor)
    .attr("opacity", 0.5)
    .attr("stroke", isDarkTheme ? colors.black : colors.light_gray)
    .attr("stroke-width", 1);

  hexbinsAndMeshLayer
    .selectAll("path.hexagon")
    .data(hexbin.visualBins)
    .enter()
    .append("path")
    .attr("d", hexbin.hexagon())
    .attr(
      "transform",
      ({ xCenterPosition, yCenterPosition }) => `translate(${xCenterPosition} ${yCenterPosition}), scale(0.95)`,
    )
    .attr("filter", isLoading ? "hue-rotate(180deg) saturate(0%)" : null)
    .attr("fill", (d) => gradientColors[Math.floor(zScale(+axesUnitsTransformer.z.toString(d.bin.z)))])
    .attr("id", ({ bin }) =>
      bin.coordinates.x === activeBin.x && bin.coordinates.y === activeBin.y ? ACTIVE_HEX_DOM_ID : null,
    )
    .on("mouseover", function (ev, chartBin) {
      const hoveredBinType =
        d3.select(this).attr("id") === ACTIVE_HEX_DOM_ID ? HoveredBinType.ACTIVE : HoveredBinType.GRID;
      d3.select(this).attr("stroke-width", 3).attr("stroke", "black");

      onBinHover(ev, hoveredBinType, chartBin.bin);
    })
    .on("mouseout", function () {
      if (d3.select(this).attr("id") === ACTIVE_HEX_DOM_ID) {
        d3.select(this).attr("stroke-width", 2).attr("stroke", colors.well_color);
      } else d3.select(this).attr("stroke", "transparent");

      onBinHoverOut();
    });

  if (hexbin.activeBin && !isLoading) {
    const activeBinElement = visualAidsLayer
      .selectAll("path.hexagon")
      .data([hexbin.activeBin])
      .enter()
      .append("path")
      .attr("d", hexbin.hexagon())
      .attr("transform", (bin) => `translate(${bin.xCenterPosition} ${bin.yCenterPosition}), scale(0.9)`)
      .attr("fill", (d) => gradientColors[Math.floor(zScale(+axesUnitsTransformer.z.toString(d.bin.z)))])
      .attr("stroke-width", 2)
      .attr("stroke", colors.well_color)
      .attr("pointer-events", "none");

    // Copy of the bin with white stroke
    activeBinElement
      .clone()
      .attr("transform", (bin) => `translate(${bin.xCenterPosition} ${bin.yCenterPosition}), scale(0.8)`)
      .attr("stroke-width", 2)
      .attr("stroke", colors.white);

    // Glowing animations
    if (activeBinSettings?.isRealtime) {
      activeBinElement
        .attr("filter", `url(#${GLOWING_FILTER_ID})`)
        .append("animate")
        .attr("attributeName", "opacity")
        .attr("values", "0.2;1;0.2")
        .attr("begin", "0s")
        .attr("dur", "2.5s")
        .attr("repeatCount", "indefinite");
    }
  }
  // --------------------------------------- OVERLAYS Focal/Offset

  // Overlays -- focal
  if (hexbin.overlayPrimaryBin) {
    visualAidsLayer
      .selectAll("path.hexagon")
      .data([hexbin.overlayPrimaryBin])
      .enter()
      .append("path")
      .attr("d", hexbin.hexagon())
      .attr("transform", (bin) => `translate(${bin.xCenterPosition} ${bin.yCenterPosition}), scale(2)`)
      .attr("fill", "transparent")
      .attr("stroke-width", 1)
      .attr("stroke", colors.well_color)
      .attr("pointer-events", "stroke")
      .on("mouseover", function (ev) {
        onBinHover(ev, HoveredBinType.PRIMARY);
      })
      .on("mouseout", onBinHoverOut);
  }

  // Overlays --  offset
  if (hexbin.overlaySecondaryBin) {
    visualAidsLayer
      .selectAll("path.hexagon")
      .data([hexbin.overlaySecondaryBin])
      .enter()
      .append("path")
      .attr("d", hexbin.hexagon())
      .attr("transform", (bin) => `translate(${bin.xCenterPosition} ${bin.yCenterPosition}), scale(2)`)
      .attr("fill", "transparent")
      .attr("stroke-width", 1)
      .attr("stroke", isDarkTheme ? colors.white : colors.black)
      .attr("pointer-events", "stroke")
      .on("mouseover", function (ev) {
        onBinHover(ev, HoveredBinType.SECONDARY);
      })
      .on("mouseout", onBinHoverOut);
  }

  // --------------------------------------- LINEAR GRADIENT LEGEND

  const colorExtent = d3.extent(gradientColors, (d, i) => i);

  const zContainer = svg
    .append("g")
    .attr("transform", `translate(${BIN_PADDING_X} 0)`)
    .attr("height", dimensions.height.widthPadding)
    .attr("width", 100)
    .attr("x", "100%")
    .attr("fill", "rgba(0,0,0,0.1");

  // TOP Track name
  zContainer
    .append("text")
    .text(d3.max(bins, (d) => +axesUnitsTransformer.z.toString(d.z)) || 0)
    .attr("text-anchor", "middle")
    .attr("x", "100%")
    .attr("transform", `translate(-${BIN_PADDING_X - LINEAR_GRADIENT_WIDTH / 2})`);

  // BOTTOM Track name
  zContainer
    .append("text")
    .text(d3.min(bins, (d) => +axesUnitsTransformer.z.toString(d.z)) || 0)
    .attr("text-anchor", "middle")
    .attr("x", "100%")
    .attr("y", "100%")
    .attr("transform", `translate(-${BIN_PADDING_X - LINEAR_GRADIENT_WIDTH / 2} ${DEFAULT_TRANSLATE})`);

  const gradientG = svg.append("g");

  gradientG
    .append("rect")
    .attr("width", LINEAR_GRADIENT_WIDTH)
    .attr("height", `${dimensions.height.withPaddingAndLegend + 40}`)
    .attr("transform", `translate(${dimensions.width.widthPadding} ${DEFAULT_TRANSLATE})`)
    .style("fill", "url(#linear-gradient)");

  // Linear Gradient
  zContainer
    .append("linearGradient")
    .attr("id", "linear-gradient")
    .attr("gradientTransform", "rotate(90)")
    .selectAll("stop")
    .data(gradientColors)
    .enter()
    .append("stop")
    .attr(
      "offset",
      (d, i) => ((i - (colorExtent[0] || 0)) / ((colorExtent[1] || 0) - (colorExtent[0] || 0))) * 100 + "%",
    )
    .attr("stop-color", (d) => d);
};
