/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BoilerSlotType } from './BoilerSlotType';
import {
    BoilerSlotTypeFromJSON,
    BoilerSlotTypeFromJSONTyped,
    BoilerSlotTypeToJSON,
} from './BoilerSlotType';
import type { GeneratorSlotType } from './GeneratorSlotType';
import {
    GeneratorSlotTypeFromJSON,
    GeneratorSlotTypeFromJSONTyped,
    GeneratorSlotTypeToJSON,
} from './GeneratorSlotType';
import type { FuelType } from './FuelType';
import {
    FuelTypeFromJSON,
    FuelTypeFromJSONTyped,
    FuelTypeToJSON,
} from './FuelType';

/**
 * 
 * @export
 * @interface FuelConsumptionByGeneratorUserLensDto
 */
export interface FuelConsumptionByGeneratorUserLensDto {
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    lensTabId: number;
    /**
     * 
     * @type {number}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    lensTemplateId: number;
    /**
     * 
     * @type {boolean}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    isAvailableOnReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    label: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    isSystem: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    squeezesDisplay: boolean;
    /**
     * 
     * @type {Array<GeneratorSlotType>}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    selectedGenerators: Array<GeneratorSlotType>;
    /**
     * 
     * @type {FuelType}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    selectedFuel: FuelType;
    /**
     * 
     * @type {Array<BoilerSlotType>}
     * @memberof FuelConsumptionByGeneratorUserLensDto
     */
    selectedBoilers: Array<BoilerSlotType>;
}



/**
 * Check if a given object implements the FuelConsumptionByGeneratorUserLensDto interface.
 */
export function instanceOfFuelConsumptionByGeneratorUserLensDto(value: object): value is FuelConsumptionByGeneratorUserLensDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('lensTabId' in value) || value['lensTabId'] === undefined) return false;
    if (!('lensTemplateId' in value) || value['lensTemplateId'] === undefined) return false;
    if (!('isAvailableOnReports' in value) || value['isAvailableOnReports'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('isSystem' in value) || value['isSystem'] === undefined) return false;
    if (!('squeezesDisplay' in value) || value['squeezesDisplay'] === undefined) return false;
    if (!('selectedGenerators' in value) || value['selectedGenerators'] === undefined) return false;
    if (!('selectedFuel' in value) || value['selectedFuel'] === undefined) return false;
    if (!('selectedBoilers' in value) || value['selectedBoilers'] === undefined) return false;
    return true;
}

export function FuelConsumptionByGeneratorUserLensDtoFromJSON(json: any): FuelConsumptionByGeneratorUserLensDto {
    return FuelConsumptionByGeneratorUserLensDtoFromJSONTyped(json, false);
}

export function FuelConsumptionByGeneratorUserLensDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelConsumptionByGeneratorUserLensDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'lensTabId': json['LensTabId'],
        'lensTemplateId': json['LensTemplateId'],
        'isAvailableOnReports': json['IsAvailableOnReports'],
        'label': json['Label'],
        'isSystem': json['IsSystem'],
        'squeezesDisplay': json['SqueezesDisplay'],
        'selectedGenerators': ((json['SelectedGenerators'] as Array<any>).map(GeneratorSlotTypeFromJSON)),
        'selectedFuel': FuelTypeFromJSON(json['SelectedFuel']),
        'selectedBoilers': ((json['SelectedBoilers'] as Array<any>).map(BoilerSlotTypeFromJSON)),
    };
}

export function FuelConsumptionByGeneratorUserLensDtoToJSON(value?: FuelConsumptionByGeneratorUserLensDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'LensTabId': value['lensTabId'],
        'LensTemplateId': value['lensTemplateId'],
        'IsAvailableOnReports': value['isAvailableOnReports'],
        'Label': value['label'],
        'IsSystem': value['isSystem'],
        'SqueezesDisplay': value['squeezesDisplay'],
        'SelectedGenerators': ((value['selectedGenerators'] as Array<any>).map(GeneratorSlotTypeToJSON)),
        'SelectedFuel': FuelTypeToJSON(value['selectedFuel']),
        'SelectedBoilers': ((value['selectedBoilers'] as Array<any>).map(BoilerSlotTypeToJSON)),
    };
}

