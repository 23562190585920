/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LensTabVisibilityUpdateDto
 */
export interface LensTabVisibilityUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof LensTabVisibilityUpdateDto
     */
    id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LensTabVisibilityUpdateDto
     */
    rigIds?: Array<number> | null;
}

/**
 * Check if a given object implements the LensTabVisibilityUpdateDto interface.
 */
export function instanceOfLensTabVisibilityUpdateDto(value: object): value is LensTabVisibilityUpdateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function LensTabVisibilityUpdateDtoFromJSON(json: any): LensTabVisibilityUpdateDto {
    return LensTabVisibilityUpdateDtoFromJSONTyped(json, false);
}

export function LensTabVisibilityUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LensTabVisibilityUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'rigIds': json['RigIds'] == null ? undefined : json['RigIds'],
    };
}

export function LensTabVisibilityUpdateDtoToJSON(value?: LensTabVisibilityUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'RigIds': value['rigIds'],
    };
}

