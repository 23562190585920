import type { PlanDrillingParameterDto, PlanDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { Input } from "atoms/Form";
import IconComponent from "components/WellPlan/WellPlanCommun/Icon";
import { keyBy, mapValues, pick } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import colors from "utils/colors";
import { useUOM } from "utils/format";
import {
  depthData as depthDataTransform,
  flowData,
  pressureData,
  rotationData,
  speedData,
  torqueData,
  wobData,
} from "utils/wellplan/utils";

const DrillingParameterRowWrapper = styled.div`
  padding: 0 20px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 36px;
  grid-gap: 8px;
  margin-bottom: 8px;
`;

export type SelectedUnits = {
  wob: keyof typeof wobData;
  rpm: keyof typeof rotationData;
  rop: keyof typeof speedData;
  spp: keyof typeof pressureData;
  diffPressure: keyof typeof pressureData;
  torque: keyof typeof torqueData;
  flowIn: keyof typeof flowData;
};

const columnList: Array<{
  key: keyof SelectedUnits;
  data: {
    [key: string]: {
      value?: string;
      abv?: string;
      unitSystem?: IUnitSystem;
      toSI?: (key: string) => number;
      toString?: (key: number) => string;
    };
  };
  title: string;
}> = [
  { key: "wob", data: wobData, title: "WOB" },
  { key: "rpm", data: rotationData, title: "RPM" },
  { key: "rop", data: speedData, title: "ROP" },
  { key: "diffPressure", data: pressureData, title: "Diff-Pressure" },
  { key: "torque", data: torqueData, title: "Torque" },
  { key: "flowIn", data: flowData, title: "Flow In" },
];

const columnMap = keyBy(columnList, "key");

// Measured depth is custom
const parameterKeys: Array<{
  key: keyof Omit<PlanDrillingParameterDto, "id" | "measuredDepth">;
  placeholder: string;
  column: keyof SelectedUnits;
}> = [
  { key: "wobMin", placeholder: "Min", column: "wob" },
  { key: "wobMax", placeholder: "Max", column: "wob" },
  { key: "revolutionPerSecondMin", placeholder: "Min", column: "rpm" },
  { key: "revolutionPerSecondMax", placeholder: "Max", column: "rpm" },
  { key: "ropMin", placeholder: "Min", column: "rop" },
  { key: "ropMax", placeholder: "Max", column: "rop" },
  { key: "diffPressureMin", placeholder: "Min", column: "diffPressure" },
  { key: "diffPressureMax", placeholder: "Max", column: "diffPressure" },
  { key: "torqueMin", placeholder: "Min", column: "torque" },
  { key: "torqueMax", placeholder: "Max", column: "torque" },
  { key: "flowInMin", placeholder: "Min", column: "flowIn" },
  { key: "flowInMax", placeholder: "Max", column: "flowIn" },
];

const parameterMap = keyBy(parameterKeys, "key");

export const drillingParameterFields: Array<keyof Partial<PlanDrillingParameterDto>> = [
  "measuredDepth",
  "wobMin",
  "wobMax",
  "revolutionPerSecondMin",
  "revolutionPerSecondMax",
  "ropMin",
  "ropMax",
  "diffPressureMin",
  "diffPressureMax",
  "torqueMin",
  "torqueMax",
  "flowInMin",
  "flowInMax",
];

export const DrillingParameterRow = ({
  drillingParameter,
  setPlan,
  setPlanModified,
  plan,
  selectedUnits,
  errors,
  isFirst = false,
}: {
  drillingParameter: PlanDrillingParameterDto;
  setPlan: React.Dispatch<React.SetStateAction<PlanDto | undefined>>;
  setPlanModified: React.Dispatch<React.SetStateAction<boolean>>;
  plan: PlanDto;
  selectedUnits?: SelectedUnits;
  errors?: any;
  isFirst?: boolean;
}) => {
  const [localValues, setLocalValues] = useState<Partial<Record<keyof PlanDrillingParameterDto, string | number>>>({});
  const [startValue, setStartValue] = useState<string>("");
  const depthData = useUOM(DimensionType.Metres);
  const depthValue = isFirst ? startValue : localValues.measuredDepth;

  const transformValues = useCallback(
    (object: Record<string, unknown>, transform: "string" | "SI") => {
      return mapValues(object, (value, key) => {
        const column = parameterMap[key]?.column;
        if (value === "" || value === undefined) return undefined;
        if (key === "measuredDepth") {
          const depthTransformFunction =
            depthDataTransform[depthData.currentUOM === IUnitSystem.METRIC ? "meters" : "feet"][
              transform === "string" ? "toString" : "toSI"
            ];
          if (value === 0) return "0.00";
          else return value ? depthTransformFunction(value as never) : "";
        }

        if (!column) {
          return value;
        }

        const selectedUnit = selectedUnits?.[column];
        const data = columnMap[column].data;

        if (transform === "string") {
          const unitToString = selectedUnit ? data?.[selectedUnit]?.toString : null;
          return !value || !unitToString ? "" : unitToString(value as number);
        }

        if (transform === "SI") {
          const unitToSI = selectedUnit ? data?.[selectedUnit]?.toSI : null;
          return !value || !unitToSI ? undefined : unitToSI(value as string);
        }
      });
    },
    [depthData.currentUOM, selectedUnits],
  );

  useEffect(() => {
    const crtValues = transformValues(pick(drillingParameter, drillingParameterFields), "string");
    setLocalValues(crtValues);

    setStartValue(
      plan?.startParameterDepth !== undefined
        ? depthDataTransform[depthData.currentUOM === IUnitSystem.METRIC ? "meters" : "feet"]["toString"](
            plan?.startParameterDepth || 0,
          )
        : "",
    );
    // eslint-disable-next-line
  }, [drillingParameter, plan?.startParameterDepth, transformValues]);

  const updateData = useCallback(() => {
    setPlan((prevPlan) =>
      prevPlan
        ? {
            ...prevPlan,
            drillingParameters: prevPlan.drillingParameters.map((item) => {
              return item.id === drillingParameter.id ? { ...item, ...transformValues(localValues, "SI") } : item;
            }),
          }
        : undefined,
    );
    setPlanModified(true);
  }, [drillingParameter.id, localValues, setPlan, setPlanModified, transformValues]);

  const updateStartValue = useCallback(() => {
    setPlan((prevPlan) =>
      prevPlan
        ? {
            ...prevPlan,
            startParameterDepth: startValue
              ? depthDataTransform[depthData.currentUOM === IUnitSystem.METRIC ? "meters" : "feet"]["toSI"](startValue)
              : undefined,
          }
        : undefined,
    );
    setPlanModified(true);
  }, [setPlan, setPlanModified, depthData.currentUOM, startValue]);

  const deleteRow = useCallback(() => {
    Track.interact("Drilling Parameters - Delete");
    setPlan((prevPlan) =>
      prevPlan
        ? {
            ...prevPlan,
            drillingParameters: prevPlan.drillingParameters.filter((item) => item.id !== drillingParameter.id),
          }
        : undefined,
    );
    setPlanModified(true);
  }, [drillingParameter.id, setPlan, setPlanModified]);

  return (
    <DrillingParameterRowWrapper>
      <Input
        type="number"
        placeholder={isFirst ? "Start Depth" : "End Depth"}
        onKeyDown={(event) => (event.key === "e" || event.key === "-" || event.key === "+") && event.preventDefault()}
        style={{ width: "100%", borderColor: `${colors.actions_bg}!important` }}
        value={depthValue}
        error={isFirst ? errors?.startDepth : drillingParameter.id && errors?.[drillingParameter.id]?.measuredDepth}
        suffix={
          depthValue !== "" && Number.isFinite(+(depthValue || 0)) ? (
            depthData.currentUOM === IUnitSystem.METRIC ? (
              "Meters"
            ) : (
              "Feet"
            )
          ) : (
            <span />
          )
        }
        onChange={(e) => {
          const value = e.currentTarget.value;
          if (isFirst) {
            setStartValue(value);
          } else {
            setLocalValues((prev) => ({ ...prev, measuredDepth: value }));
          }
        }}
        onBlur={isFirst ? updateStartValue : updateData}
      />
      {parameterKeys.map(({ key, placeholder }) => (
        <Input
          type="number"
          onKeyDown={(event) => (event.key === "e" || event.key === "-" || event.key === "+") && event.preventDefault()}
          key={key}
          onBlur={updateData}
          placeholder={isFirst ? "—" : placeholder}
          style={{ width: "100%" }}
          value={
            localValues[key] !== "" && Number.isFinite(+(localValues[key] as string)) ? localValues[key] : undefined
          }
          error={!isFirst && drillingParameter.id ? errors?.[drillingParameter.id]?.[key] : null}
          onChange={(e) => {
            const value = e.currentTarget.value;
            setLocalValues((prev) => ({ ...prev, [key]: value ?? undefined }));
          }}
          disabled={isFirst}
        />
      ))}
      <IconComponent name="delete" disabled={isFirst} onClick={deleteRow} />
    </DrillingParameterRowWrapper>
  );
};
