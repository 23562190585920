import { StyledDropdownButton } from "atoms/common";
import { Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { useRigs } from "hooks/useRigs";
import { useSelectedRig } from "hooks/useSelectedRig";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Track } from "services/Mixpanel";
import { useDebounce } from "use-debounce";
import { Popover, Space } from "utils/componentLibrary";

import { Arrow, StyledButton, StyledDropdownContainer } from "./style";

export function RigSelector({ redirectLocation }: { redirectLocation: (rigId: number) => string }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSelect, setShowSelect] = useState(false);

  const navigate = useNavigate();

  const { data: rigs } = useRigs();
  const selectedRig = useSelectedRig();

  const [debouncedSearchTerm] = useDebounce(searchTerm, 200);

  const selectionOptions = useMemo(
    () =>
      rigs?.list.filter(
        (rig) =>
          rig.id !== selectedRig &&
          (rig?.shortName || "").toLocaleLowerCase().includes(debouncedSearchTerm.toLocaleLowerCase().trim()),
      ),
    [debouncedSearchTerm, rigs?.list, selectedRig],
  );

  const onRigChange = (rigId: number) => {
    setSearchTerm("");
    setShowSelect(false);
    // timeout before navigation to give antd enough time to close the popover
    setTimeout(() => {
      if (redirectLocation) {
        navigate(redirectLocation(rigId));
      }
    }, 50);
  };

  return (
    <Popover
      content={
        <PDComponent.ListWithSearch<number>
          placeholder={"Search..."}
          width={456}
          options={selectionOptions.map((rig) => ({ id: rig.id, name: `Rig ${rig.shortName ?? ""}` }))}
          values={selectionOptions.map((rig) => rig.id)}
          selectedItem={selectionOptions.find((rig) => rig.id === selectedRig)?.name ?? ""}
          onClick={(item) => {
            Track.interact("Rig Dashboard - Focal Rig Change", {
              "Rig Name": `Rig ${item?.name || ""}`,
            });
            onRigChange(item.id);
          }}
        />
      }
      trigger="click"
      placement="bottomLeft"
      open={showSelect}
      onOpenChange={(show) => setShowSelect(show)}
      destroyTooltipOnHide
    >
      <StyledButton onClick={() => setShowSelect(!showSelect)}>
        {selectedRig ? (
          <Space
            direction="horizontal"
            size={6}
            style={{
              padding: "9px 0",
            }}
          >
            Rig
            <Title level={3} variant="faded" style={{ display: "inline-block" }}>
              {rigs?.byId[selectedRig]?.shortName || ""}
            </Title>
          </Space>
        ) : null}
        <StyledDropdownContainer>
          <StyledDropdownButton
            style={{
              paddingTop: showSelect ? 10 : 7,
            }}
          >
            <Arrow up={showSelect} />
          </StyledDropdownButton>
        </StyledDropdownContainer>
      </StyledButton>
    </Popover>
  );
}
