/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WellStatusType } from './WellStatusType';
import {
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface AvailableIdsByWellsQueryDto
 */
export interface AvailableIdsByWellsQueryDto {
    /**
     * 
     * @type {boolean}
     * @memberof AvailableIdsByWellsQueryDto
     */
    keepEvengreenOnly: boolean;
    /**
     * 
     * @type {Array<WellStatusType>}
     * @memberof AvailableIdsByWellsQueryDto
     */
    wellStatuses?: Array<WellStatusType> | null;
    /**
     * 
     * @type {DateDto}
     * @memberof AvailableIdsByWellsQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof AvailableIdsByWellsQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof AvailableIdsByWellsQueryDto
     */
    wellIds?: Array<number> | null;
}

/**
 * Check if a given object implements the AvailableIdsByWellsQueryDto interface.
 */
export function instanceOfAvailableIdsByWellsQueryDto(value: object): value is AvailableIdsByWellsQueryDto {
    if (!('keepEvengreenOnly' in value) || value['keepEvengreenOnly'] === undefined) return false;
    if (!('from' in value) || value['from'] === undefined) return false;
    if (!('to' in value) || value['to'] === undefined) return false;
    return true;
}

export function AvailableIdsByWellsQueryDtoFromJSON(json: any): AvailableIdsByWellsQueryDto {
    return AvailableIdsByWellsQueryDtoFromJSONTyped(json, false);
}

export function AvailableIdsByWellsQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableIdsByWellsQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'keepEvengreenOnly': json['KeepEvengreenOnly'],
        'wellStatuses': json['WellStatuses'] == null ? undefined : ((json['WellStatuses'] as Array<any>).map(WellStatusTypeFromJSON)),
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'wellIds': json['WellIds'] == null ? undefined : json['WellIds'],
    };
}

export function AvailableIdsByWellsQueryDtoToJSON(value?: AvailableIdsByWellsQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'KeepEvengreenOnly': value['keepEvengreenOnly'],
        'WellStatuses': value['wellStatuses'] == null ? undefined : ((value['wellStatuses'] as Array<any>).map(WellStatusTypeToJSON)),
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'WellIds': value['wellIds'],
    };
}

