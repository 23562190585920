import type { BatteryStateFactDto } from "apis/oag";
import { DimensionType } from "apis/oag";
import { TooltipHighlightValue } from "components/Lenses/common/Tooltip";
import { useChartTooltip } from "components/Lenses/common/useChartTooltip";
import { bisector } from "d3-array";
import type { ScaleLinear } from "d3-scale";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { OnRefChangeType } from "react-resize-detector/build/types/types";
import colors from "utils/colors";
import { useUOM } from "utils/format";
import { formatTime } from "utils/helper";

import { SUMMARY_HEIGHT_MINI } from "./utils";

export const useBatteryStateTooltip = ({
  containerRef,
  xScale,
  series,
  isPointerInsideChart,
  plotWidth,
}: {
  containerRef: OnRefChangeType<HTMLDivElement> | React.MutableRefObject<HTMLDivElement>;
  xScale: ScaleLinear<number, number, never>;
  series: BatteryStateFactDto[];
  isPointerInsideChart: boolean;
  plotWidth: number;
}) => {
  const [pointerPosition, setPointerPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const uomValue = useUOM(DimensionType.Watts);

  const handlePointerMove = useCallback((event: React.PointerEvent<SVGSVGElement>) => {
    const { left, top } = event.currentTarget.getBoundingClientRect();
    const x = ("clientX" in event ? event.clientX : 0) - left;
    const y = ("clientY" in event ? event.clientY : 0) - top;
    setPointerPosition({ x, y });
  }, []);

  const { showTooltip, hideTooltip, tooltipElement } = useChartTooltip({
    containerRef,
    renderContent: ({ tooltipData }: { tooltipData?: BatteryStateFactDto }) => {
      return (
        <>
          <TooltipHighlightValue style={{ fontWeight: "bold", fontSize: 12 }}>
            {uomValue.display(tooltipData?.realPower, {
              fractionDigits: 0,
            })}
          </TooltipHighlightValue>
          {tooltipData?.at ? (
            <TooltipHighlightValue style={{ color: colors.off_state, fontSize: 12 }}>
              {formatTime(tooltipData.at, { formatStr: "MM/D HH:mm:ss" })}
            </TooltipHighlightValue>
          ) : null}
        </>
      );
    },
  });

  const tooltipPoint: BatteryStateFactDto | null = useMemo(() => {
    if (!series) return null;

    if (pointerPosition.x > plotWidth) {
      return null;
    }

    const x0 = xScale.invert(pointerPosition.x);

    let extremitiesBisectFix = 0;

    if (pointerPosition.x < 30) {
      // To make sure we can select both points at the edge of the graph, normal  bisect leaves out either one or the other
      // Artificially adding mouse X if it's close to the plot edges to get correct edge index
      extremitiesBisectFix = -0.001;
    } else if (Math.abs(pointerPosition.x - plotWidth) < 10) {
      extremitiesBisectFix = 0.001;
    }

    const index = bisector<BatteryStateFactDto, number>((p) => p.index).left(series, x0 + extremitiesBisectFix);

    if (index >= 0 && index < series.length && series[index].index) {
      return series[index];
    }

    return null;
  }, [plotWidth, pointerPosition.x, series, xScale]);

  useEffect(() => {
    isPointerInsideChart && tooltipPoint
      ? showTooltip({
          tooltipLeft: xScale(tooltipPoint.index) || 0,
          tooltipTop: SUMMARY_HEIGHT_MINI,
          tooltipData: tooltipPoint,
        })
      : hideTooltip();
  }, [hideTooltip, isPointerInsideChart, pointerPosition, showTooltip, tooltipPoint, xScale]);

  return { handlePointerMove, tooltipPoint, tooltipElement };
};
