import { Arrow } from "atoms/common";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import { useEffect, useMemo, useState } from "react";
import { Track } from "services/Mixpanel";
import { Popover, Space, Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

import * as Styled from "./style";

const BUTTON_WIDTH = 36;
const INNER_TITLE_PADDING_LEFT = 16;

interface MultipleChoiceSelectProps {
  placeholder: string;
  isLoading: boolean;
  noSelectAll?: boolean;
  noTooltip?: boolean;
  width: number;
  currentSelectionTitle: string | React.ReactElement;
  trackingTitle: string;
  listItems: IOption<number>[];
  disabled?: boolean;
  onSelectionChange: (optionIds: number[]) => void;
}

export const MultipleChoiceSelect = ({
  placeholder,
  isLoading,
  width,
  listItems,
  disabled,
  currentSelectionTitle,
  trackingTitle,
  noSelectAll,
  noTooltip,
  onSelectionChange,
}: MultipleChoiceSelectProps) => {
  const [showSelect, _setShowSelect] = useState(false);
  const setShowSelect = disabled ? Function.prototype() : _setShowSelect;
  const { atomThemeVariant, themeStyle } = useCustomTheme();
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);

  useEffect(() => {
    onSelectionChange(selectedItemIds);
  }, [onSelectionChange, selectedItemIds]);

  const loadingText = "Loading...";

  const titleText = useMemo(() => {
    if (isLoading) {
      return loadingText;
    } else if (currentSelectionTitle) {
      return currentSelectionTitle;
    } else return placeholder ?? "Search";
  }, [currentSelectionTitle, isLoading, placeholder]);

  return (
    <Tooltip title={noTooltip ? "" : placeholder}>
      <Popover
        content={
          <PDComponent.ComboBoxMultiSelect
            width={width}
            placeholder={"Search"}
            options={listItems}
            values={selectedItemIds}
            onReset={() => {
              Track.interact(`${trackingTitle} - Options reset`);
              setSelectedItemIds([]);
              setShowSelect(false);
            }}
            noSelectAll={noSelectAll}
            onChange={(itemIds) => {
              Track.interact(`${trackingTitle} - Option Change`, { Action: "Update" });
              setSelectedItemIds(itemIds);
              setShowSelect(false);
            }}
          />
        }
        trigger="click"
        placement="bottom"
        open={showSelect}
        onOpenChange={(isOpen) => setShowSelect(isOpen)}
        destroyTooltipOnHide
      >
        <Styled.StyledButton onClick={() => setShowSelect(!showSelect)}>
          <Space direction="horizontal" size={6} style={{ padding: "9px 0" }}>
            <Styled.SelectTitle
              level={3}
              variant={disabled ? "faded" : atomThemeVariant}
              $maxWidth={width - BUTTON_WIDTH - INNER_TITLE_PADDING_LEFT}
              $isPlaceholder={titleText === placeholder || titleText === loadingText}
            >
              {titleText}
            </Styled.SelectTitle>
          </Space>
          <Styled.DropdownContainer>
            <Styled.DropdownButton $showSelect={showSelect}>
              <Arrow up={showSelect} color={themeStyle.colors.secondary_accent} />
            </Styled.DropdownButton>
          </Styled.DropdownContainer>
        </Styled.StyledButton>
      </Popover>
    </Tooltip>
  );
};
