/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScoreSummaryRootDto } from './ScoreSummaryRootDto';
import {
    ScoreSummaryRootDtoFromJSON,
    ScoreSummaryRootDtoFromJSONTyped,
    ScoreSummaryRootDtoToJSON,
} from './ScoreSummaryRootDto';
import type { ScoreComplianceStateType } from './ScoreComplianceStateType';
import {
    ScoreComplianceStateTypeFromJSON,
    ScoreComplianceStateTypeFromJSONTyped,
    ScoreComplianceStateTypeToJSON,
} from './ScoreComplianceStateType';
import type { RigCardFactValue } from './RigCardFactValue';
import {
    RigCardFactValueFromJSON,
    RigCardFactValueFromJSONTyped,
    RigCardFactValueToJSON,
} from './RigCardFactValue';
import type { RigCardFactKpiValue } from './RigCardFactKpiValue';
import {
    RigCardFactKpiValueFromJSON,
    RigCardFactKpiValueFromJSONTyped,
    RigCardFactKpiValueToJSON,
} from './RigCardFactKpiValue';

/**
 * 
 * @export
 * @interface RigCardFactDto
 */
export interface RigCardFactDto {
    /**
     * 
     * @type {string}
     * @memberof RigCardFactDto
     */
    label: string;
    /**
     * 
     * @type {RigCardFactKpiValue}
     * @memberof RigCardFactDto
     */
    kpiValue: RigCardFactKpiValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    operatorScore: RigCardFactValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    rigScore: RigCardFactValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    improvementScore: RigCardFactValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    targetDeltaTime: RigCardFactValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    invisibleLostTime: RigCardFactValue;
    /**
     * 
     * @type {RigCardFactValue}
     * @memberof RigCardFactDto
     */
    savedTime: RigCardFactValue;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactDto
     */
    targetValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactDto
     */
    pdBenchmarkValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RigCardFactDto
     */
    baseValue?: number | null;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigCardFactDto
     */
    operatorScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigCardFactDto
     */
    rigScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreComplianceStateType}
     * @memberof RigCardFactDto
     */
    improvementScoreComplianceState: ScoreComplianceStateType;
    /**
     * 
     * @type {ScoreSummaryRootDto}
     * @memberof RigCardFactDto
     */
    scoreSummary: ScoreSummaryRootDto;
}



/**
 * Check if a given object implements the RigCardFactDto interface.
 */
export function instanceOfRigCardFactDto(value: object): value is RigCardFactDto {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('kpiValue' in value) || value['kpiValue'] === undefined) return false;
    if (!('operatorScore' in value) || value['operatorScore'] === undefined) return false;
    if (!('rigScore' in value) || value['rigScore'] === undefined) return false;
    if (!('improvementScore' in value) || value['improvementScore'] === undefined) return false;
    if (!('targetDeltaTime' in value) || value['targetDeltaTime'] === undefined) return false;
    if (!('invisibleLostTime' in value) || value['invisibleLostTime'] === undefined) return false;
    if (!('savedTime' in value) || value['savedTime'] === undefined) return false;
    if (!('operatorScoreComplianceState' in value) || value['operatorScoreComplianceState'] === undefined) return false;
    if (!('rigScoreComplianceState' in value) || value['rigScoreComplianceState'] === undefined) return false;
    if (!('improvementScoreComplianceState' in value) || value['improvementScoreComplianceState'] === undefined) return false;
    if (!('scoreSummary' in value) || value['scoreSummary'] === undefined) return false;
    return true;
}

export function RigCardFactDtoFromJSON(json: any): RigCardFactDto {
    return RigCardFactDtoFromJSONTyped(json, false);
}

export function RigCardFactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RigCardFactDto {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['Label'],
        'kpiValue': RigCardFactKpiValueFromJSON(json['KpiValue']),
        'operatorScore': RigCardFactValueFromJSON(json['OperatorScore']),
        'rigScore': RigCardFactValueFromJSON(json['RigScore']),
        'improvementScore': RigCardFactValueFromJSON(json['ImprovementScore']),
        'targetDeltaTime': RigCardFactValueFromJSON(json['TargetDeltaTime']),
        'invisibleLostTime': RigCardFactValueFromJSON(json['InvisibleLostTime']),
        'savedTime': RigCardFactValueFromJSON(json['SavedTime']),
        'targetValue': json['TargetValue'] == null ? undefined : json['TargetValue'],
        'pdBenchmarkValue': json['PdBenchmarkValue'] == null ? undefined : json['PdBenchmarkValue'],
        'baseValue': json['BaseValue'] == null ? undefined : json['BaseValue'],
        'operatorScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['OperatorScoreComplianceState']),
        'rigScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['RigScoreComplianceState']),
        'improvementScoreComplianceState': ScoreComplianceStateTypeFromJSON(json['ImprovementScoreComplianceState']),
        'scoreSummary': ScoreSummaryRootDtoFromJSON(json['ScoreSummary']),
    };
}

export function RigCardFactDtoToJSON(value?: RigCardFactDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Label': value['label'],
        'KpiValue': RigCardFactKpiValueToJSON(value['kpiValue']),
        'OperatorScore': RigCardFactValueToJSON(value['operatorScore']),
        'RigScore': RigCardFactValueToJSON(value['rigScore']),
        'ImprovementScore': RigCardFactValueToJSON(value['improvementScore']),
        'TargetDeltaTime': RigCardFactValueToJSON(value['targetDeltaTime']),
        'InvisibleLostTime': RigCardFactValueToJSON(value['invisibleLostTime']),
        'SavedTime': RigCardFactValueToJSON(value['savedTime']),
        'TargetValue': value['targetValue'],
        'PdBenchmarkValue': value['pdBenchmarkValue'],
        'BaseValue': value['baseValue'],
        'OperatorScoreComplianceState': ScoreComplianceStateTypeToJSON(value['operatorScoreComplianceState']),
        'RigScoreComplianceState': ScoreComplianceStateTypeToJSON(value['rigScoreComplianceState']),
        'ImprovementScoreComplianceState': ScoreComplianceStateTypeToJSON(value['improvementScoreComplianceState']),
        'ScoreSummary': ScoreSummaryRootDtoToJSON(value['scoreSummary']),
    };
}

