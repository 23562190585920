import styled, { css } from "styled-components";
import colors from "utils/colors";

export const FeedbackContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  margin-right: 10px;
  background: ${({ theme }) => (theme.isDark ? `${colors.black}24` : `${colors.off_grey}8c`)};
  position: relative;
  width: 100%;
  grid-area: feedbacks;
`;

export const Submit = styled.button`
  padding: 8px 16px;
  align-items: center;
  border-radius: 4px;
  border: none;
  border: 1px solid
    ${({ theme }) => (theme.isDark ? colors.gluon_grey : `${theme.themeStyle.colors.primary_scroll_bg}88`)};
  color: ${({ theme }) => (theme.isDark ? colors.aphrodite_aqua : colors.white)};
  background: ${({ theme }) => (theme.isDark ? colors.gluon_grey : colors.shutter_black)};
  cursor: pointer;
  :disabled {
    background: transparent;
    cursor: not-allowed;
    color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
  }
`;

export const InitialTags = styled.button<{ $isActive: boolean }>`
  padding: 6px 8px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid
    ${({ theme }) =>
      theme.isDark ? `${theme.themeStyle.colors.primary_scroll_bg}88` : `${theme.themeStyle.colors.primary_scroll_bg}`};

  background: transparent;

  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  ${({ $isActive, theme }) =>
    $isActive
      ? css`
          border-color: ${theme.isDark ? colors.limo_scene : theme.themeStyle.colors.primary_scroll_bg};
          background: ${theme.isDark ? colors.limo_scene : theme.themeStyle.colors.primary_scroll_bg};
          color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
        `
      : ``};

  cursor: pointer;
  & + & {
    margin-left: 8px;
  }
`;

export const OtherText = styled.input`
  height: 36px;
  width: 360px;
  padding: 9px 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_scroll_bg}88;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  background: transparent;
  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.themeStyle.colors.primary_typography};
  }
`;

export const StyledCloseIcon = styled.span`
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`;
