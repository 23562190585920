/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StandKpiType } from './StandKpiType';
import {
    StandKpiTypeFromJSON,
    StandKpiTypeFromJSONTyped,
    StandKpiTypeToJSON,
} from './StandKpiType';
import type { FiltersDto } from './FiltersDto';
import {
    FiltersDtoFromJSON,
    FiltersDtoFromJSONTyped,
    FiltersDtoToJSON,
} from './FiltersDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface StandKpiInspectionQueryDto
 */
export interface StandKpiInspectionQueryDto {
    /**
     * 
     * @type {boolean}
     * @memberof StandKpiInspectionQueryDto
     */
    useMockData: boolean;
    /**
     * 
     * @type {number}
     * @memberof StandKpiInspectionQueryDto
     */
    sampleCount: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StandKpiInspectionQueryDto
     */
    comparisonWellIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof StandKpiInspectionQueryDto
     */
    selectedRigIds?: Array<number> | null;
    /**
     * 
     * @type {FiltersDto}
     * @memberof StandKpiInspectionQueryDto
     */
    selectedFilters: FiltersDto;
    /**
     * 
     * @type {number}
     * @memberof StandKpiInspectionQueryDto
     */
    zoomStartDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandKpiInspectionQueryDto
     */
    zoomEndDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandKpiInspectionQueryDto
     */
    zoomStartDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StandKpiInspectionQueryDto
     */
    zoomEndDepth?: number | null;
    /**
     * 
     * @type {DateDto}
     * @memberof StandKpiInspectionQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof StandKpiInspectionQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {number}
     * @memberof StandKpiInspectionQueryDto
     */
    wellId: number;
    /**
     * 
     * @type {number}
     * @memberof StandKpiInspectionQueryDto
     */
    standId: number;
    /**
     * 
     * @type {StandKpiType}
     * @memberof StandKpiInspectionQueryDto
     */
    standKpiType: StandKpiType;
}



/**
 * Check if a given object implements the StandKpiInspectionQueryDto interface.
 */
export function instanceOfStandKpiInspectionQueryDto(value: object): value is StandKpiInspectionQueryDto {
    if (!('useMockData' in value) || value['useMockData'] === undefined) return false;
    if (!('sampleCount' in value) || value['sampleCount'] === undefined) return false;
    if (!('selectedFilters' in value) || value['selectedFilters'] === undefined) return false;
    if (!('from' in value) || value['from'] === undefined) return false;
    if (!('to' in value) || value['to'] === undefined) return false;
    if (!('wellId' in value) || value['wellId'] === undefined) return false;
    if (!('standId' in value) || value['standId'] === undefined) return false;
    if (!('standKpiType' in value) || value['standKpiType'] === undefined) return false;
    return true;
}

export function StandKpiInspectionQueryDtoFromJSON(json: any): StandKpiInspectionQueryDto {
    return StandKpiInspectionQueryDtoFromJSONTyped(json, false);
}

export function StandKpiInspectionQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StandKpiInspectionQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'useMockData': json['UseMockData'],
        'sampleCount': json['SampleCount'],
        'comparisonWellIds': json['ComparisonWellIds'] == null ? undefined : json['ComparisonWellIds'],
        'selectedRigIds': json['SelectedRigIds'] == null ? undefined : json['SelectedRigIds'],
        'selectedFilters': FiltersDtoFromJSON(json['SelectedFilters']),
        'zoomStartDuration': json['ZoomStartDuration'] == null ? undefined : json['ZoomStartDuration'],
        'zoomEndDuration': json['ZoomEndDuration'] == null ? undefined : json['ZoomEndDuration'],
        'zoomStartDepth': json['ZoomStartDepth'] == null ? undefined : json['ZoomStartDepth'],
        'zoomEndDepth': json['ZoomEndDepth'] == null ? undefined : json['ZoomEndDepth'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'wellId': json['WellId'],
        'standId': json['StandId'],
        'standKpiType': StandKpiTypeFromJSON(json['StandKpiType']),
    };
}

export function StandKpiInspectionQueryDtoToJSON(value?: StandKpiInspectionQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'UseMockData': value['useMockData'],
        'SampleCount': value['sampleCount'],
        'ComparisonWellIds': value['comparisonWellIds'],
        'SelectedRigIds': value['selectedRigIds'],
        'SelectedFilters': FiltersDtoToJSON(value['selectedFilters']),
        'ZoomStartDuration': value['zoomStartDuration'],
        'ZoomEndDuration': value['zoomEndDuration'],
        'ZoomStartDepth': value['zoomStartDepth'],
        'ZoomEndDepth': value['zoomEndDepth'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'WellId': value['wellId'],
        'StandId': value['standId'],
        'StandKpiType': StandKpiTypeToJSON(value['standKpiType']),
    };
}

