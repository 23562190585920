import { CurveType } from "components/Lenses/common/LineChart/utils";
import colors from "utils/colors";

export const LEGEND_HEIGHT = 25;
export const DECIMAL_THRESHOLD = 10;

export const LENS_TITLE = "Fuel Consumption By Generator";

export const curveColors = {
  [CurveType.Diesel]: colors.sunburnt_toes,
  [CurveType.NatGas]: colors.livid,
  [CurveType.AvailablePower]: colors.aurora_magenta,
};
