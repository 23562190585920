/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BinCoordinates
 */
export interface BinCoordinates {
    /**
     * 
     * @type {number}
     * @memberof BinCoordinates
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof BinCoordinates
     */
    y: number;
}

/**
 * Check if a given object implements the BinCoordinates interface.
 */
export function instanceOfBinCoordinates(value: object): value is BinCoordinates {
    if (!('x' in value) || value['x'] === undefined) return false;
    if (!('y' in value) || value['y'] === undefined) return false;
    return true;
}

export function BinCoordinatesFromJSON(json: any): BinCoordinates {
    return BinCoordinatesFromJSONTyped(json, false);
}

export function BinCoordinatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BinCoordinates {
    if (json == null) {
        return json;
    }
    return {
        
        'x': json['X'],
        'y': json['Y'],
    };
}

export function BinCoordinatesToJSON(value?: BinCoordinates | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'X': value['x'],
        'Y': value['y'],
    };
}

