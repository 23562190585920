import styled from "styled-components";
import colors from "utils/colors";

export const StyledFormItem = styled.div<{ $validateStatus?: "error" | "success" }>`
  position: relative;
  .ant-input {
    padding-right: ${({ $validateStatus }) => ($validateStatus ? "36px" : "0")};
    border-color: ${({ $validateStatus }) => ($validateStatus === "error" ? colors.blood_burst : "none")} !important;
    background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg} !important;
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    outline: none;
  }
  .ant-input:focus {
    border: none !important;
  }
  .ant-input-affix-wrapper {
    background-color: ${({ theme }) => theme.themeStyle.colors.quaterniary_bg};
    color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .ant-select {
    width: 100%;
    .ant-select-selector {
      border-color: ${({ $validateStatus }) =>
    $validateStatus === "error" ? colors.blood_burst : undefined} !important;
    }
  }
  .ant-select-arrow {
    padding-right: ${({ $validateStatus }) => ($validateStatus ? "18px" : "0")};
  }
`;

export const StyledFormItemIcon = styled.span<{ $validateStatus: "error" | "success" }>`
  position: absolute;
  right: 0;
  width: 36px;
  height: 100%;
  display: inline-flex;
  font-size: ${({ $validateStatus }) => ($validateStatus === "error" ? "18px" : "16px")};
  place-content: center;
  place-items: center;
  color: ${({ $validateStatus }) => ($validateStatus === "error" ? colors.blood_burst : colors.luigi)};
`;
