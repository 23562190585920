import { APPROX_TICK_HEIGHT, FALLBACK_NUM_TICKS_Y_AXIS } from "utils/constants";
const SPACE_AROUND_TICK_FACTOR = 2.125; // Approximate factor of how much space to leave around a tick, where a factor of 1 is the font size
const DEFAULT_TICK_CONTAINER_HEIGHT = 108; // Height such that default tick count is 3 by default = (108 /(4.5 * APPROX_TICK_HEIGHT))

export const getYAxisTicksCount = ({
  tickHeight = APPROX_TICK_HEIGHT,
  tickContainerHeight = DEFAULT_TICK_CONTAINER_HEIGHT,
}: {
  tickHeight?: number; // Space a single tick takes vertically, related to fontSize
  tickContainerHeight?: number;
  hasSmallTicks?: boolean;
} = {}) => {
  if (Number.isFinite(tickContainerHeight)) {
    return Math.max(3, Math.floor(tickContainerHeight / (tickHeight * SPACE_AROUND_TICK_FACTOR)));
  } else return FALLBACK_NUM_TICKS_Y_AXIS;
};
