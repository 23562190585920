import type { IValueWithUnit } from "utils/interfaces/Well/interfaces";
import {
  flowData,
  pressureData,
  rotationData,
  speedData,
  SSSICumulativeData,
  SSSIData,
  torqueData,
  wobData,
  ZTData,
} from "utils/wellplan/utils";

export interface ITrackUnitTypes {
  [key: string]: { [index: string]: IValueWithUnit };
}

export const trackUnitTypesByDepth: ITrackUnitTypes = {
  WOB: wobData,
  RPM: rotationData,
  ROP: speedData,
  "Diff-Pressure": pressureData,
  Torque: torqueData,
  FlowIn: flowData,
  SPP: pressureData,
};

export const trackUnitTypesStickSlip: ITrackUnitTypes = {
  WOB: wobData,
  RPM: rotationData,
  ROP: speedData,
  "Diff-Pressure": pressureData,
  Torque: torqueData,
  FlowIn: flowData,
  SPP: pressureData,
  SSSI: SSSIData,
  "SSSI Cumulative": SSSICumulativeData,
  "ZT On": ZTData,
};
