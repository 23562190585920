import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ApiRigPowerUserLensesIdFactsPutRequest, RigPowerResultDto } from "apis/oag";
import { RigPowerUserLensesApi } from "apis/oag";
import { initialZoomData } from "components/WellDashboard/ChartControls";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useSelectedWell } from "hooks/useSelectedWell";
import { useAppDispatch } from "reducers/store";
import type { IZoomData } from "reducers/types";
import { IZoomType } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateDto } from "utils/helper";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const api = new RigPowerUserLensesApi(apiConfig);

export const useRigPowerFacts = (
  lensId: number,
  requestParameters: ApiRigPowerUserLensesIdFactsPutRequest,
  options?: Omit<UseQueryOptions<RigPowerResultDto>, "queryKey" | "queryFn">,
) => {
  const [zoomData] = useStateQuery<IZoomData>(URL_STATE_PARAM.ZOOM_WELL, initialZoomData, [
    URL_STATE_PARAM.ZOOM_WIDGET,
  ]);

  const crtWellId = useSelectedWell();
  const dispatch = useAppDispatch();

  let requestParametersWithZoom = requestParameters;
  if (zoomData.date_start && zoomData.type === IZoomType.DATE) {
    requestParametersWithZoom = {
      ...requestParameters,
      rigPowerQueryDto: requestParameters.rigPowerQueryDto
        ? {
            ...requestParameters.rigPowerQueryDto,
            from: dateToDateDto(zoomData.date_start) || defaultDateDto.from,
            to: dateToDateDto(zoomData.date_end) || defaultDateDto.to,
          }
        : undefined,
    };
  }
  const queryKey: PDLensFactsQueryKey<ApiRigPowerUserLensesIdFactsPutRequest> = {
    type: PDQueryType.FACTS,
    uid: RequestUID.rigPowerFacts,
    lensId,
    params: requestParametersWithZoom,
    comparisonType: "single",
  };

  const request = useSuspenseQuery<RigPowerResultDto>({
    queryKey: [queryKey],
    queryFn: ({ signal }) =>
      api.apiRigPowerUserLensesIdFactsPut(requestParametersWithZoom, { signal }).then((data) => {
        if (data?.lastUpdatedAt) {
          dispatch({
            type: "SET_POWER_MANAGEMENT_REFRESH_DATE",
            payload: {
              [crtWellId]: data?.lastUpdatedAt,
            },
          });
        }

        return data;
      }),
    ...options,
  });

  return request;
};
