import { NoDataContainer } from "components/Lenses/common/NoData";
import styled from "styled-components";
import { PdSpaceItem } from "utils/componentLibrary/Space";

export const CommentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  margin-right: 16px;
  margin-left: 24px;
  padding-bottom: 16px;
`;

export const CommentInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
`;

export const CommentInfoRow = styled.div`
  display: flex;
  flex-grow: 0;
  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: baseline;
`;

export const CommentInfoMetric = styled.div`
  white-space: pre;
  font-size: 14px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const CommentInfoResult = styled.div<{ $isMain?: boolean }>`
  font-size: 14px;
  color: ${({ theme, $isMain }) =>
    $isMain ? theme.themeStyle.colors.primary_button_bg : theme.themeStyle.colors.disabled_typography};
`;

export const Divider = styled.div`
  width: 1px;
  margin: 0px 8px;
  align-self: stretch;
  background-color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const HorizontalDivider = styled.div`
  width: calc(100% - 8px);
  height: 1px;
  background-color: ${({ theme }) => theme.themeStyle.colors.tertiary_accent};
`;

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  max-height: 215px;
  overflow-y: auto;
  padding-right: 8px;
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_chart_bg};
  border-radius: 8px;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 100%;
`;

export const CommentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const Author = styled.div`
  display: flex;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const CommentBody = styled.div`
  display: flex;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const MultiStandContainer = styled.div`
  display: flex;
  gap: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const MultiStandInfo = styled.div`
  display: flex;
  gap: 6px;
  color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
`;

export const MultiStandDepthDate = styled.div`
  display: flex;
  color: ${({ theme }) => theme.themeStyle.colors.disabled_typography};
`;

export const NoCommentsContainer = styled.div`
  ${NoDataContainer} {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    ${PdSpaceItem} {
      .pd-icon {
        display: flex;
        place-content: center;
        place-items: center;
        font-size: 14px;
        width: 28px;
        height: 28px;
        background-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
        color: ${({ theme }) => theme.themeStyle.colors.primary_typography};
        border-radius: 50%;
      }
    }
  }
`;
