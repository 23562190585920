import styled from "styled-components";

export const StyledLensContainerFlex = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  position: absolute;
  overflow: visible;
  user-select: "none";
  display: flex;
  flex-direction: column;
`;
