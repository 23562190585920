import type { PowerLoadDistributionProps } from "components/Lenses/interfaces";
import React from "react";

import { Single } from "./Single";

export const PowerLoadDistribution: React.FC<PowerLoadDistributionProps> = ({
  isLocked,
  lens,
  detailed,
  setLensDate,
}) => {
  return <Single lens={lens} detailed={detailed} setLensDate={setLensDate} isLocked={isLocked} />;
};
