/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WellStatusType } from './WellStatusType';
import {
    WellStatusTypeFromJSON,
    WellStatusTypeFromJSONTyped,
    WellStatusTypeToJSON,
} from './WellStatusType';
import type { WellJobsDto } from './WellJobsDto';
import {
    WellJobsDtoFromJSON,
    WellJobsDtoFromJSONTyped,
    WellJobsDtoToJSON,
} from './WellJobsDto';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface WellInfoDto
 */
export interface WellInfoDto {
    /**
     * 
     * @type {number}
     * @memberof WellInfoDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WellInfoDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WellInfoDto
     */
    operatorId: number;
    /**
     * 
     * @type {WellStatusType}
     * @memberof WellInfoDto
     */
    status: WellStatusType;
    /**
     * 
     * @type {string}
     * @memberof WellInfoDto
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof WellInfoDto
     */
    padExternalId?: string | null;
    /**
     * 
     * @type {DateDto}
     * @memberof WellInfoDto
     */
    spudDateTime: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof WellInfoDto
     */
    lastWellFactUpdateAt: DateDto;
    /**
     * 
     * @type {boolean}
     * @memberof WellInfoDto
     */
    isEverGreen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WellInfoDto
     */
    isBess: boolean;
    /**
     * 
     * @type {Array<WellJobsDto>}
     * @memberof WellInfoDto
     */
    jobs: Array<WellJobsDto>;
    /**
     * 
     * @type {number}
     * @memberof WellInfoDto
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellInfoDto
     */
    longitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellInfoDto
     */
    targetFormationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WellInfoDto
     */
    basinId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WellInfoDto
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WellInfoDto
     */
    state?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WellInfoDto
     */
    countryId: number;
}



/**
 * Check if a given object implements the WellInfoDto interface.
 */
export function instanceOfWellInfoDto(value: object): value is WellInfoDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('operatorId' in value) || value['operatorId'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('externalId' in value) || value['externalId'] === undefined) return false;
    if (!('spudDateTime' in value) || value['spudDateTime'] === undefined) return false;
    if (!('lastWellFactUpdateAt' in value) || value['lastWellFactUpdateAt'] === undefined) return false;
    if (!('isEverGreen' in value) || value['isEverGreen'] === undefined) return false;
    if (!('isBess' in value) || value['isBess'] === undefined) return false;
    if (!('jobs' in value) || value['jobs'] === undefined) return false;
    if (!('countryId' in value) || value['countryId'] === undefined) return false;
    return true;
}

export function WellInfoDtoFromJSON(json: any): WellInfoDto {
    return WellInfoDtoFromJSONTyped(json, false);
}

export function WellInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'],
        'operatorId': json['OperatorId'],
        'status': WellStatusTypeFromJSON(json['Status']),
        'externalId': json['ExternalId'],
        'padExternalId': json['PadExternalId'] == null ? undefined : json['PadExternalId'],
        'spudDateTime': DateDtoFromJSON(json['SpudDateTime']),
        'lastWellFactUpdateAt': DateDtoFromJSON(json['LastWellFactUpdateAt']),
        'isEverGreen': json['IsEverGreen'],
        'isBess': json['IsBess'],
        'jobs': ((json['Jobs'] as Array<any>).map(WellJobsDtoFromJSON)),
        'latitude': json['Latitude'] == null ? undefined : json['Latitude'],
        'longitude': json['Longitude'] == null ? undefined : json['Longitude'],
        'targetFormationId': json['TargetFormationId'] == null ? undefined : json['TargetFormationId'],
        'basinId': json['BasinId'] == null ? undefined : json['BasinId'],
        'county': json['County'] == null ? undefined : json['County'],
        'state': json['State'] == null ? undefined : json['State'],
        'countryId': json['CountryId'],
    };
}

export function WellInfoDtoToJSON(value?: WellInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'OperatorId': value['operatorId'],
        'Status': WellStatusTypeToJSON(value['status']),
        'ExternalId': value['externalId'],
        'PadExternalId': value['padExternalId'],
        'SpudDateTime': DateDtoToJSON(value['spudDateTime']),
        'LastWellFactUpdateAt': DateDtoToJSON(value['lastWellFactUpdateAt']),
        'IsEverGreen': value['isEverGreen'],
        'IsBess': value['isBess'],
        'Jobs': ((value['jobs'] as Array<any>).map(WellJobsDtoToJSON)),
        'Latitude': value['latitude'],
        'Longitude': value['longitude'],
        'TargetFormationId': value['targetFormationId'],
        'BasinId': value['basinId'],
        'County': value['county'],
        'State': value['state'],
        'CountryId': value['countryId'],
    };
}

