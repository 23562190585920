/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StopWatchDto } from './StopWatchDto';
import {
    StopWatchDtoFromJSON,
    StopWatchDtoFromJSONTyped,
    StopWatchDtoToJSON,
} from './StopWatchDto';
import type { ServiceMessageDto } from './ServiceMessageDto';
import {
    ServiceMessageDtoFromJSON,
    ServiceMessageDtoFromJSONTyped,
    ServiceMessageDtoToJSON,
} from './ServiceMessageDto';
import type { ResultDataState } from './ResultDataState';
import {
    ResultDataStateFromJSON,
    ResultDataStateFromJSONTyped,
    ResultDataStateToJSON,
} from './ResultDataState';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';
import type { DrillingSplitsDto } from './DrillingSplitsDto';
import {
    DrillingSplitsDtoFromJSON,
    DrillingSplitsDtoFromJSONTyped,
    DrillingSplitsDtoToJSON,
} from './DrillingSplitsDto';

/**
 * 
 * @export
 * @interface WellDrillingSummaryLensResultDto
 */
export interface WellDrillingSummaryLensResultDto {
    /**
     * 
     * @type {StopWatchDto}
     * @memberof WellDrillingSummaryLensResultDto
     */
    stopWatch: StopWatchDto;
    /**
     * 
     * @type {Array<ServiceMessageDto>}
     * @memberof WellDrillingSummaryLensResultDto
     */
    messages?: Array<ServiceMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryLensResultDto
     */
    userLensId: number;
    /**
     * 
     * @type {DateDto}
     * @memberof WellDrillingSummaryLensResultDto
     */
    lastUpdatedAt: DateDto;
    /**
     * 
     * @type {DrillingSplitsDto}
     * @memberof WellDrillingSummaryLensResultDto
     */
    drillingSplits: DrillingSplitsDto;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryLensResultDto
     */
    totalConnectionCount: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryLensResultDto
     */
    reamingConnectionCount: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryLensResultDto
     */
    reamingDuration: number;
    /**
     * 
     * @type {number}
     * @memberof WellDrillingSummaryLensResultDto
     */
    readonly reamingConnectionRatio: number;
    /**
     * 
     * @type {ResultDataState}
     * @memberof WellDrillingSummaryLensResultDto
     */
    dataState: ResultDataState;
}



/**
 * Check if a given object implements the WellDrillingSummaryLensResultDto interface.
 */
export function instanceOfWellDrillingSummaryLensResultDto(value: object): value is WellDrillingSummaryLensResultDto {
    if (!('stopWatch' in value) || value['stopWatch'] === undefined) return false;
    if (!('userLensId' in value) || value['userLensId'] === undefined) return false;
    if (!('lastUpdatedAt' in value) || value['lastUpdatedAt'] === undefined) return false;
    if (!('drillingSplits' in value) || value['drillingSplits'] === undefined) return false;
    if (!('totalConnectionCount' in value) || value['totalConnectionCount'] === undefined) return false;
    if (!('reamingConnectionCount' in value) || value['reamingConnectionCount'] === undefined) return false;
    if (!('reamingDuration' in value) || value['reamingDuration'] === undefined) return false;
    if (!('reamingConnectionRatio' in value) || value['reamingConnectionRatio'] === undefined) return false;
    if (!('dataState' in value) || value['dataState'] === undefined) return false;
    return true;
}

export function WellDrillingSummaryLensResultDtoFromJSON(json: any): WellDrillingSummaryLensResultDto {
    return WellDrillingSummaryLensResultDtoFromJSONTyped(json, false);
}

export function WellDrillingSummaryLensResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WellDrillingSummaryLensResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'stopWatch': StopWatchDtoFromJSON(json['StopWatch']),
        'messages': json['Messages'] == null ? undefined : ((json['Messages'] as Array<any>).map(ServiceMessageDtoFromJSON)),
        'userLensId': json['UserLensId'],
        'lastUpdatedAt': DateDtoFromJSON(json['LastUpdatedAt']),
        'drillingSplits': DrillingSplitsDtoFromJSON(json['DrillingSplits']),
        'totalConnectionCount': json['TotalConnectionCount'],
        'reamingConnectionCount': json['ReamingConnectionCount'],
        'reamingDuration': json['ReamingDuration'],
        'reamingConnectionRatio': json['ReamingConnectionRatio'],
        'dataState': ResultDataStateFromJSON(json['DataState']),
    };
}

export function WellDrillingSummaryLensResultDtoToJSON(value?: Omit<WellDrillingSummaryLensResultDto, 'ReamingConnectionRatio'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'StopWatch': StopWatchDtoToJSON(value['stopWatch']),
        'Messages': value['messages'] == null ? undefined : ((value['messages'] as Array<any>).map(ServiceMessageDtoToJSON)),
        'UserLensId': value['userLensId'],
        'LastUpdatedAt': DateDtoToJSON(value['lastUpdatedAt']),
        'DrillingSplits': DrillingSplitsDtoToJSON(value['drillingSplits']),
        'TotalConnectionCount': value['totalConnectionCount'],
        'ReamingConnectionCount': value['reamingConnectionCount'],
        'ReamingDuration': value['reamingDuration'],
        'DataState': ResultDataStateToJSON(value['dataState']),
    };
}

