import { useQueryClient } from "@tanstack/react-query";
import { StyledButton } from "components/MiniLens/style";
import { PDComponent } from "components/PDComponents";
import { useAppDispatch } from "reducers/store";
import type { PDLensFactsQueryKey } from "utils/queryNamespaces";

import { FallbackContainer } from "./style";

export const LensFallback = ({ resetError, lensId }: { resetError: () => void; lensId: number }) => {
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  return (
    <FallbackContainer>
      <StyledButton
        onClick={() => {
          dispatch({
            type: "SET_LOADING_KPI_TYPE_FACTS",
            payload: false,
          });

          queryClient
            .refetchQueries({
              predicate: (query): boolean => (query.queryKey[0] as PDLensFactsQueryKey).lensId === lensId,
            })
            .then(() => {
              resetError();
            });
        }}
      >
        <PDComponent.SvgIcon name="refetchIcon" width={40} height={40} />
      </StyledButton>
    </FallbackContainer>
  );
};
