/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClaimWellInfo } from './ClaimWellInfo';
import {
    ClaimWellInfoFromJSON,
    ClaimWellInfoFromJSONTyped,
    ClaimWellInfoToJSON,
} from './ClaimWellInfo';

/**
 * 
 * @export
 * @interface UserRigAccessDto
 */
export interface UserRigAccessDto {
    /**
     * 
     * @type {number}
     * @memberof UserRigAccessDto
     */
    rigId: number;
    /**
     * 
     * @type {number}
     * @memberof UserRigAccessDto
     */
    rigExternalId: number;
    /**
     * 
     * @type {Array<ClaimWellInfo>}
     * @memberof UserRigAccessDto
     */
    wells?: Array<ClaimWellInfo> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRigAccessDto
     */
    futureWellAccess?: boolean | null;
}

/**
 * Check if a given object implements the UserRigAccessDto interface.
 */
export function instanceOfUserRigAccessDto(value: object): value is UserRigAccessDto {
    if (!('rigId' in value) || value['rigId'] === undefined) return false;
    if (!('rigExternalId' in value) || value['rigExternalId'] === undefined) return false;
    return true;
}

export function UserRigAccessDtoFromJSON(json: any): UserRigAccessDto {
    return UserRigAccessDtoFromJSONTyped(json, false);
}

export function UserRigAccessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRigAccessDto {
    if (json == null) {
        return json;
    }
    return {
        
        'rigId': json['RigId'],
        'rigExternalId': json['RigExternalId'],
        'wells': json['Wells'] == null ? undefined : ((json['Wells'] as Array<any>).map(ClaimWellInfoFromJSON)),
        'futureWellAccess': json['FutureWellAccess'] == null ? undefined : json['FutureWellAccess'],
    };
}

export function UserRigAccessDtoToJSON(value?: UserRigAccessDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'RigId': value['rigId'],
        'RigExternalId': value['rigExternalId'],
        'Wells': value['wells'] == null ? undefined : ((value['wells'] as Array<any>).map(ClaimWellInfoToJSON)),
        'FutureWellAccess': value['futureWellAccess'],
    };
}

